/** @format */

import React from "react";

import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ButtonPdb } from "src/v2/designSystem";

export const BottomSheetActions = (props: {
  showBack: boolean;
  onBack?: () => void;
  onConfirm: () => void;
  disabled: boolean;
  buttonText: string;
  hideConfirm?: boolean;
  showDelete?: boolean;
  onDelete?: () => void;
}) => (
  <div className="flex items-center justify-between py-4">
    <div>
      {props.showBack && !props.showDelete && (
        <FontAwesomeIcon
          onClick={() => props.onBack && props.onBack()}
          className="p-2"
          icon={faArrowLeft}
          size="xl"
        />
      )}
      {props.showDelete && (
        <ButtonPdb
          icon={<FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon>}
          onClick={() => props.onDelete && props.onDelete()}
          variant="secondary"
        >
          Delete
        </ButtonPdb>
      )}
    </div>
    {!props.hideConfirm && (
      <ButtonPdb onClick={props.onConfirm} disabled={props.disabled}>
        {props.buttonText}
      </ButtonPdb>
    )}
  </div>
);
