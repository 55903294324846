/** @format */

import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

interface AccordionBoxProps {
  title: string;
  expandedAnswer: string[];
  accordionType?: "question" | "information";
  backgroundColor?: string;
}

export const AccordionBox = ({
  title,
  expandedAnswer,
  accordionType = "question",
  backgroundColor,
}: AccordionBoxProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  let bgColor = "bg-cloud-light-40";
  if (accordionType === "question") {
    bgColor = "bg-sand-40";
  }
  if (backgroundColor) {
    bgColor = `${backgroundColor}`;
  }
  return (
    <div
      className={`p-4 my-4 text-14 cursor-pointer relative ${bgColor}`}
      onClick={() => setOpen(!open)}
    >
      <div className="flex items-center">
        {accordionType === "question" && <img src="/assets/helper-info.svg" alt="question icon" />}
        {accordionType === "information" && (
          <FontAwesomeIcon color={'"#1C4A46"'} className={"fa-lg"} icon={["far", "circle-info"]} />
        )}
        <span className="font-medium flex-1 ml-4">{title}</span>
        <FontAwesomeIcon icon={"chevron-down"} color={"#838A8A"} />
      </div>
      {open && (
        <div className="mt-3">
          {expandedAnswer.map((paragraph, index) => {
            const max = paragraph.length > 10 ? 10 : paragraph.length;
            const key = paragraph.slice(0, max).replace(" ", "");
            return (
              <p key={key} className={classnames({ "mt-4": index > 0 })}>
                {paragraph}
              </p>
            );
          })}
        </div>
      )}
    </div>
  );
};
