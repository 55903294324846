/** @format */

import React, { useEffect } from "react";

import type { ProfileSchema } from "src/api";
import type { ConsultsModel } from "src/v2/models/consults";

import { formatText } from "src/content/contentUtils";
import membershipBenefits from "src/content/v2/components/MembershipBenefits/membershipBenefits";
import { useMembershipPrice } from "src/utils/hooks/useMembershipPrice";
import { Button } from "src/v2/components/Button";
import MarkdownText from "src/v2/components/MarkdownText/MarkdownText";
import { MembershipModal } from "src/v2/components/MonthlyMembership/MembershipModal";
import { CONSULT_FEE_STR, MEMBERSHIP_QUARTERLY_FEE, MEMBERSHIP_YEARLY_FEE } from "src/v2/constants";
import { useStoreState } from "src/v2/models";

export interface Benefit {
  imageSrc: string;
  title: string;
}

const monthlyMembershipBenefits: Benefit[] = [
  { imageSrc: "/assets/membership-prompt-messages.svg", title: membershipBenefits.benefitMessages },
  {
    imageSrc: "/assets/membership-prompt-health.svg",
    title: membershipBenefits.benefitHealth,
  },
  {
    imageSrc: "/assets/membership-prompt-cost.svg",
    title: membershipBenefits.benefitCost,
  },
];

interface MembershipBenefitsProps {
  href?: string;
}

interface MonthlyMembershipBenefitsProps extends MembershipBenefitsProps {
  condition: string;
  profile: ProfileSchema;
  onSkipVisit: () => void;
}

export const MonthlyMembershipBenefitsPrompt = (props: MonthlyMembershipBenefitsProps) => {
  const { currentConsultation } = useStoreState((state) => state.consults) as ConsultsModel;

  useEffect(() => {
    if (currentConsultation) {
      localStorage.setItem("currentConsultationId", currentConsultation.id.toString());
    }
  }, []);

  const visitCost =
    props.condition === "birthcontrol" || props.condition === "eyelashes" ? "$15" : CONSULT_FEE_STR;
  const skipButtonText = formatText(membershipBenefits.skipButton, { price: visitCost });
  const membershipPriceInfo = useMembershipPrice();

  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <div className="flex flex-1 items-center">
        <div className="pt-2">
          <div className="flex flex-row mb-4 items-start px-6">
            <div className="flex flex-1 items-center flex-col text-center">
              <img src={"/assets/alpha-logo-forest.svg"} alt="alpha logo" className="w-20" />
              <h2 className="font-bold text-sm my-1 uppercase " style={{ letterSpacing: ".25em" }}>
                {membershipBenefits.title}
              </h2>
            </div>
            <div className="flex align-top">
              <img
                src="/assets/close.svg"
                onClick={() => {
                  props.onSkipVisit();
                }}
                className="cursor-pointer"
                alt="Close Alpha Plan Benefits prompt"
              />
            </div>
          </div>
          <div className="flex justify-center xl:px-20 lg:px-16 md:px-4 sm:px-4">
            <img src={"/assets/membership-prompt-banner.svg"} className="w-screen mb-6" />
          </div>
          <div className="mb-1 px-6">
            <div className="mb-6 text-left text-lg font-medium">{membershipBenefits.details}</div>
            <div className="flex flex-col mb-2">
              {monthlyMembershipBenefits.map((benefit) => {
                return (
                  <div className="flex flex-row items-center py-3 " key={benefit.title}>
                    <img className="pr-4" src={benefit.imageSrc} alt={"benefit icon"} />
                    <MarkdownText
                      containerClassName="text-left link-cornflower"
                      className="text-sm font-normal"
                      text={benefit.title}
                      callback={() => setIsOpen(true)}
                    />
                  </div>
                );
              })}
            </div>
            <Button
              bgColor="forest-100"
              textColor="white"
              href={props.href}
              extraClassNames={"rounded font-medium"}
            >
              {formatText(membershipBenefits.joinButton, {
                price: membershipPriceInfo["alpha"].hasBenefit
                  ? (membershipPriceInfo["alpha"].discountAmountString as string)
                  : (membershipPriceInfo["alpha"].originalPriceString as string),
              })}
            </Button>
            <button
              onClick={() => {
                props.onSkipVisit();
              }}
              className="justify-center items-center text-sm text-cornflower-100 my-6 w-full"
            >
              {skipButtonText}
            </button>
          </div>
        </div>
      </div>
      <MembershipModal modalOpen={isOpen} setModalOpen={setIsOpen} />
    </>
  );
};
