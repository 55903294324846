/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddressesCreateAddressRequestBodySchema } from '../models/AddressesCreateAddressRequestBodySchema';
import type { AddressesSaveAddressRequestBodySchema } from '../models/AddressesSaveAddressRequestBodySchema';
import type { BillingInfoSchema } from '../models/BillingInfoSchema';
import type { CheckInDataSchema } from '../models/CheckInDataSchema';
import type { CouponSchema } from '../models/CouponSchema';
import type { CreditInfoSchema } from '../models/CreditInfoSchema';
import type { EmergencyContactSchema } from '../models/EmergencyContactSchema';
import type { FormReponsesCollectionSchema } from '../models/FormReponsesCollectionSchema';
import type { GenericAddressSchema } from '../models/GenericAddressSchema';
import type { GenericChartSchema } from '../models/GenericChartSchema';
import type { GenericLabSchema } from '../models/GenericLabSchema';
import type { InsuranceSchema } from '../models/InsuranceSchema';
import type { LabsUploadLabRequestBodySchema } from '../models/LabsUploadLabRequestBodySchema';
import type { Medication_TrackingAddMedicationTrackingRequestBodySchema } from '../models/Medication_TrackingAddMedicationTrackingRequestBodySchema';
import type { Medication_TrackingAddSymptomTrackingRequestBodySchema } from '../models/Medication_TrackingAddSymptomTrackingRequestBodySchema';
import type { Medication_TrackingUpdateMedicationTrackingRequestBodySchema } from '../models/Medication_TrackingUpdateMedicationTrackingRequestBodySchema';
import type { Medication_TrackingUpdateSymptomTrackingRequestBodySchema } from '../models/Medication_TrackingUpdateSymptomTrackingRequestBodySchema';
import type { PatientMedicationTrackingSchema } from '../models/PatientMedicationTrackingSchema';
import type { PatientSchema } from '../models/PatientSchema';
import type { PcpCreatePcpRequestBodySchema } from '../models/PcpCreatePcpRequestBodySchema';
import type { PcpUpdatePcpRequestBodySchema } from '../models/PcpUpdatePcpRequestBodySchema';
import type { PhiAddUserObservationRequestBodySchema } from '../models/PhiAddUserObservationRequestBodySchema';
import type { PhiEditUserObservationRequestBodySchema } from '../models/PhiEditUserObservationRequestBodySchema';
import type { PhiUpdatePhiRequestBodySchema } from '../models/PhiUpdatePhiRequestBodySchema';
import type { PiiSchema } from '../models/PiiSchema';
import type { ProfileSchema } from '../models/ProfileSchema';
import type { SavingsSeekerSchema } from '../models/SavingsSeekerSchema';
import type { SecondaryUserViewsRegisterRequestBodySchema } from '../models/SecondaryUserViewsRegisterRequestBodySchema';
import type { SecondaryUserViewsUpdateUserPiiRequestBodySchema } from '../models/SecondaryUserViewsUpdateUserPiiRequestBodySchema';
import type { SymptomTrackingSchema } from '../models/SymptomTrackingSchema';
import type { UserChatAuthTokenSchema } from '../models/UserChatAuthTokenSchema';
import type { UserInsuranceUpdateInsurancePhotoRequestBodySchema } from '../models/UserInsuranceUpdateInsurancePhotoRequestBodySchema';
import type { UserPlanSchema } from '../models/UserPlanSchema';
import type { UserRelationshipsSchema } from '../models/UserRelationshipsSchema';
import type { Users2RequestUpdatePhotoIdRequestBodySchema0 } from '../models/Users2RequestUpdatePhotoIdRequestBodySchema0';
import type { Users2RequestUpdatePhotoIdRequestBodySchema1 } from '../models/Users2RequestUpdatePhotoIdRequestBodySchema1';
import type { Users2StartPatientCheckinRequestBodySchema } from '../models/Users2StartPatientCheckinRequestBodySchema';
import type { UsersPutUserEmergencyContactRequestBodySchema } from '../models/UsersPutUserEmergencyContactRequestBodySchema';
import type { UsersPutUsersRequestBodySchema } from '../models/UsersPutUsersRequestBodySchema';
import type { UsersSaveUserBillingInfoRequestBodySchema } from '../models/UsersSaveUserBillingInfoRequestBodySchema';
import type { UsersUpdateUserNkdaRequestBodySchema } from '../models/UsersUpdateUserNkdaRequestBodySchema';
import type { VisitHistorySchema } from '../models/VisitHistorySchema';
import type { Weight_Loss_JournalUpdateWeightLossJournalPreferencesRequestBodySchema } from '../models/Weight_Loss_JournalUpdateWeightLossJournalPreferencesRequestBodySchema';
import type { WeightLossJournalPreferencesSchema } from '../models/WeightLossJournalPreferencesSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * @deprecated
     * @returns any No response format defined
     * @throws ApiError
     */
    public static grantChannelAccess({
        channelId,
    }: {
        channelId: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/channels/{channel_id}/grant',
            path: {
                'channel_id': channelId,
            },
        });
    }

    /**
     * @returns CheckInDataSchema
     * @throws ApiError
     */
    public static getPatientCheckinData({
        userId,
        checkinKey,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        /**
         * Key to identify the check-in outline form key.
         */
        checkinKey?: string,
    }): CancelablePromise<CheckInDataSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/checkin/{user_id}',
            path: {
                'user_id': userId,
            },
            query: {
                'checkin_key': checkinKey,
            },
        });
    }

    /**
     * @returns CheckInDataSchema
     * @throws ApiError
     */
    public static startPatientCheckin({
        userId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        requestBody: Users2StartPatientCheckinRequestBodySchema,
    }): CancelablePromise<CheckInDataSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/checkin/{user_id}',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Gets account data for a given user
     * Returns high level user level data, and allows different groups of data to be queried and returned in a single
     * request.
     * ---
     * @returns ProfileSchema
     * @throws ApiError
     */
    public static getProfile({
        userId,
        include,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        /**
         * A '+' separated list of attributes to include in the response. Allowed attributes are pii, insurance, followup_items, zendesk_info, subscriptions, consults, phi, assigned_clinicians, licenses, winbacks, primary_provider, current_state, children, parents, emergency_contact, membership, employer_benefits, flag, flagged_at, flag_reason, active_reason
         */
        include?: string,
    }): CancelablePromise<ProfileSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/profile/{user_id}',
            path: {
                'user_id': userId,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getAllProvidersPii({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/profile/{user_id}/all_providers_pii',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static requestUpdatePhotoId({
        requestBody,
    }: {
        requestBody: (Users2RequestUpdatePhotoIdRequestBodySchema0 | Users2RequestUpdatePhotoIdRequestBodySchema1),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/request-update-photo-id',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns BillingInfoSchema
     * @throws ApiError
     */
    public static userBillingInfo(): CancelablePromise<BillingInfoSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/billing-info',
        });
    }

    /**
     * @returns BillingInfoSchema
     * @throws ApiError
     */
    public static saveUserBillingInfo({
        requestBody,
    }: {
        requestBody: UsersSaveUserBillingInfoRequestBodySchema,
    }): CancelablePromise<BillingInfoSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/billing-info',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns BillingInfoSchema
     * @throws ApiError
     */
    public static saveUserBillingInfo1({
        requestBody,
    }: {
        requestBody: UsersSaveUserBillingInfoRequestBodySchema,
    }): CancelablePromise<BillingInfoSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/billing-info',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CreditInfoSchema
     * @throws ApiError
     */
    public static userCreditInfo(): CancelablePromise<CreditInfoSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/credit-info',
        });
    }

    /**
     * @deprecated
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getUser({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static putUsers({
        userId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        requestBody: UsersPutUsersRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/{user_id}',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GenericAddressSchema
     * @throws ApiError
     */
    public static getAddresses({
        userId,
        id,
        includeArchived,
        includeLegacy,
        type,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        /**
         * Set to filter the return addresses by address id
         */
        id?: string,
        /**
         * Whether or not archived pharmacies should be included
         */
        includeArchived?: boolean,
        /**
         * Whether or not data from the legacy pharmacy table should be used
         */
        includeLegacy?: boolean,
        /**
         * Set to filter the return addresses by address type
         */
        type?: string,
    }): CancelablePromise<Array<GenericAddressSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/addresses',
            path: {
                'user_id': userId,
            },
            query: {
                'id': id,
                'include_archived': includeArchived,
                'include_legacy': includeLegacy,
                'type': type,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static createAddress({
        userId,
        requestBody,
        validateAddress,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        requestBody: AddressesCreateAddressRequestBodySchema,
        validateAddress?: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/{user_id}/addresses',
            path: {
                'user_id': userId,
            },
            query: {
                'validate_address': validateAddress,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static saveAddress({
        userId,
        addressId,
        requestBody,
        validateAddress,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        addressId: number,
        requestBody: AddressesSaveAddressRequestBodySchema,
        validateAddress?: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/{user_id}/addresses/{address_id}',
            path: {
                'user_id': userId,
                'address_id': addressId,
            },
            query: {
                'validate_address': validateAddress,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserPlanSchema
     * @throws ApiError
     */
    public static getUserBenefits({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<Array<UserPlanSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/benefits',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getChannels({
        userId,
        include,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        include?: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/channels',
            path: {
                'user_id': userId,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Fetch overview of patients weight loss data
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getWeightLossOverview({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/charts/weight-loss/overview',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * Fetch summary of patients weight loss data
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getWeightLossSummary({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/charts/weight-loss/summary',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * Fetch a patients chart data
     * @returns GenericChartSchema
     * @throws ApiError
     */
    public static getChartData({
        userId,
        chartType,
        includeObservations,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        chartType: string,
        /**
         * if true, include UserObservation values matching chart name
         */
        includeObservations?: boolean,
    }): CancelablePromise<GenericChartSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/charts/{chart_type}',
            path: {
                'user_id': userId,
                'chart_type': chartType,
            },
            query: {
                'include_observations': includeObservations,
            },
        });
    }

    /**
     * @deprecated
     * @returns any No response format defined
     * @throws ApiError
     */
    public static usersGetAuthKey({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/chat-auth-key',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns UserChatAuthTokenSchema
     * @throws ApiError
     */
    public static refreshChatAuthToken({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<UserChatAuthTokenSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/{user_id}/chat-auth-token',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns VisitHistorySchema
     * @throws ApiError
     */
    public static userConsultHistory({
        userId,
        consultId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        consultId: string,
    }): CancelablePromise<VisitHistorySchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/consults/{consult_id}/history',
            path: {
                'user_id': userId,
                'consult_id': consultId,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static checkAndApplyCoupon({
        userId,
        couponCode,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        couponCode?: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/coupons',
            path: {
                'user_id': userId,
            },
            query: {
                'coupon_code': couponCode,
            },
        });
    }

    /**
     * @returns EmergencyContactSchema
     * @throws ApiError
     */
    public static getUserEmergencyContact({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<EmergencyContactSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/emergency_contact',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static putUserEmergencyContact({
        userId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        requestBody: UsersPutUserEmergencyContactRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/{user_id}/emergency_contact',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static putUserEmergencyContact1({
        userId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        requestBody: UsersPutUserEmergencyContactRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/{user_id}/emergency_contact',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getPatientHealthCheckins({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/health-checkins',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns PatientMedicationTrackingSchema
     * @throws ApiError
     */
    public static getMedicationTracking({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<Array<PatientMedicationTrackingSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/medication-tracking',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns PatientMedicationTrackingSchema
     * @throws ApiError
     */
    public static addMedicationTracking({
        userId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        requestBody: Medication_TrackingAddMedicationTrackingRequestBodySchema,
    }): CancelablePromise<PatientMedicationTrackingSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/{user_id}/medication-tracking',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static deleteMedicationTracking({
        userId,
        trackingId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        trackingId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/users/{user_id}/medication-tracking/{tracking_id}',
            path: {
                'user_id': userId,
                'tracking_id': trackingId,
            },
        });
    }

    /**
     * @returns PatientMedicationTrackingSchema
     * @throws ApiError
     */
    public static updateMedicationTracking({
        userId,
        trackingId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        trackingId: number,
        requestBody: Medication_TrackingUpdateMedicationTrackingRequestBodySchema,
    }): CancelablePromise<PatientMedicationTrackingSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/{user_id}/medication-tracking/{tracking_id}',
            path: {
                'user_id': userId,
                'tracking_id': trackingId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static updateUserNkda({
        userId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        requestBody: UsersUpdateUserNkdaRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/{user_id}/nkda',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static addUserObservation({
        userId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        requestBody: PhiAddUserObservationRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/{user_id}/observation',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static deleteUserObservation({
        userId,
        observationId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        observationId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/users/{user_id}/observation/{observation_id}',
            path: {
                'user_id': userId,
                'observation_id': observationId,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static editUserObservation({
        userId,
        observationId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        observationId: number,
        requestBody: PhiEditUserObservationRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/{user_id}/observation/{observation_id}',
            path: {
                'user_id': userId,
                'observation_id': observationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getPcp({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/pcp',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static createPcp({
        userId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        requestBody: PcpCreatePcpRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/{user_id}/pcp',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static updatePcp({
        userId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        requestBody: PcpUpdatePcpRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/{user_id}/pcp',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static updatePhi({
        userId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        requestBody: PhiUpdatePhiRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/{user_id}/phi',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserRelationshipsSchema
     * @throws ApiError
     */
    public static getUserRelationships({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<UserRelationshipsSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/relationships',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * Fetch a patients savings seeker card if it exists or creates one
     * @returns SavingsSeekerSchema
     * @throws ApiError
     */
    public static getSavingsSeekerCard({
        userId,
        consultId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        consultId: number,
    }): CancelablePromise<SavingsSeekerSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/savings-seeker/{consult_id}',
            path: {
                'user_id': userId,
                'consult_id': consultId,
            },
        });
    }

    /**
     * @returns SymptomTrackingSchema
     * @throws ApiError
     */
    public static getSymptomTracking({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<Array<SymptomTrackingSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/symptom-tracking',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns SymptomTrackingSchema
     * @throws ApiError
     */
    public static addSymptomTracking({
        userId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        requestBody: Medication_TrackingAddSymptomTrackingRequestBodySchema,
    }): CancelablePromise<SymptomTrackingSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/{user_id}/symptom-tracking',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static deleteSymptomTracking({
        userId,
        trackingId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        trackingId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/users/{user_id}/symptom-tracking/{tracking_id}',
            path: {
                'user_id': userId,
                'tracking_id': trackingId,
            },
        });
    }

    /**
     * @returns SymptomTrackingSchema
     * @throws ApiError
     */
    public static updateSymptomTracking({
        userId,
        trackingId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        trackingId: number,
        requestBody: Medication_TrackingUpdateSymptomTrackingRequestBodySchema,
    }): CancelablePromise<SymptomTrackingSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/{user_id}/symptom-tracking/{tracking_id}',
            path: {
                'user_id': userId,
                'tracking_id': trackingId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GenericLabSchema
     * @throws ApiError
     */
    public static getUserLabs({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<Array<GenericLabSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/user-labs',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static uploadLab({
        userId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        requestBody: LabsUploadLabRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/{user_id}/user-labs',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns WeightLossJournalPreferencesSchema
     * @throws ApiError
     */
    public static getWeightLossJournalPreferences({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<WeightLossJournalPreferencesSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/weight-loss-journal-preferences',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns WeightLossJournalPreferencesSchema
     * @throws ApiError
     */
    public static updateWeightLossJournalPreferences({
        userId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        requestBody: Weight_Loss_JournalUpdateWeightLossJournalPreferencesRequestBodySchema,
    }): CancelablePromise<WeightLossJournalPreferencesSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/{user_id}/weight-loss-journal-preferences',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Creates a new User, UserPii, UserPhi, RecurlyAccount object, and UserPlan if needed.
     * ---
     * @returns any No response format defined
     * @throws ApiError
     */
    public static register({
        requestBody,
    }: {
        requestBody: SecondaryUserViewsRegisterRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/register',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Link an anonymous user to an existing user in a B2B user registration flow.
     * Args:
     * user_id (str): The ID of the existing user.
     * anonymous_user_id (str): The ID of the anonymous user to be linked.
     *
     * Returns:
     * tuple: A tuple containing the success message and HTTP status code (Success, 200).
     *
     * Raises:
     * ApiError: If an error occurs while linking the anonymous user with the existing user.
     * @returns any No response format defined
     * @throws ApiError
     */
    public static linkAnonymousUserToAccount({
        userId,
        anonymousUserId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        anonymousUserId: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/user/{user_id}/anonymous_user/{anonymous_user_id}',
            path: {
                'user_id': userId,
                'anonymous_user_id': anonymousUserId,
            },
        });
    }

    /**
     * @returns FormReponsesCollectionSchema
     * @throws ApiError
     */
    public static getUserRegistrationForm({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<FormReponsesCollectionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/user/{user_id}/registration_form',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * Get a user
     * Returns a users data (excluding pii) and links to related resources
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getUser1({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/users/{user_id}',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * Creates a new User, UserPii, UserPhi and RecurlyAccount object
     * ---
     * @returns any No response format defined
     * @throws ApiError
     */
    public static addRelationship({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/users/{user_id}/add-relationship',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * Returns a users signed consent records
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getUserConsents({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/users/{user_id}/consents',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns CouponSchema
     * @throws ApiError
     */
    public static getEligibleRecurlyCoupon({
        userId,
        conditionKey,
        couponCode,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        /**
         * Consult condition key
         */
        conditionKey?: string,
        /**
         * Recurly coupon code
         */
        couponCode?: string,
    }): CancelablePromise<CouponSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/users/{user_id}/coupon_codes',
            path: {
                'user_id': userId,
            },
            query: {
                'condition_key': conditionKey,
                'coupon_code': couponCode,
            },
        });
    }

    /**
     * Fetch a users insurance info
     * @returns InsuranceSchema
     * @throws ApiError
     */
    public static getInsurance({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<InsuranceSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/users/{user_id}/insurance',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * Updates a users insurance data
     * Updates different insurance fields for a users. If setting either the front photo or back photo data, the photo data
     * must be a string encoded as a data URI (https://en.wikipedia.org/wiki/Data_URI_scheme)
     * @returns InsuranceSchema
     * @throws ApiError
     */
    public static updateInsurancePhoto({
        userId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        requestBody: UserInsuranceUpdateInsurancePhotoRequestBodySchema,
    }): CancelablePromise<InsuranceSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v2/users/{user_id}/insurance',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static patients({
        userId,
        desc,
        page = 1,
        pageSize = 10,
        query,
        sort = 'created_at',
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        /**
         * Whether or not the results should be sorted in descending order or not
         */
        desc?: boolean,
        /**
         * Which page of results to return
         */
        page?: number,
        /**
         * How many results to return per page
         */
        pageSize?: number,
        /**
         * Filter by the query column
         */
        query?: string,
        /**
         * The attribute to sort by
         */
        sort?: string,
    }): CancelablePromise<{
        items: Array<PatientSchema>;
        page: number;
        per_page: number;
        total: number;
        total_pages: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/users/{user_id}/patients',
            path: {
                'user_id': userId,
            },
            query: {
                'desc': desc,
                'page': page,
                'page_size': pageSize,
                'query': query,
                'sort': sort,
            },
        });
    }

    /**
     * Returns a users pii data
     * ---
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getUserPii({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/users/{user_id}/pii',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns PiiSchema
     * @throws ApiError
     */
    public static updateUserPii({
        userId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        requestBody: SecondaryUserViewsUpdateUserPiiRequestBodySchema,
    }): CancelablePromise<PiiSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v2/users/{user_id}/pii',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getEligiblePromotions({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/users/{user_id}/promotions',
            path: {
                'user_id': userId,
            },
        });
    }

}
