/** @format */

import ReactMarkdown from "react-markdown";

import { ButtonPdb } from "src/v2/designSystem";

import type { planSlug } from "src/routes/Membership/MembershipOptions";

import { AlphaPlusAdditionalFeatures } from "./AlphaPlusAdditionalFeatures";
import { AlphaPlusBenefitCards } from "./AlphaPlusBenefitCards";
import { PlanComparisonChart } from "./PlanComparisonChart";
import TrustpilotWidget from "./TrustPilot";

export interface Feature {
  text: string;
  icon: string;
}

export interface VariantProps {
  showExtraSections?: boolean;
  showBenefitHeader?: boolean;
  showAlphaLogo?: boolean;
  buttonsIfMembershipIncluded?: any;
  styles: {
    cardsBackground?: string;
    titleAlignment?: string;
    plus?: { border: string; feature: string; button: string };
    alpha?: { border: string; feature: string; button: string };
  };
}

export interface PlanSection {
  /** Subtitle is an array of strings to allow for different styling */
  title: string[];
  plans: Plan[];
  benefitsDisclaimer?: string;
}

export interface Plan {
  id: string;
  title: string;
  pricePerDay?: string[];
  pricePerMonth?: string;
  pricePerMonthB2B?: string;
  priceStrikeThrough?: string;
  badge?: string;
  features: Feature[];
  cta: string;
}

export interface AlphaPlus {
  title: string[];
  features: Feature[];
}

export interface FooterContent {
  text: string;
  cta: string;
}

export interface PlanComparisonContent {
  plansSection: PlanSection;
  benefitsSection: Feature[];
  alphaPlusSection: AlphaPlus;
  footerSection: FooterContent;
}

interface PlanComparisonPageProps {
  handleOnClick: (planSlug: planSlug) => void;
  pageContent: PlanComparisonContent;
  cardsContent: PlanSection;
  plansOffered: planSlug[];
  buttonsIfMembershipIncluded?: any;
  variantProps: VariantProps;
}

export const PlanComparisonPage = ({
  handleOnClick,
  pageContent,
  cardsContent,
  plansOffered,
  variantProps,
}: PlanComparisonPageProps): JSX.Element => {
  const showExtraSections = variantProps?.showExtraSections;
  return (
    <>
      <PlanComparisonChart
        content={cardsContent}
        onSubmit={handleOnClick}
        plansOffered={plansOffered}
        variantProps={variantProps}
      />
      {pageContent.benefitsSection && showExtraSections && (
        <AlphaPlusBenefitCards content={pageContent.benefitsSection} />
      )}
      {pageContent.alphaPlusSection && showExtraSections && (
        <AlphaPlusAdditionalFeatures content={pageContent.alphaPlusSection} />
      )}
      {showExtraSections && (
        <div className="sm:max-w-2xl mx-auto w-full px-6 pt-8 pb-10 bg-cloud-40">
          <TrustpilotWidget />
          <ButtonPdb
            variant="primary"
            className="w-full my-6"
            onClick={(e) => {
              e.preventDefault();
              handleOnClick("plus");
            }}
          >
            {pageContent.footerSection.cta}
          </ButtonPdb>
          {pageContent.footerSection.text && (
            <span className="text-xs font-normal text-grey-160">
              <ReactMarkdown>{pageContent.footerSection.text}</ReactMarkdown>
            </span>
          )}
        </div>
      )}
    </>
  );
};
