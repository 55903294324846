/** @format */

import { FORM_ERROR } from "final-form";

import type { DynamicFormPage, EventCallbacks } from "src/components/DynamicForm/types";

import { isSuicidal } from "src/components/DynamicForm/Validators/services/group-therapy";

import type { FormValidator } from "./types";

import { blockMinors, groupTherapyEligibility } from "./eligibility";

const validatorFunctions: Record<string, FormValidator> = {
  "eligibility:minors": blockMinors,
  "eligibility:group-therapy": groupTherapyEligibility,
  suicidal: isSuicidal,
};

export const validatePage = (
  callbacks: EventCallbacks,
  values: Record<string, any>,
  page: DynamicFormPage,
) => {
  const validators = (callbacks.trigger || []) // Get list of beforeSubmit Triggers from page
    .filter((callback) => callback.type === "validate") // Only get validation triggers
    .map((callback) => validatorFunctions[callback.id]) // Map triggers to functions
    .filter((validator) => !!validator); // Filter out triggers that failed to map to a function

  for (let idx = 0; idx < validators.length; idx++) {
    const result = validators[idx](values);
    if (result) {
      return { [FORM_ERROR]: result };
    }
  }
  return null;
};
