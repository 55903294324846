/** @format */

import { includes, intersection, isEmpty, isEqual, keys, pick, size, some } from "lodash";

export const pages = [
  "intro",
  "authorization",
  "details",
  "disclosure-overview",
  "terms",
  "identity",
  "sign",
  "success",
];

export interface FormValues {
  // confirm personal details
  authorized_person: "myself" | "other";
  first_name?: string;
  first_name_or_organization?: string;
  last_name?: string;
  dob?: string;
  address: string;
  address2?: string;
  city: string;
  state: string;
  zipcode: string;
  phone_number: string;
  email?: string;
  fax?: string;

  // disclosure overview
  disclosure_overview: string;
  only_details?: string;
  all_except_details?: string[];
  all_except_details_other?: string;

  // terms and conditions
  terms_checkbox: string[];

  // form completed by
  completed_by: string;
  id_photo?: string;

  // signature
  signature: string;
  signature_checkbox: string[];
  signature_date: string;
}

const baseRequiredDetails = ["address", "city", "state", "zipcode", "email", "phone_number"];

const requiredDetailsForMyself = ["first_name", "last_name", "dob", ...baseRequiredDetails];

const requiredDetailsForSomeoneElse = ["first_name_or_organization", ...baseRequiredDetails];

export const validatePage = (values: FormValues, pageKey: string): string | null => {
  if (includes(["intro", "authorization", "disclosure-details"], pageKey)) {
    // no validation needed on these pages
    return null;
  }
  if (pageKey === "details") {
    if (!values?.["authorized_person"]) {
      return "Please select who you are requesting records for";
    } else {
      const authorizedPerson = values?.["authorized_person"];
      const requiredDetails =
        authorizedPerson === "myself" ? requiredDetailsForMyself : requiredDetailsForSomeoneElse;
      if (
        some(pick(values, requiredDetails), isEmpty) ||
        size(intersection(keys(values), requiredDetails)) !== size(requiredDetails)
      ) {
        return "Please fill out the required fields";
      }
    }
  }

  if (pageKey === "disclosure-overview") {
    const disclosure = values?.["disclosure_overview"];
    if (!disclosure) {
      return "Please select one of the disclosure options";
    }
    if (
      (disclosure === "all_except" && !values?.["all_except_details"]) ||
      (disclosure === "only" && !values?.["only_details"])
    ) {
      return "Please enter something into the text input to clarify your disclosure preferences";
    }
    if (
      disclosure === "all_except" &&
      includes(values?.["all_except_details"], "other") &&
      !values?.["all_except_details_other"]
    ) {
      return "Please enter something into the text input to clarify your disclosure preferences";
    }
  }
  if (pageKey === "terms" && !isEqual(values?.["terms_checkbox"], ["true"])) {
    return "You must agree to the above terms and conditions to proceed";
  }
  if (pageKey === "identity") {
    const completedBy = values?.["completed_by"];
    if (!completedBy) {
      return "Please specify who completed this form";
    }
    if (!values?.["id_photo"]) {
      return "Please upload a photo of your ID";
    }
  }
  if (pageKey === "sign") {
    if (!isEqual(values?.["signature_checkbox"], ["true"])) {
      return "Please consent to using an e-signature";
    }
    if (!values?.signature || !values?.["signature_date"]) {
      return "Please sign and date above";
    }
  }
  return null;
};
