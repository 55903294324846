/** @format */

import React from "react";
import Modal from "react-modal";

import { ButtonPdb } from "src/v2/designSystem";

import { ConsentsService } from "src/api";
import { TERMS_AND_CONDITIONS_TEXT } from "src/content/src/v2/components/Popups/FollowupItemPopupFile/TermsAndConditions";
import { FullPageModal } from "src/v2/components";
import { MarkdownText } from "src/v2/components/MarkdownText";
import { useStoreDispatch } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";

interface ListItemProps {
  text: string;
  iconPath: string;
  altText: string;
  first: boolean;
  url: string;
}

const ListItem = (props: ListItemProps) => {
  return (
    <>
      {props.first && <div className="border-b-2 border-grey-2" />}
      <a href={props.url} className="flex-row flex items-center py-6 cursor-pointer">
        <img className="flex-initial" src={`assets/${props.iconPath}`} alt={props.altText} />
        <p className="flex-initial ml-4">{props.text}</p>
        <img
          src="assets/carrot-right-primary.svg"
          alt="Right Arrow"
          className="flex-initial ml-auto"
        />
      </a>
      <div className="border-b-2 border-grey-2" />
    </>
  );
};

type ConsentFollowup = {
  consent_list?: any;
  followup_type: string;
};

interface Consent {
  date_signed: string;
  is_latest: boolean;
  key: string;
  latest_version: string;
  signed_version: string;
}

interface TermsAndConditionsProps {
  item: ConsentFollowup;
  closeMainModal: () => void;
  mainModalOpen: boolean;
}

const TermsAndConditions = (props: TermsAndConditionsProps) => {
  const user = useCurrentUser();
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const dispatch = useStoreDispatch();

  const signConsent = () => {
    const consent_forms = props.item.consent_list.map((consent: Consent) => {
      return {
        key: consent.key,
        version: consent.latest_version,
      };
    });
    ConsentsService.signConsentsForUser({
      userId: user.id,
      requestBody: { consent_forms: consent_forms },
    })
      .then(() => {
        return dispatch.profile.fetchProfile({ includeAll: true });
      })
      .then(() => {
        setModalOpen(false);
        props.closeMainModal();
      });
  };

  const name = user.pii.preferred_firstname || user.pii.first_name;

  if (!props.item.consent_list || props.item.consent_list.length == 0) {
    props.closeMainModal();
  }

  return (
    <Modal
      style={{ overlay: { backgroundColor: "rgba(180,180,180, .5)" } }}
      isOpen={props.mainModalOpen}
      className={"bg-white h-full w-full absolute right-0 overflow-y-scroll"}
      aria={{
        labelledby: "modal-header",
        describedby: "modal-content",
      }}
    >
      <FullPageModal
        setIsOpen={setModalOpen}
        isOpen={modalOpen}
        title={TERMS_AND_CONDITIONS_TEXT.modalTitle}
      >
        <div className="px-6">
          <h1 className="my-10 text-xl text-forest-darker font-medium">
            {TERMS_AND_CONDITIONS_TEXT.modalHeader}
          </h1>
          <p className="mb-10">
            <MarkdownText
              text={TERMS_AND_CONDITIONS_TEXT.modalBody}
              linkStyle={{ color: "#6271C2" }}
            />
          </p>
          <ButtonPdb onClick={() => setModalOpen(false)}>
            {TERMS_AND_CONDITIONS_TEXT.goBack}
          </ButtonPdb>
          <p className="my-10 font-medium">{TERMS_AND_CONDITIONS_TEXT.contactUs}</p>
          <div className="mt-10">
            <ListItem
              text={TERMS_AND_CONDITIONS_TEXT.listItemEmail}
              altText="Message Icon"
              iconPath="message-care-team.svg"
              first={true}
              url={`mailto:${TERMS_AND_CONDITIONS_TEXT.listItemEmail}`}
            />
            <ListItem
              text={TERMS_AND_CONDITIONS_TEXT.listItemTelephone}
              altText="Phone Icon"
              iconPath="phone.svg"
              first={false}
              url="tel:+14156635584"
            />
          </div>
        </div>
      </FullPageModal>
      <div className="bg-sand h-12 flex-row text-center flex items-center justify-between px-4">
        <div className="flex-1 justify-start" />
        <img src="assets/alpha-logo.svg" alt="Alpha Logo" height={24} width={72} className="flex" />
        <div className="flex flex-1 justify-end text-xs">
          <div onClick={() => dispatch.auth.logout()}>{TERMS_AND_CONDITIONS_TEXT.logout}</div>
        </div>
      </div>
      <div className="w-96 mx-auto px-6">
        <h1 className="text-lg text-forest-darker my-10 font-medium">
          {TERMS_AND_CONDITIONS_TEXT.welcome}
          {name && `, ${name}.`}
        </h1>
        <img className="mb-10" src="assets/digital-signature.svg" alt="Digital Signature Icon" />
        <p className="mb-10">
          <MarkdownText text={TERMS_AND_CONDITIONS_TEXT.body} linkStyle={{ color: "#6271C2" }} />
        </p>
        <div className="flex-row flex items-center">
          <ButtonPdb onClick={signConsent} className="flex-initial mr-4">
            {TERMS_AND_CONDITIONS_TEXT.agree}
          </ButtonPdb>
          <p
            onClick={() => setModalOpen(true)}
            className="flex-initial text-cornflower-100 bg-transparent text-center font-semibold"
          >
            {TERMS_AND_CONDITIONS_TEXT.disagree}
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default TermsAndConditions;
