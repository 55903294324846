/** @format */

import React from "react";
import { Field, useForm } from "react-final-form";

import { Elements, RecurlyProvider, useRecurly } from "@recurly/react-recurly";
import _ from "lodash";

import CouponInput from "src/components/Coupon/CouponInput";
import { DefaultValueContext } from "src/components/DynamicForm/DynamicForm";
import { getRecurlyPublicKey } from "src/helpers/http";

import type { CustomFieldProps } from "./types";

interface CouponCodeOptions {
  coupon: string;
}

interface CouponError {
  name: string;
  code: string;
  message: string;
}

/** Wrapper around the recurly.coupon function to convert its results to a promise. Also adds types to the callback
 * values since they are missing from the recurly library. */
const getCouponData = (recurly: any, options: CouponCodeOptions) => {
  return new Promise((resolve, reject) => {
    recurly.coupon(options, (err: CouponError, coupon: any) => {
      if (err) {
        reject(err);
      } else {
        resolve(coupon);
      }
    });
  });
};

/** Custom field for use on the checkout page */
const InternalCouponCodeField: React.FC<CustomFieldProps> = ({ field }) => {
  const recurly = useRecurly();
  const form = useForm();

  // Get the default coupon code value if available
  const defaultValues = React.useContext(DefaultValueContext);
  const defaultValue =
    field.defaultValue != null ? field.defaultValue : _.get(defaultValues, field.key);
  return (
    <>
      {/* The actual form input. This input field is NOT connected to final form inorder to support the need for the
      field value to not be set until the "Apply" button is pressed. */}
      <CouponInput
        defaultValue={defaultValue}
        onSubmit={(e, code) => form.change(field.key, code)}
      />
      {/* A hidden field to tie the field value to the form state. Validates the coupon code by calling the recurly SDK */}
      <Field
        name={field.key}
        component="input"
        type="hidden"
        defaultValue={defaultValue}
        validate={(newCouponCode) => {
          return (
            newCouponCode &&
            getCouponData(recurly, { coupon: newCouponCode })
              .then((res) => null)
              .catch((err: CouponError) => err.message)
          );
        }}
      />
      {/* Display the validation errors for this field, if any. Unlike most fields we display the error even if the
      field is not "touched", since we don't want to wait for the next field to be clicked for the message to appear */}
      <Field name={field.key} subscription={{ error: true, submitError: true }}>
        {({ meta: { error, submitError } }) => {
          const showErrors = error || submitError;
          return <span className="h-6 block text-red">{showErrors && (submitError || error)}</span>;
        }}
      </Field>
    </>
  );
};

/** Wrapper around the actual coupon code field to make the recurly instance available via useRecurly */
export const CouponCodeField: React.FC<CustomFieldProps> = (props) => (
  <RecurlyProvider publicKey={getRecurlyPublicKey()}>
    <Elements>
      <InternalCouponCodeField {...props} />
    </Elements>
  </RecurlyProvider>
);
