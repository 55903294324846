/** @format */

// The string value that can be used for a "None" option, which will clear all other values when selected
export const NONE_KEYWORD = "!none";

export const parseCheckboxNewFieldValue = (values: any) => {
  if (!values || values.length <= 1) {
    return values;
  }
  const noneIdx = values.indexOf(NONE_KEYWORD);
  // If none is the newly selected option (end of the array), reset value to just have none selected
  if (noneIdx === values.length - 1) {
    return [NONE_KEYWORD];
    // If a new option is selected, unselect none
  } else if (noneIdx !== -1) {
    values.splice(noneIdx, 1);
    return values;
  }
  return values;
};
