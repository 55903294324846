/** @format */

// Module dependencies
import type { FC } from "react";

import React, { isValidElement } from "react";

import type { WebNavigationIconProps } from "src/v2/designSystem/WebNavigation";

// UI Components & Styles
import { BadgePdb } from "src/v2/designSystem/BadgePdb";

import "./BottomNavigation.scss";

export const BottomNavigationIcon: FC<WebNavigationIconProps> = ({
  badgeLabel,
  badgeVariant,
  clickCallback,
  iconActive,
  iconInactive,
  isActive,
  label,
  value,
}) => {
  const handleIconClick = () => {
    if (clickCallback) clickCallback(value);
  };

  return (
    <div
      className="inline-flex flex-col items-center justify-center gap-1"
      onClick={() => handleIconClick()}
    >
      <div
        className="flex items-center h-6 relative"
        style={{ color: "#1C4A46", fontSize: "21px" }}
      >
        {isActive ? (
          <>
            {typeof iconActive === "string" ? (
              <i className={iconActive} />
            ) : (
              isValidElement(iconActive) && iconActive
            )}
          </>
        ) : (
          <>
            {typeof iconInactive === "string" ? (
              <i className={iconInactive} />
            ) : (
              isValidElement(iconInactive) && iconInactive
            )}
          </>
        )}

        <BadgePdb
          variant={badgeVariant ? badgeVariant : "notification"}
          label={badgeLabel}
          textTransform="capitalize"
          size="small"
          addClasses="absolute bottom-nav-icon-badge"
        />
      </div>

      <span
        className={`text-xs leading-4 font-medium ${isActive ? "text-forest" : "text-neutral"}`}
      >
        {label}
      </span>
    </div>
  );
};
