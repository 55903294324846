/** @format */

import React, { useEffect, useRef } from "react";

import classNames from "classnames";

import type { Consultation } from "src/v2/types/consultation";

import welcome from "src/content/welcome";
import { conditionInfoMap, getConditionInfo } from "src/v2/constants";

interface ConditionAutoScrollerProps {
  currentConsultation?: Consultation;
  className: string;
  backgroundColor?: string;
}

interface ConditionWithIconProps {
  conditionKey: string;
  conditionName: string;
  conditionImageSrc: string;
}

const orderConditions = (currentConsultation?: Consultation) => {
  let orderedConditions = welcome.orderedConditionKeys;

  // push current condition to the front of the ordered conditions list
  if (currentConsultation && currentConsultation.condition_display_name) {
    const currentKey = Object.keys(conditionInfoMap).filter((key) => {
      const details = conditionInfoMap[key];
      return details.label === currentConsultation.condition_display_name;
    })[0];

    if (currentKey) {
      orderedConditions = orderedConditions.filter((x) => x !== currentKey);
      orderedConditions.unshift(currentKey);
    }
  }

  return orderedConditions.reduce<Array<ConditionWithIconProps>>((arr, nextKey) => {
    const details = getConditionInfo(nextKey);

    // this should never happen that the key doesn't exist, but we don't want a key to show if something in the future breaks this
    if (details.label !== nextKey) {
      arr.push({
        conditionKey: nextKey,
        // For only the PCOS condition we want to override the display name.
        conditionName: nextKey === "pcos" ? "PCOS" : details.label,
        conditionImageSrc: details.imageSrc,
      });
    }
    return arr;
  }, []);
};

const ConditionWithIcon = ({ conditionName, conditionImageSrc }: ConditionWithIconProps) => {
  return (
    <div className="flex flex-col content-center mr-3 welcome__condition-item">
      <div className="flex flex-row justify-center welcome__icon-container">
        <img
          src={conditionImageSrc}
          // Since the image is a companion to the text, it doesn't add value for screen readers
          alt=""
          className="welcome__icon-small"
        />
      </div>
      <p className="text-center text-primary text-sm welcome__condition-text">{conditionName}</p>
    </div>
  );
};

const ConditionScroller = ({
  currentConsultation,
  className,
  backgroundColor,
}: ConditionAutoScrollerProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!scrollRef.current) {
      return;
    }
    let location = 0;
    const interval = setInterval(() => {
      location += 1;
      scrollRef.current?.scrollTo(location, 0);
    }, 20);
    return () => {
      clearInterval(interval);
    };
  }, [scrollRef]);

  return (
    <div className={className}>
      <div
        className={classNames("w-full h-full max-w-100 relative", backgroundColor || "bg-sand-20")}
      >
        <div className="pt-8 pb-12 flex flex-row items-center overflow-x-auto" ref={scrollRef}>
          <div className={"ml-10 "} />
          {orderConditions(currentConsultation).map((item) => {
            return <ConditionWithIcon {...item} key={item.conditionKey} />;
          })}
        </div>
        <div className="welcome__disable-interaction-overlay"></div>
      </div>
    </div>
  );
};

export default ConditionScroller;
