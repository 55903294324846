/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProblemSearchResultSchema } from '../models/ProblemSearchResultSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserProblemsService {

    /**
     * @returns ProblemSearchResultSchema
     * @throws ApiError
     */
    public static searchProblemsTable({
        limit = 399,
        term,
    }: {
        limit?: number,
        term?: string,
    }): CancelablePromise<Array<ProblemSearchResultSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/search_problems',
            query: {
                'limit': limit,
                'term': term,
            },
        });
    }

}
