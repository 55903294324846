/** @format */

import React from "react";
import { Link } from "react-router-dom";

import _ from "lodash";
import moment from "moment";

const renderConditionNotices = (minAgeWithConsent: string) => {
  const conditionNotices = {
    state: {
      default: `We're sorry, we do not support your state at this time.`,
      "mental-health":
        "We’re sorry, the Alpha Mental Health Service is not available in your state at this time.  If you need help with other medical conditions, please visit the patient dashboard and start a new consultation.",
      "sti-testing-kit": {
        NY: "Our STI Testing services are unfortunately not available for patients in New York. Due to state regulations, we cannot ship the test kits to you directly. We're sorry for the inconvenience, but if you would like further information you can reach out to support from your messaging portal or via email at contact@helloalpha.com",
        default:
          "We’re sorry, our treatments for STI Testing Kits are not available in your state at this time.  If you need help with other medical conditions, please visit the patient dashboard and start a new consultation.",
      },
      "acid-reflux":
        "We’re sorry, our treatments for Acid Reflux are not available in your state at this time.  If you need help with other medical conditions, please visit the patient dashboard and start a new consultation.",
      nutrition:
        "We’re sorry, our treatments for Nutrition are not available in your state at this time.  If you need help with other medical conditions, please visit the patient dashboard and start a new consultation.",
    },
    sex: {
      hairloss: `
      Currently we are only able to treat assigned males at birth for this condition. However, we would love
      to know if there’s anything we can do to help. Feel free to leave us a comment or
      question in the patient messaging portal so we can better help meet your needs in the future.
    `,
      "mens-health": `
      Currently we are only able to treat assigned males at birth for this condition. However, we would love
      to know if there’s anything we can do to help. Feel free to leave us a comment or
      question in the patient messaging portal so we can better help meet your needs in the future.
    `,
      birthcontrol: `
      We can only prescribe hormonal contraceptives to assigned females at birth at this time.
      We’re sorry we’re unable to help you today, but we would still love to hear from you.
      Feel free to leave your comments or questions below so we can better help meet your
      needs in the future.
    `,
      "preconception-counseling": `
      This intake form regarding preconception and pre-pregnancy counseling is designed specifically for assigned females at birth. We’re sorry for the confusion, but if you would like to connect with an Alpha Provider about preconception counseling, please complete the Men’s Health visit.
      `,
      pcos: `
      We are only able to treat assigned females at birth for this condition.
      `,
      default: `
      Because our treatments have a strong link to a person’s hormonal balance, we can only treat assigned females at birth. For more information regarding this issue, please read our FAQs
    `,
    },
    dob: {
      birthcontrol: `
    At this time, laws in your state of residence allow clinicians to treat patients
    who are below the age of ${minAgeWithConsent} only with parental or guardian consent.<br/>
    We believe it’s important for all patients to get the care they need when they want it regardless of age.
    Therefore, with parent/guardian consent we are happy to provide our members with the care they need.<br/><br/>
    If you are unable to provide parent/guardian consent, feel free to reach out to us again when you turn ${minAgeWithConsent} -
    we’d love to join you on your journey.
      `,
      hairloss: `
      At this time, Alpha is only able to treat patients who are ${minAgeWithConsent} or older. This is to ensure
      we meet the special safeguards put in place for your safety and privacy. Feel
      free to reach out to us again when you turn ${minAgeWithConsent} - we’d love to join you on your journey.
    `,
      eyelashes: `
      At this time, Alpha is only able to treat patients who are ${minAgeWithConsent} or older. This is to ensure
      we meet the special safeguards put in place for your safety and privacy. Feel
      free to reach out to us again when you turn ${minAgeWithConsent} - we’d love to join you on your journey.
    `,
      skincare: `
      At this time, Alpha is only able to treat patients who are ${minAgeWithConsent} or older. This is to ensure
      we meet the special safeguards put in place for your safety and privacy as a minor.<br/> We
      also believe it’s important for all people to get the care they need when they want it
      regardless of age, so we’re working to make skin care treatment more widely available.<br/>
      Feel free to reach out to us again when you turn ${minAgeWithConsent} - we’d love to join you on your journey.
    `,
      "genital-herpes": `
    At this time, laws in your state of residence allow clinicians to treat patients
    who are below the age of ${minAgeWithConsent} only with parental or guardian consent.<br/>
    We believe it’s important for all patients to get the care they need when they want it regardless of age.
    Therefore, with parent/guardian consent we are happy to provide our members with the care they need.<br/><br/>
    If you are unable to provide parent/guardian consent, feel free to either continue or reach out to us again when you turn ${minAgeWithConsent} -
    we’d love to join you on your journey.
    `,
      "sti-testing-kit": `At this time, laws in your state of residence allow clinicians to treat patients
    who are below the age of ${minAgeWithConsent} only with parental or guardian consent.
    We believe it’s important for all patients to get the care they need when they want it regardless of age.
    Therefore, with parent/guardian consent we are happy to provide our members with the care they need.<br/><br/>
    If you are unable to provide parent/guardian consent, feel free to reach out to us again when you turn ${minAgeWithConsent} -
    we’d love to join you on your journey.`,
      dermatitis: `
    At this time, laws in your state of residence allow clinicians to treat patients
    who are below the age of ${minAgeWithConsent} only with parental or guardian consent.<br/>
    We believe it’s important for all patients to get the care they need when they want it regardless of age.
    Therefore, with parent/guardian consent we are happy to provide our members with the care they need.<br/><br/>
    If you are unable to provide parent/guardian consent, feel free to either continue or reach out to us again when you turn ${minAgeWithConsent} - we’d love to join you on your journey.
    `,
      "nail-infection": `At this time, laws in your state of residence allow clinicians to treat patients
    who are below the age of ${minAgeWithConsent} only with parental or guardian consent.<br/>
    We believe it’s important for all patients to get the care they need when they want it regardless of age.
    Therefore, with parent/guardian consent we are happy to provide our members with the care they need.<br/><br/>
    If you are unable to provide parent/guardian consent, feel free to either continue or reach out to us again when you turn ${minAgeWithConsent} -
    we’d love to join you on your journey.`,
      "postpartum-mastitis": `At this time, laws in your state of residence allow clinicians to treat patients
    who are below the age of ${minAgeWithConsent} only with parental or guardian consent.
    We believe it’s important for all patients to get the care they need when they want it regardless of age.
    Therefore, with parent/guardian consent we are happy to provide our members with the care they need.<br/><br/>
    If you are unable to provide parent/guardian consent, feel free to reach out to us again when you turn ${minAgeWithConsent} -
    we’d love to join you on your journey.`,
      "peds-itchy-rash":
        "At this time, Alpha is only able to treat children who are six months or older for this condition.",
      "diaper-rash":
        "At this time, Alpha is only able to treat children who are six months or older for this condition.",
      menopause: `At this time, laws in your state of residence allow clinicians to treat patients
    who are below the age of ${minAgeWithConsent} only with parental or guardian consent.
    We believe it’s important for all patients to get the care they need when they want it regardless of age.
    Therefore, with parent/guardian consent we are happy to provide our members with the care they need.<br/><br/>
    If you are unable to provide parent/guardian consent, feel free to reach out to us again when you turn ${minAgeWithConsent} -
    we’d love to join you on your journey.`,
      "cold-sore": `At this time, laws in your state of residence allow clinicians to treat patients
    who are below the age of ${minAgeWithConsent} only with parental or guardian consent.
    We believe it’s important for all patients to get the care they need when they want it regardless of age.
    Therefore, with parent/guardian consent we are happy to provide our members with the care they need.<br/><br/>
    If you are unable to provide parent/guardian consent, feel free to reach out to us again when you turn ${minAgeWithConsent} -
    we’d love to join you on your journey.`,
      "weight-loss": `At this time, laws in your state of residence allow clinicians to treat patients
    who are below the age of ${minAgeWithConsent} only with parental or guardian consent.
    We believe it’s important for all patients to get the care they need when they want it regardless of age.
    Therefore, with parent/guardian consent we are happy to provide our members with the care they need.<br/><br/>
    If you are unable to provide parent/guardian consent, feel free to reach out to us again when you turn ${minAgeWithConsent} -
    we’d love to join you on your journey.`,
      cystitis: `At this time, laws in your state of residence allow clinicians to treat patients
    who are below the age of ${minAgeWithConsent} only with parental or guardian consent.
    We believe it’s important for all patients to get the care they need when they want it regardless of age.
    Therefore, with parent/guardian consent we are happy to provide our members with the care they need.<br/><br/>
    If you are unable to provide parent/guardian consent, feel free to reach out to us again when you turn ${minAgeWithConsent} -
    we’d love to join you on your journey.`,
      nutrition: `At this time, laws in your state of residence allow clinicians to treat patients
    who are below the age of ${minAgeWithConsent} only with parental or guardian consent.
    We believe it’s important for all patients to get the care they need when they want it regardless of age.
    Therefore, with parent/guardian consent we are happy to provide our members with the care they need.<br/><br/>
    If you are unable to provide parent/guardian consent, feel free to reach out to us again when you turn ${minAgeWithConsent} -
    we’d love to join you on your journey.`,
      vulvovaginitis: `At this time, laws in your state of residence allow clinicians to treat patients
    who are below the age of ${minAgeWithConsent} only with parental or guardian consent.
    We believe it’s important for all patients to get the care they need when they want it regardless of age.
    Therefore, with parent/guardian consent we are happy to provide our members with the care they need.<br/><br/>
    If you are unable to provide parent/guardian consent, feel free to reach out to us again when you turn ${minAgeWithConsent} -
    we’d love to join you on your journey.`,
      default: `
      At this time, Alpha is only able to treat patients who are ${minAgeWithConsent} or older for this condition. This is to ensure
      we meet the special safeguards put in place for your safety and privacy. Feel
      free to reach out to us again when you turn ${minAgeWithConsent} - we’d love to join you on your journey.
    `,
    },
    isMigraineDiagnosed: {
      positiveForMigraine: `Based on your responses to the Migraine Diagnosis Questionnaire,
       you most likely have migraines.  Please complete the rest of this medical intake form,
        so that we can determine what type of care is right for you.`,
      negativeForMigraine: `Based on your responses to the Migraine Diagnosis Questionnaire,
      your headaches are most likely not migraines.  Unfortunately, we won’t be able to provide
       you with migraine care at this time.`,
    },
    diagnosisQuestionnaire: {
      default: `Based on your answer, it sounds like you haven’t yet been diagnosed
       with migraines by a licensed clinician. The next three questions will help
       your Alpha Provider determine if your headaches may be considered migraines.`,
    },
  };
  return conditionNotices;
};

interface EligibilityFormConditionProps {
  invalid: any;
  condition: string;
  closeModal: any;
  moveForward: any;
  saveValues: any;
  values: any;
}

export const EligibilityFormCondition = ({
  invalid,
  condition,
  closeModal,
  moveForward,
  saveValues,
  values,
}: EligibilityFormConditionProps) => {
  const invalidSex = invalid.sex;
  const invalidDob = invalid.dob;
  // const state = invalid.state;
  const invalidState = invalid.invalidState;
  const minAge = invalid.minAge || 18;
  const minAgeWithConsent = invalid.minAgeWithConsent || minAge;
  const invalidName = invalid.name;

  const renderNoticeMessage = (noticeMessages: any) => {
    if (invalidSex) {
      return noticeMessages.sex[condition] || noticeMessages.sex.default;
    } else if (invalidState) {
      return (
        _.get(noticeMessages.state[condition], invalid.state) ||
        _.get(noticeMessages.state[condition], "default") ||
        noticeMessages.state[condition] ||
        noticeMessages.state.default
      );
    } else if (invalidDob) {
      return noticeMessages.dob[condition] || noticeMessages.dob.default;
    } else if (invalidName === "diagnosisQuestionnaire") {
      return noticeMessages.diagnosisQuestionnaire.default;
    }
    if (invalidName === "isMigraineDiagnosed" && invalid.diagnosedWithMigraine) {
      return noticeMessages.isMigraineDiagnosed.positiveForMigraine;
    } else {
      return noticeMessages.isMigraineDiagnosed.negativeForMigraine;
    }
  };

  const continueAfterPopup = async () => {
    try {
      await saveValues(values);
    } catch (e) {
      // tslint:disable-next-line
      console.warn("there was an error saving the address");
    }
    moveForward();
    closeModal();
  };

  const renderContinueButton = () => {
    const currentAge = moment().diff(_.get(values, "dob"), "years");
    if (condition === "dermatitis" && currentAge < 12) {
      return false;
      // If we have been diagnosed with a migraine, render the button.
      // See src/v2/utils/page/_validators/condition.ts for diagnosis logic.
    } else if (invalid.diagnosedWithMigraine) {
      return true;
    } else if (invalidName === "diagnosisQuestionnaire") {
      return true;
    } else if (invalid.minAgeWithConsent) {
      return (
        ["cystitis", "vulvovaginitis", "skincare", "nutrition"].indexOf(condition) !== -1 &&
        currentAge >= minAgeWithConsent
      );
    } else if (condition === "pcos" && invalid.sex) {
      return false;
    } else {
      // If none of the above conditionals return true
      // then render the continue button based on if we're in one of the below
      // condition outlines
      return (
        [
          "birthcontrol",
          "genital-herpes",
          "dermatitis",
          "nail-infection",
          "postpartum-mastitis",
          "menopause",
          "cold-sore",
          "weight-loss",
          "pcos",
        ].indexOf(condition) !== -1
      );
    }
  };

  return (
    <>
      {["isMigraineDiagnosed", "diagnosisQuestionnaire"].indexOf(invalidName) === -1 && (
        <div className="text-2xl leading-tight">
          {invalidName === "isPediatricsMinor"
            ? "Unfortunately, we are unable to treat your child at this time."
            : invalidSex || invalidState
              ? "Unfortunately, we are unable to treat you at this time."
              : `You must be ${minAgeWithConsent} years or older to proceed,
                   or have parent/guardian consent.`}
        </div>
      )}
      <p
        className="text-base leading-normal my-4"
        dangerouslySetInnerHTML={{
          __html: renderNoticeMessage(renderConditionNotices(minAgeWithConsent)),
        }}
      />
      <div className="flex flex-col items-center">
        {renderContinueButton() && (
          <button className="btn btn-primary w-full mt-4" onClick={continueAfterPopup}>
            Continue
          </button>
        )}
        <Link className="w-full no-underline" to="/my-cases">
          <div className="flex flex-col items-center">
            <button className="btn btn-primary w-full mt-5">End my consultation</button>
          </div>
        </Link>
      </div>
    </>
  );
};
