/** @format */

import type React from "react";

import type { AgeEligiblityError } from "src/components/DynamicForm/Validators/types";

import type { ValidationErrorContentProps } from "./types";

export const MinimumAgeMessage: React.FC<ValidationErrorContentProps<AgeEligiblityError>> = ({
  validationError: { requiredAge },
}) => (
  <>
    <strong>
      At this time, Alpha is only able to offer this service to patients who are {requiredAge} or
      older.
    </strong>{" "}
    This is to ensure we meet the special safeguards put in place for your safety and privacy. Feel
    free to reach out to us again when you turn {requiredAge} - we'd love to join you on your
    journey.
  </>
);
