/** @format */

import React from "react";

export interface PaymentFooterProps {
  refillable?: boolean;
  cxMargin?: string;
}

export const PaymentFooter = ({ refillable, cxMargin }: PaymentFooterProps) => {
  return (
    <div className={`flex ${cxMargin || "mt-5 mb-6"} justify-between text-xs`}>
      <div className="flex justify-start items-center">
        <span className="far fa-heart" />
        <div className="ml-2">Free shipping</div>
      </div>
      {refillable && (
        <div className="flex justify-center items-center">
          <span className="fas fa-sync ml-2" />
          <div className="ml-2">Auto-refills</div>
        </div>
      )}
      <div className="flex justify-end items-center">
        <span className="fas fa-ban ml-2" />
        <div className="ml-2">Cancel any time</div>
      </div>
    </div>
  );
};
