/** @format */

import React from "react";

import _ from "lodash";

const hardStopMessage = (props: any) => {
  const asthma = props.condition === "asthma";
  const herpes = props.condition === "genital-herpes";
  const coldSores = props.condition === "cold-sore";
  const acidReflux = props.condition === "acid-reflux";
  const hyperlipidemia = props.condition === "hyperlipidemia";
  const pediatricCondition = ["peds-itchy-rash", "diaper-rash"].indexOf(props.condition) !== -1;
  const weightLoss = props.condition === "weight-loss";
  const tempDisableDateRange = props.invalid.date_range;

  if (tempDisableDateRange) {
    return `This condition is temporarily disabled between these dates: ${tempDisableDateRange}`;
  }
  if (asthma && props.invalid.hardStopType === "who-diagnosed") {
    return `You selected that you have not been diagnosed with asthma by a licensed clinician.
      To make a diagnosis of asthma,
      clinicians need your medical history and results of a test
      called spirometry to measure how effectively you inhale and exhale.
      `;
  } else if (asthma && props.invalid.hardStopType === "following-danger-signs") {
    return `You selected a high risk danger sign. Take 4-6 puffs of your quick-relief medicine AND
seek in-person medical attention NOW!`;
  } else if (asthma && props.invalid.hardStopType === "short-of-breath") {
    return `You selected a high risk danger sign. You may experience symptoms
    such as trouble walking and/or talking due to shortness
     of breath or your lips or fingernails may turn blue, seek in-person medical attention NOW!`;
  } else if ((herpes || coldSores) && props.invalid.hardStopType === "symptoms-locations") {
    return `We are unable to treat you online because your vision could be affected
    and you need an eye exam immediately.`;
  } else if (acidReflux && props.invalid.hardStopType === "cannot-treat-gerd") {
    return `We are unable to adequately treat you online.
    <br/> You need an in-office evaluation.`;
  } else if (acidReflux && props.invalid.hardStopType === "need-visit-gastroenterologist") {
    return `We may not be able to adequately treat you online.<br/>
    You may need an in-office evaluation by a gastroenterologist`;
  } else if (hyperlipidemia) {
    return `Cholesterol-lowering medications are not recommended for pregnant women.
    Studies have shown they may cause birth defects and that the risks clearly outweigh any benefit.`;
  } else if (pediatricCondition) {
    return `Unfortunately, we are not able to provide care to your child at this time,
    please seek medical help immediately.<br/>
        If the answer is incorrect, please enter the correct answer.`;
  } else if (props.condition === "hairloss") {
    return "Medications used to treat hair loss are contraindicated in pregnancy because they can cause harm to the developing fetus.";
  } else if (weightLoss && props.invalid.hardStopType === "bulimic-anorexic") {
    return `Depending upon the severity of ${props.invalid.answer}, you should be having check-ins which include regularly scheduled labs due to medical instability that can occur due to electrolyte and cardiac abnormalities. This level of complexity is currently beyond the scope of our telemedicine platform.`;
  } else if (weightLoss) {
    return `We cannot prescribe controlled substances/scheduled drugs. Telemedicine regulations vary by state and most states do not allow Alpha providers to prescribe controlled substances online.`;
  } else {
    return `Unfortunately, we are not able to provide care to you at this time,
    please seek medical help immediately.<br/>
        If the answer is incorrect, please enter the correct answer.`;
  }
};

const processButtonText = (props: any) => {
  if (props.condition === "weight-loss") {
    return "I would like to change my answer";
  } else {
    return "This is incorrect, I would like to correct my answer";
  }
};

const processText = (props: any) => {
  if (props.invalid.date_range) {
    return "Ok, I'll return when this condition is available again.";
  }
  if (props.condition === "weight-loss" && props.invalid.hardStopType !== "bulimic-anorexic") {
    return "I'm only interested in this type of medication, terminate my visit.";
  } else {
    return "This is correct, terminate my visit";
  }
};

interface EligibilityFormHardStopProps {
  closeModal: any;
  condition: string;
  invalid: any;
}

export const EligibilityFormHardStop = (props: EligibilityFormHardStopProps) => {
  return (
    <div className="m-5">
      <div
        className="text-lg my-3 text-center"
        dangerouslySetInnerHTML={{ __html: hardStopMessage(props) }}
      />
      {!_.get(props.invalid, "date_range") && (
        <button className="btn btn-primary w-full my-2" onClick={props.closeModal}>
          {processButtonText(props)}
        </button>
      )}
      <a
        href="/my-cases"
        className={`
            block
            text-sm
            text-primary-60 hover:text-primary
            cursor-pointer hover:underline text-center
          `}
      >
        {processText(props)}
      </a>
    </div>
  );
};
