/** @format */

import React from "react";

import { Typography } from "@material-ui/core";
import classNames from "classnames";

import { Popup } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import { Loader } from "src/components/Loader";
import { PageWrapper } from "src/components/PageWrapper";
import { CHECKINS_LIST_PAGE } from "src/routes";
import { history } from "src/utils/history";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { RecommendedVisits } from "src/v2/components/RecommendedVisits";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useStoreDispatch, useStoreState } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";

import { HealthActionPlanCareGuideSection } from "./HealthActionPlanCareGuideSection";
import { HealthActionPlanHeaderSection } from "./HealthActionPlanHeaderSection";

const onBackCallback = () => {
  if (history == null) {
    return;
  }
  const location = history.location;
  const prevPath = location?.state;
  const prevPathPatterns = ["/complete", "check-ins/redirect"];

  // If the user is coming from the completion page or the redirect handler, redirect them to the home page since those pages are no longer available.
  if (
    prevPath &&
    prevPath["from"] &&
    prevPathPatterns.some((pattern) => prevPath["from"].includes(pattern))
  ) {
    history.push("/");
  } else history.goBack();
};

export const HealthActionPlanPage = () => {
  const [loadingHAP, setLoadingHAP] = React.useState(true);
  const healthActionPlan = useStoreState((state) => state.healthActionPlan.healthActionPlan);
  const recommendedVisitSection = healthActionPlan?.content?.find(
    (section) => section.type == "RECOMMENDED_VISIT",
  );
  const careGuideSection = healthActionPlan?.content?.find(
    (section) => section.type == "CARE_GUIDE",
  );
  const [emptyStateModalOpen, setEmptyStateModalOpen] = React.useState(false);
  const dispatch = useStoreDispatch();
  const user = useCurrentUser();
  const title = "Health Action Plan";
  const modalOpenDelay = 1500;

  React.useEffect(() => {
    dispatch.healthActionPlan.getLastHealthActionPlan({ userId: user.id }).then(() => {
      setLoadingHAP(false);
    });
  }, []);

  React.useEffect(() => {
    // check that we've got a health action plan and both sections are empty
    if (
      !loadingHAP &&
      healthActionPlan?.date_created &&
      !recommendedVisitSection &&
      !careGuideSection
    ) {
      setTimeout(() => setEmptyStateModalOpen(true), modalOpenDelay);
    }
  }, [loadingHAP, healthActionPlan, recommendedVisitSection, careGuideSection]);

  useTopBar({
    title: title,
    variant: "nested",
    showBackButton: true,
    onBackCallback: onBackCallback,
  });

  return (
    <Loader show={loadingHAP}>
      <PageWrapper cxPadding="pl-0">
        <div className="max-w-2xl mx-auto">
          {healthActionPlan && (
            <>
              <HealthActionPlanHeaderSection
                planTitle="Health Action Plan"
                planDate={healthActionPlan?.date_created}
                showWarning={healthActionPlan?.showBloodPressureWarning}
              />
              <RecommendedVisits
                key={recommendedVisitSection?.group_section_title?.title.replace(" ", "")}
                content={recommendedVisitSection}
                showProgressBar={false}
              />
              <HealthActionPlanCareGuideSection
                key={careGuideSection?.group_section_title?.title.replace(" ", "")}
                sectionTitle={careGuideSection?.group_section_title?.title || "Care Guide"}
                content={careGuideSection}
              />
            </>
          )}
        </div>
      </PageWrapper>
      {emptyStateExplanationModal(emptyStateModalOpen, () => setEmptyStateModalOpen(false))}
    </Loader>
  );
};

const emptyStateExplanationModal = (
  emptyStateModalOpen: boolean,
  setEmptyStateModalOpen: () => void,
) => {
  const _body_text =
    "Thanks for completing your check-in! Based on your answers, you don’t have any recommendations in your Health Action Plan.";

  return (
    <Popup
      isOpen={emptyStateModalOpen}
      onRequestClose={setEmptyStateModalOpen}
      headerText="No recommendations to show"
      accentColor="white"
    >
      <div id="body-text-section" className="px-5 pt-1 pb-4 w-327">
        <Typography className="text-left text-forest-120 font-bold">{_body_text}</Typography>
      </div>
      {/* make a flex div that can house two buttons that are centered, full width and have 1rem of space between them */}
      <div id="button-section" className="flex flex-col space-y-4 p-5">
        <ButtonPdb
          variant="primary"
          className="w-full"
          onClick={() => {
            history && history.push(CHECKINS_LIST_PAGE);
          }}
        >
          Browse other check-ins
        </ButtonPdb>
        <ButtonPdb
          variant="secondary"
          className="w-full"
          onClick={() => {
            history && history.push("/");
          }}
        >
          Return home
        </ButtonPdb>
      </div>
    </Popup>
  );
};

export const HealthActionPlanEmptyStateSection = (
  sectionType: "RECOMMENDED_VISIT" | "CARE_GUIDE",
) => {
  const sectionTypeMapper = {
    RECOMMENDED_VISIT: {
      text: "recommendations",
      bg: "",
      marginTop: "mt-4",
    },
    CARE_GUIDE: {
      text: "care guides",
      bg: "bg-grey-10",
      marginTop: "",
    },
  };

  const style = {
    width: "327px",
    height: "84px",
    flexShrink: 0,
    borderRadius: "2px",
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <div
        className={classNames(
          "flex flex-col items-center justify-center",
          sectionTypeMapper[sectionType].bg,
          sectionTypeMapper[sectionType].marginTop,
        )}
        style={style}
      >
        <p className="text-center text-grey-160 text-xs">{`No ${sectionTypeMapper[sectionType].text} to show`}</p>
      </div>
    </div>
  );
};

export default WithTopBarNavigation(HealthActionPlanPage);
