/** @format */

import React from "react";
import { Field, useFormState } from "react-final-form";
import { Link } from "react-router-dom";

import _ from "lodash";

import { useMultiPageForm } from "@alphamedical/components";

import { _POST } from "src/helpers/http";
import { Button } from "src/v2/components/Button";
import { useCurrentUser } from "src/v2/models/profile";

export const GroupSessionWaitlist = () => {
  const currentUser = useCurrentUser();
  const [submitted, setSubmitted] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [hasAvailableSessions, setHasAvailableSessions] = React.useState(false);
  const { prevPage } = useMultiPageForm();
  const { values } = useFormState();

  const signup = () => {
    setSubmitting(true);
    _POST(`/users/${currentUser.id}/${values["topic_slug"]}/group_session_waitlist`)
      .then(() => setSubmitted(true))
      .finally(() => setSubmitting(false));
  };

  return (
    <div className="border sm:max-w-md mx-auto">
      <div className="py-8 px-5 space-y-4">
        <Field name="meeting_id" subscription={{ data: true }}>
          {({ meta }) => {
            const optionCount = _.get(meta, "data.optionCount", undefined) as number | undefined;
            if (optionCount != null) {
              setHasAvailableSessions(optionCount > 0);
            }
            return null;
          }}
        </Field>
        {!submitted && (
          <>
            <div className="text-lg font-bold text-center">
              {hasAvailableSessions
                ? "Not seeing a time that works for you?"
                : "There are no available sessions for this topic"}
            </div>
            <p className="text-center">
              Sign up to get notified by email when new sessions become available for this topic.
            </p>
            <Button disabled={submitting} onClick={signup}>
              Notify Me
            </Button>
          </>
        )}
        {submitted && (
          <>
            <div className="text-lg font-bold text-center">We'll notify you soon</div>

            <p>
              We'll send you an email when new spots become available for this topic. in the
              meantime you can:
            </p>
            <button onClick={prevPage} className="block text-sky-dark font-bold cursor-pointer">
              Choose a different topic
            </button>
            <Link to="/" className="block text-sky-dark font-bold">
              Explore Alpha Services
            </Link>
          </>
        )}
      </div>
    </div>
  );
};
