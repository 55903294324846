/** @format */

import React from "react";

interface DoseListProps {
  dose: {
    med_name: string;
    monthly_cost: string;
    ships_every: string;
    dosage?: string;
    quantity?: string;
    id: string;
  };
  key: string;
  paymentPreference?: string;
}

export const DoseList = ({ dose, paymentPreference }: DoseListProps) => {
  return (
    <div key={dose.id} className="border-b border-grey flex flex-col mt-4">
      <div className="font-bold mb-4">{dose.med_name}</div>
      <div className="flex mb-4">
        <div>Price</div>
        <div className="flex-1 text-right ml-4">
          {paymentPreference === "insurance" ? "Your copay" : dose.monthly_cost}
        </div>
      </div>
      <div className="flex mb-4">
        <div>{dose.ships_every ? "Ships every" : "One time shipment"}</div>
        {dose.ships_every && <div className="flex-1 text-right ml-4">{dose.ships_every}</div>}
      </div>
      {dose.dosage && (
        <div className="flex justify-between mb-4">
          <div>Dosage</div>
          <div className="text-right">{dose.dosage}</div>
        </div>
      )}
      {dose.quantity && (
        <div className="flex justify-between mb-4">
          <div>Quantity</div>
          <div>{dose.quantity}</div>
        </div>
      )}
    </div>
  );
};
