/** @format */

import React from "react";

interface ProgressBarProps {
  percentComplete: number;
}

export const ProgressBar = ({ percentComplete }: ProgressBarProps) => {
  return (
    <div className={"bg-sunset-light rounded-xl mt-4 h-3"}>
      <div className={"bg-sunset rounded-xl h-3"} style={{ width: `${percentComplete}%` }}></div>
    </div>
  );
};
