/** @format */

import React from "react";
import { useHistory } from "react-router-dom";

import { Typography } from "@alphamedical/components";
import { BadgePdb, ButtonPdb } from "src/v2/designSystem";

import { Analytics } from "src/analytics";

interface HomeCardProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  img: string;
  title: string;
  description: string;
  ctaText: string;
  showNewPill?: boolean;
  showPreferredTag?: boolean;
  ctaLink: string;
}

const HomeCard = ({
  img,
  title,
  description: body,
  ctaText,
  ctaLink,
  showNewPill = false,
  showPreferredTag = false,
  ...props
}: HomeCardProps): JSX.Element => {
  const history = useHistory();
  const preferredPartnerTag = "Preferred Partner";

  React.useEffect(() => {
    Analytics.trackContentImpression({
      name: "Card",
      piece: title || "null",
      target: ctaLink,
    });
  }, [title]);

  const isValidUrl = (url: string) => {
    const urlRegex =
      /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b(?:[-a-zA-Z0-9@:%_+.~#?&//=]*)$/;
    return urlRegex.test(url);
  };

  const onClickHandler = () => {
    Analytics.trackContentInteraction("click", {
      name: "Card",
      piece: title || "null",
      target: ctaLink,
    });

    if (isValidUrl(ctaLink)) {
      window.open(ctaLink, "_blank");
    } else {
      history.push(ctaLink);
    }
  };

  return (
    <div className="flex flex-col justify-between bg-white m-1 rounded-lg shadow gap-4">
      <div className="relative">
        {showNewPill && <BadgePdb addClasses="absolute ml-4 mt-4" variant="light" label={"New"} />}
        <img src={img} alt="Card Image" className="min-w-16 rounded-t-lg w-full" />
        {showPreferredTag && (
          <span className="absolute bottom-0 left-0 py-1 px-4 text-white bg-forest-120 opacity-80 font-normal text-xs leading-4">
            {preferredPartnerTag}
          </span>
        )}
      </div>
      <div className="px-4 pb-4">
        <Typography variant="h4">{title}</Typography>
        <Typography variant="body2" className="mt-1 h-8">
          {body}
        </Typography>
        <ButtonPdb
          variant="secondary"
          className="mt-4 w-full"
          name="card-cta"
          onClick={onClickHandler}
          {...props}
        >
          {ctaText}
        </ButtonPdb>
      </div>
    </div>
  );
};

export default HomeCard;
