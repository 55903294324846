/** @format */

import React from "react";
import { Field } from "react-final-form";

import type { ConsentPage } from "src/components/DynamicForm/types";

import { consentMap } from "src/v2/components/ConsentForms";

import type { PageFormProps } from "./types";

import { PageFormWrapper } from "./pageWrapper";

export const ConsentPageForm = (props: PageFormProps) => {
  const ConsentBody = consentMap[(props.page as ConsentPage).consentFormKey] || (() => null);
  const field = props.page.fields[0];

  return (
    <PageFormWrapper
      page={props.page}
      form={{ ...props.form, initialValues: { ...props.form.initialValues, [field.key]: "yes" } }}
      scrollContent={true}
    >
      <ConsentBody />
      <Field name={field.key} component="input" type="hidden" value="yes" />
    </PageFormWrapper>
  );
};
