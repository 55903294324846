/** @format */

import React from "react";
import { Field } from "react-final-form";

import { FieldWrapper } from "src/components/DynamicForm/Fields/DynamicFieldWrapper";

export const FieldSelect = ({
  field,
  parse,
  extraClassName,
  isDisabled = false,
  piiStateChange,
  widthClass = null,
  type,
  existingValue,
}: any) => {
  const options = existingValue
    ? [...field.options, { label: existingValue, value: existingValue }]
    : field.options;
  const formatInvalidValue = (value: string) => {
    if (!value) {
      return "";
    }
    const validValues = options.map((option: any) => option.value);
    return validValues.indexOf(value) === -1 ? "" : value;
  };
  return (
    <FieldWrapper field={field}>
      <Field name={field.key} multiple={field.multiple} format={formatInvalidValue} parse={parse}>
        {({ input, meta }) => {
          // if props.stateChanged exists
          if (piiStateChange && typeof piiStateChange == "function") {
            piiStateChange(input.value, meta.pristine);
          }
          return (
            <div className="relative">
              <select
                disabled={isDisabled}
                {...input}
                className={`
                block appearance-none py-3 px-4 pr-8
                ${type === "grey" ? "bg-grey-1 text-primary" : "bg-sky-40 border border-sky text-grey-darker rounded"}
                leading-tight text-sm
                focus:outline-none focus:border-grey ${widthClass || "w-full"} ${extraClassName}
              `}
              >
                <option value="" disabled={true}>
                  {field.label}
                </option>
                {options.map((option: any, fidx: any) => (
                  <option value={option.value} key={`${field.key}-option-${fidx}`}>
                    {option.label}
                  </option>
                ))}
              </select>
              <div
                className={`
                pointer-events-none absolute
                inset-y-0 right-0 flex items-center px-2 text-grey-darker
              `}
              >
                <div className="fas fa-chevron-down text-forest-40 mx-2" />
              </div>
            </div>
          );
        }}
      </Field>
    </FieldWrapper>
  );
};
