/** @format */

import React from "react";

import * as Sentry from "@sentry/react";
import type { AxiosError } from "axios";
import _ from "lodash";

import type {
  ErrorResponse,
  FormPopupError,
  FormReponsesCollectionSchema,
} from "src/v2/models/api_types";
import type { Outline } from "src/v2/models/outline";

import { Analytics } from "src/analytics";
import { _GET, _PUT } from "src/helpers/http";
import { useStoreDispatch } from "src/v2/models";
import { isConsultResponsesValidationError } from "src/v2/models/api_types";
import { useCurrentUser } from "src/v2/models/profile";
import { useQuery } from "src/v2/utils/useQuery";

import type { DynamicFormProps } from "./DynamicForm";

import { DynamicForm } from "./DynamicForm";
import { axiosErrorHandler, getResponsesPayload } from "./utils";

interface DynamicFormControllerProps
  extends Omit<DynamicFormProps, "onPageSubmit" | "validationError" | "clearValidationError"> {
  responseCollectionId: number;
  onFormComplete?: () => void;
}

type RefObjectsProps = () => void;

const urlParamsToObject = (params: URLSearchParams) => {
  return Object.fromEntries(params.entries());
};

export const DynamicFormController = ({
  responseCollectionId,
  onFormComplete,
  ...props
}: DynamicFormControllerProps) => {
  const refObject = React.useRef<RefObjectsProps>();
  const currentUser = useCurrentUser();
  const dispatch = useStoreDispatch();
  const [responseValidationError, setResponseValidationError] = React.useState<
    FormPopupError | undefined
  >();
  const [responses, setReponseData] = React.useState<FormReponsesCollectionSchema | undefined>();
  const [isCallNextStep, setIsCallNextStep] = React.useState<boolean>(false);

  React.useEffect(() => {
    _GET<FormReponsesCollectionSchema>(
      `/users/${currentUser.id}/outline_responses/${responseCollectionId}`,
    ).then((res) => setReponseData(res));
  }, []);

  const urlParams = useQuery();
  const [defaultUrlValues] = React.useState(urlParamsToObject(urlParams));
  const [skippedValidators, setSkippedValidators] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (onFormComplete && responses?.completed) {
      onFormComplete();
    }
  }, [responses?.completed]);

  // The backend might return an outline key that starts with 'outline-'. For compatibility, we remove this before
  // passing it along to <DynamicForm />
  let outlineKey = responses?.outline?.key;
  if (!!outlineKey && outlineKey.startsWith("outline-")) {
    outlineKey = outlineKey.replace("outline-", "");
  }

  const getFieldsAsQueryString = (outline: Outline, currentPageKey: string) => {
    let fields = "?";
    outline.pages
      .find((page) => page.key === currentPageKey)
      ?.fields?.map((field) => {
        return (fields += `field=${field.key}&`);
      });
    return fields.slice(0, -1);
  };

  const getSubmitUrl = (id: number, outline: Outline, currentPageKey = "") => {
    return `/users/${id}/outline_responses/${responseCollectionId}/responses${getFieldsAsQueryString(
      outline,
      currentPageKey,
    )}`;
  };

  React.useEffect(() => {
    // This useEffect is to ensure we call next stops after response value state is updated
    if (refObject.current && isCallNextStep) {
      refObject.current();
      setIsCallNextStep(false);
    }
  }, [responses?.values, isCallNextStep]);

  return (
    <>
      {!!responses && !responses.completed && (
        <DynamicForm
          allowFormAutoSubmit={true}
          // The key of the oultine file to use for this signup
          outlineKey={outlineKey}
          initialValues={responses.values || {}}
          defaultValues={{ ...currentUser.pii, ...currentUser.pii.address, ...defaultUrlValues }}
          // Submit the changed fields to the api
          onPageSubmit={async ({
            outline,
            changedValues,
            hasNextPage,
            nextPage,
            values,
            currentPageKey,
          }) => {
            Analytics.trackEvent({
              category: "Form",
              action: "PageSubmitted",
              name: currentPageKey,
            });
            let isChangedValue = false;
            if (!_.isEmpty(changedValues)) {
              isChangedValue = true;
              try {
                if (values.validatorsToSkip)
                  setSkippedValidators([...skippedValidators, ...(values.validatorsToSkip || [])]);

                const res = await _PUT<FormReponsesCollectionSchema>(
                  // TODO refactor the way we create the URL for submit answers
                  getSubmitUrl(currentUser.id, outline, currentPageKey),
                  // we need to return all response so form validation can validate against all form values
                  getResponsesPayload(outline, changedValues),
                  {
                    "Skip-Validators": values.validatorsToSkip || [...(skippedValidators || [])],
                  },
                );
                setReponseData(res);
              } catch (err) {
                const error = err as AxiosError<ErrorResponse<any>>;
                if (
                  error &&
                  error.response &&
                  isConsultResponsesValidationError(error.response.data)
                )
                  return setResponseValidationError(error.response.data);
                else return axiosErrorHandler("Unable to submit responses")(error);
              }
            }

            // Group submitted values by category and call other endpoints if needed to keep easy-peasy store up to date
            const { pii, insurance } = _.groupBy<string>(Object.keys(changedValues), (fieldKey) => {
              return outline.fields[fieldKey]?.category;
            });

            // If either pii or insurance data is submitted, refetch that data
            if (pii != null || insurance != null) {
              dispatch.profile
                .fetchProfile({ include: insurance ? ["insurance"] : [] })
                .catch(Sentry.captureException);
            }

            if (hasNextPage) {
              if (isChangedValue) {
                // This allows us to call nextPage after state for response data is updated
                // This allows any logic for conditional pages to finish before requesting to call to the next page
                refObject.current = nextPage;
                setIsCallNextStep(true);
              } else {
                nextPage();
              }
            } else {
              const res = await _PUT<FormReponsesCollectionSchema>(
                `/users/${currentUser.id}/outline_responses/${responseCollectionId}`,
                { completed: true },
              );
              setReponseData(res);
              if (outlineKey == "registration") {
                await dispatch.profile.fetchRegistrationOutlineData({ userId: currentUser.id });
              }
            }
          }}
          {...props}
          validationError={responseValidationError}
          clearValidationError={() => setResponseValidationError(undefined)}
        />
      )}
    </>
  );
};
