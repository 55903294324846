/** @format */

import React from "react";
import { Link, withRouter } from "react-router-dom";

import { LogoutButton } from "src/components/_buttons/Logout";
import { MEMBERSHIP_DETAILS } from "src/routes";
import { serializeDependency } from "src/utils";
import { useAuthenticatedUser } from "src/utils/hooks";
import { useStoreState } from "src/v2/models";

import type { NavItem } from "./navLinkValues";

import NavGroup from "./NavGroup";
import NavLink from "./NavLink";
import { navItems } from "./navLinkValues";

import "./navStyles.scss";

const NavModalContent = (props: any) => {
  const membership = useStoreState((state) => state.membership.membership);
  const [, isAuthenticatedUser] = useAuthenticatedUser();
  const slideMenu = React.useRef<HTMLDivElement>(null);
  const slideMenuMain = React.useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = React.useState(false);

  function closeMenu() {
    const sm = slideMenu.current;
    if (sm) {
      const slideMenuSecondary = sm.querySelectorAll(".active");
      Array.from(slideMenuSecondary).forEach(function (element) {
        element.classList.remove("active");
      });
      sm.classList.remove("active");
    }
  }

  const handleClick = (e: MouseEvent) => {
    const sm = slideMenu.current;
    const smm = slideMenuMain.current;
    const target = e.target as HTMLElement;

    if (target.closest(".toggle-button")) {
      const button = target.closest(".toggle-button");
      const buttonTarget = button && button.getAttribute("data-target");
      const slideMenuSecondary = document.querySelector(`[data-menu='${buttonTarget}']`);
      if (slideMenuSecondary) {
        slideMenuSecondary.classList.toggle("active");
      }
      if (smm) {
        smm.classList.toggle("active");
      }
      return;
    }
    if (target.closest(".slide-menu-close")) {
      setMenuOpen(false);
      closeMenu();
      return;
    }
    if (sm && target.closest(".slide-menu-open")) {
      sm.classList.add("active");
      setMenuOpen(true);
      return;
    }
    if (!target.closest('[class~="slide-menu"]')) {
      setMenuOpen(false);
      closeMenu();
      return;
    }
  };

  React.useEffect(() => {
    closeMenu();
    setMenuOpen(false);
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, [serializeDependency(props.history.location)]);

  const subMenu = navItems.filter((item) =>
    "subMenu indentedSubMenu".includes(`${item.navItemStyle}`),
  );

  return (
    <nav
      ref={slideMenu}
      className="slide-menu fixed overflow-y-scroll overflow-x-hidden left-0 top-0 w-screen sm:max-w-sm lg:w-1/3 h-screen shadow bg-white"
      // style={{maxWidth: '32rem'}}
    >
      <div className="absolute flex justify-center items-center w-100 z-20 pt-6 w-full">
        <button
          className="slide-menu-close absolute left-0 mx-8 p-1 z-20 text-base focus:border-2 focus:border-primary"
          aria-expanded={menuOpen}
          aria-label="Toggle main navigation"
          tabIndex={menuOpen ? undefined : -1}
        >
          <span className="absolute inset-0 z-10"></span>
          <img src={"/assets/close.svg"} alt="Close navigation menu" />
        </button>
        <Link to="/" tabIndex={-1}>
          <img
            className="mx-auto"
            style={{ width: "5rem" }}
            src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/874875/alpha-forest.svg"
            alt="Go to homepage"
          />
        </Link>
      </div>
      <div className="relative z-0 w-full h-full">
        <div
          className="slide-menu-main bg-white px-8 pt-12 pb-12 flex flex-col justify-between h-full"
          ref={slideMenuMain}
        >
          <ul style={{ listStyleType: "none", padding: "0" }}>
            {navItems.map((item: NavItem) => {
              const [small] = item.navItemSmall;
              const [border] = item.navItemBorder;
              const smallClass = small ? " text-sm lh-solid " : "";
              const mb = small ? "mb-2" : `mb-6`;
              const borderClass = border ? ` relative bordered ${mb} pt-6 pb-0` : "";
              const parentClassName = `${smallClass} ${borderClass}`;
              return (
                <li key={item.uid} className={parentClassName}>
                  {(() => {
                    switch (item.navItemStyle) {
                      case "indented":
                        if (item.anchor.length > 1) {
                          return (
                            <ul style={{ listStyleType: "none", padding: "0" }}>
                              <NavGroup
                                anchor={item.anchor.slice(0, 1)}
                                textColor={"primary"}
                                listStyle={mb}
                              />
                              <NavGroup
                                anchor={item.anchor.slice(1)}
                                textColor={"primary"}
                                listStyle={`pl-8 ${mb}`}
                              />
                            </ul>
                          );
                        } else {
                          return (
                            <ul style={{ listStyleType: "none", padding: "0" }}>
                              <NavGroup
                                anchor={item.anchor.slice(0, 1)}
                                textColor={"primary"}
                                listStyle={`pl-8 ${mb}`}
                              />
                            </ul>
                          );
                        }

                      case "subMenu":
                        return (
                          <ul style={{ listStyleType: "none", padding: "0" }}>
                            <li className={`${mb}`} key={item.uid}>
                              <button
                                data-target={item.uid}
                                className="relative flex w-full text-left block toggle-button text-lg md:text-xl leading-none text-primary"
                              >
                                {`${item.heading} `}
                                <span className="relative ml-auto mt-1">
                                  <img src={"/assets/arrow.svg"} alt="Open a sub menu" />
                                </span>
                              </button>
                            </li>
                          </ul>
                        );
                      case "indentedSubMenu":
                        return (
                          <ul style={{ listStyleType: "none", padding: "0" }}>
                            <li className={`pl-8 ${mb}`} key={item.uid}>
                              <button
                                data-target={item.uid}
                                className="relative flex w-full text-left  block toggle-button"
                              >
                                {`${item.heading} `}
                                <span className="relative ml-auto mt-1">
                                  <img src={"/assets/arrow.svg"} alt="Open an indented sub menu" />
                                </span>
                              </button>
                            </li>
                          </ul>
                        );
                      case "flat":
                      default:
                        return (
                          <ul style={{ listStyleType: "none", padding: "0" }}>
                            <NavGroup
                              tabIndex={menuOpen ? undefined : -1}
                              anchor={item.anchor}
                              textColor={"primary"}
                              listStyle={mb}
                            />
                          </ul>
                        );
                    }
                  })()}
                </li>
              );
            })}
          </ul>
          <ul className="mb-24" style={{ listStyleType: "none", padding: "0" }}>
            {isAuthenticatedUser && (
              <>
                <li className="mb-6">
                  <NavLink
                    tabIndex={menuOpen ? undefined : -1}
                    anchorText={membership?.active ? "Manage Plan" : "Join Plan"}
                    href={MEMBERSHIP_DETAILS}
                    className="text-primary font-700 no-underline text-lg md:text-xl leading-none font-bold"
                  />
                  <span className="ml-2 fa fa-arrow-right" />
                </li>
                <li className="mb-6">
                  <NavLink
                    tabIndex={menuOpen ? undefined : -1}
                    anchorText="Account"
                    href="/account"
                    className="text-primary no-underline text-lg md:text-xl leading-none"
                  />
                </li>
              </>
            )}
            <li className="mb-6">
              <NavLink
                tabIndex={menuOpen ? undefined : -1}
                anchorText="Help & Support"
                href="https://help.helloalpha.com/"
                className="text-primary no-underline text-lg md:text-xl leading-none"
              />
            </li>
            <li>
              <LogoutButton
                tabIndex={menuOpen ? undefined : -1}
                className="text-primary text-lg md:text-xl leading-none"
              />
            </li>
          </ul>
        </div>
        {subMenu.map((item: NavItem) => {
          return (
            <React.Fragment key={item.uid}>
              <div
                key={item.uid}
                data-menu={item.uid}
                className="slide-menu-secondary absolute top-0 w-full h-full p-6 pt-24 bg-white"
              >
                <ul style={{ listStyleType: "none", padding: "0" }}>
                  <li className="mb-6">
                    <button
                      data-target={item.uid}
                      className="text-lg md:text-xl leading-none text-primary text-left block toggle-button"
                    >
                      <span className="align-baseline inline-block">
                        <img
                          src={"/assets/arrow.svg"}
                          className="mr-4"
                          style={{ transform: "rotate(180deg)" }}
                          alt="Close a sub menu"
                        />
                      </span>
                      {item.heading}
                    </button>
                  </li>
                  <NavGroup anchor={item.anchor} textColor={"primary"} listStyle={"pl-12 mb-6"} />
                </ul>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </nav>
  );
};

export default withRouter(NavModalContent);
