/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PatientActionItemSchema } from '../models/PatientActionItemSchema';
import type { PatientActionItemsCompletePatientActionItemsRequestBodySchema0 } from '../models/PatientActionItemsCompletePatientActionItemsRequestBodySchema0';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PatientActionItemsService {

    /**
     * @returns PatientActionItemSchema
     * @throws ApiError
     */
    public static getPatientActionItems({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<Array<PatientActionItemSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/patient_action_items',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static completePatientActionItems({
        userId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        requestBody: PatientActionItemsCompletePatientActionItemsRequestBodySchema0,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/{user_id}/patient_action_items/complete',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
