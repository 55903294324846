/** @format */

import React from "react";

import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useWindowSize } from "@alphamedical/components";

import { MarkdownText } from "src/v2/components/MarkdownText";
import { CloseIcon } from "src/v2/components/Svgs";
import { useStoreActions, useStoreState } from "src/v2/models";
import { COLORS } from "src/v2/utils/colors";

/**
 * Displays an alert at the bottom of the app.
 * There are three different types, info, warning, error.
 * Each has its own icon and bg color.
 * Create snack example
 *
 * dispatch.snacks.addSnack({
 *       id: "couponAlert",
 *       type: "info",
 *       message: "A $10 coupon has been added to your account. Learn more.",
 *       delay: 10
 *     });
 *
 * @constructor
 */
export const SnackBar = () => {
  const [width] = useWindowSize();

  // set the bottom position, 8% when the footer nav is preset, 1% when not
  const bottom = width < 640 ? "8%" : "1%";

  const { snackList } = useStoreState((state) => state.snacks);
  const { removeSnack } = useStoreActions((actions) => actions.snacks);

  const styles: SnackStyles = {
    light: {
      bg: "bg-grey-10",
      text: "text-forest-100",
      icon: ["far", "circle-check"],
      iconColor: COLORS["forest-100"],
      closeColor: COLORS["forest-100"],
    },
    brand: {
      bg: "bg-sand-60",
      text: "text-forest-100",
      icon: ["far", "circle-check"],
      iconColor: COLORS["forest-100"],
      closeColor: COLORS["forest-100"],
    },
    success: {
      bg: "bg-success",
      text: "text-forest-darker",
      icon: ["far", "circle-check"],
      iconColor: COLORS["forest-100"],
      closeColor: COLORS["forest-100"],
    },
    warning: {
      bg: "bg-warning-pdb",
      text: "text-forest-darker",
      icon: ["far", "triangle-exclamation"],
      iconColor: COLORS["forest-100"],
      closeColor: COLORS["forest-100"],
    },
    error: {
      bg: "bg-error",
      text: "text-white",
      icon: ["far", "circle-exclamation"],
      iconColor: COLORS["white"],
      closeColor: "white",
    },
    primary: {
      bg: "bg-primary",
      text: "text-white",
      icon: ["far", "circle-check"],
      iconColor: COLORS["white"],
      closeColor: "white",
    },
  };

  return (
    <div
      style={{ bottom, left: "50%", transform: "translate(-50%, 0)" }}
      className={`p-4 fixed w-full max-w-sm z-[100] ${snackList.length === 0 ? "hidden" : ""}`}
    >
      {snackList.map((snack, idx) => {
        return (
          <div
            role="alert"
            key={snack.id}
            className={`${styles[snack.type].bg} font-medium ${
              styles[snack.type].text
            } flex flex-row p-4 rounded justify-between shadow-lg items-center ${
              snackList.length > 1 && idx !== snackList.length - 1 ? "mb-3" : ""
            }`}
          >
            <div className={"mr-4 flex justify-center"}>
              <FontAwesomeIcon
                fontSize={24}
                color={styles[snack.type].iconColor}
                icon={styles[snack.type].icon}
              />
            </div>
            <MarkdownText
              containerClassName={"mr-4"}
              className={`${styles[snack.type].text} text-sm`}
              text={snack.message}
            />
            <div className={"mt-1"}>
              <CloseIcon
                fill={styles[snack.type].closeColor}
                onClick={() => removeSnack(snack.id)}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

interface SnackStyles {
  [key: string]: {
    bg: string;
    text: string;
    icon: IconProp;
    iconColor: string;
    closeColor: string;
  };
}
