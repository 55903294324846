/** @format */

import React from "react";
import { Form } from "react-final-form";
import { Link } from "react-router-dom";
import { useLocation } from "react-use";

import * as Sentry from "@sentry/react";
import { FORM_ERROR } from "final-form";

import { CheckboxNewField, TextFieldNew, Typography } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import type { Organization, SecondaryUserViewsRegisterRequestBodySchema } from "src/api";

import { Analytics } from "src/analytics";
import { UsersService } from "src/api";
import { HIPAABadges } from "src/components/Badges/ HIPAABadges";
import config from "src/config";
import { isAxiosError } from "src/helpers/axios";
import { getCurrentProduct } from "src/utils/tracking/tracking";
import { MarkdownText } from "src/v2/components/MarkdownText";
import { PasswordField } from "src/v2/components/PasswordField";
import { B2B_ORGANIZATION_SLUGS } from "src/v2/constants";
import { useStoreDispatch, useStoreState } from "src/v2/models";
import { _emailPolicy } from "src/v2/utils/auth/_regex";
import { INVALID_PATIENT_AGE_ERROR_TYPE } from "src/v2/utils/auth/age";
import {
  INSECURE_PASSWORD_ERROR_TYPE,
  MINIMUM_LENGTH,
  validatePasswordForm,
} from "src/v2/utils/auth/password";

type RegisterValues = {
  email?: string;
  password?: string;
  telehealthConsent?: string[];
  over13Consent?: string[];
  email_check?: string;
};

type ErrorValues = {
  email?: string;
  password?: string;
  telehealthConsent?: string;
  over13Consent?: string;
  email_check?: string;
};

interface RegisterFormProps {
  registrationType?: "benefit-holder-registration";
}

const getEmployerData = (): Organization | null => {
  try {
    const employerData = sessionStorage.getItem("employer");
    return employerData ? JSON.parse(employerData) : null;
  } catch (error) {
    console.error("Error parsing employer data:", error);
    return null;
  }
};

const getAnonymousUserId = () => {
  return localStorage.getItem("anonymousUserId");
};

const parseConsents = (values: RegisterValues) => {
  const signedConsents = [];
  if (values.telehealthConsent && values.telehealthConsent[0] === "true") {
    signedConsents.push("telehealth-consent", "privacy-policy", "terms-and-conditions");
  }
  if (values.over13Consent && values.over13Consent[0] === "true") {
    signedConsents.push("over-13-consent");
  }
  return signedConsents;
};

export const RegisterForm = ({ registrationType }: RegisterFormProps) => {
  const eligibilityLookupValues = useStoreState((state) => state.auth.eligibilityLookupValues);
  const dispatch = useStoreDispatch();
  const location = useLocation();

  const onSubmit = async (values: RegisterValues) => {
    const { email, password } = values;
    const condition = getCurrentProduct();

    const parsedConsents = parseConsents(values);

    const registrationPayload = {
      email,
      password,
      condition,
      eligibility_lookup_values: eligibilityLookupValues,
      signed_consents: parsedConsents,
    } as SecondaryUserViewsRegisterRequestBodySchema;

    // We add employer data if available and the previous page was sponsored-benefits
    const employer = getEmployerData();
    const isFromSponsoredBenefits = location.state?.state?.from?.includes("sponsored-benefits");
    if (employer && isFromSponsoredBenefits) registrationPayload.employer_id = employer.id;

    // Add meta data from any funnel if present
    const funnelSignUpKey = location.state?.state?.funnel;
    if (funnelSignUpKey) registrationPayload.metadata = { funnel: funnelSignUpKey };

    return dispatch.auth
      .register(registrationPayload)
      .then(async () => {
        Analytics.trackEvent({ category: "Account", action: "Register" });
        const auid = getAnonymousUserId();
        if (auid) {
          // Link the anonymous user to the new account and remove the auid from local storage
          try {
            await UsersService.linkAnonymousUserToAccount({ anonymousUserId: auid, userId: "me" });
          } catch (error) {
            Sentry.captureException(error);
          } finally {
            localStorage.removeItem("anonymousUserId");
          }
        }

        if (registrationType === "benefit-holder-registration") {
          window.location.href = "/";
        }
      })
      .catch((err: unknown) => {
        if (isAxiosError(err)) {
          if (err.response && err.response.status === 409) {
            return {
              [FORM_ERROR]: "Looks like you already have an account. Sign in!",
            };
          } else if (err.response && err.response.status === 406) {
            return {
              [FORM_ERROR]:
                "The data you provided to find your sponsored benefit has already been used for another account. Please try again or contact support.",
            };
          } else if (
            err.response &&
            err.response.status === 403 &&
            (err.response.data?.type === INSECURE_PASSWORD_ERROR_TYPE ||
              err.response.data?.type === INVALID_PATIENT_AGE_ERROR_TYPE)
          ) {
            // This is unlikely. The app checks for insecure passwords before sending to backend, but this is backup
            return { [FORM_ERROR]: err.response.data?.errors };
          }
        }
        return {
          [FORM_ERROR]:
            "There was an error in processing your request. Please try again or contact support.",
        };
      });
  };

  const validate = async (values: RegisterValues) => {
    const errors: ErrorValues = {};

    if (values.email) {
      if (!values.email.match(RegExp(_emailPolicy))) {
        errors.email = "Invalid email provided";
      }
    }

    const password = values.password;
    errors.password = (await validatePasswordForm({ password })).password;
    return errors;
  };

  const validateTelehealthConsent = (values: any) => {
    let errors = "";

    if (!values || values[0] !== "true") {
      errors = "You must consent to telehealth.";
    }

    return errors;
  };

  const validateOver13Consent = (values: any) => {
    let errors = "";

    if (!values || values[0] !== "true") {
      errors = "You must be 13 or older to register.";
    }

    return errors;
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ submitError, handleSubmit, submitting, pristine }) => (
          <form onSubmit={handleSubmit} className={`max-w-lg mx-auto`}>
            <TextFieldNew
              className="mb-4"
              name="email"
              label="Email address"
              required
              data-content-target="Email"
            />
            <PasswordField
              label={`Password (${MINIMUM_LENGTH}+ characters)`}
              name={"password"}
              required
            />
            <div className="mt-6 mb-8 w-full">
              <CheckboxNewField
                name="over13Consent"
                className="max-w-lg text-sm"
                overrideLabelClassname="m-2 normal-case field flex items-center flex gap-2 leading-5"
                validate={validateOver13Consent}
                options={[
                  {
                    value: "true",
                    label: "I am 13 or older.",
                  },
                ]}
              />
              <CheckboxNewField
                name="telehealthConsent"
                className="max-w-lg text-sm"
                overrideLabelClassname="m-2 normal-case field flex items-start flex gap-2 leading-5"
                validate={validateTelehealthConsent}
                options={[
                  {
                    value: "true",
                    label: (
                      <MarkdownText
                        linkStyle={{ color: "#6271C2", textDecoration: "none" }}
                        text={`I agree to the [Terms of Use](${config.wwwUrl}/terms/), [Consent to Telehealth](${config.wwwUrl}/terms/consent/), and [Privacy Policy](${config.wwwUrl}/terms/privacy/). I also agree to let Alpha disclose my PHI to its partners who send text and email communications on Alpha’s behalf.`}
                      />
                    ),
                  },
                ]}
              />
            </div>

            <ButtonPdb
              type={"submit"}
              data-content-target="Sign up"
              className="btn btn-primary w-full"
              disabled={submitting || pristine}
            >
              Sign up
            </ButtonPdb>

            {submitError &&
              ((submitError === 409 && (
                <div className="text-red my-2">
                  Looks like you already have an account. Sign in!
                </div>
              )) || <div className="text-red my-2">{submitError}</div>)}
          </form>
        )}
      />
    </>
  );
};

export const Register = () => {
  const dispatch = useStoreDispatch();

  const location = useLocation();
  const isFromSponsoredBenefits = location.state?.state?.from?.includes("sponsored-benefits");

  // sonja cares custom logic
  const employer = getEmployerData();
  const isNotFromSonjaCares = employer?.slug !== B2B_ORGANIZATION_SLUGS.SONJA_CARES;

  // This is only to clean up any employer data that could be added by the new B2B logic when use leaves this page
  React.useEffect(() => {
    return () => {
      sessionStorage.removeItem("employer");
    };
  }, []);

  return (
    <div
      data-track-content
      data-content-name="RegistrationPage"
      data-content-piece="Create Your Account"
      className="max-w-lg mx-auto mb-12 px-6"
    >
      <div className="flex justify-center mt-12">
        <a href={config.wwwUrl}>
          <img
            src="/assets/alpha-logo.svg"
            alt="Go to Alpha Medical informational web site"
            className="w-20 mb-8"
          />
        </a>
      </div>
      {getAnonymousUserId() && isFromSponsoredBenefits ? (
        <div className="mb-6">
          <Typography variant="h3" className="mb-4">
            Confirmation successful!
          </Typography>
          {isNotFromSonjaCares && (
            <Typography variant="body1">
              We recommend creating an account with your personal email address.
            </Typography>
          )}
        </div>
      ) : (
        <div className="text-xl mb-6 text-center">Create Your Account</div>
      )}
      <RegisterForm />
      <div className="mt-8 text-center">
        {!getEmployerData() && (
          <Typography variant="body1" className="cursor-pointer">
            Already have an account?
            <a
              className="text-cornflower-100 cursor-pointer"
              onClick={(e: any) => {
                e.preventDefault();
                dispatch.auth.setShowLogin(true);
              }}
            >
              {" "}
              Log in
            </a>
          </Typography>
        )}
        <div className="border-b border-primary-20 my-4 w-full" />
        {!getEmployerData() && (
          <Typography variant="body1" className="cursor-pointer mb-4">
            Have Alpha through your employer?{" "}
            <Link to="/sponsored-benefits" className="text-cornflower-100 text-center text-">
              Activate benefits
            </Link>
          </Typography>
        )}
        <HIPAABadges />
      </div>
    </div>
  );
};
