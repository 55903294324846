/** @format */

import type { Action, State, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";

import type { TreatmentPlanInfoSchema } from "src/api";
import type { CreateModel } from "src/v2/models/_create";

import { ConsultsService } from "src/api";

export interface PostVisitSummariesModel {
  fetchingPostVisitSummaries: boolean;
  postVisitSummaries: TreatmentPlanInfoSchema[];
  setFetchingPostVisitSummaries: Action<PostVisitSummariesModel, boolean>;
  setPostVisitSummaries: Action<PostVisitSummariesModel, TreatmentPlanInfoSchema[]>;
  fetchPostVisitSummaries: Thunk<PostVisitSummariesModel>;
}

export const makePvsSelector = (pvsId: string | undefined) => (state: State<CreateModel>) =>
  state.postVisitSummaries.postVisitSummaries.find(
    (value) => pvsId && value.id === parseInt(pvsId),
  );

export const postVisitSummariesStore: PostVisitSummariesModel = {
  fetchingPostVisitSummaries: false,
  postVisitSummaries: [],

  setFetchingPostVisitSummaries: action((state, fetching) => {
    state.fetchingPostVisitSummaries = fetching;
  }),

  setPostVisitSummaries: action((state, postVisitSummaries) => {
    state.postVisitSummaries = postVisitSummaries;
  }),

  fetchPostVisitSummaries: thunk(async (actions) => {
    actions.setFetchingPostVisitSummaries(true);
    return ConsultsService.getUserPvsInfo({ userId: "me" })
      .then((res) => {
        actions.setPostVisitSummaries(res);
      })
      .finally(() => {
        actions.setFetchingPostVisitSummaries(false);
      });
  }),
};
