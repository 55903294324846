/** @format */

import { includes } from "lodash";
import moment from "moment";

import type { MedicationOrder } from "src/v2/types/medications";
import type { SkuMap } from "src/v2/types/skus";
import type { ExternalSubscription, Subscription } from "src/v2/types/subscription";

import { VALID_FOLLOWUP_CONSULTS } from "src/v2/constants";

export const statusMapper = (status: string, consult_type?: string) => {
  switch (status) {
    case "STARTED":
      return consult_type === "FOLLOWUP" ? "Follow-Up Visit Incomplete" : "Started";
    case "APPROVED":
      return "Approved";
    case "COMPLETED":
      return "Completed";
    case "READY_FOR_REVIEW":
      return "Ready for review";
    case "NEEDS_FOLLOWUP":
      return "Needs Followup";
    case "DENIED":
      return "Denied";
    case "WITHDRAWN":
      return "Withdrawn";
    case "CANCELED":
      return "Canceled";
    case "CANCELLED":
      return "Canceled";
    case "ACTIVE":
      return "Active";
    case "EXPIRED":
      return "Expired";
    case "FAILED":
      return "Failed";
    case "PENDING":
      return "Pending";
    case "PAUSED":
      return "Paused";
    case "NOT_RECEIVED":
      return "Not yet received by lab";
    case "IN_PROCESS":
      return "Kit sample(s) being processed";
    case "REJECTED":
      return "Rejected";
    case "READY":
      return "Test results ready";
    default:
      return status;
  }
};
export const getFollowupUrl = (conditionKey: string, consult_type?: string) => {
  if (consult_type === "RETURN_CONSULT" && conditionKey === "mental-health") {
    return `/return-consult/depression-anxiety-checkup`;
  } else if (
    consult_type === "RETURN_CONSULT" ||
    !includes(VALID_FOLLOWUP_CONSULTS, conditionKey)
  ) {
    return `/return-consult/${conditionKey}-checkup`;
  }
  const outlineKey =
    {
      eyelashes: "lashes",
    }[conditionKey] || conditionKey;
  return `/followup/${outlineKey}`;
};

interface CreateDetailsListProps {
  subscription?: Subscription;
  type: string;
  skus?: SkuMap;
  externalSubscription?: ExternalSubscription;
  testKit?: MedicationOrder;
}

export const expiresIn30Days = (date: string) => {
  const thirtyDaysFromExpirationDate = date && moment(date).subtract("30", "days");
  const expirationDate = date && moment(date);
  const inExpirationWindow = moment().isBetween(thirtyDaysFromExpirationDate, expirationDate);
  return inExpirationWindow;
};

export const createDetailsList = ({
  type,
  subscription,
  externalSubscription,
  testKit,
}: CreateDetailsListProps) => {
  const nextRefillOrStatus =
    ["INACTIVE", "CANCELLED", "PAUSED"].indexOf(subscription?.status || "") !== -1
      ? subscription?.status
      : moment(subscription?.next_refill).format("l");
  const alphaPharmacy = subscription?.preferred_pharmacy === "ALPHA";
  const fillRequest = testKit?.fill_request;
  const testResults = fillRequest?.test_results;
  const subscriptionSku = subscription?.sku;
  const subscriptoinSkuName = subscriptionSku
    ? subscriptionSku.display_label || subscriptionSku.name
    : subscription?.current_sku || "N/A";
  switch (type) {
    case "alpha-medication":
      return [
        {
          title: "medication",
          data: subscriptoinSkuName,
        },
        {
          title: alphaPharmacy && subscription?.next_refill && "next refill",
          data: nextRefillOrStatus,
        },
        {
          title: alphaPharmacy && "Refill Frequency",
          data:
            (subscription?.refill_frequency && `Every ${subscription?.refill_frequency} days`) ||
            (subscription?.sku && `Every ${subscription?.sku.days_supply} days`),
          hideSection: externalSubscription ? true : false,
        },
        {
          title: alphaPharmacy && !!subscription?.refills_remaining && "refills remaining",
          data: subscription?.refills_remaining,
        },
        {
          title: alphaPharmacy && subscription?.expires_on && "expiration date",
          data: moment(subscription?.expires_on).format("l"),
          render_label: expiresIn30Days(subscription?.expires_on || ""),
        },
        {
          title: "Your Pharmacy",
          data: alphaPharmacy ? "Alpha Network Pharmacy" : "External Pharmacy",
        },
        {
          title: subscription?.payment_preference && "paid with",
          data: subscription?.payment_preference,
        },
      ];
    case "external-medication":
      return [
        {
          title: externalSubscription?.name && "medication",
          data: externalSubscription?.name,
        },
        {
          title: "your pharmacy",
          data: "External Pharmacy",
        },
        {
          title: subscription?.payment_preference && "paid with",
          data: subscription?.payment_preference,
        },
      ];
    case "test-kit":
      return [
        {
          title: "test kit name",
          data: testKit?.sku.display_label || testKit?.sku.name,
        },
        {
          title: "registration",
          data: fillRequest?.registered ? "Registration complete" : "Not registered",
          emphasize: !fillRequest?.registered,
          hideSection: !fillRequest,
        },
        {
          title: "test kit status",
          data: statusMapper(testResults?.status || "") || "Unknown",
          emphasize: testResults?.status === "READY",
          hideSection: !fillRequest?.test_results,
        },
        {
          title: "details",
          data: testKit?.sku?.ingredients
            ? `Tests for ${testKit.sku.ingredients}`
            : "No additional details for this kit.",
        },
      ];
    default:
      return [];
  }
};

export const getTimelineData = (order: MedicationOrder, condition: string) => {
  const steps =
    condition === "sti-testing-kit" || condition === "diabetes"
      ? [
          { title: "Alpha preparing your order" },
          { title: "Order shipped" },
          { title: "Order delivered" },
        ]
      : [
          { title: "We're preparing your order" },
          { title: "Prescription order shipped" },
          { title: "Prescription order delivered" },
        ];

  const currentStep =
    order.status === "DELIVERED"
      ? 2
      : ["SHIPPED", "PENDING_SHIPMENT"].indexOf(order.status) !== -1
        ? 1
        : 0;

  return {
    steps,
    currentStep,
  };
};
