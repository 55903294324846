/** @format */

import React from "react";

import type { ErrorProps } from "src/v2/components/Forms/BillingInfo/BillingInfoForm";

import { STATES } from "src/v2/constants";

import { PaymentFooter } from "./payment-footer";

import "./recurly.scss";

interface RecurlyFormProps {
  handleSubmit: (event: any) => void;
  saving: boolean;
  pii: any;
  errors: ErrorProps;
  showFooter?: boolean;
  nextBtnLabel?: string;
}

interface RecurlyFormState {
  useShippingAddressForBilling: boolean;
}

interface RecurlyFieldProps {
  name: string;
  label: string;
  recurlyName?: string;
  className?: string;
  error?: string;
}

interface RecurlyFieldSelectProps extends RecurlyFieldProps {
  options: any;
}

const keys = <T extends object>(obj: T) => obj && (Object.keys(obj) as (keyof T)[]);

export const RecurlyField = (props: RecurlyFieldProps) => {
  return (
    <div className={`${props.className} mb-8 mt-3 ${props.error && "border border-red"}`}>
      <input
        type="text"
        data-recurly={props.recurlyName || props.name}
        id={props.name}
        name={props.name}
        placeholder={props.label}
        className="w-full py-2 text-16 text-primary outline-none border-b border-primary-20 focus:border-primary"
      />
    </div>
  );
};

export const RecurlyFieldSelect = (props: RecurlyFieldSelectProps) => (
  <div className={`${props.className} mb-6 mt-3 ${props.error && "border border-red"}`}>
    <div className="relative">
      <select
        defaultValue=""
        data-recurly={props.recurlyName || props.name}
        id={props.name}
        name={props.name}
        style={{ background: "#EDF3F3" }}
        className={`
          block appearance-none w-full
          text-primary
          py-3 px-4 pr-8 leading-tight
          focus:outline-none focus:bg-gray-700 focus:border-gray"
          rounded-none
        `}
      >
        <option value="" disabled={true}>
          {props.label}
        </option>
        {props.options.map((option: any, fidx: any) => (
          <option value={option.value} key={`${props.name}-option-${fidx}`}>
            {option.label}
          </option>
        ))}
      </select>
      <div
        className={`
          pointer-events-none absolute
          inset-y-0 right-0 flex items-center px-2 text-gray-darker
        `}
      >
        <div className="fas fa-chevron-down text-primary-40 mx-2" />
      </div>
    </div>
  </div>
);

export class RecurlyForm extends React.Component<RecurlyFormProps, RecurlyFormState> {
  constructor(props: RecurlyFormProps) {
    super(props);
    this.state = {
      useShippingAddressForBilling: true,
    };
  }

  toggleShowPiiFields = () => {
    this.setState({
      useShippingAddressForBilling: !this.state.useShippingAddressForBilling,
    });
  };

  render() {
    const { handleSubmit, saving, errors, nextBtnLabel } = this.props;
    let { pii } = this.props;
    let { showFooter } = this.props;
    if (showFooter === undefined) {
      showFooter = true;
    }
    if (!pii) {
      pii = {
        address: {
          address: "tbd",
          city: "tbd",
          state: "CA",
          zipcode: "94301",
        },
        first_name: "tbd",
        last_name: "tbd",
      };
    }

    return (
      <form onSubmit={handleSubmit} className="bg-white">
        <input type="hidden" data-recurly="token" name="recurly-token" />
        <input type="hidden" data-recurly="country" id="country" name="country" value="US" />

        <div data-recurly="number" />
        {errors && errors.number && (
          <div className="m-2 text-red">Invalid Number -- please double check your input.</div>
        )}
        <div className="flex">
          <div className="flex-1 mr-3">
            <div data-recurly="month" />
            {errors && errors.month && <div className="m-2 text-red">Invalid Month</div>}
          </div>
          <div className="flex-1 mx-3">
            <div data-recurly="year" />
            {errors && errors.year && <div className="m-2 text-red">Invalid Year</div>}
          </div>
          <div className="flex-1 ml-3">
            <div data-recurly="cvv" />
            {errors && errors.cvv && <div className="m-2 text-red">{errors.cvv}</div>}
          </div>
        </div>

        <label className="my-1 flex items-center">
          <input
            className="display-none"
            type="checkbox"
            onChange={this.toggleShowPiiFields}
            defaultChecked={this.state.useShippingAddressForBilling}
          />
          <div
            className={`
              flex items-center justify-center
              checkbox-field w-8 h-8 bg-sky
              border-8 border-sky
              ${(this.state.useShippingAddressForBilling && `fas fa-check`) || ""}
            `}
          />
          <span className="ml-5 normal-case">Billing address same as Shipping</span>
        </label>
        {(this.state.useShippingAddressForBilling && (
          <>
            <input
              type="hidden"
              data-recurly="first_name"
              id="first_name"
              name="first-name"
              value={pii.first_name}
            />
            <input
              type="hidden"
              data-recurly="last_name"
              id="last_name"
              name="last-name"
              value={pii.last_name}
            />
            <input
              type="hidden"
              data-recurly="address1"
              id="address1"
              name="address1"
              value={pii.address.address}
            />
            <input
              type="hidden"
              data-recurly="city"
              id="city"
              name="city"
              value={pii.address.city}
            />
            <input
              type="hidden"
              data-recurly="state"
              id="state"
              name="state"
              value={pii.address.state}
            />
            <input
              type="hidden"
              data-recurly="postal_code"
              id="postal_code"
              name="postal_code"
              value={pii.address.zipcode}
            />
          </>
        )) || (
          <>
            <div className="flex">
              <RecurlyField
                className="flex-1 mr-3"
                name="first_name"
                label="First name"
                error={errors.firstName}
              />
              <RecurlyField
                className="flex-1 ml-3"
                name="last_name"
                label="Last name"
                error={errors.lastName}
              />
            </div>
            <RecurlyField name="address1" label="Address line 1" error={errors.address1} />
            <RecurlyField name="address2" label="Address line 2" />
            <div className="flex">
              <RecurlyField className=" flex-1 mr-3" name="city" label="City" error={errors.city} />
              <RecurlyFieldSelect
                className="flemx-3"
                name="state"
                label="State"
                options={STATES.map((state) => ({ value: state, label: state }))}
                error={errors.state}
              />
              <RecurlyField
                className="flex-1 ml-3"
                name="postal_code"
                label="Zip Code"
                error={errors.postalCode}
              />
            </div>
          </>
        )}

        {showFooter && <PaymentFooter />}

        <button type="submit" className="btn btn-primary w-full mt-5" disabled={saving}>
          {(!saving && `${nextBtnLabel || "Next"}`) || `...verifying...`}
        </button>

        <div className="text-red mt-5">
          {!saving &&
            errors &&
            keys(errors)
              .filter((errorKey) => ["cvv", "month"].indexOf(errorKey) === -1)
              .map((errorKey) => {
                const error = errors[errorKey];
                const message = error && error.replace(new RegExp(errorKey, "g"), "");
                return (
                  <div className="red" key={`error-${errorKey}`}>
                    {message}
                  </div>
                );
              })}
        </div>
      </form>
    );
  }
}
