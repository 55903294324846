/** @format */

import React from "react";
import { useFormState } from "react-final-form";

import classnames from "classnames";

import { isImageOptionField } from "src/components/DynamicForm/types";

import type { CustomOptionComponentProps } from "./types";

export const ProductOption = ({
  field,
  option,
  input,
  meta,
  defaultOption,
}: CustomOptionComponentProps) => {
  const imageOption =
    isImageOptionField(field) && field.options.find((opt) => opt.value === option.value);
  const { values } = useFormState();

  const usingInsurance = values.payment_preference === "insurance";

  if (!imageOption) {
    return defaultOption;
  }

  return (
    <label
      className={classnames("rounded-lg flex flex-row items-center", "t-2 my-4", {
        "bg-aqua border-aqua": input.checked,
        "bg-sky-40 border-sand": !input.checked,
        "min-h-24 border-8": !!imageOption.image,
        "border-4": !imageOption.image,
      })}
    >
      <input className="appearance-none inline" {...input} />
      {imageOption.image && (
        <div
          className={classnames("w-24 sm:w-32 bg-grey-1 self-stretch bg-center bg-no-repeat", {
            "bg-contain": imageOption.backgroundSize === "contain",
            "bg-cover": imageOption.backgroundSize !== "contain",
          })}
          style={{
            backgroundImage: `url(${imageOption.image})`,
          }}
        />
      )}
      <div className="flex-1 flex flex-col mx-4 my-2">
        <div className="flex flex-col sm:flex-row">
          <div
            className={`flex-1 ${(imageOption.image && "text-lg") || "text-sm"}`}
            dangerouslySetInnerHTML={{ __html: imageOption.label }}
          />
          <div className="text-sm text-right my-1 sm:my-0">
            <span>{usingInsurance ? "Your copay" : imageOption.cost}</span>
          </div>
        </div>
        {imageOption.sublabel && <div className="text-primary-60 my-1">{imageOption.sublabel}</div>}
      </div>
    </label>
  );
};
