/** @format */
import React from "react";

import _ from "lodash";

import type { ValidationError } from "src/components/DynamicForm/Validators/types";

import {
  isAgeEligibilityError,
  isGroupSessionUnavailableError,
  isRecurlyValidationError,
  isStateEligibilityError,
  isSuicidalError,
  isWaitlistEligibilityError,
} from "src/components/DynamicForm/Validators/types";

import { GroupSessionUnavailableMessage } from "./GroupSessionUnavailableMessage";
import { MinimumAgeMessage } from "./MinimumAgeMessage";
import { PaymentFailureMessage } from "./PaymentFailureMessage";
import { SuicidalWarningMessage } from "./SuicidalWarningMessage";
import { UnsupportedStateMessage } from "./UnsupportedStateMesage";
import { WaitlistMessage } from "./WaitlistMessage";

export const ValidationErrorContent = ({
  validationError,
}: {
  validationError: ValidationError;
}) => {
  if (_.isString(validationError)) {
    return <>{validationError}</>;
  }
  if (isSuicidalError(validationError)) {
    return <SuicidalWarningMessage validationError={validationError} />;
  }
  if (isAgeEligibilityError(validationError)) {
    return <MinimumAgeMessage validationError={validationError} />;
  }
  if (isStateEligibilityError(validationError)) {
    return <UnsupportedStateMessage validationError={validationError} />;
  }
  if (isGroupSessionUnavailableError(validationError)) {
    return <GroupSessionUnavailableMessage validationError={validationError} />;
  }
  if (isRecurlyValidationError(validationError)) {
    return <PaymentFailureMessage validationError={validationError} />;
  }
  if (isWaitlistEligibilityError(validationError)) {
    return <WaitlistMessage validationError={validationError} />;
  }
  return (
    <>There was an issue submitting your responses. Please review your responses and try again</>
  );
};
