/** @format */

import * as React from "react";

import type { IProvidedProps } from "@peacechen/google-maps-react";
import { GoogleApiWrapper } from "@peacechen/google-maps-react";
import classNames from "classnames";
import Cleave from "cleave.js/react";
import _, { map } from "lodash";

import type { Address, AddressesCreateAddressRequestBodySchema } from "src/api";
import type { FormPopupError } from "src/v2/models/api_types";

import config from "src/config";
import { Button } from "src/v2/components/Button";
import { Dropdown } from "src/v2/components/Dropdown";
import { ADDRESS_TYPES } from "src/v2/components/Page/AddressPage";
import { STATES } from "src/v2/constants";
import { useStoreDispatch } from "src/v2/models";

interface AddressFormProps extends IProvidedProps {
  closeModal?: any;
  selectedPharmacy?: any;
  addressType: "PHARMACY" | "SHIPPING" | "LAB";
  updateSelected: (value: any) => void;
  nextClick: () => any;
  children?: React.ReactChild;
}

export const AddressForm = (props: AddressFormProps) => {
  const { google, selectedPharmacy, closeModal, addressType, children } = props;
  const dispatch = useStoreDispatch();
  const [response, setResponse] = React.useState({} as { error?: any });
  const [name, setName] = React.useState(selectedPharmacy.name || "");
  const [address1, setAddress1] = React.useState(selectedPharmacy.address1 || "");
  const [address2, setAddress2] = React.useState(selectedPharmacy.address2 || "");
  const [city, setCity] = React.useState(selectedPharmacy.city || "");
  const [state, setState] = React.useState(selectedPharmacy.state || "");
  const [zipcode, setZipcode] = React.useState(selectedPharmacy.zipcode || "");
  const [disabled, setDisabled] = React.useState(true);
  const enablePlaceApi = !_.isEmpty(config.googleAPIKey);
  const autoCompleteRef = React.useRef<HTMLDivElement>(null);
  const [addressError, setAddressError] = React.useState<FormPopupError | null>(null);
  const [formError, setFormError] = React.useState("");

  const onSuccess = (res: any) => {
    // try to get id
    const addressId = _.get(res, "id");
    if (addressId) {
      // address updated successfully, move to next question
      props.updateSelected(addressId);
      props.nextClick();
      closeModal();
    } else if (res.response.data.type === "FormPopupError") {
      // address did not validate correctly, set address error
      setAddressError(res.response.data);
    }
  };

  const onFailure = (err: any) => {
    setResponse(err);
    closeModal();
  };

  /**
   * Edits or submits a new address
   * @param e Mouse Event
   * @param validateAddress Boolean, tells the backend if you want to validate the address is correct or not.
   */
  const submitNewAddress = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    validateAddress = false,
  ) => {
    e.preventDefault();
    if (disabled) {
      return;
    }
    // conditional POST route based on pharmacy existing or not
    if (selectedPharmacy.id) {
      const payload: Partial<Omit<Address, "user_id">> = {
        id: selectedPharmacy.id,
        default: true,
        name,
        address1,
        address2,
        city,
        state,
        zipcode,
        type: addressType,
      };

      // saving existing address
      dispatch.addresses
        .updateAddress({ address: payload as Address, validate: validateAddress })
        .then(onSuccess)
        .catch(onFailure);
    } else {
      const payload: AddressesCreateAddressRequestBodySchema = {
        default: true,
        name,
        address1,
        address2,
        city,
        state,
        zipcode,
        type: addressType,
      };
      dispatch.addresses
        .postAddress({ address: payload, validate: validateAddress })
        .then(onSuccess)
        .catch(onFailure);
    }
  };

  React.useLayoutEffect(() => {
    if (autoCompleteRef.current) {
      const autoComplete =
        document.getElementById("autocomplete") &&
        new google.maps.places.Autocomplete(document.getElementById("autocomplete"), {
          types: ["address"],
          componentRestrictions: { country: "us" },
        });

      if (autoComplete) {
        google.maps.event.addDomListener(
          document.getElementById("autocomplete"),
          "focus",
          (e: any) => e.target.setAttribute("autocomplete", "new-password"),
        );

        autoComplete.setFields(["address_component"]);
        autoComplete.addListener("place_changed", () => {
          const place = autoComplete.getPlace();
          const address_string: string[] = [];
          if (place.address_components) {
            const address_components = place.address_components;
            address_components.forEach((component: any) => {
              if (component.types.indexOf("street_number") !== -1) {
                address_string.push(`${component.short_name}`);
              }
              if (component.types.indexOf("route") !== -1) {
                address_string.push(" ", component.short_name);
                setAddress1(address_string.join(""));
              }
              if (component.types.indexOf("locality") !== -1) {
                setCity(component.long_name);
              }
              if (component.types.indexOf("administrative_area_level_1") !== -1) {
                setState(component.short_name);
              }
              if (component.types.indexOf("postal_code") !== -1) {
                setZipcode(component.short_name);
              }
            });
          }

          if (place.name) {
            setAddress1(place.name);
          }
        });
        return () => {
          google.maps.event.clearInstanceListeners(autoComplete);
        };
      }
    }
    return;
  }, []);

  React.useEffect(() => {
    setDisabled(
      !name || !address1 || !city || !state || !zipcode || !addressType || zipcode.length < 5,
    );
  }, [name, address1, city, state, zipcode, addressType]);

  const inputStyles = `py-3 text-sm my-3 bg-transparent text-primary field-placeholder
  w-full border-b border-forest-20 focus:outline-none
  focus:border-primary`;

  /**
   * User selected to update address, so we have to save the new address in the backend.
   * @param values
   */
  const changeAddress = (values: any) => {
    // conditional POST route based on pharmacy existing or not
    if (selectedPharmacy.id) {
      const payload: Partial<Omit<Address, "user_id">> = {
        id: selectedPharmacy.id,
        default: true,
        name,
        address1: values.address,
        address2: values.address2,
        city: values.city,
        state: values.state,
        zipcode: values.zipcode,
        type: addressType,
      };
      payload.id = selectedPharmacy.id;
      // saving existing address
      dispatch.addresses
        .updateAddress({ address: payload as Address, validate: true })
        .then(onSuccess)
        .catch(onFailure);
    } else {
      const payload: AddressesCreateAddressRequestBodySchema = {
        default: true,
        name,
        address1: values.address,
        address2: values.address2,
        city: values.city,
        state: values.state,
        zipcode: values.zipcode,
        type: addressType,
      };
      dispatch.addresses
        .postAddress({ address: payload, validate: true })
        .then(onSuccess)
        .catch(onFailure);
    }
  };

  return (
    <form>
      {!addressError && (
        <>
          <div
            style={{
              backgroundColor: "#FFF9F7",
              maxWidth: "inherit",
              width: "inherit",
              minHeight: "70px",
            }}
            className="flex z-20 sticky inset-x-0 top-0 items-center justify-between px-8"
          >
            <p className="text-18 font-semibold m-0">Enter {ADDRESS_TYPES[addressType]} address</p>
            <img
              src="/assets/close-light.svg"
              onClick={closeModal}
              className="cursor-pointer"
              alt="Close address form"
            />
          </div>
          <div className="p-6 overflow-hidden">
            {children}
            {addressType == "PHARMACY" && (
              <p className="text-18 m-0">
                What is the address of the pharmacy where you want to pick up your medication?
              </p>
            )}
            <label hidden>Name</label>
            <input
              type="text"
              className={inputStyles}
              placeholder={`${
                ADDRESS_TYPES[addressType][0].toUpperCase() +
                ADDRESS_TYPES[addressType].substr(1, ADDRESS_TYPES[addressType].length)
              } name`}
              autoComplete="off"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {!address1 && enablePlaceApi && (
              <div ref={autoCompleteRef}>
                {/* This label is used to trick googles autofill algorithm (not autocomplete drop down)*/}
                <label className="hidden">
                  <span className="hidden">some random stuff</span>
                </label>
                <input
                  // onBlur={e => setAddress1(e.target.value)}
                  id="autocomplete"
                  className={inputStyles}
                  placeholder="Address line 1"
                />
              </div>
            )}
            <div
              className={!address1 && enablePlaceApi ? "absolute" : ""}
              style={{ left: !address1 && enablePlaceApi ? "-999px" : "" }}
            >
              <input
                type="text"
                className={inputStyles}
                name="street-address-1"
                autoComplete="off"
                placeholder="Address line 1"
                value={address1}
                onChange={(e) => setAddress1(e.target.value)}
              />
            </div>
            <input
              type="text"
              className={inputStyles}
              placeholder="Address line 2"
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
            />
            <input
              type="text"
              className={inputStyles}
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <div className="flex justify-between py-3 items-center">
              <div className="w-1/2">
                <Dropdown
                  value={state}
                  onChange={setState}
                  placeholder="State"
                  options={map(STATES, (state) => ({ value: state, label: state }))}
                />
              </div>
              <div className="w-1/4" />
              <div className="w-1/2">
                <Cleave
                  className={inputStyles}
                  placeholder="ZIP Code"
                  value={zipcode}
                  options={{
                    numericOnly: true,
                    blocks: [5, 4],
                    delimiter: "-",
                    delimiterLazyShow: true,
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setZipcode(e.target.value);
                    if (e.target.value && e.target.value.length < 5) {
                      setFormError((value) => "ZIP code must be at least 5 characters long.");
                    } else {
                      setFormError((value) => "");
                    }
                  }}
                />
              </div>
            </div>
            {formError && <p className={"text-red"}>{formError}</p>}
          </div>
          {response && response.error && (
            <pre className="text-red">{JSON.stringify(response.error, null, 2)}</pre>
          )}
          <div
            className="sticky left-0 right-0 bottom-0 flex items-center justify-center"
            style={{
              backgroundColor: "#FFF9F7",
              maxWidth: "inherit",
              width: "inherit",
              minHeight: "87px",
            }}
          >
            <div className="flex flex-col justify-center items-center w-full mx-8">
              {addressType == "PHARMACY" ? (
                <Button
                  disabled={disabled}
                  onClick={(e) => submitNewAddress(e, true)}
                  cxMargin="mt-0"
                >
                  Next
                </Button>
              ) : (
                <button
                  className="btn btn-violator w-full"
                  disabled={disabled}
                  onClick={(e) => submitNewAddress(e, true)}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </>
      )}
      {addressError && (
        <>
          <div
            style={{
              backgroundColor: "#FFF9F7",
              maxWidth: "inherit",
              width: "inherit",
              minHeight: "70px",
            }}
            className="flex z-20 sticky inset-x-0 top-0 items-center justify-between px-8"
          >
            <p className="text-18 font-semibold m-0">{addressError.header}</p>
            <img
              src="/assets/close-light.svg"
              onClick={closeModal}
              className="cursor-pointer"
              alt="Close address form"
            />
          </div>
          {addressError.description && (
            <div
              className="px-8 mt-6"
              dangerouslySetInnerHTML={{ __html: addressError.description }}
            ></div>
          )}
          <div className="bg-grey-lightest p-5 space-y-4">
            {addressError.options.map((option, idx) => (
              <button
                key={option.cta}
                onClick={(event: any) => {
                  event.preventDefault();
                  if (option.cta === "Yes, use the suggested address") {
                    if (option.change_values) {
                      changeAddress(option.change_values);
                    }
                  } else if (option.cta === "No, use the original address") {
                    submitNewAddress(event, false);
                  } else if (option.cta === "Edit address") {
                    setAddressError(null);
                  }
                }}
                type="submit"
                className={classNames("w-full h-12 text-center font-bold", {
                  "bg-forest text-white": idx === 0,
                  "bg-clear text-primary border border-primary": idx !== 0,
                })}
              >
                {option.cta}
              </button>
            ))}
          </div>
        </>
      )}
    </form>
  );
};

export default GoogleApiWrapper((props: any) => ({
  apiKey: config.googleAPIKey || "",
  libraries: ["places"],
}))(AddressForm);
