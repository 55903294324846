/** @format */

import type { Action, State, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";

import type { ScatterChart } from "src/api";

import { UsersService } from "src/api";
import { ChartType } from "src/v2/components/Charts/types";

import type { CreateModel, CreateModelDispatch } from "./_create";

export interface ChartModel {
  chartsData: Partial<Record<ChartType, ScatterChart>>;
  weightLossOverview?: any;

  setChartData: Action<ChartModel, { type: ChartType; chart: ScatterChart }>;
  setWeightLossOverview: Action<ChartModel, any>;
  fetchChartData: Thunk<ChartModel, ChartType>;
  fetchChartType: Thunk<ChartModel, ChartType>;
  fetchAllCharts: Thunk<ChartModel>;
  fetchWeightLossOverview: Thunk<ChartModel>;
  submitObservation: Thunk<
    ChartModel,
    { value: number; date: string; chartType: ChartType.WEIGHT | ChartType.WAIST_CIRCUMFERENCE }
  >;
  updateObservation: Thunk<
    ChartModel,
    { observationId: number; value: number; date: string; chartType: ChartType }
  >;
  deleteObservation: Thunk<ChartModel, { observationId: number; chartType: ChartType }>;
}

export const chartsSelector = (state: State<CreateModel>) => state.charts.chartsData;
export const makeChartTypeSelector = (chartType: ChartType) => (state: State<CreateModel>) =>
  state.charts.chartsData[chartType];

export const chartStore: ChartModel = {
  chartsData: {},
  weightLossOverview: {},
  setChartData: action((state, res) => {
    state.chartsData[res.type] = res.chart;
  }),

  setWeightLossOverview: action((state, action) => {
    state.weightLossOverview = action;
  }),

  fetchChartData: thunk(async (actions, chartType, { getStoreState }) => {
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;
    const wlj_charts = [ChartType.BMI, ChartType.WEIGHT, ChartType.WAIST_CIRCUMFERENCE];
    const includeObservations = wlj_charts.includes(chartType);
    return (
      currentProfileId &&
      UsersService.getChartData({
        userId: currentProfileId,
        chartType,
        includeObservations: includeObservations,
      }).then((chart) => {
        actions.setChartData({ type: chartType, chart });
        return chart;
      })
    );
  }),

  fetchChartType: thunk(async (actions, chartType) => {
    await actions.fetchChartData(chartType);

    // fetch BMI if weight was added
    if (chartType == ChartType.WEIGHT) {
      await actions.fetchChartData(ChartType.BMI);
    }
  }),

  fetchWeightLossOverview: thunk(async (actions, _, { getStoreState }) => {
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;

    return (
      currentProfileId &&
      UsersService.getWeightLossOverview({ userId: currentProfileId }).then((chart) => {
        actions.setWeightLossOverview(chart);
        return chart;
      })
    );
  }),

  fetchAllCharts: thunk(async (actions) => {
    return Promise.all(
      [ChartType.BMI, ChartType.WEIGHT, ChartType.WAIST_CIRCUMFERENCE].map((value) =>
        actions.fetchChartData(value),
      ),
    );
  }),

  submitObservation: thunk(async (actions, { value, chartType, date }, { dispatch }) => {
    const _dispatch = dispatch as CreateModelDispatch;
    const observationNames = new Map<string, string>([
      [ChartType.WEIGHT, "weight_in_pounds"],
      [ChartType.WAIST_CIRCUMFERENCE, "waist_circumference"],
    ]);
    const observationName = observationNames.get(chartType);
    if (observationName != undefined) {
      await UsersService.addUserObservation({
        userId: "me",
        requestBody: {
          observation_name: observationName as "waist_circumference" | "weight_in_pounds",
          observation_date: date,
          observation_value: value,
        },
      });
      _dispatch.profile.setShowShareData(true);
      await actions.fetchChartType(chartType);
    }
  }),

  updateObservation: thunk(async (actions, { observationId, value, date, chartType }) => {
    await UsersService.editUserObservation({
      userId: "me",
      observationId,
      requestBody: {
        observation_date: date,
        observation_value: value,
      },
    });
    await actions.fetchChartType(chartType);
  }),

  deleteObservation: thunk(async (actions, { observationId, chartType }) => {
    await UsersService.deleteUserObservation({ userId: "me", observationId });
    await actions.fetchChartType(chartType);
  }),
};
