/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LeadCreateSchema } from '../models/LeadCreateSchema';
import type { LeadsLeadCreateRequestBodySchema } from '../models/LeadsLeadCreateRequestBodySchema';
import type { WaitlistAddUserToWaitlistRequestBodySchema } from '../models/WaitlistAddUserToWaitlistRequestBodySchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MarketingService {

    /**
     * @returns LeadCreateSchema
     * @throws ApiError
     */
    public static leadCreate({
        requestBody,
    }: {
        requestBody: LeadsLeadCreateRequestBodySchema,
    }): CancelablePromise<LeadCreateSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/lead/new',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static newLinkTracking(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/link-tracking',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static addUserToWaitlist({
        userId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        requestBody: WaitlistAddUserToWaitlistRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/{user_id}/waitlist',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
