/** @format */

import React from "react";

import { Intro } from "src/components/DynamicForm/Fields/CustomFields/WelcomeFlow/WelcomeIntro/Intro";
import { WelcomePageContainerHealthCheckIn } from "src/components/DynamicForm/Fields/CustomFields/WelcomeFlow/WelcomePageCotainer/WelcomePageContainerHealthCheckIn";
import welcome from "src/content/welcome";

interface WelcomeIntroHealthCheckInProps {
  questionKey: string;
}

export const WelcomeIntroHealthCheckIn = ({ questionKey }: WelcomeIntroHealthCheckInProps) => {
  return (
    <WelcomePageContainerHealthCheckIn
      submitButtonTitle={welcome.screen1.submitButton}
      questionKey={questionKey}
    >
      <Intro />
    </WelcomePageContainerHealthCheckIn>
  );
};
