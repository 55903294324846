/** @format */

import React from "react";
import { Field } from "react-final-form";

interface FieldErrorProps {
  name: string;
}

export const FieldError = (props: FieldErrorProps) => (
  <Field name={props.name} subscription={{ submitError: true, error: true, touched: true }}>
    {({ meta }) => {
      const hasError = (meta.error || meta.submitError) && meta.touched;
      return hasError ? (
        <span className="inline-block px-3 text-red">
          {meta.touched ? meta.error || meta.submitError : ""}
        </span>
      ) : null;
    }}
  </Field>
);
