/** @format */

import type { FC } from "react";

import React from "react";

import classNames from "classnames";

import "./BadgePdb.scss";

export interface BadgePdbProps {
  /** Badge theme. Affect background and text color.
   * @default neutral
   */
  variant?:
    | "light"
    | "neutral"
    | "notification"
    | "informative"
    | "success"
    | "warning"
    | "error"
    | "brandLight"
    | "brandDark";

  /** Badge size.
   * @default medium
   */
  size?: "medium" | "small" | "indicator";

  /** Label to be displayed on the badge. */
  label?: string | number;

  /** Badge internal text transform style.
   * @default capitalize
   */
  textTransform?: "normal-case" | "uppercase" | "lowercase" | "capitalize";

  /** Flag. Show a skeleton until data is available to display.
   * @default false
   */
  loading?: boolean;

  /** Flag. Keeps the badge visible even when the label value is equal to zero.
   * @default false
   */
  alwaysVisible?: boolean;

  /** Additional classnames to be appended to existing classes. */
  addClasses?: string;
}

export const BadgePdb: FC<BadgePdbProps> = ({
  variant,
  size,
  label,
  textTransform,
  loading,
  alwaysVisible,
  addClasses,
}) => {
  // Style Mappers
  const variantToCssClassMapper: { [key: string]: string } = {
    light: "bg-grey-20 text-forest-120",
    neutral: "bg-neutral text-white",
    notification: "bg-sand-120 text-forest-120",
    informative: "bg-information text-white",
    success: "bg-success-pdb text-forest-120",
    warning: "bg-warning-pdb text-forest-120",
    error: "bg-error text-white",
    brandLight: "bg-sand text-forest-120",
    brandDark: "bg-forest text-white",
    loading: "bg-grey-pdb-20",
  };

  const loadingSizeClassMapper: { [key: string]: string } = {
    medium: "w-6 h-6 badge-rounded-20",
    small: "w-4 h-4 badge-rounded-20",
    indicator: "w-2 h-2 rounded-full",
  };

  const sizeToCssClassMapper: { [key: string]: string } = {
    medium: `${label ? "px-2" : "px-1"} py-1 badge-rounded-20`,
    small: `${label ? "py-0" : "py-1"} px-1 badge-rounded-20`,
    indicator: "w-2 h-2 rounded-full",
  };

  /**
   * Function. It allows to obtain the correct style depending on the badge params.
   * @returns string. Tailwind classes.
   */
  const getBadgeStyles = () => {
    const mappedVariantClasses: string = variant
      ? variantToCssClassMapper[variant]
      : variantToCssClassMapper["neutral"];
    const textVariant = textTransform ? textTransform : "capitalize";

    const styleMapper = loading ? loadingSizeClassMapper : sizeToCssClassMapper;
    const themeMapper = loading ? variantToCssClassMapper["loading"] : mappedVariantClasses;

    const mappedSizeClasses = size ? styleMapper[size] : styleMapper["medium"];

    return `${mappedSizeClasses} ${themeMapper} ${textVariant}`;
  };

  return (
    <>
      {(label || alwaysVisible) && (
        <div
          className={classNames(
            getBadgeStyles(),
            "inline-flex",
            "items-center",
            "justify-center",
            "font-medium",
            "text-xs",
            "leading-4",
            addClasses,
          )}
        >
          <>{label && size !== "indicator" && !loading && <span>{label}</span>}</>
        </div>
      )}
    </>
  );
};
