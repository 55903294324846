/** @format */
import type React from "react";

import { useFormState } from "react-final-form";
import { useRouteMatch } from "react-router-dom";

import * as Sentry from "@sentry/react";
import _ from "lodash";

import { Typography, useMultiPageForm } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import type { FormReponsesCollectionSchema } from "src/api";
import type { DynamicField } from "src/components/DynamicForm/types";

import { FormOutlinesService } from "src/api";
import { useStoreState } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";

interface RouteMatch {
  id: string;
  page?: string;
}

interface ValueDisplayProps {
  values: FormReponsesCollectionSchema["values"];
  valueKey: string;
}

interface BaseValueProps {
  value: string;
}

interface ReviewAndConfirmProps {
  fields_to_check: DynamicField[];
}

const BaseValue: React.FC<BaseValueProps> = ({ value }) => (
  <Typography variant="body1" component="body1" align="left" className="mb-3 mt-1 text-forest-100">
    {value}
  </Typography>
);

const ValueDisplay: React.FC<ValueDisplayProps> = ({ values, valueKey }) => {
  const storedPhoto = useStoreState((state) => state.profile?.profile?.pii?.license_photo);

  if (values) {
    if (valueKey === "preferred_pronouns") {
      if (values[valueKey] && Array.isArray(values[valueKey])) {
        const arrayToUpdate = values[valueKey] as string[];
        return (
          <BaseValue
            value={arrayToUpdate
              .map((pronoun: string) => _.lowerCase(pronoun).replace(/ /g, "/"))
              .join(", ")}
          />
        );
      }
    }
    if (valueKey === "which-race-describes-you") {
      if (values[valueKey] && Array.isArray(values[valueKey])) {
        const arrayToUpdate = values[valueKey] as string[];
        return (
          <BaseValue
            value={arrayToUpdate.map((race: string) => _.upperFirst(_.lowerCase(race))).join(", ")}
          />
        );
      }
    }
    if (valueKey === "license_type") {
      const stringToUpdate = values[valueKey] as string;
      return <BaseValue value={_.upperFirst(_.lowerCase(stringToUpdate))} />;
    }
    if (valueKey === "license_photo") {
      const img_src = values[valueKey] || storedPhoto;
      return (
        <img src={img_src as string} width={400} alt="Driver License photo" className="pt-5" />
      );
    }
    return <BaseValue value={_.upperFirst(values[valueKey] as string)} />;
  }
  return null;
};

const ReadOnlyVersion = ({ field, values }: { field: DynamicField; values: any }) => {
  return (
    <div className="mb-5">
      <Typography variant="body2" component="body2" align="left" className="mb-3 mt-1">
        {field.title}
        {field.title && field.required && <span className="text-error">*</span>}
      </Typography>
      <ValueDisplay values={values} valueKey={field.key} />
    </div>
  );
};

const ReviewAndConfirm: React.FC<ReviewAndConfirmProps> = (props) => {
  const formState = useFormState();
  const values = formState.values;

  const fieldsNotToShow = ["health-goals-field"];
  const sanitizePropsToCheck = props.fields_to_check.filter(
    (field: DynamicField) => !fieldsNotToShow.includes(field.key),
  );

  const { prevPage } = useMultiPageForm();

  const handleBackToEdit = () => {
    prevPage();
  };

  return (
    <div className="pt-3">
      {values && (
        <div className="mt-5">
          {sanitizePropsToCheck.map((field: DynamicField) => (
            <ReadOnlyVersion key={field.key} field={field} values={values} />
          ))}
        </div>
      )}
      <ButtonPdb variant="secondary" className="w-full" onClick={handleBackToEdit}>
        Back to edit
      </ButtonPdb>
    </div>
  );
};

export default ReviewAndConfirm;
