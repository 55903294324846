/** @format */

export type FormValidator<FormValues = Record<string, any>> = (
  values: FormValues,
) => null | ValidationError | Promise<ValidationError | null>;

export type ValidationError = BaseValidationError | string;

export interface BaseValidationError {
  type: string;
  description?: string;
}
export interface AgeEligiblityError extends BaseValidationError {
  type: "age";
  requiredAge: number;
}

export interface StateEligibilityError extends BaseValidationError {
  type: "state";
  state: string;
}

export interface WaitlistEligibilityError extends BaseValidationError {
  type: "waitlist";
  waitlistType: string;
  state?: string;
}

export interface GroupSessionUnavailableError extends BaseValidationError {
  type: "GroupSessionUnavailableError";
}

export interface RecurlyValidationError extends BaseValidationError {
  type: "RecurlyValidationError";
}

export interface IsSuicidal extends BaseValidationError {
  type: "suicidal";
}

export const isAgeEligibilityError = (error: any): error is AgeEligiblityError =>
  error.type === "age";
export const isStateEligibilityError = (error: any): error is StateEligibilityError =>
  error.type === "state";
export const isWaitlistEligibilityError = (error: any): error is WaitlistEligibilityError =>
  error.type === "waitlist";
export const isGroupSessionUnavailableError = (error: any): error is GroupSessionUnavailableError =>
  error.type === "GroupSessionUnavailableError";
export const isSuicidalError = (error: any): error is IsSuicidal => error.type === "suicidal";
export const isRecurlyValidationError = (error: any): error is RecurlyValidationError =>
  error.type === "RecurlyValidationError";
