/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IntakeIntakeAddressValidationRequestBodySchema } from '../models/IntakeIntakeAddressValidationRequestBodySchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ShippoService {

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static intakeAddressValidation({
        requestBody,
    }: {
        requestBody: IntakeIntakeAddressValidationRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/intake/address',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
