/** @format */

import React from "react";

import { PageWrapper } from "src/components/PageWrapper";
import { InputKitRegistrationCode } from "src/routes/RegisterKit/InputKitRegistrationCode";
import { RegisterKitSuccess } from "src/routes/RegisterKit/RegisterKitSuccess";

export type RegisterKitStage = "input-kit" | "success-page";

export const RegisterKitPage = () => {
  const [stage, setStage] = React.useState<RegisterKitStage>("input-kit");
  const [kit, setKit] = React.useState<string>("");
  return (
    <PageWrapper>
      <div className="my-8 flex flex-col max-w-xl mx-auto">
        {stage === "input-kit" && <InputKitRegistrationCode setStage={setStage} setKit={setKit} />}
        {stage === "success-page" && <RegisterKitSuccess setStage={setStage} kit={kit} />}
      </div>
    </PageWrapper>
  );
};
