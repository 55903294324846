/** @format */

import React from "react";

import * as Sentry from "@sentry/react";

import { Typography } from "@alphamedical/components";
import { Paper } from "src/v2/designSystem";

import type { ProviderPii } from "src/api";

import { UsersService } from "src/api";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useStoreDispatch, useStoreState } from "src/v2/models";

import { PageWrapper } from "./PageWrapper";

const AlphaPCPFields = () => {
  useTopBar({ title: "Alpha primary care provider", variant: "nested", showBackButton: true });
  const dispatch = useStoreDispatch();
  const pcp = useStoreState((state) =>
    state.profile.profile ? state.profile.profile.primary_provider : null,
  );
  const [, setAllProviderPii] = React.useState<ProviderPii[]>([]);

  React.useEffect(() => {
    if (!pcp || !pcp.pii) {
      dispatch.profile
        .fetchProfile({ include: ["primary_provider"] })
        .catch(Sentry.captureException);
    }
    UsersService.getAllProvidersPii({ userId: "me" })
      .then((res) => {
        setAllProviderPii(res);
      })
      .catch(Sentry.captureException);
  }, []);

  return (
    <PageWrapper cxPadding="px-0" fitToPageHeight>
      <Paper elevation={0} rounded={false} className="mx-auto h-screen w-full max-w-2xl px-6">
        <div className="bg-white w-full flex flex-col relative shadow mt-6">
          <div className="flex justify-around items-center py-4 px-6">
            <img
              alt="User Icon"
              src={
                pcp && pcp.pii
                  ? pcp.pii.sex === "male"
                    ? "/assets/male-provider-icon.svg"
                    : "/assets/doctor-icon.svg"
                  : "/assets/doctor-icon.svg"
              }
              className="mr-4"
            />
            <h1>
              {pcp ? (
                <Typography variant="body1">
                  {pcp.pii?.first_name}, {pcp.pii?.last_name}
                </Typography>
              ) : (
                <Typography variant="body1">
                  You have not been assigned an Alpha primary care provider yet.
                </Typography>
              )}
            </h1>
          </div>
        </div>
      </Paper>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(AlphaPCPFields);
