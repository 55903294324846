/** @format */

import React from "react";
import { generatePath, Redirect, useHistory, useRouteMatch } from "react-router-dom";

import classNames from "classnames";

import { ProgressBar, ButtonPdb as SystemDesignButton } from "src/v2/designSystem";

import type { DynamicFormPage } from "src/components/DynamicForm/types";

import { Analytics } from "src/analytics";
import { useAnalyticsDimension } from "src/analytics/hooks";
import { MaintenanceBanner } from "src/components";
import { CTACard } from "src/components/Cards/CTACard";
import { DynamicFormController } from "src/components/DynamicForm/DynamicFormController";
import { useURLNavigationController } from "src/components/DynamicForm/hooks";
import { GenericBanner } from "src/components/Maintenance/GenericBanner";
import * as PDB_ROUTES from "src/routes";
import { calculateProgress } from "src/utils/formUtils";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { SnackBar } from "src/v2/components";
import { Button } from "src/v2/components/Button";
import Div100vh from "src/v2/components/Div100vh";
import { Complete } from "src/v2/components/Page/Complete";
import CompletionPage from "src/v2/components/Page/Complete/CompletionPage";
import { TopBarNavigationPdb } from "src/v2/components/WithTopBarNavigation/TopBarNavigationPdb";
import { useStoreDispatch, useStoreState } from "src/v2/models";
import { CHECKIN_OUTLINE_KEYS } from "src/v2/models/checkins";
import { useCurrentUser } from "src/v2/models/profile";
import { useQuery } from "src/v2/utils/useQuery";

import { CompletionPageWithTopBar as RegistrationCompletionPage } from "./CompletionPage/CompletionPage";

interface RouteMatch {
  id: string;
  page?: string;
}

export const isSpecificTransitionScreen = (currentPage?: DynamicFormPage, variant?: string) => {
  return (
    currentPage &&
    currentPage.fields &&
    currentPage.fields.some(
      (field) =>
        field.description &&
        field.description["#ref"] &&
        field.description["#ref"].contentType === "transitionScreen" &&
        field.description["#ref"].variant === variant,
    )
  );
};

export const GenericForm = () => {
  const query = useQuery();
  const formType = query.get("form_type");
  const topBarProps = useTopBar({
    showBackButton: true,
    withLeftHandNav: false,
    showLogoutButton: formType === "registration",
  });

  const [completed, setCompleted] = React.useState(false);
  const routeMatch = useRouteMatch<RouteMatch>();
  const responsesId = parseInt(routeMatch.params.id);
  const history = useHistory();
  const user = useCurrentUser();
  const userRegistration = useStoreState((actions) => actions.profile.userRegistration);
  const { maintenanceData, upcomingMaintenance } = useStoreState((state) => state.maintenance);

  const { pageKey, setPageKey } = useURLNavigationController<RouteMatch>(
    `/form/${responsesId}`,
    (routeMatch) => routeMatch.params.page,
  );
  const [pageIndex, setPageIndex] = React.useState(1);
  const dispatch = useStoreDispatch();
  const currentOutline = useStoreState((state) => state.outlines.currentOutline);

  React.useEffect(() => {
    // When default Page updates we want to change the page key
    // This applys when a user uses the browser back and forth buttons
    if (routeMatch.params.page) {
      setPageKey(routeMatch.params.page);
      setPageIndex(
        currentOutline?.pages.findIndex((page) => page.key === routeMatch.params.page) || 0,
      );
    }
  }, [routeMatch.params.page]);

  React.useEffect(() => {
    if (currentOutline?.key) {
      Analytics.trackEvent({
        category: "Form",
        action: "Started",
        name: currentOutline?.key ? "outline-" + currentOutline.key : "Generic Form",
      });
    }
  }, [currentOutline?.key]);

  useAnalyticsDimension("FormPageKey", routeMatch.params.page);
  useAnalyticsDimension("FormPageIndex", pageIndex.toString());

  const MembershipCancellationComplete = () => {
    return (
      <CompletionPage heading=" " showMessagesLink={false}>
        <CTACard
          img="/assets/doctor-icon.svg"
          title="Thanks for your feedback!"
          description="Interested in something else? We can treat over 50 medical conditions online"
          cta={{
            bgColor: "violator",
            children: "See What We Treat",
            href: "/your-care/explore-visits",
            desktopWidth: "w-1/2",
          }}
          bgColor="bg-cloud-light-20"
        />
        <div className="flex justify-center w-full px-6 -mt-3">
          <Button
            href="/my-cases"
            bgColor={"bg-cloud-light-20"}
            borderColor="grey-3"
            hoverBgColor="grey-3"
            hrefStyles="w-full sm:w-1/2"
          >
            <div className="flex items-center justify-center text-primary">
              Continue to Your Dashboard
            </div>
          </Button>
        </div>
      </CompletionPage>
    );
  };

  const B2BHealthCheckInComplete = () => {
    return (
      <CompletionPage heading=" " showMessagesLink={false}>
        <h1 className={"text-xl font-bold mb-8"}>
          Thank you for completing your Comprehensive Health Check-In!
        </h1>
        <p className={"text-forest-120 mb-8"}>
          This helps your provider get to know you and personalize any treatment plans
        </p>
        <img className={"mb-8"} src={`/assets/green-circle-sand-background-checkmark.svg`} />
        <p className={"text-forest-120 mb-8"}>
          You can now access Alpha Medical's services provided by your employer
        </p>

        <SystemDesignButton
          className="w-full"
          variant="primary"
          onClick={() => history.push("/your-care/explore-visits")}
        >
          Explore Alpha Services
        </SystemDesignButton>
      </CompletionPage>
    );
  };

  const CHCIComplete = () => {
    // return blank page for CHCI, we push them to the hap once complete
    return <></>;
  };

  const renderProperCompletionPage = () => {
    switch (formType) {
      case "mh_cancellation":
      case "membership_cancellation":
        return MembershipCancellationComplete();
      case "b2b-hci":
        return B2BHealthCheckInComplete();
      case "chci":
        return CHCIComplete();
      case "registration": {
        if (!user.is_ubacare_user) {
          const newTopBarProps = { withLeftHandNav: true, showLogoutButton: false };
          return <RegistrationCompletionPage {...newTopBarProps} />;
        } else {
          return <Redirect to={PDB_ROUTES.UBACARE_SERVICE_URL} />;
        }
      }
      default:
        return <Complete title="" isSynchronous={false} />;
    }
  };

  // With the new logic redirecting users to the last answered page,
  // we must ensure that if the registration flow was completed from outside the generic form flow,
  // users are directed to the appropriate completion page - This will only executes for registration form
  React.useEffect(() => {
    if (userRegistration?.completed && formType === "registration") {
      setCompleted(true);
    }
  }, [userRegistration?.completed]);

  const isTextBubbleTransitionScreen =
    currentOutline &&
    isSpecificTransitionScreen(
      currentOutline.pages.find((page) => page.key == pageKey),
      "Text-Bubble",
    );

  return (
    <>
      {upcomingMaintenance && <MaintenanceBanner data={maintenanceData} />}
      {!completed && <GenericBanner />}
      <Div100vh
        data-track-content
        data-content-name={currentOutline?.key ? "outline-" + currentOutline.key : "GenericForm"}
        data-content-piece="Generic Form"
        className="flex flex-col w-full"
      >
        {!completed ? (
          <DynamicFormController
            defaultPageKey={pageKey}
            onPageChange={({ pageIdx, page }) => {
              setPageIndex(pageIdx);
              setPageKey(page.key);
            }}
            onFormComplete={async () => {
              if (userRegistration && !userRegistration.completed) {
                await dispatch.profile.fetchRegistrationOutlineData({ userId: user.id });
              }
              setCompleted(true);
              setPageKey("complete");
              Analytics.trackEvent({
                category: "Form",
                action: "Completed",
                name: currentOutline?.key ? "outline-" + currentOutline.key : "Generic Form",
              });
              if (formType === "chci") {
                dispatch.channels.fetchChannels();

                const searchParams = new URLSearchParams();
                searchParams.set("redirect", PDB_ROUTES.HEALTH_ACTION_PLAN);
                const url =
                  generatePath(PDB_ROUTES.CHECKIN_REDIRECT_PAGE, {
                    checkinKey: CHECKIN_OUTLINE_KEYS.COMPREHENSIVE_HEALTH_CHECK_IN,
                  }) + `?${searchParams.toString()}`;
                history.push(url);
              }
            }}
            responseCollectionId={responsesId}
          >
            {({ currentPageIdx, outline, prevPage, page }) => {
              return (
                <>
                  <TopBarNavigationPdb
                    {...topBarProps}
                    onBackCallback={prevPage}
                    showBackButton={currentPageIdx > 0}
                  />
                  <ProgressBar
                    progress={calculateProgress(outline, pageKey)}
                    segmented
                    withTopBar
                  />
                  <div
                    className={classNames(
                      "flex flex-1",
                      isTextBubbleTransitionScreen && "bg-cloud-light-20",
                    )}
                  >
                    <div
                      className={classNames(
                        "w-full flex flex-col flex-1 px-6 py-6 max-w-2xl m-auto h-full",
                      )}
                    >
                      {page}
                    </div>
                  </div>
                </>
              );
            }}
          </DynamicFormController>
        ) : (
          renderProperCompletionPage()
        )}
        <SnackBar />
      </Div100vh>
    </>
  );
};
