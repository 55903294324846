/** @format */

import React from "react";
import { useFormState } from "react-final-form";

import { some } from "lodash";

import { Popup } from "@alphamedical/components";

import { DynamicField } from "src/components/DynamicForm/Fields";
import { checkConditionalsOnGenericForms } from "src/components/DynamicForm/Processors/fieldConditionals";
import { isConsentPage } from "src/components/DynamicForm/types";
import { useGetMembership } from "src/utils/hooks";
import { useStoreState } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";
import { ResponsesValidationPopup } from "src/v2/routes/Consultation/ResponsesValidationPopup";

import type { FormPageProps } from "./types";

import { ConsentPage } from "./ConsentPage";
import { DynamicFormPageWrapper } from "./DynamicFormPageWrapper";

export const StandardFormPage = (props: FormPageProps) => {
  const { values } = useFormState();
  const user = useCurrentUser();
  const profile = useStoreState((state) => state.profile.profile);
  const { membership } = useGetMembership();
  const { validationError, clearValidationError, handleSubmit, goToPage } = props;
  const allowClose =
    some(validationError?.options, "allow_continue") && !validationError?.prevent_close;

  return (
    <DynamicFormPageWrapper {...props}>
      <div className="overflow-y-visible">
        {props.page.fields
          .filter((field) =>
            checkConditionalsOnGenericForms(field, values, user.pii, profile, membership),
          )
          .map((field) => (
            <DynamicField key={field.key} field={field} />
          ))}
        {validationError && clearValidationError && (
          <Popup
            headerText={validationError.header}
            isOpen={!!validationError}
            onRequestClose={allowClose ? clearValidationError : undefined}
          >
            <ResponsesValidationPopup
              validationError={validationError}
              goToPage={goToPage}
              onSubmit={handleSubmit}
              clearValidationError={clearValidationError}
              condition="health-checkin"
            />
          </Popup>
        )}
      </div>
    </DynamicFormPageWrapper>
  );
};

export const FormPage = ({ page, ...props }: FormPageProps) => {
  if (isConsentPage(page)) {
    return <ConsentPage page={page} {...props} />;
  }
  return <StandardFormPage page={page} {...props} />;
};
