/** @format */

import type { FieldInputProps } from "react-final-form";

import React from "react";
import { Field } from "react-final-form";

import classNames from "classnames";

import { FieldError } from "@alphamedical/components";

import { useStoreState } from "src/v2/models";

export const FieldHeight = (props: any) => {
  const feetRef = React.createRef<HTMLInputElement>();
  const inchesRef = React.createRef<HTMLInputElement>();
  const { field } = props;

  // Get patient's height from saved PHI values
  const patientHeight = useStoreState((state) => state.profile.profile?.phi?.height_in_inches);
  const patientFeet = patientHeight ? Math.floor(patientHeight / 12).toString() : "";
  const patientInches = patientHeight ? (patientHeight % 12).toString() : "";

  const [textColorFeet, setTextColorFeet] = React.useState("text-grey-100");
  const [textColorInches, setTextColorInches] = React.useState("text-grey-100");
  const [feetHasBeenModified, setFeetHasBeenModified] = React.useState(false);
  const [inchesHasBeenModified, setInchesHasBeenModified] = React.useState(false);

  const feetInputName = "feetInput";
  const inchesInputName = "inchesInput";

  // Extract feet and inches from string height value
  const extractValueParts = (value: string): { feet: string; inches: string } => {
    if (!value) return { feet: "", inches: "" };

    const matches = value.match(/^\s*(\d+|)ft (\d+|)in\s*$/);
    if (matches && matches.length !== 3) {
      // Unrecognized format
      return { feet: "", inches: "" };
    }
    const feet = (matches && matches[1]) || "";
    const inches = (matches && matches[2]) || "";

    return { feet, inches };
  };

  const handleChange = (input: any, _event: any) => {
    const feet = feetRef && (feetRef.current?.value || "");
    const inches = inchesRef && (inchesRef.current?.value || "");
    input.onChange(`${feet}ft ${inches}in`);
  };

  const _validate = (value: any) => {
    const { feet, inches } = extractValueParts(value);
    if (field.required && (!feet || !inches)) {
      return "Required";
    }
    return undefined;
  };

  // This should clear any pre populated value the first time the user attempts to enter a new value
  const handleOnKeyDown = (event: any, input: any) => {
    if (event.key === "Backspace" || event.key === "Delete" || !isNaN(+event.key)) {
      if (event.currentTarget.name === feetInputName && !feetHasBeenModified) {
        const inches = inchesRef && (inchesRef.current?.value || "");
        input.onChange(`ft ${inches}in`);
        setFeetHasBeenModified(true);
      }

      if (event.currentTarget.name === inchesInputName && !inchesHasBeenModified) {
        const feet = feetRef && (feetRef.current?.value || "");
        input.onChange(`${feet}ft in`);
        setInchesHasBeenModified(true);
      }
    }
  };

  return (
    <Field
      name={field.key}
      defaultValue={field.defaultValue || `${patientFeet}ft ${patientInches}in`}
      validate={_validate}
      {...props}
    >
      {({ input }: { input: FieldInputProps<string, HTMLElement> }) => {
        const { feet, inches } = extractValueParts(input.value);

        return (
          <>
            <div className="flex items-center flex-no-wrap" style={{ columnGap: 18 }}>
              <div className="flex items-center w-full mt-1 relative overflow-hidden">
                <input
                  type="number"
                  id={feetInputName}
                  name={feetInputName}
                  onChange={(event) => handleChange(input, event)}
                  className={classNames(
                    "w-full text-right pr-10 pl-4 py-3 border-2 border-grey-100 rounded focus:outline-none focus:border-primary",
                    `focus:${textColorFeet}`,
                  )}
                  onInput={() => setTextColorFeet("text-forest-120")}
                  value={feet}
                  ref={feetRef}
                  onBlur={(event) => input.onBlur(event)}
                  min={0}
                  max={7}
                  onKeyDown={(event) => handleOnKeyDown(event, input)}
                />
                <label className="absolute right-0 pr-4" htmlFor="feetInput">
                  ft.
                </label>
              </div>
              <div className="flex items-center w-full mt-1 relative overflow-hidden">
                <input
                  type="number"
                  id={inchesInputName}
                  name={inchesInputName}
                  onChange={(event) => handleChange(input, event)}
                  className={classNames(
                    "w-full text-right pr-10 pl-4 py-3 border-2 border-grey-100 rounded focus:outline-none focus:border-primary",
                    `focus:${textColorInches}`,
                  )}
                  onInput={() => setTextColorInches("text-forest-120")}
                  value={inches}
                  ref={inchesRef}
                  onBlur={(event) => input.onBlur(event)}
                  min={0}
                  max={11}
                  onKeyDown={(event) => handleOnKeyDown(event, input)}
                />
                <label className="absolute right-0 pr-4" htmlFor="inchesInput">
                  in.
                </label>
              </div>
            </div>
            <FieldError name={input.name} cxError="border-t border-red" />
          </>
        );
      }}
    </Field>
  );
};
