/** @format */

import type { FieldRenderProps } from "react-final-form";

import React from "react";
import { useForm } from "react-final-form";
import BarLoader from "react-spinners/BarLoader";

import { RecurlyProvider } from "@recurly/react-recurly";

import { FieldError, TextField } from "@alphamedical/components";

import type { BillingInfoModel } from "src/v2/models/billing-info";

import { HIPAABadges } from "src/components/Badges/ HIPAABadges";
import { getRecurlyPublicKey } from "src/helpers/http";
import { BillingInfoPopup } from "src/v2/components/Forms/BillingInfo/BillingInfoPopup";
import { useStoreActions, useStoreDispatch, useStoreState } from "src/v2/models";

import type { CustomFieldProps } from "./types";

/** A constant value used to set to the payment field when billing information is present. Used to indicate that
 * a) The user added or already has billing info set and b) has submitted/hit next on the payment page
 */
const PAYMENT_FIELD_VALUE = "payment-info-submitted";

export const BillingInfoField: React.FC<CustomFieldProps> = (props) => {
  const dispatch = useStoreDispatch();
  const form = useForm();
  const { billingInfo } = useStoreState((state) => state.billingInfo) as BillingInfoModel;
  const [loadingBillingInfo, setLoadingBillingInfo] = React.useState(true);

  const [showBillingInfoPopup, setShowBillingInfoPopup] = React.useState(false);
  const { addSnack } = useStoreActions((actions) => actions.snacks);

  React.useEffect(() => {
    setLoadingBillingInfo(true);
    dispatch.billingInfo.fetchBillingInfo().finally(() => setLoadingBillingInfo(false));
  }, []);

  return (
    <RecurlyProvider publicKey={getRecurlyPublicKey()}>
      <div className="uppercase text-sm">Payment</div>
      <div className="border-sand border-b-2 mt-3" />
      {loadingBillingInfo && <BarLoader color={"#A4B7B5"} loading={true} />}
      {!loadingBillingInfo && !!billingInfo && (
        <div className="mt-6 mb-12 flex items-center">
          <div className="text-14 flex-1">{billingInfo.card_type}</div>
          <div className="text-14 mr-4 sm:mr-8">
            <div>
              <span className="display-none sm:display-inline">**** ****</span> ****{" "}
              {billingInfo.last_four}
            </div>
          </div>
          <div className="text-14 mr-4 sm:mr-8">
            <div>
              {billingInfo.month} / {billingInfo.year}
            </div>
          </div>
          <div className="text-16 cursor-pointer">
            <img
              onClick={() => setShowBillingInfoPopup(true)}
              src="/assets/cancel.svg"
              alt="Close form for entering your billingInfo information"
            />
          </div>
        </div>
      )}
      {!loadingBillingInfo && !billingInfo && (
        <div className="flex cursor-pointer mt-4" onClick={() => setShowBillingInfoPopup(true)}>
          <img
            src="/assets/add.svg"
            alt="Open form for entering billing information"
            style={{ width: 19 }}
          />
          <p className="ml-3 font-bold">Add your payment method</p>
        </div>
      )}
      <FieldError name={props.field.key} />
      <TextField
        name={props.field.key}
        required={props.field.required}
        defaultValue={billingInfo && PAYMENT_FIELD_VALUE}
      >
        {({ input }: FieldRenderProps<string>) => (
          <input type="hidden" className="hidden" {...input} />
        )}
      </TextField>
      <HIPAABadges />
      {showBillingInfoPopup && (
        <BillingInfoPopup
          isOpen={showBillingInfoPopup}
          onBillingInfoUpdated={(_info) => {
            setShowBillingInfoPopup(false);
            form.change(props.field.key, PAYMENT_FIELD_VALUE);
          }}
          onBillingInfoFailure={(error_message = "") => {
            addSnack({
              type: "error",
              message:
                error_message ||
                "Something went wrong. Please try again or add a new payment method. ",
              id: "billing-info-failure",
              delay: 5,
            });
          }}
          onRequestClose={() => setShowBillingInfoPopup(false)}
        />
      )}
    </RecurlyProvider>
  );
};
