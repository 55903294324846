/** @format */

import type { RouteComponentProps } from "react-router-dom";

import React from "react";
import { withRouter } from "react-router-dom";

import type { FollowupItem } from "src/components/Popups/types";

import { getFollowupUrl } from "src/helpers/visitDetails";
import { AlphaModal } from "src/v2/components/AlphaModal";
import { Button } from "src/v2/components/Button";
import { useStoreDispatch } from "src/v2/models";

export interface RXRefillApprovalProps extends RouteComponentProps {
  item: FollowupItem;
  showPopup: boolean;
  setShowPopup: (arg: boolean) => void;
  key?: number;
  pii?: any;
  profile?: any;
}

const RXRefillApprovalPopup = ({
  item: { followup_type, order_id, condition_name, condition_key, medication_name },
  showPopup,
  setShowPopup,
  pii,
  profile,
  history,
}: RXRefillApprovalProps) => {
  const dispatch = useStoreDispatch();
  const isApproved = (approved: boolean) => {
    if (approved) {
      dispatch.profile.sendOrderApproval({ approved, order_id, followup_type });
      history.push(getFollowupUrl(condition_key || ""));
    } else {
      dispatch.profile.sendOrderApproval({
        approved,
        order_id,
        followup_type,
        condition_name,
        medication_name,
      });
    }
    setShowPopup(false);
    dispatch.profile.setHasSeenPopup(true);
  };

  return (
    <div>
      <AlphaModal
        isOpen={showPopup && !profile.hasSeenPopup}
        className="max-w-sm m-auto bg-white shadow-md"
        cxPadding="p-8"
        addPaddingForNavBar={true}
        onRequestClose={() => {
          setShowPopup(false);
          dispatch.profile.setHasSeenPopup(true);
        }}
      >
        <div className="flex flex-col text-16">
          <p className="font-semibold">
            Hi {(pii && pii.preferred_firstname) || (pii && pii.first_name)},
          </p>
          <p className="mb-4">
            We are reaching out because your prescription has not yet been renewed and you no longer
            have refills left on your {condition_name} prescription for {medication_name}.
          </p>
          <p className="mb-4">If you would like to renew your prescription, the process is easy.</p>

          <p className="mb-4">
            First, complete the renewal medical intake form by clicking the{" "}
            <strong>Start Renewal</strong> button below. You will be prompted to pay a small medical
            consult fee for our clinician to review your case and make sure the medication is still
            appropriate.
          </p>
          <p className="mb-6">
            Once this has been completed, your provider will review and reach out if they have any
            questions. Upon approval, your provider will renew your {condition_name} prescription
            for another year of refills.
          </p>
          <div className="flex justify-content">
            <Button
              extraClassNames="btn btn-violator w-1/2 mr-4 cursor-pointer"
              disabled={profile.postingApproval}
              onClick={() => isApproved(true)}
            >
              Start Renewal
            </Button>
            <Button
              extraClassNames={`
                                text-center
                                border-2 border-error-80
                                hover:text-white
                                items-center w-1/2 cursor-pointer`}
              disabled={profile.postingApproval}
              onClick={() => isApproved(false)}
              bgColor={"white"}
              hoverBgColor={"error-80"}
              textColor={"error-80"}
            >
              Reject Order
            </Button>
          </div>
          {profile.error && <p className="text-red">{profile.error}</p>}
        </div>
      </AlphaModal>
    </div>
  );
};

export default withRouter(RXRefillApprovalPopup);
