/** @format */

import React from "react";
import { useFormState } from "react-final-form";

import classNames from "classnames";

import InformationalCard, {
  InformationalCardTypeEnum,
} from "src/v2/components/InformationalCard/InformationalCard";
import { useFormNav } from "src/v2/routes/Consultation";

export interface VisitInfoProps {
  title: string;
  body: Array<string[] | string>;
  info?: {
    title?: string;
    body?: string;
  };
  submitButton: string;
  viewOnceKey?: string;
}

export const VisitInfo = (props: VisitInfoProps) => {
  const formNav = useFormNav();
  const formState = useFormState();
  const values = formState.values;

  return (
    <div className={"flex flex-col justify-between h-[80vh]"}>
      <article className="prose">
        <h1 className={"font-semibold text-xl mb-8"}>{props.title}</h1>
        {props.body.map((content, index) => {
          if (Array.isArray(content)) {
            return (
              <ol className="list-decimal list-inside my-4" key={`visit-info-${index}`}>
                {content.map((step, i) => (
                  <li className="my-2" key={i}>
                    {step}
                  </li>
                ))}
              </ol>
            );
          }

          const paragraphStyle = classNames("mb-4 text-base text-primary", {
            "font-semibold": index === 0,
          });
          return (
            <p className={paragraphStyle} key={`visit-info-${index}`}>
              {content}
            </p>
          );
        })}
      </article>

      {!!props.info && (
        <div className="mt-6 mb-8">
          <InformationalCard
            type={InformationalCardTypeEnum.outline}
            title={props.info.title}
            content={props.info.body}
            showIcon={true}
          />
        </div>
      )}

      <button
        onClick={() => {
          if (props.viewOnceKey) {
            // setting the viewOnceKey to true so this wont show again
            values[props.viewOnceKey] = "true";
          }
          formNav.nextClick(values);
        }}
        className={`btn btn-primary w-full mb-5`}
      >
        {props.submitButton}
      </button>
    </div>
  );
};
