/** @format */
import React from "react";
import { useHistory } from "react-router-dom";

import { ButtonPdb } from "src/v2/designSystem";

import type {
  PlanComparisonContent,
  PlanSection,
} from "src/v2/components/PlanComparison/PlanComparisonPage";

import { Analytics } from "src/analytics";
import { ContentfulService } from "src/api";
import { Loader } from "src/components/Loader";
import { useGetMembership } from "src/utils/hooks";
import { useMembershipPrice } from "src/utils/hooks/useMembershipPrice";
import { PlanComparisonPage } from "src/v2/components/PlanComparison/PlanComparisonPage";
import { useStoreState } from "src/v2/models";

// aligns with top level keys in membershipOptionsContent
export type scenarioSlug = "d2c" | "b2b" | "b2b_membershipIncluded";
// aligns with card keys in membershipOptionsContent
export type planSlug = "alpha" | "plus";
type flowSlug = "registration" | "account";

interface MembershipOptionsProps {
  flow: flowSlug;
}

export const primaryMembershipCardStyles = {
  border: "border-2 border-forest-dark",
  feature: "text-information",
  button: "primary",
};

export const secondaryMembershipCardStyles = {
  border: "border border-grey-100",
  feature: "text-grey-160",
  button: "secondary",
};

export const updatePricingOnCardContent = (cardsContent: PlanSection, prices: any) => {
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  cardsContent.plans.forEach((plan) => {
    const planPriceInfo = prices[plan.id];
    if (planPriceInfo.hasBenefit) {
      plan.priceStrikeThrough = `${planPriceInfo.originalPriceString} / month`;
      plan.pricePerMonthB2B = `${planPriceInfo.discountAmountString} / month`;
    } else {
      const pricePerDayCost = currencyFormatter.format(
        planPriceInfo.originalPriceInCents / 30 / 100,
      );
      plan.pricePerDay = [pricePerDayCost, "/ day"];
      plan.pricePerMonth = `${planPriceInfo.originalPriceString} / month`;
    }
  });
};

export const MembershipOptions = ({ flow = "registration" }: MembershipOptionsProps) => {
  const history = useHistory();
  const { membership, isFetchingMembership } = useGetMembership();

  // get user plan info
  const { profile } = useStoreState((state) => state.profile);
  const isB2BPatient = profile?.is_b2b_user;
  const possibleBenefit = profile?.employer_benefits?.find((plan) => plan.active);
  const [isLoading, setIsLoading] = React.useState(true);
  const [pageContent, setPageContent] = React.useState<PlanComparisonContent>();
  const prices = useMembershipPrice();

  // determine plans offered
  const plansOffered: planSlug[] = [];
  if (isB2BPatient) {
    possibleBenefit?.plan?.offer_basic && plansOffered.push("alpha");
    possibleBenefit?.plan?.offer_plus && plansOffered.push("plus");
  } else {
    plansOffered.push("alpha", "plus");
  }

  React.useEffect(() => {
    const fetchContentAsync = async () => {
      const { content } = await ContentfulService.getUnstructuredContentByKey({
        key: "plan-comparison-page",
      });
      if (content) {
        setPageContent(content);
        setIsLoading(false);
      } else history.push("/my-cases");
    };
    fetchContentAsync();
  }, []);

  // We check if the membership is 100% covered.
  // The convention is that a null value means it is.
  // Or if there is a single plan and the discount == the price
  const isMembershipIncluded = possibleBenefit?.plan?.membership_discount_in_cents === null;

  // determine scenario
  let scenario: scenarioSlug;
  let benefitPlanCode = undefined;
  if (isB2BPatient) {
    scenario = isMembershipIncluded ? "b2b_membershipIncluded" : "b2b";
    benefitPlanCode = possibleBenefit?.plan?.code;
  } else {
    scenario = "d2c";
  }

  const buttonsIfMembershipIncluded = <ButtonPdb className="w-full">Continue</ButtonPdb>;

  const handleOnClick = async (membershipType: "alpha" | "plus") => {
    // implement piwik event with flow and membershipType
    Analytics.trackContentInteraction("click", {
      name: "MembershipOptions",
      piece: flow,
      target: membershipType,
    });

    history.push(`/membership-signup?flow=${flow}&plan=${membershipType}`);
  };

  const variantProps = {
    d2c: {
      showExtraSections: true,
      showAlphaLogo: true,
      styles: {
        cardsBackground: "bg-sand-20",
        titleAlignment: "center",
        plus: primaryMembershipCardStyles,
        alpha: secondaryMembershipCardStyles,
      },
    },
    b2b: {
      showBenefitHeader: true,
      styles: {
        cardsBackground: "bg-white",
        titleAlignment: "left",
        alpha: primaryMembershipCardStyles,
        plus: primaryMembershipCardStyles,
      },
    },
    b2b_membershipIncluded: {
      buttonsIfMembershipIncluded: buttonsIfMembershipIncluded,
      styles: {
        cardsBackground: "bg-white",
        showAlphaLogo: false,
        showBenefitHeader: true,
        titleAlignment: "left",
        alpha: primaryMembershipCardStyles,
        plus: primaryMembershipCardStyles,
      },
    },
  };

  const cardsContent: PlanSection =
    pageContent?.plansSection[benefitPlanCode || ""] || pageContent?.plansSection[scenario];
  if (cardsContent && prices) {
    updatePricingOnCardContent(cardsContent, prices);
  }

  return isFetchingMembership || isLoading || !pageContent ? (
    <Loader show={true} />
  ) : (
    <>
      {!membership?.active && (
        <div className="flex flex-col pt-2">
          <PlanComparisonPage
            handleOnClick={handleOnClick}
            pageContent={pageContent as PlanComparisonContent}
            cardsContent={cardsContent}
            plansOffered={plansOffered}
            variantProps={variantProps[scenario]}
          />
        </div>
      )}
    </>
  );
};
