/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CanRescheduleSchema } from '../models/CanRescheduleSchema';
import type { SynchronousVisitRescheduleSynchronousVisitRequestBodySchema } from '../models/SynchronousVisitRescheduleSynchronousVisitRequestBodySchema';
import type { SynchronousVisitSchema } from '../models/SynchronousVisitSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SynchronousVisitService {

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getConsultAvailableTimeSlots({
        userId,
        consultId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        consultId: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/consults/{consult_id}/available_sync',
            path: {
                'user_id': userId,
                'consult_id': consultId,
            },
        });
    }

    /**
     * @returns CanRescheduleSchema
     * @throws ApiError
     */
    public static canRescheduleSyncVisit({
        userId,
        consultId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        consultId: string,
    }): CancelablePromise<CanRescheduleSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/consults/{consult_id}/can_reschedule',
            path: {
                'user_id': userId,
                'consult_id': consultId,
            },
        });
    }

    /**
     * @returns SynchronousVisitSchema
     * @throws ApiError
     */
    public static rescheduleSynchronousVisit({
        userId,
        consultId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        consultId: string,
        requestBody: SynchronousVisitRescheduleSynchronousVisitRequestBodySchema,
    }): CancelablePromise<SynchronousVisitSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/{user_id}/consults/{consult_id}/reschedule',
            path: {
                'user_id': userId,
                'consult_id': consultId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Retrieves the information about whether a user needs to complete a synchronous visit.
     * This does not check if the consult state has sync visits enabled.
     * That check is performed in the frontend.
     *
     * Args:
     * user_id (str): The ID of the user.
     *
     * Returns:
     * str: A JSON string representing whether the user needs a synchronous visit within the past year.
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getNeedsSyncVisit({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/needs_sync_visit',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns SynchronousVisitSchema
     * @throws ApiError
     */
    public static getSynchronousVisits({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<Array<SynchronousVisitSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/synchronous_visits',
            path: {
                'user_id': userId,
            },
        });
    }

}
