/** @format */

import type { FormRenderProps } from "react-final-form";

import React from "react";
import { Field } from "react-final-form";

import _, { keys } from "lodash";

import type { DynamicField } from "src/components/DynamicForm/types";
import type { Consultation } from "src/v2/types/consultation";
import type { Sku } from "src/v2/types/skus";

import { serializeDependency } from "src/utils";
import { formatPrice } from "src/v2/components/Payment/utils";
import { useStoreDispatch, useStoreState } from "src/v2/models";

interface Kit {
  is_recommended: boolean;
  sku_id: string;
}

interface OutlineProductSelectionPageProps {
  formProps: FormRenderProps;
  fields: DynamicField[];
  nextDisabled?: boolean;
  consult: Consultation;
  values: any;
  nextClick: () => any;
}

const OutlineProductSelectionPage = (props: OutlineProductSelectionPageProps) => {
  const {
    details: { sti_testing_kits },
  } = props.consult;
  const dispatch = useStoreDispatch();
  const [recommendedKits, setRecommendedKits] = React.useState(
    sti_testing_kits.filter((kit: Kit) => kit.is_recommended),
  );
  const hasRecommendedKits = recommendedKits.length > 0;
  const [restOfKits, setRestOfKits] = React.useState(
    sti_testing_kits.filter((kit: Kit) => !kit.is_recommended),
  );
  const [numberToShow, setNumberToShow] = React.useState<number>(1);
  const [subTotal, setSubTotal] = React.useState<number | undefined>();
  const productsSelected = _.get(props.formProps.values, "outline-product-selection", []);
  const { skus } = useStoreState((state) => state.skus);

  React.useEffect(() => {
    if (_.isEmpty(skus)) {
      dispatch.skus.fetchSkus({ condition: "sti-testing-kit" });
    }
    const costTotal = productsSelected.reduce((acc: number, sku_id: string) => {
      return !_.isEmpty(skus) && acc + skus[sku_id].cost_in_cents;
    }, 0);

    setSubTotal(costTotal);
  }, [serializeDependency(props.formProps.values), serializeDependency(keys(skus))]);

  const sortKits = (a: Kit, b: Kit) => {
    const aSku = skus && skus[a.sku_id];
    const bSku = skus && skus[b.sku_id];
    if (aSku && bSku) {
      return aSku.cost_in_cents - bSku.cost_in_cents;
    } else return 0;
  };

  React.useEffect(() => {
    if (skus) {
      setRecommendedKits(recommendedKits.sort(sortKits));
      setRestOfKits(restOfKits.sort(sortKits));
    }
  }, [
    serializeDependency(keys(skus)),
    serializeDependency(recommendedKits, ["sku_id"]),
    serializeDependency(restOfKits, ["sku_id"]),
  ]);

  return (
    <div className="flex flex-col text-primary">
      {!hasRecommendedKits && (
        <div className="bg-violator-20 text-xl p-5 font-bold my-6">
          Based on your responses, there is no specific STI Kit that we recommend.
          <br />
          <br />
          Please select from the list below if you would still like to purchase a test kit.
        </div>
      )}
      {hasRecommendedKits && (
        <div className="mt-6 text-xl font-bold">Recommended based on your responses</div>
      )}
      {recommendedKits.map(
        (kit: Kit, idx: number) =>
          !_.isEmpty(skus) &&
          kit.sku_id && (
            <ProductSelectionCard
              kit={skus[kit.sku_id]}
              fieldName={props.fields[0].key}
              key={`${kit.sku_id}-selection-${idx}`}
            />
          ),
      )}
      {hasRecommendedKits && <div className="mt-6 text-xl font-bold">Other kits we provide</div>}
      {restOfKits.map((kit: Kit, idx: number) => {
        return idx <= numberToShow
          ? !_.isEmpty(skus) && kit.sku_id && (
              <ProductSelectionCard
                kit={skus[kit.sku_id]}
                fieldName={props.fields[0].key}
                key={`${kit.sku_id}-selection-${idx}`}
              />
            )
          : null;
      })}
      {numberToShow < restOfKits.length && (
        <div
          style={{ color: "#6BA5A5" }}
          className="text-lg font-bold underline text-center mt-3 mb-3 cursor-pointer"
          onClick={() => setNumberToShow(restOfKits.length)}
        >
          View more STI Kits
        </div>
      )}
      <div className="border-b-2 border-grey width-full mt-10 mb-6" />
      <div className="flex justify-between font-bold text-2xl mb-6 md:mx-24">
        <div>Subtotal</div>
        {subTotal && <div>{formatPrice(subTotal, 0)}</div>}
      </div>
      <button
        className="btn btn-primary w-full mb-5"
        disabled={props.nextDisabled || productsSelected.length === 0}
      >
        Next
      </button>
    </div>
  );
};

interface ProductSelectionCardProps {
  fieldName: string;
  kit: Sku;
}

const ProductSelectionCard = ({ fieldName, kit }: ProductSelectionCardProps) => {
  return (
    kit && (
      <div>
        <Field name={fieldName} type="checkbox" value={kit.sku_id}>
          {({ input, meta }) => {
            return (
              <>
                <label className="block normal-case field flex items-center cursor-pointer">
                  <>
                    <input type="checkbox" id={input.name} {...input} className="display-none" />
                    <div
                      tabIndex={0}
                      title={kit.display_label}
                      onKeyPress={(e: any) => {
                        if (e.which === 32) {
                          e.target.parentElement.click();
                        }
                      }}
                      style={{ minHeight: "235px" }}
                      className={`
                                                        flex flex-col rounded
                                                        checkbox-field w-full
                                                        text-primary my-4 p-5
                                                        ${input.checked ? "bg-sky" : "bg-sky-60"}
                                                    `}
                    >
                      <div className="flex justify-between items-start">
                        <div className="text-3xl md:text-2xl font-bold">{kit.display_label}</div>
                        <div className="text-2xl font-bold">
                          {formatPrice(kit.cost_in_cents, 0)}
                        </div>
                      </div>
                      <p className="font-bold mb-0">infections tested</p>
                      <p className="m-0">{kit.ingredients}</p>
                      <div className="border-b border-primary width-full mt-16" />
                      <p className="font-bold mb-0">How sample is collected</p>
                      <p className="m-0">{kit.medication_sig}</p>
                    </div>
                  </>
                </label>
                {meta.error && meta.touched && <div className="text-red">{meta.error}</div>}
              </>
            );
          }}
        </Field>
      </div>
    )
  );
};

export default OutlineProductSelectionPage;
