/** @format */
import React from "react";
import { useForm, useFormState } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import ReactMarkdown from "react-markdown";

import { Typography, useMultiPageForm } from "@alphamedical/components";

import { DynamicComponent } from "src/components/DynamicComponent";
import { checkConditionalsOnGenericForms } from "src/components/DynamicForm/Processors/fieldConditionals";
import { isContentReference } from "src/components/DynamicForm/types";
import { isHTML } from "src/components/DynamicForm/utils";
import { useGetMembership } from "src/utils/hooks";
import SkipQuestion from "src/v2/components/SkipQuestion/SkipQuestion";
import { useStoreState } from "src/v2/models";

import type { FormPageProps } from "./types";

import { NextButton } from "./NextButton";
import { DynamicFormSubmitErrors } from "./SubmitErrors";

export const DynamicFormPageWrapper: React.FC<FormPageProps> = ({
  page,
  allowAutoSubmit,
  children,
}) => {
  const { hasNextPage, nextPage } = useMultiPageForm();
  const { initialValues = {}, values } = useFormState();
  const profile = useStoreState((state) => state.profile.profile);
  const { membership } = useGetMembership();

  // Track if the patient answers an auto submit answer
  const [isSubmitAutoSubmit, setIsSubmitAutoSubmit] = React.useState<boolean>(false);

  const form = useForm();
  // Get the list of fields that support auto submit. Currently only radio fields are supported
  const visibleFields = page.fields.filter((field) =>
    checkConditionalsOnGenericForms(field, values, profile?.pii, profile, membership),
  );
  const autoSubmitFields = page.fields.filter(
    (field) => field.type === "radio" && field.required === true,
  );
  const visibleAutoSubmitFields = visibleFields.filter(
    (field) => field.type === "radio" && field.required === true,
  );

  // Determine if auto submitting should be enabled for this page
  const enableAutoSubmit =
    allowAutoSubmit && // Check if the auto submit setting is set
    hasNextPage && // Only allow auto submit if this is not the last page
    visibleAutoSubmitFields.length === 1 && // Only allow auto submit if there is only one visible auto submittable field
    !values[visibleAutoSubmitFields[0].key] && // Prevents auto-submit if user selects an option that displays a hidden field and then chooses another option
    visibleFields.length < 2 && // Only allow auto submit if there is a single visible field.
    autoSubmitFields.every((field) => initialValues[field.key] == null); // Only allow auto submit if the fields are currently unanswered

  return (
    <div className="h-full flex flex-col">
      <div className="flex-1 flex-shrink mb-2">
        {page.title && (
          <div className="pt-6 pb-2">
            <Typography variant="subtitle1_as_h1">{page.title}</Typography>
          </div>
        )}
        {!!page.banner && (
          <>
            {isContentReference(page.banner) ? (
              <DynamicComponent content={page.banner} />
            ) : (
              <div className="flex items-center my-4 h-40 sm:h-64 w-full">
                <img className="max-h-full w-full" src={page.banner.imageUrl} alt="" />
              </div>
            )}
          </>
        )}
        {page.subtitle && (
          <div className="py-2">
            <Typography variant="h3">
              <ReactMarkdown>{page.subtitle}</ReactMarkdown>
            </Typography>
          </div>
        )}
        {page.description && (
          <DynamicComponent
            stringWrapperProps={{ className: "py-2", variant: "body1" }}
            renderStringAsHtml={isHTML(page.description)}
            content={page.description}
          />
        )}
        {children}
      </div>

      {!enableAutoSubmit && !page.customNext && !isSubmitAutoSubmit && <NextButton page={page} />}
      {page.allowSkip && <SkipQuestion goToPage={nextPage} nextClick={nextPage} skip={{}} />}

      {autoSubmitFields.map((field) => (
        <OnChange key={field.key} name={field.key}>
          {(value: any, previous: any) => {
            if (!previous && !!value && value !== previous) {
              if (field.type === "radio" && field.required) {
                const otherFieldOnPage = (page.fields || []).filter((pageField) => {
                  return (
                    pageField.key !== field.key &&
                    checkConditionalsOnGenericForms(pageField, values, profile?.pii, profile)
                  );
                });
                if (otherFieldOnPage.length === 0) {
                  setIsSubmitAutoSubmit(true);
                  form.submit();
                }
              }
            }
          }}
        </OnChange>
      ))}
      <DynamicFormSubmitErrors />
      {page.footer && (
        <DynamicComponent
          stringWrapperProps={{ variant: "caption" }}
          renderStringAsHtml={isHTML(page.footer)}
          content={page.footer}
        />
      )}
    </div>
  );
};
