/** @format */

import React from "react";
import { Field, FormSpy } from "react-final-form";

import _ from "lodash";

import type { GenericFieldCondition } from "src/components/DynamicForm/types";

interface ConditionProps {
  when: string;
  is: string | number | boolean | undefined;
  resetOnHide?: string[];
  children: JSX.Element | (() => JSX.Element);
}

export const Condition = ({ when, is, resetOnHide, children }: ConditionProps) => {
  return (
    // We are just using the FormSpy component here to get the form object. TODO: replace this with useForm hook
    // after updating the react-final-form module
    <FormSpy subscription={{ values: true, initialValues: true }}>
      {({ form, initialValues }) => (
        <Field name={when} subscription={{ value: true }}>
          {({ input }) => {
            const isValid = _.isArray(input.value)
              ? input.value.indexOf(is) !== -1
              : input.value === is;

            if (!isValid && !!resetOnHide) {
              resetOnHide.forEach((fieldName) => {
                form.change(fieldName, initialValues[fieldName]);
              });
            }
            return isValid ? (_.isFunction(children) ? children() : children) : null;
          }}
        </Field>
      )}
    </FormSpy>
  );
};

interface Conditional {
  key: string;
  value: string;
}

interface ConditionalWrapperProps {
  children: JSX.Element;
  resetOnHide?: string[];
  conditionals?: GenericFieldCondition[];
}

export const ConditionalWrapper = (props: ConditionalWrapperProps) => {
  const [first = undefined, ...rest] = props.conditionals || [];

  if (!first || first.key == null) {
    return props.children;
  }

  return (
    <Condition when={first.key} is={first.value} resetOnHide={props.resetOnHide}>
      {!!rest && rest.length > 0 ? (
        <ConditionalWrapper conditionals={rest as Conditional[]}>
          {props.children}
        </ConditionalWrapper>
      ) : (
        props.children
      )}
    </Condition>
  );
};

export const EXCLAMATION_MARK_ERROR = "fas fa-exclamation-circle";
