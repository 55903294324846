/**
 * @format
 */

import type { InitializationOptions } from "@braze/web-sdk";
import {
  automaticallyShowInAppMessages,
  changeUser,
  destroy,
  getUser,
  initialize,
  logCustomEvent,
  openSession,
  subscribeToContentCardsUpdates,
  toggleLogging,
  User,
  wipeData,
} from "@braze/web-sdk";

import config from "src/config";

// hacky type aliases due to lack of support for type-only imports/exports
export type IBrazeOptions = InitializationOptions;
export type IBrazeUser = User;
export type IBrazeGenders = (typeof User.Genders)[keyof typeof User.Genders];

export const Genders = User.Genders;

/**
 * Only export the symbols we need for better tree shaking.
 */
const _braze = {
  automaticallyShowInAppMessages,
  changeUser,
  destroy,
  getUser,
  initialize,
  logCustomEvent,
  openSession,
  subscribeToContentCardsUpdates,
  toggleLogging,
  wipeData,
};

/**
 * API key read from env
 */
export const apiKey = config.braze.apiKey;

export const defaultOptions: IBrazeOptions = {
  // SDK endpoint read from env
  baseUrl: config.braze.sdkEndpoint,
  // do not use Braze fontawsome, we have our own
  doNotLoadFontAwesome: true,
  // enable logging in non-production environments
  enableLogging: !config.isStaticBuild,
  // set app version (optional)
  // appVersion: "1.0"
};

/**
 * Helper type containing exported Braze symbols.
 */
export declare type Braze = typeof _braze;

export const braze: Braze = _braze;
