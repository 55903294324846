/** @format */

import React from "react";
import { useField } from "react-final-form";

import { ConditionCarousel } from "src/components/DynamicForm/Fields/CustomFields/WelcomeFlow/WelcomeConditionCarousel/ConditionCarousel";
import { WelcomePageContainerHealthCheckIn } from "src/components/DynamicForm/Fields/CustomFields/WelcomeFlow/WelcomePageCotainer/WelcomePageContainerHealthCheckIn";
import welcome from "src/content/welcome";

interface WelcomeConditionCarouselHealthCheckInProps {
  questionKey: string;
}

export const WelcomeConditionCarouselHealthCheckIn = ({
  questionKey,
}: WelcomeConditionCarouselHealthCheckInProps) => {
  const field = useField("preferred_firstname");

  return (
    <WelcomePageContainerHealthCheckIn
      backgroundColorClass={"overflow-x-hidden"}
      questionKey={questionKey}
      submitButtonTitle={welcome.screen3.submitButton}
    >
      <ConditionCarousel name={field.input.value} />
    </WelcomePageContainerHealthCheckIn>
  );
};
