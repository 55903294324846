/** @format */

import React from "react";

import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";

interface AssistantButtonProps {
  onClick: () => void;
  icon: IconProp;
  iconClassname?: string;
  label: string;
}

export const AssistantButton = (props: AssistantButtonProps) => (
  <div
    className="border rounded-lg border-grey-60 p-2 leading-half mr-4"
    role="button"
    onClick={props.onClick}
  >
    <FontAwesomeIcon icon={props.icon} className={cn(props.iconClassname, "mr-2")} />
    <div className="inline-block text-sm">{props.label}</div>
  </div>
);
