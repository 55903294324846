/** @format */
import React from "react";
import { useHistory } from "react-router-dom";

interface BackButtonProps {
  onClick?: (e: any) => void;
}

const BackButton = ({ onClick }: BackButtonProps) => {
  const history = useHistory();
  return (
    <button
      className="flex items-center"
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        } else {
          history.goBack();
        }
      }}
    >
      <span className="pr-2">
        <img
          src={`/assets/back-arrow.svg`}
          style={{ left: "0", marginTop: "2px" }}
          alt="Go to previous page"
        />
      </span>
      <span className="hidden">Back</span>
    </button>
  );
};

export default BackButton;
