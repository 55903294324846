/** @format */

import React, { useState } from "react";

import { getContent } from "src/content/src/v2/components/DynamicForm/Fields/CustomFields/membershipGate";
import { Button } from "src/v2/components/Button";
import { MembershipCardItem } from "src/v2/components/MonthlyMembership";
import {
  MembershipModal,
  MembershipPlusModal,
} from "src/v2/components/MonthlyMembership/MembershipModal";
import { MEMBERSHIP_PLANS } from "src/v2/constants";
import { useCurrentConsult } from "src/v2/routes/Consultation";

interface MembershipGateParams {
  planCode: string;
}

const MembershipGate = ({ planCode }: MembershipGateParams) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const consult = useCurrentConsult();
  const content = getContent(
    planCode,
    () => setModalOpen(true),
    consult.subscription.condition.name,
  );
  const isBase = planCode == MEMBERSHIP_PLANS.alpha;
  const isPlus = planCode == MEMBERSHIP_PLANS.plus;

  return (
    <>
      {isPlus && <MembershipPlusModal modalOpen={modalOpen} setModalOpen={setModalOpen} />}
      {isBase && <MembershipModal modalOpen={modalOpen} setModalOpen={setModalOpen} />}
      <img src="/assets/alpha-logo.svg" alt="Alpha Logo" className="object-top mx-auto" />
      <p className="font-bold text-center text-forest-100 text-sm uppercase tracking-bigwide">
        MEMBERSHIP {isPlus && "PLUS"}
      </p>
      {isPlus && (
        <img
          src="/assets/cheerfulWomen.png"
          alt="Cheerful women standing together"
          className="mx-auto"
        />
      )}
      <div className="font-medium text-lg mb-4 pt-4">{content.header}</div>
      <div style={{ marginBottom: "40px" }}>
        {content.firstBullets?.map((bulletPoint, key) => {
          return (
            <MembershipCardItem key={key} iconName={bulletPoint.iconPath} iconAlt={bulletPoint.alt}>
              {bulletPoint.content}
            </MembershipCardItem>
          );
        })}
      </div>
      <div className="bg-sand-40 p-4" style={{ marginBottom: "28px" }}>
        <p className="text-lg pb-4 font-medium">{content.bulletsSubHeader}</p>
        {content.secondBullets?.map((bulletPoint, key) => {
          return (
            <MembershipCardItem key={key} iconName={bulletPoint.iconPath} iconAlt={bulletPoint.alt}>
              {bulletPoint.content}
            </MembershipCardItem>
          );
        })}
      </div>
      {isBase && (
        <div className="text-sm text-grey-8 pt-6">
          *Alpha Basic Plan is required for this service.
        </div>
      )}
      <Button>Continue</Button>
    </>
  );
};

export const AlphaMembershipGate = () => {
  return <MembershipGate planCode={MEMBERSHIP_PLANS.alpha} />;
};

export const AlphaPlusMembershipGate = () => {
  return <MembershipGate planCode={MEMBERSHIP_PLANS.plus} />;
};
