/** @format */

import * as Sentry from "@sentry/react";
import _, { includes, snakeCase } from "lodash";

import type { MembershipSchema, ProfileSchema } from "src/api";
import type { FeatureFlag } from "src/config";
import type { Consultation } from "src/v2/types/consultation";

import {
  getPiiSpecificKey,
  hideIfActiveBenefits,
  hideIfAlreadyCapturedField,
  showPhotoUpload,
} from "src/components/DynamicForm/Processors/fieldConditionals";
import config from "src/config";
import { FUNNELS, MEMBERSHIP_PLANS } from "src/v2/constants";

import { calculateAgeRange, IsDateOfBirthValid } from "./_validators/condition";

/* First checks if this current consult is a return consult, if it is, we want to show all questions
    connected to these score checks. If it's not a return consult:
    Counts the number of questions answered from an outline and returns true or false
    if the count is equal to or greater than the minimum score. */
export const showDependingOnScore = (
  outlineValues: any,
  score_to_test: string,
  consult_type?: string,
  // minimum_score?: number
) => {
  if (consult_type && consult_type === "RETURN_CONSULT") return true;

  // else if (minimum_score) {
  //   if (score_to_test === "mood_disorder_score" && outlineValues.mood_disorder_score >= minimum_score) return true;
  //   else return false;
  // }

  if (typeof outlineValues[score_to_test] === "string") {
    try {
      outlineValues[score_to_test] = parseInt(outlineValues[score_to_test]);
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  const conditional1 = score_to_test === "anxiety_score" && outlineValues.anxiety_score > 9;
  const conditional2 =
    score_to_test === "mood_disorder_score" && outlineValues.mood_disorder_score >= 2;
  const conditional3 =
    score_to_test === "mood_disorder_positive" &&
    outlineValues.mood_disorder_score >= 7 &&
    outlineValues["multiple-mood-symptoms-over-same-period"] === "yes" &&
    ["moderate-problem", "serious-problem"].indexOf(outlineValues["scale-of-mood-problem"]) !== -1;
  const conditional4 =
    score_to_test === "AUDIT_10_score" &&
    outlineValues.sex === "male" &&
    outlineValues.AUDIT_10_score >= 4;
  const conditional5 =
    score_to_test === "AUDIT_10_score" &&
    outlineValues.sex === "female" &&
    outlineValues.AUDIT_10_score >= 3;
  const conditional6 = score_to_test === "phq_2_score" && outlineValues.phq_2_score >= 3;
  const conditional7 = score_to_test === "scoff_score" && outlineValues[score_to_test] >= 2;
  const conditional8 = score_to_test === "pre_crafft_score" && outlineValues[score_to_test] > 0;

  const conditionals = [
    conditional1,
    conditional2,
    conditional3,
    conditional4,
    conditional5,
    conditional6,
    conditional7,
    conditional8,
  ];

  return conditionals.some((conditional) => conditional);
};

// Add here any conditional method that relays on anything besides the values
// This is to support conditionals in page tracking where only values are passed
// We have to explicitly define this because some optional params won't work for the undefined case
const NOT_SUPPORTED_TRACKING_CONDITIONALS = [
  "hide:if:already:captured",
  "hide:if:photo:upload",
  "skip-blood-pressure",
  "showDependingOnScore",
  "hide:if:not:new:user",
  "hide:if:seen:welcome:experience",
  "show:if:monthly:membership:active",
  "hide:if:plus:member",
  "hide:if:member",
  "hide:if:not:member",
  "hide:if:active:benefits",
  "hide:if:ubacare",
];

export const checkConditionals = (
  values: any,
  conditional: any,
  consult?: Consultation,
  profile?: ProfileSchema,
  field?: any,
  membership?: MembershipSchema,
  hasWesternDentalBenefits?: boolean,
  userRequiresSyncVisit?: boolean,
) => {
  const piiSpecificKey = getPiiSpecificKey(conditional.key);
  const _values = values[conditional.key] || profile?.pii[piiSpecificKey];
  // checks if there's a method property on the "conditionals" in a field.
  if (conditional.method) {
    // If there is a method property on the "conditionals" field,
    // we do some validation and return true if we want the field to be hidden.
    if (conditional.method === "calculate:min:age") {
      const { dob } = values;
      return dob && !IsDateOfBirthValid(dob, conditional.value);
    } else if (conditional.method === "calculate:age") {
      const not = conditional.not ? conditional.not : false;
      const { dob } = values;
      if (not) {
        return dob && !calculateAgeRange(dob, conditional.minAge, conditional.maxAge);
      }
      return dob && calculateAgeRange(dob, conditional.minAge, conditional.maxAge);
    } else if (consult && conditional.method === "skip-blood-pressure") {
      const bCWithoutEstrogen = [
        "sku_42",
        "sku_8",
        "sku_3_8_cont",
        "sku_3_8_3mo",
        "sku_3_8_3mo_v1",
        "sku_50",
      ];
      return !consult.recomended_skus.some((sku) => bCWithoutEstrogen.indexOf(sku.sku_id) !== -1);
    } else if (conditional.method === "showDependingOnScore") {
      return showDependingOnScore(
        values,
        conditional.score_to_test,
        consult && consult.consult_type,
        // conditional.minimum_score
      );
    } else if (conditional.method === "hide:if:already:captured" && profile) {
      return !hideIfAlreadyCapturedField(field, profile.pii);
    } else if (conditional.method === "hide:if:photo:upload" && profile) {
      // Conditional to show or hide photo upload if any of the photo values are missing
      return showPhotoUpload(field, profile.pii);
    } else if (conditional.method === "hide:if:not:new:user") {
      if (conditional.not && conditional.not === true) {
        return !profile?.is_new_patient;
      }
      return profile?.is_new_patient;
    } else if (conditional.method === "show:if:feature:flag") {
      const featureFlag = conditional.featureFlag as FeatureFlag | null;
      const not = conditional.not ? conditional.not : false;
      if (
        featureFlag &&
        featureFlag in config.featureFlags &&
        config.featureFlags[featureFlag] === true
      ) {
        return !not;
      }
      return not;
    } else if (conditional.method === "hide:if:seen:welcome:experience") {
      if (
        profile?.meta &&
        profile.meta["has_seen_welcome_experience"] &&
        profile.meta["has_seen_welcome_experience"] === true
      ) {
        return false;
      }
      return true;
    } else if (conditional.method === "show:if:weight:loss:funnel") {
      return profile?.meta?.funnel === FUNNELS.WEIGHT_LOSS;
    } else if (conditional.method === "show:if:stdgrant") {
      const stdGrantEntities = ["friends_for_all"];
      return includes(stdGrantEntities, snakeCase(consult?.ubacare_visit?.covered_entity_name));
    } else if (conditional.method == "hide:if:plus:member") {
      return !membership || !membership.active || membership.plan_code != MEMBERSHIP_PLANS.plus;
    } else if (conditional.method == "hide:if:member") {
      // returning false will hide the field
      return conditional.not ? membership && membership.active : !(membership && membership.active);
    } else if (conditional.method == "hide:if:not:member") {
      return conditional.not ? !(membership && membership.active) : membership && membership.active;
    } else if (conditional.method == "hide:if:not:sync:consult") {
      return !!userRequiresSyncVisit;
    } else if (conditional.method === "hide:if:active:benefits") {
      // returning true if field should be shown means inverse of hideIfActiveBenefits
      return !hideIfActiveBenefits(conditional, profile);
    } else if (conditional.method === "hide:if:ubacare") {
      return !profile?.is_ubacare_user;
    } else if (conditional.method === "state:sync:visits:enabled") {
      const consultState =
        values["consult-state"] ||
        values["state"] ||
        values["current-state-field"] ||
        values["followup-state"] ||
        values["peds-consult-state"];
      const isSyncState = config.featureFlags.enabledSynchronousStates.includes(consultState);
      const isUbacareVisit = consult?.subscription?.condition?.key === "ubacare-program-visit";
      // all ubacare visits are eligible for sync visits
      return isUbacareVisit || isSyncState;
    } else if (conditional.method === "show:if:prepopulated:answers") {
      const notCondition = conditional.not ? conditional.not : false;
      const shouldPrepopulate = consult?.prev_answers && consult?.prev_answers.length > 0;
      if ((shouldPrepopulate && !notCondition) || (!shouldPrepopulate && notCondition)) {
        return true;
      }
    } else return false;
  } else if (conditional.not) {
    // recommended for a radio/select
    if (_values && _values.value && _values.value instanceof Array) {
      return _values.value.indexOf(conditional.value) < 0;
    } else if (_values instanceof Array) {
      return _values.indexOf(conditional.value) < 0;
    } else if (typeof _values === "string") {
      return _values !== conditional.value;
    } else if (_values == null) {
      return true;
    }
    return false;
    // return values[conditional.key] !== conditional.value;
  } else if (conditional.anyOf) {
    if (_values && _values.value && _values.value instanceof Array) {
      return (
        conditional.anyOf.filter((value: any) => _values.value.indexOf(value) !== -1).length > 0
      );
    } else if (_values instanceof Array) {
      return conditional.anyOf.filter((value: any) => _values.indexOf(value) !== -1).length > 0;
    }
    return conditional.anyOf.indexOf(values[conditional.key]) !== -1;
  } else if (conditional.noneOf) {
    if (_values == null) {
      return false;
    } else if (_values.value && _values.value instanceof Array) {
      return _.intersection(conditional.noneOf, _values.value).length === 0;
    } else if (_values instanceof Array) {
      return _.intersection(conditional.noneOf, _values).length === 0;
    }
    return conditional.noneOf.indexOf(_values) === -1;
  } else if (conditional.conditionals) {
    return conditional.type === "or"
      ? conditional.conditionals.some((c: any) =>
          checkConditionals(
            values,
            c,
            consult,
            profile,
            field,
            membership,
            hasWesternDentalBenefits,
            userRequiresSyncVisit,
          ),
        )
      : conditional.conditionals.every((c: any) =>
          checkConditionals(
            values,
            c,
            consult,
            profile,
            field,
            membership,
            hasWesternDentalBenefits,
            userRequiresSyncVisit,
          ),
        );
  } else {
    if (_values && _values.value && _values.value instanceof Array) {
      return _values.value.indexOf(conditional.value) >= 0;
    } else if (_values instanceof Array) {
      return _values.indexOf(conditional.value) >= 0;
    }
    if (conditional.value === null) {
      return !(conditional.key in values);
    }
    return values[conditional.key] === conditional.value;
  }
};

export const getFieldToCheck = (field: any) => {
  if (
    !field.conditionals &&
    field.type === "info" &&
    typeof field.description === "object" &&
    field.description["#ref"]
  ) {
    return field.description["#ref"];
  } else {
    return field;
  }
};

export const getFieldCanBeShown = (
  values: any,
  field: any = {},
  consult?: Consultation,
  profile?: ProfileSchema,
  membership?: MembershipSchema,
  hasWesternDentalBenefits?: boolean,
  userRequiresSyncVisit?: boolean,
) => {
  // ALPHA-1738 Additional logic in case a custom component nests conditionals within
  // In the CMS, we hold the components in a different way for these types of custom components
  // Checking to be sure that we don't actually have any current conditionals on the field for backwards compatibility
  const fieldToCheck = getFieldToCheck(field);

  let show = true;
  if (fieldToCheck.conditionals) {
    show = fieldToCheck.conditionals.every((conditional: any) => {
      return checkConditionals(
        values,
        conditional,
        consult,
        profile,
        fieldToCheck,
        membership,
        hasWesternDentalBenefits,
        userRequiresSyncVisit,
      );
    });
  }
  return show;
};

export const getFieldsCanBeShown = (
  fields: any,
  answers: any,
  consult?: Consultation,
  profile?: ProfileSchema,
  membership?: MembershipSchema,
  hasWesternDentalBenefits?: boolean,
  userRequiresSyncVisit?: boolean,
) => {
  return fields.filter((field: any) =>
    getFieldCanBeShown(
      answers,
      field,
      consult,
      profile,
      membership,
      hasWesternDentalBenefits,
      userRequiresSyncVisit,
    ),
  );
};

export const isValidTrackingConditional = (conditional: any) => {
  const isValidMethod =
    !conditional.method || !NOT_SUPPORTED_TRACKING_CONDITIONALS.includes(conditional.method);
  return (
    isValidMethod &&
    conditional.conditionals &&
    conditional.conditionals.every(isValidTrackingConditional)
  );
};
