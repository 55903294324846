/** @format */

import React from "react";

import type { Consultation } from "src/v2/types/consultation";

import { Button } from "src/v2/components/Button";

const renderMessage = (props: EligibilityMedNotCoveredProps) => {
  switch (props.invalid.name) {
    case "xulane":
      return {
        title: "Notice",
        body: `
          We're sorry, our partner pharmacy does not currently accept insurance for Xulane.<br/><br/>Please select one of the options below before continuing.<br/><br/>The Xulane cash price can be as low as $140/month, or you may use your insurance at an external pharmacy.<br/><br/>
        `,
        mainCTA: "Pay with Cash Instead",
        secondaryCTA: "Send Rx to my Local Pharmacy",
      };
    case "nuvaring":
      return {
        title: "Notice",
        body: `
          We're sorry, our partner pharmacy does not currently accept insurance for Nuvaring.<br/><br/>Please select one of the options below before continuing.<br/><br/>The Nuvaring cash price can be as low as $170/month, or you may use your insurance at an external pharmacy.<br/><br/>
        `,
        mainCTA: "Pay with Cash Instead",
        secondaryCTA: "Send Rx to my Local Pharmacy",
      };
    default:
      return {
        title: "Notice",
        body: "One of the options you selected is not a valid response based on your previous answers. Please select a different option.",
      };
  }
};

interface EligibilityMedNotCoveredProps {
  closeModal: any;
  condition: string;
  invalid: any;
  saveValues: any;
  moveForward: any;
  values: any;
  consult: Consultation;
}

export const EligibilityMedNotCovered = (props: EligibilityMedNotCoveredProps) => {
  const CTAHandler = async (props: EligibilityMedNotCoveredProps, cta?: string) => {
    if (["xulane", "nuvaring"].indexOf(props.invalid.name) !== -1) {
      if (cta === "mainCTA") {
        props.values["payment_preference"] = "cash";
        props.values["med-not-covered-boolean"] = "internal";
        props.values["preferred_pharmacy"] = "alpha";
        await props.saveValues(props.values);
        props.closeModal();
        props.moveForward();
      } else {
        props.values["med-not-covered-boolean"] = "external";
        props.values["preferred_pharmacy"] = "external";
        await props.saveValues(props.values);
        props.closeModal();
        props.moveForward();
      }
    }
  };
  return (
    <div className="flex flex-col">
      <h1 style={{ top: "-18px", width: "315px" }} className="text-3xl md:relative font-500">
        {renderMessage(props).title}
      </h1>
      <div
        style={{ top: "-14px" }}
        className={`w-full md:relative border ${"border-b-grey-500"} mb-2`}
      />
      <div
        className={`text-lg mt-4`}
        dangerouslySetInnerHTML={{ __html: renderMessage(props).body }}
      />
      {renderMessage(props).mainCTA && (
        <Button onClick={() => CTAHandler(props, "mainCTA")}>
          <span>{renderMessage(props).mainCTA}</span>
        </Button>
      )}
      {renderMessage(props).secondaryCTA && (
        <Button onClick={() => CTAHandler(props, "secondaryCTA")}>
          <span>{renderMessage(props).secondaryCTA}</span>
        </Button>
      )}
    </div>
  );
};
