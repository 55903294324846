/** @format */

import React from "react";

import type { AxiosError } from "axios";

import type { LegacyAssessmentPlanSchema } from "src/api";

import { ConsultsService } from "src/api";
import { Loader } from "src/components/Loader";
import { Button } from "src/v2/components/Button";

interface VisitSummaryProps {
  consultId: number;
}

export const VisitSummary = (props: VisitSummaryProps) => {
  const [summaries, setSummaries] = React.useState<LegacyAssessmentPlanSchema[] | undefined>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<AxiosError | undefined>();

  React.useEffect(() => {
    setLoading(true);
    ConsultsService.getConsultPvs({ userId: "me", consultId: props.consultId })
      .then((res) => {
        setSummaries(res);
        setLoading(false);
      })
      .catch(setError);
  }, []);

  const summary = summaries != null && summaries[0];

  return (
    <Loader show={loading} cxHeight="h-64">
      {!!error && <p>We're sorry, we were unable to load your visit summary</p>}
      {!error && summary ? (
        // TODO: Only get first visit summary for now
        <>
          <div dangerouslySetInnerHTML={{ __html: summary.content }} />
          {summary.download_url && (
            <div className="w-full flex justify-center mb-4">
              <Button target="_blank" href={summary.download_url}>
                Download
              </Button>
            </div>
          )}
        </>
      ) : (
        <p>We're sorry, we were unable to find an Post-Visit Summary for this visit.</p>
      )}
    </Loader>
  );
};
