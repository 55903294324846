/** @format */

import React from "react";
import { Redirect } from "react-router-dom";

class App extends React.Component {
  render() {
    return <Redirect to="/my-cases" />;
  }
}

export default App;
