/** @format */

import React from "react";

import type { BillingInfoSchema } from "src/api";

import { UsersService } from "src/api";
import { getRecurlyPublicKey } from "src/helpers/http";
import { useAuthenticatedUser } from "src/utils/hooks";
import { AlphaModal } from "src/v2/components/AlphaModal";
import { RecurlyField, RecurlyFieldSelect } from "src/v2/components/Payment/RecurlyForm";
import { STATES } from "src/v2/constants";
export interface ErrorProps {
  cvv?: string;
  number?: string;
  month?: string;
  year?: string;
  firstName?: string;
  lastName?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
}

export const recurlyFieldStyles = () => {
  return {
    all: {
      style: {
        fontFamily: "ARS Maquette Pro, Montserrat, sans-serif",
        fontColor: "#1c4a46",
        padding: "0px",
      },
    },
    month: { style: { placeholder: { content: "MM", color: "#779290", opacity: "0.8" } } },
    year: { style: { placeholder: { content: "YYYY", color: "#779290", opacity: "0.8" } } },
    number: {
      style: { placeholder: { content: "Credit card number", color: "#779290", opacity: "0.8" } },
    },
    cvv: { style: { placeholder: { content: "CVV", color: "#779290", opacity: "0.8" } } },
  };
};

interface BillingInfoFormProps {
  isOpen: boolean;
  onRequestClose: any;
  edit?: { billing: boolean; shipping: boolean };
  saving?: boolean;
  errorsFromProps?: ErrorProps;
  setSaving?: (boolean: boolean) => void;
  setBilling?: (billing: BillingInfoSchema) => void;
  submitBillingInfo?: (event: any) => void;
}

const BillingInfoForm = ({
  isOpen,
  onRequestClose,
  edit,
  saving,
  setSaving,
  setBilling,
  submitBillingInfo,
  errorsFromProps,
}: BillingInfoFormProps) => {
  const recurly = (window as any).recurly;
  const inputElement = React.useRef<HTMLButtonElement>(null);
  const iframe = React.useRef(null);
  const [storage, setStorage] = React.useState<any>();
  const [errors, setErrors] = React.useState<ErrorProps>();
  //  use authenticated user
  const [{ pii }] = useAuthenticatedUser();
  recurly.on("change", () => setErrors(undefined));

  const localBillingInfoSubmit = (event: any) => {
    event.preventDefault();
    event.persist();
    setSaving && setSaving(true);
    recurly.token(event.target, async (_error: any, _token: any) => {
      if (!_error) {
        await UsersService.saveUserBillingInfo1({ requestBody: { token: _token.id } })
          .then((_billing) => {
            setBilling && setBilling(_billing);
            setSaving && setSaving(false);
            setErrors(undefined);
            if (inputElement.current) {
              inputElement.current.click();
            }
          })
          .catch((_err) => {
            // tslint:disable-next-line
            console.log("payment error");
            setErrors(
              (_err.response && _err.response.data && _err.response.data.errors) || undefined,
            );
            setSaving && setSaving(false);
          });
      } else {
        if (_error) {
          const newErrors: ErrorProps = {};
          if (_error.fields.indexOf("cvv") >= 0) {
            newErrors.cvv = _error.message;
          }
          if (_error.fields.indexOf("number") >= 0) {
            newErrors.number = _error.message;
          }
          if (_error.fields.indexOf("month") >= 0) {
            newErrors.month = _error.message;
          }
          if (_error.fields.indexOf("year") >= 0) {
            newErrors.year = _error.message;
          }
          if (_error.fields.indexOf("first_name") >= 0) {
            newErrors.firstName = _error.message;
          }
          if (_error.fields.indexOf("last_name") >= 0) {
            newErrors.lastName = _error.message;
          }
          if (_error.fields.indexOf("address1") >= 0) {
            newErrors.address1 = _error.message;
          }
          if (_error.fields.indexOf("address2") >= 0) {
            newErrors.address2 = _error.message;
          }
          if (_error.fields.indexOf("city") >= 0) {
            newErrors.city = _error.message;
          }
          if (_error.fields.indexOf("state") >= 0) {
            newErrors.state = _error.message;
          }
          if (_error.fields.indexOf("postal_code") >= 0) {
            newErrors.postalCode = _error.message;
          }
          setErrors(newErrors);
          setSaving && setSaving(false);
        }
      }
    });
  };

  const closeModal = () =>
    // This logic makes this component re-usable. We're either editing billingInfo or adding it.
    edit ? onRequestClose({ ...edit, billing: !edit.billing }) : onRequestClose(isOpen);

  React.useEffect(() => {
    setStorage(iframe.current);
    if (iframe.current) {
      (window as any).recurly.configure({
        publicKey: getRecurlyPublicKey(),
        fields: recurlyFieldStyles(),
      });
    }
    // tslint:disable-next-line: align
  }, [storage]);

  // Is shipping info completely filled out?
  const validateShippingInfo = {
    first_name: pii.first_name,
    last_name: pii.last_name,
    address: pii.address.address,
    city: pii.address.city,
    state: pii.address.state,
    zipcode: pii.address.zipcode,
  };

  const hasAllShippingFields = Object.values(validateShippingInfo).every(
    (addressItem) => typeof addressItem === "string",
  );

  const [showBillingInfoFields, setShowbillingInfoFields] = React.useState(
    hasAllShippingFields ? true : false,
  );

  return (
    <div>
      <AlphaModal
        className={`max-w-sm m-auto`}
        isOpen={isOpen}
        bgColor="white"
        closeIconColor="primary-40"
        addPaddingForNavBar={true}
        cxPadding="p-0"
      >
        <div
          style={{
            backgroundColor: "#FFF9F7",
            maxWidth: "inherit",
            width: "inherit",
            minHeight: "70px",
          }}
          className="flex z-20 sticky inset-x-0 top-0 items-center justify-between px-8"
        >
          <p className="text-18 font-semibold m-0">{edit ? "Edit" : "Add"} Payment Method</p>
          <img
            src="/assets/close-light.svg"
            onClick={closeModal}
            className="cursor-pointer"
            alt="Close form for entering your billing information"
          />
        </div>

        <form onSubmit={submitBillingInfo ? submitBillingInfo : localBillingInfoSubmit}>
          <div className="p-8">
            <input type="hidden" data-recurly="token" name="recurly-token" />
            <input type="hidden" data-recurly="country" id="country" name="country" value="US" />
            <div className="flex items-start">
              <div>
                <div style={{ width: "100%" }} data-recurly="number" ref={iframe} />
                {((errorsFromProps && errorsFromProps.number) || (errors && errors.number)) && (
                  <div className="m-2 text-red">
                    Invalid Number -- please double check your input.
                  </div>
                )}
                <div className="flex">
                  <div className="flex-1 mr-3">
                    <div data-recurly="month" />
                    {((errorsFromProps && errorsFromProps.month) || (errors && errors.month)) && (
                      <div className="m-2 text-red">Invalid Month</div>
                    )}
                  </div>
                  <div className="flex-1 mx-3">
                    <div data-recurly="year" />
                    {((errorsFromProps && errorsFromProps.year) || (errors && errors.year)) && (
                      <div className="m-2 text-red">Invalid Year</div>
                    )}
                  </div>
                  <div className="flex-1 ml-3">
                    <div data-recurly="cvv" />
                    {
                      <div className="m-2 text-red">
                        {errorsFromProps ? errorsFromProps.cvv : errors && errors.cvv}
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>

            {hasAllShippingFields && (
              <label className="my-6 flex items-center">
                <input
                  className="display-none"
                  type="checkbox"
                  onChange={() => setShowbillingInfoFields(!showBillingInfoFields)}
                  checked={showBillingInfoFields}
                />
                <div
                  style={{
                    height: "27px",
                    width: "27px",
                    backgroundColor: "#8fdcdc",
                    border: showBillingInfoFields ? "" : "2px solid #dae1e7",
                  }}
                  className={`
                            flex items-center justify-center
                            cursor-pointer hover:bg-sky-80
                            ${showBillingInfoFields ? "fas fa-check" : ""}
                            `}
                />
                <span className="ml-5 normal-case text-16">Billing address same as shipping</span>
              </label>
            )}
            {(showBillingInfoFields && (
              <>
                <input
                  type="hidden"
                  data-recurly="first_name"
                  id="first_name"
                  name="first-name"
                  value={pii.first_name}
                />
                <input
                  type="hidden"
                  data-recurly="last_name"
                  id="last_name"
                  name="last-name"
                  value={pii.last_name}
                />
                <input
                  type="hidden"
                  data-recurly="address1"
                  id="address1"
                  name="address1"
                  value={pii.address.address}
                />
                <input
                  type="hidden"
                  data-recurly="city"
                  id="city"
                  name="city"
                  value={pii.address.city}
                />
                <input
                  type="hidden"
                  data-recurly="state"
                  id="state"
                  name="state"
                  value={pii.address.state}
                />
                <input
                  type="hidden"
                  data-recurly="postal_code"
                  id="postal_code"
                  name="postal_code"
                  value={pii.address.zipcode}
                />
              </>
            )) || (
              <div className="flex">
                <div className="w-full">
                  {
                    <RecurlyField
                      className="mb-10"
                      name="first_name"
                      label="First name"
                      error={
                        errorsFromProps ? errorsFromProps.firstName : errors && errors.firstName
                      }
                    />
                  }
                  <RecurlyField
                    className="mb-10"
                    name="last_name"
                    label="Last name"
                    error={errorsFromProps ? errorsFromProps.lastName : errors && errors.lastName}
                  />
                  <RecurlyField
                    className="mb-10"
                    name="address1"
                    label="Address line 1"
                    error={errorsFromProps ? errorsFromProps.address1 : errors && errors.address1}
                  />
                  <RecurlyField className="mb-8" name="address2" label="Address line 2" />
                  <RecurlyField
                    className="flex-1 mb-10"
                    name="city"
                    label="City"
                    error={errorsFromProps ? errorsFromProps.city : errors && errors.city}
                  />
                  <div className="flex justify-between">
                    <RecurlyFieldSelect
                      className="w-1/3 rounded-none"
                      name="state"
                      label="State"
                      options={STATES.map((state) => ({ value: state, label: state }))}
                      error={errorsFromProps ? errorsFromProps.state : errors && errors.state}
                    />
                    <RecurlyField
                      className="w-1/2 ml-3 mt-4"
                      name="postal_code"
                      label="ZIP Code"
                      error={
                        errorsFromProps ? errorsFromProps.postalCode : errors && errors.postalCode
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className="sticky left-0 right-0 bottom-0 flex items-center justify-center"
            style={{
              backgroundColor: "#FFF9F7",
              maxWidth: "inherit",
              width: "inherit",
              minHeight: "87px",
            }}
          >
            <div className="flex flex-col justify-center items-center w-full mx-8 pt-4">
              <button type="submit" className="btn btn-violator w-full" disabled={saving}>
                {!saving ? `${edit ? "Update Billing Info " : "Add Billing Info"}` : "verifying..."}
              </button>

              <div className="text-red mt-5">
                {!saving &&
                  errors &&
                  Object.keys(errors)
                    .filter((errorKey: string) => ["cvv", "month"].indexOf(errorKey) === -1)
                    .map((errorKey: string) => {
                      const message = errors[errorKey].replace(new RegExp(errorKey, "g"), "");
                      return (
                        message && (
                          <div className="red" key={`error-${errorKey}`}>
                            {message}
                          </div>
                        )
                      );
                    })}
              </div>
            </div>
          </div>
        </form>
      </AlphaModal>
    </div>
  );
};

export default BillingInfoForm;
