/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DiagnosisRecommendationSchema } from '../models/DiagnosisRecommendationSchema';
import type { GenericMedicationSchema } from '../models/GenericMedicationSchema';
import type { MedicationAddSelfReportedMedicationRequestBodySchema } from '../models/MedicationAddSelfReportedMedicationRequestBodySchema';
import type { MedicationDiscontinuePatientMedicationRequestBodySchema } from '../models/MedicationDiscontinuePatientMedicationRequestBodySchema';
import type { MedicationVersionSchema } from '../models/MedicationVersionSchema';
import type { SelfReportedMedicationSchema } from '../models/SelfReportedMedicationSchema';
import type { SigRecommendationSchema } from '../models/SigRecommendationSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MedicationsService {

    /**
     * @returns DiagnosisRecommendationSchema
     * @throws ApiError
     */
    public static getDiagnosisRecommendations({
        conditionKey,
    }: {
        /**
         * The condition key to use for filtering recommended diagnosis
         */
        conditionKey?: string,
    }): CancelablePromise<Array<DiagnosisRecommendationSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/medications/recommendations/diagnosis',
            query: {
                'condition_key': conditionKey,
            },
        });
    }

    /**
     * @returns SigRecommendationSchema
     * @throws ApiError
     */
    public static getMedicationSigRecommendations({
        skuKey,
    }: {
        /**
         * The sku key to use for filtering recommended sigs
         */
        skuKey?: string,
    }): CancelablePromise<SigRecommendationSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/medications/recommendations/sig',
            query: {
                'sku_key': skuKey,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static discontinuePatientMedication({
        medicationIds,
        requestBody,
    }: {
        medicationIds: string,
        requestBody: MedicationDiscontinuePatientMedicationRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/medications/{medication_ids}/discontinue',
            path: {
                'medication_ids': medicationIds,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns MedicationVersionSchema
     * @throws ApiError
     */
    public static getMedicationVersions({
        medicationId,
    }: {
        medicationId: number,
    }): CancelablePromise<Array<MedicationVersionSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{medication_id}/versions',
            path: {
                'medication_id': medicationId,
            },
        });
    }

    /**
     * @returns GenericMedicationSchema
     * @throws ApiError
     */
    public static getMedications({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<Array<GenericMedicationSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/medications',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns SelfReportedMedicationSchema
     * @throws ApiError
     */
    public static inactivateSelfReportedMedication({
        userId,
        medicationId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        medicationId: string,
    }): CancelablePromise<SelfReportedMedicationSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/{user_id}/medications/{medication_id}',
            path: {
                'user_id': userId,
                'medication_id': medicationId,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static addSelfReportedMedication({
        userId,
        condition,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        condition: string,
        requestBody: MedicationAddSelfReportedMedicationRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/{user_id}/{condition}/self_reported_medications',
            path: {
                'user_id': userId,
                'condition': condition,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
