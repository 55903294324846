/** @format */

import React from "react";

import { FieldError, SimpleForm } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import { NpsService } from "src/api";
import { useStoreDispatch } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";

import CircularButtonList from "./CircularButtonList";

interface NpsResponseFormValues {
  score: string;
}

interface NpsFormProps {
  setShowConfirmation: () => void;
}

const NpsForm = ({ setShowConfirmation }: NpsFormProps) => {
  const user = useCurrentUser();
  const dispatch = useStoreDispatch();

  const validateNpsForm = (values: NpsResponseFormValues) => {
    const errors: { score?: string } = {};
    if (!values.score) {
      errors["score"] = "Required";
    }
    return errors;
  };

  const handleSubmit = (values: NpsResponseFormValues) => {
    NpsService.saveNpsScore({
      userId: user?.id,
      requestBody: {
        score: values.score,
      },
    }).finally(() => {
      setShowConfirmation();
    });
  };

  return (
    <SimpleForm onSubmit={handleSubmit} validate={validateNpsForm} hideDebugInfo={true}>
      {({ form, values, errors, submitting }) => {
        return (
          <>
            <div className="p-4">
              <div className="mb-8 sm:w-4/6 lg:w-full mx-auto text-forest-darker font-medium text-lg text-center">
                How likely are you to recommend Alpha to a friend or colleague?
              </div>
              <div className="lg:max-w-xl md:max-w-xs max-w-xs mx-auto">
                <div className="flex items-end justify-items-end mb-4">
                  <div className="flex-1 text-forest-darker">0 - Not likely at all</div>
                  <div className="flex-1 text-right text-forest-darker">10 - Very likely</div>
                </div>
                <CircularButtonList
                  onClick={(value: string) => form.change("score", value)}
                  selectedScore={values.score}
                />
                <FieldError name="score" cxError={errors?.score} />
              </div>
            </div>

            <div className="bg-grey-lightest flex items-end place-items-end justify-items-end">
              <div className="flex-1 w-4" />
              <ButtonPdb disabled={submitting} className="m-4 flex-initial" type="submit">
                Submit
              </ButtonPdb>
            </div>
          </>
        );
      }}
    </SimpleForm>
  );
};

export default NpsForm;
