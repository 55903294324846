/** @format */
import React from "react";
import { useHistory } from "react-router-dom";

import { ButtonPdb } from "src/v2/designSystem";

interface LookupSecondaryButtonProps {
  content: string;
  redirect?: string;
  clearFormError: () => void;
}

export const LookupSecondaryButton = ({
  clearFormError,
  content,
  redirect = "",
}: LookupSecondaryButtonProps) => {
  const history = useHistory();

  const hanbleSecondaryButtonClick = (path: string) => {
    clearFormError();
    history.push(path);
  };

  return (
    <>
      <ButtonPdb
        variant="secondary"
        className="w-full"
        onClick={() => hanbleSecondaryButtonClick(redirect)}
        type="button"
      >
        {content}
      </ButtonPdb>
    </>
  );
};
