/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FileSchema } from '../models/FileSchema';
import type { FilesPostFilesRequestBodySchema } from '../models/FilesPostFilesRequestBodySchema';
import type { FilesRotatePhotoRequestBodySchema } from '../models/FilesRotatePhotoRequestBodySchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FilesService {

    /**
     * Upload file to chat channel.
     * file: a file upload
     * channel_id: integer - channel this file was uploaded to
     * @returns FileSchema
     * @throws ApiError
     */
    public static postFiles({
        requestBody,
    }: {
        requestBody: FilesPostFilesRequestBodySchema,
    }): CancelablePromise<FileSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/files',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Input
     * file: a file upload
     * This should only be used for deleting files that are uploaded to a message thread and then not sent.
     * For deleting files that have already been sent or uploaded elsewhere, please soft delete.
     * @returns any No response format defined
     * @throws ApiError
     */
    public static deleteFile({
        fileId,
    }: {
        fileId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/files/delete/{file_id}',
            path: {
                'file_id': fileId,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getFiles({
        fileId,
    }: {
        fileId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/files/{file_id}',
            path: {
                'file_id': fileId,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getInternalFiles({
        fileId,
        userId,
    }: {
        fileId: number,
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/files_internal/{file_id}/{user_id}',
            path: {
                'file_id': fileId,
                'user_id': userId,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getInstanceFile({
        path,
    }: {
        path: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/instance/{path}',
            path: {
                'path': path,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static rotatePhoto({
        requestBody,
    }: {
        requestBody: FilesRotatePhotoRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/photo/rotate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
