/** @format */

export interface PeriodItemType {
  imgSrc?: string;
  imgAlt?: string;
  formKey: string;
  title?: string;
  subTitle?: string;
}

export const PAD_ITEMS: PeriodItemType[] = [
  {
    imgSrc: "/assets/padLight.svg",
    imgAlt: "Pad light bleeding",
    formKey: "pad_light",
  },
  {
    imgSrc: "/assets/padMedium.svg",
    imgAlt: "Pad medium bleeding",
    formKey: "pad_medium",
  },
  {
    imgSrc: "/assets/padHeavy.svg",
    imgAlt: "Pad heavy bleeding",
    formKey: "pad_heavy",
  },
];

export const TAMPON_ITEMS: PeriodItemType[] = [
  {
    imgSrc: "/assets/tamponLight.svg",
    imgAlt: "Tampon light bleeding",
    formKey: "tampon_light",
  },
  {
    imgSrc: "/assets/tamponMedium.svg",
    imgAlt: "Tampon medium bleeding",
    formKey: "tampon_medium",
  },
  {
    imgSrc: "/assets/tamponHeavy.svg",
    imgAlt: "Tampon heavy bleeding",
    formKey: "tampon_heavy",
  },
];

export const CLOT_ITEMS: PeriodItemType[] = [
  {
    title: "Small clot",
    subTitle: "size of a dime",
    formKey: "small_clot",
  },
  {
    title: "Large clot",
    subTitle: "size of a quarter",
    formKey: "large_clot",
  },
];

export const FLOW_ITEMS: PeriodItemType[] = [
  {
    title: "Menstrual accident",
    subTitle: "instance of flooding",
    formKey: "flooding_accidents",
  },
];
