/** @format */

import type { match } from "react-router-dom";

import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import type { AxiosError } from "axios";

import { SimpleForm, Typography } from "@alphamedical/components";
import { SingleSelect } from "src/v2/designSystem";

import type { ErrorResponse } from "src/v2/models/api_types";

import { OrganizationsService } from "src/api/services/OrganizationsService";
import { BenefitHolderRegistrationContent } from "src/content/v2/routes/BenefitHolderRegistration";
import { IconTextChevron, Line } from "src/v2/components";
import { AddressLookupForm } from "src/v2/components/Forms/BenefitRegistration/AddressLookupForm";
import { PhoneLookupForm } from "src/v2/components/Forms/BenefitRegistration/PhoneLookupForm";
import { RegistrationForm } from "src/v2/components/Forms/BenefitRegistration/RegistrationForm";
import { ArrowSvg } from "src/v2/components/Svgs/ArrowSvg";
import { useStoreDispatch } from "src/v2/models";

interface MatchParams {
  step: "register" | "phone" | "address" | "options";
}

export type EligibilityLookUpType = "address" | "phone_number";

const BenefitHolderRegistration = ({ match }: { match: match<MatchParams> }) => {
  const step = match.params.step;
  const history = useHistory();
  const dispatch = useStoreDispatch();
  const [formError, setError] = React.useState<string>("");
  const [isDependent, setDependent] = React.useState<boolean>(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dependent = queryParams.get("isDependent");

  React.useEffect(() => {
    if (dependent === "true") setDependent(true);
  }, [dependent]);

  const onSubmitLookupForm = (
    values: any,
    lookupType: EligibilityLookUpType,
    successUrl: string,
  ) => {
    return OrganizationsService.organizationEligibilityLookup({
      lookupType: lookupType,
      phoneNumber: values?.phone_number,
      address1: values?.address1,
      address2: values?.address2,
      city: values?.city,
      state: values?.state,
      zipcode: values?.zipcode,
      birthDate: values?.date_of_birth,
      isDependent: isDependent,
    })
      .then((_response: any) => {
        dispatch.auth.setEligibilityLookupValues({
          phone_number: values?.phone_number,
          address_1: values?.address1,
          address_2: values?.address2,
          city: values?.city,
          state: values?.state,
          zipcode: values?.zipcode,
          lookup_type: lookupType,
          eligibleIndividualFound: true,
        });
        setError("");
        history.push(successUrl);
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        setError(error.response?.data.description || "Something went wrong");
      });
  };

  const formFooter = (
    <div
      className="flex-col mx-6 text-base leading-6 text-center pb-8 pt-4"
      style={{ borderTop: "1px solid #DFE1E1" }}
    >
      <p className="text-grey-160">Need help? Email us at </p>
      <a className="font-medium text-cornflower-100" href="mailto:sponsored@helloalpha.com">
        sponsored@helloalpha.com
      </a>
    </div>
  );

  return (
    <div className="flex flex-col min-h-screen">
      <div className="justify-center py-4 bg-sand">
        <div className="sm:w-3/4 md:w-1/2 w-full mx-auto">
          <div className="lg:w-3/4 w-full mx-auto grid grid-cols-4 items-center">
            <div
              className="cols-start-1 cols-end-1 ml-6 cursor-pointer"
              onClick={() => {
                setError("");
                history.goBack();
              }}
            >
              <ArrowSvg direction={"left"} />
            </div>
            <img
              src="/assets/alpha-logo.svg"
              alt="Alpha's Brand Logo"
              className="w-20 col-span-2 place-self-center"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col sm:w-3/4 md:w-1/2 w-full mx-auto">
        <div className="flex flex-1 flex-col lg:w-3/4 w-full mt-6 mx-auto space-y-12">
          <div className="flex flex-1 mx-6">
            {step === "phone" ? (
              <PhoneLookupForm
                lookupType="phone_number"
                onSubmitLookupForm={onSubmitLookupForm}
                formError={formError}
                successUrl="/sponsored-benefits/register"
                clearFormError={() => setError("")}
              />
            ) : step === "address" ? (
              <AddressLookupForm
                lookupType="address"
                onSubmitLookupForm={onSubmitLookupForm}
                formError={formError}
                successUrl="/sponsored-benefits/register"
                clearFormError={() => setError("")}
              />
            ) : step === "register" ? (
              <RegistrationForm />
            ) : step == "options" ? (
              <>
                <div className="mb-8">
                  <Typography variant="h3" className="mb-8">
                    {BenefitHolderRegistrationContent.optionsPage?.title}
                  </Typography>
                  {BenefitHolderRegistrationContent.optionsPage?.iconTextChevronOptions.map(
                    ({ text, icon, iconAlt, planAction }) => {
                      return (
                        <div key={planAction}>
                          <Link to={`/sponsored-benefits/${planAction}`}>
                            <IconTextChevron
                              planAction={planAction}
                              text={text}
                              icon={icon}
                              iconAlt={iconAlt}
                            />
                            <Line />
                          </Link>
                        </div>
                      );
                    },
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="mb-8">
                  <Typography variant="h3" className="mb-6">
                    {BenefitHolderRegistrationContent.dependentStatus.title}
                  </Typography>
                  {/* eslint-disable @typescript-eslint/no-empty-function */}
                  <SimpleForm onSubmit={() => {}} hideDebugInfo>
                    {() => {
                      const selectOption = (option: any) => {
                        history.push(`/sponsored-benefits${option}`);
                      };

                      const selectOptions =
                        BenefitHolderRegistrationContent.dependentStatus.statuses.map(
                          (status, i) => {
                            return {
                              primaryText: status.title,
                              secondaryText: status.subtitle,
                              optionKey: status.path,
                              onSelection: selectOption,
                            };
                          },
                        );
                      return (
                        <>
                          <SingleSelect
                            name="select"
                            options={selectOptions}
                            className="pl-0 list-none"
                          ></SingleSelect>
                        </>
                      );
                    }}
                  </SimpleForm>
                </div>
              </>
            )}
          </div>
          {formFooter}
        </div>
      </div>
    </div>
  );
};

export default BenefitHolderRegistration;
