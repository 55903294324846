/** @format */

import React from "react";
import { useFormState } from "react-final-form";

import classnames from "classnames";
import _ from "lodash";

import type { Consultation } from "src/v2/types/consultation";

import { ProvidedTreatmentsPopup } from "src/components/Popups/ProvidedTreatmentsPopup";
import { useHasEmployerBenefits } from "src/utils/hooks/useHasEmployerBenefits";
import { AlphaModal } from "src/v2/components/AlphaModal";
import { Button } from "src/v2/components/Button";
import {
  CONDITIONS_MIGRATED_FROM_VISIT_TO_CONSULT,
  providedTreatmentsByConditionAndMedication,
  REQUIRED_MEMBERSHIP_CONDITIONS,
  REQUIRED_PLUS_MEMBERSHIP_CONDITIONS,
} from "src/v2/constants";
import { useStoreDispatch } from "src/v2/models";
import { useCurrentConsult } from "src/v2/routes/Consultation";

export const getBannerImageUrl = (
  consult: Consultation,
  hasWesternDentalBenefits?: boolean,
): string | undefined => {
  const method = _.get(consult, "answers.bc-medication-type-selection.value", "pill");
  const conditionKey = consult.subscription.condition.key;
  const wantsMedication =
    _.get(consult, "answers.choose-mental-health-medications.value") !== "no-i-dont-need-any";

  if (
    consult.consult_type === "VISIT" ||
    CONDITIONS_MIGRATED_FROM_VISIT_TO_CONSULT.indexOf(conditionKey) !== -1
  ) {
    return "/assets/visit-icon.svg";
  } else if (conditionKey === "hairloss") {
    return "/assets/hairloss-payment.svg";
  } else if (conditionKey === "skincare") {
    return "/assets/skincare-icon.svg";
  } else if (conditionKey === "eyelashes") {
    return "/assets/latisse-icon.svg";
  } else if (conditionKey === "birthcontrol") {
    if (method === "patch") {
      return "/assets/xulane-patch.svg";
    } else if (method === "ring") {
      return "/assets/birth-control-ring.svg";
    } else {
      return "/assets/birth-control-icon.svg";
    }
  } else if (conditionKey === "migraine") {
    if (consult.recomended_skus.find((s) => s.sku_id.includes("INJ"))) {
      return "/assets/injector.svg";
    } else {
      return "/assets/migraine-icon.svg";
    }
  } else if (conditionKey === "herpes") {
    return "/assets/genital-herpes-icon.svg";
  } else if (conditionKey === "cold-sore") {
    return "/assets/cold-sore.png";
  } else if (conditionKey === "asthma") {
    return "/assets/asthma.svg";
  } else if (conditionKey === "dermatitis") {
    return "/assets/eczema.svg";
  } else if (conditionKey === "mental-health") {
    if (!hasWesternDentalBenefits) {
      return "/assets/mental-health-gear.svg";
    }
    if (wantsMedication) {
      return "/assets/mental-health.png";
    } else {
      return "/assets/alpha-logo-forest.svg";
    }
  } else if (conditionKey === "sti-testing-kit") {
    return "/assets/cotton-swab.svg";
  } else if (conditionKey === "hay-fever") {
    return "/assets/hay-fever-payment.svg";
  } else if (conditionKey === "acid-reflux") {
    return "/assets/acid-reflux-payment-icon.svg";
  } else if (conditionKey === "endometriosis") {
    return "/assets/endometriosis-icon-pay-review.svg";
  } else if (conditionKey === "hypothyroidism") {
    return "/assets/hypothyroidism-icon.svg";
  } else if (conditionKey === "hyperlipidemia") {
    return "/assets/high-cholesterol-payment-icon.svg";
  } else if (conditionKey === "smoking-cessation") {
    return "/assets/smoking-cessation-payment-icon.svg";
  } else if (conditionKey === "nail-infection") {
    return "/assets/nail-infection-payment.svg";
  } else if (conditionKey === "menopause") {
    return "/assets/menopause-payment.svg";
  } else if (conditionKey === "nutrition") {
    return "/assets/nutrition-icon.svg";
  } else if (conditionKey === "weight-loss") {
    return "/assets/weight-loss-payment.svg";
  } else if (conditionKey === "covid-19-treatment") {
    return "/assets/covid-19-treatment-icon.svg";
  } else if (conditionKey === "pcos") {
    return "/assets/pcos.svg";
  } else if (conditionKey === "emergency-contraceptives") {
    return "/assets/emergency-contraceptives.svg";
  } else if (conditionKey === "abnormal-uterine-bleeding") {
    return "/assets/abnormal-uterine-bleeding.svg";
  }

  return "/assets/alpha-logo-forest.svg";
};

const paymentBannerTitle = (consult: Consultation, hasWesternDentalBenefits: boolean) => {
  const conditionKey = consult.subscription.condition.key;
  const conditionName = consult.subscription.condition.name;

  if (["preconception-counseling", "birthcontrol"].indexOf(conditionKey) !== -1) {
    return conditionName;
  } else if (conditionKey === "sti-testing-kit") {
    return `STI Health Treatment`;
  } else if (conditionKey === "hyperlipidemia") {
    return "High Cholesterol Treatment";
  } else if (conditionKey === "eyelashes") {
    return "Lashes Treatment";
  } else if (
    !hasWesternDentalBenefits &&
    REQUIRED_MEMBERSHIP_CONDITIONS.indexOf(conditionKey) != -1
  ) {
    return "Access to mental health, weight loss, diabetes services, and treatment for over 100 medical conditions";
  }
  return `${conditionName} Treatment`;
};

interface PaymentBannerProps {
  imageUrl?: string;
  title?: string;
  cta?: {
    label: string;
    onClick: () => void;
  };
}

export const PaymentBanner = (props: PaymentBannerProps) => {
  return (
    <div className="mt-8 h-64 w-full" style={{ backgroundColor: "#F7F7F7" }}>
      <div className="flex flex-col justify-center items-center text-center h-full py-1">
        <img src={props.imageUrl || "/assets/alpha-logo-forest.svg"} alt="" role="presentation" />
        <div className="flex flex-col">
          {props.title && <h3 className="text-xl text-primary mt-4 uppercase">{props.title}</h3>}
          {props.cta && (
            <Button extraClassNames="focus:outline-none" onClick={props.cta.onClick}>
              {props.cta.label}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export const TreatmentPaymentBanner = () => {
  const consult = useCurrentConsult();
  const dispatch = useStoreDispatch();
  const hasWesternDentalBenefits = useHasEmployerBenefits("western-dental");
  const { values } = useFormState();
  const bannerUrl = getBannerImageUrl(consult, hasWesternDentalBenefits);
  const [showPopup, setShowPopup] = React.useState<boolean>(false);
  const conditionKey = consult.subscription.condition.key;
  const membershipRequiredCondition =
    !hasWesternDentalBenefits && REQUIRED_MEMBERSHIP_CONDITIONS.indexOf(conditionKey) !== -1;
  const paymentPreference = values.payment_preference;
  const doesntWantMedication =
    conditionKey === "mental-health" &&
    _.get(consult, "answers.choose-mental-health-medications.value") === "no-i-dont-need-any";

  const welldyneSelected = values["preferred_pharmacy"] == "alpha";

  // We must provide good faith estimates for medication pricing for users who
  // have selected welldyne as their preferred pharmacy
  const allowTreatmentOptionPopup =
    welldyneSelected &&
    !doesntWantMedication &&
    !!providedTreatmentsByConditionAndMedication[conditionKey];

  React.useEffect(() => {
    dispatch.profile.fetchEmployerBenefits();
  }, []);

  return (
    <>
      <div className={classnames("p-6 w-full", "bg-grey-lightest")}>
        {membershipRequiredCondition && (
          <>
            <img
              src="/assets/alpha-logo.svg"
              alt="Alpha Logo"
              className="object-top mx-auto mb-2"
            />
            <p className="mb-4 font-bold text-center text-forest-100 text-sm uppercase tracking-bigwide">
              MEMBERSHIP
              {REQUIRED_PLUS_MEMBERSHIP_CONDITIONS.indexOf(conditionKey) !== -1 && " PLUS"}
            </p>
          </>
        )}
        <div className="flex flex-col justify-center items-center text-center h-full py-1">
          {bannerUrl && !membershipRequiredCondition && (
            <img className={"h-12"} src={bannerUrl} alt="" role="presentation" />
          )}
          <div className="flex flex-col w-full">
            <h3 className="text-primary mt-4">
              {paymentBannerTitle(
                consult,
                hasWesternDentalBenefits ? hasWesternDentalBenefits : false,
              )}
            </h3>
            {allowTreatmentOptionPopup && (
              <Button
                extraClassNames="focus:outline-none py-4 mt-4"
                onClick={() => setShowPopup(true)}
              >
                View Treatments &amp; Pricing
              </Button>
            )}
          </div>
        </div>
      </div>
      <AlphaModal
        className="bg-white max-w-lg m-auto"
        isOpen={showPopup}
        onRequestClose={() => setShowPopup(false)}
        shouldCloseOnOverlayClick={true}
        positionCloseButton={{ right: "14px", top: "14px" }}
        closeIconColor="forest-60"
        closeIconSize="xl"
      >
        <ProvidedTreatmentsPopup
          onRequestClose={() => setShowPopup(false)}
          condition={conditionKey}
          consult={consult}
          paymentPreference={paymentPreference}
        />
      </AlphaModal>
    </>
  );
};
