/** @format */

// Module dependencies
import type { FC } from "react";

import React from "react";

import type { WebNavigationProps } from "src/v2/designSystem/WebNavigation";

import { AlphaLogo } from "@alphamedical/components";

// UI Components & Styles
import { LeftHandNavigationIcon } from "./LeftHandNavigationIcon";

import "./LeftHandNavigation.scss";

export const LeftHandNavigation: FC<WebNavigationProps> = ({
  icons,
  comparator,
  activeValue,
  logoCallback,
}) => {
  const availableIcons = icons.filter(({ visibleOn }) => visibleOn !== "mobile");

  return (
    <div
      className={
        "bg-sand-light p-6 flex flex-col w-64 z-30 left-hand-navigation-pdb hidden sm:block"
      }
    >
      <div className="mb-16 cursor-pointer" onClick={logoCallback}>
        <AlphaLogo />
      </div>

      <div className="flex flex-col gap-6">
        {availableIcons.map((icon) => (
          <LeftHandNavigationIcon
            {...icon}
            key={icon.value}
            isActive={comparator(activeValue, icon.value)}
          />
        ))}
      </div>
    </div>
  );
};
