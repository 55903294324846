/** @format */

import type React from "react";

import { Form } from "react-final-form";

import { FORM_ERROR } from "final-form";

import { TextFieldNew, Typography } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import { DynamicField } from "src/components/DynamicForm";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { STATES_FULL } from "src/v2/constants";
import { useStoreDispatch } from "src/v2/models";

export interface AddChildFormProps {
  showNonRequired: boolean;
  callback: (value: any) => void;
}

export const AddChildForm: React.FC<AddChildFormProps> = ({ showNonRequired, callback }) => {
  useTopBar({ title: "Add Child", variant: "nested", showBackButton: true });
  const dispatch = useStoreDispatch();
  return (
    <div className="mt-8">
      <Typography variant="h4">To add a child, let us know these details. </Typography>
      <Form
        onSubmit={(values: { [key: string]: any }) => {
          return dispatch.profile
            .addRelation(values)
            .then(callback)
            .catch((error: Error) => {
              console.error(error);
              return {
                [FORM_ERROR]: "Something went wrong. Please try again or message support",
              };
            });
        }}
        render={({
          form,
          values,
          handleSubmit,
          submitError,
          hasValidationErrors,
          submitting,
          ...rest
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="text-sm sm:text-base mt-10">
                <TextFieldNew
                  name="first_name"
                  placeholder="Child's first name"
                  label="Child's first name"
                  required={true}
                  className="mb-4"
                />
                <TextFieldNew
                  name="last_name"
                  placeholder="Child's last name"
                  label="Child's last name"
                  required={true}
                  className="mb-4"
                />
                {showNonRequired && (
                  <>
                    <TextFieldNew
                      name="address"
                      placeholder="Child's address"
                      label="Child's address"
                      className="mb-4"
                    />
                    <TextFieldNew
                      name="address2"
                      placeholder="Child's address line 2"
                      label="Child's address line 2"
                      className="mb-4"
                    />
                    <TextFieldNew name="city" placeholder="City" label="City" className="mb-2" />
                    <div className="flex justify-between space-x-3">
                      <div className="flex-1 mr-3">
                        <DynamicField
                          field={{
                            key: "state",
                            label: "State",
                            placeholder: "Select",
                            required: true,
                            category: "pii",
                            type: "select",
                            options: STATES_FULL,
                            subtype: "dropdown",
                          }}
                        />
                      </div>
                      <div className="flex-1 ml-3">
                        <DynamicField
                          field={{
                            key: "zipcode",
                            required: true,
                            type: "text",
                            subtype: "zipcode",
                            label: "ZIP code",
                          }}
                        />
                      </div>
                    </div>
                    <DynamicField
                      field={{
                        key: "phone",
                        required: true,
                        type: "text",
                        subtype: "phone",
                        label: "Mobile phone number",
                      }}
                    ></DynamicField>
                  </>
                )}
              </div>
              {submitError && <div className="text-red p-4">{submitError}</div>}
              <ButtonPdb
                type={"submit"}
                disabled={hasValidationErrors || submitting}
                className="w-full mt-6"
              >
                Add Child
              </ButtonPdb>
            </form>
          );
        }}
      />
    </div>
  );
};
