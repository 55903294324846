/** @format */

import type React from "react";
// import "./loader.scss";

interface Props {
  show: boolean;
  children?: React.ReactNode;
  message?: string;
  screen?: boolean;
  cxHeight?: string;
  dontShowLoader?: boolean;
  hideMessage?: boolean;
  stopAnimation?: boolean;
}

export const Loader: React.FC<Props> = (props: Props) =>
  props.show ? (
    <div
      className={`
          ${props.screen ? `h-screen` : props.cxHeight ? props.cxHeight : `h-full`}
          flex flex-col px-2
          justify-center items-center
        `}
    >
      {!props.hideMessage && (
        <span className="my-4 text-primary text-2xl">{props.message || `Loading`}</span>
      )}
      {!props.dontShowLoader && (
        <img
          className="w-12 h-12"
          alt="loading"
          src={props.stopAnimation ? "/assets/alpha-a.svg" : "/assets/alpha-loader-250x250.gif"}
        />
      )}
    </div>
  ) : (
    <>{props.children ? props.children : null}</>
  );
