/** @format */
import React from "react";

import { Popup } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import { useStoreDispatch, useStoreState } from "src/v2/models";
import { showShareDataSelector } from "src/v2/models/profile";

const WithShareDataModal = <T extends object>(WrappedComponent: React.ComponentType<T>) => {
  const ShareDataModalWrapper = (props: T) => {
    const isOpen = useStoreState(showShareDataSelector);
    const dispatch = useStoreDispatch();

    React.useEffect(() => {
      dispatch.profile.fetchWLJPreferences();
    }, []);

    const handleSelection = async (selection: boolean) => {
      await dispatch.profile.updateWLJPreferences({ share_with_provider: selection });
      dispatch.profile.setShowShareData(false);
    };

    return (
      <>
        <WrappedComponent {...props} />
        <Popup isOpen={isOpen} headerText="Share your data">
          <div className="p-4">
            You can allow your care team to have access to your Weight Loss Journal data. If you
            share your data, your care team will only review it if you mention it in your visit
            answers or a message.
          </div>
          <div className="p-4 bg-grey-10 space-y-4 flex flex-col justify-end">
            <ButtonPdb onClick={() => handleSelection(true)}>Allow</ButtonPdb>
            <ButtonPdb variant="secondary" onClick={() => handleSelection(false)}>
              Deny
            </ButtonPdb>
          </div>
        </Popup>
      </>
    );
  };

  const displayName = WrappedComponent?.displayName || WrappedComponent?.name || "Component";
  ShareDataModalWrapper.displayName = `WithShareDataModal(${displayName})`;
  return ShareDataModalWrapper;
};

export default WithShareDataModal;
