/** @format */
import React from "react";
import { useHistory } from "react-router-dom";

import moment from "moment";

import type { RecurlyInvoiceSchema } from "src/api";

import { INVOICES } from "src/routes";

interface InvoicesListViewProps {
  invoices: RecurlyInvoiceSchema[];
}

const InvoicesListView = ({ invoices }: InvoicesListViewProps) => {
  const history = useHistory();
  return (
    <>
      {invoices?.map((invoice: RecurlyInvoiceSchema) => (
        <div key={invoice.uuid} className=" px-4">
          <div
            onClick={() => history.push(`${INVOICES}/${invoice.uuid}`)}
            className="cursor-pointer flex py-4 border-grey-light border-b"
          >
            <div className="grid w-full">
              <p className="text-primary text-base w-full">{invoice.description}</p>
              <p className="text-grey-140 text-sm w-4/5 pt-2">{`Paid on ${moment(
                invoice.paid_on,
              ).format("MM/DD/YYYY")}`}</p>
            </div>
            <div className="flex items-center">
              <p className="text-cornflower-100 mr-3">{invoice.amount}</p>
              <img src={"/assets/carrot-right-primary.svg"} alt={`Invoice #${invoice.number} `} />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default InvoicesListView;
