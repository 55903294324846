/** @format */

import React from "react";
import { useLocation } from "react-use";

import UnAuthWrapper from "src/v2/components/UnAuthWrapper";
import { useStoreDispatch, useStoreState } from "src/v2/models";
import { Login } from "src/v2/routes/Login";
import { Register } from "src/v2/routes/Register";
interface AuthWallProps {
  defaultShowLogin: boolean;
}

export const AuthWall = (props: AuthWallProps) => {
  const dispatch = useStoreDispatch();
  const { showLogin } = useStoreState((state) => state.auth);
  const location = useLocation();

  const [authenticating, setAuthenticating] = React.useState<boolean>(true);

  React.useEffect(() => {
    let safeSetAuthenticating = (authState: boolean) => {
      setAuthenticating(authState);
    };
    let _showLogin = showLogin === undefined ? props.defaultShowLogin : showLogin;
    if (location.search) {
      if (location.search.indexOf("login=true") >= 0) {
        _showLogin = true;
      } else if (location.search.indexOf("register=true") >= 0) {
        _showLogin = false;
      }
    }
    dispatch.auth.setShowLogin(_showLogin);
    dispatch.auth.checkAuth().finally(() => safeSetAuthenticating(false));
    return () => {
      safeSetAuthenticating = () => {
        // nothing to do here, component unmounted
      };
    };
  }, []);

  return (
    <UnAuthWrapper showLoader={authenticating}>
      {showLogin ? <Login /> : <Register />}
    </UnAuthWrapper>
  );
};

export const AuthWallLogin = () => {
  return <AuthWall defaultShowLogin={true} />;
};
