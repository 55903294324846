/** @format */

import type { PropsWithChildren } from "react";
import type React from "react";

import { Typography } from "@alphamedical/components";

export type BaseCardProps = {
  bgClassName?: string;
  icon?: React.ReactNode;
  title?: string;
  description: string;
};
export const BaseCard = ({
  bgClassName,
  children,
  icon,
  title,
  description,
}: PropsWithChildren<BaseCardProps>) => (
  <div className={`p-4 mb-4 shadow rounded ${bgClassName || "bg-sand-20"}`}>
    <div className="flex items-center mb-2">
      {icon}
      <Typography variant="h4">{title}</Typography>
    </div>
    <Typography variant="body2">{description}</Typography>
    {children && <div className="mt-4">{children}</div>}
  </div>
);
