/** @format */
import React from "react";
import { useFormState } from "react-final-form";
import { OnChange } from "react-final-form-listeners";

import { ConsultsService } from "src/api";
import { consentToTelehealth } from "src/v2/components/Payment/PaymentV2";
import { telehealthConsentVersion } from "src/v2/constants";
import { useCurrentUser } from "src/v2/models/profile";
import { ConsultContext } from "src/v2/routes/Consultation";
import { type Consultation } from "src/v2/types/consultation";

export const TelehealthConsent: React.FC = () => {
  const { values, error } = useFormState();
  const user = useCurrentUser();
  const consult = React.useContext(ConsultContext) as Consultation;

  const signConsent = () =>
    ConsultsService.signConsent({
      userId: user.id.toString(),
      consultId: consult.id.toString(),
      requestBody: { consent_forms: [telehealthConsentVersion] },
    });
  return (
    <>
      {consentToTelehealth("", values.consents, !!error)}
      <OnChange name="consents">{() => signConsent()}</OnChange>
    </>
  );
};
