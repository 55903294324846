/** @format */

import React from "react";
import { useForm } from "react-final-form";
import ClipLoader from "react-spinners/ClipLoader";

import _ from "lodash";

import type {
  CustomOptionFieldRenderProps,
  OptionType,
  SelectFieldProps,
} from "@alphamedical/components";
import type { DropdownOption } from "src/v2/designSystem";

import { ToggleField } from "@alphamedical/components";
import { CheckboxSelection, DropdownSelect, RadioFieldV2 } from "src/v2/designSystem";

import type { OptionLoader } from "src/components/DynamicForm/Processors/optionLoaders";
import type { DynamicOptionField as IDynamicOptionField } from "src/components/DynamicForm/types";

import { optionLoaders } from "src/components/DynamicForm/Processors";

import type { DynamicFieldProps } from "./index";

import { CustomOptionComponents } from "./CustomOptionComponents";

type DynamicOptionFieldProps = DynamicFieldProps &
  Partial<Omit<SelectFieldProps, "children">> & {
    field: IDynamicOptionField;
  };

const getOptionRenderer = (field: IDynamicOptionField) => {
  if (!field.optionComponent) {
    return undefined;
  }
  const CustomComponent = CustomOptionComponents[field.optionComponent];
  if (!CustomComponent) {
    return undefined;
  }
  return (renderProps: CustomOptionFieldRenderProps<string>) => (
    <CustomComponent field={field} {...renderProps} />
  );
};

export const DynamicOptionField = ({ field, ...props }: DynamicOptionFieldProps) => {
  const form = useForm();
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState<OptionType[]>(field.options || []);

  const selectAllThatApply = field.showSelectAllThatApply ? (
    <div className="text-sm text-forest-darker mb-6">Select all that apply.</div>
  ) : (
    ""
  );

  React.useEffect(() => {
    const loader: undefined | OptionLoader =
      field.optionLoader && optionLoaders[field.optionLoader];
    if (!loader && field.optionLoader) {
      console.log(`Unable to find option loader named '${field.optionLoader}'`);
    }
    if (loader) {
      const result = loader(form, field);
      if (_.isArray(result)) {
        setOptions(result);
      } else {
        setLoading(true);
        result
          .then((loadedOptions) => {
            setOptions(loadedOptions);
            form.mutators.setFieldData(field.key, {
              optionCount: loadedOptions.length,
            });
            field.options = loadedOptions.map((opt) => ({
              ...opt,
              label: opt.label.toString(),
            })); //coerce to string, even if it is an object
          })
          .catch((err) => console.log("Failed to load options"))
          .finally(() => setLoading(false));
      }
    }
  }, [field.optionLoader]);

  if (loading) {
    return <ClipLoader color={"#A4B7B5"} loading={true} />;
  }

  const optionRenderer = getOptionRenderer(field);

  if (field.type === "select") {
    if (field.subtype === "dropdown") {
      return (
        <DropdownSelect
          options={options as DropdownOption[]}
          name={field.key}
          label={field.label}
          required={field.required}
          clearable={true}
          placeholder={field.placeholder}
          searchable={field.isSearchable}
        />
      );
    }

    if (field.subtype === "multi-dropdown") {
      return (
        <>
          {selectAllThatApply}
          <DropdownSelect
            options={options as DropdownOption[]}
            name={field.key}
            label={field.label}
            required={field.required}
            clearable={true}
            placeholder={field.placeholder}
            searchable={field.isSearchable}
            isMulti
          />
        </>
      );
    }

    if (field.subtype === "checkbox") {
      if (field.toggle) {
        const label = field.label || field.options[0]?.label;
        const defaultValue = (field.defaultValue && !!field.defaultValue) || undefined;
        return <ToggleField defaultValue={defaultValue} label={label as any} name={field.key} />;
      }

      return (
        <>
          {selectAllThatApply}

          <CheckboxSelection name={field.key} options={options} required={field.required}>
            {optionRenderer}
          </CheckboxSelection>
        </>
      );
    }
  }

  return (
    <RadioFieldV2 name={field.key} {...props} options={options}>
      {optionRenderer}
    </RadioFieldV2>
  );
};
