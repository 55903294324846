/** @format */

export const getCurrentProduct = () => {
  if (!window) {
    return undefined;
  }
  // Split the url path
  const urlParts = (window.location.origin + window.location.pathname).split("/");

  // Find the index that 'consultation' appears in the path, and make sure that a) 'consultation' is in the path and
  // b) there is atleast one value after consultation (the outline key)
  const consultationIndex = urlParts.findIndex((s) => s === "consultation");
  if (consultationIndex === -1 || consultationIndex + 1 >= urlParts.length) {
    return undefined;
  }

  // Get the product name that follows 'consultation'
  const condition = urlParts[consultationIndex + 1];

  // Return the product name after mapping it if necessary
  const conditionAliases: Partial<Record<string, string>> = {
    lashes: "eyelashes",
  };
  return conditionAliases[condition] || condition;
};
