/** @format */

// Module dependencies
import React from "react";
// UI Components & Styles
import ClipLoader from "react-spinners/ClipLoader";

import classnames from "classnames";
import { isFunction } from "lodash";

import "./ButtonPdb.scss";

export type ButtonVariant =
  | "primary"
  | "secondary"
  | "tertiary"
  | "quaternary"
  | "danger"
  | "transparent";

export interface ButtonPdbProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * @default primary
   * The theme of the button, which will have a different appearance depending on the context of the application the button is used in
   */
  variant?: ButtonVariant;

  /**
   * An optional icon rendered to the left of the button text.
   */
  icon?: string | React.ReactNode;

  /**
   * Determines whether the loading text, icon, styles, and functionality are applied.
   */
  isLoading?: boolean;

  /**
   * Icon size for incon-only variant.
   */
  iconSize?: "medium" | "small" | "extraSmall";

  /**
   * Additional classnames to be appended to existing classes.
   */
  className?: string;

  /**
   * @default false
   * Boolean. If true the buttons only show the icon whithout the children.
   */
  iconOnly?: boolean;
}

export const ButtonPdb: React.FC<ButtonPdbProps> = ({
  variant = "primary",
  icon = "",
  isLoading,
  className,
  onClick,
  children,
  type = "submit",
  iconSize = "small",
  iconOnly = false,
  ...props
}) => {
  // Style Mappers
  const iconSizeClassMapper: { [key: string]: string } = {
    medium: "text-2xl",
    small: "text-base",
    extraSmall: "text-xs",
  };

  const iconSizeButtonMapper: { [key: string]: string } = {
    medium: "p-2",
    small: iconOnly ? "p-3" : "py-3 px-5",
    extraSmall: "button-iconOnly_extraSmall",
  };

  const variantStyleMapper: { [key: string]: string } = {
    primary: "bg-forest-100 text-white border border-forest-100",
    secondary: "text-forest-100 border border-forest-100",
    tertiary: "text-cornflower-100 border border-transparent",
    quaternary: "bg-alert text-white border border-transparent",
    danger: "bg-error text-white border border-error",
    transparent: "border border-transparent",
  };

  const loadingColor: { [key: string]: string } = {
    primary: "white",
    secondary: "forest-100",
    tertiary: "cornflower-100",
    danger: "white",
    transparent: "forest-100",
  };

  const mappedIconClasses = iconOnly ? iconSizeClassMapper[iconSize] : iconSizeClassMapper["small"];

  const mappedButtonClasses = iconOnly
    ? iconSizeButtonMapper[iconSize]
    : iconSizeButtonMapper["small"];

  return isLoading && !icon ? (
    <></>
  ) : (
    <button
      onClick={(e) => {
        isFunction(onClick) && onClick(e);
      }}
      className={classnames(
        `flex flex-row items-center justify-center relative button-pdb button-pdb_${variant}`,
        "text-base font-medium rounded leading-6 box-border",
        mappedButtonClasses,
        variantStyleMapper[variant],
        iconOnly ? "h-10 w-10 justify-center button-pdb_iconOnly" : "h-12",
        { loading: isLoading },
        isLoading && "button-pdb_loading",
        !children && icon && "justify-center",
        props.disabled && "button-pdb_disabled cursor-not-allowed",
        className,
      )}
      disabled={isLoading || props.disabled}
      type={type}
      {...props}
    >
      {isLoading ? (
        <div className="flex justify-center items-center">
          {/*<ClipLoader size={16} color={loadingColor[variant]} className={`button-pdb_${variant}`} />*/}
          <ClipLoader size={16} color={loadingColor[variant]} />
          {!iconOnly && <span className="ml-2">Loading</span>}
        </div>
      ) : (
        <>
          {icon && (
            <div className={classnames(children && !iconOnly && "mr-2", mappedIconClasses)}>
              {typeof icon === "string" && <i className={classnames(icon)} />}
              {React.isValidElement(icon) && icon}
            </div>
          )}
          {!iconOnly && children}
        </>
      )}
    </button>
  );
};
