/** @format */

import React from "react";

import { BackButtonHeading } from "src/components/_buttons/BackButtonHeading";
import config from "src/config";
import IconText from "src/v2/components/TreatmentInfo/IconText";

interface AtHomeTestKitProps {
  setLoading: (loading: boolean) => void;
}

const INFO = [
  {
    alt: "Box Icon",
    icon: "/assets/box.svg",
    text: "Our lab partner, Molecular Labs, will send you an A1C test kit",
  },
  {
    alt: "Cotton Swab Icon",
    icon: "/assets/cotton-swab.svg",
    text: "Follow the instructions included in your kit to collect your sample, then use the prepaid label and packaging to send it to our lab partner for testing",
  },
  {
    alt: "Session Icon",
    icon: "/assets/session.svg",
    text: `We will notify you when your sample has been tested and your lab results are available. Our medical team will reach out within ${config.responseTime} to discuss treatment if necessary`,
  },
];

const AtHomeTestKit = ({ setLoading }: AtHomeTestKitProps) => {
  React.useEffect(() => {
    setLoading(false);
  }, []);
  return (
    <div>
      <BackButtonHeading title="At-home test kit" />
      <div className="mt-8">
        {INFO.map((info) => {
          return <IconText key={info.icon} icon={info.icon} iconAlt={info.alt} text={info.text} />;
        })}
      </div>
    </div>
  );
};

export default AtHomeTestKit;
