/** @format */

import React from "react";
import { useHistory } from "react-router-dom";

import { faChevronRight, faEnvelopes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { BadgePdb } from "src/v2/designSystem";

import { ChannelList } from "src/components/Chat/ChannelList";
import { RecordAccordion } from "src/components/RecordAccordion/RecordAccordion";
import { useGetChannels } from "src/utils/hooks";
import { useTotalUnreadMessages } from "src/utils/hooks/pubnub";

interface YourMessagesProps {
  expandOnUnread?: boolean;
}

export const YourMessages = ({ expandOnUnread = false }: YourMessagesProps) => {
  const history = useHistory();
  const { channels = [] } = useGetChannels();

  const unseenMessagesCount = useTotalUnreadMessages();
  const defaultExpanded = unseenMessagesCount > 0 && !expandOnUnread;

  return (
    <RecordAccordion
      title="Your Messages"
      HeaderRightElement={
        <BadgePdb
          variant="informative"
          label={
            expandOnUnread && unseenMessagesCount
              ? `${unseenMessagesCount} new`
              : unseenMessagesCount
          }
          addClasses="mr-4"
        />
      }
      IconElement={<FontAwesomeIcon icon={faEnvelopes} size="xl" />}
      defaultExpanded={defaultExpanded}
      FooterElement={
        <div
          role="button"
          onClick={() => history.push("/messages")}
          className="flex items-center justify-center align-center text-cornflower-100 flex-grow"
        >
          <div className="pr-1">All messages</div>
          <FontAwesomeIcon icon={faChevronRight} size="2xs" className="mt-1 font-bold" />
        </div>
      }
    >
      <ChannelList channels={channels} isForInbox={true}></ChannelList>
    </RecordAccordion>
  );
};
