/** @format */

import type React from "react";

import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import { Typography } from "@alphamedical/components";

import { MEMBERSHIP_DETAILS } from "src/routes";
import { useStoreState } from "src/v2/models";

export interface VisitCardProps {
  idx: number;
  title: string;
  description?: string;
  imageUrl?: string;
  onClick: (event: React.MouseEvent | React.KeyboardEvent) => void;
  condition?: string;
}

const VisitCard: React.FunctionComponent<VisitCardProps> = ({
  idx,
  title,
  imageUrl,
  onClick,
  description,
  condition,
}) => {
  const membership = useStoreState((state) => state.membership.membership);

  const unLocked =
    !!membership && membership.plan_code === "alpha-membership-plus" && membership.active;
  const conditionsLocked: string[] = [];
  const showLocked = condition && conditionsLocked.includes(condition) && !unLocked;
  const history = useHistory();

  return (
    <div
      className={classNames(
        "flex flex-row",
        { "mt-4": idx > 0 },
        "p-6 bg-white shadow-md",
        { "cursor-not-allowed": showLocked },
        { "cursor-pointer": !showLocked },
      )}
      onClick={(e) => !showLocked && onClick(e)}
    >
      {imageUrl && (
        <div className="w-12 mr-6">
          <img className="object-contain w-full" src={imageUrl} alt={title} />
        </div>
      )}
      <div className="flex-1">
        <div className={"flex justify-between"}>
          <Typography
            variant="subtitle1"
            className={classNames(
              { "text-grey-160": showLocked },
              { "text-forest-120": !showLocked },
            )}
          >
            {title}
          </Typography>
          {showLocked && <FontAwesomeIcon color={"#656E6D"} icon={["far", "lock"]} />}
        </div>
        {description && (
          <Typography
            variant="body2"
            className={classNames(
              "mt-3",
              { "text-forest-120": !showLocked },
              { "text-grey-160": showLocked },
            )}
          >
            {description}
          </Typography>
        )}
        {showLocked && (
          <div className={"cursor-pointer"} onClick={() => history.push(MEMBERSHIP_DETAILS)}>
            <Typography className={"mt-5 text-center"} variant={"hyperlink"}>
              Upgrade to Alpha Plus+ Plan
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default VisitCard;
