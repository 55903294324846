/** @format */

import React, { useRef } from "react";

import classNames from "classnames";

import type { FieldRenderProps } from "@alphamedical/components";

import "./SingleSelectOption.scss";

export interface SingleSelectOptionProps<T = string> extends FieldRenderProps<T> {
  optionKey: string;
  tabIndex?: number;
  primaryText?: string;
  secondaryText?: string;
  icon?: string | React.ReactNode;
  onSelection?: (optionKey: string) => void;
  bulletPoints?: string[];
}

export const SingleSelectOption = (props: SingleSelectOptionProps) => {
  const {
    input,
    onSelection,
    icon,
    primaryText,
    secondaryText,
    disabled,
    tabIndex,
    bulletPoints,
    optionKey,
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyboardSelection = (e: React.KeyboardEvent<HTMLLabelElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      inputRef.current?.click();
    }
  };

  const handleClick = (_event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    if (onSelection) {
      onSelection(optionKey);
    }
    inputRef.current?.click();
  };

  return (
    <label
      className={classNames(
        "flex flex-col p-3 rounded cursor-pointer single-select",
        "focus:border-2 focus:border-forest-100 single-select-option",
        input.checked ? "bg-sand-60 selected" : "bg-grey-10 sm:hover:bg-sand-40",
        disabled && "bg-grey-20",
      )}
      tabIndex={tabIndex || 0}
      onKeyDown={(e) => {
        handleKeyboardSelection(e);
      }}
    >
      <input
        disabled={disabled}
        className="appearance-none inline h-full"
        {...input}
        onClick={handleClick}
        ref={inputRef}
        tabIndex={-1}
      />
      <div className="flex flex-row justify-between">
        <span
          className={classNames(
            "text-forest-100 text-base leading-6 text-left",
            secondaryText ? "font-medium" : "font-normal",
          )}
        >
          {primaryText}
        </span>
        {icon && React.isValidElement(icon) && icon}
        {icon && typeof icon === "string" && (
          <img src={icon} alt="button icon cursor-pointer" className="pl-5" />
        )}
      </div>
      {bulletPoints && (
        <ul className={"text-grey-160 font-normal ml-4 text-sm pt-3 list-disc"}>
          {bulletPoints.map((point) => (
            <li key={point.replace(" ", "")}>{point}</li>
          ))}
        </ul>
      )}
      {secondaryText && (
        <span className="text-grey-160 font-normal text-sm leading-5 pt-3 cursor-pointer">
          {secondaryText}
        </span>
      )}
    </label>
  );
};
