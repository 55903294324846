/** @format */

import React from "react";
import { Link } from "react-router-dom";

import type { Record } from "src/v2/models/record";

import { WrapLayout } from "src/components/Layouts";

interface ScreeningToolResultsProps {
  assessmentLabel?: string;
  scores: Record;
  description?: string;
  onStartNewAssessment?: () => void;
}

const SEVERITY_COLORS = ["bg-grey-1", "bg-white", "bg-sand-light", "bg-sand-dark"];

export const ScreeningToolResults = (props: ScreeningToolResultsProps) => {
  const mostRecentScore = props.scores.scores[props.scores.scores.length - 1];
  return (
    <div className="space-y-8">
      <h1 className="font-semibold text-2xl">Your Assessment</h1>
      <div className="flex flex-col sm:flex-row flex-start items-center space-y-8 sm:space-y-0 sm:space-x-8">
        <div
          className="bg-sand shadow p-4 sm:p-5 flex flex-col items-center justify-between"
          style={{ width: "140px", height: "130px" }}
        >
          <span className="font-semibold text-lg">Your Score</span>
          <span className="font-semibold text-2xl">{mostRecentScore.score}</span>
          <span className="text-lg sm:text-base">points</span>
        </div>
        <div className="flex flex-col justify-between items-start">
          <h2 className="font-semibold text-xl">
            {mostRecentScore.score_description}
            {props.assessmentLabel && ` ${props.assessmentLabel}`}
          </h2>
          <div className="flex flex-col sm:flex-row my-2 space-y-4 sm:space-y-0 sm:space-x-4">
            <a className="btn btn-violator" href="/consultation/mental-health">
              Get Treated Now
            </a>
            {props.scores.scores.length > 1 && (
              <Link
                to="/mental-health-records/assessments"
                className="btn border border-primary text-primary"
              >
                View Your Previous Scores
              </Link>
            )}
          </div>
          {!!props.onStartNewAssessment && (
            <button className="underline text-primary-60" onClick={props.onStartNewAssessment}>
              Start a new assessment
            </button>
          )}
        </div>
      </div>
      <div className="space-y-4">
        <p>
          These measures are an important piece of information about you. They are not meant to
          represent your entire clinical picture or minimize your thoughts, feelings, or
          experiences.
        </p>
        <p>
          This is an important tool that you can repeat to trend your feelings and emotions and can
          help you and, if you choose, help your Alpha Provider, primary care provider, or a
          behavioral health specialist identify the specific symptoms which are causing you
          difficulty.
        </p>
        <p>
          If you would like to seek further diagnosis and evidence-based treatment, get started with
          the Alpha Mental Health Service.
        </p>
      </div>
      <WrapLayout childSize="300px" cxPadding="">
        {props.scores.severity_info.map((severity, idx) => (
          <div key={severity.score_range} className={`p-4 flex ${SEVERITY_COLORS[idx]} h-full`}>
            <div className="text-xl w-1/4">{severity.score_range}</div>
            <div className="flex-1 flex flex-col">
              <span className="text-lg font-semibold mb-4">{severity.title}</span>
              <p>{severity.description}</p>
            </div>
          </div>
        ))}
      </WrapLayout>
    </div>
  );
};
