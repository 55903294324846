/** @format */

import React from "react";

import type { BadAddressDetails, SecondaryUserViewsUpdateUserPiiRequestBodySchema } from "src/api";

import ConfirmShippingAddress from "src/components/Popups/FollowupItemPopupsFile/BadAddressDetails/ConfirmShippingAddress";
import Message from "src/components/Popups/FollowupItemPopupsFile/BadAddressDetails/Message";
import UpdateShippingAddress from "src/components/Popups/FollowupItemPopupsFile/BadAddressDetails/UpdateShippingAddress";
import { AlphaModal } from "src/v2/components/AlphaModal";

interface BadAddressDetailsPopupProps {
  item: BadAddressDetails;
  showPopup: boolean;
  setShowPopup: (show: boolean) => void;
  pii: SecondaryUserViewsUpdateUserPiiRequestBodySchema;
}

const BadAddressDetailsPopup = ({
  item,
  showPopup,
  setShowPopup,
  pii,
}: BadAddressDetailsPopupProps) => {
  const [modalContent, setModalContent] = React.useState("message");
  const modalTypes = {
    message: <Message item={item} pii={pii} setModalContent={setModalContent} />,
    update: (
      <UpdateShippingAddress
        setShowPopup={setShowPopup}
        item={item}
        setModalContent={setModalContent}
      />
    ),
    confirm: (
      <ConfirmShippingAddress
        setShowPopup={setShowPopup}
        item={item}
        setModalContent={setModalContent}
      />
    ),
  };
  return (
    <div>
      <AlphaModal
        isOpen={showPopup}
        className="max-w-sm m-auto bg-white shadow-md"
        cxPadding="p-8"
        addPaddingForNavBar={true}
        onRequestClose={() => {
          setShowPopup(false);
        }}
      >
        {modalTypes[modalContent]}
      </AlphaModal>
    </div>
  );
};

export default BadAddressDetailsPopup;
