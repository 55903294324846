/** @format */
import { MEDICAL_RECORDS } from "src/routes";

export interface AnchorItem {
  uid: string;
  internalEntry?: string;
  anchorURL?: string;
  anchorText: string;
  onlyAuthenticated?: boolean;
}
export interface NavItem {
  uid: string;
  heading: string;
  navItemStyle: string;
  navItemBorder: string[];
  navItemSmall: string[];
  anchor: AnchorItem[];
}

export const navItems: NavItem[] = [
  {
    uid: "1",
    heading: "",
    navItemStyle: "flat",
    navItemBorder: [],
    navItemSmall: [],
    anchor: [
      {
        uid: "0.9",
        internalEntry: "my-cases",
        anchorText: "Home",
      },
      {
        uid: "1.0",
        internalEntry: "visit",
        anchorText: "Get Treated",
      },
      {
        uid: "1.1",
        internalEntry: "orders",
        anchorText: "Your Orders",
      },
      {
        uid: "1.2",
        internalEntry: MEDICAL_RECORDS,
        anchorText: "Your Alpha Medical Records",
        onlyAuthenticated: true,
      },
      {
        uid: "1.3",
        anchorURL: "https://shop.helloalpha.com/",
        anchorText: "Alpha Shop",
      },
    ],
  },
];
