/** @format */

import React from "react";

import { DateRangeChart } from "@alphamedical/components";

import type { ChartType } from "src/v2/components/Charts/types";

import { useStoreDispatch, useStoreState } from "src/v2/models";
import { makeChartTypeSelector } from "src/v2/models/charts";

import { titles } from "./constants";

type DateRangeChartProps = React.ComponentProps<typeof DateRangeChart>;

type PatientChart = DateRangeChartProps & {
  chartType: ChartType;
};

const PatientChart: React.FC<PatientChart> = ({ chartType, data, ...dateRangeChartProps }) => {
  const dispatch = useStoreDispatch();
  const chart = useStoreState(makeChartTypeSelector(chartType));

  React.useEffect(() => {
    if (!data && chartType) {
      dispatch.charts.fetchChartData(chartType);
    }
  }, [chartType]);

  return <DateRangeChart title={titles[chartType]} data={chart} {...dateRangeChartProps} />;
};

export default PatientChart;
