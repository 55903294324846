/** @format */

import React from "react";

import * as Sentry from "@sentry/react";

import { isAxiosError } from "src/helpers/axios";
import { useStoreDispatch } from "src/v2/models";
import { ForgotPasswordForm } from "src/v2/routes/ForgotPassword/form";

export type ForgotPasswordValidation = {
  email?: string;
};

export const ForgotPassword = () => {
  const dispatch = useStoreDispatch();
  const [successfulSubmit, setSuccessfulSubmit] = React.useState(false);
  const [formError, setFormError] = React.useState("");

  const validate = (values: ForgotPasswordValidation) => {
    const errors: ForgotPasswordValidation = {};
    if (!values.email) {
      errors.email = "Email Address Required";
    } else if (!values.email.includes("@")) {
      errors.email = "Invalid Email Address";
    }
    return errors;
  };

  const onSubmit = async (values: any) => {
    return dispatch.auth
      .sendResetLink({ email: values.email })
      .then(() => setSuccessfulSubmit(true))
      .catch((err: any) => {
        if (isAxiosError(err)) {
          Sentry.captureException(err);
        }
        setFormError("Something went wrong. Please try again later.");
      });
  };

  return (
    <div className="h-screen w-full flex flex-col justify-center items-center">
      <div style={{ height: "10px" }} className="bg-sand-40 w-full flex" />
      <div className="p-6 w-full z-10 flex-1">
        <div className="h-full bg-white flex">
          <div className="mx-auto">
            <ForgotPasswordForm
              validate={validate}
              formError={formError}
              onSubmit={onSubmit}
              successfulSubmit={successfulSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
