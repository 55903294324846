/** @format */

import React from "react";

import { map } from "lodash";

import { Toggle } from "@alphamedical/components";

import type { testName } from "src/utils/hooks/useABTest";

import { PageWrapper } from "src/components/PageWrapper";
import { useABTest } from "src/utils/hooks/useABTest";
import { useStoreActions } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";

export const VARIANT_OVERRIDE_PREFIX = "variant-override-";
const VARIANT_FEATURES = [
  {
    key: "weight-loss-funnel",
    title: "Weight Loss Funnel",
    description: "Content Variant for Weight Loss Funnel",
  },
];

export const VariantSettings = () => {
  const addSnack = useStoreActions((actions) => actions.snacks.addSnack);
  const [_forceRerender, setForceRerender] = React.useState(false);
  const currentUser = useCurrentUser();

  return (
    <PageWrapper cxPadding="p-8">
      <h2 className="text-2xl mb-4">Variant Toggles</h2>
      <div>
        Use this page to toggle between different variants of the same feature. This is useful for
        testing different versions of a feature. Overrides persist across multiple accounts. Use the
        "Clear Override" button to remove the override. If there is no override set then the toggle
        shows the currently active variant based on the logged in users Alpha ID.
      </div>
      <div>
        {map(VARIANT_FEATURES, (feature) => {
          const overrideIsSet = localStorage.getItem(VARIANT_OVERRIDE_PREFIX + feature.key);
          return (
            <div
              className="py-6 border-b-2 flex justify-between items-start max-w-3xl"
              key={feature.key}
            >
              <div>
                <div className="text-xl mb-1 font-medium">{feature.title}</div>
                <div>{feature.description}</div>
              </div>
              <div className="flex">
                <Toggle
                  label="A"
                  className="mt-4"
                  onChange={(newValue) => {
                    const newVariant = newValue ? "B" : "A";
                    localStorage.setItem(VARIANT_OVERRIDE_PREFIX + feature.key, newVariant);
                    setForceRerender((oldVal) => !oldVal);
                    addSnack({
                      message: `${feature.title} set to variant ${newVariant}`,
                      type: "success",
                      id: feature.key + newVariant,
                      delay: 3,
                    });
                  }}
                  // Populate value with the useABTest hook once it is implemented
                  value={useABTest(feature.key as testName, currentUser.id) === "B"}
                />
                <div className="ml-2 mt-4">B</div>
                {overrideIsSet && (
                  <div
                    className="ml-4 mt-4 text-cornflower-100 cursor-pointer"
                    onClick={() => {
                      localStorage.removeItem(VARIANT_OVERRIDE_PREFIX + feature.key);
                      setForceRerender((oldVal) => !oldVal);
                    }}
                  >
                    Clear Override
                  </div>
                )}
                {!overrideIsSet && <div className="ml-4 mt-4">No override set</div>}
              </div>
            </div>
          );
        })}
      </div>
    </PageWrapper>
  );
};
