/** @format */

import React from "react";
import { useToggle } from "react-use";

import { useAuthenticatedUser } from "src/utils/hooks";
import { Button } from "src/v2/components/Button";
import { useStoreActions, useStoreDispatch } from "src/v2/models";

import { AddChildForm } from "./AddChildForm";

export interface AddOrSelectRelationshipProps {
  callback?: (values?: any) => void;
}

export const AddOrSelectRelationship: React.FC<AddOrSelectRelationshipProps> = ({ callback }) => {
  const dispatch = useStoreDispatch();
  const [authenticatedUser] = useAuthenticatedUser();
  const actions = useStoreActions((actions) => actions.profile);
  const family = [...(authenticatedUser.parents || []), ...(authenticatedUser.children || [])];
  const [addChild, toggleAddChild] = useToggle(false);
  const [selectedChild, setSelectedChild] = React.useState<number>();

  React.useEffect(() => {
    // Re-fetch authenticated user to ensure all relationships are up to date and loaded
    dispatch.auth.fetchAuthenticatedUser({});
  }, [addChild]);

  if (addChild) return <AddChildForm showNonRequired={false} callback={toggleAddChild} />;

  return (
    <div>
      <h1 className="text-xl font-bold leading-snug">Which child would you like us to treat?</h1>
      <ul className="pl-0">
        {family &&
          family.map((user) => {
            return (
              <li key={user.id} className="bg-white flex flex-col">
                <button
                  className={`p-5 block  text-primary font-bold mb-4
                  hover:bg-grey-light hover:shadow-none focus:shadow-none cursor-pointer
                  ${selectedChild === user.id ? "bg-grey-light" : "bg-grey-lightest shadow-md"}
                  `}
                  onClick={() => setSelectedChild(user.id)}
                >
                  <span className="text-left font-bold">{user.pii.full_name}</span>
                </button>
              </li>
            );
          })}
      </ul>

      <Button
        onClick={toggleAddChild}
        hoverBgColor="primary"
        bgColor="white"
        borderColor="forest-20"
        textColor="primary"
        extraClassNames="p-4 flex items-center font-bold hover:text-white"
      >
        <>
          <img src="/assets/plus.svg" alt="Add a child to your account" className="w-8 mr-6" /> Add
          child
        </>
      </Button>
      <Button
        onClick={() => {
          selectedChild && actions.setCurrentProfileId({ userId: selectedChild });
        }}
        disabled={!selectedChild}
      >
        Next
      </Button>
    </div>
  );
};
