/** @format */

import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import { Typography } from "@alphamedical/components";

import type { AlphaPlus } from "./PlanComparisonPage";

interface AlphaPlusAdditionalFeaturesProps {
  content: AlphaPlus;
}

export const AlphaPlusAdditionalFeatures = ({ content }: AlphaPlusAdditionalFeaturesProps) => {
  return (
    <div className="sm:max-w-2xl mx-auto w-full px-6 pt-10 pb-10 bg-white">
      <div className="text-center mb-6">
        <Typography variant="h1">
          {content.title.map((part, index) => (
            <span
              key={index}
              className={classNames({
                "text-information": index === 1,
                "text-forest-darker": index !== 1,
              })}
            >
              {part}
            </span>
          ))}
        </Typography>
      </div>
      <div className="text-forest-120 flex flex-col gap-3 items-start self-stretch">
        {content.features.map((feature) => {
          return (
            <p key={feature.text} className="flex flex-row gap-2">
              <FontAwesomeIcon
                icon={faCircleCheck}
                size="xl"
                className="text-forest-100"
                style={{ marginTop: "2px", marginLeft: "2px" }}
              ></FontAwesomeIcon>
              <span className="text-base font-medium">{feature.text}</span>
            </p>
          );
        })}
      </div>
    </div>
  );
};
