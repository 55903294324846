/** @format */

import type { Action, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";

import type { BillingInfoSchema } from "src/api";

import { UsersService } from "src/api";

export interface BillingInfoModel {
  billingInfo?: BillingInfoSchema;

  setBillingInfo: Action<BillingInfoModel, BillingInfoSchema | undefined>;

  fetchBillingInfo: Thunk<BillingInfoModel>;

  updateBillingInfo: Thunk<BillingInfoModel, string>;

  fetching?: any;
}

export const billingInfoStore: BillingInfoModel = {
  setBillingInfo: action((state, billingInfo) => {
    state.billingInfo = billingInfo;
    state.fetching = false;
  }),

  fetchBillingInfo: thunk(async (actions, __, { getState }) => {
    const state = getState();
    if (state.fetching) {
      return;
    }
    // actions.setFetching<any>(true);
    await UsersService.userBillingInfo()
      .then((response) => {
        actions.setBillingInfo(response);
      })
      .catch((err) => {
        actions.setBillingInfo(undefined);
      });
  }),

  updateBillingInfo: thunk(async (actions, token, { getState }) => {
    const response = await UsersService.saveUserBillingInfo1({ requestBody: { token } });
    actions.setBillingInfo(response);
  }),
};
