/** @format */

import moment from "moment";

import { _date } from "src/v2/utils/auth/_regex";

export const isValidPhoneNumber = (value: string) => {
  if (!value) {
    return "Required";
  }

  const numbersOnlyLength = value.replace(/[^0-9]/g, "").length;
  if (numbersOnlyLength < 10 || numbersOnlyLength > 11) {
    return "Invalid phone number. Must be in the format 987-654-3210";
  }
  return undefined;
};

export const isValidZipcode = (value: string) => {
  if (!value) {
    return "Required";
  }

  if (value.length < 5) {
    return "Zipcode must have at least 5 numbers";
  }

  return undefined;
};

export const extractBloodPressureParts = (value: string) => {
  let systolic = null;
  let diastolic = null;

  if (value) {
    const matches = value.match(/^\s*(\d+)\s*\/\s*(\d+)\s*$/);
    if (matches !== null) {
      systolic = parseInt(matches[1], 10);
      diastolic = parseInt(matches[2], 10);
    }
  }
  return { systolic, diastolic };
};

export const isValidBloodPressureFormat = (value: string) => {
  if (!value) {
    return undefined;
  }
  const errorMessage = "Please enter a valid blood pressure reading.";
  const matches = value.match(/^\s*(\d+)\s*\/\s*(\d+)\s*$/);
  if (matches == null) {
    return errorMessage;
  }
  const systolic = parseInt(matches[1], 10);
  const diastolic = parseInt(matches[2], 10);
  if (diastolic > systolic) {
    return errorMessage;
  }

  return undefined;
};

const isAllZeroes = (value: any) => {
  return value.match(/^0+$/)?.length;
};

export const isValidRXBin = (value: any) => {
  if (value == null) {
    return value;
  }
  if (isNaN(value)) {
    return "Must be only numbers";
  }
  const length = value.toString().length;
  if (length < 4 || length > 6) {
    return "Should be between 4 and 6 numbers";
  }
  if (isAllZeroes(value)) {
    return "Please enter a valid bin number";
  }
  return undefined;
};

export const isValidRXPCN = (value: any) => {
  if (value == null) {
    return value;
  }
  if (value.length >= 10) {
    return "Must be less than 10 characters";
  }
  if (isAllZeroes(value)) {
    return "Please enter a valid RX PCN";
  }
  return undefined;
};

export const isValidRXGroupNumber = (value: any) => {
  if (value == null) {
    return value;
  }
  if (value.length >= 15) {
    return "Must be less than 15 characters";
  }
  if (isAllZeroes(value)) {
    return "Please enter a valid RX PCN";
  }
  return undefined;
};

export const isValidDOB = (value: string, minAge?: number) => {
  const defaultMinAge = typeof minAge === "number" ? minAge : 13;
  if (value == null) {
    return value;
  }

  const today = moment();
  const matches = value.match(_date);
  const future = moment(value, "MM-DD-YYYY").isAfter(today);
  const dateDiff = moment(value).diff(today, "years");
  const maxAge = dateDiff <= -125;

  if (matches == null) {
    return "Please enter your birth date as MM-DD-YYYY";
  }

  if (future) {
    return "Date cannot be in the future";
  }

  if (maxAge) {
    return "Date is too far in the past";
  }

  if (defaultMinAge != null && dateDiff > -defaultMinAge) {
    return `You must be ${defaultMinAge} years or older`;
  }

  return undefined;
};

export const isValidExpirationDate = (value: string) => {
  if (value == null) {
    return value;
  }

  const today = moment();
  const matches = value.match(_date);
  const future = moment(value, "MM-DD-YYYY").isAfter(today);

  if (matches == null) {
    return "Please enter your ID expiration date as MM-DD-YYYY";
  }

  if (!future) {
    return "Date must be in the future";
  }

  return undefined;
};
