/** @format */

import React from "react";
import { Form } from "react-final-form";

import * as Sentry from "@sentry/react";

import { Typography } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import type { ValidatePasswordIO } from "src/v2/utils/auth/password";

import { isAxiosError } from "src/helpers/axios";
import { PasswordField } from "src/v2/components/PasswordField";
import { useStoreDispatch } from "src/v2/models";
import { validatePasswordForm } from "src/v2/utils/auth/password";

export const ResetPassword = (props: any) => {
  const dispatch = useStoreDispatch();
  const [formError, setFormError] = React.useState("");

  const onSubmit = async (values: ValidatePasswordIO) => {
    setFormError("");
    const { resetKey } = props.match.params;
    return dispatch.auth
      .resetPassword({
        token: resetKey,
        requestBody: {
          password: values.password || "",
          password_confirm: values.passwordConfirm || "",
        },
      })
      .then(() => {
        props.history.push("/my-cases");
      })
      .catch((err: any) => {
        if (isAxiosError(err)) {
          if (!err.response?.data.type) {
            Sentry.captureException(err);
          }
          if (err.response?.data.description) {
            setFormError(err.response?.data.description);
          } else {
            setFormError("Something went wrong");
          }
        } else {
          setFormError("Something went wrong");
        }
      });
  };

  return (
    <div className="h-screen w-full flex flex-col justify-center items-center">
      <div className={"bg-sand-40 w-full"} style={{ height: "10px" }}></div>
      <img
        src={"/assets/alpha-logo-forest.svg"}
        style={{ height: "32px" }}
        alt="alpha logo"
        className="mt-8"
      />
      <div className="w-full z-10 flex-1">
        <div className="h-full bg-white flex">
          <div className="mx-auto w-full max-w-md">
            <Form<ValidatePasswordIO>
              onSubmit={onSubmit}
              validate={validatePasswordForm}
              render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} className="mt-2 p-6">
                  <Typography className="text-primary text-center" variant="h2">
                    Reset your password
                  </Typography>
                  <Typography className="text-primary my-6" variant="body1">
                    Please enter your new password
                  </Typography>
                  <PasswordField name={"password"} label="New password (12+ characters)" />
                  <PasswordField
                    label="Confirm new password (12+ characters)"
                    name="passwordConfirm"
                  />
                  <ButtonPdb type={"submit"} className={"w-full"} disabled={submitting}>
                    Reset password
                  </ButtonPdb>
                  {formError && (
                    <Typography variant={"body1"} className={"text-error mt-2"}>
                      {formError}
                    </Typography>
                  )}
                </form>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
