/** @format */

import React from "react";

import type { PeriodItemType } from "src/components/DynamicForm/Fields/CustomFields/BloodLossReporting/constants";

import PeriodItem from "src/components/DynamicForm/Fields/CustomFields/BloodLossReporting/PeriodItem";

interface ReportingSectionProps {
  titleLeft: string;
  titleRight: string;
  periodItems: PeriodItemType[];
  day: number;
}

const ReportingSection = ({ titleLeft, titleRight, periodItems, day }: ReportingSectionProps) => {
  return (
    <div className={"mb-8"}>
      <div className="flex flex-row justify-between">
        <p className="text-sm font-bold w-1/2">{titleLeft}</p>
        <p className="text-sm font-bold">{titleRight}</p>
      </div>
      {periodItems.map(({ imgAlt, imgSrc, formKey, title, subTitle }, index) => {
        return (
          <div
            key={`blood_loss.${day}.${formKey}`}
            className={`py-8 ${index !== periodItems.length - 1 && "border-b-1 border-grey-3"}`}
          >
            <PeriodItem
              imgSrc={imgSrc}
              imgAlt={imgAlt}
              title={title}
              subtitle={subTitle}
              day={day}
              formKey={formKey}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ReportingSection;
