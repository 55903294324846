/** @format */

import { isHypothyroidismPrescriptionInvalid, isPediatricsMinor } from "./_validators/condition";
import { isReSelect, isSoftStop } from "./_validators/soft-stop";
import { isStateValid } from "./_validators/state";
import { hasPastMedicalConditions } from "./_validators/visit";

export interface InvalidType {
  invalid: boolean;
  invalidType: string;
  modalClasses?: string;
  cxPadding?: string;
}
export type IsInvalid<T, U> = (values: T) => U;

export const isInvalidType = (obj: any): obj is InvalidType => {
  return obj.invalid != null;
};

export type EligibilityValidator = (values: any) => InvalidType;

export interface ValidatorsType {
  [type: string]: EligibilityValidator;
}

export const ELIGIBILITY_INSURANCE = "eligibility:insurance";
const ELIGIBILITY_PAST_CONDITIONS = "eligibility:past-conditions";
const ELIGIBILITY_SOFT_STOP = "eligibility:soft-stop-message";
const ELIGIBILITY_VALID_STATE = "eligibility:valid-state";
const ELIGIBILITY_RESELECT = "eligibility:re-select";
const ELIGIBILITY_HYPOTHYROIDISM_PRESCRIPTIONS = "eligibility:hypothyroidism-prescriptions";
const ELIGIBILITY_PEDIATRIC_MINORS = "eligibility:pediatrics-minor";

const Validators: ValidatorsType = {
  [ELIGIBILITY_PAST_CONDITIONS]: hasPastMedicalConditions,
  [ELIGIBILITY_PEDIATRIC_MINORS]: isPediatricsMinor,
  [ELIGIBILITY_SOFT_STOP]: isSoftStop,
  [ELIGIBILITY_VALID_STATE]: isStateValid,
  [ELIGIBILITY_RESELECT]: isReSelect,
  [ELIGIBILITY_HYPOTHYROIDISM_PRESCRIPTIONS]: isHypothyroidismPrescriptionInvalid,
};

export default Validators;
