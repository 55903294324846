/** @format */

import React from "react";

import type { Channel } from "src/v2/models/channels";

import { useUnreadMessages } from "src/utils/hooks/pubnub";

import { ChannelListItem } from "./ChannelListItem";

interface ChannelListProps {
  channels: Channel[];
  channelTypeFilter?: string[];
  isForInbox?: boolean;
}

export const channelTypeMap: Record<string, string> = {
  NOTIFICATION:
    "Receive a variety of notifications and updates including information regarding your Alpha services and general company updates.",
  SUPPORT:
    "Message the Alpha Support team for help with non-clinical questions or concerns, like technical issues, order related issues, or general questions about our services.",
  SERVICE:
    "Message your Alpha Provider with clinical questions or concerns, like questions about your treatment, prescriptions, etc.",
  HEALTH_CHECKIN:
    "Messages from our Alpha Care Team regarding your health check-in will appear in this message thread.",
};

export const ChannelList = ({
  channels = [],
  channelTypeFilter = [],
  isForInbox = false,
}: ChannelListProps) => {
  const unreadMessages = useUnreadMessages();

  // Filter channels based on if Support or Clinical message category is selected
  const filteredChannels = channels.filter(
    (value) =>
      channelTypeFilter.length === 0 ||
      (value.channel_type && channelTypeFilter.includes(value.channel_type)),
  );

  // Sort channels based on read or unread status
  const channelsWithUnreads = filteredChannels.filter(
    (channel) => unreadMessages[channel.name] > 0,
  );
  const channelsWithNoUnreads = filteredChannels.filter(
    (channel) =>
      unreadMessages[channel.name] === 0 || !Object.keys(unreadMessages).includes(channel.name),
  );
  // Determine how many messages to show on All Messages page
  const channelLimit = Math.max(channelsWithUnreads.length, 2);

  // Sort channels based on latest unread message first
  const sortChannels = (a: Channel, b: Channel) => {
    if (a.most_recent_message && b.most_recent_message) {
      return (
        new Date(b.most_recent_message.created_at).getTime() -
        new Date(a.most_recent_message.created_at).getTime()
      );
    } else {
      return a.most_recent_message ? -1 : 1;
    }
  };

  channelsWithUnreads.sort(sortChannels);
  channelsWithNoUnreads.sort(sortChannels);

  const sortedChannels = channelsWithUnreads.concat(channelsWithNoUnreads);

  const channelsToShow = isForInbox ? sortedChannels.splice(0, channelLimit) : sortedChannels;
  return (
    <div className="w-full">
      {channelsToShow.map((channel) => (
        <ChannelListItem
          key={channel.name}
          unreadMessageCount={unreadMessages[channel.name]}
          channel={channel}
        />
      ))}
    </div>
  );
};
