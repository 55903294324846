/** @format */
import PiwikPro, {
  ContentTracking,
  CookieManagement,
  CustomDimensions,
  CustomEvent,
  DataLayer,
  UserManagement,
} from "@piwikpro/react-piwik-pro";
import * as Sentry from "@sentry/react";
// CustomEvent.trackEvent("", "");
import _ from "lodash";
import { v5 as uuidv5 } from "uuid";

import type { AnalyticsEvent, Content, Interaction } from "./types";

// adapters/core.ts
abstract class AnalyticsAdapter {
  abstract setUserUUID(uuid: string): void;
  abstract initialize(config: unknown): void;
  abstract trackEvent(event: AnalyticsEvent, dimensions?: { [key: string]: string }): void;
  abstract trackContentImpression(content: Content): void;
  abstract trackContentInteraction(action: "click", content: Content): void;
}

// adapters/piwik.ts
class PiwikProAdapter extends AnalyticsAdapter {
  initialize = (config: { containerId: string; containerUrl: string }) => {
    PiwikPro.initialize(config.containerId, config.containerUrl);
    CookieManagement.setSecureCookie(true);
  };

  setUserUUID = (uuid: string) => {
    const piwikProUUID = uuidv5("piwik-pro", uuid);
    UserManagement.setUserId(piwikProUUID);
  };

  defineDimension = (dimensions?: { [key: string]: string }) => {
    if (!dimensions) {
      return null;
    }
    const add = ([key, value]: [string, string]) =>
      CustomDimensions.setCustomDimensionValue(key, value);
    const remove = ([key, _value]: [string, string]) => CustomDimensions.deleteCustomDimension(key);
    Object.entries(dimensions).forEach(add);

    return () => Object.entries(dimensions).forEach(remove);
  };

  trackEvent(event: AnalyticsEvent, dimensions?: { [key: string]: string }): void {
    const cleanupDimensions = this.defineDimension(dimensions);
    CustomEvent.trackEvent(event.category, event.action, event.name, event.value);
    if (cleanupDimensions) {
      cleanupDimensions();
    }

    // Push event to the data layer so that it can trigger other tags. The data layer
    // takes a single string for the event name, so we build that by combining the
    // category, action, and name, of the PiwikPro event (e.g. Account:Register).
    const dataLayerEventName = _.chain([event.category, event.action, event.name])
      .compact() // Remove falsy values in case event.name is undefined
      .join(":")
      .value();

    DataLayer.push({ event: dataLayerEventName });
  }

  trackContentImpression(content: Content): void {
    ContentTracking.trackContentImpression(content.name, content.piece, content.target);
  }

  trackContentInteraction(action: Interaction, content: Content): void {
    ContentTracking.trackContentInteraction(action, content.name, content.piece, content.target);
  }
}

type AdapterMap = {
  piwikPro: PiwikProAdapter;
};

type AdapterType = keyof AdapterMap;

type AnalyticsConfig = {
  [Key in AdapterType]?: Parameters<AdapterMap[Key]["initialize"]>;
};

class RootAnalyticsAdapter extends AnalyticsAdapter {
  adapters: Partial<AdapterMap>;

  constructor() {
    super();
    this.adapters = {};
  }

  initialize = (config: AnalyticsConfig) => {
    if (config.piwikPro) {
      this.adapters.piwikPro = new PiwikProAdapter();
      this.adapters.piwikPro.initialize(config.piwikPro[0]);
    }
  };

  setUserUUID = (uuid: string) => {
    Object.values(this.adapters).forEach((adapter) => adapter && adapter.setUserUUID(uuid));
  };

  trackEvent(event: AnalyticsEvent, dimensions?: { [key: string]: string }): void {
    try {
      Object.values(this.adapters).forEach(
        (adapter) => adapter && adapter.trackEvent(event, dimensions),
      );
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  trackContentImpression(content: Content): void {
    try {
      Object.values(this.adapters).forEach(
        (adapter) => adapter && adapter.trackContentImpression(content),
      );
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  trackContentInteraction(action: Interaction, content: Content): void {
    try {
      Object.values(this.adapters).forEach(
        (adapter) => adapter && adapter.trackContentInteraction(action, content),
      );
    } catch (err) {
      Sentry.captureException(err);
    }
  }
}

export const Analytics = new RootAnalyticsAdapter();
