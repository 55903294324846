/** @format */

import type { Action, Dispatch } from "easy-peasy";
import { action } from "easy-peasy";

import type { TopBarNavigationProps } from "src/v2/designSystem";

import { history } from "src/utils/history";

const OnBackCallbackDefault = () => {
  history && history.goBack();
};

const OnHelpCallbackDefault = () => {
  history && history.push("help");
};

const OnLogoCallbackDefault = () => {
  history && history.push("/");
};

const OnLogoutCallbackDefault = (dispatch: Dispatch) => {
  (dispatch as any).auth.logout();
};

export const defaultTopBarValues: TopBarNavigationProps = {
  variant: "top-level",
  withLeftHandNav: true,
  showBackButton: false,
  showMenuButton: false,
  showCloseButton: false,
  showCompleteButton: false,
  showHelpButton: true,
  showNotificationButton: false,
  showLogoutButton: false,
  showSettingsButton: false,
  title: "",
  subtitle: "",
  notifications: undefined,
  isNotificationPageActive: false,
  onBackCallback: OnBackCallbackDefault,
  onHelpCallback: OnHelpCallbackDefault,
  onLogoCallback: OnLogoCallbackDefault,
  onLogoutCallback: OnLogoutCallbackDefault,
  onCloseCallback: undefined,
  onCompleteCallback: undefined,
  onMenuCallback: undefined,
  onNotificationCallback: undefined,
};

export interface TopBarModel extends TopBarNavigationProps {
  setVariant: Action<TopBarModel, TopBarModel["variant"]>;
  setShowBackButton: Action<TopBarModel, boolean | undefined>;
  setShowMenuButton: Action<TopBarModel, boolean | undefined>;
  setShowCloseButton: Action<TopBarModel, boolean | undefined>;
  setShowCompleteButton: Action<TopBarModel, boolean | undefined>;
  setShowHelpButton: Action<TopBarModel, boolean | undefined>;
  setShowLogoutButton: Action<TopBarModel, boolean | undefined>;
  setShowSettingsButton: Action<TopBarModel, boolean | undefined>;
  setWithLeftHandNav: Action<TopBarModel, boolean | undefined>;
  setshowNotificationButton: Action<TopBarModel, boolean | undefined>;
  setTitle: Action<TopBarModel, string | undefined>;
  setSubtitle: Action<TopBarModel, string | undefined>;
  setNotifications: Action<TopBarModel, string | number | undefined>;
  setOnBackCallback: Action<TopBarModel, TopBarModel["onBackCallback"]>;
  setOnCloseCallback: Action<TopBarModel, TopBarModel["onCloseCallback"]>;
  setOnCompleteCallback: Action<TopBarModel, TopBarModel["onCompleteCallback"]>;
  setOnHelpCallback: Action<TopBarModel, TopBarModel["onHelpCallback"]>;
  setOnLogoCallback: Action<TopBarModel, TopBarModel["onLogoCallback"]>;
  setOnMenuCallback: Action<TopBarModel, TopBarModel["onMenuCallback"]>;
  setOnNotificationCallback: Action<TopBarModel, TopBarModel["onNotificationCallback"]>;
  setOnLogoutCallback: Action<TopBarModel, TopBarModel["onLogoutCallback"]>;
  setOnSettingsCallback: Action<TopBarModel, TopBarModel["onSettingsCallback"]>;
  setIsNotificationPageActive: Action<TopBarModel, TopBarModel["isNotificationPageActive"]>;
}

export const topBarStore: TopBarModel = {
  variant: defaultTopBarValues.variant,
  withLeftHandNav: defaultTopBarValues.withLeftHandNav,
  showBackButton: defaultTopBarValues.showBackButton,
  showMenuButton: defaultTopBarValues.showMenuButton,
  showCloseButton: defaultTopBarValues.showCloseButton,
  showCompleteButton: defaultTopBarValues.showCompleteButton,
  showHelpButton: defaultTopBarValues.showHelpButton,
  showLogoutButton: defaultTopBarValues.showLogoutButton,
  showSettingsButton: defaultTopBarValues.showSettingsButton,
  showNotificationButton: defaultTopBarValues.showNotificationButton,
  title: defaultTopBarValues.title,
  subtitle: defaultTopBarValues.subtitle,
  notifications: defaultTopBarValues.notifications,
  onBackCallback: defaultTopBarValues.onBackCallback,
  onHelpCallback: defaultTopBarValues.onHelpCallback,
  onLogoCallback: defaultTopBarValues.onLogoCallback,
  onCloseCallback: defaultTopBarValues.onCloseCallback,
  onCompleteCallback: defaultTopBarValues.onCompleteCallback,
  onMenuCallback: defaultTopBarValues.onMenuCallback,
  onNotificationCallback: defaultTopBarValues.onNotificationCallback,
  onLogoutCallback: defaultTopBarValues.onLogoutCallback,
  onSettingsCallback: defaultTopBarValues.onSettingsCallback,
  isNotificationPageActive: defaultTopBarValues.isNotificationPageActive,

  setVariant: action((state, variant) => {
    state.variant = variant;
  }),
  setShowBackButton: action((state, show) => {
    state.showBackButton = show;
  }),
  setShowMenuButton: action((state, show) => {
    state.showMenuButton = show;
  }),
  setShowCloseButton: action((state, show) => {
    state.showCloseButton = show;
  }),
  setShowCompleteButton: action((state, show) => {
    state.showCompleteButton = show;
  }),
  setShowHelpButton: action((state, show) => {
    state.showHelpButton = show;
  }),
  setShowLogoutButton: action((state, show) => {
    state.showLogoutButton = show;
  }),
  setShowSettingsButton: action((state, show) => {
    state.showSettingsButton = show;
  }),
  setWithLeftHandNav: action((state, show) => {
    state.withLeftHandNav = show;
  }),
  setshowNotificationButton: action((state, show) => {
    state.showNotificationButton = show;
  }),
  setTitle: action((state, title) => {
    state.title = title;
  }),
  setSubtitle: action((state, subtitle) => {
    state.subtitle = subtitle;
  }),
  setNotifications: action((state, notifications) => {
    state.notifications = notifications;
  }),
  setOnBackCallback: action((state, onBackCallback) => {
    state.onBackCallback = onBackCallback;
  }),
  setOnCloseCallback: action((state, onCloseCallback) => {
    state.onCloseCallback = onCloseCallback;
  }),
  setOnCompleteCallback: action((state, onCompleteCallback) => {
    state.onCompleteCallback = onCompleteCallback;
  }),
  setOnHelpCallback: action((state, onHelpCallback) => {
    state.onHelpCallback = onHelpCallback;
  }),
  setOnLogoCallback: action((state, onLogoCallback) => {
    state.onLogoCallback = onLogoCallback;
  }),
  setOnMenuCallback: action((state, onMenuCallback) => {
    state.onMenuCallback = onMenuCallback;
  }),
  setOnNotificationCallback: action((state, onNotificationCallback) => {
    state.onNotificationCallback = onNotificationCallback;
  }),
  setOnLogoutCallback: action((state, onLogoutCallback) => {
    state.onLogoutCallback = onLogoutCallback;
  }),
  setOnSettingsCallback: action((state, onSettingsCallback) => {
    state.onSettingsCallback = onSettingsCallback;
  }),
  setIsNotificationPageActive: action((state, active) => {
    state.isNotificationPageActive = active;
  }),
};
