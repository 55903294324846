/** @format */

import type React from "react";
import type { FieldInputProps, FieldMetaState } from "react-final-form";

import { useLocation } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

import Cleave from "cleave.js/react";
import type { SubmissionErrors } from "final-form";

import { NumberField, SimpleForm, Typography } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import type { EligibilityLookUpType } from "src/routes/BenefitHolderRegistration";

import { ActivateBenefitsContent } from "src/content/v2/routes/ActivateBenefits";
import { BenefitHolderRegistrationContent } from "src/content/v2/routes/BenefitHolderRegistration";
import { ErrorPrompt } from "src/v2/components/Svgs/ErrorPrompt";

import { LookupSecondaryButton } from "./LookupSecondaryButton";

interface ChangeEvent<T> extends React.ChangeEvent<T> {
  target: { rawValue: string } & EventTarget & T;
}

interface PhoneLookupFormComponentProps {
  lookupType: EligibilityLookUpType;
  formError: string;
  successUrl: string;
  clearFormError: () => void;
  onSubmitLookupForm: (
    values: any,
    lookupType: EligibilityLookUpType,
    successUrl: string,
  ) => SubmissionErrors | Promise<SubmissionErrors> | void;
}
export const PhoneLookupForm = ({
  lookupType,
  formError,
  successUrl,
  clearFormError,
  onSubmitLookupForm,
}: PhoneLookupFormComponentProps) => {
  const location = useLocation();
  const content = location.pathname.includes("activate-benefits")
    ? ActivateBenefitsContent
    : BenefitHolderRegistrationContent;

  return (
    <div>
      <div className="flex justify-center">
        <div>
          <Typography variant="h3" className="mb-4">
            {!formError ? content.phoneLookUp?.title : "We're having trouble finding this account."}
          </Typography>
          {!formError && <Typography variant="body1">{content.phoneLookUp?.body}</Typography>}
        </div>
      </div>
      <div>
        <SimpleForm<PhoneLookupFormProps>
          hideDebugInfo={false}
          onSubmit={(values) => {
            return onSubmitLookupForm(values, lookupType, successUrl);
          }}
          validate={(values: PhoneLookupFormProps) => {
            const errors = {};
            if (!values.phone_number) {
              errors["phone_number"] = "A ten digit phone number is required";
            }
            if (values.phone_number?.length < 10) {
              errors["phone_number"] = "A ten digit phone number is required";
            }
            return errors;
          }}
        >
          {({ form }) => {
            const formState = form.getState();
            return (
              <div className={"mt-6 h-full"}>
                {formError ? (
                  <div>
                    <p dangerouslySetInnerHTML={{ __html: formError }} />
                    <ButtonPdb
                      className={"mt-12 w-full"}
                      onClick={() => {
                        clearFormError();
                      }}
                    >
                      Try Again
                    </ButtonPdb>
                  </div>
                ) : (
                  <>
                    {/* TODO: Add this style of number field to alpha-js-packages and replace the below code. */}
                    <p
                      className={`text-sm mb-2 ${
                        formState.errors?.phone_number && formState.touched?.phone_number
                          ? "text-error"
                          : ""
                      }`}
                    >
                      Your phone number
                    </p>
                    <NumberField name={"phone_number"} placeholder={"e.g. 415-663-5584"}>
                      {({
                        input,
                        meta,
                      }: {
                        input: FieldInputProps<any>;
                        meta: FieldMetaState<any>;
                      }) => {
                        const dirtyError = (meta.touched && meta.error) || meta.submitError;
                        return (
                          <>
                            <Cleave
                              className={`py-3 px-2 text-sm bg-transparent text-grey-160 field-placeholder
                          w-full border-2 ${
                            dirtyError ? "border-red" : "border-grey-100 "
                          } focus:outline-none rounded leading-6`}
                              placeholder="e.g. 415-663-5584"
                              value={input.value}
                              options={{
                                numericOnly: true,
                                blocks: [3, 3, 4],
                                delimiter: "-",
                                delimiterLazyShow: true,
                              }}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                form.change("phone_number", e.target.rawValue);
                              }}
                            />
                            <input {...input} className={"hidden"} />
                            {dirtyError && <ErrorPrompt text={meta.error || meta.submitError} />}
                          </>
                        );
                      }}
                    </NumberField>
                    <ButtonPdb
                      disabled={formState.submitting}
                      className={"flex w-full justify-center items-center mt-8 mb-4"}
                    >
                      {formState.submitting ? (
                        <ClipLoader color={"#A4B7B5"} loading={formState.submitting} />
                      ) : (
                        content.phoneLookUp?.cta[0]?.text
                      )}
                    </ButtonPdb>
                    <LookupSecondaryButton
                      clearFormError={clearFormError}
                      content={content.phoneLookUp?.cta[1]?.text}
                      redirect={content.phoneLookUp?.cta[1]?.to}
                    />
                    {formState.submitting && <div className={"flex justify-center my-10"}></div>}
                  </>
                )}
              </div>
            );
          }}
        </SimpleForm>
      </div>
    </div>
  );
};

interface PhoneLookupFormProps {
  name: string;
  phone_number: string;
}
