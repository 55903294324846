/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PartnershipSettingsSchema } from '../models/PartnershipSettingsSchema';
import type { ProviderCoverageSetPatientWaitForPcpRequestBodySchema } from '../models/ProviderCoverageSetPatientWaitForPcpRequestBodySchema';
import type { WaitForPCPSchema } from '../models/WaitForPCPSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProviderCoverageService {

    /**
     * @returns PartnershipSettingsSchema
     * @throws ApiError
     */
    public static getPartnershipSettings(): CancelablePromise<PartnershipSettingsSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/provider-coverage/partnerships-settings',
        });
    }

    /**
     * @returns WaitForPCPSchema
     * @throws ApiError
     */
    public static getPatientWaitForPcp({
        userId,
        entityId,
        entityType,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        entityId: string,
        entityType: 'consult' | 'channel',
    }): CancelablePromise<WaitForPCPSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/wait-pcp/{entity_type}/{entity_id}',
            path: {
                'user_id': userId,
                'entity_id': entityId,
                'entity_type': entityType,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static setPatientWaitForPcp({
        userId,
        entityId,
        entityType,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        entityId: string,
        entityType: 'consult' | 'channel',
        requestBody: ProviderCoverageSetPatientWaitForPcpRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/{user_id}/wait-pcp/{entity_type}/{entity_id}',
            path: {
                'user_id': userId,
                'entity_id': entityId,
                'entity_type': entityType,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
