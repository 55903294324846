/** @format */

import type { match } from "react-router-dom";

import React from "react";
import { useHistory } from "react-router-dom";

import moment from "moment";

import { Paper } from "src/v2/designSystem";

import type { Service } from "src/v2/models/service";

import { ActionItemsService } from "src/api";
import { PageWrapper } from "src/components/PageWrapper";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useStoreDispatch } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";
import VisitCtaCard from "src/v2/routes/Visit/VisitCtaCard";
import VisitInformation from "src/v2/routes/Visit/VisitInformation";
import { getLoadedReturnVisitByService } from "src/v2/utils/returnVisitUtils";

interface MatchParams {
  condition_key: string;
}

const dueContent =
  "Submit your return visit to continue your care. Your provider will review your treatment plan and can refill prescriptions.";

const upcomingContent =
  "It’s too early to complete this return visit. If you have questions about your current treatment plan, message your Alpha care team.";

const ReturnVisitDetails = ({ match }: { match: match<MatchParams> }) => {
  const history = useHistory();
  useTopBar({ title: "Your return visit", variant: "nested", showBackButton: true });
  const dispatch = useStoreDispatch();
  const user = useCurrentUser();
  const condition_key = match.params.condition_key;
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [returnVisit, setReturnVisit] = React.useState<any>();

  const handleClick = (): void => {
    if (returnVisit.returnVisitStatus === "due") {
      history.push(`/return-consult/${condition_key}-checkup`);
    } else {
      history.push("/messages");
    }
  };

  React.useEffect(() => {
    ActionItemsService.fetchReturnVisits({ userId: user.id })
      .then((returnVisits) => {
        dispatch.service.fetchAllServices({ include: ["consults"] }).then((services: Service[]) => {
          const relatedService = services.filter(
            (service) => service.condition && service.condition?.key === condition_key,
          )[0];
          const visitForService = getLoadedReturnVisitByService(relatedService, returnVisits);
          setReturnVisit(visitForService);
        });
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      <PageWrapper cxPadding="px-0" fitToPageHeight>
        <Paper elevation={0} rounded={false} className="flex-1 mx-auto w-full max-w-2xl px-6">
          <div className="mx-auto max-w-lg pt-10">
            {!isLoading && returnVisit && (
              <>
                <VisitInformation
                  content={returnVisit.returnVisitStatus === "due" ? dueContent : upcomingContent}
                  subtitle={`Due on ${moment(new Date(returnVisit.trigger_at)).format("MMM D, YYYY")}`}
                  status={returnVisit.returnVisitStatus.toUpperCase()}
                  condition_key={condition_key}
                  ctaText={
                    returnVisit.returnVisitStatus === "due" ? "Submit visit" : "Send message"
                  }
                  ctaCallback={() => handleClick()}
                  buttonVariant={returnVisit.returnVisitStatus === "due" ? "primary" : "secondary"}
                />
                {returnVisit.latestConsultStatus === "APPROVED" && (
                  <>
                    <div className="w-full border border-t border-grey-60 mt-6" />
                    <VisitCtaCard
                      icon="/assets/treatment_plan.svg"
                      iconAlt="Treatment Plan icon"
                      text="Visit details"
                      callBack={() => history.push(`/visit-details/${condition_key}`)}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </Paper>
      </PageWrapper>
    </>
  );
};

export default WithTopBarNavigation(ReturnVisitDetails);
