/** @format */

import React from "react";

import classNames from "classnames";
import _ from "lodash";

import type { DynamicField as IDynamicField } from "src/components/DynamicForm/types";
import type { FetchProfileResponse } from "src/v2/models/api_types";

import { DefaultValueContext } from "src/components/DynamicForm/DynamicForm";
import BloodLossReporting from "src/components/DynamicForm/Fields/CustomFields/BloodLossReporting/BloodLossReporting";
import { fieldParsers, fieldValidators } from "src/components/DynamicForm/Processors";
import {
  isFileField,
  isNumberField,
  isOptionField,
  isTextField,
} from "src/components/DynamicForm/types";
import { FieldHeight } from "src/v2/components/Page/fields/field-height";

import type { FieldWrapperProps } from "./DynamicFieldWrapper";

import { CustomFields } from "./CustomFields";
import { AllergySearchField } from "./CustomFields/AllergyForm/AllergySearchField";
import { ProblemSearchField } from "./CustomFields/ProblemsSearchField/ProblemSearchField";
import { FieldWrapper } from "./DynamicFieldWrapper";
import { DynamicFileFieldComponent } from "./DynamicFileField";
import { DynamicNumberField } from "./DynamicNumberField";
import { DynamicOptionField } from "./DynamicOptionField";
import { DynamicTextField } from "./DynamicTextField";

export interface DynamicFieldProps extends FieldWrapperProps {
  field: IDynamicField;
  profile?: FetchProfileResponse;
  hideFieldWrapper?: boolean;
  errorType?: string;
  errorMessage?: string;
}

export const DynamicFieldInput = ({
  field,
  profile,
  errorType,
  errorMessage,
}: DynamicFieldProps) => {
  const defaultValues = React.useContext(DefaultValueContext);

  const defaultValue =
    field.defaultValue != null ? field.defaultValue : _.get(defaultValues, field.key);

  // Define common props that will be passed to all fields
  const parse = field.parse && fieldParsers[field.parse];
  if (!parse && field.parse) {
    console.log(`Unable to find callback for field parser '${field.parse}'`);
  }

  const validate = field.validate && fieldValidators[field.validate];
  if (!validate && field.validate) {
    console.log(`Unable to find callback for field validator '${field.validate}'`);
  }

  const standardFieldProps = {
    name: field.key,
    required: field.required,
    placeholder: field.placeholder ? field.placeholder : field.label,
    defaultValue,
    parse,
    validate,
  };

  /** Final Form behaves wierdly if undefined is passed in as a prop for these functions. To get around this remove them
   * if undefined so that they are not passed in as props in the first place.
   */
  if (!parse) {
    delete standardFieldProps.parse;
  }
  if (!validate) {
    delete standardFieldProps.validate;
  }

  if (field.component) {
    const CustomFieldComponent = CustomFields[field.component];
    if (CustomFieldComponent) {
      return (
        <CustomFieldComponent field={field} errorType={errorType} errorMessage={errorMessage} />
      );
    } else {
      console.log(`Unable to render custom field '${field.component}'`);
    }
  }

  if (isTextField(field)) {
    return <DynamicTextField field={field} {...standardFieldProps} />;
  } else if (isNumberField(field)) {
    return <DynamicNumberField {...standardFieldProps} field={field} />;
  } else if (isOptionField(field)) {
    return <DynamicOptionField {...standardFieldProps} field={field} />;
  } else if (isFileField(field)) {
    return <DynamicFileFieldComponent {...standardFieldProps} field={field} />;
  } else if (field.type === "height") {
    return <FieldHeight field={field} />;
  } else if (field.type === "allergy-search-field") {
    return profile ? <AllergySearchField field={field} profile={profile} /> : null;
  } else if (field.type === "problem-search-field") {
    return <ProblemSearchField field={field} />;
  } else if (field.type === "spacer" || field.type === "info") {
    return <div className="h-2" />;
  } else if (field.type === "BloodLossReporting") {
    return <BloodLossReporting {...standardFieldProps} field={field} />;
  }
  return null;
};

export const DynamicField = ({
  hideFieldWrapper,
  hideFooter,
  className,
  ...props
}: DynamicFieldProps) => {
  if (hideFieldWrapper) {
    return <DynamicFieldInput {...props} />;
  }

  return (
    <FieldWrapper
      field={props.field}
      hideFooter={hideFooter}
      className={classNames("py-1", className)}
    >
      <div className="pb-1 pt-2">
        <DynamicFieldInput {...props} />
      </div>
    </FieldWrapper>
  );
};
