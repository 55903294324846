/** @format */

import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";

import cn from "classnames";
import { size } from "lodash";

import { Paper } from "src/v2/designSystem";

import { PageWrapper } from "src/components/PageWrapper";
import { useChildRedirect } from "src/utils/hooks";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useCurrentUser } from "src/v2/models/profile";

const MedicalRecords = () => {
  useTopBar({ title: "My Health Records", variant: "nested", showBackButton: true });
  useChildRedirect();
  const user = useCurrentUser();
  const [links, setLinks] = React.useState([
    {
      title: "Your Post-Visit Summaries",
      url: "/post-visit-summary",
      description: "Review visit details and treatment plans.",
    },
    {
      title: "Labs",
      url: "/your-care/labs",
      description: "Submit results for your provider to review or view past labs.",
    },
    {
      title: "Weight loss journal",
      url: "/weight-loss-journal",
      description: "Record your body measurements and track your weight loss. ",
    },
    {
      title: "Mental health records",
      url: "/mental-health-records",
    },
  ]);

  React.useEffect(() => {
    if (!user.is_new_patient) {
      setLinks([
        ...links,
        {
          title: "Request Alpha medical records",
          url: "/medical-records-request",
        },
      ]);
    }
  }, [user.is_new_patient]);

  return (
    <PageWrapper cxPadding="px-0" fitToPageHeight>
      <Paper
        elevation={0}
        rounded={false}
        className="flex-1 mx-auto h-screen w-full max-w-2xl px-6 overflow-hidden pb-24"
      >
        <div className="flex flex-1 flex-col mt-6">
          {links.map((link, index) => {
            return (
              <Link
                key={link.url}
                to={`${link.url}`}
                className={cn("flex justify-between items-start my-2 pb-4", {
                  "border-b border-grey-light": index < size(links),
                })}
              >
                <div className="flex flex-1 items-start justify-start">
                  <div>
                    <p className="text-primary text-base w-full mb-1">{link.title}</p>
                    <p className="text-primary text-xs w-full">{link.description}</p>
                  </div>
                </div>
                <img
                  src={"/assets/carrot-right-primary.svg"}
                  alt={`View ${link.title}`}
                  className="w-2 mt-2"
                />
              </Link>
            );
          })}
        </div>
      </Paper>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(MedicalRecords);
