/** @format */

import React from "react";

import { BadgePdb } from "src/v2/designSystem";

import type { BaseCardProps } from "src/components/Cards/BaseCard";

import { BaseCard } from "src/components/Cards/BaseCard";
import { ChannelListIcon } from "src/components/Chat/ChannelList/ChannelListIcon";
import config from "src/config";
interface TeamCardParams extends BaseCardProps {
  team: string;
  iconSrc: string;
}

export const TeamCard = ({ team, iconSrc, ...baseCardProps }: TeamCardParams) => {
  return (
    <BaseCard {...baseCardProps} icon={<ChannelListIcon src={iconSrc} />} title={team}>
      {config.responseTimeMessage && (
        <div className="flex">
          <BadgePdb
            variant="brandLight"
            label={config.responseTimeMessage}
            textTransform="normal-case"
          />
        </div>
      )}
    </BaseCard>
  );
};
