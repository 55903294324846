/** @format */

import React from "react";
import ReactMarkdown from "react-markdown";

import { Typography } from "@alphamedical/components";
import { Accordion } from "src/v2/designSystem";

import type { FaqsDetails } from "./noticePage.types";

export const FrequentlyAskedQuestions = ({ faqs, title }: FaqsDetails) => {
  const formatContent = (answers: any) => {
    return (
      <div className="flex flex-col">
        {answers.map((answer: any, idx: number) => {
          return (
            <ReactMarkdown skipHtml key={idx} className="pb-2">
              {answer}
            </ReactMarkdown>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <Typography variant="h2">{title}</Typography>
      <Accordion
        variant="helper"
        expandMultiple={false}
        sections={faqs.map((faq: any, idx: number) => {
          return {
            id: idx,
            title: faq.question,
            content: formatContent(faq.answers),
            showDivider: idx + 1 < faqs.length,
            showIcon: false,
          };
        })}
      />
    </>
  );
};
