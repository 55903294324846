/** @format */

import type { ReactNode } from "react";

import React from "react";

import { Tooltip } from "@alphamedical/components";

import { formatPrice } from "./utils";

interface PaymentLineItemProps {
  description: string | JSX.Element;
  cost?: number | string;
  costColor?: string;
  isMinimum?: boolean;
  cxDescriptionStyles?: string;
  inlineStyles?: any;
  onClick?: (boolean: boolean) => void;
  tooltip?: boolean;
  tooltipContent?: string | ReactNode;
  textSize?: string;
}

export const PaymentLineItem = ({
  description,
  cost,
  costColor,
  isMinimum,
  cxDescriptionStyles,
  inlineStyles,
  onClick,
  tooltip,
  tooltipContent,
  textSize,
}: PaymentLineItemProps) => {
  const cxCostColor = costColor ? `text-${costColor}` : "";
  return (
    <div className="flex justify-center my-3">
      <div className="flex-1">
        <div className="flex items-center">
          <div
            style={inlineStyles}
            className={`${cxDescriptionStyles} ${textSize}`}
            onClick={() => onClick && onClick(true)}
          >
            {description}
          </div>
          {tooltip && (
            <div className="w-4 ml-2">
              <Tooltip content={tooltipContent}>
                <img src="/assets/help.svg" />
              </Tooltip>
            </div>
          )}

          {cost && cost < 0 && (
            <div className="w-4 ml-1">
              <img src="/assets/discount.svg" alt="discount" className="ml-2" />
            </div>
          )}
        </div>
      </div>
      <div className={`ml-4 text-right ${cxCostColor} ${textSize}`}>
        {isMinimum && "As low as "}
        {typeof cost === "number" ? formatPrice(cost) : cost}
      </div>
    </div>
  );
};
