/** @format */
import type { match } from "react-router-dom";

import React from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import _ from "lodash";

type GetDefaultPageKeyCallable<T extends object> = (routeMatch: match<T>) => string | undefined;

interface DynamicFormRouteMatch {
  page?: string;
}

export const useURLNavigationController = <T extends DynamicFormRouteMatch>(
  baseUrl: string,
  defaultPageKey?: string | GetDefaultPageKeyCallable<T>,
) => {
  const history = useHistory();
  const location = useLocation();
  const routeMatch = useRouteMatch<T>();
  const [pageKey, setPageKey] = React.useState<string | undefined>(() => {
    return _.isFunction(defaultPageKey) ? defaultPageKey(routeMatch) : defaultPageKey;
  });

  React.useEffect(() => {
    // We only want to push to history if the pageKey
    // and current page key are different
    // We expect that key to be `page` for now. (although it may be differnent based on t)
    const currentPageKey = routeMatch.params.page || "";
    if (pageKey && pageKey !== currentPageKey) {
      history.push({ pathname: `${baseUrl}/${pageKey}`, search: location.search });
    }
  }, [pageKey, baseUrl]);

  return { pageKey, setPageKey };
};

export const useDynamicFormUrl = (pageKey?: string) => {
  const location = useLocation();
  const route = useRouteMatch<DynamicFormRouteMatch>();

  const currentPage = route.params.page;
  return currentPage != null
    ? `${location.pathname}${location.search}`.replace(currentPage, pageKey || "payment")
    : null;
};

export const useMobileResponsive = () => {
  const [width, setWidth] = React.useState<number>(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return { isMobile: width <= 768, width: width };
};

export const useAudio = (url: string) => {
  const [audio] = React.useState(new Audio(url));
  const [playing, setPlaying] = React.useState(false);

  const toggle = () => setPlaying(!playing);

  React.useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  React.useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  return { playing, toggle };
};
