/** @format */

import React from "react";

import type { ExternalSubscription, Subscription } from "src/v2/types/subscription";

import { PrescriptionCard } from "src/components/Cards/PrescriptionCard";

interface YourPrescriptionCardsProps {
  subscriptions: Subscription[];
  condition: string;
  externalSubscriptions: ExternalSubscription[];
}

export const YourPrescriptionCards = ({
  subscriptions,
  condition,
  externalSubscriptions,
}: YourPrescriptionCardsProps) => {
  const detailListType = condition === "sti-testing-kit" ? condition : "alpha-medication";

  return (
    <div>
      {subscriptions
        .filter((subscription) => subscription.sku && subscription.sku.type !== "test_kit")
        .map((subscription) => (
          <div key={subscription.id}>
            {subscription.current_sku && subscription.current_sku !== "external_sku" && (
              <PrescriptionCard
                condition={condition}
                subscription={subscription}
                detailListType={detailListType}
              />
            )}
          </div>
        ))}
      {externalSubscriptions.map((externalSubscription) => {
        return (
          <div key={externalSubscription.name}>
            <PrescriptionCard
              condition={condition}
              externalSubscription={externalSubscription}
              detailListType={"external-medication"}
            />
          </div>
        );
      })}
    </div>
  );
};
