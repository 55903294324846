/** @format */
import { isArray, join, map, pick } from "lodash";

/*
 * <param> search: query param string value, i.e;: "?showPopUp=true"
 * <param> queryKey: The key you want to get a value for. i.e.: "showPopUp"
 * Returns the value of the queryKey passed in. i.e.; "true"
 */

export const getQuery = (search: string, queryKey: string) => {
  return new URLSearchParams(search).get(queryKey);
};

/*
  Turns objects and arrays (and array of objects) into strings for use as useEffect dependencies
  If keys are provided, the serialized dependency will only use those keys (works when dependency is an object or an array of objects)
  Using this function without keys should be reserved for small and shallow dependencies to preserve performance
*/

const serializeItem = (item?: Record<string, any>, keys?: string[]) =>
  keys ? JSON.stringify(pick(item, keys)) : JSON.stringify(item);

export const serializeDependency = (dependency?: any[] | Record<string, any>, keys?: string[]) => {
  if (isArray(dependency)) {
    return join(
      map(dependency, (item) => serializeItem(item, keys)),
      ",",
    );
  }
  return serializeItem(dependency, keys);
};
