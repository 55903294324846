/** @format */

import type React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Typography } from "@alphamedical/components";

interface VisitCtaCardProps {
  icon?: string;
  iconElement?: React.ReactNode;
  iconWidth?: string;
  iconAlt?: string;
  text?: string;
  subText?: string;
  showBottomDivider?: boolean;
  callBack?: () => void;
}

const VisitCtaCard = (props: VisitCtaCardProps) => {
  const {
    text,
    icon,
    iconAlt,
    callBack,
    iconElement,
    iconWidth,
    subText,
    showBottomDivider = true,
  } = props;
  return (
    <>
      <button className="w-full flex p-4 items-center" disabled={!callBack} onClick={callBack}>
        <div className="text-forest-100">
          {!iconElement ? (
            <img src={icon} alt={iconAlt} className="max-h-6 max-w-6" />
          ) : (
            <div
              style={{ width: iconWidth || "24px" }}
              className="text-center text-grey-160 overflow-hidden"
            >
              {iconElement}
            </div>
          )}
        </div>
        <div className="flex pl-4 justify-between items-center text-forest-120 w-full">
          <div className="flex flex-col items-start pb-1">
            <Typography variant="h4" component="p" align="left">
              {text}
            </Typography>
            {subText && (
              <Typography variant="body2" component="p" align="left" className="text-grey-160">
                {subText}
              </Typography>
            )}
          </div>
          {callBack && (
            <FontAwesomeIcon size="lg" color="#656E6D" icon={["fas", "chevron-right"]} />
          )}
        </div>
      </button>
      {showBottomDivider && <div className="w-full border-t border-grey-60" />}
    </>
  );
};

export default VisitCtaCard;
