/** @format */

import type React from "react";

import { Field, Form } from "react-final-form";

import { isEmpty } from "lodash";

import { CheckboxNewField } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import type { InsuranceSchema } from "src/api/models/InsuranceSchema";

import { DynamicField } from "src/components/DynamicForm";
import { DynamicFileFieldComponent } from "src/components/DynamicForm/Fields/DynamicFileField";

import {
  CARRIER_OPTIONS,
  isPrimaryCardholder,
  processInsuranceFormValues,
  RELATIONSHIP_OPTIONS,
  validation,
} from "./utils";

export interface InsuranceFormValues extends InsuranceSchema {
  primary_cardholder_checkbox?: { label: string; value: string }[];
  is_federally_funded_plan_checkbox?: { label: string; value: string }[];
}

interface InsuranceFormProps {
  onSubmit: (values: InsuranceFormValues) => void;
  initialValues?: InsuranceFormValues;
}

export const CardholderRelationship = () => (
  <>
    <DynamicField
      field={{
        key: "cardholder_first_name",
        label: "Primary Cardholder First Name",
        type: "text",
        required: true,
      }}
    />
    <DynamicField
      field={{
        key: "cardholder_last_name",
        label: "Primary Cardholder Last Name",
        type: "text",
        required: true,
      }}
    />
    <DynamicField
      field={{
        key: "relationship_code",
        label: "Relationship",
        placeholder: "Select",
        required: true,
        type: "select",
        options: RELATIONSHIP_OPTIONS,
        subtype: "dropdown",
      }}
    />
  </>
);

const InsuranceForm: React.FC<InsuranceFormProps> = ({ onSubmit, initialValues = {} }) => {
  return (
    <Form
      onSubmit={(values) => onSubmit(processInsuranceFormValues(values))}
      validate={validation}
      initialValues={{
        ...initialValues,
        primary_cardholder_checkbox: isPrimaryCardholder(initialValues) ? ["option1"] : [],
        is_federally_funded_plan_checkbox: initialValues.is_federally_funded_plan
          ? ["federally_funded_plan"]
          : [],
      }}
    >
      {({ handleSubmit, submitting, values, form, dirty }) => {
        const validFormValues =
          values.cardholder_id &&
          values.rx_bin &&
          values.rx_pcn &&
          !form.getState().errors?.rx_bin &&
          values.carrier;
        if (values.carrier && values.carrier != "other") {
          form.change("carrier_other", "");
        }
        return (
          <form onSubmit={handleSubmit} className="flex flex-col mt-8 pb-4">
            <CheckboxNewField
              name="primary_cardholder_checkbox"
              options={[{ label: "Yes, I am the primary card holder", value: "option1" }]}
            />
            {isEmpty(values.primary_cardholder_checkbox) && (
              <div>
                <CardholderRelationship />
              </div>
            )}
            <Field name="id" component="input" type="hidden" />
            <DynamicField
              field={{
                key: "carrier",
                label: "Insurance carrier",
                placeholder: "Select",
                type: "select",
                options: CARRIER_OPTIONS,
                subtype: "dropdown",
                required: true,
              }}
            />
            <div hidden={values.carrier != "other"}>
              <DynamicField
                field={{ key: "carrier_other", label: "Please specify", type: "text" }}
              />
            </div>
            <DynamicField
              field={{ key: "cardholder_id", label: "Member ID", type: "text", required: true }}
            />
            <DynamicField
              field={{
                key: "rx_bin",
                label: "RxBin",
                required: true,
                type: "text",
                validate: "isValidRXBin",
              }}
            />
            <DynamicField
              field={{
                key: "rx_pcn",
                label: "Rx PCN",
                type: "text",
                required: true,
                validate: "isValidRXPCN",
              }}
            />
            <DynamicField
              field={{
                key: "group_number",
                label: "Rx Group / Group Number",
                type: "text",
                required: true,
                validate: "isValidRXGroupNumber",
              }}
            />
            <CheckboxNewField
              name="is_federally_funded_plan_checkbox"
              options={[
                {
                  label: "This a Medicare, Medicaid, or other state- or federally-funded plan",
                  value: "federally_funded_plan",
                },
              ]}
            />
            <DynamicFileFieldComponent
              key="front_photo"
              showLabelForImage={true}
              field={{
                key: "front_photo",
                accepts: "image/*",
                category: "insurance",
                label: "Front of your insurance card",
                type: "file",
              }}
              version={3}
            />
            <DynamicFileFieldComponent
              key="back_photo"
              showLabelForImage={true}
              field={{
                key: "back_photo",
                accepts: "image/*",
                category: "insurance",
                label: "Back of your insurance card",
                type: "file",
              }}
              version={3}
            />
            <ButtonPdb
              type="submit"
              className="my-4"
              disabled={!dirty || submitting || !validFormValues}
            >
              {submitting ? "Saving..." : "Save"}
            </ButtonPdb>
          </form>
        );
      }}
    </Form>
  );
};

export default InsuranceForm;
