/** @format */

import type { FormRenderProps } from "react-final-form";

import React from "react";
import { Form } from "react-final-form";

import type { FormApi } from "final-form";
import _ from "lodash";

import { DynamicComponent } from "src/components/DynamicComponent";
import { Loader } from "src/components/Loader";

import type { PageFormWrapperProps } from "./types";

export const PageFormWrapper = ({ form, page, children, ...props }: PageFormWrapperProps) => {
  const _onSubmit = (values: object, formApi: FormApi) => {
    return form.onSubmit(values, formApi);
  };

  const content = (renderProps: FormRenderProps) => (
    <div className={props.scrollContent ? "flex-1 overflow-y-auto" : ""}>
      {_.isFunction(children) ? children(renderProps) : children}
    </div>
  );

  return (
    <Form {...form} onSubmit={_onSubmit}>
      {(renderProps: FormRenderProps) => (
        <Loader show={renderProps.submitting || !!props.loading}>
          {page.title && <div className="text-3xl my-2">{page.title}</div>}
          {page.subtitle && <div className="text-2xl my-2">{page.subtitle}</div>}
          {page.description && (
            <DynamicComponent
              stringWrapperProps={{ className: "text-base my-2" }}
              renderStringAsHtml={true}
              content={page.description}
            />
          )}
          <form onSubmit={renderProps.handleSubmit} className="flex-1 flex flex-col min-h-0">
            {content(renderProps)}
            {!props.customNext && (
              <button className={`btn ${page.overrideButtonColor || "btn-primary"} mt-3 w-full`}>
                {page.overrideNextText || "Next"}
              </button>
            )}
            {renderProps.hasSubmitErrors && (
              <span className="text-red">{renderProps.submitError}</span>
            )}
          </form>
        </Loader>
      )}
    </Form>
  );
};
