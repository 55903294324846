/** @format */

import type { Action, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";

import type {
  ExternalSubscription,
  RecurlySubscription,
  Subscription,
} from "src/v2/types/subscription";

import { _GET, getTokenHeaderV2 } from "src/helpers/http";

import type { CreateModel } from "./_create";

type PlanType = "mental-health" | "membership";

export interface SubscriptionsModel {
  subscriptions: Subscription[];
  recurlySubscription?: RecurlySubscription[];
  Subscriptions?: boolean;
  Subscription?: number | false;
  externalSubscriptions: ExternalSubscription[];

  setSubscriptions: Action<SubscriptionsModel, Subscription[]>;

  setRecurlySubscriptions: Action<SubscriptionsModel, any>;

  setExternalSubscriptions: Action<SubscriptionsModel, any>;

  setSubscriptionsBoolean: Action<SubscriptionsModel, boolean>;

  getSubscriptionByCondition: Thunk<SubscriptionsModel, string>;

  fetchSubscriptions: Thunk<SubscriptionsModel, { params: { [x: string]: any } } | void>;

  fetchRecurlySubscriptions: Thunk<SubscriptionsModel, PlanType>;

  fetchExternalSubscriptions: Thunk<SubscriptionsModel, string>;
}

export const subscriptionsStore: SubscriptionsModel = {
  subscriptions: [],
  externalSubscriptions: [],

  setSubscriptions: action((state, subscriptions) => {
    state.subscriptions = [...subscriptions];
    state.Subscriptions = false;
  }),

  setRecurlySubscriptions: action((state, payload) => {
    state.recurlySubscription = payload;
    state.Subscriptions = false;
  }),

  setExternalSubscriptions: action((state, payload) => {
    state.externalSubscriptions = payload;
    state.Subscriptions = false;
  }),

  setSubscriptionsBoolean: action((state, boolean) => {
    state.Subscriptions = boolean;
  }),

  getSubscriptionByCondition: thunk(async (actions, condition, { getState }) => {
    let state = getState();
    if (!state.subscriptions.length) {
      await actions.fetchSubscriptions();
    }
    state = getState();
    return state.subscriptions.find(
      (subscription: Subscription) => subscription.condition.key === condition,
    );
  }),

  fetchSubscriptions: thunk(async (actions, payload, { getState, getStoreState }) => {
    const state = getState();
    if (state.Subscriptions) {
      console.warn("Already fetching subscriptions...please wait");
      return;
    }
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;
    actions.setSubscriptionsBoolean(true);
    let paramStr = "";
    if (!!payload && !!payload.params) {
      paramStr =
        "?" +
        Object.keys(payload.params)
          .map((k) => `${k}=${payload.params[k]}`)
          .join("&");
    }
    const url = `/v2/users/${currentProfileId}/subscriptions${paramStr}`;
    const subscriptions: Subscription[] = await _GET(url, {}, getTokenHeaderV2());
    actions.setSubscriptions(subscriptions);
  }),

  fetchRecurlySubscriptions: thunk(async (actions, plan_type, { getStoreState }) => {
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;
    actions.setSubscriptionsBoolean(true);
    const url = `/v2/users/${currentProfileId}/recurly/subscriptions?plan_type=${plan_type}&status=ACTIVE`;
    const response = await _GET(url, {}, getTokenHeaderV2());
    actions.setRecurlySubscriptions(response);
  }),

  // An external subscription is just a subscription where subscription.current_sku === "external_sku"
  fetchExternalSubscriptions: thunk(async (actions, condition, { getStoreState }) => {
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;
    actions.setSubscriptionsBoolean(true);
    const url = `/v2/users/${currentProfileId}/external-subscriptions?conditionKey=${condition}`;
    const response = await _GET(url, {}, getTokenHeaderV2());
    actions.setExternalSubscriptions(response);
  }),
};
