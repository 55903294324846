/** @format */

import React from "react";
import { useHistory } from "react-router-dom";

import { Elements, RecurlyProvider } from "@recurly/react-recurly";
import _ from "lodash";
import moment from "moment";

import { Typography } from "@alphamedical/components";
import { Paper } from "src/v2/designSystem";

import type { CancellationDiscountCurrentPeriodSchema, MembershipSchema } from "src/api";
import type { PdbPillType } from "src/v2/components";

import { ServicesService } from "src/api";
import { PageWrapper } from "src/components/PageWrapper";
import { formatText } from "src/content/contentUtils";
import { getRecurlyPublicKey } from "src/helpers/http";
import { MEMBERSHIP_CANCELLATION, MEMBERSHIP_CHANGE } from "src/routes";
import { MembershipOptions } from "src/routes/Membership/MembershipOptions";
import { useChildRedirect } from "src/utils/hooks";
import { useHasEmployerBenefits } from "src/utils/hooks/useHasEmployerBenefits";
import { useMembershipPrice } from "src/utils/hooks/useMembershipPrice";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { IconTextChevron, Line, PaymentVisitInfo, PdbPill } from "src/v2/components";
import { Button } from "src/v2/components/Button";
import InformationalCard from "src/v2/components/InformationalCard/InformationalCard";
import { MarkdownText } from "src/v2/components/MarkdownText";
import AlertSvg from "src/v2/components/Svgs/AlertSvg";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { MEMBERSHIP_PLANS } from "src/v2/constants";
import { useStoreDispatch, useStoreState } from "src/v2/models";

const MEMBER_PLAN_TEXT: Partial<Record<string, string>> = {
  "alpha-membership": `Alpha Basic Plan for **{{price}}/month**`,
  "alpha-membership-plus": `Alpha Plus+ Plan for **{{price}}/month**`,
};

const canceledMsg = "Your Alpha plan has been canceled and will not renew.";
const MEMBERSHIP_ERROR_MESSAGES: Partial<Record<string, string>> = {
  expired: "Your Alpha plan has expired.",
  canceled: canceledMsg,
  cancelled: canceledMsg,
};

const formatCostInCents = (costInCents: any) => `$${(costInCents / 100).toFixed(2)}`;

const getPlanName = (planCode: string | undefined) => {
  let planName: "plus" | "alpha" | undefined = "alpha";
  if (planCode === MEMBERSHIP_PLANS.plus) {
    planName = "plus";
  } else if (planCode === MEMBERSHIP_PLANS.alpha) {
    planName = "alpha";
  }
  return planName;
};

const MembershipDetailsInner = () => {
  useTopBar({ title: "Plan Details", variant: "nested", showBackButton: true });
  const dispatch = useStoreDispatch();
  const history = useHistory();
  useChildRedirect();
  const membership = useStoreState((state) => state.membership.membership);
  const membershipAlerts = useStoreState((state) => state.alerts);
  const [currentMonthDiscount, setCurrentMonthDiscount] =
    React.useState<CancellationDiscountCurrentPeriodSchema>();

  // plan-specific functionality
  const hasWesternDentalBenefits = useHasEmployerBenefits("western-dental");
  const hasSonjaCaresBenefits = useHasEmployerBenefits("sonja-cares");
  const SONJA_CARES_PILOT_EXPIRATION_DATE_STR = "06/03/2025";

  const membershipPriceInfo = useMembershipPrice();

  const formattedMembershipCost =
    membership?.cost_in_cents && formatCostInCents(membership.cost_in_cents);

  React.useEffect(() => {
    dispatch.profile.fetchEmployerBenefits();
    dispatch.membership.fetchMembership();
    ServicesService.membershipCancellationDiscountCurrentPeriod({ userId: "me" }).then((res) => {
      setCurrentMonthDiscount(res);
    });
  }, []);

  const ctaClick = (membershipType: "alpha" | "plus") => {
    if (membershipType === "alpha") {
      history.push("/membership-signup?plan=alpha&flow=account");
    } else {
      history.push("/membership-signup?plan=plus&flow=account");
    }
  };

  const handleMembershipAction = (key: string) => {
    switch (key) {
      case "change-plan":
        history.push(MEMBERSHIP_CHANGE);
        break;
      case "cancel-membership":
        history.push(MEMBERSHIP_CANCELLATION);
        break;
      case "message-care-team":
        history.push("/messages");
        break;
      default:
        break;
    }
  };

  const getMembershipPlanText = (membership: MembershipSchema) => {
    const { state, renews_at } = membership;
    const hasBasicSonjaCaresBenefits =
      hasSonjaCaresBenefits && membership?.plan_code === MEMBERSHIP_PLANS.alpha;
    let headerText;

    let membershipStatus;
    membershipStatus = state;

    if (membership?.membership_status === "CANCELLED") {
      membershipStatus = "canceled";
    }

    if (membershipStatus) {
      switch (membershipStatus.toLowerCase()) {
        case "expired":
          headerText = "Your plan expired on";
          break;
        case "canceled":
          headerText = "Your plan will expire on";
          break;
        default:
          if (!hasBasicSonjaCaresBenefits) {
            headerText = "Your plan will renew on";
          }
      }
    }

    const sonjaCaresPlusExpirationText = (
      <Typography variant="caption" className="mt-6">
        Your Sonja Cares plan benefits will expire on {SONJA_CARES_PILOT_EXPIRATION_DATE_STR}.
      </Typography>
    );

    const sonjaCaresBasicExpirationText = (
      <>
        <h1 className={"text-forest-darker font-medium mt-6"}>
          Your Sonja Cares plan benefits will expire on
        </h1>
        <p className={"text-forest-darker text-sm mt-1"}>{SONJA_CARES_PILOT_EXPIRATION_DATE_STR}</p>
      </>
    );

    return (
      <>
        {headerText && (
          <>
            <h1 className={"text-forest-darker font-medium mt-6"}>{headerText}</h1>
            <p className={"text-forest-darker text-sm mt-1"}>
              {moment(renews_at).format("MM/DD/YYYY")}
            </p>
          </>
        )}
        {hasSonjaCaresBenefits &&
          (hasBasicSonjaCaresBenefits
            ? sonjaCaresBasicExpirationText
            : sonjaCaresPlusExpirationText)}
      </>
    );
  };

  const getStatus = (): { text: string; color: PdbPillType } => {
    if (membership?.state === "ACTIVE" && membership?.membership_status !== "CANCELLED") {
      if (membershipAlerts.requestedCancellation) {
        return { text: "Processing cancellation", color: "warning" };
      }
      if (membershipAlerts.requestedDowngrade) {
        return { text: "Processing membership change", color: "warning" };
      }
      return {
        text: "Active",
        color: "default",
      };
    }

    let pillType: PdbPillType = "default";
    switch (membership?.state) {
      case "FAILED":
        pillType = "warning";
        break;
      case "CANCELED":
        pillType = "error";
        break;
      case "EXPIRED":
        pillType = "disabled";
        break;
    }

    if (membership?.membership_status === "CANCELLED") {
      return {
        text: "Canceled",
        color: "error",
      };
    }

    return {
      text: membership?.status_details
        ? membership.status_details
        : _.capitalize(membership?.state),
      color: pillType,
    };
  };

  const membershipPageHeader = (
    <>
      <img src="/assets/alphaMembership.svg" alt="Alpha Plan Logo" style={{ width: "127px" }} />
      <img src="/assets/cheerfulWomen.png" alt="Cheerful women standing together" />
      <p className={"text-forest-darker text-lg font-medium mt-10 text-center px-4"}>
        Our membership options cover all your primary care needs, from an expert in women’s health.
      </p>
    </>
  );

  const statusMessage =
    membership?.state && MEMBERSHIP_ERROR_MESSAGES[membership.state.toLowerCase()];
  const membershipStatus =
    membership?.membership_status &&
    MEMBERSHIP_ERROR_MESSAGES[membership.membership_status.toLowerCase()];
  const alertMessage = statusMessage || membershipStatus;

  const membershipPlanCode = membership?.plan_code;
  const scheduledMembershipPlanCode = membership?.scheduled_plan_code;
  const isScheduledChange =
    scheduledMembershipPlanCode && membershipPlanCode !== scheduledMembershipPlanCode;
  const currentPlanCode = isScheduledChange ? scheduledMembershipPlanCode : membershipPlanCode;

  const currentPlanName: "alpha" | "plus" = getPlanName(currentPlanCode);
  const currentMembershipPriceInfo = membershipPriceInfo[currentPlanName];

  const planText = (currentPlanCode && MEMBER_PLAN_TEXT[currentPlanCode]) || "";
  const currentPrice = !isScheduledChange
    ? formattedMembershipCost
    : currentMembershipPriceInfo.discountAmountString ||
      formatCostInCents(currentMembershipPriceInfo.originalPriceInCents);

  const price = (currentPrice ||
    currentMembershipPriceInfo?.discountAmountString ||
    currentMembershipPriceInfo?.originalPriceString) as string;

  const yourMembershipText = !currentMonthDiscount?.discount_in_period
    ? formatText(planText, {
        price: price,
      })
    : formatText(
        `${planText} starting {{discountStart}}, then {{regularCost}}/month starting {{regularStart}}`,
        {
          price: formatCostInCents(currentMonthDiscount.discount_cost_in_cents),
          discountStart: moment(membership?.renews_at).format("MM/DD/YYYY"),
          regularCost: price,
          regularStart: moment(membership?.renews_at).add(1, "month").format("MM/DD/YYYY"),
        },
      );

  const handleRenew = async () => {
    if (!membership || !currentPlanCode) return;

    if (!membership.active) {
      history.push("/membership-signup");
    } else {
      try {
        await dispatch.membership.signupMembership({ payment_plan: getPlanName(currentPlanCode) });
        dispatch.snacks.addSnack({
          type: "success",
          id: "reactivated-membership-success",
          message: "Your plan has been reactivated!",
          delay: 10,
        });
      } catch (e) {
        dispatch.snacks.addSnack({
          type: "error",
          id: "reactivated-membership-error",
          message: "There was an error reactivating your plan. Please contact support.",
          delay: 10,
        });
      }
    }
  };

  return (
    <PageWrapper cxPadding="px-0" fitToPageHeight>
      <Paper elevation={0} rounded={false} className="flex-1 mx-auto w-full max-w-2xl px-4">
        <div className={"mx-auto flex flex-col items-center mt-8 flex-1"}>
          {membership && membership.recurly_subscription_id && (
            <>
              <div className={"bg-white px-6 w-full flex flex-col items-center"}>
                <h1 className={"text-2xl text-forest-darker font-medium"}>Plan details</h1>
                <img className={"mt-4"} src="/assets/alpha-a.svg" alt="Alpha a logo" />
                {alertMessage && (
                  <div>
                    <div className={"flex mt-10 items-center"}>
                      <AlertSvg fill={"#D83B50"} />
                      <p className={"ml-4 text-error font-medium"}>{alertMessage}</p>
                    </div>
                    <Button onClick={handleRenew} extraClassNames={"rounded"}>
                      {!membership.active ? "Renew Your Plan" : "Reactivate Your Plan"}
                    </Button>
                  </div>
                )}
              </div>
              <div className={"bg-white p-10 w-full flex flex-col mt-4"}>
                <h1 className={"font-bold text-lg text-forest-darker"}>Plan details</h1>
                <p className={"text-forest-darker mt-6 font-medium"}>Your plan</p>
                {membership.plan_code && (
                  <MarkdownText
                    className={"text-forest-darker text-sm mt-2"}
                    text={yourMembershipText}
                  />
                )}
                <Line />
                <h1 className={"text-forest-darker font-medium mt-6"}>Status</h1>
                <div className={"mt-3"}>
                  <PdbPill type={getStatus().color}>{getStatus().text}</PdbPill>
                </div>
                <Line />
                <PaymentVisitInfo showNumberOfVisits={true} />
                <Line />
                {getMembershipPlanText(membership)}
                <Line />
                <IconTextChevron
                  planAction={"change-plan"}
                  icon={"/assets/TextBoxes.svg"}
                  iconAlt={"filled text box icon"}
                  text={"Change your plan"}
                  onClick={handleMembershipAction}
                />
                <Line />
                <IconTextChevron
                  planAction={"message-care-team"}
                  icon={"/assets/MessageIcon.svg"}
                  iconAlt={"Message Icon"}
                  text={"Message Care Team"}
                  onClick={handleMembershipAction}
                />
                <Line />
                {membership.membership_status !== "CANCELLED" && (
                  <>
                    <IconTextChevron
                      planAction={"cancel-membership"}
                      icon={"/assets/CancelRedCircle.svg"}
                      iconAlt={"Cancel circle"}
                      text={"Cancel Plan"}
                      onClick={handleMembershipAction}
                      textColor={"text-error"}
                    />
                    <Line />
                  </>
                )}
              </div>
            </>
          )}
          {!membership ||
            (!membership.recurly_subscription_id && !hasWesternDentalBenefits && (
              <MembershipOptions flow="account" />
            ))}
          {hasWesternDentalBenefits && (
            <>
              {membershipPageHeader}
              <div className="mt-10 px-6">
                <InformationalCard
                  type="Outline"
                  title="You already have access"
                  content="Your employer-sponsored access to Alpha includes all the benefits of an Alpha plan. Start a visit any time to get started."
                />
                <Button extraClassNames="rounded w-full mt-10" href="/your-care/explore-visits">
                  Explore Alpha Services
                </Button>
              </div>
            </>
          )}
        </div>
      </Paper>
    </PageWrapper>
  );
};

const MembershipDetails = () => {
  return (
    <RecurlyProvider publicKey={getRecurlyPublicKey()}>
      <Elements>
        <MembershipDetailsInner />
      </Elements>
    </RecurlyProvider>
  );
};

export default WithTopBarNavigation(MembershipDetails);
