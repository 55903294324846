/** @format */

import React from "react";
import { useHistory } from "react-router";

import { Analytics } from "src/analytics";

const ErrorPage = (props: { message?: string }) => {
  const history = useHistory();
  React.useEffect(() => {
    Analytics?.trackEvent({ category: "Page", action: "Crash", name: history?.location?.pathname });
  }, []);
  return (
    <div className="flex flex-col min-h-full bg-sand-dark px-8">
      <div className="flex flex-col flex-1 items-center justify-center">
        <div className="text-2xl">Something went wrong</div>
        {!!props.message && <div className="text-base">{props.message}</div>}
        <div className="max-w-sm mt-6 text-sm text-center">
          <div className="my-4">We apologize for the inconvenience, but something went wrong.</div>
          <div className="my-4">
            We’ve sent a report automatically to our team to investigate this issue. No further
            action from you is needed.
          </div>
          <div className="my-4">
            <a className="border-b" href="/">
              Return to homepage
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
