/** @format */

import React from "react";

import { CounterField } from "@alphamedical/components";

interface PeriodItemProps {
  imgSrc?: string;
  imgAlt?: string;
  title?: string;
  subtitle?: string;
  day: number;
  formKey: string;
}

const PeriodItem = ({ imgSrc, imgAlt, title, subtitle, day, formKey }: PeriodItemProps) => {
  return (
    <div className={"flex flex-row items-center justify-between"}>
      {imgSrc && <img src={imgSrc} alt={imgAlt} className={""} />}
      {title && (
        <div>
          <p className={"text-sm font-medium"}>{title}</p>
          <p className={"text-xs"}>({subtitle})</p>
        </div>
      )}
      <CounterField name={`blood_loss.day${day}.${formKey}`} min={0} max={10} />
    </div>
  );
};

export default PeriodItem;
