/** @format */

import React from "react";

import { Button } from "src/v2/components/Button";

interface CTACardProps {
  title: string;
  description?: string;
  bgColor?: string;
  img?: string;
  cta?: {
    href?: string;
    onClick?: () => void;
    bgColor?: string;
    children: string;
    disabled?: boolean;
    hasArrow?: boolean;
    desktopWidth?: string;
  };
  cxContainer?: string;
}

export const CTACard = ({ title, description, bgColor, cta, cxContainer, img }: CTACardProps) => {
  return (
    <div className={`${cxContainer} p-6 ${bgColor || "bg-white"}`} style={{ minHeight: "125px" }}>
      <div className="flex mb-3 justify-between flex-col items-center">
        <p className="font-semibold text-center">{title}</p>
        {img && <img className="my-3" src={img} alt="" role="presentation" />}
        {description && <p className="text-sm mt-3 text-center">{description}</p>}
      </div>
      <div className="flex justify-center w-full">
        {cta && (
          <Button
            href={cta.href}
            bgColor={cta.bgColor}
            hrefStyles={`w-full sm:${cta.desktopWidth || "w-1/3"}`}
          >
            <div className="flex items-center justify-center">
              {cta.children}
              {cta.hasArrow && <span className={"ml-2 fa fa-arrow-right"} />}
            </div>
          </Button>
        )}
      </div>
    </div>
  );
};
