/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AllergySearchResultSchema } from '../models/AllergySearchResultSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AllergiesService {

    /**
     * @returns AllergySearchResultSchema
     * @throws ApiError
     */
    public static searchAllergies({
        userId,
        value,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        value?: string,
    }): CancelablePromise<Array<AllergySearchResultSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/allergies/search',
            path: {
                'user_id': userId,
            },
            query: {
                'value': value,
            },
        });
    }

}
