/** @format */

import React from "react";
import { Link } from "react-router-dom";

import type { WaitlistEligibilityError } from "src/components/DynamicForm/Validators/types";

import { FormContext } from "src/components/DynamicForm/DynamicForm";
import { _POST } from "src/helpers/http";
import { useCurrentUser } from "src/v2/models/profile";

import type { ValidationErrorContentProps } from "./types";

export const WaitlistMessage: React.FC<ValidationErrorContentProps<WaitlistEligibilityError>> = ({
  validationError,
}) => {
  const [fetching, setFetching] = React.useState<boolean>(false);
  const [successfullyCompleted, setSuccessfullyCompleted] = React.useState<boolean>(false);
  const user = useCurrentUser();
  const context = React.useContext(FormContext);

  const addToWaitlist = () => {
    setFetching(true);
    _POST(`/users/${user.id}/waitlist`, {
      key: `${validationError.waitlistType}_waitlist`,
      association_class: "GroupSessionSignup",
      association_pk: context.signup.id,
      details: { state: validationError?.state },
    })
      .then(() => setSuccessfullyCompleted(true))
      .finally(() => setFetching(false));
  };

  // TODO: When this component is used for non-state related waitlist, make the below values more dynamic.
  return (
    <div className="px-5 py-8">
      <div className="text-xl">
        {successfullyCompleted ? "We'll notify you soon" : "Not available in your state"}
      </div>
      <p>
        {successfullyCompleted
          ? "We'll send you an email when this Alpha service becomes available in your state. In the meantime you can:"
          : "Sign up to get notified by email when this service becomes available in your state."}
      </p>
      {successfullyCompleted ? (
        <div className="pt-6">
          <Link to="/your-care/explore-visits" className="text-sky-dark text-md font-bold">
            Explore Alpha Services
          </Link>
        </div>
      ) : (
        <div className="w-full pt-6">
          <button
            disabled={fetching}
            onClick={addToWaitlist}
            className="btn btn-primary w-full font-bold"
          >
            Notify Me
          </button>
        </div>
      )}
    </div>
  );
};
