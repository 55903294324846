/** @format */

import React from "react";
import { Link } from "react-router-dom";

import "./TimelineCard.scss";

interface TimelineSteps {
  title: string;
  subText?: string;
}
interface TimelineCardProps {
  title: string;
  steps: TimelineSteps[];
  currentStep: number;
  cta?: {
    href?: string;
    label: JSX.Element;
  };
}

export interface TimelineProps {
  currentStep: number;
  fadeFinishedSteps?: boolean;
  steps: TimelineSteps[];
}

export const Timeline = (props: TimelineProps) => (
  <ul className="timeline-card">
    {props.steps.map((step, idx: number) => (
      <li
        key={idx}
        className={`
           flex flex-col text-sm
          ${props.currentStep >= idx ? "filled" : ""}
          ${props.fadeFinishedSteps && props.currentStep > idx ? "opacity-25" : ""}
        `}
      >
        <div className="">{step.title}</div>
        <div className={`text-sm`}>{step.subText}</div>
      </li>
    ))}
  </ul>
);

export const TimelineCard = (props: TimelineCardProps) => {
  const { title, currentStep, steps, cta } = props;
  return (
    <div className="p-6 bg-white mb-4">
      <p className="font-bold text-grey-dark mb-4 text-sm">{title}</p>
      <div className="mb-4">
        <Timeline currentStep={currentStep} fadeFinishedSteps={true} steps={steps} />
      </div>
      {(cta && cta.href && (
        <Link to={cta.href} className={`flex items-center text-sky-dark font-semibold text-sm`}>
          {cta.label}
        </Link>
      )) ||
        (cta && (
          <p className={`text-right w-48 flex items-center text-grey font-semibold text-sm`}>
            {cta.label}
          </p>
        ))}
    </div>
  );
};
