/** @format */

import React from "react";
import { Route, Switch } from "react-router-dom";

import { Paper } from "src/v2/designSystem";

import { PageWrapper } from "src/components/PageWrapper";
import SettingsLink from "src/components/Settings/SettingsLink";
import { ALPHA_PCP, CARE_TEAM } from "src/routes";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";

const PrimaryCareProvider = () => {
  useTopBar({ title: "Care Team", variant: "nested", showBackButton: true });
  const links = [
    {
      title: "Alpha primary care provider",
      url: ALPHA_PCP,
    },
  ];

  return (
    <PageWrapper cxPadding="px-0" fitToPageHeight>
      <Paper elevation={0} rounded={false} className="mx-auto h-screen w-full max-w-2xl px-6">
        <Switch>
          <Route path={CARE_TEAM}>
            <div className="mx-auto">
              <div className="pb-8" />
              {links.map((link) => (
                <SettingsLink key={link.title} url={link.url} title={link.title} />
              ))}
            </div>
          </Route>
        </Switch>
      </Paper>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(PrimaryCareProvider);
