/** @format */

import type { Action, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";

import type { CouponDiscount } from "src/v2/components/Payment";

import { UsersService } from "src/api";

export interface PromoError {
  is_error: boolean;
  description?: string;
}

export interface PaymentsModel {
  dueToday: number;
  couponCode: string | undefined;
  promoError: PromoError;
  couponDiscount: CouponDiscount | undefined;
  couponDiscountAmount: number; // gets couponDiscountAmount formatted as cost_in_cents

  setDueToday: Action<PaymentsModel, number>;
  setCouponCode: Action<PaymentsModel, string | undefined>;
  setPromoError: Action<PaymentsModel, PromoError>;
  setCouponDiscount: Action<PaymentsModel, CouponDiscount | undefined>;
  setCouponDiscountAmount: Action<PaymentsModel, number>;

  checkCoupon: Thunk<
    PaymentsModel,
    {
      couponCode?: string;
      conditionKey: string;
      consultFee?: number;
      setErrorIfFailed?: boolean;
    }
  >;
}

export const paymentStore: PaymentsModel = {
  dueToday: 0,
  couponCode: undefined,
  promoError: { is_error: false },
  couponDiscount: undefined,
  couponDiscountAmount: 0,

  setDueToday: action((state, payload) => {
    state.dueToday = payload;
  }),

  setCouponCode: action((state, payload) => {
    state.couponCode = payload;
  }),

  setPromoError: action((state, payload) => {
    state.promoError = payload;
  }),

  setCouponDiscount: action((state, payload) => {
    state.couponDiscount = payload;
  }),

  setCouponDiscountAmount: action((state, payload) => {
    state.couponDiscountAmount = payload;
  }),

  checkCoupon: thunk(
    (actions, { couponCode, conditionKey, consultFee, setErrorIfFailed = true }) => {
      if (couponCode) {
        actions.setCouponCode(couponCode);
      }

      return UsersService.getEligibleRecurlyCoupon({ userId: "me", conditionKey, couponCode })
        .then((coupon) => {
          actions.setCouponDiscount({
            type: "CouponCode",
            discount: coupon,
          });
          if (coupon.discount_type === "percent" && coupon.discount_in_percents && consultFee) {
            actions.setCouponDiscountAmount(consultFee * (coupon.discount_in_percents / 100));
          } else if (coupon.discount_type === "dollars" && coupon.discount_in_cents) {
            actions.setCouponDiscountAmount(coupon.discount_in_cents);
          }
          return coupon;
        })
        .catch((err) => {
          if (setErrorIfFailed) {
            actions.setPromoError({ is_error: true, description: err.response.data.description });
          }
          // remove invalid coupon_code from localStorage
          actions.setCouponCode(undefined);
          // clear out couponDiscount
          actions.setCouponDiscount(undefined);
          return err;
        });
    },
  ),
};
