/** @format */
// Module dependencies
import type { FC } from "react";
import type React from "react";

import { useRef } from "react";

import classNames from "classnames";
import _ from "lodash";

import type { FieldRenderProps, OptionType } from "@alphamedical/components";
// UI Compopnents & Styles
import type { BadgePdbProps } from "src/v2/designSystem/BadgePdb";

import { BadgePdb } from "src/v2/designSystem/BadgePdb";

// Utils

export type OptionTypeWithBadge<T = string> = OptionType<T> & {
  /** Props for BadgePdb component. */
  badgeProps?: BadgePdbProps;
  disabled?: boolean;
};

type OptionBadgeFieldRenderProps<T> = FieldRenderProps<T> & {
  /** Input options plus the BadgePdb component props. */
  option: OptionTypeWithBadge<T>;
};

export const CheckboxOption: FC<OptionBadgeFieldRenderProps<string>> = ({
  disabled,
  input,
  option: { label, badgeProps },
  errorState,
  reverseLayout,
  clickEvent,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  /** Function to handle user entry through keyboard. */
  const handleKeyboardSelection = (e: React.KeyboardEvent<HTMLLabelElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      inputRef.current?.click();
    }
  };

  /** Function to execute the callback, and to create and trigger a change event in the input. */
  const handleCheckboxClick = () => {
    if (clickEvent) clickEvent();

    const changeEvent = new Event("change", { bubbles: true, cancelable: false });
    inputRef.current?.dispatchEvent(changeEvent);
  };

  const getStyles = () => {
    const baseStyle = "cursor-pointer checkbox-selection";
    const disabledStyle = "opacity-50 cursor-not-allowed bg-grey-20";
    const checkedStyle = "bg-sand-60";
    const uncheckedStyle = "bg-grey-10 hover:bg-sand-20";

    return `${disabled ? disabledStyle : baseStyle} ${input.checked ? checkedStyle : uncheckedStyle}`;
  };

  return (
    <label
      className={classNames(
        "px-4 py-3 normal-case field flex items-center gap-2 rounded",
        getStyles(),
        reverseLayout ? "flex-row-reverse justify-between" : "flex-row justify-start",
      )}
      onKeyDown={(e) => handleKeyboardSelection(e)}
      aria-checked={input.checked}
    >
      <input
        className="hidden"
        disabled={disabled}
        type="checkbox"
        id={input.name}
        ref={inputRef}
        onClick={handleCheckboxClick}
        {...input}
      />
      <div
        className="mr-2 flex items-center justify-center rounded checkbox-selection-box"
        tabIndex={0}
        title={_.isString(label) ? label : undefined}
        onKeyDown={(e: any) => {
          if (e.which === 32) {
            e.target.parentElement.click();
          }
        }}
      >
        <div
          className={classNames(
            "w-6 h-6 flex items-center justify-center",
            "border-2 border-forest-100 rounded",
            input.checked && "fas fa-check bg-forest-100 text-white",
            errorState && "border-error",
          )}
        />
      </div>

      {typeof label === "string" ? (
        <div
          className={classNames("flex-1 rounded-sm text-forest-100", errorState && "text-error")}
          id={`${input.name}-label`}
          dangerouslySetInnerHTML={{ __html: label }}
        ></div>
      ) : (
        <div
          className={classNames("flex-1 rounded-sm text-forest-100", errorState && "text-error")}
          id={`${input.name}-label`}
        >
          {label}
        </div>
      )}

      {badgeProps && <BadgePdb variant={badgeProps?.variant || "brandDark"} {...badgeProps} />}
    </label>
  );
};
