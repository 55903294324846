/** @format **/
import React from "react";

interface NumberedListProps {
  title?: string;
  items: string[];
  className?: string;
}

const SandBullet = ({ title, items, className }: NumberedListProps) => {
  const customListClassname = className ? ` ${className}` : "";
  const customItemClassname = className ? ` ${className}` : "";
  return (
    <ol className={`align-middle mb-2 flex flex-col ${customListClassname}`}>
      {title && <h4 className={"text-forest-120 text-base leading-6 font-medium mb-6"}>{title}</h4>}
      {items.map((item, index) => (
        <li key={item} className={`align-middle mb-6 flex${customItemClassname}`}>
          <div
            className={
              "flex font-medium text-sm items-center justify-center bg-sand-100 border-2 rounded-full border-forest-100 px-2 w-6 h-6 mr-4"
            }
          >
            {index + 1}
          </div>
          <div className="">{item}</div>
        </li>
      ))}
    </ol>
  );
};

export default SandBullet;
