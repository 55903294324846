/** @format */

import axios from "axios";
import type { Action, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";
import moment from "moment";

import type { MaintenanceData } from "src/v2/types/maintenance";

import config from "src/config";

export interface ExtendedMaintenanceData extends MaintenanceData {
  maintenanceWithin3Days?: boolean;
  maintenanceToday?: boolean;
  maintenanceWithin2Hours?: boolean;
}

export interface MaintenanceModel {
  maintenanceData: ExtendedMaintenanceData;
  inMaintenance: boolean;
  upcomingMaintenance: boolean;
  bannerDismissed: boolean;
  genericBannerDismissed: boolean;
  startTime?: string;
  endTime?: string;
  setMaintenanceData: Action<MaintenanceModel, MaintenanceData | undefined>;
  checkMaintenanceData: Thunk<MaintenanceModel>;
  setBannerDismissed: Action<MaintenanceModel>;
  setGenericBannerDismissed: Action<MaintenanceModel>;
  dismissBanner: Thunk<MaintenanceModel>;
  dismissGenericBanner: Thunk<MaintenanceModel>;
}

export const maintenanceStore: MaintenanceModel = {
  maintenanceData: {},
  inMaintenance: false,
  upcomingMaintenance: false,
  bannerDismissed: false,
  genericBannerDismissed: false,
  startTime: undefined,
  endTime: undefined,

  setMaintenanceData: action((state, maintenanceData) => {
    if (!maintenanceData) {
      state.inMaintenance = false;
      state.startTime = undefined;
      state.endTime = undefined;
      state.upcomingMaintenance = false;
      return;
    }

    state.maintenanceData = maintenanceData;

    state.inMaintenance = maintenanceData.inMaintenance ?? false;
    state.endTime = maintenanceData.endTime;
    state.startTime = maintenanceData.startTime;

    if (!maintenanceData.startTime && !maintenanceData.endTime) {
      // if nothing scheduled, don't need to evaluate timing.
      return;
    }

    const currentTime = moment();
    const start = moment(maintenanceData.startTime);
    const end = moment(maintenanceData.endTime);

    // check to see if we should be in maintenance based on start and end.
    if (!state.inMaintenance && currentTime.isBetween(start, end)) {
      state.inMaintenance = true;
      state.maintenanceData.inMaintenance = true;
    }

    if (start.diff(currentTime, "hours") < 0) {
      state.upcomingMaintenance = false;
      state.maintenanceData.maintenanceWithin3Days = false;
      state.maintenanceData.maintenanceToday = false;
      state.maintenanceData.maintenanceWithin2Hours = false;
      return;
    }

    state.upcomingMaintenance = start.diff(currentTime, "hours") < 73;
    state.maintenanceData.maintenanceWithin3Days = state.upcomingMaintenance;
    state.maintenanceData.maintenanceToday = currentTime.get("date") === start.get("date");
    state.maintenanceData.maintenanceWithin2Hours = start.diff(currentTime, "minutes") < 120;
  }),

  checkMaintenanceData: thunk((actions) => {
    return axios
      .create({ baseURL: config.assetsUrl })
      .get(config.maintenanceFilename)
      .then((res) => {
        actions.setMaintenanceData(res.data as MaintenanceData);

        // -- Testing Data --
        // const startTimeString = "2023-10-31T23:30:00.000Z";
        // const endTimeString = "2023-11-01T14:00:00.000Z";
        // const currentTime = moment();
        // const endTime = moment(endTimeString);
        // const startTime = moment(startTimeString);
        // const inMaintenance = currentTime.isBefore(endTime) && currentTime.isAfter(startTime);
        // actions.setMaintenanceData({
        //   inMaintenance: inMaintenance,
        //   startTime: startTimeString,
        //   endTime: endTimeString
        // });
        // return inMaintenance;

        return res.data.inMaintenance;
      })
      .catch((e) => {
        actions.setMaintenanceData(undefined);
      });
  }),

  setBannerDismissed: action((state) => {
    state.bannerDismissed = true;
  }),

  setGenericBannerDismissed: action((state) => {
    state.genericBannerDismissed = true;
  }),

  dismissBanner: thunk((actions) => {
    actions.setBannerDismissed();
  }),

  dismissGenericBanner: thunk((actions) => {
    actions.setGenericBannerDismissed();
  }),
};
