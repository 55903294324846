/** @format */

// Module dependencies
import type { FC, ReactNode } from "react";

import React from "react";

import classNames from "classnames";
import { isString } from "lodash";

import { Typography } from "@alphamedical/components";
// UI Components
import { BadgePdb } from "src/v2/designSystem/BadgePdb";
import { ButtonPdb } from "src/v2/designSystem/ButtonPdb";
import { Paper } from "src/v2/designSystem/Paper";

interface CardProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Title of the card.
   */
  title: string;

  /**
   * Body text of the card.
   */
  body: string;

  /**
   * Represents the call to action message displayed on the button.
   */
  ctaMessage?: string;

  /**
   * Represents the label for the BadgePdb component. And defined if it is rendered.
   */
  badgeLabel?: string;

  /**
   * represents the URL of the card icon. It can also be a valid ReactNode. If it is a string, an image tag is generated with the source as the URL. If it is a valid ReactNode, it is rendered as is.
   */
  cardIcon?: string | ReactNode;
}

const HomeCard: FC<CardProps> = ({
  badgeLabel,
  body,
  cardIcon,
  ctaMessage,
  title,
  ...props
}): JSX.Element => (
  <Paper
    className="flex flex-row justify-between p-4 gap-4 w-full sm:max-w-2xl border border-grey-60"
    elevation={0}
    rounded={true}
  >
    <div>
      {badgeLabel && <BadgePdb variant="notification" label={badgeLabel} />}

      <Typography variant="h4" className={classNames(badgeLabel && "mt-2")}>
        {title}
      </Typography>

      <Typography variant="body2" className="mt-1">
        {body}
      </Typography>

      {ctaMessage && (
        <ButtonPdb variant="secondary" className="mt-4" name="card-cta" {...props}>
          {ctaMessage}
        </ButtonPdb>
      )}
    </div>

    <div>
      {isString(cardIcon) ? (
        <img src={cardIcon} alt="Home card icon" className="w-16 h-auto" />
      ) : (
        React.isValidElement(cardIcon) && cardIcon
      )}
    </div>
  </Paper>
);

export default HomeCard;
