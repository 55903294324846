/** @format */

import React from "react";
import { Form } from "react-final-form";

import * as Sentry from "@sentry/react";

import { TextField } from "@alphamedical/components";

import type { RegisterKitStage } from "src/routes/RegisterKit";
import type { Channel } from "src/v2/models/channels";

import { isAxiosError } from "src/helpers/axios";
import { _POST, getTokenHeaderV2 } from "src/helpers/http";
import { Button } from "src/v2/components/Button";
import { useStoreState } from "src/v2/models";
import { useQuery } from "src/v2/utils/useQuery";

interface InputKitRegistrationCodeProps {
  setStage: (param: RegisterKitStage) => void;
  setKit: (param: string) => void;
}

export const InputKitRegistrationCode = ({ setStage, setKit }: InputKitRegistrationCodeProps) => {
  const { currentProfileId } = useStoreState((state) => state.profile);
  const channels = useStoreState((state) => state.channels.channels) as Channel[] | undefined;
  const supportChannel =
    !!channels && channels.find((channel) => channel.channel_type === "SUPPORT");
  const query = useQuery();
  const condition = query.get("condition");

  return (
    <div>
      <div className="flex flex-col items-center my-8">
        <h1 className="text-2xl">{`Register Your ${condition === "diabetes" ? "A1C" : "Test"} Kit`}</h1>
        <img
          src={condition === "diabetes" ? "/assets/diabetes-icon.svg" : "/assets/sti-kit-icon.svg"}
          alt=""
          role="presentation"
          className="w-16 mt-4"
        />
        <Form
          onSubmit={(values: any, form: any) => {
            return _POST(
              `/users/${currentProfileId}/register_kit`,
              { registration_code: values.registration_code },
              getTokenHeaderV2(),
            )
              .then((res) => {
                if (res.success) {
                  setKit(res.name);
                  setStage("success-page");
                  return;
                } else {
                  if (res.error_msg === "Kit id doesn’t exist") {
                    return {
                      registration_code: res.error_msg,
                    };
                  } else if (res.error_msg === "Order has been received and processed.") {
                    return { registration_code: "That kit has already been registered." };
                  } else {
                    return {
                      registration_code: "There was an error registering your kit id.",
                    };
                  }
                }
              })
              .catch((err) => {
                if (isAxiosError(err)) {
                  const handledErrorMessageTypes = ["MTLOrderNotFound", "MtlOrderMismatch"];
                  // If we haven't handled the error message or don't know what it is. Send it to Sentry.
                  const errorType = err.response?.data?.type;
                  if (errorType != null && !handledErrorMessageTypes.includes(errorType)) {
                    Sentry.captureException(err);
                  }

                  if (err.response?.data.description) {
                    return { registration_code: err.response.data.description };
                  }
                }
                return {
                  registration_code:
                    "There was an error registering your kit ID. Please try again. If this error continues, contact Alpha Support for assistance. ",
                };
              });
          }}
        >
          {({ form, handleSubmit, values, errors, submitErrors, ...formProps }) => {
            return (
              <form onSubmit={handleSubmit} className="w-full flex flex-col items-center px-4">
                <div className="w-full">
                  <TextField
                    name="registration_code"
                    placeholder="Enter kit registration number"
                    required={true}
                  />
                </div>
                {submitErrors && submitErrors.registration_code && values.registration_code && (
                  <p className="mb-8 text-sm">
                    Try re-entering the kit registration number. If that doesn't work, contact Alpha
                    Support for assistance.
                  </p>
                )}
                <Button bgColor="violator" disabled={!values.registration_code} cxMargin="mb-4">
                  Register
                </Button>
                {submitErrors && submitErrors.registration_code && values.registration_code && (
                  <Button
                    bgColor="transparent"
                    borderColor="forest-40 hover:border-forest-20"
                    textColor="primary"
                    cxMargin="mb-6"
                    hoverBgColor="forest-20"
                    hrefStyles="w-full"
                    href={supportChannel ? `/messages/${supportChannel.name}` : "/messages/new"}
                  >
                    Contact Alpha Support
                  </Button>
                )}
              </form>
            );
          }}
        </Form>
      </div>
    </div>
  );
};
