/** @format */

import React from "react";

import { faBell } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { GenericAvatar } from "src/v2/designSystem/Avatar/GenericAvatar";

import type { CardPopupContent } from "src/components/Popups/CardPopup";
import type { PubNubMessage } from "src/utils/hooks/pubnub";

interface Props {
  author: {
    name: string;
    headshot_photo_url: string;
    biography: string;
    [key: string]: any;
  };
  message?: PubNubMessage;
  DBChannels?: any[];
  bgColorClassName?: string;
  subtitle?: string;
  setBioModalIsOpen?: (value: boolean) => void;
  setBioModalContent?: (value: CardPopupContent) => void;
}

const UserAvatar = ({
  author,
  subtitle,
  bgColorClassName = "bg-sand-60",
  setBioModalIsOpen,
  setBioModalContent,
}: Props) => {
  const { name, biography, headshot_photo_url } = author || {};
  const genericIcon = name ? name.charAt(0) : <FontAwesomeIcon icon={faBell} />;
  const photoIcon = <img src={headshot_photo_url} alt={name} />;
  const [modalShouldShow, setModalShouldShow] = React.useState(false);

  React.useEffect(() => {
    const rolesThatShowModal = ["provider"];
    setModalShouldShow(author?.roles?.some((role: string) => rolesThatShowModal.includes(role)));
  }, [author]);

  return (
    <GenericAvatar
      bgColorClassName={headshot_photo_url ? "" : bgColorClassName}
      iconElement={headshot_photo_url ? photoIcon : genericIcon}
      title={name || "Hello Alpha"}
      subtitle={subtitle}
      // only allow avatar to open a modal we've got a real user to display
      bioModalProps={
        (modalShouldShow && {
          setBioModalIsOpen,
          setBioModalContent,
          bioModalContent: {
            title: name,
            cardHeader: "About",
            cardContent: biography || "Biography not currently available",
            buttonText: "Close",
          },
        }) ||
        undefined
      }
    />
  );
};

export default UserAvatar;
