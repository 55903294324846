/** @format */

import React from "react";

import type { FollowupItem } from "src/components/Popups/types";

import config from "src/config";
import { AlphaModal } from "src/v2/components/AlphaModal";
import { Button } from "src/v2/components/Button";
import { formatPrice } from "src/v2/components/Payment/utils";
import { useStoreDispatch } from "src/v2/models";

export interface MedNotCoveredProps {
  item: FollowupItem;
  showPopup: boolean;
  setShowPopup: (arg: boolean) => void;
  key?: number | string;
  pii?: any;
  profile?: any;
}

export const MedNotCoveredPopup = ({
  item: {
    followup_type,
    subscription_id,
    medication_name,
    condition_name,
    condition_key,
    medication_cost,
    days_supply,
  },
  showPopup,
  setShowPopup,
  pii,
  profile,
}: MedNotCoveredProps) => {
  const [showForm, setShowForm] = React.useState<boolean>(false);
  const [pharmacyInfo, setPharmacyInfo] = React.useState<string>("");
  const dispatch = useStoreDispatch();
  const handleInsuranceXulaneApproval = (selection: string) => {
    dispatch.profile.sendSubscriptionApproval({
      selection,
      subscription_id,
      followup_type,
      medication_name,
      condition_name,
      medication_cost,
      days_supply,
      condition_key,
      pharmacy_info: pharmacyInfo,
    });
    setShowPopup(false);
    setShowForm(false);
  };

  return (
    <div>
      <AlphaModal
        isOpen={showPopup}
        className="max-w-sm m-auto bg-white shadow-md"
        cxPadding="p-6"
        onRequestClose={() => {
          setShowPopup(false);
        }}
        addPaddingForNavBar={true}
      >
        {!showForm && (
          <div className="flex flex-col text-16">
            <p className="font-semibold">
              Hi {(pii && pii.preferred_firstname) || (pii && pii.first_name)},
            </p>
            <p className="mb-4">
              We are reaching out to you regarding a change in your prescription shipment.
              Unfortunately, our partner pharmacy is no longer accepting insurance for your{" "}
              {medication_name} medication. Your options are to:
            </p>
            <p>
              <strong>1.</strong> Continue with the Alpha free shipping service, forgo insurance,
              and pay the cash price ({medication_cost && formatPrice(medication_cost)} for a{" "}
              {days_supply} day supply).
            </p>
            <Button
              extraClassNames="cursor-pointer mb-4"
              disabled={profile.postingApproval}
              onClick={() => handleInsuranceXulaneApproval("cash")}
            >
              Pay Cash Moving Forward
            </Button>
            <p className="mt-4">
              <strong>2.</strong> Continue with the Alpha free shipping service and choose a
              different medication.
              <br />
              {condition_key && ["birthcontrol", "endometriosis"].indexOf(condition_key) !== -1 && (
                <div>
                  Click{" "}
                  <a
                    href={`${config.wwwUrl}/birth-control-brands-we-carry`}
                    target="blank"
                    rel="noreferrer noopener"
                  >
                    here
                  </a>{" "}
                  for a full list of birthcontrol brands we carry.
                </div>
              )}
            </p>
            <div className="flex flex-col justify-content">
              <Button
                extraClassNames="cursor-pointer mb-4"
                disabled={profile.postingApproval}
                onClick={() => handleInsuranceXulaneApproval("request_med_change")}
              >
                Request Medication Change
              </Button>
              <p className="mt-4">
                <strong>3.</strong> Transfer your prescription for {medication_name} to your
                pharmacy of choice. Please click below to provide information on your local
                pharmacy.
              </p>
              <Button
                extraClassNames={`text-center cursor-pointer mb-4`}
                disabled={profile.postingApproval}
                onClick={() => setShowForm(true)}
              >
                Transfer to External Pharmacy
              </Button>
              <p className="my-4">
                We apologize for the inconvenience. We are currently exploring other pharmacy
                partners and will let you know when {medication_name} is available with insurance
                coverage.
              </p>
              <p>
                Please note this only affects your {medication_name} prescription. Your other
                prescriptions with our partner pharmacies will continue to ship as scheduled.
              </p>
            </div>
            {profile.error && <p className="text-red">{profile.error}</p>}
          </div>
        )}
        {showForm && (
          <div className="w-sm bg-white m-auto p-6">
            <div
              className={`
            absolute top-0 left-0 fas fa-arrow-left text-2xl p-5 mt-1 cursor-pointer text-primary-60 hover:text-primary
        `}
              onClick={() => setShowForm(false)}
            />
            <p className="text-18 text-center">
              Please enter the local pharmacy's information that you wish to use.
            </p>
            <textarea
              style={{ height: "100px" }}
              className={`border w-full border-primary-20 border-primary-20
                                focus:border-primary p-3 my-3 outline-none`}
              name="pharmacy"
              onChange={(e) => setPharmacyInfo(e.target.value)}
            />
            <div className="flex">
              <Button
                extraClassNames="w-1/2"
                disabled={profile.postingApproval || pharmacyInfo.length === 0}
                onClick={() => handleInsuranceXulaneApproval("transfer_to_external_pharmacy")}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </AlphaModal>
    </div>
  );
};
