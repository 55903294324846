/** @format */

import type { DropFilesEventHandler } from "react-dropzone";

import React from "react";
import Dropzone from "react-dropzone";
import TextareaAutosize from "react-textarea-autosize";

import "./messages.scss";

interface MessageInputProps {
  disableOption?: boolean;
  onDrop: DropFilesEventHandler;
  text: string;
  setText(e: any): void;
}

export const MessageInput = ({
  disableOption = true,
  text,
  setText,
  onDrop,
}: MessageInputProps) => {
  let dropzoneRef: any;
  return (
    <div className="flex w-full bg-white pt-4 sm:pb-4 sm:px-4">
      <div className="bg-grey-lightest flex items-end w-full">
        <TextareaAutosize
          id="textarea"
          name="message"
          className={`
            p-3 w-full
            bg-white message-textarea text-primary text-sm
          `}
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Type your message..."
          minRows={1}
          maxRows={5}
        />
        <img
          onClick={() => {
            dropzoneRef.open();
          }}
          src="/assets/attach-icon.svg"
          alt="Attach a file"
          className="mr-4 pb-3 cursor-pointer"
        ></img>
        <Dropzone
          ref={(node: any) => {
            dropzoneRef = node;
          }}
          className="display-none"
          multiple={true}
          onDrop={onDrop}
          accept="image/*, application/pdf"
        >
          Drop files here
        </Dropzone>
      </div>
      <div className="flex items-end">
        <button
          disabled={disableOption}
          className={`pr-2 ${disableOption ? "cursor-not-allowed" : ""}`}
        >
          <img
            src={disableOption ? "/assets/send-icon-grey.svg" : "/assets/send-icon.svg"}
            alt="Send a message"
            className={`ml-2`}
          />
        </button>
      </div>
    </div>
  );
};
