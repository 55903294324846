/** @format */

import React from "react";

export interface EitherAddressType {
  name?: string;
  address?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
}

interface ReadOnlyAddressProps {
  address: EitherAddressType;
}

const ReadOnlyAddress = ({ address }: ReadOnlyAddressProps) => (
  <>
    <div>{address.address1 || address.address}</div>
    {address.address2 && <div>{address.address2}</div>}
    <div>
      {address.city}, {address.state?.toUpperCase()} {address.zipcode}
    </div>
  </>
);

export default ReadOnlyAddress;
