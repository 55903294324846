/** @format */

import type { match } from "react-router-dom";

import React from "react";
import { useHistory } from "react-router-dom";

import { PageWrapper } from "src/components/PageWrapper";
import config from "src/config";
import { braze } from "src/utils/braze";
import { now } from "src/utils/date_utils";
import InfoPage from "src/v2/components/InfoPage/InfoPage";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";

interface StateIneligibleParams {
  step: "notify-me" | "confirmation" | "error";
  state?: string;
}

export const StateIneligiblePage = ({ match }: { match: match<StateIneligibleParams> }) => {
  const step = match.params.step;
  const state = match.params.state;
  const history = useHistory();
  const addToWaitlist = () => {
    const eventTriggered = braze.logCustomEvent("waitlist_requested", {
      segment: state,
      date: now("PPPPpppp"),
    });

    if (eventTriggered) {
      history.push("/state-ineligible/confirmation");
    } else {
      history.push("/state-ineligible/error");
    }
  };

  React.useEffect(() => {
    if (step === "notify-me" && !state) {
      history.push("/state-ineligible/error");
    }
  });

  return (
    <PageWrapper>
      <div className="my-8 flex flex-col max-w-xl mx-auto">
        {step === "notify-me" && state && (
          <InfoPage
            title={"Hello Alpha isn't available in your state right now."}
            content={"We'll notify you via email when we're able to provide care in your state."}
            icon="/assets/coming-soon.svg"
            ctaText="Notify me"
            ctaCallback={addToWaitlist}
          />
        )}
        {step === "confirmation" && (
          <InfoPage
            title={"Thanks! We'll send you an email when Hello Alpha is available in your state."}
            icon="/assets/group-3.svg"
            ctaText="Visit homepage"
            ctaCallback={() => {
              window.location.href = config.wwwUrl;
            }}
          />
        )}
        {step === "error" && (
          <InfoPage
            title={"We’re sorry but something went wrong."}
            content={"Please try again later."}
            icon="/assets/layer-25.svg"
            ctaText="Visit homepage"
            ctaCallback={() => {
              window.location.href = config.wwwUrl;
            }}
          />
        )}
      </div>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(StateIneligiblePage);
