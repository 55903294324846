/** @format */

import React from "react";
import { useField } from "react-final-form";
import ReactMarkdown from "react-markdown";

import classNames from "classnames";

import { Typography, useMultiPageForm } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import type { CustomFieldProps } from "src/components/DynamicForm/Fields/CustomFields/types";

import { AccordionBox } from "src/components/QuestionAccordionBox/AccordionBox";
import InformationalCard from "src/v2/components/InformationalCard/InformationalCard";

import "./transitionScreenStyles.scss";

interface GenericFormTransitionScreenProps extends CustomFieldProps {
  title: string;
  subtitle?: string;
  paragraphsTitle?: string;
  paragraphs?: string[];
  numberedBullets?: string[];
  bulletStyleIcon?: string;
  informationCard?: {
    title: string;
    bulletPoints: string[];
    type?: "Neutral" | "Brand" | "Outline" | "Warning" | "Error";
  };
  accordion?: {
    title: string;
    expandedAnswer: string[];
    backgroundColor?: string;
    accordionType?: "question" | "information";
  };
  icon?: string;
  answerKey?: string;
  nextButtonText?: string;
  variant?: "Section-Intro" | "Screening" | "Text-Bubble";
  footer?: string;
  image?: any;
}

export const GenericFormTransitionScreen: React.FC<GenericFormTransitionScreenProps> = ({
  title,
  paragraphs,
  informationCard,
  accordion,
  icon,
  answerKey,
  subtitle,
  nextButtonText,
  paragraphsTitle = "",
  numberedBullets,
  bulletStyleIcon,
  variant,
  footer,
  image,
}: GenericFormTransitionScreenProps) => {
  const { nextPage } = useMultiPageForm();
  const [answerKeyState, setAnswerKeySate] = React.useState<string>("");
  const field = useField(answerKeyState);

  React.useEffect(() => {
    if (answerKey) {
      setAnswerKeySate(answerKey);
    }
  }, []);

  const transitionScreenButton = (
    <ButtonPdb
      onClick={() => {
        // setting the answerKey to true, so it doesn't show this again
        if (answerKey) {
          field.input.onChange("true");
        }
        nextPage();
      }}
      className="w-full my-2"
    >
      {nextButtonText ? nextButtonText : "Next"}
    </ButtonPdb>
  );

  const sectionIntroVariant = (
    <div style={{ marginTop: -28 }}>
      {image && (
        <span className="flex justify-center overflow-hidden relative w-full h-48 sm:h-56 md:h-64">
          <img
            src={image.file.url}
            alt={image.description}
            className="h-full w-full object-cover absolute"
          />
        </span>
      )}
      {title && (
        <Typography variant="h3" className="my-4">
          <ReactMarkdown>{title}</ReactMarkdown>
        </Typography>
      )}
      {subtitle && (
        <div className="py-2">
          <Typography variant="body1">
            <ReactMarkdown>{subtitle}</ReactMarkdown>
          </Typography>
        </div>
      )}
      {paragraphsTitle && <p className={"text-forest-120 mb-6 font-bold"}>{paragraphsTitle}</p>}
      {paragraphs &&
        paragraphs.map((text) => {
          return (
            <p className={"my-10 text-xl"} key={text.slice(0, 10).replace(" ", "")}>
              {text}
            </p>
          );
        })}
      {numberedBullets && (
        <div className="mb-8">
          {numberedBullets.map((text, idx) => {
            return (
              <div
                className={classNames(
                  "flex items-start relative py-3 bullet-item",
                  idx < numberedBullets.length - 1 && "line",
                )}
                key={`${text + idx}`}
              >
                <p className={classNames("flex mt-1 mr-4")}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    {idx === 0 ? (
                      <circle cx="8" cy="8" r="7.5" fill="#1879CE" />
                    ) : (
                      <circle cx="8" cy="8" r="7.5" stroke="#1879CE" strokeWidth={"1px"} />
                    )}
                  </svg>
                </p>
                <Typography variant="body1">{text}</Typography>
              </div>
            );
          })}
        </div>
      )}
      {icon && <img className={"mb-10"} src={`/assets/${icon}.svg`} />}
      {informationCard && (
        <InformationalCard
          type={informationCard.type}
          showIcon={false}
          title={informationCard.title}
          content={
            <ul style={{ color: "#004F47" }}>
              {informationCard.bulletPoints.map((point) => {
                return (
                  <li className={"mb-1"} key={point.replace(" ", "")}>
                    {point}
                  </li>
                );
              })}
            </ul>
          }
        />
      )}
      {accordion && (
        <AccordionBox
          backgroundColor={accordion.backgroundColor}
          accordionType={accordion.accordionType}
          title={accordion.title}
          expandedAnswer={accordion.expandedAnswer}
        />
      )}
      {footer && <p className="text-xs leading-4 text-grey-160 font-normal pb-6">{footer}</p>}
      {transitionScreenButton}
    </div>
  );

  const screeningVariant = (
    <div
      className="flex flex-col items-center p-6 bg-white shadow-md rounded"
      style={{ marginTop: -16 }}
    >
      {image && <img src={image.file.url} alt={image.description} />}
      <Typography variant="h3" className={"font-bold my-4 text-forest-120"}>
        {title}
      </Typography>
      {subtitle && (
        <div className="py-2">
          <Typography variant="body1">
            <ReactMarkdown>{subtitle}</ReactMarkdown>
          </Typography>
        </div>
      )}
      {transitionScreenButton}
    </div>
  );

  const bubbleTextVariant = (
    <div className="flex flex-col" style={{ minHeight: "80vh" }}>
      <div className="flex flex-1 flex-col justify-center">
        {title && (
          <div className="bg-white rounded-3xl py-2 px-3 ml-2">
            <Typography variant="h3" className="">
              <ReactMarkdown>{title}</ReactMarkdown>
            </Typography>
          </div>
        )}
        {paragraphsTitle && (
          <div className="flex flex-row relative">
            <img
              src={"/assets/bubble-corner.svg"}
              alt="bubble chat corner"
              width={19}
              className="absolute bottom-0 left-0"
            />
            <div className="flex-1 bg-white rounded-3xl py-2 px-3 ml-2 mt-2 text-bubble">
              <Typography variant="body1" className="">
                <ReactMarkdown>{paragraphsTitle}</ReactMarkdown>
              </Typography>
            </div>
          </div>
        )}
      </div>
      {transitionScreenButton}
    </div>
  );

  const legacyVariant = (
    <div>
      <Typography variant="h3" className={"font-bold mb-6 text-forest-120"}>
        {title}
      </Typography>
      {subtitle && (
        <div className="py-2">
          <Typography variant="body1">
            <ReactMarkdown>{subtitle}</ReactMarkdown>
          </Typography>
        </div>
      )}
      {paragraphsTitle && <p className={"text-forest-120 mb-6 font-bold"}>{paragraphsTitle}</p>}
      {paragraphs &&
        paragraphs.map((text) => {
          return (
            <p className={"my-10 text-xl"} key={text.slice(0, 10).replace(" ", "")}>
              {text}
            </p>
          );
        })}
      {numberedBullets &&
        numberedBullets.map((text, idx) => {
          return (
            <div className="flex items-start relative my-6" key={`${text + idx}`}>
              <img
                className="mt-2"
                src={`/assets/${bulletStyleIcon}.svg`}
                alt="decorative bullet"
              />
              <span className="numbered-bullet mt-2">{idx + 1}</span>
              <p
                className={"ml-4 text-forest-120 font-normal text-base leading-6"}
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </div>
          );
        })}
      {icon && <img className={"mb-10"} src={`/assets/${icon}.svg`} />}
      {informationCard && (
        <InformationalCard
          type={"Brand"}
          showIcon={false}
          title={informationCard.title}
          content={
            <ul style={{ color: "#004F47" }}>
              {informationCard.bulletPoints.map((point) => {
                return (
                  <li className={"mb-1"} key={point.replace(" ", "")}>
                    {point}
                  </li>
                );
              })}
            </ul>
          }
        />
      )}
      {accordion && (
        <AccordionBox
          backgroundColor={accordion.backgroundColor}
          accordionType={"information"}
          title={accordion.title}
          expandedAnswer={accordion.expandedAnswer}
        />
      )}
      <button
        onClick={() => {
          // setting the answerKey to true, so it doesn't show this again
          if (answerKey) {
            field.input.onChange("true");
          }
          nextPage();
        }}
        className={`btn btn-primary w-full my-5 rounded`}
      >
        {nextButtonText ? nextButtonText : "Next"}
      </button>
    </div>
  );

  const variantMapper = {
    "Text-Bubble": bubbleTextVariant,
    Screening: screeningVariant,
    "Section-Intro": sectionIntroVariant,
  };

  return variant ? variantMapper[variant] : legacyVariant;
};
