/** @format */

// Moduel dependencies
import type { FC } from "react";

import React from "react";

import classNames from "classnames";

import type { OptionFieldRenderProps } from "@alphamedical/components";

export interface ExtendedOptionFieldRenderProps<T = string> extends OptionFieldRenderProps<T> {
  image?: any;
}

export const RadioFieldOption: FC<ExtendedOptionFieldRenderProps<string>> = ({
  input,
  disabled,
  option,
  image,
}) => {
  return (
    <label
      className={classNames(
        "flex p-3 rounded cursor-pointer single-select",
        "focus:border-2 focus:border-forest-100 single-select-option",
        input.checked ? "bg-sand-60 selected" : "bg-grey-10 sm:hover:bg-sand-40",
        disabled && "bg-grey-20",
        image && "flex flex-row items-center",
      )}
    >
      {image && (
        <img src={image.file.url} alt={image.description} className="w-12 h-12 ml-1 mr-4" />
      )}
      <input disabled={disabled} className="appearance-none inline h-full" {...input} />
      <div className="flex flex-row justify-between">
        <span className="text-forest-100 text-base leading-6 text-left font-normal">
          {option.label}
        </span>
      </div>
    </label>
  );
};
