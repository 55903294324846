/** @format */

import React from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useHistory, useParams } from "react-router";

import { Typography } from "@alphamedical/components";
import { ButtonPdb, Paper } from "src/v2/designSystem";

import { ContentfulService } from "src/api/services/ContentfulService";
import { Loader } from "src/components/Loader";
import { useStoreActions } from "src/v2/models";

import type { LandingPageRouteParams, PageContent } from "./SponsoredBenefits";

export const NotEligiblePage = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const { employer } = useParams<LandingPageRouteParams>();
  const [pageContent, setPageContent] = React.useState<PageContent>();
  const history = useHistory();
  const { setShowLogin } = useStoreActions((actions) => actions.auth);

  React.useEffect(() => {
    const fetchContentAsync = async () => {
      const { content } = await ContentfulService.getUnstructuredContentByKey({
        key: `b2b-${employer}-not-eligible-page`,
      });
      if (content) {
        setPageContent(content);
        setIsLoading(false);
      } else history.push("/my-cases");
    };
    fetchContentAsync();
  }, []);

  const handleOnNotEligibleClick = () => {
    setShowLogin(false);
    history.push({ pathname: "/my-cases" });
  };

  return isLoading || !pageContent ? (
    <Loader show={true} />
  ) : (
    <div className="flex flex-col justify-between min-h-screen">
      <div>
        <div className="flex justify-center items-center bg-sand-40 h-12">
          <div className="flex flex-1 justify-center">
            <img
              src="/assets/alpha-logo.svg"
              alt="Alpha's Brand Logo"
              className="w-20 col-span-2 place-self-center"
            />
          </div>
        </div>
        {pageContent && (
          <Paper
            elevation={0}
            rounded={false}
            className="flex flex-col justify-center m-auto px-6 max-w-xl"
          >
            <div className="flex flex-1 flex-col items-center mb-6">
              <img
                src="/assets/sponsored-benefits-banner.png"
                alt="Cheerful women standing together"
                className="mt-6"
              />
              <Typography variant="h1" className="mt-6">
                {pageContent?.title}
              </Typography>
              <Typography variant="h4" className="mt-2 text-center">
                <ReactMarkdown>{pageContent?.subtitle}</ReactMarkdown>
              </Typography>
              <ButtonPdb className="w-full mt-8" onClick={handleOnNotEligibleClick}>
                {pageContent?.cta}
              </ButtonPdb>
            </div>
          </Paper>
        )}
      </div>
    </div>
  );
};
