/** @format */

import React from "react";

import { MEMBERSHIP_PLANS } from "src/v2/constants";

export const getContent = (planCode: string, clickHandler: any, condition: string) => {
  const linkClasses = "text-cornflower-100 underline cursor-pointer";
  if (planCode == MEMBERSHIP_PLANS.alpha) {
    return {
      header: `We've rolled our ${condition}* service into Alpha Basic Plan.`,
      bulletsSubHeader: "Also included:",
      firstBullets: [
        {
          iconPath: "stethoscope-icon",
          alt: "Stethoscope Icon",
          content: (
            <>{`Affordable access to a licensed provider who you'll see for every ${condition} visit`}</>
          ),
        },
        {
          iconPath: "Story-Cost",
          alt: "Cash Icon",
          content: (
            <>
              <span className={linkClasses} onClick={clickHandler}>
                Return visits
              </span>{" "}
              &amp; prescription renewals are free
            </>
          ),
        },
        {
          iconPath: "delivery-icon",
          alt: "Delivery Icon",
          content: <>Medications shipped to you for free (or sent to your pharmacy for pick up).</>,
        },
      ],
      secondBullets: [
        {
          iconPath: "Story-Health",
          alt: "Health Icon",
          content: (
            <>
              $0 medical visit fees for treatment of{" "}
              <span className={linkClasses} onClick={clickHandler}>
                over 100 medical conditions
              </span>
            </>
          ),
        },
        {
          iconPath: "Story-Messages",
          alt: "Messages Icon",
          content: <>Unlimited messaging with your Care Team</>,
        },
      ],
    };
  } else if (planCode == MEMBERSHIP_PLANS.plus) {
    return {
      header: `Join Alpha Plus+ Plan to access treatment for ${condition}`,
      bulletsSubHeader: "Access all the same benefits of Alpha Basic Plan:",
      firstBullets: [
        {
          iconPath: "conditions-icon",
          alt: "Conditions Icon",
          content: <>Includes access to Alpha's Mental Health, Weight Loss & Diabetes Services</>,
        },
        {
          iconPath: "clipboard",
          alt: "Clipboard",
          content: (
            <>
              <span className={linkClasses} onClick={clickHandler}>
                4 visits per month
              </span>
              , that can be used for over{" "}
              <span className={linkClasses} onClick={clickHandler}>
                100 eligible medical conditions.
              </span>{" "}
              Additional visits available at a discounted rate
            </>
          ),
        },
        {
          iconPath: "messages-health",
          alt: "Messages Icon",
          content: <>Unlock free trials for talk therapy and meditation with our partners</>,
        },
      ],
      secondBullets: [
        {
          iconPath: "Story-Health",
          alt: "Health Icon",
          content: (
            <>All visits with the same dedicated provider who's an expert in women's health</>
          ),
        },
        {
          iconPath: "Story-Messages",
          alt: "Messages Icon",
          content: <>Unlimited messaging with your Care Team</>,
        },
        {
          iconPath: "Story-Cost",
          alt: "Cost Icon",
          content: <>Flexible, month-to-month commitment</>,
        },
      ],
    };
  } else {
    return {};
  }
};
