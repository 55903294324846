/** @format */

import type React from "react";

import cn from "classnames";

import { CheckboxField, TextFieldNew } from "@alphamedical/components";

import { DateFieldNew } from "src/v2/components/Wizard/fields/text";

import type { MedicalRecordsRequestFormPageProps } from "./MedicalRecordsRequestForm";

const Authorization: React.FC<MedicalRecordsRequestFormPageProps> = ({ show }) => (
  <div className={cn({ block: show, hidden: !show }, "mb-4")}>
    <div className="text-xl font-medium mb-8">Sign this authorization</div>
    <div>To proceed, write your name below to sign this Authorization Electronically.</div>
    <CheckboxField
      name="signature_checkbox"
      options={[
        {
          label: "I consent to the use of an e-signature in place of my written signature",
          value: "true",
        },
      ]}
    />
    <div>Signature</div>
    <TextFieldNew
      name="signature"
      placeholder="Signature (type your legal name here)"
      className="py-1"
    />
    <div className="mt-8">Date</div>
    <DateFieldNew
      name="signature_date"
      required
      cleaveProps={{
        delimiter: "-",
        date: true,
        datePattern: ["m", "d", "Y"],
      }}
      placeholder="mm-dd-yyyy"
      inputClassName="font-normal outline-none w-full rounded py-3 bg-transparent textfield-new border-2 border-grey-100 cursor-pointer undefined false undefined pl-4 pr-4 bg-transparent text-sm mb-2"
    />
  </div>
);

export default Authorization;
