/** @format */

import React from "react";

export const SkincarePhotosDescription = () => (
  <>
    <div className="text-sm my-2">
      <div>
        Your Alpha Provider will need clear photos to ensure the right treatment is selected for
        you. These photos must be taken today and clearly show your skin and all affected areas from
        multiple angles.
      </div>
      <div className="mt-2 flex flex-col font-bold">
        <ul>
          <li>No make up!</li>
          <li>Take photos in a well lit room.</li>
          <li>Preferably against a white or neutral background.</li>
          <li>Make sure you get all your affected areas!</li>
        </ul>
      </div>
    </div>
  </>
);
