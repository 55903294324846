/** @format */

import type React from "react";

import { Route, useHistory } from "react-router-dom";

import { hasEmployerBenefits } from "src/utils/hooks/useHasEmployerBenefits";
import { useMembership } from "src/utils/hooks/useMembership";
import { FullPageModal } from "src/v2/components";
import { useStoreState } from "src/v2/models";

interface PaywallRouteProps {
  component: React.ComponentType<any>;
  enable: boolean;
  allowedPlanCodes: string[];
  paywallTitle: string;
  paywallComponent: React.ComponentType<any>;

  [key: string]: any;
}

const PaywallRoute = ({
  component: Component,
  paywallComponent: PayWallComponent,
  enable,
  paywallTitle,
  allowedPlanCodes = [],
  allowedBenefitPlans = [],
  ...kwargs
}: PaywallRouteProps) => {
  const { activePlanCode } = useMembership();
  const userPlanRecords = useStoreState((state) => state.profile.profile?.employer_benefits);
  const hasAllowedBenefit = hasEmployerBenefits(userPlanRecords, allowedBenefitPlans);
  const history = useHistory();

  return (
    <Route
      {...kwargs}
      render={(props) => {
        if (activePlanCode === undefined) return null;

        return !enable ||
          (activePlanCode && allowedPlanCodes.includes(activePlanCode)) ||
          hasAllowedBenefit ? (
          <Component {...props} />
        ) : (
          <FullPageModal isOpen setIsOpen={() => history.goBack()} title={paywallTitle}>
            <PayWallComponent />
          </FullPageModal>
        );
      }}
    />
  );
};

export default PaywallRoute;
