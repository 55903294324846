/** @format */

import type { Action, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";

import type { AlertActionItem } from "src/api";

import { _GET, _POST } from "src/helpers/http";

import type { CreateModel } from "./_create";
import type { PaginatedResult } from "./action_items";

export interface MembershipAlertsModel {
  membershipAlertList: AlertActionItem[];
  fetchMembershipAlerts: Thunk<MembershipAlertsModel>;
  setMembershipAlerts: Action<MembershipAlertsModel, AlertActionItem[]>;
  registerMembershipAlert: Thunk<MembershipAlertsModel, "cancel" | "downgrade">;
  clearMembershipAlerts: Action<MembershipAlertsModel>;
  requestedCancellation?: boolean;
  requestedDowngrade?: boolean;
  setRequestedCancellation: Action<MembershipAlertsModel, boolean>;
  setRequestedDowngrade: Action<MembershipAlertsModel, boolean>;
}

export const membershipAlertsStore: MembershipAlertsModel = {
  membershipAlertList: [],

  fetchMembershipAlerts: thunk(async (actions, __, { getStoreState }) => {
    try {
      const storeState = getStoreState() as CreateModel;
      const { currentProfileId } = storeState.profile;
      const url = `/users/${currentProfileId}/membership_alerts`;

      const actionFetchAlertsResponse = await _GET<PaginatedResult<AlertActionItem>>(url, {});
      actions.setMembershipAlerts(actionFetchAlertsResponse.items);

      if (actionFetchAlertsResponse.total > 0) {
        const { items } = actionFetchAlertsResponse;
        actions.setRequestedCancellation(
          items.filter((alert) => alert.alert_type === "CANCEL_MEMBERSHIP").length > 0,
        );
        actions.setRequestedDowngrade(
          items.filter((alert) => alert.alert_type === "DOWNGRADE_MEMBERSHIP").length > 0,
        );
      }

      return actionFetchAlertsResponse;
    } catch (e) {
      actions.setMembershipAlerts([]);
      return null;
    }
  }),

  setMembershipAlerts: action((state, alerts) => {
    state.membershipAlertList = alerts;
  }),

  registerMembershipAlert: thunk(async (actions, actionType, { getStoreState }) => {
    try {
      const storeState = getStoreState() as CreateModel;
      const { currentProfileId } = storeState.profile;
      const url = `/users/${currentProfileId}/membership/request?action=${actionType}`;

      const actionRegisterAlertResponse = await _POST(url, {});

      if (actionRegisterAlertResponse === "Success")
        actionType === "cancel"
          ? actions.setRequestedCancellation(true)
          : actions.setRequestedDowngrade(true);

      return actionRegisterAlertResponse;
    } catch (e) {
      return null;
    }
  }),

  clearMembershipAlerts: action((state) => {
    state.membershipAlertList = [];
  }),

  setRequestedCancellation: action((state, hasRequestedCancellation) => {
    state.requestedCancellation = hasRequestedCancellation;
  }),

  setRequestedDowngrade: action((state, hasRequestedDowngrade) => {
    state.requestedDowngrade = hasRequestedDowngrade;
  }),
};
