/** @format */

import type { match } from "react-router-dom";

import React from "react";

import { Loader } from "src/components/Loader";
import { PageWrapper } from "src/components/PageWrapper";
import AtHomeTestKit from "src/v2/components/TreatmentInfo/AtHomeTestKit";
import DiabetesLabTests from "src/v2/components/TreatmentInfo/DiabetesLabTests";
import Libre2 from "src/v2/components/TreatmentInfo/Libre2";

interface MatchParams {
  condition: string;
  info: string;
}

type ComponentMap = Partial<Record<string, React.FC<any>>>;

const TREATMENT_INFO_COMPONENTS: Partial<Record<string, ComponentMap>> = {
  diabetes: {
    "lab-tests": DiabetesLabTests,
    "at-home-test-key": AtHomeTestKit,
    "libre-2": Libre2,
  },
};

const VisitDetailsTreatmentInfo = ({ match }: { match: match<MatchParams> }) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const condition = match.params.condition;
  const info = match.params.info;

  const Component = TREATMENT_INFO_COMPONENTS[condition]?.[info];

  if (!Component) {
    setLoading(false);
  }

  return (
    <PageWrapper>
      <Loader show={loading} message={"Loading..."} screen={true}></Loader>
      {Component ? (
        <div className="max-w-xl mx-auto mb-8 mt-0">
          <Component setLoading={setLoading} />
        </div>
      ) : (
        {}
      )}
    </PageWrapper>
  );
};

export default VisitDetailsTreatmentInfo;
