/** @format */

import React from "react";
import { useForm } from "react-final-form";

import { Typography } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import { AlphaModal } from "src/v2/components/AlphaModal";

interface SkipQuestionProps {
  goToPage: (key: string, values: any) => void;
  nextClick: (values: any) => void;
  skip: {
    nextPageKey?: string;
    showPopup?: boolean;
    popupText?: string;
    stayButtonText?: string;
    skipButtonText?: string;
  };
}

const SkipQuestion = ({ goToPage, nextClick, skip }: SkipQuestionProps) => {
  const [popupOpen, setPopupOpen] = React.useState(false);
  const form = useForm();

  const skipQuestion = () => {
    const { values } = form.getState();
    if (skip.nextPageKey) {
      goToPage(skip.nextPageKey, values);
    } else {
      nextClick(values);
    }
  };

  return (
    <div className={"my-4 flex justify-center"}>
      {skip && skip.skipButtonText && skip.stayButtonText && (
        <AlphaModal
          isOpen={popupOpen}
          shouldCloseOnOverlayClick={false}
          onRequestClose={() => setPopupOpen(false)}
          className="max-w-xl mx-auto"
          cxPadding="p-0"
          bgColor="white"
          noCloseIcon
        >
          <div className="w-full sticky top-0 flex items-center justify-between p-5 border-b border-grey-light bg-white">
            <Typography variant="subtitle1">Skip This Section?</Typography>
            <i
              className="fa fa-times text-2xl cursor-pointer text-grey-160"
              onClick={() => setPopupOpen(false)}
            />
          </div>
          <div className="h-auto py-6 px-5" style={{ minHeight: "10rem" }}>
            {skip.popupText}
          </div>
          <div className="w-full sticky bottom-0 gap-4 flex items-center justify-end bg-grey-lightest py-4 px-5">
            <ButtonPdb onClick={() => setPopupOpen(false)} variant="secondary">
              {skip.stayButtonText}
            </ButtonPdb>
            <ButtonPdb onClick={skipQuestion}>{skip.skipButtonText}</ButtonPdb>
          </div>
        </AlphaModal>
      )}
      <ButtonPdb
        onClick={(e) => {
          e.preventDefault();
          if (skip.showPopup) {
            setPopupOpen(true);
          } else {
            skipQuestion();
          }
        }}
        variant="secondary"
        className="w-full"
      >
        Skip
      </ButtonPdb>
    </div>
  );
};

export default SkipQuestion;
