/** @format */

import React from "react";

import type { ServiceCategorySchema } from "src/api";

import VisitCard from "./VisitCard";

interface VisitGroupListProps {
  options: ServiceCategorySchema[];
  onSelectVisitGroup: (visitGroup: ServiceCategorySchema) => void;
}

export const VisitGroupList = (props: VisitGroupListProps) => {
  return (
    <>
      {props.options.map((visitGroup, idx) => (
        <VisitCard
          key={visitGroup.key}
          onClick={() => props.onSelectVisitGroup(visitGroup)}
          idx={idx}
          imageUrl={visitGroup.imageURL}
          title={visitGroup.name}
          description={visitGroup.description}
        />
      ))}
    </>
  );
};
