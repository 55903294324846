/** @format */

import React from "react";
import { Link } from "react-router-dom";

import _ from "lodash";

import type { EitherAddressType } from "src/components/ReadOnlyAddress";

import { PageWrapper } from "src/components/PageWrapper";
import ReadOnlyAddress from "src/components/ReadOnlyAddress";
import config from "src/config";
import { ACCOUNT_PERSONAL_INFORMATION } from "src/routes";
import { useStoreDispatch } from "src/v2/models";

export interface CompletionStep {
  imgSrc: string;
  title?: string;
  description: string | JSX.Element;
  link?: React.ReactElement;
  address?: EitherAddressType;
}

interface CompletionStepsProps {
  steps: CompletionStep[];
  cxImageHeight?: string;
}

const getContactSupportStep = (text: string) => (
  <>
    <p className="mb-3">{text}</p>
    <Link to="/messages/new" className="text-sky-dark font-semibold">
      Contact Alpha Support <span className="ml-2 fa fa-arrow-right" />
    </Link>
  </>
);

export const contactSupportStep: CompletionStep = {
  description: getContactSupportStep("Reach out to us if you have any questions."),
  imgSrc: "/assets/alpha-support-icon.svg",
};

export const contactSupportSyncStep: CompletionStep = {
  description: getContactSupportStep(
    "If you have any questions or need accessibility options, please let us know.",
  ),
  imgSrc: "/assets/alpha-support-icon.svg",
};

export const getDefaultSteps = (
  externalPharmacy: boolean,
  condition: string,
  isSync?: boolean,
  address?: EitherAddressType,
  source?: any,
) => {
  const isUbacare = source === "UBACARE";
  const steps = [];
  const visitResponseTime = condition === "COVID-19" ? "8 hours" : config.responseTime;
  steps.push({
    description: isUbacare
      ? "An Alpha provider will review information about your condition, prescription, and medical history."
      : isSync
        ? "Your Alpha Provider will review your information and discuss treatment options during your call."
        : `Your Alpha Provider will review your case within ${visitResponseTime} and respond to you in the ${condition} section of your Messages.`,
    imgSrc: "/assets/provider-icon-pink-bg.svg",
  });
  if (!isUbacare) {
    steps.push({
      description: (
        <>
          <span>
            {`Because you chose ${
              externalPharmacy ? "an External Pharmacy" : "Alpha Network Pharmacy"
            }, your medication, if prescribed, will be ${externalPharmacy ? "sent" : "shipped"} `}
          </span>
          {address ? (
            <>
              <span>to:</span>
              <div className="font-semibold my-3">
                {address.name && <span>{address.name}</span>}
                <ReadOnlyAddress address={address} />
              </div>
            </>
          ) : (
            <span>to the address you selected.</span>
          )}
          {address && !externalPharmacy && (
            <Link to={ACCOUNT_PERSONAL_INFORMATION} className="text-sky-dark font-semibold">
              Change shipping address <span className="ml-2 fa fa-arrow-right" />
            </Link>
          )}
        </>
      ),
      imgSrc: `/assets/${externalPharmacy ? "delivery" : "shipping-icon"}.svg`,
    });
  }
  steps.push(isSync ? contactSupportSyncStep : contactSupportStep);
  return steps;
};

export const CompletionSteps = ({ steps, cxImageHeight }: CompletionStepsProps) => (
  <>
    {steps.length > 0 &&
      steps.map((step, index) => {
        return (
          <div key={`step-${step.title || index}`}>
            <div className="flex items-start pb-8">
              <img
                className={`w-8 ${cxImageHeight || "h-8"} mt-1`}
                src={step.imgSrc}
                alt=""
                role="presentation"
              />
              <div className="pl-6">
                {step.title && (
                  <div
                    className="mb-2 font-semibold"
                    dangerouslySetInnerHTML={{ __html: step.title }}
                  />
                )}
                {(_.isString(step.description) && (
                  <div
                    className={step.title ? "text-sm" : "text-base"}
                    dangerouslySetInnerHTML={{ __html: step.description }}
                  />
                )) || (
                  <div className={step.title ? "text-sm" : "text-base"}>{step.description}</div>
                )}
              </div>
            </div>
            {step.address && (
              <div className="pl-16 font-semibold mb-3">
                {step.address.name && <span>{step.address.name}</span>}
                <ReadOnlyAddress address={step.address} />
              </div>
            )}
            {step.link && (
              <div className="flex items-start pb-8">
                <div className="w-12" />
                {step.link}
              </div>
            )}
          </div>
        );
      })}
  </>
);

interface CompletionPageProps {
  firstName?: string;
  info?: string;
  showMessagesLink?: boolean;
  thankYouMessage?: string;
  heading?: string;
  btnStyle?: string;
  headingFontSize?: string;
  cxMaxWidth?: string;
}

const CompletionPage: React.FC<CompletionPageProps> = ({
  firstName,
  info,
  children,
  heading,
  thankYouMessage,
  btnStyle,
  headingFontSize,
  cxMaxWidth,
  showMessagesLink = true,
}) => {
  const dispatch = useStoreDispatch();

  React.useEffect(() => {
    dispatch.channels.fetchChannels();
  }, []);

  const _heading = heading || (firstName ? `Thanks, ${firstName}!` : "Thank you!");

  return (
    <PageWrapper
      fitToPageHeight={true}
      cxMaxWidth={`${cxMaxWidth || "sm:max-w-2xl"} w-full mx-auto pt-4`}
      cxPadding="p-0"
    >
      <div className="h-full px-6 pb-6">
        <div className="py-12 text-xl">
          <h1 className={` ${headingFontSize || "text-2xl font-bold"} leading-tight`}>
            {_heading}
          </h1>
          {info && <p>{info}</p>}
        </div>
        {children}
        {showMessagesLink && (
          <Link
            to={"/messages"}
            className={`btn ${btnStyle || "btn-outline-primary"} w-full sm:w-64 mx-auto block mt-4`}
          >
            Go to Messages
          </Link>
        )}
      </div>
    </PageWrapper>
  );
};

export default CompletionPage;
