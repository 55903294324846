/** @format */
import React from "react";

import classNames from "classnames";

import { Typography } from "@alphamedical/components";

import type { Content } from "src/api";

interface CareGuideResourceLinksSectionProps {
  moreResources: Content | undefined;
}

const title = "More resources";

export const CareGuideResourceLinksSection = ({
  moreResources,
}: CareGuideResourceLinksSectionProps): JSX.Element => {
  return moreResources?.resourceLinks ? (
    <div className="flex flex-col mb-4 p-6 bg-white justify-start shadow">
      <Typography variant="h3">{moreResources?.group_section_title?.title || title}</Typography>
      <div className="mt-4">
        {moreResources.resourceLinks.map((link, idx) => (
          <Typography variant="hyperlink" key={link.url} className={classNames(idx > 0 && "mt-4")}>
            <a target={link.isNewTab ? "_blank" : "_self"} rel="noreferrer" href={link.url}>
              {link.linkLabel || link.url}
            </a>
          </Typography>
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
};
