/** @format */

// Module dependencies
import type { FC } from "react";

import React from "react";

import classnames from "classnames";

// UI Component & Styles
import "./ProgressBar.scss";

interface ProgressBarProps {
  /**
   * An array of numbers representing the % progress of each segment in the progress bar.
   */
  progress: number[];

  /**
   * A boolean value indicating whether the progress bar should be segmented or not.
   * If true, the progress bar will display multiple segments, each with its own progress value.
   * If false, the progress bar will display a single bar representing the overall progress.
   * @default true
   */
  segmented: boolean;

  /**
   * Optional boolean value that determines whether or not the progress bar should be sticky along with the TopBar.
   * @default false
   */
  withTopBar?: boolean;

  /**
   * Optional parameter that designates the color of the progress bar.
   * @default "bg-forest-100"
   */
  progressBarColor?: string;
}

export const ProgressBar: FC<ProgressBarProps> = ({
  progress,
  segmented,
  withTopBar = false,
  progressBarColor = "bg-forest-100",
}) => {
  const compoundProgress = (): number => {
    const currentProgress = progress.reduce((acc, curr) => acc + curr, 0);
    const totalProgress = progress.length * 100;

    return (currentProgress / totalProgress) * 100;
  };

  return (
    <div
      className={classnames(
        "flex w-full bg-white",
        segmented && "gap-1 sm:gap-2",
        withTopBar && "sticky z-20 progressbar_topbar",
      )}
    >
      {segmented ? (
        progress.map((barProgress, i) => (
          <div className="bg-forest-20 h-2 w-full relative" key={i}>
            <div
              className={classnames(progressBarColor + " absolute top-0 left-0 h-2")}
              style={{ width: `${barProgress}%` }}
            />
          </div>
        ))
      ) : (
        <div className="bg-forest-20 h-2 w-full relative">
          <div
            className={classnames(progressBarColor + " absolute top-0 left-0 h-2")}
            style={{ width: `${compoundProgress()}%` }}
          />
        </div>
      )}
    </div>
  );
};
