/** @format */
import type { Action, State, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";

import type {
  ChannelOpenTaskSchema,
  PatientActionItemSchema,
  ReturnConsultTaskSchema,
} from "src/api";
import type { CreateModel } from "src/v2/models/_create";

import { ActionItemsService, PatientActionItemsService } from "src/api";
import { getSortedReturnVisits } from "src/v2/utils/returnVisitUtils";

export interface PaginatedResult<T = any> {
  items: T[];
  total: number;
  page: number;
  per_page: number;
}

export interface ActionItemModel {
  actionItems: PatientActionItemSchema[];
  messageActionItem?: ChannelOpenTaskSchema;
  returnVisits: ReturnConsultTaskSchema[];
  fetchingReturnVisits: boolean;

  setMessageActionItem: Action<ActionItemModel, { messageActionItem?: ChannelOpenTaskSchema }>;
  setActionItems: Action<ActionItemModel, { actionItems: PatientActionItemSchema[] }>;
  clearMessageActionItem: Action<ActionItemModel>;
  fetchMessageActionItemByChannel: Thunk<ActionItemModel, number>;
  fetchActionItems: Thunk<ActionItemModel>;
  fetchReturnVisitActionItems: Thunk<ActionItemModel>;
  setReturnVisits: Action<ActionItemModel, { returnVisits: ReturnConsultTaskSchema[] }>;
  setFetchingReturnVisits: Action<ActionItemModel, boolean>;
}

export const returnVisitsSelector = (state: State<CreateModel>) => {
  const serviceList = state.service.serviceList;
  const returnVisits = state.actionItems.returnVisits;

  const [dueVisits, upcomingVisits] = getSortedReturnVisits(serviceList, returnVisits);

  // Filter some undefined values from previous function
  return [...dueVisits, ...upcomingVisits].filter((visit) => !!visit);
};

export const actionItemStore: ActionItemModel = {
  setActionItems: action((state, { actionItems }) => {
    state.actionItems = actionItems;
  }),
  actionItems: [],
  messageActionItem: undefined,
  returnVisits: [],
  fetchingReturnVisits: false,
  setMessageActionItem: action((state, payload) => {
    state.messageActionItem = payload.messageActionItem;
  }),
  setReturnVisits: action((state, payload) => {
    state.returnVisits = payload.returnVisits;
  }),
  clearMessageActionItem: action((state) => {
    state.messageActionItem = undefined;
  }),
  fetchMessageActionItemByChannel: thunk(async (actions, channelId) => {
    try {
      const actionItemResponse = await ActionItemsService.getChannelOpenTask({
        userId: "me",
        channelId,
      });
      actions.setMessageActionItem({ messageActionItem: actionItemResponse });
      return actionItemResponse;
    } catch (e) {
      actions.setMessageActionItem({ messageActionItem: undefined });
      return null;
    }
  }),
  fetchActionItems: thunk(async (actions) => {
    const actionItems = await PatientActionItemsService.getPatientActionItems({ userId: "me" });
    actions.setActionItems({ actionItems });
  }),
  fetchReturnVisitActionItems: thunk(async (actions) => {
    const returnVisits = await ActionItemsService.fetchReturnVisits({ userId: "me" });
    actions.setReturnVisits({ returnVisits });
    return returnVisits;
  }),
  setFetchingReturnVisits: action((state, payload) => {
    state.fetchingReturnVisits = payload;
  }),
};
