/** @format */

import { addMinutes } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

import { Typography } from "@alphamedical/components";

import type { SynchronousVisitSchema } from "src/api";

import { guessTimezone } from "src/utils/date_utils";
import { SYNC_VISIT_DURATION_MINUTES } from "src/utils/hooks/useSynchronousVisits";
import VisitCtaCard from "src/v2/routes/Visit/VisitCtaCard";

interface SyncVisitCardProps {
  syncVisit: SynchronousVisitSchema;
  callBack?: () => void;
}

export const SyncVisitCard = ({
  callBack,
  syncVisit: { condition_name, meeting_timestamp: timestamp },
}: SyncVisitCardProps) => {
  const timezone = guessTimezone() || "UTC";
  const date = formatInTimeZone(timestamp, timezone, "MMM d").toUpperCase().split(" ");
  const timeStart = formatInTimeZone(timestamp, timezone, "h:mm a");
  const endTimestamp = addMinutes(new Date(timestamp), SYNC_VISIT_DURATION_MINUTES);
  const timeEnd = formatInTimeZone(endTimestamp, timezone, "h:mm a");

  const iconElement = (
    <div className="w-16 h-16 bg-grey-10 flex flex-col justify-center items-center">
      <p className="text-xs">{date[0]}</p>
      <Typography variant="h4" component="h4" className="text-md">
        {date[1]}
      </Typography>
    </div>
  );

  return (
    <div>
      <VisitCtaCard
        text={`${condition_name} visit`}
        subText={`${timeStart} - ${timeEnd}`}
        iconElement={iconElement}
        iconWidth="64px"
        showBottomDivider={false}
        callBack={callBack ? () => callBack() : undefined}
      />
    </div>
  );
};
