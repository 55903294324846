/** @format */

import React from "react";
import Sheet from "react-modal-sheet";

import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Typography } from "@alphamedical/components";

export const BottomSheetHeader = (props: { title: string; onClick: () => void }) => (
  <Sheet.Header>
    <div className="pt-4 pb-2 flex justify-between items-center">
      <Typography variant="subtitle1">{props.title}</Typography>
      <div onClick={props.onClick} role="button">
        <FontAwesomeIcon size="xl" className="p-3" icon={faTimes} />
      </div>
    </div>
  </Sheet.Header>
);
