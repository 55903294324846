/** @format */

import React from "react";

import moment from "moment";

import type { FollowupItem } from "src/components/Popups/types";

import { AlphaModal } from "src/v2/components/AlphaModal";
import { Button } from "src/v2/components/Button";
import { formatPrice } from "src/v2/components/Payment/utils";
import { useStoreDispatch } from "src/v2/models";

export interface RefillTooSoonApprovalPopupProps {
  item: FollowupItem;
  showPopup: boolean;
  setShowPopup: (arg: boolean) => void;
  key?: number;
  pii?: any;
  profile?: any;
}

export const RefillTooSoonApprovalPopup = ({
  item: { followup_type, medication_name, order_id, medication_cost, next_refill_date },
  showPopup,
  setShowPopup,
  pii,
  profile,
}: RefillTooSoonApprovalPopupProps) => {
  const dispatch = useStoreDispatch();
  const payCashOrWaitForNextRefill = (approved: boolean) => {
    if (approved) {
      dispatch.profile.sendOrderApproval({
        approved,
        followup_type,
        order_id,
        medication_name,
        medication_cost,
      });
    } else {
      dispatch.profile.sendOrderApproval({
        approved,
        order_id,
        followup_type,
        next_refill_date: moment(next_refill_date).format("LL"),
        medication_name,
      });
    }
    setShowPopup(false);
  };

  return (
    <div>
      <AlphaModal
        isOpen={showPopup && !profile.hasSeenPopup}
        className="max-w-sm m-auto bg-white shadow-md"
        addPaddingForNavBar={true}
        cxPadding="p-8"
        onRequestClose={() => {
          setShowPopup(false);
        }}
      >
        <div className="flex flex-col text-16">
          <p className="font-700">
            Hi {(pii && pii.preferred_firstname) || (pii && pii.first_name)},
          </p>
          <p className="mb-4">
            Alpha Care Team here!
            <br />
            <br /> We sent your prescription to the pharmacy but your insurance only allows refills
            within a certain amount of time.
          </p>
          <p className="mb-4">
            We will have to wait to send your next prescription until the allowable fill date, which
            is {moment(next_refill_date).format("LL")}.
          </p>
          <p className="mb-4">
            If you think this is an error or if you have refilled your prescription elsewhere,
            please let us know so we can act accordingly.
          </p>
          <p className="mb-4">
            Alternatively, we can run your order this once to process as cash. We would charge the
            card on file {medication_cost && formatPrice(medication_cost)} for the {medication_name}
            .
          </p>

          <p className="mb-6">
            If you have any questions, we would be happy to chat! Feel free to reach out here any
            time to connect with our care team.
            <br />
            <br />
            Sincerely,
            <br />
            The Alpha Care Team
          </p>
          <div className="flex justify-content flex-col">
            <Button
              extraClassNames="btn btn-violator cursor-pointer"
              disabled={profile.postingApproval}
              onClick={() => payCashOrWaitForNextRefill(true)}
            >
              Pay Cash
            </Button>
            <Button
              extraClassNames={`
                                    text-center
                                    border-2 border-primary
                                    hover:text-white
                                    items-center cursor-pointer`}
              disabled={profile.postingApproval}
              bgColor={"white"}
              hoverBgColor={"primary-80"}
              textColor={"primary"}
              onClick={() => payCashOrWaitForNextRefill(false)}
            >
              Wait for Next Refill Date
            </Button>
          </div>
          {profile.error && <p className="text-red">{profile.error}</p>}
        </div>
      </AlphaModal>
    </div>
  );
};
