/** @format */

import type { RouteComponentProps } from "react-router-dom";

import React from "react";

import ChatChannel from "src/components/Chat/ChatChannel";
import { PageWrapper } from "src/components/PageWrapper";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";

const ChatThread = (props: RouteComponentProps<{ channelName: string }>) => {
  return (
    <PageWrapper fitToPageHeight={true} cxMaxWidth="w-screen sm:max-w-xl" bgColor="bg-white">
      <ChatChannel channelName={props.match.params.channelName} />
    </PageWrapper>
  );
};

export default WithTopBarNavigation(ChatThread);
