/** @format */

import React from "react";

import { faCircle } from "@fortawesome/pro-light-svg-icons";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import type { FunnelPageContent } from "./WeightLossSignUpFunnel";

interface LoaderPageVariantProps {
  currentPage: FunnelPageContent;
  onAnimationComplete: () => void;
  styles?: { [key: string]: string };
}

export const LoaderPageVariant = ({
  currentPage,
  onAnimationComplete,
  styles = {},
}: LoaderPageVariantProps) => {
  const [progress, setProgress] = React.useState(0);
  const numberOfBullets = currentPage.bullets?.length || 0;

  React.useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const nextProgress = prevProgress + numberOfBullets;
        if (nextProgress > numberOfBullets * 100) {
          clearInterval(interval);

          if (onAnimationComplete) {
            setTimeout(onAnimationComplete, 500);
          }
        }
        return nextProgress;
      });
    }, 25);

    return () => clearInterval(interval);
  }, [currentPage.bullets?.length]);

  return (
    <div className="sm:max-w-2xl mx-auto w-full px-4 py-6">
      <div className="pb-6">
        <img src="/assets/alpha-logo.svg" alt="Alpha Logo" className="w-24 object-top mx-auto" />
      </div>
      <div className="px-8 py-6">
        {currentPage.subtitle && (
          <p className="text-2xl font-medium text-center">
            {currentPage.subtitle.map((part: string, index: number) => (
              <div key={index} className={index === 1 ? styles.loaderText : "text-forest-darker"}>
                {part}
              </div>
            ))}
          </p>
        )}
        {currentPage.bullets && currentPage.bullets.length > 0 && (
          <div>
            <div className="flex justify-center py-10">
              {[0, 1, 2].map((segment, idx) => (
                <div key={segment} className="bg-white w-1/3 h-2 mx-1 relative overflow-hidden">
                  <div
                    className={`${styles.loaderColor} absolute top-0 left-0 h-full transition-all duration-150`}
                    style={{ width: `${Math.min(progress - idx * 100, 100)}%` }}
                  />
                </div>
              ))}
            </div>
            {currentPage.bullets.map((bullet, idx) => {
              const isCompleted = (idx + 1) * 100 < progress;
              return (
                <div key={idx} className="flex flex-row flex-1 items-center gap-3 mb-5">
                  <FontAwesomeIcon
                    icon={isCompleted ? faCircleCheck : faCircle}
                    className={styles.loaderCheckbox}
                    size="xl"
                  />
                  <p className="text-base font-normal text-forest-120">{bullet}</p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
