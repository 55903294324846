/** @format */

import React, { useState } from "react";
import { Form } from "react-final-form";
import { Redirect, useHistory } from "react-router-dom";

import { usePubNub } from "pubnub-react";

import { ConsultsService, UsersService } from "src/api";
import { addMessageAction } from "src/components/Chat/utils";
import { DynamicFileFieldComponent } from "src/components/DynamicForm/Fields/DynamicFileField";
import { _POST, _PUT } from "src/helpers/http";
import { ACCOUNT_PERSONAL_INFORMATION } from "src/routes";
import { useGetChannels } from "src/utils/hooks";
import IconTextLink from "src/v2/components/TreatmentInfo/IconTextLink";
import { useCurrentUser } from "src/v2/models/profile";

export const PhotoIDUpdateRequest = () => {
  const messageActionArgs = JSON.parse(localStorage.getItem("messageAction") || "{}");
  const history = useHistory();
  const currentUser = useCurrentUser();
  const pubnub = usePubNub();
  const [stage, setStage] = useState(1);
  const [expanded, setExpanded] = useState(false);
  const { channels } = useGetChannels();
  const supportChannel = channels?.find((channel) => channel.channel_type === "SUPPORT");

  const completeRequest = async (values: any) => {
    await ConsultsService.updateConsultFile({
      userId: currentUser.id,
      consultId: messageActionArgs.consult_id,
      requestBody: {
        consult_file_type: "license_photo",
        data_uri: values.photo_id,
      },
    });

    await UsersService.requestUpdatePhotoId({
      requestBody: {
        patient_id: currentUser.id,
        action: "complete_update_request",
        consult_id: messageActionArgs.consult_id,
      },
    }).then(() => {
      addMessageAction({ ...messageActionArgs, pubnub });
      localStorage.removeItem("messageAction");
      setStage(stage + 1);
    });
  };

  if (!messageActionArgs.consult_id && stage !== 3) {
    return <Redirect to="/messages" />;
  }

  return (
    <>
      <div className="grid max-w-lg p-6 h-screen items-start m-auto">
        {stage === 1 && (
          <>
            <div className="grid items-start">
              <h1 className="text-2xl my-4">
                {stage == 1 ? "Update your photo ID" : "Please take a photo of your ID"}
              </h1>
              <div className="grid bg-sand-40 p-4 mb-4">
                <div className="flex items-center" onClick={() => setExpanded(!expanded)}>
                  <div className="text-violator-60 fas fa-question-circle" />
                  <span className="ml-3 text-small text-primary">
                    Why do I need to provide this?
                  </span>
                </div>
                {expanded && (
                  <span className="mt-2 text-small text-primary">
                    Telemedicine laws require Alpha Providers to know who they are treating. Your
                    information is secure. Only your provider and Alpha Care team can access it.
                  </span>
                )}
              </div>
              <span>Your photo ID must be one of the following government-issued photo IDs:</span>
              <div className="grid mt-3">
                <li className="mb-6 flex items-start">
                  <img src="/assets/sand-bullet.svg" className="mr-4 mt-2" />
                  driver's license
                </li>
                <li className="mb-6 flex items-start">
                  <img src="/assets/sand-bullet.svg" className="mr-4 mt-2" />
                  passport
                </li>
                <li className="mb-6 flex items-start">
                  <img src="/assets/sand-bullet.svg" className="mr-4 mt-2" />
                  state-issued ID card
                </li>
              </div>
            </div>
            <button className="btn btn-primary self-end" onClick={() => setStage(stage + 1)}>
              Continue
            </button>
          </>
        )}
        {(stage == 2 || stage == 3) && (
          <>
            <Form onSubmit={completeRequest}>
              {({ handleSubmit, values }) => (
                <>
                  <div className="grid items-start">
                    <h1 className="text-2xl my-4">
                      {stage == 2
                        ? "Please take a photo of your photo ID"
                        : "Your photo ID has been updated and we'll now continue reviewing your visit."}
                    </h1>
                    <form onSubmit={handleSubmit} className="flex flex-row">
                      <div className="grid flex-1 w-1/3">
                        <DynamicFileFieldComponent
                          key="photo_id"
                          showLabelForImage={true}
                          field={{
                            key: "photo_id",
                            accepts: "image/*",
                            type: "file",
                            message:
                              "Place on a flat surface with the front side up then tap here to take a photo.",
                          }}
                          disabled={stage == 3}
                          version={2}
                        />
                        {stage == 2 && (
                          <div className="flex bg-grey-lightest p-4 items-center">
                            <div className="fas fa-info-circle text-sky-100" />
                            <span className="ml-3 text-sm text-primary">
                              This photo must be clearly legible and not blurry
                            </span>
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                  <button
                    onClick={stage == 3 ? () => history.push("/my-cases") : handleSubmit}
                    className={`btn btn-primary ${stage == 3 ? "" : "self-end"}`}
                    disabled={!values.photo_id}
                  >
                    {stage == 2 ? "Next" : "Continue to Dashboard"}
                  </button>
                </>
              )}
            </Form>
            {stage == 3 && (
              <>
                <div className="grid">
                  <span className="text-primary text-lg mb-4 mt-4">Need more help?</span>
                  <IconTextLink
                    treatmentInfo={{
                      icon: "/assets/email.svg",
                      iconAlt: "Email Icon",
                      url: `/messages/${supportChannel?.name}`,
                      title: "Contact Alpha Support →",
                    }}
                    showRightCarrot={false}
                    showBorderTop={false}
                    textClass={"text-forest-60"}
                  />
                </div>
                <div className="grid">
                  <span className="text-primary text-lg mb-4">
                    Need to update your photo ID again?
                  </span>
                  <IconTextLink
                    treatmentInfo={{
                      icon: "/assets/user-icon-grey.svg",
                      iconAlt: "User Icon",
                      url: ACCOUNT_PERSONAL_INFORMATION,
                      title: "Go to your Account →",
                    }}
                    showRightCarrot={false}
                    showBorderTop={false}
                    textClass={"text-forest-60"}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
