/** @format */
import { faCalculator, faRuler, faWeightScale } from "@fortawesome/pro-regular-svg-icons";

import type { ChartType } from "src/v2/components/Charts/types";

export const TIME_TAKEN_OPTIONS = [
  { label: "Morning", value: "morning" },
  { label: "Afternoon", value: "afternoon" },
  { label: "Evening", value: "evening" },
  { label: "Night", value: "night" },
];
export const DATE_TAKEN_FORMAT = "MM-DD-YYYY";

const chartTypesMeta = {
  weight: { long: "pounds", short: "lb", icon: faWeightScale },
  "waist-circumference": { long: "inches", short: "in", icon: faRuler },
  bmi: { long: "", short: "", icon: faCalculator },
};

export const measurementTypes = {
  weight: "Weight",
  "waist-circumference": "Waist Circumference",
  bmi: "Body Mass Index",
};
export const getShortUnitsForChartType = (type: ChartType | string) => chartTypesMeta[type].short;
export const getMeasurementTypeDisplay = (type: ChartType | string) => measurementTypes[type];
