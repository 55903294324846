/** @format */

// Module dependencies
import type { FC } from "react";

import React from "react";

import classNames from "classnames";

import type { WebNavigationProps } from "src/v2/designSystem/WebNavigation";

// UI Components & Styles
import { BottomNavigationIcon } from "./BottomNavigationIcon";

import "./BottomNavigation.scss";

export const BottomNavigation: FC<WebNavigationProps> = ({ activeValue, comparator, icons }) => {
  const availableIcons = icons.filter(({ visibleOn }) => visibleOn !== "desktop");

  return (
    <div
      className={classNames(
        "w-full bottom-nav-container border-t bg-white z-30",
        "py-4 px-6 flex justify-between align-center border-grey-60 sm:hidden",
      )}
    >
      {availableIcons.map((icon) => (
        <BottomNavigationIcon
          {...icon}
          key={icon.value}
          isActive={comparator(activeValue, icon.value)}
        />
      ))}
    </div>
  );
};
