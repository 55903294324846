/** @format */

import React from "react";

interface EligibilityFormVisitProps {
  forceExit?: boolean;
  closeModal: any;
  saveValues: any;
  values: any;
  moveForward: any;
}

export const EligibilityFormVisit = (props: EligibilityFormVisitProps) => {
  return (
    <div className="m-5">
      <div className="text-lg my-3">
        To ensure comprehensive care of your health, we recommend you see a medical provider
        in-person. Based on the conditions you've presented, we won't be able to prescribe treatment
        for you online, but we can help you schedule an appointment with a provider located near
        you.
      </div>
      <div className="text-lg my-3">
        If you choose to complete your consult, our staff will reach back to coordinate your care
        with another provider outside of our network (normal charges would still apply). You may
        also choose to end your consult here.
      </div>
      <button
        className="btn btn-primary w-full my-2"
        onClick={async () => {
          await props.saveValues(props.values);
          props.moveForward();
          props.closeModal();
        }}
      >
        Got it - I'll continue with my consultation
      </button>
      <div
        onClick={props.closeModal}
        className={`
            block
            text-sm
            text-primary-60 hover:text-primary
            cursor-pointer hover:underline text-center
          `}
      >
        Thanks for letting me know - I'll schedule an in-persion visit
      </div>
    </div>
  );
};
