/** @format */

import React from "react";
import { useParams } from "react-router-dom";

import { Typography } from "@alphamedical/components";

import { ChannelList } from "src/components/Chat/ChannelList";
import { useChannels } from "src/components/Chat/ChannelLoader";
import { PageWrapper } from "src/components/PageWrapper";
import { useAuthenticatedUser } from "src/utils/hooks";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { ProfileSwitcher } from "src/v2/components/Family/ProfileSwitcher";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";

const teamChannelData = {
  clinical: { type: "SERVICE", title: "Alpha Clinical Team" },
  support: { type: "SUPPORT", title: "Support Team" },
  notifications: { type: "NOTIFICATION", title: "Notifications" },
};

const MessageThread = () => {
  const channels = useChannels();
  const { team } = useParams<{ team: keyof typeof teamChannelData }>();
  const [authenticatedUser] = useAuthenticatedUser();
  const children = authenticatedUser?.children || [];

  useTopBar({
    title: teamChannelData[team]?.title || "All Messages",
    showBackButton: true,
    variant: "nested",
  });
  return (
    <div className="flex flex-col flex-shrink bg-white px-4 pt-4">
      {/* don't render this div if there won't be anything in it - avoids an empty white space here */}
      {team !== "notifications" && (channels.length === 0 || children.length > 0) && (
        <div className="pb-2 text-center">
          <div className="mb-4 w-full sm:w-2/3 mx-auto">
            <ProfileSwitcher bgClass="bg-grey-lightest" />
          </div>
          {channels.length === 0 && (
            <div>
              <p className="mb-2">
                You will be able to message the Alpha Care Team here once you have completed your
                first visit with us.
              </p>
              <p>
                For any questions or inquiries you may have at this time, please email us at{" "}
                <a href="mailto:contact@helloalpha.com">contact@helloalpha.com</a>.
              </p>
            </div>
          )}
        </div>
      )}
      {team === "notifications" && <Typography variant="h1">Notifications</Typography>}
      <div className="flex justify-center">
        <ChannelList
          channelTypeFilter={team ? [teamChannelData[team].type] : []}
          channels={channels}
        />
      </div>
    </div>
  );
};

const MessageThreadWrapper = () => {
  return (
    <PageWrapper
      fitToPageHeight={true}
      cxMaxWidth="w-screen sm:max-w-xl"
      bgColor="bg-white"
      cxPadding="p-0"
    >
      <MessageThread />
    </PageWrapper>
  );
};

export default WithTopBarNavigation(MessageThreadWrapper);
