/** @format */

import React from "react";
import { useHistory } from "react-router-dom";

import {
  faClipboardListCheck,
  faPrescriptionBottlePill,
  faTruckFast,
  faVials,
} from "@fortawesome/pro-regular-svg-icons";
import { faStethoscope } from "@fortawesome/pro-solid-svg-icons";

import { ChoiceChips, Typography } from "@alphamedical/components";

import { Analytics } from "src/analytics";
import { BaseCard } from "src/components/Cards/BaseCard";
import { EngagementFeatureCard } from "src/components/Cards/EngagementFeatureCard";
import { PageWrapper } from "src/components/PageWrapper";
import * as PDB_ROUTES from "src/routes";
import { useFeatureFlags } from "src/utils/hooks/useFeatureFlags";
import { useMembership } from "src/utils/hooks/useMembership";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { ProfileSwitcher } from "src/v2/components/Family/ProfileSwitcher";
import { IconMenuList } from "src/v2/components/Menus/IconMenuList";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { MEMBERSHIP_PLANS } from "src/v2/constants";
import { useStoreDispatch, useStoreState } from "src/v2/models";
import { CHECKIN_OUTLINE_KEYS } from "src/v2/models/checkins";
import { useCurrentUser } from "src/v2/models/profile";

import Cases from "./Cases";

const YourCare = () => {
  useTopBar({ variant: "main", title: "My care" });
  const dispatch = useStoreDispatch();
  const user = useCurrentUser();
  const { activePlanCode } = useMembership();
  const enableWeightLossJournalPaywall = useFeatureFlags("enableWeightLossJournalPaywall");

  const history = useHistory();
  const [selectedCareType, setSelectedCareType] = React.useState<string>("primary");
  const isPrimaryCare = selectedCareType === "primary";
  const { healthActionPlan, isLoading: isLoadingPlan } = useStoreState(
    (state) => state.healthActionPlan,
  );
  const [hasPlan, setHasPlan] = React.useState(false);
  const { orders } = useStoreState((state) => state.orders);

  React.useEffect(() => {
    dispatch.orders.fetchOrders({ user_id: user.id });

    dispatch.checkins.fetchCheckIn({
      userId: user.id,
      checkinKey: CHECKIN_OUTLINE_KEYS.COMPREHENSIVE_HEALTH_CHECK_IN,
    });
    dispatch.healthActionPlan.getLastHealthActionPlan({ userId: user.id });

    Analytics.trackContentImpression({
      name: "MyCarePage",
      piece: "MyCarePage",
      target: "PageLoad",
    });
  }, []);

  React.useEffect(() => {
    if (!isLoadingPlan) {
      setHasPlan((healthActionPlan?.content && healthActionPlan?.content.length > 0) || false);
    }
  }, [isLoadingPlan]);

  const patientTools = [
    {
      icon: faClipboardListCheck,
      onClick: () => history.push("/post-visit-summary"),
      label: "Post-Visit Summaries",
    },
    ...(isPrimaryCare
      ? [
          {
            icon: faPrescriptionBottlePill,
            onClick: () => history.push("/prescriptions"),
            label: "Alpha prescriptions",
          },
        ]
      : []),
    ...(isPrimaryCare
      ? [
          {
            icon: faVials,
            onClick: () => history.push("/your-care/labs"),
            label: "Labs",
          },
        ]
      : []),
    {
      icon: faStethoscope,
      onClick: () => history.push(PDB_ROUTES.MY_CARE_TEAM_PAGE),
      label: "My care team",
    },
  ];

  if (orders && orders.length > 0) {
    patientTools.push({
      icon: faTruckFast,
      onClick: () => history.push("/orders"),
      label: "Orders",
    });
  }

  const getStatusBasedContent = () => {
    return {
      title: "My Health Action Plan",
      description: "Review my Alpha recommendations →",
    };
  };

  // If user has a plan, redirect to the health action plan page.
  const onPlanCardHandler = () => {
    history.push(PDB_ROUTES.HEALTH_ACTION_PLAN);
  };

  return (
    <PageWrapper cxPadding="px-6 py-8">
      <div className="max-w-2xl mx-auto">
        <ProfileSwitcher />
        <div className="mb-6">
          <Typography className="mb-6" variant="h1">
            My Care
          </Typography>
        </div>
        <div className="mb-6">
          <ChoiceChips
            filterOptions={[
              {
                key: "primary",
                label: "Primary care",
              },
              {
                key: "urgent",
                label: "Urgent care",
                bgClassNameSelected: "bg-sunset-80",
                textColorClassNameSelected: "text-forest-120",
              },
            ]}
            selected={selectedCareType}
            onOptionChange={(filterKey) => setSelectedCareType(filterKey)}
            variant="wide"
          />
        </div>
        <Cases careType={selectedCareType} />
        {isPrimaryCare && (
          <div className="border-b border-grey-60 mb-8 pb-8">
            {!isLoadingPlan && hasPlan && (
              <button className="mb-6 w-full text-left" onClick={onPlanCardHandler}>
                <BaseCard
                  bgClassName="bg-sky-20"
                  title={getStatusBasedContent().title}
                  description={getStatusBasedContent().description}
                />
              </button>
            )}
            <Typography className="mb-6" variant="h3">
              Manage my health
            </Typography>
            <div className="flex flex-row gap-4">
              <EngagementFeatureCard
                feature="Weight Loss Journal"
                url="/weight-loss-journal"
                iconSrc="/assets/weight-loss-journal.svg"
                iconAlt="Icon of a person scaling a bar chart, reaching for a flag at the highest bar."
                locked={enableWeightLossJournalPaywall && activePlanCode !== MEMBERSHIP_PLANS.plus}
              />
              <EngagementFeatureCard
                feature={"Health Check-Ins"}
                url={PDB_ROUTES.CHECKINS_LIST_PAGE}
                iconSrc={"/assets/health-checkins-icon.svg"}
                iconAlt={
                  "Icon of a person pointing at a smartphone screen displaying a health chart, with a stethoscope in the background."
                }
              />
              <EngagementFeatureCard
                feature="BMI Calculator"
                url="/bmi-calculator"
                iconSrc="/assets/bmi-calculator.svg"
                iconAlt={
                  "An icon of a colorful calculator with various buttons in shades of pink, yellow, green, and orange. The calculator display shows the symbols +, -, x and =."
                }
              />
            </div>
          </div>
        )}
        <div>
          <Typography className="mb-6" variant="h3">
            How can we help?
          </Typography>
          <IconMenuList tools={patientTools} />
        </div>
      </div>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(YourCare);
