/** @format */

import { exifImageRotate } from "src/utils/image/exif";

import type { ExifImageRotateCallbackType } from "./types";

export const processImage = (
  eventTargetName: string,
  file: any,
  callback: ExifImageRotateCallbackType,
) => {
  const reader = new FileReader();
  const name = eventTargetName;

  const MAX_WIDTH = 1500;
  const MAX_HEIGHT = 1500;

  reader.onloadend = () => {
    const image = new Image();
    const blob = new Blob([file]);
    image.src = window.URL.createObjectURL(blob);

    image.onload = () => {
      const canvas: HTMLCanvasElement = document.createElement("canvas");
      let width = image.width;
      let height = image.height;

      // Change the image width and height if the image is large
      if (width > height) {
        if (width > MAX_WIDTH) {
          height = Math.round((height *= MAX_WIDTH / width));
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width = Math.round((width *= MAX_HEIGHT / height));
          height = MAX_HEIGHT;
        }
      }

      const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
      ctx.save();

      exifImageRotate({
        canvas,
        ctx,
        file,
        image,
        width,
        height,
        callback,
        name,
      });
    };
  };

  reader.readAsDataURL(file);
};
