/** @format */

import React from "react";

import { WelcomePageContainerHealthCheckIn } from "src/components/DynamicForm/Fields/CustomFields/WelcomeFlow";
import { howAlphaWorks } from "src/content/src/v2/components/DynamicForm/Fields/CustomFields/WelcomeFlow/HowAlphaWorks/howAlphaWorks";

interface HowAlphaWorksStepProps {
  title: string;
  key: string;
  icon: string;
  text: string;
}

const HowAlphaWorksStep = ({ title, key, icon, text }: HowAlphaWorksStepProps) => {
  return (
    <div className={"flex mb-10"} key={key}>
      <img src={`/assets/${icon}`} className={"mr-6 self-start"} />
      <div>
        <h2 className={"font-bold text-forest-120 mb-2"}>{title}</h2>
        <p className={"text-forest-100"}>{text}</p>
      </div>
    </div>
  );
};

interface HowAlphaWorksProps {
  questionKey: string;
}

export const HowAlphaWorks = ({ questionKey }: HowAlphaWorksProps) => {
  return (
    <WelcomePageContainerHealthCheckIn questionKey={questionKey} submitButtonTitle={"Next"}>
      <div>
        <h1 style={{ fontWeight: 500 }} className={"text-forest-120 text-xl mb-10"}>
          {howAlphaWorks.title}
        </h1>
        {howAlphaWorks.steps.map((step) => (
          <HowAlphaWorksStep
            title={step.title}
            key={step.title.replace(" ", "")}
            icon={step.icon}
            text={step.text}
          />
        ))}
      </div>
    </WelcomePageContainerHealthCheckIn>
  );
};
