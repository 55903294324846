/** @format */

/** @format */

import type { FormProps } from "react-final-form";

import React from "react";
import { useHistory } from "react-router-dom";
import { useLocalStorage } from "react-use";

import type { PageFormProps } from "src/v2/components/Wizard/pages/types";

import { MembershipOptions } from "src/routes/Membership/MembershipOptions";

type OnSubmitReturn = ReturnType<FormProps["onSubmit"]>;
type OnSubmitParams = Parameters<FormProps["onSubmit"]>;

// Overload the form.onSubmit function type to allow it to be called without a FormAPI object
type MonthlyMembershipPlanProps = PageFormProps & {
  form: FormProps & {
    onSubmit: (values: OnSubmitParams[0]) => OnSubmitReturn;
  };
};

export const MonthlyMembershipPlan = ({ form }: MonthlyMembershipPlanProps) => {
  const queryParams = new URLSearchParams(window.location.search);
  const [page, setPage] = useLocalStorage("monthly-membership-page", 0);
  const history = useHistory();

  const setMembershipType = (type: "alpha" | "plus") => {
    if (queryParams) {
      const plan = queryParams.get("plan");
      const flow = queryParams.get("flow");
      if (type !== plan) {
        history.push(`/membership-signup?plan=${type}&flow=${flow}`);
      }
    }
    form.onSubmit({ "payment-plan": type });
  };

  React.useEffect(() => {
    setPage(0);
    if (queryParams && page === 0) {
      const plan = queryParams.get("plan");
      if (plan && (plan === "alpha" || plan === "plus")) {
        setMembershipType(plan);
      }
    }
    return () => {
      setPage(0);
    };
  }, []);

  return (
    <div className="mt-8">
      <h1 className={"text-lg text-forest-darker font-medium"}>
        Discover healthcare that's in hand and on demand.
      </h1>
      <h2 className={"text-forest-darker mt-4"}>
        Our Alpha plans cover your primary care needs, from an expert in women's health.
      </h2>
      <MembershipOptions flow="registration" />
    </div>
  );
};
