/** @format */

import React from "react";

import type { AccountLinksProps } from "src/routes/YourAccount/types";

import { CARE_TEAM, MEDICAL_RECORDS } from "src/routes";
import AccountListItemPage from "src/routes/YourAccount/AccountListItemPage";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";

const HealthInformation = () => {
  const accountLinks: AccountLinksProps[] = [
    {
      title: "Care team",
      url: CARE_TEAM,
    },
    {
      title: "My Health Records",
      url: MEDICAL_RECORDS,
    },
  ];
  return <AccountListItemPage title="Health Information" accountLinks={accountLinks} />;
};

export default WithTopBarNavigation(HealthInformation);
