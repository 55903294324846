/** @format */

import React from "react";

interface WrapLayoutProps {
  childSize: string;
  cxPadding?: string;
}

export const WrapLayout: React.FC<WrapLayoutProps> = (props) => (
  <div className="flex flex-wrap">
    {React.Children.map(props.children, (child) => {
      return (
        child && (
          <div style={{ flex: `0 1 ${props.childSize}`, flexGrow: 1 }} className={props.cxPadding}>
            {child}
          </div>
        )
      );
    })}
  </div>
);
