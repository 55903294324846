/** @format */
import _ from "lodash";

import type { FormValidator } from "src/components/DynamicForm/Validators/types";

export const isSuicidal: FormValidator = (values) => {
  const suicidalKeys = [
    "intention-of-acting-on-suicide",
    "carry-out-suicidal-plan",
    "enacted-suicidal-plan",
    "enacted-suicidal-plan-in-last-3-months",
  ];
  const suicidal = suicidalKeys.filter((key) => _.get(values, key) === "yes");
  if (suicidal.length === 0) {
    return null;
  }
  return { type: "suicidal" };
};
