/** @format */
import type { EffectCallback } from "react";

import React, { useRef } from "react";
import { generatePath, useHistory } from "react-router-dom";

import type { FormReponsesCollectionSchema, ProfileSchema } from "src/api";

import { useStoreActions, useStoreState } from "src/v2/models";

let unListener: EffectCallback | undefined = undefined;

export const useRedirects = () => {
  const history = useHistory();
  const currentUser = useStoreState((state) => state.profile.profile);
  const userRegistration = useStoreState((actions) => actions.profile.userRegistration);
  const addSnack = useStoreActions((actions) => actions.snacks.addSnack);
  const beforeRegistrationLink = useStoreState((state) => state.profile.beforeRegistrationLink);
  const setBeforeRegistrationLink = useStoreActions(
    (actions) => actions.profile.setBeforeRegistrationLink,
  );

  // We have to create a ref so that the value of userRegistration is accessible in handleUrlChange
  const registrationRef = useRef<FormReponsesCollectionSchema | null>(null);
  const profileRef = useRef<ProfileSchema>();

  const linkPatternToSaveList = ["/visit", "/consultation", "/followup", "/return-consult"];

  registrationRef.current = userRegistration;
  profileRef.current = currentUser;

  React.useEffect(() => {
    // Store original url, so we can direct the patient to that url after account setup
    if (
      !beforeRegistrationLink &&
      linkPatternToSaveList.some((pattern) => history.location.pathname.includes(pattern))
    ) {
      // Only set a before register link once
      setBeforeRegistrationLink(history.location.pathname);
    }
  }, []);

  const handleUrlChange = (location: string, showSnack = false) => {
    // send to account setup flow if reg outline_response_collection is created
    // but not complete

    // We want to skip membership signup flow from this logic.
    if (location.includes("/membership-signup")) return;

    if (profileRef.current && profileRef.current.is_ubacare_user) {
      if (
        registrationRef.current &&
        registrationRef.current.completed &&
        location.includes(`/form/${registrationRef.current?.id}/complete`)
      ) {
        history.push("/consultation/ubacare-program-visit");
        return;
      }
    }

    if (
      registrationRef.current &&
      registrationRef.current?.id &&
      !registrationRef.current?.completed &&
      !location.includes(`/form/${registrationRef.current?.id}`)
    ) {
      // tell the patient they can't leave
      if (showSnack) {
        addSnack({
          type: "brand",
          message: "To continue, please set up your account.",
          id: "registration-redirect", // id so that only one toast will show
          delay: 5,
        });
      }

      const url = generatePath("/form/:id", { id: registrationRef.current.id });

      const searchParams = new URLSearchParams({ form_type: "registration" });
      const queryString = searchParams.toString();

      // force patient back into account setup flow
      history.push({
        pathname: url,
        search: queryString,
      });
    }
  };

  React.useEffect(() => {
    if (userRegistration && userRegistration.id && !userRegistration.completed) {
      // initial redirect into account setup form
      handleUrlChange(history.location.pathname, false);
    } else if (userRegistration?.completed && unListener) {
      // If the patient has completed their account setup.
      // No need to continue checking their location
      unListener();
    }
  }, [userRegistration?.id, userRegistration?.completed]);

  React.useEffect(() => {
    const unlistener = history.listen((location) => {
      handleUrlChange(location.pathname, true);
    });
    if (!unListener) {
      unListener = unlistener;
      // setting global listener so we can call it when account setup is complete
    }
    return unlistener; // returning unlistener here in case component unmounts
  }, []);
};
