/** @format */

import React from "react";

import type { MEMBERSHIP_PLAN_NAMES } from "src/v2/constants";

import { serializeDependency } from "src/utils";
import {
  MEMBERSHIP_PLANS,
  MONTHLY_MEMBERSHIP_FEE_IN_CENTS,
  MONTHLY_MEMBERSHIP_PLUS_FEE_IN_CENTS,
} from "src/v2/constants";
import { useCurrentUser } from "src/v2/models/profile";

const defaultAlphaMembership = {
  name: MEMBERSHIP_PLANS.alpha,
  originalPriceString: `$${MONTHLY_MEMBERSHIP_FEE_IN_CENTS / 100}`,
  originalPriceInCents: MONTHLY_MEMBERSHIP_FEE_IN_CENTS,
  hasBenefit: false,
  discountAmountInCents: 0,
  discountAmountString: "",
};

const defaultPlusMembership = {
  name: MEMBERSHIP_PLANS.plus,
  originalPriceString: `$${MONTHLY_MEMBERSHIP_PLUS_FEE_IN_CENTS / 100}`,
  originalPriceInCents: MONTHLY_MEMBERSHIP_PLUS_FEE_IN_CENTS,
  hasBenefit: false,
  discountAmountInCents: 0,
  discountAmountString: "",
};

/**
 * Used to get the original and discounted price of membership
 */
export const useMembershipPrice = () => {
  const user = useCurrentUser();
  const [alphaMembership, setAlphaMembership] =
    React.useState<MembershipPlanPriceInfo>(defaultAlphaMembership);
  const [plusMembership, setPlusMembership] =
    React.useState<MembershipPlanPriceInfo>(defaultPlusMembership);

  React.useEffect(() => {
    if (user.employer_benefits) {
      // find plan that has a discount
      const possibleBenefit = user.employer_benefits.find(
        (plan) => plan.active && plan.plan?.membership_discount_in_cents,
      );
      if (possibleBenefit && possibleBenefit.plan?.membership_discount_in_cents) {
        // set discount amount and calculate new membership cost
        const discountAmountInCents = possibleBenefit.plan.membership_discount_in_cents;

        setPlusMembership({
          ...plusMembership,
          hasBenefit: true,
          discountAmountInCents,
          discountAmountString: `$${(MONTHLY_MEMBERSHIP_PLUS_FEE_IN_CENTS - discountAmountInCents) / 100}`,
          percentDiscount: discountAmountInCents / MONTHLY_MEMBERSHIP_PLUS_FEE_IN_CENTS,
          percentDiscountString: `${Math.floor((discountAmountInCents / MONTHLY_MEMBERSHIP_PLUS_FEE_IN_CENTS) * 100)}%`,
        });

        setAlphaMembership({
          ...alphaMembership,
          hasBenefit: true,
          discountAmountInCents,
          discountAmountString: `$${(MONTHLY_MEMBERSHIP_FEE_IN_CENTS - discountAmountInCents) / 100}`,
          percentDiscount: discountAmountInCents / MONTHLY_MEMBERSHIP_FEE_IN_CENTS,
          percentDiscountString: `${Math.floor((discountAmountInCents / MONTHLY_MEMBERSHIP_FEE_IN_CENTS) * 100)}%`,
        });
      }
    }
  }, [serializeDependency(user.employer_benefits)]);

  return { alpha: alphaMembership, plus: plusMembership };
};

export interface MembershipPlanPriceInfo {
  name: MEMBERSHIP_PLAN_NAMES;
  originalPriceString: string;
  originalPriceInCents: number;
  hasBenefit: boolean;
  discountAmountInCents: number;
  discountAmountString?: string;
  percentDiscount?: number;
  percentDiscountString?: string;
}
