/** @format */

import { faCircleCheck, faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import { Typography } from "@alphamedical/components";
import { BadgePdb } from "src/v2/designSystem/BadgePdb/BadgePdb";
import { ButtonPdb } from "src/v2/designSystem/ButtonPdb/ButtonPdb";

import type { planSlug } from "src/routes/Membership/MembershipOptions";

import type { Plan, PlanSection, VariantProps } from "./PlanComparisonPage";

interface PlanComparisonChartProps {
  content: PlanSection;
  onSubmit: (plan: planSlug) => void;
  plansOffered?: planSlug[];
  variantProps: VariantProps;
}

interface PlanDescriptionProps {
  content: Plan;
  onSubmit: (plan: planSlug) => void;
  styles: any;
}

export const PlanComparisonChart = ({
  content,
  onSubmit,
  plansOffered,
  variantProps,
}: PlanComparisonChartProps) => {
  const filteredPlansContent = plansOffered
    ? content.plans.filter((planContent) => plansOffered?.includes(planContent.id as planSlug))
    : content.plans;

  const styles = variantProps.styles;

  return (
    <div className={`sm:max-w-2xl mx-auto w-full px-6 pb-10 ${styles.cardsBackground}`}>
      {variantProps.showAlphaLogo && (
        <div className="pt-6">
          <img src="/assets/alpha-logo.svg" alt="Alpha Logo" className="w-24 object-top mx-auto" />
        </div>
      )}
      {variantProps.showBenefitHeader && (
        <Typography className="text-bold" variant="h4">
          Your sponsored benefit
        </Typography>
      )}
      {content.title && (
        <div className="py-6">
          <Typography align={styles.titleAlignment as "left" | "center"} variant="h1">
            {content.title.map((part, index) => (
              <span
                key={index}
                className={classNames({
                  "text-information": index === 1,
                  "text-forest-darker": index !== 1,
                })}
              >
                {part}
              </span>
            ))}
          </Typography>
        </div>
      )}
      <div className="flex justify-center gap-1">
        {filteredPlansContent.map((plan) => {
          return (
            <PlanDescription key={plan.id} content={plan} onSubmit={onSubmit} styles={styles} />
          );
        })}
      </div>
      {content.benefitsDisclaimer && (
        <div className="text-grey-160 text-sm mt-4">{content.benefitsDisclaimer}</div>
      )}
      {variantProps.buttonsIfMembershipIncluded}
    </div>
  );
};

export const PlanDescription = ({ content, onSubmit, styles }: PlanDescriptionProps) => {
  const cardStyles = styles[content.id];
  return (
    <div
      key={content.id}
      className={classNames(
        "flex flex-col flex-1 pt-6 pb-4 px-3 rounded-lg relative bg-white",
        cardStyles.border,
      )}
    >
      <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
        <BadgePdb label={content.badge} variant="informative" addClasses="px-3" />
      </div>
      <Typography variant="h2">{content.title}</Typography>
      {content.pricePerDay && (
        <p className="text-forest-darker">
          {content.pricePerDay?.map((price, index) => {
            return (
              <span
                key={index}
                className={classNames({
                  "text-xs font-normal": index === 1,
                  "text-2xl font-medium": index !== 1,
                })}
              >
                {price}
              </span>
            );
          })}
        </p>
      )}
      {content.pricePerMonth && (
        <p className="text-grey-120 text-xs font-normal">{content.pricePerMonth}</p>
      )}
      {content.priceStrikeThrough && (
        <p className="text-grey-160 text-lg font-medium line-through">
          {content.priceStrikeThrough}
        </p>
      )}
      {content.pricePerMonthB2B && (
        <p className="text-forest-120 text-3xl font-medium">{content.pricePerMonthB2B}</p>
      )}
      <div className="border border-grey-40 my-4"></div>
      <div className="flex flex-col gap-3 flex-grow w-full">
        {content.features.map((feature) => {
          return (
            <p
              key={feature.text}
              className={classNames(
                "flex flex-row items-stretch items gap-2 w-full",
                cardStyles.feature,
              )}
            >
              <FontAwesomeIcon
                icon={feature.icon === "fa-circle-xmark" ? faCircleXmark : faCircleCheck}
                size="lg"
                className={classNames(feature.icon === "fa-circle-xmark" && "text-grey-100")}
                style={{ marginTop: "2px", marginLeft: "2px" }}
              ></FontAwesomeIcon>
              <span className="flex text-sm font-medium flex-grow">{feature.text}</span>
            </p>
          );
        })}
      </div>
      {content.cta && (
        <ButtonPdb
          variant={cardStyles.button}
          className="w-full mt-4"
          onClick={(e) => {
            e.preventDefault();
            onSubmit(content.id as planSlug);
          }}
        >
          {content.cta}
        </ButtonPdb>
      )}
    </div>
  );
};
