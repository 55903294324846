/** @format */

import React from "react";
import { Link } from "react-router-dom";

interface NavLinkProps {
  href: string;
  anchorText: string;
  underline?: boolean;
  className?: string;
  tabIndex?: number;
}

const NavLink = ({ href, anchorText, underline, className, tabIndex }: NavLinkProps) => {
  const internal = /^\/(?!\/)/.test(href);

  return internal ? (
    <Link
      tabIndex={tabIndex}
      style={{ textDecoration: underline ? "underline" : "no-underline" }}
      className={className}
      to={href}
    >
      {anchorText}
    </Link>
  ) : (
    <a
      style={{ textDecoration: underline ? "underline" : "no-underline" }}
      className={className}
      href={href}
      target="_blank"
      rel="noreferrer noopener"
      aria-describedby="new-window-2"
      tabIndex={tabIndex}
    >
      {anchorText}
    </a>
  );
};

export default NavLink;
