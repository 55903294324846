/** @format */

import type { Action, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";

import { UsersService } from "src/api";

import type { CreateModel } from "./_create";

export interface Channel {
  id: number;
  name: string;
  public_name?: string;
  type: string;
  channel_type?: "SUPPORT" | "SERVICE" | "NOTIFICATION" | "LEGACY" | "HEALTH_CHECKIN";
  locked?: boolean;
  locked_reason?: string;
  users: any[];
  most_recent_message?: {
    id: number;
    created_at: string;
  };
  service?: {
    id: number;
    type: string;
    condition?: {
      id: number;
      key: string;
      name: string;
      refillable: boolean;
    };
    days_to_lock?: number;
  };
}

export interface ChannelsModel {
  channels?: Channel[];

  setChannels: Action<ChannelsModel, Channel[]>;

  fetchChannels: Thunk<ChannelsModel>;

  requestChannelAccess: Thunk<ChannelsModel, string>;
}

export const channelStore: ChannelsModel = {
  setChannels: action((state, channels) => {
    state.channels = [...channels];
  }),

  fetchChannels: thunk(async (actions, _, { getStoreState }) => {
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;
    if (!currentProfileId) {
      return;
    }

    return UsersService.getChannels({ userId: currentProfileId }).then((response) => {
      actions.setChannels(response.channels);
    });
  }),

  requestChannelAccess: thunk(async (actions, channelId) =>
    UsersService.grantChannelAccess({ channelId }),
  ),
};
