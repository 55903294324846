/** @format */

import React from "react";

const HealthActionPlanUnreadIndicator = () => {
  return (
    <div className="ml-2 w-2 h-2">
      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
        <circle cx="4" cy="4" r="4" fill="#1879CE" />
      </svg>
    </div>
  );
};

export default HealthActionPlanUnreadIndicator;
