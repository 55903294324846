/** @format */

import type { ReactElement } from "react";

import React from "react";
import { useField } from "react-final-form";

import { useMultiPageForm, useWindowSize } from "@alphamedical/components";

import SkipQuestion from "src/v2/components/SkipQuestion/SkipQuestion";
import { useStoreState } from "src/v2/models";

interface WelcomePageContainerHealthCheckInProps {
  questionKey: string;
  children: ReactElement | Array<ReactElement>;
  backgroundColorClass?: string;
  submitButtonTitle: string;
}

export const WelcomePageContainerHealthCheckIn = ({
  questionKey,
  children,
  submitButtonTitle,
  backgroundColorClass,
}: WelcomePageContainerHealthCheckInProps) => {
  const [, height] = useWindowSize();
  const { nextPage } = useMultiPageForm();
  const profile = useStoreState((state) => state.profile.profile);
  const field = useField(questionKey);

  React.useEffect(() => {
    if (questionKey !== "preferred_firstname") {
      field.input.onChange("true");
    }
  }, []);

  return (
    <div
      style={{ height: `${height - 180}px` }}
      className={`flex flex-col justify-between ${backgroundColorClass}`}
    >
      {children}
      <button type={"submit"} className={`btn btn-primary w-full mb-5`}>
        {submitButtonTitle}
      </button>
      {!profile?.is_new_patient && (
        <SkipQuestion
          goToPage={nextPage}
          nextClick={nextPage}
          skip={{
            nextPageKey: "eligibility",
            showPopup: false,
          }}
        />
      )}
    </div>
  );
};
