/** @format */

import React from "react";

import type { MembershipPlanPriceInfo } from "src/utils/hooks/useMembershipPrice";

import { formatText } from "src/content/contentUtils";
import {
  MEMBERSHIP_MODAL_TEXT,
  MEMBERSHIP_PLUS_MODAL_TEXT,
} from "src/content/src/v2/components/MonthlyMembership/membershipModalText";
import { useMembershipPrice } from "src/utils/hooks/useMembershipPrice";
import { FullPageModal } from "src/v2/components";
import { Button } from "src/v2/components/Button";
import { MarkdownText } from "src/v2/components/MarkdownText";

import "./membershipModal.scss";

interface MembershipModalProps {
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
}

interface BaseModalProps extends MembershipModalProps {
  content: { [key: string]: string }[];
  extraContent?: string;
}

const cancellationPolicyLink = "https://www.helloalpha.com/terms";

const BaseModal: React.FC<BaseModalProps> = ({
  modalOpen,
  setModalOpen,
  content,
  extraContent,
}) => {
  const membershipPriceInfo = useMembershipPrice();

  return (
    <FullPageModal isOpen={modalOpen} setIsOpen={setModalOpen} title={"About Alpha Plans"}>
      <div
        id="modal-content"
        className={"p-6 flex flex-col mb-10 no-scrollbar overflow-y-scroll"}
        style={{ height: "100%" }}
      >
        {content.map((membership) => {
          const { discountAmountString, hasBenefit, originalPriceString }: MembershipPlanPriceInfo =
            membershipPriceInfo[membership.membershipType];
          const price = hasBenefit ? discountAmountString : originalPriceString;
          return (
            <React.Fragment key={membership.title}>
              <h1 className={"text-forest-darker font-medium text-lg mt-8"}>{membership.title}</h1>
              <div className={"mt-10"}>
                <MarkdownText
                  linkStyle={{ color: "#6271C2" }}
                  className={"text-forest-darker"}
                  text={formatText(membership.description, { price: price as string })}
                />
              </div>
            </React.Fragment>
          );
        })}
        {extraContent && <p className="text-forest-darker mt-6">{extraContent}</p>}
        <div className={"mt-10 mb-16"}>
          {/*todo: replace x and add link to cancellation policy*/}
          <p className={"text-forest-darker text-sm mt-2"}>
            *You can request to cancel your plan at any time. See our{" "}
            <a
              className={"underline cursor-pointer text-cornflower-100"}
              href={cancellationPolicyLink}
              rel={"noreferrer"}
              target={"_blank"}
            >
              cancellation policy
            </a>{" "}
            for details
          </p>
          <div className={"mt-10"}></div>
          <Button onClick={() => setModalOpen(false)}>Close</Button>
        </div>
      </div>
    </FullPageModal>
  );
};

export const MembershipModal = (props: MembershipModalProps) => {
  return (
    <BaseModal
      {...props}
      content={MEMBERSHIP_MODAL_TEXT.content}
      extraContent={MEMBERSHIP_MODAL_TEXT.extraContent}
    />
  );
};

export const MembershipPlusModal = (props: MembershipModalProps) => {
  return (
    <BaseModal
      {...props}
      content={MEMBERSHIP_PLUS_MODAL_TEXT.content}
      extraContent={MEMBERSHIP_PLUS_MODAL_TEXT.extraContent}
    />
  );
};
