/** @format */

import React from "react";
import { Form } from "react-final-form";
import { Link } from "react-router-dom";

import type { ValidationErrors } from "final-form";

import { TextFieldNew, Typography } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import type { ForgotPasswordValidation } from "src/v2/routes/ForgotPassword/index";

import config from "src/config";

interface ForgotPasswordFormProps {
  onSubmit: (values: ForgotPasswordValidation) => void;
  successfulSubmit: boolean;
  formError: string;
  validate: (values: any) => ValidationErrors;
}

export const ForgotPasswordForm = ({
  onSubmit,
  successfulSubmit,
  formError,
  validate,
}: ForgotPasswordFormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="max-w-lg flex flex-col items-center">
          <a className="mb-8" href={config.wwwUrl}>
            <img
              src="/assets/alpha-logo-forest.svg"
              alt="Go to Alpha Medical informational website"
              className="w-20"
            />
          </a>
          <div id="login-text" className="mb-5">
            <Typography className={"text-primary"} variant={"h2"}>
              {successfulSubmit ? "Thank you" : "Reset Your Password"}
            </Typography>
          </div>
          <Typography className={"mb-6 text-primary"} variant={"body1"}>
            {successfulSubmit
              ? "If there’s an Alpha account that matches this email, we’ll send a link to reset the password."
              : "Please enter your email address and we will send you a link to reset your password"}
          </Typography>
          {!successfulSubmit && (
            <>
              <div className="w-full mb-8">
                <TextFieldNew label={"Email address"} name="email" required={true} />
                {formError && (
                  <Typography variant={"body1"} className={"text-error mt-2"}>
                    {formError}
                  </Typography>
                )}
              </div>
              <ButtonPdb role={"submit"} className={"w-full"}>
                Send
              </ButtonPdb>
              <Link to="/" className={`text-cornflower-100 no-underline mt-8`}>
                Go back to log in
              </Link>
            </>
          )}
        </form>
      )}
    />
  );
};
