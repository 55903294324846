/** @format */

import type { match } from "react-router-dom";

import React from "react";
import { useHistory } from "react-router-dom";

import { isEmpty } from "lodash";
import moment from "moment";

import type { TreatmentPlanInfoSchema } from "src/api";

import { Loader } from "src/components/Loader";
import { PageWrapper } from "src/components/PageWrapper";
import SettingsLink from "src/components/Settings/SettingsLink";
import { PostVisitSummaryDetails } from "src/routes/PostVisitSummary/PostVisitSummaryDetails";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useStoreDispatch, useStoreState } from "src/v2/models";
import { makePvsSelector } from "src/v2/models/post_visit_summaries";

interface MatchParams {
  pvs_id?: string;
}

interface PostVisitSummaryParams {
  match: match<MatchParams>;
}

const getPvsTitle = (pvs: TreatmentPlanInfoSchema) =>
  `${moment(pvs.submitted_date).format("MMM DD, YYYY")} | ${pvs.condition} visit`;

const PostVisitSummary = ({ match: { params } }: PostVisitSummaryParams) => {
  const pvsId = params.pvs_id;
  const history = useHistory();
  const dispatch = useStoreDispatch();

  const { postVisitSummaries, fetchingPostVisitSummaries } = useStoreState(
    (state) => state.postVisitSummaries,
  );
  const pvs = useStoreState(makePvsSelector(pvsId));

  const getTitle = () => (pvsId && pvs ? getPvsTitle(pvs) : "Your Post-Visit Summaries");

  useTopBar({ title: getTitle(), showBackButton: true, variant: "nested" });

  const displayPvs = (id: number) => {
    history.push(`/post-visit-summary/${id}`);
  };

  React.useEffect(() => {
    dispatch.postVisitSummaries.fetchPostVisitSummaries();
  }, []);

  return (
    <PageWrapper cxPadding={"px-6"}>
      <Loader show={fetchingPostVisitSummaries}>
        {!pvsId ? (
          <div className="pt-10">
            {isEmpty(postVisitSummaries) && (
              <div>
                <div>No Post-Visit Summaries to show</div>
                <div className="mt-4 text-neutral">
                  Your provider will submit a Post-Visit Summary for each Alpha visit. A Post-Visit
                  Summary includes your treatment plan, a summary of your health, and follow-up
                  instructions to continue your care.
                </div>
              </div>
            )}
            {postVisitSummaries.map((_pvs) => (
              <SettingsLink
                callback={() => displayPvs(_pvs.id)}
                arrowPrimary={true}
                key={_pvs.id}
                title={moment(_pvs.submitted_date).format("MMM DD, YYYY")}
                text={_pvs.condition}
                url=""
              />
            ))}
          </div>
        ) : (
          pvsId && pvs?.patient_instructions && <PostVisitSummaryDetails pvs={pvs} />
        )}
      </Loader>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(PostVisitSummary);
