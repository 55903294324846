/** @format */
import React from "react";

import _, { filter, map, uniqBy } from "lodash";
import type { FetchMessagesResponse } from "pubnub";
import type PubNub from "pubnub";
import { usePubNub } from "pubnub-react";

import type { MessageContentSchema } from "src/api";
import type { PubNubModel } from "src/v2/models/pubnub";

import { ChatService } from "src/api";
import { useStoreDispatch, useStoreState } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";

export interface MessageAction {
  key: string;
  text: string;
  completionCallback?: string;
  url?: string;
  secondary?: boolean;
  completionText: string;
  component?: string;
  paragraphText?: string;
  paragraphHeading?: string;
}

export interface MessageActionData {
  title: string;
  subtitle: string;
  expirationText?: string;
  expiration: Date;
  actions: MessageAction[];
  completionKey?: string;
  consult_id?: number;
  action_item_id?: number;
}
export interface PubNubMessage {
  timetoken: string | number;
  channel: string;
  actions?: {
    [type: string]: {
      [value: string]: Array<{
        uuid: string;
        actionTimetoken: string | number; // timetoken
      }>;
    };
  };
  message: {
    id: string;
    authorId: number | undefined;
    text: string;
    channel: string;
    customAttributes?: {
      messageActions?: MessageActionData;
      customIdentifier?: string;
    };
  };
  data?: {
    [key: string]: unknown;
  };
}

interface UnreadMessageCounts {
  [channelId: string]: number;
}

export const useUnreadMessages = (): UnreadMessageCounts => {
  const dispatch = useStoreDispatch();
  const profile = useCurrentUser();
  const pubnub = usePubNub();
  const { unreadMessages } = useStoreState((state) => state.pubnub) as PubNubModel;

  React.useEffect(() => {
    pubnub.channelGroups.listChannels({ channelGroup: `user-${profile.uuid}` }).then((res) => {
      const channels = res.channels.filter((channel) => !channel.endsWith("receipts"));
      channels.forEach((channel) => {
        pubnub
          .fetchMessages({
            channels: [channel],
            count: 10,
            includeMessageActions: true,
          })
          .then((response) => {
            const currentUnreadMessages = _.mapValues(response.channels, (channelData) => {
              const readMessageIndex = channelData.reverse().findIndex((messageData) => {
                return (
                  messageData.actions &&
                  messageData.actions.receipt &&
                  !!messageData.actions.receipt.message_read
                );
              });
              return readMessageIndex > -1 ? readMessageIndex : channelData.length;
            });
            dispatch.pubnub.setUnreadMessages(currentUnreadMessages);
          });
      });
    });
  }, []);

  return unreadMessages;
};

export const useTotalUnreadMessages = () => {
  const unreadMessages = useUnreadMessages();
  return Object.values(unreadMessages).reduce((total, count) => total + count, 0);
};

const MESSAGE_LOAD_COUNT = 10;

export const useChannelMessages = (
  channelId: string,
  loadCount?: number,
): [boolean, PubNubMessage[], boolean, () => void] => {
  const pubnub = usePubNub();
  const dispatch = useStoreDispatch();
  const user = useCurrentUser();
  const [messages, setMessages] = React.useState<PubNubMessage[]>([]);
  const [newMessage, setNewMessage] = React.useState<PubNubMessage>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [hasUnloadedMessages, setHasUnloadedMessages] = React.useState<boolean>(true);
  const count = loadCount || MESSAGE_LOAD_COUNT;

  const mergeMessages = (
    backendMessage: MessageContentSchema,
    pubnubMessage: PubNubMessage | undefined,
  ) => {
    return {
      channel: backendMessage.channel,
      timetoken: backendMessage.timetoken,
      actions: pubnubMessage?.actions,
      message: {
        id: backendMessage.id,
        authorId: backendMessage.author_id,
        text: backendMessage.text,
        channel: backendMessage.channel,
        customAttributes: backendMessage.custom_attributes,
        hasAttachments: backendMessage.has_attachments,
      },
    };
  };

  const receiveNewMessage = (e: PubNub.MessageEvent) => {
    if (e.message.channel !== channelId) {
      return;
    }
    ChatService.getMessageById({
      userId: user.id,
      channel: channelId,
      messageId: e.message.id,
    }).then((backendMessage) => {
      if (backendMessage) {
        const mergedMessage = mergeMessages(backendMessage, e);
        setNewMessage(mergedMessage);
      }
    });
  };

  const fetchMessages = () => {
    const start = messages[0] && messages[0].timetoken;
    const pubnubPromise = new Promise<FetchMessagesResponse>((resolve, _reject) => {
      pubnub.fetchMessages(
        { channels: [channelId], count, start, includeMessageActions: true },
        (_status, res: FetchMessagesResponse) => {
          resolve(res);
        },
      );
    });
    const backendPromise = ChatService.getUserChannelMessages({
      userId: user.id,
      channel: channelId,
      count,
      start: start?.toString(),
    });
    Promise.all([pubnubPromise, backendPromise]).then(([pubnubRes, backendRes]) => {
      const newPubnubMessages = pubnubRes?.channels[channelId] || [];
      const newMessages: PubNubMessage[] = backendRes.map((backendMessage) => {
        const pubnubMessage = newPubnubMessages.find(
          (mes: PubNubMessage) => mes.timetoken == backendMessage.timetoken,
        );
        return mergeMessages(backendMessage, pubnubMessage);
      });
      setMessages((prevMessages) => {
        return [...newMessages, ...prevMessages];
      });
      setHasUnloadedMessages(newMessages.length === count);
      setLoading(false);
    });
  };

  React.useEffect(() => {
    if (newMessage) {
      // a new message may belong to a different channel - see message redirect in ChatChannel
      const newMessages = [...messages, newMessage];
      const processedMessage = uniqBy(
        filter(newMessages, (message) => message.channel === newMessage.channel),
        (message) => message.message.id,
      );
      setMessages(processedMessage);
    }
  }, [newMessage?.timetoken, newMessage?.channel, newMessage?.message?.id]);

  React.useEffect(() => {
    fetchMessages();

    pubnub.addListener({
      message: receiveNewMessage,
    });

    return () => {
      pubnub.removeListener({
        message: receiveNewMessage,
      });
    };
  }, [channelId]);

  return [loading, messages, hasUnloadedMessages, fetchMessages];
};

export const createMessagesDependency = (messages?: PubNubMessage[]) =>
  JSON.stringify(map(messages, (message) => `${message?.timetoken}${message?.message?.id}`));
