/** @format */

import React from "react";

import type { Consultation } from "src/v2/types/consultation";

import { PaymentTreatmentCard } from "src/components/TreatmentCard/PaymentTreatmentCard/PaymentTreatmentCard";
import { Button } from "src/v2/components/Button";
import { getBannerImageUrl } from "src/v2/components/Payment/PaymentBanner";
import { providedTreatmentsByConditionAndMedication } from "src/v2/constants";

interface ProvidedTreatmentsPopupProps {
  onRequestClose: (boolean: boolean) => void;
  condition: string;
  consult: Consultation;
  paymentPreference?: string;
}

export const ProvidedTreatmentsPopup = ({
  onRequestClose,
  condition,
  consult,
  paymentPreference,
}: ProvidedTreatmentsPopupProps) => {
  const medicationKeys = Object.keys(providedTreatmentsByConditionAndMedication[condition]);
  const [showNumber, setShowNumber] = React.useState<number>(5);
  return (
    <div>
      <div style={{ backgroundColor: "#F7F7F7" }} className="p-5 mb-3">
        <div className="flex flex-col justify-center items-center">
          <img className="mt-4 h-20" src={getBannerImageUrl(consult)} alt="" role="presentation" />
          <h3 className="text-xl text-primary my-4">
            {consult.subscription.condition.name} Treatments
          </h3>
        </div>
        {medicationKeys.map((medKey, idx) =>
          idx <= showNumber ? (
            <PaymentTreatmentCard
              medKey={medKey}
              doses={providedTreatmentsByConditionAndMedication[condition][medKey]}
              key={medKey}
              paymentPreference={paymentPreference}
            />
          ) : null,
        )}
      </div>
      {showNumber < medicationKeys.length && (
        <div
          style={{ color: "#6BA5A5" }}
          className="text-lg font-bold underline text-center mt-3 mb-3 cursor-pointer"
          onClick={() => setShowNumber(showNumber + 5)}
        >
          View more
        </div>
      )}
      <p className="text-center mt-2 mb-4">
        During your consultation, your Alpha Provider will help you choose a treatment option that
        works best for you.
      </p>
      <Button
        onClick={() => {
          onRequestClose(false);
          setShowNumber(5);
        }}
      >
        Back to Payment
      </Button>
    </div>
  );
};
