/** @format */

import type React from "react";

import cn from "classnames";
import { dropRight } from "lodash";

import { RadioButtonField } from "@alphamedical/components";

import { fields } from "src/routes/MyProfile/fields";
import Pii from "src/v2/components/Pii";

import type { MedicalRecordsRequestFormPageProps } from "./MedicalRecordsRequestForm";

/** @format */

const myselfDetails = [
  {
    key: "first_name",
    label: "Legal first name",
    required: true,
    type: "text",
    category: "pii",
  },
  {
    key: "last_name",
    label: "Legal last name",
    required: true,
    type: "text",
    category: "pii",
  },
  {
    key: "dob",
    label: "Date of birth",
    required: true,
    type: "text",
    category: "pii",
  },
  ...dropRight(fields), // remove irrelevant questions
  {
    key: "email",
    label: "Email",
    required: true,
    type: "text",
    category: "pii",
  },
];

const anotherPersonDetails = [
  {
    key: "first_name_or_organization",
    label: "Legal first name or organization name",
    required: true,
    type: "text",
  },
  {
    key: "last_name",
    label: "Legal last name",
    type: "text",
  },
  ...dropRight(fields), // removes an irrelevant question
  {
    key: "email",
    label: "Email",
    type: "text",
    category: "pii",
  },
  {
    key: "fax",
    label: "Fax",
    type: "text",
    category: "pii",
  },
];

const Details: React.FC<MedicalRecordsRequestFormPageProps> = ({ show, formProps }) => {
  const authorizedPerson = formProps?.values?.authorized_person;

  return (
    <div className={cn({ block: show, hidden: !show })}>
      <div className="mt-6 text-xl font-medium">
        Who do you want to authorize to receive and use your medical information?
      </div>
      <div className="mt-4">
        <RadioButtonField
          name="authorized_person"
          className="flex flex-col"
          options={[
            {
              value: "myself",
              label: <div className="mb-3">Myself</div>,
            },
            {
              value: "other",
              label: "Another person or organization",
            },
          ]}
        />
      </div>
      {authorizedPerson && (
        <Pii
          formProps={formProps as any}
          fields={authorizedPerson === "myself" ? myselfDetails : anotherPersonDetails}
          showNameFields={authorizedPerson === "myself"}
          hideNameWarning={true}
        />
      )}
    </div>
  );
};
export default Details;
