/** @format */

import type { EmergencyContact, PiiSchema, Profile, ProviderReferenceWithPii } from "src/api";
import type { AccordionProps } from "src/components/DynamicForm/Fields/CustomFields/Accordion";
import type { Condition } from "src/v2/types/condition";
import type { ZoomRegistrantSchema, ZoomSessionSchema } from "src/v2/types/zoom";

export interface ErrorResponse<T = any> {
  description: string;
  type: string;
  errors: T;
}

export interface FormPopupOption {
  /** The text to display in the CTA button */
  cta: string;
  /** If set, this option will navigate the user to the given page, leaving the form entirely. */
  link?: string;
  /** If set, this option will navigate the user to the external page, leaving the form entirely. */
  external_link?: string;
  /** If set, this option will just close the modal withouth re-submitting the form, allowing the user to edit their
   * field values.
   */
  close_modal?: boolean;
  /** If true, this option will re-submit the form and skip this validator without any changes to the form
   * values. Ignored if close_modal or link is set. */
  allow_continue?: boolean;
  /** If set, then clicking this option should change the given form values to what is in this object. The keys of this
   * object represent the keys of the field to change, and the value is the new value. This should be done before
   * resubmitting the form.
   */
  change_values?: Record<string, string>;

  /** If set, then post this users email to the leads table for future remarketing */
  lead?: string;

  /** If set, then this page in the intake form should be navigated to after choosing this option */
  next_page?: string;
}

/**The response that is returned by the API when a backend validation requirement failed and a popup needs to be shown
 * to the user with there options on what to do next.
 */
export interface FormPopupError extends ErrorResponse {
  type: "FormPopupError";
  /**The text that should be displayed in the header of the popup */
  header: string;
  /**A string value to identify which validation requirement was failed */
  validator_code: string;
  /**A list of one or more options that should be given to the user display  */
  options: FormPopupOption[];

  /** Shows an accordion in the form popup **/
  accordion?: AccordionProps;

  hard_stop: boolean;
  prevent_close?: boolean;
}

export const isConsultResponsesValidationError = (err: any): err is FormPopupError =>
  !!err && err.type === "FormPopupError";

export interface FetchProfileResponse {
  id: number;
  uuid: string;
  email: string;
  chat_auth_key?: string;
  roles?: string[];
  pii: PiiSchema;
  insurance?: FetchInsuranceResponse;
  followup_items?: FetchFollowUpItemsResponse[];
  consults?: FetchConsultsResponse[];
  clinician_fields?: {
    signature?: string;
    qualifications?: string;
  };
  licenses?: { state: string; number?: string }[];
  winbacks?: { active?: boolean; type?: string }[];
  children?: Profile[];
  parents?: Profile[];
  emergency_contact?: EmergencyContact;
  primary_provider?: ProviderReferenceWithPii;
  is_new_patient?: boolean;
}

export interface FetchInsuranceResponse {
  cardholder_id?: string;
  rx_bin?: string;
  rx_pcn?: string;
  group_number?: string;
  front_photo?: string;
  back_photo?: string;
  carrier?: string;
  carrier_other?: string;
  is_federally_funded_plan?: boolean;
}

export interface FetchFollowUpItemsResponse {
  // Could be replaced with a union type
  followup_type: any;
  order_id?: number;
  copay_amount?: number;
  medication_name?: string;
}

export interface FetchConsultsResponse {
  id: number;
  consult_type: string;
  completed_at?: string;
  status: string;
}

export interface LegacyResponse<T> {
  meta: {
    code: number;
  };
  response: T;
}

export interface FormOutline {
  condition?: Condition;
  key: string;
}

export interface FormReponsesCollectionSchema {
  id: number;
  completed?: boolean;
  created_at: string;
  outline?: FormOutline;
  values?: { [fieldKey: string]: string | string[] };
}

export interface GroupSessionSignupSchema {
  completed: boolean;
  created_at: string;
  id: number;
  responses: FormReponsesCollectionSchema;
  post_assessment_responses?: FormReponsesCollectionSchema;
  zoom_session?: ZoomSessionSchema;
  zoom_registrant?: ZoomRegistrantSchema;
}

export interface RecurlyAdjustmentSchema {
  accounting_code?: string;
  description?: string;
  product_code: string;
  quantity: number;
  unit_amount_in_cents: number;
  display_label?: string;
}

export interface GroupSessionCostSchema {
  due_now: {
    adjustments: RecurlyAdjustmentSchema[];
  };
}

export interface StateSchema {
  age_of_majority?: number;
  code: string;
  id: number;
  name: string;
  supported: boolean;
  timezone?: string;
}
