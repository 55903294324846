/** @format **/
import type React from "react";

interface SandBulletProps {
  point: string | React.ReactNode;
  classes?: string;
  style?: React.CSSProperties;
}

const SandBullet = ({ point, classes = "text-base", style }: SandBulletProps) => {
  return (
    <div className={`align-middle mb-2 flex ${classes}`} style={style}>
      <img className="mr-3 self-start mt-2" src="/assets/sand-bullet.svg" alt="bullet point" />
      {point}
    </div>
  );
};

export default SandBullet;
