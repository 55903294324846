/** @format */

import { DimensionName, useAnalyticsDimension } from "src/analytics/hooks";
import { VARIANT_OVERRIDE_PREFIX } from "src/components/ABTesting/VariantSettings";
import config from "src/config";

export type testName = "weight-loss-funnel";
export type variant = "A" | "B";

const testNameToDimensionMapping = {
  "weight-loss-funnel": "WeightLossFunnelVariant",
};

export const useABTest: (testName: testName, userId?: number) => variant = (
  testName: testName,
  userId?: number,
): variant => {
  const analyticsDimension = testNameToDimensionMapping[testName] as DimensionName;
  // Check for testing overrides in staging + development only
  let chosenVariant;
  if (!config.isProd) {
    const overrideKey = VARIANT_OVERRIDE_PREFIX + testName;
    if (localStorage.getItem(overrideKey)) {
      chosenVariant = localStorage.getItem(overrideKey) as variant;
      useAnalyticsDimension(analyticsDimension, chosenVariant);
      return chosenVariant;
    }
  }

  // Check local storage for the test variant. This is primarily used for
  // Variants outside of the auth wall
  if (localStorage.getItem(testName)) {
    chosenVariant = localStorage.getItem(testName) as variant;
    useAnalyticsDimension(analyticsDimension, chosenVariant);
    return chosenVariant;
  }

  // If no user ID is provided, randomly assign a variant
  if (!userId) {
    const randomlyChosenVariant = Math.random() < 0.5 ? "A" : "B";
    localStorage.setItem(testName, randomlyChosenVariant);
    useAnalyticsDimension(analyticsDimension, chosenVariant);
    return randomlyChosenVariant;
  }

  // For initial implementation we are doing a 50/50 split based on userID.
  // In the future we can use a more sophisticated method to assign users to variants
  chosenVariant = userId % 2 === 0 ? "A" : "B";
  useAnalyticsDimension(analyticsDimension, chosenVariant);
  return chosenVariant as variant;
};
