/** @format */

import type { Action, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";

import type { OrderSchema } from "src/api";

import { OrdersService } from "src/api";

export interface OrdersModel {
  fetchingOrders: boolean;
  orders: OrderSchema[];
  setFetchingOrders: Action<OrdersModel, boolean>;
  setOrders: Action<OrdersModel, OrderSchema[]>;
  fetchOrders: Thunk<OrdersModel, { user_id: number }>;
}

export const ordersStore: OrdersModel = {
  fetchingOrders: false,
  orders: [],

  setFetchingOrders: action((state, fetching) => {
    state.fetchingOrders = fetching;
  }),

  setOrders: action((state, orders) => {
    state.orders = orders;
  }),

  fetchOrders: thunk(async (actions, payload) => {
    actions.setFetchingOrders(true);
    return OrdersService.getOrders({ userId: payload.user_id })
      .then((res) => {
        actions.setOrders(res);
      })
      .finally(() => {
        actions.setFetchingOrders(false);
      });
  }),
};
