/** @format */

import type { FC } from "react";

import React from "react";

import classNames from "classnames";

import type { BaseFieldProps } from "@alphamedical/components";

import { FieldWrapper } from "@alphamedical/components";

import type { SingleSelectOptionProps } from "./SingleSelectOption";

import { SingleSelectOption } from "./SingleSelectOption";

interface SingleSelectProps<T = string> extends BaseFieldProps<T> {
  options: Omit<SingleSelectOptionProps, "input" | "meta">[];
}

export const SingleSelect: FC<SingleSelectProps> = ({ options, className, ...fieldProps }) => (
  <ul className={classNames(className, "flex flex-col gap-4")}>
    {options.map(
      ({
        optionKey,
        onSelection,
        disabled,
        icon,
        primaryText,
        secondaryText,
        tabIndex,
        bulletPoints,
      }) => (
        <FieldWrapper key={optionKey} {...fieldProps} value={optionKey} type="radio">
          {({ input, meta }) => {
            return (
              <li className="">
                <SingleSelectOption
                  input={input}
                  meta={meta}
                  disabled={disabled}
                  optionKey={optionKey}
                  onSelection={onSelection}
                  primaryText={primaryText}
                  secondaryText={secondaryText}
                  icon={icon}
                  tabIndex={tabIndex}
                  bulletPoints={bulletPoints}
                />
              </li>
            );
          }}
        </FieldWrapper>
      ),
    )}
  </ul>
);
