/** @format */

import React from "react";
import { generatePath, useHistory, useLocation, useParams } from "react-router-dom";

import type { CheckInDataSchema } from "src/api";
import type { OutlinesUpdateResponseCollectionRequestBodySchema } from "src/api/models/OutlinesUpdateResponseCollectionRequestBodySchema";
import type { CheckInLocationState } from "src/v2/components/CheckinResultPage/CheckinResultsPage";

import { FormOutlinesService } from "src/api/services/FormOutlinesService";
import { Loader } from "src/components/Loader";
import * as PDB_ROUTES from "src/routes";
import { useStoreActions, useStoreState } from "src/v2/models";
import { CHECKIN_OUTLINE_KEYS } from "src/v2/models/checkins";
import { useCurrentUser } from "src/v2/models/profile";
import { useQuery } from "src/v2/utils/useQuery";

enum CheckInStatus {
  NOT_STARTED = "not_started",
  STARTED = "started",
  COMPLETED = "completed",
  RETAKE_AVAILABLE = "retake_available",
  SHOW_RESULTS = "show_results",
}

interface RouteParams {
  checkinKey: string;
}

/**
 * CheckinRedirectHandler Component
 *
 * Handles redirection and logic for various check-in states.
 *
 * Usage:
 * This component should be used to handle the redirection and logic related to check-ins.
 * It expects the 'checkinKey' parameter to be provided in the route params, which is used
 * to determine the current check-in state.
 *
 * Required Route Params:
 * - checkinKey: The key identifying the type of check-in to handle.
 *
 * Optional Query Parameters:
 * - action: Specifies the action to perform. Default is no action.
 *           If set to 'retake', indicates that the check-in should be retaken. Used mainly from the CheckinResultPage component.
 *           If set to 'bypass' indicates whether to bypass certain check-in checks (e.g. CHCI bypass repeatable check). *
 * - redirect: Specifies the URL to redirect to after completing the check-in. Default is no redirect.
 * - show_results: Specifies whether to show the check-in results if the previous check-in was successfully completed. Default is no action.
 *
 * @summary Handles redirection and logic for various check-in states.
 *
 * Example:
 * "/your-care/check-ins/redirect/outline-hci?action=bypass"
 */
const CheckinRedirectHandler = () => {
  const currentUser = useCurrentUser();
  const { startCheckIn, fetchCheckIn } = useStoreActions((actions) => actions.checkins);
  const { fetchHealthActionPlan } = useStoreActions((actions) => actions.healthActionPlan);
  const { profile } = useStoreState((state) => state.profile);

  // Use the checkinKey param to map to the correct checkin state we need.
  const { checkinKey } = useParams<RouteParams>();
  const checkinState = useStoreState((state) => state.checkins.checkinList[checkinKey]);
  const history = useHistory();
  const location = useLocation<CheckInLocationState>();
  const title = location.state?.title || "Check-in";

  const queryParams = useQuery();
  const action = queryParams.get("action");
  const redirectUrl = queryParams.get("redirect");
  const showResults = queryParams.get("show_results");

  // As of #ALPHA-2408, we want to start the check-in always from the beginning from all the entry points.
  // In the same way, if the check-in was completed, we need to show the results page.
  // So, we have now a new action param to identify these scenarios.
  let status: CheckInStatus | undefined;
  if (checkinState?.status === CheckInStatus.COMPLETED && showResults === "true") {
    status = CheckInStatus.SHOW_RESULTS;
  } else if (action === "retake") {
    status = CheckInStatus.RETAKE_AVAILABLE;
  } else {
    status = checkinState?.status;
  }

  React.useEffect(() => {
    fetchCheckIn({ checkinKey, userId: currentUser.id });
  }, []);

  const handleCheckinCompletionAsync = async (outlineResponseCollectionId: number) => {
    const requestBody: OutlinesUpdateResponseCollectionRequestBodySchema = { completed: true };
    await FormOutlinesService.updateResponseCollection({
      requestBody,
      responseCollectionId: outlineResponseCollectionId,
      userId: "me",
    });
    fetchCheckIn({ checkinKey, userId: currentUser.id });
  };

  // Special handling for CHCI.
  // If bypass param is present we need to set the bypassRepeatableBypass to true and start a new instance of the CHCI.
  // If not, we need to redirect to the HAP page.
  const handleCompletedStatus = () => {
    if (checkinKey === CHECKIN_OUTLINE_KEYS.COMPREHENSIVE_HEALTH_CHECK_IN) {
      if (action === "bypass") {
        startCheckIn({
          userId: currentUser.id,
          checkinKey,
          bypassRepeatableBypass: true,
        }).then((result: any) => {
          if (result) history.replace(PDB_ROUTES.HEALTH_ACTION_PLAN);
        });
      }
    } else {
      history.replace({
        pathname: generatePath(PDB_ROUTES.CHECKIN_RESULTS_PAGE, {
          checkinKey,
        }),
        state: { title },
      });
    }
  };

  const handleRedirectIntoCheckinForm = (formId: number | undefined) => {
    if (!formId) return;
    // We use the form_type query param to execute specific CHCI logic in the Generic Form.
    const formType =
      checkinKey === CHECKIN_OUTLINE_KEYS.COMPREHENSIVE_HEALTH_CHECK_IN
        ? profile?.is_b2b_user
          ? "b2b-hci"
          : "chci"
        : "";

    const searchParams = new URLSearchParams();
    if (formType) {
      searchParams.set("form_type", formType);
    }

    const url = generatePath(`/form/:formId`, { formId }) + `?${searchParams.toString()}`;
    history.replace(url);
  };

  // Main logic for handling redirection and check-in status
  React.useEffect(() => {
    const handleRedirectAsync = async () => {
      // If the redirectUrl is present, that means this is call from the Result Page and we need to handle the check-in completion and HAP generation.
      if (redirectUrl && checkinState?.outline_response_collection_id) {
        // Flag the checkin as complete and generate the HAP based on the checkin.
        if (checkinState?.status !== CheckInStatus.COMPLETED) {
          await handleCheckinCompletionAsync(checkinState.outline_response_collection_id);
        }
        await fetchHealthActionPlan({
          userId: currentUser.id,
          responseCollectionId: checkinState.outline_response_collection_id,
        });
        // Handle redirect based on the query param.
        history.push(redirectUrl, { from: "check-ins/redirect" });
      } else {
        // If there is no redirectUrl, the behavior is based on the check-in status.
        switch (status) {
          case CheckInStatus.NOT_STARTED:
          case CheckInStatus.RETAKE_AVAILABLE:
            startCheckIn({ userId: currentUser.id, checkinKey }).then(
              (result: CheckInDataSchema) => {
                if (result) {
                  handleRedirectIntoCheckinForm(result.outline_response_collection_id);
                }
              },
            );
            break;
          case CheckInStatus.STARTED:
            handleRedirectIntoCheckinForm(checkinState?.outline_response_collection_id);
            break;
          case CheckInStatus.COMPLETED:
          case CheckInStatus.SHOW_RESULTS:
            handleCompletedStatus();
            break;
          default:
            break;
        }
      }
    };
    handleRedirectAsync();
  }, [status, checkinKey]);

  // Display a loader while processing the redirect.
  return <Loader show={true} />;
};

export default CheckinRedirectHandler;
