/** @format */

export const _emailPolicy = [
  "^([\\w-]+(?:[\\.\\+][\\w-]+)*)",
  "@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})",
  "\\.([a-z]{2,6}(?:\\.[a-z]{2})?)",
  "$",
].join("");

export const _date = "([0][1-9]|[1][0-2])-([0-2][0-9]|[3][0-1])-([12][0-9]{3})";
export const _phone = /^([2-9]{1})(\d{2})(-)?([2-9]{1})(\d{2})(-)?(\d{4})$/;
