/** @format */

import React, { useState } from "react";

import { MedicationJournalReport } from "@alphamedical/components";

import type { PatientMedicationTrackingSchema, SymptomTrackingSchema } from "src/api";

import { PageWrapper } from "src/components/PageWrapper";
import AddMedicationEntryBottomSheet from "src/routes/Records/WeightLossJournal/AddMedicationEntryBottomSheet";
import WithShareDataModal from "src/routes/Records/WeightLossJournal/WithShareDataModal";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useStoreDispatch, useStoreState } from "src/v2/models";

interface MedicationEntry extends PatientMedicationTrackingSchema {
  entry_type: "medication";
}

interface SymptomEntry extends SymptomTrackingSchema {
  entry_type: "symptom";
}

export type MedicationJournalEntry = MedicationEntry | SymptomEntry;

const MedicationJournal: React.FC = () => {
  useTopBar({ title: "Medications", variant: "nested", showBackButton: true });

  const { medicationTrackingRecords, symptomTrackingRecords } = useStoreState(
    (state) => state.medicationTracking,
  );

  const [selectedEntry, setSelectedEntry] = useState<MedicationJournalEntry>();
  const dispatch = useStoreDispatch();

  const { medications } = useStoreState((state) => state.medications);

  React.useEffect(() => {
    dispatch.medications.fetchMedications();
    dispatch.medicationTracking.fetchMedicationTracking();
    dispatch.medicationTracking.fetchSymptomTracking();
  }, []);

  const [addDoseModalOpen, setAddDoseModalOpen] = useState(false);

  const convertedEntries = (): MedicationJournalEntry[] => {
    const mEntries: MedicationEntry[] = medicationTrackingRecords.map((record) => ({
      ...record,
      entry_type: "medication",
    }));
    const sEntries: SymptomEntry[] = symptomTrackingRecords.map((record) => ({
      ...record,
      entry_type: "symptom",
    }));

    return [...mEntries, ...sEntries];
  };

  return (
    <PageWrapper cxPadding="px-0 py-0" cxMaxWidth="w-screen sm:max-w-xl">
      <MedicationJournalReport<MedicationJournalEntry>
        onAddClick={() => setAddDoseModalOpen(true)}
        onSelectEntry={setSelectedEntry}
        medicationEntries={convertedEntries()}
      />
      {(addDoseModalOpen || selectedEntry) && (
        <AddMedicationEntryBottomSheet
          entry={selectedEntry}
          entryType={selectedEntry?.entry_type}
          availableMedications={medications.filter(
            (value) =>
              value.condition?.key && ["weight-loss", "diabetes"].includes(value.condition.key),
          )}
          onClose={() => {
            setAddDoseModalOpen(false);
            setSelectedEntry(undefined);
          }}
        />
      )}
    </PageWrapper>
  );
};

export default WithTopBarNavigation(WithShareDataModal(MedicationJournal));
