/** @format */

import React from "react";
import { Link } from "react-router-dom";

import type { FormApi } from "final-form";
import { dropRight, includes, isFunction, last, map, size } from "lodash";

import { Popup, SimpleForm } from "@alphamedical/components";

import type { FollowupItem } from "src/components/Popups/types";

import { _POST } from "src/helpers/http";
import { Button } from "src/v2/components/Button";
import { useStoreDispatch } from "src/v2/models";

export interface OutOfStockPopupProps {
  item: FollowupItem;
  showPopup: boolean;
  setShowPopup: (arg: boolean) => void;
  key?: number;
  pii?: any;
  profile?: any;
}

enum Page {
  GENERIC = "generic",
  GENERIC_SIZE = "generic_size",
  GENERIC_FREQUENCY = "generic_frequency",
  GENERIC_CONFIRMED = "generic_confirmed",
  DELAY = "delay",
  DELAY_CONFIRMED = "delay_confirmed",
  EXTERNAL_PHARMACY = "external_pharmacy",
  EXTERNAL_PHARMACY_SELECTED = "external_pharmacy_selected",
  EXTERNAL_PHARMACY_CONFIRMED = "external_pharmacy_confirmed",
  CANCEL = "cancel",
  CANCEL_CONFIRMED = "cancel_confirmed",
}

interface FormValues {
  selection: string;
  preferred_size: number;
  refill_frequency: number;
}

interface Cta {
  text: string;
  clickHandler: (form: Form) => void;
  secondary?: boolean;
  hide?: (values: FormValues) => boolean; // some options are dependent on form state
}

type Form = FormApi<FormValues, Partial<FormValues>>;

interface PageData {
  description: string | React.ReactElement;
  cta?: Cta[];
}
type PopupContent = Record<Page, PageData>;

export const OutOfStockPopup = ({
  item: { order_id },
  showPopup,
  setShowPopup,
  pii,
  profile,
}: OutOfStockPopupProps) => {
  const [page, setPage] = React.useState<Page>(Page.GENERIC);
  const [pageHistory, setPageHistory] = React.useState<Page[]>([Page.GENERIC]);
  const [title, setTitle] = React.useState<string>("Action Required");
  const dispatch = useStoreDispatch();

  React.useEffect(() => {
    if (page && page !== last(pageHistory)) {
      setPageHistory([...pageHistory, page]);
    }
  }, [page]);

  const goBack = () => {
    if (size(pageHistory) > 1) {
      const newPage = pageHistory[pageHistory?.length - 2] || Page.GENERIC;
      setPageHistory(dropRight(pageHistory));
      setPage(newPage);
    }
  };

  const submitForm = ({ selection, preferred_size, refill_frequency }: FormValues) => {
    if (selection) {
      return _POST(`/v2/users/me/orders/${order_id}/out_of_stock`, {
        selection,
        preferred_size,
        refill_frequency,
      }).then((res) => {
        const { selection } = res;
        setPage(selection);
        setTitle("Thank you");
      });
    }
    return;
  };

  const frequency = (text: string, days: number, hiddenFor?: number) => ({
    text,
    clickHandler: (form: Form) => {
      form.change("refill_frequency", days);
      form.submit();
    },
    hide: (values: FormValues) => values?.preferred_size === hiddenFor,
  });

  const content: PopupContent = {
    [Page.GENERIC]: {
      description:
        "Latisse® is currently out of stock at our partner pharmacy. Would you like to temporarily switch to generic Latisse® (bimatoprost ophthalmic solution, 0.03%)?",
      cta: [
        {
          text: "Yes",
          clickHandler: (form: Form) => {
            form.change("selection", "generic");
            setPage(Page.GENERIC_SIZE);
          },
        },
        { text: "No", clickHandler: () => setPage(Page.DELAY), secondary: true },
      ],
    },
    [Page.GENERIC_SIZE]: {
      description: "Please select your preferred generic Latisse® bottle size.",
      cta: [
        {
          text: "3mL",
          clickHandler: (form: Form) => {
            form.change("preferred_size", 3);
            setPage(Page.GENERIC_FREQUENCY);
          },
        },
        {
          text: "5mL",
          clickHandler: (form: Form) => {
            form.change("preferred_size", 5);
            setPage(Page.GENERIC_FREQUENCY);
          },
        },
      ],
    },
    [Page.GENERIC_FREQUENCY]: {
      description: "Please select your preferred refill frequency.",
      cta: [
        frequency("Every Month", 30, 5),
        frequency("Every Other Month", 60),
        frequency("Every 3 Months", 90, 3),
      ],
    },
    [Page.GENERIC_CONFIRMED]: {
      description: `Thank you for choosing to temporarily switch to generic Latisse® (bimatoprost ophthalmic solution, 0.03%). We will update and resubmit your order accordingly. Please do not hesitate to message us if you have any questions or concerns.
      `,
    },
    [Page.DELAY]: {
      description:
        "We understand you don’t want to switch to generic Latisse®. Would you like to delay your next shipment until Latisse® is back in stock?",
      cta: [
        {
          text: "Yes",
          clickHandler: (form: Form) => {
            form.change("selection", "delay");
            form.submit();
          },
        },
        { text: "No", clickHandler: () => setPage(Page.EXTERNAL_PHARMACY), secondary: true },
      ],
    },
    [Page.DELAY_CONFIRMED]: {
      description: `Thank you for choosing to delay your next Latisse® shipment until it is back in stock (expected time frame is mid-November). Please do not hesitate to message us if you have any questions or concerns.`,
    },
    [Page.EXTERNAL_PHARMACY]: {
      description:
        "Latisse® is currently out of stock at our partner pharmacy. Would you like to transfer your prescription to an external pharmacy to be picked up?",
      cta: [
        {
          text: "Yes",
          clickHandler: () => setPage(Page.EXTERNAL_PHARMACY_SELECTED),
        },
        { text: "No", clickHandler: () => setPage(Page.CANCEL), secondary: true },
      ],
    },
    [Page.EXTERNAL_PHARMACY_SELECTED]: {
      description: (
        <>
          <p>
            You must contact your preferred pharmacy to request that they help with a prescription
            transfer.
          </p>
          <p>
            They will need to contact our partner pharmacy using the following contact information
            and request the transfer for you.
          </p>
          <br />
          <div>Pharmacy Name: Truepill</div>
          <div>Address: 3121 Diablo Ave, Hayward, CA 94545</div>
          <div>Phone: (855) 910-8606</div>
          <div>Pharmacy customer service: (650) 353-5495</div>
        </>
      ),
      cta: [
        {
          text: "Confirm",
          clickHandler: (form: Form) => {
            form.change("selection", "external_pharmacy");
            form.submit();
          },
          secondary: true,
        },
      ],
    },
    [Page.EXTERNAL_PHARMACY_CONFIRMED]: {
      description: `Thank you for choosing to transfer your Latisse® prescription to an external pharmacy. Please do not hesitate to message us if you have any questions or concerns.`,
    },
    [Page.CANCEL]: {
      description: `We will cancel your subscription for Latisse® since you have selected “no” to the following options: switch to generic Latisse®, delay your next shipment until Latisse® is back in stock, and transfer your prescription to an external pharmacy. Please note that your subscription can be reactivated as long as your prescription is still active.`,
      cta: [
        {
          text: "Confirm",
          clickHandler: (form: Form) => {
            form.change("selection", "cancel");
            form.submit();
          },
          secondary: true,
        },
      ],
    },
    [Page.CANCEL_CONFIRMED]: {
      description: (
        <>
          We have cancelled your subscription for Latisse® since you have selected “no” to the
          following options: switch to generic Latisse®, delay your next shipment until Latisse®
          is back in stock, and transfer your prescription to an external pharmacy. Please note that
          your subscription can be reactivated as long as your prescription is still active. You can
          simply message us <Link to="/messages">here</Link> and we will be able to further assist
          you.
        </>
      ),
    },
  };

  return (
    <div>
      <Popup
        isOpen={showPopup && !profile.hasSeenPopup}
        size="medium"
        onRequestClose={() => {
          setShowPopup(false);
          dispatch.profile.setHasSeenPopup(true);
        }}
        headerText={title}
        accentColor="sand-20"
      >
        <SimpleForm<FormValues> onSubmit={submitForm} hideDebugInfo={true}>
          {({ submitting, form, values }) => {
            const renderCta = ({ text, clickHandler, secondary, hide }: Cta) => {
              const secondaryProps = secondary
                ? {
                    extraClassNames: `
                    text-center
                    border-2 border-grey
                    hover:border-primary-normal
                    hover:text-white
                    items-center cursor-pointer`,
                    bgColor: "white",
                    hoverBgColor: "primary-80",
                    textColor: "primary",
                  }
                : {};
              if (isFunction(hide) && hide(values)) {
                return null;
              }
              return (
                text &&
                clickHandler && (
                  <Button
                    key={`button-${text}`}
                    cxMargin="mx-4"
                    onClick={() => clickHandler(form)}
                    disabled={submitting}
                    {...secondaryProps}
                  >
                    <span className="text-md font-bold">{text}</span>
                  </Button>
                )
              );
            };

            return (
              <div className="pb-12 px-6">
                {!includes(page, "confirmed") && page !== Page.GENERIC && (
                  <div onClick={goBack} className="flex items-center cursor-pointer mt-6">
                    <img src={`/assets/back-arrow.svg`} alt="Go to previous options" />
                    <span className="font-bold ml-2">Back</span>
                  </div>
                )}
                <div className="my-6">{content[page]?.description}</div>
                {content[page]?.cta ? (
                  <div className="flex items-center">{map(content[page]?.cta, renderCta)}</div>
                ) : (
                  <span>- The Alpha Care Team</span>
                )}
              </div>
            );
          }}
        </SimpleForm>
      </Popup>
    </div>
  );
};
