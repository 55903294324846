/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EditOtcResponseSchema } from '../models/EditOtcResponseSchema';
import type { SubscriptionsEditOtcProductsRequestBodySchema } from '../models/SubscriptionsEditOtcProductsRequestBodySchema';
import type { SubscriptionsSkipNextRefillRequestBodySchema } from '../models/SubscriptionsSkipNextRefillRequestBodySchema';
import type { SubscriptionsUpdateSubscriptionRequestBodySchema } from '../models/SubscriptionsUpdateSubscriptionRequestBodySchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubscriptionsService {

    /**
     * @returns EditOtcResponseSchema
     * @throws ApiError
     */
    public static editOtcProducts({
        subscriptionId,
        requestBody,
    }: {
        subscriptionId: string,
        requestBody: SubscriptionsEditOtcProductsRequestBodySchema,
    }): CancelablePromise<Array<EditOtcResponseSchema>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v2/subscriptions/{subscription_id}/otc',
            path: {
                'subscription_id': subscriptionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getExternalSubscriptions({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/users/{user_id}/external-subscriptions',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getSubscriptionsForUser({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/users/{user_id}/subscriptions',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static updateSubscription({
        userId,
        subscriptionId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        subscriptionId: string,
        requestBody: SubscriptionsUpdateSubscriptionRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v2/users/{user_id}/subscriptions/{subscription_id}',
            path: {
                'user_id': userId,
                'subscription_id': subscriptionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static skipNextRefill({
        userId,
        subscriptionId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        subscriptionId: string,
        requestBody: SubscriptionsSkipNextRefillRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v2/users/{user_id}/subscriptions/{subscription_id}/skip',
            path: {
                'user_id': userId,
                'subscription_id': subscriptionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static subscriptionAutomationSelection({
        userId,
        subscriptionId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        subscriptionId: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/users/{user_id}/subscriptions/{subscription_id}/subscription_user_approvals',
            path: {
                'user_id': userId,
                'subscription_id': subscriptionId,
            },
        });
    }

    /**
     * @deprecated
     * Returns an list of a patients subscriptions for the 'Your Treatments' page
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getUserTreatments({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/users/{user_id}/treatments',
            path: {
                'user_id': userId,
            },
        });
    }

}
