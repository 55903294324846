/** @format */

import type { PharmacyContentFromCMS, PharmacyModalContent } from "src/v2/components/Pharmacy";

export const PHARMACY_DIFFERENCES_MODAL_CONTENT: PharmacyModalContent = {
  title: "Learn more about your options",
  options: [
    {
      title: "Free shipping",
      bullets: [
        "Through Alpha's trusted partner pharmacy.",
        "Prescriptions ship for free right to your home.",
        "Refills are automatic.",
        "Get notified before payment is processed if your cost is over $60.",
      ],
    },
    {
      title: "Pickup",
      bullets: [
        "Select a pharmacy near you.",
        "Check with different pharmacies if your medication is low in stock.",
        "Helpful option if you're traveling or are away from home.",
      ],
    },
  ],
};

export const DEFAULT_SONJA_CARES_USERS_PHARMACY_CONTENT: PharmacyContentFromCMS = {
  title: "How would you like to get your prescription?",
  options: [
    {
      title: "Tower Pharmacy",
      value: "tower",
      showInUrgentVisits: true,
      description: "Pay just $2 for a 30-day supply of one of over 1000 eligible medications",
      badge: "Sonja Cares recommends",
    },
    {
      title: "Mail-order pharmacy",
      value: "alpha",
      showInUrgentVisits: false,
      description: "Our partner pharmacy will ship your prescription",
    },
    {
      title: "Other pharmacy",
      value: "external",
      showInUrgentVisits: true,
      description: "Search for a pharmacy near you or another mail-order pharmacy",
    },
  ],
  modal: {
    title: "Learn more about your options",
    options: [
      {
        title: "Tower Pharmacy*",
        bullets: [
          "This is your most affordable option",
          "A 30-day supply of an eligible medication is $2",
          "Pick up at 501 E Olive Ave., Turlock, California 95380, or contact pharmacy for delivery options",
        ],
      },
      {
        title: "Mail-order pharmacy",
        bullets: [
          "You will be responsible for the full cash price of the medication",
          "You cannot use any insurance benefits for prescriptions through this pharmacy",
          "Prescriptions ship for free",
        ],
      },
      {
        title: "Other pharmacy",
        bullets: ["Check with different pharmacies if your medication is low in stock"],
      },
    ],
    footer: [
      "*To use Tower Pharmacy, you cannot receive medical or pharmacy benefits through a Medicare, Medicaid/Medi-Cal, Tricare, VA, or other government-funded insurance plan. These types of benefits can't be used for any service through Hello Alpha or any resulting prescription.",
      "You must also live in one of the following ZIP codes: 95380, 95382, 95315, 95334, 95324, 95316, 95363, 95307, 95301, 95360, 95326, 95388, 95328, 95322, 95381, 95374, 95358, 95303, 95313.",
    ],
  },
};
