/** @format */

import React from "react";

interface VimeoPlayerProps {
  videoId: string;
  autoPlay?: boolean;
}

export const VimeoPlayer = ({ videoId, autoPlay = true }: VimeoPlayerProps) => {
  let src = `https://player.vimeo.com/video/${videoId}?dnt=1&title=0&byline=0&portrait=0`;
  if (autoPlay) {
    src += "&autoplay=1";
  }
  return (
    <>
      <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
        <iframe
          title="vimeo player"
          src={src}
          style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen={true}
        />
      </div>
      <script src="https://player.vimeo.com/api/player.js" />
    </>
  );
};
