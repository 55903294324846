/** @format */

import React from "react";

import Pii from "src/v2/components/Pii";

import type { PageFormProps } from "./types";

import { PageFormWrapper } from "./pageWrapper";

export const PiiPage = (props: PageFormProps) => {
  return (
    <PageFormWrapper {...props}>
      {(formRenderProps) => (
        <Pii formProps={formRenderProps} fields={props.page.fields} showNameFields={true} />
      )}
    </PageFormWrapper>
  );
};
