/** @format */

import React from "react";

import _ from "lodash";

import type { Consultation } from "src/v2/types/consultation";

import { SUICIDE_HOTLINE_NUMBER } from "src/v2/constants";
import { NUTRITION_VALID_STATES } from "src/v2/utils/page/_validators/condition";

const softStopMessage = (props: EligibilityFormSoftStopProps, dontRenderContinue?: boolean) => {
  const consultState =
    (props.consult &&
      props.consult.answers &&
      _.get(props.consult.answers, "consult-state.value")) ||
    "";

  switch (props.invalid.name) {
    case "home-self-collection-kit":
      return {
        title: "Notice",
        body: `
        By choosing the home self-collection test kit, you will be proceeding to the purchase of a Thyroid Stimulating Hormone (TSH) - At Home Collection Kit which will be mailed to you directly. This is a cash-only purchase (insurance not accepted).
        <br/><br/>
        Once your sample is tested and lab results are ready, our medical team will
        reach out to discuss next steps.`,
      };
    case "mental-health-looking-to-treat":
      return {
        title: "Before you continue",
        body: `We do not treat Bipolar Disorder, Borderline Personality Disorder, or Schizophrenia.<br/><br/>
        Depending upon your mental health needs, our clinicians may not be able to treat you online.<br/><br/>
        However, if you choose to sign up for the mental health service, our clinicians will review your case.
        `,
      };
    case "suicidal-feelings":
      return {
        title: "Notice",
        body: `Our clinicians cannot safely treat you via telemedicine.<br/> We recommend that you seek immediate help: go to the emergency room, call ${SUICIDE_HOTLINE_NUMBER}, text 741741, or call 911 so you can be evaluated.
            ${
              !dontRenderContinue
                ? "<br/><br/> However, you may complete this intake and we will review your case."
                : ""
            }
            `,
      };
    case "outline-product-selection":
      return {
        title: "Before you continue...",
        body: `If this test is positive, you will need to get a blood test to confirm whether or not you have this infection.
        <br/><br/>
        <p><strong>Hep B</strong></p>
        <p><strong>Hep C</strong></p>
        <p><strong>HIV</strong></p>
        <p><strong>Syphillis</strong></p>`,
      };
    case "sti-women-current-symptoms":
      return {
        title: "Notice",
        body: `We recommend an in-person exam with a pregnancy test, blood tests, and cervical cultures.`,
      };

    case "choose-medications":
      return {
        title: "Please choose again",
        body: `Estrogen-containing contraceptives are not recommended in women
        ages 35 years or older who smoke due to higher risks of heart attack and
        stroke.<br/><br/>
        <strong>Please press the X and choose a different medication.</strong>`,
      };
    case "osteo-orilissa-warning":
      return {
        title: "Please choose again",
        body: `Orilissa is not recommended for patients with osteoporosis.<br/><br/>
        <strong>Please press the X and choose a different medication.</strong>`,
      };
    case "cyclosporine-orilissa-warning":
      return {
        title: "Please choose again",
        body: `Orilissa is not recommended for patients using Cyclosporine.<br/><br/>
        <strong>Please press the X and choose a different medication.</strong>`,
      };
    case "gemfibrozil-orilissa-warning":
      return {
        title: "Please choose again",
        body: `Orilissa is not recommended for patients using Gemfibrozil.<br/><br/>
        <strong>Please press the X and choose a different medication.</strong>`,
      };
    case "bmi-invalid":
      return {
        title: "Notice",
        body: `Your current BMI (Body Mass Index) is ${props.invalid.bmiValue}.
          ${
            !dontRenderContinue
              ? `<br/><br/>  Because your BMI is less than 30 your provider may not recommend weight loss medication for you unless you have weight-related health problems like high blood pressure or type 2 diabetes.<br/><br/> However, you may complete this intake and we will review your case. ${
                  NUTRITION_VALID_STATES.indexOf(consultState) > -1
                    ? "<br/><br/> You can also complete a Nutrition visit in order to connect with a registered Dietitian to track and achieve your goals. <br/><br/> <a class='hover:underline text-sky-dark' href='/consultation/nutrition'><b>Click here to start a Nutrition visit now.</b></a>"
                    : ""
                }`
              : `<br/><br/>  Clinical guidelines do not recommend weight loss medications in people with BMIs less than 27. The risk is greater than the benefit for someone in your BMI category.${
                  NUTRITION_VALID_STATES.indexOf(consultState) === -1
                    ? "<br/><br/> Because of this we will not be able to treat you for this condition."
                    : "<br/><br/> However you can complete a Nutrition visit in order to connect with a registered Dietitian to track and achieve your goals. <br/><br/> <a class='hover:underline text-sky-dark' href='/consultation/nutrition'><b>Click here to start a Nutrition visit now.</b></a>"
                }`
          }
          `,
      };
    default:
      return {
        title: "Notice",
        body: "If you test positive for any of these infections, you will need to get a follow-up blood test to make sure that the diagnosis is correct.",
      };
  }
};

interface EligibilityFormSoftStopProps {
  closeModal: any;
  condition: string;
  invalid: any;
  saveValues: any;
  moveForward: any;
  values: any;
  consult: Consultation;
}

export const EligibilityFormSoftStop = (props: EligibilityFormSoftStopProps) => {
  const whiteBackground =
    [
      "outline-product-selection",
      "choose-medications",
      "osteo-orilissa-warning",
      "cyclosporine-orilissa-warning",
      "gemfibrozil-orilissa-warning",
      "home-self-collection-kit",
    ].indexOf(props.invalid.name) !== -1;

  const dontRenderContinue =
    (props.invalid.name === "suicidal-feelings" &&
      ["INITIAL_CONSULT", "VISIT"].indexOf(props.consult.consult_type) !== -1) ||
    props.condition === "smoking-cessation" ||
    (props.condition === "weight-loss" && props.invalid.bmiValue < 27);

  return (
    <div className="flex flex-col">
      <h1 style={{ top: "-18px", width: "315px" }} className="text-3xl md:relative font-medium">
        {softStopMessage(props).title}
      </h1>
      <div
        style={{ top: "-14px" }}
        className={`w-full md:relative border ${whiteBackground ? "border-b-grey-500" : "border-primary"} mb-2`}
      />
      <div
        className={`text-lg mt-4 mb-10 ${whiteBackground ? "" : "text-center"}`}
        dangerouslySetInnerHTML={{ __html: softStopMessage(props, dontRenderContinue).body }}
      />
      {!dontRenderContinue && (
        <button
          className="btn btn-primary w-full my-2"
          onClick={async () => {
            await props.saveValues(props.values);
            props.moveForward();
            props.closeModal();
          }}
        >
          Continue
        </button>
      )}
    </div>
  );
};
