/** @format */

import type { RouteComponentProps } from "react-router-dom";

import React from "react";
import { generatePath } from "react-router-dom";

import classNames from "classnames";
import _, { find, findIndex, isEmpty, isNumber } from "lodash";

import { ProgressBar } from "src/v2/designSystem";

import type { BillingInfoSchema, ProfileSchema, WaitForPCPSchema } from "src/api";
import type { DynamicField, DynamicFormPage } from "src/components/DynamicForm/types";
import type { FormPopupError } from "src/v2/models/api_types";
import type { Outline } from "src/v2/models/outline";
import type { Consultation as TConsultation } from "src/v2/types/consultation";
import type { InvalidType } from "src/v2/utils/page/validators";

import { Analytics } from "src/analytics";
import {
  ConsultsService,
  MedicationsService,
  ProviderCoverageService,
  UsersService,
} from "src/api";
import { Loader } from "src/components/Loader";
import OutlineInfoPopupContainer from "src/components/Popups/OutlineInfoPopupContainer";
import { WaitForPCPPopup } from "src/components/Popups/WaitForPCPPopup";
import config from "src/config";
import { isAxiosError } from "src/helpers/axios";
import { isSpecificTransitionScreen } from "src/routes/GenericForm";
import { getQuery, serializeDependency } from "src/utils";
import { calculateProgress } from "src/utils/formUtils";
import { useGetMembership } from "src/utils/hooks";
import { useHasEmployerBenefits } from "src/utils/hooks/useHasEmployerBenefits";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { useUserRequiresSyncVisit } from "src/utils/hooks/useUserRequiresSyncVisit";
import Div100vh from "src/v2/components/Div100vh";
import { PediatricsAfterVisit } from "src/v2/components/Family/PediatricsAfterVisit";
import { Page } from "src/v2/components/Page";
import { Complete } from "src/v2/components/Page/Complete";
import CompletionPage, {
  CompletionSteps,
  getDefaultSteps,
} from "src/v2/components/Page/Complete/CompletionPage";
import DiabetesCompletionPage from "src/v2/components/Page/DiabetesCompletionPage/DiabetesCompletionPage";
import { TopBarNavigationPdb } from "src/v2/components/WithTopBarNavigation/TopBarNavigationPdb";
import { useStoreActions, useStoreDispatch, useStoreState } from "src/v2/models";
import { isConsultResponsesValidationError } from "src/v2/models/api_types";
import { getFieldCanBeShown } from "src/v2/utils/page/fields";
import * as PageHandlers from "src/v2/utils/page/handlers";
import { getLastAnsweredPageIdx } from "src/v2/utils/page/pages";
import { isInvalidType } from "src/v2/utils/page/validators";

import EligibilityForm from "./EligibilityForm";

interface ConsultationProps extends RouteComponentProps<{ outline: string; pageKey?: string }> {
  outline: Outline;
  consult: TConsultation;
  profile: ProfileSchema;
}

interface FormNavContextInterface {
  nextClick: (values: any) => void;
  goToPage: (pageKey: string, values: any) => void;
}

export const ConsultContext = React.createContext<TConsultation | undefined>(undefined);

export const FormNavContext = React.createContext<FormNavContextInterface | undefined>(undefined);

export const useFormNav = () => {
  const formNav = React.useContext(FormNavContext);
  if (formNav == null) {
    throw Error(
      "Unable to access current consult. Make sure that useCurrentConsult is called within a FormNavContext.Provider",
    );
  }
  return formNav;
};

export const useCurrentConsult = () => {
  const consult = React.useContext(ConsultContext);
  if (consult == null) {
    throw Error(
      "Unable to access current consult. Make sure that useCurrentConsult is called within a ConsultContext.Provider",
    );
  }
  return consult;
};

export const Consultation = (props: ConsultationProps) => {
  const [currentPage, setCurrentPage] = React.useState<DynamicFormPage | undefined>();
  const [mounted, setMounted] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [invalid, setInvalid] = React.useState<InvalidType | undefined>();
  const [invalidValues, setInvalidValues] = React.useState<any>();
  const [updatingPage, setUpdatingPage] = React.useState(false);
  const [submittingConsult, setSubmittingConsult] = React.useState<boolean>(false);
  const [consultSubmitted, setConsultSubmitted] = React.useState<boolean>(
    props.consult.status !== "STARTED",
  );
  const [consultSubmissionErrorType, setConsultSubmissionErrorType] =
    React.useState<any>(undefined);
  const [consultSubmissionErrorMessage, setConsultSubmissionErrorMessage] =
    React.useState<string>("");
  const [responseValidationError, setResponseValidationError] = React.useState<
    FormPopupError | undefined
  >();
  const [answerValues, setAnswerValues] = React.useState({});
  const [showPopUp] = React.useState(getQuery(window.location.search, "h") === "t");
  const [waitForPCPOpen, setWaitForPCPOpen] = React.useState(false);
  const [waitForPCPData, setWaitForPCPData] = React.useState<WaitForPCPSchema>();
  const { membership } = useGetMembership();
  const { addSnack } = useStoreActions((actions) => actions.snacks);

  // I added this code to handle an auto-submit logic that moves to the next page automatically, but for some reason it's not being triggered all the time.
  // During the initial page load, we want to skip this automatic submission.
  const [isLoadingReEntryPage, setIsLoadingReEntryPage] = React.useState(false);
  let isAutoSubmit = false;
  const setIsAutoSubmit = (value: boolean) => {
    isAutoSubmit = value;
  };
  const hasWesternDentalBenefits = useHasEmployerBenefits("western-dental");
  const userRequiresSyncVisit = useUserRequiresSyncVisit();

  // Models
  const dispatch = useStoreDispatch();

  const { consult } = props;
  const isSynchronous = consult.is_synchronous || !!consult.answers.scheduling?.value?.date;
  const visitSubmittedText = isSynchronous
    ? "You've submitted your visit details."
    : "Your visit is complete.";

  React.useEffect(() => {
    dispatch.consults.fetchHealthHistory();
  }, []);

  const urlParams = props.match.params;
  const getUrl = (pageKey: string) =>
    generatePath(props.match.path, {
      outline: urlParams.outline,
      pageKey,
    });
  const { couponCode } = useStoreState((state) => state.payments);
  const { setCouponCode } = useStoreActions((action) => action.payments);

  const refreshAnswers = async () => {
    setAnswerValues(await dispatch.consults.getFormAnswers());
  };

  const saveValues = async (values: any) => {
    const { validatorsToSkip, ...formValues } = values;
    if (_.get(formValues, "has-insurance") === "no") {
      formValues["payment_preference"] = "cash";
    }

    return await dispatch.consults
      .submitAnswers({
        consultId: consult.id,
        values: formValues,
        fields: props.outline.fields,
        skippedValidators: validatorsToSkip,
      })
      .then(() => {
        return dispatch.profile.fetchProfile({
          include: ["pii", "insurance", "consults"],
        });
      })
      .finally(() => {
        refreshAnswers();
      });
  };
  /* Returns an object of pii values which will be spread into the initialValues prop
  on the Form in the Page component. Passing the properties of this object into the initialValues
  of the Form will allow for these pii fields to be pre-populated for the patient as they are
  going through a Consultation. */
  const getExistingPiiAnswers = async () => {
    const { pii, emergency_contact } = props.profile;
    const valueKeys = [
      "dob",
      "sex",
      "phone_number",
      "first_name",
      "last_name",
      "address.address",
      "address.address2",
      "address.city",
      "address.state",
      "address.zipcode",
      "name",
      "phone",
      "relationship",
      "preferred_firstname",
      "preferred_lastname",
      "preferred_pronouns",
      "prefix",
      "license_type",
      "license_expiration",
    ];
    const _answerValues = await dispatch.consults.getFormAnswers();
    return valueKeys.reduce<Record<string, any>>((_answers, valueKey) => {
      const _piiValue = _.get(pii, valueKey);
      const emergencyContactValue = _.get(emergency_contact, valueKey);
      if (_piiValue) {
        const fieldKey = valueKey.replace("address.", "");
        if (_piiValue !== _.get(_answerValues, fieldKey)) {
          _answers[fieldKey] = _piiValue;
        }
      }
      if (emergencyContactValue) {
        _answers[valueKey] = emergencyContactValue;
      }
      return _answers;
    }, {});
  };

  const [prevAnswers, setPrevAnswers] = React.useState();
  React.useEffect(() => {
    const getPrevAnswersAsync = async () => {
      setPrevAnswers(await dispatch.consults.getFormPreviousAnswers());
    };

    getPrevAnswersAsync();
  }, []);

  React.useEffect(() => {
    const initializeConsultation = async () => {
      const { pages } = props.outline;
      await dispatch.profile.fetchProfile({
        include: ["insurance", "pii", "consults", "emergency_contact"],
      });
      let billing: BillingInfoSchema | undefined;
      try {
        billing = await UsersService.userBillingInfo();
      } catch (e) {
        billing = undefined;
      }

      const piiValues = await getExistingPiiAnswers();
      if (!_.isEmpty(piiValues) && consult) {
        await dispatch.consults
          .submitAnswers({
            consultId: consult.id,
            values: piiValues,
            fields: props.outline.fields,
          })
          .catch((err: any) => {
            const res = err.response?.data;
            if (isConsultResponsesValidationError(res)) {
              // if there is a validation error for pii, prefilled from a prior consult, the answer can't be changed
              setResponseValidationError({
                ...res,
                prevent_close: currentPage?.key !== "pii",
              });
              setUpdatingPage(false);
            }
          });
      }

      const _answerValues = await dispatch.consults.getFormAnswers();

      const lastAnsweredPageIdx = getLastAnsweredPageIdx(
        pages,
        _answerValues,
        consult,
        !!billing,
        props.profile,
        membership,
        hasWesternDentalBenefits,
        userRequiresSyncVisit,
      );

      const lastAnsweredPage = pages[lastAnsweredPageIdx];

      setPage(lastAnsweredPageIdx);
      setCurrentPage(lastAnsweredPage);
      setMounted(true);
      refreshAnswers();
      setIsLoadingReEntryPage(true);

      if (lastAnsweredPage) {
        props.history.replace(getUrl(lastAnsweredPage.key));
      }
    };
    initializeConsultation();

    return () => {
      dispatch.consults.clearCurrentConsultation();
    };
  }, []);

  React.useEffect(() => {
    if (currentPage) {
      const pageKey = consultSubmitted ? "complete" : currentPage.key;
      if (pageKey !== urlParams.pageKey) {
        props.history.push(getUrl(pageKey));
      }

      if (currentPage.key !== "payment") {
        PageHandlers.scrollToTop();
      }
    }
  }, [serializeDependency(currentPage, ["key"])]);

  React.useEffect(() => {
    if (currentPage && urlParams.pageKey && urlParams.pageKey !== currentPage.key) {
      const { pages } = props.outline;
      const foundPage = find(pages, (page) => page.key === urlParams.pageKey);
      if (foundPage) {
        setCurrentPage(foundPage);
        const foundIndex = findIndex(pages, foundPage);
        if (isNumber(foundIndex)) {
          setPage(foundIndex);
        }
      }
    }
  }, [urlParams?.pageKey]);

  const trackNext = () => {
    const pageKey = currentPage?.key;
    if (pageKey === "medication-reporting") {
      MedicationsService.getMedications({
        userId: props.profile.id,
      });
    }
  };

  const finishConsult = () => setConsultSubmitted(true);

  const moveForward = async (nextPageIdx: number = page + 1, values: any) => {
    if (props.outline.pages.length <= nextPageIdx) {
      setSubmittingConsult(true);
      dispatch.consults
        .submitConsult({
          couponCode,
          consultId: consult.id,
          condition: props.outline.condition,
        })
        .then(() => {
          ProviderCoverageService.getPatientWaitForPcp({
            userId: "me",
            entityId: `${consult.id}`,
            entityType: "consult",
          }).then((res) => {
            if (res.can_wait_pcp) {
              setWaitForPCPData(res);
              setWaitForPCPOpen(true);
            } else {
              finishConsult();
            }
          });
        })
        .catch((err: any) => {
          if (err?.response?.data?.type === "telehealth-consent-not-found") {
            addSnack({
              type: "error",
              message: "To proceed, please agree to the Consent to Telehealth",
              id: "consent-error",
              delay: 5,
            });
          }

          if (typeof err == "string") {
            setConsultSubmissionErrorType(err);
          } else {
            setConsultSubmissionErrorType(err?.response?.data?.type);
            setConsultSubmissionErrorMessage(err?.response?.data?.description);
            const pageToMoveTo = err?.response?.data?.page;
            if (pageToMoveTo) {
              moveForward(
                props.outline.pages.findIndex((_page) => _page.key === pageToMoveTo),
                values,
              );
            }
          }
        })
        .finally(() => {
          // clear used coupon code
          setCouponCode(undefined);
          setSubmittingConsult(false);
        });
    } else {
      setIsLoadingReEntryPage(false);
      const nextPage = props.outline.pages[nextPageIdx];
      const answers = await dispatch.consults.getFormAnswers();
      const shownFields = nextPage.fields.filter((field) => {
        if (field.type && field.type === "accordion") {
          return false;
        }
        return getFieldCanBeShown(
          answers,
          field,
          consult,
          props.profile,
          membership,
          hasWesternDentalBenefits,
          userRequiresSyncVisit,
        );
      });
      if (!shownFields.length) {
        await moveForward(nextPageIdx + 1, values);
      } else {
        trackNext();
        setCurrentPage(nextPage);
        setPage(nextPageIdx);
        setUpdatingPage(false);
        const { condition } = props.outline;
        await PageHandlers.pageOnBefore(condition, nextPage, values, consult.consult_type);
      }
    }
  };

  const onWaitForPCP = (value: boolean) => {
    ProviderCoverageService.setPatientWaitForPcp({
      userId: "me",
      entityId: `${consult.id}`,
      entityType: "consult",
      requestBody: { value },
    }).finally(() => {
      setWaitForPCPOpen(false);
      finishConsult();
    });
  };

  const goToPage = async (pageKey: string, values: any) => {
    const pageIdx = props.outline.pages.findIndex((_page) => _page.key === pageKey);
    await moveForward(pageIdx, values);
  };

  const moveBackward = async (prevPageIdx: number = page - 1) => {
    const prevPage = props.outline.pages[prevPageIdx];
    const answers = await dispatch.consults.getFormAnswers();
    const shownFields = prevPage.fields.filter((field: DynamicField) => {
      if (field.type && field.type === "accordion") {
        return false;
      }
      return getFieldCanBeShown(
        answers,
        field,
        consult,
        props.profile,
        membership,
        hasWesternDentalBenefits,
        userRequiresSyncVisit,
      );
    });
    if (!shownFields.length) {
      moveBackward(prevPageIdx - 1);
    } else {
      setPage(prevPageIdx);
      setCurrentPage(prevPage);
      setUpdatingPage(false);
    }
  };

  const nextClick = async (values: any) => {
    if (isLoadingReEntryPage && isAutoSubmit) {
      setIsLoadingReEntryPage(false);
      isAutoSubmit = false;
      return;
    }

    Analytics.trackContentInteraction("click", {
      name: "VisitPage",
      piece: currentPage?.key || "null",
      target: "Next",
    });

    if (values) {
      setResponseValidationError(undefined);
      try {
        const { condition } = props.outline;
        await PageHandlers.pageBeforeSubmit(condition, currentPage, values, consult.consult_type);
        setUpdatingPage(true);

        await PageHandlers.pageOnSubmit(condition, currentPage, values, consult.consult_type);

        // some nextClicks are sending events
        if (!values.target) {
          if (values.address && !values.address2) {
            values.address2 = "";
          }
          await saveValues(values);
        }
        Analytics.trackEvent({ category: "Visit", action: "PageSubmit", name: currentPage?.key });
        await moveForward(page + 1, values);
        await PageHandlers.pageOnAfter(condition, currentPage, values, consult.consult_type);
      } catch (err) {
        if (isInvalidType(err) && err.invalid) {
          setInvalid(err);
          setInvalidValues(values);
          setUpdatingPage(false);
        } else if (isAxiosError(err)) {
          const res = err.response?.data;
          if (isConsultResponsesValidationError(res)) {
            if (res.validator_code === "StateEnabled") {
              window.location.href = `/state-ineligible/notify-me/${res.header}`;
            } else if (res.validator_code === "StateRestricted") {
              window.location.href = `/visit-unavailable/notify-me/${res.header}`;
            } else {
              setResponseValidationError(res);
              setAnswerValues(values);
              setUpdatingPage(false);
            }
          }
        }
      }
    }
  };

  const prevClick = React.useCallback(() => {
    if (page > 0) {
      moveBackward();
    }
  }, [page]);
  const topBarProps = useTopBar({
    showBackButton: page > 0,
    withLeftHandNav: false,
    onBackCallback: prevClick,
  });
  const isProcessing = !currentPage || submittingConsult || !mounted;
  const loadingMessage = submittingConsult ? "Submitting your visit" : "Preparing your visit";
  const isExternalPharmacy = _.toLower(consult?.answers?.preferred_pharmacy?.value) !== "alpha";
  const selectedPharmacyId = consult?.answers?.pharmacy_selected?.value;
  const addresses = useStoreState((state) => state.addresses.addresses);

  const isScreeningTransitionScreen =
    currentPage && isSpecificTransitionScreen(currentPage, "Screening");

  const isSectionIntroTransitionScreen =
    currentPage && isSpecificTransitionScreen(currentPage, "Section-Intro");

  React.useEffect(() => {
    if (selectedPharmacyId && isEmpty(addresses)) {
      (async () => {
        await dispatch.addresses.fetchAddresses({
          id: selectedPharmacyId,
        });
      })();
    }
  }, [selectedPharmacyId]);

  React.useEffect(() => {
    if (currentPage) {
      Analytics.trackContentImpression({
        name: "VisitPage",
        piece: currentPage.key,
        target: "Next",
      });
    }
  }, [currentPage?.key]);

  const showLoading = updatingPage && !consultSubmissionErrorType;

  // This is a temporary fix to get the source of the consult if it's not available in the consult object
  // Remove this when the consult initialization refactor is done
  const [consultSource, setConsultSource] = React.useState<string | undefined>(undefined);
  React.useEffect(() => {
    const getSourceAsync = async () => {
      if (consultSubmitted && props.consult.source === undefined) {
        const currentConsult = await ConsultsService.getConsult({
          consultId: props.consult.id.toString(),
          userId: "me",
        });
        setConsultSource(currentConsult.source);
      }
    };
    getSourceAsync();
  }, [consultSubmitted]);

  return (
    <Loader screen={!currentPage} show={isProcessing} message={loadingMessage}>
      {waitForPCPData && (
        <WaitForPCPPopup
          type="consult"
          providerName={waitForPCPData.provider_name}
          returnDate={waitForPCPData.return_date}
          open={waitForPCPOpen}
          onCancel={() => onWaitForPCP(false)}
          onWait={() => onWaitForPCP(true)}
          onAutoClose={() => {
            setWaitForPCPOpen(false);
            finishConsult();
          }}
        />
      )}
      {(consultSubmitted &&
        consult.subscription.condition.key === "nutrition" &&
        consult.consult_type === "INITIAL_CONSULT" && (
          <Complete
            title="Your interview is complete."
            conditionKey={props.outline.condition}
            consult={consult}
            isSynchronous={isSynchronous}
          >
            <>
              <div className="pb-6">
                <CompletionSteps
                  cxImageHeight="h-auto"
                  steps={[
                    {
                      imgSrc: "/assets/stewardess.svg",
                      description: isSynchronous
                        ? "Your Alpha Provider will review your information and discuss treatment options during your call."
                        : `Your Registered Dietitian will review your case within ${config.responseTime}.`,
                    },
                    {
                      imgSrc: "/assets/nutrition-icon.svg",
                      description:
                        "You'll then receive diet & nutrition recommendations tailored to your needs.",
                    },
                  ]}
                />
              </div>
              <div>
                <h4 className="text-xs uppercase font-semibold text-grey-dark pb-6">
                  While you wait
                </h4>
                <CompletionSteps
                  cxImageHeight="h-auto"
                  steps={[
                    {
                      imgSrc: "/assets/writing-icon.svg",
                      description: (
                        <>
                          <p className="pb-3">We highly recommend keeping a food diary.</p>
                          <a
                            className="font-bold text-sky-dark"
                            target="_blank"
                            href="/assets/nutrition_care_manuals/food_diary.pdf"
                          >
                            Learn more <span className="ml-2 fa fa-arrow-right" />
                          </a>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            </>
          </Complete>
        )) ||
        (consultSubmitted &&
          consult.subscription.condition.key === "mental-health" &&
          consult.consult_type === "INITIAL_CONSULT" && (
            <CompletionPage
              firstName={props.profile.pii && props.profile.pii.first_name}
              info={visitSubmittedText}
            >
              <div>
                <h4 className="text-sm font-semibold text-grey-dark pb-6 uppercase">
                  {isSynchronous ? "What happens next:" : "Here's what's next"}
                </h4>
                <CompletionSteps
                  steps={[
                    {
                      imgSrc: "/assets/doctor-icon.svg",
                      title: "Your Alpha Provider is working on your treatment plan.",
                      description: `Your responses are in review, and we'll contact you within ${config.responseTime}.`,
                    },
                    {
                      imgSrc: "/assets/rx-icon.svg",
                      title: "You’ll receive your first phase of treatment.",
                      description:
                        "Medications (if prescribed) will be shipped to you or available for pickup at your local pharmacy. Talk therapy or self-care may also be recommended.",
                    },
                    {
                      imgSrc: "/assets/alert-icon.svg",
                      title: "<span class='text-violator'>Complete your return visits.</span>",
                      description:
                        "In the upcoming weeks, we'll ask you to complete a few return visits online (no additional fees).  This will help us measure your progress and adjust your treatment plan. <b>Any future refills will depend on your completion of these return visits.</b>",
                    },
                  ]}
                />
              </div>
            </CompletionPage>
          )) ||
        (consultSubmitted &&
          consult.subscription.condition.key === "diabetes" &&
          consult.consult_type === "INITIAL_CONSULT" && (
            <DiabetesCompletionPage
              firstName={props.profile.pii && props.profile.pii.first_name}
              info={visitSubmittedText}
              consult={consult}
              profile={props.profile}
              isExternalPharmacy={isExternalPharmacy}
              selectedPharmacyId={selectedPharmacyId}
              addresses={addresses}
            />
          )) ||
        (consultSubmitted && consult.subscription.condition.is_pediatric && (
          <PediatricsAfterVisit />
        )) ||
        (consultSubmitted && (
          <Complete
            resetMembership={true}
            title={visitSubmittedText}
            conditionKey={props.outline.condition}
            consult={consult}
            isSynchronous={isSynchronous}
          >
            <CompletionSteps
              steps={getDefaultSteps(
                isExternalPharmacy,
                consult.subscription.condition.name,
                isSynchronous,
                isExternalPharmacy
                  ? _.find(addresses, (address) => address.id === selectedPharmacyId)
                  : props.profile.pii.address,
                props.consult.source || consultSource,
              )}
            />
          </Complete>
        )) || (
          <Div100vh
            className={classNames(isScreeningTransitionScreen ? "bg-cloud-light-20" : "bg-white")}
          >
            <ConsultContext.Provider value={consult}>
              <FormNavContext.Provider value={{ nextClick, goToPage }}>
                {invalid && (
                  <EligibilityForm
                    invalid={invalid}
                    consult={consult}
                    email={props.profile.email}
                    condition={props.outline.condition}
                    values={invalidValues}
                    saveValues={saveValues}
                    moveForward={moveForward}
                    onClose={() => {
                      setInvalid(undefined);
                    }}
                  />
                )}
                {showPopUp && page === 0 && (
                  <OutlineInfoPopupContainer
                    showPopUp={showPopUp}
                    condition={props.outline.condition}
                    consult={props.consult}
                  />
                )}
                <div className="flex flex-col h-full relative">
                  <div className="flex flex-1 overflow-auto">
                    <div id="page-container" className="flex-1 pb-8 overflow-y-auto w-full">
                      <TopBarNavigationPdb {...topBarProps} />
                      <ProgressBar
                        progress={calculateProgress(props.outline, currentPage?.key || "", 6)}
                        segmented
                        withTopBar
                      />
                      <div
                        className={classNames(!isSectionIntroTransitionScreen && "px-6", {
                          "pt-3": showLoading,
                        })}
                      >
                        <Loader message="Updating your visit" show={showLoading}>
                          <div className="max-w-2xl m-auto h-full">
                            <Page
                              nextClick={nextClick}
                              prevClick={prevClick}
                              goToPage={goToPage}
                              pageIdx={page}
                              page={currentPage}
                              fields={currentPage?.fields || []}
                              values={answerValues}
                              condition={props.outline.condition}
                              errorType={consultSubmissionErrorType}
                              errorMessage={consultSubmissionErrorMessage}
                              saveValues={saveValues}
                              consult={consult}
                              profile={props.profile}
                              isLastPage={props.outline.pages.length - 1 === page}
                              validationError={responseValidationError}
                              clearValidationError={() => setResponseValidationError(undefined)}
                              setIsAutoSubmit={setIsAutoSubmit}
                              userRequiresSyncVisit={userRequiresSyncVisit}
                              prevAnswers={prevAnswers}
                            />
                          </div>
                        </Loader>
                      </div>
                    </div>
                  </div>
                </div>
              </FormNavContext.Provider>
            </ConsultContext.Provider>
          </Div100vh>
        )}
    </Loader>
  );
};
