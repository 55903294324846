/** @format */

import React, { forwardRef } from "react";

import type { BaseFieldProps } from "@alphamedical/components";

import { FieldErrorNew, FieldWrapper, TextFieldNew } from "@alphamedical/components";
// TODO Review this
// import "./../../../DesignSystemV1/Forms/Fields/TextFieldNew.css";

import type { FieldInputProps } from "react-final-form";

import DateFnsUtils from "@date-io/date-fns";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import classNames from "classnames";
import Cleave from "cleave.js/react";
import { addDays, format, isValid, parse, parseISO, subDays } from "date-fns";
import type { FieldValidator } from "final-form";

// Custom DatePicker styles, overwriting base styles.
import "./datepicker.scss";

export interface DatePickerProps extends BaseFieldProps {
  /** An optional icon to display. The component has a fallback icon if no icon is provided and this option is enabled. */
  icon?: React.ReactNode;
  /** Determines whether to show the icon */
  showIcon?: boolean;
  /** Custom error message for validation */
  validationErrorMessage?: string;
  /** A date string value that indicates the minimum date boundary. The date string format must follow the ISO 8601 date format YYYY-MM-DD. */
  minDate?: string;
  /** A date string value that indicates the maximum date boundary. The date string format must follow the ISO 8601 date format YYYY-MM-DD. */
  maxDate?: string;
  /**
   * Determines whether to include past dates in the date picker's options.
   * If true, past dates will be included.
   * Default: true
   */
  includePastDates?: boolean;
  /**
   * Determines whether to include future dates in the date picker's options.
   * If true, future dates will be included.
   * Default: true
   */
  includeFutureDates?: boolean;
  /**
   * Determines whether to include the current date in the date picker's options.
   * If true, the current date will be included.
   * Default: true
   */
  includeCurrentDate?: boolean;
}

const CustomDateInput = forwardRef<HTMLDivElement, any>(({ inputProps }, ref) => {
  const {
    validate,
    icon,
    showIcon,
    onClick,
    setSelectedValue,
    disabled,
    required,
    validationErrorMessage,
    label,
    minDate,
    maxDate,
    includePastDates,
    includeFutureDates,
    includeCurrentDate,
    ...otherProps
  } = inputProps;

  /**
   * Default date validation function
   * @param value - The input date value
   * @returns An error message if validation fails, otherwise undefined
   */
  const defaultDateValidation = (value: string) => {
    if (value == null) {
      return value;
    }

    const _date = "(0[1-9]|1[0-2])-(0[1-9]|[1-2]\\d|3[0-1])-(1\\d{3}|2\\d{3})";
    const parsedDate = parse(value, "MM-dd-yyyy", new Date());

    if (!isValid(parsedDate) || !value.match(_date)) {
      return validationErrorMessage || "Please enter date as mm-dd-yyyy.";
    }
    return undefined;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.currentTarget.value;
    const validationError = defaultDateValidation(inputValue);
    if (inputValue && !validationError) {
      setSelectedValue(new Date(inputValue));
    }
  };

  const cleaveOptions = {
    delimiter: "-",
    date: true,
    dateMin: minDate,
    dateMax: maxDate,
    datePattern: ["m", "d", "Y"],
  };

  return (
    <TextFieldNew
      className={"w-full"}
      validate={validate || (defaultDateValidation as FieldValidator<string | undefined>)}
      disabled={disabled}
      required={required}
      ref={ref}
      {...otherProps}
    >
      {({ input, meta }: { input: FieldInputProps<any>; meta: any }) => {
        const { error, touched } = meta;
        const showErrors = error && touched;

        return (
          <>
            {label && (
              <p
                className={classNames(
                  `text-sm mb-2 ${showErrors ? "text-error" : "text-forest-100"}`,
                )}
              >
                {label}
                {required && "*"}
              </p>
            )}
            <div className="flex relative">
              <Cleave
                {...input}
                placeholder={otherProps.placeholder || otherProps.label}
                autoComplete="off"
                options={cleaveOptions}
                onChange={(e) => {
                  handleInputChange(e);
                  input.onChange(e);
                }}
              />
              {showIcon && (
                <span className="absolute" style={{ top: 10, right: 2 }}>
                  {icon || <DefaultIcon onClick={onClick} disabled={disabled} />}
                </span>
              )}
            </div>
            {showErrors && <FieldErrorNew name={input.name} />}
          </>
        );
      }}
    </TextFieldNew>
  );
});

const DefaultIcon = ({ onClick, disabled }: any) => (
  <div className="flex flex-row items-center cursor-pointer" onClick={!disabled && onClick}>
    <div className="w-px h-8 bg-grey-160 mr-4"></div>
    <FontAwesomeIcon icon={faCalendar} className="text-grey-160 pr-4" />
  </div>
);

export const Datepicker = ({
  name,
  disabled,
  showIcon = true,
  minDate,
  maxDate,
  includeCurrentDate = true,
  includeFutureDates = true,
  includePastDates = true,
  ...fieldProps
}: DatePickerProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleClose = () => setIsOpen(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(new Date());

  const yesterday = format(subDays(new Date(), 1), "yyyy-MM-dd");
  const today = format(new Date(), "yyyy-MM-dd");
  const tomorrow = format(addDays(new Date(), 1), "yyyy-MM-dd");
  const defaultMinDate = minDate ?? "1900-01-01";
  const defaultMaxDate = maxDate ?? "2100-12-31";

  const innerMinDate = includePastDates ? defaultMinDate : includeCurrentDate ? today : tomorrow;
  const innerMaxDate = includeFutureDates ? defaultMaxDate : includeCurrentDate ? today : yesterday;

  const toggleOpen = (event: any) => {
    setIsOpen((currentOpen) => !currentOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      <FieldWrapper name={name} {...fieldProps}>
        {({ input }) => {
          const handleDateChange = (date: Date | null) => {
            setSelectedDate(date);
            if (date) {
              input.onChange(format(new Date(date), "MM-dd-yyyy"));
            }
          };

          return (
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  format="MM/dd/yyyy"
                  variant="inline"
                  id="date-picker-inline"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  disabled={disabled}
                  TextFieldComponent={CustomDateInput}
                  inputProps={{
                    disabled,
                    name,
                    showIcon,
                    minDate,
                    maxDate,
                    onClick: toggleOpen,
                    setSelectedValue: setSelectedDate,
                    ...fieldProps,
                  }}
                  open={isOpen}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  PopoverProps={{
                    anchorEl: anchorEl,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  }}
                  minDate={parseISO(innerMinDate)}
                  maxDate={parseISO(innerMaxDate)}
                />
              </MuiPickersUtilsProvider>
            </div>
          );
        }}
      </FieldWrapper>
    </>
  );
};
