/** @format */

import type { FormProps } from "react-final-form";
import type { RouteComponentProps } from "react-router-dom";

import React from "react";
import { withRouter } from "react-router-dom";

import _ from "lodash";

import { ProgressBar } from "src/v2/designSystem";

import type { DynamicField, DynamicFormPage } from "src/components/DynamicForm/types";
import type { PageFormProps } from "src/v2/components/Wizard/pages/types";
import type { FetchProfileResponse } from "src/v2/models/api_types";
import type { Outline } from "src/v2/models/outline";

import { calculateProgress } from "src/utils/formUtils";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { MonthlyMembershipPlan } from "src/v2/components";
import Div100vh from "src/v2/components/Div100vh";
import { TopBarNavigationPdb } from "src/v2/components/WithTopBarNavigation/TopBarNavigationPdb";
import {
  ConsentPageForm,
  EligibilityPage,
  GenericPage,
  PCPPage,
  PiiPage,
} from "src/v2/components/Wizard/pages";
import { MonthlyBillingInfoPage } from "src/v2/components/Wizard/pages/monthlyBillingInfoPage";
import { useCurrentUser } from "src/v2/models/profile";

interface OutlineFormProps extends RouteComponentProps<{ h: string }> {
  outline: Outline;
  initialValues?: object;
  onPageSubmit: (
    page: DynamicFormPage,
    changedValues: object,
    allValues: object,
    isLastPage: boolean,
  ) => Promise<any>;
}

const deepDiff = (obj1: object, obj2: object): object => {
  const _deepDiff = (object: any, base: any): object => {
    return _.transform(object, (result, value, key) => {
      if (!_.isEqual(value, base[key])) {
        result[key] =
          _.isObject(value) && _.isObject(base[key]) ? _deepDiff(value, base[key]) : value;
      }
    });
  };
  return _deepDiff(obj1, obj2);
};

const conditionalFieldList = [
  "dob",
  "sex",
  "preferred_firstname",
  "preferred_lastname",
  "preferred_pronouns",
  "prefix",
  "prefix-other",
  "first_name",
  "last_name",
];

// TODO: Remove check for !completedConsult once locked_fields has been backfilled.
export const fieldFilter = (field: DynamicField, user: FetchProfileResponse) => {
  const completedConsult = user.consults?.some((consult) => consult.completed_at);
  const lockedFields = user.pii.locked_fields || [];
  const lockedField = lockedFields.indexOf(field.key) !== -1;
  const skippableFields = conditionalFieldList.indexOf(field.key) > -1;
  if (skippableFields && completedConsult) {
    return false;
  }
  if (skippableFields && lockedField) {
    return false;
  }
  return true;
};

const OutlineForm = (props: OutlineFormProps) => {
  const topBarProps = useTopBar({ showBackButton: true, withLeftHandNav: false });

  const { pages } = props.outline;
  const [pageIdx, setPageIdx] = React.useState<number>(0);
  const [currentValues, setCurrentValues] = React.useState<object>(props.initialValues || {});
  const user = useCurrentUser();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const paymentPlan = urlParams.get("plan");
  const flow = urlParams.get("flow") || "";

  const visiblePages = pages.filter((page) => {
    const hasValidField = page.fields.some((field) => fieldFilter(field, user));

    // Skip "payment-plan" because we already have a new page being used in the registration flow as a replacement for this page.
    // also, when they come from Account/Membership & Payment nav option, we have a replacement for that page too
    const shouldWeSkipPaymentPlanPage = ["registration", "account"].includes(flow);
    const isPaymentPlanPage = page.key === "payment-plan";

    return hasValidField && !(shouldWeSkipPaymentPlanPage && isPaymentPlanPage);
  });

  const currentPage = visiblePages[pageIdx];

  const isLastPage = pageIdx === visiblePages.length - 1;

  const prev = () => {
    setPageIdx(Math.max(pageIdx - 1, 0));
  };

  const next = () => {
    setPageIdx(Math.min(pageIdx + 1, visiblePages.length - 1));
  };

  const validate = (values: object) => {
    return currentPage.fields.reduce((errors, field) => {
      // Note: x == null is equivalent to (x === null || x === undefined)
      if (values[field.key] == null && !!field.required && field.key !== "prefix-other") {
        errors[field.key] = "Required";
      }
      return errors;
    }, {});
  };

  const handleSubmit = (values: object) => {
    // As we skipped the first page where the plan is being set, if they come from the account setup flow, we need to add this prop.
    if (["registration", "account"].includes(flow)) values["payment-plan"] = paymentPlan;

    const changedValues = deepDiff(values, currentValues);

    return new Promise<object | undefined>((resolve) => {
      props
        .onPageSubmit(currentPage, changedValues, values, isLastPage)
        .then(() => {
          if (!isLastPage) {
            next();
          }
          setCurrentValues({ ...currentValues, ...values });
          resolve(undefined);
        })
        .catch((errors) => resolve(errors));
    });
  };

  const formProps: FormProps = {
    onSubmit: handleSubmit,
    initialValues: currentValues,
    validate,
  };

  const getPageComponent = (page: DynamicFormPage): ((props: PageFormProps) => JSX.Element) => {
    if (page && page.type === "consent") {
      return ConsentPageForm;
    }

    const currPage = {
      pii: PiiPage,
      eligibility: EligibilityPage,
      payment: MonthlyBillingInfoPage,
      "payment-plan": MonthlyMembershipPlan as (props: PageFormProps) => JSX.Element,
      pcp: PCPPage,
    }[page && page.key];

    return currPage || GenericPage;
  };

  const PageComponent = getPageComponent(currentPage);

  return (
    <Div100vh className="flex flex-col w-full">
      {/* Render the top top progress bar with the back button if we are past the first page */}
      <div className="flex flex-1 overflow-auto">
        <div className="w-full flex flex-col flex-1 pb-6 m-auto h-full overflow-auto items-center">
          <TopBarNavigationPdb {...topBarProps} />
          <ProgressBar
            progress={calculateProgress(props.outline, currentPage?.key || "", 6)}
            segmented
            withTopBar
          />
          <div className={"max-w-2xl w-full px-5 "}>
            {pageIdx > 0 && (
              <div
                className="text-sm cursor-pointer py-2 hover:text-primary-100 text-primary-80 hidden md:block"
                onClick={prev}
              >
                &lt; Back
              </div>
            )}
            <PageComponent form={formProps} page={currentPage} paymentPlan={paymentPlan} />
          </div>
        </div>
      </div>
    </Div100vh>
  );
};

export default withRouter(OutlineForm);
