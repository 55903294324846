/** @format */

import React from "react";

import { Button } from "src/v2/components/Button";

export interface ServicesPopupContentProps {
  popUpEligibility?: boolean;
  condition?: string;
  url?: string;
  onClick?: () => void;
  history?: any;
  bodyBackgroundColor?: string;
  foregroundColor?: string;
  heading?: string;
  titleList?: { [key: string]: string }[];
  subText?: string;
  bulletListHeader?: string;
  bulletList?: { [key: string]: string }[];
  btnText?: string;
  btnBgColor?: string;
}

const ServicesPopupContent = ({
  bulletListHeader,
  onClick,
  bodyBackgroundColor,
  heading,
  titleList,
  bulletList,
  subText,
  btnText,
  foregroundColor,
  btnBgColor,
}: ServicesPopupContentProps) => {
  return (
    <div>
      {/* body content */}
      <div className={`bg-${bodyBackgroundColor} py-4 px-6`}>
        {heading && <h2 className="text-2xl sm:text-xl font-semibold mb-4">{heading}</h2>}
        <div className={`${foregroundColor ? `border-l-4 pl-3  border-${foregroundColor}` : ""}`}>
          {titleList &&
            titleList.map((titleObj) => {
              return (
                <div key={titleObj.subHeader}>
                  <div className="text-2xl">{titleObj.subHeader}</div>
                  <div className="-mt-1 mb-2">{titleObj.text}</div>
                </div>
              );
            })}
          {subText && <p>{subText}</p>}
        </div>
      </div>
      {/* list */}
      <div className="p-6 bg-white">
        {bulletListHeader && <div className="mb-4">{bulletListHeader}</div>}
        <div className="mb-10">
          {bulletList &&
            bulletList.map((content: any, idx: number) => {
              return (
                <div className="flex items-start mb-4" key={`key-${content.text}-${idx}`}>
                  <div
                    className="border-2 border-primary bg-primary rounded-full mr-4 mt-2"
                    style={{ minWidth: "8px", minHeight: "8px" }}
                  />
                  <div>
                    {content.heading && <div className="font-bold">{content.heading}</div>}
                    {content.text && <p>{content.text}</p>}
                  </div>
                </div>
              );
            })}
        </div>
        {btnText && (
          <Button bgColor={btnBgColor || "primary"} onClick={onClick}>
            {btnText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ServicesPopupContent;
