/** @format */

import React from "react";

import { size } from "lodash";

import type { ConditionSchema, PiiSchema } from "src/api";
import type { IntakeProps } from "src/v2/routes/Intake";

import { ServicesService } from "src/api";
import { Loader } from "src/components/Loader";
import { PageWrapper } from "src/components/PageWrapper";
import { useAuthenticatedUser } from "src/utils/hooks";

import { AddOrSelectRelationship } from "./AddOrSelectRelationship";
import { ParentPIIForm } from "./ParentPIIForm";

export const checkParentPii = (pii?: PiiSchema) => {
  return Boolean(pii && pii.first_name && pii.last_name && pii.dob && pii.sex);
};

export const VisitRouter: React.FC<IntakeProps> = ({ match, getOutlineKey, children }) => {
  const { params } = match;
  const outlineKey = (getOutlineKey && getOutlineKey(params.outline)) || params.outline;
  const [condition, setCondition] = React.useState<ConditionSchema>();
  const isPediatric = Boolean(condition && condition.is_pediatric);
  const [hasParentPII, setHasParentPII] = React.useState(false);
  const [authenticatedUser, isAuthenticatedUser] = useAuthenticatedUser();

  React.useEffect(() => {
    ServicesService.getCondition({ conditionKey: outlineKey }).then((schema) => {
      setCondition(schema);
    });
  }, []);

  React.useEffect(() => {
    setHasParentPII(checkParentPii(authenticatedUser.pii));
  }, [size(authenticatedUser.pii)]);

  const setParentPII = (res: PiiSchema) => {
    setHasParentPII(checkParentPii(res));
  };

  if (!condition) return <Loader message={`Preparing your visit`} show={true} />;
  if (!isPediatric && isAuthenticatedUser) return <>{children}</>;
  if (!hasParentPII)
    return (
      <PageWrapper hideNav={true} cxPadding="pb-3">
        <div className="max-w-xl mx-auto bg-white px-6 py-12 min-h-screen">
          <ParentPIIForm callback={setParentPII} />
        </div>
      </PageWrapper>
    );
  if (isAuthenticatedUser)
    return (
      <PageWrapper hideNav={true} cxPadding="pb-3">
        <div className="max-w-xl mx-auto bg-white px-6 py-12 min-h-screen">
          <AddOrSelectRelationship />
        </div>
      </PageWrapper>
    );
  return <>{children}</>;
};
