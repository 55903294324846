/** @format */

import React from "react";

import { COLORS } from "src/v2/utils/colors";

interface CloseIconProps {
  onClick?: () => void;
  width?: number;
  fill?: string;
}

export const CloseIcon = ({ fill = COLORS["grey-160"], width = 12, onClick }: CloseIconProps) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div style={{ width: `${width}px` }}>
      <svg onClick={handleClick} width={width} height={width} viewBox="0 0 13 12" fill="none">
        <path
          d="M6.00092 4.38123L1.95385 0.33536C1.50734 -0.111787 0.782506 -0.111787 0.33536 0.33536C-0.111787 0.781309 -0.111787 1.5067 0.33536 1.95385L4.38243 5.99972L0.33536 10.0468C-0.111787 10.4927 -0.111787 11.2181 0.33536 11.6646C0.782506 12.1118 1.50726 12.1118 1.95385 11.6646L6.00092 7.61813L10.048 11.6646C10.4945 12.1118 11.2193 12.1118 11.6665 11.6646C12.113 11.2181 12.113 10.4927 11.6665 10.0468L7.61941 5.99972L11.6665 1.95385C12.113 1.5067 12.113 0.781309 11.6665 0.33536C11.2193 -0.111787 10.4946 -0.111787 10.048 0.33536L6.00092 4.38123Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};
