/** @format */

import React from "react";
import { useHistory } from "react-router-dom";

import { Analytics } from "src/analytics";
import { ContentfulService } from "src/api/services/ContentfulService";
import { Loader } from "src/components/Loader";
import {
  primaryMembershipCardStyles,
  secondaryMembershipCardStyles,
} from "src/routes/Membership/MembershipOptions";
import {
  MONTHLY_MEMBERSHIP_FEE_IN_CENTS,
  MONTHLY_MEMBERSHIP_PLUS_FEE_IN_CENTS,
} from "src/v2/constants";
import { useStoreActions } from "src/v2/models";

import type { PlanComparisonContent, PlanSection } from "./PlanComparisonPage";

import { PlanComparisonPage } from "./PlanComparisonPage";

export const MembershipOptionsNotAuth = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [pageContent, setPageContent] = React.useState<PlanComparisonContent>();
  const history = useHistory();
  const { setShowLogin } = useStoreActions((actions) => actions.auth);

  const handleOnSubmit = (plan: string) => {
    setShowLogin(false);
    // emit piwik event for NotAuth click on plan
    Analytics.trackContentInteraction("click", {
      name: "MembershipOptions",
      piece: "pre-auth",
      target: plan,
    });

    history.push(`/membership-signup?plan=${plan}&flow=account`);
  };

  React.useEffect(() => {
    const fetchContentAsync = async () => {
      const { content } = await ContentfulService.getUnstructuredContentByKey({
        key: "plan-comparison-page",
      });
      if (content) {
        setPageContent(content);
        setIsLoading(false);
      } else history.push("/my-cases");
    };
    fetchContentAsync();
  }, []);

  const variantProps = {
    showExtraSections: true,
    showAlphaLogo: true,
    styles: {
      cardsBackground: "bg-sand-20",
      titleAlignment: "center",
      plus: primaryMembershipCardStyles,
      alpha: secondaryMembershipCardStyles,
    },
  };

  const cardsContent: PlanSection = pageContent?.plansSection["d2c"];
  if (cardsContent) {
    const currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    cardsContent.plans.forEach((plan) => {
      const costInCents =
        plan.id == "alpha" ? MONTHLY_MEMBERSHIP_FEE_IN_CENTS : MONTHLY_MEMBERSHIP_PLUS_FEE_IN_CENTS;
      const priceString = `$${costInCents / 100}`;
      const pricePerDayCost = currencyFormatter.format(costInCents / 30 / 100);
      plan.pricePerDay = [pricePerDayCost, "/ day"];
      plan.pricePerMonth = `${priceString} / month`;
    });
  }

  return isLoading || !pageContent ? (
    <Loader show={true} />
  ) : (
    <>
      <PlanComparisonPage
        pageContent={pageContent}
        cardsContent={cardsContent}
        handleOnClick={handleOnSubmit}
        plansOffered={["alpha", "plus"]}
        variantProps={variantProps}
      />
    </>
  );
};
