/** @format */

import type React from "react";

import { Pill } from "@alphamedical/components";

interface PdbPillProps {
  type: PdbPillType;
}

export type PdbPillType = "error" | "warning" | "disabled" | "default" | "success";

export const PdbPill: React.FC<PdbPillProps> = ({ type, children }) => {
  const styles = {
    error: {
      bgColor: "error",
      textColor: "white",
    },
    warning: {
      bgColor: "warning",
      textColor: "forest-darker",
    },
    disabled: {
      bgColor: "grey-160",
      textColor: "white",
    },
    default: {
      bgColor: "sand-dark",
      textColor: "forest-darker",
    },
    success: {
      bgColor: "success",
      textColor: "black",
    },
  };

  return (
    <Pill
      cxPadding={"p-1"}
      bgColor={styles[type].bgColor}
      textColor={styles[type].textColor}
      cxFontSize={"text-xs font-medium"}
    >
      {children}
    </Pill>
  );
};
