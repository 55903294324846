/** @format */

import React from "react";

import * as Sentry from "@sentry/react";

import { SelectField, SimpleForm, TextField } from "@alphamedical/components";

import type {
  BadAddressDetails,
  MedicationOrderAutomationsBadAddressAutomationRequestBodySchema,
} from "src/api";

import { OrdersService } from "src/api";
import BackButton from "src/components/_buttons/BackButton";
import { DynamicField } from "src/components/DynamicForm";
import { Button } from "src/v2/components/Button";
import { STATES } from "src/v2/constants";
import { useStoreDispatch } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";

interface UpdateShippingAddressForm {
  address: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
}

interface UpdateShippingAddressProps {
  item: BadAddressDetails;
  setModalContent: (content: string) => void;
  setShowPopup: (value: boolean) => void;
}

const UpdateShippingAddress = ({
  item,
  setModalContent,
  setShowPopup,
}: UpdateShippingAddressProps) => {
  const { id } = useCurrentUser();
  const dispatch = useStoreDispatch();
  const [disabled, setDisabled] = React.useState(false);
  const submit = (values: UpdateShippingAddressForm) => {
    setDisabled(true);
    const requestBody: MedicationOrderAutomationsBadAddressAutomationRequestBodySchema = {
      action: "update",
      address: values.address,
      city: values.city,
      state: values.state,
      zipcode: values.zipcode,
    };

    if (values.address2) {
      requestBody["address2"] = values.address2;
    }

    OrdersService.badAddressAutomation({
      userId: id,
      orderId: item.order_id.toString(),
      requestBody,
    })
      .then((res) => {
        setShowPopup(false);
        dispatch.profile.setHasSeenPopup(true);
        setDisabled(false);
      })
      .catch((err) => {
        Sentry.captureException(err);
        setShowPopup(false);
        dispatch.profile.setHasSeenPopup(true);
        setDisabled(false);
      });
  };
  return (
    <div>
      <BackButton onClick={() => setModalContent("message")} />
      <h1 className="my-4 text-xl">Update Shipping Address</h1>
      <SimpleForm<UpdateShippingAddressForm>
        onSubmit={submit}
        initialValues={{
          address: item.address,
          address2: item.address2,
          city: item.city,
          state: item.state,
          zipcode: item.zipcode,
        }}
        hideDebugInfo={true}
      >
        {({ dirty, submitting }) => {
          return (
            <>
              <TextField required={true} name="address" placeholder="Address" />
              <TextField name="address2" placeholder="Address 2" />
              <TextField required={true} name="city" placeholder="City" />
              <SelectField
                required={true}
                name="state"
                options={STATES.map((state) => ({ label: state, value: state }))}
              />
              <DynamicField
                field={{ key: "zipcode", required: true, type: "text", subtype: "zipcode" }}
              />
              <Button disabled={!dirty || disabled}>Submit</Button>
            </>
          );
        }}
      </SimpleForm>
    </div>
  );
};

export default UpdateShippingAddress;
