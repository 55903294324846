/** @format */

import React from "react";
import { useHistory } from "react-router-dom";

import { useWindowSize } from "@alphamedical/components";

import { ChannelLoader } from "src/components/Chat/ChannelLoader";
import { SnackBar } from "src/v2/components";
import { Nav } from "src/v2/components/Nav";
import { useStoreDispatch, useStoreState } from "src/v2/models";

interface Props {
  showIntercom?: boolean;
  title?: string;
  bgColor?: string;
  cxMaxWidth?: string;
  fitToPageHeight?: boolean;
  children: React.ReactNode;
  includeMembershipPromo?: boolean;
  showHighVolumeBanner?: boolean;
  cxPadding?: string;
  hideNav?: boolean;
}

export const pageHasNavBarContext = React.createContext(false);

const PageWrapper = (props: Props) => {
  const dispatch = useStoreDispatch();
  const history = useHistory();
  const membership = useStoreState((state) => state.membership.membership);
  const [width] = useWindowSize();

  React.useEffect(() => {
    if (!membership) {
      dispatch.membership.fetchMembership();
      dispatch.alerts.fetchMembershipAlerts();
    }
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [history?.location?.pathname]);

  return (
    <ChannelLoader>
      <div
        className={`
            ${props.fitToPageHeight ? "h-screen flex flex-col" : "min-h-full"} ${props.bgColor || "bg-white relative"}
            `}
      >
        {!props.hideNav && <Nav />}
        {!!props.showHighVolumeBanner && (
          <div className="fixed bottom-0 inset-x-0 bg-primary text-white text-center leading-tight px-6 py-4">
            <div className="max-w-xl mx-auto mb-20 sm:mb-0">
              We are experiencing a higher number of messages so our response time may be delayed{" "}
              <strong>for support questions not related to health</strong>. We will try to respond
              as soon as possible and appreciate your patience.
            </div>
          </div>
        )}
        <div className={"flex"}>
          {width >= 640 && <div className={"w-64 flex-shrink-0 h-full"}></div>}
          <main
            role="main"
            id="main"
            className={`mx-auto ${props.cxMaxWidth || "max-w-5xl"} ${props.cxPadding || "px-3"} flex-1
              ${props.fitToPageHeight ? "flex-1 flex flex-col" : ""} ${width <= 640 ? "pb-20" : "pb-3"}
              `}
            style={{
              width: "100%",
              overflow: "auto",
            }}
          >
            {props.title && (
              <h1 className="text-2xl sm:text-4xl pt-12 mb-3 text-center font-normal">
                {props.title}
              </h1>
            )}
            <pageHasNavBarContext.Provider value={true}>
              {props.children}
            </pageHasNavBarContext.Provider>
          </main>
        </div>

        <SnackBar />
      </div>
    </ChannelLoader>
  );
};

export { PageWrapper };
