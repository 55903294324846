/** @format */
import React from "react";
import { generatePath, Link } from "react-router-dom";

import { Typography } from "@alphamedical/components";

import type { Content } from "src/api";

import { Analytics } from "src/analytics";
import { HealthActionPlanEmptyStateSection } from "src/components/HealthActionPlan/HealthActionPlanPage";
import * as PDB_ROUTES from "src/routes";
import { useStoreDispatch } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";

import HealthActionPlanUnreadIndicator from "./HealthActionPlanUnreadIndicator";

interface HealthActionPlanCareGuideSectionProps {
  sectionTitle: string;
  content: Content | undefined;
}

interface HealthActionPlanCareGuideItemProps {
  title?: string;
  slug?: string;
  data: Content;
}

const HealthActionPlanCareGuideItem = ({
  title,
  slug,
  data,
}: HealthActionPlanCareGuideItemProps) => {
  const dispatch = useStoreDispatch();
  const currentUser = useCurrentUser();
  return (
    <>
      <span className="flex justify-between items-center">
        <Typography variant="h4">{title}</Typography>
        {!data.first_read_at && <HealthActionPlanUnreadIndicator />}
      </span>
      <Link
        to={generatePath(PDB_ROUTES.CARE_GUIDES, { guide: slug })}
        className={"flex items-center text-cornflower-100 font-medium text-sm mt-2"}
        onClick={() => {
          if (!data.first_read_at)
            dispatch.healthActionPlan.markItemAsRead({
              userId: currentUser.id,
              healthActionPlanId: data.node_id,
            });
          dispatch.healthActionPlan.setCareGuide(data);
          Analytics.trackContentInteraction("click", {
            name: "CareGuide",
            piece: title || "null",
            target: slug || "null",
          });
        }}
      >
        Open guide
      </Link>
    </>
  );
};

export const HealthActionPlanCareGuideSection = ({
  sectionTitle,
  content,
}: HealthActionPlanCareGuideSectionProps) => {
  const [showAllGuides, setShowAllGuides] = React.useState(false);

  const getGuideSlug = (title: string) => {
    return title.replace(/ /g, "-").toLowerCase();
  };

  return (
    <div
      key={sectionTitle.replace(" ", "")}
      className="flex flex-col items-start bg-white p-6 shadow mb-6"
    >
      <Typography variant="h3" className="mb-4">
        {sectionTitle}
      </Typography>
      <div className="flex flex-col w-full">
        {content
          ? content.subSection
              ?.slice(0, showAllGuides ? undefined : 3)
              .map((guide, index: number) => (
                <div key={index} className="bg-grey-10 rounded-sm p-4 mb-3">
                  {(index < 3 || showAllGuides) && (
                    <HealthActionPlanCareGuideItem
                      title={guide.group_section_title?.title}
                      slug={getGuideSlug(guide.userFriendlyName)}
                      data={guide}
                    />
                  )}
                </div>
              ))
          : HealthActionPlanEmptyStateSection("CARE_GUIDE")}
      </div>
      {content?.subSection && content.subSection.length > 3 && (
        <p
          onClick={() => setShowAllGuides(!showAllGuides)}
          className="text-cornflower-100 bg-transparent text-center font-medium"
        >
          {showAllGuides ? "Show less" : `+ ${content.subSection?.length - 3} more`}
        </p>
      )}
    </div>
  );
};
