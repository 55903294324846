/** @format */

import React from "react";

import { Intro } from "src/components/DynamicForm/Fields/CustomFields/WelcomeFlow/WelcomeIntro/Intro";
import { WelcomePageContainer } from "src/components/DynamicForm/Fields/CustomFields/WelcomeFlow/WelcomePageCotainer/WelcomePageContainer";
import welcome from "src/content/welcome";

export const WelcomeIntro = () => {
  return (
    <WelcomePageContainer
      submitButtonTitle={welcome.screen1.submitButton}
      pageName="welcome-intro"
      backgroundColorClass="bg-sand-20"
    >
      <Intro />
    </WelcomePageContainer>
  );
};
