/** @format */

import React from "react";

import { CTACard } from "src/components/Cards/CTACard";
import config from "src/config";

interface FeaturePromotionsProps {
  showPromotions: boolean;
}

const FeaturePromotions = ({ showPromotions }: FeaturePromotionsProps) => {
  const promotions = [];
  if (config.featureFlags.wellnessWorkshop) {
    promotions.push(
      <CTACard
        title="Wellness Workshops"
        description="Choose from a variety of virtual workshops led by trained professionals and coaches."
        img="/assets/group-coaching-icon.svg"
        cta={{
          href: "/workshops/signup",
          children: "Sign up for $30",
        }}
      />,
    );
  }
  return (
    <>
      {showPromotions && promotions.length > 0 && (
        <>
          <header className="uppercase mb-4 font-bold text-forest-60 text-sm">
            You may also be interested in
          </header>
          {promotions}
        </>
      )}
    </>
  );
};

export default FeaturePromotions;
