/** @format */

import _ from "lodash";

import type { InvalidType } from "src/v2/utils/page/validators";

export const INVALID_TYPE_VISIT = "visit";
export const INVALID_TYPE_HARD_STOP = "hard-stop";

interface ColdAndFluValues {
  "conditions-pregnancy": string[];
  "existing-conditions": string[];
}

export const isValidColdAndFluVisit = (values: ColdAndFluValues): InvalidType => {
  const isPregnant = (values["conditions-pregnancy"] || []).indexOf("i-am-pregnant") !== -1;
  const conditionsBlacklist = [
    "heart-disease",
    "lung-disease",
    "kidney-disease",
    "neuromuscular-disease",
    "immunosuppression",
    "other",
    56265001,
    19829001,
    90708001,
    257277002,
    86553008,
  ];
  const hasExistingConditions =
    _.intersection(values["existing-conditions"] || [], conditionsBlacklist).length > 0;
  return {
    invalid: isPregnant || hasExistingConditions,
    invalidType: INVALID_TYPE_VISIT,
  };
};

interface CystitisValues {
  "vaginal-discharge": string;
  "conditions-pregnancy": string[];
  "conditions-medical": string[];
  "conditions-pain": string[];
}

export const isValidCystitisVisit = (values: CystitisValues): InvalidType => {
  const conditionsBlacklist = [
    "diabetes",
    "kidney-infection",
    "kidney-stones",
    "bladder-infection",
    "immunosuppressed",
    "urinary-structural-abnormality",
    "recent-surgery",
    "pain",
    "fever",
    "chills",
    405751000,
    129128006,
    95570007,
    38822007,
    86553008,
    41368006,
  ];
  const hasDischarge = values["vaginal-discharge"] === "yes";
  const isPregnant = (values["conditions-pregnancy"] || []).indexOf("pregnant") !== -1;
  const hasExistingConditions =
    _.intersection(values["conditions-medical"] || [], conditionsBlacklist).length > 0 ||
    _.intersection(values["conditions-pain"] || [], conditionsBlacklist).length > 0;
  return {
    invalid: hasDischarge || isPregnant || hasExistingConditions,
    invalidType: INVALID_TYPE_VISIT,
  };
};

interface VulvovaginitisValues {
  "conditions-pregnancy": string[];
  "current-symptoms": string[];
}

export const isValidVulvovaginitisVisit = (values: VulvovaginitisValues): InvalidType => {
  const isPregnant = (values["conditions-pregnancy"] || []).indexOf("pregnant") !== -1;
  const symptomBlacklist = ["fever", "nausea-vomiting", "pelvic-pain"];
  const hasSymptoms = _.intersection(values["current-symptoms"] || [], symptomBlacklist).length > 0;
  return {
    invalid: isPregnant || hasSymptoms,
    invalidType: INVALID_TYPE_VISIT,
  };
};

interface SkinAndHairValues {
  "existing-skin-issues": string[];
}

export const isValidSkinAndHairVisit = (values: SkinAndHairValues): InvalidType => {
  const conditionBlacklist = [
    "generalized-rash",
    "raised-scars",
    "fever",
    "moles",
    "lesions-in-hair-bearing-areas",
  ];
  const hasSkinIssues =
    _.intersection(values["existing-skin-issues"] || [], conditionBlacklist).length > 0;
  return {
    invalid: hasSkinIssues,
    invalidType: INVALID_TYPE_VISIT,
  };
};

interface ConditionPregnancyValues {
  "conditions-pregnancy"?: string[];
  "conditions-pregnancy-hyperlipidemia"?: string[];
}

export const isPatientPregnant = (values: ConditionPregnancyValues) => {
  const isPregnant = (values["conditions-pregnancy"] || []).indexOf("i-am-pregnant") !== -1;
  return {
    invalid: isPregnant,
    invalidType: INVALID_TYPE_VISIT,
  };
};

export const isPatientPregnantHyperlipidemia = (values: ConditionPregnancyValues) => {
  const hyperlipidemiaIsPregnant =
    (values["conditions-pregnancy-hyperlipidemia"] || []).indexOf("i-am-pregnant") !== -1;
  const hyperlipidemiaTryingToGetPregnant =
    (values["conditions-pregnancy-hyperlipidemia"] || []).indexOf("i-am-trying-to-get-pregnant") !==
    -1;

  return {
    invalid: hyperlipidemiaIsPregnant || hyperlipidemiaTryingToGetPregnant,
    invalidType: INVALID_TYPE_HARD_STOP,
  };
};

export const isPatientPregnantHairloss = (values: ConditionPregnancyValues) => {
  const responses = values["conditions-pregnancy"] || [];
  const invalid =
    _.intersection(responses, [
      "i-am-pregnant",
      "i-am-trying-to-get-pregnant",
      "i-have-had-unprotected-intercourse-since-my-last-menstrual-period",
    ]).length > 0;
  return {
    invalid,
    invalidType: INVALID_TYPE_HARD_STOP,
  };
};

interface PastMedicationConditionValues {
  "conditions-medical": string[];
}

export const hasPastMedicalConditions = (values: PastMedicationConditionValues) => {
  const hasExistingConditions = (values["conditions-medical"] || []).indexOf("!none") === -1;
  return {
    invalid: hasExistingConditions,
    invalidType: INVALID_TYPE_VISIT,
  };
};

interface ColdSoresLocationValues {
  "symptoms-locations": string[];
}

export const hasColdSoresInEyes = (values: ColdSoresLocationValues) => {
  const inEyes = (values["symptoms-locations"] || []).indexOf("eyes") !== -1;
  return {
    invalid: inEyes,
    hardStopType: "symptoms-locations",
    invalidType: INVALID_TYPE_HARD_STOP,
  };
};

interface WhoDiagnosedValues {
  "who-diagnosed": string;
}

export const isSelfDiagnosed = (values: WhoDiagnosedValues) => {
  return {
    hardStopType: "who-diagnosed",
    invalid: values["who-diagnosed"] !== "my-clinician",
    invalidType: INVALID_TYPE_HARD_STOP,
  };
};

interface DangerSignsValues {
  "following-danger-signs": string;
}

export const isDangerSigns = (values: DangerSignsValues) => {
  return {
    hardStopType: "following-danger-signs",
    invalid: values["following-danger-signs"] !== "!none",
    invalidType: INVALID_TYPE_HARD_STOP,
  };
};

interface IsShortOfBreathValues {
  "how-many-breaths-per-minute": string;
  "most-recent-peak-flow": string;
}

export const isShortOfBreath = (values: IsShortOfBreathValues) => {
  const breathsPerMinute = values["how-many-breaths-per-minute"];
  const invalidBreathsPerMinute =
    ["more-than-24-breaths-per-minute"].indexOf(breathsPerMinute) !== -1;

  return {
    hardStopType: "short-of-breath",
    invalid: invalidBreathsPerMinute,
    invalidType: INVALID_TYPE_HARD_STOP,
  };
};

interface HasLowPeaksValues {
  "most-recent-peak-flow": string;
}

export const hasLowPeaks = (values: HasLowPeaksValues) => {
  const peakFlow = values["most-recent-peak-flow"];
  const invalidPeaks =
    ["50-79%-of-my-best-peak-flow", "less-than-50%-of-my-best-peak-flow"].indexOf(peakFlow) !== -1;

  return {
    hardStopType: "short-of-breath",
    invalid: invalidPeaks,
    invalidType: INVALID_TYPE_HARD_STOP,
  };
};

interface CurrentMigrainesValues {
  "symptoms-right-now": string[];
}

export const hasCurrentMigraine = (values: CurrentMigrainesValues) => {
  const valueBlacklist = [
    "change-in-my-established-headache-pattern",
    "thunderclap-headache",
    "worse-when-you-are-lying-down-waking-at-night-or-in-the-morning",
    "migraine-caused-by-bearing-down-coughing-or-sneezing",
    "fever-neck-stiffness-new-rashes",
    "new-onset-after-50-years-of-age",
    // tslint:disable-next-line max-line-length
    "seizures-trouble-with-vision-lack-of-coordination-or-new-clumsiness-impairment-in-strength-on-one-side-impairment-in-level-of-consciousness-or-persistent-vomiting",
    "migraine-lasting-more-than-72-hours",
  ];
  return {
    invalid: _.intersection(values["symptoms-right-now"] || [], valueBlacklist).length > 0,
    invalidType: INVALID_TYPE_HARD_STOP,
  };
};

interface CannotTreatGERDValues {
  "gerd-symptoms": string;
  "describe-vomit": string;
  "medications-to-treat-symptoms": string[];
  "describe-response-to-medications": string;
  q: number;
}

export const cannotTreatGERD = (values: CannotTreatGERDValues) => {
  const q = _.get(values, "q", undefined);
  const gerdSymptomAnswer = _.get(values, "gerd-symptoms", "");
  const gerdSymptomsInvalid =
    [
      "problems-painful-swallowing",
      "unexplained-weight-loss",
      "coughing-up-blood",
      "blood-in-stool",
    ].indexOf(gerdSymptomAnswer) !== -1;
  const vomitAnswer = _.get(values, "describe-vomit", "");
  const vomitInvalid =
    [
      "looks-like-coffee-grounds",
      "contains-blood",
      "vomit-large-amounts",
      "have-regular-projectile,-or-forceful,-vomiting",
      "have-problems-breathing-after-vomiting",
    ].indexOf(vomitAnswer) !== -1;
  const medications = _.get(values, "medications-to-treat-symptoms", "");
  const protonPump = medications.includes("proton-pump-inhibitors");
  const responseToMedications = _.get(values, "describe-response-to-medications", "");
  const unsatisfactory = responseToMedications === "unsatisfactory";

  if (q === 3) {
    return {
      hardStopType: "cannot-treat-gerd",
      invalid: gerdSymptomsInvalid,
      invalidType: INVALID_TYPE_HARD_STOP,
    };
  } else if (q === 4) {
    return {
      hardStopType: "cannot-treat-gerd",
      invalid: vomitInvalid,
      invalidType: INVALID_TYPE_HARD_STOP,
    };
  } else if (q === 7) {
    return {
      hardStopType: "need-visit-gastroenterologist",
      invalid: protonPump && unsatisfactory,
      invalidType: INVALID_TYPE_HARD_STOP,
    };
  }
  return {
    invalid: false,
    invalidType: "",
  };
};

interface CantTreatMhDiagnosedConditionsProps {
  "mental-health-conditions-diagnosed": string;
  "professional-tell-you": string;
}
export const cantTreatMhDiagnosedConditions = (values: CantTreatMhDiagnosedConditionsProps) => {
  const valueBlackList = [
    "bipolar-disorder",
    "borderline-personality-disorder",
    "schizophrenia",
    13746004,
    58214004,
    20010003,
  ];
  const ihaveBipolarDisorder = values["professional-tell-you"] === "i-have-bipolar-disorder";
  if (ihaveBipolarDisorder) {
    return {
      hardStopType: "mental-health-conditions-diagnosed",
      invalid: ihaveBipolarDisorder,
      invalidType: INVALID_TYPE_HARD_STOP,
    };
  }
  return {
    hardStopType: "mental-health-conditions-diagnosed",
    invalid:
      _.intersection(values["mental-health-conditions-diagnosed"] || [], valueBlackList).length > 0,
    invalidType: INVALID_TYPE_HARD_STOP,
  };
};

interface CannotTreatChildProps {
  "severe-underlying-conditions": string[];
  "hospitalized-before": string;
  "conditions-hospitalized-for": Array<string | number>;
  "when-child-had-surgery": string;
  "what-surgery-child-had": Array<string | number>;
  "timeline-of-itchy-rash": string;
}
export const cannotTreatChild = (values: CannotTreatChildProps) => {
  const severeConditions = values["severe-underlying-conditions"] || [];
  const conditionsHospitalizedFor = values["conditions-hospitalized-for"] || [];
  const surgeryChildHad = values["what-surgery-child-had"] || [];

  const itchyRashTooLong = values["timeline-of-itchy-rash"] === "more-than-6-weeks";
  const cannotTreatDueToSurgery =
    surgeryChildHad.includes("orthopedic-surgery-with-implants") ||
    surgeryChildHad.includes("brain-surgery") ||
    surgeryChildHad.includes("heart-surgery") ||
    surgeryChildHad.includes("lung-surgery") ||
    surgeryChildHad.includes("cancer-surgery") ||
    surgeryChildHad.includes(394610002) ||
    surgeryChildHad.includes(64915003) ||
    surgeryChildHad.includes(408456005) ||
    surgeryChildHad.includes(370612006);
  const recentSurgery = values["when-child-had-surgery"] === "within-the-past-2-months";
  const recentlyHospitalized = values["hospitalized-before"] === "yes-within-the-last-two-months";
  const cannotTreatDueToSevereConditions =
    severeConditions.filter((condition: string) => condition === "!none").length < 1;
  const cannotTreatDueToHospitalizedConditions =
    conditionsHospitalizedFor.includes("rash") ||
    conditionsHospitalizedFor.includes("burns") ||
    conditionsHospitalizedFor.includes(125666000) ||
    conditionsHospitalizedFor.includes(271807003);
  return {
    invalid:
      cannotTreatDueToSevereConditions ||
      recentlyHospitalized ||
      cannotTreatDueToHospitalizedConditions ||
      recentSurgery ||
      cannotTreatDueToSurgery ||
      itchyRashTooLong,
    invalidType: INVALID_TYPE_HARD_STOP,
  };
};

interface WantsAddictiveWeightLossMedicationValues {
  "desired-weightloss-prescription": string;
}

export const wantsAddictiveWeightLossMedication = (
  values: WantsAddictiveWeightLossMedicationValues,
) => {
  const desiredWeightLossPrescription = values["desired-weightloss-prescription"];
  const valueBlacklist = [
    "benzphetamine",
    "hcg",
    "diethylpropion",
    "methamphetamine",
    "phentermine-topiramate",
    "phentermine",
    "phendimetrazine",
  ];

  return {
    invalid: valueBlacklist.indexOf(desiredWeightLossPrescription) !== -1,
    invalidType: INVALID_TYPE_HARD_STOP,
  };
};

interface IsBulimicAnorexicValues {
  "nutrition-medical-history": Array<string | number>;
}

export const isBulimicAnorexic = (values: IsBulimicAnorexicValues) => {
  const conditions = values["nutrition-medical-history"];
  const isBulimic = (conditions.includes("bulimia") || conditions.includes(78004001)) && "bulimia";
  const isAnorexic =
    (conditions.includes("anorexia-nervosa") || conditions.includes(56882008)) &&
    "anorexia-nervosa";
  return {
    hardStopType: "bulimic-anorexic",
    answer: isBulimic || isAnorexic,
    invalid: !!isBulimic || !!isAnorexic,
    invalidType: INVALID_TYPE_HARD_STOP,
  };
};
