/** @format */

import type { EligibilityValidator } from "src/v2/utils/page/validators";

export interface StateIsOtherValues {
  state: string;
}

export const INVALID_STATE_OTHER = "state";

export const isStateOther: EligibilityValidator = (values: StateIsOtherValues) => {
  const invalid = values.state === "other";
  return {
    invalid,
    invalidType: INVALID_STATE_OTHER,
  };
};

export interface StatesByConditionValues {
  "consult-state": string;
  condition: string;
}

const INVALID_TYPE_CONDITION = "condition";

export const isStateValid = (values: StatesByConditionValues) => {
  // Placeholder function since we have no conditions disabled only in specific states
  return {
    invalid: false,
    invalidType: INVALID_TYPE_CONDITION,
  };
};
