/** @format */

import React from "react";
import { useHistory } from "react-router-dom";

import cn from "classnames";

import { Typography } from "@alphamedical/components";

import type { PatientActionItemSchema } from "src/api";
import type { Service } from "src/v2/models/service";

import InformationalCard from "src/v2/components/InformationalCard/InformationalCard";
import { actionTypesMap } from "src/v2/components/PatientActionItems/actions";
import { useStoreState } from "src/v2/models";
import VisitCtaCard from "src/v2/routes/Visit/VisitCtaCard";

interface PatientActionItemsProps {
  highlighted?: boolean;
  actionItems?: PatientActionItemSchema[];
}

const PatientActionItems = ({ highlighted = false, actionItems }: PatientActionItemsProps) => {
  const history = useHistory();
  const serviceList: Service[] = useStoreState((state) => state.service.serviceList);

  const getSubTextForActionType = (actionItem: PatientActionItemSchema) => {
    const { service_id } = actionItem;

    if (service_id) {
      const service = serviceList.find((value) => value.id === service_id);
      if (service) {
        return service.condition?.name || service.name;
      }
    }
    return actionTypesMap[actionItem.action_type].description || "";
  };

  const getRedirectForActionType = (actionItem: PatientActionItemSchema) => {
    return actionTypesMap[actionItem.action_type].url || "";
  };

  if (!serviceList) return null;

  return (
    <div className="mb-4">
      {actionItems && actionItems.length > 0 ? (
        <>
          <Typography className="mb-4 text-grey-160" variant="body2">
            Complete these to continue your care.
          </Typography>
          <div className={cn({ "border-t border-l border-r border-grey-60 rounded": highlighted })}>
            {actionItems.map((actionItem) => {
              const { action_type } = actionItem;
              return (
                <div key={action_type}>
                  <VisitCtaCard
                    text={actionTypesMap[action_type].display}
                    subText={getSubTextForActionType(actionItem)}
                    iconElement={actionTypesMap[action_type].icon}
                    callBack={() => history.push(getRedirectForActionType(actionItem))}
                  />
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <InformationalCard
          icon="/assets/green-circle-sand-background-checkmark.svg"
          content="No new action items for you right now."
          title="You're all set"
        />
      )}
    </div>
  );
};

export default PatientActionItems;
