/** @format */

import type React from "react";

import { DynamicComponent } from "src/components/DynamicComponent";
import { isImageOptionField } from "src/components/DynamicForm/types";

import type { CustomOptionComponentProps } from "./types";

export const OptionWithImage: React.FC<CustomOptionComponentProps> = ({ field, option }) => {
  const imageOption =
    isImageOptionField(field) && field.options.find((opt) => opt.value === option.value);
  return (
    (imageOption && (
      <label
        key={`${field.key}-checkbox-image-${option.value}`}
        className="block normal-case field-image"
        tabIndex={0}
        onKeyPress={(e: any) => {
          if (e.which === 32) {
            e.target.click();
          }
        }}
      >
        <div
          className={`
              checked-field-image rounded-lg
              flex flex-row items-center
              bg-sky-40 border-8 border-white
              t-2 my-3
            `}
        >
          <div
            className="w-32 bg-sky self-stretch bg-contain bg-center bg-no-repeat"
            style={{
              backgroundImage: `url(${imageOption.image})`,
            }}
          />
          <div className="flex-1 mx-4 my-2">
            <DynamicComponent content={option.label} renderStringAsHtml={true} />
            <div className="text-primary-40 my-1">{imageOption.sublabel}</div>
          </div>
        </div>
      </label>
    )) ||
    null
  );
};
