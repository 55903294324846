/** @format */

import React from "react";

import type { ServiceTypeCategory } from "src/api";

import VisitCard from "src/v2/routes/Visit/VisitCard";

import type { VisitSubGroup } from "./constants";

export interface VisitLink {
  title: string;
  link: string;
  description?: string;
}

interface VisitLinkListProps {
  links: ServiceTypeCategory[];
  selectedGroupLinks?: VisitLink[];
  onSelectVisit: (visitLink: ServiceTypeCategory) => void;
  selectedSubGroupLinks?: VisitSubGroup[];
  selectedGroupKey?: string;
}

export const VisitLinkList = ({
  links,
  selectedSubGroupLinks,
  onSelectVisit,
}: VisitLinkListProps) => {
  const renderLinks = (links: ServiceTypeCategory[]) => {
    return links
      ?.sort((a, b) => {
        return a.title.localeCompare(b.title);
      })
      .map((visitLink, idx: number) => (
        <VisitCard
          condition={visitLink.service_key}
          key={visitLink.title}
          onClick={() => onSelectVisit(visitLink)}
          idx={idx}
          title={visitLink.title}
          description={visitLink.description}
        />
      ));
  };

  return (
    <div>
      {renderLinks(links)}
      {selectedSubGroupLinks?.map((subGroup) => (
        <div key={`subgroup-${subGroup.title}`} className="my-8" id={subGroup.title}>
          <h2 className="text-xl sm:text-2xl text-center text-normal mb-6">{subGroup.title}</h2>
        </div>
      ))}
    </div>
  );
};
