/** @format */

// Module dependencies
import type { FC, ReactElement } from "react";
import type React from "react";

import { useEffect, useState } from "react";

import classNames from "classnames";

import { CloseIcon, InfoOutlinedIcon } from "@alphamedical/components";
// UI Components & Styles
import { ButtonPdb } from "src/v2/designSystem/ButtonPdb/ButtonPdb";

import "./Banner.scss";

export interface BannerProps {
  /** Banner theme. Affect background and text color.
   * @default notification
   */
  variant?: "notification" | "warning" | "error";

  /** Text body to be displayed on the banner. */
  body: React.ReactNode;

  /** Left icon of the banner. */
  icon?: string | ReactElement;

  /** Flag. Show or hide banner icon.
   * @default true
   */
  showIcon?: boolean;

  /** Flag. Show or hide banner. */
  showBanner: boolean;
}

export const Banner: FC<BannerProps> = ({ variant, body, icon, showIcon = true, showBanner }) => {
  const [showComponent, setShowComponent] = useState<boolean>(showBanner);

  useEffect(() => {
    setShowComponent(showBanner);
  }, [showBanner]);

  const handleHideComponent = (): void => setShowComponent(false);

  // Style Mappers
  const variantToCssClassMapper: { [key: string]: string } = {
    notification: "bg-sand-120 text-forest-120",
    warning: "bg-warning-pdb text-forest-120",
    error: "bg-error text-white",
  };

  const variantToIconColorMapper: { [key: string]: string } = {
    notification: "#364241",
    warning: "#364241",
    error: "#FFF",
  };

  const bannerTheme = variant
    ? variantToCssClassMapper[variant]
    : variantToCssClassMapper["notification"];
  const iconColor = variant
    ? variantToIconColorMapper[variant]
    : variantToIconColorMapper["notification"];

  return (
    <>
      {showComponent && (
        <div
          className={classNames(
            bannerTheme,
            "py-4 pl-4 shadow-lg overflow-hidden",
            "leading-5 flex items-center",
            "justify-between z-30",
            "w-full sm:max-w-2xl top-16",
            "banner-pdb transform -translate-x-1/2",
          )}
        >
          <div className="flex items-center justify-start gap-4 flex-grow">
            {showIcon && (
              <div>
                {typeof icon === "string" ? (
                  <div className="text-2xl">
                    <i className={icon} />
                  </div>
                ) : (
                  <InfoOutlinedIcon color={iconColor} />
                )}
              </div>
            )}

            <p className={"text-sm font-medium banner-pdb_paragraph overflow-hidden"}>{body}</p>
          </div>

          <ButtonPdb
            variant="transparent"
            iconOnly={true}
            icon={<CloseIcon color={iconColor} />}
            onClick={() => handleHideComponent()}
            iconSize="extraSmall"
            className={`text-${iconColor}`}
          />
        </div>
      )}
    </>
  );
};
