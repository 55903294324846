/** @format */

import React from "react";
import Modal from "react-modal";

import { pageHasNavBarContext } from "src/components/PageWrapper/index";

import "./modal.scss";

const ReactModal = Modal as any;
interface AlphaModalProps {
  isOpen: boolean;
  children?: any;
  className?: string;
  shouldCloseOnOverlayClick?: boolean;
  bgColor?: string;
  onRequestClose?: (e: React.MouseEvent<HTMLElement>) => void;
  cxPadding?: string;
  closeIconColor?: string;
  addPaddingForNavBar?: boolean;
  positionCloseButton?: object;
  customCloseIcon?: string;
  closeIconSize?: string;
  noCloseIcon?: boolean;
}

export const AlphaModal = (props: AlphaModalProps) => {
  const hasNavBar = React.useContext(pageHasNavBarContext);
  const shouldAddPaddingForNavBar = hasNavBar && props.addPaddingForNavBar;
  return (
    <ReactModal
      isOpen={props.isOpen}
      overlayClassName={`z-30 alpha-modal-overlay flex flex-col
    justify-center p-3 sm:p-6 sm:p-0 ${shouldAddPaddingForNavBar ? "pt-20" : ""}
    `}
      className={`
      alpha-modal
      flex flex-col bg-${props.bgColor || "sand-dark"} max-h-full w-full
      sm:h-auto ${props.cxPadding || "p-3 sm:p-6"} sm:my-10 overflow-auto
      relative
      ${props.className || ""}
    `}
      onRequestClose={props.onRequestClose}
      shouldCloseOnOverlayClick={!!props.shouldCloseOnOverlayClick}
      ariaHideApp={false}
    >
      {props.onRequestClose &&
        (props.customCloseIcon ? (
          <img
            style={props.positionCloseButton}
            src={props.customCloseIcon}
            className="top-0 right-0 p-5 cursor-pointer fixed"
            onClick={props.onRequestClose}
            alt="close modal"
          />
        ) : props.noCloseIcon ? (
          <></>
        ) : (
          <div
            style={props.positionCloseButton}
            className={`
              absolute top-0 right-0 fas fa-times p-5 cursor-pointer
              text-${props.closeIconColor || "primary"}
              text-${props.closeIconSize || "2xl"}
            `}
            onClick={props.onRequestClose}
          />
        ))}
      {props.children}
    </ReactModal>
  );
};
