/** @format */

import React from "react";
import { Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { useLocation } from "react-use";

import { some } from "lodash";

import { Popup, Typography } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import type { ConsultAnswer, MembershipSchema, ProfileSchema } from "src/api";
import type { DynamicField as IDynamicField } from "src/components/DynamicForm/types";
import type { FormPopupError } from "src/v2/models/api_types";
import type { Consultation } from "src/v2/types/consultation";

import { DynamicComponent } from "src/components/DynamicComponent";
import { DynamicField } from "src/components/DynamicForm";
import { isOptionField } from "src/components/DynamicForm/types";
import { isHTML } from "src/components/DynamicForm/utils";
import { useHasEmployerBenefits } from "src/utils/hooks/useHasEmployerBenefits";
import { ConsentFormV2 } from "src/v2/components/ConsentFormsV2";
import { MonthlyMembershipBenefitsPrompt } from "src/v2/components/MembershipBenefits";
import OutlineProductSelectionPage from "src/v2/components/OutlineProductSelectionPage/OutlineProductSelectionPage";
import AddressPage from "src/v2/components/Page/AddressPage";
import PaxlovidLocator from "src/v2/components/Page/PaxlovidLocator";
import { PaymentV2 } from "src/v2/components/Payment/PaymentV2";
import Pharmacy from "src/v2/components/Pharmacy";
import Pii from "src/v2/components/Pii";
import SkipQuestion from "src/v2/components/SkipQuestion/SkipQuestion";
import { B2B_ORGANIZATION_SLUGS, REQUIRED_MEMBERSHIP_CONDITIONS } from "src/v2/constants";
import { useStoreDispatch, useStoreState } from "src/v2/models";
import { ResponsesValidationPopup } from "src/v2/routes/Consultation/ResponsesValidationPopup";
import { getFieldCanBeShown } from "src/v2/utils/page/fields";
import * as PageHandlers from "src/v2/utils/page/handlers";

import { validate } from "./validate";

interface PageProps {
  page: any;
  condition: string;
  fields: IDynamicField[];
  values: any;
  pageIdx: number;
  prevClick: (values: any) => void;
  nextClick: (values: any) => Promise<any>;
  goToPage: (pageKey: string, values: any) => void;
  saveValues: (values: any) => Promise<any>;
  errorType?: string;
  errorMessage?: string;
  consult: Consultation;
  profile: ProfileSchema;
  isLastPage: boolean;
  validationError?: FormPopupError;
  clearValidationError?: () => void;
  setIsAutoSubmit?: (value: boolean) => void;
  userRequiresSyncVisit?: boolean;
  prevAnswers?: Array<ConsultAnswer>;
}
export const Page = (props: PageProps) => {
  const dispatch = useStoreDispatch();
  const location = useLocation();
  const membership = useStoreState((state) => state.membership.membership);
  const [showMembershipPanel, setShowMembershipPanel] = React.useState<boolean>(false);
  const [prepopulatedImageData, setPrepopulatedImageData] = React.useState<{
    [key: string]: string | undefined;
  }>({});
  const healthHistory = useStoreState((state) => state.consults.healthHistory);

  const membershipBenefitsPromptPage = "anything-else";
  const isPopupPage = props.page.key === membershipBenefitsPromptPage;

  const hasWesternDentalBenefits = useHasEmployerBenefits("western-dental");

  const isSonjaCaresPatient = useHasEmployerBenefits(B2B_ORGANIZATION_SLUGS.SONJA_CARES);
  const isPharmacyPreferencePage = props.page.key === "pharmacy-preference";

  React.useEffect(() => {
    const pageLoad = async () => {
      if (props.page) {
        PageHandlers.pageOnLoad(
          props.condition,
          props.page,
          props.values,
          props.consult.consult_type,
        );
      }
    };
    pageLoad();
  }, [props.page.key]);

  React.useEffect(() => {
    if (
      isPopupPage &&
      !props.consult.subscription.condition.is_pediatric &&
      REQUIRED_MEMBERSHIP_CONDITIONS.indexOf(props.condition) == -1
    ) {
      if (membership && !membership.active && !hasWesternDentalBenefits) {
        setShowMembershipPanel(true);
      } else if (!membership) {
        dispatch.membership.fetchMembership().then((res: MembershipSchema) => {
          if (!res.active) {
            setShowMembershipPanel(true);
            dispatch.alerts.fetchMembershipAlerts();
          }
        });
      }
    }
  }, []);

  const onSubmit = async (values: any) => {
    await props.nextClick(values);
  };

  // This useEffect fetches the image data for file fields that have been prepopulated
  // and sets the value of the field to the image data rather than the url it is located at
  React.useEffect(() => {
    props.fields
      .filter((field) => field["type"] == "file")
      .forEach((field) => {
        const prevAnswer = props.prevAnswers?.find((answer: any) => answer.key === field.key);
        if (prevAnswer) {
          fetch(prevAnswer?.value)
            .then((res: any) => {
              return res.blob();
            })
            .then((blob: any) => {
              const reader = new FileReader();
              reader.onload = function () {
                setPrepopulatedImageData((oldValue) => {
                  const newValue = { ...oldValue };
                  newValue[field.key] = reader.result?.toString();
                  return newValue;
                });
              };
              reader.readAsDataURL(blob);
            });
        }
      });
  }, [props.page.key]);

  const pageDefaults = props.fields.reduce((reduced: object, field: any) => {
    const defaultValue = field.defaultValue;

    if (healthHistory && field["prepopulate"]) {
      const healthHistoryAnswer = healthHistory.find((answer: any) => answer.key === field.key);
      const healthHistoryAnswerValue =
        healthHistoryAnswer?.value_data_type === "array"
          ? healthHistoryAnswer.value_object
          : healthHistoryAnswer?.value;
      if (healthHistoryAnswerValue) {
        reduced[field.key] = healthHistoryAnswerValue;
      }
    }

    // Find the previous answer for the current field (if exists)
    const prevAnswer = props.prevAnswers?.find((answer: any) => answer.key === field.key);
    const prevAnswerValue =
      prevAnswer?.value_data_type === "array" ? prevAnswer.value_object : prevAnswer?.value;

    // Assign the previous answer value if it exists, otherwise assign the default value
    if (defaultValue || prevAnswerValue) {
      reduced[field.key] = prevAnswerValue !== undefined ? prevAnswerValue : defaultValue;
    }

    if (prepopulatedImageData[field.key]) {
      reduced[field.key] = prepopulatedImageData[field.key];
    }

    return reduced;
  }, {});

  const shouldSkipMembershipPanel = (key: string) => {
    return REQUIRED_MEMBERSHIP_CONDITIONS.indexOf(key) != -1;
  };

  return (
    <div className="w-full h-full">
      <Form
        validate={validate(
          props.fields,
          (values: any, field: any) =>
            getFieldCanBeShown(
              values,
              field,
              props.consult,
              props.profile,
              membership,
              hasWesternDentalBenefits,
              props.userRequiresSyncVisit,
            ),
          props.page,
        )}
        onSubmit={onSubmit}
        initialValues={{
          ...pageDefaults,
          ...props.values,
        }}
      >
        {(formProps: any) => {
          const { handleSubmit, submitting, pristine, invalid, values } = formProps;
          const { fields, profile, validationError, clearValidationError, setIsAutoSubmit } = props;

          const fieldsIsVisible = fields.map((field) =>
            getFieldCanBeShown(
              values,
              field,
              props.consult,
              props.profile,
              membership,
              hasWesternDentalBenefits,
              props.userRequiresSyncVisit,
            ),
          );

          const noFields = !fields || !fields.length;
          const noFieldsRequired =
            !fields ||
            (fields.length &&
              fields.every((f: any) => {
                return !f.required;
              }));

          const targetFields = fields.filter((field, idx) => {
            return (
              isOptionField(field) &&
              (field.type === "radio" ||
                (field.type === "select" && field.subtype === "checkbox")) &&
              fieldsIsVisible[idx]
            );
          });
          const unansweredTargetFields = targetFields.filter((field) => {
            return !values[field.key];
          });
          const nonTargetVisibleFields = fields.filter((field, idx) => {
            return field.type !== "radio" && field.type !== "select" && fieldsIsVisible[idx];
          });

          const canAutoNext =
            unansweredTargetFields.length === 1 && nonTargetVisibleFields.length === 0;

          const pharmacyNextDisabled =
            props.page.key === "external-pharmacy" && !formProps?.values?.pharmacy_selected;
          const nextDisabled =
            false ||
            (!noFields && !noFieldsRequired && (invalid || submitting)) ||
            pharmacyNextDisabled;
          const nextLabel =
            (pristine && props.page.overridePristineNextText) ||
            props.page.overrideNextText ||
            "Next";

          const allowClose =
            some(validationError?.options, "allow_continue") && !validationError?.prevent_close;

          if (
            !shouldSkipMembershipPanel(props.condition) &&
            showMembershipPanel &&
            props.page.key === membershipBenefitsPromptPage
          ) {
            return (
              <div className="z-10 mt-10 bg-white absolute md:relative top-0 left-0 px-4 md:px-0 pt-10 md:pt-2">
                <MonthlyMembershipBenefitsPrompt
                  href={`/membership-signup?redirect=${location.pathname || ""}`}
                  condition={props.condition}
                  profile={props.profile}
                  onSkipVisit={() => setShowMembershipPanel(false)}
                />
              </div>
            );
          }

          return (
            <div className="w-full h-full flex flex-col">
              {validationError && clearValidationError && (
                <Popup
                  headerText={validationError.header}
                  isOpen={!!validationError}
                  onRequestClose={allowClose ? clearValidationError : undefined}
                >
                  <ResponsesValidationPopup
                    validationError={validationError}
                    clearValidationError={clearValidationError}
                    goToPage={props.goToPage}
                    onSubmit={handleSubmit}
                    condition={props.condition}
                  />
                </Popup>
              )}
              {targetFields.map((field) => (
                <OnChange key={field.key} name={field.key}>
                  {(value: any, previous: any) => {
                    if (!previous && !!value && value !== previous) {
                      if (
                        isOptionField(field) &&
                        (field.type === "radio" ||
                          (field.subtype === "checkbox" && value[0] === "!none"))
                      ) {
                        const otherFieldsOnPage = (fields || []).filter((pageField) => {
                          return (
                            pageField.key !== field.key &&
                            getFieldCanBeShown(
                              values,
                              pageField,
                              props.consult,
                              props.profile,
                              membership,
                              hasWesternDentalBenefits,
                              props.userRequiresSyncVisit,
                            )
                          );
                        });
                        if (otherFieldsOnPage.length === 0) {
                          if (setIsAutoSubmit) {
                            setIsAutoSubmit(true);
                          }
                          handleSubmit();
                        }
                      }
                    }
                  }}
                </OnChange>
              ))}
              {props.page.title &&
                (isPopupPage && showMembershipPanel ? (
                  <></>
                ) : (
                  <DynamicComponent
                    stringWrapperProps={{ className: "pb-2 pt-8", variant: "subtitle1_as_h1" }}
                    renderStringAsHtml={isHTML(props.page.title)}
                    content={props.page.title}
                  />
                ))}
              {props.page.subtitle && !(isSonjaCaresPatient && isPharmacyPreferencePage) && (
                <DynamicComponent
                  stringWrapperProps={{
                    className: !props.page.title ? "pt-8 pb-2" : "py-2",
                    variant: "h3",
                  }}
                  renderStringAsHtml={isHTML(props.page.subtitle)}
                  content={props.page.subtitle}
                />
              )}
              {props.page.description && (
                <DynamicComponent
                  stringWrapperProps={{ className: "py-2", variant: "body1" }}
                  renderStringAsHtml={isHTML(props.page.description)}
                  content={props.page.description}
                />
              )}
              {((props.page.key === "payment" || props.errorType === "payment") && (
                <PaymentV2
                  formProps={formProps}
                  hasFailedPayment={props.errorType === "payment"}
                  handleSubmit={handleSubmit}
                  condition={props.condition}
                />
              )) ||
                (props.page.type === "consent-v2" && (
                  <>
                    <ConsentFormV2
                      condition={props.condition}
                      consentKey={props.page.consentFormKey}
                      formProps={formProps}
                      fields={fields}
                      nextClick={handleSubmit}
                    />
                  </>
                )) || (
                  <form onSubmit={handleSubmit}>
                    {(props.page.key === "pii" && (
                      <Pii
                        formProps={formProps}
                        fields={fields}
                        showNameFields={props.page?.showNameFields !== false}
                      />
                    )) ||
                      (props.page.type === "address" && (
                        <AddressPage
                          formProps={formProps}
                          fields={fields}
                          values={props.values}
                          nextClick={handleSubmit}
                        />
                      )) ||
                      (props.page.key === "pharmacy-preference" && (
                        <Pharmacy
                          formProps={formProps}
                          fields={fields}
                          nextDisabled={nextDisabled}
                          nextLabel={nextLabel}
                          nextClick={handleSubmit}
                          condition={props.condition}
                        />
                      )) ||
                      (props.page.key === "outline-product-selection" && (
                        <OutlineProductSelectionPage
                          formProps={formProps}
                          fields={fields}
                          values={props.values}
                          nextClick={handleSubmit}
                          consult={props.consult}
                        />
                      )) ||
                      (props.page.key === "treatment-locator" && (
                        <PaxlovidLocator nextClick={handleSubmit} />
                      )) ||
                      (fields &&
                        fields.map((field: any, idx: number) => {
                          const show = fieldsIsVisible[idx];

                          // If a field is hidden due to its conditions but was previously answered (not null) then reset
                          // the value
                          if (!show && values[field.key] != null && !pristine) {
                            // NOTE: I am not sure of all the implications of resetting answers when hidden, so I am only
                            // doing it for this question right now
                            if (
                              field.key === "no-blood-pressure-taken" ||
                              field.key === "cardholder_first_name" ||
                              field.key === "cardholder_last_name" ||
                              field.key === "relation_to_cardholder" ||
                              field.key === "relationship_code" ||
                              field.key === "would-like-help-quitting" ||
                              field.key === "help-quitting"
                            ) {
                              formProps.form.change(field.key, null);
                            }
                          }
                          if (field.key === "consult-state" && values["state"]) {
                            field.defaultValue = values["state"];
                          }
                          // We need to parse this key since the expected value is a string withing an array
                          if (
                            field.key === "is_federally_funded_plan" &&
                            values["is_federally_funded_plan"] &&
                            pristine
                          ) {
                            values["is_federally_funded_plan"] = [
                              values["is_federally_funded_plan"] ? "true" : "",
                            ];
                          }
                          return (
                            show && (
                              <DynamicField
                                key={field.key}
                                field={field}
                                profile={profile}
                                errorType={props.errorType}
                                errorMessage={props.errorMessage}
                                className={!props.page.title ? "pt-8" : ""}
                              />
                            )
                          );
                        }))}
                    <div className="py-2">
                      <Typography variant="caption">{props.page.footer}</Typography>
                    </div>
                    {!canAutoNext && !props.page.customNext && (
                      <ButtonPdb variant="primary" disabled={nextDisabled} className="mt-2 w-full">
                        {(pristine && props.page.overridePristineNextText) ||
                          props.page.overrideNextText ||
                          `${props.isLastPage ? "Complete Your Visit" : "Next"}`}
                      </ButtonPdb>
                    )}
                    {props.page.allowSkip && (
                      <SkipQuestion
                        goToPage={props.goToPage}
                        nextClick={props.nextClick}
                        skip={props.page.skip}
                      />
                    )}
                  </form>
                )}
            </div>
          );
        }}
      </Form>
    </div>
  );
};
