/** @format */

import type React from "react";

import MedsWithInfo from "src/components/DynamicForm/Fields/CustomOptionComponents/MedsWithInfo";

import type { CustomOptionComponentProps } from "./types";

import { OptionWithImage } from "./OptionWithImage";
import { ProductOption } from "./ProductOption";

interface CustomOptionComponentMap {
  [componentName: string]: React.FC<CustomOptionComponentProps> | undefined;
}

export const CustomOptionComponents: CustomOptionComponentMap = {
  ProductOption,
  OptionWithImage,
  MedsWithInfo,
};
