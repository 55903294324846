/** @format */

import type { FormRenderProps } from "react-final-form";

import React from "react";
import { Form } from "react-final-form";
import { Link } from "react-router-dom";

import * as Sentry from "@sentry/react";
import { isString, size } from "lodash";

import { ActionItemsService } from "src/api";
import { PageWrapper } from "src/components/PageWrapper";
import { MEDICAL_RECORDS } from "src/routes";
import { now } from "src/utils/date_utils";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { Button } from "src/v2/components/Button";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useCurrentUser } from "src/v2/models/profile";

import type { FormValues } from "./validation";

import Authorization from "./Authorization";
import Details from "./Details";
import DisclosureOverview from "./DisclosureOverview";
import Identity from "./Identity";
import Intro from "./Intro";
import Sign from "./Sign";
import Terms from "./Terms";
import { pages, validatePage } from "./validation";

export interface MedicalRecordsRequestFormPageProps {
  show: boolean;
  formProps?: FormRenderProps<FormValues>;
}

const MedicalRecordsRequestForm = () => {
  const user = useCurrentUser();
  const [activePage, setActivePage] = React.useState<number>(0);
  const [errorMessage, setErrorMessage] = React.useState("");
  const isLastPage = activePage === size(pages) - 2; // the actual last page is the success page
  const pageKey = pages[activePage];
  const progress = (100 * activePage) / size(pages);

  useTopBar({
    title: "Request medical records",
    variant: "nested",
    showBackButton: activePage < 2,
  });

  const triggerSuccess = () => setActivePage(size(pages) - 1);
  const handleError = (err: any) => {
    Sentry.captureException(err);
    const message = isString(err)
      ? err
      : err?.message || "We're sorry, something went wrong. Please try again.";
    setErrorMessage(message);
  };

  const submitHandler = (values: FormValues) => {
    if (user.is_new_patient) {
      return;
    }
    ActionItemsService.requestMedicalRecord({
      userId: "me",
      requestBody: {
        request_form_answers: values,
      },
    })
      .then(triggerSuccess)
      .catch(handleError);
  };

  const today = now("MM-dd-yyyy");
  return (
    <PageWrapper cxMaxWidth="max-w-none" bgColor="white">
      {user.is_new_patient ? (
        <div className="h-full mx-auto px-4 py-6 text-center max-w-lg">
          <Link to={MEDICAL_RECORDS} className="flex items-center w-full sm:w-64 mt-4">
            <img src={`/assets/back-arrow.svg`} className="mt-1 mr-4" alt="Go to previous page" />
            <div className="text-xl">Request Your Records</div>
          </Link>
          <div className="bg-cloud-light-40 py-4 px-5 mt-8 mb-10 mx-auto">
            <div className="flex items-center">
              <img src="/assets/information_filled.svg" />
              <span className="font-medium flex-1 ml-4 text-left text-sm">
                No medical records available
              </span>
            </div>
            <div className="mt-3 text-left text-sm">
              {
                "We don’t have any medical records available for you at this time. They will only become available after you have completed a visit."
              }
            </div>
          </div>
          <Link
            to={"/your-care/explore-visits"}
            className="btn btn-violator w-full sm:w-64 mx-auto block mb-3"
          >
            Start a Visit
          </Link>
          <Link to={"/my-cases"} className="btn btn-outline-primary w-full sm:w-64 mx-auto block">
            Back to Home
          </Link>
        </div>
      ) : (
        <>
          {pageKey === "success" ? (
            <div className="text-center h-full mx-auto mt-12 px-5 py-6 max-w-xl">
              <div className="text-lg font-medium">Thanks!</div>
              <div>
                You successfully submitted your medical record request. We sent you a copy of this
                form in your Notifications message thread.
              </div>
              <Link to={"/messages"} className="btn btn-primary w-full sm:w-64 mx-auto block mt-4">
                Go to Messages
              </Link>
            </div>
          ) : (
            <div className="pt-4">
              <div style={{ width: "95vw" }} className="h-3 bg-grey-lightest">
                <div
                  className="h-full bg-primary top-0 left-0 t-3"
                  style={{ width: `${progress}%` }}
                />
              </div>
              {activePage > 0 && (
                <div
                  onClick={() => {
                    setErrorMessage("");
                    setActivePage(activePage - 1);
                    window.scrollTo(0, 0);
                  }}
                >
                  <img src={`/assets/back-arrow.svg`} className="mt-4" alt="Go to previous page" />
                </div>
              )}
              <Form<FormValues>
                onSubmit={submitHandler}
                initialValues={{ signature_date: today }}
                render={(formProps) => (
                  <form onSubmit={formProps.handleSubmit} className="m-6 max-w-lg mx-auto">
                    <div>
                      <Intro show={pageKey === "intro"} />
                      <Authorization show={pageKey === "authorization"} />
                      <Details show={pageKey === "details"} formProps={formProps} />
                      <DisclosureOverview
                        show={pageKey === "disclosure-overview"}
                        values={formProps?.values}
                      />
                      <Terms show={pageKey === "terms"} />
                      <Identity show={pageKey === "identity"} />
                      <Sign show={pageKey === "sign"} />
                    </div>
                    <Button
                      disabled={formProps?.submitting}
                      onClick={(e) => {
                        e.preventDefault();
                        const validationMessage = validatePage(formProps.values, pageKey);
                        if (validationMessage) {
                          setErrorMessage(validationMessage);
                        } else {
                          setErrorMessage("");
                          window.scrollTo(0, 0);
                          isLastPage ? formProps.handleSubmit() : setActivePage(activePage + 1);
                        }
                      }}
                    >
                      {isLastPage ? "Submit" : activePage === 0 ? "Continue" : "Next"}
                    </Button>
                    {errorMessage && <div className="text-error py-6">{errorMessage}</div>}
                  </form>
                )}
              />
            </div>
          )}
        </>
      )}
    </PageWrapper>
  );
};

export default WithTopBarNavigation(MedicalRecordsRequestForm);
