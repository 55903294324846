/** @format */
import { IsDateOfBirthValid } from "src/v2/utils/page/_validators/condition";

export const INVALID_TYPE_SOFT_STOP = "soft-stop";
export const INVALID_TYPE_RESELECT = "reselect";

interface IsSoftStopValues {
  "mental-health-conditions-looking-to-treat": string[];
  "suicidal-feelings": string;
  "intention-of-acting-on-suicide": string;
  "carry-out-suicidal-plan": string;
  "enacted-suicidal-plan": string;
  "hypothyroidism-where-tested": string;
  "outline-product-selection": string;
  "sti-women-current-symptoms": string[];
  "side-effects-from-medications": string[];
  key: string;
}

export const isSoftStop = (values: IsSoftStopValues) => {
  // STI Testing Kit
  const sotfStopAnswers = ["pelvic-pain", "fever", "nausea-vomiting"];
  const currentSymptomsAnswer = values["sti-women-current-symptoms"] || [];
  let softStopPresent = false;
  currentSymptomsAnswer.map((answer: string) => {
    if (sotfStopAnswers.indexOf(answer) > -1) {
      softStopPresent = true;
    }
    return undefined;
  });

  // Mental Health
  const lookingToTreat = values["mental-health-conditions-looking-to-treat"] || [];
  const unsureOrOther = lookingToTreat.some((answer) => ["unsure", "other"].indexOf(answer) !== -1);
  const suicidalFeelings = values["suicidal-feelings"];
  const suicidalIntention = values["intention-of-acting-on-suicide"];
  const suicidalPlan = values["carry-out-suicidal-plan"];
  const enactedSuicidalPlan = values["enacted-suicidal-plan"];
  const suicidalAnswers =
    [suicidalIntention, suicidalPlan, enactedSuicidalPlan].indexOf("yes") > -1;
  const feelingSuicidal =
    ["several-days", "over-half-the-days", "nearly-every-day"].indexOf(suicidalFeelings) !== -1;

  // Smoking Cessationg
  const medicationSideEffects = values["side-effects-from-medications"] || [];
  const suicidalThoughts = medicationSideEffects.indexOf("suicidal-thoughts") > -1;

  // Hypothyroidism
  const homeSelfCollectionKit =
    values["hypothyroidism-where-tested"] === "id-like-to-use-a-home-self-collection-test-kit";

  if (homeSelfCollectionKit) {
    return {
      name: "home-self-collection-kit",
      invalid: homeSelfCollectionKit,
      invalidType: INVALID_TYPE_SOFT_STOP,
      modalClasses: "max-w-sm bg-white",
    };
  }
  // if (unsureOrOther && values.key === "mental-health-conditions-looking-to-treat") {
  //   return {
  //     name: "mental-health-looking-to-treat",
  //     invalid: unsureOrOther,
  //     key: values.key,
  //     invalidType: INVALID_TYPE_SOFT_STOP
  //   };
  // }
  // // used in mental health
  // if (feelingSuicidal && suicidalAnswers) {
  //   return {
  //     name: "suicidal-feelings",
  //     key: values.key,
  //     invalid: feelingSuicidal,
  //     invalidType: INVALID_TYPE_SOFT_STOP
  //   };
  // }
  // // used in return smoking cessation
  // if (suicidalThoughts && suicidalAnswers) {
  //   return {
  //     name: "suicidal-feelings",
  //     key: values.key,
  //     invalid: suicidalAnswers,
  //     invalidType: INVALID_TYPE_SOFT_STOP
  //   };
  // }
  // if (softStopPresent) {
  //   return {
  //     name: "sti-women-current-symptoms",
  //     invalid: softStopPresent,
  //     invalidType: INVALID_TYPE_SOFT_STOP
  //   };
  // }
  // if (additionalTesting && values.key === "outline-product-selection") {
  //   return {
  //     name: "outline-product-selection",
  //     modalClasses: "max-w-sm bg-white",
  //     invalid: additionalTesting,
  //     invalidType: INVALID_TYPE_SOFT_STOP
  //   };
  // }
  return {
    invalid: false,
    invalidType: INVALID_TYPE_SOFT_STOP,
  };
};

export interface IsReselectProps {
  "choose-medications": string;
  "conditions-smoking": string;
  "endo-medical-condition-history": Array<string | number>;
  "other-prescription-medications-edo": string[];
  "choose-birth-control": string;
  "conditions-related-to-blood-clots": Array<string | number>;
  "hypothyroidism-where-tested": string;
  "select-lab-location": string;
  "select-medication-for-weight-loss": string;
  "brand-or-generic": string;
  payment_preference: string;
  dob: string;
  key: string;
}
export const isReSelect = (values: IsReselectProps) => {
  const payment_preference = values["payment_preference"];
  // Endometriosis
  const chosenMedications = values["choose-medications"];
  const isSmoker = values["conditions-smoking"] === "yes";
  const is35OrOlder = IsDateOfBirthValid(values.dob, 35);
  const conditionHistory = values["endo-medical-condition-history"] || [];
  const hasOsteoporosis = conditionHistory.some(
    (answer) => ["osteoporosis", 64859006].indexOf(answer) !== -1,
  );
  const usedPrescriptions = values["other-prescription-medications-edo"] || [];
  const usedCyclosporine = usedPrescriptions.some(
    (answer) => ["cyclosporine"].indexOf(answer) !== -1,
  );
  const usedGemfibrozil = usedPrescriptions.some(
    (answer) => ["gemfibrozil"].indexOf(answer) !== -1,
  );
  const birthControlSelection = values["choose-birth-control"];
  const bloodClotConditionOptions = values["conditions-related-to-blood-clots"];
  const hasInvalidBloodClotCondition =
    bloodClotConditionOptions &&
    bloodClotConditionOptions.some(
      (answer) =>
        [
          "dont-remember-cause",
          75753009,
          "factor-v-leiden-various",
          64779008,
          "deep-vein-thrombosis",
          128053003,
        ].indexOf(answer) !== -1,
    );

  // Hypothyroidism
  const nonQuestLab =
    values["hypothyroidism-where-tested"] === "id-like-to-go-to-a-lab-different-from-quest";

  // Hyperlipidemia
  const nonQuestLabHyperlipidemia = values["select-lab-location"] === "lab-different-from-quest";

  // Weight Loss
  const selectedWeightLossMeds = values["select-medication-for-weight-loss"];
  const isHighPricedMed =
    [
      "liraglutide-injection",
      "naltrexone-bupropion",
      "orlistat",
      "semaglutide-wegovy",
      "semaglutide-ozempic",
      "semaglutide-rybelsus",
      "liraglutide-injection-victoza",
    ].indexOf(selectedWeightLossMeds) !== -1;

  // Lashes
  // const choseBrandLatisse = values["brand-or-generic"] === "brand";

  // if (choseBrandLatisse) {
  //   return {
  //     name: "brand-latisse",
  //     invalid: choseBrandLatisse,
  //     invalidType: INVALID_TYPE_RESELECT,
  //     modalClasses: "max-w-sm bg-white"
  //   };
  // }

  // if (nonQuestLabHyperlipidemia) {
  //   return {
  //     name: "non-quest-lab",
  //     invalid: nonQuestLabHyperlipidemia,
  //     invalidType: INVALID_TYPE_RESELECT,
  //     modalClasses: "max-w-sm bg-white"
  //   };
  // }
  // if (nonQuestLab) {
  //   return {
  //     name: "non-quest-lab",
  //     invalid: nonQuestLab,
  // if (payment_preference === "cash" && isHighPricedMed) {
  //   return {
  //     name: selectedWeightLossMeds,
  //     invalid: payment_preference === "cash" && isHighPricedMed,
  //     invalidType: INVALID_TYPE_RESELECT,
  //     modalClasses: "max-w-sm bg-white"
  //   };
  // }

  if (
    isSmoker &&
    is35OrOlder &&
    (chosenMedications === "vaginal-ring" || chosenMedications === "patch") &&
    values.key === "choose-medications"
  ) {
    return {
      name: "choose-medications",
      modalClasses: "max-w-sm bg-white",
      invalid: true,
      key: values.key,
      invalidType: INVALID_TYPE_RESELECT,
    };
  }
  if (
    isSmoker &&
    is35OrOlder &&
    ["blisovi-fe", "altevera", "sprintec"].indexOf(birthControlSelection) > -1 &&
    values.key === "choose-birth-control"
  ) {
    return {
      name: "no-estrogen-bc-with-risk-factors",
      modalClasses: "max-w-sm bg-white",
      invalid: true,
      key: values.key,
      invalidType: INVALID_TYPE_RESELECT,
    };
  }
  // if (hasOsteoporosis && (chosenMedications === "orilissa-150-mg" || chosenMedications === "orilissa-200-mg")) {
  //   return {
  //     name: "osteo-orilissa-warning",
  //     modalClasses: "max-w-sm bg-white",
  //     invalid: true,
  //     key: values.key,
  //     invalidType: INVALID_TYPE_RESELECT
  //   };
  // }
  // if (usedCyclosporine && (chosenMedications === "orilissa-150-mg" || chosenMedications === "orilissa-200-mg")) {
  //   return {
  //     name: "cyclosporine-orilissa-warning",
  //     modalClasses: "max-w-sm bg-white",
  //     invalid: true,
  //     key: values.key,
  //     invalidType: INVALID_TYPE_RESELECT
  //   };
  // }
  // if (usedGemfibrozil && (chosenMedications === "orilissa-150-mg" || chosenMedications === "orilissa-200-mg")) {
  //   return {
  //     name: "gemfibrozil-orilissa-warning",
  //     modalClasses: "max-w-sm bg-white",
  //     invalid: true,
  //     key: values.key,
  //     invalidType: INVALID_TYPE_RESELECT
  //   };
  // }
  // if (
  //   hasInvalidBloodClotCondition &&
  //   ["aygestin-15", "aygestin-12.5", "aygestin-10", "aygestin-7.5", "aygestin-5"].indexOf(chosenMedications) > -1
  // ) {
  //   return {
  //     name: "aygestin-warning",
  //     modalClasses: "max-w-sm bg-white",
  //     invalid: true,
  //     key: values.key,
  //     invalidType: INVALID_TYPE_RESELECT
  //   };
  // }

  return {
    invalid: false,
    invalidType: INVALID_TYPE_SOFT_STOP,
  };
};

interface IsBMIInvalidValues {
  key: string;
  height: string;
  weight: string;
}

export const isBMIInvalid = (values: IsBMIInvalidValues) => {
  // eg. 166
  const weightValue = parseInt(values["weight"]);
  // eg. "5ft 6in" -> "5 6" -> ["5", "6"]
  const heightValuesArr = (values["height"] || " ").replace(/ft|in/gi, "").split(" ");
  const heightInInches = parseInt(heightValuesArr[0]) * 12 + parseInt(heightValuesArr[1]);

  // BMI calcuation based on:
  // https://www.cdc.gov/nccdphp/dnpao/growthcharts/training/bmiage/page5_2.html
  const bmiValue = +((weightValue / heightInInches / heightInInches) * 703).toFixed(2);

  if (bmiValue < 30) {
    return {
      name: "bmi-invalid",
      modalClasses: "max-w-md bg-white",
      key: values.key,
      invalid: true,
      bmiValue,
      invalidType: INVALID_TYPE_SOFT_STOP,
    };
  }

  return {
    invalid: false,
    invalidType: INVALID_TYPE_SOFT_STOP,
  };
};
