/** @format */

import React from "react";

import { Typography } from "@alphamedical/components";
import { BorderedContent } from "src/v2/designSystem/Content/BorderedContent";

import type { TreatmentPlanInfoSchema } from "src/api";
import type { CardPopupContent } from "src/components/Popups/CardPopup";

import { CardPopup } from "src/components/Popups/CardPopup";
import { MedicationRecordAccordion } from "src/components/RecordAccordion/MedicationRecordAccordion";
import UserAvatar from "src/components/UserAvatar/index";
import InformationalCard from "src/v2/components/InformationalCard/InformationalCard";
import { useCurrentUser } from "src/v2/models/profile";

export const PostVisitSummaryDetails = (props: { pvs: TreatmentPlanInfoSchema }) => {
  const currentUser = useCurrentUser();
  const [expanded, setExpanded] = React.useState(false);
  const [bioModalContent, setBioModalContent] = React.useState<CardPopupContent>();
  const [bioModalIsOpen, setBioModalIsOpen] = React.useState(false);

  return (
    <>
      <div className="pt-8">
        <Typography variant="h1">Post-Visit Summary</Typography>
        <Typography className="mb-6" variant="body1">
          {currentUser.pii.full_name}
        </Typography>
        <BorderedContent>
          <UserAvatar
            author={{
              name: props.pvs.provider,
              ...props.pvs.provider_details,
            }}
            subtitle="My Alpha Provider"
            setBioModalContent={setBioModalContent}
            setBioModalIsOpen={setBioModalIsOpen}
          />
          <div
            style={
              expanded
                ? {}
                : {
                    maxHeight: 170,
                    maskImage:
                      "linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))",
                  }
            }
            className="overflow-hidden"
          >
            {props.pvs.patient_instructions && (
              <p
                className="pb-6"
                dangerouslySetInnerHTML={{ __html: props.pvs.patient_instructions }}
              />
            )}
          </div>
          <div role="button" onClick={() => setExpanded(!expanded)}>
            <Typography variant="hyperlink">{expanded ? "Read less" : "Read more"}</Typography>
          </div>
        </BorderedContent>
        {props.pvs.medications &&
          props.pvs.medications.map((medication) => (
            <MedicationRecordAccordion key={medication.id} medication={medication} />
          ))}
        {currentUser.is_ubacare_user &&
          props.pvs.medications &&
          props.pvs.medications.length > 0 && (
            <InformationalCard
              type="Warning"
              title="Your prescription"
              content={
                <>
                  <span>
                    If you have questions about your prescription or experience side effects, please
                    contact the in-person provider who prescribes your medication. If you experience
                    an adverse reaction, please seek in-person emergency care.
                    <span>
                      <br /> <br />
                    </span>
                    When you need additional refills or an adjustment to your medication, please
                    contact the in-person provider who prescribes your medication.
                  </span>
                </>
              }
            />
          )}
      </div>
      {bioModalContent && (
        <CardPopup
          isOpen={bioModalIsOpen}
          onRequestClose={() => setBioModalIsOpen(false)}
          content={bioModalContent}
        />
      )}
    </>
  );
};
