/** @format */

import React from "react";
import { useHistory, useParams } from "react-router-dom";

import type { ServiceTypeCategory } from "src/api";

import { PageWrapper } from "src/components/PageWrapper";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { ProfileSwitcher } from "src/v2/components/Family/ProfileSwitcher";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useStoreActions, useStoreDispatch, useStoreState } from "src/v2/models";
import { VisitLinkList } from "src/v2/routes/Visit/VisitSelection";

export const VisitCategory = () => {
  useTopBar({ variant: "nested", title: "", showBackButton: true });
  const history = useHistory();
  const { categoryKey } = useParams<{ categoryKey?: string }>();
  const serviceCategories = useStoreState((state) => state.service.serviceCategories);
  const category = serviceCategories.find((cat) => cat.key == categoryKey);
  const dispatch = useStoreDispatch();
  const setTitle = useStoreActions((actions) => actions.topBar.setTitle);

  React.useEffect(() => {
    if (category) {
      setTitle(category.name);
    }
  }, [category?.key]);

  React.useEffect(() => {
    if (serviceCategories.length === 0) {
      dispatch.service.fetchServiceCategories();
    }
  }, []);

  const selectVisit = (visitLink: ServiceTypeCategory) => {
    history.push(visitLink.link || "#");
    window.scrollTo(0, 0);
  };
  return (
    <PageWrapper bgColor={"bg-grey-lightest"}>
      <div className={"p-3 mt-1"}>
        <div className={"mb-4"}>
          <ProfileSwitcher />
        </div>
        <VisitLinkList links={category?.service_type_category || []} onSelectVisit={selectVisit} />
      </div>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(VisitCategory);
