/** @format */

import React from "react";

interface PromoErrorContentProps {
  onClose: (e: any) => void;
  error: string | undefined;
  btnClass?: string;
}

const PromoErrorContent = ({ onClose, error, btnClass = undefined }: PromoErrorContentProps) => {
  return (
    <div className="m-4">
      <p>{error}</p>
      <button onClick={onClose} className={`w-full btn ${btnClass || "btn-violator"} mt-6`}>
        Back to Payment
      </button>
    </div>
  );
};

export default PromoErrorContent;
