/** @format */
import type React from "react";

import { useEffect, useState } from "react";

import * as Sentry from "@sentry/react";
import { filter, isEmpty, map } from "lodash";

import type { User } from "src/v2/models/user";

import { _GET, _POST, _PUT } from "src/helpers/http";
import { AlphaModal } from "src/v2/components/AlphaModal";
import { Button } from "src/v2/components/Button";
import { useStoreState } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";

interface Medication {
  active: boolean;
  added_by: User;
  consult: {
    completed_at: string;
    id: number;
  };
  created_at: string;
  id: number;
  is_otc: boolean;
  name: string;
  patient: User;
  source: string;
}

interface MedicationReportingProps {
  showSelfReportedOnly?: boolean;
}

const MedicationReporting: React.FC<MedicationReportingProps> = ({ showSelfReportedOnly }) => {
  const currentUser = useCurrentUser();
  const consult = useStoreState((state) => state.consults.currentConsultation);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openRemoveModal, setRemoveOpenModal] = useState<boolean>(false);
  const [selectMedRemove, setSelectMedRemove] = useState<Medication>();
  const [addMedName, setAddMedName] = useState<string>();
  const [medications, setMedications] = useState<Medication[]>([]);
  const [error, setError] = useState<string>("");

  const condition = consult?.subscription.condition.key;

  const fetchMedications = () => {
    if (currentUser?.id) {
      _GET(`/users/${currentUser?.id}/medications`)
        .then((res) => {
          if (!isEmpty(res)) {
            setMedications(res);
          }
        })
        .catch(Sentry.captureException);
    }
  };

  useEffect(() => {
    fetchMedications();
  }, [currentUser?.id]);

  const removeSelfReportedMed = ({ userId, medId }: { userId?: number; medId?: number }) => {
    _PUT(`/users/${userId}/medications/${medId}`)
      .then(() => fetchMedications())
      .catch(Sentry.captureException);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddMedName(event.target.value);
    setError("");
  };

  const addSelfReportedMed = ({ userId, medName }: { userId?: number; medName?: string }) => {
    _POST(`/users/${userId}/${condition}/self_reported_medications`, {
      self_reported_medications: [{ medication_details: medName }],
    })
      .then(() => fetchMedications())
      .catch(Sentry.captureException);
  };

  const filteredMeds = (source: string) =>
    filter(medications, (medication) => medication.source === source && medication.active);

  const selfReportedMeds = filteredMeds("self_reported");
  const alphaMeds = filteredMeds("alpha");

  const closeModal = () => {
    setOpenModal(false);
    setError("");
    setAddMedName("");
  };

  return (
    <div className="py-10">
      {/* Self Reported Meds */}
      {(!isEmpty(selfReportedMeds) || !isEmpty(alphaMeds)) && (
        <div className="pb-8">
          {!showSelfReportedOnly && <p className="font-bold pb-1">Reported by you</p>}
          <p>Add or remove medications that you are taking (including vitamins and supplements).</p>
          {map(selfReportedMeds, (medication, index) => (
            <div
              key={`${index}-${medication.name}`}
              className="flex flex-row p-3 my-3 items-center justify-between bg-grey-lightest"
            >
              <p>{medication.name}</p>
              <span
                onClick={() => {
                  setSelectMedRemove(medication);
                  setRemoveOpenModal(true);
                }}
                className="cursor-pointer fas fa-times  text-forest-40"
              />
            </div>
          ))}
        </div>
      )}
      <div
        className="flex flex-row items-center pb-8 cursor-pointer"
        onClick={() => setOpenModal(true)}
      >
        <span className="fas fa-plus-circle text-sky-dark " />
        <p className="ml-2 text-sky-dark">Report a medication</p>
      </div>
      {/* Alpha Prescriptions */}
      {!showSelfReportedOnly && !isEmpty(alphaMeds) && (
        <div className="pt-8 border-t-2 border-grey-2">
          <p className="font-bold">Prescribed by your Alpha Provider</p>
          <p className="mb-3">These can only be removed by contacting the Care Team.</p>
          {map(alphaMeds, (medication, index) => (
            <div
              key={`${index}-${medication.name}`}
              className="flex flex-row p-3 items-center bg-grey-lighter"
            >
              <p>{medication.name}</p>
            </div>
          ))}
        </div>
      )}
      {/* Add Medication Modal*/}
      <AlphaModal
        bgColor="white"
        closeIconColor="forest-40"
        className="max-w-xl mx-auto"
        cxPadding="0"
        isOpen={openModal}
        noCloseIcon={true}
        onRequestClose={closeModal}
      >
        <div className="flex flex-row justify-between items-center p-4 bg-sand-20">
          <p className="font-bold">Report a medication</p>
          <span onClick={closeModal} className="cursor-pointer fas fa-times  text-forest-40" />
        </div>
        <div className="h-10" />
        <div className="p-4">
          <input
            className="w-full focus:outline-none"
            name="self_reported_med_name"
            onChange={(e) => handleInputChange(e)}
            placeholder="Medication name"
          />
          <div className="border border-b border-grey-2" />
        </div>
        <div className="h-10" />

        <div className="flex flex-col justify-center items-center p-4">
          <Button
            onClick={() => {
              if (addMedName) {
                addSelfReportedMed({
                  userId: currentUser?.id,
                  medName: addMedName,
                });
                closeModal();
              } else {
                setError("Please enter a medication.");
              }
            }}
          >
            Save
          </Button>
          <div className="text-violator pt-3">{error || ""}</div>
        </div>
      </AlphaModal>
      {/* Remove Medication Modal*/}
      <AlphaModal
        bgColor="white"
        closeIconColor="forest-40"
        className="max-w-xl mx-auto"
        cxPadding="0"
        isOpen={openRemoveModal}
        noCloseIcon={true}
        onRequestClose={() => setRemoveOpenModal(false)}
      >
        <div className="flex flex-row justify-between items-center p-4 bg-sand-20">
          <p className="font-bold">Remove medication?</p>
          <span
            onClick={() => setRemoveOpenModal(false)}
            className="cursor-pointer fas fa-times  text-forest-40"
          />
        </div>
        <div className="h-10" />
        <div className="p-4">
          <p className="text-sm">
            Please confirm that you want to remove the following medication which you reported
            previously.
          </p>
          {selectMedRemove && <p className="font-bold mt-3">{selectMedRemove.name}</p>}
        </div>
        <div className="h-10" />

        <div className="flex flex-row justify-center items-center p-4">
          <Button
            onClick={() => {
              removeSelfReportedMed({ userId: currentUser?.id, medId: selectMedRemove?.id });
              setRemoveOpenModal(false);
            }}
          >
            Yes, remove it
          </Button>
        </div>
      </AlphaModal>
    </div>
  );
};

export default MedicationReporting;
