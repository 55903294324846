/** @format */

import React from "react";

import _ from "lodash";

import type { MedicationOrder } from "src/v2/types/medications";
import type { SkuMap } from "src/v2/types/skus";

import { TimelineCard } from "src/components/Cards/TimelineCard/TimelineCard";
import { getTimelineData } from "src/helpers/visitDetails";

interface YourOrdersCardsProps {
  medicationsByCondition?: MedicationOrder[];
  condition: string;
  skus: SkuMap;
}

export const YourOrdersCards = ({
  medicationsByCondition,
  skus,
  condition,
}: YourOrdersCardsProps) => {
  const threeMostRecentMedicationOrders =
    medicationsByCondition &&
    medicationsByCondition.filter((_medOrder: MedicationOrder, idx: number) => idx < 3);

  return (
    <div>
      {threeMostRecentMedicationOrders &&
        threeMostRecentMedicationOrders.map((medicationOrder) => {
          const sku = medicationOrder.sku || _.get(skus, medicationOrder.sku_key);
          return (
            <TimelineCard
              key={medicationOrder.id}
              currentStep={getTimelineData(medicationOrder, condition).currentStep}
              steps={getTimelineData(medicationOrder, condition).steps}
              title={sku ? sku.display_label || sku.name : medicationOrder.sku_key}
            />
          );
        })}
    </div>
  );
};
