/** @format */

import React from "react";

import {
  faCalendarStar,
  faCircleInfo,
  faLocationDot,
  faPills,
  faSquareDollar,
} from "@fortawesome/pro-regular-svg-icons";
import { faMapLocationDot, faTruckFast } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment/moment";

import { Pill, Typography } from "@alphamedical/components";

import type { OrderSchema } from "src/api";
import type { RecordAction } from "src/components/RecordAccordion/RecordAccordionActions";
import type { RecordValuesListProps } from "src/components/RecordAccordion/RecordValuesList";

import { RoundedFontAwesomeIcon } from "src/components/Icons/RoundedFontAwesomeIcon";
import { pluralizeWord } from "src/components/RecordAccordion/MedicationRecordAccordion";
import { RecordAccordionActions } from "src/components/RecordAccordion/RecordAccordionActions";
import { RecordValuesList } from "src/components/RecordAccordion/RecordValuesList";

interface OrderAccordionProps {
  order: OrderSchema;
}

const pillStyles: Partial<Record<string, { bgColor: string; textColor: string }>> = {
  Processing: {
    bgColor: "grey-20",
    textColor: "forest-120",
  },
  "Action Required": {
    bgColor: "forest-100",
    textColor: "white",
  },
  Shipped: {
    bgColor: "information",
    textColor: "white",
  },
  Delivered: {
    bgColor: "success",
    textColor: "forest-120",
  },
  Cancelled: {
    bgColor: "error",
    textColor: "white",
  },
  Upcoming: {
    bgColor: "grey-160",
    textColor: "white",
  },
};

const orderStatusMap: Partial<Record<string, string>> = {
  UPCOMING: "Upcoming",
  OPENED: "Processing",
  PAYMENT_ERROR: "Action Required",
  PHARMACY_PROCESSING: "Processing",
  PHARMACY_ERROR: "Processing",
  PENDING_SHIPMENT: "Processing",
  SHIPPED: "Shipped",
  DELIVERED: "Delivered",
  CANCELLED: "Cancelled",
  UNKNOWN: "Processing",
  SHIPMENT_FAILURE: "Shipped",
};

export const OrderAccordion = ({ order }: OrderAccordionProps) => {
  const product = order.products ? order.products[0] : undefined;

  const [valueList, setValueList] = React.useState<RecordValuesListProps[]>([]);
  const [actions, setActions] = React.useState<RecordAction[]>([]);

  React.useEffect(() => {
    let cost = order.adjustments
      ? order.adjustments.find((adjustment) => adjustment.type === "charge")?.unit_amount_in_cents
      : 0;

    if (!cost) {
      cost = order.products?.reduce((total, product) => {
        return total + (product.cost_in_cents ? product.cost_in_cents : 0);
      }, 0);
    }

    const paymentPreference = order.payment_preference;

    if (order.tracking_url) {
      setActions([
        {
          key: "track",
          label: "Track",
          iconClassName: "text-cornflower-100",
          icon: faMapLocationDot,
        },
      ]);
    }

    const list = [];
    if (order.delivered_at) {
      const deliveryText = `Delivered on ${moment(order.delivery_time).format("MMMM DD, YYYY")}`;
      list.push({
        id: "delivery_time",
        icon: faCalendarStar,
        text: deliveryText,
      });
    }

    const formattedCost = cost ? `$${(cost / 100).toFixed(2)}` : "";
    list.push({
      id: "price",
      icon: faSquareDollar,
      text: (
        <div className="text-forest-darker text-sm">
          {paymentPreference === "INSURANCE" ? "Copay" : formattedCost}{" "}
          <span className="text-grey-160">(Free shipping)</span>
        </div>
      ),
    });

    if (product && product.days_supply) {
      list.push({
        id: "supply",
        icon: faPills,
        text: `${product.days_supply} ${pluralizeWord("day", product.days_supply)} supply`,
      });
    }

    if (order.delivered_to) {
      list.push({
        id: "delivered-to",
        icon: faLocationDot,
        text: (
          <a
            href={`https://maps.google.com/?q=${[
              order.delivered_to.address,
              order.delivered_to.city,
              order.delivered_to.state,
              order.delivered_to.zipcode,
            ].join(", ")}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography variant="h5" className="text-cornflower-100">
              <div>{order.delivered_to.address}</div>
              <div>
                {order.delivered_to.city}, {order.delivered_to.state}, {order.delivered_to.zipcode}
              </div>
            </Typography>
          </a>
        ),
      });
    }

    if (order.number) {
      list.push({
        id: "order-number",
        icon: faCircleInfo,
        text: `${order.number}`,
      });
    }
    setValueList(list);
  }, []);

  const orderStatus =
    order.status && orderStatusMap[order.status] ? orderStatusMap[order.status] : undefined;
  const bgColor = orderStatus ? pillStyles[orderStatus]?.bgColor : undefined;
  const textColor = orderStatus ? pillStyles[orderStatus]?.textColor : undefined;

  return (
    <RecordAccordionActions
      IconElement={
        <RoundedFontAwesomeIcon
          backgroundClassName={"bg-grey-20"}
          iconClassName={"text-primary"}
          icon={faTruckFast}
        />
      }
      HeaderRightElement={
        orderStatus && (
          <div className={"mr-4 flex items-center"} style={{ whiteSpace: "nowrap" }}>
            <Pill bgColor={bgColor} textColor={textColor} cxFontSize={"text-xs font-medium"}>
              {orderStatus}
            </Pill>
          </div>
        )
      }
      preventFooterCollapse={true}
      title={product ? product.name : "N/A"}
      actions={actions}
      onActionClick={() => {
        window.open(order.tracking_url, "_blank");
      }}
    >
      <RecordValuesList rowData={valueList} />
    </RecordAccordionActions>
  );
};
