/** @format */

import { useHistory } from "react-router-dom";

import { Typography } from "@alphamedical/components";

import type { SynchronousVisitSchema } from "src/api";

import { guessTimezone } from "src/utils/date_utils";
import { SyncVisitCard } from "src/v2/routes/Visit/SyncVisitCard";

interface SynchronousVisitsListProps {
  scheduledCalls: SynchronousVisitSchema[];
}

const SynchronousVisitsList = ({ scheduledCalls }: SynchronousVisitsListProps) => {
  const timezone = guessTimezone() || "UTC";
  const history = useHistory();
  return (
    <div className="mb-4">
      {scheduledCalls && scheduledCalls.length > 0 && (
        <>
          <Typography className="mb-4 text-grey-160" variant="body2">
            You'll receive an email with instructions to join.
          </Typography>
          <div>
            {scheduledCalls.map((scheduledCall) => {
              return (
                <SyncVisitCard
                  key={scheduledCall.id}
                  syncVisit={scheduledCall}
                  callBack={() => history.push(`/sync-visit-details/${scheduledCall.consult_id}`)}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default SynchronousVisitsList;
