/** @format */

import React from "react";
import { Form } from "react-final-form";

import { Typography } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import { PasswordField } from "src/v2/components/PasswordField";
import { validatePasswordForm } from "src/v2/utils/auth/password";

interface Props {
  defaultValues: {
    // Currently, there's no legit way to change the email.
    // Therefore, do not include it in the satate, it's diplay only.
    email: string;
    password: string;
    passwordConfirm: string;
  };
  isSubmitting: boolean;
  error: any;
  onSubmit?: any;
}

// TODO: Move components/Forms, convert to functional component with hooks, switch to easy-peasy and remove redux
class FormLoginDetails extends React.Component<Props, object> {
  constructor(props: any) {
    super(props);

    this.state = this.props.defaultValues;
  }

  handleSubmit = (values: any) => {
    this.props.onSubmit(values);
  };

  renderError() {
    if (this.props.error !== null) {
      return <p className="text-red">Updating the password failed. Please try again.</p>;
    }

    return null;
  }

  render() {
    return (
      <Form
        onSubmit={this.handleSubmit}
        validate={validatePasswordForm}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className="my-8">
            <div className="mb-4">
              <Typography variant="body2">Email</Typography>
              <Typography variant="body1">{this.props.defaultValues.email}</Typography>
            </div>
            <PasswordField
              label={"New password"}
              name={"password"}
              placeholder={"New password (12+ characters)"}
              required={true}
              className="mb-4"
            />
            <PasswordField
              name="passwordConfirm"
              required={true}
              placeholder="Confirm New Password"
              label="Confirm new password"
              className="mb-8"
            />
            <ButtonPdb type="submit" className="w-full" disabled={submitting}>
              {this.props.isSubmitting ? "Saving..." : "Save"}
            </ButtonPdb>
            {this.renderError()}
          </form>
        )}
      />
    );
  }
}

export default FormLoginDetails;
