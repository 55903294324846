/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConsultAnswerSchema } from '../models/ConsultAnswerSchema';
import type { ConsultsAssignClinicianToConsultRequestBodySchema } from '../models/ConsultsAssignClinicianToConsultRequestBodySchema';
import type { ConsultsChangeConsultPharmacyRequestBodySchema } from '../models/ConsultsChangeConsultPharmacyRequestBodySchema';
import type { ConsultsCreateConsultRequestBodySchema } from '../models/ConsultsCreateConsultRequestBodySchema';
import type { ConsultsSignConsentRequestBodySchema } from '../models/ConsultsSignConsentRequestBodySchema';
import type { ConsultsSubmitConsultRequestBodySchema } from '../models/ConsultsSubmitConsultRequestBodySchema';
import type { ConsultsUpdateAnswersRequestBodySchema } from '../models/ConsultsUpdateAnswersRequestBodySchema';
import type { ConsultsUpdateConsultFileRequestBodySchema } from '../models/ConsultsUpdateConsultFileRequestBodySchema';
import type { ConsultsUpdateConsultRequestBodySchema } from '../models/ConsultsUpdateConsultRequestBodySchema';
import type { GenericConsultPharmacyAddressSchema } from '../models/GenericConsultPharmacyAddressSchema';
import type { LegacyAssessmentPlanSchema } from '../models/LegacyAssessmentPlanSchema';
import type { LegacyConsultSchema } from '../models/LegacyConsultSchema';
import type { PaymentSummarySchema } from '../models/PaymentSummarySchema';
import type { ProfileSchema } from '../models/ProfileSchema';
import type { TreatmentPlanInfoSchema } from '../models/TreatmentPlanInfoSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConsultsService {

    /**
     * @returns ConsultAnswerSchema
     * @throws ApiError
     */
    public static getHealthHistory({
        userId,
    }: {
        /**
         * The ID of the user.
         */
        userId: string,
    }): CancelablePromise<Array<ConsultAnswerSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/consults/{user_id}/health_history',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns LegacyConsultSchema
     * @throws ApiError
     */
    public static getConsultsForUser({
        userId,
        conditions,
        omitStatus,
        sort,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        /**
         * Comma separated list of conditions.
         */
        conditions?: string,
        /**
         * Consult status(es) to omit
         */
        omitStatus?: Array<string>,
        /**
         * Direction to sort the consult created at field.
         */
        sort?: 'asc',
    }): CancelablePromise<Array<LegacyConsultSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/users/{user_id}/consults',
            path: {
                'user_id': userId,
            },
            query: {
                'conditions': conditions,
                'omit_status': omitStatus,
                'sort': sort,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static createConsult({
        userId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        requestBody: ConsultsCreateConsultRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/users/{user_id}/consults',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LegacyConsultSchema
     * @throws ApiError
     */
    public static getConsult({
        userId,
        consultId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        consultId: string,
    }): CancelablePromise<LegacyConsultSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/users/{user_id}/consults/{consult_id}',
            path: {
                'user_id': userId,
                'consult_id': consultId,
            },
        });
    }

    /**
     * Updates the status and/or answers of a consult and takes appropriate action based on the new status
     * Set the new consults status
     * If the new status is Approved or Denied
     * Create a Log event if the consult was approved or denied
     * Close all open tasks
     * If the new status is Needs Followup
     * Snooze tasks to wait for patient response
     * @returns any No response format defined
     * @throws ApiError
     */
    public static updateConsult({
        userId,
        consultId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        consultId: string,
        requestBody: ConsultsUpdateConsultRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v2/users/{user_id}/consults/{consult_id}',
            path: {
                'user_id': userId,
                'consult_id': consultId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static updateAnswers({
        userId,
        consultId,
        requestBody,
        skipValidators,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        consultId: number,
        requestBody: ConsultsUpdateAnswersRequestBodySchema,
        /**
         * A list of string values that identify which validators should be skipped when saving. Used for 'soft-stop' requirements that allow a user to continue anyway
         */
        skipValidators?: Array<string>,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v2/users/{user_id}/consults/{consult_id}/answers',
            path: {
                'user_id': userId,
                'consult_id': consultId,
            },
            headers: {
                'Skip-Validators': skipValidators,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static assignClinicianToConsult({
        userId,
        consultId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        consultId: string,
        requestBody: ConsultsAssignClinicianToConsultRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v2/users/{user_id}/consults/{consult_id}/assign_clinician_to_consult',
            path: {
                'user_id': userId,
                'consult_id': consultId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Filter list of clinicians based on state, vacation, etc.
     * ---
     * @returns ProfileSchema
     * @throws ApiError
     */
    public static getCliniciansForConsult({
        userId,
        consultId,
        includeDefaultSub,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        consultId: string,
        /**
         * If true the list of clinicians will include the default substitute.
         */
        includeDefaultSub?: string,
    }): CancelablePromise<Array<ProfileSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/users/{user_id}/consults/{consult_id}/available_clinicians',
            path: {
                'user_id': userId,
                'consult_id': consultId,
            },
            query: {
                'include_default_sub': includeDefaultSub,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getConsultFee({
        userId,
        consultId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        consultId: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/users/{user_id}/consults/{consult_id}/consult-fee',
            path: {
                'user_id': userId,
                'consult_id': consultId,
            },
        });
    }

    /**
     * @returns PaymentSummarySchema
     * @throws ApiError
     */
    public static getConsultCost({
        userId,
        consultId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        consultId: number,
    }): CancelablePromise<PaymentSummarySchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/users/{user_id}/consults/{consult_id}/cost',
            path: {
                'user_id': userId,
                'consult_id': consultId,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static updateConsultFile({
        userId,
        consultId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        consultId: string,
        requestBody: ConsultsUpdateConsultFileRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v2/users/{user_id}/consults/{consult_id}/file',
            path: {
                'user_id': userId,
                'consult_id': consultId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GenericConsultPharmacyAddressSchema
     * @throws ApiError
     */
    public static getUsersConsultPharmacy({
        userId,
        consultId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        consultId: number,
    }): CancelablePromise<Array<GenericConsultPharmacyAddressSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/users/{user_id}/consults/{consult_id}/pharmacies',
            path: {
                'user_id': userId,
                'consult_id': consultId,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static changeConsultPharmacy({
        userId,
        consultId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        consultId: number,
        requestBody: ConsultsChangeConsultPharmacyRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v2/users/{user_id}/consults/{consult_id}/pharmacy',
            path: {
                'user_id': userId,
                'consult_id': consultId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LegacyAssessmentPlanSchema
     * @throws ApiError
     */
    public static getConsultPvs({
        userId,
        consultId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        consultId: number,
    }): CancelablePromise<Array<LegacyAssessmentPlanSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/users/{user_id}/consults/{consult_id}/pvs',
            path: {
                'user_id': userId,
                'consult_id': consultId,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getConsultReport({
        userId,
        consultId,
        format,
        style,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        consultId: string,
        format?: string,
        style?: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/users/{user_id}/consults/{consult_id}/report',
            path: {
                'user_id': userId,
                'consult_id': consultId,
            },
            query: {
                'format': format,
                'style': style,
            },
        });
    }

    /**
     * Endpoint for signing consent forms.
     * Parameters:
     * - user_id (str): ID of the user.
     * - consult_id (str): ID of the consult.
     * - child_id (str): Optional. ID of the child
     *
     * Returns:
     * - JSON: Consultation data in JSON format.
     *
     * Raises:
     * - ApiError: If at least one consent form is not provided in the request.
     * @returns any No response format defined
     * @throws ApiError
     */
    public static signConsent({
        consultId,
        userId,
        requestBody,
        childId,
    }: {
        /**
         * The ID of the consult.
         */
        consultId: string,
        /**
         * The ID of the user.
         */
        userId: string,
        requestBody: ConsultsSignConsentRequestBodySchema,
        /**
         * The ID of the child (optional).
         */
        childId?: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/users/{user_id}/consults/{consult_id}/sign_consent',
            path: {
                'consult_id': consultId,
                'user_id': userId,
            },
            query: {
                'child_id': childId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static submitConsult({
        userId,
        consultId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        consultId: string,
        requestBody: ConsultsSubmitConsultRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/users/{user_id}/consults/{consult_id}/submit',
            path: {
                'user_id': userId,
                'consult_id': consultId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns TreatmentPlanInfoSchema
     * @throws ApiError
     */
    public static getPvs({
        userId,
        pvsId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        pvsId: number,
    }): CancelablePromise<TreatmentPlanInfoSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/users/{user_id}/pvs/{pvs_id}/summary',
            path: {
                'user_id': userId,
                'pvs_id': pvsId,
            },
        });
    }

    /**
     * @returns TreatmentPlanInfoSchema
     * @throws ApiError
     */
    public static getUserPvsInfo({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<Array<TreatmentPlanInfoSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/users/{user_id}/userpvs',
            path: {
                'user_id': userId,
            },
        });
    }

}
