/** @format */

import type { RouteComponentProps } from "react-router-dom";

import React from "react";
import { withRouter } from "react-router-dom";

import cn from "classnames";

import type { FollowupItem } from "src/components/Popups/types";
import type { InsuranceFormValues } from "src/v2/components/Forms/Insurance/InsuranceForm";

import { useGetInsurance } from "src/utils/hooks";
import { AlphaModal } from "src/v2/components/AlphaModal";
import { Button } from "src/v2/components/Button";
import InsuranceForm from "src/v2/components/Forms/Insurance/InsuranceForm";
import { useStoreDispatch } from "src/v2/models";

export interface InsuranceApprovalPopupProps extends RouteComponentProps {
  item: FollowupItem;
  showPopup: boolean;
  setShowPopup: (arg: boolean) => void;
  key?: number | string;
  pii?: any;
  profile?: any;
  showContactInsurance?: boolean;
}

export const renderInsuranceDetails = (followUpItem: FollowupItem) => (
  <ul className="mb-4">
    <li>Member ID: {followUpItem.cardholder_id}</li>
    <li>RxBIN: {followUpItem.rx_bin}</li>
    <li>RxPCN: {followUpItem.rx_pcn}</li>
    <li>RxGroup: {followUpItem.group_number}</li>
  </ul>
);

const InsuranceApprovalPopup: React.FC<InsuranceApprovalPopupProps> = ({
  item: { followup_type, medication_name, order_id, cardholder_id, rx_bin, rx_pcn, group_number },
  showPopup,
  setShowPopup,
  pii,
  profile,
  history,
  showContactInsurance,
  children,
}) => {
  const insurance = useGetInsurance() || {};
  const [showConfirm, setShowConfirm] = React.useState<boolean>(false);
  const [showForm, setShowForm] = React.useState<boolean>(false);
  const dispatch = useStoreDispatch();
  const verifyNowOrVerifyLater = (
    approved: boolean,
    insuranceData?: InsuranceFormValues,
    contactInsurance?: boolean,
  ) => {
    if (approved) {
      // verify now
      dispatch.profile.sendOrderApproval({
        approved,
        order_id,
        medication_name,
        followup_type,
        insuranceData,
      });
      setShowPopup(false);
    } else {
      // verify later
      dispatch.profile.sendOrderApproval({
        approved,
        order_id,
        followup_type,
        medication_name,
        contactInsurance,
      });
    }
  };

  const validation = (values: any) => {
    const errors: any = {};
    if (!values.cardholder_id) {
      errors.cardholder_id = "Required";
    }
    if (!values.rx_pcn) {
      errors.rx_pcn = "Required";
    }
    return errors;
  };

  return (
    <div>
      <AlphaModal
        isOpen={showPopup && !profile.hasSeenPopup}
        className="max-w-sm m-auto bg-white shadow-md"
        cxPadding="p-8"
        addPaddingForNavBar={true}
        onRequestClose={() => {
          !showConfirm && setShowPopup(false);
        }}
      >
        {!showConfirm && !showForm && (
          <div className="flex flex-col text-16">
            <p className="font-700">
              Hi {(pii && pii.preferred_firstname) || (pii && pii.first_name)},
            </p>
            {children}
            <p className="mb-6">
              Sincerely,
              <br />
              The Alpha Care Team
            </p>
            <div className={cn("flex", { "flex-wrap": showContactInsurance })}>
              <Button
                extraClassNames={cn("btn btn-violator cursor-pointer", {
                  "mr-4": !showContactInsurance,
                })}
                disabled={profile.postingApproval}
                onClick={() => setShowForm(true)}
              >
                Verify Now
              </Button>
              <Button
                extraClassNames={`
                  text-center
                  border-2 border-primary
                  hover:text-white
                  items-center cursor-pointer
                `}
                disabled={profile.postingApproval}
                onClick={() => {
                  verifyNowOrVerifyLater(false);
                  setShowConfirm(true);
                }}
              >
                Verify Later
              </Button>
              {showContactInsurance && (
                <Button
                  extraClassNames="btn btn-violator cursor-pointer"
                  disabled={profile.postingApproval}
                  onClick={() => {
                    verifyNowOrVerifyLater(false, undefined, true);
                    setShowPopup(false);
                  }}
                >
                  I'll Contact my Insurance
                </Button>
              )}
            </div>
            {profile.error && <p className="text-red">{profile.error}</p>}
          </div>
        )}
        {showConfirm && (
          <div className="bg-white m-auto">
            <p className="text-18 text-center">
              We just sent you a message with a link where you will be able to verify your insurance
              information at a later time.
            </p>
            <p className="text-16 text-center">
              <strong>
                Please note: <br />
              </strong>{" "}
              It may take up to 30 seconds for the message to arrive.
            </p>

            <div className="flex flex-col text-16">
              <Button
                extraClassNames=""
                disabled={profile.postingApproval}
                onClick={() => {
                  setShowPopup(false);
                  setShowConfirm(false);
                  history.push("/messages");
                  dispatch.profile.fetchProfile({ include: ["followup_items", "pii"] });
                }}
              >
                Take me to my messages
              </Button>
            </div>
          </div>
        )}

        {showForm && (
          <div>
            <div
              className={`
                absolute top-0 left-0 fas fa-arrow-left text-h5 p-5 mt-1 cursor-pointer text-primary-60 hover:text-primary
            `}
              onClick={() => setShowForm(false)}
            />
            <div>
              <InsuranceForm
                onSubmit={(values) => {
                  verifyNowOrVerifyLater(true, values);
                  setShowForm(false);
                }}
                initialValues={insurance}
              />
            </div>
          </div>
        )}
      </AlphaModal>
    </div>
  );
};

export default withRouter(InsuranceApprovalPopup);
