/** @format */

import { isObject } from "lodash";

import type { OptionType } from "@alphamedical/components";

/** The root object type of the data returned when fetching the outline data */
export interface RawDynamicOutline {
  /** Identifies the service/condition this outline is for (e.g. "birthcontrol").  */
  key: string;
  /** A list of sections of the outline. Each section will contain one or more pages.  */
  stages: OutlineStage[];
}

/** A group of form pages */
export interface OutlineStage {
  /** A unique string identified for the stage */
  key: string;
  /** The display text used to identify this stage */
  title: string;
  /** The list of pages to be shown during this stage */
  pages: (string | RawDynamicPageData)[];
}

/** Represents page data that is in the raw json files. Changes the fields property so that it can be a string */
export type RawDynamicPageData = Omit<DynamicFormPage, "fields" | "stage"> & {
  fields: (string | DynamicField)[];
};

/** Page Object Types  */

/** The main type for a page in a dynamic form */
export type DynamicFormPage = BaseFormPage | ConsentPage;

/** Represents a single page in a multi page page.  */
export interface BaseFormPage {
  /** An optional identifier for the page, not currently used for anything */
  q?: number | string;

  /** A unique string identifier for the page, usually matches the field key of the first field on the page */
  key: string;

  /** The string identifier of the stage is this page a part of */
  stage?: string;

  /** Used to convey what type this page is and if a custom component is needed. */
  type?: "consent" | "consent-v2" | "address";

  /** The title displayed at the top of the page */
  title?: string;

  banner?:
    | {
        imageUrl: string;
      }
    | ContentReference;

  /** The text to be displayed below the title */
  subtitle?: string;

  /** Longer descriptive text that can be shown for the page */
  description?: DynamicContent;

  /** The list of fields to display on this page */
  fields: DynamicField[];

  /** The label to display in the next button instead of the default. */
  overrideNextText?: string;

  /** The background color to use for the next button. */
  overrideButtonColor?: string;

  /** The content to be displayed beneath the page submit button */
  footer?: DynamicContent;

  /** A collection of events that should be triggered when a page is loaded and first displayed */
  onBefore?: EventCallbacks;

  /** A collection of events that should be triggered before a page is submitted (e.g. analytics, validation, et.c). */
  beforeSubmit?: EventCallbacks;

  /** A collection of events that should be triggered before a page is submitted (e.g. analytics, validation, et.c).
   * @deprecated Use beforeSubmit instead*/
  onSubmit?: EventCallbacks;

  /** A collection of events that should be triggered after a successful submission of a page */
  onAfter?: EventCallbacks;

  /** Used to determine if the skip button should show */
  allowSkip?: boolean;

  /** If functionality for skipping forward in an multi page form to the first unanswered question is supported, this flag can
   * be set to true to always stop at this page. Used in the past for the eligbility page which potentially could have
   * all of its questions pre-answered from previous consults
   */
  cannotBeSkippedIfAnswered?: boolean;

  /** A flag to determine if the first/last name fields should be shown on this page if there is a previously approved
   * consult.
   * @deprecated If at all posible use a custom conditional method instead
   * @todo Figure out a generalized way to handle this. */
  showNameFields?: boolean;

  /** Should the intercom widget be shown.
   * @deprecated We've removed our integration with Intercom, this flag now does nothing
   */
  showIntercom?: boolean;

  /** Set to true when a custom component will be used for the page and if that component should render its own next
   * button.
   * @deprecated Use the overrideNextText and overrideButtonColor properties instead to customize the next button
   */
  customNext?: boolean;

  /** Legacy property to set the next label text only when the form is prisine.
   * https://final-form.org/docs/final-form/types/FormState#pristine
   * @deprecated No longer in use, can be ignored
   */
  overridePristineNextText?: string;

  /** A list of fields that require a certain value before proceeding.
   * @todo Refactor this to be both at the field level and a more general system for validating values
   */
  mustHaveValues?: {
    fieldKey: string;
    value: string;
  }[];
}

/** Special page type that loads and displays a consent document based on a slug */
export interface ConsentPage extends BaseFormPage {
  type: "consent" | "consent-v2";

  /** The identifier/slug of the consent form to load and display */
  consentFormKey: string;
}

export function isConsentPage(page: BaseFormPage): page is ConsentPage {
  return page.type === "consent" || page.type === "consent-v2";
}

/** Field Object Types */

/** The root field type, combines all potentialy variants that a field can have */
export type DynamicField =
  | BaseDynamicField
  | DynamicTextField
  | DynamicOptionField
  | DynamicNumberField
  | DynamicFileField
  | DynamicProductField
  | DynamicAddressField
  | DynamicTimeSlotField;

export interface BaseDynamicField {
  /** A unique string identifier for this field. Will be what is used to identify this field when storing responses. */
  key: string;

  /** An optional identifier for the page, not currently used for anything */
  q?: number | string;

  /** Whether or not a value needs to be set in this field before proceeding.
   * @default true
   */
  required?: boolean;

  /** The field type. Used to determine which field component to use and what data type to use for the value */
  type:
    | "text"
    | "select"
    | "radio"
    | "number"
    | "height"
    | "file"
    | "spacer"
    | "info"
    | "payment"
    | "product"
    | "address"
    | "allergy-search-field"
    | "counter-field"
    | "BloodLossReporting"
    | "problem-search-field"
    | "accordion"
    | "component";

  /** A list of tags that can be used to identify a field later. Typically used by the backend to group and process
   * related fields (e.g. find and process all allergy related fields)
   */
  tags?: string[];

  /** The main text/prompt for this field */
  title?: string;

  /** The text to be shown below the title */
  subtitle?: DynamicContent;
  /** Longer descriptive text to be shown around the field */
  description?: DynamicContent;

  /** The content to be shown below the field */
  footer?: DynamicContent;

  /** The conditions that determine if this field should be shown. */
  conditionals?: FieldCondition[];

  /** The name of a custom component to use to render this field. */
  component?: string;

  /** A default label for this field, typically used as the placeholder text */
  label?: string;

  /** Placeholder text for a field without a user input */
  placeholder?: string;

  /** The default value that should be used in this field if no initial value is provider */
  defaultValue?: string;

  /** Allows for arbitrary text to be added as a comment on the field. Not used anywhere, but is useful since JSON
   * does not support real comments
   */
  comment?: string;

  /** Controls what type of question this is. Primarily used to determine how the backend should store/process the answer
   * @default consult
   */
  category?: "pii" | "subscription" | "billing" | "insurance" | "emergency_contact" | "consult";

  /** A string identifier to say which parse function should be used on this field */
  parse?: string;

  /** A string identifier to say which validation function should be used on this field */
  validate?: string;

  /** If set to true will hide the title of the field */
  hideTitle?: boolean;

  /** If set to true field will have repeated question styling */
  repeatedQuestionStyling?: boolean;

  /** The order the field appears in the outline */
  position?: number;
}

export interface DynamicTextField extends BaseDynamicField {
  type: "text";

  /** The field subtype. Used to further define which component should be used for the field.
   * The bloodpressure subtype is deprecated, it can be accomplished by setting the parse and validate properties.
   * The rx_bin subtype is deprecated, it can be accomplished by setting the validate property.
   */
  subtype?: "date" | "textarea" | "phone" | "bloodpressure" | "rx_bin" | "zipcode";

  /** The minimum required length of text (inclusive) */
  minLength?: number;
  maxLength?: number;

  /** Only for date subtype: Include past dates in the date picker options. */
  includePastDates?: boolean;
  /** Only for date subtype: Include future dates in the date picker options. */
  includeFutureDates?: boolean;
  /** Only for date subtype: Include current date in the date picker options. */
  includeCurrentDate?: boolean;
}

export const isTextField = (field: BaseDynamicField): field is DynamicTextField =>
  field.type === "text";

export interface DynamicOptionField extends BaseDynamicField {
  type: "select" | "radio";
  subtype?: "checkbox" | "dropdown" | "images" | "multi-dropdown";

  /** A string identifier of the function to call to dynamically load the list of options  */
  optionLoader?: string;

  /** The list of options to use for this field */
  options: {
    value: string;
    label: string;
  }[];

  /** The name of a custom component to use to render each option. Only used for radio and checkbox fields */
  optionComponent?: string;

  /** Should this field be treated as a binary toggle field */
  toggle?: boolean;

  showSelectAllThatApply?: boolean;

  /** Determine if the user can type in the input to search the options. Only used for single and multi dropdowns. */
  isSearchable?: boolean;
}

export function isOptionField(field: BaseDynamicField): field is DynamicOptionField {
  return ["select", "radio"].indexOf(field.type) !== -1;
}

export function isOption(option: any): option is OptionType {
  return isObject(option) && option.value != null && option.label != null;
}

export interface DynamicImageOptionField extends Omit<DynamicOptionField, "toggle"> {
  subtype: "images";

  /** The list of options to use for this field */
  options: {
    value: string;
    label: string;

    /** The url of the image to display for this option */
    image?: string;

    /** The layout of the image
     * @default cover
     */
    backgroundSize?: "contain" | "cover";

    /** The cost of the product being listed, if applicable */
    cost?: string;

    /** The subtext to be displayed below the label */
    sublabel?: string;
  }[];
}

export const isImageOptionField = (field: BaseDynamicField): field is DynamicImageOptionField =>
  isOptionField(field) && field.subtype === "images";

export interface DynamicNumberField extends BaseDynamicField {
  type: "number";

  subtype: "counter" | null;

  placeholder?: string;

  /** The maximum value this field should be allowed to have */
  max?: number;

  /** The minimum value this field should be allowed to have */
  min?: number;

  /** The units that represents the values in this field. */
  units?: string;

  /** The number of decimal places to allow */
  decimalNumber?: number;
}

export function isNumberField(field: BaseDynamicField): field is DynamicNumberField {
  return field.type === "number";
}

export interface DynamicFileField extends BaseDynamicField {
  type: "file";

  /** The placeholder image to display on the file input if no file is selected */
  imageUrl?: string;

  /** The hyperlink placeholder text to display on the file input if no file is selected
   */
  hyperlink?: string;

  /** The placeholder text to display on the file input if no file is selected
   */
  message?: string;

  /** A comma separate list of mimetypes that is added to the file input to limit
   * what kinds of files are allowed.
   * @default "image/*,application/pdf"
   */
  accepts?: string;

  /**
   * An integer that corresponds to one of the FileField component design versions
   */
  componentVersion?: number;
}

export interface DynamicTimeSlotField extends BaseDynamicField {
  days?: number;
}

export function isFileField(field: BaseDynamicField): field is DynamicFileField {
  return field.type === "file";
}

export interface DynamicProductField extends BaseDynamicField {
  type: "product";

  /** The sku of the product to display in this field */
  sku: string;
}

export function isProductField(field: BaseDynamicField): field is DynamicProductField {
  return field.type === "product";
}

export interface DynamicAddressField extends BaseDynamicField {
  type: "address";
  /** What kind of address is being asked for.
   * @default PHARMACY
   */
  address_type?: "PHARMACY" | "LAB" | "SHIPPING";
}

export function isAddressField(field: BaseDynamicField): field is DynamicAddressField {
  return field.type === "address";
}

/** Represents a group of callbacks identified by some string value that should be triggered during some event */
export interface EventCallbacks {
  /** A list of general functions that should be run when the event is triggered. Currently only form validation
   * callbacks are supported.
   */
  trigger?: {
    type: "validate";
    id: string;
  }[];
}

export type ContentReference = {
  "#ref": {
    // Currently only references to custom components are supported
    type: "component";
    name: string;
  };
};

export const isContentReference = (data: Record<string, any>): data is ContentReference =>
  !!data["#ref"];

/** A data type to support either normal text or a reference to other content (e.g. some custom component).
 * If the content is a string, it should be assumed that there can be html elements inside it. */
export type DynamicContent = string | ContentReference;

/** The different condition formats that can be used to define when to display a field */
export type FieldCondition = GenericFieldCondition | NestedFieldCondition | CustomFieldCondition;

/** Allows to define a condition based on static values and references to other fields */
export interface GenericFieldCondition {
  /** The key of the field to check when evaluating this condition */
  key: string;

  /** Used when this condition should be true if the field referenced by `key` matches this value.
   * For all examples assume that the following values are in the form state
   * { fieldA : "a" }
   * @example { key: "fieldA", value: "a" } Should evaluate to true
   * @example { key: "fieldA", value: "b" } Should evaluate to false
   * @example { key: "fieldB": value: "a" } Should evaluate to false because fieldB does not exist (is null)
   */
  value?: string;

  /** When true, this condition should be true if the field referenced by `key` does not matche the `value` property.
   * This can only be used in conjunction with the `value` property, it is ignored when using anyOf or noneOf. If the
   * field value is an array, the condition will be false if atleast one value in the array matches this condition.
   * If the field value is false, the condition will be true even though the values do not match, this is because otherwise
   * unanswered fields will be hidden.
   * @default false
   *
   * For all examples assume that the following values are in the form state
   * { fieldA : "a" }
   * @example { key: "fieldA", value: "a", not: true } Should evaluate to false
   * @example { key: "fieldA", value: "b", not: true } Should evaluate to true
   * @example { key: "fieldB": value: "a", not: true } Should evaluate to true because fieldB does not exist (is null)
   */
  not?: boolean;

  /** Used when this condition should be true if the field referenced by `key`matches any one of these values. If the field value
   * is also an array, then the condition should be true if there is at least one overlapping value between the two lists.
   *
   * For all examples assume that the following values are in the form state
   * { fieldA : "a" }
   * @example { key: "fieldA", anyOf: ["a"] } Should evaluate to true
   * @example { key: "fieldA", anyOf: ["a", "b"] } Should still evaluate to true
   * @example { key: "fieldA", anyOf: ["c"] } Should evaluate to false
   */
  anyOf?: string[];

  /** Used when this condition should be true if the field referenced by `key` matches none of these values. If the field value
   * is also an array, then the condition should be true if there are no overlapping value between the two lists.
   *
   * For all examples assume that the following values are in the form state
   * { fieldA : "a" }
   * @example { key: "fieldA", noneOf: ["a"] } Should evaluate to false
   * @example { key: "fieldA", noneOf: ["a", "b"] } Should still evaluate to flase
   * @example { key: "fieldA", noneOf: ["c"] } Should evaluate to true
   */
  noneOf?: string[];
}

export function isGenericFieldCondition(
  condition: FieldCondition,
): condition is GenericFieldCondition {
  return (condition as any).key != null;
}

/** Allows for nesting different conditions together with different boolean operators */
export interface NestedFieldCondition {
  /** Which boolean operator to use when evaluating the list of conditions
   * @default and
   */
  type?: "and" | "or";
  /** The list of conditions that should be evaluated */
  conditionals: FieldCondition[];
}

export function isNestedFieldCondition(
  condition: FieldCondition,
): condition is NestedFieldCondition {
  return (condition as any).conditionals != null;
}

/** A condition that represents a custom functiont that should return either true or false  */
export interface CustomFieldCondition {
  /** A string identifier of the function to call for this condition */
  method: string;
  minAge?: number;
  maxAge?: number;
  not?: boolean;
  score_to_test?: string;
  value?: string;
  anyOf?: string[];
  noneOf?: string[];
}

export function isCustomFieldCondition(
  condition: FieldCondition,
): condition is CustomFieldCondition {
  return (condition as any).method != null;
}
