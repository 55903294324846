/** @format */

import React from "react";

interface RadioPdbProps {
  color?: string;
  selected?: boolean;
  className?: string;
}

export const RadioPdb = ({ color = "#1C4A46", selected = false, className }: RadioPdbProps) => (
  <div className={className}>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="11" stroke={color} strokeWidth="2" />
      {selected && <circle cx="20" cy="20" r="6.5" fill={color} />}
    </svg>
  </div>
);
