/** @format */

import React from "react";

import * as Sentry from "@sentry/react";

import type { Address, LegacyConsultSchema } from "src/api";

import { UsersService } from "src/api";
import { BackButtonHeading } from "src/components/_buttons/BackButtonHeading";
import ReadOnlyAddress from "src/components/ReadOnlyAddress";
import { isLegacyAddress } from "src/v2/constants";
import { useStoreDispatch, useStoreState } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";

interface DiabetesLabTestsProps {
  setLoading: (loading: boolean) => void;
}

const DiabetesLabTests = ({ setLoading }: DiabetesLabTestsProps) => {
  const [labAddress, setLabAddress] = React.useState<Address | null>(null);
  const user = useCurrentUser();
  const dispatch = useStoreDispatch();
  const { consultsByCondition } = useStoreState((state) => state.consults);
  const [firstApproved, setFirstApproved] = React.useState<LegacyConsultSchema | null>(null);
  const [addressQuestion, setAddressQuestion] = React.useState<Question | null>(null);

  React.useEffect(() => {
    consultsByCondition.every((consult) => {
      if (consult.approved_at) {
        setFirstApproved(consult);
        if (consult.consult_type === "RETURN_CONSULT") {
          if (consult.answers["diabetes-where-tested-return"]) {
            setAddressQuestion(consult.answers["diabetes-where-tested-return"]);
          }
        } else {
          if (consult.answers["diabetes-where-tested"]) {
            setAddressQuestion(consult.answers["diabetes-where-tested"]);
          }
        }
        return false;
      }
      return true;
    });
  }, [consultsByCondition.length]);

  React.useEffect(() => {
    UsersService.getAddresses({ userId: user.id, type: "LAB" })
      .then((res) => {
        res.every((address) => {
          if (!isLegacyAddress(address) && address.default === true) {
            setLabAddress(address as Address);
            return false;
          }
          return true;
        });
      })
      .catch((err) => {
        Sentry.captureException(err);
      })
      .finally(() => {
        setLoading(false);
      });

    dispatch.consults.fetchConsultsByCondition("diabetes");
  }, []);

  return (
    <div>
      <BackButtonHeading title="Lab Tests" />
      {addressQuestion && addressQuestion.value === "id-like-to-go-to-a-quest-lab-near-me" ? (
        <p className="text-sm mt-8">
          Because you chose to go to a Quest lab near you, if tests are needed, your Alpha Provider
          will submit a request on your behalf
        </p>
      ) : (
        <p className="text-sm mt-8">
          Because you chose to go to a lab of your choice, if tests are needed, your Alpha Provider
          will submit a request to this lab on your behalf
        </p>
      )}
      {addressQuestion && labAddress && addressQuestion.value === "other" && (
        <div className="mt-5">
          <h1 className="font-bold text-sm">Lab of your choice</h1>
          <p className="text-sm mt-3">{labAddress.name}</p>
          <ReadOnlyAddress address={labAddress} />
        </div>
      )}
    </div>
  );
};

interface Question {
  value: string;
}

export default DiabetesLabTests;
