/** @format */

import config from "src/config";

type FeatureFlagName =
  | "enableMessageWebhooks"
  | "enableHelpPage"
  | "enableHierarchicalDropdowns"
  | "enableMonthlyMembership"
  | "disableTP"
  | "enablePharmacySearch"
  | "enableWD"
  | "enableWeightLossJournalPaywall"
  | "enableBenefitHolderBehavior";

export const useFeatureFlags = (name: FeatureFlagName): boolean => {
  if (name in config.featureFlags && config.featureFlags[name] === true) {
    return true;
  }
  return false;
};
