/** @format */

export default {
  /**
   * Maintenance banner
   */
  banner: {
    later:
      "Alpha's patient portal will be down for maintenance **on {{date}} from {{start}} to {{end}}.**",
    today:
      "Alpha's patient portal will be down for maintenance **today from {{start}} to {{end}}.**",
    shortly: "Alpha's patient portal will be down for maintenance **in {{timer}}.**",
  },

  formatters: {
    date: "dddd, MMMM DD, YYYY",
    time: "h:mm A z",
    countdown: "h mm ss",
  },

  /**
   * Maintenance Page
   */
  screen: {
    title: "We're Down for Maintenance",
    details:
      "We apologize for any inconvenience this may cause and appreciate your patience. If you have questions, email us at [contact@helloalpha.com](mailto:contact@helloalpha.com)",
    disclaimerTitle: "Do you have a medical emergency?",
    disclaimerContent: [
      "For medical emergencies, **please call 911 immediately.**",
      "If you or someone you know is in a mental health crisis, **call or text 988 or visit [988lifeline.org](https://988lifeline.org) to chat with a counselor.**",
      "If you're experiencing a severe or sudden onset of symptoms, seek immediate in-person care from a qualified health provider. Alpha's service is not intended for emergency medical situations.",
    ],
  },
};
