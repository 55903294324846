/** @format */
import type React from "react";

import { useHistory } from "react-router-dom";

export const BackButtonHeading: React.FC<{
  title: string;
  onClick?: (values: any) => void;
  cxPadding?: string;
  cxText?: string;
  center?: boolean;
}> = ({ title, onClick, cxPadding, cxText, center }) => {
  const history = useHistory();
  return (
    <div
      className={`flex ${center ? "justify-between" : "justify-start"} items-center ${
        cxPadding ? cxPadding : "pt-10 pb-4"
      } mx-1`}
    >
      <button className="cursor-pointer mr-4" onClick={onClick || (() => history.goBack())}>
        <span>
          <img src="/assets/back-arrow.svg" alt="Go to previous page" />
          <span className="hidden">Back</span>
        </span>
      </button>
      <h1 className={`font-normal ${cxText || "text-xl"}`}>{title}</h1>
      {center && <span />}
    </div>
  );
};
