/** @format */

import React from "react";
import BarLoader from "react-spinners/BarLoader";
import { useLocalStorage } from "react-use";

import type { LinkTracking } from "src/components/ImageHeading";
import type { Service } from "src/v2/models/service";
import type { SnackType } from "src/v2/models/snackBar";
import type { Consultation } from "src/v2/types/consultation";

import { ActionItemsService, UsersService } from "src/api";
import FeaturePromotions from "src/components/FeaturePromotions";
import { PageWrapper } from "src/components/PageWrapper";
import { isAxiosError } from "src/helpers/axios";
import { _GET, getTokenHeaderV2 } from "src/helpers/http";
import { PAYMENT_METHODS } from "src/routes";
import { YourMessages } from "src/routes/Messages/YourMessages";
import { serializeDependency } from "src/utils";
import { useGetChannels } from "src/utils/hooks";
import { useTopBar } from "src/utils/hooks/useTopBar";
import Carousel from "src/v2/components/Carousel/Carousel";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useStoreDispatch, useStoreState } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";
import { sortAndGroupVisits } from "src/v2/routes/MyCases/utils";
import { getConsultsFromServices, getSortedReturnVisits } from "src/v2/utils/returnVisitUtils";

import ExploreVisitsCard from "./ExploreVisitsCard";
import MyCasesAccordion from "./MyCasesAccordion";

export const conditionMap: Record<string, string> = {
  eyelashes: "lashes",
};

export interface TodoProps {
  title?: string;
  imageSrc: string;
  description?: string;
  ctaText?: string;
  ctaLink?: string;
  secondaryCtaText?: string;
  secondaryCtaLink?: string;
  trackingUrl?: string;
  tracking?: LinkTracking;
  secondaryTracking?: LinkTracking;
}

const MyCasesV2 = () => {
  useTopBar({ variant: "main", title: "Home" });
  const dispatch = useStoreDispatch();
  const [serviceList, setServiceList] = React.useState<Service[]>([]);
  const [visits, setVisits] = React.useState<Consultation[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [, setPage] = useLocalStorage("monthly-membership-page");

  const [returnVisits, setReturnVisits] = React.useState<any[]>([]);

  const user = useCurrentUser();
  const queryParams = new URLSearchParams(window.location.search);
  const couponCode = queryParams.get("coupon_code");
  const { profile } = useStoreState((state) => state.profile);
  const { channels = [] } = useGetChannels();

  React.useEffect(() => {
    dispatch.profile.fetchEmployerBenefits();
  }, [profile?.employer_benefits?.length]);

  React.useEffect(() => {
    setPage(0);
    dispatch.user.fetchUser();
    dispatch.actionItems.fetchActionItems();
    const include = ["consults"];
    // Calling this directly because changing the ServicesService to allow 'include' as a parameter requires a backend change
    // which breaks calls to the endpoint from cdb. This would be a good change to make in the future, in both pdb and cdb.
    _GET(`/users/${user.id}/services?include=${include.join("+")}`, {}, getTokenHeaderV2())
      .then((res) => {
        setServiceList(res);
        dispatch.service.setServiceList(res);
      })
      .finally(() => {
        if (serviceList.length === 0) {
          setIsLoading(false);
        }
      });
    if (couponCode) {
      UsersService.checkAndApplyCoupon({ userId: user.id, couponCode })
        .then((res) => {
          dispatch.snacks.addSnack({
            type: "success",
            id: "couponCode",
            message: `A $10 coupon has been added to your account. [Learn more](${PAYMENT_METHODS}).`,
            delay: 10,
          });
        })
        .catch((err) => {
          let message = "Something went wrong.";
          let type: SnackType = "error";
          const id = "couponCode";
          if (isAxiosError(err) && err.response) {
            message = err.response.data.description;
            const errorType = err.response.data.type;
            if (errorType === "CouponAlreadyRedeemed") {
              type = "warning";
            }
          }
          dispatch.snacks.addSnack({ type, id, message, delay: 10 });
        });
    }
  }, []);

  React.useEffect(() => {
    if (serviceList && serviceList.length > 0) {
      setIsLoading(true);
      ActionItemsService.fetchReturnVisits({ userId: user.id })
        .then((return_visits) => {
          const [dueVisits, upcomingVisits] = getSortedReturnVisits(serviceList, return_visits);
          setReturnVisits([...dueVisits, ...upcomingVisits]);
        })
        .finally(() => setIsLoading(false));
      const tempVisits = getConsultsFromServices(serviceList);
      setVisits(sortAndGroupVisits(tempVisits) || []);
    }
  }, [serializeDependency(serviceList, ["id"])]);

  return (
    <PageWrapper includeMembershipPromo={true}>
      <div className="pt-4 pb-4 max-w-2xl mx-auto">
        <div className="py-3 text-2xl font-medium leading-8 text-forest-120">Welcome back!</div>
        {channels.length > 0 && <YourMessages expandOnUnread={true}></YourMessages>}
        <Carousel />
        {(!visits || visits.length == 0) && !isLoading && <ExploreVisitsCard />}
        <div className="my-4">
          <BarLoader color={"#A4B7B5"} loading={isLoading} />
        </div>
        {!isLoading && <MyCasesAccordion yourVisits={visits} returnVisits={returnVisits} />}
        <FeaturePromotions showPromotions={visits.length > 0} />
      </div>
    </PageWrapper>
  );
};
export default WithTopBarNavigation(MyCasesV2);
