/** @format */
import React from "react";

import { isEmpty, isNumber } from "lodash";

import { ButtonPdb } from "src/v2/designSystem";

import type { LineItemGroup } from "src/api";

import { PaymentVisitInfo } from "src/v2/components";
import { FullPageModal } from "src/v2/components/_modals/FullPageModal";
import { MEMBERSHIP_PLANS } from "src/v2/constants";

import { PaymentSectionTitle } from "./PaymentSectionTitle";

interface PaymentSectionProps {
  lineItemGroup: LineItemGroup;
  openPlusModal: () => void;
  openBaseModal: () => void;
}

export const PaymentSection = ({ lineItemGroup, ...modalCallbacks }: PaymentSectionProps) => {
  const showPaymentVisitInfo = lineItemGroup.name === "Your Online Visit";
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [modalContent, setModalContent] = React.useState<any>({});

  const getModalOpenCallback = (plan_code: string) => {
    if (plan_code == MEMBERSHIP_PLANS.alpha) {
      return modalCallbacks.openBaseModal;
    }
    if (plan_code == MEMBERSHIP_PLANS.plus) {
      return modalCallbacks.openPlusModal;
    }
    return undefined;
  };

  const modalChildren = (
    <>
      {modalContent?.sections?.map((section: any) => {
        return (
          <>
            <div className="text-xl text-forest-darker px-6 py-6 font-medium">{section.header}</div>
            <div className="text-forest-darker px-6">{section.body}</div>
          </>
        );
      })}
      <div className="px-6">
        <ButtonPdb className="w-full mt-10" onClick={() => setIsOpen(false)}>
          Close
        </ButtonPdb>
      </div>
    </>
  );

  return (
    <>
      <FullPageModal title={modalContent?.title} isOpen={isOpen} setIsOpen={setIsOpen}>
        {modalChildren}
      </FullPageModal>
      <div className="text-sm">
        <PaymentSectionTitle>{lineItemGroup.name}</PaymentSectionTitle>
        {showPaymentVisitInfo && <PaymentVisitInfo showNumberOfVisits={false} />}
        {lineItemGroup.info_notifier?.title && (
          // TODO: Replace this with info box from design system
          <div className="p-3 border-2 rounded-md border-grey-6 mb-2">
            <div className="flex mb-4">
              <div className="flex-initial">
                <img src="/assets/information-gray.svg" alt="Info Icon" />
              </div>
              <div className="flex-1 ml-4 font-medium text-sm">
                {lineItemGroup.info_notifier.title}
              </div>
            </div>
            <div className="text-sm">{lineItemGroup.info_notifier.content}</div>
          </div>
        )}
        {lineItemGroup.line_items.map((lineItem, idx) => {
          return (
            <React.Fragment key={lineItem.product_code}>
              {idx !== 0 && <div className="bg-grey-2 my-4 w-full" style={{ height: "1px" }} />}
              <div className="space-y-3">
                <div className="flex flex-col">
                  {lineItem.plan_code && (
                    <span
                      className="font-bold text-cornflower-100 underline cursor-pointer"
                      onClick={getModalOpenCallback(lineItem.plan_code)}
                    >
                      {lineItem.plan_code
                        .split("-")
                        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                        .join(" ")}
                    </span>
                  )}
                </div>
                {lineItem.name && (
                  <div className="flex flex-col">
                    {lineItem.name && <span className="font-semibold">{lineItem.name}</span>}
                    {lineItem.name_sub_text && (
                      <span className="text-xs">{lineItem.name_sub_text}</span>
                    )}
                  </div>
                )}
                {lineItem.frequency_description && (
                  <div className="flex justify-between">
                    <span>{lineItem.frequency_description}</span>
                    <span>{lineItem.frequency}</span>
                  </div>
                )}
                <div className="flex justify-between">
                  <div className="flex">
                    <span
                      className={`${
                        !isEmpty(lineItem.benefits_modal_content)
                          ? "font-bold text-cornflower-100 underline cursor-pointer"
                          : ""
                      }`}
                      onClick={() => {
                        if (!isEmpty(lineItem.benefits_modal_content)) {
                          setModalContent(lineItem.benefits_modal_content);
                          setIsOpen(true);
                        }
                      }}
                    >
                      {lineItem.amount_description}
                    </span>
                    {isNumber(lineItem.amount_in_cents) && lineItem.amount_in_cents < 0 && (
                      <img src="/assets/discount.svg" alt="discount" className="ml-2" />
                    )}
                  </div>

                  <span>{lineItem.amount || lineItem.amount_fallback}</span>
                </div>
              </div>
              {lineItem.subtext && <strong className="text-xs">{lineItem.subtext}</strong>}
            </React.Fragment>
          );
        })}
        {lineItemGroup.info_text.map((info, idx) => (
          <div
            key={idx}
            className="bg-cloud-light-40 mt-8 p-5 leading-5"
            dangerouslySetInnerHTML={{ __html: info }}
          />
        ))}
      </div>
    </>
  );
};
