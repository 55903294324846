/** @format */

import React from "react";

import { faAddressCard, faMemo, faWavePulse } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const UPDATE_PHOTO_ID_ACTION_TYPE = "update-photo-id";
export const ADD_BLOOD_PRESSURE_ACTION_TYPE = "add-blood-pressure";
export const ANNUAL_UPDATE_INSURANCE_ACTION_TYPE = "annual-update-insurance";
export const actionTypesMap: Partial<Record<string, any>> = {
  [ADD_BLOOD_PRESSURE_ACTION_TYPE]: {
    display: "Add blood pressure",
    icon: <FontAwesomeIcon size="lg" icon={faWavePulse} />,
    url: "/add-blood-pressure",
  },
  [UPDATE_PHOTO_ID_ACTION_TYPE]: {
    display: "Update Photo ID",
    icon: <FontAwesomeIcon size="lg" icon={faAddressCard} />,
    url: "/account/personal-information/photo-id",
  },
  [ANNUAL_UPDATE_INSURANCE_ACTION_TYPE]: {
    display: "Update insurance",
    icon: <FontAwesomeIcon size="lg" icon={faMemo} />,
    description: "Annual update recommended",
  },
};
