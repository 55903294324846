/** @format */
import React from "react";
import BarLoader from "react-spinners/BarLoader";

import { Typography } from "@alphamedical/components";
import { Paper } from "src/v2/designSystem";

import type { Service } from "src/v2/models/service";
import type { Consultation } from "src/v2/types/consultation";

import { PageWrapper } from "src/components/PageWrapper";
import { serializeDependency } from "src/utils";
import { useTopBar } from "src/utils/hooks/useTopBar";
import VisitList from "src/v2/components/Visits/VisitList";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useStoreDispatch, useStoreState } from "src/v2/models";
import { groupActiveVisits, isVisitStarted, sortConsults } from "src/v2/routes/MyCases/utils";

const AllVisits = () => {
  useTopBar({ title: "All visits", variant: "nested", showBackButton: true });
  const dispatch = useStoreDispatch();
  const { serviceList } = useStoreState((state) => state.service);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [startedVisits, setStartedVisits] = React.useState<Consultation[]>([]);
  const [activeVisits, setActiveVisits] = React.useState<Consultation[]>([]);

  const setVisits = (visits: Consultation[]) => {
    // Sort all user consults
    if (visits.length > 0) {
      const sortedVisits = sortConsults(visits);

      // filter incomplete visits
      setStartedVisits(sortedVisits.filter((visit) => isVisitStarted(visit)));

      // filter active visits
      setActiveVisits(groupActiveVisits(sortedVisits));
    }
  };

  const getVisits = (serviceList: Service[]) => {
    const visits: Consultation[] = [];
    serviceList.forEach((service) => {
      if (service.consults) {
        service.consults.forEach((consult) => {
          if (!consult.consult_condition) {
            if (service.condition) {
              consult.consult_condition = service.condition;
            }
          }
        });
      }
      if (service.consults && service.consults.length > 0) visits.push(...service.consults);
    });
    setVisits(visits);
  };

  React.useEffect(() => {
    // Get all consults for a user
    dispatch.service.fetchAllServices({ include: ["consults"] }).finally(() => setIsLoading(false));
    if (serviceList && serviceList.length > 0) {
      getVisits(serviceList);
    }
  }, [serializeDependency(serviceList, ["id"])]);

  return (
    <PageWrapper cxPadding="px-0" fitToPageHeight>
      <Paper elevation={0} rounded={false} className="flex-1 mx-auto w-full max-w-2xl px-6 py-10">
        <div className="mx-auto max-w-lg pb-20">
          <BarLoader color={"#A4B7B5"} loading={isLoading} />
          {activeVisits?.length > 0 && !isLoading && (
            <div>
              <Typography variant="h2" className="text-forest-120">
                Active
              </Typography>
              <Typography variant="body2" className="mb-6 mt-1 text-grey-160">
                Check the status of your visit, message your care team, or view your Post-Visit
                Summary.
              </Typography>
              {!isLoading && <VisitList consults={activeVisits} listType="consultList" />}
            </div>
          )}

          {startedVisits?.length > 0 && !isLoading && (
            <div>
              <Typography variant="h2" className="text-forest-120 mt-10">
                Started
              </Typography>
              <Typography variant="body2" className="mb-6 mt-1 text-grey-160">
                Answer each question and submit your visit.
              </Typography>
              {!isLoading && <VisitList consults={startedVisits} listType="consultList" />}
            </div>
          )}
        </div>
      </Paper>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(AllVisits);
