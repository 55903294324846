/** @format */
import React from "react";

import * as Sentry from "@sentry/react";
import type { Action, State, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";
import _ from "lodash";

import type {
  FormReponsesCollectionSchema,
  PiiSchema,
  ProfileSchema,
  SecondaryUserViewsUpdateUserPiiRequestBodySchema,
  WeightLossJournalPreferencesSchema,
} from "src/api";
import type { UserPlanSchema } from "src/api/models/UserPlanSchema";
import type { SendApprovalProps } from "src/v2/types/profile";

import { Analytics } from "src/analytics";
import { PartnerApiService, UsersService } from "src/api";
import { _GET, _POST, getTokenHeaderV2 } from "src/helpers/http";
import { formatPrice } from "src/v2/components/Payment/utils";

import type { CreateModel, CreateModelDispatch } from "./_create";

const keys = <T extends object>(obj: T) => obj && (Object.keys(obj) as (keyof T)[]);

export const CURRENT_PROFILE_ID = "currentProfileId";
export const getCurrentProfileId = () => {
  const _id = localStorage.getItem(CURRENT_PROFILE_ID);
  return (_id && parseInt(_id)) || "me";
};
export const setCurrentProfileId = (currentProfileId: number) => {
  localStorage.setItem(CURRENT_PROFILE_ID, currentProfileId.toString());
};

export const CurrentUserContext = React.createContext<ProfileSchema | null>(null);

export const useCurrentUser = () => {
  const profile = React.useContext(CurrentUserContext);
  if (profile == null) {
    throw Error(
      "Cannot access profile. Make sure that useCurrentUser is called inside CurrentUserContext.Provider and that the profile is set.",
    );
  }
  return profile;
};

type IncludeOptions =
  | "consults"
  | "pii"
  | "insurance"
  | "followup_items"
  | "children"
  | "parents"
  | "emergency_contact"
  | "employer_benefits"
  | "primary_provider"
  | "phi";

export interface FetchProfileOptions {
  include?: IncludeOptions[];
  includeAll?: boolean;
  userId?: number | "me";
}

export interface OpenRequest {
  request: Promise<any>;
  include: string[];
}

export const showShareDataSelector = (state: State<CreateModel>) =>
  (state.profile.wlj_preferences &&
    state.profile.wlj_preferences.share_with_provider === null &&
    state.profile.showShareData) ||
  false;

export interface ProfileModel {
  currentProfileId?: number | "me";
  profile?: ProfileSchema;
  requests: OpenRequest[];
  hasSeenPopup: boolean;
  postingApproval: boolean;
  fetchingRegistrationData: boolean;
  error: string;
  userRegistration: null | FormReponsesCollectionSchema;
  beforeRegistrationLink?: string | null;
  employer_benefits?: null | UserPlanSchema;

  wlj_preferences?: WeightLossJournalPreferencesSchema;
  showShareData: boolean;

  setCurrentProfileId: Action<ProfileModel, { userId: number; redirectUrl?: string }>;

  setProfileData: Action<ProfileModel, ProfileSchema>;

  setChatAuthToken: Action<ProfileModel, string>;

  setProfilePii: Action<ProfileModel, PiiSchema>;

  setHasSeenPopup: Action<ProfileModel, boolean>;

  setFetchingItems: Action<ProfileModel, boolean>;

  setUserRegistration: Action<ProfileModel, FormReponsesCollectionSchema>;

  setBeforeRegistrationLink: Action<ProfileModel, string>;

  setError: Action<ProfileModel>;

  removeFromCache: Action<ProfileModel, Promise<any>>;

  setCachedRequest: Action<ProfileModel, { include: string[]; request: Promise<any> }>;

  fetchProfile: Thunk<ProfileModel, FetchProfileOptions>;

  updatePii: Thunk<ProfileModel, SecondaryUserViewsUpdateUserPiiRequestBodySchema>;

  sendOrderApproval: Thunk<ProfileModel, SendApprovalProps>;

  sendSubscriptionApproval: Thunk<ProfileModel, SendApprovalProps>;

  addRelation: Thunk<ProfileModel, object>;

  refreshChatAuthToken: Thunk<ProfileModel>;

  setFetchingRegistrationData: Action<ProfileModel, boolean>;

  fetchRegistrationOutlineData: Thunk<ProfileModel, { userId: number }>;

  fetchEmployerBenefits: Thunk<ProfileModel>;

  setEmployerBenefits: Action<ProfileModel, UserPlanSchema[]>;

  fetchWLJPreferences: Thunk<ProfileModel>;

  setWLJPreferences: Action<ProfileModel, WeightLossJournalPreferencesSchema>;

  setShowShareData: Action<ProfileModel, boolean>;

  updateWLJPreferences: Thunk<ProfileModel, WeightLossJournalPreferencesSchema>;

  verifyRegistrationToken: Thunk<ProfileModel>;
}

export const profileStore: ProfileModel = {
  requests: [],
  currentProfileId: getCurrentProfileId(),
  hasSeenPopup: false,
  postingApproval: false,
  userRegistration: null,
  beforeRegistrationLink: null,
  fetchingRegistrationData: false,
  error: "",
  employer_benefits: null,
  showShareData: false,
  wlj_preferences: undefined,

  setCurrentProfileId: action((state, { userId, redirectUrl }) => {
    // Set localstorage
    setCurrentProfileId(userId);
    // clear profile data with hard refresh
    redirectUrl
      ? window.location.assign(redirectUrl)
      : state.currentProfileId !== userId && window.location.reload();
  }),

  setProfileData: action((state, profile) => {
    state.profile = { ...state.profile, ...profile };
  }),

  setChatAuthToken: action((state, token) => {
    if (state.profile) {
      state.profile = { ...state.profile, chat_auth_key: token };
    }
  }),

  setEmployerBenefits: action((state, employerBenefits) => {
    if (state.profile) {
      state.profile = { ...state.profile, employer_benefits: employerBenefits };
    }
  }),

  setProfilePii: action((state, pii) => {
    if (state.profile != null) {
      state.profile.pii = pii;
    }
    // state.profile = { ...state.profile, ...{ pii: { ...(state.profile || {}).pii, ...pii }}}
  }),

  setHasSeenPopup: action((state, _hasSeenPopup) => {
    state.hasSeenPopup = _hasSeenPopup;
  }),

  setFetchingItems: action((state, _postingApproval) => {
    state.postingApproval = _postingApproval;
  }),

  setFetchingRegistrationData: action((state, _fetchingRegistrationData) => {
    state.fetchingRegistrationData = _fetchingRegistrationData;
  }),

  setError: action((state) => {
    state.error = "An error occurred, please try again later.";
  }),

  setUserRegistration: action((state, payload) => {
    state.userRegistration = payload;
  }),

  setBeforeRegistrationLink: action((state, payload) => {
    state.beforeRegistrationLink = payload;
  }),

  removeFromCache: action((state, request) => {
    _.remove(state.requests, (openRequest) => openRequest.request == request);
  }),

  setCachedRequest: action((state, params) => {
    const { include, request } = params;
    state.requests = [...state.requests, { include, request }];
  }),

  fetchProfile: thunk(async (actions, options, { getState, getStoreState, dispatch }) => {
    const _dispatch = dispatch as CreateModelDispatch;
    const {
      auth: { authenticatedUser },
    } = getStoreState() as CreateModel;
    const include =
      options.include ||
      (options.includeAll
        ? ["pii", "insurance", "followup_items", "consults", "emergency_contact"]
        : []);
    const state = getState();
    const userId = options.userId || state.currentProfileId || "me";
    const existingRequest = state.requests.find((openRequest) => {
      return _.difference(include, openRequest.include).length === 0;
    });

    if (existingRequest) {
      return existingRequest.request;
    } else {
      if (userId) {
        const request = _GET<ProfileSchema>(
          `/profile/${userId}?include=${include.join("+")}`,
          {},
          getTokenHeaderV2(),
        )
          .then((res) => {
            try {
              Analytics.setUserUUID(res.uuid);
            } catch (err) {
              Sentry.captureException(err);
            }
            actions.setProfileData(res);
            if (authenticatedUser && res.id === authenticatedUser.id) {
              _dispatch.auth.setAuthenticatedUser(res);
            }
            return res;
          })
          .finally(() => actions.removeFromCache(request));
        actions.setCachedRequest({ include, request });
        return request;
      }
    }
  }),

  updatePii: thunk(async (actions, edits, { getState, getStoreState }) => {
    const state = getState();
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;
    const hasChanged = keys(edits).reduce((result, attr) => {
      const current = state.profile?.pii as SecondaryUserViewsUpdateUserPiiRequestBodySchema;
      if (
        !state.profile ||
        (current &&
          current[attr] !== edits[attr] &&
          edits[attr] != null &&
          Object.keys(state.profile.pii).indexOf(attr) !== -1)
      ) {
        return true;
      }
      return result;
    }, false);
    if (hasChanged) {
      return UsersService.updateUserPii({
        userId: currentProfileId as number,
        requestBody: { ...edits },
      })
        .then((res) => {
          actions.setProfilePii(res);
          return res;
        })
        .catch((err) => {
          return err;
        });
    }
  }),

  sendOrderApproval: thunk(
    async (
      actions,
      {
        approved,
        order_id,
        selection,
        copay_amount,
        medication_name,
        followup_type,
        condition_key,
        condition_name,
        copay_approval,
        days_supply,
        medication_cost,
        pharmacy_info,
        next_refill_date,
        insuranceData,
        contactInsurance,
      },
      { getState, getStoreState },
    ) => {
      const storeState = getStoreState() as CreateModel;
      const { currentProfileId } = storeState.profile;
      // const state = getState();
      actions.setFetchingItems(true);
      // This is for caching purposes
      const url = copay_approval
        ? `/v2/users/${currentProfileId}/orders/${order_id}/copay`
        : `/v2/users/${currentProfileId}/orders/${order_id}/order_user_approvals`;
      const request = _POST(
        url,
        {
          approved,
          selection,
          medication_name,
          copay_amount: copay_amount && formatPrice(copay_amount),
          followup_type,
          condition_key,
          condition_name,
          days_supply,
          medication_cost: medication_cost && formatPrice(medication_cost),
          pharmacy_info,
          next_refill_date,
          insuranceData,
          contactInsurance,
        },
        getTokenHeaderV2(),
      )
        .then((_res) => {
          actions.setFetchingItems(false);
          if (
            followup_type &&
            [
              "coverage_not_effective_approval",
              "non_formulary_approval",
              "bad_insurance_details_approval",
            ].indexOf(followup_type) > -1 &&
            !approved
          ) {
            return;
          } else {
            actions.fetchProfile({ include: ["followup_items", "pii"] });
          }
        })
        .catch((_err) => {
          actions.setError();
        });

      return request;
    },
  ),

  sendSubscriptionApproval: thunk(
    async (
      actions,
      {
        selection,
        subscription_id,
        followup_type,
        medication_name,
        condition_name,
        medication_cost,
        condition_key,
        pharmacy_info,
        days_supply,
      },
      { getState, getStoreState },
    ) => {
      // const state = getState();
      const storeState = getStoreState() as CreateModel;
      const { currentProfileId } = storeState.profile;
      actions.setFetchingItems(true);
      const url = `/v2/users/${currentProfileId}/subscriptions/${subscription_id}/subscription_user_approvals`;
      const request = _POST(
        url,
        {
          selection,
          followup_type,
          medication_name,
          condition_name,
          days_supply,
          medication_cost: medication_cost && formatPrice(medication_cost),
          condition_key,
          pharmacy_info,
        },
        getTokenHeaderV2(),
      )
        .then((_res) => {
          actions.setFetchingItems(false);
          actions.fetchProfile({ include: ["followup_items", "pii"] });
        })
        .catch((_err) => {
          actions.setError();
        });

      return request;
    },
  ),

  addRelation: thunk(async (actions, payload, { getState, getStoreState }) => {
    // Adds user (currently a child) to the authenticated user
    const url = `/v2/users/me/add-relationship`;
    return await _POST(url, payload, getTokenHeaderV2());
  }),

  refreshChatAuthToken: thunk(async (actions) => {
    return UsersService.refreshChatAuthToken({ userId: "me" }).then(({ token }) => {
      actions.setChatAuthToken(token);
    });
  }),

  fetchRegistrationOutlineData: thunk(async (actions, payload) => {
    const { userId } = payload;
    try {
      if (userId) {
        actions.setFetchingRegistrationData(true);
        const registrationOutlineData = await UsersService.getUserRegistrationForm({ userId });
        actions.setUserRegistration(registrationOutlineData);
        return registrationOutlineData;
      }
      return null;
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      return error;
    } finally {
      actions.setFetchingRegistrationData(false);
    }
  }),

  fetchEmployerBenefits: thunk(async (actions) => {
    return UsersService.getUserBenefits({ userId: "me" }).then((benefits) => {
      actions.setEmployerBenefits(benefits);
    });
  }),

  setShowShareData: action((state, show) => {
    state.showShareData = show;
  }),

  setWLJPreferences: action((state, wlj_preferences) => {
    state.wlj_preferences = wlj_preferences;
  }),

  fetchWLJPreferences: thunk(async (actions) => {
    const wlj_preferences = await UsersService.getWeightLossJournalPreferences({ userId: "me" });
    actions.setWLJPreferences(wlj_preferences);
  }),

  updateWLJPreferences: thunk(async (actions, wlj_preferences) => {
    const res = await UsersService.updateWeightLossJournalPreferences({
      userId: "me",
      requestBody: wlj_preferences,
    });
    actions.setWLJPreferences(res);
  }),

  verifyRegistrationToken: thunk(async (actions) => {
    const registrationToken = localStorage.getItem("registration_token");
    if (registrationToken) {
      try {
        await PartnerApiService.verifyPartnerToken({
          userId: "me",
          requestBody: { registration_token: registrationToken },
        });
        localStorage.removeItem("registration_token");
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
      }
    }
  }),
};
