/** @format */

import React from "react";
import { Link } from "react-router-dom";

import moment from "moment";

import { Popup, SimpleForm, ToggleField } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import type { FollowupItem } from "src/components/Popups/types";

import { _POST } from "src/helpers/http";
import { Button } from "src/v2/components/Button";
import { formatPrice } from "src/v2/components/Payment/utils";
import { useStoreDispatch } from "src/v2/models";

export interface CashApprovalPopupProps {
  item: FollowupItem;
  showPopup: boolean;
  setShowPopup: (arg: boolean) => void;
  key?: number;
  pii?: any;
  profile?: any;
}
type Page = "approve" | "one_time_approval" | "approval_moving_forward" | "cancelled";
interface FormValues {
  approve_future_refills: boolean;
  selection: string;
}

// TODO: Add copy for is_one_time skus
export const CashApprovalPopup = ({
  item: {
    followup_type,
    medication_name,
    medication_cost,
    order_id,
    refill_frequency,
    ingredients,
    condition_name,
    is_one_time,
  },
  showPopup,
  setShowPopup,
  pii,
  profile,
}: CashApprovalPopupProps) => {
  const [page, setPage] = React.useState<Page>("approve");
  const [title, setTitle] = React.useState<string>("Please Approve Payment");
  const [refillDate, setRefillDate] = React.useState<string>("");
  const dispatch = useStoreDispatch();

  const price = medication_cost && formatPrice(medication_cost);

  const submitForm = ({ approve_future_refills, selection }: FormValues) => {
    return _POST(`/v2/users/me/orders/${order_id}/cash_approval`, {
      approve_future_refills,
      selection,
      medication_name,
      medication_cost: price,
    }).then((res) => {
      const { selection, next_refill } = res;
      if (selection === "approval_moving_forward") {
        setPage("approval_moving_forward");
        setTitle("You've Approved Payment");
      } else if (selection === "one_time_approval") {
        setPage("one_time_approval");
        setTitle("You've Approved Payment");
      } else if (selection === "cancelled") {
        setPage("cancelled");
        setTitle("You've Cancelled This Order");
      }
      setRefillDate(next_refill);
    });
  };

  return (
    <div>
      <Popup
        isOpen={showPopup && !profile.hasSeenPopup}
        size="small"
        onRequestClose={() => {
          setShowPopup(false);
          dispatch.profile.setHasSeenPopup(true);
        }}
        headerText={title}
        accentColor="sand-20"
      >
        <SimpleForm<FormValues> onSubmit={submitForm} hideDebugInfo={false}>
          {({ submitting, submitSucceeded, values, form }) => {
            return (
              <>
                <div className="p-6">
                  {page === "approve" && (
                    <>
                      <p className="text-lg mb-4">
                        Your Alpha Provider has prescribed your{" "}
                        {condition_name && <strong>{condition_name}</strong>} medication.
                      </p>
                      <p className="text-forest-60 font-bold uppercase">Medication Prescribed</p>
                      <p className="mb-4">{medication_name}</p>
                      <div className="flex justify-between">
                        <div>
                          <p className="text-forest-60 font-bold uppercase">Cost</p>
                          <p className="mb-4">{price}</p>
                        </div>
                        {refill_frequency && (
                          <div>
                            <p className="text-forest-60 font-bold uppercase">Cost</p>
                            <p className="mb-4">every {refill_frequency} days</p>
                          </div>
                        )}
                      </div>
                      {ingredients && (
                        <div>
                          <p className="text-forest-60 font-bold uppercase">Dosage</p>
                          <p className="mb-4">{ingredients}</p>
                        </div>
                      )}
                    </>
                  )}
                  {["one_time_approval", "approval_moving_forward", "cancelled"].indexOf(page) >
                    -1 && (
                    <>
                      {["one_time_approval", "approval_moving_forward"].indexOf(page) > -1 && (
                        <>
                          <p className="mb-4">
                            Thank you for approving payment for your prescription.{" "}
                            <strong>Your card has been charged {price}</strong> and your order will
                            be processed and shipped in the next 72 hours.
                          </p>
                          <Link
                            onClick={() => dispatch.profile.setHasSeenPopup(true)}
                            to="/orders"
                            className="text-sky-dark font-bold text-md"
                          >
                            Track your order
                            <span className="ml-2 fa fa-arrow-right" />
                          </Link>
                        </>
                      )}
                      {page === "cancelled" && (
                        <p>
                          You have cancelled the {price} payment for your prescription.{" "}
                          <strong>Your order has been canceled,</strong> and we will not ship you
                          this medication. Please let us know if you would like to explore other
                          medication options with your Alpha Provider
                        </p>
                      )}
                      {!is_one_time && (
                        <>
                          <div className="border border-grey-1 w-full my-4" />
                          <p className="text-violator uppercase mb-3 font-bold">Please note</p>
                          {page === "one_time_approval" && (
                            <p className="mb-4">
                              We will notify you again like this before processing your next refill.
                            </p>
                          )}
                          {page === "cancelled" && (
                            <p className="mb-4">
                              Your subscription has not been canceled, only this order.
                            </p>
                          )}
                          {refillDate && (
                            <p>
                              Your next refill will be processed on:
                              <br /> <strong>{moment(refillDate).format("MMMM Do, YYYY")}</strong>
                            </p>
                          )}
                          {page === "cancelled" && (
                            <p className="mt-4">
                              If you'd like to cancel future refills, please contact support.
                            </p>
                          )}
                          {page === "approval_moving_forward" && (
                            <p className="mt-4">
                              Because you've approved future refills they will be{" "}
                              <strong>automatically charged and shipped.</strong> You will be
                              notified via email of upcoming refills 3 days before you are charged.
                              You may cancel at any time
                            </p>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className="bg-sand-20 p-6">
                  {page === "approve" && (
                    <div>
                      <Button
                        cxMargin={`${!is_one_time ? "mb-6" : ""}`}
                        onClick={() => form.change("selection", "approve")}
                        disabled={submitting}
                      >
                        <span className="text-md font-bold">Approve Payment of {price}</span>
                      </Button>
                      {!is_one_time && (
                        <>
                          <span className="font-bold text-forest-80">
                            <ToggleField
                              name="approve_future_refills"
                              label="Approve all my future refills"
                            />
                          </span>
                          <p className="text-forest-60 text-xs mt-3 mb-6">
                            By checking this box, your future refills will be automatically charged
                            and shipped. You will be notified via email of upcoming refills 3 days
                            before you are charged. You may cancel at any time.
                          </p>
                        </>
                      )}
                      <ButtonPdb
                        onClick={() => form.change("selection", "cancel")}
                        variant="secondary"
                        className={`w-full py-3`}
                        disabled={submitting || values.approve_future_refills}
                      >
                        <span className="text-md font-bold">Cancel This Order</span>
                      </ButtonPdb>
                      {values.approve_future_refills && (
                        <p className="text-red text-xs">
                          Uncheck approve all my future orders to cancel this order
                        </p>
                      )}
                    </div>
                  )}
                  {["one_time_approval", "approval_moving_forward", "cancelled"].indexOf(page) >
                    -1 && (
                    <div>
                      <Button
                        cxMargin="mb-6"
                        href={`/messages/new`}
                        onClick={() => {
                          setShowPopup(false);
                          dispatch.profile.setHasSeenPopup(true);
                        }}
                      >
                        <span className="text-md font-bold">Contact Support</span>
                      </Button>
                      <button
                        onClick={() => {
                          setShowPopup(false);
                          dispatch.profile.setHasSeenPopup(true);
                        }}
                        className="bg-transparent hover:bg-primary w-full border border-primary py-3 text-primary hover:text-white font-bold"
                      >
                        Close & Continue
                      </button>
                    </div>
                  )}
                </div>
              </>
            );
          }}
        </SimpleForm>
      </Popup>
    </div>
  );
};
