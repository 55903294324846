/** @format */

import type { FormRenderProps } from "react-final-form";

import React from "react";

import type { IProvidedProps } from "@peacechen/google-maps-react";
import { GoogleApiWrapper } from "@peacechen/google-maps-react";
import { isEmpty } from "lodash";

import { TextFieldNew } from "@alphamedical/components";

import { DynamicField } from "src/components/DynamicForm";
import config from "src/config";
import { DateField } from "src/v2/components/Wizard/fields";
import { useCurrentUser } from "src/v2/models/profile";
import { ConsultContext } from "src/v2/routes/Consultation";
import { getFieldCanBeShown } from "src/v2/utils/page/fields";

import "./pii.scss";

interface PiiProps extends IProvidedProps {
  formProps: FormRenderProps;
  showNameFields?: boolean;
  fields: any;
  hideNameWarning?: boolean;
}

const Pii = (props: PiiProps) => {
  const { google, formProps, showNameFields, hideNameWarning } = props;
  const { initialValues, values } = formProps;
  const fields = props.fields.reduce((_fields: any, field: any) => {
    _fields[field.key] = field;
    return _fields;
  }, {});

  if (
    initialValues?.sms_allowed === null &&
    values.sms_allowed === null &&
    fields.sms_allowed &&
    fields.sms_allowed.defaultValue
  ) {
    formProps.form.change("sms_allowed", fields.sms_allowed.defaultValue);
  }

  const currentUser = useCurrentUser();
  const consult = React.useContext(ConsultContext);

  const firstNameField = getFieldCanBeShown(values, fields.first_name, consult, currentUser);
  const lastNameField = getFieldCanBeShown(values, fields.last_name, consult, currentUser);

  const enablePlaceApi = !isEmpty(config.googleAPIKey);

  const autoCompleteRef = React.useRef<HTMLInputElement>(null);
  const addressRef = React.useRef<HTMLInputElement>(null);

  const fillInAddress = (autoCompleteInput: any) => {
    const place = autoCompleteInput.getPlace();
    const addressString: string[] = [];

    if (place.address_components) {
      const addressComponents = place.address_components;
      addressComponents.forEach((component: any) => {
        if (component.types.indexOf("street_number") !== -1) {
          addressString.push(`${component.short_name}`);
        }
        if (component.types.indexOf("route") !== -1) {
          addressString.push(" ", component.short_name);
          formProps.form.change("address", addressString.join(""));
        }
        if (component.types.indexOf("locality") !== -1) {
          formProps.form.change("city", component.long_name);
        }
        if (component.types.indexOf("administrative_area_level_1") !== -1) {
          formProps.form.change("state", component.short_name);
        }
        if (component.types.indexOf("postal_code") !== -1) {
          formProps.form.change("zipcode", component.short_name);
        }
      });
    }

    if (place.name) {
      formProps.form.change("address", place.name);
    }

    formProps.form.change("autocomplete", "");
    addressRef.current?.focus();
  };

  React.useLayoutEffect(() => {
    const initializeAutocomplete = () => {
      if (autoCompleteRef && autoCompleteRef.current) {
        const autoCompleteInput = new google.maps.places.Autocomplete(autoCompleteRef.current, {
          types: ["address"],
          componentRestrictions: { country: "us" },
        });

        if (autoCompleteInput) {
          autoCompleteInput.setFields(["address_component"]);
          autoCompleteInput.addListener("place_changed", () => fillInAddress(autoCompleteInput));
        }
      }
    };

    if (autoCompleteRef.current && google && google.maps) {
      initializeAutocomplete();
    }
  }, [google]);

  React.useEffect(() => {
    if (!addressRef.current?.value) {
      autoCompleteRef.current?.focus();
    }
  }, [addressRef.current?.value]);

  const formState = formProps.form.getState();
  const enableAutocomplete = enablePlaceApi && fields.autocomplete;

  return (
    <>
      <div className="flex-1 text-sm sm:text-base overflow-hidden">
        {showNameFields && firstNameField && lastNameField && (
          <>
            <div className="flex justify-between space-x-4">
              <DynamicField hideFooter={true} className="flex-1" field={fields.first_name} />
              <DynamicField hideFooter={true} className="flex-1" field={fields.last_name} />
            </div>
            {!hideNameWarning && (
              <p className="text-xs leading-normal mb-2">
                *Name provided must match name on ID (and insurance card, if paying with insurance)
              </p>
            )}
          </>
        )}
        {fields.first_name_or_organization && (
          <>
            <DynamicField hideFooter={true} field={fields.first_name_or_organization} />
            <DynamicField hideFooter={true} field={fields.last_name} />
          </>
        )}
        {fields.dob && (
          <>
            <div className="text-sm mt-2 mb-1">Date of Birth</div>
            <DateField
              name="dob"
              cxTextField="outline-none rounded py-3 bg-transparent leading-6 px-4"
              cxBorder="border-2 border-grey-100"
            />
          </>
        )}

        <div
          style={{ display: !formState.values.address && enableAutocomplete ? "block" : "none" }}
        >
          <TextFieldNew
            name={fields.autocomplete.key}
            className={`w-full py-3`}
            {...fields.autocomplete}
            ref={autoCompleteRef}
            placeholder="Address line 1"
            autocomplete="new-password"
            onBlur={(event: any) => {
              if (event.currentTarget.value) {
                formProps.form.change("address", event.currentTarget.value);
              }
            }}
          />
        </div>

        <div
          style={{ display: formState.values.address || !enableAutocomplete ? "block" : "none" }}
        >
          <TextFieldNew
            name={fields.address.key}
            className={`w-full py-3`}
            {...fields.address}
            ref={addressRef}
            onChange={(event) => {
              if (!event.currentTarget.value) {
                autoCompleteRef.current?.focus();
              }
            }}
          />
        </div>

        <DynamicField field={fields.address2} />
        <DynamicField field={fields.city} />
        <div className="flex justify-between space-x-3">
          <div className="flex-1 mr-3">
            <DynamicField field={fields.state} />
          </div>
          <div className="flex-1 ml-3">
            <DynamicField field={fields.zipcode} />
          </div>
        </div>
        <DynamicField field={fields.phone_number} />
        {fields.email && <DynamicField hideFooter={true} className="flex-1" field={fields.email} />}
        {fields.fax && <DynamicField hideFooter={true} field={fields.fax} />}
        {fields.sms_allowed && <DynamicField field={fields.sms_allowed} />}
      </div>
    </>
  );
};

export default GoogleApiWrapper((props: any) => ({
  apiKey: config.googleAPIKey || "",
  libraries: ["places"],
}))(Pii);
