/** @format */

import React from "react";

import { NumberField, SimpleForm } from "@alphamedical/components";

import type { BmiData } from "./BmiCalculator";

interface BmiFormProps {
  backgroundColor?: string;
  foregroundColor?: string;
  submitButtonText?: string;
  setData: (values: BmiData) => void;
  padding?: string;
  marginTop?: string;
}

interface BmiFormValues {
  feet: number;
  inches: number;
  pounds: number;
}

interface BioFormError {
  feet: undefined | string;
  inches: undefined | string;
  pounds: undefined | string;
}

const BmiForm = ({
  backgroundColor,
  setData,
  foregroundColor,
  submitButtonText,
  padding,
  marginTop,
}: BmiFormProps) => {
  if (!foregroundColor) {
    foregroundColor = "forest-100";
  }
  if (!backgroundColor) {
    backgroundColor = "sand-40";
  }
  return (
    <div
      className={`bg-${backgroundColor} ${marginTop ? marginTop : "mt-12"} ${padding ? padding : "p-12"}`}
    >
      <SimpleForm
        onSubmit={(values) => {
          const inches = values.feet * 12 + values.inches;
          const bmi = (values.pounds / inches / inches) * 703;
          if (!isNaN(bmi)) {
            setData({ bmi: bmi, height: inches, weight: values.pounds });
          }
        }}
        hideDebugInfo={true}
        validate={(values: BmiFormValues) => {
          const errors: BioFormError = { feet: undefined, inches: undefined, pounds: undefined };
          if ((values.feet && values.feet > 10) || values.feet < 0) {
            errors.feet = "Feet must be between 0 and 10";
          }
          if ((values.inches && values.inches > 12) || values.feet < 0) {
            errors.inches = "Inches must be between 0 and 12";
          }
          if ((values.pounds && values.pounds > 999) || values.pounds < 0) {
            errors.pounds = "Pounds must be between 0 and 999";
          }
          return errors;
        }}
      >
        {({ submitting, handleSubmit }) => {
          return (
            <>
              <div className="flex flex-col sm:flex-row">
                <div className="flex flex-col md:w-4/6 sm:w-full">
                  <div className="mb-3 mb-3 font-medium text-lg">What is your height?</div>
                  <div className="flex">
                    <NumberField
                      className={`w-1/2 mr-5`}
                      min={0}
                      max={10}
                      name="feet"
                      placeholder="Feet"
                    />
                    <NumberField
                      className="w-1/2 mr-5"
                      min={0}
                      max={12}
                      name="inches"
                      placeholder="Inches"
                    />
                  </div>
                </div>
                <div className="flex flex-col md:w-2/6 sm:w-full">
                  <div className="mb-3 mb-3 font-medium text-lg w-full">What is your weight?</div>
                  <NumberField min={0} max={999} name="pounds" placeholder="Pounds" />
                </div>
              </div>
              <button
                disabled={submitting}
                onClick={handleSubmit}
                className={`w-full p-4 mt-10 bg-${foregroundColor} text-white`}
              >
                {submitButtonText || "Calculate Your BMI"}
              </button>
            </>
          );
        }}
      </SimpleForm>
    </div>
  );
};

export default BmiForm;
