/** @format */
import type { match } from "react-router-dom";

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { SimpleForm, Typography } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import type { Consultation } from "src/v2/types/consultation";

import { ConsultsService, SynchronousVisitService } from "src/api";
import { DynamicField } from "src/components/DynamicForm";
import { PageWrapper } from "src/components/PageWrapper";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useStoreActions } from "src/v2/models";
import { ConsultContext } from "src/v2/routes/Consultation";

interface MatchParams {
  condition: string;
  consultId: string;
}

interface SchedulingValues {
  scheduling: { patient_timezone: string; date: string };
}

const SyncVisitReschedule = ({ match }: { match: match<MatchParams> }) => {
  const history = useHistory();
  const { addSnack } = useStoreActions((actions) => actions.snacks);
  useTopBar({
    title: "Reschedule Visit",
    variant: "nested",
    showBackButton: true,
  });
  const { consultId } = match.params;
  const [consult, setConsult] = React.useState<Consultation>();
  const [isSaving, setIsSaving] = React.useState(false);

  useEffect(() => {
    ConsultsService.getConsult({ consultId: consultId, userId: "me" }).then((response) => {
      setConsult({ ...response, files: [] } as Consultation);
    });
  }, [consultId]);

  if (!consult) {
    return null;
  }

  const onSubmit = async (values: SchedulingValues) => {
    try {
      setIsSaving(true);
      await SynchronousVisitService.rescheduleSynchronousVisit({
        userId: "me",
        consultId,
        requestBody: values,
      });
      addSnack({
        type: "success",
        id: "reschedule-success",
        message:
          "Your visit has been rescheduled. You will receive an email with instructions to join.",
        delay: 5,
      });
      history.goBack();
    } catch (e) {
      addSnack({
        type: "error",
        id: "reschedule-error",
        message: "There was an error rescheduling your visit. Please try again later.",
        delay: 10,
      });
    } finally {
      setIsSaving(false);
    }
  };
  return (
    <PageWrapper cxPadding="px-6 pt-8" cxMaxWidth="w-screen sm:max-w-xl">
      <Typography className="mb-6" variant="h2">
        Choose a time that works for you
      </Typography>
      <ConsultContext.Provider value={consult}>
        <SimpleForm<SchedulingValues> hideDebugInfo onSubmit={onSubmit}>
          {({ values }) => {
            return (
              <>
                <DynamicField
                  hideFieldWrapper
                  hideFooter
                  field={{
                    key: "scheduling",
                    type: "component",
                    component: "SchedulingPage",
                    days: 15,
                  }}
                />
                <ButtonPdb disabled={isSaving || !values.scheduling} className="w-full mt-6">
                  Confirm
                </ButtonPdb>
              </>
            );
          }}
        </SimpleForm>
      </ConsultContext.Provider>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(SyncVisitReschedule);
