/** @format */

import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { BrowserRouter, Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";

import * as Sentry from "@sentry/react";
import { StoreProvider } from "easy-peasy";
import { isError } from "lodash";
import moment from "moment";

import { Analytics } from "src/analytics";
import AlphaPCPFields from "src/components/AlphaPCPFields";
import { Authenticate } from "src/components/Authenticate/Authenticate";
import { NotFound404 } from "src/components/NotFound404";
import config from "src/config";
import * as PDB_ROUTES from "src/routes";
import Account from "src/routes/Account";
import ActivateBenefits from "src/routes/ActivateBenefits";
import AddressInformation from "src/routes/AddressInformation/AddressInformation";
import App from "src/routes/App";
import EmergencyContactInformation from "src/routes/EmergencyContactInformation";
import { GenericForm } from "src/routes/GenericForm";
import InsuranceInformation from "src/routes/InsuranceInformation";
import MembershipCancellation from "src/routes/Membership/MembershipCancellation/MembershipCancellation";
import MembershipChangeFlow from "src/routes/MembershipChange";
import ChatThread from "src/routes/Messages/ChatThread";
import Inbox from "src/routes/Messages/Inbox";
import MessageThread from "src/routes/Messages/MessageThread";
import NewMessageThread from "src/routes/Messages/NewMessageThread";
import AddBloodPressure from "src/routes/PatientActionItems/AddBloodPressure";
import PaymentsPage from "src/routes/PaymentsPage";
import PersonalInformation from "src/routes/PersonalInformation";
import PhotoId from "src/routes/PhotoID/PhotoID";
import PreferredPronouns from "src/routes/PreferredPronouns/PreferredPronouns";
import ActivePrescriptions from "src/routes/Prescriptions/ActivePrescriptions";
import PrimaryCareProvider from "src/routes/PrimaryCareProvider";
import MedicalRecords from "src/routes/Records/MedicalRecords";
import MentalHealthRecords from "src/routes/Records/MentalHealthRecords";
import MedicationJournal from "src/routes/Records/WeightLossJournal/MedicationJournal";
import WeightLossJournal from "src/routes/Records/WeightLossJournal/WeightLossJournal";
import WeightLossJournalHome from "src/routes/Records/WeightLossJournal/WeightLossJournalHome";
import WeightLossJournalPreferences from "src/routes/Records/WeightLossJournal/WeightLossJournalPreferences";
import { RegisterKitPage } from "src/routes/RegisterKit";
import Settings from "src/routes/Settings/containers/SettingsContainer";
import SyncVisitDetails from "src/routes/VisitDetails/SyncVisitDetails";
import SyncVisitReschedule from "src/routes/VisitDetails/SyncVisitReschedule";
import { VisitDetails } from "src/routes/VisitDetails/VisitDetails";
import VisitDetailsTreatmentInfo from "src/routes/VisitDetails/VisitDetailsTreatmentInfo";
import HealthInformation from "src/routes/YourAccount/HealthInformation";
import MembershipAndPayment from "src/routes/YourAccount/MembershipAndPayment";
import { setHistory } from "src/utils/history";
import { BrazeContextProvider } from "src/utils/hooks/useBraze";
import { useFeatureFlags } from "src/utils/hooks/useFeatureFlags";
import { useRedirects } from "src/utils/hooks/useRedirects";
import { PharmacyForm } from "src/v2/components/Forms/PharmacyForm";
import VisitUnavailablePage from "src/v2/components/Page/VisitUnavailablePage/VisitUnavailablePage";
import { useStoreActions, useStoreDispatch, useStoreState } from "src/v2/models";
import { store as easyStore } from "src/v2/models/_create";
import { ForgotPassword } from "src/v2/routes/ForgotPassword";
import Help from "src/v2/routes/Help/Help";
import {
  ConsultContainer,
  FollowupConsultContainer,
  ReturnConsultConsultContainer,
  VisitContainer,
} from "src/v2/routes/Intake";
import LabsPage from "src/v2/routes/Labs/LabsPage";
import MembershipSignUp from "src/v2/routes/MembershipSignup";
import AllVisits from "src/v2/routes/MyCases/AllVisits";
import MyCasesV2 from "src/v2/routes/MyCases/MyCasesV2";
import ReturnVisitDetails from "src/v2/routes/MyCases/ReturnVisitDetails";
import Orders from "src/v2/routes/Orders";
import { ResetPassword } from "src/v2/routes/ResetPassword";
import VisitCategory from "src/v2/routes/Visit/VisitCategory";
import YourCare from "src/v2/routes/YourCare";
import BmiCalculatorPage from "src/v2/routes/YourCare/BmiCalculatorPage";
import ExploreVisits from "src/v2/routes/YourCare/ExploreVisits";
import YourCareVisitCategory from "src/v2/routes/YourCare/VisitCategory";

import { useAnalyticsDimension } from "./analytics/hooks";
import { MaintenancePage } from "./components";
import { VariantSettings } from "./components/ABTesting/VariantSettings";
import CareGuidePage from "./components/HealthActionPlan/CareGuidePage";
import HealthActionPlanPage from "./components/HealthActionPlan/HealthActionPlanPage";
import { Loader } from "./components/Loader";
import { Assessment } from "./routes/Assessment";
import InvoicesPage from "./routes/InvoicesPage";
import MembershipDetails from "./routes/MembershipDetails";
import MyCareTeamPage from "./routes/MyCareTeam/MyCareTeamPage";
import NoticePage from "./routes/NoticePages/NoticePage";
import { PhotoIDUpdateRequest } from "./routes/PhotoIDUpdateRequest";
import PostVisitSummary from "./routes/PostVisitSummary/PostVisitSummary";
import MedicalRecordsRequestForm from "./routes/Records/MedicalRecordsRequest/MedicalRecordsRequestForm";
import { WeightLossJournalPaywall } from "./routes/Records/WeightLossJournal/WeightLossJournalPaywall";
import SelfReportedMedications from "./routes/ReportedMedications/SelfReportedMedications";
import SelfCare from "./routes/SelfCare";
import { NotAvailablePage } from "./routes/SponsoredBenefits/NotAvailable";
import { NotEligiblePage } from "./routes/SponsoredBenefits/NotEligible";
import { SponsoredBenefits } from "./routes/SponsoredBenefits/SponsoredBenefits";
import CheckinResultsPage from "./v2/components/CheckinResultPage/CheckinResultsPage";
import StateIneligiblePage from "./v2/components/Page/StateIneligiblePage/StateIneligiblePage";
import { MembershipOptionsNotAuth } from "./v2/components/PlanComparison/MembershipOptionsNotAuth";
import { SignUpFunnel } from "./v2/components/SignUpFunnel/WeightLossSignUpFunnel";
import {
  ACCEPTED_ASSESSMENT_TYPES,
  MEMBERSHIP_PLANS,
  NON_MH_REPEATABLE_CONDITIONS,
} from "./v2/constants";
import { CHECKIN_OUTLINE_KEYS } from "./v2/models/checkins";
import PaywallRoute from "./v2/routes/MembershipRoute/PaywallRoute";
import CheckinRedirectHandler from "./v2/routes/YourCare/CheckinRedirectHandler";
import CheckInsListPage from "./v2/routes/YourCare/CheckInsListPage";

import "./fontAwesomeConfig";
import "./index.scss";

const { piwikContainerId, piwikContainerUrl } = config.tracking;
Analytics.initialize({
  piwikPro:
    (piwikContainerId &&
      piwikContainerUrl && [
        {
          containerId: piwikContainerId,
          containerUrl: piwikContainerUrl,
        },
      ]) ||
    undefined,
});

Modal.setAppElement("#root");

// eslint-disable-next-line
declare var window: any;

// check and save assessment score & type if available
const urlParams = new URLSearchParams(window.location.search);
const assessment_type = (urlParams.get("assessment_type") || "").toUpperCase();
const assessment_score = urlParams.get("assessment_score");
if (ACCEPTED_ASSESSMENT_TYPES.includes(assessment_type) && assessment_score) {
  localStorage.setItem("assessment_type", assessment_type);
  localStorage.setItem("assessment_score", assessment_score);
}
const registrationToken = urlParams.get("registration_token");
registrationToken && localStorage.setItem("registration_token", registrationToken);

const redirects = [
  // Redirects for old consult ab tests
  { from: "/consultation/birthcontrol-uRa39yHfk2", to: "/consultation/birthcontrol" },
  { from: "/consultation/birthcontrol-vJgr3ga2s1", to: "/consultation/birthcontrol" },
  { from: "/consultation/birthcontrol-wA12pm0d3L", to: "/consultation/birthcontrol" },
  { from: "/consultation/birthcontrol-xAv13rcHuK", to: "/consultation/birthcontrol" },
  { from: "/consultation/skincare-Afagh30oPl", to: "/consultation/skincare" },
  { from: "/consultation/lashes-Xg3sMte5a3", to: "/consultation/lashes" },

  // Redirect old visit urls to new consultation ones
  { from: "/visit/depression-screening", to: "/consultation/mental-health" },
  { from: "/visit/anxiety-screening", to: "/consultation/mental-health" },
  { from: "/visit/mood-disorder-screening", to: "/consultation/mental-health" },
  { from: "/visit/depression", to: "/consultation/mental-health" },
  { from: "/visit/anxiety", to: "/consultation/mental-health" },
  { from: "/visit/mood-disorder", to: "/consultation/mental-health" },
  { from: "/visit/nail-infection", to: "/consultation/nail-infection" },
  { from: "/visit/undefined", to: "/visits/your-visits" },

  // Redirect old visit page to your care explore visits
  { from: "/visit", to: "/your-care/explore-visits", exact: true },

  // Redirect old reports page to labs
  { from: "/my-lab-reports", to: "/your-care/labs" },

  // Redirect eyelashes followup url
  { from: "/followup/eyelashes", to: "followup/lashes" },

  // Redirect group therapy pages
  { from: "/group-therapy/signup", to: "/support-groups/signup" },
  { from: "/sessions/group-therapy", to: "/sessions/support-groups" },

  { from: "/consultation/anxiety", to: "/consultation/mental-health" },
  { from: "/followup/weight-loss", to: "/return-consult/weight-loss-checkup" },
  { from: "/followup/mental-health", to: "/return-consult/depression-anxiety-checkup" },
  { from: "/followup/menopause", to: "/return-consult/menopause-checkup" },
  { from: "/followup/nail-infection", to: "/return-consult/nail-infection-checkup" },
  { from: "/followup/pcos", to: "/return-consult/pcos-checkup" },
  { from: "/followup/hypothyroidism", to: "/return-consult/hypothyroidism-checkup" },
  { from: "/followup/vulvovaginitis", to: "/return-consult/vulvovaginitis-checkup" },
  { from: "/followup/cold-and-flu", to: "/return-consult/cold-and-flu-checkup" },
  { from: "/return-consult/genital-herpes-checkup", to: "/followup/herpes" },
  { from: "/return-consult/weight-loss", to: "/return-consult/weight-loss-checkup" },
  {
    from: "/return-consult/mental-health-checkup",
    to: "/return-consult/depression-anxiety-checkup",
  },
];

NON_MH_REPEATABLE_CONDITIONS.forEach((non_return_consults) => {
  redirects.push({
    from: PDB_ROUTES.RETURN_CONSULT_ROUTE(non_return_consults),
    to: PDB_ROUTES.CONSULTATION_ROUTE(non_return_consults),
  });
});

const wwwRedirects: string[] = ["/terms/consent", "/terms/privacy", "/terms"];

const Routes = () => {
  const enableHelpPage = useFeatureFlags("enableHelpPage");
  const enableWeightLossJournalPaywall = useFeatureFlags("enableWeightLossJournalPaywall");

  const checkMaintenanceData = useStoreActions(
    (actions) => actions.maintenance.checkMaintenanceData,
  );
  const { inMaintenance, upcomingMaintenance, startTime } = useStoreState(
    (state) => state.maintenance,
  );
  const authenticated = useStoreState((state) => state.auth.authenticated);
  const authenticatedUserId = useStoreState((state) => state.auth.authenticatedUser?.id);
  const fetchEmployerBenefits = useStoreActions((actions) => actions.profile.fetchEmployerBenefits);
  const fetchCheckin = useStoreActions((actions) => actions.checkins.fetchCheckIn);
  const { getLastHealthActionPlan } = useStoreActions((actions) => actions.healthActionPlan);
  const dispatch = useStoreDispatch();
  const { profile } = useStoreState((state) => state.profile);
  const CHECK_AUTH_INTERVAL_DELAY = 600000; // every 10 minutes == 600,000
  const fetchingRegistrationData = useStoreState(
    (actions) => actions.profile.fetchingRegistrationData,
  );

  useRedirects();
  const location = useLocation();
  setHistory(useHistory());

  React.useEffect(() => {
    if (authenticated && authenticatedUserId) {
      fetchEmployerBenefits();
      fetchCheckin({
        userId: authenticatedUserId,
        checkinKey: CHECKIN_OUTLINE_KEYS.COMPREHENSIVE_HEALTH_CHECK_IN,
      });
      getLastHealthActionPlan({ userId: authenticatedUserId });
      dispatch.profile.fetchRegistrationOutlineData({ userId: authenticatedUserId });
      dispatch.profile.verifyRegistrationToken();
    }
  }, [authenticated, authenticatedUserId]);

  useAnalyticsDimension(
    "Organization",
    profile?.employer_benefits?.length ? profile.employer_benefits[0].organization?.slug : "null",
  );

  React.useEffect(() => {
    checkMaintenanceData();
  }, []);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch.auth.checkAuth();
      return () => clearInterval(intervalId);
    }, CHECK_AUTH_INTERVAL_DELAY);
  }, []);

  React.useEffect(() => {
    let timeoutId: number | undefined = undefined;
    if (upcomingMaintenance && startTime) {
      const timeoutDelay = moment(startTime).diff(moment(), "milliseconds") + 3000;
      if (timeoutDelay < 3000) {
        return;
      }
      timeoutId = setTimeout(() => {
        checkMaintenanceData();
      }, timeoutDelay) as any;
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [upcomingMaintenance]);

  if (inMaintenance) {
    return <MaintenancePage />;
  }

  // This is to give a smoother expierence after signing up
  if (fetchingRegistrationData) {
    return <Loader show={true} />;
  }

  return (
    <Switch>
      <Route
        path={"/:url*([.]+)"}
        exact={false}
        render={() => <Redirect to={location.pathname.slice(0, -1)} />}
      />
      <Route exact={true} path="/" component={App} />
      {redirects.map(({ exact = false, from, to }) => (
        <Route key={from} path={from} exact={exact} render={() => <Redirect to={to} />} />
      ))}
      {wwwRedirects.map((wwwRedirect) => (
        <Route
          key={wwwRedirect}
          path={wwwRedirect}
          render={() => (window.location = `${config.wwwUrl}${wwwRedirect}`)}
        />
      ))}
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset/:resetKey" component={ResetPassword} />
      <Route path="/shop" render={() => (window.location = "https://shop.helloalpha.com/")} />
      <Route
        exact={true}
        path="/sponsored-benefits/not-eligible/:employer"
        component={NotEligiblePage}
      />
      <Route
        exact={true}
        path="/sponsored-benefits/not-available/:employer"
        component={NotAvailablePage}
      />
      <Route exact={true} path="/sponsored-benefits/:employer?" component={SponsoredBenefits} />
      <Route exact={true} path="/membership-options" component={MembershipOptionsNotAuth} />
      <Route exact={true} path="/weight-loss-plan/:pageKey?" component={SignUpFunnel} />
      {/* Route Shows when in temporary maintenance mode. */}
      <Route path="/maintenance" component={MaintenancePage} />
      <Authenticate>
        <Switch>
          {enableHelpPage && <Route path="/help" component={Help} />}
          <Route path="/my-visits" render={() => <Redirect to="/" />} />
          <Route path="/my-profile" render={() => <Redirect to={`/account`} />} />

          <Route path="/account" component={Account} exact />
          <Route path="/bmi-calculator" component={BmiCalculatorPage} exact />
          <Route
            path={PDB_ROUTES.ACCOUNT_PERSONAL_INFORMATION}
            component={PersonalInformation}
            exact
          />
          <Redirect to={PDB_ROUTES.ACCOUNT_PERSONAL_INFORMATION} from="/personal-information" />
          <Route path={PDB_ROUTES.PERSONAL_INFORMATION_CONTACT} component={AddressInformation} />
          <Redirect to={PDB_ROUTES.PERSONAL_INFORMATION_CONTACT} from="/address-information" />
          <Route path={PDB_ROUTES.PERSONAL_INFORMATION_PHOTO_ID} component={PhotoId} />
          <Redirect to={PDB_ROUTES.PERSONAL_INFORMATION_PHOTO_ID} from="/photo-id" />
          <Route path={PDB_ROUTES.PERSONAL_INFORMATION_PRONOUNS} component={PreferredPronouns} />
          <Route exact path={PDB_ROUTES.ALPHA_PCP} component={AlphaPCPFields} />
          <Route path={PDB_ROUTES.CARE_TEAM} component={PrimaryCareProvider} />
          <Route exact path={PDB_ROUTES.MY_CARE_TEAM_PAGE} component={MyCareTeamPage} />
          <Redirect to={PDB_ROUTES.CARE_TEAM} from="/primary-care-provider" />
          <Route
            path={PDB_ROUTES.PERSONAL_INFORMATION_EMERGENCY_CONTACT}
            component={EmergencyContactInformation}
          />
          <Redirect
            to={PDB_ROUTES.PERSONAL_INFORMATION_EMERGENCY_CONTACT}
            from="/emergency-contact"
          />
          <Route exact={true} path="/post-visit-summary/:pvs_id?" component={PostVisitSummary} />
          <Redirect to="/post-visit-summary" from="/after-visit-summary/:condition?/:pvs_id?" />
          <Redirect
            to="/post-visit-summary/:pvs_id?"
            from="/post-visit-summary/:condition?/:pvs_id?"
          />

          <Route path={PDB_ROUTES.YOUR_VISITS} component={AllVisits} />

          <Route
            exact={true}
            path="/return-visit-details/:condition_key"
            component={ReturnVisitDetails}
          />
          <Route exact path={PDB_ROUTES.HEALTH_INFORMATION} component={HealthInformation} />
          <Route exact path={PDB_ROUTES.MEMBERSHIP_AND_PAYMENT} component={MembershipAndPayment} />
          <Route exact={true} path="/messages" component={MessageThread} />
          <Route exact={true} path="/inbox" component={Inbox} />
          <Route exact={true} path="/messages/team/:team" component={MessageThread} />
          <Route path="/contact" render={() => <Redirect to="/messages" />} />
          <Route path={PDB_ROUTES.PAYMENT_METHODS} component={PaymentsPage} />
          <Redirect to={PDB_ROUTES.PAYMENT_METHODS} from="/payments" />
          <Route path={PDB_ROUTES.INSURANCE_INFORMATION} component={InsuranceInformation} />
          <Redirect to={PDB_ROUTES.INSURANCE_INFORMATION} from="/insurance-information" />
          <Route path="/pharmacy-information" component={PharmacyForm} />
          <Route path={PDB_ROUTES.SETTINGS} component={Settings} />
          <Redirect to={PDB_ROUTES.SETTINGS} from="/settings" />
          <Route path="/orders" component={Orders} />
          <Route path="/my-cases" component={MyCasesV2} />
          <Route path="/consultation/:outline/:pageKey?" component={ConsultContainer} />
          <Route path="/visit/:outline/:pageKey?" component={VisitContainer} />
          <Route path="/photo-update-request" component={PhotoIDUpdateRequest} />
          <Route path="/add-blood-pressure" component={AddBloodPressure} />
          <Route path="/followup/:outline/:pageKey?" component={FollowupConsultContainer} />
          <Route
            path="/return-consult/:outline/:pageKey?"
            component={ReturnConsultConsultContainer}
          />
          <Route path={`${PDB_ROUTES.INVOICES}/:uuid?`} component={InvoicesPage} />
          <Route exact={true} path={`${PDB_ROUTES.YOUR_CARE}`} component={YourCare} />
          <Route exact={true} path={`${PDB_ROUTES.EXPLORE_VISITS}`} component={ExploreVisits} />
          <Route
            exact={true}
            path={`${PDB_ROUTES.CHECKINS_LIST_PAGE}`}
            component={CheckInsListPage}
          />
          <Route
            exact={true}
            path={`${PDB_ROUTES.CHECKIN_RESULTS_PAGE}`}
            component={CheckinResultsPage}
          />
          <Redirect to="/" from="/health-checkin-redirect" />
          <Route
            exact={true}
            path={`${PDB_ROUTES.CHECKIN_REDIRECT_PAGE}`}
            component={CheckinRedirectHandler}
          />
          <Route exact={true} path="/your-care/labs" component={LabsPage} />
          <Route exact={true} path={PDB_ROUTES.SERVICE_CATEGORIES} component={VisitCategory} />
          <Route
            exact={true}
            path={`/your-care/explore-visits/:categoryKey`}
            component={YourCareVisitCategory}
          />
          <Route exact={true} path="/visit-details/:condition" component={VisitDetails} />
          <Route exact={true} path="/sync-visit-details/:consultId" component={SyncVisitDetails} />
          <Route
            exact={true}
            path="/sync-visit-details/:consultId/reschedule"
            component={SyncVisitReschedule}
          />
          <Route
            exact={true}
            path="/visit-details/:condition/treatment-info/:info"
            component={VisitDetailsTreatmentInfo}
          />
          <Route exact={true} path="/membership-signup" component={MembershipSignUp} />
          <Route
            path="/interview/:condition"
            render={(props: any) => {
              const { condition } = props.match.params;
              const _map: Record<string, string> = {
                eyelashes: "lashes",
              };
              const outline = _map[condition] || condition;
              return <Redirect to={`/consultation/${outline}`} />;
            }}
          />
          <Route exact={true} path={PDB_ROUTES.MEDICAL_RECORDS} component={MedicalRecords} />
          <Redirect to={PDB_ROUTES.MEDICAL_RECORDS} from="/medical-records" />
          <Route exact={true} path="/mental-health-records" component={MentalHealthRecords} />
          <Route
            path="/mental-health-records/:type(assessments|visits)"
            component={MentalHealthRecords}
          />
          <PaywallRoute
            path="/weight-loss-journal/medications"
            component={MedicationJournal}
            allowedPlanCodes={[MEMBERSHIP_PLANS.plus]}
            enable={enableWeightLossJournalPaywall}
            paywallTitle="Weight Loss Journal"
            paywallComponent={WeightLossJournalPaywall}
          />
          <PaywallRoute
            path="/weight-loss-journal/preferences"
            component={WeightLossJournalPreferences}
            allowedPlanCodes={[MEMBERSHIP_PLANS.plus]}
            enable={enableWeightLossJournalPaywall}
            paywallTitle="Weight Loss Journal"
            paywallComponent={WeightLossJournalPaywall}
          />
          <PaywallRoute
            path="/weight-loss-journal/measurements/:type?"
            component={WeightLossJournal}
            allowedPlanCodes={[MEMBERSHIP_PLANS.plus]}
            enable={enableWeightLossJournalPaywall}
            paywallTitle="Weight Loss Journal"
            paywallComponent={WeightLossJournalPaywall}
          />
          <PaywallRoute
            path="/weight-loss-journal"
            component={WeightLossJournalHome}
            allowedPlanCodes={[MEMBERSHIP_PLANS.plus]}
            enable={enableWeightLossJournalPaywall}
            paywallTitle="Weight Loss Journal"
            paywallComponent={WeightLossJournalPaywall}
          />
          <Route path="/self-care" component={SelfCare} />
          <Route path="/prescriptions" component={ActivePrescriptions} />
          <Route path="/reported-medications" component={SelfReportedMedications} />
          <Route path="/register-kit" component={RegisterKitPage} />
          <Route path="/medical-records-request" component={MedicalRecordsRequestForm} />
          <Route path="/notice-page/:condition" component={NoticePage} />
          <Route path={PDB_ROUTES.MEMBERSHIP_DETAILS} component={MembershipDetails} exact />
          <Route
            path={PDB_ROUTES.MEMBERSHIP_CANCELLATION}
            component={MembershipCancellation}
            exact
          />
          <Route path={PDB_ROUTES.MEMBERSHIP_CHANGE} component={MembershipChangeFlow} exact />
          <Redirect to={PDB_ROUTES.MEDICAL_RECORDS} from="/membership" />
          <Route path="/messages/new" component={NewMessageThread} />
          <Route path="/messages/:channelName" component={ChatThread} />
          <Route exact={true} path="/activate-benefits/:step?" component={ActivateBenefits} />
          <Route path="/state-ineligible/:step/:state?" component={StateIneligiblePage} exact />
          <Route
            path="/visit-unavailable/:step/:state?/:visit?"
            component={VisitUnavailablePage}
            exact
          />
          <Route
            exact={true}
            path={PDB_ROUTES.HEALTH_ACTION_PLAN}
            component={HealthActionPlanPage}
          />
          <Route path={`${PDB_ROUTES.CARE_GUIDES}`} component={CareGuidePage} />
          <Route path="/form/:id/:page?" component={GenericForm} />
          <Route path="/tool/:type(anxiety|depression)" component={Assessment} />
          {!config.isProd && <Route path="/variant-settings" component={VariantSettings} />}
          {/* Route 404 */}
          <Route component={NotFound404} />
        </Switch>
      </Authenticate>
    </Switch>
  );
};

const _render = () => {
  return (
    <StoreProvider store={easyStore}>
      <BrowserRouter>
        <BrazeContextProvider>
          <Routes />
        </BrazeContextProvider>
      </BrowserRouter>
    </StoreProvider>
  );
};

Sentry.init({
  dsn: config.sentry.dsn,
  environment: config.sentry.env,
  release: config.version,
  ignoreErrors: [
    /^ResizeObserver loop limit exceeded$/,
    "Non-Error promise rejection captured with value: Object Not Found Matching Id:2",
  ],
  beforeSend: (event, hint) => {
    const error = hint?.originalException;
    if (error && isError(error) && error.message?.includes("PubNub call failed")) {
      return null;
    }

    return event;
  },
});

ReactDOM.render(_render(), document.getElementById("root"));
