/** @format */
export enum MembershipManagementFlowEnum {
  cancel = "cancel",
  change = "change",
}

export interface FlowModalProps {
  setModalOpen: (value: boolean) => void;
  hasRequestedCancellation?: boolean;
  hasRequestedDowngrade?: boolean;
}
