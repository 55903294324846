/** @format */

import React from "react";
import { Link } from "react-router-dom";

import sanitizeHtml from "sanitize-html";

import { Typography } from "@alphamedical/components";
import { BadgePdb } from "src/v2/designSystem";

import type { PubNubMessage } from "src/utils/hooks/pubnub";
import type { Channel } from "src/v2/models/channels";

import { ChannelListIcon } from "src/components/Chat/ChannelList/ChannelListIcon";
import { threadIconMap } from "src/components/Chat/ChannelList/constants";
import { channelTypeMap } from "src/components/Chat/ChannelList/index";
import { shouldHideMessage } from "src/components/Chat/ChatChannel";
import { getTimetokenDisplay } from "src/components/Chat/utils";
import { useChannelMessages } from "src/utils/hooks/pubnub";
import { conditionInfoMap } from "src/v2/constants";

import "./ChannelListItem.scss";

export interface ChannelListItemProps {
  unreadMessageCount?: number;
  channel: Channel;
}

export const ChannelListItem = (props: ChannelListItemProps) => {
  const {
    unreadMessageCount,
    channel: { channel_type: channelType, name: channelId, public_name: title = "Chat", service },
  } = props;

  const [lastMessage, setLastMessage] = React.useState<PubNubMessage>();
  const [loadingMessages, messages] = useChannelMessages(channelId, 5);
  const [filteredMessages, setFilteredMessages] = React.useState<PubNubMessage[]>([]);

  const read = !unreadMessageCount;
  const messageCount = filteredMessages.length;

  const renderTime = (timetoken: string | number) => (
    <p className={`text-xs text-grey-160 ${!read && "font-bold"}`}>
      {getTimetokenDisplay(timetoken)}
    </p>
  );

  React.useEffect(() => {
    if (messages) {
      setFilteredMessages(messages.filter((message) => !shouldHideMessage(message)));
    }
  }, [messages]);

  // Initialize last message content
  React.useEffect(() => {
    const channelLastMessage =
      !loadingMessages && messageCount > 0 ? filteredMessages[messageCount - 1] : undefined;
    setLastMessage(channelLastMessage);
  }, [filteredMessages]);
  // Update whenever new message is sent and check that channel matches
  React.useEffect(() => {
    if (messageCount && filteredMessages[messageCount - 1].channel == channelId) {
      setLastMessage(filteredMessages[messageCount - 1]);
    }
  }, [filteredMessages.length]);

  const channelDescription = channelType ? channelTypeMap[channelType] : "";
  const sanitizedText = sanitizeHtml(
    (lastMessage && lastMessage.message.text) || channelDescription,
    {
      allowedTags: [],
      allowedAttributes: {},
    },
  );

  const conditionIcon =
    service?.condition?.key && conditionInfoMap[service.condition.key]?.imageSrc;
  const defaultIcon = channelType ? threadIconMap[channelType] : "/assets/doctor-icon.svg";
  const imgSrc = conditionIcon || defaultIcon;

  return (
    <Link to={`/messages/${channelId}`}>
      <div className="flex items-center border-b border-grey-light py-6">
        <ChannelListIcon src={imgSrc} fit={!conditionIcon} />
        <div className="flex flex-col flex-1">
          <div className="flex justify-between mb-1 items-center">
            <Typography variant={read ? "h4" : "subtitle1"}>{title}</Typography>
            <div>{lastMessage && renderTime(lastMessage.timetoken)}</div>
          </div>
          <div className="flex justify-between items-center">
            <div>
              <Typography
                variant={read ? "body2" : "h5"}
                className="max-h-full line-clamp text-grey-160"
              >
                {sanitizedText}
              </Typography>
            </div>
            <div className="ml-4">
              {!!unreadMessageCount && (
                <BadgePdb
                  variant="informative"
                  label={unreadMessageCount > 9 ? "9+" : unreadMessageCount}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
