/** @format */

import React from "react";
import { useField } from "react-final-form";

import { NameIntro } from "src/components/DynamicForm/Fields/CustomFields/WelcomeFlow/WelcomeNameIntro/NameIntro";
import { WelcomePageContainerHealthCheckIn } from "src/components/DynamicForm/Fields/CustomFields/WelcomeFlow/WelcomePageCotainer/WelcomePageContainerHealthCheckIn";
import welcome from "src/content/welcome";

interface WelcomeNameIntroHealthCheckinProps {
  questionKey: string;
}

export const WelcomeNameIntroHealthCheckin = ({
  questionKey,
}: WelcomeNameIntroHealthCheckinProps) => {
  const field = useField(questionKey);
  return (
    <WelcomePageContainerHealthCheckIn
      questionKey={questionKey}
      submitButtonTitle={welcome.screen2.submitButton}
    >
      <NameIntro
        onChange={(value) => field.input.onChange(value)}
        defaultValue={field.input.value}
      />
    </WelcomePageContainerHealthCheckIn>
  );
};
