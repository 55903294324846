/** @format */

import React from "react";
import { Link } from "react-router-dom";

interface GenericCardProps {
  title: string;
  imageSrc: string;
  detailsText: string;
  detailsLink?: string | null;
  detailsTextColor?: string;
  bgColor?: string;
  extraClassName?: string;
}

const GenericCard = ({
  title,
  imageSrc,
  detailsLink,
  detailsText,
  detailsTextColor,
  bgColor,
  extraClassName,
}: GenericCardProps) => {
  return (
    <div className={`p-6 ${bgColor || "bg-white"} ${extraClassName}`} style={{ height: "125px" }}>
      <div className="flex mb-3 justify-between">
        <p className="font-semibold">{title}</p>
      </div>
      <div className="flex justify-between items-end">
        <img src={imageSrc} alt="" role="presentation" className="h-10" />
        {(detailsLink && (
          <Link
            to={detailsLink}
            className={`text-right flex items-center ${detailsTextColor || "text-sky-dark"} font-semibold text-sm`}
          >
            {detailsText}
            <span className="ml-2 fa fa-arrow-right" />
          </Link>
        )) || (
          <p
            className={`text-right w-48 flex items-center ${detailsTextColor || "text-primary"} font-semibold text-sm`}
          >
            {detailsText}
          </p>
        )}
      </div>
      <style>{`

      `}</style>
    </div>
  );
};

export default GenericCard;
