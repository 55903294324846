/** @format */

import React from "react";

import { Typography } from "@alphamedical/components";

import { FullPageModal } from "src/v2/components/_modals";

import type { PharmacyModalContent } from ".";

interface PharmacyDifferencesModalProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  content: PharmacyModalContent;
}

const PharmacyDifferencesModal = ({
  isOpen,
  setIsOpen,
  content,
}: PharmacyDifferencesModalProps) => {
  return (
    <FullPageModal setIsOpen={setIsOpen} isOpen={isOpen} title={content.title}>
      <div
        id="modal-content"
        style={{ height: "100%" }}
        className={"p-6 flex flex-col no-scrollbar overflow-y-scroll"}
      >
        {content?.options.map((section: any, contentIdx: number) => {
          return (
            <div key={contentIdx}>
              <Typography variant="h3">{section.title}</Typography>
              <ul className="text-forest-darker list-disc pl-5 mt-4 mb-6">
                {section.bullets.map((bullet: string, idx: number) => {
                  return (
                    <li key={idx}>
                      <Typography variant="body1">{bullet}</Typography>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
        {content.footer && (
          <div className="mt-4 mb-10">
            {content.footer.map((footer: string, idx: number) => {
              return (
                <div key={idx}>
                  <Typography key={idx} variant="body2" className="text-grey-160">
                    {footer}
                  </Typography>
                  <br />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </FullPageModal>
  );
};

export default PharmacyDifferencesModal;
