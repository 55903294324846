/** @format */

import type React from "react";

import type { GroupSessionUnavailableError } from "src/components/DynamicForm/Validators/types";

import { useDynamicFormUrl } from "src/components/DynamicForm/hooks";

import type { ValidationErrorContentProps } from "./types";

export const GroupSessionUnavailableMessage: React.FC<
  ValidationErrorContentProps<GroupSessionUnavailableError>
> = ({ validationError: { description } }) => {
  const topicsUrl = useDynamicFormUrl("group-session-topics");
  return (
    <>
      <p>
        {description || (
          <>
            The session you have chosen is no longer available. Please choose a new topic and
            session that is still available.
          </>
        )}
      </p>
      <a
        href={topicsUrl || "#"}
        className="mt-4 block btn bg-violator text-white w-full font-light"
      >
        <div className="inline-block">
          Choose a new session <span className="fas fa-arrow-right text-sm" />
        </div>
      </a>
    </>
  );
};
