/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CashApprovalReturnSchema } from '../models/CashApprovalReturnSchema';
import type { MedicationOrderAutomationsBadAddressAutomationRequestBodySchema } from '../models/MedicationOrderAutomationsBadAddressAutomationRequestBodySchema';
import type { MedicationOrderAutomationsCashApprovalAutomationRequestBodySchema } from '../models/MedicationOrderAutomationsCashApprovalAutomationRequestBodySchema';
import type { MedicationOrderAutomationsOutOfStockAutomationRequestBodySchema } from '../models/MedicationOrderAutomationsOutOfStockAutomationRequestBodySchema';
import type { MedicationOrderSchema } from '../models/MedicationOrderSchema';
import type { OrderSchema } from '../models/OrderSchema';
import type { OutOfStockReturnSchema } from '../models/OutOfStockReturnSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrdersService {

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static resubmitInsuranceOrders({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/orders/{user_id}/insurance',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns OrderSchema
     * @throws ApiError
     */
    public static getOrders({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<Array<OrderSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/users/{user_id}/orders',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns MedicationOrderSchema
     * @throws ApiError
     */
    public static getOrdersByCondition({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<Array<MedicationOrderSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/users/{user_id}/orders-by-condition',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getOrder({
        userId,
        orderId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        orderId: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/users/{user_id}/orders/{order_id}',
            path: {
                'user_id': userId,
                'order_id': orderId,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static badAddressAutomation({
        userId,
        orderId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        orderId: string,
        requestBody: MedicationOrderAutomationsBadAddressAutomationRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/users/{user_id}/orders/{order_id}/bad_address',
            path: {
                'user_id': userId,
                'order_id': orderId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CashApprovalReturnSchema
     * @throws ApiError
     */
    public static cashApprovalAutomation({
        userId,
        orderId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        orderId: string,
        requestBody: MedicationOrderAutomationsCashApprovalAutomationRequestBodySchema,
    }): CancelablePromise<CashApprovalReturnSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/users/{user_id}/orders/{order_id}/cash_approval',
            path: {
                'user_id': userId,
                'order_id': orderId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static approveOrDenyCopay({
        userId,
        orderId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        orderId: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/users/{user_id}/orders/{order_id}/copay',
            path: {
                'user_id': userId,
                'order_id': orderId,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static tpErrorAutomation({
        userId,
        orderId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        orderId: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/users/{user_id}/orders/{order_id}/order_user_approvals',
            path: {
                'user_id': userId,
                'order_id': orderId,
            },
        });
    }

    /**
     * @returns OutOfStockReturnSchema
     * @throws ApiError
     */
    public static outOfStockAutomation({
        userId,
        orderId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        orderId: string,
        requestBody: MedicationOrderAutomationsOutOfStockAutomationRequestBodySchema,
    }): CancelablePromise<OutOfStockReturnSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/users/{user_id}/orders/{order_id}/out_of_stock',
            path: {
                'user_id': userId,
                'order_id': orderId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
