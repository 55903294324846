/** @format */

import React from "react";

interface CarrotSvgProps {
  fill?: string;
  direction?: "right" | "left" | "up" | "down";
}

/**
 * Creates a carrot (>) default is right. Default color is white.
 *
 * @param fill       the color of the carrot
 * @param direction
 * @constructor
 */
const CarrotSvg = ({ fill = "#D2DBDA", direction = "right" }: CarrotSvgProps) => {
  let rotate = 0;

  switch (direction) {
    case "left":
      rotate = 180;
      break;
    case "up":
      rotate = -90;
      break;
    case "down":
      rotate = 90;
      break;
  }

  return (
    <svg
      width="10px"
      height="17px"
      viewBox="0 0 10 17"
      version="1.1"
      transform={`rotate(${rotate})`}
    >
      <title>2B2C878B-943D-46EB-B843-E1862F9B599C</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="nav-drawer---main"
          transform="translate(-345.000000, -232.000000)"
          fill={fill}
          fillRule="nonzero"
        >
          <g id="Group-5" transform="translate(25.000000, 77.000000)">
            <g id="Group-3">
              <g id="Group-7" transform="translate(-0.000000, 147.000000)">
                <g id="Group-6" transform="translate(0.000000, 0.000000)">
                  <path
                    d="M332.592627,12.9277586 L331.803604,12.1546896 C331.698467,12.0514549 331.577355,12 331.440434,12 C331.303845,12 331.182789,12.0514549 331.077652,12.1546896 L324.875083,18.2319442 L318.67279,12.1548521 C318.567598,12.0516174 318.446542,12.0001625 318.309787,12.0001625 C318.172976,12.0001625 318.05192,12.0516174 317.946783,12.1548521 L317.157871,12.9279752 C317.052513,13.0309933 317,13.1496103 317,13.2836638 C317,13.417609 317.052679,13.536226 317.157871,13.6392441 L324.512079,20.8454187 C324.617216,20.948491 324.738328,21 324.875083,21 C325.011838,21 325.132729,20.948491 325.23781,20.8454187 L332.592627,13.6392441 C332.697763,13.5361719 332.75,13.4175548 332.75,13.2836638 C332.75,13.1496103 332.697763,13.0309933 332.592627,12.9277586 Z"
                    id="Path"
                    transform="translate(324.875000, 16.500000) rotate(270.000000) translate(-324.875000, -16.500000) "
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CarrotSvg;
