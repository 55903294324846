/** @format */

import { MembershipManagementContent } from "src/content/v2/routes/Membership/MembershipManagement";

import { MembershipManagementFlowEnum } from "./types";

export const CONTENT = {
  [MembershipManagementFlowEnum.cancel]: MembershipManagementContent.cancelFlow,
  [MembershipManagementFlowEnum.change]: MembershipManagementContent.changeFlow,
};
