/** @format */

import type { ImageFile } from "react-dropzone";

import React from "react";

import * as Sentry from "@sentry/react";
import type { Dispatch } from "easy-peasy";
import type { ObjectCustom } from "pubnub";
import PubNub from "pubnub";
import { usePubNub } from "pubnub-react";

import { formatDateTodayYesterday } from "@alphamedical/components";

import type { FileSchema } from "src/api";
import type { PubNubMessage } from "src/utils/hooks/pubnub";
import type { CreateModel } from "src/v2/models/_create";
import type { Channel } from "src/v2/models/channels";

import { ChatService, FilesService } from "src/api";
import { fileToDataURI } from "src/v2/models/userLabs";

export const uploadFile = async (file: ImageFile, channelId: number) => {
  if (!channelId || !file) {
    const _message = `File Upload Failed: Channel ID: ${channelId}, File: ${file}`;
    const _error = new Error(_message);
    Sentry.captureException(_error);
    throw _error;
  }

  const dataUri = await fileToDataURI(file);
  return FilesService.postFiles({
    requestBody: { channel_id: channelId, data_uri: dataUri, file_name: file.name },
  })
    .then((_file: FileSchema) => {
      return _file.id;
    })
    .catch((_err) => {
      Sentry.captureException(_err);
      throw _err;
    });
};

export interface SendMessageProps {
  text: string;
  authorId: number;
  channelName: string;
  setText: (str: string) => void;
  pubnub: any;
  categorySlug?: string;
  callBack?: () => void;
  newMessageThread?: boolean;
  noNotification?: boolean;
  conditionKey?: string;
  attachments?: any;
}

export const deleteFile = (
  fileId: any,
  attachments: any[],
  setAttachments: (attachments: any[]) => void,
) => {
  FilesService.deleteFile({ fileId: fileId })
    .then(() => {
      // remove file from front and backend
      const filteredAttachments = attachments.filter((a) => a !== fileId);
      setAttachments(filteredAttachments);
    })
    .catch((e) => Sentry.captureException(e));
};

export const sendMessage = async ({
  text,
  authorId,
  channelName,
  setText,
  pubnub,
  categorySlug,
  callBack,
  newMessageThread,
  noNotification,
  conditionKey,
  attachments,
}: SendMessageProps) => {
  if (!text && attachments.length === 0) {
    return;
  }
  const message = {
    authorId,
    channel: channelName,
    id: PubNub.generateUUID(),
    text,
    categorySlug,
    newMessageThread,
    noNotification,
    conditionKey,
    attachments,
  };
  return ChatService.sendMessage({ userId: authorId, requestBody: message }).then((response) => {
    if (newMessageThread) {
      pubnub.addMessageAction({
        channel: channelName,
        messageTimetoken: response.timetoken,
        action: { type: "receipt", value: "message_read" },
      });
    }
    setText("");
    callBack && callBack();

    return response;
  });
};

export interface AddMessageActionProps {
  pubnub: any;
  channelId: string;
  timetoken: string | number;
  value: string;
  type: string;
}

export const addMessageAction = async ({
  pubnub,
  channelId,
  timetoken,
  value,
  type,
}: AddMessageActionProps) =>
  await pubnub.addMessageAction({
    channel: channelId,
    messageTimetoken: timetoken,
    action: {
      type,
      value,
    },
  });

interface OnFileDropProps {
  acceptedFiles: ImageFile[];
  channels?: Channel[];
  channelName: string;
  setAttachments: (attachments: any[]) => void;
  attachments: any[];
  dispatch: Dispatch<CreateModel, any>;
}
const displayFileUploadErrorSnack = (dispatch: Dispatch<CreateModel, any>) => {
  dispatch.snacks.addSnack({
    type: "error",
    message: "File upload failed, please try again.",
    id: "file_upload_failure",
    delay: 5,
  });
};

export const onFileDrop = ({
  acceptedFiles,
  channels,
  channelName,
  setAttachments,
  attachments,
  dispatch,
}: OnFileDropProps) => {
  if (!acceptedFiles.length) {
    return;
  }
  const channel = channels?.find((ch) => ch.name === channelName);
  if (channel && channel.id) {
    const arrayToConcat: any = [];
    acceptedFiles.forEach((file) => {
      if (file) {
        try {
          uploadFile(file, channel.id)
            .then((file_id) => {
              if (file_id) {
                arrayToConcat.push(file_id);
                setAttachments(attachments.concat(arrayToConcat));
              }
            })
            .catch(() => displayFileUploadErrorSnack(dispatch));
        } catch {
          displayFileUploadErrorSnack(dispatch);
        }
      }
    });
  }
};

type Scalar = string | number | boolean;

export const useChannelMetaData = (
  channelName: string,
  metaKey: string,
  defaultValue: Scalar,
): [Scalar, (scalar: Scalar) => void, boolean] => {
  const pubnubClient = usePubNub();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [metaValue, setMetaValue] = React.useState(defaultValue);
  const [metaDataCustom, setMetaDataCustom] = React.useState<ObjectCustom | null | undefined>();
  React.useEffect(() => {
    pubnubClient.objects
      .getChannelMetadata({
        channel: channelName,
      })
      .then((res) => {
        setMetaDataCustom(res.data.custom);
        res.data.custom && setMetaValue(res.data.custom[metaKey]);
      })
      .catch((err) => {
        // check for 404
        console.log({ err });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [channelName]);

  const changeMetaValue = (value: Scalar) => {
    pubnubClient.objects
      .setChannelMetadata({
        channel: channelName,
        data: {
          custom: { ...metaDataCustom, [metaKey]: value }, // preserve existing metaData, pass in new value
        },
      })
      .then((res) => {
        setMetaValue(value);
        setMetaDataCustom(res.data.custom);
      });
  };

  return [metaValue, changeMetaValue, loading];
};
export const getUserMessageColor = (user: any) => {
  const hasRoles = user && user.roles;
  const isSupportTeam = hasRoles && user.roles.includes("customer_associate");
  if (isSupportTeam) {
    return "bg-cloud-40";
  } else if (hasRoles && user.roles.includes("clinician", "registered_nurse")) {
    return "bg-sand-60";
  } else {
    return "bg-grey-20";
  }
};

export const getDBUserFromMessage = (
  DBChannels: any[] | undefined,
  message: PubNubMessage | undefined,
) => {
  const usersInDBChannel =
    DBChannels &&
    DBChannels.reduce((acc: any[], channel) => {
      return acc.concat(channel.users);
    }, []);
  return (
    usersInDBChannel &&
    usersInDBChannel.find((user) => message && message.message.authorId === user.id)
  );
};

export const getTimetokenDisplay = (timetoken: string | number) => {
  const messageDate = new Date(
    (typeof timetoken === "string" ? parseInt(timetoken) : timetoken) / 10000,
  );

  return formatDateTodayYesterday(messageDate);
};

export const getProviderSubtitle = (user: any) => {
  const hasRoles = user && user.roles;
  const isSupportTeam = hasRoles && user.roles.includes("customer_associate");
  if (isSupportTeam) {
    return "Alpha Support";
  } else if (hasRoles && user.roles.includes("clinician", "registered_nurse")) {
    return "Your Alpha Provider";
  } else {
    return "";
  }
};
