/** @format */

import React from "react";

import { SynchronousVisitService } from "src/api";
import { useCurrentUser } from "src/v2/models/profile";

export const useUserRequiresSyncVisit = () => {
  const current_user = useCurrentUser();
  const [needsSync, setNeedsSync] = React.useState(false);

  React.useEffect(() => {
    SynchronousVisitService.getNeedsSyncVisit({ userId: current_user.id }).then((response) => {
      setNeedsSync(response);
    });
  }, []);

  return needsSync;
};
