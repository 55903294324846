/** @format */

import _ from "lodash";

import { logAnalyticsEvent } from "src/analytics/utils";
import config from "src/config";
import { checkConditionals, isValidTrackingConditional } from "src/v2/utils/page/fields";

import type { InvalidType } from "./validators";

import { INVALID_TYPE_CONDITION } from "./_validators/condition";
import { INVALID_TYPE_INSURANCE } from "./_validators/insurance";
import * as VALIDATORS from "./validators";
import Validators from "./validators";

interface EventTrigger {
  id: string;
  type: string;
}

const _pageTrigger: any = (event: { trigger: EventTrigger[] }, page: any, values: any) => {
  const _values = { ...page, ...values };
  if (event.trigger) {
    let invalidator: InvalidType = {
      invalid: false,
      invalidType: INVALID_TYPE_CONDITION,
    };
    for (let i = 0; i < event.trigger.length; i++) {
      const eventTrigger = event.trigger[i];
      const skippedValidators = config.featureFlags.disabledValidators || [];
      if (eventTrigger.type === "validate" && skippedValidators.indexOf(eventTrigger.id) === -1) {
        const _validator = Validators[eventTrigger.id];
        if (_validator) {
          invalidator = _validator(_values);
        } else {
          if (eventTrigger.id !== VALIDATORS.ELIGIBILITY_INSURANCE) {
            invalidator = {
              invalid: false,
              invalidType: INVALID_TYPE_INSURANCE,
            };
          }
        }
        if (invalidator.invalid) {
          return invalidator;
        }
      }
    }

    return invalidator;
  }
  return;
};

const _pageAnalytics = (
  event: { analytics?: { category: any; action: any; conditionals?: any[] }[] },
  page: any,
  values: any,
) => {
  if (event.analytics) {
    event.analytics.forEach(({ action, category, conditionals = [] }) => {
      if (
        conditionals
          .filter((conditional) => isValidTrackingConditional(conditional))
          .every((conditional) => checkConditionals(values, conditional))
      ) {
        logAnalyticsEvent(category, action, page.key);
      }
    });
  }
};

type actionKeyTypes = "beforeSubmit" | "onSubmit" | "onAfter" | "onBefore" | "onLoad";

const _pageAction = (_actionKey: actionKeyTypes) =>
  _.debounce(
    (_condition: string, page: any, values: any, consult_type: string) => {
      if (page[_actionKey]) {
        const trigger = _pageTrigger(page[_actionKey], page, values, consult_type);
        _pageAnalytics(page[_actionKey], page, values);

        if (trigger) {
          if (trigger instanceof Promise) {
            return trigger;
          } else {
            return new Promise<void>((resolve, reject) => {
              if (trigger.invalid) {
                reject(trigger);
              }
              resolve();
            });
          }
        }
      }
      return Promise.resolve();
    },
    400,
    { leading: true },
  );

export const pageBeforeSubmit = _pageAction("beforeSubmit");
export const pageOnSubmit = _pageAction("onSubmit");
export const pageOnAfter = _pageAction("onAfter");
export const pageOnBefore = _pageAction("onBefore");
export const pageOnLoad = _pageAction("onLoad");

export const scrollToTop = _.debounce(() => {
  const page = document.getElementById("page-container");
  if (page) {
    page.scrollTo(0, 0);
  }
}, 350);
