/** @format */

import React from "react";
import { OnChange } from "react-final-form-listeners";

import _, { filter } from "lodash";

import { Typography } from "@alphamedical/components";
import { ButtonPdb, RadioSelection } from "src/v2/designSystem";

import type { Address } from "src/api";

import { EhrService } from "src/api";
import { serializeDependency } from "src/utils";
import { useFeatureFlags } from "src/utils/hooks/useFeatureFlags";
import AddressForm from "src/v2/components/AddressForm";
import { AlphaModal } from "src/v2/components/AlphaModal";
import { PharmacySearch } from "src/v2/components/Forms/PharmacySearch/PharmacySearch";
import { paxlovidWarning } from "src/v2/components/Page/PaxlovidLocator";
import { useStoreDispatch, useStoreState } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";

export const ADDRESS_TYPES: Record<string, string> = {
  PHARMACY: "pharmacy",
  LAB: "lab",
};

const DEFAULT_ADDRESS_PROMPT = {
  PHARMACY: "Please select the pharmacy where you want to pick up your medication",
  LAB: "Please select your lab",
};

const DEFAULT_SELECT_PROMPT = {
  PHARMACY: "Your medication will be sent to the pharmacy below.",
  LAB: "Your tests will be sent to the lab below.",
};
const DEFAULT_SUBTITLE = {
  PHARMACY: "No current pharmacies. Add one Now!",
  LAB: "No current labs. Add one Now!",
};

const AddressPage = ({ formProps, fields, values, nextClick }: any) => {
  const addressField = fields[0];
  const fieldKey = addressField.key;
  const dispatch = useStoreDispatch();
  const addresses = useStoreState((state) => state.addresses.addresses);
  const addressType: Address["type"] = _.get(addressField, "address_type", "PHARMACY");
  const [selectedValue, setSelectedValue] = React.useState(values[fieldKey] || "");
  const user = useCurrentUser();

  const enablePharmacySearch = useFeatureFlags("enablePharmacySearch");

  const updateSelected = (value: any) => {
    formProps.form.change(fieldKey, value);
    setSelectedValue(value);
  };

  const [addAddress, setAddAddress] = React.useState(false);

  // selected pharmacy for edit
  const [selectedPharmacy, setSelectedPharmacy] = React.useState({});
  const { currentConsultation } = useStoreState((state) => state.consults);
  const conditionKey = currentConsultation?.subscription?.condition?.key;
  const isCovidConsult = conditionKey === "covid-19-treatment";

  const onMount = () => {
    (async () => {
      await dispatch.addresses.fetchAddresses({ type: addressType });
    })();
  };

  React.useEffect(onMount, []);
  React.useEffect(() => {
    if (!values[fieldKey] || enablePharmacySearch) {
      const defaultAddress = addresses.find((addr) => addr.default);
      const defaultValue = defaultAddress ? defaultAddress.id : "";
      updateSelected(defaultValue);
    }
    setAddAddress(addresses.length === 0);
  }, [serializeDependency(addresses, ["id"])]);

  const hasAddresses = addresses.length > 0;
  const title = addressField.title || DEFAULT_ADDRESS_PROMPT[addressType];
  const selectedHeader = DEFAULT_SELECT_PROMPT[addressType] || "";
  const subtitle = addressField.subtitle || DEFAULT_SUBTITLE[addressType];
  const showAddressFromLocator = (id?: number) =>
    isCovidConsult && values[fieldKey] === values?.["covid_treatment_locator"]
      ? id === values[fieldKey]
      : true;

  const filteredAddresses = filter(
    addresses,
    (address) => address.type === addressType && showAddressFromLocator(address.id),
  );

  const sortedAddresses = filteredAddresses.sort((a, b) => {
    if (a.default) {
      return -1;
    } else if (b.default) {
      return 1;
    }
    return 0;
  });

  const getAddressInfoCard = (address: Address) => (
    <div className="flex justify-between items-center">
      <div>
        <Typography variant="h3" className="mb-1">
          {address.name}
        </Typography>
        <Typography variant="body1">{address.address1}</Typography>
        <Typography variant="body1">{address?.address2}</Typography>
        <Typography variant="body1">{`${address.city}, ${address.state} ${address.zipcode}`}</Typography>
      </div>

      {!enablePharmacySearch && (
        <ButtonPdb
          variant="transparent"
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            setAddAddress(true);
            setSelectedPharmacy(address);
          }}
        >
          Edit
        </ButtonPdb>
      )}
    </div>
  );

  const radioOptions: any = sortedAddresses.map((address) => ({
    label: getAddressInfoCard(address),
    value: address.id.toString(),
    badgeProps: {
      label: enablePharmacySearch && address.default ? "Default" : "",
    },
  }));

  const getCurrentAddress = (addressId: number) =>
    addresses.find((address) => address.id === addressId);
  const selectedAddress = getCurrentAddress(selectedValue);

  const goToNextQuestion = () => {
    const currentAddress = selectedAddress || getCurrentAddress(parseInt(selectedValue));
    if (currentAddress && currentAddress.default !== true) {
      EhrService.setDefaultPharmacy({
        userId: user.id,
        requestBody: {
          athena_pharmacy: {
            ...currentAddress,
            clinicalproviderid: currentAddress.clinical_provider_id,
          },
        },
      });
    }
    nextClick();
  };

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAddAddress(true);
    setSelectedPharmacy({});
  };

  const handleLinkKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      setAddAddress(true);
      setSelectedPharmacy({});
    }
  };

  const showPharmacySearch = enablePharmacySearch && addressType != "LAB";
  const pharmacySearchModal = (
    <PharmacySearch open={addAddress} setOpen={() => setAddAddress(false)} />
  );
  const addressEntryModal = (
    <AlphaModal
      bgColor="white"
      closeIconColor="primary-40"
      cxPadding="p-0"
      isOpen={addAddress}
      onRequestClose={() => setAddAddress(false)}
      className="max-w-sm m-auto"
    >
      <AddressForm
        closeModal={() => setAddAddress(false)}
        selectedPharmacy={selectedPharmacy}
        addressType={addressType}
        updateSelected={updateSelected}
        nextClick={nextClick}
      />
    </AlphaModal>
  );

  return (
    <div>
      {(hasAddresses && selectedValue?.length == 0) || filteredAddresses.length > 1 ? (
        <div className="text-xl my-4">{title}</div>
      ) : selectedValue ? (
        <div className="text-xl my-4">{selectedHeader}</div>
      ) : (
        <></>
      )}
      {isCovidConsult && paxlovidWarning}
      {filteredAddresses.length == 1 && addressType == "PHARMACY" && (
        <h3 className="text-base uppercase text-primary-lighter my-2">{"Your Pharmacy"}</h3>
      )}
      {filteredAddresses.length > 1 && addressType == "PHARMACY" && (
        <h3 className="text-base uppercase text-primary-lighter my-2">{"Your Pharmacies"}</h3>
      )}
      {filteredAddresses.length == 0 && <div className="text-xl my-4">{subtitle}</div>}

      <RadioSelection
        options={radioOptions}
        name="pharmacy-address-selector"
        defaultValue={selectedAddress?.id.toString()}
      />
      <OnChange name="pharmacy-address-selector">
        {(value: string) => updateSelected(value)}
      </OnChange>

      <div>
        {addressType == "PHARMACY" ? (
          <button
            className="flex flex-row items-center mb-16 mt-2 w-full"
            onClick={handleOnClick}
            onKeyDown={handleLinkKeyDown}
          >
            <img src="/assets/plus.svg" alt="plus icon" className="h-4 mr-3" />
            <div className="cursor-pointer text-sky-dark text-lg">
              {hasAddresses ? "Pick up at a different pharmacy" : "Add new pharmacy address"}
            </div>
          </button>
        ) : (
          <div
            onClick={() => {
              setAddAddress(true);
              setSelectedPharmacy({});
            }}
            className="underline cursor-pointer hover:text-sky-dark mb-6"
          >
            Add new lab address
          </div>
        )}
      </div>
      <input
        name={fieldKey}
        type="text"
        className={`
          border-b w-full
          p-3 my-3 outline-none
          hidden
        `}
        disabled={true}
        onChange={(e) => setSelectedValue(e.target.value)}
        value={selectedValue}
      />
      {showPharmacySearch ? pharmacySearchModal : addressEntryModal}
      <button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          goToNextQuestion();
        }}
        className={`btn btn-primary w-full mb-5`}
        disabled={!selectedValue}
      >
        Next
      </button>
    </div>
  );
};

export default AddressPage;
