/** @format */

import * as Sentry from "@sentry/react";
import type { Action, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";

import type { MembershipSchema } from "src/api";

import { ServicesService } from "src/api";
import { _POST, getTokenHeaderV2 } from "src/helpers/http";

import type { CreateModel } from "./_create";

export interface MembershipModel {
  membership?: MembershipSchema;
  isFetchingMembership: boolean;
  MembershipPost?: boolean;

  setMembership: Action<MembershipModel, MembershipSchema>;

  clearMembership: Action<MembershipModel>;

  setIsFetchingMembership: Action<MembershipModel, boolean>;

  setMembershipPost: Action<MembershipModel, boolean>;

  signupMembership: Thunk<
    MembershipModel,
    {
      healthGoals?: string;
      has_pcp?: string;
      consult_state?: string;
      payment_plan: string;
    }
  >;

  fetchOrCreateMembership: Thunk<MembershipModel>;

  fetchMembership: Thunk<MembershipModel>;

  upgradeMembership: Thunk<
    MembershipModel,
    { newPlan: "alpha-membership" | "alpha-membership-plus"; recurlyId: number }
  >;

  cancelMembership: Thunk<MembershipModel, { recurlySubscriptionId: number }>;
}

export const membershipStore: MembershipModel = {
  isFetchingMembership: false,

  setMembership: action((state, membership) => {
    state.membership = membership;
  }),

  clearMembership: action((state) => {
    state.membership = undefined;
  }),

  setIsFetchingMembership: action((state, boolean) => {
    state.isFetchingMembership = boolean;
  }),

  setMembershipPost: action((state, boolean) => {
    state.MembershipPost = boolean;
  }),

  signupMembership: thunk(async (actions, payload, { getState, getStoreState }) => {
    const state = getState();
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;

    if (state.MembershipPost) {
      console.warn("Already posting membership status...please wait");
      return;
    }

    actions.setMembershipPost(true);

    const couponCode = localStorage.getItem("membership_signup.coupon_code");

    try {
      const url = `/users/${currentProfileId}/membership/add`;
      const response = await _POST(
        url,
        {
          plan_code: payload.payment_plan === "plus" ? "alpha-membership-plus" : "alpha-membership",
        },
        getTokenHeaderV2(),
      );

      // clear coupon_code once used
      if (couponCode) {
        localStorage.removeItem(`membership_signup.coupon_code`);
      }

      actions.setMembership(response);
    } finally {
      actions.setMembershipPost(false);
    }
  }),

  fetchOrCreateMembership: thunk(async (actions, __, { getState, getStoreState }) => {
    const state = getState();
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;
    if (state.isFetchingMembership) {
      console.warn("Already fetching plan details...please wait");
      return;
    }
    actions.setIsFetchingMembership(true);

    if (currentProfileId) {
      return ServicesService.createMembership({
        userId: currentProfileId,
        requestBody: { health_goal: null },
      })
        .then((res) => {
          actions.setMembership(res);
        })
        .catch((err) => {
          Sentry.captureException(err);
        })
        .finally(() => {
          actions.setIsFetchingMembership(false);
        });
    }
  }),

  fetchMembership: thunk(async (actions, __, { getStoreState }) => {
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;
    actions.setIsFetchingMembership(true);
    return ServicesService.getMembership({ userId: currentProfileId as number })
      .then((res) => {
        actions.setMembership(res);
      })
      .catch((err) => {
        Sentry.captureException(err);
      })
      .finally(() => {
        actions.setIsFetchingMembership(false);
      });
  }),

  upgradeMembership: thunk(async (_actions, payload, { getStoreState }) => {
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;
    const { newPlan, recurlyId } = payload;

    return (
      currentProfileId &&
      ServicesService.changeMembership({
        userId: currentProfileId,
        recurlySubscriptionId: recurlyId,
        newPlanCode: newPlan,
      })
        .then(async (res) => {
          await _actions.fetchMembership();
          return res;
        })
        .catch((err) => {
          Sentry.captureException(err);
          return false;
        })
    );
  }),

  cancelMembership: thunk(async (actions, { recurlySubscriptionId }) => {
    await ServicesService.cancelMembership({
      userId: "me",
      recurlySubscriptionId,
    });
    return actions.fetchMembership();
  }),
};
