/** @format */
// Module dependencies
import type { FC } from "react";
import type React from "react";

import { useForm } from "react-final-form";

import type { BaseFieldProps, CustomOptionFieldRenderProps } from "@alphamedical/components";

import { FieldErrorNew, FieldWrapper } from "@alphamedical/components";

// UI Components & Styles
import type { OptionTypeWithBadge } from "./CheckboxOption";

import { CheckboxOption } from "./CheckboxOption";
// Utils
import { parseCheckboxNewFieldValue } from "./utils";

import "./CheckboxSelection.scss";

export type SelectFieldBadgeProps<T = string> = Omit<BaseFieldProps<T>, "children"> & {
  /** Input options plus the BadgePdb component props array. */
  options: OptionTypeWithBadge<T>[];

  children?: (renderProps: CustomOptionFieldRenderProps<T>) => React.ReactNode;
};

export const CheckboxSelection: FC<SelectFieldBadgeProps> = ({
  options,
  className,
  children,
  required,
  reverseLayout,
  clickEvent,
  ...fieldProps
}) => {
  const form = useForm();
  const fieldState = form.getFieldState(fieldProps.name);
  const { touched, submitError, error, dirty } = fieldState || {};
  const errorState = (error || submitError) && (dirty || touched);

  return (
    <div className={`sm:max-w-2xl flex flex-col gap-4 ${className}`}>
      {options.map(({ value, label, badgeProps, disabled }) => (
        <FieldWrapper
          key={`${fieldProps.name}-${value}`}
          required={required}
          {...fieldProps}
          type="checkbox"
          parse={parseCheckboxNewFieldValue}
          value={value}
        >
          {({ input, meta }) => {
            const defaultOption = (
              <CheckboxOption
                disabled={disabled}
                errorState={errorState}
                reverseLayout={reverseLayout}
                input={input}
                meta={meta}
                option={{ value, label, badgeProps }}
                clickEvent={clickEvent}
              />
            );
            if (children) {
              return children({
                input,
                meta,
                option: { value, label },
                defaultOption,
                disabled,
              });
            }
            return defaultOption;
          }}
        </FieldWrapper>
      ))}

      <FieldErrorNew name={fieldProps.name} />
    </div>
  );
};
