/** @format */

import React from "react";
import { generatePath, useHistory } from "react-router-dom";

import { Typography } from "@alphamedical/components";

import { Analytics } from "src/analytics";
import { PageWrapper } from "src/components/PageWrapper";
import { CHECKIN_REDIRECT_PAGE } from "src/routes";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { IconMenuList } from "src/v2/components/Menus/IconMenuList";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { CHECKIN_OUTLINE_KEYS } from "src/v2/models/checkins";
import { useCurrentUser } from "src/v2/models/profile";

const CheckInsListPage = () => {
  useTopBar({ title: "Check-Ins", variant: "nested", showBackButton: true });
  const history = useHistory();
  const currentUser = useCurrentUser();

  const onHCIClick = () => {
    history.push({
      pathname: generatePath(CHECKIN_REDIRECT_PAGE, {
        checkinKey: CHECKIN_OUTLINE_KEYS.COMPREHENSIVE_HEALTH_CHECK_IN,
      }),
      search: "?action=bypass",
      state: { title: "Comprehensive health check-in" },
    });
    Analytics.trackContentInteraction("click", {
      name: "HCIListPage",
      piece: "ComprehensiveHealthCheckin",
      target: "ComprehensiveHealthCheckin",
    });
  };

  const onCIClick = (key: string, pageTitle: string, analyticsKey: string) => {
    const searchParams = new URLSearchParams();
    searchParams.set("action", "retake");
    searchParams.set("show_results", "true");
    history.push({
      pathname: generatePath(CHECKIN_REDIRECT_PAGE, {
        checkinKey: key,
      }),
      search: searchParams.toString(),
      state: { title: pageTitle },
    });
    Analytics.trackContentInteraction("click", {
      name: "HCIListPage",
      piece: analyticsKey || "null",
      target: analyticsKey,
    });
  };

  const patientTools = [
    {
      onClick: onHCIClick,
      label: "Comprehensive Health Check-In",
    },
    {
      onClick: () =>
        onCIClick(CHECKIN_OUTLINE_KEYS.ANXIETY_CHECK_IN, "Anxiety check-in", "AnxietyCheckin"),
      label: "Anxiety Check-In",
    },
  ];

  // Only show Reproductive Health Check-In to patients who are assigned
  // female at birth or if we have not collected that information yet
  if (!currentUser?.pii?.sex || currentUser?.pii?.sex == "female") {
    patientTools.push({
      onClick: () =>
        onCIClick(
          CHECKIN_OUTLINE_KEYS.REPRODUCTIVE_HEALTH_CHECK_IN,
          "Reproductive health check-in",
          "ReproductiveHealthCheckin",
        ),
      label: "Reproductive Health Check-In",
    });
  }

  return (
    <PageWrapper cxPadding="px-0 py-0" cxMaxWidth="w-screen sm:max-w-xl">
      <div className="flex justify-center bg-sand-40 py-4">
        <img alt="Health Checkins Icon" src="/assets/health-checkins-icon.svg" />
      </div>
      <div className="px-6">
        <Typography variant="body1" className="py-6">
          Explore all Alpha check-ins that you can complete for free. When you complete check-ins,
          we update your personalized recommendations in your health action plan.
        </Typography>
        <div className="border-t border-grey-60 pt-6">
          <IconMenuList tools={patientTools} />
        </div>
      </div>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(CheckInsListPage);
