/** @format */

import React from "react";
import { useFormState } from "react-final-form";

interface SubmitButtonProps {
  label?: string;
  submittingLabel?: string;
  buttonColor?: string;
}

export const SubmitButton = ({
  label = "Save",
  submittingLabel = "Saving...",
  buttonColor = "violator",
  ...props
}: SubmitButtonProps) => {
  const { submitting, submitSucceeded, dirtySinceLastSubmit, submitError, error } = useFormState();
  const errorToDisplay = submitError || error;
  return (
    <>
      <button
        type="submit"
        className={`btn btn-${buttonColor} w-full`}
        disabled={submitting || (submitSucceeded && !dirtySinceLastSubmit)}
      >
        {submitting ? submittingLabel : label}
      </button>
      {!!errorToDisplay && <span className="text-red">{errorToDisplay}</span>}
    </>
  );
};
