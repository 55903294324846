/** @format */

import React from "react";

import type { Action, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";

import type {
  DynamicField,
  DynamicFormPage,
  RawDynamicOutline,
  RawDynamicPageData,
} from "src/components/DynamicForm/types";
import type { CreateModelDispatch } from "src/v2/models/_create";

import { isOptionField, isTextField } from "src/components/DynamicForm/types";
import { _GET } from "src/helpers/http";
import { history } from "src/utils/history";
export interface OutlineReferences {
  pages?: { [key: string]: RawDynamicPageData };
  fields?: { [key: string]: DynamicField };
}

export const OutlineContext = React.createContext<Outline | undefined>(undefined);

export class Outline {
  key: string;
  condition: string;
  stages: { key: string; title: string }[];
  pages: DynamicFormPage[];
  fields: { [key: string]: DynamicField };

  constructor(outlineData: RawDynamicOutline, outlineReferences?: OutlineReferences) {
    this.key = outlineData.key;
    this.condition = this.key;

    // Adds the key of each page's parent stage to the page data itself.
    outlineData.stages.forEach((stage) => {
      // Modify each page object by adding the stage property and pulling the page data from a common reference file
      // if the page data is just a string.
      stage.pages = stage.pages.map((page) => {
        if (typeof page === "string" && !!outlineReferences && !!outlineReferences.pages) {
          return {
            stage: stage.key,
            ...outlineReferences.pages[page],
          };
        }
        return {
          stage: stage.key,
          ...(page as DynamicFormPage),
        };
      });
    });

    this.stages = outlineData.stages.map((stage) => ({ key: stage.key, title: stage.title }));

    // Flattens the array of arrays found in the raw outline to a single array of page objects
    this.pages = outlineData.stages
      .map((stage) => stage.pages as DynamicFormPage[])
      .reduce((pagesArray, stagePages) => pagesArray.concat(stagePages), []);

    this.pages.forEach((page) => {
      page.fields = page.fields
        .map((field) => {
          if (typeof field === "string" && !!outlineReferences && !!outlineReferences.fields) {
            return { ...outlineReferences.fields[field] };
          }
          return field;
        })
        .map(this.formatField);
    });

    this.fields = this.pages
      .map((page) => page.fields)
      .reduce((acc: any, val: any) => acc.concat(val), []) // flatten
      .reduce((_fields: any, field: any, idx: number) => {
        return {
          ..._fields,
          [field.key]: {
            ...field,
            position: idx + 1,
          },
        };
      }, {});
  }

  /** Apply some post-processing on the field data before it is used by the application. */
  formatField(field: DynamicField): DynamicField {
    if (isTextField(field)) {
      /** If a text field is using a deprecated subtype add the parse/validate properties that achieve the same result */
      if (field.subtype === "bloodpressure") {
        field.parse = "parseBloodPressure";
        field.validate = "isValidBloodPressureFormat";
      } else if (field.subtype === "rx_bin") {
        field.validate = "isValidRXBin";
      }
    } else if (isOptionField(field)) {
      if (field.type === "radio" && field.subtype === "images" && !field.optionComponent) {
        field.optionComponent = "ProductOption";
      } else if (field.type === "select" && field.subtype === "images" && !field.optionComponent) {
        field.optionComponent = "OptionWithImage";
      }
    }
    return field;
  }
}

export interface OutlinesModel {
  currentOutline: Outline | undefined;
  outlines: { [fileKey: string]: Outline };
  fetchingOutlines: {
    [fileKey: string]: boolean;
  };

  setFetchingOutlines: Action<OutlinesModel, { fileKey: string; fetching: boolean }>;

  setOutline: Action<
    OutlinesModel,
    { key: string; outline: RawDynamicOutline; commonData?: OutlineReferences }
  >;

  getStages: Thunk<OutlinesModel, string>;

  fetchOutline: Thunk<OutlinesModel, { key: string; override?: string | null }>;
}

export const outlineStore: OutlinesModel = {
  currentOutline: undefined,
  outlines: {},
  fetchingOutlines: {},

  setFetchingOutlines: action((state, payload) => {
    state.fetchingOutlines[payload.fileKey] = payload.fetching;
  }),

  setOutline: action((state, payload) => {
    const outline = new Outline(payload.outline, payload.commonData);
    state.outlines[payload.key] = outline;
    state.currentOutline = outline;
    state.fetchingOutlines[payload.key] = false;
  }),

  getStages: thunk(async (actions, fileKey, { getState }) => getState().outlines[fileKey].stages),

  fetchOutline: thunk(async (actions, outlineData, { getStoreState, getState, dispatch }) => {
    const _dispatch = dispatch as CreateModelDispatch;
    // let store: CreateModel = getStoreState() as CreateModel;
    const state = getState();

    if (!state.fetchingOutlines[outlineData.key]) {
      actions.setFetchingOutlines({ fileKey: outlineData.key, fetching: true });

      const fetchCommonOutline = () => {
        return _GET(`/outlines/common`);
      };
      try {
        let fetchOutlineUrl = `/outlines/outline-${outlineData.key}`;
        if (outlineData.override) {
          fetchOutlineUrl += `?override=${outlineData.override}`;
        }
        const [outline, commonData] = await Promise.all([
          _GET(fetchOutlineUrl),
          fetchCommonOutline(),
        ]);
        actions.setOutline({ key: outlineData.key, outline, commonData });
        return outline;
      } catch (e) {
        // tslint:disable-next-line
        console.log(e);
        _dispatch.snacks.addSnack({
          type: "error",
          id: "create_consult",
          message:
            "Unable to begin visit. Please try again. If this problem persists, contact our support team.",
          delay: 10,
        });
        history && history.push("/your-care/explore-visits");
      }
    }
  }),
};
