/** @format */

import type React from "react";

interface MembershipCardItemProps {
  iconName: string;
  iconAlt: string;
}

export const MembershipCardItem: React.FC<MembershipCardItemProps> = ({
  iconName,
  iconAlt,
  children,
}) => {
  return (
    <div className={"flex mt-6"}>
      <img className={"mr-4"} src={`/assets/${iconName}.svg`} alt={iconAlt} />
      <p className={"text-forest-darker text-sm"}>{children}</p>
    </div>
  );
};
