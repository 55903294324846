/** @format */

import React from "react";

import type { IconName } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { IconPrefix } from "@fortawesome/sharp-solid-svg-icons";
import * as Sentry from "@sentry/react";

import type { MembershipDetailsSchema } from "src/api";

import { OrganizationsService } from "src/api";
import { WelcomePageContainerHealthCheckIn } from "src/components/DynamicForm/Fields/CustomFields/WelcomeFlow";
import { isAxiosError } from "src/helpers/axios";
import { useCurrentUser } from "src/v2/models/profile";

interface B2BMembershipDetailsProps {
  questionKey: string;
}

export const B2BMembershipDetails = ({ questionKey }: B2BMembershipDetailsProps) => {
  const [membershipDetails, setMembershipDetails] = React.useState<MembershipDetailsSchema | null>(
    null,
  );

  const user = useCurrentUser();

  React.useEffect(() => {
    OrganizationsService.getMembershipDetails({ userId: user.id })
      .then((res) => {
        setMembershipDetails(res);
      })
      .catch((err) => {
        if (isAxiosError(err)) {
          Sentry.captureException(err);
        }
      });
  }, []);

  return (
    <WelcomePageContainerHealthCheckIn submitButtonTitle={"Next"} questionKey={questionKey}>
      <div>
        <h1 style={{ fontWeight: 500 }} className={"text-xl text-forest-120 mb-10"}>
          Here's what you can access with your {membershipDetails && membershipDetails.org_name}{" "}
          benefits:
        </h1>
        {membershipDetails &&
          membershipDetails.points.map((point) => {
            const keyLength = point.text.length > 10 ? 10 : point.text.length;
            return (
              <div
                className={"flex items-center mb-6"}
                key={point.text.replace(" ", "").slice(keyLength)}
              >
                <FontAwesomeIcon
                  style={{ width: "24px", height: "24px" }}
                  className={"mr-5"}
                  rotate={point.icon.rotation}
                  icon={[point.icon.group as IconPrefix, point.icon.name as IconName]}
                />
                <p className={"text-forest-120"}>{point.text}</p>
              </div>
            );
          })}
      </div>
    </WelcomePageContainerHealthCheckIn>
  );
};
