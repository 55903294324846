/** @format */

import React from "react";
import { useHistory } from "react-router-dom";

import { Typography } from "@alphamedical/components";

import { ContentfulService } from "src/api";
import { Loader } from "src/components/Loader";
import {
  primaryMembershipCardStyles,
  updatePricingOnCardContent,
} from "src/routes/Membership/MembershipOptions";
import { useGetMembership } from "src/utils/hooks";
import { useMembershipPrice } from "src/utils/hooks/useMembershipPrice";
import {
  type PlanComparisonContent,
  PlanComparisonPage,
} from "src/v2/components/PlanComparison/PlanComparisonPage";
import { MEMBERSHIP_PLANS } from "src/v2/constants";

export const WeightLossJournalPaywall = () => {
  const history = useHistory();
  const { membership } = useGetMembership();

  const [isLoading, setIsLoading] = React.useState(true);
  const [pageContent, setPageContent] = React.useState<PlanComparisonContent>();
  const prices = useMembershipPrice();

  const hasPreviouslyCompletedMembershipSignup =
    membership?.plan_code == MEMBERSHIP_PLANS.alpha ||
    (membership?.state && ["CANCELED", "EXPIRED"].includes(membership.state));

  const handleOnClick = async (membershipType: "alpha" | "plus") => {
    // If a user has completed membership signup they must go through
    // the upgrade process in the acocunt membership details page
    // The membership signup flow is not repeatable so we redirect them
    // to the account page in that case.
    if (hasPreviouslyCompletedMembershipSignup) {
      history.push("/account/membership_and_payment/membership/change");
    } else {
      history.push(`/membership-signup?flow=account&plan=${membershipType}`);
    }
  };

  React.useEffect(() => {
    const fetchContentAsync = async () => {
      const { content } = await ContentfulService.getUnstructuredContentByKey({
        key: "plan-comparison-page",
      });
      if (content) {
        setPageContent(content);
        setIsLoading(false);
      } else history.push("/my-cases");
    };
    fetchContentAsync();
  }, []);

  const plusCardContent = pageContent?.plansSection["d2c"]["plans"].find(
    (plan: any) => plan.id == "plus",
  );

  if (plusCardContent) {
    plusCardContent["badge"] = "";
  }
  if (pageContent) {
    pageContent.plansSection["d2c"]["title"] = [];
  }

  const variantProps = {
    styles: {
      plus: primaryMembershipCardStyles,
    },
  };

  const cardsContent = pageContent?.plansSection["d2c"];
  if (cardsContent && prices) {
    updatePricingOnCardContent(cardsContent, prices);
  }

  return (
    <div className="p-6">
      <Typography className="pb-4" variant="h2">
        Unlock the Weight Loss Journal
      </Typography>
      <Typography>
        This feature is only available with Alpha Plus+ Plan. Join now to access the Weight Loss
        Journal and all Alpha Plus+ Plan benefits.
      </Typography>
      {isLoading || !pageContent ? (
        <Loader show={true} />
      ) : (
        <div className="mt-6">
          <PlanComparisonPage
            plansOffered={["plus"]}
            handleOnClick={handleOnClick}
            pageContent={pageContent}
            cardsContent={cardsContent}
            variantProps={variantProps}
          />
        </div>
      )}
    </div>
  );
};
