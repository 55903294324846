/** @format */

import React from "react";

import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import { Typography } from "@alphamedical/components";

import type { TreatmentDetails as TreatmentDetailsI } from "./noticePage.types";

export const TreatmentDetails = ({ title, talkingPoints }: TreatmentDetailsI) => {
  return (
    <>
      <Typography variant="h2">{title}</Typography>
      {talkingPoints.map((item: any, idx: number) => {
        return (
          <div key={idx} className={classNames("py-6", idx !== 0 && "border-t border-grey-60")}>
            <span className={"flex flex-row items-center pb-2"}>
              <FontAwesomeIcon
                icon={item.icon as IconProp}
                size="lg"
                className="mr-4"
                title={item.alt}
              />
              <Typography variant="h4">{item.title}</Typography>
            </span>
            <Typography variant="body2">{item.description}</Typography>
          </div>
        );
      })}
    </>
  );
};
