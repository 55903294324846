/** @format */

// Module dependencies
import type { FC } from "react";

import React from "react";

import type { OptionType, SelectFieldProps } from "@alphamedical/components";

import { FieldError, FieldWrapper, useFieldName } from "@alphamedical/components";

// UI Components
import { RadioFieldOption } from "./RadioFieldOption";

// Utils
type ExtendedOptionType<T> = OptionType<T> & { image_file: any };

export const RadioFieldV2: FC<SelectFieldProps> = ({
  options,
  className,
  children,
  disabled,
  ...fieldProps
}) => {
  const fieldName = useFieldName(fieldProps.name);

  return (
    <div className={`${className} flex flex-col gap-4`}>
      {options.map((option) => {
        const { value, label, image_file } = option as ExtendedOptionType<string>;

        return (
          <FieldWrapper
            key={`${fieldProps.name}-${value}`}
            {...fieldProps}
            type="radio"
            value={value}
          >
            {({ input, meta }) => {
              const defaultOption = (
                <RadioFieldOption
                  disabled={disabled}
                  input={input}
                  meta={meta}
                  option={{ value, label }}
                  image={image_file}
                />
              );
              if (children) {
                return children({
                  input,
                  meta,
                  option: { value, label },
                  defaultOption,
                  disabled,
                });
              }
              return defaultOption;
            }}
          </FieldWrapper>
        );
      })}
      <FieldError name={fieldName} />
    </div>
  );
};
