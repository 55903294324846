/** @format */
import * as Sentry from "@sentry/react";
import type { Action, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";
import _ from "lodash";

import type { ConsultAnswerSchema, LegacyConsultSchema, ServiceSchema } from "src/api";
import type { DynamicField } from "src/components/DynamicForm/types";
import type { Consultation } from "src/v2/types/consultation";

import { Analytics } from "src/analytics";
import { ConsultsService, ServicesService, UsersService } from "src/api";
import { getPiiSpecificKey } from "src/components/DynamicForm/Processors/fieldConditionals";
import { isOptionField } from "src/components/DynamicForm/types";
import { _GET, _POST, getTokenHeaderV2 } from "src/helpers/http";
import { history } from "src/utils/history";
import {
  CONDITIONS_MIGRATED_FROM_VISIT_TO_CONSULT,
  CONDITIONS_THAT_RESET_AFTER_X_DAYS,
  CONSULT_STATUSES,
  NON_MH_REPEATABLE_CONDITIONS,
} from "src/v2/constants";

import type { CreateModel, CreateModelDispatch } from "./_create";
import type { FetchProfileResponse } from "./api_types";

interface FormValues {
  [key: string]: any;
}

interface SubmitAnswersOptions {
  consultId: number;
  fields: { [key: string]: DynamicField };
  skippedValidators?: string[];
  values: FormValues;
}

const skipPreviousFileAnswer = (answer: any, field: any) => {
  return (
    field.type === "file" &&
    answer &&
    (answer.indexOf("http://") > -1 || answer.indexOf("https://") > -1)
  );
};

export interface ConsultsModel {
  visits: Consultation[];
  consultsByCondition: LegacyConsultSchema[];
  fetchingConsults: boolean;
  currentConsultation?: Consultation;
  consultFee?: number;
  credit?: number;
  service?: ServiceSchema | null;
  showUserRedirectMessage: boolean;
  healthHistory?: ConsultAnswerSchema[];

  setShowUserRedirectMessage: Action<this, boolean>;

  setCurrentService: Action<ConsultsModel, ServiceSchema>;

  setCurrentConsultation: Action<ConsultsModel, Consultation>;

  setConsultFee: Action<ConsultsModel, { consult_fee: number }>;

  setCredit: Action<ConsultsModel, { credit_balance: number }>;

  clearCurrentConsultation: Action<ConsultsModel>;

  setFetchingConsults: Action<ConsultsModel>;

  setVisits: Action<ConsultsModel, Consultation[]>;

  setConsultsByCondition: Action<ConsultsModel, LegacyConsultSchema[]>;

  fetchAllConsults: Thunk<ConsultsModel>;

  fetchConsultsByCondition: Thunk<ConsultsModel, string>;

  fetchConsultFee: Thunk<ConsultsModel, number>;

  fetchCredit: Thunk<ConsultsModel>;

  fetchOrCreateConsult: Thunk<ConsultsModel, { conditionKey: string; type: string }>;

  getFormAnswers: Thunk<ConsultsModel, undefined, any, CreateModel>;

  getFormPreviousAnswers: Thunk<ConsultsModel, undefined, any, CreateModel>;

  submitAnswers: Thunk<ConsultsModel, SubmitAnswersOptions>;

  submitConsult: Thunk<
    ConsultsModel,
    { couponCode: string | undefined; consultId: number; condition: string }
  >;

  setHealthHistory: Action<ConsultsModel, ConsultAnswerSchema[]>;

  fetchHealthHistory: Thunk<ConsultsModel>;
}

export const consultStore: ConsultsModel = {
  visits: [],
  consultsByCondition: [],
  fetchingConsults: false,
  service: null,
  showUserRedirectMessage: false,
  healthHistory: [],

  setShowUserRedirectMessage: action((state, value) => {
    state.showUserRedirectMessage = value;
  }),

  setCurrentService: action((state, service) => {
    state.service = service;
  }),

  setCurrentConsultation: action((state, consult) => {
    state.currentConsultation = consult;
  }),

  setConsultFee: action((state, fee) => {
    state.consultFee = fee.consult_fee;
  }),

  setCredit: action((state, credit) => {
    state.credit = credit.credit_balance;
  }),

  clearCurrentConsultation: action((state) => {
    state.currentConsultation = undefined;
  }),

  // Can be removed
  setFetchingConsults: action((state) => {
    state.fetchingConsults = true;
  }),

  setVisits: action((state, visits) => {
    state.visits = visits;
    state.fetchingConsults = false;
  }),

  setConsultsByCondition: action((state, consultsByCondition) => {
    state.consultsByCondition = consultsByCondition;
    state.fetchingConsults = false;
  }),

  fetchAllConsults: thunk(async (actions, __, { getState, getStoreState }) => {
    const state = getState();
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;
    if (!state.fetchingConsults) {
      actions.setFetchingConsults();
      try {
        const visits = await _GET(`/v2/users/${currentProfileId}/consults`, {}, getTokenHeaderV2());
        actions.setVisits(visits);
      } catch (e) {
        // tslint:disable-next-line
        console.log(e);
      }
    }
  }),

  fetchConsultsByCondition: thunk(async (actions, condition, { getState, getStoreState }) => {
    const state = getState();
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;
    if (!state.fetchingConsults) {
      actions.setFetchingConsults();
      if (currentProfileId) {
        ConsultsService.getConsultsForUser({
          userId: currentProfileId,
          conditions: condition,
          omitStatus: ["WITHDRAWN"],
        })
          .then((res) => {
            actions.setConsultsByCondition(res);
          })
          .catch((err) => {
            Sentry.captureException(err);
          });
      }
    }
  }),

  fetchCredit: thunk(async (actions) => {
    try {
      const creditInfo = await UsersService.userCreditInfo();
      actions.setCredit(creditInfo);
    } catch (e) {
      Sentry.captureException(e);
    }
  }),

  fetchConsultFee: thunk(async (actions, consultId, { getStoreState }) => {
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;
    try {
      const consultFee = await _GET(
        `/v2/users/${currentProfileId}/consults/${consultId}/consult-fee`,
        {},
        getTokenHeaderV2(),
      );
      actions.setConsultFee(consultFee);
    } catch (e) {
      Sentry.captureException(e);
    }
  }),

  fetchOrCreateConsult: thunk(async (actions, options, { getStoreState, dispatch }) => {
    const _dispatch = dispatch as CreateModelDispatch;
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;

    const { started, needsFollowup, completed } = CONSULT_STATUSES;
    const openStatuses = [started, needsFollowup, completed];
    const identifyOpenConsult = (consult: Consultation) => {
      const isOpen = openStatuses.includes(consult.status);
      const repeatableConditions = NON_MH_REPEATABLE_CONDITIONS;

      if (options.type === "INITIAL_CONSULT") {
        if (repeatableConditions.includes(options.conditionKey)) {
          return isOpen;
        }
        // by treating any non-repeatable condition (that is, one with a return-consult outline) as open, we allow the component to assess whether to redirect to a return consult
        // See src/v2/routes/Intake/index.tsx:136 for the redirect logic
        return true;
      }

      return isOpen;
    };

    const findLatestOpenConsult = (consults: Consultation[]) => {
      const openConsults = consults.filter((consult) => identifyOpenConsult(consult));

      // find the most recent result
      let result = _.maxBy(openConsults, (consult) => new Date(consult.created_at).getTime());
      if (
        result &&
        ["COMPLETED", "APPROVED"].includes(result.status) &&
        lastVisitTooLongAgoForReturnConsult(consults, options.conditionKey)
      ) {
        result = undefined;
      }
      return result;
    };

    const openLegacyVisitHandling = (openConsult: Consultation | undefined) => {
      if (
        openConsult?.consult_type === "VISIT" &&
        CONDITIONS_MIGRATED_FROM_VISIT_TO_CONSULT.includes(options.conditionKey)
      ) {
        // if a visit and a migrated condition. Create new consult and set the current consult to withdrawn.
        actions.setShowUserRedirectMessage(true);
        ConsultsService.updateConsult({
          userId: currentProfileId as number,
          consultId: openConsult.id.toString(),
          requestBody: { status: "WITHDRAWN" },
        });
      }
    };

    // get the users service to see if they need to complete an annual checkup
    const service = await ServicesService.getServiceForUser({
      userId: "me",
      serviceType: options.conditionKey,
    })
      .then((service) => {
        actions.setCurrentService(service);
        return service;
      })
      .catch((err) => {
        // if the server endpoint throws an error because the service hasn't been created yet, then just continue on.
        return {
          needs_annual: false,
        };
      });

    // if the user doesn't need an annual consult.
    if (!service.needs_annual || options.type === "FOLLOWUP") {
      // gets users consults to check for started one.
      return await _GET(
        `/v2/users/${currentProfileId}/consults?omit_status=WITHDRAWN&omit_status=DENIED`,
        {},
        getTokenHeaderV2(),
      )
        .then(async (consults: Consultation[]) => {
          const existingConsultsForServiceAndType = consults.filter((consult) => {
            return (
              [options.type, "FOLLOWUP"].includes(consult.consult_type) &&
              consult.subscription.condition.key === options.conditionKey
            );
          });

          // check if the user has a consult that was started
          const openConsult = findLatestOpenConsult(existingConsultsForServiceAndType);
          openLegacyVisitHandling(openConsult);

          // has started consult. Return the started consult
          if (
            openConsult &&
            (!CONDITIONS_MIGRATED_FROM_VISIT_TO_CONSULT.includes(options.conditionKey) ||
              openConsult.consult_type != "VISIT")
          ) {
            return openConsult;
          }

          // redirect to return consult if the user has a completed consult and the condition has return consults
          // unless the last visit was too long ago for a return consult
          if (
            options.type === "INITIAL_CONSULT" &&
            existingConsultsForServiceAndType.length > 0 &&
            NON_MH_REPEATABLE_CONDITIONS.indexOf(options.conditionKey) === -1 &&
            !lastVisitTooLongAgoForReturnConsult(consults, options.conditionKey)
          ) {
            window.location.href = `/return-consult/${options.conditionKey}-checkup/`;
            return;
          }

          let consultType = options.type;
          if (
            options.type === "VISIT" &&
            CONDITIONS_MIGRATED_FROM_VISIT_TO_CONSULT.includes(options.conditionKey)
          ) {
            consultType = "INITIAL_CONSULT";
            existingConsultsForServiceAndType.forEach((consult) => {
              if (consult.consult_type === "INITIAL_CONSULT") {
                consultType = "RETURN_CONSULT";
              }
            });
          }

          // create new consult

          // if consultType is "RETURN_CONSULT" and the last visit was too long ago for a return consult, redirect to an initial consult"
          if (
            consultType === "RETURN_CONSULT" &&
            lastVisitTooLongAgoForReturnConsult(consults, options.conditionKey)
          ) {
            window.location.href = `/consultation/${options.conditionKey}/`;
            return;
          }

          const payload = {
            condition_key: options.conditionKey,
            is_followup: ["FOLLOWUP", "RETURN_CONSULT"].indexOf(options.type) !== -1,
            consult_type: consultType,
          };
          return _POST(`/v2/users/${currentProfileId}/consults`, payload, getTokenHeaderV2()).catch(
            (error) => {
              if (error.response.status === 403 && error.response.data.errors.redirect_route) {
                history && history.push(error.response.data.errors.redirect_route);
              } else {
                _dispatch.snacks.addSnack({
                  type: "error",
                  id: "create_consult",
                  message:
                    "Unable to begin visit. Please try again. If this problem persists, contact our support team.",
                  delay: 10,
                });
                history && history.push("/your-care/explore-visits");
              }
            },
          );
        })
        .then((consult: Consultation) => {
          actions.setCurrentConsultation(consult);
          return consult;
        });
    } else {
      return {};
    }
  }),

  getFormAnswers: thunk(async (actions, __, { getStoreState, getState }) => {
    const storeState = getStoreState();
    const state = getState();
    const { currentConsultation } = state;
    const profile = storeState.profile.profile;
    const { pii, insurance } = profile || ({} as FetchProfileResponse);
    const addressFields = ["state", "zipcode", "address", "address2", "city"];

    if (!currentConsultation) {
      return {};
    }

    const { answers, files } = currentConsultation;
    const formAnswers = Object.keys(answers).reduce<Record<string, any>>((_answers, answerKey) => {
      const answer = answers[answerKey];

      if (answer.category === "consult") {
        if (answer.type === "file") {
          const fileData = files.find((f: any) => f.name === answerKey);
          _answers[answerKey] = fileData && fileData.file_url;
        } else {
          _answers[answerKey] = answer.value;
        }
      }

      if (answer.category === "pii" && pii) {
        const piiSpecificKey = getPiiSpecificKey(answerKey);
        _answers[answerKey] =
          addressFields.indexOf(answerKey) !== -1
            ? pii.address[piiSpecificKey]
            : pii[piiSpecificKey];
      }

      if (answer.category === "subscription") {
        const subscriptionAnswer = currentConsultation.subscription[answerKey];
        if (answerKey === "consent_confirmed") {
          _answers[answerKey] = subscriptionAnswer ? "yes" : "no";
        } else if (answerKey === "pharmacy_info" || answerKey === "refill_frequency") {
          _answers[answerKey] = subscriptionAnswer || null;
        } else if (answerKey.startsWith("product")) {
          _answers[answerKey] = _.find(currentConsultation.subscription.additional_products, (p) =>
            answerKey.includes(p.sku_key),
          );
        } else {
          _answers[answerKey] = (subscriptionAnswer && subscriptionAnswer.toLowerCase()) || null;
        }
      }

      if (answer.category === "emergency_contact") {
        _answers[answerKey] = answer.value;
      }

      return _answers;
    }, {});

    // fetch license_photo from pii if answerList.license_photo doesn't exist
    const licensePhotoKey = "license_photo";
    if (pii && pii[licensePhotoKey] && !formAnswers[licensePhotoKey]) {
      formAnswers[licensePhotoKey] = pii[licensePhotoKey];
    }

    if (insurance) {
      _.forEach(insurance, (insuranceValue, insuranceKey) => {
        if (insuranceKey !== "id" && insuranceValue !== null) {
          formAnswers[insuranceKey] = insuranceValue;
        }
      });
    }
    return formAnswers;
  }),

  getFormPreviousAnswers: thunk(async (_actions, __, { getState }) => {
    const state = getState();
    const { currentConsultation } = state;

    if (!currentConsultation) {
      return {};
    }

    const { prev_answers } = currentConsultation;

    return prev_answers;
  }),

  submitAnswers: thunk(async (actions, options, { getState, getStoreState }) => {
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;
    const processedFields = _.chain(options.values)
      .pickBy((answer, answerKey) => {
        const field = options.fields[answerKey];
        return answer !== undefined && field && !skipPreviousFileAnswer(answer, field);
      })
      .mapValues((answer, answerKey) => {
        const field = options.fields[answerKey];
        return {
          category: field.category || "consult",
          name: answerKey,
          type: field.type,
          value: answer,
          position: field.position,
          question_text: field.title || field.subtitle,
          options: (isOptionField(field) && field.options) || undefined,
          tags: field.tags,
        };
      })
      .value();
    return ConsultsService.updateAnswers({
      userId: currentProfileId || "me",
      consultId: options.consultId,
      skipValidators: options.skippedValidators,
      requestBody: processedFields as any,
    }).then((consult: Consultation) => {
      // Preserve stored previous answers
      const { prev_answers } = getState()?.currentConsultation || {};
      const updatedConsult = { ...consult, prev_answers: prev_answers };

      actions.setCurrentConsultation(updatedConsult);
      return updatedConsult;
    });
  }),

  submitConsult: thunk(async (actions, options): Promise<any> => {
    const { condition, consultId, couponCode } = options;

    return ConsultsService.submitConsult({
      userId: "me",
      consultId: consultId.toString(),
      requestBody: {
        coupon_code: couponCode,
      },
    })
      .then((response) => {
        actions.fetchAllConsults();
        Analytics.trackEvent({
          category: "Visit",
          action: "Submit",
          name: condition,
        });
        // clear coupon_code once used
        if (couponCode) {
          localStorage.removeItem(`${condition}.coupon_code`);
        }
        return response;
      })
      .catch((err) => {
        const responseError = _.get(err, "response.data.type");

        if (responseError === "RecurlyValidationError") {
          throw "payment";
        }

        throw err;
      });
  }),

  setHealthHistory: action((state, healthHistory) => {
    state.healthHistory = healthHistory;
  }),

  fetchHealthHistory: thunk(async (actions, __, { getStoreState }) => {
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;
    if (!currentProfileId) {
      return;
    }
    try {
      const healthHistory = await ConsultsService.getHealthHistory({
        userId: currentProfileId.toString(),
      });
      actions.setHealthHistory(healthHistory);
    } catch (e) {
      Sentry.captureException(e);
    }
  }),
};

// HELPERS
/**
 * Checks if the last condition visit was more than ninety days ago.
 * @param consults - An array of Consultation objects or LegacyConsultSchema objects.
 * @param conditionKey - The key of the condition to check.
 * @returns A boolean indicating whether the last condition visit was more than ninety days ago.
 */
export const lastConditionVisitWasMoreThanXDaysAgo = (
  consults: Consultation[] | LegacyConsultSchema[] | any[],
  conditionKey: string,
  days: number,
) => {
  const DAYS_IN_MS = 1000 * 60 * 60 * 24 * days;

  const CompletedConsults = consults.filter((consult) => {
    return (
      consult.subscription.condition.key === conditionKey &&
      ["COMPLETED", "APPROVED"].includes(consult.status)
    );
  });
  const lastCompletedConsult = _.maxBy(CompletedConsults, (consult) => {
    return consult.completed_at ? new Date(consult.completed_at).getTime() : 0;
  });
  if (lastCompletedConsult && lastCompletedConsult.completed_at) {
    const lastCompletedConsultCompletionDate = new Date(lastCompletedConsult.completed_at);
    const now = new Date();
    const xDaysAgo = new Date(now.getTime() - DAYS_IN_MS);
    return lastCompletedConsultCompletionDate < xDaysAgo;
  } else {
    return false;
  }
};

/**
 * Checks if the last visit for a specific condition is too long ago for a return consultation.
 * @param consults - An array of Consultation objects or LegacyConsultSchema objects.
 * @param conditionKey - The key of the condition to check.
 * @returns A boolean indicating whether the last visit was too long ago for a return consultation.
 */
export const lastVisitTooLongAgoForReturnConsult = (
  consults: Consultation[] | LegacyConsultSchema[],
  conditionKey: string,
) => {
  const days = CONDITIONS_THAT_RESET_AFTER_X_DAYS[conditionKey];
  return days && lastConditionVisitWasMoreThanXDaysAgo(consults, conditionKey, days);
};
