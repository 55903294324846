/** @format */

import type { Action, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";

import { _POST, getTokenHeaderV2 } from "src/helpers/http";

import type { CreateModel } from "./_create";

interface ConsentSigning {
  key: string;
  signedAt: string;
  signedVersion: string;
  isLatest: boolean;
  latestVersion: string;
}

interface ConsentForm {
  key: string;
  version: string;
}

interface ConsentMap {
  [key: string]: ConsentSigning;
}

export interface ConsentModel {
  signedConsentForms: ConsentMap;

  setSignedConsentForms: Action<ConsentModel, ConsentSigning[]>;

  signConsentForms: Thunk<ConsentModel, ConsentForm[]>;
}

export const consentStore: ConsentModel = {
  signedConsentForms: {},

  setSignedConsentForms: action((state, consentSignings) => {
    consentSignings.forEach((consentSigning) => {
      state.signedConsentForms[consentSigning.key] = consentSigning;
    });
  }),

  signConsentForms: thunk(async (actions, consentForms, { getStoreState }) => {
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;
    await _POST(
      `/v2/users/${currentProfileId}/sign_consents`,
      { consent_forms: consentForms },
      getTokenHeaderV2(),
    );
  }),
};
