/** @format */

import type { ActionMeta } from "react-select";

import React from "react";
import Select, { components } from "react-select";

import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import type { BaseFieldProps } from "@alphamedical/components";

import { FieldErrorNew, FieldWrapper } from "@alphamedical/components";
import { NONE_KEYWORD } from "src/v2/designSystem/CheckboxSelection/utils";

export type DropdownOption = {
  value: string;
  label: string;
};

export interface DropdownSelectProps extends Omit<BaseFieldProps, "allowNull" | "reverseLayout"> {
  /**
   * Dropdown options to populate the select menu.
   */
  options: DropdownOption[] | undefined;

  /**
   * Label for the dropdown.
   */
  label?: string;

  /**
   * Whether the dropdown should have a clearable option.
   */
  clearable?: boolean;

  /**
   * Whether the dropdown should be searchable.
   */
  searchable?: boolean;

  /**
   * Helper text to display below the dropdown.
   */
  helperText?: string;

  /**
   * Determine if select is multi select
   */
  isMulti?: boolean;

  /**
   * Text to display when the search returns no results.
   */
  noOptionsMessage?: string;
}

const CaretDownIcon = () => {
  return <FontAwesomeIcon icon={faCaretDown} className="text-grey-160" />;
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};

/**
 * The current implementation of the dropdown, utilizing *react-select*,
 * aims to provide the essential functionality required by Product/UX teams.
 * It should be expanded as needed.
 *  */
export const DropdownSelect = ({
  options,
  label,
  placeholder,
  disabled = false,
  clearable = false,
  searchable = false,
  isMulti = false,
  className,
  helperText,
  noOptionsMessage,
  ...props
}: DropdownSelectProps) => {
  const [hasError, setHasError] = React.useState(false);

  /**
   * Custom styles for the react-select dropdown.
   */
  const customDropdownStyles = {
    control: (state: any) =>
      classNames(
        "bg-white w-full rounded border-2 py-3 px-4 leading-6",
        hasError && "border-red",
        state.isFocused ? "border-forest-100" : "border-grey-100",
      ),
    option: (state: any) =>
      classNames(
        "font-normal text-forest-100 py-3 px-4",
        state.isSelected && "bg-sand-60",
        state.isFocused && "bg-forest-8",
        state.isDisabled && "text-red",
      ),
    valueContainer: () => "font-normal text-forest-120 pr-6",
    menuList: () => "bg-white rounded shadow-lg",
    placeholder: () => "text-grey-160",
    clearIndicator: () => "text-grey-160 pr-2",
    multiValue: () => "bg-grey-40 rounded-sm mr-1 mb-1",
    multiValueLabel: () => "p-1",
    multiValueRemove: () => "hover:bg-red-light hover:text-red-darker p-1 rounded-sm",
    noOptionsMessage: () =>
      "text-sm font-normal text-grey-160 leading-5 p-4 mt-2 shadow-md rounded-sm",
  };

  return (
    <>
      <FieldWrapper {...props}>
        {({ input, meta }) => {
          const isOnError = (meta.invalid && meta.touched) || false;
          setHasError(isOnError);

          // All shared props for Selects
          const selectProps = {
            isMulti: isMulti,
            placeholder: placeholder,
            inputId: input.name,
            name: input.name,
            options: options,
            unstyled: true,
            isDisabled: disabled,
            isClearable: clearable,
            isSearchable: searchable,
            classNames: customDropdownStyles,
            menuPortalTarget: document.body,
          };

          return (
            <div className={className}>
              {label && (
                <p className={`text-sm mb-2 text-forest-100`}>
                  {label}
                  {props.required && "*"}
                </p>
              )}
              {!isMulti ? (
                // Handle case for single select
                <Select
                  components={{ DropdownIndicator }}
                  menuPosition="fixed"
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  onChange={(newValue: any, _actionMeta: ActionMeta<any>) => {
                    input.onChange(newValue?.value);
                  }}
                  value={options?.find((option) => option.value === input.value)}
                  {...selectProps}
                  noOptionsMessage={() => noOptionsMessage || "Your search returned no results"}
                />
              ) : (
                // Handle case for multi select
                <Select
                  styles={{
                    option: (style, { isDisabled }) => {
                      if (isDisabled) {
                        return { ...style, color: "#D0D0D1", cursor: "not-allowed" };
                      }
                      return style;
                    },
                  }}
                  menuPosition="fixed"
                  components={{ DropdownIndicator }}
                  onChange={(newValue: any, _actionMeta: ActionMeta<any>) => {
                    if (newValue && Array.isArray(newValue)) {
                      if (
                        newValue.some((opt: { value: string }) => {
                          return opt.value === NONE_KEYWORD;
                        })
                      ) {
                        input.onChange([NONE_KEYWORD]);
                        return [NONE_KEYWORD];
                      } else {
                        input.onChange(newValue.map((opt) => opt.value));
                        return newValue;
                      }
                    }
                    return null;
                  }}
                  {...selectProps}
                  value={options
                    ?.filter((opt) => input.value.includes(opt.value))
                    .map((val) => {
                      return { value: val.value, label: val.label };
                    })}
                  options={options?.map((opt) => {
                    if (input.value.includes(NONE_KEYWORD)) {
                      return { ...opt, isDisabled: true };
                    } else {
                      return opt;
                    }
                  })}
                  noOptionsMessage={() => noOptionsMessage || "Your search returned no results"}
                />
              )}
              <FieldErrorNew name={input.name} />
              {helperText && (
                <div className={"mt-2 flex items-center"}>
                  <i className={`fas fa-exclamation-circle mr-2 text-error`}></i>
                  <span
                    className={classNames(
                      "min-h-6 w-full text-grey-160 text-sm align-top inline-block",
                    )}
                  >
                    {helperText}
                  </span>
                </div>
              )}
            </div>
          );
        }}
      </FieldWrapper>
    </>
  );
};
