/** @format */

import type { FC } from "react";
import type React from "react";

import { useRef } from "react";

import classNames from "classnames";

import type { BadgePdbProps } from "src/v2/designSystem/BadgePdb";

import { BadgePdb } from "src/v2/designSystem/BadgePdb";

interface IRadioOptionProps {
  /**
   * Flag. Indicates if the radio button is selected.
   * @default false
   */
  selected?: boolean;

  /**
   * Flag. Enable / disable radio button.
   * @default false
   */
  disabled?: boolean;

  /**
   * Color to be applied in the radio button.
   */
  color?: string;

  /**
   * Radio button label.
   */
  label: string | JSX.Element;

  /**
   * Badge componenets props.
   */
  badgeProps?: BadgePdbProps;
}

export const RadioOption: FC<IRadioOptionProps> = ({
  selected = false,
  disabled = false,
  color,
  label,
  badgeProps,
  ...input
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyboardSelection = (e: React.KeyboardEvent<HTMLLabelElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      inputRef.current?.click();
    }
  };

  const getStyles = () => {
    const baseStyle = "cursor-pointer radio-selection";
    const disabledStyle = "opacity-50 cursor-not-allowed bg-grey-20";
    const selectedStyle = "bg-sand-60";
    const unselectedStyle = "bg-grey-10 hover:bg-sand-20";

    return `${disabled ? disabledStyle : baseStyle} ${selected ? selectedStyle : unselectedStyle}`;
  };

  return (
    <label
      className={classNames("flex flex-row px-6 py-5 items-center rounded", getStyles())}
      tabIndex={disabled ? -1 : 0}
      onKeyDown={(e) => {
        handleKeyboardSelection(e);
      }}
      aria-checked={selected}
      role="radio"
    >
      <input disabled={disabled} className="hidden" ref={inputRef} {...input} />
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="11" stroke={color || "#364241"} strokeWidth="2" />
        {selected && <circle cx="12" cy="12" r="6.5" fill={color || "#364241"} />}
      </svg>
      <div className="flex-1 flex-col leading-6 px-4">
        {typeof label === "string" ? <p>{label}</p> : <>{label}</>}
      </div>

      {badgeProps && <BadgePdb variant={badgeProps?.variant || "brandDark"} {...badgeProps} />}
    </label>
  );
};
