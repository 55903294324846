/** @format */

import React from "react";

import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";

interface RoundedFontAwesomeIconProps {
  backgroundClassName: string;
  iconClassName: string;
  icon: IconProp;
}

export const RoundedFontAwesomeIcon = (props: RoundedFontAwesomeIconProps) => (
  <div
    className={cn(
      "h-8 w-8 rounded-full flex items-center justify-center overflow-hidden",
      props.backgroundClassName,
    )}
  >
    <FontAwesomeIcon className={props.iconClassName} icon={props.icon} />
  </div>
);
