/** @format */

import type React from "react";
import type { FieldInputProps, FieldMetaState } from "react-final-form";

import { useLocation } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

import classnames from "classnames";
import Cleave from "cleave.js/react";
import type { SubmissionErrors } from "final-form";
import moment from "moment";

import type { DropdownOption } from "src/v2/designSystem";

import {
  FieldWrapper,
  NumberField,
  SimpleForm,
  TextFieldNew,
  Typography,
} from "@alphamedical/components";
import { ButtonPdb, DropdownSelect } from "src/v2/designSystem";

import type { EligibilityLookUpType } from "src/routes/BenefitHolderRegistration";

import { ActivateBenefitsContent } from "src/content/v2/routes/ActivateBenefits";
import { BenefitHolderRegistrationContent } from "src/content/v2/routes/BenefitHolderRegistration";
import { ErrorPrompt } from "src/v2/components/Svgs/ErrorPrompt";
import { STATES_FULL } from "src/v2/constants";

import { LookupSecondaryButton } from "./LookupSecondaryButton";

// TODO: Move this styling to v2/designSystem
import "./dropdown.scss";

interface ChangeEvent<T> extends React.ChangeEvent<T> {
  target: { rawValue: string } & EventTarget & T;
}

const NULL_OPTION = "!none";

interface AddressLookupComponentProps {
  lookupType: EligibilityLookUpType;
  formError: string;
  successUrl: string;
  clearFormError: () => void;
  onSubmitLookupForm: (
    values: any,
    lookupType: EligibilityLookUpType,
    successUrl: string,
  ) => SubmissionErrors | Promise<SubmissionErrors> | void;
}

export const AddressLookupForm = ({
  lookupType,
  formError,
  successUrl,
  clearFormError,
  onSubmitLookupForm,
}: AddressLookupComponentProps) => {
  const location = useLocation();
  const inputStyles = (
    error?: boolean,
  ) => `py-3 px-2 text-sm bg-transparent text-grey-160 field-placeholder
  w-full border-2 ${error ? "border-red" : "border-grey-100"} focus:outline-none rounded`;
  const inputTitleStyles = (error?: boolean) => `text-sm mt-4 mb-2 ${error ? "text-error" : ""}`;
  const content = location.pathname.includes("activate-benefits")
    ? ActivateBenefitsContent
    : BenefitHolderRegistrationContent;

  return (
    <div>
      <div className={`flex justify-center ${formError ? "" : "mb-8"}`}>
        <div>
          <Typography variant="h3" className="mb-4">
            {!formError
              ? content.addressLookUp?.title
              : "We're having trouble finding this account."}
          </Typography>
          {!formError && <Typography variant="body1">{content.addressLookUp?.body}</Typography>}
        </div>
      </div>
      <div>
        <SimpleForm<AddressLookupFormProps>
          hideDebugInfo={false}
          onSubmit={(values) => {
            return onSubmitLookupForm(values, lookupType, successUrl);
          }}
          validate={(values: AddressLookupFormProps) => {
            const errors: Record<string, string> = {};
            if (values.zipcode?.length < 5) {
              errors["zipcode"] = "Must be 5 digits";
            }
            return errors;
          }}
        >
          {({ form }) => {
            const formState = form.getState();
            const address1Error = formState.errors?.address1 && formState.touched?.address1;
            const address2Error = formState.errors?.address2 && formState.touched?.address2;
            const cityError = formState.errors?.city && formState.touched?.city;
            const zipcodeError =
              formState.errors?.zipcode &&
              (formState.modified?.zipcode || formState.touched?.zipcode);
            const stateError = formState.errors?.state && formState.touched?.state;
            return (
              <div className={"mt-6 h-full"}>
                {/* TODO: Add this style of text field to alpha-js-packages and replace the below code */}
                {formError ? (
                  <div>
                    <p dangerouslySetInnerHTML={{ __html: formError }} />
                    <ButtonPdb
                      className={"mt-12 w-full"}
                      onClick={() => {
                        clearFormError();
                      }}
                    >
                      Try Again
                    </ButtonPdb>
                  </div>
                ) : (
                  <>
                    {" "}
                    <p className={inputTitleStyles(address1Error)}>
                      {location.pathname.includes("activate-benefits")
                        ? "Your address"
                        : "Primary benefit holder address"}
                    </p>
                    <TextFieldNew
                      name={"address1"}
                      placeholder={"e.g. 201 Main St"}
                      minLength={2}
                      required={true}
                    >
                      {({
                        input,
                        meta,
                      }: {
                        input: FieldInputProps<any>;
                        meta: FieldMetaState<any>;
                      }) => {
                        return (
                          <div>
                            <input
                              className={inputStyles(address1Error)}
                              placeholder={"e.g. 201 Main St"}
                              type={"text"}
                              {...input}
                            />
                            {address1Error && <ErrorPrompt text={meta.error} />}
                          </div>
                        );
                      }}
                    </TextFieldNew>
                    {/* TODO: Add this style of text field to alpha-js-packages and replace the below code */}
                    <p className={inputTitleStyles(address2Error)}>
                      Apartment, suite, etc (optional)
                    </p>
                    <TextFieldNew name={"address2"} placeholder={"e.g. Apt 1"}>
                      {({
                        input,
                        meta,
                      }: {
                        input: FieldInputProps<any>;
                        meta: FieldMetaState<any>;
                      }) => {
                        return (
                          <div>
                            <input
                              className={inputStyles(meta.error)}
                              placeholder={"e.g. Apt 1"}
                              type={"text"}
                              {...input}
                            />
                            {address2Error && <ErrorPrompt text={meta.error} />}
                          </div>
                        );
                      }}
                    </TextFieldNew>
                    {/* TODO: Add this style of text field to alpha-js-packages and replace the below code */}
                    <p className={inputTitleStyles(cityError)}>City</p>
                    <TextFieldNew name={"city"} placeholder={"e.g. Houston"} required={true}>
                      {({
                        input,
                        meta,
                      }: {
                        input: FieldInputProps<any>;
                        meta: FieldMetaState<any>;
                      }) => {
                        return (
                          <div>
                            <input
                              className={inputStyles(cityError)}
                              placeholder={"e.g. Houston"}
                              type={"text"}
                              {...input}
                            />
                            {cityError && <ErrorPrompt text={meta.error} />}
                          </div>
                        );
                      }}
                    </TextFieldNew>
                    <div className="grid grid-cols-2 gap-2">
                      <div className="grid-cols-start-2 grid-cols-end-1">
                        {/* TODO: Add this style of select field to alpha-js-packages and replace the below code */}
                        <p className={inputTitleStyles(stateError)}>State</p>
                        <FieldWrapper
                          format={(value) => (value == null ? NULL_OPTION : value)}
                          parse={(value) => (value === NULL_OPTION ? null : value)}
                          name="state"
                          required={true}
                        >
                          {({ input, meta }) => {
                            return (
                              <div>
                                <DropdownSelect
                                  options={STATES_FULL as DropdownOption[]}
                                  {...input}
                                  name={input.name}
                                />
                              </div>
                            );
                          }}
                        </FieldWrapper>
                      </div>
                      <div className="grid-cols-start-1 grid-cols-end-2">
                        {/* TODO: Add this style of NumberField field to alpha-js-packages and replace the below code */}
                        <p className={inputTitleStyles(zipcodeError)}>ZIP code</p>
                        <NumberField name={"zipcode"} placeholder={"e.g. 10001"} required={true}>
                          {({
                            input,
                            meta,
                          }: {
                            input: FieldInputProps<any>;
                            meta: FieldMetaState<any>;
                          }) => {
                            return (
                              <>
                                <Cleave
                                  className={classnames(inputStyles(zipcodeError), "leading-6")}
                                  placeholder="e.g. 10001"
                                  value={input.value}
                                  options={{
                                    numericOnly: true,
                                    blocks: [5],
                                    delimiter: "-",
                                    delimiterLazyShow: true,
                                  }}
                                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    form.change("zipcode", e.target.rawValue);
                                  }}
                                />
                                <input {...input} {...meta} className={"hidden"} />
                                {zipcodeError && <ErrorPrompt text={meta.error} />}
                              </>
                            );
                          }}
                        </NumberField>
                      </div>
                    </div>
                    <ButtonPdb
                      disabled={formState.submitting}
                      className={"flex w-full justify-center items-center mt-8 mb-4"}
                    >
                      {formState.submitting ? (
                        <ClipLoader color={"#A4B7B5"} />
                      ) : (
                        content.addressLookUp?.cta[0]?.text
                      )}
                    </ButtonPdb>
                    <LookupSecondaryButton
                      clearFormError={clearFormError}
                      content={content.addressLookUp?.cta[1]?.text}
                      redirect={content.addressLookUp?.cta[1]?.to}
                    />
                    {formState.submitting && <div className={"flex justify-center my-10"}></div>}
                  </>
                )}
              </div>
            );
          }}
        </SimpleForm>
      </div>
    </div>
  );
};

interface AddressLookupFormProps {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
}
