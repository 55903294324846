/** @format */

import React from "react";

import type { BadAddressDetails, SecondaryUserViewsUpdateUserPiiRequestBodySchema } from "src/api";

import { Button } from "src/v2/components/Button";

interface MessageProps {
  item: BadAddressDetails;
  pii: SecondaryUserViewsUpdateUserPiiRequestBodySchema;
  setModalContent: (key: string) => void;
}
const Message = ({ item, pii, setModalContent }: MessageProps) => {
  return (
    <div className="flex flex-col text-center text-16">
      <p className="font-semibold">
        Hi {(pii && pii.preferred_firstname) || (pii && pii.first_name)},
      </p>
      <p className="mt-4">
        We attempted to submit your order to our partner pharmacy for {item.medication_name},
        however, they let us know there was an issue with the shipping address you provided us.
      </p>
      <p className="mt-4">Below is the shipping address information we have on file for you:</p>
      <p className="mt-4 font-bold">
        {item.address} {item.address2}
      </p>
      <p className="font-bold">
        {item.city}, {item.state} {item.zipcode}
      </p>
      <p className="mt-4">
        If this information is incorrect in any way, please click “Update Shipping Address” below to
        provide us with your correct shipping address information.
      </p>
      <p className="mt-4">
        If everything looks correct, please click “Confirm Shipping Address” and leave us a note
        letting us know we have the correct shipping information.
      </p>
      <p className="mt-4">Please let us know if you have any questions, we are here to help!</p>
      <Button
        extraClassNames={`
                        text-center
                        border-2 border-primary
                        hover:text-white
                        items-center w-1/2 cursor-pointer`}
        bgColor={"primary"}
        hoverBgColor={"primary-80"}
        textColor={"white"}
        onClick={() => setModalContent("update")}
      >
        Update Shipping Address
      </Button>
      <Button
        extraClassNames={`
                        text-center
                        border-2 border-primary
                        hover:text-white
                        items-center w-1/2 cursor-pointer`}
        bgColor={"primary"}
        hoverBgColor={"primary-80"}
        textColor={"white"}
        onClick={() => setModalContent("confirm")}
      >
        Confirm Shipping Address
      </Button>
    </div>
  );
};

export default Message;
