/** @format */

import type { AxiosError } from "axios";
import type { FormApi } from "final-form";
import { FORM_ERROR } from "final-form";
import _ from "lodash";

import type { ContentReference } from "src/components/DynamicForm/types";
import type { ErrorResponse } from "src/v2/models/api_types";
import type { Outline } from "src/v2/models/outline";

import { isOptionField } from "./types";

export const axiosErrorHandler =
  (message: string, getFieldErrors?: (errorData: ErrorResponse) => object) =>
  (error: AxiosError<ErrorResponse>) => {
    const errorData = error.response?.data;
    const errorDescription = errorData?.type
      ? `${errorData.type}: ${errorData.description}`
      : error.message;

    const fullMessage = [message, errorDescription].filter((text) => !!text).join(". ");

    const getErrorsFunc = getFieldErrors || ((errorData) => errorData.errors);

    const fieldErrors = errorData ? getErrorsFunc(errorData) : {};
    console.log({ error });
    return {
      [FORM_ERROR]: fullMessage,
      ...fieldErrors,
    };
  };

/**Use the form state to determine which fields have been changed.
 * https://final-form.org/docs/final-form/types/FormState
 *
 * special workaround for fileFields that send data as base64 data-string,
 * but get existing data as signed URLs. If that data saved by means other than the
 * current form and populated by a context provider, it would otherwise appear to be
 * a changed value.
 */
export const getChangedValues = <FormValues, InitialFormValues>(
  values: any,
  form: FormApi<FormValues, InitialFormValues>,
  existingFileMap: Record<string, string | undefined>,
) => {
  const formState = form.getState();
  return _.pickBy(
    values as any,
    (value, key) =>
      (!!formState.dirtyFields[key] ||
        (formState.modified && !!formState.modified[key]) ||
        !!formState.dirtyFieldsSinceLastSubmit[key]) &&
      !(key in existingFileMap && value === existingFileMap[key]),
  );
};

/** Combines form value data with metadata from the outline for submitting data to the API  */
export const getResponsesPayload = <FormValues = Record<string, any>>(
  outline: Outline,
  values: FormValues,
) => {
  return _.chain(values as unknown as object)
    .pickBy((value, fieldKey) => {
      // don't submit answers that are undefined
      if (value === undefined) {
        return false;
      }

      // check if a field exists in the outline
      const field = outline.fields[fieldKey];

      // keep response if valid field
      return field;
    })
    .mapValues((value, fieldKey) => {
      const field = outline.fields[fieldKey];
      return {
        category: field.category || "consult",
        name: fieldKey,
        type: field.type,
        value: value,
        question_text: field.title || field.subtitle,
        options: isOptionField(field) ? field.options : undefined,
        ...(field.tags && { tags: field.tags }),
      };
    })
    .value();
};

export const isHTML = (str: string | ContentReference | undefined) => {
  return typeof str === "string" && /<\/?[a-z][\s\S]*>/i.test(str);
};
