/** @format */

import React from "react";

import { Typography } from "@alphamedical/components";

import VisitList from "src/v2/components/Visits/VisitList";

const ReturnVisitsList = (props: any) => {
  const dueVisits = props.dueVisits;
  const upcomingVisits = props.upcomingVisits;
  const displayLimit = 2;

  return (
    <div className="mb-1">
      {[...dueVisits, ...upcomingVisits].length > 0 && (
        <Typography variant="body2" component="p" className="mb-4 text-grey-160">
          Submit to continue your care.
        </Typography>
      )}
      {[...dueVisits, ...upcomingVisits].length === 0 && (
        <div className="bg-grey-10 rounded">
          <Typography variant="h4" component="h4" className="text-forest-120 p-4">
            No return visits scheduled.
          </Typography>
        </div>
      )}
      {dueVisits.length > 0 && (
        <div className="mb-4">
          <Typography variant="h5" component="h5" align="left" className="mb-3 text-grey-160">
            Due
          </Typography>
          <VisitList
            consults={dueVisits}
            listType="taskList"
            limitItems={displayLimit}
            linkText={`+${dueVisits.length - displayLimit} more`}
          />
        </div>
      )}

      {upcomingVisits.length > 0 && dueVisits.length > 0 && <div className="mt-3"></div>}
      {upcomingVisits.length > 0 && (
        <div className="mb-4">
          <Typography variant="h5" component="h5" align="left" className="mb-3 text-grey-160">
            Upcoming
          </Typography>

          <VisitList
            consults={upcomingVisits}
            listType="taskList"
            limitItems={displayLimit}
            linkText={`+${upcomingVisits.length - displayLimit} more`}
          />
        </div>
      )}
    </div>
  );
};

export default ReturnVisitsList;
