/** @format */

import type { match } from "react-router-dom";

import React from "react";
import { Link, useHistory } from "react-router-dom";

import type { AxiosError } from "axios";

import { ButtonPdb } from "src/v2/designSystem";

import type { ErrorResponse } from "src/v2/models/api_types";

import { Analytics } from "src/analytics";
import { OrganizationsService } from "src/api/services/OrganizationsService";
import { PageWrapper } from "src/components/PageWrapper";
import { ActivateBenefitsContent } from "src/content/v2/routes/ActivateBenefits";
import { FullPageModal, IconTextChevron, Line } from "src/v2/components";
import Confirm from "src/v2/components/ActivateBenefits/Confirm";
import { AddressLookupForm } from "src/v2/components/Forms/BenefitRegistration/AddressLookupForm";
import { PhoneLookupForm } from "src/v2/components/Forms/BenefitRegistration/PhoneLookupForm";
import { MarkdownText } from "src/v2/components/MarkdownText";
import { useStoreDispatch } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";

import type { EligibilityLookUpType } from "./BenefitHolderRegistration";

interface MatchParams {
  step: "confirm" | "phone" | "address";
}

const ActivateBenefits = ({ match }: { match: match<MatchParams> }) => {
  const step = match.params.step;
  const history = useHistory();
  const [isOpen, setIsOpen] = React.useState(false);
  const [formError, setError] = React.useState<string>("");
  const user = useCurrentUser();
  const dispatch = useStoreDispatch();

  const onSubmitLookupForm = (
    values: any,
    lookupType: EligibilityLookUpType,
    successUrl: string,
  ) => {
    return OrganizationsService.organizationEligibilityLookup({
      lookupType: lookupType,
      phoneNumber: values?.phone_number,
      address1: values?.address1,
      address2: values?.address2,
      city: values?.city,
      state: values?.state,
      zipcode: values?.zipcode,
    })
      .then((_response) => {
        setError("");
        return OrganizationsService.activateBenefits({
          requestBody: {
            eligibility_lookup_values: {
              phone_number: values?.phone_number,
              address_1: values?.address1,
              address_2: values?.address2,
              city: values?.city,
              state: values?.state,
              zipcode: values?.zipcode,
              lookup_type: lookupType,
            },
            user_id: user.id,
          },
        })
          .then((_values) => {
            Analytics.trackEvent({
              category: "Benefits",
              action: "Activate",
              name: _values?.plan?.code,
            });
            dispatch.auth.setEligibilityLookupValues({
              eligibleIndividualFound: true,
            });
            history.push(successUrl);
          })
          .catch((error: AxiosError<ErrorResponse>) =>
            setError(error.response?.data.description || "Something went wrong"),
          );
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        setError(error.response?.data.description || "Something went wrong");
      });
  };
  return (
    <PageWrapper showIntercom={true}>
      <div className="mx-auto max-w-lg px-4 pt-10">
        {step === "phone" ? (
          <PhoneLookupForm
            lookupType="phone_number"
            onSubmitLookupForm={onSubmitLookupForm}
            formError={formError}
            successUrl="/activate-benefits/confirm"
            clearFormError={() => setError("")}
          />
        ) : step === "address" ? (
          <AddressLookupForm
            lookupType="address"
            onSubmitLookupForm={onSubmitLookupForm}
            formError={formError}
            successUrl="/activate-benefits/confirm"
            clearFormError={() => setError("")}
          />
        ) : step === "confirm" ? (
          <Confirm />
        ) : (
          <>
            <div className="flex justify-center mb-8">
              <p className="text-xl">{ActivateBenefitsContent.optionsPage?.title}</p>
            </div>
            {ActivateBenefitsContent.optionsPage?.iconTextChevronOptions.map(
              ({ text, icon, iconAlt, planAction }) => {
                return (
                  <div key={planAction}>
                    <Link to={`/activate-benefits/${planAction}`}>
                      <IconTextChevron
                        planAction={planAction}
                        text={text}
                        icon={icon}
                        iconAlt={iconAlt}
                      />
                      <Line />
                    </Link>
                  </div>
                );
              },
            )}
          </>
        )}

        {step !== "confirm" && (
          <p
            onClick={() => setIsOpen(true)}
            className="flex justify-center text-cornflower-100 text-center mt-6 cursor-pointer font-bold"
          >
            {ActivateBenefitsContent.optionsPage?.link?.text}
          </p>
        )}
        <FullPageModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          title={ActivateBenefitsContent?.helpPage?.modalTitle}
        >
          <div className="mx-6 flex flex-col justify-center">
            <p className="text-xl mt-12 mb-6">{ActivateBenefitsContent?.helpPage?.title}</p>
            <p className="mb-6">{ActivateBenefitsContent?.helpPage?.body}</p>
            <IconTextChevron
              planAction="N/A"
              icon={ActivateBenefitsContent?.helpPage?.action?.icon}
              iconAlt={ActivateBenefitsContent?.helpPage?.action?.alt}
              text={
                <MarkdownText
                  text={ActivateBenefitsContent?.helpPage?.action?.text}
                  linkStyle={{ color: "#6271C2", fontWeight: "bold" }}
                />
              }
            />
            <Line />

            <ButtonPdb onClick={() => setIsOpen(false)} className="mt-10" variant="secondary">
              {ActivateBenefitsContent.helpPage?.cta?.text}
            </ButtonPdb>
          </div>
        </FullPageModal>
      </div>
    </PageWrapper>
  );
};

export default ActivateBenefits;
