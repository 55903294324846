/** @format */

import type React from "react";

interface IconTextProps {
  icon: string;
  iconWidth?: number;
  iconAlt: string;
  text: string | React.ReactNode;
  textAlignment?: string;
  mb?: string;
  iconMarginTop?: string;
  textColor?: string;
}

/**
 * Displays icon on left with text to the right.
 */
const IconText = ({
  icon,
  iconWidth,
  iconAlt,
  text,
  mb = "mb-10",
  textAlignment = "items-start",
  iconMarginTop = "mt-2",
  textColor = "text-forest",
}: IconTextProps) => {
  return (
    <div className={`flex flex-row ${mb} ${textAlignment}`}>
      <img className={`mr-6 ${iconMarginTop}`} width={iconWidth} src={icon} alt={iconAlt} />
      <p className={`text-sm ${textColor}`}>{text}</p>
    </div>
  );
};

export default IconText;
