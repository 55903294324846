/** @format */

import type React from "react";

import { Loader } from "src/components/Loader";

interface UnAuthWrapperProps {
  showLoader: boolean;
  children: React.ReactNode;
  bgColor?: string;
}
const UnAuthWrapper = (props: UnAuthWrapperProps) => {
  return (
    <Loader show={props.showLoader} screen={true}>
      <div className="w-full bg-sand">
        <div style={{ height: "11px" }} className="w-full" />
        <div className={`bg-${props.bgColor || "white"} w-full`}>
          <div className={`flex items-center sm:px-0 w-full`}>{props.children}</div>
        </div>
      </div>
    </Loader>
  );
};

export default UnAuthWrapper;
