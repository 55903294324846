/** @format */

import React from "react";

import moment from "moment/moment";

import { SimpleForm } from "@alphamedical/components";
import { Datepicker, DropdownSelect } from "src/v2/designSystem";

import type { AlphaMedication, PatientMedicationTrackingSchema, TagsSchema } from "src/api";

import { Analytics } from "src/analytics";
import { TagsService } from "src/api";
import { BottomSheet } from "src/components/BottomSheet/BottomSheet";
import {
  DATE_TAKEN_FORMAT,
  TIME_TAKEN_OPTIONS,
} from "src/routes/Records/WeightLossJournal/constants";
import { now } from "src/utils/date_utils";
import { useStoreDispatch } from "src/v2/models";

interface MedicationTrackingValues {
  medication_id: string;
  date_taken: string;
  time_taken?: "morning" | "afternoon" | "evening" | "night";
  injection_site?: string;
}

export const MedicationDoseForm = (props: {
  entry?: PatientMedicationTrackingSchema;
  availableMedications: AlphaMedication[];
  onFinish: () => void;
  onCancel: () => void;
}) => {
  const isEdit = !!props.entry;

  const dispatch = useStoreDispatch();
  const initialValues: MedicationTrackingValues = isEdit
    ? {
        medication_id: props.entry?.medication ? props.entry.medication.id.toString() : "",
        date_taken: moment(props.entry?.date_taken, DATE_TAKEN_FORMAT).format(DATE_TAKEN_FORMAT),
        time_taken: props.entry?.time_taken,
        injection_site: props.entry?.injection_site,
      }
    : {
        medication_id: "",
        date_taken: now("MM-dd-yyyy"),
        time_taken: undefined,
        injection_site: undefined,
      };

  const [injectionSites, setInjectionSites] = React.useState<TagsSchema[]>([]);

  React.useEffect(() => {
    TagsService.getTagList({ tagType: "medication_tracking_injection_site" }).then((tags) => {
      setInjectionSites(tags);
    });
  }, []);

  const onSubmit = async (values: MedicationTrackingValues) => {
    const action = !isEdit ? "added" : "edited";
    try {
      const requestBody = {
        medication_id: parseInt(values.medication_id),
        date_taken: values.date_taken,
        time_taken: values.time_taken === null ? undefined : values.time_taken,
        injection_site: values.injection_site === null ? undefined : values.injection_site,
      };
      if (!isEdit) {
        await dispatch.medicationTracking.createMedicationTracking(requestBody);
      } else {
        if (props.entry) {
          await dispatch.medicationTracking.updateMedicationTracking({
            requestBody,
            medicationTrackingId: props.entry.id,
          });
        }
      }
      Analytics.trackEvent({ category: "WeightLossJournal", action: "AddMedicationEntry" });
      dispatch.snacks.addSnack({
        type: "primary",
        id: "add-medication-journal-entry",
        message: `Medication dose ${action}.`,
        delay: 10,
      });
    } catch (e) {
      dispatch.snacks.addSnack({
        type: "error",
        id: "add-medication-journal-entry",
        message: `Sorry, your journal entry could not be ${action}`,
        delay: 10,
      });
    } finally {
      props.onFinish();
    }
  };

  const handleDelete = async () => {
    if (isEdit && props.entry) {
      try {
        await dispatch.medicationTracking.deleteMedicationTracking(props.entry.id);
        Analytics.trackEvent({ category: "WeightLossJournal", action: "DeleteMedicationEntry" });
        dispatch.snacks.addSnack({
          type: "primary",
          id: "delete-medication-journal-entry",
          message: `Medication dose deleted.`,
          delay: 10,
        });
      } catch (e) {
        dispatch.snacks.addSnack({
          type: "error",
          id: "delete-medication-journal-entry-error",
          message: `Sorry, your journal entry could not be deleted.`,
          delay: 10,
        });
      } finally {
        props.onFinish();
      }
    }
  };
  return (
    <SimpleForm<MedicationTrackingValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ invalid, handleSubmit, pristine }) => {
        return (
          <>
            <div className="py-4 space-y-4">
              <DropdownSelect
                required
                label="Medication"
                name="medication_id"
                options={props.availableMedications.map((medication: AlphaMedication) => ({
                  label: medication.name || "N/A",
                  value: medication.id.toString(),
                }))}
              />
              <Datepicker
                required
                name="date_taken"
                label="Date Taken"
                maxDate={new Date().toString()}
              />
              <DropdownSelect
                label="Time taken (optional)"
                name="time_taken"
                options={TIME_TAKEN_OPTIONS}
                clearable
              />
              <DropdownSelect
                label="Injection site (optional)"
                name="injection_site"
                options={injectionSites.map((tag) => ({
                  label: tag.label,
                  value: tag.label,
                }))}
                clearable
              />
            </div>
            <BottomSheet.Actions
              showBack
              showDelete={isEdit}
              onBack={props.onCancel}
              buttonText={!isEdit ? "Add" : "Save"}
              onConfirm={handleSubmit}
              disabled={invalid || (isEdit && pristine)}
              onDelete={handleDelete}
            />
          </>
        );
      }}
    />
  );
};
