/** @format */

import React from "react";

import { PageSectionTitle } from "src/components/PageSectionTitle";

interface CardContentProps {
  detailList: {
    title: string | undefined | boolean;
    data: string | undefined | JSX.Element;
    render_label?: boolean;
    emphasize?: boolean;
    hideSection?: boolean;
  }[];
}

export const CardContent = ({ detailList }: CardContentProps) => {
  return (
    <div>
      {detailList.map((detail: any) => {
        return (
          !detail.hideSection &&
          detail.title && (
            <div key={detail.title} className="flex flex-col text-sm">
              <PageSectionTitle
                title={detail.title}
                cxFontColor={detail.emphasize ? "text-primary" : "text-primary-60"}
                cxFontSize="text-xs"
              />
              <div className="flex justify-between">
                {(React.isValidElement(detail.data) && (
                  <div className="flex mt-2 w-full">{detail.data}</div>
                )) || (
                  <p
                    className={`${detail.emphasize ? "font-bold text-primary" : ""} mb-6 mt-1`}
                    dangerouslySetInnerHTML={{ __html: detail.data }}
                  />
                )}
                {detail.render_label && (
                  <div className="uppercase text-violator text-xs font-bold mt-1">
                    <span className="fas fa-circle fa-xs mr-1" /> expiring soon
                  </div>
                )}
              </div>
            </div>
          )
        );
      })}
    </div>
  );
};
