/** @format */

import type { FormRenderProps } from "react-final-form";

import React from "react";

import { DynamicField } from "src/components/DynamicForm";
import { PrimaryCarePhysicianFields } from "src/v2/components/Forms/PrimaryCarePhysicianFields";
import { Condition } from "src/v2/components/Wizard/utils";

import type { PageFormProps } from "./types";

import { PageFormWrapper } from "./pageWrapper";

export const PCPPage = (props: PageFormProps) => {
  return (
    <PageFormWrapper {...props}>
      {(formRenderProps: FormRenderProps) => {
        return (
          <>
            <DynamicField
              field={{
                key: "has_pcp",
                type: "radio",
                title: "Do you have a primary care provider?",
                options: [
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ],
              }}
            />
            <Condition when="has_pcp" is={"yes"} resetOnHide={["pcp"]}>
              {() => {
                const { values, form } = formRenderProps;
                if (!values.pcp.id) {
                  form.change("pcp.authorized_to_contact", true);
                }
                return <PrimaryCarePhysicianFields />;
              }}
            </Condition>
          </>
        );
      }}
    </PageFormWrapper>
  );
};
