/** @format */

import type React from "react";

import { useEffect, useState } from "react";
import { useForm, useFormState } from "react-final-form";
import BarLoader from "react-spinners/BarLoader";

import { Typography } from "@alphamedical/components";

import type { CustomFieldProps } from "src/components/DynamicForm/Fields/CustomFields/types";
import type { DynamicTimeSlotField } from "src/components/DynamicForm/types";

import { SynchronousVisitService } from "src/api";
import Scheduling from "src/components/DynamicForm/Fields/CustomFields/Synchronous/Scheduling";
import { guessTimezone } from "src/utils/date_utils";
import { useCurrentUser } from "src/v2/models/profile";
import { useCurrentConsult } from "src/v2/routes/Consultation";

export const SchedulingPage: React.FC<CustomFieldProps> = (props) => {
  const form = useForm();
  const user = useCurrentUser();
  const consult = useCurrentConsult();
  const fieldKey = props.field.key;
  const days = "days" in props.field ? (props.field as DynamicTimeSlotField).days : undefined;

  const { values } = useFormState();

  const [timeSlots, setTimeSlots] = useState<string[]>([]);
  const [loadingTimeSlots, setLoadingTimeSlots] = useState(true);
  const [timeSlotUpdated, setTimeSlotUpdated] = useState<boolean>(false);

  const userTimezone = guessTimezone() || "UTC";

  const loadAvailableDates = async () => {
    try {
      setLoadingTimeSlots(true);
      const daySlots = await SynchronousVisitService.getConsultAvailableTimeSlots({
        userId: user.id,
        consultId: `${consult.id}`,
      });
      setTimeSlots(daySlots);

      // Check if a previous selected slot is still available
      const selectedTime = values[fieldKey]?.date;
      if (selectedTime && !daySlots.includes(selectedTime)) {
        form.change(fieldKey, null);
      }
    } finally {
      setLoadingTimeSlots(false);
    }
  };

  useEffect(() => {
    loadAvailableDates();
  }, []);

  const timeSlotSelected = (value: string) => {
    setTimeSlotUpdated(true);
    form.change(fieldKey, {
      patient_timezone: userTimezone,
      date: value,
    });
  };

  return (
    <>
      <div>
        <Typography variant="body2">Timezone</Typography>
        <Typography>{userTimezone}</Typography>
      </div>
      {props.errorType == "sync-visit-scheduling" && !timeSlotUpdated && (
        <div>
          <Typography className="text-error" variant="h2">
            {props.errorMessage}
          </Typography>
        </div>
      )}
      {loadingTimeSlots ? (
        <div className="py-4">
          <BarLoader color={"#A4B7B5"} />
        </div>
      ) : (
        <Scheduling
          selectedTime={values[fieldKey]?.date}
          timesAvailable={timeSlots}
          timezone={userTimezone}
          onTimeSelected={timeSlotSelected}
          daysToShow={days}
        />
      )}
    </>
  );
};
