/** @format */

import React from "react";

export const HIPAABadges = () => (
  <div className="flex justify-end items-between">
    <div className="flex justify-between">
      <a
        href="https://www.legitscript.com/websites/?checker_keywords=helloalpha.com"
        target="_blank"
        rel="noopener noreferrer"
        title="Verify LegitScript Approval"
        className="m-auto"
        aria-describedby="new-window-2"
      >
        <img
          src="https://static.legitscript.com/seals/3128294.png"
          alt="LegitScript approved"
          width="59"
          height="42"
        />
      </a>
    </div>
  </div>
);
