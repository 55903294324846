/** @format */

// Module dependencies
import type { FC } from "react";

import React from "react";
import ReactMarkdown from "react-markdown";

import type { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faCircleExclamation,
  faCircleInfo,
  faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
// UI Components & Styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
// Utils

export type InfoCardVariants = "neutral" | "brand" | "outline" | "warning" | "error" | "repeating";

interface InfoCardProps {
  /**
   * The title of the info card.
   */
  title: string;

  /**
   * The body content of the info card.
   */
  body: string;

  /**
   * Whether to hide the icon in the info card.
   */
  hideIcon?: boolean;

  /**
   * The variant of the info card. Defaults to "brand".
   */
  variant: InfoCardVariants;

  /**
   * Additional classnames to be appended to existing classes.
   */
  className?: string;
}

export const InfoCard: FC<InfoCardProps> = ({
  title,
  body,
  hideIcon,
  variant = "brand",
  className,
}) => {
  /**
   * Maps the variant to the corresponding CSS classes.
   */
  const mapVariantToStyles: { [key in InfoCardVariants]: string } = {
    neutral: "bg-grey-10 text-forest-120",
    brand: "bg-cloud-40 text-forest-120",
    outline: "bg-white text-forest-120 border border-grey-light",
    warning: "bg-warning-pdb text-forest-120",
    error: "bg-error text-white",
    repeating: "bg-sky-20 text-forest-120",
  };

  /**
   * Gets the icon corresponding to the variant.
   */
  const getIconFromVariant: { [key in InfoCardVariants]: IconDefinition } = {
    neutral: faCircleInfo,
    brand: faCircleInfo,
    outline: faCircleInfo,
    warning: faTriangleExclamation,
    error: faCircleExclamation,
    repeating: faCircleInfo,
  };

  return (
    <div
      className={classnames(
        "rounded text-base leading-6 inline-flex p-4 flex-col gap-4 w-full",
        mapVariantToStyles[variant],
        className,
      )}
    >
      {title && (
        <div className="flex items-center gap-4">
          {!hideIcon && <FontAwesomeIcon icon={getIconFromVariant[variant]} size="xl" />}
          <span>{title}</span>
        </div>
      )}

      {body && (
        <span>
          <ReactMarkdown skipHtml>{body}</ReactMarkdown>
        </span>
      )}
    </div>
  );
};
