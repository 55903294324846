/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessageContentSchema } from '../models/MessageContentSchema';
import type { Pubnub_HooksSendMessageRequestBodySchema } from '../models/Pubnub_HooksSendMessageRequestBodySchema';
import type { Report_IssueReportIssueRequestBodySchema } from '../models/Report_IssueReportIssueRequestBodySchema';
import type { SentMessageSchema } from '../models/SentMessageSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ChatService {

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static completeMessageAction({
        key,
        userId,
    }: {
        key: string,
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/message_action/{key}/{user_id}',
            path: {
                'key': key,
                'user_id': userId,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static reportIssue({
        requestBody,
    }: {
        requestBody: Report_IssueReportIssueRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/report-issue',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns SentMessageSchema
     * @throws ApiError
     */
    public static sendMessage({
        userId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        requestBody: Pubnub_HooksSendMessageRequestBodySchema,
    }): CancelablePromise<SentMessageSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/send-message/{user_id}',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * This API is primarily for when pubnub notifies the frontend of a new message in a channel,
     * Since we must first send a message to pubnub to get the timetoken there can be a race condition
     * before the timetoken is added to the message record. By querying on message_id we can be sure at this point
     * the message has already been saved in our db.
     * @returns MessageContentSchema
     * @throws ApiError
     */
    public static getMessageById({
        userId,
        channel,
        messageId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        channel: string,
        messageId: string,
    }): CancelablePromise<MessageContentSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/{user_id}/channels/{channel}/message/{message_id}',
            path: {
                'user_id': userId,
                'channel': channel,
                'message_id': messageId,
            },
        });
    }

    /**
     * @returns MessageContentSchema
     * @throws ApiError
     */
    public static getUserChannelMessages({
        userId,
        channel,
        count,
        start,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        channel: string,
        /**
         * The total number of messages to return
         */
        count?: number,
        /**
         * Timetoken to search before
         */
        start?: string,
    }): CancelablePromise<Array<MessageContentSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/{user_id}/channels/{channel}/messages',
            path: {
                'user_id': userId,
                'channel': channel,
            },
            query: {
                'count': count,
                'start': start,
            },
        });
    }

}
