/** @format */
import type { match } from "react-router-dom";

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Typography } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import type { CanRescheduleSchema } from "src/api";

import { SynchronousVisitService } from "src/api";
import { PageWrapper } from "src/components/PageWrapper";
import { useSynchronousVisits } from "src/utils/hooks/useSynchronousVisits";
import { useTopBar } from "src/utils/hooks/useTopBar";
import InformationalCard from "src/v2/components/InformationalCard/InformationalCard";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { SyncVisitCard } from "src/v2/routes/Visit/SyncVisitCard";

interface MatchParams {
  consultId: string;
}

const SyncVisitDetails = ({ match }: { match: match<MatchParams> }) => {
  useTopBar({
    title: "Your return visit",
    variant: "nested",
    showBackButton: true,
  });
  const history = useHistory();
  const consultId = match.params.consultId;
  const [rescheduleData, setRescheduleData] = useState<CanRescheduleSchema>();
  useEffect(() => {
    SynchronousVisitService.canRescheduleSyncVisit({
      userId: "me",
      consultId,
    }).then((response) => {
      setRescheduleData(response);
    });
  }, []);
  const syncVisits = useSynchronousVisits();
  const syncVisit = syncVisits.find((visit) => `${visit.consult_id}` === consultId);

  return (
    <PageWrapper cxPadding="px-6 pt-8" cxMaxWidth="w-screen sm:max-w-xl">
      {syncVisit && (
        <div>
          {rescheduleData && !rescheduleData.can_reschedule && (
            <InformationalCard
              type="Warning"
              title="Reschedule not available"
              content={`It has been more than ${rescheduleData.max_days_reschedule} days since you submitted your visit, or there are no time slots available before this deadline. ${
                syncVisit.source === "UBACARE"
                  ? "Please contact your case manager for next steps in your care."
                  : `To continue your ${syncVisit.condition_name} care, please submit a new ${syncVisit.condition_name} visit.`
              }`}
            />
          )}
          <SyncVisitCard syncVisit={syncVisit} />
          <div className="space-y-8">
            <div>
              <Typography>
                You'll receive an email with instructions to join. You will need to dial in and
                enter a PIN at the time of your call.
              </Typography>
            </div>
            <div>
              {rescheduleData && (
                <Typography>
                  You must complete your provider call within {rescheduleData.max_days_reschedule}{" "}
                  days of submitting your online visit.
                </Typography>
              )}
            </div>
            <div>
              <ButtonPdb
                disabled={!rescheduleData || !rescheduleData.can_reschedule}
                className="w-full"
                variant="secondary"
                onClick={() =>
                  history.push(`/sync-visit-details/${syncVisit.consult_id}/reschedule`)
                }
              >
                Reschedule
              </ButtonPdb>
            </div>
          </div>
        </div>
      )}
    </PageWrapper>
  );
};

export default WithTopBarNavigation(SyncVisitDetails);
