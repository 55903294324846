/** @format */

import type React from "react";

import { Typography } from "@alphamedical/components";

import type { CardPopupContent } from "src/components/Popups/CardPopup";

interface GenericAvatarProps {
  bgColorClassName?: string;
  iconElement: React.ReactNode;
  title: string;
  subtitle?: string;
  bioModalProps?: {
    setBioModalIsOpen?: (value: boolean) => void;
    setBioModalContent?: (value: CardPopupContent) => void;
    bioModalContent?: CardPopupContent;
  };
}

export const GenericAvatar = ({
  bgColorClassName = "bg-sand-60",
  iconElement,
  subtitle,
  title,
  bioModalProps,
}: GenericAvatarProps) => {
  const { setBioModalIsOpen, setBioModalContent, bioModalContent } = bioModalProps || {};
  const useModal = !!(setBioModalIsOpen && setBioModalContent && bioModalContent);

  const handleClick = () => {
    if (useModal) {
      setBioModalContent(bioModalContent);
      setBioModalIsOpen(true);
    }
  };

  return (
    <div className="flex items-center bg-white mb-4">
      <div
        className={`w-10 h-10 rounded-full flex items-center justify-center overflow-hidden  ${bgColorClassName}`}
      >
        {iconElement}
      </div>
      <div className="ml-2">
        {/* render following div based on whether its title is used to open a biographical modal (rendered by a parent) */}
        <div className={useModal ? "cursor-pointer" : ""} onClick={handleClick}>
          <Typography variant={useModal ? "hyperlink" : "h4"} className="line-clamp-1">
            {title}
          </Typography>
        </div>
        {subtitle && <Typography variant="caption">{subtitle}</Typography>}
      </div>
    </div>
  );
};
