/** @format */

import React from "react";
import { Field } from "react-final-form";

import { FieldError } from "./error";

interface SelectFieldProps {
  name: string;
  options: { value: string; label: string }[];
}

export const SelectField = (props: SelectFieldProps) => (
  <>
    <Field name={props.name} options={props.options}>
      {({ input, meta }) => {
        const invalid = meta.error && meta.touched;
        return (
          <>
            <div className="relative">
              <select
                {...input}
                className={`
                  block mt-2 appearance-none w-full bg-sky-40 border border-sky text-grey-darker py-3 px-4 pr-8 rounded
                  leading-tight focus:outline-none focus:bg-white focus:border-grey
                `}
              >
                <option value="" disabled={true} />
                {props.options.map((option) => (
                  <option value={option.value} key={`${input.name}-option-${option.value}`}>
                    {option.label}
                  </option>
                ))}
              </select>
              <div
                className={`
                  pointer-events-none absolute
                  inset-y-0 right-0 flex items-center px-2 text-grey-darker
                `}
              >
                <div className="fas fa-chevron-down text-forest-40 mx-2" />
              </div>
            </div>
            {invalid && <span className="px-3 text-red">{meta.error}</span>}
          </>
        );
      }}
    </Field>
    <FieldError name={props.name} />
  </>
);

interface CheckboxFieldProps extends SelectFieldProps {
  toggle?: boolean;
}

export const CheckboxField = (props: CheckboxFieldProps) => (
  <>
    {props.options.map((option, idx) => (
      <Field
        key={`${props.name}-checkbox-${option.value}`}
        name={props.name}
        type="checkbox"
        value={option.value}
        parse={(props.toggle && ((value, name) => (value.length > 0) as any)) || undefined}
        format={(props.toggle && ((value, name) => (value ? ["yes"] : []))) || undefined}
      >
        {({ input, meta }) => {
          const invalid = meta.error && meta.touched;
          return (
            <>
              <label className="block normal-case field flex items-center">
                <input {...input} type="checkbox" className="display-none" />
                <div
                  tabIndex={0}
                  title={option.label}
                  onKeyPress={(e: any) => {
                    if (e.which === 32) {
                      e.target.parentElement.click();
                    }
                  }}
                  className={`
                  flex items-center justify-center checkbox-field w-8 h-8
                  bg-sky-60 border-8 border-sky-60 text-primary
                  ${input.checked ? "fas fa-check" : ""}
                `}
                />
                <div
                  className="checked-field p-4 t-2 rounded-sm"
                  dangerouslySetInnerHTML={{ __html: option.label }}
                />
              </label>
              {invalid && <div className="text-red">{meta.error}</div>}
            </>
          );
        }}
      </Field>
    ))}
  </>
);
