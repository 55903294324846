/** @format */
import React from "react";
import { useHistory } from "react-router-dom";

import { includes } from "lodash";
import moment from "moment";

import type { Service } from "src/v2/models/service";

import { PageSectionTitle } from "src/components/PageSectionTitle";
import { Button } from "src/v2/components/Button";
import { CONSULTS_WITH_CHECKUP } from "src/v2/constants";

interface YourVisitReturnCardProps {
  currentService: Service | undefined;
  condition: string;
  returnVisit?: any;
}

const newConsultLink = (key: string | undefined, is_refillable: boolean | undefined) => {
  if (key === "mental-health") {
    return "/return-consult/depression-anxiety-checkup";
  }
  if (includes(CONSULTS_WITH_CHECKUP, key)) {
    return key === "sti-testing-kit" ? `/consultation/${key}` : `/return-consult/${key}-checkup`;
  } else if (!is_refillable) {
    return `/visit/${key}`;
  }
  return `/return-consult/${key}-checkup`;
};

export const YourReturnVisitCard = ({
  currentService,
  condition,
  returnVisit,
}: YourVisitReturnCardProps) => {
  const [show, setShow] = React.useState(false);
  const [isReturnVisitDue, setIsReturnVisitDue] = React.useState<boolean>();
  const [triggerAt, setTriggerAt] = React.useState<string | undefined>();
  const show_cta = localStorage.getItem(`${condition}:start_anyway`) === "show_cta";
  const history = useHistory();
  const ctaRef = React.useRef<HTMLDivElement>(null);

  const startReturnAnyway = () => {
    localStorage.setItem(`${condition}:start_anyway`, "start_anyway");
    const link = newConsultLink(condition, currentService?.condition?.refillable);
    history.push(link);
  };

  const startReturnVisit = () => {
    const link = `/return-consult/${condition}-checkup`;
    history.push(link);
  };

  React.useEffect(() => {
    setIsReturnVisitDue(returnVisit && returnVisit.returnVisitStatus === "due");
    setTriggerAt(returnVisit && returnVisit.trigger_at ? returnVisit.trigger_at : undefined);
    if (show_cta || isReturnVisitDue) {
      ctaRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [returnVisit?.trigger_at, currentService?.scheduled_followup_task?.trigger_at, show_cta]);

  return (
    <div className="mb-4">
      <div ref={ctaRef} />
      <PageSectionTitle title="Your Next Return Visit" cxMargin="m-0 mb-4 mt-8" />
      <div className="p-6 bg-cloud-light-40">
        {condition === "mental-health" && (
          <div className="mb-6">
            <strong>You return visit must be completed in order to get your next refill.</strong>
          </div>
        )}
        <span className="text-xs font-semibold">DATE OF YOUR NEXT RETURN VISIT</span>
        {triggerAt ? (
          <p className="text-sm mt-2">{moment(triggerAt).format("l")}</p>
        ) : (
          <p className="text-sm mt-2">Not yet scheduled by your Alpha Provider.</p>
        )}
        {isReturnVisitDue && <Button onClick={startReturnVisit}>Submit visit</Button>}
        {!isReturnVisitDue && show_cta && (
          <Button onClick={startReturnAnyway}>Start a Return Visit Anyway</Button>
        )}
        <div onClick={() => setShow(!show)} className={`text-primary w-full mt-6 cursor-pointer`}>
          <div className="flex items-center">
            <span className="mr-3 text-sm font-bold">Learn more</span>
            <div className={`fas ${show ? "fa-chevron-up" : "fa-chevron-down"} text-sm`} />
          </div>
          {show && (
            <>
              {condition === "mental-health" ? (
                <p className="text-sm mt-4">
                  During your treatment, we'll ask you to complete a few return visits online (no
                  additional fees). This will help us measure your progress and adjust your
                  treatment plan.{" "}
                  <strong>
                    Any future refills will depend on your completion of these return visits.
                  </strong>
                </p>
              ) : (
                <p className="text-sm mt-4">
                  During your treatment, we'll ask you to complete a few return visits online. This
                  will help us measure your progress and adjust your treatment plan.{" "}
                  <strong>
                    Any future refills may depend on your completion of these return visits.
                  </strong>
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
