/** @format */

// Content for Activate benefits modal

export const ActivateBenefitsModalContent = {
  modalTitle: "Activate benefits",
  inactive_benefits: {
    body: [
      "If you have Alpha through your employer, we'll help you activate your plan and set up your account.",
      "If you aren't sure about your benefits, please reach out to your employer. For other questions, email us at",
    ],
    link: [
      {
        text: "Get started",
        to: "/activate-benefits",
      },
      {
        text: "sponsored-benefits@helloalpha.com",
        to: "mailto:sponsored-benefits@helloalpha.com",
      },
    ],
  },
  active_benefits: {
    title: "We've confirmed your coverage with your employer.",
    body: "Welcome back! We look forward to caring for you.",
    link: {
      text: "Start a visit",
      to: "/your-care/explore-visits",
    },
  },
  helpPage: {
    modalTitle: "Help with accessing your benefit",
    title: "Need help with your sponsored benefit?",
    body: "If you have an employer-sponsored benefit that you're unable to access, please contact us.",
    action: {
      icon: "/assets/MessageIcon.svg",
      text: "Email us at ",
      alt: "Envelope",
    },
    cta: {
      text: "Go back",
    },
  },
};
