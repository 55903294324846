/** @format */

import type { Feature } from "./PlanComparisonPage";

interface AlphaPlusBenefitCardsProps {
  content: Feature[];
}

export const AlphaPlusBenefitCards = ({ content }: AlphaPlusBenefitCardsProps) => {
  return (
    <div className="sm:max-w-2xl mx-auto w-full px-6 pt-10 pb-10 bg-white">
      <div className="grid grid-cols-2 md:grid-cols-4 justify-center items-stretch gap-2 bg-white">
        {content.map((benefit) => {
          return <BenefitCard key={benefit.text} {...benefit} />;
        })}
      </div>
    </div>
  );
};

export const BenefitCard = (benefit: Feature) => {
  return (
    <div
      key={benefit.icon}
      className="flex flex-col items-center bg-cloud-light-40 rounded-lg pt-6 px-4 pb-4"
    >
      <img src={`/assets/${benefit.icon}`} alt={benefit.text} className="w-20 h-20" />
      <p className="text-sm font-medium text-center">{benefit.text}</p>
    </div>
  );
};
