/** @format */

import React from "react";

import axios from "axios";

import config from "src/config";
import { AXIOS_FETCH_PARAMS } from "src/helpers/http";

interface ImgifyProps {
  className: string;
  targetSelector?: string;
  imgContainerClass?: string;
  wrapperClass?: string;
  afterImgRender(): void;
  children?: any;
  forceDownload?: boolean;
  externalFile?: boolean;
}

interface ImgifyState {
  images: JSX.Element[];
}

const getProtectedFile = (url: string) => {
  return AXIOS_FETCH_PARAMS({ url, responseType: "blob" });
};
const externalDownload = async (_href: string) => {
  const response = await axios.get(_href, { responseType: "blob" });
  return response.data;
};

export class Imgify extends React.Component<ImgifyProps, ImgifyState> {
  selfRef: HTMLDivElement;

  public static defaultProps: Partial<ImgifyProps> = {
    targetSelector: "a",
    imgContainerClass: "",
    wrapperClass: "",
  };

  constructor(props: ImgifyProps, context: any) {
    super(props, context);
    this.selfRef = document.createElement("div");
    this.state = {
      images: [],
    };
  }

  /**
   * Render images if any after component renders.
   */
  componentDidMount() {
    const imgLinks = this.selfRef.querySelectorAll(`${this.props.targetSelector}`);
    if (imgLinks.length) {
      const hrefs = Array.from(imgLinks)
        // get href attribute
        .map((imgLink) => imgLink.getAttribute("href"))
        // leave img hrefs only
        .filter(
          (href: string | null) => href && (this.props.forceDownload || this.isValidImgUrl(href)),
        );

      const imgPromises: Promise<string | Blob>[] = [];
      hrefs.forEach((href: string | null) => {
        if (href) {
          let promise: Promise<string | Blob> = Promise.resolve(href);
          if (this.props.forceDownload || this.isProtectedImgUrl(href)) {
            if (!this.props.externalFile) {
              promise = getProtectedFile(href);
            } else {
              promise = externalDownload(href);
            }
          }
          imgPromises.push(promise);
        }
      });
      Promise.all(imgPromises).then((responses) => {
        const images: JSX.Element[] = [];
        responses.forEach((res: any, index: number) => {
          let url = res;
          if (res instanceof Blob) {
            // response is img blob, get internal link
            url = URL.createObjectURL(res);
          }
          images.push(
            <div key={index} className={this.props.imgContainerClass} style={{ padding: "0 5px" }}>
              {res.type === "application/pdf" ? (
                <div>
                  <div className="max-w-full hidden md:block mb-4" style={{ height: "400px" }}>
                    <iframe className="w-full h-full max-w-full" src={url} title="pdf" />
                  </div>
                  <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-describedby="new-window-0"
                    className="flex items-center"
                  >
                    <span>
                      <img className="h-12" alt="download an attached PDF" src="/assets/pdf.svg" />
                    </span>
                    <span>Click to view uploaded document</span>
                  </a>
                </div>
              ) : (
                <img
                  src={url}
                  className="w-full h-auto"
                  alt="a message with a file from your Alpha provider or the Alpha support team"
                />
              )}
            </div>,
          );
        });
        this.setState({ images });
      });
    }
  }

  componentDidUpdate() {
    // img render callback
    if (this.props.afterImgRender) {
      this.props.afterImgRender();
    }
  }

  isValidImgUrl(url: string) {
    return this.isProtectedImgUrl(url) || this.isImgUrl(url);
  }

  isProtectedImgUrl(url: string) {
    return url.includes(`${config.apiUrl}/files/`);
  }

  isImgUrl(url: string) {
    return url.match(/\.(jpeg|jpg|gif|png)$/) !== null;
  }

  render() {
    return (
      <div
        ref={(node: HTMLDivElement) => {
          this.selfRef = node;
        }}
        className={this.props.className}
      >
        {!this.state.images.length && this.props.children}

        {this.state.images.length !== 0 && (
          <div className={this.props.wrapperClass}>{this.state.images}</div>
        )}
      </div>
    );
  }
}
