/** @format */

import React from "react";
import { useToggle } from "react-use";

import classnames from "classnames";

import type { FetchProfileResponse } from "src/v2/models/api_types";

import { Loader } from "src/components/Loader";
import { useAuthenticatedUser } from "src/utils/hooks";
import { useStoreActions } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";

export const ProfileSwitcher: React.FC<{ bgClass?: string }> = ({ bgClass }) => {
  const actions = useStoreActions((action) => action);
  const currentUser = useCurrentUser();
  const [authenticatedUser] = useAuthenticatedUser();
  const children = authenticatedUser?.children || [];
  const combinedUsers: FetchProfileResponse[] = (
    authenticatedUser ? [authenticatedUser, ...children] : []
  ).filter((user) => currentUser.id !== user.id);
  const [show, toggle] = useToggle(false);

  const handleClick = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (!target.closest(".profile-switcher")) {
      toggle(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, []);

  if (!authenticatedUser) return <Loader show={true} />;
  if (children.length < 1) return null;

  return (
    <div className="profile-switcher flex flex-col items-center justify-center relative h-16">
      <div className="shadow-md bg-white w-full flex flex-col text-sky-dark absolute top-0 left-0">
        <div
          className={classnames(
            bgClass,
            "flex items-center px-5 py-3 hover:bg-grey-light border-b border-grey-light",
          )}
          onClick={() => toggle()}
        >
          <div className="w-8 mr-4">
            <img
              className="w-full"
              src="/assets/family-green.svg"
              alt="Toggle between different family members on your account"
            />
          </div>
          <div className="text-left">
            <div className="uppercase text-xs text-grey">Using Alpha As</div>
            <div className="text-base font-bold ">{currentUser.pii.full_name}</div>
          </div>

          <span
            className={classnames(
              "ml-auto fa",
              { "fa-angle-down": !show },
              { "fa-angle-up": show },
            )}
          />
        </div>
        <ul className={classnames("bottom-0 pl-0 w-full m-0", { hidden: !show })}>
          {combinedUsers.map((user, idx) => {
            const last = idx === combinedUsers.length - 1;
            return (
              <li key={user.id} className="bg-white flex flex-col">
                <div
                  className={classnames("p-4 hover:bg-grey-light text-sm", {
                    "border-b border-grey-light": !last,
                  })}
                  onClick={() => actions.profile.setCurrentProfileId({ userId: user.id })}
                >
                  <div className={"text-sm text-sky-dark"}>{user.pii.full_name}</div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
