/** @format */
import React from "react";

import { Typography } from "@alphamedical/components";

import InformationalCard from "src/v2/components/InformationalCard/InformationalCard";

interface HealthActionPlanHeaderSectionProps {
  planTitle: string;
  planDate: string;
  showWarning: boolean;
}

export const HealthActionPlanHeaderSection = ({
  planTitle,
  planDate,
  showWarning,
}: HealthActionPlanHeaderSectionProps) => {
  return (
    <div className="flex flex-col items-center bg-white p-6 shadow mb-4">
      <img
        src="/assets/care-plan-icon.svg"
        alt="health Action Plan icon"
        className="min-w-16 w-16 mt-2 h-auto"
      />
      <Typography variant="h2" className="mt-4">
        {planTitle}
      </Typography>
      <Typography variant="h5" className="mt-2 text-grey-160">
        Last updated: {planDate}
      </Typography>
      {showWarning && (
        <div className="mt-4">
          <InformationalCard
            type="Warning"
            title="Please seek immediate care"
            content="The blood pressure you provided indicates a hypertensive crisis. Please seek immediate in-person care."
          ></InformationalCard>
        </div>
      )}
    </div>
  );
};
