/** @format */

import React from "react";
import { Form } from "react-final-form";
import { Link } from "react-router-dom";

import type { AxiosError } from "axios";
import { FORM_ERROR } from "final-form";

import { TextFieldNew, Typography } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import type { ErrorResponse } from "src/v2/models/api_types";

import config from "src/config";
import { PasswordField } from "src/v2/components/PasswordField";
import { useStoreDispatch, useStoreState } from "src/v2/models";

interface FormValues {
  email: string;
  password: string;
}

export const Login = () => {
  const dispatch = useStoreDispatch();
  const unauthorizedUserRole = useStoreState((state) => state.auth.unauthorizedUserRole);
  const onSubmit = async (values: any) => {
    const { email, password } = values;
    return dispatch.auth
      .login({ email, password })
      .then(() => ({}))
      .catch((err: AxiosError<ErrorResponse>) => {
        return { [FORM_ERROR]: err.response?.data.description || "Login failed" };
      });
  };

  const validate = (values: Partial<FormValues>) => {
    const errors: Partial<FormValues> = {};
    if (!values.email) {
      errors.email = "Email address cannot be blank";
    } else if (!values.email.includes("@")) {
      errors.email = "A valid email address is required";
    }
    if (!values.password) {
      errors.password = "Password cannot be blank";
    }
    return errors;
  };
  return unauthorizedUserRole ? (
    <div className="max-w-2xl mx-auto mt-8">
      <h1 className="text-red text-2xl text-center">
        Sorry, you do not have authorization to access this page.
      </h1>
    </div>
  ) : (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ submitError, handleSubmit }) => (
        <form onSubmit={handleSubmit} className={`sm:w-3/4 md:w-1/2 w-full max-w-lg mx-auto`}>
          <div className="w-full mx-auto mt-12 mb-12 px-6">
            <div className="flex justify-center">
              <a href={config.wwwUrl}>
                <img
                  src="/assets/alpha-logo.svg"
                  alt="Go to Alpha Medical informational web site"
                  className="w-20 mb-4"
                />
              </a>
            </div>
            <div className="text-xl text-center mb-6">Log in to Your Account</div>
            <div>
              <TextFieldNew className="mb-4" name="email" label="Email address" />
              <PasswordField name={"password"} />
            </div>
            <div className="flex flex-col w-full mb-6 mt-4">
              <Link to="/forgot-password" className={`mb-4 no-underline`}>
                <Typography className="!text-cornflower-100">Forgot your password?</Typography>
              </Link>
              {submitError && <div className="text-sm text-red mb-4">{submitError}</div>}
              <ButtonPdb type={"submit"} className="btn btn-primary w-full my-4">
                Log in
              </ButtonPdb>
            </div>
            <div className="flex flex-col w-full items-center mt-4">
              <div className="text-black text-center">
                <Typography>
                  Don't Have an Account?{" "}
                  <a
                    className="cursor-pointer no-underline text-cornflower-100"
                    href="#"
                    onClick={(e: any) => {
                      e.preventDefault();
                      dispatch.auth.setShowLogin(false);
                    }}
                  >
                    Sign up
                  </a>
                </Typography>
                <div className="border-b border-primary-20 w-full my-4" />
                <Typography>Have a sponsored benefit?</Typography>
                <div>
                  <Link to="/sponsored-benefits" className="no-underline">
                    <Typography className="!text-cornflower-100">Start here</Typography>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
};
