/** @format */

import React from "react";
import { Field } from "react-final-form";

import { RadioButtonField, SimpleForm } from "@alphamedical/components";

import type { MessageAction, PubNubMessage } from "src/utils/hooks/pubnub";

import { Button } from "src/v2/components/Button";

import type { MessageActionCompletion } from "./MessageActions";

interface CSFormValues {
  score: string;
  careNeededReceived: string;
}

interface CustomerSatisfactionProps {
  action: MessageAction;
  clickHandler: (props: MessageActionCompletion) => Promise<{ [key: string]: any }>;
  buttonStyle: (secondary: boolean) => any;
  markMessageCompleted: (
    message: PubNubMessage,
    key: string,
    successCallback?: () => any,
    failureCallback?: () => any,
  ) => any;
  completed: boolean;
  message: PubNubMessage;
  isExpired: boolean;
  expirationText?: string;
  completionText?: string;
}

const CustomerSatisfaction = ({
  message,
  action,
  clickHandler,
  buttonStyle,
  markMessageCompleted,
  completed,
  isExpired,
  expirationText,
  completionText,
}: CustomerSatisfactionProps) => {
  const [isCompleted, setIsCompleted] = React.useState<boolean>(completed);
  const [error, setError] = React.useState<string>("");
  const [localCompleteText, setLocalCompleteText] = React.useState<string>();

  const customerSatisfactionSubmitHandler = (values: CSFormValues) => {
    return clickHandler({
      ...action,
      payload: {
        ...values,
        channelId: message.channel,
        actionItemId: message?.message?.customAttributes?.messageActions?.action_item_id,
      },
    })
      .then(() => {
        markMessageCompleted(message, action.key, () => setIsCompleted(true));
        setLocalCompleteText(action.completionText);
      })
      .catch((err) => {
        if (err.message == "Feedback already saved") {
          markMessageCompleted(message, action.key, () => setIsCompleted(true));
          setLocalCompleteText(action.completionText);
        } else {
          setError(err["message"]);
        }
      });
  };
  if (isCompleted) {
    return <p>{completionText || localCompleteText}</p>;
  } else if (isExpired) {
    return <p>{expirationText}</p>;
  } else {
    return (
      <div>
        <SimpleForm<CSFormValues> onSubmit={customerSatisfactionSubmitHandler} hideDebugInfo={true}>
          {({ submitting, invalid, values, form }) => {
            return (
              <>
                <div className="mb-4 text-sm">
                  How was the experience with your provider from your most recent visit?
                </div>
                <div className="flex-row flex mb-8">
                  {[1, 2, 3, 4, 5].map((rating) => {
                    const showFilled = rating <= parseInt(values.score);
                    return (
                      <div key={rating} className="flex-initial mr-2 cursor-pointer">
                        <img
                          src={`/assets/${showFilled ? "star-filled.svg" : "star-empty.svg"}`}
                          onClick={() => form.change("score", rating.toString())}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="mt-8 mb-4 text-sm">Did you receive the care you needed?</div>
                <RadioButtonField
                  fillColor="#6BA5A5"
                  validate={(value) => (value ? undefined : "Required")}
                  name="received_care_needed"
                  className="flex flex-row mb-12"
                  options={[
                    { value: "true", label: "Yes" },
                    { value: "false", label: "No" },
                  ]}
                />
                <div hidden={true}>
                  <Field
                    component="input"
                    name="score"
                    validate={(value) =>
                      parseInt(value) <= 5 && parseInt(value) > 0 ? undefined : "Required"
                    }
                  />
                </div>
                <Button
                  {...buttonStyle(action.secondary || false)}
                  key={action.key}
                  disabled={invalid || submitting}
                >
                  {action.text}
                </Button>
                {error && <p className="text-red">{error}</p>}
              </>
            );
          }}
        </SimpleForm>
      </div>
    );
  }
};

export const getComponentForMessageAction = (componentName: string, props: any) => {
  if (componentName == "customer_satisfaction") {
    return <CustomerSatisfaction {...props} />;
  }
  return <></>;
};
