/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActionItemsRequestMedicalRecordRequestBodySchema } from '../models/ActionItemsRequestMedicalRecordRequestBodySchema';
import type { ChannelOpenTaskSchema } from '../models/ChannelOpenTaskSchema';
import type { GenericActionItemSchema } from '../models/GenericActionItemSchema';
import type { ReturnConsultTaskSchema } from '../models/ReturnConsultTaskSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ActionItemsService {

    /**
     * @returns ChannelOpenTaskSchema
     * @throws ApiError
     */
    public static getChannelOpenTask({
        userId,
        channelId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        channelId: number,
    }): CancelablePromise<ChannelOpenTaskSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/channels/{channel_id}/channel-open-task',
            path: {
                'user_id': userId,
                'channel_id': channelId,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static requestMedicalRecord({
        userId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        requestBody: ActionItemsRequestMedicalRecordRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/{user_id}/medical_record',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static getUserAlerts({
        userId,
        alertType,
        desc,
        page = 1,
        pageSize = 10,
        sort = 'created_at',
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        /**
         * Filter by the alert_type column
         */
        alertType?: string,
        /**
         * Whether or not the results should be sorted in descending order or not
         */
        desc?: boolean,
        /**
         * Which page of results to return
         */
        page?: number,
        /**
         * How many results to return per page
         */
        pageSize?: number,
        /**
         * The attribute to sort by
         */
        sort?: string,
    }): CancelablePromise<{
        items: Array<GenericActionItemSchema>;
        page: number;
        per_page: number;
        total: number;
        total_pages: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/membership_alerts',
            path: {
                'user_id': userId,
            },
            query: {
                'alert_type': alertType,
                'desc': desc,
                'page': page,
                'page_size': pageSize,
                'sort': sort,
            },
        });
    }

    /**
     * All scheduled return visits for a patient
     * @returns ReturnConsultTaskSchema
     * @throws ApiError
     */
    public static fetchReturnVisits({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<Array<ReturnConsultTaskSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/return_visits',
            path: {
                'user_id': userId,
            },
        });
    }

}
