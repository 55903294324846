/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FormReponsesCollectionSchema } from '../models/FormReponsesCollectionSchema';
import type { OutlinesUpdateResponseCollectionRequestBodySchema } from '../models/OutlinesUpdateResponseCollectionRequestBodySchema';
import type { ProcessedScoreSchema } from '../models/ProcessedScoreSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FormOutlinesService {

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static sendOutline({
        outlineKey,
        override,
    }: {
        outlineKey: string,
        override?: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/outlines/{outline_key}',
            path: {
                'outline_key': outlineKey,
            },
            query: {
                'override': override,
            },
        });
    }

    /**
     * @returns FormReponsesCollectionSchema
     * @throws ApiError
     */
    public static getResponseCollection({
        userId,
        responseCollectionId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        responseCollectionId: number,
    }): CancelablePromise<FormReponsesCollectionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/outline_responses/{response_collection_id}',
            path: {
                'user_id': userId,
                'response_collection_id': responseCollectionId,
            },
        });
    }

    /**
     * Update the response collection based on the provided data.
     * This is mainly used to flag the response_collection as completed.
     *
     * Args:
     * user_id (int): The ID of the user.
     * response_collection_id (int): The ID of the response collection.
     *
     * Raises:
     * ApiError: If form is Registration and membership is required, but the user does not have an active membership.
     *
     * Returns:
     * dict: The updated response collection as a dictionary.
     * @returns FormReponsesCollectionSchema
     * @throws ApiError
     */
    public static updateResponseCollection({
        userId,
        responseCollectionId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        responseCollectionId: number,
        requestBody: OutlinesUpdateResponseCollectionRequestBodySchema,
    }): CancelablePromise<FormReponsesCollectionSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/{user_id}/outline_responses/{response_collection_id}',
            path: {
                'user_id': userId,
                'response_collection_id': responseCollectionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FormReponsesCollectionSchema
     * @throws ApiError
     */
    public static updateResponseCollectionValues({
        userId,
        responseCollectionId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        responseCollectionId: number,
    }): CancelablePromise<FormReponsesCollectionSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/{user_id}/outline_responses/{response_collection_id}/responses',
            path: {
                'user_id': userId,
                'response_collection_id': responseCollectionId,
            },
        });
    }

    /**
     * Retrieve scores for a specific outline response collection.
     * Args:
     * user_id (str): The ID of the user.
     * response_collection_id (int): The ID of the outline response collection.
     *
     * Returns:
     * dict: A JSON response containing the scores.
     * @returns ProcessedScoreSchema
     * @throws ApiError
     */
    public static getResponseCollectionScores({
        userId,
        responseCollectionId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        responseCollectionId: number,
    }): CancelablePromise<Array<ProcessedScoreSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/outline_responses/{response_collection_id}/scores',
            path: {
                'user_id': userId,
                'response_collection_id': responseCollectionId,
            },
        });
    }

}
