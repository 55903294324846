/** @format */

import type React from "react";

import config from "src/config";
import { MarkdownText } from "src/v2/components/MarkdownText";
import { useStoreActions, useStoreState } from "src/v2/models";

export const GenericBanner = (): React.ReactElement | null => {
  const dismissed = useStoreState((state) => state.maintenance.genericBannerDismissed);
  const setGenericBannerDismissed = useStoreActions(
    (actions) => actions.maintenance.setGenericBannerDismissed,
  );
  const message = config.genericBannerMessage;

  if (dismissed || !message) {
    // if we are dismissed or don't have a message to display, return null
    return null;
  }

  return (
    <div className="flex-row flex justify-between relative bg-forest-100 p-4 topbar-container_leftNav">
      <div className={"w-10"}>
        <img src={"/assets/information_filled_white.svg"} alt={"information icon"} />
      </div>
      <div className={"mx-2 w-full flex justify-center"}>
        <MarkdownText containerClassName="text-white text-sm text-center w-3/4" text={message} />
      </div>

      <div className={"w-10"}>
        <div onClick={() => setGenericBannerDismissed()} className={"p-2"}>
          <img src={"/assets/close-light.svg"} alt={"close icon"} />
        </div>
      </div>
    </div>
  );
};
