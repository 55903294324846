/** @format */

import React from "react";

import { useStoreDispatch } from "src/v2/models";

interface LogoutButtonProps {
  className?: string;
  tabIndex?: number;
}

export const LogoutButton = (props: LogoutButtonProps) => {
  const dispatch = useStoreDispatch();
  return (
    <button
      tabIndex={props.tabIndex}
      className={props.className}
      onClick={() => dispatch.auth.logout()}
    >
      Logout
    </button>
  );
};
