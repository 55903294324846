/** @format */

import type { FormRenderProps } from "react-final-form";

import React from "react";
import { useForm, useFormState } from "react-final-form";

import { CheckboxNewField } from "@alphamedical/components";

import type { Consultation } from "src/v2/types/consultation";

import { type BillingInfoSchema, ConsultsService, UsersService } from "src/api";
import { DynamicComponent } from "src/components/DynamicComponent";
import { Loader } from "src/components/Loader";
import { InformationalPopup } from "src/components/Popups/InformationalPopup";
import { useAuthenticatedUser } from "src/utils/hooks";
import { useFeatureFlags } from "src/utils/hooks/useFeatureFlags";
import { useHasEmployerBenefits } from "src/utils/hooks/useHasEmployerBenefits";
import { BillingInfoPopup } from "src/v2/components/Forms/BillingInfo/BillingInfoPopup";
import { MarkdownText } from "src/v2/components/MarkdownText";
import { telehealthConsentVersion } from "src/v2/constants";
import { useStoreActions, useStoreDispatch, useStoreState } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";
import { ConsultContext } from "src/v2/routes/Consultation";

import { PaymentFooter } from "./payment-footer";
import { feeInfoDetails, formatPrice } from "./utils";

import "./recurly.scss";

interface PaymentV2Props {
  formProps: FormRenderProps;
  handleSubmit: (values: any) => void;
  condition: string;
  hasFailedPayment: boolean;
}

export const consentToTelehealth = (className?: string, value?: string, error?: boolean) => (
  <CheckboxNewField
    className={className}
    required={true}
    label="consent"
    name="consents"
    value={value}
    options={[
      {
        label: (
          <MarkdownText
            containerClassName="leading-none"
            text="I agree to the [Consent to Telehealth](https://www.helloalpha.com/terms/consent)."
            linkStyle={{ color: `${error ? "#FD4F57" : "#6271C2"}` }}
          />
        ),
        value: "true",
      },
    ]}
  />
);

export const PaymentV2 = (props: PaymentV2Props) => {
  const consult = React.useContext(ConsultContext) as Consultation;
  const inputElement = React.useRef<HTMLButtonElement>(null);
  const [billing, setBilling] = React.useState<BillingInfoSchema>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [billingInfoOpen, setBillingInfoOpen] = React.useState<boolean>(false);
  const [informationalPopupOpen, setInformationalPopupOpen] = React.useState<boolean>(false);
  const recurlySubscriptions = useStoreState((state) => state.subscriptions.recurlySubscription);
  const checkboxRef = React.useRef<HTMLDivElement>(null);
  const [consentSignError, setConsentSignError] = React.useState<boolean>(false);
  const { values } = useFormState();
  const authenticatedUser = useAuthenticatedUser()[0];
  const currentUser = useCurrentUser();

  if (checkboxRef && props.formProps.errors?.consents) {
    checkboxRef.current?.scrollTo();
  }

  const mentalHealthSubscription = (recurlySubscriptions || []).find(
    (sub) =>
      sub.plan_code === "annual_mental_health_services_fee" ||
      sub.plan_code === "quarterly_mental_health_services_fee",
  );
  const dispatch = useStoreDispatch();

  const { couponDiscountAmount, dueToday } = useStoreState((state) => state.payments);
  const { checkCoupon } = useStoreActions((actions) => actions.payments);

  const toggleBillingModal = (toggle: boolean) => {
    setBillingInfoOpen(!toggle);
  };

  const resetCard = () => {
    setBilling(undefined);
  };

  const infoPopupDetails = feeInfoDetails(
    consult,
    props.condition,
    mentalHealthSubscription && mentalHealthSubscription.current_period_ends_at,
  );

  const preSubmitHandler = (consultId = "") => {
    setConsentSignError(false);
    if (values.consents == "true") {
      // If authenticatedUser is different from currentUser, this should be a ped consult,
      // so we must send both IDs, and the consent must be signed by the parent.
      const userId = authenticatedUser.id.toString();
      const childId =
        authenticatedUser.id !== currentUser.id ? currentUser.id.toString() : undefined;

      ConsultsService.signConsent({
        userId: userId.toString(),
        childId: childId !== undefined ? childId.toString() : childId,
        consultId,
        requestBody: { consent_forms: [telehealthConsentVersion] },
      })
        .then(() => {
          props.handleSubmit({ payment: "payment-info-submitted" });
        })
        .catch(() => {
          setConsentSignError(true);
        });
    } else {
      props.handleSubmit({ payment: "payment-info-submitted" });
    }
  };

  React.useEffect(() => {
    setLoading(true);
    dispatch.consults.fetchConsultFee(consult.id);
    dispatch.consults.fetchCredit();
    if (!recurlySubscriptions) {
      dispatch.subscriptions.fetchRecurlySubscriptions("mental-health");
    }
    const initializePayment = async () => {
      try {
        setBilling(await UsersService.userBillingInfo());
      } catch (e) {
        console.log("no billing...rendering payment form");
      }

      checkCoupon({ conditionKey: props.condition, setErrorIfFailed: false });
      setLoading(false);
    };
    initializePayment();
  }, []);

  const dueNow = dueToday - couponDiscountAmount;

  const isSonjaCaresPatient = useHasEmployerBenefits("sonja-cares");
  const canSkipBillingInfo = isSonjaCaresPatient && dueNow == 0;

  const isMonthlyMembershipEnabled = useFeatureFlags("enableMonthlyMembership");
  const { addSnack } = useStoreActions((actions) => actions.snacks);
  const form = useForm();
  const fieldState = form.getFieldState("consents");
  const { touched, submitError, error, dirty } = fieldState || {};
  const errorState = (error || submitError) && (dirty || touched);

  return (
    <Loader message="Getting your payment ready" show={loading}>
      <div className="w-full" data-track-content data-content-name="Review & Pay">
        {(props.hasFailedPayment && (
          <div className="p-5">
            <div className="text-3xl lg:text-3xl text-red">Unable to process payment</div>
            <div className="text-lg my-4">
              Please make sure the payment method we have on file is correct. If it is correct and
              this problem persists, contact your bank for further assistance.
            </div>
            <div>
              Don't worry, we're still here to help! In the meantime, you can:
              <ul className="ml-10">
                <li className="">Update your existing card information OR add a new card</li>
                <li className="">Call your card provider to resolve the issue and retry payment</li>
              </ul>
            </div>
          </div>
        )) || (
          <DynamicComponent
            content={{ "#ref": { type: "component", name: "TreatmentPaymentBanner" } }}
          />
        )}
        <div className="py-6">
          <DynamicComponent
            content={{ "#ref": { type: "component", name: "TreatmentPaymentLineItems" } }}
          />
        </div>
        {!canSkipBillingInfo && (
          <>
            <p className="font-bold tracking-wide">PAYMENT</p>
            <div className="border-b-2 border-sand mt-2 mb-4" />
          </>
        )}
        {!billing && !canSkipBillingInfo && (
          <div className="mb-8">
            <div
              onClick={() => setBillingInfoOpen(!billingInfoOpen)}
              className="flex cursor-pointer"
            >
              <img
                src="/assets/add.svg"
                alt="Open form for entering your billing information"
                className="w-5"
              />
              <p className="ml-3 font-semibold">Add your payment method</p>
            </div>
            <div className="hidden sm:block">
              <PaymentFooter refillable={consult.subscription.condition.refillable} />
            </div>
            {billingInfoOpen && (
              <BillingInfoPopup
                isOpen={billingInfoOpen}
                onBillingInfoUpdated={(_info: any) => {
                  setBilling(_info);
                  setBillingInfoOpen(false);
                }}
                onBillingInfoFailure={(error_message = "") => {
                  addSnack({
                    type: "error",
                    message:
                      error_message ||
                      "Something went wrong. Please try again or add a new payment method. ",
                    id: "billing-info-failure",
                    delay: 5,
                  });
                }}
                onRequestClose={() => setBillingInfoOpen(false)}
              />
            )}
            <div
              className="fixed left-0 right-0 bottom-0 flex flex-col items-center justify-center sm:hidden bg-white px-4"
              style={{
                maxWidth: "inherit",
                width: "inherit",
                minHeight: "87px",
                boxShadow: "0px 100px 11px 96px rgba(0,0,0,0.25)",
              }}
            >
              <PaymentFooter
                refillable={consult.subscription.condition.refillable}
                cxMargin="mt-4"
              />
              <div className="flex flex-col justify-center items-center w-full my-4">
                <button
                  onClick={() => setBillingInfoOpen(!billingInfoOpen)}
                  className="btn btn-violator w-full"
                >
                  Add Payment Method
                </button>
              </div>
            </div>
            <button
              onClick={() => setBillingInfoOpen(!billingInfoOpen)}
              className="btn btn-violator w-full hidden sm:block"
            >
              Add Payment Method
            </button>
          </div>
        )}
        <div>
          {billing && (
            <div className="mt-6 mb-8 flex items-center">
              <div className="text-14 flex-1">{billing?.card_type}</div>
              <div className="text-14 mr-4 sm:mr-8">
                <div>
                  <span className="display-none sm:display-inline">**** ****</span> ****{" "}
                  {billing?.last_four}
                </div>
              </div>
              <div className="text-14 mr-4 sm:mr-8">
                <div>
                  {billing?.month} / {billing?.year}
                </div>
              </div>
              <div
                className="text-16 cursor-pointer"
                onClick={() => {
                  toggleBillingModal(billingInfoOpen);
                  resetCard();
                }}
              >
                <img
                  src="/assets/cancel.svg"
                  alt="Close form for entering your billing information"
                />
              </div>
            </div>
          )}
          {(billing || canSkipBillingInfo) && (
            <>
              {consentSignError && (
                <p className="hidden sm:block mx-auto text-error">Failed to sign consent forms</p>
              )}
              {isMonthlyMembershipEnabled && (
                <>
                  <div className="border-b-2 border-grey-3 mb-4" />
                  <div className="flex flex-row items-center justify-items-auto">
                    <div className="mx-auto flex-initial">
                      {consentToTelehealth(
                        "hidden sm:block max-w-xl",
                        values.consents,
                        !!errorState,
                      )}
                    </div>
                  </div>
                </>
              )}
              <div className="hidden sm:block">
                <PaymentFooter refillable={consult.subscription.condition.refillable} />
              </div>

              {(props.hasFailedPayment && (
                <div className="flex flex-col sm:flex-row">
                  <button
                    className="btn btn-cloud sm:w-1/2 sm:mr-3 my-3"
                    onClick={() => preSubmitHandler(consult.id.toString())}
                    ref={inputElement}
                  >
                    Retry Payment
                  </button>
                  <button className="btn btn-primary sm:w-1/2 sm:ml-3 my-3" onClick={resetCard}>
                    Update Payment Information
                  </button>
                </div>
              )) || (
                <>
                  <div
                    className="fixed left-0 right-0 bottom-0 flex flex-col items-center justify-center sm:hidden bg-white px-6"
                    style={{
                      maxWidth: "inherit",
                      width: "inherit",
                      minHeight: "87px",
                      boxShadow: "0px 100px 11px 96px rgba(0,0,0,0.25)",
                    }}
                  >
                    <PaymentFooter
                      refillable={consult.subscription.condition.refillable}
                      cxMargin="mt-4"
                    />
                    {consentSignError && (
                      <p className="sm:hidden text-error">Failed to sign consent forms</p>
                    )}
                    {isMonthlyMembershipEnabled &&
                      consentToTelehealth("sm:hidden", values.consents, !!errorState)}
                    <div className="flex flex-col justify-center items-center w-full mb-4 mt-2">
                      <button
                        className="btn btn-violator w-full"
                        onClick={() => preSubmitHandler(consult.id.toString())}
                        ref={inputElement}
                      >
                        Pay {dueNow === 0 ? "$0.00" : formatPrice(dueNow)} Now
                      </button>
                    </div>
                  </div>

                  <button
                    className="btn btn-violator w-full hidden sm:inline-block"
                    onClick={() => preSubmitHandler(consult.id.toString())}
                    ref={inputElement}
                  >
                    {dueNow === 0 && "Approve & Continue"}
                    {dueNow !== 0 && `Pay ${formatPrice(dueNow)} Now`}
                  </button>
                </>
              )}
            </>
          )}
        </div>
        <div className="mb-48 sm:mb-8 sm:mt-2">
          <DynamicComponent content={{ "#ref": { type: "component", name: "HIPAABadges" } }} />
        </div>
        {informationalPopupOpen && (
          <InformationalPopup
            isOpen={informationalPopupOpen}
            onRequestClose={setInformationalPopupOpen}
            title={infoPopupDetails.title}
            text={infoPopupDetails.text}
            btnStyle="primary"
            btnText="Back to Payment"
            boxBgColor={{ backgroundColor: "#F7F7F7" }}
          />
        )}
      </div>
    </Loader>
  );
};
