/** @format */

import React from "react";

import * as Sentry from "@sentry/react";

import ErrorPage from "./ErrorPage";

interface ErrorBoundaryProps {
  children: any;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any, info: any) {
    // Display fallback UI
    Sentry.captureException(error);
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // tslint:disable-next-line
    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorPage />;
    }
    return this.props.children;
  }
}
