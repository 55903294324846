/** @format */

import React from "react";
import { useHistory } from "react-router-dom";

import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";

import { Typography } from "@alphamedical/components";
import { BadgePdb } from "src/v2/designSystem";

interface FeatureCardProps {
  feature: string;
  url: string;
  status_badge?: string;
  iconSrc?: string;
  iconAlt?: string;
  locked?: boolean;
}
const defaultIconSrc = "/assets/cross.svg";
export const EngagementFeatureCard = ({
  feature,
  status_badge,
  url,
  iconSrc = defaultIconSrc,
  iconAlt = "",
  locked = false,
}: FeatureCardProps) => {
  const history = useHistory();

  if (iconSrc == defaultIconSrc && iconAlt == "") {
    iconAlt = "Icon of a health cross inside a circular border";
  }
  const onClickHandler = () => {
    const externalURL = ["http", "https"].some((scheme) => url.startsWith(scheme));
    if (externalURL) {
      window.open(url);
    } else {
      history.push(url);
    }
  };
  return (
    <div
      className="w-1/3 lg:w-1/6 relative"
      role="button"
      onClick={() => {
        onClickHandler();
      }}
    >
      {locked && (
        <FontAwesomeIcon style={{ top: 8, right: 8 }} className="absolute z-10" icon={faLock} />
      )}
      <div
        className={cn("p-6 mb-4 shadow rounded relative", {
          "bg-grey-10": !locked,
          "bg-gradient-to-t from-grey-100 to-bg-grey-10": locked,
        })}
      >
        {status_badge && (
          <BadgePdb
            variant="notification"
            label={status_badge}
            size="small"
            addClasses="absolute left-0 -top-2"
          />
        )}
        <img className="mx-auto" src={iconSrc} alt={iconAlt} />
      </div>
      <Typography className="mt-4 text-center text-grey-160" variant="h5">
        {feature}
      </Typography>
    </div>
  );
};
