/** @format */

import React from "react";

import moment from "moment";

import {
  FieldWrapper,
  FormChips,
  SimpleForm,
  StepSlider,
  Typography,
} from "@alphamedical/components";
import { Datepicker, DropdownSelect } from "src/v2/designSystem";

import type { SymptomTrackingSchema, TagsSchema } from "src/api";

import { Analytics } from "src/analytics";
import { TagsService } from "src/api";
import { BottomSheet } from "src/components/BottomSheet/BottomSheet";
import {
  DATE_TAKEN_FORMAT,
  TIME_TAKEN_OPTIONS,
} from "src/routes/Records/WeightLossJournal/constants";
import { now } from "src/utils/date_utils";
import { useStoreDispatch } from "src/v2/models";

interface SymptomFormProps {
  entry?: SymptomTrackingSchema;
  onFinish: () => void;
  onCancel: () => void;
  onClose: () => void;
}

type SymptomLevel = "none" | "mild" | "moderate" | "severe";

interface SymptomTrackingValues {
  symptom: string;
  date_taken: string;
  time_taken: "morning" | "afternoon" | "evening" | "night";
  symptom_level: SymptomLevel;
}

const symptomLevels: {
  key: SymptomLevel;
  label: string;
}[] = [
  {
    key: "none",
    label: "None",
  },
  {
    key: "mild",
    label: "Mild",
  },
  {
    key: "moderate",
    label: "Moderate",
  },
  {
    key: "severe",
    label: "Severe",
  },
];
export const SymptomForm = (props: SymptomFormProps) => {
  const dispatch = useStoreDispatch();

  const isEdit = !!props.entry;
  const [symptoms, setSymptoms] = React.useState<TagsSchema[]>([]);
  const [stage, setStage] = React.useState<"symptom_data" | "symptom_levels">("symptom_data");

  const initialValues: SymptomTrackingValues = props.entry
    ? {
        symptom: props.entry.symptom,
        date_taken: moment(props.entry.date_taken, DATE_TAKEN_FORMAT).format(DATE_TAKEN_FORMAT),
        time_taken: props.entry.time_taken,
        symptom_level: props.entry.symptom_level,
      }
    : {
        symptom: "",
        date_taken: now("MM-dd-yyyy"),
        time_taken: undefined,
        symptom_level: "none",
      };

  const onSubmit = async (values: SymptomTrackingValues) => {
    const action = !isEdit ? "added" : "edited";
    try {
      const requestBody = {
        symptom: values.symptom,
        date_taken: values.date_taken,
        time_taken: values.time_taken,
        symptom_level: values.symptom_level,
      };
      if (!isEdit) {
        await dispatch.medicationTracking.createSymptomTracking(requestBody);
      } else {
        if (props.entry) {
          await dispatch.medicationTracking.updateSymptomTracking({
            symptomTrackingId: props.entry.id,
            requestBody,
          });
        }
      }
      Analytics.trackEvent({ category: "WeightLossJournal", action: "AddSymptomEntry" });
      dispatch.snacks.addSnack({
        type: "primary",
        id: "add-medication-journal-symptom-entry",
        message: `Symptom ${action}.`,
        delay: 10,
      });
    } catch (e) {
      dispatch.snacks.addSnack({
        type: "error",
        id: "add-medication-journal-symptom-entry",
        message: `Sorry, your symptom could not be ${action}`,
        delay: 10,
      });
    } finally {
      props.onFinish();
    }
  };

  const handleDelete = async () => {
    if (isEdit && props.entry) {
      try {
        await dispatch.medicationTracking.deleteSymptomTracking(props.entry.id);
        Analytics.trackEvent({ category: "WeightLossJournal", action: "DeleteSymptomEntry" });
        dispatch.snacks.addSnack({
          type: "primary",
          id: "delete-medication-journal-symptom-entry",
          message: `Symptom deleted.`,
          delay: 10,
        });
      } catch (e) {
        dispatch.snacks.addSnack({
          type: "error",
          id: "delete-medication-journal-entry-error",
          message: `Sorry, your symptom could not be deleted.`,
          delay: 10,
        });
      } finally {
        props.onFinish();
      }
    }
  };

  React.useEffect(() => {
    // Fetch symptoms
    TagsService.getTagList({ tagType: "symptom_tracking" }).then((tags) => {
      setSymptoms(tags);
    });
  }, []);

  const isSymptomDataStage = stage === "symptom_data";
  return (
    <>
      <BottomSheet.Header
        title={isSymptomDataStage ? `${!isEdit ? "Add" : "Edit"} symptom` : "Symptom levels"}
        onClick={props.onClose}
      />
      <SimpleForm<SymptomTrackingValues>
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ values, handleSubmit, invalid, form, errors }) => {
          const saveText = !isEdit ? "Add" : "Save";
          return (
            <>
              <div className="space-y-4">
                {isSymptomDataStage ? (
                  <>
                    <div className="space-y-2">
                      <Typography variant="body2">Symptom*</Typography>
                      <FieldWrapper
                        required
                        name="symptom"
                        render={() => (
                          <FormChips
                            selected={values.symptom}
                            filterOptions={symptoms.map((value) => ({
                              label: value.label,
                              key: value.label,
                            }))}
                            onOptionChange={(newValue) => form.change("symptom", newValue)}
                          />
                        )}
                      />
                    </div>
                    <Datepicker
                      required
                      name="date_taken"
                      label="Date"
                      maxDate={new Date().toString()}
                    />
                    <DropdownSelect
                      required
                      label="Time of day"
                      name="time_taken"
                      options={TIME_TAKEN_OPTIONS}
                    />
                  </>
                ) : (
                  <div>
                    <FieldWrapper
                      required
                      name="symptom_level"
                      render={() => (
                        <div>
                          <Typography className="mb-6" variant="h4">
                            {values.symptom}
                          </Typography>
                          <StepSlider
                            value={values.symptom_level}
                            onChange={(value) =>
                              form.change("symptom_level", value as SymptomLevel)
                            }
                            options={symptomLevels}
                          />
                        </div>
                      )}
                    />
                  </div>
                )}
              </div>
              <BottomSheet.Actions
                showDelete={isEdit && isSymptomDataStage}
                showBack
                onBack={() => (isSymptomDataStage ? props.onCancel() : setStage("symptom_data"))}
                buttonText={isSymptomDataStage ? "Next" : saveText}
                onConfirm={() => (isSymptomDataStage ? setStage("symptom_levels") : handleSubmit())}
                disabled={
                  isSymptomDataStage
                    ? errors?.symptom || errors?.time_taken || errors?.date_taken
                    : invalid
                }
                onDelete={handleDelete}
              />
            </>
          );
        }}
      />
    </>
  );
};
