/** @format */

import React from "react";
import { useField } from "react-final-form";

import { Popup, useMultiPageForm } from "@alphamedical/components";

import { useAudio } from "src/components/DynamicForm/hooks";
import { Loader } from "src/components/Loader";
import { VimeoPlayer } from "src/components/VimeoPlayer";
import welcome from "src/content/welcome";
import { useHasEmployerBenefits } from "src/utils/hooks/useHasEmployerBenefits";

const VIDEO_SCRIPT = welcome.screen4.videoTranscript;

interface WelcomeVideoHealthCheckInProps {
  questionKey: string;
}

export const WelcomeVideoHealthCheckIn = ({ questionKey }: WelcomeVideoHealthCheckInProps) => {
  const { nextPage } = useMultiPageForm();
  const [openModal, setModalOpen] = React.useState(false);
  const [mode, setMode] = React.useState<"video" | "audio" | "text">("video");
  const { toggle, playing } = useAudio(welcome.screen4.audioUrl);
  const field = useField(questionKey);
  const hasWesternDentalBenefits = useHasEmployerBenefits("western-dental");
  const nextButtonText = hasWesternDentalBenefits
    ? welcome.screen4.b2bSubmitButton
    : welcome.screen4.submitButton;

  const options: { title: string; value: "video" | "audio" | "text"; icon: string }[] = [
    { title: welcome.screen4.readText, value: "text", icon: "readIcon" },
    { title: welcome.screen4.playVideo, value: "video", icon: "play" },
    { title: welcome.screen4.playAudio, value: "audio", icon: "speaker" },
  ];

  const textOptions = {
    video: welcome.screen4.switchFromVideo,
    audio: welcome.screen4.switchFromAudio,
    text: welcome.screen4.switchFromText,
  };

  React.useEffect(() => {
    field.input.onChange("true");
  }, []);

  return (
    <div className={"flex flex-col justify-between"} style={{ height: "80vh" }}>
      <div>
        <h1 className={"font-semibold text-xl mb-8 text-center"}>{welcome.screen4.title}</h1>
        <Popup
          headerText={"Other options"}
          isOpen={openModal}
          onRequestClose={() => setModalOpen(false)}
        >
          <div className={"py-3 px-6"}>
            {options
              .filter((option) => option.value !== mode)
              .map((option, index) => (
                <button
                  key={option.value}
                  className={`flex font-semibold text-sm py-6 w-full ${
                    index !== options.length - 2 && "border-b border-grey-3"
                  }`}
                  onClick={() => {
                    if (playing) {
                      toggle();
                    }
                    setModalOpen(false);
                    setMode(option.value);
                  }}
                >
                  <img
                    className={"mr-5"}
                    src={`/assets/${option.icon}.svg`}
                    alt={`${option.value} icon`}
                  />
                  {option.title}
                </button>
              ))}
          </div>
        </Popup>
        {mode === "video" && (
          <div className="relative mb-4">
            <div className="absolute inset-0 bg-white">
              <Loader show={true}>{null}</Loader>
            </div>
            <VimeoPlayer autoPlay={false} videoId={welcome.screen4.videoId} />
          </div>
        )}
        {mode === "text" && (
          <div
            className={"bg-grey-lightest p-5 mb-4 overflow-y-scroll"}
            style={{ maxHeight: "35vh" }}
          >
            {VIDEO_SCRIPT.map((paragraph, index) => {
              return (
                <p key={"par-" + index} className={"pb-4 text-sm"} style={{ fontWeight: 500 }}>
                  {paragraph}
                </p>
              );
            })}
          </div>
        )}
        {mode === "audio" && (
          <div
            className={"bg-grey-lightest mb-4 py-6 px-4 flex items-center justify-between"}
            onClick={() => toggle()}
            role="button"
          >
            <div className={"flex items-center"}>
              <div>
                {!playing && <img src="/assets/playNoBackground.svg" alt="play button" />}
                {playing && <img src="/assets/pauseGreen.svg" alt="pause button" />}
              </div>
              <div className={"ml-6"}>
                <img src="/assets/soundPlaying.svg" alt="sound wave" />
              </div>
            </div>
            <div>
              <img src="/assets/speaker.svg" alt="speaker icon" />
            </div>
          </div>
        )}
        <div className={"flex justify-end"}>
          <button
            onClick={(e) => {
              e.preventDefault();
              setModalOpen(true);
            }}
            className={"text-sky-dark text-sm font-bold"}
          >
            {textOptions[mode]}
          </button>
        </div>
      </div>
      <div>
        <div className={"bg-sand-40 p-5 flex mb-10"}>
          <img
            className={"flex self-start mt-1"}
            src="/assets/alpha_provider.svg"
            alt="alpha provider icon"
          />
          <p className={"text-sm ml-4"} style={{ fontWeight: 500 }}>
            {welcome.screen4.footerNote}
          </p>
        </div>

        <button
          onClick={() => {
            // setting the welcome video value to true so this video doesn't show again
            nextPage();
          }}
          className={`btn btn-primary w-full mb-5`}
        >
          {nextButtonText}
        </button>
      </div>
    </div>
  );
};
