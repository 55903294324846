/** @format */

import * as Sentry from "@sentry/react";
import type { Action, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";

import type { CheckInDataSchema } from "src/api";

import { UsersService } from "src/api";

export const CHECKIN_OUTLINE_KEYS = {
  ANXIETY_CHECK_IN: "outline-anxiety-check-in",
  COMPREHENSIVE_HEALTH_CHECK_IN: "outline-hci",
  REPRODUCTIVE_HEALTH_CHECK_IN: "outline-reproductive-health-check-in",
};

type CheckInState = Record<string, CheckInDataSchema | null>;

type SetCheckInPayload = { key: string; data: CheckInDataSchema | null };

export interface CheckInsModel {
  checkinList: CheckInState;
  isLoadingCheckIn: boolean;
  setCheckIn: Action<CheckInsModel, SetCheckInPayload>;
  startCheckIn: Thunk<
    CheckInsModel,
    { userId: number | "me"; checkinKey: string; bypassRepeatableBypass?: boolean }
  >;
  fetchCheckIn: Thunk<CheckInsModel, { userId: number | "me"; checkinKey: string }>;
  setIsLoadingCheckIn: Action<CheckInsModel, boolean>;
}

export const CheckInsStore: CheckInsModel = {
  checkinList: {},
  isLoadingCheckIn: false,

  setCheckIn: action((state, { key, data }) => {
    state.checkinList[key] = data;
  }),

  startCheckIn: thunk((actions, payload): Promise<CheckInDataSchema | void> => {
    const { userId, checkinKey, bypassRepeatableBypass = false } = payload;
    return UsersService.startPatientCheckin({
      userId,
      requestBody: { checkin_key: checkinKey, bypass_repeatable_check: bypassRepeatableBypass },
    })
      .then((res) => {
        actions.setCheckIn({ key: checkinKey, data: res });
        return res;
      })
      .catch((err) => {
        Sentry.captureException(err);
        actions.setCheckIn({ key: checkinKey, data: null });
        return undefined;
      });
  }),

  fetchCheckIn: thunk((actions, payload) => {
    const { userId, checkinKey } = payload;
    actions.setIsLoadingCheckIn(true);
    return UsersService.getPatientCheckinData({ userId, checkinKey })
      .then((res) => {
        actions.setCheckIn({ key: checkinKey, data: res });
        return res;
      })
      .catch((err) => {
        Sentry.captureException(err);
        actions.setCheckIn({ key: checkinKey, data: null });
        return undefined;
      })
      .finally(() => {
        actions.setIsLoadingCheckIn(false);
      });
  }),

  setIsLoadingCheckIn: action((state, isLoading) => {
    state.isLoadingCheckIn = isLoading;
  }),
};
