/** @format */

import type { RouteComponentProps } from "react-router-dom";

import React from "react";
import { withRouter } from "react-router-dom";

import type { FollowupItem } from "src/components/Popups/types";

import { AlphaModal } from "src/v2/components/AlphaModal";
import { Button } from "src/v2/components/Button";
import { formatPrice } from "src/v2/components/Payment/utils";
import { useStoreDispatch } from "src/v2/models";

export interface NonFormularyApprovalPopupProps extends RouteComponentProps {
  item: FollowupItem;
  showPopup: boolean;
  setShowPopup: (arg: boolean) => void;
  key?: number;
  pii?: any;
  profile?: any;
}

const NonFormularyApprovalPopup = ({
  item: {
    followup_type,
    medication_name,
    order_id,
    medication_cost,
    condition_name,
    condition_key,
    days_supply,
    is_one_time,
  },
  showPopup,
  setShowPopup,
  pii,
  profile,
  history,
}: NonFormularyApprovalPopupProps) => {
  const [showConfirm, setShowConfirm] = React.useState<boolean>(false);
  const dispatch = useStoreDispatch();
  const makeSelection = (selection: string) => {
    dispatch.profile.sendOrderApproval({
      approved: selection === "cash",
      selection,
      order_id,
      medication_name,
      followup_type,
      condition_name,
      days_supply,
      medication_cost,
    });
    selection === "cash" && setShowPopup(false);
  };

  const monthsSupply = days_supply && Math.round(days_supply / 30);
  const formattedCost =
    condition_key === "skincare" || is_one_time
      ? medication_cost && formatPrice(medication_cost)
      : medication_cost &&
        `${formatPrice(medication_cost)} ${
          monthsSupply && monthsSupply > 1 ? `every ${monthsSupply} months` : "per month"
        }`;

  return (
    <div>
      <AlphaModal
        isOpen={showPopup && !profile.hasSeenPopup}
        className="max-w-sm m-auto bg-white shadow-md"
        addPaddingForNavBar={true}
        cxPadding="p-8"
        onRequestClose={() => {
          !showConfirm && setShowPopup(false);
        }}
      >
        {!showConfirm && (
          <div className="flex flex-col text-16">
            <p className="font-700">
              Hi {(pii && pii.preferred_firstname) || (pii && pii.first_name)},
            </p>
            <p className="mb-4">
              Alpha Care Team here!
              <br /> Unfortunately, it looks like the medication you were prescribed,{" "}
              {medication_name}, is not covered by your insurance as it is not part of your plan's
              formulary (medication list).
            </p>
            <p className="mb-4">
              If you would like, you can proceed as a cash patient. As a cash patient,{" "}
              {medication_name} is {formattedCost}. Shipping is always free.
            </p>
            <p className="mb-4">
              Alternatively, you may call your insurance using the member service number listed on
              the back of your insurance card and ask what alternative medications your insurance
              covers. Please let us know once you have this information so we can process this
              change on your behalf.
            </p>
            <p className="mb-4">
              We can also attempt to file a prior authorization with your insurance company. Please
              be aware this can take 15-25 business days to reach a conclusion. We will process your
              order once the prior authorization is approved. If it is denied, you will need to
              contact your insurance to see what other medications are covered and share this list
              with your Alpha provider.
            </p>
            <p className="mb-4">
              Please let us know how you would like to proceed. If you have any questions please
              don’t hesitate to reach out.
            </p>

            <p className="mb-6">
              Sincerely,
              <br />
              The Alpha Care Team
            </p>
            <div className="flex justify-content flex-col">
              <Button
                extraClassNames="btn btn-violator cursor-pointer"
                disabled={profile.postingApproval}
                onClick={() => makeSelection("cash")}
              >
                Pay Cash
              </Button>
              <Button
                extraClassNames={`
                                    text-center
                                    border-2 border-primary
                                    hover:text-white
                                    items-center cursor-pointer`}
                disabled={profile.postingApproval}
                bgColor={"white"}
                hoverBgColor={"primary-80"}
                textColor={"primary"}
                onClick={() => {
                  makeSelection("file_prior_authorization");
                  setShowConfirm(true);
                }}
              >
                File a Prior Authorization
              </Button>
              <Button
                extraClassNames={`
                                    text-center
                                    border-2 border-primary
                                    hover:text-white
                                    items-center cursor-pointer`}
                disabled={profile.postingApproval}
                bgColor={"white"}
                hoverBgColor={"primary-80"}
                textColor={"primary"}
                onClick={() => {
                  makeSelection("check_with_insurance");
                  setShowConfirm(true);
                }}
              >
                Check with My Insurance
              </Button>
            </div>
            {profile.error && <p className="text-red">{profile.error}</p>}
          </div>
        )}
        {showConfirm && (
          <div className="bg-white m-auto">
            <p className="text-18 text-center">
              We just sent you a message confirming your choice.
            </p>
            <p className="text-16 text-center">
              <strong>
                Please note: <br />
              </strong>{" "}
              It may take up to 30 seconds for the message to arrive.
            </p>

            <div className="flex flex-col text-16">
              <Button
                extraClassNames=""
                disabled={profile.postingApproval}
                onClick={() => {
                  setShowPopup(false);
                  setShowConfirm(false);
                  history.push("/messages");
                  dispatch.profile.fetchProfile({ include: ["followup_items", "pii"] });
                }}
              >
                Take me to my messages
              </Button>
              {profile.error && <p className="text-red">{profile.error}</p>}
            </div>
          </div>
        )}
      </AlphaModal>
    </div>
  );
};

export default withRouter(NonFormularyApprovalPopup);
