/** @format */

import React from "react";

import { Paper } from "src/v2/designSystem";

import { PageWrapper } from "src/components/PageWrapper";
import SettingsLink from "src/components/Settings/SettingsLink";
import {
  PERSONAL_INFORMATION_CONTACT,
  PERSONAL_INFORMATION_EMERGENCY_CONTACT,
  PERSONAL_INFORMATION_PHOTO_ID,
  PERSONAL_INFORMATION_PRONOUNS,
} from "src/routes";
import { useChildRedirect } from "src/utils/hooks";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";

const PersonalInformation = () => {
  useChildRedirect();
  useTopBar({ title: "My information", variant: "nested", showBackButton: true });
  const links = [
    {
      title: "Contact information",
      url: PERSONAL_INFORMATION_CONTACT,
    },
    {
      title: "Emergency contact",
      url: PERSONAL_INFORMATION_EMERGENCY_CONTACT,
    },
    {
      title: "Photo ID",
      url: PERSONAL_INFORMATION_PHOTO_ID,
    },
    {
      title: "Pronouns",
      url: PERSONAL_INFORMATION_PRONOUNS,
    },
  ];
  return (
    <PageWrapper cxPadding="px-0">
      <Paper
        elevation={0}
        rounded={false}
        className="mx-auto w-full max-w-2xl px-6 overflow-hidden"
      >
        <div className="pb-4" />
        {links.map((link) => (
          <SettingsLink arrowPrimary={true} key={link.title} url={link.url} title={link.title} />
        ))}
      </Paper>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(PersonalInformation);
