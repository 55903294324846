/** @format */
import React from "react";

import cn from "classnames";
import { formatInTimeZone } from "date-fns-tz";
import moment from "moment";

import { Typography } from "@alphamedical/components";

import { serializeDependency } from "src/utils";

interface SchedulingProps {
  // Times available in UTC
  timesAvailable: Array<string>;
  timezone: string;
  selectedTime?: string;
  // TODO This could be inferred from timesAvailable
  daysToShow?: number;
  onDayChanged?: (value: string) => void;
  onTimeSelected: (value: string) => void;
}

const Scheduling = ({
  timesAvailable,
  onTimeSelected,
  onDayChanged,
  timezone,
  selectedTime,
  daysToShow = 5,
}: SchedulingProps) => {
  const currentDay = moment()
    .utc()
    .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
    .format();

  // default to today until timesAvailable
  const [currentSchedulingDate, setCurrentSchedulingDate] = React.useState<string>(
    selectedTime || currentDay,
  );
  const [timesAvailableOnDay, setTimesAvailableOnDay] = React.useState<Array<string>>([]);

  const formatDay = (utcString: string) => formatInTimeZone(utcString, timezone, "eeee, MMMM do");

  const getNearestDate = () => {
    if (!timesAvailable || timesAvailable.length === 0) {
      return currentDay;
    }

    // Get the closest day
    const lowestDiff = Math.min(
      ...timesAvailable.map((time) => moment(time).diff(moment(currentDay), "days")),
    );
    return moment()
      .add(lowestDiff, "day")
      .utc()
      .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
      .format();
  };

  React.useEffect(() => {
    setTimesAvailableOnDay(
      timesAvailable.filter((availableTime) =>
        moment(availableTime).isSame(currentSchedulingDate, "day"),
      ),
    );
  }, [currentSchedulingDate, serializeDependency(timesAvailable)]);

  React.useEffect(() => {
    if (!selectedTime) {
      setCurrentSchedulingDate(getNearestDate() || currentDay);
    }
  }, [serializeDependency(timesAvailable)]);

  const moveDay = (type: "add" | "subtract") => {
    const newDate = moment(currentSchedulingDate)[type](1, "day").utc().format();
    setCurrentSchedulingDate(newDate);
    onDayChanged && onDayChanged(newDate);
  };

  const prevDay = () => moveDay("subtract");
  const nextDay = () => moveDay("add");

  const dayLimit = daysToShow - 1;
  return (
    <div className="pt-4 max-w-2xl mx-auto">
      <div className="items-center flex justify-between">
        <Typography variant="h3">{formatDay(currentSchedulingDate)}</Typography>
        <div className="flex flex-row mt-3">
          <button
            type="button"
            disabled={moment(currentSchedulingDate).diff(moment(currentDay), "days") <= 0}
            className="flex items-center rounded-lg hover:bg-grey-9 active:bg-grey-11 focus:outline-none"
            onClick={prevDay}
          >
            <span className="fa-solid fa-arrow-left text-grey-160 p-2" />
          </button>
          <button
            type="button"
            className="flex items-center rounded-lg hover:bg-grey-9 active:bg-grey-11 focus:outline-none"
            disabled={moment(currentSchedulingDate).diff(moment(currentDay), "days") === dayLimit}
            onClick={nextDay}
          >
            <span className="fa-solid fa-arrow-right text-grey-160 p-2" />
          </button>
        </div>
      </div>
      <div className="p-2 grid grid-cols-3 gap-x-2 gap-y-3">
        {timesAvailableOnDay.length ? (
          timesAvailableOnDay.sort().map((time) => (
            <button
              type="button"
              onClick={() => onTimeSelected(time)}
              className={cn(
                "focus:outline-none rounded-lg text-xl p-2 w-fit",
                time === selectedTime ? "bg-sand-60" : "bg-grey-10",
              )}
              key={time}
            >
              <Typography className={"text-forest-100"} variant="hyperlink">
                {formatInTimeZone(time, timezone, "p")}
              </Typography>
            </button>
          ))
        ) : (
          <div className="text-grey-160 bg-grey-10 w-full text-center col-span-3 p-6">
            No appointments available
          </div>
        )}
      </div>
    </div>
  );
};

export default Scheduling;
