/** @format */

import type React from "react";

import Modal from "react-modal";

import classNames from "classnames";

import { CloseIcon } from "src/v2/components/Svgs";

import "./fullPageModal.scss";

/**
 * Full page modal that slides in from the right.
 * In desktop, it only takes up a small part of the screen on the right side.
 *
 * Example
 *
 * const [open, setOpen] = React.useState(true);
 *
 *      <FullPageModal
 *         title={"About Alpha Basic Plan"}
 *         isOpen={open}
 *         setIsOpen={setOpen}
 *       >
 *         <div id="modal-content" className={"p-6 flex flex-col h-full"}>
 *           <p>
 *             Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aperiam aut commodi dolore doloremque error
 *             fugiat ipsum laboriosam, magni officia officiis placeat porro quod rem, sapiente sequi sit ut veritatis.
 *           </p>
 *           <button>Close modal</button>
 *         </div>
 *       </FullPageModal>
 *
 * Make sure to add id='modal-content' to the appropriate element. Header is already taken care
 * of in this component if you don't change the aria props
 *
 * @constructor
 */
export const FullPageModal: React.FC<FullPageModalProps> = ({
  children,
  isOpen,
  setIsOpen,
  title,
  aria = {
    labelledby: "modal-header",
    describedby: "modal-content",
  },
  isScrollable,
}) => {
  return (
    <Modal
      style={{ content: { outlineStyle: "none" } }}
      isOpen={isOpen}
      overlayClassName={"alpha-modal-overlay-new"}
      className={classNames(
        "flex-1 bg-white h-full max-w-sm xs:max-w-md sm:max-w-lg md:max-w-xl absolute right-0",
        {
          "overflow-y-auto no-scrollbar": isScrollable,
        },
      )}
      aria={aria}
      closeTimeoutMS={500}
    >
      <div
        className={
          "w-full pl-6 pr-4 border-b border-grey-2 flex items-center justify-between cursor-pointer"
        }
      >
        <h1 id={"modal-header"} className={"text-forest-darker py-3 "} style={{ fontWeight: 500 }}>
          {title}
        </h1>
        <button onClick={() => setIsOpen(false)} className="p-2">
          <CloseIcon width={16} fill="#1C4A46" />
        </button>
      </div>
      {children}
    </Modal>
  );
};

interface FullPageModalProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  title: string;
  aria?: { [ariaLabel: string]: string }; // {describedby: "full_description} Do not prefix aria
  isScrollable?: boolean;
}
