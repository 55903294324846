/** @format */
import React from "react";

import { Typography } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import { AlphaModal } from "src/v2/components/AlphaModal";

interface InstructionsComponentProps {
  linkText: string;
  title: string;
  steps: string[];
  alternativeStep?: string;
  image?: any;
}

/**
 * Displays link text. When clicked opens modals and displays a list of steps to complete.
 */
const InstructionsComponent = ({
  linkText,
  steps,
  title,
  alternativeStep,
  image,
}: InstructionsComponentProps) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Typography variant="hyperlink" className="cursor-pointer mb-4">
        <p onClick={() => setOpen(!open)}>{linkText}</p>
      </Typography>
      <AlphaModal
        shouldCloseOnOverlayClick={true}
        className="max-w-md mx-auto text-center shadow-lg bg-white"
        closeIconColor="primary-40"
        isOpen={open}
        cxPadding={"0"}
        closeIconSize={"lg"}
      >
        <div className="px-4 pt-4">
          <div className="flex justify-between align-middle items-center w-full">
            <Typography variant="subtitle1">{title}</Typography>
            <div
              className={`fas fa-times cursor-pointer text-grey-160 text-2xl`}
              onClick={() => setOpen(false)}
            />
          </div>
          <div className="overflow-y-auto mb-4 text-left">
            <Typography variant="body1">
              <ul className="pl-4">
                {steps.map((text: string, i: number) => {
                  return (
                    <li className="list-decimal" key={"step-" + i}>
                      {text}
                    </li>
                  );
                })}
              </ul>
              {alternativeStep}
            </Typography>
          </div>
          {image && (
            <div className="flex flex-1 justify-center mb-4">
              <img src={image.file.url} alt={image.description} className="rounded-2xl w-full" />
            </div>
          )}
          <div className="pb-5 bg-grey-10">
            <ButtonPdb onClick={() => setOpen(false)} className="w-full">
              Go Back
            </ButtonPdb>
          </div>
        </div>
      </AlphaModal>
    </>
  );
};

export default InstructionsComponent;
