/** @format */
import React from "react";

import { A11y, Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import type { ContentfulCarouselCardSchema } from "src/api";
import type { CustomFieldCondition } from "src/components/DynamicForm/types";

import { ContentfulService } from "src/api/services/ContentfulService";
import { useMobileResponsive } from "src/components/DynamicForm/hooks";
import { hideIfActiveBenefits } from "src/components/DynamicForm/Processors/fieldConditionals";
import HomeCard from "src/v2/components/Carousel/HomeCard";
import { useStoreState } from "src/v2/models";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const Carousel = () => {
  const [cardContent, setCardContent] = React.useState<ContentfulCarouselCardSchema[]>([]);
  const prevRef = React.useRef(null);
  const nextRef = React.useRef(null);
  const dimensions = useMobileResponsive();
  const { profile } = useStoreState((state) => state.profile);
  const [isLastSlide, setIsLastSlide] = React.useState<boolean>(false);
  const [isFirstSlide, setIsFirstSlide] = React.useState<boolean>(true);
  const showSingleCard = dimensions.width < 920;

  const checkCardConditionals = (cards: ContentfulCarouselCardSchema[]) => {
    return cards.filter((card: ContentfulCarouselCardSchema) => {
      if (card.conditionals && card.conditionals.length > 0) {
        return card.conditionals.every((conditional: any) => {
          return processCardConditionals(conditional);
        });
      }
      return true;
    });
  };

  const processCardConditionals = (conditional: any) => {
    const fields = conditional.fields as CustomFieldCondition;
    let mustShow = true;
    if (fields.method === "hide:if:active:benefits") {
      mustShow = !hideIfActiveBenefits(fields, profile);
    }

    return mustShow;
  };

  React.useEffect(() => {
    if (profile?.id) {
      ContentfulService.getCarouselCards({ userId: profile.id.toString() }).then((res): void => {
        setCardContent(res);
      });
    }
  }, [profile?.id]);

  const filteredCards = checkCardConditionals(cardContent);

  return (
    <div className="relative pb-4">
      <img
        hidden={isFirstSlide || filteredCards.length <= 1}
        ref={prevRef}
        src="/assets/carousel-button.svg"
        alt="Carousel Button"
        className="w-8 h-8 my-auto absolute transform rotate-180 z-10 focus:outline-none cursor-pointer"
        style={{
          left: "10px",
          top: "25%",
          filter:
            "drop-shadow(0 -10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 -4px 3px rgb(0 0 0 / 0.1))",
        }}
      />
      <Swiper
        navigation={{ prevEl: prevRef.current, nextEl: nextRef.current }}
        modules={[A11y, Navigation, Pagination, Autoplay]}
        spaceBetween={20}
        slidesPerView={showSingleCard ? 1 : 2}
        pagination={{ clickable: true }}
        autoHeight={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
        }}
        wrapperClass="mb-8"
        centeredSlides={showSingleCard}
        style={{ "--swiper-pagination-color": "#1C4A46" } as React.CSSProperties}
        onSlideChange={(swiper: any) => {
          swiper.isEnd ? setIsLastSlide(true) : setIsLastSlide(false);
          swiper.isBeginning ? setIsFirstSlide(true) : setIsFirstSlide(false);
        }}
      >
        {filteredCards.map((card, index) => {
          return (
            <SwiperSlide key={index}>
              <HomeCard
                img={card.image}
                title={card.title}
                description={card.description}
                ctaText={card.cta_text}
                ctaLink={card.cta_link}
                showNewPill={card.show_new_pill}
                showPreferredTag={card.show_preferred_partner_tag}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>

      <img
        hidden={isLastSlide || filteredCards.length <= 1}
        ref={nextRef}
        className="w-8 h-8 absolute z-10 focus:outline-none cursor-pointer"
        src="/assets/carousel-button.svg"
        alt="Carousel Button"
        style={{
          right: "10px",
          top: "25%",
          filter:
            "drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1))",
        }}
      />
    </div>
  );
};

export default Carousel;
