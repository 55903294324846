/** @format */

import React, { useEffect, useState } from "react";

import cn from "classnames";

import { Popup, SimpleForm, TextFieldNew, Typography } from "@alphamedical/components";
import { Accordion, ButtonPdb } from "src/v2/designSystem";

import type { Phi } from "src/api";

import { PatientActionItemsService, UsersService } from "src/api";
import { parseBloodPressure } from "src/components/DynamicForm/Processors/fieldParsers";
import {
  extractBloodPressureParts,
  isValidBloodPressureFormat,
} from "src/components/DynamicForm/Processors/fieldValidators";
import { Loader } from "src/components/Loader";
import { PageWrapper } from "src/components/PageWrapper";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { ADD_BLOOD_PRESSURE_ACTION_TYPE } from "src/v2/components/PatientActionItems/actions";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useCurrentUser } from "src/v2/models/profile";

interface AddBloodPressureValues {
  bloodPressure: string;
}

export const AddBloodPressure = () => {
  useTopBar({ variant: "nested", title: "Action item", showBackButton: true });
  const currentUser = useCurrentUser();
  const [userPhi, setUserPhi] = useState<Phi>();
  const [showUpdate, setShowUpdate] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    UsersService.getProfile({ userId: currentUser.id, include: "phi" }).then((profile) =>
      setUserPhi(profile.phi),
    );
  }, [currentUser.id]);

  const onSubmit = (values: AddBloodPressureValues) =>
    PatientActionItemsService.completePatientActionItems({
      userId: currentUser.id,
      requestBody: {
        blood_pressure: values.bloodPressure,
        action_type: ADD_BLOOD_PRESSURE_ACTION_TYPE,
      },
    }).then(() => history.back());

  const isUpdate = userPhi && userPhi.systolic_blood_pressure && userPhi.diastolic_blood_pressure;
  const showCurrent = !showUpdate && isUpdate;

  const handleSubmit = (values: AddBloodPressureValues) => {
    const { systolic, diastolic } = extractBloodPressureParts(values.bloodPressure);

    const isHighPressure = (systolic && systolic > 180) || (diastolic && diastolic > 120);
    if (isHighPressure && !showWarning) {
      setShowWarning(true);
    } else {
      onSubmit(values);
    }
  };

  return (
    <PageWrapper cxPadding="p-6">
      <Loader show={!userPhi}>
        <div className="pt-4">
          {showCurrent && userPhi ? (
            <>
              <Typography className="mb-6" variant="h2">
                Your blood pressure
              </Typography>
              <div className="mb-10">
                <Typography variant="body2" className="mb-1">
                  Systolic (top)/Diastolic (bottom)
                </Typography>
                <Typography variant="h3">
                  {userPhi.systolic_blood_pressure}/{userPhi.diastolic_blood_pressure}
                </Typography>
              </div>
              <ButtonPdb
                className="w-full"
                type="button"
                variant="secondary"
                onClick={() => setShowUpdate(true)}
              >
                Update
              </ButtonPdb>
            </>
          ) : (
            <>
              <Typography variant="h2" className={cn({ "mb-5": isUpdate })}>
                What is your most recent blood pressure reading?
              </Typography>
              {!isUpdate && (
                <Accordion
                  variant="helper"
                  expandMultiple={false}
                  sections={[
                    {
                      id: 0,
                      title: "How to measure your blood pressure",
                      content: (
                        <div className="pb-2">
                          Get a blood pressure reading for free at your local pharmacy, or purchase
                          a home blood pressure monitor.
                        </div>
                      ),
                    },
                  ]}
                />
              )}
              <SimpleForm<AddBloodPressureValues> onSubmit={handleSubmit} hideDebugInfo>
                {({ form }) => (
                  <>
                    <div className="pb-12">
                      <TextFieldNew
                        required
                        validate={(value) => value && isValidBloodPressureFormat(value)}
                        parse={parseBloodPressure}
                        name="bloodPressure"
                        label="Systolic (top)/Diastolic (bottom)"
                        placeholder="Enter blood pressure"
                      />
                    </div>
                    <ButtonPdb disabled={form.getState().invalid} className="w-full">
                      Submit
                    </ButtonPdb>
                    <Popup isOpen={showWarning} headerText="Are you sure?">
                      <div>
                        <div className="mb-2 p-6">
                          The blood pressure reading you provided{" "}
                          <strong>{form.getState().values.bloodPressure}</strong> indicates a
                          hypertensive crisis. If this reading is correct, please proceed with the
                          submission. If you’ve made an error, please edit your blood pressure
                          reading.
                        </div>
                        <div className="flex flex-col bg-grey-lightest p-4 justify-end">
                          <ButtonPdb className="w-full mb-2" onClick={() => form.submit()}>
                            Submit
                          </ButtonPdb>
                          <ButtonPdb
                            type="button"
                            variant="secondary"
                            className="w-full"
                            onClick={() => setShowWarning(false)}
                          >
                            Edit reading
                          </ButtonPdb>
                        </div>
                      </div>
                    </Popup>
                  </>
                )}
              </SimpleForm>
            </>
          )}
        </div>
      </Loader>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(AddBloodPressure);
