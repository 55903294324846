/** @format */

import type { match } from "react-router-dom";

import React from "react";
import { Link } from "react-router-dom";

import { Paper } from "src/v2/designSystem";

import type { Record as RecordModel } from "src/v2/models/record";

import { BackButtonHeading } from "src/components/_buttons/BackButtonHeading";
import ImageHeading from "src/components/ImageHeading";
import { Loader } from "src/components/Loader";
import { PageWrapper } from "src/components/PageWrapper";
import config from "src/config";
import { useTopBar } from "src/utils/hooks/useTopBar";
import Record from "src/v2/components/Record/Record";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useStoreDispatch, useStoreState } from "src/v2/models";

interface MatchParams {
  type?: "visits" | "assessments";
}

const DoYouNeed = () => (
  <ImageHeading
    extraClassName={" mt-8 mb-8"}
    titleFontSize={"text-xl"}
    title={`Do you need treatment for a mental health condition?`}
    imageSrc={"/assets/mental-health-head.svg"}
    ctaLink={`${config.wwwUrl}/mental-health`}
    ctaText={"Learn more"}
    bgColor={"cloud-light-60"}
    btnStyle={"mt-6 btn btn-violator mx-auto w-full"}
    externalLink={true}
  />
);

const MentalHealthRecord = ({ match }: { match: match<MatchParams> }) => {
  useTopBar({ title: "Mental Health Records", variant: "nested", showBackButton: true });
  const dispatch = useStoreDispatch();
  const [loading, setLoading] = React.useState(true);
  const matchType = match.params.type;
  const consultScores = useStoreState((state) => state.records.consultScores) as RecordModel[];
  const assessmentScores = useStoreState(
    (state) => state.records.assessmentScores,
  ) as RecordModel[];
  const merged = [...assessmentScores, ...consultScores];
  React.useEffect(() => {
    dispatch.records.fetchMentalHealthRecords().then(() => setLoading(false));
    dispatch.records.fetchAssessmentScores().then(() => setLoading(false));
  }, []);

  return (
    <PageWrapper cxPadding="px-0" fitToPageHeight>
      <Paper elevation={0} rounded={false} className="mx-auto h-screen w-full max-w-2xl px-6 mt-6">
        {matchType ? (
          <Loader show={loading}>
            <BackButtonHeading
              title={
                matchType === "visits"
                  ? "Your Mental Health Service Scores"
                  : "Your Free Assessment Scores"
              }
            />

            {matchType === "visits" &&
              consultScores.length > 0 &&
              consultScores.map((record) => <Record key={record.assessment_type} {...record} />)}

            {matchType === "assessments" &&
              assessmentScores.length > 0 &&
              assessmentScores.map((record) => <Record key={record.assessment_type} {...record} />)}
          </Loader>
        ) : (
          <>
            {merged.length === 0 && (
              <>
                <p className="text-center">
                  You have no mental health records to be displayed at this time.
                </p>
                <DoYouNeed />
              </>
            )}
            {consultScores.length > 0 && (
              <Link
                to={`/mental-health-records/visits`}
                className={`flex pb-6 border-grey-light border-b mb-6`}
              >
                <p className="text-primary text-base w-full">Your Mental Health Service Scores</p>
                <img
                  src={"/assets/carrot-right.svg"}
                  alt="View your mental health service scores"
                />
              </Link>
            )}
            {assessmentScores.length > 0 && (
              <Link
                to={`/mental-health-records/assessments`}
                className={`flex pb-6 border-grey-light border-b mb-6`}
              >
                <p className="text-primary text-base w-full">Your Free Assessment Scores</p>
                <img
                  src={"/assets/carrot-right.svg"}
                  alt="View your free mental health assessment scores"
                />
              </Link>
            )}

            {assessmentScores.length > 0 && consultScores.length < 1 && <DoYouNeed />}
          </>
        )}
      </Paper>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(MentalHealthRecord);
