/** @format */

import React from "react";

import * as Sentry from "@sentry/react";
import { find, isEmpty, omit } from "lodash";

import { Paper } from "src/v2/designSystem";

import type { InsuranceFormValues } from "src/v2/components/Forms/Insurance/InsuranceForm";

import { OrdersService, UsersService } from "src/api";
import { PageWrapper } from "src/components/PageWrapper";
import { useGetInsurance } from "src/utils/hooks";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { AlphaModal } from "src/v2/components/AlphaModal";
import { Button, secondaryProps } from "src/v2/components/Button";
import InsuranceForm from "src/v2/components/Forms/Insurance/InsuranceForm";
import { isPrimaryCardholder, RELATIONSHIP_OPTIONS } from "src/v2/components/Forms/Insurance/utils";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useStoreActions, useStoreDispatch } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";

export const InsuranceInformation = () => {
  useTopBar({ title: "Insurance card", variant: "nested", showBackButton: true });
  const insurance = useGetInsurance() || {};
  const patient = useCurrentUser();
  const dispatch = useStoreDispatch();
  const [showConfirm, setShowConfirm] = React.useState<boolean>(false);
  const [formValues, setFormValues] = React.useState<InsuranceFormValues>(insurance);
  const addSnack = useStoreActions((actions) => actions.snacks.addSnack);

  React.useEffect(() => {
    if (!isEmpty(insurance)) {
      setFormValues(insurance);
    }
  }, [insurance.cardholder_id]);

  const renderInsuranceValues = (data: InsuranceFormValues) => (
    <>
      <li>
        <strong>Carrier</strong> {data.carrier == "other" ? data.carrier_other : data.carrier}
      </li>
      <li>
        <strong>Member ID:</strong> {data.cardholder_id}
      </li>
      <li>
        <strong>RX Bin:</strong> {data.rx_bin}
      </li>
      <li>
        <strong>RX PCN:</strong> {data.rx_pcn}
      </li>
      {data.group_number && (
        <li>
          <strong>RX Group / Group Number:</strong> {data.group_number}
        </li>
      )}
      <li>
        <strong>Is this a Medicare, Medicaid, or other state- or federally-funded plan?: </strong>
        {data.is_federally_funded_plan ? "Yes" : "No"}
      </li>
      {!isPrimaryCardholder(data) && (
        <>
          <li>
            <strong>Primary Cardholder:</strong> {data.cardholder_first_name}{" "}
            {data.cardholder_last_name}
          </li>
          <li>
            <strong>Relationship to Primary Cardholder:</strong>{" "}
            {find(RELATIONSHIP_OPTIONS, (option) => option.value === data.relationship_code)
              ?.label || "N/A"}
          </li>
        </>
      )}
    </>
  );

  const handleSubmit = () => {
    UsersService.updateInsurancePhoto({
      userId: patient.id,
      requestBody: formValues,
    })
      .then(async () => {
        addSnack({
          type: "success",
          id: "saving-insurance-success",
          message: `Insurance data saved successfully.`,
          delay: 5,
        });
        await dispatch.profile.fetchProfile({ include: ["insurance"], userId: patient.id });
        setShowConfirm(false);
        OrdersService.resubmitInsuranceOrders({ userId: patient.id });
      })
      .catch((error) => {
        Sentry.captureException(error);
        addSnack({
          type: "error",
          message: "Something went wrong. Try again or contact support.",
          id: "saving-insurance-error",
          delay: 5,
        });
      });
  };

  return (
    <PageWrapper cxPadding="px-0">
      <Paper elevation={0} rounded={false} className="mx-auto w-full max-w-2xl px-6">
        <InsuranceForm
          onSubmit={(values) => {
            setFormValues(omit(values, ["history"]));
            setShowConfirm(true);
          }}
          initialValues={{ ...insurance, ...formValues }}
        />
        {showConfirm && (
          <AlphaModal
            isOpen={true}
            className="max-w-sm m-auto bg-white shadow-md"
            onRequestClose={() => setShowConfirm(false)}
          >
            <h3 className="text-2xl font-medium">Confirm</h3>
            <div className="my-4">
              Please confirm your insurance details before submitting to avoid delays in your
              upcoming medication orders.
            </div>
            <div>
              <ul>{renderInsuranceValues(formValues)}</ul>
            </div>
            <div className="my-4">
              By selecting “Submit” you are agreeing to apply your updated insurance information to
              all current and future medication subscriptions.
            </div>
            <div className="flex justify-between">
              <Button onClick={() => setShowConfirm(false)} {...secondaryProps}>
                Cancel
              </Button>
              <Button extraClassNames="ml-8" onClick={handleSubmit}>
                Confirm
              </Button>
            </div>
          </AlphaModal>
        )}
      </Paper>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(InsuranceInformation);
