/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MembershipDetailsSchema } from '../models/MembershipDetailsSchema';
import type { OrganizationsActivateBenefitsRequestBodySchema } from '../models/OrganizationsActivateBenefitsRequestBodySchema';
import type { OrganizationsLandingPageEligibilityScreeningRequestBodySchema } from '../models/OrganizationsLandingPageEligibilityScreeningRequestBodySchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrganizationsService {

    /**
     * Endpoint for performing eligibility screening from the landing page. Currently exclusively used for the LHE flow.
     * Args:
     * org_id (int): The ID of the organization.
     *
     * Request Body:
     * eligibility_screening_values (dict): The values for the eligibility screening.
     *
     * Returns:
     * If the user is eligible, it returns a JSON response with status code 200.
     * If the user is not eligible or an error occurs, it raises an ApiError with the appropriate status code and description.
     * @returns any No response format defined
     * @throws ApiError
     */
    public static landingPageEligibilityScreening({
        orgId,
        requestBody,
    }: {
        orgId: number,
        requestBody: OrganizationsLandingPageEligibilityScreeningRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/organizations/eligibility-screening/{org_id}',
            path: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static organizationEligibilityLookup({
        lookupType,
        address1,
        address2,
        birthDate,
        city,
        isDependent,
        phoneNumber,
        state,
        zipcode,
    }: {
        lookupType: 'phone_number' | 'address',
        address1?: string,
        address2?: string,
        birthDate?: string,
        city?: string,
        isDependent?: boolean,
        phoneNumber?: string,
        state?: string,
        zipcode?: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations/eligibility/lookup',
            query: {
                'address_1': address1,
                'address_2': address2,
                'birth_date': birthDate,
                'city': city,
                'is_dependent': isDependent,
                'lookup_type': lookupType,
                'phone_number': phoneNumber,
                'state': state,
                'zipcode': zipcode,
            },
        });
    }

    /**
     * Retrieve an organization by employer slug.
     * Parameters:
     * employer: The slug of the employer.
     *
     * Returns:
     * The organization details if found, or an error message.
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getOrganizationByEmployer({
        employer,
    }: {
        /**
         * The slug of the employer
         */
        employer: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations/employer/{employer}',
            path: {
                'employer': employer,
            },
        });
    }

    /**
     * Endpoint to check the availability of signup for an organization.
     * Args:
     * org_id (int): The ID of the organization.
     *
     * Returns:
     * A response indicating whether signup is available or not.
     *
     * Raises:
     * ApiError: If the organization ID is not found.
     * @returns any No response format defined
     * @throws ApiError
     */
    public static signupAvailabilityCheck({
        orgId,
    }: {
        orgId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations/signup-availability-check/{org_id}',
            path: {
                'org_id': orgId,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static activateBenefits({
        requestBody,
    }: {
        requestBody: OrganizationsActivateBenefitsRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/organziations/active-benefits',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns MembershipDetailsSchema
     * @throws ApiError
     */
    public static getMembershipDetails({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<MembershipDetailsSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{user_id}/membership/details',
            path: {
                'user_id': userId,
            },
        });
    }

}
