/** @format */

import React from "react";
import { useHistory, useParams } from "react-router-dom";

import type { UnstructuredContentSchema } from "src/api/models/UnstructuredContentSchema";

import { ContentfulService } from "src/api/services/ContentfulService";
import { Loader } from "src/components/Loader";
import { NotFound404 } from "src/components/NotFound404";
import { useABTest } from "src/utils/hooks/useABTest";
import { FUNNELS } from "src/v2/constants";
import { useStoreDispatch } from "src/v2/models";

import { LandingPageVariant } from "./LandingPageVariant";
import { LoaderPageVariant } from "./LoaderPageVariant";
import { QuizPageVariant } from "./QuizPageVariant";

export type FunnelPageContent = {
  key: string;
  type: "landing" | "quiz" | "loader";
  image: string;
  imageAlt?: string;
  title?: string;
  /** Subtitle is an array of strings to allow for different styling */
  subtitle?: string[];
  primaryCta: string;
  secondaryCta?: string;
  mainText?: string;
  quote?: string;
  captionText?: string;
  bullets?: string[];
  mainTextStyles?: string;
};

type FunnelContent = {
  pages: FunnelPageContent[];
};

const variants = {
  A: {
    buttonVariant: "primary",
    loaderColor: "bg-forest-100",
    loaderText: "text-information",
    loaderCheckbox: "text-forest-100",
    progressBarColor: "bg-forest-100",
  },
  B: {
    buttonVariant: "quaternary",
    loaderColor: "bg-alert",
    loaderText: "text-alert",
    loaderCheckbox: "text-alert",
    progressBarColor: "bg-alert",
  },
};

const processContent = (data: UnstructuredContentSchema): FunnelContent => {
  const { assets, content } = data;

  const assetMap: { [key: string]: string } = {};
  assets.forEach((asset) => {
    if (asset.title && asset.file) assetMap[asset.title] = asset.file.url;
  });

  const processedPages = content.pages.map((page: FunnelPageContent) => {
    const imageUrl = assetMap[page.image];
    return {
      ...page,
      image: imageUrl || page.image,
    };
  });

  return {
    ...content,
    pages: processedPages,
  };
};

const WL_PLAN_FUNNEL_CONTENT_KEY = "weight-loss-plan-funnel-content";

export const SignUpFunnel = () => {
  const funnelKey = FUNNELS.WEIGHT_LOSS;
  const { pageKey } = useParams<{ pageKey: string }>();
  const history = useHistory();
  const [pageContent, setPageContent] = React.useState<FunnelContent | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState(true);
  const [currentPageIndex, setCurrentPageIndex] = React.useState<number>(0);
  const [currentPage, setCurrentPage] = React.useState<FunnelPageContent | undefined>(undefined);
  const dispatch = useStoreDispatch();
  const variant = useABTest("weight-loss-funnel");

  React.useEffect(() => {
    const fetchContentAsync = async () => {
      const data: UnstructuredContentSchema = await ContentfulService.getUnstructuredContentByKey({
        key: WL_PLAN_FUNNEL_CONTENT_KEY,
      });
      if (data?.content?.pages) {
        const processedContent = processContent(data);
        setPageContent(processedContent);

        // Check if key exists and find the corresponding page
        const foundPageIndex = processedContent.pages.findIndex((page) => page.key === pageKey);
        if (pageKey && foundPageIndex !== -1) {
          const foundPage = processedContent.pages[foundPageIndex];
          history.push(`/${funnelKey}/${foundPage.key}`);
          setCurrentPage(foundPage);
          setCurrentPageIndex(foundPageIndex);
        } else if (processedContent.pages.length > 0) {
          // Default to the first page if key doesn't match any existing page
          history.push(`/${funnelKey}/${processedContent.pages[0].key}`);
          setCurrentPage(processedContent.pages[0]);
          setCurrentPageIndex(0);
        }
        setIsLoading(false);
      } else {
        setCurrentPage(undefined);
        setIsLoading(false);
      }
    };
    fetchContentAsync();
  }, [funnelKey]);

  React.useEffect(() => {
    const handlePopstate = () => {
      const currentPageKey = history.location.pathname.split("/").pop();
      const foundPageIndex = pageContent?.pages.findIndex((page) => page.key === currentPageKey);

      if (foundPageIndex !== -1 && foundPageIndex !== undefined && pageContent) {
        setCurrentPage(pageContent.pages[foundPageIndex]);
        setCurrentPageIndex(foundPageIndex);
      } else {
        history.replace(funnelKey);
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [history, pageContent]);

  const moveToPage = (pageIndex: number) => {
    if (pageContent && pageIndex >= pageContent.pages.length) {
      dispatch.auth.setShowLogin(false);
      history.push({ pathname: "/my-cases", state: { funnel: funnelKey } });
    }

    if (pageContent && pageIndex >= 0 && pageIndex < pageContent.pages.length) {
      history.push(`/${funnelKey}/${pageContent.pages[pageIndex].key}`);
      setCurrentPageIndex(pageIndex);
      setCurrentPage(pageContent.pages[pageIndex]);
    }
  };

  const moveToNextPage = () => {
    const nextPageIndex = currentPageIndex + 1;
    moveToPage(nextPageIndex);
  };

  const moveToPreviousPage = () => {
    const previousPageIndex = currentPageIndex - 1;
    moveToPage(previousPageIndex);
  };

  const calculateProgress = () => {
    return pageContent && pageContent.pages.length > 0
      ? [(currentPageIndex * 100) / pageContent.pages.length]
      : [];
  };

  const variantStyles = variants[variant];

  const renderPageVariant = (_currentPage: FunnelPageContent) => {
    switch (_currentPage.type) {
      case "landing":
        return (
          <LandingPageVariant
            currentPage={_currentPage}
            onClickCallback={moveToNextPage}
            styles={variantStyles}
          />
        );
      case "quiz":
        return (
          <QuizPageVariant
            currentPage={_currentPage}
            onBackCallback={moveToPreviousPage}
            onClickCallback={moveToNextPage}
            progress={calculateProgress()}
            styles={variantStyles}
          />
        );
      case "loader":
        return (
          <LoaderPageVariant
            currentPage={_currentPage}
            onAnimationComplete={moveToNextPage}
            styles={variantStyles}
          />
        );
      default:
        return <NotFound404 />;
    }
  };

  return isLoading || !pageContent || !currentPage ? (
    <Loader show={isLoading} />
  ) : (
    renderPageVariant(currentPage)
  );
};
