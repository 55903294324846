/** @format */

import React from "react";

import welcome from "src/content/welcome";
import { useHasEmployerBenefits } from "src/utils/hooks/useHasEmployerBenefits";

export const Intro = () => {
  const hasWesternDentalBenefits = useHasEmployerBenefits("western-dental");
  const title = hasWesternDentalBenefits ? welcome.screen1.b2bTitle : welcome.screen1.title;

  return (
    <div className="flex flex-1 flex-col justify-center mb-20">
      <div>
        <h1 className="text-semibold text-2xl mb-2 text-center text-primary">{title}</h1>
      </div>
      <div className="mt-6 mb-8 flex flex-row justify-center">
        {[
          { icon: "alpha_provider", alt: '"alpha provider icon' },
          { icon: "people/woman-two", alt: "icon of a patient" },
        ].map((item) => (
          <img
            key={item.icon}
            src={`/assets/${item.icon}.svg`}
            alt={item.alt}
            className="welcome__person-icon-medium"
          />
        ))}
      </div>
      <div className="w-full flex flex-row justify-center">
        <p className="w-5/6 text-lg text-primary text-center">{welcome.screen1.subtitle}</p>
      </div>
    </div>
  );
};
