/** @format */

// Module dependencies
import type { FC, ReactElement } from "react";

import React from "react";

// UI Components
import { BottomNavigation } from "src/v2/designSystem/BottomNavigation";
import { LeftHandNavigation } from "src/v2/designSystem/LeftHandNavigation";

export type badgeVariant =
  | "light"
  | "neutral"
  | "notification"
  | "informative"
  | "success"
  | "warning"
  | "error"
  | "brandLight"
  | "brandDark";

export type visibility = "mobile" | "desktop" | "always";

export interface BaseNavigationIconProps {
  /** Badge label. */
  badgeLabel?: string | number;

  /** Badge color. */
  badgeVariant?: badgeVariant;

  /** Callback function to be called on Icon click. */
  clickCallback?: (args?: any) => any;

  /** Active state icon. */
  iconActive: string | ReactElement;

  /** Inactive state icon. */
  iconInactive: string | ReactElement;

  /** Icon label. */
  label: string;

  /** Value for the navigation item. Used to determine the active status. */
  value: string | number;

  /** Determines what screen size the navigation item should be available on. */
  visibleOn?: visibility;
}

export interface WebNavigationIconProps extends BaseNavigationIconProps {
  /** Flag. Indicates the state of the icon. */
  isActive: boolean;
}

export interface WebNavigationProps {
  /** Navigation items list. */
  icons: BaseNavigationIconProps[];

  /** Initial state for the active navigation list. */
  activeValue: string;

  /** Function. Used to compare and determine the active flag */
  comparator: (...args: any) => boolean;

  /** Callback function to be called on Logo click. */
  logoCallback?: (args?: any) => any;
}

export const WebNavigation: FC<WebNavigationProps> = ({
  icons,
  activeValue,
  comparator,
  logoCallback,
}) => (
  <>
    <BottomNavigation icons={icons} comparator={comparator} activeValue={activeValue} />
    <LeftHandNavigation
      icons={icons}
      comparator={comparator}
      activeValue={activeValue}
      logoCallback={logoCallback}
    />
  </>
);
