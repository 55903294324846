/** @format */

import React from "react";

interface ChannelListIconProps {
  src: any;
  fit?: boolean;
}

export const ChannelListIcon = ({ src, fit = true }: ChannelListIconProps) => (
  <div
    className="mr-4 bg-sand-40 overflow-hidden flex items-center justify-center"
    style={{ height: "48px", width: "48px", borderRadius: "24px" }}
  >
    <img
      src={src}
      alt=""
      role="presentation"
      style={fit ? { height: "48px" } : { maxHeight: "24px" }}
    />
  </div>
);
