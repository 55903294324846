/** @format */

import React from "react";

interface AlertSvgProps {
  fill: string;
}

const AlertSvg = ({ fill = "#F0783" }: AlertSvgProps) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <title>Alert Icon</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="maria-screen"
          transform="translate(-24.000000, -522.000000)"
          fill={fill}
          fillRule="nonzero"
        >
          <g id="Group-5" transform="translate(24.000000, 181.000000)">
            <g id="Group-3" transform="translate(0.000000, 339.000000)">
              <g id="alert" transform="translate(0.000000, 2.000000)">
                <path
                  d="M11.9829787,0 C5.36496232,0 -1.45132133e-15,5.36496232 -1.45132133e-15,11.9829787 C-1.45132133e-15,18.6009951 5.36496232,23.9659574 11.9829787,23.9659574 C18.6009951,23.9659574 23.9659574,18.6009951 23.9659574,11.9829787 C23.9581538,5.36819739 18.5977601,0.00780364328 11.9829787,0 Z M11.9829787,21.787234 C6.56823803,21.787234 2.1787234,17.3977194 2.1787234,11.9829787 C2.1787234,6.56823803 6.56823803,2.1787234 11.9829787,2.1787234 C17.3977194,2.1787234 21.787234,6.56823803 21.787234,11.9829787 C21.7806315,17.3949822 17.3949822,21.7806315 11.9829787,21.787234 L11.9829787,21.787234 Z"
                  id="Shape"
                ></path>
                <path
                  d="M11.9829787,5.44680851 C11.3813409,5.44680851 10.893617,5.93453236 10.893617,6.53617021 L10.893617,14.1617021 C10.893617,14.76334 11.3813409,15.2510638 11.9829787,15.2510638 C12.5846166,15.2510638 13.0723404,14.76334 13.0723404,14.1617021 L13.0723404,6.53617021 C13.0723404,5.93453236 12.5846166,5.44680851 11.9829787,5.44680851 Z"
                  id="Path"
                ></path>
                <circle id="Oval" cx="11.9829787" cy="17.4297872" r="1.0893617"></circle>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AlertSvg;
