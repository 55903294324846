/** @format */

import React from "react";
import { Link } from "react-router-dom";

import type { InvalidType } from "src/v2/utils/page/validators";

import { _POST, getTokenHeaderV2 } from "src/helpers/http";
import { ACTIVE_STATES } from "src/v2/constants";
import { ConsultContext } from "src/v2/routes/Consultation";

interface EligibilityStateIsOtherProps {
  invalid: InvalidType;
  values: any;
  closeModal: any;
  saveValues: any;
  moveForward: any;
  submitted: boolean;
}

const EligibilityStateIsOther = (props: EligibilityStateIsOtherProps) => {
  const consult = React.useContext(ConsultContext);

  const sendOpsEmail = () => {
    _POST("/states/ineligible", { consult_id: consult && consult.id }, getTokenHeaderV2())
      .then(() => props.closeModal())
      // tslint:disable-next-line
      .catch((e) => console.log(e));
  };

  return (
    <div className="m-5">
      <div className="text-center">
        <p className="font-bold mt-0 mx-auto w-3/4 text-xl">
          States we cover:
          <br />
          {ACTIVE_STATES.join(", ")}
        </p>
        <p className="text-primary">
          We regret to inform you that Alpha Medical does not currently serve outside of the states
          listed above.
        </p>
        <p className="text-primary">
          An Alpha Care Representative will be reaching out to you shortly to discuss next steps.
        </p>
        <Link to="/my-cases">
          <button onClick={sendOpsEmail} className={`btn btn-primary w-full my-2`}>
            Send me to my dashboard.
          </button>
        </Link>
      </div>
    </div>
  );
};

export default EligibilityStateIsOther;
