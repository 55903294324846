/** @format */

import React from "react";

interface PageSectionTitleProps {
  title: string;
  cxFontColor?: string;
  cxMargin?: string;
  cxFontSize?: string;
}

export const PageSectionTitle = ({
  title,
  cxFontColor,
  cxMargin,
  cxFontSize,
}: PageSectionTitleProps) => {
  return (
    <p
      className={`${cxFontColor || "primary"} ${cxMargin || "m-0"} ${cxFontSize || "text-sm"} font-bold uppercase`}
    >
      {title}
    </p>
  );
};
