/** @format */

import type { MembershipSchema, ProfileSchema } from "src/api";
import type { DynamicField, DynamicFormPage } from "src/components/DynamicForm/types";
import type { Consultation } from "src/v2/types/consultation";

import { getFieldsCanBeShown } from "./fields";

export const findFirstUnansweredPageIdx = (
  pages: DynamicFormPage[],
  values: any,
  consult: Consultation,
  hasBilling: boolean,
  profile: ProfileSchema,
  membership?: MembershipSchema,
  hasWesternDentalBenefits?: boolean,
  userRequiresSyncVisit?: boolean,
) => {
  let firstUnansweredPageIdx = pages.findIndex((_page: any) => {
    const _requiredFields = getFieldsCanBeShown(
      _page.fields,
      values,
      consult,
      profile,
      membership,
      hasWesternDentalBenefits,
      userRequiresSyncVisit,
    ).filter((field: any) => !!field.required);
    if (_page.key === "payment" && !hasBilling) {
      return true;
    }
    const _fields = _requiredFields.map((field: any) => ({
      key: field.key,
      required: field.required,
      answered: !!values[field.key] && values[field.key] !== "null",
    }));
    if (_page.cannotBeSkippedIfAnswered) {
      return true;
    }
    return (
      (_requiredFields.length && !_fields.length) || !_fields.every((field: any) => field.answered)
    );
  });

  if (firstUnansweredPageIdx === -1) {
    firstUnansweredPageIdx = pages.length - 1;
  }
  return firstUnansweredPageIdx;
};

/**
 * Returns the index of the last answered page in a dynamic form.
 * If no pages are answered, it returns the index of the first page.
 *
 * @param pages An array of objects representing the pages of the dynamic form.
 * @param values An object containing the values of the form fields.
 * @param consult An object representing the current consultation.
 * @param hasBilling A boolean value indicating whether is has billing.
 * @param profile - The user profile.
 * @param membership - Optional. The user membership.
 * @param hasWesternDentalBenefits Optional. A boolean value indicating whether the user has WD benefits.
 * @param userRequiresSyncVisit Optional. A boolean value indication if the user meets the criteria for a sync visit.
 * @returns The index of the last answered page or the index of the first page if no pages are answered.
 */
export const getLastAnsweredPageIdx = (
  pages: DynamicFormPage[],
  values: any,
  consult: Consultation,
  hasBilling: boolean,
  profile: ProfileSchema,
  membership?: MembershipSchema,
  hasWesternDentalBenefits?: boolean,
  userRequiresSyncVisit?: boolean,
) => {
  let lastAnsweredPageIdx = -1;
  let firstVisiblePageIdx = -1;

  const firstUnansweredPageIdx = findFirstUnansweredPageIdx(
    pages,
    values,
    consult,
    hasBilling,
    profile,
    membership,
    hasWesternDentalBenefits,
    userRequiresSyncVisit,
  );

  for (let i = firstUnansweredPageIdx - 1; i > -1; i--) {
    const page = pages[i];

    // Check if the page has fields to be shown; if not, the page will be hidden
    const fieldsToBeShown = getFieldsCanBeShown(
      page.fields,
      values,
      consult,
      profile,
      membership,
      hasWesternDentalBenefits,
      userRequiresSyncVisit,
    );

    // If the page is visible, record its index
    if (fieldsToBeShown.length > 0) {
      firstVisiblePageIdx = i;
    }

    // Check if the current page contains any input fields. If it does, mark it as answered and proceed to show it.
    // We can stop searching for the last answered page once we find a page with input fields.
    const pageHasInputFields = fieldsToBeShown.some((field: DynamicField) => field.type !== "info");
    if (pageHasInputFields) {
      lastAnsweredPageIdx = i;
      break;
    }
  }

  // Return the last answered page if found, otherwise return the first visible page or the first unanswered page
  return lastAnsweredPageIdx !== -1
    ? lastAnsweredPageIdx
    : firstVisiblePageIdx !== -1
      ? firstVisiblePageIdx
      : firstUnansweredPageIdx;
};
