/** @format **/

import React from "react";
import { useHistory } from "react-router-dom";

interface SettingsLinksProps {
  url: string;
  title: string;
  text?: string;
  arrowPrimary?: boolean;
  redirect?: boolean;
  callback?: () => void;
}

const SettingsLink = ({
  url,
  title,
  text,
  arrowPrimary,
  redirect,
  callback,
}: SettingsLinksProps) => {
  const history = useHistory();
  const handleClick = () => {
    if (callback) callback();
    else {
      history.push(url);
    }
  };
  if (redirect)
    return (
      <a key={url} href={`${url}`} className="cursor-pointer no-underline my-">
        <div className="flex py-4 border-grey-light border-b">
          <div className="flex flex-col w-full">
            <p className="text-primary text-base w-full">{title}</p>
            {text && <p className="text-primary text-sm w-4/5 pt-2">{text}</p>}
          </div>
          <img
            src={arrowPrimary ? "/assets/carrot-right-primary.svg" : "/assets/carrot-right.svg"}
            alt={`Go to an account settings page for ${title}`}
          />
        </div>
      </a>
    );
  return (
    <div onClick={handleClick} className="cursor-pointer flex py-4 border-grey-light border-b">
      <div className="flex flex-col w-full">
        <p className="text-primary text-base w-full">{title}</p>
        {text && <p className="text-primary text-sm w-4/5 pt-2">{text}</p>}
      </div>
      <img
        src={arrowPrimary ? "/assets/carrot-right-primary.svg" : "/assets/carrot-right.svg"}
        alt={`Go to an account settings page for ${title}`}
      />
    </div>
  );
};

export default SettingsLink;
