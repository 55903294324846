/** @format */
import React from "react";
import { useFormState } from "react-final-form";

import { Typography } from "@alphamedical/components";

import { STATES_FULL } from "src/v2/constants";

type StepDescriptionItemProps = {
  stepText?: string;
  stepHeader?: string;
};

interface StepDescriptionProps {
  title: string;
  steps: Array<StepDescriptionItemProps>;
  replaceState?: boolean;
  showNumbers?: boolean;
}

export const StepDescriptionMulti = ({
  replaceState = false,
  steps,
  title,
  showNumbers = false,
}: StepDescriptionProps) => {
  const formState = useFormState();
  const values = formState.values;
  const stateCode = values["consult-state"];
  const stateName = STATES_FULL.find((value) => value.value === stateCode)?.label || stateCode;

  const stepTitle = replaceState ? title.replace("[stateName]", stateName) : title;
  return (
    <div className="pb-4">
      <Typography variant="h2" className="pb-6">
        {stepTitle}
      </Typography>
      {showNumbers &&
        steps.length > 0 &&
        steps.map((step, index) => {
          return (
            <div className="flex flex-row items-center pb-4" key={index}>
              <Typography
                variant="h5"
                className="flex-none bg-sand-100 flex rounded-full h-6 w-6 justify-center items-center align-top border-2 border-forest-100"
              >
                {index + 1}
              </Typography>
              <div className="flex flex-col pl-4">
                {step.stepHeader && <Typography variant="h4">{step.stepHeader}</Typography>}
                {step.stepText && <Typography variant="body2">{step.stepText}</Typography>}
              </div>
            </div>
          );
        })}
      {!showNumbers && steps.length > 0 && (
        <ul className="content-start ml-4 mt-0 pl-0">
          {steps.map((step, index) => {
            return (
              <li key={index}>
                {step.stepText && (
                  <Typography variant="body1">
                    <span dangerouslySetInnerHTML={{ __html: step.stepText }} />
                  </Typography>
                )}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
