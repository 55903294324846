/** @format */

export const MEMBERSHIP_MODAL_TEXT = {
  content: [
    {
      title: "What is Alpha Basic Plan?",
      membershipType: "alpha",
      description:
        "Alpha Basic Plan is your access to online treatment for over 100 medical conditions, including urgent care, women's health, and more (weight loss, diabetes, and mental health conditions are available with the Alpha Plus+ Plan only). With Alpha Basic Plan, you'll receive 2 visits per month that can be used towards any eligible medical conditions (you may also start additional visits in a month at a discounted rate) and unlimited messaging with your Care Team. Alpha Basic Plan is {{price}}/month and billed on a monthly basis, with no minimum commitment. You may request to cancel at any time.* Plan fees are nonrefundable.",
    },
    {
      title: "What is Alpha Plus+ Plan?",
      membershipType: "plus",
      description:
        "With Alpha Plus+ Plan, you'll have access to everything in the Alpha Basic Plan and our mental health, weight loss, and diabetes services. You'll receive 4 visits per month that can be used towards any of these conditions (you may also start additional visits in a month at a discounted rate). Alpha Plus+ Plan is {{price}}/month and billed on a monthly basis, with no minimum commitment. You may request to cancel at any time.* Plan fees are nonrefundable.",
    },
  ],
  extraContent:
    "Please note that the cost of medication and lab tests are not included in Alpha Basic Plan or Alpha Plus+ Plan. When you choose an Alpha Partner pharmacy, your prescriptions are filled by third party pharmacies.",
};

export const MEMBERSHIP_PLUS_MODAL_TEXT = {
  content: [
    {
      title: "What is Alpha Plus+ Plan?",
      membershipType: "plus",
      description:
        "With Alpha Plus+ Plan, you'll have access to everything in Alpha Basic Plan and our mental health, weight loss, and diabetes services. You'll receive 4 visits per month that can be used towards any of these conditions (you may also start additional visits in a month at a discounted rate). Alpha Plus+ Plan is {{price}}/month and billed on a monthly basis, with no minimum commitment. You may request to cancel at any time.* Plan fees are nonrefundable.",
    },
    {
      title: "How is this different from Alpha Basic Plan?",
      membershipType: "alpha",
      description:
        "Alpha Basic Plan includes access to online treatment for over 100 medical conditions, but does not include our weight loss, diabetes, and mental health services. With Alpha Basic Plan, you'll receive 2 visits per month that can be used towards any eligible medical conditions.",
    },
  ],
  extraContent:
    "Both Alpha Basic Plan and Alpha Plus+ Plan include unlimited messaging with your Care Team, and are flexible, month-to-month commitments.",
};
