/** @format */

import { useEffect } from "react";

import type { TopBarNavigationProps } from "src/v2/designSystem";

import { useStoreActions, useStoreState } from "src/v2/models";
import { defaultTopBarValues } from "src/v2/models/topBar";

/**
 * `useTopBarDefaultValues` is a hook that sets the default values for the `TopBarNavigation` component.
 *
 * @returns An object containing the `TopBar` state and actions.
 */
export const useTopBarDefaultValues = () => {
  const actions = useStoreActions((state) => state.topBar);
  const topBar = useStoreState((state) => state.topBar);

  useEffect(() => {
    actions.setVariant(defaultTopBarValues.variant);
    actions.setWithLeftHandNav(defaultTopBarValues.withLeftHandNav);
    actions.setShowBackButton(defaultTopBarValues.showBackButton);
    actions.setShowCloseButton(defaultTopBarValues.showCloseButton);
    actions.setShowCompleteButton(defaultTopBarValues.showCompleteButton);
    actions.setShowHelpButton(defaultTopBarValues.showHelpButton);
    actions.setShowMenuButton(defaultTopBarValues.showMenuButton);
    actions.setShowSettingsButton(defaultTopBarValues.showSettingsButton);
    actions.setshowNotificationButton(defaultTopBarValues.showNotificationButton);
    actions.setSubtitle(defaultTopBarValues.subtitle);
    actions.setTitle(defaultTopBarValues.title);
    actions.setNotifications(defaultTopBarValues.notifications);
    actions.setOnBackCallback(defaultTopBarValues.onBackCallback);
    actions.setOnHelpCallback(defaultTopBarValues.onHelpCallback);
    actions.setOnLogoCallback(defaultTopBarValues.onLogoCallback);
    actions.setOnCloseCallback(defaultTopBarValues.onCloseCallback);
    actions.setOnCompleteCallback(defaultTopBarValues.onCompleteCallback);
    actions.setOnMenuCallback(defaultTopBarValues.onMenuCallback);
    actions.setOnNotificationCallback(defaultTopBarValues.onNotificationCallback);
    actions.setIsNotificationPageActive(defaultTopBarValues.isNotificationPageActive);
    actions.setOnLogoutCallback(defaultTopBarValues.onLogoutCallback);
    actions.setOnSettingsCallback(defaultTopBarValues.onSettingsCallback);
  }, []);

  return { ...topBar, ...actions };
};

/**
 * `useUpdateTopBar` is a hook that updates the `TopBarNavigation` component state with the provided props.
 *
 * @param props An optional object with props to update the `TopBarNavigation` state.
 *
 * @returns An object containing the updated `TopBarNavigation` state and actions.
 */
export const useUpdateTopBar = (props?: TopBarNavigationProps) => {
  const actions = useStoreActions((state) => state.topBar);
  const topBar = useStoreState((state) => state.topBar);

  useEffect(() => {
    if (props?.variant) actions.setVariant(props.variant);
    if (props?.withLeftHandNav !== undefined) actions.setWithLeftHandNav(props.withLeftHandNav);
    if (props?.showBackButton !== undefined) actions.setShowBackButton(props.showBackButton);
    if (props?.showCloseButton !== undefined) actions.setShowCloseButton(props.showCloseButton);
    if (props?.showCompleteButton !== undefined)
      actions.setShowCompleteButton(props.showCompleteButton);
    if (props?.showHelpButton !== undefined) actions.setShowHelpButton(props.showHelpButton);
    if (props?.showMenuButton !== undefined) actions.setShowMenuButton(props.showMenuButton);
    if (props?.showNotificationButton !== undefined)
      actions.setshowNotificationButton(props.showNotificationButton);
    if (props?.showLogoutButton !== undefined) actions.setShowLogoutButton(props.showLogoutButton);
    if (props?.showSettingsButton !== undefined) {
      actions.setShowSettingsButton(props.showSettingsButton);
    }
    // clear title on page change
    actions.setTitle(props?.title || "");
    // clear subtitle on page change
    actions.setSubtitle(props?.subtitle || "");
    if (props?.notifications) actions.setNotifications(props.notifications);
    if (props?.onBackCallback) actions.setOnBackCallback(props.onBackCallback);
    if (props?.onHelpCallback) actions.setOnHelpCallback(props.onHelpCallback);
    if (props?.onLogoCallback) actions.setOnLogoCallback(props.onLogoCallback);
    if (props?.onCloseCallback) actions.setOnCloseCallback(props.onCloseCallback);
    if (props?.onCompleteCallback) actions.setOnCompleteCallback(props.onCompleteCallback);
    if (props?.onMenuCallback) actions.setOnMenuCallback(props.onMenuCallback);
    if (props?.onNotificationCallback)
      actions.setOnNotificationCallback(props.onNotificationCallback);
    if (props?.isNotificationPageActive)
      actions.setIsNotificationPageActive(props.isNotificationPageActive);
    if (props?.onLogoutCallback) actions.setOnLogoutCallback(props.onLogoutCallback);
    if (props?.onSettingsCallback) actions.setOnSettingsCallback(props.onSettingsCallback);
  }, [props]);

  return { ...topBar, ...actions };
};

/**
 * `useTopBar` is a hook that provides easy access to the `TopBarNavigation` component state and actions.
 * It combines the `useTopBarDefaultValues` and `useUpdateTopBar` hooks.
 *
 * @param props An optional object with props to set the initial `TopBarNavigation` state.
 *
 * @returns An object containing the `TopBarNavigation` state and actions.
 */
export const useTopBar = (props?: TopBarNavigationProps) => {
  return useUpdateTopBar({ ...defaultTopBarValues, ...props });
};
