/** @format */

import type { PropsWithChildren } from "react";
import type React from "react";

import type { IconProp } from "@fortawesome/fontawesome-svg-core";

import type { RecordAccordionProps } from "src/components/RecordAccordion/RecordAccordion";

import { AssistantButton } from "src/components/Buttons/AssistantButton";
import { RecordAccordion } from "src/components/RecordAccordion/RecordAccordion";

export type RecordAction = { icon: IconProp; iconClassName?: string; label: string; key: string };

interface RecordAccordionActionsProps extends RecordAccordionProps {
  /**
   * The actions to display in the footer
   */
  actions?: RecordAction[];

  /**
   * callback for the actions click
   * @param actionKey the pressed action key
   */
  onActionClick?: (actionKey: string) => void;
}

export const RecordAccordionActions: React.FC<PropsWithChildren<RecordAccordionActionsProps>> = ({
  children,
  actions = [],
  onActionClick,
  ...actionCardProps
}) => {
  return (
    <RecordAccordion
      {...actionCardProps}
      FooterElement={
        actions?.length > 0 ? (
          <div className="flex flex-grow border-t border-grey-60 pt-4">
            {actions.map((action) => (
              <AssistantButton
                key={action.key}
                onClick={() => onActionClick && onActionClick(action.key)}
                iconClassname={action.iconClassName}
                label={action.label}
                icon={action.icon}
              />
            ))}
          </div>
        ) : null
      }
    >
      <div>{children}</div>
    </RecordAccordion>
  );
};
