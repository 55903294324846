/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancellationDiscountCurrentPeriodSchema } from '../models/CancellationDiscountCurrentPeriodSchema';
import type { CancellationDiscountEligibleSchema } from '../models/CancellationDiscountEligibleSchema';
import type { ConditionSchema } from '../models/ConditionSchema';
import type { MembershipSchema } from '../models/MembershipSchema';
import type { ServiceCategorySchema } from '../models/ServiceCategorySchema';
import type { ServicesCancelReasonRequestBodySchema } from '../models/ServicesCancelReasonRequestBodySchema';
import type { ServiceSchema } from '../models/ServiceSchema';
import type { ServicesCreateMembershipRequestBodySchema } from '../models/ServicesCreateMembershipRequestBodySchema';
import type { ServicesEligibilityRequestBodySchema } from '../models/ServicesEligibilityRequestBodySchema';
import type { ServiceTypeSchema } from '../models/ServiceTypeSchema';
import type { VisitInfoSchema } from '../models/VisitInfoSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ServicesService {

    /**
     * Fetch a list of all available condition types from Alpha Medical
     * @returns ConditionSchema
     * @throws ApiError
     */
    public static getConditions(): CancelablePromise<Array<ConditionSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/conditions',
        });
    }

    /**
     * Fetch a condition based on its key identifier.
     * @returns ConditionSchema
     * @throws ApiError
     */
    public static getCondition({
        conditionKey,
    }: {
        conditionKey: string,
    }): CancelablePromise<ConditionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/conditions/{condition_key}',
            path: {
                'condition_key': conditionKey,
            },
        });
    }

    /**
     * @deprecated
     * Determine eligibility for a consult based on condition, state, dob, gender.
     * Returns a json object with a list of reasons.
     * @returns any No response format defined
     * @throws ApiError
     */
    public static eligibility({
        requestBody,
    }: {
        requestBody: ServicesEligibilityRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/eligibility',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ServiceCategorySchema
     * @throws ApiError
     */
    public static getServiceCategories(): CancelablePromise<Array<ServiceCategorySchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service-categories',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getServiceTypeCategories({
        key,
    }: {
        key: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service-type-categories/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @returns ServiceTypeSchema
     * @throws ApiError
     */
    public static getServiceTypes(): CancelablePromise<Array<ServiceTypeSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/service_types',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static checkCurrentServiceReturnStatus({
        patientId,
        conditionKey,
    }: {
        patientId: number,
        conditionKey: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{patient_id}/service/{condition_key}/return-status',
            path: {
                'patient_id': patientId,
                'condition_key': conditionKey,
            },
        });
    }

    /**
     * @returns MembershipSchema
     * @throws ApiError
     */
    public static getMembership({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<MembershipSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/membership',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns MembershipSchema
     * @throws ApiError
     */
    public static createMembership({
        userId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        requestBody: ServicesCreateMembershipRequestBodySchema,
    }): CancelablePromise<MembershipSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/{user_id}/membership',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Cancels a user's membership and associated recurly subscription, no refunds are offered
     * @returns any No response format defined
     * @throws ApiError
     */
    public static cancelMembership({
        userId,
        recurlySubscriptionId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        recurlySubscriptionId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/{user_id}/membership/cancel',
            path: {
                'user_id': userId,
            },
            query: {
                'recurly_subscription_id': recurlySubscriptionId,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static cancelReason({
        userId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        requestBody: ServicesCancelReasonRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/{user_id}/membership/cancel_reason',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static acceptMembershipCancellationDiscount({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/{user_id}/membership/cancellation-discount',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns CancellationDiscountCurrentPeriodSchema
     * @throws ApiError
     */
    public static membershipCancellationDiscountCurrentPeriod({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<CancellationDiscountCurrentPeriodSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/membership/cancellation-discount-current-period',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns CancellationDiscountEligibleSchema
     * @throws ApiError
     */
    public static membershipCancellationDiscountEligible({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<CancellationDiscountEligibleSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/membership/cancellation-discount-eligible',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * Upgrades or downgrades a user's membership with prorated refunds for upgrades as calculated by recurly. Patients can only upgrade directly, downgrades must be requested (see create_membership_request). Both actions can be performed in CDB.
     * @returns any No response format defined
     * @throws ApiError
     */
    public static changeMembership({
        userId,
        newPlanCode,
        recurlySubscriptionId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        /**
         * There are currently only two membership tiers so the new membership tier could be implied, but this serves as a sanity check to make the user's intentions explicit
         */
        newPlanCode: 'alpha-membership' | 'alpha-membership-plus',
        recurlySubscriptionId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/{user_id}/membership/change',
            path: {
                'user_id': userId,
            },
            query: {
                'new_plan_code': newPlanCode,
                'recurly_subscription_id': recurlySubscriptionId,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static addCoupon({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/{user_id}/membership/promo',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * Allows patients to request downgrades to or cancelations of their membership plans, prompting an alert task to be created
     * @returns any No response format defined
     * @throws ApiError
     */
    public static createMembershipRequest({
        userId,
        action,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        /**
         * Indicates whether the patient wants downgrade or cancel
         */
        action: 'downgrade' | 'cancel',
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/{user_id}/membership/request',
            path: {
                'user_id': userId,
            },
            query: {
                'action': action,
            },
        });
    }

    /**
     * @returns VisitInfoSchema
     * @throws ApiError
     */
    public static getVisitLimitInfo({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<VisitInfoSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/membership/visit-limit-info',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getUserRecommendations({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/recommendations',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * Get all services for a user.
     * Test file: test_services_views.py
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getServicesForUser({
        userId,
        include,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        /**
         * List of services to include
         */
        include?: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/services',
            path: {
                'user_id': userId,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Get a single service for a user.
     * @returns ServiceSchema
     * @throws ApiError
     */
    public static getServiceForUser({
        userId,
        serviceType,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        serviceType: string,
    }): CancelablePromise<ServiceSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/services/{service_type}',
            path: {
                'user_id': userId,
                'service_type': serviceType,
            },
        });
    }

    /**
     * Schedule a followup reminder task for a service.
     * @returns any No response format defined
     * @throws ApiError
     */
    public static scheduleServiceFollowup({
        userId,
        serviceType,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        serviceType: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/{user_id}/services/{service_type}/schedule-followup',
            path: {
                'user_id': userId,
                'service_type': serviceType,
            },
        });
    }

}
