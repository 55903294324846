/** @format */

// --------- Account Routes ---------
const YOUR_ACCOUNT = "/account";

export const ACCOUNT_PERSONAL_INFORMATION = `${YOUR_ACCOUNT}/personal-information`;
export const PERSONAL_INFORMATION_CONTACT = `${ACCOUNT_PERSONAL_INFORMATION}/contact`;
export const PERSONAL_INFORMATION_EMERGENCY_CONTACT = `${ACCOUNT_PERSONAL_INFORMATION}/emergency-contact`;
export const PERSONAL_INFORMATION_PHOTO_ID = `${ACCOUNT_PERSONAL_INFORMATION}/photo-id`;
export const PERSONAL_INFORMATION_PRONOUNS = `${ACCOUNT_PERSONAL_INFORMATION}/preferred-pronouns`;

export const HEALTH_INFORMATION = `${YOUR_ACCOUNT}/health-information`;
export const CARE_TEAM = `${HEALTH_INFORMATION}/primary-care-provider`;
export const ALPHA_PCP = `${CARE_TEAM}/alpha-pcp`;
export const MY_CARE_TEAM_PAGE = `${YOUR_ACCOUNT}/my-care-team`;
export const MEDICAL_RECORDS = `${HEALTH_INFORMATION}/medical-records`;
export const HEALTH_ACTION_PLAN = `${MEDICAL_RECORDS}/health-action-plan`;
export const CARE_GUIDES = `${HEALTH_ACTION_PLAN}/care-guides/:guide`;

export const MEMBERSHIP_AND_PAYMENT = `${YOUR_ACCOUNT}/membership_and_payment`;
export const MEMBERSHIP_DETAILS = `${MEMBERSHIP_AND_PAYMENT}/membership`;
export const MEMBERSHIP_CANCELLATION = `${MEMBERSHIP_AND_PAYMENT}/membership/cancel`;
export const MEMBERSHIP_CHANGE = `${MEMBERSHIP_AND_PAYMENT}/membership/change`;
export const PAYMENT_METHODS = `${MEMBERSHIP_AND_PAYMENT}/payments`;
export const INSURANCE_INFORMATION = `${MEMBERSHIP_AND_PAYMENT}/insurance-information`;
export const INVOICES = `${MEMBERSHIP_AND_PAYMENT}/invoices`;

export const SETTINGS = "/settings";

export const SERVICE_CATEGORIES = "/visits/:categoryKey";

const VISITS = "/visits";
export const YOUR_VISITS = `${VISITS}/your-visits`;

export const YOUR_CARE = "/your-care";
export const EXPLORE_VISITS = `${YOUR_CARE}/explore-visits`;
export const CHECKINS_LIST_PAGE = `${YOUR_CARE}/check-ins`;
export const CHECKIN_RESULTS_PAGE = `${CHECKINS_LIST_PAGE}/:checkinKey`;
export const CHECKIN_REDIRECT_PAGE = `${CHECKINS_LIST_PAGE}/redirect/:checkinKey`;

// --------- Consultation Routes ---------
export const CONSULTATION_ROUTE = (consultKey: string) => `/consultation/${consultKey}`;

// --------- Return consult  Routes ---------
export const RETURN_CONSULT_ROUTE = (consultKey: string) => `/return-consult/${consultKey}-checkup`;

// UBACARE Routes
export const UBACARE_SERVICE_URL = "/consultation/ubacare-program-visit";
