/** @format */

import React from "react";

import { isAxiosError } from "axios";

import type { Consultation } from "src/v2/types/consultation";
import type { AddressInvalid } from "src/v2/utils/page/_validators/address";
import type { ConditionInvalid } from "src/v2/utils/page/_validators/condition";
import type { InvalidType } from "src/v2/utils/page/validators";

import { MarketingService } from "src/api";
import { AlphaModal } from "src/v2/components/AlphaModal";
import { INVALID_TYPE_ADDRESS } from "src/v2/utils/page/_validators/address";
import {
  INVALID_TYPE_BLOODPRESSURE,
  INVALID_TYPE_HIGH_DIASTOLIC,
  INVALID_TYPE_HIGH_SYSTOLIC,
} from "src/v2/utils/page/_validators/blood-pressure";
import {
  INVALID_TYPE_CONDITION,
  INVALID_TYPE_HYPOTHYROIDISM_PRESCRIPTION,
  INVALID_TYPE_MED_NOT_COVERED,
  INVALID_TYPE_WAITLIST,
} from "src/v2/utils/page/_validators/condition";
import { INVALID_TYPE_INSURANCE } from "src/v2/utils/page/_validators/insurance";
import {
  INVALID_TYPE_RESELECT,
  INVALID_TYPE_SOFT_STOP,
} from "src/v2/utils/page/_validators/soft-stop";
import { INVALID_STATE_OTHER } from "src/v2/utils/page/_validators/state";
import { INVALID_TYPE_HARD_STOP, INVALID_TYPE_VISIT } from "src/v2/utils/page/_validators/visit";

import { EligibilityFormAddress } from "./EligibilityFormAddress";
import { EligibilityFormCondition } from "./EligibilityFormCondition";
import { EligibilityFormHardStop } from "./EligibilityFormHardStop";
import { EligibilityFormHypothyroidismPrescription } from "./EligibilityFormHypothyroidismPrescription";
import { EligibilityFormInsurance } from "./EligibilityFormInsurance";
import { EligibilityFormReselect } from "./EligibilityFormReselect";
import { EligibilityFormSoftStop } from "./EligibilityFormSoftStop";
import EligibilityFormStateOther from "./EligibilityFormStateOther";
import { EligibilityFormVisit } from "./EligibilityFormVisit";
import { EligibilityFormWaitlist } from "./EligibilityFormWaitlist";
import EligibilityHighBloodPressure from "./EligibilityHighBloodPressure";
import { EligibilityMedNotCovered } from "./EligibilityMedNotCovered";
import EligibilityNoBloodPressure from "./EligibilityNoBloodPressure";

interface EligibilityFormProps {
  email: string;
  invalid: InvalidType;
  consult: Consultation;
  condition: string;
  values: any;
  saveValues: any;
  moveForward: any;
  onClose?: () => void;
}

export const postEmailLead = async (submission: any) => {
  try {
    return await MarketingService.leadCreate({ requestBody: submission });
  } catch (e) {
    throw (
      (isAxiosError(e) && e.response && e.response.data) || {
        response: {
          message: "invalid response. maybe 404?",
        },
      }
    );
  }
};
const EligibilityForm = (props: EligibilityFormProps) => {
  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | undefined>();

  const onSubmitCondition = async (_values: any) => {
    const { email, comment } = _values;
    const invalid: ConditionInvalid = props.invalid as ConditionInvalid;
    const invalidSex = invalid.sex;
    const invalidDob = invalid.dob;
    const condition = props.condition;
    const platform = invalidSex
      ? `app:consultation:ineligible:${condition}:sex`
      : invalidDob
        ? `app:consultation:ineligible:${condition}:dob`
        : `app:consultation:ineligible:${condition}:unknown`;
    const product = props.condition.toLowerCase();
    const submission = { email, platform, product, comment };

    return await postEmailLead(submission);
  };

  const onSubmitInsurance = async (_values: any) => {
    const { email } = _values;
    const condition = props.condition;
    const platform = "app:consultation:ineligible:express-script";
    const product = condition.toLowerCase();
    const submission = { email, platform, product };

    return await postEmailLead(submission);
  };

  const onSubmit = async (_values: any) => {
    const { email } = _values;
    if (!email) {
      return;
    }

    if (props.invalid.invalidType === INVALID_TYPE_CONDITION) {
      await onSubmitCondition(_values)
        .then((res: any) => {
          setSubmitted(true);
        })
        .catch((err: any) => {
          setSubmitted(true);
          setError(err.response.message);
          console.log(error);
        });
    } else if (props.invalid.invalidType === INVALID_TYPE_INSURANCE) {
      await onSubmitInsurance(_values)
        .then((res: any) => {
          setSubmitted(true);
        })
        .catch((err: any) => {
          setSubmitted(true);
          setError(err.response.message);
          console.log(error);
        });
    }
  };

  const { invalidType } = props.invalid;
  const isInvalidCondition = invalidType === INVALID_TYPE_CONDITION;
  const isInvalidInsurance = invalidType === INVALID_TYPE_INSURANCE;
  const isInvalidAddress = invalidType === INVALID_TYPE_ADDRESS;
  const isInvalidBloodPressure = invalidType === INVALID_TYPE_BLOODPRESSURE;
  const isInvalidVisit = invalidType === INVALID_TYPE_VISIT;
  const isHighSystolic = invalidType === INVALID_TYPE_HIGH_SYSTOLIC;
  const isHighDiastolic = invalidType === INVALID_TYPE_HIGH_DIASTOLIC;
  const isHardStop = invalidType === INVALID_TYPE_HARD_STOP;
  const isStateOther = invalidType === INVALID_STATE_OTHER;
  const isSoftStop = invalidType === INVALID_TYPE_SOFT_STOP;
  const isReslect = invalidType === INVALID_TYPE_RESELECT;
  const isInvalidHypothyroidismPrescription =
    invalidType === INVALID_TYPE_HYPOTHYROIDISM_PRESCRIPTION;
  const medNotCovered = invalidType === INVALID_TYPE_MED_NOT_COVERED;
  const waitlist = invalidType === INVALID_TYPE_WAITLIST;
  return (
    <AlphaModal
      isOpen={!!props.invalid}
      className={`${props.invalid.modalClasses ? props.invalid.modalClasses : "max-w-lg"} m-auto`}
      onRequestClose={props.onClose}
      cxPadding={props.invalid.cxPadding}
    >
      {isInvalidAddress && (
        <EligibilityFormAddress
          condition={props.condition}
          invalid={props.invalid as AddressInvalid}
          values={props.values}
          closeModal={props.onClose}
          saveValues={props.saveValues}
          moveForward={props.moveForward}
        />
      )}
      {isInvalidCondition && (
        <EligibilityFormCondition
          invalid={props.invalid}
          condition={props.condition}
          closeModal={props.onClose}
          moveForward={props.moveForward}
          saveValues={props.saveValues}
          values={props.values}
        />
      )}
      {isInvalidInsurance && (
        <EligibilityFormInsurance
          closeModal={props.onClose}
          values={props.values}
          saveValues={props.saveValues}
          email={props.email}
          submitted={submitted}
          submitLead={onSubmit}
        />
      )}
      {isInvalidBloodPressure && (
        <EligibilityNoBloodPressure
          invalid={props.invalid}
          values={props.values}
          saveValues={props.saveValues}
          submitted={submitted}
          moveForward={props.moveForward}
          closeModal={props.onClose}
        />
      )}
      {isStateOther && (
        <EligibilityFormStateOther
          invalid={props.invalid}
          values={props.values}
          saveValues={props.saveValues}
          submitted={submitted}
          moveForward={props.moveForward}
          closeModal={props.onClose}
        />
      )}
      {(isHighSystolic || isHighDiastolic) && (
        <EligibilityHighBloodPressure
          invalid={props.invalid}
          values={props.values}
          saveValues={props.saveValues}
          submitted={submitted}
          moveForward={props.moveForward}
          closeModal={props.onClose}
        />
      )}
      {isInvalidVisit && (
        <EligibilityFormVisit
          values={props.values}
          moveForward={props.moveForward}
          saveValues={props.saveValues}
          closeModal={props.onClose}
        />
      )}
      {isHardStop && (
        <EligibilityFormHardStop
          closeModal={props.onClose}
          condition={props.condition}
          invalid={props.invalid}
        />
      )}
      {isSoftStop && (
        <EligibilityFormSoftStop
          closeModal={props.onClose}
          condition={props.condition}
          invalid={props.invalid}
          saveValues={props.saveValues}
          moveForward={props.moveForward}
          values={props.values}
          consult={props.consult}
        />
      )}
      {isReslect && (
        <EligibilityFormReselect
          closeModal={props.onClose}
          condition={props.condition}
          invalid={props.invalid}
          saveValues={props.saveValues}
          moveForward={props.moveForward}
          values={props.values}
          consult={props.consult}
        />
      )}
      {isInvalidHypothyroidismPrescription && (
        <EligibilityFormHypothyroidismPrescription
          closeModal={props.onClose}
          condition={props.condition}
          invalid={props.invalid}
          saveValues={props.saveValues}
          moveForward={props.moveForward}
          values={props.values}
          consult={props.consult}
        />
      )}
      {medNotCovered && (
        <EligibilityMedNotCovered
          closeModal={props.onClose}
          condition={props.condition}
          invalid={props.invalid}
          saveValues={props.saveValues}
          moveForward={props.moveForward}
          values={props.values}
          consult={props.consult}
        />
      )}
      {waitlist && (
        <EligibilityFormWaitlist
          closeModal={props.onClose}
          condition={props.condition}
          invalid={props.invalid}
          saveValues={props.saveValues}
          moveForward={props.moveForward}
          values={props.values}
          consult={props.consult}
        />
      )}
    </AlphaModal>
  );
};

export default EligibilityForm;
