/** @format */

import React from "react";
import { useForm } from "react-final-form";
import ClipLoader from "react-spinners/ClipLoader";

import { Elements, RecurlyProvider, useCheckoutPricing } from "@recurly/react-recurly";

import type { GroupSessionCostSchema, RecurlyAdjustmentSchema } from "src/v2/models/api_types";

import { FormContext } from "src/components/DynamicForm/DynamicForm";
import { _GET, getRecurlyPublicKey } from "src/helpers/http";
import { isGroupSessionFormContext } from "src/routes/GroupSessions/GroupSessionSignup";
import { useCurrentUser } from "src/v2/models/profile";

/** Render an itemized list of charges for the checkout page  */
const PaymentLineItems = () => {
  const user = useCurrentUser();
  const context = React.useContext(FormContext);
  const form = useForm();

  // Get the currently set coupon code from the form state, but only if the current code is valid
  const couponFieldState = form.getFieldState("coupon");
  const currentCouponCode = couponFieldState?.valid ? couponFieldState.value : undefined;

  const [signupFeeAdjustments, setSignupFeeAdjustments] = React.useState<RecurlyAdjustmentSchema[]>(
    [],
  );
  const [{ price, loading }, setCheckoutData] = useCheckoutPricing({});

  // Depending on what kind of data is part of the form context, call an endpoint to get a list of adjustments to
  // display. When returned also update Recurly's useCheckoutPricing hook. Run on mount or when the couponCode changes.
  React.useEffect(() => {
    if (isGroupSessionFormContext(context)) {
      _GET<GroupSessionCostSchema>(
        `/users/${user.id}/group_session_signups/${context.signup.id}/cost`,
      ).then((res) => {
        setSignupFeeAdjustments(res.due_now.adjustments);
        setCheckoutData({
          coupon: currentCouponCode,
          adjustments: res.due_now.adjustments.map((adj) => ({
            amount: adj.unit_amount_in_cents / 100.0,
            quantity: adj.quantity,
            taxCode: "",
            taxExempt: false,
          })),
        });
      });
    }
  }, [currentCouponCode]);

  return (
    <>
      {(!loading && (
        <div className="pb-4">
          {signupFeeAdjustments.map((adjustment, idx) => (
            <div key={adjustment.product_code} className="flex justify-between">
              <div>
                {idx === 0 && <span>Due now: </span>}
                {adjustment.display_label || adjustment.description}
              </div>
              <div>
                {price.currency.symbol}
                {(adjustment.unit_amount_in_cents / 100.0).toFixed(2)}
              </div>
            </div>
          ))}
          {price.now.discount !== "0.00" && (
            <div className="flex justify-between">
              <div>Discount</div>
              <div>
                -{price.currency.symbol}
                {price.now.discount}
              </div>
            </div>
          )}
          <div className="border-b-2 border-primary my-6" />
          <div className="flex justify-between">
            <div className="font-bold">Total</div>
            <div>
              {price.currency.symbol}
              {price.now.total}
            </div>
          </div>
        </div>
      )) || <ClipLoader loading={true} />}
    </>
  );
};

export default () => (
  <RecurlyProvider publicKey={getRecurlyPublicKey()}>
    <Elements>
      <PaymentLineItems />
    </Elements>
  </RecurlyProvider>
);
