/** @format */

import React from "react";

import type { ConsentPage as IConsentPage } from "src/components/DynamicForm/types";

import { DynamicField } from "src/components/DynamicForm/Fields";
import { Loader } from "src/components/Loader";
import { useGetOnMount } from "src/utils/hooks";

import type { FormPageProps } from "./types";

import { DynamicFormPageWrapper } from "./DynamicFormPageWrapper";

interface ConsentPageProps extends FormPageProps {
  page: IConsentPage;
}

export const ConsentPage = (props: ConsentPageProps) => {
  const { page } = props;
  const url = `/v2/consents?version=${page.consentFormKey}`;
  const [consentHtml, loadingConsent] = useGetOnMount<string>(url, "");
  return (
    <Loader show={loadingConsent}>
      <DynamicFormPageWrapper {...props} allowAutoSubmit={false}>
        <div className="mb-4" dangerouslySetInnerHTML={{ __html: consentHtml }} />
        <div className="display-none">
          {page.fields.map((field) => (
            <DynamicField key={field.key} field={field} />
          ))}
        </div>
      </DynamicFormPageWrapper>
    </Loader>
  );
};
