/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConsentsSignConsentsForUserRequestBodySchema } from '../models/ConsentsSignConsentsForUserRequestBodySchema';
import type { LegacyConsultConsentSchema } from '../models/LegacyConsultConsentSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConsentsService {

    /**
     * Returns a jinja template populated by the version query param passed in. i.e.; version=patient_agreement-1.1.1.html
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getConsent({
        version,
    }: {
        /**
         * The file name of a consent html template
         */
        version?: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/consents',
            query: {
                'version': version,
            },
        });
    }

    /**
     * Returns a jinja template for a specific condition, populated by the version query param passed in. i.e.; version=skincare.html
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getConsentForCondition({
        conditionKey,
        version,
    }: {
        conditionKey: string,
        /**
         * The file name of a consent html template
         */
        version?: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/consents/{condition_key}',
            path: {
                'condition_key': conditionKey,
            },
            query: {
                'version': version,
            },
        });
    }

    /**
     * Returns a jinja template for displaying a consent report, based on a specified user and consent_id passed in.
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getConsultConsent({
        userId,
        consentId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        consentId: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/users/{user_id}/consent/{consent_id}',
            path: {
                'user_id': userId,
                'consent_id': consentId,
            },
        });
    }

    /**
     * Takes in a list of consents to sign for a given user,
     * If a consent_form version that doesn't exist is passed in, it creates it then signs it for the user.
     * @returns any No response format defined
     * @throws ApiError
     */
    public static signConsentsForUser({
        userId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        requestBody: ConsentsSignConsentsForUserRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/users/{user_id}/sign_consents',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Returns a list of the consent's associated with a given user that are saved in the database.
     * @returns LegacyConsultConsentSchema
     * @throws ApiError
     */
    public static getConsentsForUser({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<LegacyConsultConsentSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/users/{user_id}/signed_consents',
            path: {
                'user_id': userId,
            },
        });
    }

}
