/** @format */
import React from "react";
import Linkify from "react-linkify";

import cn from "classnames";
import linkifyHtml from "linkifyjs/html";
import { compact } from "lodash";
import sanitizeHtml from "sanitize-html";

import { Typography } from "@alphamedical/components";

import type { CardPopupContent } from "src/components/Popups/CardPopup";
import type { PubNubMessage } from "src/utils/hooks/pubnub";

import { ChatService } from "src/api";
import {
  getDBUserFromMessage,
  getProviderSubtitle,
  getTimetokenDisplay,
  getUserMessageColor,
} from "src/components/Chat/utils";
import { Imgify } from "src/components/Imgify";
import UserAvatar from "src/components/UserAvatar";

import { MessageActions } from "./MessageActions";

import "./messages.scss";

const renderTruncatedMessage = (message: string): string =>
  message.slice(0, 273) + (message.length > 273 ? "..." : "");

interface MessageProps {
  message: PubNubMessage;
  currentUser?: boolean;
  hideUserAvatar: boolean;
  DBChannels?: any[];
  truncateMessageOnRender: boolean;
  setBioModalIsOpen?: (value: boolean) => void;
  setBioModalContent?: (value: CardPopupContent) => void;
}

export const Message = ({
  DBChannels,
  hideUserAvatar,
  currentUser,
  message,
  truncateMessageOnRender,
  message: {
    timetoken,
    message: { text, customAttributes },
  },
  setBioModalIsOpen,
  setBioModalContent,
}: MessageProps) => {
  const [hideFullMessage, setHideFullMessage] = React.useState<boolean>(truncateMessageOnRender);
  const renderReadMore = text && text.length >= 273;
  const timeSent = getTimetokenDisplay(timetoken);
  const { messageActions } = customAttributes || {};
  const [attachments, setAttachments] = React.useState<string[]>();
  const user = getDBUserFromMessage(DBChannels, message);
  React.useEffect(() => {
    if (message.message["hasAttachments"]) {
      ChatService.getMessageById({
        userId: user?.id,
        channel: message.channel,
        messageId: message?.message?.id,
      }).then((res) => {
        setAttachments(res["attachments"]);
      });
    }
  }, [user]);

  const providerSubtitle = getProviderSubtitle(user);
  const bgColorClassName = getUserMessageColor(user);
  const messageStyles = currentUser
    ? "bg-grey-10"
    : messageActions
      ? "bg-white border border-grey-light"
      : bgColorClassName;

  const sanitizedText = sanitizeHtml(text, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(["details", "summary"]),
  });

  const textContent = (
    <Imgify
      className="text-sm font-normal"
      targetSelector=".linkified"
      imgContainerClass="flx-cell-equal"
    >
      <p
        className="text-sm whitespace-pre-wrap text-primary leading-normal"
        dangerouslySetInnerHTML={{
          __html: linkifyHtml(
            hideFullMessage ? renderTruncatedMessage(sanitizedText) : sanitizedText,
          ),
        }}
      />
    </Imgify>
  );

  const messageActionComponents = compact(
    messageActions?.actions.map((action) => action.component) || [],
  );

  return (
    <div className={`w-5/6 mt-6`}>
      {!currentUser && !hideUserAvatar && (
        <UserAvatar
          author={user}
          bgColorClassName={bgColorClassName}
          subtitle={providerSubtitle}
          setBioModalContent={setBioModalContent}
          setBioModalIsOpen={setBioModalIsOpen}
        />
      )}
      <div className="rounded overflow-hidden">
        {messageActions == null ? (
          <div className={cn("force-wrap p-4", messageStyles)}>
            {/* this is how we display message attachments sent from pdb after the message infrastructure rework.  */}
            {attachments &&
              attachments.map((a) => {
                return (
                  <Imgify
                    key={a}
                    className="text-sm font-normal"
                    targetSelector=".linkified"
                    imgContainerClass="flx-cell-equal"
                    forceDownload={true}
                    externalFile={true}
                  >
                    <p
                      className="text-sm whitespace-pre-wrap text-primary leading-normal"
                      dangerouslySetInnerHTML={{
                        __html: linkifyHtml(a),
                      }}
                    />
                  </Imgify>
                );
              })}
            {/* else, pre-infrastructure-rework the attachments are sent in the text as links and rendered with Linkify */}
            <Linkify
              properties={{
                target: "_blank",
                "data-linkified": true,
                "aria-describedby": "new-window-2",
              }}
            >
              {textContent}
            </Linkify>

            <div
              className={`flex items-baseline ${renderReadMore ? "justify-between" : "justify-end"} tems-end mt-4`}
            >
              {renderReadMore && (
                <div
                  className="cursor-pointer"
                  onClick={() => setHideFullMessage(!hideFullMessage)}
                >
                  <Typography variant="h5" className="text-cornflower-100">
                    {hideFullMessage ? "Read More" : "Read Less"}
                  </Typography>
                </div>
              )}
              <div className="text-forest-60 text-xs">{timeSent}</div>
            </div>
          </div>
        ) : (
          <>
            <div className={cn("force-wrap p-4", messageStyles)}>
              <Linkify
                properties={{
                  target: "_blank",
                  "data-linkified": true,
                  "aria-describedby": "new-window-2",
                }}
              >
                {messageActions?.title && (
                  <div className="bg-sand-light p-5 w-full text-forest-100">
                    <div className="font-bold font-lg mb-3">{messageActions?.title}</div>
                    <div className="font-medium">{messageActions?.subtitle}</div>
                  </div>
                )}
                {messageActionComponents.length == 0 && textContent}
              </Linkify>
              <MessageActions message={message} />
            </div>
            <div className="mt-4 text-right text-forest-60 text-xs">{timeSent}</div>
          </>
        )}
      </div>
    </div>
  );
};
