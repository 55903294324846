/** @format */

import React from "react";

import CircularButton from "./CircularButton";

interface CircularButtonListProps {
  onClick: (value: string) => void;
  selectedScore?: string;
}

const CircularButtonList = ({ onClick, selectedScore }: CircularButtonListProps) => {
  return (
    <>
      <div className="flex flex-row flex-wrap justify-center items-center lg:max-w-xl md:max-w-xs mx-auto max-w-xs overflow-auto">
        {["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"].map((value, idx) => {
          return (
            <div key={value} className={`${idx == 0 ? "" : "ml-1"}`}>
              <CircularButton onClick={onClick} value={value} selected={value == selectedScore} />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CircularButtonList;
