/** @format */

import type { FC } from "react";
import type React from "react";

import classnames from "classnames";

interface PaperProps {
  /**
   * Additional classnames to be appended to existing classes.
   */
  className?: string;

  /**
   * The content of the component.
   */
  children?: React.ReactNode;

  /**
   * Shadow depth.
   * @default 1
   */
  elevation?: 0 | 1;

  /**
   * If `true` round the edge of the paper.
   * @default true
   */
  rounded?: boolean;
}

export const Paper: FC<PaperProps> = ({ className, children, elevation = 1, rounded = true }) => {
  const elevationMapper: { [key: string]: string } = {
    0: "shadow-none",
    1: "shadow",
  };

  return (
    <div className={classnames(elevationMapper[elevation], rounded && "rounded-lg", className)}>
      {children}
    </div>
  );
};
