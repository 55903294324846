/** @format */

import React from "react";

export const WeightLossJournalHeader = () => (
  <div className="p-4 bg-sand-40 flex items-center justify-center">
    <img
      className="mr-4"
      src={"/assets/weight-loss-journal.svg"}
      height="40"
      width="40"
      alt="Icon of a person scaling a bar chart, reaching for a flag at the highest bar."
    />
  </div>
);
