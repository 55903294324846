/** @format */

import React from "react";

import { useStoreDispatch, useStoreState } from "src/v2/models";

export const useMembership = () => {
  const dispatch = useStoreDispatch();
  const membership = useStoreState((state) => state.membership.membership);

  React.useEffect(() => {
    dispatch.membership.fetchMembership();
  }, []);

  return {
    membership,
    planCode: membership?.plan_code,
    activePlanCode: membership?.active && membership?.plan_code,
  };
};
