/** @format */

import type { FieldInputProps, FieldMetaState } from "react-final-form";

import React from "react";

import classNames from "classnames";
import Cleave from "cleave.js/react";

import type { BaseFieldProps } from "@alphamedical/components";

import { FieldErrorNew, TextFieldNew } from "@alphamedical/components";
import { Datepicker } from "src/v2/designSystem";

import type { DynamicTextField as IDynamicTextField } from "src/components/DynamicForm/types";

import { fieldValidators } from "src/components/DynamicForm/Processors";

import type { DynamicFieldProps } from "./index";

import "cleave.js/dist/addons/cleave-phone.us";

type DynamicTextFieldProps = Omit<DynamicFieldProps, "field"> &
  Partial<Omit<BaseFieldProps, "type">> & {
    field: IDynamicTextField;
  };

const DEFAULT_CLEAVE_OPTIONS = {
  date: {
    placeholder: "mm-dd-yyyy",
    options: {
      delimiter: "-",
      date: true,
      datePattern: ["m", "d", "Y"],
    },
  },
  phone: {
    placeholder: "987-654-3210",
    options: {
      delimiter: "-",
      phone: true,
      phoneRegionCode: "US",
    },
  },
  zipcode: {
    placeholder: "ZIP code",
    options: {
      delimiter: "-",
      delimiterLazyShow: true,
      numericOnly: true,
      blocks: [5, 4],
    },
  },
};

export const DynamicTextField = ({ field, ...props }: DynamicTextFieldProps) => {
  if (field.subtype) {
    if (field.subtype === "date") {
      const validate = field.validate && fieldValidators[field.validate];
      return (
        <Datepicker
          name={field.key}
          validate={validate}
          required={field.required}
          defaultValue={field.defaultValue}
          placeholder={field.placeholder}
          label={field.label}
          includeCurrentDate={field.includeCurrentDate}
          includeFutureDates={field.includeFutureDates}
          includePastDates={field.includePastDates}
        />
      );
    }
    if (["phone", "zipcode"].indexOf(field.subtype) !== -1) {
      const cleaveProps = DEFAULT_CLEAVE_OPTIONS[field.subtype];
      const { label, required } = field;

      return (
        <TextFieldNew name={field.key} {...props}>
          {({ input, meta }: { input: FieldInputProps<any>; meta: FieldMetaState<any> }) => {
            const { error, touched } = meta;
            const showErrors = error && touched;

            return (
              <>
                {label && (
                  <p
                    className={classNames(
                      `text-sm mb-2 ${showErrors ? "text-error" : "text-forest-100"}`,
                    )}
                  >
                    {label}
                    {required && "*"}
                  </p>
                )}
                <Cleave
                  {...input}
                  placeholder={cleaveProps.placeholder || field.label}
                  autoComplete="off"
                  options={cleaveProps.options}
                />
                {showErrors && <FieldErrorNew name={field.key} />}
              </>
            );
          }}
        </TextFieldNew>
      );
    }
  }

  return (
    <TextFieldNew
      customRequiredFieldError="Required"
      {...props}
      name={field.key}
      label={field.label}
      type={field.subtype === "textarea" ? "textarea" : "text"}
    />
  );
};
