/** @format */

import type { Outline } from "src/v2/models/outline";

/**
 * Calculates the progress of a user in a given outline based on the current page.
 *
 * @format
 * @param outline The outline containing the stages and pages.
 * @param pageKey The key of the current page.
 * @param maxStageNumber (Optional) The maximum number of stages allowed for the progress calculation. If the number of stages in the outline is greater than `maxStageNumber`, the progress will be calculated based on the total number of pages instead of the number of pages in the current stage.
 * @returns An array with the progress for each stage of the outline.
 */
export const calculateProgress = (outline: Outline, pageKey = "", maxStageNumber?: number) => {
  if (!outline || !pageKey) {
    return [0];
  }

  const { stages, pages } = outline;
  const currentPage = pages.find((page) => page.key === pageKey);

  if (!currentPage) {
    return [0];
  }

  const currentStagePages = pages.filter((page) => page.stage === currentPage.stage);
  const pageCount = currentStagePages.length;
  const pageIdx = currentStagePages.findIndex((page) => page.key === currentPage.key);
  const stageIdx = stages.findIndex((stage) => stage.key === currentPage.stage);

  if (maxStageNumber && stages.length > maxStageNumber) {
    return [((pageIdx + 1) / pages.length) * 100];
  }

  return stages.map((_, i) => {
    if (i === stageIdx) {
      return ((pageIdx + 1) * 100) / pageCount;
    } else {
      return i > stageIdx ? 0 : 100;
    }
  });
};
