/** @format */

import React from "react";
import { useFormState } from "react-final-form";

import { ButtonPdb } from "src/v2/designSystem";

import type { DynamicFormPage } from "src/components/DynamicForm/types";

interface NextButtonProps {
  page: DynamicFormPage;
  defaultLabel?: string;
}

export const NextButton = (props: NextButtonProps) => {
  const { submitting } = useFormState();
  const nextButtonText = props.page.overrideNextText || props.defaultLabel || "Next";
  return (
    <ButtonPdb variant="primary" disabled={submitting} className="mt-2">
      {submitting ? "Saving" : nextButtonText}
    </ButtonPdb>
  );
};
