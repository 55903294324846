/** @format */

import React from "react";

interface ErrorPromptProps {
  text: string;
}
export const ErrorPrompt = ({ text }: ErrorPromptProps) => {
  return (
    <div className="flex mt-2">
      <img className={`mr-2`} src={"/assets/error-filled-red.svg"} alt={"error icon"} />
      <p className={"text-error text-sm"}>{text}</p>
    </div>
  );
};
