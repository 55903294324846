/** @format */

import React from "react";
import { Field } from "react-final-form";

import type { ExifImageRotateCallbackPropsType } from "src/utils/image/types";

import { processImage } from "src/utils/image/process";

import { FieldError } from "./error";

interface ImageFieldProps {
  name: string;
  bgImageUrl?: string;
  message?: string;
}

const exifCallBack = (input: any) => (cb: ExifImageRotateCallbackPropsType) => {
  const image = cb.canvas.toDataURL("image/jpeg", 1);
  input.onChange(image);
};

const handleAnswerChange = (input: any) => (event: any) => {
  const file = event.target.files[0];
  if (file) {
    event.preventDefault();
    processImage(event.target.name, file, exifCallBack(input));
  }
};

export const ImageField = (props: ImageFieldProps) => (
  <Field name={props.name}>
    {({ input, meta }) => {
      const hasBgImage = !!props.bgImageUrl;
      return (
        <>
          <input
            name={`upload-for-${input.name}`}
            id={`upload-for-${input.name}`}
            className="display-none"
            type="file"
            accept="image/*"
            onChange={handleAnswerChange(input)}
          />
          <label htmlFor={`upload-for-${input.name}`}>
            <div className="max-w-sm w-full m-auto">
              {(input.value && (
                <img
                  className={`
                          flex flex-col
                          bg-white
                          border border-sky
                          cursor-pointer
                        `}
                  src={input.value}
                  alt="Display for attached file"
                />
              )) || (
                <>
                  <div
                    className={`
                            flex flex-col
                            h-64 bg-white
                            ${!hasBgImage ? "border border-sky" : ""}
                            cursor-pointer
                            bg-center bg-contain bg-no-repeat
                          `}
                    style={{ backgroundImage: hasBgImage ? `url(${props.bgImageUrl})` : undefined }}
                  >
                    <div className="flex-1" />
                    {!hasBgImage && (
                      <div className="h-1/3 flex justify-center items-center text-sky fas fa-plus text-6xl" />
                    )}
                    <div className="h-1/3 flex items-center text-center max-w-xs m-auto">
                      {props.message || "Tap to take or upload a photo to us"}
                    </div>
                  </div>
                  <input {...input} id={input.name} type="hidden" value={input.value} />
                </>
              )}
              <FieldError name={props.name} />
            </div>
          </label>
        </>
      );
    }}
  </Field>
);
