/** @format */

import type React from "react";

import { useDynamicFormUrl } from "src/components/DynamicForm/hooks";

import type { ValidationErrorContentProps } from "./types";

export const PaymentFailureMessage: React.FC<ValidationErrorContentProps> = () => {
  const paymentPageUrl = useDynamicFormUrl("payment");
  return (
    <>
      <p>There was a problem processing your fee.</p>
      <a
        href={paymentPageUrl || "#"}
        className="mt-4 block btn bg-violator text-white w-full font-light"
      >
        <div className="inline-block">
          Please choose a new form of payment <span className="fas fa-arrow-right text-sm" />
        </div>
      </a>
    </>
  );
};
