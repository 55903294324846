/** @format */

import React from "react";
import { Field } from "react-final-form";

import { FieldError } from "./error";

interface RadioFieldProps {
  name: string;
  options?: { value: string; label: string }[];
}

export const RadioField = (props: RadioFieldProps) => (
  <>
    {(props.options || []).map((option) => (
      <label
        key={`${props.name}-radio-${option.value}`}
        className="block normal-case field"
        tabIndex={0}
        onKeyPress={(e: any) => {
          if (e.which === 32) {
            e.target.click();
          }
        }}
      >
        <Field name={props.name} component="input" type="radio" value={option.value} />
        <div className="checked-field p-4 my-3 bg-sky-40 t-2 rounded-sm">{option.label}</div>
      </label>
    ))}
    <FieldError name={props.name} />
  </>
);
