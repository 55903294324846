/** @format */

import type { Action, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";

import type { SkuMap } from "src/v2/types/skus";

import { _GET, getTokenHeaderV2 } from "src/helpers/http";

export interface SkuModel {
  skus: SkuMap;

  setSkus: Action<SkuModel, SkuMap>;

  fetchSkus: Thunk<SkuModel, { condition: string }>;
}

export const skuStore: SkuModel = {
  skus: {},

  setSkus: action((state, skus) => {
    state.skus = { ...state.skus, ...skus };
  }),

  fetchSkus: thunk(async (actions, { condition }) => {
    const skus = await _GET(`/v2/skus?condition=${condition}`, {}, getTokenHeaderV2()).catch(
      (e) => {
        throw e.response;
      },
    );
    actions.setSkus(skus);
  }),
};
