/** @format */

import type { EligibilityValidator } from "src/v2/utils/page/validators";

export interface NoSelectedBloodPressureValues {
  "no-blood-pressure-taken": string;
  "blood-pressure-taken": string;
}

export interface HighBloodPressureValues {
  "blood-pressure": string;
}

export const INVALID_TYPE_BLOODPRESSURE = "blood-pressure";
export const INVALID_TYPE_HIGH_DIASTOLIC = "high-diastolic";
export const INVALID_TYPE_HIGH_SYSTOLIC = "high-systolic";

export const isBloodPressureNoSelected: EligibilityValidator = (
  values: NoSelectedBloodPressureValues,
) => {
  const invalid =
    values["blood-pressure-taken"] === "no" &&
    ["progestin-only", "self-injection"].indexOf(values["no-blood-pressure-taken"]) > -1;

  return {
    invalid,
    invalidType: INVALID_TYPE_BLOODPRESSURE,
  };
};

export const isBloodPressureHigh: EligibilityValidator = (values: HighBloodPressureValues) => {
  const matches = (values["blood-pressure"] || "").match(/^\s*(\d+)\s*\/\s*(\d+)\s*$/);
  const diastolic = matches && parseInt(matches[2], 10);
  const systolic = matches && parseInt(matches[1], 10);
  const highDiastolic = (diastolic && diastolic >= 140) || false;
  const highSystolic = (systolic && systolic >= 180) || false;

  if (highSystolic) {
    return {
      invalid: highSystolic,
      invalidType: INVALID_TYPE_HIGH_SYSTOLIC,
    };
  }

  if (highDiastolic) {
    return {
      invalid: highDiastolic,
      invalidType: INVALID_TYPE_HIGH_DIASTOLIC,
    };
  }

  return {
    invalid: false,
    invalidType: "",
  };
};
