/** @format */

import React from "react";

import type { ConditionTreatmentInfo } from "src/v2/constants";

import CarrotSvg from "src/v2/components/Svgs/CarrotSvg";

interface IconTextLinkProps {
  treatmentInfo: ConditionTreatmentInfo;
  showBorderTop: boolean;
  showRightCarrot?: boolean;
  textClass?: string;
  onClick?: () => void;
  openInNewWindow?: boolean;
}

/**
 * Creates a card that is clickable, icon on the left, title in the middle, chevron on the right.
 *
 * @param treatmentInfo
 * @param showBorderTop
 * @param showRightCarrot
 * @param textClass
 * @param onClick
 * @param openInNewWindow
 * @constructor
 */
const IconTextLink = ({
  treatmentInfo,
  showBorderTop,
  showRightCarrot = true,
  textClass = "",
  onClick,
  openInNewWindow = false,
}: IconTextLinkProps) => {
  return (
    <a
      onClick={onClick}
      href={treatmentInfo.url}
      target={openInNewWindow ? "_blank" : ""}
      rel={"noreferrer"}
    >
      {showBorderTop && <div className="border-t mb-5" style={{ borderColor: "#D0D0D1" }}></div>}
      <div className="flex justify-between mb-5">
        <div className="flex items-center">
          <img className="mr-5" src={treatmentInfo.icon} alt={treatmentInfo.iconAlt} />
          <div className={textClass}>{treatmentInfo.title}</div>
        </div>
        {showRightCarrot && <CarrotSvg fill={"#1C4A46"} />}
      </div>
    </a>
  );
};

export default IconTextLink;
