/** @format */

import React from "react";

import type { RegisterKitStage } from "src/routes/RegisterKit";

import { Button } from "src/v2/components/Button";

interface RegisterKitSuccessProps {
  setStage: (param: RegisterKitStage) => void;
  kit: string;
}

export const RegisterKitSuccess = ({ setStage, kit }: RegisterKitSuccessProps) => {
  return (
    <div>
      <div className="flex flex-col items-center my-8 px-4 text-center">
        <h1 className="text-2xl">Registration Complete</h1>
        <img
          src="/assets/green-circle-checkmark.svg"
          alt=""
          role="presentation"
          className="w-12 mt-4 mb-2"
        />
        <p className="my-6">
          You've registered the
          <br />
          <strong>{kit} testing kit</strong>
        </p>
        <p className="mb-8">
          Next, please follow the instructions printed on the card included in the kit.
        </p>
        <Button
          href={`/my-cases`}
          cxMargin="mb-6"
          hrefStyles="w-full"
          bgColor="transparent"
          borderColor="forest-40 hover:border-forest-20"
          textColor="primary"
          hoverBgColor="forest-20"
        >
          Go to Home
        </Button>
        <Button
          onClick={() => setStage("input-kit")}
          bgColor="transparent"
          borderColor="forest-40 hover:border-forest-20"
          textColor="primary"
          cxMargin="mb-6"
          hoverBgColor="forest-20"
        >
          Register Another Test Kit
        </Button>
      </div>
    </div>
  );
};
