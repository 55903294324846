/** @format */

import type { match } from "react-router-dom";

import React from "react";
import { Link, useHistory } from "react-router-dom";

import { isEmpty, map } from "lodash";

import type { TreatmentPlanInfoSchema } from "src/api/models/TreatmentPlanInfoSchema";
import type { TodoProps } from "src/v2/routes/MyCases/MyCasesV2";
import type { Subscription } from "src/v2/types/subscription";

import { ActionItemsService, ConsultsService } from "src/api";
import GenericCard from "src/components/Cards/GenericCard";
import { TestKitCards } from "src/components/Cards/TestKitCards";
import { YourOrdersCards } from "src/components/Cards/TimelineCard/YourOrdersCards";
import { YourPrescriptionCards } from "src/components/Cards/YourPrescriptionCards";
import { YourReturnVisitCard } from "src/components/Cards/YourReturnVisitCard";
import { YourVisitCards } from "src/components/Cards/YourVisitCards";
import ImageHeading from "src/components/ImageHeading";
import { Loader } from "src/components/Loader";
import { PageSectionTitle } from "src/components/PageSectionTitle";
import { PageWrapper } from "src/components/PageWrapper";
import { expiresIn30Days, getFollowupUrl } from "src/helpers/visitDetails";
import { useThunk } from "src/utils/hooks";
import { IconTextChevron, Line } from "src/v2/components";
import TreatmentInfo from "src/v2/components/TreatmentInfo/TreatmentInfo";
import {
  CONSULTS_WITH_CHECKUP,
  getConditionInfo,
  hasConditionTreatmentInfo,
} from "src/v2/constants";
import { useStoreDispatch, useStoreState } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";
import { getLoadedReturnVisitByService } from "src/v2/utils/returnVisitUtils";

interface MatchParams {
  condition: string;
}

export const VisitDetails = ({ match }: { match: match<MatchParams> }) => {
  const dispatch = useStoreDispatch();
  const condition = match.params.condition;
  const { medicationsByCondition } = useStoreState((state) => state.medications);
  const { skus } = useStoreState((state) => state.skus);
  const { externalSubscriptions, recurlySubscription } = useStoreState(
    (state) => state.subscriptions,
  );
  const [patientTodo, setPatientTodo] = React.useState<TodoProps[]>([]);
  const [pvsForService, setPvsForService] = React.useState<TreatmentPlanInfoSchema[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [returnVisit, setReturnVisit] = React.useState<any>();
  const { currentService } = useStoreState((state) => state.service);
  const userId = useCurrentUser().id;
  const history = useHistory();

  const [fetchingService] = useThunk(() => {
    return dispatch.service.fetchCurrentService({
      includeAll: true,
      service_type: condition,
      include: ["consults"],
    });
  });

  const [fetchingExternalSubscriptions] = useThunk(() => {
    return dispatch.subscriptions.fetchExternalSubscriptions(condition);
  });
  const [fetchingSkus] = useThunk(() => {
    return dispatch.skus.fetchSkus({ condition });
  });

  const [fetchingRecurlySubscription] = useThunk(() => {
    return dispatch.subscriptions.fetchRecurlySubscriptions("mental-health");
  });

  const [fetchingMedications] = useThunk(() => {
    return dispatch.medications.fetchMedicationsByCondition({
      params: { conditionKey: condition, filterInvalidStatuses: true, descOrder: true },
    });
  });

  const patientHasAtLeastOneApprovedConsult =
    currentService &&
    currentService.consults &&
    (currentService.consults.some((consult) => consult.status === "APPROVED") ||
      condition === "sti-testing-kit" ||
      condition === "diabetes");

  const hasACurrentSku =
    currentService &&
    currentService.subscriptions &&
    currentService.subscriptions.some(
      (subscription) => typeof subscription.current_sku === "string",
    );
  const conditionInfo = getConditionInfo(condition);

  const testKits =
    medicationsByCondition &&
    medicationsByCondition.filter(
      (medication) => medication.sku && medication.sku.type === "test_kit",
    );

  React.useEffect(() => {
    ActionItemsService.fetchReturnVisits({ userId: userId })
      .then((returnVisits) => {
        if (currentService) {
          const serviceReturnVisit = getLoadedReturnVisitByService(currentService, returnVisits);
          setReturnVisit(serviceReturnVisit);
        }
      })
      .finally(() => setIsLoading(false));
  }, [currentService?.condition?.key]);

  React.useEffect(() => {
    const _todos: TodoProps[] = [];
    if (currentService && currentService.subscriptions && currentService.subscriptions.length > 0) {
      const _subs = currentService.subscriptions
        .filter((sub) => sub && sub.expires_on && expiresIn30Days(sub.expires_on))
        .map((sub) => {
          return {
            imageSrc: conditionInfo.imageSrc,
            description: `Your prescription for ${conditionInfo.label} will be expiring in 30 days. Please take a moment to renew your prescription.`,
            ctaText: "Renew Your Prescription",
            ctaLink: getFollowupUrl(condition),
            tracking: {
              url: "pdb:prescription:renewal:cta",
              payload: { condition },
            },
          };
        });
      _todos.push(..._subs);
    }
    _todos.push({
      title: conditionInfo.label,
      imageSrc: conditionInfo.imageSrc,
    });
    setPatientTodo(_todos);

    ConsultsService.getUserPvsInfo({ userId: userId }).then((res) => {
      setPvsForService(res.filter((pvs) => pvs.condition === currentService?.name));
    });
  }, [recurlySubscription, currentService && currentService.subscriptions]);
  const subscriptions: Subscription[] = map(medicationsByCondition, "subscription");
  const summaryText =
    pvsForService.length === 0 ? null : pvsForService.length === 1 ? "Summary" : "Summaries";
  const pvsId = pvsForService.length === 1 ? pvsForService[0].id : null;
  return (
    <PageWrapper>
      <Loader
        show={
          fetchingService ||
          fetchingSkus ||
          fetchingExternalSubscriptions ||
          fetchingRecurlySubscription ||
          fetchingMedications ||
          !patientHasAtLeastOneApprovedConsult ||
          isLoading
        }
        message={
          !fetchingService && !patientHasAtLeastOneApprovedConsult
            ? "No Visit Details Found"
            : "Loading..."
        }
        screen={true}
        dontShowLoader={!fetchingService && !patientHasAtLeastOneApprovedConsult}
      >
        <div className="max-w-xl mx-auto my-8">
          <div className="flex flex-col">
            {patientTodo.length > 0 && (
              <div className="pb-4">
                <ImageHeading
                  title={patientTodo[0].title || patientTodo[patientTodo.length - 1].title}
                  imageSrc={patientTodo[0].imageSrc || patientTodo[patientTodo.length - 1].imageSrc}
                  description={patientTodo[0].description}
                  ctaLink={patientTodo[0].ctaLink}
                  ctaText={patientTodo[0].ctaText}
                />
              </div>
            )}
            {(CONSULTS_WITH_CHECKUP.filter((el) => el !== "sti-testing-kit").indexOf(condition) !==
              -1 ||
              condition === "mental-health") &&
              returnVisit && (
                <YourReturnVisitCard
                  currentService={currentService}
                  condition={condition}
                  returnVisit={returnVisit}
                />
              )}
            {hasConditionTreatmentInfo(condition) && <TreatmentInfo conditionKey={condition} />}
            {summaryText && (
              <>
                <IconTextChevron
                  planAction=""
                  text={`Your Post-Visit ${summaryText}`}
                  icon="/assets/clipboard-empty.svg"
                  iconAlt="Clipboard Icon"
                  onClick={() => history.push(`/post-visit-summary${pvsId ? "/" + pvsId : ""}`)}
                />
                <Line />
              </>
            )}
            {currentService &&
              currentService.condition &&
              currentService.condition.key === "mental-health" && (
                <>
                  <PageSectionTitle title="Mental Health Resources" cxMargin="m-0 mb-4 mt-8" />
                  <GenericCard
                    key={`mental-health-track-card`}
                    title={`Track Your Mental Health`}
                    imageSrc={"/assets/line-chart.svg"}
                    detailsLink={`/mental-health-records`}
                    detailsText={"View Your Scores"}
                    extraClassName={`mb-4`}
                  />
                  <GenericCard
                    key={`self-care-guide-card`}
                    title={`Self Care Guide`}
                    imageSrc={"/assets/hands.svg"}
                    detailsLink={`/self-care`}
                    detailsText={"View Guide"}
                    extraClassName={`mb-4`}
                  />
                </>
              )}

            {medicationsByCondition && medicationsByCondition.length > 0 && (
              <div>
                <div className="flex justify-between">
                  <PageSectionTitle title="Your Orders" cxMargin="m-0 mb-4 mt-8" />
                  {medicationsByCondition.length > 3 && (
                    <Link
                      to="/orders"
                      className="font-bold primary-60 uppercase text-sky-dark text-sm"
                    >
                      View All Orders <span className="fas fa-arrow-right text-sm" />
                    </Link>
                  )}
                </div>
                <YourOrdersCards
                  medicationsByCondition={medicationsByCondition}
                  skus={skus}
                  condition={condition}
                />
              </div>
            )}
            {hasACurrentSku &&
              currentService &&
              !isEmpty(subscriptions) &&
              Boolean(
                subscriptions.find(
                  (subscription) => subscription.sku && subscription.sku.type !== "test_kit",
                ),
              ) && <PageSectionTitle title={"Your Prescriptions"} cxMargin="m-0 mb-4 mt-8" />}
            {!isEmpty(subscriptions) && (
              <YourPrescriptionCards
                subscriptions={subscriptions}
                condition={condition}
                externalSubscriptions={externalSubscriptions}
              />
            )}
            {testKits && testKits.length > 0 && currentService && (
              <div>
                <PageSectionTitle title="Your Test Kits" cxMargin="m-0 mb-4 mt-8" />
                <TestKitCards testKits={testKits} condition={currentService.type} />
              </div>
            )}
            {currentService && currentService.consults && currentService.consults.length > 0 && (
              <div>
                <PageSectionTitle title="Your Visits" cxMargin="m-0 mb-4 mt-8" />
                <YourVisitCards consults={currentService.consults} condition={condition} />
              </div>
            )}
          </div>
        </div>
      </Loader>
    </PageWrapper>
  );
};
