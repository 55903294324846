/** @format */

import React from "react";

import config from "src/config";

const Consent = () => (
  <div className="h-full mb-4 text-base leading-normal">
    <div className="my-3">
      This PATIENT AGREEMENT (this “Agreement”) is entered between the undersigned party (“you” or
      “Patient”) and Clarity Health, P.C. (for AL, AR, AZ, CA, CO, CT, DE, DC, FL, GA, HI, IA, ID,
      KY, LA, MA, MD, ME, MI, MN, MS, MT, ND, NE, NH, NC, NM, NV, NY, OH, OK, OR, PA, RI, SC, SD,
      TN, VA, VT, UT, WA, WI, WV, WY Patients), Clarity Health of Alaska, PC, Clarity Health of
      Kansas, P.A, Clarity Medical of New Jersey P.A, Clarity Health of Texas, PLLC (for IL, MO, TX
      Patients)(as applicable, “us” or “Medical Practice”).
      <ol className="my-3 pl-12" style={{ listStyleType: "upper-latin" }}>
        <li>
          You are 18 or older; less than 18 years old with parental consent, as needed, depending
          upon your state of residence regulations; or you are the parent or guardian of the minor
          patient and are legally authorized to give consent on his or her behalf. For parents or
          legal guardians, the terms “you, “your”, and “patient” used herein may also apply to your
          minor child (the patient) as appropriate based on the context.
        </li>
        <li>
          Medical Practice renders medical services through physicians duly qualified and licensed
          to practice medicine in your state (each a “Medical Practice Physician”), and through
          other duly licensed medical providers, such as nurse practitioners and physician’s
          assistants (each a “Medical Practice Provider”). Medical Practice Physicians and Medical
          Practice Providers are sometimes referred to collectively herein as “Health Care
          Professionals.”
        </li>
        <li>
          Medical Practice contracts with an independent third party, Alpha Medical Group, Inc, dba
          Alpha Medical (“Alpha Medical”) to provide management, administrative, and other
          non-medical services, including without limitation billing and collection of Service Fees
          (as defined below).
        </li>
        <li>
          You have requested medical services through the Alpha Medical App (as defined below), and
          you have been connected to a Health Care Professional to receive individualized health
          care services (your “Treatment”).
        </li>
        <li>
          This Agreement will take effect on the date that you sign this Agreement or the date you
          first receive services from Medical Practice under this Agreement (the “Effective Date”).
        </li>
      </ol>
    </div>

    <div className="my-3">
      The parties agree to enter into this Agreement in accordance with the following terms and
      conditions:
      <ol className="pl-12">
        <li>
          Physician-Patient Relationship. You acknowledge that a physician-patient relationship is
          not established until you have been in communication with a Health Care Professional about
          your Treatment (as defined below). To facilitate your Treatment, Medical Practice licenses
          access to a web-based application (the “Alpha Medical App”) that is owned by Alpha
          Medical. The use of the Alpha Medical App alone does not create a patient relationship
          with Medical Practice.
        </li>
        <li>
          The Services. By entering into this Agreement, you represent and warrant that you are (a)
          at least 18 years old; (b) have parental consent if less than 18 years old, depending upon
          your state of residence regulations; or (c) you are the parent or legal guardian of the
          minor patient and are legally authorized to give consent on his or her behalf, as
          applicable.
        </li>
        <li>
          <p>Consent to Services.</p>
          <ul className="pl-12">
            <li>
              By entering into this Agreement, you agree that you have requested to receive medical
              treatment and services in exchange for payment to Medical Practice. You consent to the
              rendering of medical treatment and services as considered necessary and appropriate by
              your Health Care Professional at the time of Treatment. You have the right to decline
              treatment and services at any time during the course of Treatment but you may be
              responsible for any Service Fees (as defined below) already paid.
            </li>
            <li>
              You acknowledge that no assurances or guarantees have been made to you by Medical
              Practice or the Health Care Professionals concerning the outcome and/or results of any
              medical treatment or services.
            </li>
            <li>
              You acknowledge that some medications, including medications which treat anxiety and
              depression, if stopped abruptly, can cause a relapse of your medical condition and/or
              withdrawal symptoms.
            </li>
            <li>
              During the course of Treatment, the Health Care Professionals may discuss the details
              of your or the patient’s medical or health history or personal health information. You
              may also be asked for proof of identity with a driver’s license or other legal
              documents. If you are a parent or guardian, you agree to provide evidence of your
              authority to consent on behalf of the patient upon our request.
            </li>
            <li>
              <p>
                You should seek emergency help or follow-up care when recommended by your Health
                Care Professional, and it is your responsibility to consult with your primary care
                physician or other health care professionals as recommended during or following
                Treatment. Notwithstanding the foregoing, for those who have signed up for Alpha
                Membership (as defined in the Terms of Use), Medical Practice may assist with the
                coordination of any such follow-up care. Medical Practice shall have no
                responsibility for the actions or omissions of your primary care physician or other
                health care providers, or for any consequences arising from your failure to seek
                appropriate medical treatment.
              </p>
              <p>
                Please do NOT use the Alpha Medical App for emergency or urgent medical matters. For
                all emergency or urgent medical matters, you should call 911 or go to the nearest
                emergency room or urgent care facility.
              </p>
            </li>
          </ul>
        </li>
        <li>
          Unable to Treat. Under certain limited circumstances, a Health Care Professional may
          determine, in their best judgment, that they are unable to provide medical treatment and
          services to you, based on information received or conduct occurring during the course of
          your Treatment; provided, however, that in no case shall a Health Care Professional make
          such a determination based on a Patient’s sex, sexual orientation, race, creed, color,
          national origin or disability.
        </li>
        <li>
          <p>Payment for Services.</p>
          <p>
            You agree to pay all fees or charges to the account you created through the Alpha
            Medical App (your “Account”) for the Treatment provided to you (or to a minor on whose
            behalf you are consenting to this Agreement) in accordance with the fees, charges, and
            billing terms in effect at the time a fee or charge is due and payable (the “Service
            Fees”). By providing Alpha Medical with your credit card number and associated payment
            information, you agree that Alpha Medical is authorized to immediately invoice your
            account for all fees and charges due and payable to Medical Practice hereunder and that
            no additional notice or consent is required. The third party services provider who
            manages the processing of your credit card payments may impose terms and conditions on
            you, which are independent of this Agreement, and you agree to comply with such terms.
            Medical Practice reserves the right to modify or implement a new pricing structure at
            any time prior to billing you for your initial payment or for future payments due.
            Medical Practice further reserves the right to correct any billing error or mistakes
            even if payment has already been requested or received.
          </p>
        </li>
        <li>
          Accuracy of Information. It is imperative that you provide accurate and truthful
          information about the identity and health and physical conditions of the patient, parent
          or guardian, and relevant family members during the registration process and to the Health
          Care Professionals. You represent and warrant to Medical Practice that all of the personal
          information you provide during this process is true and correct. Medical Practice and the
          Health Care Providers reserve the right to refuse to provide services if Medical Practice
          determines you have not provided complete or accurate health or identifying information.
          You also agree to keep your Account information current and accurate. If you move to a new
          address, or if any medical conditions or treatments change, you should update the
          information through the Alpha Medical App.
        </li>
        <li>
          Email and Electronic Communications. By entering into this Agreement, you consent to the
          use of unsecured email, mobile phone text messages, or other electronic methods of
          communication (“E-messages”) between you and Medical Practice and the Health Care
          Professionals for purposes of discussing personal information, including Protected Health
          Information (“PHI”) relevant to the patient’s Treatment or health records. You understand
          that E-Messages are typically a non-confidential means of communication and that there is
          a reasonable chance that a third-party may be able to intercept and see these messages
          (including people in your home or work who can access or view your phone, computer, or
          other devices, and/or third parties on the Internet such as server administrators and
          others who monitor Internet traffic). You have been informed of the risks, including but
          not limited to the risk with respect to the confidentiality of your Treatment, of
          transmitting your Protected Health Information by an unsecured means.
        </li>
        <li>
          Privacy Policy and Terms of Use. By entering into this Agreement, you are consenting to
          the terms of, and acknowledge that you have reviewed Alpha Medical’s Privacy Policy, which
          can be found <a href={`${config.wwwUrl}/terms/privacy/`}>here</a>, and the Terms of Use,
          which can be found <a href={`${config.wwwUrl}/terms/`}>here</a>. As set forth in the
          Privacy Policy, Medical Practice maintains the confidentiality of all patient records and
          other patient information in accordance with legal and professional standards, including,
          without limitation, the Health Insurance Portability and Accountability Act, as amended,
          and regulations promulgated thereunder, and applicable state privacy laws.
        </li>
        <li>
          Access to Records (for parents or guardians providing consent for patients under 18 years
          old). By entering into this Agreement, you acknowledge that either parent may access the
          patient’s Alpha Medical account and medical records and make treatment decisions for the
          patient, with exceptions under state laws. If either parent has limited rights to make
          medical decisions on behalf of the patient, you agree to disclose such limitations to
          Medical Practice prior to Treatment and provide Medical Practice with the legal documents
          stating so. In no event will Medical Practice or Alpha Medical be held liable for damages
          or liabilities of any kind arising out of your failure to disclose any such limitations on
          parental rights to Medical Practice prior to Treatment, and such damages or liabilities
          shall be subject to indemnification under Section 10 below.
        </li>
        <li>
          Indemnification. By entering into this Agreement, you acknowledge and agree that you shall
          be liable for, and shall indemnify, defend and hold harmless Medical Practice from any and
          all liability, loss, claim, lawsuit, injury, cost, damage or expense whatsoever (including
          reasonable attorneys’ fees and court costs) arising out of, incident to or in any manner
          occasioned by your performance or nonperformance of any of your duties or responsibilities
          under this Agreement, but only to the extent, and only in such amount, that such
          liability, loss, claim, lawsuit, injury, cost, damage or expense is not covered and paid
          by third party insurance.
        </li>
        <li>
          Limitation of Liability. TO THE MAXIMUM EXTENT ALLOWED BY LAW, IN NO EVENT WILL MEDICAL
          PRACTICE, OR ITS AFFILIATES, HEALTH CARE PROFESSIONALS, EMPLOYEES, OFFICERS, DIRECTORS OR
          AGENTS BE LIABLE FOR ANY CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE
          DAMAGES, INCLUDING WITHOUT LIMITATION THOSE RELATING TO LOST PROFITS OR THE COST OF
          SUBSTITUTE PRODUCTS OR SERVICES ARISING OUT OF OR IN CONNECTION WITH YOUR TREATMENT, OR
          YOUR INABILITY TO OBTAIN THE TREATMENT, WHETHER BASED ON CONTRACT, WARRANTY, PRODUCT
          LIABILITY, TORT OR OTHER LEGAL THEORY, EVEN IF MEDICAL PRACTICE HAS BEEN INFORMED OF THE
          POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY
          FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
        </li>
        <li>
          Severability. The provisions of this Agreement shall be deemed severable and if any
          portion is held invalid, illegal or unenforceable for any reason, the remainder of this
          Agreement shall be effective and binding upon the parties.
        </li>
        <li>
          Entire Agreement. This Agreement, including all corresponding attachments and exhibits
          represent the entire agreement between you and Medical Practice, and no other agreements,
          oral or written, have been entered into with respect to my Treatment and services provided
          by Medical Practice. This Agreement supersedes all prior agreements and communications of
          whatever type, whether written or oral, regarding your Treatment and services provided by
          Medical Practice. The execution of this Agreement by any electronic means or by other
          affirmative electronic acceptance shall constitute effective execution and delivery of
          this Agreement for any purposes whatsoever.
        </li>
        <li>
          Notice of Amendment. This Agreement may be amended by Medical Practice upon provision of
          thirty (30) days prior notice to you.
        </li>
        <li>
          Waiver. No delay or omission by either party to exercise any right or remedy under this
          Agreement shall be construed to be either acquiescence or waiver of the ability to
          exercise any right or remedy in the future. Any waiver of any terms and conditions hereof
          must be in writing, and signed by the parties hereto. A waiver of any term or condition
          hereof shall not be construed as a future waiver of the same or any other term or
          condition hereof.
        </li>
        <li>
          Governing Law. This Agreement shall be governed by and construed in accordance with the
          laws of the State of Delaware.
        </li>
        <li>
          Disclosure of Physician Information (CALIFORNIA PATIENTS ONLY). By entering this Agreement
          you acknowledge that you have received information regarding the applicable Medical
          Practice Physician’s name and license number, license status, highest level of academic
          degree, and board certification. Each Medical Practice Physician providing services in
          California is licensed to practice medicine in the state of California and may be a board
          certified physician or a licensed physician in his/her final year of residency who is not
          yet board-certified. You are encouraged to contact the Medical Board of California per the
          below contact information should you have any questions or concerns.
        </li>
      </ol>
    </div>
    <div className="my-3 text-center text-lg">
      <div>NOTICE</div>
      <div>Medical doctors are licensed and regulated</div>
      <div>by the Medical Board of California</div>
      <div>(800) 633-2322</div>
      <a className="block" href="mailto:webmaster@mbc.ca.gov">
        webmaster@mbc.ca.gov
      </a>
      <a className="block" href="www.mbc.ca.gov">
        www.mbc.ca.gov
      </a>
    </div>
    <div className="my-3">
      By clicking below, you agree to the above terms and conditions of this Agreement. Your
      acknowledgement represents you have received and read the terms of this Agreement, and you
      acknowledge that you have a right to receive a copy of this Agreement.
    </div>
  </div>
);

export default Consent;
