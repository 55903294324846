/** @format */

import React from "react";

import createDecorator from "final-form-focus";
import setFieldData from "final-form-set-field-data";

import type {
  MultiPageFormProps,
  MultiPageFormRenderProps,
  OnPageSubmitArgs,
} from "@alphamedical/components";

import { FormDebugInfo, FormPageSwitch, MultiPageForm } from "@alphamedical/components";

import type { FormPopupError } from "src/v2/models/api_types";
import type { Outline } from "src/v2/models/outline";

import { Loader } from "src/components/Loader";
import { useGetMembership } from "src/utils/hooks";
import { useStoreDispatch, useStoreState } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";

import type { DynamicFormPage } from "./types";

import { FormPage } from "./Pages";
import {
  checkConditionalsOnGenericForms,
  getLastAnsweredPageIdx,
} from "./Processors/fieldConditionals";
import { getChangedValues } from "./utils";
import { validatePage } from "./Validators";

const focusDecorator = createDecorator();

export type DynamicPageSubmitArgs<
  FormValues = Record<string, any>,
  InitialFormValues = Partial<FormValues>,
> = OnPageSubmitArgs<FormValues, InitialFormValues> & {
  outline: Outline;
  changedValues: Partial<FormValues>;
};

type DynamicPageRenderProps<FormValues, InitialFormValues> = MultiPageFormRenderProps<
  FormValues,
  InitialFormValues
> & {
  outline: Outline;
  page: React.ReactNode;
};

export interface DynamicFormProps<
  FormValues = Record<string, any>,
  FormContext = any,
  InitialFormValues = Partial<FormValues>,
> extends Omit<
    MultiPageFormProps<FormValues, InitialFormValues>,
    "onPageSubmit" | "onPageChange" | "children"
  > {
  outlineKey?: string;
  defaultPageKey?: string;
  outline?: Outline;
  context?: FormContext;
  onPageSubmit: (submitArgs: DynamicPageSubmitArgs<FormValues, InitialFormValues>) => any;
  onPageChange?: (callbackArgs: { pageIdx: number; page: DynamicFormPage }) => void;
  showDebugInfo?: boolean;
  allowFormAutoSubmit?: boolean;
  defaultValues?: InitialFormValues;
  children?: (
    renderProps: DynamicPageRenderProps<FormValues, InitialFormValues>,
  ) => React.ReactNode;
  validationError?: FormPopupError;
  clearValidationError?: () => void;
}

export const DefaultValueContext = React.createContext<Record<string, any> | undefined>(undefined);

export const FormContext = React.createContext<any>(undefined);

export function DynamicForm<
  FormValues = Record<string, any>,
  FormContext = any,
  InitialFormValues = Partial<FormValues>,
>({
  outlineKey,
  outline,
  onPageSubmit,
  onPageChange,
  context,
  showDebugInfo,
  defaultValues,
  defaultPageKey,
  validationError,
  clearValidationError,
  initialValues,
  ...props
}: DynamicFormProps<FormValues, FormContext, InitialFormValues>) {
  const dispatch = useStoreDispatch();
  const outlineModel = useStoreState((state) => state.outlines);
  const profile = useStoreState((state) => state.profile.profile);
  const { membership } = useGetMembership();
  const formOutline: Outline | undefined =
    outline || (outlineKey && outlineModel.outlines[outlineKey]) || undefined;
  const loading =
    !formOutline || (outlineKey && outlineModel.fetchingOutlines[outlineKey]) || false;
  const user = useCurrentUser();

  /**
   * special workaround for 'license_photo', which, if already saved in user.pii,
   * appears as though newly entered, but with the URL instead of a base64 data-string
   * may need to expand this workaround in the future to other fileField keys that render
   * data that can be entered through other means.
   */
  const existingFileMap: { [key: string]: string | undefined } = {
    license_photo: useStoreState((state) => state.profile?.profile?.pii?.license_photo),
  };

  React.useEffect(() => {
    if (outlineKey) {
      dispatch.outlines.fetchOutline({ key: outlineKey });
    }
  }, [outlineKey]);

  const [reEntryPageKey, setReEntryPageKey] = React.useState("");
  const [defaultPageUpdate, setDefaultPageUpdate] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (formOutline && initialValues && Object.keys(initialValues).length > 0) {
      const { pages } = formOutline;
      const lastAnsweredPageIdx = getLastAnsweredPageIdx(pages, initialValues, user.pii, profile);
      setReEntryPageKey(pages[lastAnsweredPageIdx].key);
    }
  }, [formOutline]);

  React.useEffect(() => {
    // When default Page updates we want to change the current page
    // This applys when a user uses the browser back and forth buttons
    setDefaultPageUpdate(true);
  }, [defaultPageKey]);

  return (
    <Loader show={loading}>
      {!!formOutline && (
        <DefaultValueContext.Provider value={defaultValues as Record<string, any>}>
          <FormContext.Provider value={context}>
            <MultiPageForm<FormValues, InitialFormValues>
              onPageChange={({ currentPageIdx, currentPageKey }) => {
                if (formOutline && onPageChange) {
                  const newPage = reEntryPageKey || currentPageKey;
                  const page = formOutline.pages.find((page) => page.key === newPage);
                  if (page) {
                    onPageChange({ pageIdx: currentPageIdx, page });
                  }
                }
              }}
              mutators={{ setFieldData: setFieldData as any }} // The setFieldData types don't work well with generic form values, so we cast to any here.
              decorators={[focusDecorator as any]} // Cast to any because the type librarys are not up to date
              defaultPageKey={defaultPageKey}
              onPageSubmit={(submitArgs) => {
                if (!formOutline) {
                  throw Error("Unable to submit page data. Missing outline");
                }
                const currentPage = formOutline.pages[submitArgs.currentPageIdx] as
                  | DynamicFormPage
                  | undefined;
                const validationErrors =
                  currentPage?.beforeSubmit &&
                  validatePage(
                    currentPage.beforeSubmit,
                    submitArgs.values as Record<string, any>,
                    currentPage,
                  );
                if (validationErrors) {
                  return validationErrors;
                }

                // blood loss value is a special case where the value is a nested object, we don't want to process it as a changed value
                const bloodLossValue = submitArgs.values["blood_loss"];
                const changedValues: Partial<FormValues> = {
                  ...((bloodLossValue
                    ? { blood_loss: bloodLossValue }
                    : {}) as Partial<FormValues>),
                  ...getChangedValues(submitArgs.values, submitArgs.form, existingFileMap),
                };

                return onPageSubmit({
                  outline: formOutline,
                  changedValues,
                  ...submitArgs,
                });
              }}
              initialValues={initialValues}
              {...props}
            >
              {(renderProps) => {
                if (reEntryPageKey || (defaultPageUpdate && defaultPageKey)) {
                  const newPage = reEntryPageKey || defaultPageKey;
                  if (newPage !== renderProps.currentPageKey)
                    renderProps.setCurrentPage(reEntryPageKey || defaultPageKey || "");
                  setDefaultPageUpdate(false);
                  setReEntryPageKey("");
                }
                const page = (
                  <form className="flex-1 mb-10" onSubmit={renderProps.handleSubmit}>
                    <FormPageSwitch>
                      {formOutline.pages
                        // This filter hides pages that don't have any visible fields due to conditionals.
                        .filter(
                          (page) =>
                            page.fields.filter((field) => {
                              // this will ignore accordion fields so the page will only show if another field is visible
                              if (field.type && field.type === "accordion") {
                                return false;
                              }
                              return checkConditionalsOnGenericForms(
                                field,
                                renderProps.values as Record<string, any>,
                                user.pii,
                                profile,
                                membership,
                              );
                            }).length !== 0,
                        )
                        .map((page) => (
                          <FormPage
                            cxPageTitle={"text-2xl"}
                            cxPageSubtitle={"text-base"}
                            data-page-key={page.key}
                            key={page.key}
                            allowAutoSubmit={props.allowFormAutoSubmit}
                            page={page}
                            validationError={validationError}
                            clearValidationError={clearValidationError}
                            handleSubmit={renderProps.handleSubmit}
                            goToPage={(pageKey: string, _values: any) => {
                              const pageIdx = formOutline.pages.findIndex(
                                (currPage) => currPage.key === pageKey,
                              );
                              if (pageIdx != -1) {
                                renderProps.setCurrentPage(pageKey);
                              }
                            }}
                          />
                        ))}
                    </FormPageSwitch>
                    {showDebugInfo && <FormDebugInfo />}
                  </form>
                );

                return props.children
                  ? props.children({ page, outline: formOutline, ...renderProps })
                  : page;
              }}
            </MultiPageForm>
          </FormContext.Provider>
        </DefaultValueContext.Provider>
      )}
    </Loader>
  );
}
