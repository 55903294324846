/** @format */

import React from "react";

import { Typography } from "@alphamedical/components";

interface BmiCategoryProps {
  bgColor: string;
  label: string;
  range: string;
}

const BmiCategory = ({ bgColor, label, range }: BmiCategoryProps) => {
  return (
    <div className="py-2 px-1 w-1/2">
      <div className={`bg-${bgColor} p-4`} style={{ background: bgColor }}>
        <Typography variant="h4" className="font-bold">
          {label[0].toUpperCase() + label.substr(1, label.length)}
        </Typography>
        <p className="text-center w-full">{range}</p>
      </div>
    </div>
  );
};

export default BmiCategory;
