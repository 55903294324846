/** @format */

import React from "react";
import { Link } from "react-router-dom";

import { Loader } from "src/components/Loader";
import config from "src/config";
import { Button } from "src/v2/components/Button";
import { useStoreDispatch, useStoreState } from "src/v2/models";

export const NotFound404 = () => {
  const dispatch = useStoreDispatch();
  const { authenticated } = useStoreState((state) => state.auth);
  const [authenticating, setAuthenticating] = React.useState<boolean>(true);
  React.useEffect(() => {
    dispatch.auth.checkAuth().finally(() => setAuthenticating(false));
  }, []);

  return (
    <Loader show={authenticating}>
      <main
        role="main"
        id="main"
        className="h-full flex flex-col justify-center text-center items-center"
      >
        <a href="/my-cases">
          <img src="/assets/alpha_forest.png" width="200" alt="Alphamedical Logo" />
        </a>
        <div className="text-4xl">Sorry, this page doesn't exist.</div>
        <a href="/my-cases" className={"mt-4"}>
          <Button extraClassNames={"rounded"}>Go to Patient Dashboard</Button>
        </a>
        {!authenticated && (
          <>
            <p className="my-5 text-2xl">
              Already a member?{" "}
              <Link className="text-primary hover:text-primary-light" to="my-cases">
                Sign in
              </Link>
            </p>
            <p className="text-2xl">
              Not a member yet?{" "}
              <a className="text-primary hover:text-primary-light" href={config.wwwUrl}>
                Get started
              </a>{" "}
              and see what we have to offer!
            </p>
          </>
        )}
      </main>
    </Loader>
  );
};
