/** @format */
import React from "react";

import { Toggle, Typography } from "@alphamedical/components";

import { PageWrapper } from "src/components/PageWrapper";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useStoreDispatch, useStoreState } from "src/v2/models";

const WeightLossJournalPreferences = () => {
  const dispatch = useStoreDispatch();
  const wljPreferences = useStoreState((state) => state.profile.wlj_preferences);
  useTopBar({
    title: "Weight loss journal preferences",
    variant: "nested",
    showBackButton: true,
  });

  React.useEffect(() => {
    dispatch.profile.fetchWLJPreferences();
  }, []);

  return (
    <PageWrapper cxPadding="p-6" cxMaxWidth="w-screen sm:max-w-xl">
      <div className="flex items-center justify-between mb-4">
        <Typography variant="body2">Share your data</Typography>
        <Toggle
          value={wljPreferences?.share_with_provider || false}
          onChange={(newValue) =>
            dispatch.profile.updateWLJPreferences({ share_with_provider: newValue })
          }
        />
      </div>
      <div>
        You can allow your care team to have access to your Weight Loss Journal data. If you share
        your data, your care team will only review it if you mention it in your visit answers or a
        message.
      </div>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(WeightLossJournalPreferences);
