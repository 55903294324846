/** @format */

export default {
  /**
   * Monthly Membership Signup Complete - Navigation Flow
   */
  navigationFlow: {
    title: "You're in, {{patientName}}! \n Welcome to Hello Alpha.",
    details:
      "Your benefits start now. You have 2 medical visits you can use for over 100 eligible medical conditions this month.",
    firstButtonText: "Start your visit",
    secondButtonText: "Go to Patient Dashboard",
  },

  /**
   * Monthly Membership Signup Complete - Visit Flow
   */
  visitFlow: {
    title: "You're in, {{patientName}}! \n Welcome to Hello Alpha.",
    details:
      "As an Alpha Member, your {{conditionName}} visit is free, and you have access to unlimited messaging with your Care Team.",
    visitText: "Are you ready to continue your visit for {{conditionName}}?",
    firstButtonText: "Continue my visit",
    noVisitButtonText: "Go to home page",
  },
};
