/** @format */
import type { GenericAddressSchema, LegacyConsultSchema, LegacyPharmacyAddress } from "src/api";

export const STATES = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export const ACTIVE_STATES = [
  "AK",
  "AL",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "HI",
  "IA",
  "ID",
  "IL",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VT",
  "WA",
  "WV",
  "WI",
  "WY",
];

export const STATES_FULL = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DC", label: "District of Columbia" },
  { value: "DE", label: "Delaware" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
];

// conditions that have been transitioned from visit to consult
export const CONDITIONS_MIGRATED_FROM_VISIT_TO_CONSULT = [
  "cold-and-flu",
  "vulvovaginitis",
  "cystitis",
];

// conditions that have return visit, but need a new initial_consult after a certain amount of time
// these amounts are set by clinical. They could be moved to the db
export const CONDITIONS_THAT_RESET_AFTER_X_DAYS: Partial<Record<string, number>> = {
  "mental-health": 98,
  diabetes: 90,
  "weight-loss": 90,
};

export const VALID_FOLLOWUP_CONSULTS = [
  "acid-reflux",
  "asthma",
  "birthcontrol",
  "endometriosis",
  "herpes",
  "hairloss",
  "hay-fever",
  "hyperlipidemia",
  "eyelashes",
  "migraine",
  "skincare",
  "smoking-cessation",
  // deprecated conditons below
  "cold-sore",
  "dermatitis",
  "genital-herpes",
];

export const MEMBERSHIP_YEARLY_FEE = "$120";
export const MEMBERSHIP_QUARTERLY_FEE = "$40";

export const MONTHLY_MEMBERSHIP_FEE_IN_CENTS = 2900;
export const MONTHLY_MEMBERSHIP_PLUS_FEE_IN_CENTS = 6400;

export const ACCEPTED_ASSESSMENT_TYPES = ["ANXIETY", "DEPRESSION", "POSTPARTUM_DEPRESSION"];

export const CONDITIONS_WITH_ASCVD_CALCULATOR = ["menopause", "hyperlipidemia"];

// Consults that have a checkup. Checkup consults are completed any time after the initial as long as they aren't due
// for an annual consult.
export const CONSULTS_WITH_CHECKUP = [
  "abnormal-uterine-bleeding",
  "acid-reflux",
  "asthma",
  "birthcontrol",
  "cold-and-flu",
  "cystitis",
  "diabetes",
  "endometriosis",
  "hypothyroidism",
  "menopause",
  "mental-health",
  "migraine",
  "nail-infection",
  "nutrition",
  "pcos",
  "skincare",
  "smoking-cessation",
  "vulvovaginitis",
  "weight-loss",
];

// Conditions that a user can do the initial consult again
export const NON_MH_REPEATABLE_CONDITIONS = [
  "cold-sore",
  "covid-19-treatment",
  "peds-covid-19-treatment",
  "dermatology",
  "dermatitis",
  "emergency-contraceptives",
  "genital-herpes",
  "hairloss",
  "hay-fever",
  "herpes",
  "hyperlipidemia",
  "eyelashes",
  "peds-itchy-rash",
  "postpartum-mastitis",
  "preconception-counseling",
  "skin-and-hair",
  "sti-testing-kit",
];

export const CONSULT_STATUSES = {
  started: "STARTED",
  completed: "COMPLETED",
  readyForReview: "READY_FOR_REVIEW",
  needsFollowup: "NEEDS_FOLLOWUP",
  approved: "APPROVED",
  denied: "DENIED",
  withdrawn: "WITHDRAWN",
};

export const providedTreatmentsByConditionAndMedication = {
  pcos: {
    Metformin: [
      {
        id: "1",
        med_name: "Metformin 500 mg",
        monthly_cost: "$25/monthly",
        ships_every: "1 month",
        dosage: "500 mg",
        group_name: "Metformin",
      },
      {
        id: "2",
        med_name: "Metformin 500 mg",
        monthly_cost: "$45/quarterly",
        ships_every: "3 months",
        dosage: "500 mg",
        group_name: "Metformin",
      },
      {
        id: "3",
        med_name: "Metformin 500 mg (x2)",
        monthly_cost: "$36/monthly",
        ships_every: "1 month",
        dosage: "500 mg",
        group_name: "Metformin",
      },
      {
        id: "4",
        med_name: "Metformin 500 mg (x2)",
        monthly_cost: "$75/quarterly",
        ships_every: "3 months",
        dosage: "500 mg",
        group_name: "Metformin",
      },
      {
        id: "5",
        med_name: "Metformin ER 500 mg",
        monthly_cost: "$25/monthly",
        ships_every: "1 month",
        dosage: "500 mg",
        group_name: "Metformin",
      },
      {
        id: "6",
        med_name: "Metformin ER 500 mg",
        monthly_cost: "$45/quarterly",
        ships_every: "3 months",
        dosage: "500 mg",
        group_name: "Metformin",
      },
      {
        id: "6",
        med_name: "Metformin ER 500 mg (x2)",
        monthly_cost: "$36/monthly",
        ships_every: "1 month",
        dosage: "500 mg",
        group_name: "Metformin",
      },
      {
        id: "6",
        med_name: "Metformin ER 500 mg (x2)",
        monthly_cost: "$75/quarterly",
        ships_every: "3 months",
        dosage: "500 mg",
        group_name: "Metformin",
      },
      {
        id: "7",
        med_name: "Metformin ER 750 mg",
        monthly_cost: "$36/monthly",
        ships_every: "1 month",
        dosage: "750 mg",
        group_name: "Metformin",
      },
      {
        id: "8",
        med_name: "Metformin ER 750 mg",
        monthly_cost: "$45/quarterly",
        ships_every: "3 months",
        dosage: "750 mg",
        group_name: "Metformin",
      },
      {
        id: "9",
        med_name: "Metformin ER 750 mg (x2)",
        monthly_cost: "$42/monthly",
        ships_every: "1 month",
        dosage: "750 mg",
        group_name: "Metformin",
      },
      {
        id: "10",
        med_name: "Metformin ER 750 mg (x2)",
        monthly_cost: "$75/quarterly",
        ships_every: "3 months",
        dosage: "750 mg",
        group_name: "Metformin",
      },
    ],
    Vaniqa: [
      {
        id: "1",
        med_name: "Vaniqa 13.9%",
        monthly_cost: "$250/monthly",
        ships_every: "1 month",
        dosage: "13.9%",
        group_name: "Vaniqa",
      },
      {
        id: "2",
        med_name: "Vaniqa 13.9%",
        monthly_cost: "$250/monthly",
        ships_every: "2 months",
        dosage: "13.9%",
        group_name: "Vaniqa",
      },
      {
        id: "3",
        med_name: "Vaniqa 13.9%",
        monthly_cost: "$250/monthly",
        ships_every: "3 months",
        dosage: "13.9%",
        group_name: "Vaniqa",
      },
      {
        id: "4",
        med_name: "Vaniqa 13.9%",
        monthly_cost: "$250/monthly",
        ships_every: "6 months",
        dosage: "13.9%",
        group_name: "Vaniqa",
      },
    ],
    Finasteride: [
      {
        id: "1",
        med_name: "Finasteride",
        monthly_cost: "$28.5/monthly",
        ships_every: "1 month",
        dosage: "1 mg",
        group_name: "Finasteride",
      },
      {
        id: "2",
        med_name: "Finasteride",
        monthly_cost: "$75/quarterly",
        ships_every: "3 month",
        dosage: "1 mg",
        group_name: "Finasteride",
      },
      {
        id: "3",
        med_name: "Finasteride",
        monthly_cost: "$30/monthly",
        ships_every: "1 month",
        dosage: "5 mg",
        group_name: "Finasteride",
      },
      {
        id: "4",
        med_name: "Finasteride",
        monthly_cost: "$75/quarterly",
        ships_every: "3 months",
        dosage: "5 mg",
        group_name: "Finasteride",
      },
    ],
    Spironolactone: [
      {
        id: "1",
        med_name: "Spironolactone",
        monthly_cost: "$30/monthly",
        ships_every: "1 month",
        dosage: "50 mg",
        group_name: "Spironolactone",
      },
      {
        id: "2",
        med_name: "Spironolactone",
        monthly_cost: "$70/quarterly",
        ships_every: "3 months",
        dosage: "50 mg",
        group_name: "Spironolactone",
      },
      {
        id: "3",
        med_name: "Spironolactone",
        monthly_cost: "$35/monthly",
        ships_every: "1 month",
        dosage: "100 mg",
        group_name: "Spironolactone",
      },
      {
        id: "4",
        med_name: "Spironolactone",
        monthly_cost: "$80/quarterly",
        ships_every: "3 months",
        dosage: "100 mg",
        group_name: "Spironolactone",
      },
    ],
    "Minoxidil/Finasteride": [
      {
        id: "1",
        med_name: "Minoxidil 3%/Finasteride 0.1%",
        monthly_cost: "$50/monthly",
        ships_every: "1 month",
        dosage: "3% / .1%",
        group_name: "Minoxidil/Finasteride",
      },
      {
        id: "1",
        med_name: "Minoxidil 6%/Finasteride 0.1%",
        monthly_cost: "$50/monthly",
        ships_every: "1 month",
        dosage: "6% / .1%",
        group_name: "Minoxidil/Finasteride",
      },
    ],
  },
  "mental-health": {
    Paroxetine: [
      {
        id: "1",
        med_name: "Paroxetine (10 mg)",
        monthly_cost: "$25/monthly",
        ships_every: "1 month",
        dosage: "10 mg",
        group_name: "Paroxetine",
      },
      {
        id: "2",
        med_name: "Paroxetine (20 mg)",
        monthly_cost: "$25/monthly",
        ships_every: "1 month",
        dosage: "20 mg",
        group_name: "Paroxetine",
      },
      {
        id: "3",
        med_name: "Paroxetine (30 mg)",
        monthly_cost: "$25/monthly",
        ships_every: "1 month",
        dosage: "30 mg",
        group_name: "Paroxetine",
      },
      {
        id: "4",
        med_name: "Paroxetine (40 mg)",
        monthly_cost: "$25/monthly",
        ships_every: "1 month",
        dosage: "40 mg",
        group_name: "Paroxetine",
      },
      {
        id: "5",
        med_name: "Paroxetine (50 mg)",
        monthly_cost: "$25/monthly",
        ships_every: "1 month",
        dosage: "50 mg",
        group_name: "Paroxetine",
      },
      {
        id: "6",
        med_name: "Paroxetine (60 mg)",
        monthly_cost: "$25/monthly",
        ships_every: "1 month",
        dosage: "60 mg",
        group_name: "Paroxetine",
      },
    ],
    Escitalopram: [
      {
        id: "7",
        med_name: "Escitalopram (10 mg)",
        monthly_cost: "$19/monthly",
        ships_every: "1 month",
        dosage: "10 mg",
        group_name: "Escitalopram",
      },
      {
        id: "8",
        med_name: "Escitalopram (20 mg)",
        monthly_cost: "$19/monthly",
        ships_every: "1 month",
        dosage: "20 mg",
        group_name: "Escitalopram",
      },
    ],
    Sertraline: [
      {
        id: "9",
        med_name: "Sertraline (50 mg)",
        monthly_cost: "$20/monthly",
        ships_every: "1 month",
        dosage: "50 mg",
        group_name: "Sertraline",
      },
      {
        id: "10",
        med_name: "Sertraline (100 mg)",
        monthly_cost: "$20/monthly",
        ships_every: "1 month",
        dosage: "100 mg",
        group_name: "Sertraline",
      },
      {
        id: "11",
        med_name: "Sertraline (150 mg)",
        monthly_cost: "$20/monthly",
        ships_every: "1 month",
        dosage: "150 mg",
        group_name: "Sertraline",
      },
      {
        id: "12",
        med_name: "Sertraline (200 mg)",
        monthly_cost: "$20/monthly",
        ships_every: "1 month",
        dosage: "200 mg",
        group_name: "Sertraline",
      },
    ],
    Citalopram: [
      {
        id: "13",
        med_name: "Citalopram (20 mg)",
        monthly_cost: "$35/monthly",
        ships_every: "1 month",
        dosage: "20 mg",
        group_name: "Citalopram",
      },
      {
        id: "14",
        med_name: "Citalopram (30 mg)",
        monthly_cost: "$35/monthly",
        ships_every: "1 month",
        dosage: "30 mg",
        group_name: "Citalopram",
      },
      {
        id: "15",
        med_name: "Citalopram (40 mg)",
        monthly_cost: "$35/monthly",
        ships_every: "1 month",
        dosage: "40 mg",
        group_name: "Citalopram",
      },
    ],
    Venlafaxine: [
      {
        id: "16",
        med_name: "Venlafaxine (75 mg)",
        monthly_cost: "$38/monthly",
        ships_every: "1 month",
        dosage: "75 mg",
        group_name: "Venlafaxine",
      },
      {
        id: "17",
        med_name: "Venlafaxine (150 mg)",
        monthly_cost: "$30/monthly",
        ships_every: "1 month",
        dosage: "150 mg",
        group_name: "Venlafaxine",
      },
      {
        id: "18",
        med_name: "Venlafaxine (225 mg)",
        monthly_cost: "$30/monthly",
        ships_every: "1 month",
        dosage: "225 mg",
        group_name: "Venlafaxine",
      },
    ],
    "Venlafaxine XR": [
      {
        id: "19",
        med_name: "Venlafaxine XR (75 mg)",
        monthly_cost: "$30/monthly",
        ships_every: "1 month",
        dosage: "75 mg",
        group_name: "Venlafaxine XR",
      },
      {
        id: "20",
        med_name: "Venlafaxine XR (150 mg)",
        monthly_cost: "$30/monthly",
        ships_every: "1 month",
        dosage: "150 mg",
        group_name: "Venlafaxine XR",
      },
      {
        id: "21",
        med_name: "Venlafaxine XR (225 mg)",
        monthly_cost: "$30/monthly",
        ships_every: "1 month",
        dosage: "225 mg",
        group_name: "Venlafaxine XR",
      },
    ],
    Fluoxetine: [
      {
        id: "22",
        med_name: "Fluoxetine (20 mg)",
        monthly_cost: "$30/monthly",
        ships_every: "1 month",
        dosage: "20 mg",
        group_name: "Fluoxetine",
      },
      {
        id: "23",
        med_name: "Fluoxetine (40 mg)",
        monthly_cost: "$30/monthly",
        ships_every: "1 month",
        dosage: "40 mg",
        group_name: "Fluoxetine",
      },
      {
        id: "24",
        med_name: "Fluoxetine (60 mg)",
        monthly_cost: "$30/monthly",
        ships_every: "1 month",
        dosage: "60 mg",
        group_name: "Fluoxetine",
      },
      {
        id: "25",
        med_name: "Fluoxetine (80 mg)",
        monthly_cost: "$30/monthly",
        ships_every: "1 month",
        dosage: "80 mg",
        group_name: "Fluoxetine",
      },
    ],
    "Duloxetine DR": [
      {
        id: "26",
        med_name: "Duloxetine DR (30 mg)",
        monthly_cost: "$25/monthly",
        ships_every: "1 month",
        dosage: "30 mg",
        group_name: "Duloxetine DR",
      },
      {
        id: "27",
        med_name: "Duloxetine DR (40 mg)",
        monthly_cost: "$25/monthly",
        ships_every: "1 month",
        dosage: "40 mg",
        group_name: "Duloxetine DR",
      },
      {
        id: "28",
        med_name: "Duloxetine DR (60 mg)",
        monthly_cost: "$25/monthly",
        ships_every: "1 month",
        dosage: "60 mg",
        group_name: "Duloxetine DR",
      },
    ],
    "Paroxetine CR": [
      {
        id: "29",
        med_name: "Paroxetine CR (12.5 mg)",
        monthly_cost: "$90/monthly",
        ships_every: "1 month",
        dosage: "12.5 mg",
        group_name: "Paroxetine CR",
      },
      {
        id: "30",
        med_name: "Paroxetine CR (25 mg)",
        monthly_cost: "$90/monthly",
        ships_every: "1 month",
        dosage: "25 mg",
        group_name: "Paroxetine CR",
      },
      {
        id: "31",
        med_name: "Paroxetine CR (37.5 mg)",
        monthly_cost: "$170/monthly",
        ships_every: "1 month",
        dosage: "37.5 mg",
        group_name: "Paroxetine CR",
      },
      {
        id: "32",
        med_name: "Paroxetine CR (50 mg)",
        monthly_cost: "$170/monthly",
        ships_every: "1 month",
        dosage: "50 mg",
        group_name: "Paroxetine CR",
      },
      {
        id: "33",
        med_name: "Paroxetine CR (62.5 mg)",
        monthly_cost: "$250/monthly",
        ships_every: "1 month",
        dosage: "62.5 mg",
        group_name: "Paroxetine CR",
      },
    ],
    "Bupropion SR": [
      {
        id: "34",
        med_name: "Bupropion SR (300 mg)",
        monthly_cost: "$30/monthly",
        ships_every: "1 month",
        dosage: "300 mg",
        group_name: "Bupropion SR",
      },
      {
        id: "35",
        med_name: "Bupropion SR (400 mg)",
        monthly_cost: "$30/monthly",
        ships_every: "1 month",
        dosage: "400 mg",
        group_name: "Bupropion SR",
      },
    ],
    "Bupropion XL": [
      {
        id: "36",
        med_name: "Bupropion XL (150 mg)",
        monthly_cost: "$30/monthly",
        ships_every: "1 month",
        dosage: "150 mg",
        group_name: "Bupropion XL",
      },
      {
        id: "37",
        med_name: "Bupropion XL (300 mg)",
        monthly_cost: "$30/monthly",
        ships_every: "1 month",
        dosage: "300 mg",
        group_name: "Bupropion XL",
      },
    ],
    Zyban: [
      {
        id: "38",
        med_name: "Zyban (300 mg)",
        monthly_cost: "$35/monthly",
        ships_every: "1 month",
        dosage: "300 mg",
        group_name: "Zyban",
      },
    ],
    Mirtazapine: [
      {
        id: "39",
        med_name: "Mirtazapine (15 mg)",
        monthly_cost: "$26/monthly",
        ships_every: "1 month",
        dosage: "15 mg",
        group_name: "Mirtazapine",
      },
      {
        id: "40",
        med_name: "Mirtazapine (30 mg)",
        monthly_cost: "$26/monthly",
        ships_every: "1 month",
        dosage: "30 mg",
        group_name: "Mirtazapine",
      },
      {
        id: "41",
        med_name: "Mirtazapine (45 mg)",
        monthly_cost: "$26/monthly",
        ships_every: "1 month",
        dosage: "45 mg",
        group_name: "Mirtazapine",
      },
    ],
    "Mirtazapine DO": [
      {
        id: "42",
        med_name: "Mirtazapine DO (15 mg)",
        monthly_cost: "$65/monthly",
        ships_every: "1 month",
        dosage: "15 mg",
        group_name: "Mirtazapine DO",
      },
      {
        id: "43",
        med_name: "Mirtazapine DO (30 mg)",
        monthly_cost: "$65/monthly",
        ships_every: "1 month",
        dosage: "30 mg",
        group_name: "Mirtazapine DO",
      },
      {
        id: "44",
        med_name: "Mirtazapine DO (45 mg)",
        monthly_cost: "$65/monthly",
        ships_every: "1 month",
        dosage: "45 mg",
        group_name: "Mirtazapine DO",
      },
    ],
    Fluvoxamine: [
      {
        id: "45",
        med_name: "Fluvoxamine (50 mg)",
        monthly_cost: "$40/monthly",
        ships_every: "1 month",
        dosage: "50 mg",
        group_name: "Fluvoxamine",
      },
      {
        id: "46",
        med_name: "Fluvoxamine (100 mg)",
        monthly_cost: "$40/monthly",
        ships_every: "1 month",
        dosage: "100 mg",
        group_name: "Fluvoxamine",
      },
      {
        id: "47",
        med_name: "Fluvoxamine (150 mg)",
        monthly_cost: "$65/monthly",
        ships_every: "1 month",
        dosage: "150 mg",
        group_name: "Fluvoxamine",
      },
      {
        id: "48",
        med_name: "Fluvoxamine (200 mg)",
        monthly_cost: "$65/monthly",
        ships_every: "1 month",
        dosage: "200 mg",
        group_name: "Fluvoxamine",
      },
      {
        id: "49",
        med_name: "Fluvoxamine (250 mg)",
        monthly_cost: "$90/monthly",
        ships_every: "1 month",
        dosage: "250 mg",
        group_name: "Fluvoxamine",
      },
      {
        id: "50",
        med_name: "Fluvoxamine (300 mg)",
        monthly_cost: "$90/monthly",
        ships_every: "1 month",
        dosage: "300 mg",
        group_name: "Fluvoxamine",
      },
    ],
    Amitriptyline: [
      {
        id: "51",
        med_name: "Amitriptyline (50 mg)",
        monthly_cost: "$35/monthly",
        ships_every: "1 month",
        dosage: "50 mg",
        group_name: "Amitriptyline",
      },
      {
        id: "52",
        med_name: "Amitriptyline (75 mg)",
        monthly_cost: "$35/monthly",
        ships_every: "1 month",
        dosage: "75 mg",
        group_name: "Amitriptyline",
      },
      {
        id: "53",
        med_name: "Amitriptyline (100 mg)",
        monthly_cost: "$42/monthly",
        ships_every: "1 month",
        dosage: "100 mg",
        group_name: "Amitriptyline",
      },
      {
        id: "54",
        med_name: "Amitriptyline (125 mg)",
        monthly_cost: "$42/monthly",
        ships_every: "1 month",
        dosage: "125 mg",
        group_name: "Amitriptyline",
      },
      {
        id: "55",
        med_name: "Amitriptyline (150 mg)",
        monthly_cost: "$42/monthly",
        ships_every: "1 month",
        dosage: "150 mg",
        group_name: "Amitriptyline",
      },
    ],
    "Fluvoxamine XR": [
      {
        id: "56",
        med_name: "Fluvoxamine XR (100 mg)",
        monthly_cost: "$300/monthly",
        ships_every: "1 month",
        dosage: "100 mg",
        group_name: "Fluvoxamine XR",
      },
      {
        id: "57",
        med_name: "Fluvoxamine XR (150 mg)",
        monthly_cost: "$300/monthly",
        ships_every: "1 month",
        dosage: "150 mg",
        group_name: "Fluvoxamine XR",
      },
      {
        id: "58",
        med_name: "Fluvoxamine XR (200 mg)",
        monthly_cost: "$530/monthly",
        ships_every: "1 month",
        dosage: "200 mg",
        group_name: "Fluvoxamine XR",
      },
      {
        id: "59",
        med_name: "Fluvoxamine XR (250 mg)",
        monthly_cost: "$550/monthly",
        ships_every: "1 month",
        dosage: "250 mg",
        group_name: "Fluvoxamine XR",
      },
    ],
    Buspirone: [
      {
        id: "60",
        med_name: "Buspirone (15 mg)",
        monthly_cost: "$35/monthly",
        ships_every: "1 month",
        dosage: "15 mg",
        group_name: "Buspirone",
      },
      {
        id: "61",
        med_name: "Buspirone (20 mg)",
        monthly_cost: "$26/monthly",
        ships_every: "1 month",
        dosage: "20 mg",
        group_name: "Buspirone",
      },
      {
        id: "62",
        med_name: "Buspirone (25 mg)",
        monthly_cost: "$26/monthly",
        ships_every: "1 month",
        dosage: "25 mg",
        group_name: "Buspirone",
      },
      {
        id: "63",
        med_name: "Buspirone (30 mg)",
        monthly_cost: "$26/monthly",
        ships_every: "1 month",
        dosage: "30 mg",
        group_name: "Buspirone",
      },
      {
        id: "64",
        med_name: "Buspirone (35 mg)",
        monthly_cost: "$26/monthly",
        ships_every: "1 month",
        dosage: "35 mg",
        group_name: "Buspirone",
      },
      {
        id: "65",
        med_name: "Buspirone (40 mg)",
        monthly_cost: "$26/monthly",
        ships_every: "1 month",
        dosage: "40 mg",
        group_name: "Buspirone",
      },
      {
        id: "66",
        med_name: "Buspirone (45 mg)",
        monthly_cost: "$26/monthly",
        ships_every: "1 month",
        dosage: "45 mg",
        group_name: "Buspirone",
      },
      {
        id: "67",
        med_name: "Buspirone (50 mg)",
        monthly_cost: "$26/monthly",
        ships_every: "1 month",
        dosage: "50 mg",
        group_name: "Buspirone",
      },
    ],
    Hydroxyzine: [
      {
        id: "68",
        med_name: "Hydroxyzine (75 mg)",
        monthly_cost: "$30/monthly",
        ships_every: "1 month",
        dosage: "75 mg",
        group_name: "Hydroxyzine",
      },
      {
        id: "69",
        med_name: "Hydroxyzine (100 mg)",
        monthly_cost: "$30/monthly",
        ships_every: "1 month",
        dosage: "100 mg",
        group_name: "Hydroxyzine",
      },
      {
        id: "70",
        med_name: "Hydroxyzine (200 mg)",
        monthly_cost: "$30/monthly",
        ships_every: "1 month",
        dosage: "200 mg",
        group_name: "Hydroxyzine",
      },
      {
        id: "71",
        med_name: "Hydroxyzine (225 mg)",
        monthly_cost: "$30/monthly",
        ships_every: "1 month",
        dosage: "225 mg",
        group_name: "Hydroxyzine",
      },
      {
        id: "72",
        med_name: "Hydroxyzine (400 mg)",
        monthly_cost: "$30/monthly",
        ships_every: "1 month",
        dosage: "400 mg",
        group_name: "Hydroxyzine",
      },
    ],
  },
  "hay-fever": {
    "Nasal Spray": [
      {
        id: "1",
        med_name: "Azelastine 0.1%",
        monthly_cost: "$29/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Nasal Spray",
      },
      {
        id: "2",
        med_name: "Azelastine 0.15%",
        monthly_cost: "$72/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Nasal Spray",
      },
      {
        id: "3",
        med_name: "Mometasone furoate 50 mcg",
        monthly_cost: "$75/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Nasal Spray",
      },
      {
        id: "4",
        med_name: "Olopatadine 0.6%",
        monthly_cost: "$115//month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Nasal Spray",
      },
      {
        id: "5",
        med_name: "Azelastine hydrochloride & Fluticasone propionate 137 mcg / 50 mcg",
        monthly_cost: "$210/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Nasal Spray",
      },
      {
        id: "6",
        med_name: "Ciclesonide 50 mcg",
        monthly_cost: "$400/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Nasal Spray",
      },
    ],
    "Oral Tablet": [
      {
        id: "7",
        med_name: "Montelukast 10 mg",
        monthly_cost: "$20/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Oral Tablet",
      },
      {
        id: "8",
        med_name: "Levocetirizine 5 mg",
        monthly_cost: "$25/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Oral Tablet",
      },
      {
        id: "9",
        med_name: "Desloratadine 5 mg",
        monthly_cost: "$40/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Oral Tablet",
      },
    ],
    "Oral Solution": [
      {
        id: "10",
        med_name: "Levocetirizine 2.5 mg / 5ml",
        monthly_cost: "$65/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Oral Solution",
      },
    ],
    "Eye Drop": [
      {
        id: "11",
        med_name: "Azelastine Hydrochloride 0.05%",
        monthly_cost: "$25/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Eye Drop",
      },
      {
        id: "12",
        med_name: "Olopatadine hydrochloride 0.1%",
        monthly_cost: "$40/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Eye Drop",
      },
      {
        id: "13",
        med_name: "Lodoxamide tromethamine 0.1%",
        monthly_cost: "$235/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Eye Drop",
      },
      {
        id: "14",
        med_name: "Olopatadine hydrochloride ophthalmic solution 0.7%",
        monthly_cost: "$280/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Eye Drop",
      },
      {
        id: "15",
        med_name: "Alcaftadine 0.25%",
        monthly_cost: "$300/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Eye Drop",
      },
      {
        id: "16",
        med_name: "Bepotastine besilate 1.5%",
        monthly_cost: "$300/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Eye Drop",
      },
      {
        id: "17",
        med_name: "Loteprednol etabonate 0.2%",
        monthly_cost: "$300/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Eye Drop",
      },
    ],
  },
  "acid-reflux": {
    "H2 Blockers": [
      {
        id: "1",
        med_name: "Cimetidine 200 mg",
        monthly_cost: "$50/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "H2 Blockers",
      },
      {
        id: "2",
        med_name: "Cimetitdine 400 mg (x1)",
        monthly_cost: "$50/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "H2 Blockers",
      },
      {
        id: "3",
        med_name: "Cimetitdine 400 mg (x2)",
        monthly_cost: "$100/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "H2 Blockers",
      },
    ],
    "Proton Pump Inhibitors": [
      {
        id: "4",
        med_name: "Omeprazole DR 10 mg",
        monthly_cost: "$25/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Proton Pump Inhibitors",
      },
      {
        id: "5",
        med_name: "Omeprazole DR 20 mg",
        monthly_cost: "$25/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Proton Pump Inhibitors",
      },
      {
        id: "6",
        med_name: "Omeprazole DR 40 mg",
        monthly_cost: "$25/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Proton Pump Inhibitors",
      },
      {
        id: "7",
        med_name: "Pantoprazole DR 20 mg",
        monthly_cost: "$25/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Proton Pump Inhibitors",
      },
      {
        id: "8",
        med_name: "Pantoprazole DR 40 mg",
        monthly_cost: "$25/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Proton Pump Inhibitors",
      },
      {
        id: "9",
        med_name: "Lansoprazole DR 30 mg",
        monthly_cost: "$30/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Proton Pump Inhibitors",
      },
      {
        id: "10",
        med_name: "Esomeprazole DR 20 mg",
        monthly_cost: "$35/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Proton Pump Inhibitors",
      },
      {
        id: "11",
        med_name: "Esomeprazole DR 40 mg",
        monthly_cost: "$35/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Proton Pump Inhibitors",
      },
      {
        id: "12",
        med_name: "Rabeprazole DR 20 mg",
        monthly_cost: "$37/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Proton Pump Inhibitors",
      },
      {
        id: "13",
        med_name: "Omeprazole 40 mg and Sodium Carbonate",
        monthly_cost: "$105/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Proton Pump Inhibitors",
      },
      {
        id: "14",
        med_name: "Dexlansoprazole DR 30 mg",
        monthly_cost: "$420/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Proton Pump Inhibitors",
      },
      {
        id: "15",
        med_name: "Dexlansoprazole DR 60 mg",
        monthly_cost: "$420/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Proton Pump Inhibitors",
      },
    ],
  },
  endometriosis: {
    "Birth Control": [
      {
        id: "1",
        med_name: "Sprintec",
        monthly_cost: "$25/month",
        ships_every: "1 month",
        quantity: "1 pack",
        group_name: "Birth Control",
      },
      {
        id: "2",
        med_name: "Sprintec",
        monthly_cost: "$45 / 3 months",
        ships_every: "3 months",
        quantity: "3 packs",
        group_name: "Birth Control",
      },
      {
        id: "3",
        med_name: "Nuvaring",
        monthly_cost: "$170/month",
        ships_every: "1 month",
        quantity: "1 ring",
        group_name: "Birth Control",
      },
      {
        id: "4",
        med_name: "Xulane",
        monthly_cost: "$175/month",
        ships_every: "1 month",
        quantity: "1 box (3 patches)",
        group_name: "Birth Control",
      },
      {
        id: "5",
        med_name: "Xulane",
        monthly_cost: "$600 / 3 months",
        ships_every: "3 months",
        quantity: "4 boxes (12 patches)",
        group_name: "Birth Control",
      },
      {
        id: "6",
        med_name: "Norethindrone 0.35 mg",
        monthly_cost: "$25/month",
        ships_every: "1 month",
        quantity: "1 pack",
        group_name: "Birth Control",
      },
      {
        id: "7",
        med_name: "Norethindrone 0.35 mg",
        monthly_cost: "$45 / 3 months",
        ships_every: "3 months",
        quantity: "3 packs",
        group_name: "Birth Control",
      },
      {
        id: "8",
        med_name: "Norethindrone 5 mg",
        monthly_cost: "$315/month",
        ships_every: "1 month",
        quantity: "90 tablets",
        group_name: "Birth Control",
      },
      {
        id: "9",
        med_name: "Blisovi FE 1/20",
        monthly_cost: "$30/month",
        ships_every: "1 month",
        quantity: "1 pack",
        group_name: "Birth Control",
      },
      {
        id: "10",
        med_name: "Blisovi FE 1/20",
        monthly_cost: "$60 / 3 months",
        ships_every: "3 months",
        quantity: "3 packs",
        group_name: "Birth Control",
      },
      {
        id: "11",
        med_name: "Altevera",
        monthly_cost: "$50 / 3 months",
        ships_every: "3 months",
        quantity: "3 packs",
        group_name: "Birth Control",
      },
    ],
    "Gonadotropin-releasing hormone antagonist": [
      {
        id: "12",
        med_name: "Orilssa 150 mg",
        monthly_cost: "$1200/month",
        ships_every: "1 month",
        quantity: "30 tablets",
        group_name: "Gonadotropin-releasing hormone antagonist",
      },
      {
        id: "13",
        med_name: "Orilssa 200 mg",
        monthly_cost: "$1250/month",
        ships_every: "1 month",
        quantity: "60 tablets",
        group_name: "Gonadotropin-releasing hormone antagonist",
      },
    ],
  },
  hypothyroidism: {
    Generic: [
      {
        id: "1",
        med_name: "Levothyroxine 25 mcg",
        monthly_cost: "$20/month",
        ships_every: "1 month",
        quantity: "30 pills",
        group_name: "Generic",
      },
      {
        id: "2",
        med_name: "Levothyroxine 50 mcg",
        monthly_cost: "$20/month",
        ships_every: "1 month",
        quantity: "30 pills",
        group_name: "Generic",
      },
      {
        id: "3",
        med_name: "Levothyroxine 75 mcg",
        monthly_cost: "$20/month",
        ships_every: "1 month",
        quantity: "30 pills",
        group_name: "Generic",
      },
      {
        id: "4",
        med_name: "Levothyroxine 88 mcg",
        monthly_cost: "$20/month",
        ships_every: "1 month",
        quantity: "30 pills",
        group_name: "Generic",
      },
      {
        id: "5",
        med_name: "Levothyroxine 100 mcg",
        monthly_cost: "$20/month",
        ships_every: "1 month",
        quantity: "30 pills",
        group_name: "Generic",
      },
      {
        id: "6",
        med_name: "Levothyroxine 112 mcg",
        monthly_cost: "$20/month",
        ships_every: "1 month",
        quantity: "30 pills",
        group_name: "Generic",
      },
      {
        id: "7",
        med_name: "Levothyroxine 125 mcg",
        monthly_cost: "$20/month",
        ships_every: "1 month",
        quantity: "30 pills",
        group_name: "Generic",
      },
      {
        id: "8",
        med_name: "Levothyroxine 150 mcg",
        monthly_cost: "$20/month",
        ships_every: "1 month",
        quantity: "30 pills",
        group_name: "Generic",
      },
      {
        id: "9",
        med_name: "Levothyroxine 175 mcg",
        monthly_cost: "$20/month",
        ships_every: "1 month",
        quantity: "30 pills",
        group_name: "Generic",
      },
      {
        id: "10",
        med_name: "Levothyroxine 200 mcg",
        monthly_cost: "$20/month",
        ships_every: "1 month",
        quantity: "30 pills",
        group_name: "Generic",
      },
      {
        id: "11",
        med_name: "Levothyroxine 300 mcg",
        monthly_cost: "$20/month",
        ships_every: "1 month",
        quantity: "30 pills",
        group_name: "Generic",
      },
    ],
    Brand: [
      {
        id: "12",
        med_name: "Synthroid 25 mcg",
        monthly_cost: "$20/month",
        ships_every: "1 month",
        quantity: "30 pills",
        group_name: "Brand",
      },
      {
        id: "13",
        med_name: "Synthroid 50 mcg",
        monthly_cost: "$20/month",
        ships_every: "1 month",
        quantity: "30 pills",
        group_name: "Brand",
      },
      {
        id: "14",
        med_name: "Synthroid 75 mcg",
        monthly_cost: "$20/month",
        ships_every: "1 month",
        quantity: "30 pills",
        group_name: "Brand",
      },
      {
        id: "15",
        med_name: "Synthroid 88 mcg",
        monthly_cost: "$20/month",
        ships_every: "1 month",
        quantity: "30 pills",
        group_name: "Brand",
      },
      {
        id: "16",
        med_name: "Synthroid 100 mcg",
        monthly_cost: "$20/month",
        ships_every: "1 month",
        quantity: "30 pills",
        group_name: "Brand",
      },
      {
        id: "17",
        med_name: "Synthroid 112 mcg",
        monthly_cost: "$20/month",
        ships_every: "1 month",
        quantity: "30 pills",
        group_name: "Brand",
      },
      {
        id: "18",
        med_name: "Synthroid 125 mcg",
        monthly_cost: "$20/month",
        ships_every: "1 month",
        quantity: "30 pills",
        group_name: "Brand",
      },
      {
        id: "19",
        med_name: "Synthroid 150 mcg",
        monthly_cost: "$20/month",
        ships_every: "1 month",
        quantity: "30 pills",
        group_name: "Brand",
      },
      {
        id: "20",
        med_name: "Synthroid 175 mcg",
        monthly_cost: "$20/month",
        ships_every: "1 month",
        quantity: "30 pills",
        group_name: "Brand",
      },
      {
        id: "21",
        med_name: "Synthroid 200 mcg",
        monthly_cost: "$20/month",
        ships_every: "1 month",
        quantity: "30 pills",
        group_name: "Brand",
      },
      {
        id: "22",
        med_name: "Synthroid 300 mcg",
        monthly_cost: "$20/month",
        ships_every: "1 month",
        quantity: "30 pills",
        group_name: "Brand",
      },
    ],
  },
  hyperlipidemia: {
    Statin: [
      {
        id: "1",
        med_name: "Simvastatin 5 mg",
        monthly_cost: "$24/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Statin",
      },
      {
        id: "2",
        med_name: "Simvastatin 10 mg",
        monthly_cost: "$24/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Statin",
      },
      {
        id: "3",
        med_name: "Simvastatin 20 mg",
        monthly_cost: "$24/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Statin",
      },
      {
        id: "4",
        med_name: "Simvastatin 40 mg",
        monthly_cost: "$24/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Statin",
      },
      {
        id: "5",
        med_name: "Simvastatin 80 mg",
        monthly_cost: "$24/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Statin",
      },
      {
        id: "6",
        med_name: "Atorvastatin 10 mg",
        monthly_cost: "$25/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Statin",
      },
      {
        id: "7",
        med_name: "Atorvastatin 20 mg",
        monthly_cost: "$25/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Statin",
      },
      {
        id: "8",
        med_name: "Atorvastatin 40 mg",
        monthly_cost: "$25/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Statin",
      },
      {
        id: "9",
        med_name: "Atorvastatin 80 mg",
        monthly_cost: "$25/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Statin",
      },
      {
        id: "10",
        med_name: "Lovastatin 10 mg",
        monthly_cost: "$27/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Statin",
      },
      {
        id: "11",
        med_name: "Lovastatin 20 mg",
        monthly_cost: "$27/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Statin",
      },
      {
        id: "12",
        med_name: "Lovastatin 30 mg",
        monthly_cost: "$27/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Statin",
      },
      {
        id: "13",
        med_name: "Pravastatin 10 mg",
        monthly_cost: "$29/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Statin",
      },
      {
        id: "14",
        med_name: "Pravastatin 20 mg",
        monthly_cost: "$29/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Statin",
      },
      {
        id: "15",
        med_name: "Pravastatin 40 mg",
        monthly_cost: "$29/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Statin",
      },
      {
        id: "16",
        med_name: "Pravastatin 80 mg",
        monthly_cost: "$29/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Statin",
      },
      {
        id: "18",
        med_name: "Rosuvastatin calcium 5 mg",
        monthly_cost: "$30/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Statin",
      },
      {
        id: "19",
        med_name: "Rosuvastatin calcium 10 mg",
        monthly_cost: "$30/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Statin",
      },
      {
        id: "20",
        med_name: "Rosuvastatin calcium 20 mg",
        monthly_cost: "$30/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Statin",
      },
      {
        id: "21",
        med_name: "Rosuvastatin calcium 40 mg",
        monthly_cost: "$30/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Statin",
      },
      {
        id: "32",
        med_name: "Fluvastatin 20 mg",
        monthly_cost: "$185/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Statin",
      },
      {
        id: "33",
        med_name: "Fluvastatin 40 mg",
        monthly_cost: "$185/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Statin",
      },
      {
        id: "34",
        med_name: "Fluvastatin ER 80 mg",
        monthly_cost: "$270/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Statin",
      },
    ],
    "Cholesterol-absorption inhibitor / Statin": [
      {
        id: "27",
        med_name: "Ezetimibe 10 mg / Simvastatin 10 mg",
        monthly_cost: "$123/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Cholesterol-absorption inhibitor / Statin",
      },
      {
        id: "29",
        med_name: "Ezetimibe 10 mg / Simvastatin 40 mg",
        monthly_cost: "$150/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Cholesterol-absorption inhibitor / Statin",
      },
      {
        id: "30",
        med_name: "Ezetimibe 10 mg / Simvastatin 80 mg",
        monthly_cost: "$150/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Cholesterol-absorption inhibitor / Statin",
      },
      {
        id: "31",
        med_name: "Ezetimibe 10 mg / Simvastatin 20 mg",
        monthly_cost: "$180/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Cholesterol-absorption inhibitor / Statin",
      },
    ],
    Antilipemic: [
      {
        id: "25",
        med_name: "Omega-3 ethyl esters 1 gram",
        monthly_cost: "$72/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Antilipemic",
      },
      {
        id: "38",
        med_name: "Icosapent ethyl 0.5 gram",
        monthly_cost: "$600/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Antilipemic",
      },
      {
        id: "39",
        med_name: "Icosapent ethyl 1 gram",
        monthly_cost: "$600/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Antilipemic",
      },
    ],
    Fibrate: [
      {
        id: "17",
        med_name: "Gemfibrozil 600 mg",
        monthly_cost: "$29/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Fibrate",
      },
      {
        id: "23",
        med_name: "Fenofibrate 160 mg",
        monthly_cost: "$38/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Fibrate",
      },
      {
        id: "24",
        med_name: "Fenofibrate 54 mg",
        monthly_cost: "$40/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Fibrate",
      },
      {
        id: "26",
        med_name: "Fenofibrate micronized 130 mg",
        monthly_cost: "$107/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Fibrate",
      },
      {
        id: "28",
        med_name: "Fenofibrate 50 mg",
        monthly_cost: "$146/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Fibrate",
      },
      {
        id: "35",
        med_name: "Fenofibrate 150 mg",
        monthly_cost: "$277/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Fibrate",
      },
      {
        id: "36",
        med_name: "Fenofibrate 40 mg",
        monthly_cost: "$343/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Fibrate",
      },
      {
        id: "40",
        med_name: "Fenofibrate 120 mg",
        monthly_cost: "$1050/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Fibrate",
      },
    ],
    "Cholesterol-absorption inhibitor": [
      {
        id: "22",
        med_name: "Ezetimibe 10 mg",
        monthly_cost: "$33/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Cholesterol-absorption inhibitor",
      },
    ],
    "Bile sequestrant": [
      {
        id: "37",
        med_name: "Colesevelam 625 mg",
        monthly_cost: "$350/month",
        ships_every: "1 month",
        quantity: "30",
        group_name: "Bile sequestrant",
      },
    ],
  },
  "smoking-cessation": {
    "Prescription ": [
      {
        id: "1",
        med_name: "Bupropion SR 150 mg (Zyban)",
        monthly_cost: "$35/month",
        ships_every: "1 month",
        dosage: "150mg",
        group_name: "Prescription ",
      },
    ],
    "Nicotine Patch": [
      {
        id: "2",
        med_name: "Nicotine Patch 7 mg / 24 hr 2 weeks supply (14 patches)",
        monthly_cost: "$60/month",
        ships_every: "",
        dosage: "7mg/24hr",
        group_name: "Nicotine Patch",
      },
      {
        id: "3",
        med_name: "Nicotine Patch 14 mg / 24 hr 2 weeks supply (14 patches)",
        monthly_cost: "$60/month",
        ships_every: "",
        dosage: "14mg/24hr",
        group_name: "Nicotine Patch",
      },
      {
        id: "4",
        med_name: "Nicotine Patch 14 mg / 24 hr 4 weeks (28 patches)",
        monthly_cost: "$110/month",
        ships_every: "",
        dosage: "14mg/24hr",
        group_name: "Nicotine Patch",
      },
      {
        id: "5",
        med_name: "Nicotine Patch 14 mg / 24 hr 6 weeks (42 patches)",
        monthly_cost: "$150/month",
        ships_every: "",
        dosage: "14mg/24hr",
        group_name: "Nicotine Patch",
      },
      {
        id: "6",
        med_name: "Nicotine Patch 21 mg / 24 hr  4 weeks supply (28 patches)",
        monthly_cost: "$120/month",
        ships_every: "",
        dosage: "21mg/24hr",
        group_name: "Nicotine Patch",
      },
      {
        id: "7",
        med_name: "Nicotine Patch 21 mg / 24 hr 6 weeks supply (42 patches)",
        monthly_cost: "$165/month",
        ships_every: "",
        dosage: "21mg/24hr",
        group_name: "Nicotine Patch",
      },
      {
        id: "8",
        med_name: "Nicotine Patch Bundle - 10 or less Cigarettes a day",
        monthly_cost: "$200/month",
        ships_every: "",
        dosage: "7mg/24hr, 14mg/24hr",
        group_name: "Nicotine Patch",
      },
      {
        id: "9",
        med_name: "Nicotine Patch Bundle - More than 10 Cigarettes a day",
        monthly_cost: "$260/month",
        ships_every: "",
        dosage: "7mg/24hr, 14mg/24hr, 21mg/24hr",
        group_name: "Nicotine Patch",
      },
    ],
  },
  hairloss: {
    Prescriptions: [
      {
        id: "1",
        med_name: "Finasteride",
        monthly_cost: "$85.50/quarter",
        ships_every: "3 months",
        dosage: "",
        group_name: "Prescriptions",
      },
      {
        id: "2",
        med_name: "Finasteride",
        monthly_cost: "$28.50/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Prescriptions",
      },
      {
        id: "3",
        med_name: "Spironolactone",
        monthly_cost: "$40/month",
        ships_every: "1 month",
        dosage: "100mg",
        group_name: "Prescriptions",
      },
      {
        id: "4",
        med_name: "Spironolactone",
        monthly_cost: "$90/quarter",
        ships_every: "3 months",
        dosage: "100mg",
        group_name: "Prescriptions",
      },
      {
        id: "4",
        med_name: "Spironolactone",
        monthly_cost: "$32/month",
        ships_every: "1 month",
        dosage: "50mg",
        group_name: "Prescriptions",
      },
      {
        id: "5",
        med_name: "Spironolactone",
        monthly_cost: "$70/month",
        ships_every: "1 month",
        dosage: "50mg",
        group_name: "Prescriptions",
      },
    ],
  },
  menopause: {
    "Menopausal hormones": [
      {
        id: "sku_36_78",
        med_name: "Brand Activella",
        monthly_cost: "$400/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_79",
        med_name: "Generic Activella",
        monthly_cost: "$70/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_80",
        med_name: "Brand Angeliq 0.25 mg/0.5 mg ",
        monthly_cost: "$290/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_31",
        med_name: "Brand Menest 0.3 mg pill ",
        monthly_cost: "$105/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_32",
        med_name: "Brand Menest 0.625 mg pill ",
        monthly_cost: "$140/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_33",
        med_name: "Brand Menest 1.25 mg pill ",
        monthly_cost: "$180/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_27",
        med_name: "Brand Estrogel",
        monthly_cost: "$225/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_28",
        med_name: "Brand Evamist  ",
        monthly_cost: "$210/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_34",
        med_name: "Brand Menest 2.5 mg pill ",
        monthly_cost: "$250/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_35",
        med_name: "Brand Minivelle 0.025 mg patch ",
        monthly_cost: "$300/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_36",
        med_name: "Brand Minivelle 0.0375 mg patch ",
        monthly_cost: "$300/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_37",
        med_name: "Brand Minivelle 0.05 mg patch ",
        monthly_cost: "$300/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_38",
        med_name: "Brand Minivelle 0.075 mg patch ",
        monthly_cost: "$300/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_39",
        med_name: "Brand Minivelle 0.1 mg patch ",
        monthly_cost: "$300/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_56",
        med_name: "Brand Vivelle-Dot 0.025 mg Patch ",
        monthly_cost: "$250/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_57",
        med_name: "Brand Vivelle-Dot 0.0375 mg Patch ",
        monthly_cost: "$220/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_58",
        med_name: "Brand Vivelle-Dot 0.05 mg Patch ",
        monthly_cost: "$220/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_59",
        med_name: "Brand Vivelle-Dot 0.075 mg Patch ",
        monthly_cost: "$220/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_60",
        med_name: "Brand Vivelle-Dot 0.1 mg Patch ",
        monthly_cost: "$220/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_61",
        med_name: "Generic Vivelle-Dot 0.025 mg Patch ",
        monthly_cost: "$120/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_62",
        med_name: "Generic Vivelle-Dot 0.0375 mg Patch ",
        monthly_cost: "$120/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_63",
        med_name: "Generic Vivelle-Dot 0.05 mg Patch ",
        monthly_cost: "$120/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_64",
        med_name: "Generic Vivelle-Dot 0.075 mg Patch ",
        monthly_cost: "$120/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_65",
        med_name: "Generic Vivelle-Dot 0.1 mg Patch ",
        monthly_cost: "$120/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_66",
        med_name: "Brand Prometrium 100 mg pill ",
        monthly_cost: "$480/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_67",
        med_name: "Brand Prometrium 200 mg pill ",
        monthly_cost: "$850/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_68",
        med_name: "Generic Prometrium 100 mg pill ",
        monthly_cost: "$58/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_69",
        med_name: "Generic Prometrium 200 mg pill ",
        monthly_cost: "$80/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_70",
        med_name: "Brand Provera 2.5 mg pill ",
        monthly_cost: "$75/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_71",
        med_name: "Brand Provera 10 mg pill ",
        monthly_cost: "$25/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_72",
        med_name: "Generic Provera 2.5 mg pill ",
        monthly_cost: "$25/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_73",
        med_name: "Generic Provera 5 mg pill ",
        monthly_cost: "$120/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_74",
        med_name: "Generic Provera 10 mg pill ",
        monthly_cost: "$25/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_40",
        med_name: "Generic Minivelle 0.025 mg patch ",
        monthly_cost: "$130/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_41",
        med_name: "Generic Minivelle 0.0375 mg patch ",
        monthly_cost: "$130/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_42",
        med_name: "Generic Minivelle 0.05 mg patch ",
        monthly_cost: "$130/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_43",
        med_name: "Generic Minivelle 0.075 mg patch ",
        monthly_cost: "$130/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_44",
        med_name: "Generic Minivelle 0.1 mg patch ",
        monthly_cost: "$130/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_81",
        med_name: "Brand Angeliq 0.5 mg/1 mg ",
        monthly_cost: "$290/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_82",
        med_name: "Brand Climara Pro Patch ",
        monthly_cost: "$330/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_83",
        med_name: "Brand Combipatch 0.05/0.14 ",
        monthly_cost: "$320/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_84",
        med_name: "Brand Combipatch 0.05/0.25 ",
        monthly_cost: "$320/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_85",
        med_name: "Brand Femhrt ",
        monthly_cost: "$240/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_86",
        med_name: "Generic Femhrt ",
        monthly_cost: "$150/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_87",
        med_name: "Brand Prefest",
        monthly_cost: "$250/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_88",
        med_name: "Brand Prempro 0.3 mg/1.5 mg ",
        monthly_cost: "$310/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_89",
        med_name: "Brand Prempro 0.45 mg/1.5 mg ",
        monthly_cost: "$310/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_90",
        med_name: "Brand Prempro 0.625 mg/2.5 mg ",
        monthly_cost: "$310/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_91",
        med_name: "Brand Prempro 0.625 mg/5 mg ",
        monthly_cost: "$310/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_92",
        med_name: "Brand Duavee ",
        monthly_cost: "$280/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_1",
        med_name: "Brand Alora 0.025 mg",
        monthly_cost: "$175/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_2",
        med_name: "Brand Alora 0.05 mg",
        monthly_cost: "$185/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_3",
        med_name: "Brand Alora 0.075 mg",
        monthly_cost: "$195/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_4",
        med_name: "Brand Alora 0.1 mg",
        monthly_cost: "$205/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_5",
        med_name: "Brand Climara 0.025 mg",
        monthly_cost: "$215/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_6",
        med_name: "Brand Climara 0.0375 mg",
        monthly_cost: "$215/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_7",
        med_name: "Brand Climara 0.05 mg",
        monthly_cost: "$215/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_8",
        med_name: "Brand Climara 0.06 mg",
        monthly_cost: "$215/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_9",
        med_name: "Brand Climara 0.075 mg",
        monthly_cost: "$215/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_10",
        med_name: "Brand Climara 0.1 mg",
        monthly_cost: "$215/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_11",
        med_name: "Brand Climara Pro",
        monthly_cost: "$330/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_12",
        med_name: "Brand Divigel 0.1% 0.25 mg",
        monthly_cost: "$240/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_13",
        med_name: "Brand Divigel 0.1 % 0.5 mg",
        monthly_cost: "$240/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_14",
        med_name: "Brand Divigel 0.1% 0.75 mg",
        monthly_cost: "$240/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_15",
        med_name: "Brand Divigel 0.1% 1 mg ",
        monthly_cost: "$240/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_16",
        med_name: "Brand Divigel 0.1% 1.25 mg ",
        monthly_cost: "$4400/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_17",
        med_name: "Brand Elestrin 0.06% ",
        monthly_cost: "$350/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_18",
        med_name: "Brand Estrace 0.5 mg ",
        monthly_cost: "$820/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_19",
        med_name: "Brand Estrace 1 mg ",
        monthly_cost: "$820/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_20",
        med_name: "Brand Estrace 2 mg ",
        monthly_cost: "$1000/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_46",
        med_name: "Brand Premarin 0.3 mg Pill ",
        monthly_cost: "$280/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_47",
        med_name: "Brand Premarin 0.45 mg Pill ",
        monthly_cost: "$280/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_48",
        med_name: "Brand Premarin 0.625 mg Pill ",
        monthly_cost: "$280/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_49",
        med_name: "Brand Premarin 0.9 mg Pill ",
        monthly_cost: "$280/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_50",
        med_name: "Brand Premarin 1.25 mg Pill ",
        monthly_cost: "$280/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_21",
        med_name: "Generic Estradiol 0.5 mg pill ",
        monthly_cost: "$45/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_22",
        med_name: "Generic Estradiol 1 mg pill ",
        monthly_cost: "$45/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
      {
        id: "sku_36_23",
        med_name: "Generic Estradiol 2 mg pill ",
        monthly_cost: "$45/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Menopausal hormones",
      },
    ],
    "Vaginal estrogen-only": [
      {
        id: "sku_36_24",
        med_name: "Brand Estrace 0.01% Vaginal Cream",
        monthly_cost: "$500/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Vaginal estrogen-only",
      },
      {
        id: "sku_36_25",
        med_name: "Generic Estrace 0.01% Vaginal Cream",
        monthly_cost: "$250/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Vaginal estrogen-only",
      },
      {
        id: "sku_36_51",
        med_name: "Brand Premarin 0.625 mg Vaginal Cream",
        monthly_cost: "$590/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Vaginal estrogen-only",
      },
      {
        id: "sku_36_52",
        med_name: "Brand Vagifem",
        monthly_cost: "$550/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Vaginal estrogen-only",
      },
      {
        id: "sku_36_53",
        med_name: "Brand Vagifem",
        monthly_cost: "$250/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Vaginal estrogen-only",
      },
      {
        id: "sku_36_54",
        med_name: "Generic Vagifem",
        monthly_cost: "$250/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Vaginal estrogen-only",
      },
      {
        id: "sku_36_55",
        med_name: "Generic Vagifem",
        monthly_cost: "$125/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Vaginal estrogen-only",
      },
      {
        id: "sku_36_26",
        med_name: "Brand Estring Vaginal Insert",
        monthly_cost: "$216/month",
        ships_every: "3 months",
        dosage: "",
        group_name: "Vaginal estrogen-only",
      },
      {
        id: "sku_36_29",
        med_name: "Brand Femring 0.05 mg Vaginal Ring ",
        monthly_cost: "$250/month",
        ships_every: "3 months",
        dosage: "",
        group_name: "Vaginal estrogen-only",
      },
      {
        id: "sku_36_30",
        med_name: "Brand Femring 0.1 mg Vaginal Ring ",
        monthly_cost: "$260/month",
        ships_every: "3 months",
        dosage: "",
        group_name: "Vaginal estrogen-only",
      },
    ],
    "Low dose antidepressants/anti-anxiety medication": [
      {
        id: "sku_36_75",
        med_name: "Brisedell ",
        monthly_cost: "$320/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Low dose antidepressants/anti-anxiety medication",
      },
      {
        id: "sku_36_76",
        med_name: "Generic Brisedell ",
        monthly_cost: "$215/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Low dose antidepressants/anti-anxiety medication",
      },
      {
        id: "sku_36_93",
        med_name: "Venlafaxine 37.5 mg",
        monthly_cost: "$45/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Low dose antidepressants/anti-anxiety medication",
      },
      {
        id: "sku_36_94",
        med_name: "Venlafaxine 75 mg",
        monthly_cost: "$25/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Low dose antidepressants/anti-anxiety medication",
      },
      {
        id: "sku_36_95",
        med_name: "Venlafaxine 37.5 mg and 75 mg ",
        monthly_cost: "$35/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Low dose antidepressants/anti-anxiety medication",
      },
    ],
    DHEA: [
      {
        id: "sku_36_77",
        med_name: "Intrasosa Vaginal Insert 6.5 mg ",
        monthly_cost: "$350/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "DHEA",
      },
    ],
    "Estrogen agonist/antagonist": [
      {
        id: "sku_36_45",
        med_name: "Osphena ",
        monthly_cost: "$350/month",
        ships_every: "1 month",
        dosage: "",
        group_name: "Estrogen agonist/antagonist",
      },
    ],
  },
  "weight-loss": {
    Pill: [
      {
        id: "1",
        med_name: "Brand Xenical 120 mg Monthly Zero Refill",
        monthly_cost: "$800/month",
        ships_every: "",
        dosage: "120 mg",
        group_name: "Pill",
      },
      {
        id: "2",
        med_name: "Brand Xenical 120 mg Monthly 2 Refills",
        monthly_cost: "$800/month",
        ships_every: "1 month",
        dosage: "120 mg",
        group_name: "Pill",
      },
      {
        id: "5",
        med_name: "Brand Contrave ER Inititation",
        monthly_cost: "$280/month",
        ships_every: "",
        dosage: "Naltrexone / Bupropion 8 mg/90 mg",
        group_name: "Pill",
      },
      {
        id: "6",
        med_name: "Brand Contrave ER Maintenance",
        monthly_cost: "$480/month",
        ships_every: "1 month",
        dosage: "Naltrexone / Bupropion 8 mg/90 mg",
        group_name: "Pill",
      },
      {
        id: "7",
        med_name: "Bupropion SR 150 mg Zero Refill",
        monthly_cost: "$32/month",
        ships_every: "",
        dosage: "150 mg",
        group_name: "Pill",
      },
      {
        id: "8",
        med_name: "Bupropion SR 150 mg 1 Refill",
        monthly_cost: "$32/month",
        ships_every: "1 month",
        dosage: "150 mg",
        group_name: "Pill",
      },
      {
        id: "9",
        med_name: "Bupropion SR 150 mg 3 Refills",
        monthly_cost: "$32/month",
        ships_every: "1 month",
        dosage: "150 mg",
        group_name: "Pill",
      },
      {
        id: "10",
        med_name: "Bupropion SR 200 mg Zero Refill",
        monthly_cost: "$40/month",
        ships_every: "",
        dosage: "200 mg",
        group_name: "Pill",
      },
      {
        id: "11",
        med_name: "Bupropion SR 200 mg 1 Refill",
        monthly_cost: "$40/month",
        ships_every: "1 month",
        dosage: "200 mg",
        group_name: "Pill",
      },
      {
        id: "12",
        med_name: "Bupropion SR 200 mg 3 Refills",
        monthly_cost: "$40/month",
        ships_every: "1 month",
        dosage: "200 mg",
        group_name: "Pill",
      },
      {
        id: "13",
        med_name: "Bupropion SR 300 mg titration Zero Refill",
        monthly_cost: "$35/month",
        ships_every: "",
        dosage: "Titration dose",
        group_name: "Pill",
      },
      {
        id: "14",
        med_name: "Bupropion SR 150 mg BID (300 mg) Zero Refill",
        monthly_cost: "$35/month",
        ships_every: "",
        dosage: "150 mg twice a day ",
        group_name: "Pill",
      },
      {
        id: "15",
        med_name: "Bupropion SR 150 mg BID (300 mg) 1 Refill",
        monthly_cost: "$35/month",
        ships_every: "1 month",
        dosage: "150 mg twice a day ",
        group_name: "Pill",
      },
      {
        id: "16",
        med_name: "Bupropion SR 150 mg BID (300 mg) 3 Refills",
        monthly_cost: "$35/month",
        ships_every: "1 month",
        dosage: "150 mg twice a day ",
        group_name: "Pill",
      },
      {
        id: "17",
        med_name: "Bupropion SR 200 mg BID (400 mg) Zero Refill",
        monthly_cost: "$60/month",
        ships_every: "",
        dosage: "200 mg twice a day ",
        group_name: "Pill",
      },
      {
        id: "18",
        med_name: "Bupropion SR 200 mg BID (400 mg) 1 Refill",
        monthly_cost: "$60/month",
        ships_every: "1 month",
        dosage: "200 mg twice a day ",
        group_name: "Pill",
      },
      {
        id: "19",
        med_name: "Bupropion SR 200 mg BID (400 mg) 3 Refills",
        monthly_cost: "$60/month",
        ships_every: "1 month",
        dosage: "200 mg twice a day ",
        group_name: "Pill",
      },
      {
        id: "20",
        med_name: "Rybelsus 3 mg QD 1 Refill",
        monthly_cost: "$1350/month",
        ships_every: "1 month",
        dosage: "3 mg once a day",
        group_name: "Pill",
      },
      {
        id: "21",
        med_name: "Rybelsus 3 mg QD 2 Refills",
        monthly_cost: "$1350/month",
        ships_every: "1 month",
        dosage: "3 mg once a day",
        group_name: "Pill",
      },
      {
        id: "22",
        med_name: "Rybelsus 3 mg QD 3 Refills",
        monthly_cost: "$1350/month",
        ships_every: "1 month",
        dosage: "3 mg once a day",
        group_name: "Pill",
      },
      {
        id: "23",
        med_name: "Rybelsus 7 mg QD 1 Refill",
        monthly_cost: "$1350/month",
        ships_every: "1 month",
        dosage: "7 mg once a day",
        group_name: "Pill",
      },
      {
        id: "24",
        med_name: "Rybelsus 7 mg QD 2 Refills",
        monthly_cost: "$1350/month",
        ships_every: "1 month",
        dosage: "7 mg once a day",
        group_name: "Pill",
      },
      {
        id: "25",
        med_name: "Rybelsus 7 mg QD 3 Refills",
        monthly_cost: "$1350/month",
        ships_every: "1 month",
        dosage: "7 mg once a day",
        group_name: "Pill",
      },
      {
        id: "26",
        med_name: "Rybelsus 14 mg QD 1 Refill",
        monthly_cost: "$1350/month",
        ships_every: "1 month",
        dosage: "14 mg once a day",
        group_name: "Pill",
      },
      {
        id: "27",
        med_name: "Rybelsus 14 mg QD 2 Refills",
        monthly_cost: "$1350/month",
        ships_every: "1 month",
        dosage: "14 mg once a day",
        group_name: "Pill",
      },
      {
        id: "28",
        med_name: "Rybelsus 14 mg QD 3 Refills",
        monthly_cost: "$1350/month",
        ships_every: "1 month",
        dosage: "14 mg once a day",
        group_name: "Pill",
      },
    ],
    "Subcutaneous Injection - Pen": [
      {
        id: "3",
        med_name: "Brand Saxenda Initiation",
        monthly_cost: "$990/month",
        ships_every: "",
        dosage: "3 ml per pen",
        group_name: "Subcutaneous Injection - Pen",
      },
      {
        id: "4",
        med_name: "Brand Saxenda Maintenance",
        monthly_cost: "$1650/month",
        ships_every: "1 month",
        dosage: "3 ml per pen",
        group_name: "Subcutaneous Injection - Pen",
      },
      {
        id: "5",
        med_name: "Brand Wegovy",
        monthly_cost: "$1650/month",
        ships_every: "1 month",
        group_name: "Subcutaneous Injection - Pen",
      },
      {
        id: "6",
        med_name: "Brand Victoza titration with needles",
        monthly_cost: "$1000/month",
        ships_every: "",
        dosage: "Titration dose",
        group_name: "Subcutaneous Injection - Pen",
      },
      {
        id: "7",
        med_name: "Brand Victoza 1.8 mg with needles",
        monthly_cost: "$1500/month",
        ships_every: "1 month",
        dosage: "1.8 mg once a day",
        group_name: "Subcutaneous Injection - Pen",
      },
      {
        id: "8",
        med_name: "Mounjaro 2.5MG",
        monthly_cost: "$1500/month",
        ships_every: "1 month",
        dosage: "2.5 mg once weekly",
        group_name: "Mounjaro",
      },
      {
        id: "9",
        med_name: "Mounjaro 5MG",
        monthly_cost: "$1500/month",
        ships_every: "1 month",
        dosage: "5 mg once weekly",
        group_name: "Mounjaro",
      },
      {
        id: "10",
        med_name: "Mounjaro 5MG",
        monthly_cost: "$1500/month",
        ships_every: "3 month",
        dosage: "5 mg once weekly",
        group_name: "Mounjaro",
      },
      {
        id: "11",
        med_name: "Mounjaro 7.5MG",
        monthly_cost: "$1500/month",
        ships_every: "1 month",
        dosage: "7.5 mg once weekly",
        group_name: "Mounjaro",
      },
      {
        id: "12",
        med_name: "Mounjaro 7.5MG",
        monthly_cost: "$1500/month",
        ships_every: "3 months",
        dosage: "7.5 mg once weekly",
        group_name: "Mounjaro",
      },
      {
        id: "13",
        med_name: "Mounjaro 10MG",
        monthly_cost: "$1500/month",
        ships_every: "1 month",
        dosage: "10 mg once weekly",
        group_name: "Mounjaro",
      },
      {
        id: "14",
        med_name: "Mounjaro 10MG",
        monthly_cost: "$1500/month",
        ships_every: "3 months",
        dosage: "10 mg once weekly",
        group_name: "Mounjaro",
      },
      {
        id: "15",
        med_name: "Mounjaro 12.5MG",
        monthly_cost: "$1500/month",
        ships_every: "1 month",
        dosage: "12.5 mg once weekly",
        group_name: "Mounjaro",
      },
      {
        id: "16",
        med_name: "Mounjaro 12.5MG",
        monthly_cost: "$1500/month",
        ships_every: "3 months",
        dosage: "12.5 mg once weekly",
        group_name: "Mounjaro",
      },
      {
        id: "17",
        med_name: "Mounjaro 15MG",
        monthly_cost: "$1500/month",
        ships_every: "1 month",
        dosage: "15 mg once weekly",
        group_name: "Mounjaro",
      },
      {
        id: "18",
        med_name: "Mounjaro 15MG",
        monthly_cost: "$1500/month",
        ships_every: "3 months",
        dosage: "15 mg once weekly",
        group_name: "Mounjaro",
      },
      {
        id: "19",
        med_name: "Ozempic 0.25 mg (0.25/0.5) pen with Needles Zero Refill",
        monthly_cost: "$1250/month",
        ships_every: "8 weeks",
        dosage: "0.25 mg once weekly",
        group_name: "Subcutaneous Injection - Pen",
      },
      {
        id: "20",
        med_name: "Ozempic 0.25 mg (0.25/0.5) pen 1 Refill",
        monthly_cost: "$1225/month",
        ships_every: "8 weeks",
        dosage: "0.25 mg once weekly",
        group_name: "Subcutaneous Injection - Pen",
      },
      {
        id: "21",
        med_name: "Ozempic 0.25 mg (0.25/0.5) pen 2 Refills",
        monthly_cost: "$1225/month",
        ships_every: "8 weeks",
        dosage: "0.25 mg once weekly",
        group_name: "Subcutaneous Injection - Pen",
      },
      {
        id: "22",
        med_name: "Ozempic 0.5 mg (0.25/0.5) pen Zero Refill",
        monthly_cost: "$1225/month",
        ships_every: "1 month",
        dosage: "0.5 mg once weekly",
        group_name: "Subcutaneous Injection - Pen",
      },
      {
        id: "23",
        med_name: "Ozempic 0.5 mg (0.25/0.5) pen 1 Refill",
        monthly_cost: "$1225/month",
        ships_every: "1 month",
        dosage: "0.5 mg once weekly",
        group_name: "Subcutaneous Injection - Pen",
      },
      {
        id: "24",
        med_name: "Ozempic 0.5 mg (0.25/0.5) pen 2 Refills",
        monthly_cost: "$1225/month",
        ships_every: "1 month",
        dosage: "0.5 mg once weekly",
        group_name: "Subcutaneous Injection - Pen",
      },
    ],
  },
  "emergency-contraceptives": {
    "My Way": [
      { id: "1", med_name: "My Way", quantity: "1", monthly_cost: "$25", group_name: "My Way" },
      { id: "2", med_name: "My Way", quantity: "2", monthly_cost: "$40", group_name: "My Way" },
      { id: "3", med_name: "My Way", quantity: "3", monthly_cost: "$55", group_name: "My Way" },
    ],
    Ella: [
      { id: "1", med_name: "Ella", quantity: "1", monthly_cost: "$50", group_name: "Ella" },
      { id: "2", med_name: "Ella", quantity: "2", monthly_cost: "$100", group_name: "Ella" },
      { id: "3", med_name: "Ella", quantity: "3", monthly_cost: "$150", group_name: "Ella" },
    ],
  },
};

interface ConditionInfo {
  label: string;
  imageSrc: string;
  is_visit?: boolean;
}

interface ConditionInfoMapProps {
  [condition: string]: Partial<ConditionInfo>;
}

export const conditionInfoMap: ConditionInfoMapProps = {
  birthcontrol: {
    label: "Birth Control",
    imageSrc: "/assets/birth-control-icon.svg",
  },
  eyelashes: {
    label: "Lashes",
    imageSrc: "/assets/latisse-icon.svg",
  },
  skincare: {
    label: "Skincare",
    imageSrc: "/assets/skincare-icon.svg",
  },
  cystitis: {
    label: "Cystitis",
    imageSrc: "/assets/visit-icon.svg",
    is_visit: true,
  },
  vulvovaginitis: {
    label: "Vulvovaginitis",
    imageSrc: "/assets/visit-icon.svg",
    is_visit: true,
  },
  "cold-and-flu": {
    label: "Cold & Flu",
    imageSrc: "/assets/visit-icon.svg",
    is_visit: true,
  },
  "skin-and-hair": {
    label: "Skin & Hair",
    is_visit: true,
    imageSrc: "/assets/visit-icon.svg",
  },
  "womens-health": {
    label: "Women's Health",
    imageSrc: "/assets/visit-icon.svg",
    is_visit: true,
  },
  "mens-health": {
    label: "Men's Health",
    imageSrc: "/assets/visit-icon.svg",
    is_visit: true,
  },
  "genital-herpes": {
    label: "Genital Herpes",
    imageSrc: "/assets/genital-herpes-icon.svg",
  },
  migraine: {
    label: "Migraine",
    imageSrc: "/assets/migraine-icon.svg",
  },
  "cold-sore": {
    label: "Cold Sore",
    imageSrc: "/assets/cold-sores-icon.svg",
  },
  dermatitis: {
    label: "Dermatitis (Eczema)",
    imageSrc: "/assets/eczema-icon.svg",
  },
  asthma: {
    label: "Asthma",
    imageSrc: "/assets/asthma-icon.svg",
  },
  "mental-health": {
    label: "Mental Health",
    imageSrc: "/assets/mental-health-icon.svg",
  },
  "sti-testing-kit": {
    label: "STI Testing Kit",
    imageSrc: "/assets/sti-kit-icon.svg",
  },
  "hay-fever": {
    label: "Hay Fever",
    imageSrc: "/assets/hay-fever-icon.svg",
  },
  "acid-reflux": {
    label: "Acid Reflux",
    imageSrc: "/assets/acid-reflux-icon.svg",
  },
  endometriosis: {
    label: "Endometriosis",
    imageSrc: "/assets/endometriosis-icon.svg",
  },
  hypothyroidism: {
    label: "Hypothyroidism",
    imageSrc: "/assets/hypothyroidism-icon.svg",
  },
  hyperlipidemia: {
    label: "High Cholesterol",
    imageSrc: "/assets/hyperlipidemia-icon.svg",
  },
  "smoking-cessation": {
    label: "Quit Smoking",
    imageSrc: "/assets/smoking-cessation.svg",
  },
  "nail-infection": {
    label: "Nail Infection",
    imageSrc: "/assets/nail-infection.svg",
    is_visit: true,
  },
  "preconception-counseling": {
    label: "Preconception Counseling",
    imageSrc: "/assets/visit-icon.svg",
    is_visit: true,
  },
  "postpartum-mastitis": {
    label: "Postpartum Mastitis",
    is_visit: true,
  },
  "peds-itchy-rash": {
    label: "Itchy Rash",
    is_visit: true,
  },
  "diaper-rash": {
    label: "Diaper Rash",
    is_visit: true,
  },
  menopause: {
    label: "Menopause",
    imageSrc: "/assets/menopause-pdb.svg",
    is_visit: false,
  },
  nutrition: {
    label: "Nutrition",
    imageSrc: "/assets/nutrition-icon.svg",
    is_visit: false,
  },
  "weight-loss": {
    label: "Weight Loss",
    imageSrc: "/assets/weight-loss.svg",
    is_visit: false,
  },
  pcos: {
    label: "Polycystic Ovarian Syndrome",
    imageSrc: "/assets/pcos.svg",
    is_visit: false,
  },
  "covid-19-treatment": {
    label: "COVID-19 Treatment",
    imageSrc: "/assets/covid-19-treatment-icon.svg",
    is_visit: false,
  },
  "emergency-contraceptives": {
    label: "Emergency Contraceptives",
    imageSrc: "/assets/emergency-contraceptives-icon.svg",
    is_visit: false,
  },
  diabetes: {
    label: "Diabetes",
    imageSrc: "/assets/diabetes-icon.svg",
    is_visit: false,
  },
  "abnormal-uterine-bleeding": {
    label: "Abnormal Uterine Bleeding",
    imageSrc: "/assets/abnormal-uterine-bleeding.svg",
    is_visit: false,
  },
};

export const getConditionInfo = (conditionKey: string): ConditionInfo => {
  const defaultInfo: ConditionInfo = {
    label: conditionKey,
    imageSrc: "/assets/condition-placeholder-icon.svg",
  };

  return { ...defaultInfo, ...(conditionInfoMap[conditionKey] || {}) };
};

export interface ConditionTreatmentInfo {
  icon: string;
  iconAlt: string;
  title: string;
  url: string;
}

/**
 * Created an info component for TreatmentInfo.tsx
 *
 * @param iconName name of the icon file in assets folder
 * @param iconAlt alt text for icon
 * @param title text you want to display next to the icon
 * @param url where you want the patient to go if clicked on
 */
export const createTreatmentInfo = (
  iconName: string,
  iconAlt: string,
  title: string,
  url: string,
) => {
  return {
    icon: `/assets/${iconName}.svg`,
    iconAlt,
    title,
    url,
  };
};

const CONDITION_TREATMENT_INFO = ["diabetes"];

export const hasConditionTreatmentInfo = (conditionKey: string): boolean => {
  if (CONDITION_TREATMENT_INFO.includes(conditionKey)) {
    return true;
  }
  return false;
};

export const getConditionTreatmentInfo = (
  conditionKey: string,
  consult: LegacyConsultSchema,
): false | ConditionTreatmentInfo[] => {
  if (conditionKey === "diabetes") {
    const info = [];

    // We are deprecating all direct zendesk links in PDB. Leaving this here commented out
    // in the case we want to re-enable this when we have all our new FAQs set up
    // https://helloalpha.atlassian.net/browse/ALPHA-1863
    // info.push(createTreatmentInfo(
    //   "questionMarkIcon",
    //   "Question Mark Icon",
    //   "Have a question?",
    //   "https://help.helloalpha.com/hc/en-us/categories/5213072476055-Diabetes"
    // ));

    if ("which-diabetes-test" in consult.answers) {
      if (consult.answers["which-diabetes-test"].value === "at-home-self-collection") {
        info.push(
          createTreatmentInfo(
            "homeIcon",
            "Home Icon",
            "At-home test kit",
            "/visit-details/diabetes/treatment-info/at-home-test-key",
          ),
        );
      }
      if (
        consult.answers["which-diabetes-test"].value === "lab-glucose-tolerance-test" ||
        consult.answers["which-diabetes-test"].value === "lab-a1c-test"
      ) {
        info.push(
          createTreatmentInfo(
            "labTestsIcon",
            "Lab Tests Icon",
            "Lab tests",
            "/visit-details/diabetes/treatment-info/lab-tests",
          ),
        );
      }
    }

    if (
      "try-cgm-free" in consult.answers &&
      (consult.answers["try-cgm-free"].value === "yes" ||
        consult.answers["try-cgm-free"].value === "let-provider-decide" ||
        consult.answers["try-cgm-free"].value === "like-more-information")
    ) {
      info.push(
        createTreatmentInfo(
          "_x34_",
          "Freestyle Libre 2 Icon",
          "Freestyle Libre 2 (CGM)",
          "/visit-details/diabetes/treatment-info/libre-2",
        ),
      );
    }

    return info;
  }
  return false;
};

export const MONTHLY_MEMBERSHIP_CONDITION_SUMMARY_MAP = {
  "mental-health": {
    title: "Join Alpha Plus+ Plan to get personalized, discreet treatment anytime, anywhere.",
    infoText: "Alpha Plus+ Plan is required for the mental health service.",
    subtitle1: "How We Treat Mental Health",
    description1:
      "The first step is to get a proper medical diagnosis. The Alpha Mental Health Service includes your initial medical diagnosis and all follow up visits with your Alpha Provider. Your Provider may prescribe medication, refer you to talk therapy, and provide self care tips.",
    talkingPoints1: [
      {
        iconClass: "fa-solid fa-capsules",
        title: "Prescription Medication",
        description:
          "Work closely with your Alpha Provider to find the most effective medication for you.",
      },
      {
        iconClass: "fa-solid fa-comment-plus",
        title: "Talk Therapy Referral",
        description:
          "We’ve partnered with BetterHelp to provide 1 free month with a therapist (optional).",
      },
      {
        iconClass: "fa-solid fa-hands-holding-heart",
        title: "Self Care Advice",
        description:
          "Access our expert resources and content to help reduce stress and improve mental health.",
      },
    ],
    subtitle2: "Benefits of Alpha’s Mental Health Service",
    talkingPoints2: [
      {
        iconClass: "fa-solid fa-notes-medical",
        title: "All Mental Health Visits",
        description:
          "All consultations are covered (up to 4 visits per month), including your initial diagnosis and follow ups. Return visits help us measure your progress and adjust your treatment plan. Any future refills will depend on your completion of these return visits.",
      },
      {
        iconClass: "fa-solid fa-clipboard-list-check",
        title: "Personalized Treatment Plan",
        description:
          "The 3 phases of treatment (acute, continuation, maintenance) are structured to minimize and prevent the return of symptoms, and prevent lifetime return of new episodes.",
      },
      {
        iconClass: "fa-solid fa-messages",
        title: "Unlimited Messaging",
        description: "Access our Care Team anytime with questions or concerns.",
      },
      {
        iconClass: "fa-solid fa-money-check-dollar",
        title: "Insurance Support",
        description:
          "We accept insurance for medication. Our insurance liaisons will inquire about your coverage, medication copays, pharmacy benefit information, and more.",
      },
      {
        iconClass: "fa-solid fa-truck-fast",
        title: "Free Shipping",
        description:
          "Prescription treatments are delivered to your home with free shipping (or pickup at your local pharmacy).",
      },
      {
        iconClass: "fa-solid fa-comment-plus",
        title: "Additional Resources",
        description:
          "Access free trial programs with our partners for talk therapy, yoga, fitness, and meditation.",
      },
    ],
    dropdownList: {
      title: "Top Questions",
      questionList: [
        {
          idx: 1,
          title: "What’s the difference between Alpha Basic Plan and Alpha Plus+ Plan?",
          answers: [
            "Alpha Basic Plan includes access to online treatment for over 100 medical conditions, but does not include our weight loss, diabetes, and mental health services. With Alpha Basic Plan, you'll receive 2 visits per month that can be used towards any eligible medical conditions.",
            "Both Alpha Basic Plan and Alpha Plus+ Plan include unlimited messaging with your Care Team, and are flexible, month-to-month commitments.",
          ],
        },
        {
          idx: 3,
          title: "Does Alpha provide talk therapy?",
          answers: [
            `Due to the nature of our telemedicine platform, patients are referred to external talk therapy resources, such as <a class="underline" href="https://www.betterhelp.com/" target="_blank">BetterHelp.</a>`,
          ],
        },
        {
          idx: 4,
          title:
            "Can I get my medications through Alpha if I have already been diagnosed elsewhere?",
          answers: [
            "Yes, but you'll still need to complete our medical intake form before your Alpha Provider can prescribe any medication.",
          ],
        },
        {
          idx: 5,
          title: "What doesn't Alpha treat?",
          answers: [
            `
              <ul class="mb-8">
                <li>Bipolar Disorder</li>
                <li>Borderline Personality Disorder</li>
                <li>Schizophrenia</li>
              </ul>
              <p>Treatment for these conditions are best guided by a psychiatrist who specializes in diagnosing and treating more complex mental health conditions.</p>
            `,
          ],
        },
      ],
    },
  },
};

export const preferredPronounsOptions = [
  { value: "SHE_HER", label: "She, her, hers, herself" },
  { value: "HE_HIM", label: "He, him, his, himself" },
  { value: "THEY_THEM", label: "They, them, their, theirs, themselves" },
  { value: "ZE_HIR", label: "Ze, hir, hirs" },
  { value: "!none", label: "Prefer not to say" },
  { value: "ANY_PRONOUNS", label: "Pronouns" },
  { value: "other", label: "Other" },
];

export const preferredPronounsValues = preferredPronounsOptions.map((option) => option.value);

export function isLegacyAddress(address: GenericAddressSchema): address is LegacyPharmacyAddress {
  return (address as LegacyPharmacyAddress).legacy;
}

export const REQUIRED_BASE_MEMBERSHIP_CONDITIONS: string[] = [];
export const REQUIRED_PLUS_MEMBERSHIP_CONDITIONS: string[] = [
  "mental-health",
  "weight-loss",
  "diabetes",
];
export const REQUIRED_MEMBERSHIP_CONDITIONS: string[] = REQUIRED_BASE_MEMBERSHIP_CONDITIONS.concat(
  REQUIRED_PLUS_MEMBERSHIP_CONDITIONS,
);

export const CONSULT_FEE = 3500;
export const CONSULT_FEE_STR = "$35";

export type MEMBERSHIP_PLAN_NAMES = "alpha-membership" | "alpha-membership-plus";

export const MEMBERSHIP_PLANS: { [key: string]: MEMBERSHIP_PLAN_NAMES } = {
  alpha: "alpha-membership",
  plus: "alpha-membership-plus",
};

export const telehealthConsentVersion = { key: "telehealth-consent", version: "3.0.0" };

export const SUICIDE_HOTLINE_NUMBER = "988";

export const B2B_ORGANIZATION_SLUGS = {
  SONJA_CARES: "sonja-cares",
};

export const FUNNELS = {
  WEIGHT_LOSS: "weight-loss-plan",
};
