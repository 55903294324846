/** @format */
import React from "react";

import { faMonitorWaveform } from "@fortawesome/pro-regular-svg-icons/faMonitorWaveform";
import { faPills } from "@fortawesome/pro-regular-svg-icons/faPills";

import type {
  AlphaMedication,
  PatientMedicationTrackingSchema,
  SymptomTrackingSchema,
} from "src/api";

import { BottomSheet } from "src/components/BottomSheet/BottomSheet";
import { MedicationDoseForm } from "src/routes/Records/WeightLossJournal/MedicationDoseForm";
import { SymptomForm } from "src/routes/Records/WeightLossJournal/SymptomForm";
import { IconMenuList } from "src/v2/components/Menus/IconMenuList";

interface AddMedicationEntryBottomSheetProps {
  onClose: () => void;
  availableMedications: AlphaMedication[];
  entry?: PatientMedicationTrackingSchema | SymptomTrackingSchema;
  entryType?: "medication" | "symptom";
}

const AddMedicationEntryBottomSheet: React.FC<AddMedicationEntryBottomSheetProps> = (props) => {
  const isEdit = !!props.entry;

  const [selectedEntryType, setSelectedEntryType] = React.useState(
    isEdit ? props.entryType : undefined,
  );

  const selectedEntryLabel = selectedEntryType === "medication" ? "medication dose" : "symptom";
  return (
    <BottomSheet
      hideHeader={selectedEntryType === "symptom"}
      open={true}
      onClose={props.onClose}
      title={`${!isEdit ? "Add" : "Edit"} ${selectedEntryType ? selectedEntryLabel : "entry"}`}
    >
      {!selectedEntryType ? (
        <IconMenuList
          tools={[
            {
              icon: faPills,
              label: "Medication Dose",
              onClick: () => setSelectedEntryType("medication"),
            },
            {
              icon: faMonitorWaveform,
              label: "Symptom",
              onClick: () => setSelectedEntryType("symptom"),
            },
          ]}
        />
      ) : selectedEntryType === "medication" ? (
        <MedicationDoseForm
          entry={props.entry as PatientMedicationTrackingSchema}
          availableMedications={props.availableMedications}
          onFinish={props.onClose}
          onCancel={() => setSelectedEntryType(undefined)}
        />
      ) : (
        <SymptomForm
          entry={props.entry as SymptomTrackingSchema}
          onFinish={props.onClose}
          onCancel={() => setSelectedEntryType(undefined)}
          onClose={props.onClose}
        />
      )}
    </BottomSheet>
  );
};

export default AddMedicationEntryBottomSheet;
