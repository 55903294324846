/** @format */

import React from "react";

import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Typography } from "@alphamedical/components";

interface MenuTool {
  onClick: () => void;
  icon?: IconProp;
  label: string;
  iconClassName?: string;
}

interface IconMenuListProps {
  tools: MenuTool[];
}

export const IconMenuList = (props: IconMenuListProps) => (
  <>
    {props.tools.map((value, index) => (
      <div
        key={index}
        className="flex items-center justify-between pb-6 border-b border-grey-60 mb-6 cursor-pointer"
        onClick={value.onClick}
      >
        <div className="flex">
          {value.icon && (
            <div className="w-6 h-6 flex items-center justify-center mr-4">
              <FontAwesomeIcon size="lg" className={value.iconClassName} icon={value.icon} />
            </div>
          )}
          <Typography>{value.label}</Typography>
        </div>
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    ))}
  </>
);
