/** @format */

import EXIF from "exif-js";

import type { ExifImageRotateProps } from "./types";

export const exifImageRotate = (rotateProps: ExifImageRotateProps): void => {
  const { canvas, ctx, file, image, width, height, callback, name } = rotateProps;

  // Using 'exif-js' to get image orientation and draw it correctly.
  // https://stackoverflow.com/a/37750456/8335898
  EXIF.getData(file, function (this: any) {
    const orientation = EXIF.getTag(this, "Orientation");

    // Calculate the new width and height, because the photo might be
    // rotated so that they are switched
    if (orientation && orientation > 4) {
      canvas.width = height;
      canvas.height = width;
    } else {
      canvas.width = width;
      canvas.height = height;
    }

    if (orientation) {
      switch (orientation) {
        case 2: {
          // horizontal flip
          ctx.translate(width, 0);
          ctx.scale(-1, 1);
          break;
        }
        case 3: {
          // 180° rotate left
          ctx.translate(width, height);
          ctx.rotate(Math.PI);
          break;
        }
        case 4: {
          // vertical flip
          ctx.translate(0, height);
          ctx.scale(1, -1);
          break;
        }
        case 5: {
          // vertical flip + 90 rotate right
          ctx.rotate(0.5 * Math.PI);
          ctx.scale(1, -1);
          break;
        }
        case 6: {
          // 90° rotate right
          ctx.rotate(0.5 * Math.PI);
          ctx.translate(0, -height);
          break;
        }
        case 7: {
          // horizontal flip + 90 rotate right
          ctx.rotate(0.5 * Math.PI);
          ctx.translate(width, -height);
          ctx.scale(-1, 1);
          break;
        }
        case 8: {
          // 90° rotate left
          ctx.rotate(-0.5 * Math.PI);
          ctx.translate(-width, 0);
          break;
        }
        default:
          break;
      }
    }

    ctx.drawImage(image, 0, 0, width, height);

    callback({ canvas, name });
  });
};
