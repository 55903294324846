/** @format */

import React from "react";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Sentry from "@sentry/react";
import classnames from "classnames";
import moment from "moment";

import { Typography } from "@alphamedical/components";

import { ConsultsService } from "src/api";
import { getConditionInfo } from "src/v2/constants";
import { useCurrentUser } from "src/v2/models/profile";

import VisitStatusPill from "./VisitStatusPill";

interface VisitCardProps {
  visit?: any;
  text?: string;
  link?: string;
  icon?: string;
  cardType: "consultCard" | "taskCard";
  iconElement?: React.ReactNode;
}

export const conditionMap: Partial<Record<string, string>> = {
  eyelashes: "lashes",
};

const getLink = (link: string | undefined, visit: any, showArrow: boolean): string => {
  if (visit && visit.status === "STARTED") {
    return `/${visit.consult_type === "VISIT" ? "visit" : "consultation"}/${
      conditionMap[visit.subscription.condition.key] || visit.subscription.condition.key
    }`;
  } else if (visit && visit.status === "APPROVED" && showArrow) {
    return `/visit-details/${visit.subscription.condition.key}${
      visit.consult_type === "VISIT" ? `?consult_id=${visit.id}` : ""
    }`;
  } else if (!visit && link) {
    return link;
  } else {
    return "";
  }
};

const VisitCard = (props: VisitCardProps) => {
  const [isCheckedForTreated, setIsCheckedForTreated] = React.useState<boolean>(false);
  const user = useCurrentUser();
  const [isTreated, setIsTreated] = React.useState<boolean>(false);
  const history = useHistory();
  const visit = props.visit;
  const hoursSinceApproved = visit?.approved_at
    ? moment().diff(moment(visit.approved_at), "hours")
    : 0;
  const hasBeenUpdatedWithin72Hours = hoursSinceApproved < 72;
  const link = props.link
    ? props.link
    : getLink(props.link, visit, isTreated || !hasBeenUpdatedWithin72Hours);
  const date =
    props.cardType === "taskCard"
      ? moment(visit.trigger_at).format("MMM D").toUpperCase().split(" ")
      : "";
  const conditionInfoForConsult = getConditionInfo(
    visit && props.cardType === "consultCard" ? visit.subscription.condition.key : "",
  );
  const text =
    visit && props.cardType === "consultCard" ? visit.condition_display_name : props.text;

  const callHasTreatmentPlan = async () => {
    setIsCheckedForTreated(false);
    if (visit) {
      try {
        const consultId = visit.latestConsultId ? visit.latestConsultId : visit.id;
        await ConsultsService.getConsultPvs({ userId: user.id, consultId: consultId }).then(
          (res) => {
            setIsTreated(!!res);
            setIsCheckedForTreated(true);
          },
        );
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  };

  React.useEffect(() => {
    callHasTreatmentPlan();
  }, []);

  return (
    <button
      className={classnames(
        "flex items-center w-full",
        link.length ? "cursor-pointer" : "cursor-default",
      )}
      onClick={() => (link.length ? history.push(link) : "")}
      disabled={link.length === 0}
    >
      {props.cardType === "consultCard" && (
        <>
          <div
            className={classnames(
              "h-16 w-16 min-w-16 flex justify-center items-center rounded",
              visit ? "bg-sand-40" : "bg-grey-10",
            )}
          >
            {!props.iconElement ? (
              <img
                src={visit ? conditionInfoForConsult.imageSrc : props.icon}
                alt={visit ? visit.condition_display_name + "icon" : "start visit icon"}
                role="presentation"
                className={visit ? "p-3 grow h-16" : "h-6"}
              />
            ) : (
              props.iconElement
            )}
          </div>
          <div className="flex pl-6 justify-between items-center text-forest-120 w-full">
            <div className="flex flex-col items-start">
              <Typography className="mb-1" variant="h4" component="h4" align="left">
                {text}
              </Typography>
              {visit && visit.status && isCheckedForTreated && (
                <VisitStatusPill
                  status={visit.status}
                  treated={isTreated}
                  hasBeenUpdatedWithin72Hours={hasBeenUpdatedWithin72Hours}
                />
              )}
            </div>
            {!!link.length && <FontAwesomeIcon color="#656E6D" icon={["fas", "chevron-right"]} />}
          </div>
        </>
      )}

      {props.cardType === "taskCard" && (
        <>
          <div
            className={classnames(
              "h-16 w-16 min-w-12 flex flex-col justify-center items-center rounded text-forest-120",
              visit.returnVisitStatus === "due" ? "bg-cloud-light-40" : "bg-grey-10",
            )}
          >
            <p className="text-xs">{date[0]}</p>
            <Typography variant="h4" component="h4" className="text-md">
              {date[1]}
            </Typography>
          </div>
          <div className="flex pl-4 justify-between items-center text-forest-120 w-full">
            <div className="flex flex-col items-start">
              <Typography variant="h4" component="h4" align="left">
                {text}
              </Typography>
            </div>
            {!!link.length && <FontAwesomeIcon color="#656E6D" icon={["fas", "chevron-right"]} />}
          </div>
        </>
      )}

      {}
    </button>
  );
};

export default VisitCard;
