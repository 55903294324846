/** @format */

import React from "react";

import { Loader } from "src/components/Loader";
import { AccordionBox } from "src/components/QuestionAccordionBox/AccordionBox";
import { VimeoPlayer } from "src/components/VimeoPlayer";
import { AlphaModal } from "src/v2/components/AlphaModal";

export const BCMethodInfo = () => {
  const [showVideo, setShowVideo] = React.useState<boolean>(false);
  const expandedAnswer = [
    "Birth control costs $0 with most insurance plans.",
    "Alpha accepts insurance for most prescription medications. Our team is able to check if we accept your specific insurance, as well as any associated copays or costs.",
    "If you are paying with cash, price varies by medication. If your medication costs over $60 you will be notified before the charge is processed.",
  ];
  return (
    <div>
      <AccordionBox title={"How much will it cost me?"} expandedAnswer={expandedAnswer} />
      <div
        className="bg-sand-40 py-4 px-8 mt-3 mb-8 flex items-center text-14 cursor-pointer"
        onClick={() => setShowVideo(true)}
      >
        <img src="/assets/play.svg" />
        <span className="flex-1 ml-4 font-medium">
          Not sure which method to choose? Watch this short video to help you decide.
        </span>
      </div>
      {showVideo && (
        <AlphaModal
          isOpen={true}
          closeIconColor="primary"
          bgColor="transparent"
          cxPadding="pt-12 px-5"
          className="max-w-lg mx-auto"
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => setShowVideo(false)}
        >
          <div className="relative">
            <div className="absolute inset-0 bg-white">
              <Loader show={true}>{null}</Loader>
            </div>
            <VimeoPlayer videoId={"366374656"} />
          </div>
        </AlphaModal>
      )}
    </div>
  );
};
