/** @format */
import _ from "lodash";
import moment from "moment";

import type { MembershipSchema, PiiSchema, ProfileSchema } from "src/api";
import type {
  CustomFieldCondition,
  DynamicField,
  DynamicFormPage,
  FieldCondition,
  GenericFieldCondition,
} from "src/components/DynamicForm/types";

import {
  isCustomFieldCondition,
  isGenericFieldCondition,
  isNestedFieldCondition,
} from "src/components/DynamicForm/types";
import { hasEmployerBenefits } from "src/utils/hooks/useHasEmployerBenefits";
import { FUNNELS, MEMBERSHIP_PLANS } from "src/v2/constants";
import { calculateAgeRange } from "src/v2/utils/page/_validators/condition";
import { getFieldToCheck, showDependingOnScore } from "src/v2/utils/page/fields";

type FormValue = Record<string, any>;

export const genericFieldConditionProcessor = (
  conditional: GenericFieldCondition,
  formValues: Record<string, any>,
) => {
  let currentFieldValue = formValues[conditional.key];
  // If the currentFieldValue is null, it means the field value isn't present in the formValues.
  if (currentFieldValue == null) {
    // If field value isn't present in the formValues we check if the conditional object has a not property, and return the opposite value.
    // So if conditional.not === true, return false
    // If conditional.not === undefined, return true
    // This will cause the field to show in the case where the value we're checking against doesn't exist if the conditional contains a "not" property.
    return !!conditional.not;
  }

  // Push the currentFieldValue into an array if it isn't one.
  currentFieldValue = _.isArray(currentFieldValue) ? currentFieldValue : [currentFieldValue];

  if (conditional.anyOf) {
    return (
      conditional.anyOf.filter((value: any) => currentFieldValue.indexOf(value) !== -1).length > 0
    );
  } else if (conditional.noneOf) {
    return _.intersection(conditional.noneOf, currentFieldValue).length === 0;
  } else {
    // Check if the conditional.value is in the currentFieldValue array.
    const result = currentFieldValue.indexOf(conditional.value) !== -1;
    return conditional.not ? !result : result;
  }
};

export const getPiiSpecificKey = (key: string): keyof PiiSchema => {
  if (key === "firstname") {
    return "first_name";
  } else if (key === "lastname") {
    return "last_name";
  } else if (key === "which-race-describes-you") {
    return "racial_description";
  }
  return key as keyof PiiSchema;
};

export const hideIfAlreadyCapturedField = (field: DynamicField, pii: PiiSchema) => {
  // Hide the field if already exists in PII, or if it's in the locked field list that comes from BE
  const lockedFields = pii.locked_fields || [];
  const lockedField = lockedFields.indexOf(field.key) !== -1;
  const piiSpecificKey = getPiiSpecificKey(field.key);

  if (pii[piiSpecificKey] && Array.isArray(pii[piiSpecificKey])) {
    return pii[piiSpecificKey].length > 0;
  }

  return pii[piiSpecificKey] || lockedField;
};

/**
 * Returns true if the photo upload component, with all its fields, should be shown.
 *
 * @param field DynamicField
 * @param pii PiiSchema
 */
export const showPhotoUpload = (field: DynamicField, pii: PiiSchema): boolean => {
  let result = false;
  if (pii["license_expiration"]) {
    const expirationDate = moment(pii["license_expiration"]);
    if (expirationDate.isBefore()) result = true;
  } else result = true;

  if (!pii["license_type"]) result = true;

  if (!pii["license_photo"]) result = true;

  return result;
};

/**
 * Determines whether to hide a field based on the provided conditional.
 * If the user profile is not provided, the field will be shown.
 * @param conditional - The conditional object containing the values to evaluate.
 * @param profile - The user's profile information.
 * @returns A boolean value indicating whether the field should be hidden.
 */
export const hideIfActiveBenefits = (
  conditional: CustomFieldCondition,
  profile?: ProfileSchema,
) => {
  const { value, not, anyOf, noneOf } = conditional;
  if (!profile) {
    return false;
  }
  const userPlanRecords = profile.employer_benefits;

  let hideIt;
  if (value) {
    hideIt = hasEmployerBenefits(userPlanRecords, value);
  } else if (anyOf) {
    hideIt = hasEmployerBenefits(userPlanRecords, anyOf);
  } else if (noneOf) {
    hideIt = !hasEmployerBenefits(userPlanRecords, noneOf);
  } else {
    hideIt = hasEmployerBenefits(userPlanRecords);
  }

  return not ? !hideIt : hideIt;
};

/**
 * Processes custom field conditions and returns a boolean value indicating whether the field should be shown or hidden.
 *
 * @param values - An object containing the form values.
 * @param field - The dynamic field being evaluated.
 * @param conditional - The custom field condition to evaluate.
 * @param pii - Optional personal identifiable information.
 * @param profile - Optional profile information.
 * @returns A boolean value indicating whether the field should be shown (true) or hidden (false).
 */
export const customFieldConditionProcessor = (
  values: { [key: string]: any },
  field: DynamicField,
  conditional: CustomFieldCondition,
  pii?: PiiSchema,
  profile?: ProfileSchema,
  membership?: MembershipSchema,
) => {
  if (conditional.method === "hide:if:already:captured" && pii) {
    // Because the way this function is invoked upstream, we have to negate the response
    return !hideIfAlreadyCapturedField(field, pii);
  } else if (conditional.method === "hide:if:photo:upload" && pii) {
    // Conditional to show or hide photo upload if any of the photo values are missing
    return showPhotoUpload(field, pii);
  } else if (conditional.method === "calculate:age") {
    const not = conditional.not ? conditional.not : false;
    let { dob } = values;
    // we need to get the patients dob from pii values if they haven't answered dob in this form
    if (!dob && pii?.dob) {
      dob = pii.dob;
    }
    if (not) {
      return dob && !calculateAgeRange(dob, conditional.minAge, conditional.maxAge);
    }
    return dob && calculateAgeRange(dob, conditional.minAge, conditional.maxAge);
  } else if (conditional.method === "showDependingOnScore" && conditional.score_to_test) {
    return showDependingOnScore(values, conditional.score_to_test);
  } else if (conditional.method === "show:if:weight:loss:funnel") {
    return profile?.meta?.funnel === FUNNELS.WEIGHT_LOSS;
  } else if (conditional.method === "hide:if:active:benefits") {
    // if we have a profile, we can check if the user has benefits. Returning true will show the field (the inverse of hiding it)
    // Otherwise, show the field
    return !hideIfActiveBenefits(conditional, profile);
  } else if (conditional.method == "hide:if:member") {
    // returning false will hide the field
    return conditional.not ? membership && membership.active : !(membership && membership.active);
  } else if (conditional.method == "hide:if:not:member") {
    return conditional.not ? !(membership && membership.active) : membership && membership.active;
  } else if (conditional.method === "hide:if:ubacare") {
    return !profile?.is_ubacare_user;
  }
};

const processConditional = (
  conditional: FieldCondition,
  field: DynamicField,
  values: FormValue,
  pii?: PiiSchema,
  profile?: ProfileSchema,
  membership?: MembershipSchema,
): boolean => {
  if (isNestedFieldCondition(conditional)) {
    if (conditional.type === "or") {
      return conditional.conditionals.some((subConditional: FieldCondition) => {
        return processConditional(subConditional, field, values, pii, profile, membership);
      });
    } else {
      return conditional.conditionals.every((subConditional: FieldCondition) => {
        return processConditional(subConditional, field, values, pii, profile, membership);
      });
    }
  }
  if (isGenericFieldCondition(conditional)) {
    return genericFieldConditionProcessor(conditional, values);
  }
  if (isCustomFieldCondition(conditional)) {
    return customFieldConditionProcessor(values, field, conditional, pii, profile, membership);
  }
  return true;
};

export const checkConditionalsOnGenericForms = (
  field: DynamicField,
  values: FormValue,
  pii?: PiiSchema,
  profile?: ProfileSchema,
  membership?: MembershipSchema, // required for method hide:if:active:benefits
) => {
  // If the field is an info field with a reference, we need to check the conditionals on the referenced field.
  // This is similar to the way we read the conditional in the Consult flow.
  const fieldToCheck = getFieldToCheck(field);

  if (fieldToCheck.conditionals) {
    // This treats the conditional objects as && statements. TODO: Implement || conditional processing.
    return fieldToCheck.conditionals.every((conditional: FieldCondition) => {
      return processConditional(conditional, field, values, pii, profile, membership);
    });
  }
  return true;
};

/**
 * Returns an array of fields that can be shown based on their conditional logic and the current input values.
 *
 * @param fields An array of DynamicField objects representing the fields to check.
 * @param values An object with key-value pairs representing the input values for the form.
 * @param pii Optional. An object representing the PII fields for the form.
 *
 * @returns An array of DynamicField objects that can be shown based on their conditional logic and the current input values.
 */
export const getFieldsCanBeShown = (
  fields: any,
  values: Record<string, any>,
  pii?: PiiSchema,
  profile?: ProfileSchema,
) => {
  return fields.filter((field: any) =>
    checkConditionalsOnGenericForms(field, values, pii, profile),
  );
};

/**
 * Returns the index of the first unanswered page in a dynamic form, based on the given input values.
 *
 * @param pages An array of DynamicFormPage objects representing the pages of the form.
 * @param values An object with key-value pairs representing the input values for the form.
 * @param pii Optional. An object representing the PII fields for the form.
 *
 * @returns The index of the first unanswered page in the form, or the index of the last page if all pages are answered.
 */
export const getFirstUnansweredPageIdx = (
  pages: DynamicFormPage[],
  values: Record<string, any>,
  pii?: PiiSchema,
  profile?: ProfileSchema,
) => {
  let firstUnansweredPageIdx = pages.findIndex((page: DynamicFormPage) => {
    const _requiredFields = getFieldsCanBeShown(page.fields, values, pii, profile).filter(
      (field: DynamicField) => !!field.required,
    );

    const _fields = _requiredFields.map((field: any) => ({
      key: field.key,
      required: field.required,
      answered: !!values[field.key] && values[field.key] !== "null",
    }));

    if (page.cannotBeSkippedIfAnswered) {
      return true;
    }
    return (
      (_requiredFields.length && !_fields.length) || !_fields.every((field: any) => field.answered)
    );
  });

  if (firstUnansweredPageIdx === -1) {
    firstUnansweredPageIdx = pages.length - 1;
  }

  return firstUnansweredPageIdx;
};

/**
 * Returns the index of the last answered page in a dynamic form.
 * If no pages are answered, it returns the index of the first page.
 *
 * @param pages An array of dynamic form pages.
 * @param values An object with key-value pairs representing the input values for the form.
 * @param pii Optional. An object representing the PII fields for the form.
 *
 * @returns The index of the last answered page or the index of the first page if no pages are answered.
 */
export const getLastAnsweredPageIdx = (
  pages: DynamicFormPage[],
  values: Record<string, any>,
  pii?: PiiSchema,
  profile?: ProfileSchema,
) => {
  const firstUnansweredPageIdx = getFirstUnansweredPageIdx(pages, values, pii, profile);
  let lastAnsweredPageIdx = -1;

  for (let i = firstUnansweredPageIdx - 1; i > 0; i--) {
    const page = pages[i];

    // Check if the page has fields to be shown; if not, the page will be hidden
    const fieldsToBeShown = getFieldsCanBeShown(page.fields, values, pii, profile);

    // If the page includes input fields, it is considered answered and we can show it.
    const pageHasInputFields = fieldsToBeShown.some((field: DynamicField) => field.type !== "info");
    if (pageHasInputFields) {
      lastAnsweredPageIdx = i;
      break;
    }

    // If the index is 0, we can show the page even if it doesn't have input fields, assuming there are not answered pages yet.
    if (i === 0) {
      lastAnsweredPageIdx = i;
    }
  }

  return lastAnsweredPageIdx !== -1 ? lastAnsweredPageIdx : firstUnansweredPageIdx;
};
