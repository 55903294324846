/** @format */

import React from "react";

import {
  faCalendar,
  faMemoCircleCheck,
  faNotesMedical,
  faPhone,
} from "@fortawesome/pro-regular-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { AccordionContentType } from "src/v2/designSystem";

import { Accordion, AccordionContentTitle } from "src/v2/designSystem";

import { type PatientActionItemSchema, type SynchronousVisitSchema } from "src/api";
import { useSynchronousVisits } from "src/utils/hooks/useSynchronousVisits";
import PatientActionItems from "src/v2/components/PatientActionItems/PatientActionItems";
import VisitList from "src/v2/components/Visits/VisitList";
import { useStoreDispatch, useStoreState } from "src/v2/models";

import ReturnVisitsList from "./ReturnVisitsList";
import SynchronousVisitsList from "./SynchronousVisitsList";

const StatusTitle = ({ showIcon = false }) => (
  <div className={"flex items-center"}>
    <div className="pl-4">
      <AccordionContentTitle title="Action items" />
    </div>
    {showIcon && (
      <FontAwesomeIcon
        className="ml-2 text-sunset-60"
        icon={faCircle}
        style={{ fontSize: "8px" }}
      />
    )}
  </div>
);

const MyCasesAccordion = (args: any) => {
  const dispatch = useStoreDispatch();
  const filteredReturnVisits = args.returnVisits.filter((visit: any) => visit !== undefined);
  const dueVisits = filteredReturnVisits.filter(
    (visit: { returnVisitStatus: string }) => visit && visit.returnVisitStatus === "due",
  );
  const upcomingVisits = filteredReturnVisits.filter(
    (visit: { returnVisitStatus: string }) => visit && visit.returnVisitStatus === "upcoming",
  );
  const hasReturnVisits = [...dueVisits, ...upcomingVisits].length > 0;
  const actionItems: PatientActionItemSchema[] = useStoreState(
    (state) => state.actionItems.actionItems,
  );
  const pendingActionItems = actionItems.filter((item) => item.status === "PENDING");
  const hasPendingActionItems = pendingActionItems?.length > 0;
  const scheduledCalls: SynchronousVisitSchema[] = useSynchronousVisits();

  React.useEffect(() => {
    dispatch.actionItems.fetchActionItems();
  }, []);

  const scheduledCallsSection = {
    id: 3,
    title: "Provider calls",
    content: <SynchronousVisitsList scheduledCalls={scheduledCalls} />,
    collapsed: true,
    disabled: false,
    sectionIcon: <FontAwesomeIcon size="xl" icon={faPhone} />,
  };

  const returnVisitsSection = {
    id: 1,
    title: "Return visits",
    content: <ReturnVisitsList dueVisits={dueVisits} upcomingVisits={upcomingVisits} />,
    collapsed: !hasReturnVisits || hasPendingActionItems,
    disabled: false,
    sectionIcon: <FontAwesomeIcon icon={faCalendar} size="xl" />,
  };

  const yourVisitsSection = {
    id: 0,
    title: "Your visits",
    content: (
      <VisitList
        consults={args.yourVisits}
        limitItems={3}
        listType="consultList"
        linkPath="/visits/your-visits"
        linkText="All visits"
        startVisitCard={true}
      />
    ),
    collapsed: hasReturnVisits || hasPendingActionItems,
    disabled: false,
    sectionIcon: <FontAwesomeIcon size="xl" icon={faNotesMedical} />,
  };
  const actionItemsSection = {
    id: 2,
    title: "Action Items",
    content: <PatientActionItems highlighted actionItems={pendingActionItems} />,
    disabled: false,
    collapsed: !hasPendingActionItems,
    sectionIcon: (
      <FontAwesomeIcon style={{ height: "24px", width: "24px" }} icon={faMemoCircleCheck} />
    ),
    customTitle: <StatusTitle showIcon={hasPendingActionItems} />,
  };

  const sections: AccordionContentType[] = [yourVisitsSection];

  if (filteredReturnVisits.length > 0) {
    sections.unshift(returnVisitsSection);
  }

  if (scheduledCalls.length > 0) {
    sections.unshift(scheduledCallsSection);
  }

  if (actionItems.length > 0) {
    sections.unshift(actionItemsSection);
  }

  return (
    <div className="flex mb-10">
      <Accordion variant="content" sections={sections} expandMultiple={false} className="w-full" />
    </div>
  );
};

export default MyCasesAccordion;
