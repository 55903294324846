/** @format */

import React from "react";

import type { FormApi } from "final-form";
import _ from "lodash";

import { isValidDOB } from "src/components/DynamicForm/Processors/fieldValidators";

import type { PageFormProps } from "./types";

import { GenericPage } from "./genericPage";

const validateEligibility = (dob: string, state: string, sex: string): object | undefined => {
  // TODO: Move eligibilty logic outside of this component
  const eligibilityErrors: any = {};
  const invalidDOB = isValidDOB(dob, 18);

  if (invalidDOB) eligibilityErrors.dob = invalidDOB;

  return eligibilityErrors;
};

export const EligibilityPage = (props: PageFormProps) => {
  const _onSubmit = (values: object, form: FormApi) => {
    if (validateEligibility) {
      const { dob, state, sex } = values as any;
      const eligibilityErrors = validateEligibility(dob, state, sex);
      if (!!eligibilityErrors && !_.isEmpty(eligibilityErrors)) {
        return eligibilityErrors;
      }
    }
    return props.form.onSubmit(values, form);
  };
  return <GenericPage {...props} form={{ ...props.form, onSubmit: _onSubmit }} />;
};
