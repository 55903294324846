/** @format */

import type { DropFilesEventHandler } from "react-dropzone";

import React from "react";
import { Field, Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";

import cn from "classnames";
import { includes, isEmpty } from "lodash";
import { usePubNub } from "pubnub-react";

import { Popup } from "@alphamedical/components";

import type { WaitForPCPSchema } from "src/api";
import type { CardPopupContent } from "src/components/Popups/CardPopup";
import type { MessageFields } from "src/routes/Messages/NewMessageThread";
import type { PubNubMessage } from "src/utils/hooks/pubnub";
import type { Option } from "src/v2/models/record";
import type { Tag } from "src/v2/models/tags";

import { ProviderCoverageService } from "src/api";
import BackButton from "src/components/_buttons/BackButton";
import { useChannels } from "src/components/Chat/ChannelLoader";
import { Message } from "src/components/Chat/Message";
import { MessageInput } from "src/components/Chat/Message/MessageInput";
import { onFileDrop, sendMessage } from "src/components/Chat/utils";
import { NavMenuButtonPortal } from "src/components/HeaderNav/NavMenuButton";
import { Loader } from "src/components/Loader";
import { CardPopup } from "src/components/Popups/CardPopup";
import { WaitForPCPPopup } from "src/components/Popups/WaitForPCPPopup";
import config from "src/config";
import { useAuthenticatedUser, useServices, useTags, useThunk } from "src/utils/hooks";
import { createMessagesDependency, useChannelMessages } from "src/utils/hooks/pubnub";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { Button } from "src/v2/components/Button";
import { MarkdownText } from "src/v2/components/MarkdownText";
import { CONSULTS_WITH_CHECKUP } from "src/v2/constants";
import { useStoreDispatch, useStoreState } from "src/v2/models";

import UploadedAttachments from "./UploadedAttachments";

interface ChatChannelProps {
  channelName: string;
}

// When updating this array with message identifiers which are hidden from patients,
// be sure to update the corresponding array 'messagesHiddenFromPatients' in CDB at
// src/components/Chat/Channel.tsx
const messagesToHide = ["pvs_sent_system_message"];
export const shouldHideMessage = (message: PubNubMessage) => {
  const customIdentifier = message.message.customAttributes?.["customIdentifier"];
  return customIdentifier ? messagesToHide.includes(customIdentifier) : false;
};

const ChatChannel = ({ channelName }: ChatChannelProps) => {
  const dispatch = useStoreDispatch();
  const history = useHistory();
  const [loading, messages, hasUnloadedMessages, loadMoreMessages] =
    useChannelMessages(channelName);
  const [text, setText] = React.useState<string>("");
  const [attachments, setAttachments] = React.useState<any[]>([]);
  const [fieldError, setFieldError] = React.useState<string>("");
  const pubnub = usePubNub();
  const [user] = useAuthenticatedUser();
  const channels = useChannels();
  const [authenticatedUser] = useAuthenticatedUser();
  const [fetchingRecurlySubscription] = useThunk(() => {
    return dispatch.subscriptions.fetchRecurlySubscriptions("mental-health");
  });
  const selectedChannel = channels.find((channel) => channel.name === channelName);
  const supportChannel = channels.find((channel) => channel.channel_type === "SUPPORT");
  const channelContainerRef = React.useRef<HTMLDivElement>(null);
  const isLocked =
    channels.findIndex((channel) => channel.name === channelName && channel.locked) !== -1;
  const [lockReason, setLockReason] = React.useState<string | null>(null);
  const [redirectChannelName, setRedirectChannelName] = React.useState("");
  const [showContinue, setShowContinue] = React.useState(false);
  const { serviceOptions } = useServices();
  const { subjectList, subjectOptions } = useTags();
  const [popupOpen, setPopupOpen] = React.useState<boolean>(false);
  const [popupContent, setPopupContent] = React.useState<string>("");
  const [popupTitle, setPopupTitle] = React.useState<string>("");
  const lastElementRef = React.useRef<HTMLDivElement>(null);
  const [waitForPCPOpen, setWaitForPCPOpen] = React.useState(false);
  const [waitForPCPData, setWaitForPCPData] = React.useState<WaitForPCPSchema>();
  const [bioModalContent, setBioModalContent] = React.useState<CardPopupContent>();
  const [bioModalIsOpen, setBioModalIsOpen] = React.useState(false);
  const isSupportChannel = selectedChannel?.channel_type === "SUPPORT";
  const isNotificationsChannel = selectedChannel?.channel_type === "NOTIFICATION";
  const isHealthCheckInChannel = selectedChannel?.channel_type === "HEALTH_CHECKIN";

  let topBarTitle: string;
  if (isSupportChannel) {
    topBarTitle = "Support Team ";
  } else if (isNotificationsChannel) {
    topBarTitle = "Notifications";
  } else if (isHealthCheckInChannel) {
    topBarTitle = "Health Check In";
  } else {
    topBarTitle = selectedChannel?.service?.condition?.name || "";
  }
  useTopBar({
    title: topBarTitle,
    variant: "nested",
    showBackButton: true,
    subtitle: !isNotificationsChannel ? config.responseTimeMessage || "" : undefined,
  });

  const openAndSetPopupContent = (tag: Tag | undefined) => {
    if (tag?.popup_active && tag?.popup_content) {
      setPopupOpen(true);
      setPopupContent(tag.popup_content);
      setPopupTitle(tag?.popup_title || "");
    }
  };

  React.useEffect(() => {
    if (messages.length > 0) {
      const latestMessage = messages[messages.length - 1];
      const readAction =
        latestMessage.actions &&
        latestMessage.actions.receipt &&
        latestMessage.actions.receipt.message_read;
      if (!readAction) {
        const messageTimetoken = `${latestMessage.timetoken}`;
        pubnub.addMessageAction({
          channel: channelName,
          messageTimetoken,
          action: { type: "receipt", value: "message_read" },
        });
      }
    }
    if (selectedChannel && isLocked && selectedChannel.locked_reason) {
      setLockReason(selectedChannel.locked_reason);
    }
  }, [channelName, !!pubnub, createMessagesDependency(messages)]);

  React.useEffect(() => {
    if (selectedChannel?.id) {
      dispatch.actionItems.fetchMessageActionItemByChannel(selectedChannel.id);
    }
    return () => {
      dispatch.actionItems.clearMessageActionItem();
    };
  }, [selectedChannel?.id]);

  const redirectMessage = () => {
    if (redirectChannelName) {
      history.replace(`/messages/${redirectChannelName}`);
      setRedirectChannelName("");
      setShowContinue(false);
      window.location.reload();
    }
  };

  React.useEffect(() => {
    let redirectInterval: NodeJS.Timeout;
    let continueInterval: NodeJS.Timeout;
    if (redirectChannelName) {
      redirectInterval = setInterval(() => {
        redirectMessage();
      }, 10000);
      continueInterval = setInterval(() => {
        setShowContinue(true);
      }, 4000);
    }
    return () => {
      clearInterval(redirectInterval);
      clearInterval(continueInterval);
    };
  }, [redirectChannelName]);

  const messageActionItem = useStoreState((state) => state.actionItems.messageActionItem);
  const isLegacyChannel = selectedChannel?.channel_type === "LEGACY";
  const isHealthCheckinChannel = selectedChannel?.channel_type === "HEALTH_CHECKIN";
  const showServiceDropdown = isSupportChannel && !isEmpty(serviceOptions);

  const validate = (values: MessageFields = {}) => {
    let errorMessage = "";
    if (showServiceDropdown && !values.service) {
      errorMessage = "You must choose an Alpha Service.";
    }
    if (!values.subject && !isHealthCheckinChannel) {
      errorMessage = "You must choose a subject.";
    }
    if (showServiceDropdown && !values.service && !values.subject) {
      errorMessage = "You must choose a subject and an Alpha Service.";
    }
    setFieldError(errorMessage);
    return errorMessage;
  };

  const onSubmit = (values: any) => {
    const errorMessage = validate(values);
    const categorySlug = isHealthCheckinChannel ? "health-checkin" : values?.subject?.value;
    const conditionKey = values?.service?.value || selectedChannel?.service?.condition?.key;
    const team = isHealthCheckinChannel ? "CLINICAL" : values?.subject?.team;
    let correctedChannelName = channelName;
    if (isSupportChannel && team == "CLINICAL") {
      const correctChannel = channels.find(
        (channel) => channel?.service?.type == conditionKey && !channel.locked,
      );
      if (correctChannel) {
        correctedChannelName = correctChannel.name;
      }
    }
    if (!isSupportChannel && includes(["SUPPORT", "PHARMACY"], team)) {
      const correctChannel = channels.find(
        (channel) => channel?.channel_type == "SUPPORT" && !channel.locked,
      );
      if (correctChannel) {
        correctedChannelName = correctChannel.name;
      }
    }
    const canSendMessage = !errorMessage && (!!text || attachments.length > 0);
    let sendMessagePromise;
    if (canSendMessage) {
      sendMessagePromise = sendMessage({
        text,
        authorId: authenticatedUser.id,
        channelName: correctedChannelName,
        setText,
        pubnub,
        categorySlug,
        conditionKey,
        attachments,
        callBack: () => {
          setAttachments([]);
          ProviderCoverageService.getPatientWaitForPcp({
            userId: "me",
            entityId: channelName,
            entityType: "channel",
          }).then((res) => {
            if (res.can_wait_pcp) {
              setWaitForPCPData(res);
              setWaitForPCPOpen(true);
            }
          });
        },
      });
    }
    if (channelName !== correctedChannelName) {
      setRedirectChannelName(correctedChannelName);
    }
    return sendMessagePromise;
  };

  const onDrop: DropFilesEventHandler = (acceptedFiles, _rejectedFiles) => {
    onFileDrop({
      acceptedFiles,
      channels,
      channelName,
      setAttachments,
      attachments,
      dispatch,
    });
  };

  React.useEffect(() => {
    // Scroll to bottom of channel container when a message is added/loaded
    if (
      messages.length > 0 &&
      channelContainerRef &&
      channelContainerRef.current &&
      !redirectChannelName &&
      !loading
    ) {
      lastElementRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [channelContainerRef.current, loading, redirectChannelName, messages.length]);

  const asteriskContent = "\\002A";
  const asterisk = { ":after": { content: `"${asteriskContent}"`, color: "red", margin: "5px" } };
  const customStyles = {
    control: (styles: any) => ({
      ...styles,
      paddingTop: ".35rem",
      paddingBottom: ".35rem",
      border: "0",
      borderRadius: "0",
      backgroundColor: "#f8fafc",
    }),
    placeholder: (styles: any) => ({ ...styles, color: "#aebebd", ...asterisk }),
    indicatorSeparator: (styles: any) => ({ ...styles, width: "0" }),
  };

  const selectField: React.FC<any> = ({ input, ...rest }) => (
    <>
      <Select {...input} {...rest} menuPlacement="auto" />
    </>
  );

  const currentSubject = () => {
    const messageSubject = messageActionItem?.last_external_message_category;
    if (messageSubject) {
      return {
        value: messageSubject?.slug,
        label: messageSubject?.label,
        team: messageSubject?.team,
      };
    }
    return;
  };
  const currentService = () => {
    if (messageActionItem?.condition?.key && messageActionItem?.condition?.name) {
      return { value: messageActionItem.condition.key, label: messageActionItem.condition.name };
    }
    return;
  };

  const initialValues = React.useMemo(
    () => ({
      subject: currentSubject(),
      service: currentService(),
    }),
    [messageActionItem],
  );

  const redirectTeam = isSupportChannel ? "your clinical team" : "Alpha Support";

  const isUbacareChannel = selectedChannel?.service?.condition?.key === "ubacare-program-visit";

  const consultExpired =
    selectedChannel?.service?.condition &&
    isLocked &&
    lockReason === "expired_consult" &&
    (includes(CONSULTS_WITH_CHECKUP, selectedChannel.service.condition.key) || isUbacareChannel);

  const visitExpired =
    selectedChannel?.service?.condition && isLocked && lockReason === "expired_visit";

  const newConsultLink = (key: string | undefined) => {
    if (key === "mental-health") {
      return "/return-consult/depression-anxiety-checkup";
    }
    if (includes(CONSULTS_WITH_CHECKUP, key)) {
      return key === "sti-testing-kit" ? `/consultation/${key}` : `/return-consult/${key}-checkup`;
    } else if (!selectedChannel?.service?.condition?.refillable) {
      return `/visit/${key}`;
    }
    return "";
  };

  const healthCheckinLocked = isHealthCheckinChannel && isLocked;
  const lockedForGenericReason =
    !consultExpired &&
    !visitExpired &&
    isLocked &&
    !isNotificationsChannel &&
    !isHealthCheckinChannel;
  const readOnly =
    consultExpired ||
    visitExpired ||
    isNotificationsChannel ||
    lockedForGenericReason ||
    healthCheckinLocked;

  const handleCloseBioModal = () => {
    setBioModalIsOpen(false);
    setBioModalContent(undefined);
  };
  const handleWaitForPCPValue = (value: boolean) => {
    ProviderCoverageService.setPatientWaitForPcp({
      userId: "me",
      entityId: channelName,
      entityType: "channel",
      requestBody: { value },
    }).finally(() => {
      setWaitForPCPOpen(false);
    });
  };
  const onContinue = () => handleWaitForPCPValue(false);
  const onWait = () => handleWaitForPCPValue(true);
  return (
    <>
      <div
        className={cn("sm:mt-8 sm:px-4 flex-1 min-h-3/4vh", { "bg-white": !redirectChannelName })}
        ref={channelContainerRef}
      >
        <Popup
          headerText={popupTitle}
          isOpen={popupOpen}
          onRequestClose={() => setPopupOpen(false)}
        >
          <div className="p-4">
            <div className="mb-4">
              <MarkdownText
                linkStyle={{ color: "#6271C2", fontWeight: "bold" }}
                text={popupContent}
              />
            </div>
            <Button onClick={() => setPopupOpen(false)}>Continue</Button>
          </div>
        </Popup>
        {waitForPCPData && (
          <WaitForPCPPopup
            type="channel"
            providerName={waitForPCPData.provider_name}
            returnDate={waitForPCPData.return_date}
            pending_consult_task={waitForPCPData.pending_consult_task}
            open={waitForPCPOpen}
            onCancel={onContinue}
            onWait={onWait}
            onAutoClose={() => setWaitForPCPOpen(false)}
          />
        )}
        <NavMenuButtonPortal>
          <BackButton />
        </NavMenuButtonPortal>
        {redirectChannelName ? (
          <div className="mt-8 py-8 px-4 text-center">
            <div className="text-xl mb-12">
              This looks like a message that would be better handled by {redirectTeam}.
            </div>
            <Loader show={true} hideMessage={true} stopAnimation={showContinue} />
            <div className="mt-12 mb-6">
              Please wait a moment while we make sure your message gets to the right member of the
              Alpha Care Team.
            </div>
            {showContinue && <Button onClick={redirectMessage}>Continue</Button>}
          </div>
        ) : (
          <>
            <Loader show={loading || fetchingRecurlySubscription}>
              {config.featureFlags.displayHighVolumeWarning && isSupportChannel && (
                <div
                  className={cn(
                    "text-sky-dark bg-white w-full text-center sticky left-0 right-0 top-0 grid items-center justify-center py-4",
                  )}
                >
                  <div className="w-full bg-forest text-white p-4 mb-8 text-sm text-center">
                    We are experiencing a higher than normal volume of messages. Response times may
                    be delayed.
                  </div>
                </div>
              )}
              {hasUnloadedMessages && (
                <div
                  onClick={() => {
                    loadMoreMessages();
                  }}
                  className="flex justify-center my-5"
                >
                  <div className="border-b border-sky-dark text-sky-dark font-bold text-base">
                    Load More
                  </div>
                </div>
              )}
              {messages
                ?.filter((message) => !shouldHideMessage(message))
                .map((message, idx) => {
                  const hideUserAvatar =
                    messages && idx === 0
                      ? false
                      : messages[idx - 1].message.authorId === message.message.authorId;
                  const lastMessage = messages.length - 1 === idx;
                  return (
                    <div
                      key={message.timetoken}
                      className={cn({ "flex justify-end": message.message.authorId === user.id })}
                    >
                      <Message
                        message={message}
                        currentUser={message.message.authorId === user.id}
                        hideUserAvatar={hideUserAvatar}
                        DBChannels={channels}
                        truncateMessageOnRender={!lastMessage}
                        setBioModalContent={setBioModalContent}
                        setBioModalIsOpen={setBioModalIsOpen}
                      />
                    </div>
                  );
                })}
            </Loader>
            <div className="text-transparent" style={{ height: "1px", lineHeight: "1px" }}>
              Anchor
            </div>
            {!readOnly && selectedChannel && (
              <div className="bg-white sticky left-0 right-0 bottom-0 p-2 mt-6">
                <Form
                  onSubmit={onSubmit}
                  initialValues={initialValues}
                  render={({ submitting, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <p className="text-red text-xs mb-1">{fieldError}</p>
                      <div className="flex sm:px-4">
                        {!isHealthCheckinChannel && (
                          <Field
                            name="subject"
                            component={selectField}
                            placeholder="Subject"
                            options={subjectOptions}
                            styles={customStyles}
                            isSearchable={false}
                            className="flex-grow max-w-xs text-sm"
                            aria-label="Choose a subject"
                          />
                        )}
                        {showServiceDropdown && (
                          <Field
                            name="service"
                            component={selectField}
                            placeholder="Service"
                            options={serviceOptions}
                            styles={customStyles}
                            isSearchable={false}
                            className="ml-4 flex-grow max-w-xs text-sm"
                            aria-label="Choose a service"
                          />
                        )}
                      </div>
                      {attachments.length > 0 && (
                        <UploadedAttachments
                          attachments={attachments}
                          setAttachments={setAttachments}
                        />
                      )}
                      <MessageInput
                        onDrop={onDrop}
                        disableOption={
                          submitting || (text.length === 0 && attachments.length === 0)
                        }
                        text={text}
                        setText={setText}
                      />
                      <OnChange name="subject">
                        {(option: Option) => {
                          const tag = subjectList.find(
                            (tag: Tag) => tag.slug === (option as Option).value,
                          );
                          openAndSetPopupContent(tag);
                        }}
                      </OnChange>
                    </form>
                  )}
                />
              </div>
            )}
            {isLegacyChannel && (
              <div className="text-sm sm:text-base text-forest-60 leading-normal pt-2 pb-4 text-center bg-white sm:px-4">
                <p>
                  We released a new version of our messaging service so this is an archive of your
                  past messages.
                </p>
                <p>
                  To send a new message click <Link to="/messages/new">click here</Link>.
                </p>
              </div>
            )}
            {selectedChannel?.service?.condition && (visitExpired || consultExpired) && (
              <div className="sticky left-0 right-0 bottom-0 text-sm sm:text-base text-forest-60 leading-normal pt-2 pb-4 text-center bg-white sm:px-4">
                <p className="mb-2">
                  {isUbacareChannel ? (
                    "This message thread is currently locked. For additional refills or questions about your medication, please contact the in-person provider who prescribes your medication."
                  ) : (
                    <>
                      <span>
                        This message thread is currently locked. To continue your care for $
                        {selectedChannel.service.condition.name}, please submit your
                      </span>
                      <Link
                        className="text-cornflower-100"
                        to={newConsultLink(selectedChannel.service.condition.key)}
                      >
                        return visit
                      </Link>
                    </>
                  )}
                </p>
              </div>
            )}
            {healthCheckinLocked && (
              <div className="sticky left-0 right-0 bottom-0 text-sm sm:text-base text-forest-60 leading-normal pt-2 pb-4 text-center bg-white sm:px-4">
                <p>
                  Messages from our Alpha Care Team regarding your health check-in will appear in
                  this message thread.
                </p>
              </div>
            )}
            {lockedForGenericReason && selectedChannel && selectedChannel?.service?.condition && (
              <div className="sticky left-0 right-0 bottom-0 text-sm sm:text-base text-forest-60 leading-normal pt-2 pb-4 text-center bg-white sm:px-4">
                <>
                  <p>
                    This message thread is currently locked. To continue your care for{" "}
                    {selectedChannel.service.condition.name}, please submit a new visit.
                  </p>
                  {newConsultLink(selectedChannel.service.condition?.key) && (
                    <Button
                      extraClassNames="mt-6"
                      onClick={() =>
                        history.push(newConsultLink(selectedChannel.service?.condition?.key))
                      }
                    >
                      Start Return Visit
                    </Button>
                  )}
                </>
              </div>
            )}
            <div ref={lastElementRef} />
          </>
        )}
      </div>
      {bioModalContent && (
        <CardPopup
          isOpen={bioModalIsOpen}
          onRequestClose={handleCloseBioModal}
          content={bioModalContent}
        />
      )}
    </>
  );
};

export default ChatChannel;
