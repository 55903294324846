/** @format */
import type { Action, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";

import type {
  Address,
  AddressesCreateAddressRequestBodySchema,
  AddressesSaveAddressRequestBodySchema,
} from "src/api";

import { UsersService } from "src/api";

import type { CreateModel } from "./_create";

export interface AddressModel {
  addresses: Address[];
  Addresses?: boolean;
  AddressPost?: boolean;
  AddressUpdate?: boolean;

  setAddresses: Action<AddressModel, Address[]>;

  getAddresses: Thunk<AddressModel>;

  setAddressPost: Action<AddressModel, boolean>;

  setAddressesBoolean: Action<AddressModel, boolean>;

  setAddressUpdate: Action<AddressModel, boolean>;

  fetchAddresses: Thunk<AddressModel, { type?: string; id?: number } | undefined>;

  postAddress: Thunk<
    AddressModel,
    { address: AddressesCreateAddressRequestBodySchema; validate: boolean }
  >;

  updateAddress: Thunk<
    AddressModel,
    { address: AddressesSaveAddressRequestBodySchema; validate: boolean }
  >;
}

export const addressStore: AddressModel = {
  addresses: [],

  setAddresses: action((state, addresses) => {
    state.addresses = [...addresses];

    state.Addresses = false;
  }),

  getAddresses: thunk(async (actions, __, { getState }) => {
    // let state = getState();
  }),

  setAddressPost: action((state, boolean) => {
    state.AddressPost = boolean;
  }),

  setAddressesBoolean: action((state, boolean) => {
    state.Addresses = boolean;
  }),

  setAddressUpdate: action((state, boolean) => {
    state.Addresses = boolean;
  }),

  fetchAddresses: thunk(async (actions, payload = {}, { getState, getStoreState, dispatch }) => {
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;
    const { id, type } = payload;
    const params: Parameters<typeof UsersService.getAddresses>[0] = {
      userId: currentProfileId as number,
    };
    if (id) {
      params["id"] = id.toString();
    } else if (type) {
      // no need to seach by id and address type
      params["type"] = type;
    }

    return UsersService.getAddresses(params).then((res) => {
      actions.setAddresses(res as Address[]);
      return res;
    });
  }),

  postAddress: thunk(async (actions, { address, validate }, { getState, getStoreState }) => {
    const state = getState();
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;
    if (state.AddressPost) {
      console.warn("Already posting a new address...please wait");
      return;
    }
    actions.setAddressPost(true);

    return UsersService.createAddress({
      userId: currentProfileId as number,
      requestBody: {
        ...address,
      },
      validateAddress: validate ? "true" : "false",
    })
      .then((res) => {
        actions.fetchAddresses({ type: address.type });
        return res;
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        actions.setAddressPost(false);
      });
  }),

  updateAddress: thunk(async (actions, { address, validate }, { getState, getStoreState }) => {
    const state = getState();
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;
    if (state.AddressUpdate) {
      console.warn("Already updating a new address..please wait");
      return;
    }
    actions.setAddressUpdate(true);

    const payload = {
      id: address.id,
      name: address.name,
      address1: address.address1,
      address2: address.address2,
      city: address.city,
      state: address.state,
      zipcode: address.zipcode,
      phone: address.phone,
    };

    if (currentProfileId && address.id) {
      return UsersService.saveAddress({
        userId: currentProfileId,
        addressId: address.id,
        requestBody: {
          ...payload,
        },
        validateAddress: validate ? "true" : "false",
      })
        .then((res) => {
          actions.fetchAddresses({ type: res.type });
          return res;
        })
        .catch((err) => {
          return err;
        });
    }
    return {};
  }),
};
