/** @format */

import React from "react";

import { Typography } from "@alphamedical/components";

import BmiForm from "src/v2/components/BmiCalculator/BmiForm";
import DisplayBmi from "src/v2/components/BmiCalculator/DisplayBmi";

export interface BmiCalculatorProps {
  heading: string;
  subHeader: string;
  text: string;
  backgroundColor: any;
  foregroundColor: any;
  submitButtonText: string;
  secondaryBackgroundColor?: string;
}

const BmiCalculator = ({
  heading,
  subHeader,
  text,
  backgroundColor, //background color
  foregroundColor, //button bg color
  submitButtonText,
  secondaryBackgroundColor, // form background color
}: BmiCalculatorProps) => {
  const [data, setData] = React.useState<BmiData>();
  return (
    <div className={`flex justify-center bg-${backgroundColor} min-h-screen`}>
      <section
        className={`flex mx-auto flex-col mt-12 p-8 text-center`}
        data-component="TopTreatments"
      >
        <div className="max-w-2xl">
          {heading && (
            <Typography className="font-bold" variant="h1">
              {heading}
            </Typography>
          )}
          <div className="flex justify-center mt-12 mb-4">
            <div className="w-12">
              <img src="/assets/weight-loss.svg" alt="Icon of a body weight scale" />
            </div>
          </div>
          {subHeader && (
            <Typography variant="h3" className="mb-4 font-bold">
              {subHeader}
            </Typography>
          )}
          {text && (
            <span className="text-center text-base" dangerouslySetInnerHTML={{ __html: text }} />
          )}

          {data && data.bmi ? (
            <DisplayBmi height={data.height} bmi={data.bmi} />
          ) : (
            <BmiForm
              backgroundColor={secondaryBackgroundColor}
              foregroundColor={foregroundColor}
              submitButtonText={submitButtonText}
              setData={setData}
            />
          )}
          {data && data.bmi && (
            <p
              onClick={() => setData(undefined)}
              className="text-center mt-3 text-base cursor-pointer w-full font-bold text-forest-80"
            >
              Calculate Your BMI Again
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export interface BmiData {
  bmi: number;
  height: number;
  weight: number;
}

export default BmiCalculator;
