/** @format */

import React from "react";

import type { BadgePdbProps } from "src/v2/designSystem";

import { BadgePdb } from "src/v2/designSystem";

interface VisitStatusPillProps {
  status: string;
  treated: boolean;
  hasBeenUpdatedWithin72Hours?: boolean;
}

const getProperties = (
  status: string,
  treated: boolean,
  hasBeenUpdatedWithin72Hours: boolean | undefined,
) => {
  const BadgeProps: BadgePdbProps = {
    textTransform: "normal-case",
  };
  if (["STARTED"].indexOf(status) > -1) {
    BadgeProps.variant = "light";
    BadgeProps.label = "Not submitted";
  } else if (["COMPLETED"].indexOf(status) > -1) {
    BadgeProps.variant = "brandLight";
    BadgeProps.label = "Submitted";
  } else if (
    ["APPROVED" || "DENIED"].indexOf(status) > -1 &&
    !treated &&
    hasBeenUpdatedWithin72Hours
  ) {
    BadgeProps.variant = "brandDark";
    BadgeProps.label = "Provider review";
  } else if (["APPROVED"].indexOf(status) > -1 && (treated || !hasBeenUpdatedWithin72Hours)) {
    BadgeProps.variant = "success";
    BadgeProps.label = "Completed";
  } else if (["UPCOMING"].indexOf(status) > -1) {
    BadgeProps.variant = "warning";
    BadgeProps.label = "Upcoming";
  } else if (["DUE"].indexOf(status) > -1) {
    BadgeProps.variant = "error";
    BadgeProps.label = "Due";
  }
  return BadgeProps;
};

const VisitStatusPill = (props: VisitStatusPillProps) => {
  return (
    <BadgePdb {...getProperties(props.status, props.treated, props.hasBeenUpdatedWithin72Hours)} />
  );
};

export default VisitStatusPill;
