/** @format */
import React from "react";

import { Typography } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

interface InfoPageProps {
  /**
   * Optional. The title of the page.
   */
  title: string;

  /**
   * Optional icon
   */
  icon?: string;

  /**
   * The content of the card. It can be just a string or another component.
   */
  content?: string | React.ReactNode;

  /**
   * Button text
   */
  ctaText?: string | React.ReactNode;

  /**
   * Action on button press
   */
  ctaCallback?: () => void;
}

const InfoPage = ({ title, icon, content, ctaText, ctaCallback }: InfoPageProps): JSX.Element => {
  return (
    <div>
      {title && (
        <Typography className="text-forest-120 mb-6" variant="h2">
          {title}
        </Typography>
      )}
      {icon && <img alt={title} height="64px" src={icon} className="mb-10" />}
      {content && React.isValidElement(content) ? (
        content
      ) : (
        <Typography className="text-forest-120 mb-6" variant="body1">
          {content}
        </Typography>
      )}
      {ctaText && ctaCallback && (
        <ButtonPdb className="w-full" onClick={ctaCallback} variant="primary">
          {ctaText}
        </ButtonPdb>
      )}
    </div>
  );
};

export default InfoPage;
