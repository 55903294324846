/** @format */
import React from "react";
import { useHistory } from "react-router-dom";

import { map, toPairs } from "lodash";

import { Typography } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import { TeamCard } from "src/components/Cards/TeamCard";
import { threadIconMap } from "src/components/Chat/ChannelList/constants";
import { PageWrapper } from "src/components/PageWrapper";
import { useGetChannels } from "src/utils/hooks";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";

import { YourMessages } from "./YourMessages";

const teamMessagesData = {
  clinical: {
    team: "Alpha Clinical Team",
    iconSrc: threadIconMap.SERVICE,
    description: "For medical questions about your symptoms, condition, and treatment plan.",
  },
  support: {
    team: "Support Team",
    iconSrc: threadIconMap.SUPPORT,
    description:
      "For non-medical questions about billing, insurance, prescription refills, and more.",
    bgClassName: "bg-cloud-light-20",
  },
};

export const Inbox = () => {
  const history = useHistory();
  const { channels = [] } = useGetChannels();
  const supportChannel = channels.find((value) => value.channel_type === "SUPPORT");
  useTopBar({ variant: "main", title: "Inbox" });
  const cannotSendMessages =
    channels.length === 0 ||
    (channels.length === 1 && channels[0].channel_type === "HEALTH_CHECKIN");
  return (
    <PageWrapper cxPadding="px-6 pt-8" cxMaxWidth="w-screen sm:max-w-xl">
      <Typography variant="h1" className="mb-8">
        Inbox
      </Typography>
      {channels.length > 0 && (
        <>
          <YourMessages></YourMessages>
          {/* only show these sections if they have at least one completed visit */}
          {!cannotSendMessages && (
            <>
              <Typography variant="h3" className="mb-6">
                Who would you like to message?
              </Typography>
              {map(toPairs(teamMessagesData), ([teamKey, team]) => (
                <div
                  key={teamKey}
                  role="button"
                  onClick={() =>
                    teamKey === "support"
                      ? supportChannel && history.push(`/messages/${supportChannel.name}`)
                      : history.push(`/messages/team/${teamKey}`)
                  }
                >
                  <TeamCard {...team} />
                </div>
              ))}
            </>
          )}
        </>
      )}
      {cannotSendMessages && (
        <>
          <Typography variant="body1" className="mb-8">
            You’ll be able to send messages to the Alpha Care Team after you’ve completed your first
            visit.
          </Typography>
          <ButtonPdb className="w-full" onClick={() => history.push("/your-care/explore-visits")}>
            Start your first visit
          </ButtonPdb>
        </>
      )}
    </PageWrapper>
  );
};

export default WithTopBarNavigation(Inbox);
