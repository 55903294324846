/** @format */

import type { FormRenderProps } from "react-final-form";

import React from "react";

import type { DynamicField as IDynamicField } from "src/components/DynamicForm/types";

import { DynamicField } from "src/components/DynamicForm/Fields";
import { Loader } from "src/components/Loader";
import { _POST, getTokenHeaderV2 } from "src/helpers/http";
import { useGetOnMount } from "src/utils/hooks";
import { Button } from "src/v2/components/Button";
import { useStoreState } from "src/v2/models";
import { ConsultContext } from "src/v2/routes/Consultation";

interface ConsentFormProps {
  condition: string;
  fields: IDynamicField[];
  formProps: FormRenderProps;
  nextClick: () => any;
  consentKey: string;
}

export const ConsentFormV2 = (props: ConsentFormProps) => {
  const { currentProfileId } = useStoreState((state) => state.profile);

  const url = `/v2/consents/${props.condition}?version=${props.consentKey}`;
  const [consentHtml, loadingConsent] = useGetOnMount<string>(url, "");
  const [signingConsent, setSigningConsent] = React.useState<boolean>(false);
  const consult = React.useContext(ConsultContext);

  const signConsent = async (consultId: number) => {
    setSigningConsent(true);
    const signEndpoint = `/v2/users/${currentProfileId}/consults/${consultId}/sign_consent`;
    const signed = await _POST(signEndpoint, { version: props.consentKey }, getTokenHeaderV2())
      .then(() => Promise.resolve(true))
      .catch(() => Promise.resolve(false))
      .finally(() => setSigningConsent(true));

    if (signed) {
      props.nextClick();
    }
  };

  const { initialValues, values } = props.formProps;
  props.fields.forEach((field) => {
    if (
      initialValues[field.key] === undefined &&
      values[field.key] === undefined &&
      field.defaultValue
    ) {
      props.formProps.form.change(field.key, field.defaultValue);
    }
  });

  return (
    <Loader show={loadingConsent}>
      <div
        className="h-full overflow-auto mb-4"
        dangerouslySetInnerHTML={{ __html: consentHtml }}
      />
      <div className="display-none">
        {props.fields.map((field) => (
          <DynamicField key={field.key} field={field} />
        ))}
      </div>
      <Button
        onClick={() => (consult ? signConsent(consult.id) : props.nextClick())}
        disabled={signingConsent}
      >
        I Agree
      </Button>
    </Loader>
  );
};
