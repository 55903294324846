/** @format */

export interface ValidatePasswordIO {
  password?: string;
  passwordConfirm?: string;
}

export const MINIMUM_LENGTH = 12;
const MINIMUM_STRENGTH = 3;

export const PASSWORD_LENGTH_MSG = `Password (at least ${MINIMUM_LENGTH} characters)`;
export const PASSWORD_MISMATCH_MSG = "Mismatched passwords";
export const PASSWORD_INSECURE_MSG =
  "Password too similar to commonly used pattern. Try adding a word or two.";

export const INSECURE_PASSWORD_ERROR_TYPE = "Insecure password";

const getPasswordStrength = async (password: string): Promise<number> => {
  const { zxcvbn, zxcvbnOptions } = await import("@zxcvbn-ts/core");
  const zxcvbnEnglish = await import("@zxcvbn-ts/language-en");
  const zxcvbnCommon = await import("@zxcvbn-ts/language-common");

  const options = {
    translations: zxcvbnEnglish.translations,
    graphs: zxcvbnCommon.adjacencyGraphs,
    dictionary: {
      ...zxcvbnCommon.dictionary,
      ...zxcvbnEnglish.dictionary,
    },
  };

  zxcvbnOptions.setOptions(options);
  const results = zxcvbn(password);

  return results.score;
};

/**
 * Central location for password validation
 * Doesn't check for a missing password (or string length zero). The parent must handle that.
 * @param password {String}
 * @returns {ValidatePasswordIO}
 */
export const validatePassword = async (password: string) => {
  const errors: ValidatePasswordIO = {};

  if (password.length < MINIMUM_LENGTH) {
    return { password: PASSWORD_LENGTH_MSG };
  }
  const strength = await getPasswordStrength(password);

  if (strength < MINIMUM_STRENGTH) {
    errors.password = PASSWORD_INSECURE_MSG;
  }

  // if there are no errors, the result is valid
  return errors;
};

export const validatePasswordForm = async (values: ValidatePasswordIO) => {
  const { password, passwordConfirm } = values;
  let errors: ValidatePasswordIO = {};

  if (!password) {
    errors.password = "Password Required";
  } else {
    errors = await validatePassword(password);
  }

  // if the password is valid, check the PasswordConfirm field
  if (Object.keys(errors).length === 0) {
    if (passwordConfirm) {
      if (password !== passwordConfirm) {
        errors.passwordConfirm = PASSWORD_MISMATCH_MSG;
      }
    } else {
      errors.passwordConfirm = "Confirm Password Required";
    }
  }

  return errors;
};
