/** @format */

import { isPast, startOfDay } from "date-fns";
import moment from "moment";

import type { ReturnConsultTaskSchema } from "src/api";
import type { Service } from "src/v2/models/service";
import type { Consultation } from "src/v2/types/consultation";

export interface ConsultationWithUrgent extends Consultation {
  is_urgent?: boolean;
}

export const getConsultsFromServices = (services: Service[]): ConsultationWithUrgent[] => {
  const consults: ConsultationWithUrgent[] = [];
  services.forEach((service) => {
    if (service.consults) {
      service.consults.forEach((consult) => {
        if (!consult.consult_condition) {
          if (service.condition) {
            consult.consult_condition = service.condition;
          }
        }
      });
    }
    if (service.consults && service.consults.length > 0) {
      consults.push(
        ...service.consults
          // UBACARE sync visits are a unique case which are used by providers only
          .filter((consult) => !(consult.source == "UBACARE" && consult.is_synchronous))
          .map((consult) => ({
            ...consult,
            is_urgent: service.condition?.is_urgent || false,
          })),
      );
    }
  });
  return consults;
};

export const getLatestConsultForCondition = (
  conditionKey: string | undefined,
  consults: Consultation[],
): Consultation | undefined => {
  // Get all consults with the same service type as the scheduled task
  // Filter for consults that are completed and are return consults
  const completedConsults = consults.filter(
    (consult) =>
      consult.subscription.condition.key === conditionKey &&
      consult.status !== ("STARTED" || "WITHDRAWN") &&
      consult.completed_at !== undefined,
  );
  // Get most recently completed consult
  if (completedConsults && completedConsults.length) {
    return completedConsults.reduce((prev, next) => {
      const prevDate = new Date(prev.completed_at);
      const nextDate = new Date(next.completed_at);
      return prevDate > nextDate ? prev : next;
    });
  }
  // If there are no completed consults for a service, do not return a consult
  return undefined;
};

const _sortReturnVisits = (visits: any[]) => {
  // sort return visits in ascending order
  return visits?.sort((a: { trigger_at: string }, b: { trigger_at: string }) => {
    const date1 = new Date(a.trigger_at).getTime();
    const date2 = new Date(b.trigger_at).getTime();
    return date1 - date2;
  });
};

const _getActiveReturnVisits = (return_visits: ReturnConsultTaskSchema[]) => {
  // Get most recently scheduled visit for each service that is scheduled within the next 8 months
  return return_visits
    .sort((a: { created_at: string }, b: { created_at: string }) => {
      const date1 = new Date(a.created_at).getTime();
      const date2 = new Date(b.created_at).getTime();
      return date2 - date1;
    })
    .filter((visit, index, array) => {
      return (
        moment(new Date(visit.trigger_at)).isBefore(moment().add(8, "months"), "day") &&
        index === array.findIndex((obj) => obj.parameters.args[2] === visit.parameters.args[2])
      );
    });
};

export const getLoadedReturnVisitByService = (
  service: Service,
  returnVisits: ReturnConsultTaskSchema[],
) => {
  // For a given service, return the relevant return visit with additional information attached
  const allConsults: Consultation[] = getConsultsFromServices([service]);
  const latestConsult = getLatestConsultForCondition(service.condition?.key, allConsults);
  const returnVisitForService = returnVisits.find(
    (visit) => visit.parameters.args[2] === latestConsult?.consult_condition.key,
  );
  let visitObj: any;
  if (returnVisitForService) {
    // If a return visit exists, check if it is due or past due
    const returnVisitDue = isPast(startOfDay(new Date(returnVisitForService.trigger_at)));
    // Check if the most recently completed consult for that service
    // was completed after the return visit was initially requested by the provider
    let isReturnVisitComplete =
      latestConsult &&
      new Date(latestConsult.completed_at) > new Date(returnVisitForService.created_at);
    if (returnVisitForService.status === "CANCELLED" || returnVisitForService.status === "FAILED") {
      isReturnVisitComplete = true;
    }
    visitObj = returnVisitForService;
    visitObj.latestConsultStatus = latestConsult?.status;
    visitObj.latestConsultId = latestConsult?.id;
    if (!isReturnVisitComplete) {
      visitObj.returnVisitStatus = returnVisitDue ? "due" : "upcoming";
    }
  }
  return visitObj;
};

export const isReturnVisitDue = (returnVisit: any) =>
  returnVisit && returnVisit.returnVisitStatus === "due";

export const getSortedReturnVisits = (
  services: Service[],
  return_visits: ReturnConsultTaskSchema[],
) => {
  // sort return visits into 'due' and 'upcoming'
  const dueVisits: ReturnConsultTaskSchema[] = [];
  const upcomingVisits: ReturnConsultTaskSchema[] = [];
  const activeReturnVisits = _getActiveReturnVisits(return_visits);

  services.forEach((service) => {
    const returnVisit = getLoadedReturnVisitByService(service, activeReturnVisits);
    isReturnVisitDue(returnVisit) ? dueVisits.push(returnVisit) : upcomingVisits.push(returnVisit);
  });
  return [_sortReturnVisits(dueVisits), _sortReturnVisits(upcomingVisits)];
};
