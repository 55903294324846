/** @format */

export const MembershipManagementContent = {
  /**
   * Plan Cancel
   * - Cancellation Flow covering the confirmation pages and additional states.
   */
  cancelFlow: {
    title: "Cancel Plan",
    confirmationPage: {
      title: "Are you sure you want to cancel your plan?",
      body: ["Switch to Alpha Basic Plan for 2 visits per month."],
      confirmButton: "Confirm",
      notNowButton: "Go Back",
    },
    followUpPage: {
      title: "We canceled your plan.",
      accessMessage:
        "You can access your plan benefits until {{date}} at {{time}}. After that, you can still log in to your account. ",
      nextSteps: {
        title: "Here's what happens next:",
        steps: [
          "A member of your Care Team will reach out to you via secure messaging.",
          "You'll be asked to confirm your cancellation in a message.",
          "When your plan has been cancelled, you'll receive an email confirming that your plan has ended.",
        ],
      },
      info: {
        title: "Mail-order prescriptions:",
        message:
          "Cancelling your plan does not cancel any active prescriptions. If you have active prescriptions through our mail-order partner pharmacy, you will continue to receive your remaining refills. If you want to cancel these refills, please message your care team.",
      },
      cta: "Continue",
    },
    offerPage: {
      title: "Get {{discount}}% off your next month of {{planCode}}.",
      body: ["Continue your care for over 100 eligible medical conditions."],
      cta: "Claim offer",
    },
    reasonPage: {
      title: "Why did you cancel your plan?",
      body: ["Please tell us why you're canceling your plan."],
      confirmButton: "Continue",
    },
    confirmDeletePage: {
      title: "Are you sure you want to cancel your plan?",
      body: [
        "You will no longer have access to your care team, medical records, and prescriptions. You can still access your medical records and prescriptions for 30 days after your plan ends.",
      ],
      cta: "Cancel plan",
    },
    processingPage: {
      title: "We're processing your request to cancel your Alpha plan.",
      body: [
        "Your request to cancel your Alpha plan has been received and is being processed. A member of your Care Team will send you a secure message to confirm your cancellation.",
        "If you didn't mean to cancel your plan, please contact us right away.",
      ],
      info: {
        title: "Reminder",
        message:
          "Cancelling your Alpha plan will not cancel upcoming medication refills. To stop receiving medication, please message your Care Team as soon as possible.",
      },
      cta: "Message Care Team",
    },
    attemptsToCancelWhileChanging: {
      title:
        "Your plan can't be cancelled right now because we're processing your request to change your plan.",
      body: [
        "After we have completed processing your request to change your plan, you will have the opportunity to cancel it.",
      ],
      cta: "Message Care Team",
    },
  },

  /**
   * Change Plan
   * - Upgrade and Downgrade Flow.
   */
  changeFlow: {
    title: "Change your plan",
    selectPlanPage: {
      title: "Are you sure you want to switch your plan to {{planName}}?",
      planOptions: {
        "alpha-membership": {
          name: "alpha-membership",
          value: "Alpha Basic Plan",
          bulletPoints: [
            "2 visits/month",
            "Care for over 100 eligible medical conditions",
            "Medication management",
            "Prior authorization support and guidance",
          ],
          extra:
            "Alpha Basic Plan does not include care for weight loss, mental health, and diabetes.",
        },
        "alpha-membership-plus": {
          name: "alpha-membership-plus",
          value: "Alpha Plus+ Plan",
          bulletPoints: [
            "4 visits/month",
            "Care for over 100 medical conditions, including weight loss, mental health, and diabetes",
            "Medication management",
            "Prior authorization support and guidance",
          ],
          extra: "",
        },
      },
      confirmButton: "Upgrade",
      notNowButton: "Cancel",
    },
    changeSuccessfulPage: {
      title: "We successfully switched your plan.",
      planDescription: {
        "alpha-membership-plus": "You can now access your Alpha Plus+ Plan benefits.",
        "alpha-membership":
          "You can access your Alpha Plus+ Plan benefits until {{renewalDate}}. After that, you can access your Alpha Basic Plan benefits.",
      },
    },
    attemptsToChangeWhileCancelling: {
      title:
        "Your plan can't be changed right now because we're processing your request to cancel your plan.",
      body: [
        "After we have completed processing your request to cancel your plan, you may reactivate it and choose the type of plan that you prefer.",
      ],
      cta: "Message Care Team",
    },
  },
};
