/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContentfulCarouselCardSchema } from '../models/ContentfulCarouselCardSchema';
import type { UnstructuredContentSchema } from '../models/UnstructuredContentSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ContentfulService {

    /**
     * Receives webhooks from Contentful CMS and creates a db record thereof.
     * @returns any No response format defined
     * @throws ApiError
     */
    public static contentful(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/content-hook',
        });
    }

    /**
     * Retrieve carousel cards for the provided user.
     * Parameters:
     * user_id (str): The user for which to fetch card content.
     *
     * Returns:
     * The carousel cards if found, or an error message.
     * @returns ContentfulCarouselCardSchema
     * @throws ApiError
     */
    public static getCarouselCards({
        userId,
    }: {
        /**
         * User for which to fetch card content
         */
        userId: string,
    }): CancelablePromise<Array<ContentfulCarouselCardSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/content/carousel_cards/{user_id}',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * Retrieve unstructured content by the provided key.
     * Parameters:
     * key (str): The content key to search in Contentful.
     *
     * Returns:
     * The unstructured content if found, or an error message.
     * @returns UnstructuredContentSchema
     * @throws ApiError
     */
    public static getUnstructuredContentByKey({
        key,
    }: {
        /**
         * The content key to search in Contentful
         */
        key: string,
    }): CancelablePromise<UnstructuredContentSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/content/{key}',
            path: {
                'key': key,
            },
        });
    }

}
