/** @format */

import type { InvalidType } from "src/v2/utils/page/validators";

import { ShippoService } from "src/api";
import { isAxiosError } from "src/helpers/axios";

export interface AddressValues {
  address: string;
  address2?: string;
  city: string;
  state: string;
  zipcode: string;
}

interface AddressSuggestion {
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
}

export interface AddressInvalid extends InvalidType {
  current: AddressValues;
  suggested?: Partial<AddressSuggestion>;
  suggestion?: boolean;
}

interface AddressValidationResponse {
  meta: { code: number };
  response: {
    status: string;
    message: string;
    suggestion: AddressSuggestion;
  };
}

export const INVALID_TYPE_ADDRESS = "address";

export const isAddressInvalid = async (values: AddressValues): Promise<AddressInvalid | void> => {
  const rejection: AddressInvalid = createRejection(values);

  try {
    const response = await ShippoService.intakeAddressValidation({
      requestBody: mapToShippoRequest(values),
    });

    if (!response.validation_results.is_valid) {
      throw rejection;
    }

    const hasErrors = response.validation_results.messages.some(
      (msg: any) => msg.type !== "address_warning",
    );

    if (hasErrors) {
      throw createSuggestion(rejection, response.suggested_address);
    }

    return createValidResponse(rejection, response.suggested_address);
  } catch (e) {
    return handleValidationError(e, rejection, values);
  }
};

const createRejection = (values: AddressValues): AddressInvalid => ({
  invalidType: INVALID_TYPE_ADDRESS,
  invalid: true,
  current: values,
});

const mapToShippoRequest = (values: AddressValues) => ({
  street1: values.address,
  street2: values.address2 || "",
  city: values.city,
  state: values.state,
  zip: values.zipcode,
});

const createSuggestion = (
  rejection: AddressInvalid,
  suggested: AddressSuggestion,
): AddressInvalid => ({
  ...rejection,
  suggested,
  suggestion: true,
});

const createValidResponse = (
  rejection: AddressInvalid,
  suggested: AddressSuggestion,
): AddressInvalid => ({
  ...rejection,
  suggested,
  suggestion: true,
  invalid: false,
});

const handleValidationError = (e: any, rejection: AddressInvalid, values: AddressValues) => {
  if (isAxiosError<AddressValidationResponse>(e)) {
    if (e.response?.status === 500) {
      return {
        ...rejection,
        invalid: false,
        suggestion: false,
      };
    }
    if (e.response?.status === 403) {
      return handle403Error(e, rejection, values);
    }
  }
  throw e;
};

const handle403Error = (e: any, rejection: AddressInvalid, values: AddressValues) => {
  const suggestion = e.response.data.response.suggestion;

  rejection.suggestion = true;
  rejection.suggested = {
    ...(suggestion.street1 !== values.address && { street1: suggestion.street1 }),
    ...(suggestion.street2 !== values.address2 && { street2: suggestion.street2 }),
    ...(suggestion.city !== values.city && { city: suggestion.city }),
    ...(suggestion.state !== values.state && { state: suggestion.state }),
    ...(suggestion.zip !== values.zipcode && { zip: suggestion.zip }),
  };

  throw rejection;
};
