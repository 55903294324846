/** @format */

import React from "react";

import { DoseList } from "src/components/TreatmentCard/PaymentTreatmentCard/DoseList";

interface PaymentTreatmentCardProps {
  medKey: string;
  doses: {
    med_name: string;
    monthly_cost: string;
    ships_every: string;
    dosage: string;
    id: string;
  }[];
  paymentPreference?: string;
}

export const PaymentTreatmentCard = ({
  medKey,
  doses,
  paymentPreference,
}: PaymentTreatmentCardProps) => {
  const [show, setShow] = React.useState(false);
  return (
    <div onClick={() => setShow(!show)} className={`text-primary w-full my-6 cursor-pointer`}>
      <div className="flex justify-between items-center">
        <h3 className="font-bold">{medKey}</h3>
        <div className={`fas ${show ? "fa-chevron-up" : "fa-chevron-down"} text-forest-60`} />
      </div>
      {show && (
        <div>
          {doses.map((doseObj) => (
            <DoseList key={doseObj.id} dose={doseObj} paymentPreference={paymentPreference} />
          ))}
        </div>
      )}
    </div>
  );
};
