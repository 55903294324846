/** @format */

import classNames from "classnames";

import type { ButtonVariant } from "src/v2/designSystem/ButtonPdb/ButtonPdb";

import { ButtonPdb } from "src/v2/designSystem/ButtonPdb/ButtonPdb";

import type { FunnelPageContent } from "./WeightLossSignUpFunnel";

interface LandingPageVariantProps {
  currentPage: FunnelPageContent;
  onClickCallback: () => void;
  styles?: { [key: string]: string };
}
export const LandingPageVariant = ({
  currentPage,
  onClickCallback,
  styles = {},
}: LandingPageVariantProps) => {
  return (
    <div className="sm:max-w-2xl mx-auto w-full px-6 py-10">
      <div>
        <img src="/assets/alpha-logo.svg" alt="Alpha Logo" className="w-24 object-top mx-auto" />
      </div>
      {currentPage.image && (
        <div className="py-6">
          <img
            src={currentPage.image}
            alt={currentPage.imageAlt}
            className="w-60 h-60 rounded-full object-cover mx-auto"
          />
        </div>
      )}
      {currentPage.title && (
        <div className="pb-4">
          <p className="text-4xl font-medium text-center">{currentPage.title}</p>
        </div>
      )}
      {currentPage.subtitle && (
        <div className="pb-6">
          <p className="text-lg font-medium text-center">
            {currentPage.subtitle.map((part: string, index: number) => (
              <span
                key={index}
                className={classNames({
                  "text-information": index === 1,
                  "text-forest-darker": index !== 1,
                })}
              >
                {part}
              </span>
            ))}
          </p>
        </div>
      )}
      <ButtonPdb
        variant={styles.buttonVariant as ButtonVariant}
        className="w-full mt-4 mb-6"
        onClick={(e) => {
          e.preventDefault();
          onClickCallback();
        }}
      >
        {currentPage.primaryCta}
      </ButtonPdb>
    </div>
  );
};
