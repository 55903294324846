/** @format */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Tooltip } from "@alphamedical/components";

const VisitLockedTooltip = () => {
  return (
    <Tooltip
      options={{
        theme: "translucent",
        placement: "bottom-end",
      }}
      content={"This feature is only available to Alpha Plus+ Plan subscribers"}
    >
      <FontAwesomeIcon color={"#656E6D"} icon={["far", "lock"]} />
    </Tooltip>
  );
};

export default VisitLockedTooltip;
