/** @format */

import type { Action, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";

import { _GET, getTokenHeaderV2 } from "src/helpers/http";

export interface Tag {
  enabled: boolean;
  id: number;
  internal_use: boolean;
  label: string;
  slug: string;
  team: string;
  type: string;
  parent_tag_id: string;
  popup_active?: boolean;
  popup_content?: string;
  popup_title?: string;
}

export interface TagsModel {
  tagsList: Tag[];
  fetchingTags: boolean;

  setFetchingTags: Action<TagsModel, boolean>;

  setRecords: Action<TagsModel, Tag[]>;

  fetchTags: Thunk<TagsModel>;

  tagGroupsList: Tag[];

  setFetchingTagGroups: Action<TagsModel, boolean>;

  setGroupRecords: Action<TagsModel, Tag[]>;

  fetchingTagGroups: boolean;

  fetchMessageCategoryGroups: Thunk<TagsModel>;
}

export const tagStore: TagsModel = {
  tagsList: [],
  fetchingTags: false,

  setFetchingTags: action((state, fetching) => {
    state.fetchingTags = fetching;
  }),

  setRecords: action((state, tagList) => {
    state.tagsList = tagList;
    state.fetchingTags = false;
  }),

  fetchTags: thunk(async (actions, __, { getState }) => {
    const state = getState();

    if (!state.fetchingTags) {
      actions.setFetchingTags(true);
      const tagList = await _GET(
        `/tags/message_category?internal_use=False`,
        {},
        getTokenHeaderV2(),
      );
      actions.setRecords(tagList);
    }
  }),

  tagGroupsList: [],
  fetchingTagGroups: false,

  setFetchingTagGroups: action((state, fetching) => {
    state.fetchingTagGroups = fetching;
  }),

  setGroupRecords: action((state, tagList) => {
    state.tagGroupsList = tagList;
    state.fetchingTagGroups = false;
  }),

  fetchMessageCategoryGroups: thunk(async (actions, __, { getState }) => {
    const state = getState();

    if (!state.fetchingTagGroups) {
      actions.setFetchingTagGroups(true);
      const tagList = await _GET(
        `/tags/message_category_group?internal_use=False`,
        {},
        getTokenHeaderV2(),
      );
      actions.setGroupRecords(tagList);
    }
  }),
};
