/** @format */

import type React from "react";

import cn from "classnames";

import { RadioButtonField } from "@alphamedical/components";

import { DynamicFileFieldComponent } from "src/components/DynamicForm/Fields/DynamicFileField";

import type { MedicalRecordsRequestFormPageProps } from "./MedicalRecordsRequestForm";

const Identity: React.FC<MedicalRecordsRequestFormPageProps> = ({ show }) => (
  <div className={cn({ block: show, hidden: !show }, "mb-3")}>
    <div className="mt-6 text-xl font-medium">This form was filled out by the:</div>
    <RadioButtonField
      name="completed_by"
      options={[
        { label: <div className="pb-6">Patient</div>, value: "patient" },
        {
          label: <div className="pb-3">Parent or guardian (if patient is a minor)</div>,
          value: "representative",
        },
      ]}
      className="flex flex-col mt-8"
    />
    <DynamicFileFieldComponent
      key="id_photo"
      showLabelForImage={true}
      field={{
        key: "id_photo",
        accepts: "image/*",
        category: "pii",
        message: "Upload a picture of your ID",
        type: "file",
      }}
    />
  </div>
);

export default Identity;
