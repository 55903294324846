/** @format */

import type React from "react";

import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface MembershipOptionCardItemProps {
  iconName: string;
  iconAlt: string;
}

export const MembershipOptionCardItem: React.FC<MembershipOptionCardItemProps> = ({
  iconName,
  iconAlt,
  children,
}) => {
  return (
    <span className={"flex flex-row items-center mt-4"}>
      <FontAwesomeIcon icon={iconName as IconProp} size="lg" className="mr-4" title={iconAlt} />
      {children}
    </span>
  );
};
