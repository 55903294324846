/** @format */

import React from "react";
import { useHistory } from "react-router-dom";

import { generateUUID } from "pubnub";

import { Typography } from "@alphamedical/components";
import { BadgePdb } from "src/v2/designSystem";

import type { Content } from "src/api";

import { Analytics } from "src/analytics";
import { HealthActionPlanEmptyStateSection } from "src/components/HealthActionPlan/HealthActionPlanPage";
import HealthActionPlanUnreadIndicator from "src/components/HealthActionPlan/HealthActionPlanUnreadIndicator";
import { serializeDependency } from "src/utils";
import { ProgressBar } from "src/v2/components";
import CarrotSvg from "src/v2/components/Svgs/CarrotSvg";
import { useStoreDispatch } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";

interface RecommendedVisitsProps {
  content: Content | undefined;
  showProgressBar?: boolean;
}

export const RecommendedVisits = ({ content, showProgressBar = false }: RecommendedVisitsProps) => {
  const [totalRecommendedVisits, setTotalRecommendedVisits] = React.useState(1);
  const [completedVisitCount, setCompletedVisitCount] = React.useState(0);
  const [totalShow, setTotalShow] = React.useState<number>(0);
  const history = useHistory();
  const dispatch = useStoreDispatch();
  const currentUser = useCurrentUser();

  const handleExpand = () => {
    if (content?.subSection) {
      if (totalShow <= 3) {
        setTotalShow(content.subSection.length);
      } else {
        setTotalShow(content.subSection.length <= 3 ? content.subSection.length : 3);
      }
    }
  };

  React.useEffect(() => {
    let completed = 0;
    if (content?.subSection) {
      content.subSection.forEach((section) => {
        if (
          section.visitMetaData?.visitStatus &&
          ["COMPLETED", "NEEDS_FOLLOWUP", "APPROVED", "DENIED", "READY_FOR_REVIEW"].includes(
            section.visitMetaData?.visitStatus,
          )
        ) {
          completed += 1;
        }
      });
      setTotalShow(content.subSection.length <= 3 ? content.subSection.length : 3);
    }
    if (content?.subSection && content.subSection.length !== totalRecommendedVisits) {
      setTotalRecommendedVisits(content.subSection.length);
    }
    setCompletedVisitCount(completed);
  }, [serializeDependency(content)]);

  // different style badges for different status
  const badgeStyles = {
    STARTED: {
      label: "In progress",
      variant: "light",
    },
    COMPLETED: {
      label: "Completed",
      variant: "success",
    },
    APPROVED: {
      label: "Completed",
      variant: "success",
    },
  };

  return (
    <div className={"mb-4 p-6 bg-white shadow"}>
      <Typography variant={"h3"}>My Recommended Visits</Typography>
      {showProgressBar && (
        <>
          <ProgressBar percentComplete={(completedVisitCount / totalRecommendedVisits) * 100} />
          <Typography variant={"body2"} className={"text-grey-160 mt-2"}>
            {completedVisitCount}/{totalRecommendedVisits} completed
          </Typography>
        </>
      )}
      {content?.subSection
        ? content.subSection
            .filter((_section, index) => index < totalShow)
            .map((section) => {
              const style = badgeStyles[section?.visitMetaData?.visitStatus || ""];

              return (
                <div
                  key={section.visitKey || generateUUID()}
                  className={
                    "border-b-1 border-grey-40 flex flex-row items-center justify-between cursor-pointer"
                  }
                  onClick={() => {
                    if (!section.first_read_at)
                      dispatch.healthActionPlan.markItemAsRead({
                        userId: currentUser.id,
                        healthActionPlanId: section.node_id,
                      });

                    if (section.visitMetaData && section.visitMetaData.visitLink) {
                      Analytics.trackContentInteraction("click", {
                        name: "VisitRecommendation",
                        piece: section.visitKey || "null",
                        target: section.visitMetaData.visitLink,
                      });
                      history.push(section.visitMetaData.visitLink);
                    }
                  }}
                >
                  <div className={"my-4"}>
                    <Typography>{section.visitMetaData?.visitName}</Typography>
                  </div>
                  <div className={"flex flex-row items-center"}>
                    {style && <BadgePdb {...style} />}
                    {!section.first_read_at && <HealthActionPlanUnreadIndicator />}
                    <div className={"ml-3"}>
                      <CarrotSvg fill={"#656E6D"} />
                    </div>
                  </div>
                </div>
              );
            })
        : HealthActionPlanEmptyStateSection("RECOMMENDED_VISIT")}
      {content?.subSection && content.subSection.length > 3 && (
        <p className={"mt-4 text-cornflower-100 font-medium cursor-pointer"} onClick={handleExpand}>
          {totalShow === 3 ? `+${content.subSection.length - 3} more` : "Show less"}
        </p>
      )}
    </div>
  );
};
