/** @format */

import { Thunk, thunk, Action, action } from "easy-peasy";
import { _GET, getTokenHeaderV2, _POST } from "src/helpers/http";
import { CreateModel } from "./_create";

export interface Option {
  value: string | number;
  label: string;
}
export interface Record {
  assessment_type: string;
  score_type: string;
  title: string;
  scores: any[];
  min_range: number;
  max_range: number;
  severity_info: [
    {
      title: string;
      description: string;
      score_range: string;
    }
  ];
}

export interface RecordModel {
  assessmentScores: Record[];
  consultScores: Record[];

  setConsultScores: Action<RecordModel, Record[]>;

  setAssessmentScores: Action<RecordModel, Record[]>;

  fetchMentalHealthRecords: Thunk<RecordModel>;

  fetchAssessmentScores: Thunk<RecordModel>;

  saveAssessmentScore: Thunk<RecordModel, { assessmentType: string; assessmentScore: number }>;
}

export const recordStore: RecordModel = {
  assessmentScores: [],
  consultScores: [],

  setConsultScores: action((state, records) => {
    state.consultScores = records;
  }),

  setAssessmentScores: action((state, records) => {
    state.assessmentScores = records;
  }),

  fetchMentalHealthRecords: thunk(async (actions, __, { getStoreState }) => {
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;
    return _GET(`/users/${currentProfileId}/records/mental_health`, {}, getTokenHeaderV2()).then(res => {
      actions.setConsultScores(res);
      return res;
    });
  }),

  fetchAssessmentScores: thunk(async (actions, __, { getStoreState }) => {
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;
    return _GET(`/users/${currentProfileId}/records/assessments`, {}, getTokenHeaderV2()).then(res => {
      actions.setAssessmentScores(res);
      return res;
    });
  }),

  saveAssessmentScore: thunk(
    async (_, { assessmentScore, assessmentType }, { getStoreState }): Promise<any> => {
      const storeState = getStoreState() as CreateModel;
      const { currentProfileId } = storeState.profile;
      const url = `/users/${currentProfileId}/records/assessment_score`;
      const payload = { assessment_type: assessmentType, assessment_score: assessmentScore };

      return _POST(url, payload, getTokenHeaderV2()).then(() => {
        localStorage.removeItem("assessment_type");
        localStorage.removeItem("assessment_score");
      });
    }
  )
};
