/** @format */

import type { RouteComponentProps } from "react-router-dom";

import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import _, { some } from "lodash";

import type { LegacyConsultSchema } from "src/api";
import type { RawDynamicOutline } from "src/components/DynamicForm/types";
import type { ConsultsModel } from "src/v2/models/consults";

import { useAnalyticsDimension } from "src/analytics/hooks";
import { MaintenanceBanner } from "src/components";
import { Loader } from "src/components/Loader";
import { GenericBanner } from "src/components/Maintenance/GenericBanner";
import { _GET } from "src/helpers/http";
import { useQueryState } from "src/utils/hooks";
import { SnackBar } from "src/v2/components";
import { AlphaModal } from "src/v2/components/AlphaModal";
import { Button } from "src/v2/components/Button";
import { VisitRouter } from "src/v2/components/Family/VisitRouter";
import { CONSULTS_WITH_CHECKUP } from "src/v2/constants";
import { useStoreDispatch, useStoreState } from "src/v2/models";
import { lastVisitTooLongAgoForReturnConsult } from "src/v2/models/consults";
import { OutlineContext } from "src/v2/models/outline";
import { useCurrentUser } from "src/v2/models/profile";
import { Consultation } from "src/v2/routes/Consultation";

interface QueryParams {
  outline: string;
  pageKey?: string;
}

export interface IntakeProps extends RouteComponentProps<QueryParams> {
  consultType?: string;
  getOutlineKey?: (outline: string) => string;
}

const Intake = (props: IntakeProps) => {
  const patient = useCurrentUser();
  const [couponCode] = useQueryState(props.history, "coupon_code");
  const { params } = props.match;
  const [override] = useQueryState(props.history, "override");
  const outlineKey = (props.getOutlineKey && props.getOutlineKey(params.outline)) || params.outline;
  const consultType = props.consultType || "INITIAL_CONSULT";
  const dispatch = useStoreDispatch();
  const location = useLocation();
  const [returnRedirect, setReturnRedirect] = React.useState<{ to?: string }>({});
  const { profile } = useStoreState((state) => state.profile);
  const { maintenanceData, upcomingMaintenance } = useStoreState((state) => state.maintenance);
  const { currentConsultation, service, consultsByCondition, showUserRedirectMessage } =
    useStoreState((state) => state.consults) as ConsultsModel;
  const outline = useStoreState((state) => state.outlines.outlines[outlineKey]);
  const startAnyway = outline && outline.key && localStorage.getItem(`${outline.key}:start_anyway`);

  React.useEffect(() => {
    // this useEffect is used to set a few storeState values:
    // currentConsult, consultsByCondition, profile, and outline
    dispatch.outlines
      .fetchOutline({ key: outlineKey, override: override })
      .then((rawOutline: RawDynamicOutline) => {
        if (rawOutline) {
          const debounceFetchOrCreate = _.debounce(dispatch.consults.fetchOrCreateConsult, 2000);
          debounceFetchOrCreate({ conditionKey: rawOutline.key, type: consultType });
          dispatch.consults.fetchConsultsByCondition(rawOutline.key);
          dispatch.profile.fetchProfile({
            include: ["pii", "insurance", "consults", "emergency_contact", "phi"],
          });
        }
      });
  }, [outlineKey]);

  if (
    currentConsultation?.consult_type === "INITIAL_CONSULT" &&
    location.pathname.startsWith("/visit")
  ) {
    window.history.replaceState(null, "", location.pathname.replace("/visit", "/consultation"));
  }
  React.useEffect(() => {
    if (!!couponCode && couponCode !== "") {
      const conditionMap: Partial<Record<string, string>> = {
        lashes: "eyelashes",
      };
      localStorage.setItem(
        `${conditionMap[params.outline] || params.outline}.coupon_code`,
        couponCode,
      );
    }
  }, [couponCode, params.outline]);

  React.useEffect(() => {
    outline &&
      outline.key &&
      _GET(`/users/${patient.id}/service/${outline.key}/return-status`).then((response) =>
        setReturnRedirect(response),
      );
  }, [consultType, outline?.key]);
  // QUESTION: why outline.key rather than outlineKey?
  // could this be ascertained from the [outlineKey] useEffect?

  useAnalyticsDimension("ServiceType", service?.type);

  const mostRecentInitialConsultIsApproved = (consultsByCondition: LegacyConsultSchema[]) => {
    const initialConsults = consultsByCondition.filter(
      (consult) => consult.consult_type === "INITIAL_CONSULT",
    );
    const mostRecentInitialConsult = _.maxBy(initialConsults, (consult) => consult.created_at);
    return mostRecentInitialConsult?.status === "APPROVED";
  };

  if (
    outline &&
    currentConsultation &&
    outline.key !== currentConsultation.subscription.condition.key
  ) {
    throw Error("Consult condition mismatch");
  }

  // TODO: put this series of if statements into well-named helper functions

  // if currentConsultation.consult_type is "INITIAL_CONSULT", but consultType is not, redirect to the correct consultType
  if (
    outline &&
    currentConsultation?.consult_type === "INITIAL_CONSULT" &&
    consultType !== "INITIAL_CONSULT"
  ) {
    window.location.href = `/consultation/${outline.key}`;
  }

  // This redirects a patient to the visit details page prior to starting a return consult unless they've already come from te visit details page
  if (
    outline &&
    consultType === "RETURN_CONSULT" &&
    returnRedirect["to"] == "visit_details" &&
    startAnyway !== "start_anyway" &&
    currentConsultation &&
    currentConsultation.status !== "COMPLETED" &&
    currentConsultation.consult_type === "RETURN_CONSULT"
  ) {
    localStorage.setItem(`${outline.key}:start_anyway`, "show_cta");
    window.location.href = `/visit-details/${outline.key}`;
  }

  // check if the user has been sent a annual return notification. If so redirect them to the annual consult
  if (
    service &&
    service.needs_annual &&
    !location.pathname.includes("followup") &&
    service.annual_link
  ) {
    // Do a full hard redirect to clear easy peasey state
    window.location.href = service.annual_link;
  }

  // check if the current consult is a followup consult. If so redirect user to annual link.
  if (
    service &&
    service.annual_link &&
    currentConsultation &&
    !location.pathname.includes("followup") &&
    currentConsultation.consult_type === "FOLLOWUP" &&
    currentConsultation.status !== "APPROVED"
  ) {
    // Do a full hard redirect to clear easy peasey state
    window.location.href = service.annual_link;
  }

  // check if the user has an approved initial consult and if there is a consult with a checkup,
  // redirect them to the checkup
  if (
    outline &&
    currentConsultation &&
    currentConsultation.consult_type === "INITIAL_CONSULT" &&
    mostRecentInitialConsultIsApproved(consultsByCondition) &&
    CONSULTS_WITH_CHECKUP.includes(outlineKey) &&
    !lastVisitTooLongAgoForReturnConsult(consultsByCondition, outlineKey)
  ) {
    // Do a full hard redirect to clear easy peasey state
    window.location.href = `/return-consult/${outlineKey}-checkup/`;
  }

  // TODO: Mental health checkup redirect

  return !!profile && !!currentConsultation && !!outline ? (
    <OutlineContext.Provider value={outline}>
      <AlphaModal
        shouldCloseOnOverlayClick={true}
        className="max-w-md mx-auto text-center shadow-lg bg-white"
        closeIconColor="primary-40"
        isOpen={showUserRedirectMessage}
        cxPadding={"0"}
        closeIconSize={"lg"}
        onRequestClose={() => dispatch.consults.setShowUserRedirectMessage(false)}
      >
        <div
          className="flex justify-between align-left w-full p-5 text-primary font-bold border-b border-grey-4"
          style={{ backgroundColor: "#FFF9F7" }}
        >
          <h1>Visit Updated</h1>
        </div>
        <div className="p-3">
          The questions in this visit have been updated and you will need to restart. We apologize
          for any inconvenience.
          <Button onClick={() => dispatch.consults.setShowUserRedirectMessage(false)}>
            Continue
          </Button>
        </div>
      </AlphaModal>
      {upcomingMaintenance && <MaintenanceBanner data={maintenanceData} />}
      <GenericBanner />
      <Consultation
        match={props.match}
        history={props.history}
        location={props.location}
        consult={currentConsultation}
        profile={profile}
        outline={outline}
      />
    </OutlineContext.Provider>
  ) : (
    <Loader show={true} />
  );
};

export const ConsultContainer = (props: IntakeProps) => {
  return (
    <VisitRouter {...props}>
      <Intake {...props} />
      <SnackBar />
    </VisitRouter>
  );
};

export const VisitContainer = (props: IntakeProps) => {
  return (
    <VisitRouter {...props}>
      <Intake {...{ ...props, consultType: "VISIT" }} />
      <SnackBar />
    </VisitRouter>
  );
};

export const FollowupConsultContainer = (props: IntakeProps) => {
  return (
    <>
      <Intake
        {...{
          ...props,
          consultType: "FOLLOWUP",
          getOutlineKey: (outline) => `followup-${outline}`,
        }}
      />
      <SnackBar />
    </>
  );
};

export const ReturnConsultConsultContainer = (props: IntakeProps) => {
  return (
    <>
      <Intake {...{ ...props, consultType: "RETURN_CONSULT" }} />
      <SnackBar />
    </>
  );
};
