/** @format */

import React from "react";

import { useStoreActions, useStoreState } from "src/v2/models";

interface CouponInputProps {
  defaultValue?: string;
  onSubmit: (e: any, promo: string) => void;
}

const CouponInput = (props: CouponInputProps) => {
  const { setCouponCode } = useStoreActions((actions) => actions.payments);
  const { couponCode } = useStoreState((state) => state.payments);

  return (
    <div className="flex items-end justify-between mt-6">
      <div className="flex items-center">
        <img src="/assets/promo-icon.svg" alt="Enter a promo code" />
        <input
          type="text"
          placeholder="Enter promo code"
          className="border-b-2 border-primary text-sm py-2 text-primary ml-4"
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value)}
        />
      </div>
      <div className="flex justify-end items-end">
        <button
          onClick={() => {
            if (couponCode) {
              props.onSubmit(null, couponCode);
              setCouponCode("");
            }
          }}
          type="button"
          className="btn-outline-primary border-2 text-center font-medium py-1 w-24"
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default CouponInput;
