/** @format */

import type React from "react";

import { Typography } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import type { Benefit, MembershipDetails as MembershipDetailsI } from "./noticePage.types";

import { MembershipOptionCard } from "./MembershipOptionCard";
import { MembershipOptionCardItem } from "./MembershipOptionCardItem";

interface MembershipDetailsProps {
  content: MembershipDetailsI;
  color: string;
  borderColor?: string;
  modalHandler: React.Dispatch<React.SetStateAction<boolean>>;
  onClickHandler: () => void;
  discount?: string;
}

export const MembershipDetails = ({
  content,
  color,
  borderColor,
  modalHandler,
  onClickHandler,
}: MembershipDetailsProps) => {
  const formatBenefit = (benefit: Benefit) => {
    if (typeof benefit.text === "string") {
      return <Typography variant="body2"> {benefit.text}</Typography>;
    }
    if (Array.isArray(benefit.text)) {
      return benefit.text.reduce(
        (accumulator, item) => {
          return (
            <>
              {accumulator}
              {item.isClickable ? (
                <div onClick={() => modalHandler(true)}>
                  <Typography variant="hyperlink" className="cursor-pointer">
                    <p className="text-sm font-normal">{item.text}</p>
                  </Typography>
                </div>
              ) : (
                <Typography variant="body2"> {item.text}</Typography>
              )}
            </>
          );
        },
        <></>,
      );
    }
    return <></>;
  };

  return (
    <MembershipOptionCard
      color={color}
      borderColor={borderColor}
      containerClass="mt-6"
      price={content.price}
    >
      {content.benefits.map((benefit, idx) => {
        return (
          <MembershipOptionCardItem iconName={benefit.icon} iconAlt={benefit.alt} key={idx}>
            <span className="flex flex-col">{formatBenefit(benefit)}</span>
          </MembershipOptionCardItem>
        );
      })}
      <ButtonPdb onClick={() => onClickHandler()} className="w-full mt-5">
        {content.button}
      </ButtonPdb>
    </MembershipOptionCard>
  );
};
