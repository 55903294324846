/** @format */

import * as Sentry from "@sentry/react";
import type { Action, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";
import _ from "lodash";

import type { ReturnConsultTaskSchema, ServiceCategorySchema } from "src/api";
import type { OpenRequest } from "src/v2/models/profile";
import type { Condition } from "src/v2/types/condition";
import type { Consultation } from "src/v2/types/consultation";
import type { Subscription } from "src/v2/types/subscription";

import { ServicesService } from "src/api";
import { isAxiosError } from "src/helpers/axios";
import { _GET, getTokenHeaderV2 } from "src/helpers/http";

import type { CreateModel } from "./_create";
import type { GroupSessionSignupSchema } from "./api_types";

export type IncludeOptions =
  | "consults"
  | "subscriptions"
  | "group_session_signups"
  | "scheduled_followup_task";

interface FetchServiceOptions {
  include?: IncludeOptions[];
  includeAll?: boolean;
  service_type: string;
}

export interface Service {
  id: number;
  consults?: Consultation[];
  subscriptions?: Subscription[];
  condition?: Condition;
  group_session_signups?: GroupSessionSignupSchema[];
  type: string;
  channel_id?: string;
  scheduled_followup_task?: ReturnConsultTaskSchema;
  needs_annual: boolean;
  annual_link?: string;
  name?: string;
}

export interface ServiceModel {
  currentService?: Service;
  requests: OpenRequest[];
  serviceList: Service[];
  fetchingServices: boolean;
  serviceCategories: ServiceCategorySchema[];

  setServiceList: Action<ServiceModel, Service[]>;

  setCurrentService: Action<ServiceModel, Service>;

  removeFromCache: Action<ServiceModel, Promise<any>>;

  setServiceCategories: Action<ServiceModel, ServiceCategorySchema[]>;

  setCachedRequest: Action<ServiceModel, { include: string[]; request: Promise<any> }>;

  fetchAllServices: Thunk<ServiceModel, { include: IncludeOptions[] }>;

  fetchCurrentService: Thunk<ServiceModel, FetchServiceOptions>;

  setFetchingServices: Action<ServiceModel, boolean>;

  setServices: Action<ServiceModel, Service[]>;

  fetchServices: Thunk<ServiceModel>;

  fetchServiceCategories: Thunk<ServiceModel>;
}

export const serviceStore: ServiceModel = {
  requests: [],
  serviceList: [],
  fetchingServices: false,
  serviceCategories: [],

  setServiceCategories: action((state, serviceCategories) => {
    state.serviceCategories = serviceCategories;
  }),

  setServiceList: action((state, services) => {
    state.serviceList = services;
  }),

  setCurrentService: action((state, service) => {
    state.currentService = service;
  }),

  removeFromCache: action((state, request) => {
    _.remove(state.requests, (openRequest) => openRequest.request == request);
  }),

  setCachedRequest: action((state, params) => {
    const { include, request } = params;
    state.requests = [...state.requests, { include, request }];
  }),

  fetchAllServices: thunk(async (actions, payload) => {
    const { include } = payload;
    actions.setFetchingServices(true);
    const request = ServicesService.getServicesForUser({ userId: "me", include: include.join("+") })
      .then((res) => {
        actions.setServiceList(res);
        return res;
      })
      .catch((err) => console.log(err))
      .finally(() => {
        actions.removeFromCache(request);
        actions.setFetchingServices(false);
      });
    actions.setCachedRequest({ include, request });
    return request;
  }),

  fetchCurrentService: thunk(async (actions, options, { getState, getStoreState }) => {
    const include = options.include || (options.includeAll ? ["consults", "subscriptions"] : []);
    const state = getState();
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;
    const existingRequest = state.requests.find((openRequest) => {
      return _.difference(include, openRequest.include).length === 0;
    });
    if (existingRequest) {
      return existingRequest.request;
    } else {
      const request = _GET(
        `/users/${currentProfileId}/services/${options.service_type}?include=${include.join("+")}`,
        {},
        getTokenHeaderV2(),
      )
        .then((res) => {
          actions.setCurrentService(res);
          return res;
        })
        .catch((err) => console.log(err))
        .finally(() => actions.removeFromCache(request));
      actions.setCachedRequest({ include, request });
      return request;
    }
  }),

  setFetchingServices: action((state, fetching) => {
    state.fetchingServices = fetching;
  }),

  setServices: action((state, tagList) => {
    state.serviceList = tagList;
    state.fetchingServices = false;
  }),

  fetchServices: thunk(async (actions, __, { getStoreState, getState }) => {
    const state = getState();
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;
    if (!state.fetchingServices) {
      actions.setFetchingServices(true);
      const _serviceList = await _GET(
        `/users/${currentProfileId}/services`,
        {},
        getTokenHeaderV2(),
      );
      actions.setServices(_serviceList);
    }
  }),

  fetchServiceCategories: thunk(async (actions) => {
    return ServicesService.getServiceCategories()
      .then((res) => {
        actions.setServiceCategories(res);
        return res;
      })
      .catch((err) => {
        if (isAxiosError(err)) {
          Sentry.captureException(err);
        }
        return err;
      });
  }),
};
