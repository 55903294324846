/** @format */

import React from "react";
import { createPortal } from "react-dom";

import NavModalContent from "./NavModalContent";

export const NavMenuButtonPortal: React.FunctionComponent = ({ children }) => {
  /*
    This portal can allow you to place any element in the
    position of the nav menu button, using patented and fancy
    React Portal technology. In any rendered component, it must
    be rendered to the actual DOM to work, can't be a wrapper,
    you can add a child component like this:

    <NavMenuButtonPortal>
    <BackButton />
    </NavMenuButtonPortal>

    This will place the BackButton here, replacing any other
    children that might exist, specifically the default hamburger.
  */

  const mount = document.getElementById("nav-menu-portal");
  const element = document.createElement("div");

  React.useEffect(() => {
    if (mount && mount.hasChildNodes()) {
      // Clear any existing elements, e.g., nav hamburger
      mount.innerHTML = "";
    }
    mount && mount.appendChild(element);
    return () => {
      mount && mount.removeChild(element);
    };
  }, [mount, element, children]);

  return createPortal(children, element);
};

const NavMenuHamburger: React.FunctionComponent = () => {
  return (
    <>
      <div id="nav-menu-hamburger" className="slide-menu-open">
        <button
          aria-haspopup="true"
          aria-label="Navigation menu"
          className="slide-menu-open relative text-primary cursor-pointer p-2 -m-2"
        >
          {/* This is to cover the entire click area, which the font awesome SVG won't */}
          <span className="absolute inset-0"></span>
          <img
            style={{ fontSize: "1.75em", width: "25px" }}
            src={"/assets/nav-stack.svg"}
            alt="Open navigation menu"
            data-modal-toggle={true}
          />
        </button>
      </div>
      <NavModalContent />
    </>
  );
};

const NavMenuButton = () => {
  return (
    <div id="nav-menu-portal" className="absolute left-0 inset-y-0 pl-5 mt-2 flex items-center">
      <NavMenuHamburger />
    </div>
  );
};

export default NavMenuButton;
