/** @format */

import React from "react";
import { useHistory } from "react-router-dom";

import { Typography } from "@alphamedical/components";

import { PageWrapper } from "src/components/PageWrapper";
import { WeightLossJournalHeader } from "src/routes/Records/WeightLossJournal/WeightLossJournalHeader";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";

const WeightLossJournalHome: React.FC = () => {
  const history = useHistory();
  const actions = useTopBar({
    title: "Weight loss journal",
    variant: "nested",
    showBackButton: true,
    showMenuButton: true,
    showNotificationButton: false,
    showSettingsButton: true,
    showHelpButton: false,
  });

  React.useEffect(() => {
    actions.setOnSettingsCallback(() => {
      history.push("/weight-loss-journal/preferences");
    });
  }, []);

  return (
    <PageWrapper cxPadding="px-0 py-0" cxMaxWidth="w-screen sm:max-w-xl">
      <WeightLossJournalHeader />
      <div className="p-6 space-y-4">
        <div>
          <Typography variant="body1" className="mb-4">
            Observe weight trends and track when you take your medications.
          </Typography>
          <Typography variant="caption">
            This information is only shared with your care team if you allow sharing. You can allow
            sharing by clicking the gear icon in the top right corner of the page. Your care team
            will only review your data if you mention it in your visit answers or a message.
          </Typography>
        </div>
        <div className="space-y-4">
          {[
            {
              title: "Weight",
              image: "/assets/weight-loss-journal/weight.svg",
              imageAlt:
                "A vector image of a person looking at a full body mirror while looking at the cell phone",
              route: "weight-loss-journal/measurements/weight",
            },
            {
              title: "Waist circ.",
              image: "/assets/weight-loss-journal/waist.svg",
              imageAlt: "A vector image of a person measuring their waist circumference",
              route: "weight-loss-journal/measurements/waist-circumference",
            },
            {
              title: "Medications",
              image: "/assets/weight-loss-journal/medications.svg",
              imageAlt: "A vector image of an open medication capsule",
              route: "weight-loss-journal/medications",
            },
          ].map(({ image, imageAlt, route, title }) => (
            <div
              role="button"
              key={title}
              style={{ height: 144, backgroundColor: "#E3E8D9" }}
              className="rounded-2xl flex justify-between"
              onClick={() => history.push(route)}
            >
              <Typography variant="h4" className="p-4">
                {title}
              </Typography>
              <div
                className="flex items-center justify-center mr-8"
                // style={{ height: 156, marginTop: -6 }}
              >
                <img className="object-contain" src={image} alt={imageAlt} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(WeightLossJournalHome);
