/** @format */

import type { FieldRenderProps } from "react-final-form";

import React from "react";
import { useForm } from "react-final-form";
import ClipLoader from "react-spinners/ClipLoader";

import * as Sentry from "@sentry/react";
import Cleave from "cleave.js/react";

import { NumberField, SimpleForm, TextField } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import type { AthenaPharmacyAddress } from "src/api";

import { EhrService } from "src/api";
import { isAxiosError } from "src/helpers/axios";
import { FullPageModal, IconTextChevron } from "src/v2/components";
import CarrotSvg from "src/v2/components/Svgs/CarrotSvg";
import { useStoreDispatch } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";
import { COLORS } from "src/v2/utils/colors";

interface PharmacySearchProps {
  open: boolean;
  setOpen: () => void;
}

export const PharmacySearch = ({ open, setOpen }: PharmacySearchProps) => {
  const [pharmacies, setPharmacies] = React.useState<AthenaPharmacyAddress[]>([]);
  const [formError, setFormError] = React.useState<string>("");
  const [loading, setLoading] = React.useState(false);
  const [pharmaciesToShow, setPharmaciesToShow] = React.useState(3);
  const [modalType, setModalType] = React.useState<"search" | "help">("search");
  const user = useCurrentUser();
  const dispatch = useStoreDispatch();
  const form = useForm();
  const { values } = form.getState();

  const inputStyles = `py-3 px-2 text-sm bg-transparent text-grey-160 field-placeholder
  w-full border-2 border-grey-100 focus:outline-none rounded`;

  const searchPharmacies = (values: SearchPharmaciesForm) => {
    setLoading(true);
    setPharmacies([]);
    setPharmaciesToShow(3);
    setFormError("");
    EhrService.searchPharmacies(values)
      .then((res) => {
        setPharmacies(res);
      })
      .catch((err) => {
        Sentry.captureException(err);
        if (isAxiosError(err) && err.response) {
          setFormError(err.response.data.description);
        } else {
          setFormError("Something went wrong.");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const backButtonPress = () => {
    if (modalType === "help") {
      setModalType("search");
    } else {
      setOpen();
    }
  };

  const saveAddress = (address: AthenaPharmacyAddress) => {
    setFormError("");
    EhrService.setDefaultPharmacy({
      userId: user.id,
      requestBody: {
        athena_pharmacy: address,
      },
    })
      .then(() => {
        dispatch.addresses.fetchAddresses({ type: "PHARMACY" });
      })
      .catch((err) => {
        Sentry.captureException(err);
        if (isAxiosError(err) && err.response) {
          setFormError(err.response.data.description);
        } else {
          setFormError("An error occurred while saving your pharmacy.");
        }
      });
  };

  const handleOptionKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    pharmacy: AthenaPharmacyAddress,
  ) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      saveAddress(pharmacy);
    }
  };

  const handleLinkKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      setModalType("help");
    }
  };

  return (
    <FullPageModal isOpen={open} setIsOpen={backButtonPress} title={"Add a pharmacy"} isScrollable>
      <div style={{ display: modalType === "search" ? "block" : "none" }}>
        <SimpleForm<SearchPharmaciesForm>
          hideDebugInfo={false}
          onSubmit={searchPharmacies}
          validate={(values: SearchPharmaciesForm) => {
            const errors: Record<string, string> = {};
            if (!values.zip) {
              errors["zip"] = "ZIP Code is required.";
            }
            if (values.zip) {
              if (values.zip.length < 5) {
                errors["zip"] = "ZIP Code must have at least 5 digits";
              }
            }

            if (!values.name) {
              errors["name"] = "Name of the pharmacy is required";
            }

            if (values.name && values.name.length < 2) {
              errors["name"] = "Name must have at least 2 characters";
            }

            return errors;
          }}
        >
          {({ form }) => {
            return (
              <div className={"px-8 mt-10 h-full"}>
                <h2 className={"text-lg text-forest-120 font-medium"}>Search for a pharmacy</h2>
                <p className={"text-forest-120 mt-2"}>Both a name and ZIP Code are required.</p>
                <p className={"text-primary text-sm mt-10 mb-2"}>Pharmacy name</p>
                <TextField name={"name"} placeholder={"e.g. CVS or Walgreens"} minLength={2}>
                  {({ input, meta }: FieldRenderProps<string>) => {
                    return (
                      <div>
                        <input
                          className={inputStyles}
                          placeholder={"e.g. CVS or Walgreens"}
                          type={"text"}
                          {...input}
                        />
                        {(!meta.error || !meta.touched) && (
                          <p className={"text-sm text-grey-160"}>Partial names accepted</p>
                        )}
                        {meta.error && meta.touched && (
                          <p className={"text-error text-sm"}>{meta.error}</p>
                        )}
                      </div>
                    );
                  }}
                </TextField>
                <p className={"mt-6 text-sm"}>ZIP Code</p>
                <NumberField
                  name={"zip"}
                  placeholder={"e.g. 12345"}
                  initialValue={values["zipcode"]}
                >
                  {({ input, meta }: FieldRenderProps<string>) => {
                    return (
                      <>
                        <Cleave
                          className={inputStyles}
                          placeholder="ZIP Code"
                          value={input.value}
                          options={{
                            numericOnly: true,
                            blocks: [5, 4],
                            delimiter: "-",
                            delimiterLazyShow: true,
                          }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            form.change("zip", e.target.value);
                          }}
                        />
                        <input {...input} className={"hidden"} />
                        {!meta.error ||
                          (!meta.dirty && (
                            <p className={"text-sm text-grey-160"}>Nearby ZIP Codes accepted</p>
                          ))}
                        {meta.error && meta.dirty && (
                          <p className={"text-error text-sm"}>{meta.error}</p>
                        )}
                      </>
                    );
                  }}
                </NumberField>
                <ButtonPdb
                  className={"flex w-full justify-center items-center mt-10"}
                  icon={"/assets/search.svg"}
                >
                  Search
                </ButtonPdb>
                {formError && <p className={"text-error"}>{formError}</p>}
                {loading && (
                  <div className={"flex justify-center my-10"}>
                    <ClipLoader color={COLORS["grey-160"]} loading={loading} />
                  </div>
                )}
                {pharmacies && (
                  <div>
                    {pharmacies.slice(0, pharmaciesToShow).map((pharmacy) => {
                      return (
                        <div
                          onClick={() => saveAddress(pharmacy)}
                          key={pharmacy.clinicalproviderid}
                          className={
                            "py-6 border-b border-grey-60 flex justify-between items-center cursor-pointer"
                          }
                          tabIndex={0}
                          onKeyDown={(e) => handleOptionKeyDown(e, pharmacy)}
                        >
                          <div>
                            <h3 className={"text-forest-120 font-medium"}>{pharmacy.name}</h3>
                            <p className={"text-forest-120 text-sm mt-2"}>{pharmacy.address}</p>
                            <p className={"text-forest-120 text-sm"}>
                              {pharmacy.city}, {pharmacy.state}, {pharmacy.zip}
                            </p>
                            <p className={"text-forest-120 text-sm"}>{pharmacy.phone}</p>
                          </div>
                          <CarrotSvg fill={COLORS["grey-160"]} />
                        </div>
                      );
                    })}
                    {pharmaciesToShow < pharmacies.length && (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          if (pharmaciesToShow + 3 < pharmacies.length) {
                            setPharmaciesToShow(pharmaciesToShow + 3);
                          } else {
                            setPharmaciesToShow(pharmacies.length);
                          }
                        }}
                        className={"text-cornflower-100 w-full text-center mt-6"}
                      >
                        Show more pharmacies
                      </button>
                    )}
                  </div>
                )}
                <div className={"border-t border-b pb-6 border-grey-60 my-6"}>
                  <IconTextChevron
                    onClick={() => setModalType("help")}
                    planAction={"need-help"}
                    icon={"/assets/header-question-icon.svg"}
                    iconAlt={"question icon"}
                    text={"Need help with your search?"}
                    onKeyDown={handleLinkKeyDown}
                    tabIndex={0}
                  />
                </div>
              </div>
            );
          }}
        </SimpleForm>
      </div>
      <div
        className={"px-8 mt-10"}
        style={{ display: modalType === "help" ? "block" : "none" }}
        tabIndex={1}
      >
        <h2 className={"text-forest-120 text-xl font-medium"}>How our pharmacy search works.</h2>
        <p className={"text-forest-120 mt-10"}>
          You'll need to enter both a name and a ZIP Code. Search results will include all
          pharmacies with that name which are in or near to that ZIP Code, sorted by distance.
        </p>
        <h2 className={"text-forest-120 text-xl font-medium mt-10"}>Entering a pharmacy name</h2>
        <p className={"mt-4 text-forest-120"}>
          Don't know the full name of the pharmacy? Partial names are accepted, for e.g. “Safeway
          Pharmacy”, “Safeway” or even “Safe” will all work.
        </p>
        <h2 className={"mt-10 text-forest-120 text-xl font-medium"}>Entering the ZIP Code</h2>
        <p className={"mt-4 text-forest-120"}>
          Not sure where the pharmacy is located? Enter any ZIP Code nearby and we'll show you a
          list of pharmacies you can choose from.
        </p>
      </div>
    </FullPageModal>
  );
};

interface SearchPharmaciesForm {
  name: string;
  zip: string;
}
