/** @format */
import React from "react";
import { generatePath, useHistory } from "react-router-dom";

import { Typography } from "@alphamedical/components";
import { Paper } from "src/v2/designSystem";

import { ContentfulService } from "src/api/services/ContentfulService";
import { Loader } from "src/components/Loader";
import { PageWrapper } from "src/components/PageWrapper";
import * as PDB_ROUTES from "src/routes";
import { useGetMembership } from "src/utils/hooks";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { MembershipModal } from "src/v2/components/MonthlyMembership/MembershipModal";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";

import type { NoticePageContent } from "./noticePage.types";

import { FrequentlyAskedQuestions } from "./FrequentlyAskedQuestions";
import { MembershipDetails } from "./MembershipOption";
import defaultContent from "./noticePageContent.json";
import { TreatmentDetails } from "./TreatmentDetails";

import "./noticePage.scss";

export const NoticePage = ({ match }: any) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [pageContent, setPageContent] = React.useState<NoticePageContent>();
  const condition = match.params.condition;
  const url = generatePath(PDB_ROUTES.CONSULTATION_ROUTE(condition));
  const history = useHistory();
  const { membership, isFetchingMembership } = useGetMembership();
  useTopBar({ showBackButton: false, withLeftHandNav: true });

  React.useEffect(() => {
    // If the user has an active membership, we want to redirect them straight to the visit for now.
    if (!isFetchingMembership && membership?.active) {
      history.push(url);
    }
  }, [membership, isFetchingMembership]);

  React.useEffect(() => {
    const initializePage = async () => {
      try {
        const { content } = await ContentfulService.getUnstructuredContentByKey({
          key: `notice-page-${condition}`,
        });
        setPageContent(content || defaultContent[condition]);
      } catch (error) {
        setPageContent(defaultContent[condition]);
      }
    };
    initializePage();
  }, []);

  const handleOnClick = async () => {
    history.push(url);
  };

  return (
    <>
      <PageWrapper cxPadding="px-0">
        <Paper elevation={0} rounded={false} className="mx-auto w-full max-w-2xl pt-8 pb-6">
          {pageContent && !isFetchingMembership ? (
            <>
              <div className="pb-4 px-6">
                <Typography variant="h3" className="pb-4">
                  {pageContent.title}
                </Typography>
                <Typography variant="body1">{pageContent.subtitle}</Typography>
                <MembershipDetails
                  content={pageContent.membershipDetails}
                  color="sand-100"
                  borderColor="sand-120"
                  modalHandler={setModalOpen}
                  onClickHandler={handleOnClick}
                />
                <MembershipModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
              </div>
              <div className="pt-6 pb-4 px-6">
                <TreatmentDetails {...pageContent.treatmentDetails} />
              </div>
              <div className="border-b border-grey-60 mb-4" />
              <div className="pt-6 pb-2 px-6 bg-cloud-40 border-b border-grey-40">
                <FrequentlyAskedQuestions {...pageContent.faqsDetails} />
              </div>
            </>
          ) : (
            <Loader show={true} />
          )}
        </Paper>
      </PageWrapper>
    </>
  );
};

export default WithTopBarNavigation(NoticePage);
