/** @format */

import React from "react";
import { Link } from "react-router-dom";

import type { Channel } from "src/v2/models/channels";
import type { MedicationOrder } from "src/v2/types/medications";

import { CardContent } from "src/components/CardContent";
import { createDetailsList } from "src/helpers/visitDetails";

interface TestKitCardProps {
  testKit?: MedicationOrder;
  supportChannel?: Channel | false;
  condition: string;
}

export const TestKitCard = ({ testKit, supportChannel, condition }: TestKitCardProps) => {
  const showRegisterCTA =
    testKit && !testKit.sku.inactive && testKit.fill_request && !testKit.fill_request.registered;
  const testResults = testKit && testKit.fill_request && testKit.fill_request.test_results;
  const showContactSupportCTA =
    testResults && ["REJECTED", "CANCELLED"].indexOf(testResults.status) !== -1;
  const showTestResultCTA = testResults && testResults.status === "READY";
  return (
    <div>
      <div className="bg-white mb-4 w-full flex flex-col p-6 relative">
        <img
          src={"/assets/tube.svg"}
          alt=""
          role="presentation"
          className="absolute"
          style={{ top: "1.5rem", right: "1.5rem" }}
        />
        <CardContent detailList={createDetailsList({ type: "test-kit", testKit: testKit })} />
        {showRegisterCTA && (
          <Link
            to={`/register-kit?condition=${condition}`}
            className="no-underline font-bold text-violator mt-4"
          >
            <div className="inline-block">
              Register Your Test Kit <span className="fas fa-arrow-right text-sm" />
            </div>
          </Link>
        )}
        {showContactSupportCTA && (
          <Link
            to={supportChannel ? `/messages/${supportChannel.name}` : "/messages/new"}
            className="no-underline font-bold text-sky-dark mt-4"
          >
            <div className="inline-block">
              Contact Support <span className="fas fa-arrow-right text-sm" />
            </div>
          </Link>
        )}
        {showTestResultCTA && (
          <Link to={"/your-care/labs"} className="no-underline font-bold text-sky-dark mt-4">
            <div className="inline-block">
              View Your Test Results <span className="fas fa-arrow-right text-sm" />
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};
