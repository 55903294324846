/** @format */

import React from "react";

import { Popup } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import { NpsService } from "src/api/services/NpsService";
import { useStoreDispatch } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";

import NpsForm from "./NpsForm";

interface NpsPopupProps {
  showNps: boolean;
  setShowNps: (value: boolean) => void;
}

const NpsPopup = ({ showNps, setShowNps }: NpsPopupProps) => {
  const [showConfirmation, setShowConfirmation] = React.useState<boolean>(false);
  const user = useCurrentUser();
  const dispatch = useStoreDispatch();
  return (
    <Popup
      isOpen={showNps}
      headerText=""
      size="xlarge"
      shouldCloseOnOverlayClick={false}
      onRequestClose={() => {
        if (!showConfirmation) {
          NpsService.declineNps({ userId: user?.id }).finally(() => {
            dispatch.profile.fetchProfile({ userId: user?.id, include: ["followup_items", "pii"] });
          });
        }
        setShowNps(false);
      }}
    >
      {!showConfirmation && <NpsForm setShowConfirmation={() => setShowConfirmation(true)} />}
      {showConfirmation && (
        <>
          <div className="p-4 mb-6">
            <div className="mb-4 text-forest-darker font-medium text-lg text-center">
              Thank you for your answer!
            </div>
            <div className="text-forest-darker text-center md:max-w-sm lg:max-w-full sm:max-w-xs mx-auto">
              Your feedback helps us improve and provide a better experience for all patients.
            </div>
          </div>
          <div className="bg-grey-lightest flex items-end place-items-end justify-items-end">
            <div className="flex-1 w-4" />
            <ButtonPdb
              className="m-4 flex-initial"
              onClick={() => {
                dispatch.profile.fetchProfile({
                  userId: user?.id,
                  include: ["followup_items", "pii"],
                });
                setShowNps(false);
              }}
            >
              Close
            </ButtonPdb>
          </div>
        </>
      )}
    </Popup>
  );
};

export default NpsPopup;
