/** @format */

import React from "react";

import { Paper } from "src/v2/designSystem";

import { PageWrapper } from "src/components/PageWrapper";
import EditLoginDetails from "src/components/Settings/EditLoginDetails";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";

const SettingsContainer = () => {
  useTopBar({ title: "Change password", variant: "nested", showBackButton: true });
  return (
    <PageWrapper cxPadding="px-0" fitToPageHeight>
      <Paper elevation={0} rounded={false} className="mx-auto h-screen w-full max-w-2xl px-6">
        <EditLoginDetails />
      </Paper>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(SettingsContainer);
