/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HealthActionPlan } from '../models/HealthActionPlan';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HealthActionPlanService {

    /**
     * @returns HealthActionPlan
     * @throws ApiError
     */
    public static getLastHealthActionPlan({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<HealthActionPlan> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/health_action_plan',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns HealthActionPlan
     * @throws ApiError
     */
    public static markItemAsRead({
        userId,
        healthActionPlanId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        healthActionPlanId: number,
    }): CancelablePromise<HealthActionPlan> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/{user_id}/health_action_plan/{health_action_plan_id}/mark-as-read',
            path: {
                'user_id': userId,
                'health_action_plan_id': healthActionPlanId,
            },
        });
    }

    /**
     * Retrieves the health action plan for a specific user and response collection.
     * If a health action plan does not exist, create it.
     *
     * Args:
     * user_id (int): The ID of the user.
     * response_collection_id (int): The ID of the response collection.
     *
     * Returns:
     * dict: The health action plan data.
     *
     * Raises:
     * BadRequestError: If the outline form is not a Health Check In.
     * @returns HealthActionPlan
     * @throws ApiError
     */
    public static getHealthActionPlan({
        userId,
        responseCollectionId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        responseCollectionId: number,
    }): CancelablePromise<HealthActionPlan> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/outline_responses/{response_collection_id}/health_action_plan',
            path: {
                'user_id': userId,
                'response_collection_id': responseCollectionId,
            },
        });
    }

}
