/** @format */

import type React from "react";

import ClipLoader from "react-spinners/ClipLoader";

import { usePromiseOnMount } from "src/utils/hooks";

interface PromiseLoaderProps<T> {
  promise: () => Promise<T>;
  children: (renderProps: { data: T }) => React.ReactNode;
}

export const PromiseLoader = <T extends object>(props: PromiseLoaderProps<T>) => {
  const [data, loading, error] = usePromiseOnMount(props.promise);

  if (loading) {
    return (
      <div className="flex justify-center items-center">
        <ClipLoader color="#A4B7B5" loading={true} />
      </div>
    );
  }

  if (error || !data) {
    return <span className="text-red">Something went wrong</span>;
  }
  return <>{props.children({ data })}</>;
};
