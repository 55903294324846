/** @format */

import React from "react";

interface CircularButtonProps {
  value: string;
  selected: boolean;
  diameter?: number;
  onClick: (value: string) => void;
}

const CircularButton = ({ selected, value, onClick }: CircularButtonProps) => {
  return (
    <div onClick={() => onClick(value)} className="cursor-pointer w-12 h-12 relative">
      {selected ? (
        <img src={"/assets/filled-circle.svg"} />
      ) : (
        <img src={"/assets/empty-circle.svg"} />
      )}
      <div
        className={`absolute top-0 left-0 font-black ${
          selected ? "text-white" : "text-cornflower-100"
        } w-full mt-3 text-center`}
      >
        {value}
      </div>
    </div>
  );
};

export default CircularButton;
