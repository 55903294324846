/** @format */

import React from "react";

import { Typography } from "@alphamedical/components";
import { ButtonPdb, Paper } from "src/v2/designSystem";

import { AlphaModal } from "src/v2/components/AlphaModal";

export interface CardPopupContent {
  title: string;
  cardHeader: string;
  cardContent: string;
  buttonText: string;
}

interface CardPopupProps {
  isOpen: boolean;
  onRequestClose: () => void;
  content: CardPopupContent;
}

/**
 * Renders an Alpha Modal styled with a Paper component. This design layout is from early 2024.
 * It is componentized here as it will likely be reused.
 */
export const CardPopup = ({ isOpen, onRequestClose, content }: CardPopupProps) => {
  const { title, cardContent, cardHeader, buttonText } = content;
  return (
    <AlphaModal
      isOpen={isOpen}
      className="bg-white max-w-sm m-auto rounded"
      cxPadding="pt-1.5"
      onRequestClose={onRequestClose}
      closeIconColor="grey-160"
    >
      <div id="popup-header" className="flex flow-row items-center justify-between m-4 pr-10">
        <Typography className="line-clamp-1">{title}</Typography>
      </div>
      <div id="bio" className="m-4 mb-2">
        <Paper className="rounded-none p-6 pt-2 flex flex-col space-y-2">
          <Typography variant="subtitle2">
            <span className="text-black">{cardHeader}</span>
          </Typography>
          <Typography variant="body1">{cardContent}</Typography>
        </Paper>
      </div>
      <div id="button-footer" className="bg-grey-10 flex justify-center p-4">
        <ButtonPdb className="w-full" onClick={onRequestClose}>
          {buttonText}
        </ButtonPdb>
      </div>
    </AlphaModal>
  );
};
