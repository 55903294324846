/** @format */

import { IsDateOfBirthValid } from "src/v2/utils/page/_validators/condition";

import type { FormValidator } from "./types";

export const blockMinors: FormValidator = (values) => {
  // List of potential fields that might be shown to the user to ask what state they are in. The 1st answered field
  // should be used for eligiblity.
  const stateFieldKeys = ["consult-state", "current-state", "state"];
  const firstAnsweredFieldKey = stateFieldKeys.find((fieldKey) => !!values[fieldKey]);
  const state = firstAnsweredFieldKey && values[firstAnsweredFieldKey];

  const minAge = state === "NE" ? 19 : state === "MS" ? 21 : 18;
  const isMinor = !IsDateOfBirthValid(values.dob, minAge);

  if (!isMinor) {
    return null;
  }
  return { type: "age", requiredAge: minAge };
};

export const groupTherapyEligibility: FormValidator = (values) => {
  const stateFieldKeys = ["consult-state", "current-state", "state"];
  const firstAnsweredFieldKey = stateFieldKeys.find((fieldKey) => !!values[fieldKey]);
  const state = firstAnsweredFieldKey && values[firstAnsweredFieldKey];

  const minAge = state === "NE" ? 19 : state === "MS" ? 21 : 18;
  const isMinor = !IsDateOfBirthValid(values.dob, minAge);

  if (isMinor) {
    return { type: "age", requiredAge: minAge };
  }
  // Disabling to open up group therapy to all states
  // if (state !== "CA") {
  //   return { type: "waitlist", waitlistType: "group_therapy", state };
  // }

  return null;
};
