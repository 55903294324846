/** @format */

import React from "react";

import { Popup } from "@alphamedical/components";

import { ConsultsService } from "src/api";
import CouponInput from "src/components/Coupon/CouponInput";
import { PromiseLoader } from "src/components/Loader/PromiseLoader";
import {
  MembershipModal,
  MembershipPlusModal,
} from "src/v2/components/MonthlyMembership/MembershipModal";
import { PaymentLineItem } from "src/v2/components/Payment/PaymentLineItem";
import PromoErrorContent from "src/v2/components/Payment/PromoErrorContent";
import { formatPrice } from "src/v2/components/Payment/utils";
import { useStoreActions, useStoreState } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";
import { useCurrentConsult } from "src/v2/routes/Consultation";

import { PaymentSection } from "./PaymentSection";

export const TreatmentPaymentLineItems = () => {
  const consult = useCurrentConsult();
  const user = useCurrentUser();
  const consultFee = useStoreState((state) => state.consults.consultFee);
  const outline = useStoreState((state) => state.outlines.currentOutline);
  const [baseModalOpen, setBaseModalOpen] = React.useState<boolean>(false);
  const [plusModalOpen, setPlusModalOpen] = React.useState<boolean>(false);

  const { couponDiscount, couponDiscountAmount, promoError } = useStoreState(
    (state) => state.payments,
  );
  const { checkCoupon, setPromoError, setDueToday } = useStoreActions(
    (actions) => actions.payments,
  );

  const couponEnter = (couponCode: string) => {
    if (outline?.condition && consultFee) {
      checkCoupon({ couponCode, consultFee, conditionKey: outline.condition });
    }
  };

  return (
    <PromiseLoader
      promise={() => ConsultsService.getConsultCost({ userId: user.id, consultId: consult.id })}
    >
      {({ data: { groups, due_today, has_promo } }) => {
        setDueToday(due_today.amount_in_cents);
        return (
          <>
            <MembershipModal modalOpen={baseModalOpen} setModalOpen={setBaseModalOpen} />
            <MembershipPlusModal modalOpen={plusModalOpen} setModalOpen={setPlusModalOpen} />
            <div className="space-y-10">
              <Popup
                headerText={"Promo Code Not Applied"}
                isOpen={promoError.is_error}
                onRequestClose={() => setPromoError({ is_error: false })}
              >
                <PromoErrorContent
                  onClose={() => setPromoError({ is_error: false })}
                  error={promoError.description}
                />
              </Popup>
              {groups.map((group) => (
                <PaymentSection
                  key={group.name}
                  lineItemGroup={group}
                  openBaseModal={() => setBaseModalOpen(true)}
                  openPlusModal={() => setPlusModalOpen(true)}
                />
              ))}
              {couponDiscount && (
                <PaymentLineItem
                  textSize={"text-sm"}
                  description={`Promotion Discount: ${couponDiscount.discount.coupon_code}`}
                  cost={-couponDiscountAmount}
                />
              )}
              {has_promo && !couponDiscount && (
                <CouponInput onSubmit={(e: any, promo: string) => couponEnter(promo)} />
              )}
              <div className="border-t-2 border-primary flex justify-between font-bold pt-3">
                <div>{due_today.label}</div>
                <div>{formatPrice(due_today.amount_in_cents - couponDiscountAmount)}</div>
              </div>
            </div>
          </>
        );
      }}
    </PromiseLoader>
  );
};
