/** @format */

import React from "react";

import type { FollowupItem } from "src/components/Popups/types";

import { AlphaModal } from "src/v2/components/AlphaModal";
import { Button } from "src/v2/components/Button";
import { formatPrice } from "src/v2/components/Payment/utils";
import { useStoreDispatch } from "src/v2/models";

export interface MailOrderApprovalPopupProps {
  item: FollowupItem;
  showPopup: boolean;
  setShowPopup: (arg: boolean) => void;
  key?: number;
  pii?: any;
  profile?: any;
}

export const MailOrderApprovalPopup = ({
  item: {
    followup_type,
    medication_name,
    order_id,
    condition_name,
    medication_cost,
    days_supply,
    is_one_time,
    condition_key,
  },
  showPopup,
  setShowPopup,
  pii,
  profile,
}: MailOrderApprovalPopupProps) => {
  const [showForm, setShowForm] = React.useState<boolean>(false);
  const [pharmacyInfo, setPharmacyInfo] = React.useState<string>("");
  const dispatch = useStoreDispatch();
  const payCashOrTransferToExternal = (approved: boolean) => {
    if (approved) {
      dispatch.profile.sendOrderApproval({
        approved,
        order_id,
        medication_name,
        followup_type,
        condition_name,
        days_supply,
        medication_cost,
      });
    } else {
      dispatch.profile.sendOrderApproval({
        approved,
        order_id,
        followup_type,
        condition_name,
        pharmacy_info: pharmacyInfo,
        medication_name,
      });
    }
    setShowPopup(false);
    setShowForm(false);
  };

  const monthsSupply = days_supply && Math.round(days_supply / 30);
  const formattedCost =
    condition_key === "skincare" || is_one_time
      ? medication_cost && formatPrice(medication_cost)
      : medication_cost &&
        `${formatPrice(medication_cost)} ${
          monthsSupply && monthsSupply > 1 ? `every ${monthsSupply} months` : "per month"
        }`;

  return (
    <div>
      <AlphaModal
        isOpen={showPopup}
        className="max-w-sm m-auto bg-white shadow-md"
        addPaddingForNavBar={true}
        cxPadding="p-8"
        onRequestClose={() => {
          setShowPopup(false);
        }}
      >
        {!showForm && (
          <div className="flex flex-col text-16">
            <p className="font-700">
              Hi {(pii && pii.preferred_firstname) || (pii && pii.first_name)},
            </p>
            <p className="mb-4">
              Alpha Care Team here! It looks like our partner pharmacy is unable to process your
              order because your insurance requires you to use their mail order program after
              receiving 2-3 orders. This is not a problem.
            </p>
            <p className="mb-4">
              To continue with Alpha and receive free home delivery, just simply give your insurance
              carrier a call using the member service number located on the back of your insurance
              card and let them know you would like to opt out of their mail pharmacy program. ---
              Please keep in mind to ask if they will still cover your medication if you opt out of
              their preferred pharmacy before you choose to opt out.
            </p>
            <p className="mb-4">
              Alternatively, you can also choose to continue with us as a cash patient. As a cash
              patient, {medication_name} is {formattedCost}. Shipping is always free.
            </p>

            <p className="mb-6">
              Please let us know if you have any questions, we are here to help!
            </p>
            <div className="flex justify-content">
              <Button
                extraClassNames="btn btn-violator w-1/2 mr-4 cursor-pointer"
                disabled={profile.postingApproval}
                onClick={() => payCashOrTransferToExternal(true)}
              >
                Pay Cash
              </Button>
              <Button
                extraClassNames={`
                                    text-center
                                    border-2 border-primary
                                    hover:text-white
                                    items-center w-1/2 cursor-pointer`}
                disabled={profile.postingApproval}
                bgColor={"white"}
                hoverBgColor={"primary-80"}
                textColor={"primary"}
                onClick={() => setShowForm(true)}
              >
                Transfer
              </Button>
            </div>
            {profile.error && <p className="text-red">{profile.error}</p>}
          </div>
        )}
        {showForm && (
          <div className="w-sm bg-white m-auto p-6">
            <div
              className={`
            absolute top-0 left-0 fas fa-arrow-left text-2xl p-5 mt-1 cursor-pointer text-primary-60 hover:text-primary
        `}
              onClick={() => setShowForm(false)}
            />
            <p className="text-18 text-center">
              Please enter the local pharmacy's information that you wish to use.
            </p>
            <textarea
              style={{ height: "100px" }}
              className={`border w-full border-primary-20 border-primary-20
                                focus:border-primary p-3 my-3 outline-none`}
              name="pharmacy"
              onChange={(e) => setPharmacyInfo(e.target.value)}
            />
            <div className="flex">
              <Button
                extraClassNames="w-1/2"
                disabled={profile.postingApproval || pharmacyInfo.length === 0}
                onClick={() => payCashOrTransferToExternal(false)}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </AlphaModal>
    </div>
  );
};
