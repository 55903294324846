/** @format */

import React from "react";
import { Field } from "react-final-form";

import classNames from "classnames";
import Cleave from "cleave.js/react";

import type { TextFieldNewProps } from "@alphamedical/components/dist/DesignSystemV1/Forms/Fields/TextFieldNew";

import { FieldWrapper } from "@alphamedical/components";

import { EXCLAMATION_MARK_ERROR } from "src/v2/components/Wizard/utils";

import { FieldError } from "./error";

interface TextFieldProps {
  name: string;
  placeholder?: string;
  cleaveProps?: any;
  parse?: (value: any, name: string) => any;
  cxTextField?: string;
  cxBorder?: string;
}

export const TextField = (props: TextFieldProps) => (
  <>
    <Field name={props.name} parse={props.parse}>
      {({ input, meta }) => {
        const hasError = (meta.error && meta.touched) || meta.submitError;
        const className = `
        w-full ${props.cxTextField || "p-3 my-3"} outline-none
          ${hasError ? "border-b border-red" : props.cxBorder || "border-b border-primary-20 focus:border-primary"}
        `;
        return (
          <>
            {(!!props.cleaveProps && (
              <Cleave
                {...input}
                options={props.cleaveProps}
                placeholder={props.placeholder}
                className={className}
              />
            )) || (
              <input {...input} type="text" placeholder={props.placeholder} className={className} />
            )}
          </>
        );
      }}
    </Field>
    <FieldError name={props.name} />
  </>
);

export const DateField = (props: TextFieldProps) => (
  <TextField
    name={props.name}
    placeholder={props.placeholder || "mm-dd-yyyy"}
    parse={(value) => (value ? value.replace(/\//g, "-") : value)}
    cleaveProps={{
      delimiter: "-",
      date: true,
      datePattern: ["m", "d", "Y"],
    }}
    cxTextField={props.cxTextField}
    cxBorder={props.cxBorder}
  />
);

export const PhoneNumberField = (props: TextFieldProps) => (
  <TextField
    name={props.name}
    placeholder={props.placeholder}
    cleaveProps={{
      delimiter: "-",
      phone: true,
      phoneRegionCode: "US",
    }}
  />
);

interface DateFieldNewProps extends TextFieldNewProps {
  showError?: boolean;
  cleaveProps?: any;
  inputClassName?: string;
  errorMessage?: string;
}

export const DateFieldNew = (props: DateFieldNewProps) => (
  <FieldWrapper name={props.name}>
    {({ input }) => (
      <div className="mb-4">
        <p
          className={classNames("text-sm mb-2", {
            "text-error": props.showError,
          })}
        >
          {props.label}
        </p>
        <Cleave
          {...input}
          options={props.cleaveProps}
          placeholder={props.placeholder}
          className={props.inputClassName}
        />
        {props.showError && (
          <div className={"flex items-center mt-2 text-error"}>
            <i className={`${EXCLAMATION_MARK_ERROR} mr-2`}></i>
            <p className={"text-sm"}>{props.errorMessage}</p>
          </div>
        )}
      </div>
    )}
  </FieldWrapper>
);
