/** @format */

import React from "react";
import ReactMarkdown from "react-markdown";

import { Typography } from "@alphamedical/components";
import { InfoCard } from "src/v2/designSystem";

import type { ContentReference } from "src/components/DynamicForm/types";

import { HIPAABadges } from "src/components/Badges/ HIPAABadges";
import { BCMethodInfo } from "src/components/BCMethodInfo";
import { Accordion } from "src/components/DynamicForm/Fields/CustomFields/Accordion";
import CurrentMedications from "src/components/DynamicForm/Fields/CustomFields/CurrentMedications";
import GeneralInfo from "src/components/DynamicForm/Fields/CustomFields/GeneralInfo";
import InstructionsComponent from "src/components/DynamicForm/Fields/CustomFields/InstructionsComponent";
import MedicationReporting from "src/components/DynamicForm/Fields/CustomFields/MedicationReporting";
import {
  AlphaMembershipGate,
  AlphaPlusMembershipGate,
} from "src/components/DynamicForm/Fields/CustomFields/MembershipGate";
import { StepDescription } from "src/components/DynamicForm/Fields/CustomFields/StepDescription";
import { StepDescriptionMulti } from "src/components/DynamicForm/Fields/CustomFields/StepDescriptionMulti";
import { SchedulingJoinInfo } from "src/components/DynamicForm/Fields/CustomFields/Synchronous/SchedulingJoinInfo";
import { TelehealthConsent } from "src/components/DynamicForm/Fields/CustomFields/TelehealthConsent";
import { GenericFormTransitionScreen } from "src/components/DynamicForm/Fields/CustomFields/TransitionScreen/GenericFormTransitionScreen";
import { TransitionScreen } from "src/components/DynamicForm/Fields/CustomFields/TransitionScreen/TransitionScreen";
import { VisitInfo } from "src/components/DynamicForm/Fields/CustomFields/VisitInfo";
import {
  B2BMembershipDetails,
  HowAlphaWorks,
  WelcomeConditionCarousel,
  WelcomeConditionCarouselHealthCheckIn,
  WelcomeIntro,
  WelcomeIntroHealthCheckIn,
  WelcomeNameIntro,
  WelcomeNameIntroHealthCheckin,
  WelcomeVideo,
  WelcomeVideoHealthCheckIn,
} from "src/components/DynamicForm/Fields/CustomFields/WelcomeFlow";
import ReviewAndConfirm from "src/components/DynamicForm/Fields/CustomFields/WelcomeFlow/ReviewAndConfirm";
import { GroupSessionWaitlist } from "src/components/GroupSessionWaitlist";
import { SkincarePhotosDescription } from "src/components/SkincarePhotosDescription";
import { MembershipOptions } from "src/routes/Membership/MembershipOptions";
import { CheckinResultsPageOutline } from "src/v2/components/CheckinResultPage/CheckinResultsPage";
import { LabAddressPage } from "src/v2/components/Page/LabAddressPage/LabAddressPage";
import { PaymentBanner, TreatmentPaymentBanner } from "src/v2/components/Payment/PaymentBanner";
import PaymentLineItems from "src/v2/components/Payment/PaymentLineItems";
import { TreatmentPaymentLineItems } from "src/v2/components/Payment/TreatmentPaymentLineItems";

interface DynamicComponentProps {
  content?: string | JSX.Element | ContentReference;
  renderStringAsHtml?: boolean;
  stringWrapperProps?: any;
}

const getComponentByName = (name: string) => {
  switch (name) {
    case "BCMethodInfo":
      return BCMethodInfo;
    case "SkincarePhotosDescription":
      return SkincarePhotosDescription;
    case "HIPAABadges":
      return HIPAABadges;
    case "AlphaPlusMembershipGate":
      return AlphaPlusMembershipGate;
    case "AlphaMembershipGate":
      return AlphaMembershipGate;
    case "PaymentBanner":
      return PaymentBanner;
    case "TreatmentPaymentBanner":
      return TreatmentPaymentBanner;
    case "PaymentLineItems":
      return PaymentLineItems;
    case "TreatmentPaymentLineItems":
      return TreatmentPaymentLineItems;
    case "GroupSessionWaitlist":
      return GroupSessionWaitlist;
    case "GeneralInfo":
      return GeneralInfo;
    case "CurrentMedications":
      return CurrentMedications;
    case "MedicationReporting":
      return MedicationReporting;
    case "InstructionsComponent":
      return InstructionsComponent;
    case "Accordion":
      return Accordion;
    case "ReviewAndConfirm":
      return ReviewAndConfirm;
    case "TransitionScreen":
      return TransitionScreen;
    case "GenericFormTransitionScreen":
      return GenericFormTransitionScreen;
    case "WelcomeIntro":
      return WelcomeIntro;
    case "WelcomeIntroHealthCheckIn":
      return WelcomeIntroHealthCheckIn;
    case "WelcomeNameIntro":
      return WelcomeNameIntro;
    case "WelcomeNameIntroHealthCheckin":
      return WelcomeNameIntroHealthCheckin;
    case "WelcomeConditionCarousel":
      return WelcomeConditionCarousel;
    case "WelcomeConditionCarouselHealthCheckIn":
      return WelcomeConditionCarouselHealthCheckIn;
    case "WelcomeVideo":
      return WelcomeVideo;
    case "WelcomeVideoHealthCheckIn":
      return WelcomeVideoHealthCheckIn;
    case "VisitInfo":
      return VisitInfo;
    case "B2BMembershipDetails":
      return B2BMembershipDetails;
    case "HowAlphaWorks":
      return HowAlphaWorks;
    case "MembershipOptions":
      return MembershipOptions;
    case "SchedulingJoinInfo":
      return SchedulingJoinInfo;
    case "StepDescription":
      return StepDescription;
    case "StepDescriptionMulti":
      return StepDescriptionMulti;
    case "LabAddressPage":
      return LabAddressPage;
    case "InfoCard":
      return InfoCard;
    case "CheckinResultsPage":
      return CheckinResultsPageOutline;
    case "TelehealthConsent":
      return TelehealthConsent;
    default:
      return null;
  }
};

export const DynamicComponent = ({ content, ...props }: DynamicComponentProps) => {
  if (React.isValidElement(content)) {
    return content;
  }

  // Adding this logic since from now we want to be able to attach custom component in the description of any field,
  // so it will check if it's the case
  let contentObject;
  if (typeof content === "object") {
    contentObject = content["#ref"];

    if (!contentObject) {
      if (typeof content["description"] === "object" && content["description"]["#ref"])
        contentObject = content["description"]["#ref"];
    }

    if (contentObject) {
      const componentName = contentObject["name"];
      const innerProps = { ...contentObject["props"] };
      const mergedProps = { ...contentObject, ...innerProps };
      const Component = getComponentByName(componentName);
      return Component != null ? <Component {...mergedProps} /> : null;
    }
  }

  const wrapperProps = props.stringWrapperProps || {};
  if (typeof content === "string" && props.renderStringAsHtml) {
    return <div {...wrapperProps} dangerouslySetInnerHTML={{ __html: content }} />;
  }

  if (typeof content === "string") {
    return (
      <Typography {...wrapperProps}>
        <ReactMarkdown>{content}</ReactMarkdown>
      </Typography>
    );
  }

  return <div {...wrapperProps}>{content}</div>;
};
