/** @format */

import React from "react";

import { ContentTracking, CustomDimensions } from "@piwikpro/react-piwik-pro";

import config from "src/config";

const DIMENSION_MAP = {
  ServiceType: {
    test: "1",
    prod: "1",
  },
  FormPageKey: {
    test: "2",
    prod: "2",
  },
  FormPageIndex: {
    test: "3",
    prod: "3",
  },
  Organization: {
    test: "4",
    prod: "5",
  },
  WeightLossFunnelVariant: {
    test: "6",
    prod: "7",
  },
};

export type DimensionName = keyof typeof DIMENSION_MAP;

export const useAnalyticsDimension = (dimension: DimensionName, dimensionValue?: string) => {
  const dimensionID = DIMENSION_MAP[dimension][config.isProd ? "prod" : "test"];

  React.useEffect(() => {
    // Cleanup the dimension value on unmount
    return () => {
      CustomDimensions.deleteCustomDimension(dimensionID);
    };
  }, []);

  React.useEffect(() => {
    if (dimensionValue) {
      CustomDimensions.setCustomDimensionValue(dimensionID, dimensionValue);
    } else {
      CustomDimensions.deleteCustomDimension(dimensionID);
    }
  }, [dimensionValue]);
};

export const useTrackContentImpressions = () => {
  React.useEffect(() => {
    const intervalId = setInterval(ContentTracking.trackAllContentImpressions, 1000);
    return () => {
      clearInterval(intervalId);
    };
  });
};
