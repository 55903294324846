/** @format */

import React from "react";
import { useFormState } from "react-final-form";

import { ConditionCarousel } from "src/components/DynamicForm/Fields/CustomFields/WelcomeFlow/WelcomeConditionCarousel/ConditionCarousel";
import { WelcomePageContainer } from "src/components/DynamicForm/Fields/CustomFields/WelcomeFlow/WelcomePageCotainer/WelcomePageContainer";
import welcome from "src/content/welcome";

export const WelcomeConditionCarousel = () => {
  const formState = useFormState();
  const values = formState.values;

  const name = values["preferred_firstname"];
  return (
    <WelcomePageContainer
      pageName="welcome-condition-carousel"
      submitButtonTitle={welcome.screen3.submitButton}
    >
      <ConditionCarousel name={name} />
    </WelcomePageContainer>
  );
};
