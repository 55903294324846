/** @format */

import type { FC } from "react";

import React from "react";

import type { SizeProp } from "@fortawesome/fontawesome-svg-core";
import {
  faBell as faBellOutlined,
  faCircleQuestion,
  faGear,
} from "@fortawesome/pro-regular-svg-icons";
import { faArrowLeft, faBars, faBell, faCheck, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

import { AlphaLogo, Typography } from "@alphamedical/components";
import { BadgePdb } from "src/v2/designSystem/BadgePdb";
import { ButtonPdb } from "src/v2/designSystem/ButtonPdb";

import { useStoreDispatch } from "src/v2/models";

import "./TopBarNavigation.scss";
export type TopBarVariant = "top-level" | "nested" | "hidden" | "main";

export interface TopBarNavigationProps {
  /**
   * Numbers of notifications
   */
  notifications?: number | string;

  /**
   * Callback `function` to be called on Back Icon click.
   */
  onBackCallback?: (args: any) => void | any;

  /**
   * Callback `function` to be called on Close Icon click.
   */
  onCloseCallback?: (args: any) => void | any;

  /**
   * Callback `function` to be called on Complete Icon click.
   */
  onCompleteCallback?: (args: any) => void | any;

  /**
   * Callback `function` to be called on Help Icon click.
   */
  onHelpCallback?: (args: any) => void | any;

  /**
   * Callback `function` to be called on Alpha Logo click.
   */
  onLogoCallback?: (args: any) => void | any;

  /**
   * Callback `function` to be called on Menu Icon click.
   */
  onMenuCallback?: (args: any) => void | any;

  /**
   * Callback `function` to be called on Notification Icon click.
   */
  onNotificationCallback?: (args: any) => void | any;

  /**
   * Callback `function` to be called on Logout Icon click.
   */
  onLogoutCallback?: (args: any) => void | any;

  /**
   * Callback `function` to be called on Settings Icon click.
   */
  onSettingsCallback?: (args: any) => void | any;

  /**
   * If `true` the Back icon is shown.
   */
  showBackButton?: boolean;

  /**
   * If `true` the Close icon is shown.
   */
  showCloseButton?: boolean;

  /**
   * If `true` the Complete icon is shown. Only valid for nested variant.
   */
  showCompleteButton?: boolean;

  /**
   * If `true` the Help icon is shown.
   */
  showHelpButton?: boolean;

  /**
   * If `true` the Menu icon is shown. Only valid for no nested variant and if showBackButton is `false`.
   */
  showMenuButton?: boolean;

  /**
   * If `true` the Notification icon is shown.
   */
  showNotificationButton?: boolean;

  /**
   *  If `true` the Logout icon is shown.
   */
  showLogoutButton?: boolean;

  /**
   *  If `true` the settings icon is shown.
   */
  showSettingsButton?: boolean;

  /**
   * If `true` the Notification icon filled.
   */
  isNotificationPageActive?: boolean;

  /**
   * TopBar subtitle.
   */
  subtitle?: string;

  /**
   * TopBar title.
   */
  title?: string;

  /**
   * Path for settings path.
   */
  settingsPath?: string;

  /**
   * Change the theme of the navbar. If `nested` indicates the page if one or more levels lower in the area hierarchy.
   * @default top-level
   */
  variant?: TopBarVariant;

  /**
   * If `true` change the style to allow the presence of the LeftHandNavigation. Only valid for no nested variant.
   * @default false
   */
  withLeftHandNav?: boolean;

  /**
   * Custom class for the container.
   */
  cxContainer?: string;
}

export const TopBarNavigation: FC<TopBarNavigationProps> = ({
  notifications,
  isNotificationPageActive,
  onBackCallback,
  onCloseCallback,
  onCompleteCallback,
  onHelpCallback,
  onLogoCallback,
  onMenuCallback,
  onNotificationCallback,
  onLogoutCallback,
  onSettingsCallback,
  showBackButton,
  showCloseButton,
  showCompleteButton,
  showHelpButton,
  showMenuButton,
  showLogoutButton,
  showNotificationButton,
  showSettingsButton,
  subtitle,
  title,
  variant = "main",
  withLeftHandNav = false,
  cxContainer,
}) => {
  const dispatch = useStoreDispatch();
  const mapVariantToStyles: { [key in TopBarVariant]: string } = {
    ["top-level"]: "bg-sand-40",
    nested: "bg-white topbar-container_nested",
    hidden: "hidden",
    main: "bg-sand-40 sm:bg-white topbar-container_main",
  };

  const mapVariantToTitleStyles: { [key in TopBarVariant]: string } = {
    ["top-level"]: "hidden",
    nested: "",
    hidden: "",
    main: "hidden sm:block",
  };

  const isNested = variant === "nested";
  const isMain = variant === "main";
  const isTopLevel = variant === "top-level";

  const baseIconProps: { color: string; size: SizeProp } = {
    color: "#1C4A46",
    size: "xl",
  };

  return (
    <div
      className={classnames(
        "flex flex-col sticky top-0 z-20",
        mapVariantToStyles[variant],
        withLeftHandNav ? "topbar-container_leftNav" : "w-full left-0",
        cxContainer,
      )}
    >
      <div className={"flex justify-between items-center py-3 px-2 topbar-container relative"}>
        <div className="flex justify-start items-center topbar-container_primary">
          {showMenuButton && !isNested && !showBackButton && (
            <ButtonPdb
              variant="transparent"
              iconOnly={true}
              icon={<FontAwesomeIcon icon={faBars} {...baseIconProps} />}
              onClick={onMenuCallback}
              iconSize="small"
            />
          )}

          {showBackButton && (
            <div className="topbar-icon">
              <ButtonPdb
                variant="transparent"
                iconOnly={true}
                icon={<FontAwesomeIcon icon={faArrowLeft} color="#1C4A46" />}
                onClick={onBackCallback}
                iconSize="small"
              />
            </div>
          )}

          <div
            className={classnames(
              "flex flex-col items-start justify-center",
              mapVariantToTitleStyles[variant],
              (isNested || isMain) && !showBackButton && !showMenuButton && "sm:pl-8 pl-2",
            )}
          >
            <div className={"overflow-hidden"}>
              <Typography variant={isMain ? "h2" : "h4"}>{title}</Typography>
            </div>
            {subtitle && (
              <Typography variant="caption" className="text-grey-160">
                {subtitle}
              </Typography>
            )}
          </div>
        </div>

        <div
          className={classnames(
            "flex justify-center items-center topbar-logo-container absolute",
            isMain && "sm:hidden",
          )}
        >
          {!isNested && (
            <div className="cursor-pointer" onClick={onLogoCallback}>
              <AlphaLogo height={24} />
            </div>
          )}
        </div>

        <div className="flex justify-end items-center gap-2 topbar-container">
          {/* TODO: This solution dosn't scale, reeplace with an array of icons/callbacks */}
          {showCompleteButton && isNested && (
            <ButtonPdb
              variant="transparent"
              iconOnly={true}
              icon={<FontAwesomeIcon icon={faCheck} color="#6271C2" size="xl" />}
              onClick={onCompleteCallback}
              iconSize="small"
            />
          )}

          {showHelpButton && (
            <ButtonPdb
              variant="transparent"
              iconOnly={true}
              icon={<FontAwesomeIcon icon={faCircleQuestion} {...baseIconProps} />}
              onClick={onHelpCallback}
              iconSize="small"
            />
          )}
          {showSettingsButton && (
            <ButtonPdb
              variant="transparent"
              iconOnly={true}
              icon={<FontAwesomeIcon icon={faGear} {...baseIconProps} />}
              onClick={onSettingsCallback}
              iconSize="small"
            />
          )}

          {showNotificationButton && (
            <div className="relative">
              <BadgePdb
                variant="informative"
                alwaysVisible={!!notifications}
                size="medium"
                addClasses={`absolute topbar-notification-badge border-2 ${
                  isTopLevel ? "border-sand-100" : "border-white"
                }`}
              />

              <ButtonPdb
                variant="transparent"
                iconOnly={true}
                icon={
                  <FontAwesomeIcon
                    icon={isNotificationPageActive ? faBell : faBellOutlined}
                    {...baseIconProps}
                  />
                }
                onClick={onNotificationCallback}
                iconSize="small"
              />
            </div>
          )}

          {showCloseButton && (
            <ButtonPdb
              variant="transparent"
              iconOnly={true}
              icon={<FontAwesomeIcon icon={faXmark} {...baseIconProps} />}
              onClick={onCloseCallback}
              iconSize="small"
            />
          )}

          {showLogoutButton && (
            <ButtonPdb
              variant="transparent"
              onClick={() => onLogoutCallback && onLogoutCallback(dispatch)}
              className="pl-1 pr-3"
            >
              Log out
            </ButtonPdb>
          )}
        </div>
      </div>
    </div>
  );
};
