/** @format */

import React from "react";

import type { SynchronousVisitSchema } from "src/api";

import { SynchronousVisitService } from "src/api";
import { useCurrentUser } from "src/v2/models/profile";

export const SYNC_VISIT_DURATION_MINUTES = 20;

export const useSynchronousVisits = () => {
  const [scheduledCalls, setScheduledCalls] = React.useState<SynchronousVisitSchema[]>([]);
  const currentUser = useCurrentUser();
  React.useEffect(() => {
    SynchronousVisitService.getSynchronousVisits({ userId: currentUser.id }).then(
      (response: SynchronousVisitSchema[]) => setScheduledCalls(response),
    );
  }, [currentUser.id]);

  return scheduledCalls;
};
