/** @format */

import type { PropsWithChildren } from "react";

import React from "react";

import { faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";

import { Typography } from "@alphamedical/components";
import { BorderedContent } from "src/v2/designSystem/Content/BorderedContent";

export interface RecordAccordionProps {
  /**
   * The text to show in the header
   */
  title: string;

  /**
   * Icon element to display
   */
  IconElement?: React.ReactNode;

  /**
   * Footer element to render
   */
  FooterElement?: React.ReactNode;

  /**
   * An element to display next to the chevron
   */
  HeaderRightElement?: React.ReactNode;

  /**
   * Whether the component will be expanded by default (false)
   */
  defaultExpanded?: boolean;

  /**
   * The footer will be displayed even if collapsed
   */
  preventFooterCollapse?: boolean;
}

export const RecordAccordion: React.FC<PropsWithChildren<RecordAccordionProps>> = ({
  children,
  defaultExpanded = false,
  preventFooterCollapse = false,
  HeaderRightElement,
  IconElement,
  FooterElement,
  title,
}) => {
  const [expanded, setExpanded] = React.useState<boolean>(defaultExpanded);

  return (
    <BorderedContent>
      <div
        className="flex items-center justify-between"
        role="button"
        onClick={() => setExpanded(!expanded)}
      >
        <div className={cn("mr-4", expanded && "border-b border-grey-60 pb-4")}>{IconElement}</div>
        <div
          className={cn(
            "flex flex-grow justify-between",
            expanded && "pb-4 border-b border-transparent",
            !expanded && "overflow-hidden",
          )}
        >
          <Typography className={cn("mr-4", !expanded && "truncate")} variant="h4">
            {title}
          </Typography>
          {HeaderRightElement}
        </div>
        <div className={cn(expanded && "pb-4")}>
          <div
            className={cn(
              "transition-transform transform duration-300",
              expanded ? "rotate-0" : "rotate-180",
            )}
          >
            <FontAwesomeIcon icon={faChevronUp} />
          </div>
        </div>
      </div>
      {expanded && <div className="mt-4">{children}</div>}
      {FooterElement && (expanded || preventFooterCollapse) && (
        <div className="flex mt-4">{FooterElement}</div>
      )}
    </BorderedContent>
  );
};
