/** @format */

import React from "react";
import { useHistory } from "react-router-dom";

import {
  faCirclePlus,
  faFolders,
  faToggleOff,
  faToggleOn,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faCirclePlus as faCirclePlusSolid,
  faFolders as faFoldersSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { badgeVariant, visibility } from "src/v2/designSystem";

import { WebNavigation } from "src/v2/designSystem";

import { FollowupItemPopups } from "src/components/Popups/FollowupItemPopups";
import config from "src/config";
import { useGetFollowupItems } from "src/utils/hooks";
import { useTotalUnreadMessages } from "src/utils/hooks/pubnub";

const YOUR_CARE_SUBPATHS = [
  "/orders",
  "/post-visit-summary",
  "/prescriptions",
  "/reported-medications",
];

export const Nav = () => {
  const history = useHistory();

  const callback = (value: string) => {
    history.push(value);
  };
  const followupItems = useGetFollowupItems();
  const activeColor = "#1C4A46";
  const inactiveColor = "#656E6D";

  const icons = [
    {
      iconActive: <FontAwesomeIcon color={activeColor} icon={["fas", "home"]} />,
      iconInactive: <FontAwesomeIcon color={inactiveColor} icon={["far", "home"]} />,
      label: "Home",
      clickCallback: callback,
      value: "/my-cases",
      visibleOn: "always" as visibility,
    },
    {
      iconActive: <FontAwesomeIcon color={activeColor} icon={faCirclePlusSolid} />,
      iconInactive: <FontAwesomeIcon color={inactiveColor} icon={faCirclePlus} />,
      label: "Start visit",
      clickCallback: callback,
      value: "/your-care/explore-visits",
      visibleOn: "desktop" as visibility,
    },
    {
      iconActive: <FontAwesomeIcon color={activeColor} icon={faFoldersSolid} />,
      iconInactive: <FontAwesomeIcon color={inactiveColor} icon={faFolders} />,
      label: "My care",
      clickCallback: callback,
      value: "/your-care",
      visibleOn: "always" as visibility,
    },
    {
      iconActive: <FontAwesomeIcon color={activeColor} icon={faCirclePlusSolid} />,
      iconInactive: <FontAwesomeIcon color={inactiveColor} icon={faCirclePlus} />,
      label: "Start visit",
      clickCallback: callback,
      value: "/your-care/explore-visits",
      visibleOn: "mobile" as visibility,
    },
    {
      iconActive: <FontAwesomeIcon color={activeColor} icon={["fas", "messages"]} />,
      iconInactive: <FontAwesomeIcon color={inactiveColor} icon={["far", "messages"]} />,
      label: "Inbox",
      badgeLabel: useTotalUnreadMessages(),
      badgeVariant: "informative" as badgeVariant,
      clickCallback: callback,
      value: "/inbox",
      visibleOn: "always" as visibility,
    },
    {
      iconActive: <FontAwesomeIcon color={activeColor} icon={["fas", "user"]} />,
      iconInactive: <FontAwesomeIcon color={inactiveColor} icon={["far", "user"]} />,
      label: "Account",
      clickCallback: callback,
      value: "/account",
      visibleOn: "always" as visibility,
    },
  ];

  if (!config.isProd) {
    icons.push({
      iconActive: <FontAwesomeIcon color={activeColor} icon={faToggleOn} />,
      iconInactive: <FontAwesomeIcon color={inactiveColor} icon={faToggleOff} />,
      label: "Variant Settings",
      clickCallback: callback,
      value: "/variant-settings",
      visibleOn: "desktop" as visibility,
    });
  }

  return (
    <>
      {followupItems && <FollowupItemPopups followupItems={followupItems} />}
      <WebNavigation
        icons={icons}
        logoCallback={() => history.push("/my-cases")}
        activeValue={history.location.pathname}
        comparator={(activePath: any, iconPath: any) =>
          iconPath === "/your-care" && YOUR_CARE_SUBPATHS.includes(activePath)
            ? true
            : activePath === iconPath
        }
      />
    </>
  );
};
