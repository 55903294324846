/** @format */

import type React from "react";

import cn from "classnames";
import { map } from "lodash";

import { CheckboxField } from "@alphamedical/components";

import SandBullet from "src/v2/components/SandBullet";

import type { MedicalRecordsRequestFormPageProps } from "./MedicalRecordsRequestForm";

const renderBullet = ({ title, details }: { title: string; details: string }) => (
  <SandBullet
    key={`bullet-${title}`}
    classes="mb-6"
    point={
      <div>
        <div className="font-bold">{title}</div>
        <div>{details}</div>
      </div>
    }
  />
);

const termsAndConditions = [
  {
    title: "Term",
    details:
      "This Authorization will remain in effect for one (1) year from the date this authorization is signed.",
  },
  {
    title: "Redisclosure",
    details:
      "I understand that once my healthcare provider discloses my health information to the recipient identified above, my healthcare provider cannot guarantee that the recipient will not redisclose my health information to a third party. The third party may not be required to abide by this Authorization or applicable federal and state law governing the use and disclosure of my health information.",
  },
  {
    title: "Refusal to sign and right to revoke",
    details:
      "I understand that I may refuse to sign or may revoke (at any time) this Authorization for any reason and that such refusal or revocation will not affect the commencement, continuation or quality of my treatment by my healthcare provider.",
  },
  {
    title: "Revocation",
    details:
      "I understand that the Authorization will remain in effect until the term of this Authorization expires or I provide a written notice of revocation. I can do this by sending a message in the Support thread in my Alpha patient portal or emailing contact@helloalpha.com. I understand that a revocation will not affect any information shared prior to revocation.",
  },
  {
    title: "Questions",
    details:
      "I may contact my healthcare provider for answers to my questions about the privacy of my health information by sending a message to my provider in my Alpha patient portal. I understand that I have the right to receive a copy of this authorization from my healthcare provider.",
  },
  {
    title: "Photocopy",
    details:
      "I have a right to receive a copy of this Authorization. A photocopy, fax, or electronic copy of this authorization shall be considered as effective and as valid as the original.",
  },
];

const Terms: React.FC<MedicalRecordsRequestFormPageProps> = ({ show }) => (
  <div className={cn({ block: show, hidden: !show })}>
    <div className="bg-sand-20 px-5 py-6 h-5/6 overflow-auto mb-6">
      <div className="text-xl font-medium mb-8">
        Please read and agree to the following terms and conditions.
      </div>
      {map(termsAndConditions, renderBullet)}
    </div>
    <CheckboxField
      name="terms_checkbox"
      options={[{ label: "I have read and I agree to the terms and conditions.", value: "true" }]}
    />
  </div>
);

export default Terms;
