/** @format */

import React from "react";
import { useHistory } from "react-router-dom";

import { find, map } from "lodash";
import moment from "moment";
import { usePubNub } from "pubnub-react";

import type { MessageAction, PubNubMessage } from "src/utils/hooks/pubnub";

import { _POST } from "src/helpers/http";
import { Button, secondaryProps } from "src/v2/components/Button";
import { useCurrentUser } from "src/v2/models/profile";

import { getComponentForMessageAction } from "./MessageActionComponents";

interface MessageActionsProps {
  message: PubNubMessage;
}

export interface MessageActionCompletion extends MessageAction {
  payload?: { [key: string]: any };
}

export const MessageActions = ({ message }: MessageActionsProps) => {
  const pubnub = usePubNub();
  const patient = useCurrentUser();
  const history = useHistory();

  const [isComplete, setIsComplete] = React.useState(false);
  const [completionText, setCompletionText] = React.useState("");
  const [isExpired, setIsExpired] = React.useState(false);
  const { messageActions } = message?.message?.customAttributes || {};
  const { expiration, actions, expirationText } = messageActions || {};

  React.useEffect(() => {
    if (expiration && moment(expiration).isBefore(moment())) {
      setIsExpired(true);
    }
  }, [expiration]);

  React.useEffect(() => {
    const completedAction = find(actions, (action) => message.actions?.completed?.[action.key]);
    if (completedAction) {
      setIsComplete(true);
      setCompletionText(completedAction.completionText);
    }
  }, [!!message?.actions?.completed]);

  const markMessageCompleted = (
    providedMessage: PubNubMessage,
    key: string,
    successCallback?: () => any,
    failureCallback?: () => any,
  ) => {
    pubnub.addMessageAction(
      {
        channel: providedMessage.channel,
        messageTimetoken: providedMessage.timetoken.toString(),
        action: {
          type: "completed",
          value: key,
        },
      },
      function (status) {
        if (!status.error) {
          successCallback && successCallback();
        } else {
          failureCallback && failureCallback();
        }
      },
    );
  };

  const clickHandler = ({ completionCallback, key, url, payload }: MessageActionCompletion) => {
    let promiseToReturn;
    if (!isExpired) {
      if (completionCallback) {
        if (payload && messageActions?.consult_id) {
          payload["consultId"] = messageActions.consult_id;
        }
        promiseToReturn = _POST(`${completionCallback}/${patient.id}`, payload);
      }

      localStorage.setItem(
        "messageAction",
        JSON.stringify({
          consult_id: messageActions?.consult_id,
          channelId: message?.channel,
          timetoken: message.timetoken,
          value: key,
          type: "completed",
        }),
      );

      if (url) {
        history.push(url);
      }
    }
    return promiseToReturn || Promise.resolve();
  };
  const buttonStyle = (secondary = false) => (secondary ? secondaryProps : {});
  const messageStillActive = !isComplete && !isExpired;

  return (
    <div>
      <div className="p-4 w-full">
        <>
          {map(actions, (action) => {
            if (action.component) {
              return getComponentForMessageAction(action.component, {
                action,
                message,
                clickHandler,
                buttonStyle,
                markMessageCompleted,
                completed: message?.actions?.completed,
                isExpired,
                expirationText,
                completionText,
              });
            } else if (messageStillActive) {
              return (
                <>
                  {(action.paragraphText || action.paragraphHeading) && (
                    <>
                      <div className="mb-5">{action.paragraphHeading}</div>
                      <div className="mb-5">{action.paragraphText}</div>
                    </>
                  )}
                  <Button
                    onClick={() => clickHandler(action)}
                    {...buttonStyle(action.secondary)}
                    key={action.key}
                  >
                    {action.text}
                  </Button>
                </>
              );
            } else {
              return (
                <div className="font-bold mb-6">{isComplete ? completionText : expirationText}</div>
              );
            }
          })}
        </>
      </div>
    </div>
  );
};
