/** @format */

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React, { useState } from "react";

import { BasePage } from "src/components/BasePage";
import { ErrorBoundary } from "src/components/ErrorBoundary";
import config from "src/config";
import { useStoreState } from "src/v2/models";
import { AuthWallLogin } from "src/v2/routes/AuthWall";

const clearQueryCache = (client: QueryClient) => {
  console.log("Clear query cache");

  client.invalidateQueries();

  client.clear();
};

interface AuthenticateProps {
  children: React.ReactNode;
}

export const Authenticate = ({ children }: AuthenticateProps) => {
  const [queryClient] = useState(() => new QueryClient());
  const { authenticated, authenticatedUser } = useStoreState((state) => state.auth);

  // Ensure that the in-memory data cache from TanStack Query is reset whenver <Authenticate />
  // is unmounted (e.g. during logout). Also clear the cache if the authenticated user changes so that
  // data is never shared accross users on the same device.
  React.useEffect(() => {
    return () => {
      clearQueryCache(queryClient);
    };
  }, [queryClient, authenticatedUser]);

  return authenticated ? (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <BasePage>{children}</BasePage>
        {config.featureFlags.enableReactQueryDevTools && (
          <ReactQueryDevtools initialIsOpen={false} />
        )}
      </QueryClientProvider>
    </ErrorBoundary>
  ) : (
    <AuthWallLogin />
  );
};
