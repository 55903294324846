/**
 * Replaces the contents of static string with dynamic text.
 * the input string should use the pattern of {{valueToReplace}}
 * where valueToReplace is a key in the second argument object
 *
 * @format
 * @param {string} inputText
 * @param replacers { [key: string]: string } with the key being the value to replace
 */

export const formatText = (inputText: string, replacers: { [key: string]: string }): string => {
  const matches = inputText.match(/{{([a-zA-Z]*)}}/gm);
  if (!matches || !matches.length) {
    console.warn("no items to replace");
    return inputText;
  }

  return matches.reduce((str, next) => {
    const key = next.slice(2, -2);
    return str.replace(next, replacers[key] || "");
  }, inputText);
};
