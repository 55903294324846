/** @format */

import React from "react";

import type { BaseFieldProps } from "@alphamedical/components";

import { CounterField, NumberFieldNew } from "@alphamedical/components";

import type { DynamicNumberField as IDynamicNumberField } from "src/components/DynamicForm/types";

import { FieldWeight } from "src/v2/components/Page/fields/field-weight";

import type { DynamicFieldProps } from "./index";

type DynamicNumberField = Omit<DynamicFieldProps, "field"> &
  Partial<Omit<BaseFieldProps, "type">> & {
    field: IDynamicNumberField;
  };

export const DynamicNumberField = ({ field, ...props }: DynamicNumberField) => {
  if (field.subtype === "counter") {
    return (
      <CounterField
        name={field.key}
        min={field.min}
        max={field.max}
        units={field.units}
        {...props}
        className={"pt-6 pb-10"}
      />
    );
  }

  if (field.subtype === "weight") {
    const filteredProps = { ...props };
    delete filteredProps.placeholder;
    return <FieldWeight field={field} {...filteredProps}></FieldWeight>;
  }

  return (
    <NumberFieldNew
      className="flex-grow"
      name={field.key}
      units={field.units}
      label={field.label}
      placeholder={field.placeholder}
      min={field.min}
      max={field.max}
      defaultValue={field.defaultValue}
      decimalPlaces={field.decimalNumber}
      {...props}
    />
  );
};
