/** @format */

import React from "react";

import type { AccountLinksProps } from "src/routes/YourAccount/types";

import { INSURANCE_INFORMATION, INVOICES, MEMBERSHIP_DETAILS, PAYMENT_METHODS } from "src/routes";
import AccountListItemPage from "src/routes/YourAccount/AccountListItemPage";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";

const MembershipAndPayment = () => {
  const accountLinks: AccountLinksProps[] = [
    {
      title: "Plan details",
      url: MEMBERSHIP_DETAILS,
    },
    {
      title: "Insurance cards",
      url: INSURANCE_INFORMATION,
    },
    {
      title: "Payment methods",
      url: PAYMENT_METHODS,
    },
    {
      title: "Invoices",
      url: INVOICES,
    },
  ];

  return <AccountListItemPage title="Plan & Payment" accountLinks={accountLinks} />;
};

export default WithTopBarNavigation(MembershipAndPayment);
