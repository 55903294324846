/** @format */

import _ from "lodash";
import moment from "moment";

import type { Consultation } from "src/v2/types/consultation";

export const formatPrice = (costInCents: number, precision = 2, isCopay?: boolean) => {
  const amount = Math.abs(costInCents / 100).toFixed(precision);
  return costInCents >= 0 ? `$${amount}${isCopay ? " (copay)" : ""}` : `- $${amount}`;
};

export const formatMonthCount = (count: number) => {
  return `${count} month${count > 1 ? "s" : ""}`;
};

export const isVisitConsultation = (consult: Consultation) => {
  return consult.consult_type === "VISIT";
};

export const feeInfoLinkText = (consult: Consultation, condition: string) => {
  const mentalHealth = condition === "mental-health";
  const returnConsult = consult.consult_type === "RETURN_CONSULT";
  const paymentPlan = _.get(consult.answers, "payment-plan.value");

  if (mentalHealth) {
    if (returnConsult) {
      return "Mental Health Follow-Up Visit";
    } else {
      return paymentPlan === "yearly"
        ? "Annual Mental Health Services fee"
        : "Quarterly Mental Health Services fee";
    }
  }
  return "consultation fee";
};

export const feeInfoDetails = (consult: Consultation, condition: string, expires?: string) => {
  const mentalHealth = condition === "mental-health";
  const returnConsult = consult.consult_type === "RETURN_CONSULT";

  if (mentalHealth && !returnConsult) {
    return {
      title: "Why a flat fee?",
      text: `Because care for mental health conditions is a long term,
        on-going treatment that requires more frequent interactions
        with your medical provider, we charge an annual (or quarterly)
        flat fee for the comprehensive service.`,
    };
  }

  if (mentalHealth && returnConsult) {
    return {
      title: "Mental Health Follow-Up Visit",
      text: `Follow-up visits are included in your
              Annual Mental Health Service so no additional
              fees are due today.${
                (expires &&
                  ` Your Annual Mental Health
              Service expires on ` +
                    moment(expires).format("ll") +
                    ".") ||
                ""
              }`,
    };
  }

  return {};
};
