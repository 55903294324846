/** @format */

import React from "react";

import welcome from "src/content/welcome";

interface NameIntroProps {
  onChange: (value: string) => void;
  defaultValue: string;
}

export const NameIntro = ({ onChange, defaultValue }: NameIntroProps) => {
  return (
    <div className="flex flex-1 flex-col justify-between items-center mb-16">
      <div>
        <div className="mt-24">
          <h1 className="text-xl mb-2 text-center text-primary">{welcome.screen2.title}</h1>
        </div>
        <div className="mt-16 flex flex-row justify-center w-fill px-8 border-b border-grey-light">
          <input
            className="p-4"
            type="text"
            id="preferred_firstname"
            name="preferred_firstname"
            onChange={(e) => {
              onChange(e.target.value);
            }}
            defaultValue={defaultValue}
            placeholder={welcome.screen2.nameEntryPlaceholder}
          />
        </div>
      </div>
      <div>
        <div className="mb-4 flex flex-row justify-center">
          {[
            { icon: "people/woman-two", alt: "illustration of a patient" },
            { icon: "people/woman-one", alt: "illustration of a patient" },
            { icon: "people/woman-three", alt: "illustration of a patient" },
          ].map((item) => (
            <img
              key={item.icon}
              src={`/assets/${item.icon}.svg`}
              alt={item.alt}
              className="welcome__person-icon-small"
            />
          ))}
        </div>
        <div className="w-full flex flex-row justify-center">
          <p className="text-base ml-4 text-primary w-5/6 text-center">
            {welcome.screen2.footerNote}
          </p>
        </div>
      </div>
    </div>
  );
};
