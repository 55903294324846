/** @format */
import { isEmpty, isFunction } from "lodash";

const getEnvVar = (name: string): string | undefined => {
  return import.meta.env[name];
};

const ENV_KEY = getEnvVar("REACT_APP_ENV") || "development";

type DefaultValue = string | ((env: string) => string);

// Returns a config value from environment variables stored in process based on a given name.
// If a variable named REACT_APP_<name> is not set, then fallback to an env variable based on the
// environment (REACT_APP_<name>_<env>). If neither env variable is set, then return the given default
// value or undefined if not given.
function getConfigValue(name: string): string | undefined;
function getConfigValue(name: string, defaultValue: DefaultValue): string;
function getConfigValue(name: string, defaultValue?: DefaultValue) {
  let value = getEnvVar(`REACT_APP_${name}`);
  if (value != null && !isEmpty(value)) {
    return value;
  }

  // Determine what suffix (DEV, PROD, STAGING), should be used
  const envSuffix =
    {
      development: "DEV",
      production: "PROD",
      "build:production": "PROD",
      "build:staging": "STAGING",
    }[ENV_KEY] || ENV_KEY.toUpperCase();

  value = getEnvVar(`REACT_APP_${name}_${envSuffix}`);
  if (value != null && !isEmpty(value)) {
    return value;
  }
  if (isFunction(defaultValue)) {
    return defaultValue(envSuffix);
  }
  return defaultValue;
}

const getConfigBooleanValue = (name: string, defaultValue?: boolean) => {
  const stringValue = getConfigValue(name, defaultValue ? "1" : "0");
  return ["1", "true"].indexOf(stringValue.toLowerCase()) !== -1;
};

const getConfigListValue = (name: string, defaultValue?: string) => {
  const stringValue = getConfigValue(name, defaultValue || "");
  const normalizedText = stringValue.replace(/[, ]+/g, ",");
  return normalizedText.split(",");
};

const config = {
  // General
  isStaticBuild: getEnvVar("NODE_ENV") === "production",
  isProd: ENV_KEY === "build:production" || ENV_KEY === "production",
  apiUrl: getConfigValue("API_URL", "http://localhost:7777"),
  wwwUrl: getConfigValue("WWW_URL", "https://www-wip.webflow.io"),
  assetsUrl: getConfigValue("ASSETS_URL", "https://assets.helloalpha.com"),
  version: getConfigValue("VERSION"),

  // Integrations
  pubnub: {
    publishKey: getConfigValue("PUBNUB_PUBLISH_KEY"),
    subscribeKey: getConfigValue("PUBNUB_SUBSCRIBE_KEY"),
    requireValidAuthKey: getConfigBooleanValue("PUBNUB_REQUIRE_VALID_AUTH_KEY"),
  },
  recurly: {
    publicKey: getConfigValue("RECURLY_PUBLIC_KEY"),
  },
  braze: {
    apiKey: getConfigValue("BRAZE_API_KEY", "d12cefb9-398b-401b-b616-ec6f1495cc04"),
    sdkEndpoint: getConfigValue("BRAZE_SDK_ENDPOINT", "sdk.iad-02.braze.com"),
  },
  googleAPIKey: getConfigValue("GOOGLE_API_KEY"),
  sentry: {
    env: getConfigValue("SENTRY_ENV", (env) => {
      if (env === "DEV") {
        return "development";
      }
      return { PROD: "production" }[env] || env.toLowerCase();
    }),
    dsn: getConfigValue("SENTRY_DSN"),
  },

  tracking: {
    piwikContainerId: getConfigValue("PIWIK_CONTAINER_ID"),
    piwikContainerUrl: getConfigValue("PIWIK_CONTAINER_URL"),
  },

  // Settings
  maintenanceFilename: getConfigValue("MAINTENANCE_FILENAME", (env) => {
    // If not set, the maintenance file is just maintenance.json in prod, or maintenance.<env>.json
    return env === "PROD" ? "maintenance.json" : `maintenance.${env.toLowerCase()}.json`;
  }),
  faqHelpCenterUrl: getConfigValue("FAQ_HELP_CENTER_URL"),
  genericBannerMessage: getConfigValue("GENERIC_BANNER_MESSAGE"),
  responseTime: getConfigValue("RESPONSE_TIME", "24 hours"),
  urgentResponseTime: getConfigValue("URGENT_RESPONSE_TIME", "24 hours"),
  responseTimeMessage: getConfigValue("RESPONSE_TIME_MESSAGE"),

  // Feature Flags
  featureFlags: {
    displayHighVolumeWarning:
      new Date() < new Date(getConfigValue("CLINICIAN_DELAY_BANNER_EXP", "08/16/2021")),
    disabledValidators: getConfigListValue("DISABLED_VALIDATORS"),
    enableHierarchicalDropdowns: getConfigBooleanValue("ENABLE_HIERARCHICAL_DROPDOWNS", true),
    enableHelpPage: getConfigBooleanValue("ENABLE_HELP_PAGE", true),
    enableMonthlyMembership: getConfigBooleanValue("ENABLE_MONTHLY_MEMBERSHIP", true),
    disableTP: getConfigBooleanValue("DISABLE_TP", true),
    enableWD: getConfigBooleanValue("ENABLE_WD", true),
    enablePharmacySearch: getConfigBooleanValue("ENABLE_PHARMACY_SEARCH", true),
    enableBenefitHolderBehavior: getConfigBooleanValue("ENABLE_BENEFIT_HOLDER_BEHAVIOR"),
    enabledSynchronousStates: getConfigListValue("ENABLED_SYNCHRONOUS_STATES", "IN,NM"),
    enableMessageWebhooks: getConfigBooleanValue("ENABLE_MESSAGE_WEBHOOKS"),
    enableWeightLossJournalPaywall: getConfigBooleanValue("ENABLE_WEIGHT_LOSS_JOURNAL_PAYWALL"),
    wellnessWorkshop: false,
    enableReactQueryDevTools: getConfigBooleanValue("ENABLE_REACT_QUERY_DEV_TOOLS"),
  },
};

export type FeatureFlag = keyof typeof config.featureFlags;

export default config;
