/** @format */

import React from "react";

import { useAuthenticatedUser } from "src/utils/hooks";
import { useStoreState } from "src/v2/models";

import type { AnchorItem } from "./navLinkValues";

import NavLink from "./NavLink";

export interface NavGroupProps {
  anchor: AnchorItem[];
  textColor: string;
  listStyle: string;
  tabIndex?: number;
}

const NavGroup = ({ anchor, textColor, listStyle, tabIndex }: NavGroupProps) => {
  const membership = useStoreState((state) => state.membership.membership);
  const [, isAuthenticatedUser] = useAuthenticatedUser();
  const activeMembership = membership && membership.active === true;

  const processedAnchors =
    anchor &&
    anchor
      .map((link) => {
        if (link.anchorURL && link.anchorURL.includes("https://shop.helloalpha.com/")) {
          return {
            ...link,
            anchorURL: activeMembership ? `${link.anchorURL}discount/skincare20` : link.anchorURL,
          };
        }
        return link;
      })
      .filter((link) => {
        if (link.onlyAuthenticated === true) {
          return isAuthenticatedUser;
        } else {
          return true;
        }
      });

  return (
    <>
      {processedAnchors.map((link: AnchorItem) => {
        const href = (link.anchorURL ? link.anchorURL : "/" + link.internalEntry) || "";
        return (
          <li className={listStyle} key={link.uid}>
            <div className="flex justify-between items-center">
              <NavLink
                anchorText={link.anchorText}
                href={href}
                className={`text-${textColor} no-underline text-lg md:text-xl leading-snug`}
                tabIndex={tabIndex}
              />
            </div>
          </li>
        );
      })}
    </>
  );
};

export default NavGroup;
