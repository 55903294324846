/** @format */

import * as Sentry from "@sentry/react";
import type { Action, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";

import type { PcpCreatePcpRequestBodySchema, PcpUpdatePcpRequestBodySchema } from "src/api";

import { UsersService } from "src/api";
import { _GET, _POST, _PUT, getTokenHeaderV2 } from "src/helpers/http";

import type { CreateModel } from "./_create";

interface EditPrimaryCareProvider {
  first_name: string;
  last_name: string;
  phone_number: string;
  authorized_to_contact?: boolean;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
}

interface PrimaryCareProvider extends EditPrimaryCareProvider {
  id: number;
  user_id: number;
}

export interface PrimaryCareProviderModel {
  pcp?: PrimaryCareProvider;

  setPCP: Action<PrimaryCareProviderModel, PrimaryCareProvider>;

  createPCP: Thunk<PrimaryCareProviderModel, PcpCreatePcpRequestBodySchema>;

  updatePCP: Thunk<PrimaryCareProviderModel, PcpUpdatePcpRequestBodySchema>;

  createOrUpdatePCP: Thunk<PrimaryCareProviderModel, EditPrimaryCareProvider>;

  fetchPCP: Thunk<PrimaryCareProviderModel>;
}

export const primaryCareProviderStore: PrimaryCareProviderModel = {
  setPCP: action((state, provider) => {
    state.pcp = provider;
  }),

  createPCP: thunk(async (actions, payload, { getStoreState }) => {
    UsersService.createPcp({
      userId: "me",
      requestBody: {
        ...payload,
      },
    })
      .then((res) => {
        actions.setPCP(res);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  }),

  updatePCP: thunk(async (actions, payload, { getStoreState }) => {
    UsersService.updatePcp({
      userId: "me",
      requestBody: {
        ...payload,
      },
    })
      .then((res) => {
        actions.setPCP(res);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  }),

  createOrUpdatePCP: thunk(async (actions, payload, { getState, getStoreState }) => {
    const { pcp } = getState();
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;
    const request = pcp
      ? _PUT(`/users/${currentProfileId}/pcp`, payload, getTokenHeaderV2())
      : _POST(`/users/${currentProfileId}/pcp`, payload, getTokenHeaderV2());

    return await request.then((response) => {
      actions.setPCP(response);
    });
  }),

  fetchPCP: thunk(async (actions, __, { getStoreState }) => {
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;
    await _GET(`/users/${currentProfileId}/pcp`, {}, getTokenHeaderV2()).then((response) => {
      actions.setPCP(response);
    });
  }),
};
