/** @format */

import React from "react";
import { Form } from "react-final-form";

import { ButtonPdb, Paper } from "src/v2/designSystem";

import { DynamicField } from "src/components/DynamicForm";
import { DynamicFileFieldComponent } from "src/components/DynamicForm/Fields/DynamicFileField";
import { PageWrapper } from "src/components/PageWrapper";
import { serializeDependency } from "src/utils";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useStoreActions, useStoreDispatch, useStoreState } from "src/v2/models";

interface ValueProps {
  license_photo?: string;
  license_type?: string;
  license_expiration?: string;
}

const PhotoID = () => {
  useTopBar({ title: "Photo ID", variant: "nested", showBackButton: true });
  const dispatch = useStoreDispatch();
  const { profile } = useStoreState((state) => state.profile);
  const { addSnack } = useStoreActions((actions) => actions.snacks);
  const [values, setValues] = React.useState<ValueProps>({});

  React.useEffect(() => {
    const newValues: ValueProps = {};
    if (profile && profile.pii.license_photo) {
      newValues.license_photo = profile.pii.license_photo;
    }
    if (profile && profile.pii.license_type) {
      newValues.license_type = profile.pii.license_type;
    }
    if (profile && profile.pii.license_expiration) {
      newValues.license_expiration = profile.pii.license_expiration;
    }
    setValues(newValues);
  }, [serializeDependency(profile?.pii)]);

  const saveValues = (values: any) => {
    return dispatch.profile.updatePii(values);
  };

  const onSubmit = (_values: any) => {
    const valuesToSubmit = {
      license_expiration: _values.license_expiration,
      license_type: _values.license_type,
      ...(_values.license_photo !== values.license_photo && {
        license_photo: _values.license_photo,
      }),
    };
    return saveValues(valuesToSubmit)
      .then(() => {
        addSnack({
          type: "success",
          message: "Photo ID Saved Successfully!",
          id: "photo-id-update",
          delay: 5,
        });
        dispatch.actionItems.fetchActionItems();
        return true;
      })
      .catch((e: any) => {
        addSnack({
          type: "error",
          message: "There was an error updating your Photo ID",
          id: "photo-id-update",
          delay: 5,
        });
        return e;
      });
  };

  // TODO this should come from the backend
  const driverLicenseType = [
    {
      contentType: "option",
      label: "Drivers License",
      userFriendlyName: "Drivers License Option",
      value: "DRIVERS_LICENSE",
    },
    {
      contentType: "option",
      label: "State Identity Card",
      userFriendlyName: "State Identity Card Option",
      value: "STATE_IDENTITY_CARD",
    },
    {
      contentType: "option",
      label: "Passport",
      userFriendlyName: "Passport Option",
      value: "PASSPORT",
    },
    {
      contentType: "option",
      label: "Passport Card",
      userFriendlyName: "Passport Card Option",
      value: "PASSPORT_CARD",
    },
    {
      contentType: "option",
      label: "Permanent Resident Card",
      userFriendlyName: "Permanent Resident Card Option",
      value: "PERMANENT_RESIDENT_CARD",
    },
  ];

  return (
    <PageWrapper cxPadding="px-0">
      <Paper elevation={0} rounded={false} className="flex-1 mx-auto w-full max-w-2xl px-6 mb-8">
        <Form onSubmit={onSubmit} initialValues={values}>
          {(formProps) => {
            const { handleSubmit, submitting } = formProps;
            return (
              <form onSubmit={handleSubmit} className="flex flex-row mt-2 mb-16">
                <div className="flex-1">
                  <DynamicField
                    field={{
                      category: "pii",
                      key: "license_type",
                      label: "Government-issued ID",
                      required: true,
                      subtype: "dropdown",
                      title: "Government-issued ID",
                      type: "select",
                      options: driverLicenseType,
                    }}
                    className="mt-2"
                  />
                  <DynamicField
                    field={{
                      category: "pii",
                      key: "license_expiration",
                      label: "Expiration date",
                      required: true,
                      subtype: "date",
                      title: "",
                      type: "text",
                      validate: "isValidExpirationDate",
                    }}
                    className="mt-2"
                  />
                  <DynamicFileFieldComponent
                    version={4}
                    field={{
                      key: "license_photo",
                      accepts: "image/*",
                      imageUrl: "/assets/file_upload_fields/DL-Front.png",
                      message:
                        "Place on a flat surface with the front side up then tap here to take a photo",
                      type: "file",
                      title: "ADD PHOTO ID",
                    }}
                  />
                  <ButtonPdb type={"submit"} disabled={submitting} className="mt-4 w-full">
                    Save changes
                  </ButtonPdb>
                </div>
              </form>
            );
          }}
        </Form>
      </Paper>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(PhotoID);
