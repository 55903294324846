/** @format */

import React from "react";
import { Form } from "react-final-form";
import { Link } from "react-router-dom";

import * as Sentry from "@sentry/react";
import moment from "moment";

import type { Subscription } from "src/api";

import { SubscriptionsService } from "src/api";
import { Loader } from "src/components/Loader";
import { dayDiff } from "src/utils/date_utils";
import { AlphaModal } from "src/v2/components/AlphaModal";
import { FieldSelect } from "src/v2/components/Page/fields/field-select";

interface ModifyTreatmentModalProps {
  showModal: boolean;
  closeModal: () => void;
  isSkippingShipment: boolean;
  subscription: Subscription;
}

const ModifyTreatmentModal: React.FC<ModifyTreatmentModalProps> = ({
  showModal,
  closeModal,
  isSkippingShipment,
  subscription,
}) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [selectedFrequency, setSelectedFrequency] = React.useState<number>(0);
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [skipConfirmation, setSkipConfirmation] = React.useState(false);

  React.useEffect(() => {
    if (showModal && subscription) {
      const { sku } = subscription;
      setIsLoading(false);
      setSelectedFrequency(subscription.refill_frequency || sku.days_supply);
      setShowConfirmation(isSkippingShipment);
      setSkipConfirmation(isSkippingShipment);
    }
  }, [showModal, subscription?.id, isSkippingShipment]);

  const onClose = () => {
    closeModal();
    setShowConfirmation(false);
    setSkipConfirmation(false);
  };

  const skipShipment = async () => {
    try {
      if (subscription.id) {
        SubscriptionsService.skipNextRefill({
          userId: "me",
          subscriptionId: `${subscription.id}`,
          requestBody: {
            skip_next_refill: true,
          },
        });
        setShowConfirmation(false);
        closeModal();
      }
    } catch (exception) {
      Sentry.captureException(exception);
    }
  };

  const updateSubscription = async (values: { refill_frequency?: number }) => {
    if (skipConfirmation) {
      skipShipment();
    } else {
      try {
        if (subscription.id) {
          if (typeof values.refill_frequency === "string") {
            values = {
              ...values,
              refill_frequency: parseInt(values.refill_frequency, 10),
            };
          }
          await SubscriptionsService.updateSubscription({
            userId: "me",
            subscriptionId: `${subscription.id}`,
            requestBody: values,
          });
          closeModal();
        }
      } catch (exception) {
        Sentry.captureException(exception);
      }
    }
  };

  const toggleConfirmation = (e: any, values: any, skip = false) => {
    e.preventDefault();
    setShowConfirmation(true);
    setSelectedFrequency(values.refill_frequency);
    setSkipConfirmation(skip);
  };

  const diff = dayDiff(new Date(), new Date(subscription.next_refill || ""));
  const tooLateToModify = diff === 0;
  const sku = subscription?.sku || {};
  const refillFrequency = subscription.refill_frequency || sku.days_supply;
  return (
    <>
      {showModal && (
        <AlphaModal
          isOpen={showModal}
          onRequestClose={onClose}
          className="max-w-sm m-auto bg-white shadow-lg p-6 z-20"
        >
          <div className="text-2xl font-medium mb-4">Modify Subscription</div>
          {skipConfirmation && showConfirmation && (
            <div className="bg-sand-60 py-3 px-4 mb-2 font-semibold">
              Your next shipment will be skipped
            </div>
          )}
          <div className="mb-6">
            <h5 className="text-base font-bold mb-0">SUBSCRIPTION</h5>
            <p className="text-2xl my-0 -mt-2">{sku ? sku.name : ""}</p>
          </div>
          <div className="mb-6">
            <h5 className="text-base font-bold mb-0">ORDER FREQUENCY</h5>
            <Loader show={isLoading} hideMessage={true}>
              {(!!subscription && (
                <>
                  <Form
                    onSubmit={updateSubscription}
                    initialValues={{
                      refill_frequency: refillFrequency,
                    }}
                  >
                    {({ handleSubmit, pristine, submitting, values }) => {
                      return (
                        <form onSubmit={handleSubmit}>
                          {(!showConfirmation && (
                            <div>
                              {sku.allowed_frequencies ? (
                                <div className="pb-4 -mt-2">
                                  <FieldSelect
                                    isDisabled={tooLateToModify}
                                    parse={(value: string | undefined) =>
                                      value && parseInt(value, 10)
                                    }
                                    field={{
                                      key: "refill_frequency",
                                      label: "Refill Frequency",
                                      footer: `
                                  For Latisse, the recommended order frequency is every
                                  ${sku.days_supply} days.
                                `,
                                      category: "subscription",
                                      type: "radio",
                                      defaultValue: sku.days_supply,
                                      options: sku.allowed_frequencies.map((frequency: number) => {
                                        return {
                                          value: frequency,
                                          label: `Send my refills every ${frequency} days`,
                                        };
                                      }),
                                    }}
                                    extraClassName={`
                                    bg-grey-1 text-primary border-none py-4
                                    ${tooLateToModify ? "text-secondary-60" : ""}
                                  `}
                                  />
                                </div>
                              ) : (
                                <div className="text-2xl">Every {refillFrequency} days</div>
                              )}
                              {(!tooLateToModify && (
                                <>
                                  {sku.allowed_frequencies && (
                                    <button
                                      onClick={(e: any) => toggleConfirmation(e, values)}
                                      className="btn btn-violator w-full"
                                      disabled={pristine || submitting}
                                    >
                                      {submitting ? "Updating..." : "Update"}
                                    </button>
                                  )}
                                  <button
                                    type="button"
                                    className="w-full text-primary underline font-semibold pt-4"
                                    onClick={(e: any) => toggleConfirmation(e, values, true)}
                                  >
                                    Skip my next shipment
                                  </button>
                                </>
                              )) || (
                                <>
                                  <div className="bg-sand-60 py-2 px-4 mb-12 font-semibold">
                                    Subscriptions cannot be changed less than 24 hours before your
                                    next shipment
                                  </div>
                                  <button
                                    type="button"
                                    className="btn btn-primary w-full"
                                    onClick={closeModal}
                                  >
                                    Back to Dashboard
                                  </button>
                                  <Link
                                    to="/contact"
                                    className="w-full block text-center text-primary underline font-semibold pt-4"
                                  >
                                    Contact us for assistance
                                  </Link>
                                </>
                              )}
                            </div>
                          )) ||
                            ((showConfirmation || isSkippingShipment) && (
                              <div>
                                <p className="text-base mt-2 mb-6">
                                  {skipConfirmation
                                    ? `Send my refills every
                                      ${subscription.refill_frequency || sku.days_supply}
                                    days`
                                    : `Send my refills every ${selectedFrequency} days`}
                                </p>
                                <div className="mb-10">
                                  <h5 className="text-base font-bold mb-0">NEXT REFILL DATE</h5>
                                  <p className="text-base mb-0 -mt-2">
                                    {skipConfirmation
                                      ? `${moment(subscription.next_refill)
                                          .add(
                                            subscription.refill_frequency || sku.days_supply,
                                            "days",
                                          )
                                          .format("LL")}`
                                      : `${moment(subscription.next_refill)
                                          .add(
                                            selectedFrequency && subscription?.refill_frequency
                                              ? selectedFrequency - subscription.refill_frequency
                                              : 0,
                                            "days",
                                          )
                                          .format("LL")}`}
                                  </p>
                                </div>
                                <div className="flex">
                                  {!isSkippingShipment && (
                                    <div
                                      className="
                                    mr-2 flex-1 btn outline-primary cursor-pointer outline-primary
                                    border-2 border-primary"
                                      onClick={() => {
                                        setShowConfirmation(false);
                                        setSkipConfirmation(false);
                                      }}
                                    >
                                      Cancel
                                    </div>
                                  )}
                                  <button
                                    className={`${isSkippingShipment ? "" : `ml-2`}
                                    flex-1 btn btn-violator w-full`}
                                  >
                                    Confirm
                                  </button>
                                </div>
                              </div>
                            ))}
                        </form>
                      );
                    }}
                  </Form>
                </>
              )) || (
                <>
                  <div className="text-lg mb-10">Updating this subscription is coming soon!</div>
                  <button className="btn btn-primary w-full" onClick={closeModal}>
                    Close
                  </button>
                </>
              )}
            </Loader>
          </div>
        </AlphaModal>
      )}
    </>
  );
};

export default ModifyTreatmentModal;
