/** @format */

import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Typography } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import { ActivateBenefitsModalContent } from "src/content/v2/components/ActivateBenefits/activateBenefitsModal";
import { FullPageModal, IconTextChevron, Line } from "src/v2/components";
import { Button } from "src/v2/components/Button";
import { MarkdownText } from "src/v2/components/MarkdownText";
import { useStoreDispatch, useStoreState } from "src/v2/models";

interface ActivateBenefitsModalProps {
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
}

export const ActivateBenefitsModal: React.FC<ActivateBenefitsModalProps> = ({
  modalOpen,
  setModalOpen,
}) => {
  const content = ActivateBenefitsModalContent;
  const dispatch = useStoreDispatch();
  const { profile } = useStoreState((state) => state.profile);
  const activeBenefits =
    profile?.employer_benefits?.length && profile?.employer_benefits.some((e) => e.active === true);
  const [status, setStatus] = useState(activeBenefits ? "active-benefits" : "inactive-benefits");
  const history = useHistory();

  // Used to get status when navigating back from the "help" step
  const getStatus = () => {
    setStatus(activeBenefits ? "active-benefits" : "inactive-benefits");
  };

  const title = status === "help" ? content.helpPage.modalTitle : content.modalTitle;

  React.useEffect(() => {
    dispatch.profile.fetchEmployerBenefits().then(() => {
      getStatus();
    });
  }, [profile?.employer_benefits?.length]);

  return (
    <FullPageModal
      isOpen={modalOpen}
      setIsOpen={() => {
        if (status === "help") {
          getStatus();
        } else {
          setModalOpen(false);
        }
      }}
      title={title}
    >
      <div className="text-forest-120 mx-auto max-w-lg px-5 flex flex-col mt-6 flex-1">
        {status === "help" ? (
          <div className="mx-6 flex flex-col justify-center">
            <p className="text-xl mt-12 mb-6">{content.helpPage.title}</p>
            <p className="mb-6">{content.helpPage.body}</p>
            <IconTextChevron
              planAction="N/A"
              icon={content.helpPage.action.icon}
              iconAlt={content.helpPage.action.alt}
              text={
                <MarkdownText
                  text={content.helpPage.action.text}
                  linkStyle={{ color: "#6271C2", fontWeight: "bold" }}
                />
              }
            />
            <Line />
            <Button
              onClick={() => getStatus()}
              extraClassNames="mt-10 rounded"
              bgColor="white"
              textColor="forest-100"
              borderColor="forest-100"
            >
              {content.helpPage.cta.text}
            </Button>
          </div>
        ) : status === "active-benefits" ? (
          <>
            <div className="py-6">
              <h3 className="mb-8 w-full text-xl">{content.active_benefits.title}</h3>
              <p>{content.active_benefits.body}</p>
            </div>
            <>
              <Button
                href={content.active_benefits.link.to}
                bgColor="primary"
                hrefStyles="w-full flex justify-center"
                extraClassNames="rounded"
              >
                {content.active_benefits.link.text}
              </Button>
            </>
          </>
        ) : (
          <>
            <Typography className="mt-8 mb-6" variant="body1">
              {content.inactive_benefits.body[0]}
            </Typography>
            <>
              <ButtonPdb
                onClick={() => {
                  if (content.inactive_benefits.link[0].to)
                    history.push(content.inactive_benefits.link[0].to);
                }}
                className="w-full flex justify-center"
              >
                {content.inactive_benefits.link[0].text}
              </ButtonPdb>
            </>
            <Typography variant="body1" className="mt-10">
              {content.inactive_benefits.body[1]}{" "}
              <a
                className="text-cornflower-100 font-medium"
                href={content.inactive_benefits.link[1].to}
              >
                {content.inactive_benefits.link[1].text}
              </a>
              {"."}
            </Typography>
          </>
        )}
      </div>
    </FullPageModal>
  );
};
