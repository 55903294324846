/** @format */

import type React from "react";

import { useHistory } from "react-router-dom";

import classNames from "classnames";

import { Typography } from "@alphamedical/components";

import { MEMBERSHIP_DETAILS } from "src/routes";
import { REQUIRED_PLUS_MEMBERSHIP_CONDITIONS } from "src/v2/constants";
import { useStoreState } from "src/v2/models";

import VisitLockedTooltip from "./VisitLockedTooltip";

export interface VisitCardProps {
  idx: number;
  title: string;
  description?: string;
  imageUrl?: string;
  onClick: (event: React.MouseEvent | React.KeyboardEvent) => void;
  condition?: string;
}

const VisitCard: React.FunctionComponent<VisitCardProps> = ({
  idx,
  title,
  imageUrl,
  onClick,
  description,
  condition,
}) => {
  const membership = useStoreState((state) => state.membership.membership);

  const isActivePlusMember = membership?.plan_code === "alpha-membership-plus" && membership.active;
  const conditionIsLocked =
    !isActivePlusMember && REQUIRED_PLUS_MEMBERSHIP_CONDITIONS.includes(condition || "");
  const conditionCursor = conditionIsLocked ? "cursor-not-allowed" : "cursor-pointer";
  const conditionColor = conditionIsLocked ? "text-grey-160" : "text-forest-120";
  const history = useHistory();

  return (
    <div
      className={classNames(
        "flex",
        "flex-row",
        { "mt-4": idx > 0 },
        "p-4 bg-white hover:shadow-md",
        {
          [conditionCursor]: true,
        },
      )}
      style={{
        boxShadow: "0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.1)",
      }}
      onClick={(e) => !conditionIsLocked && onClick(e)}
    >
      {imageUrl && (
        <div className="w-12 mr-6">
          <img className="object-contain w-full" src={imageUrl} alt={title} />
        </div>
      )}
      <div className="flex-1">
        <div className={"flex justify-between"}>
          <h2
            className={classNames("text-base font-bold mt-0 leading-none", {
              [conditionColor]: true,
            })}
          >
            {title}
          </h2>
          {conditionIsLocked && <VisitLockedTooltip />}
        </div>
        {description && (
          <div className={classNames("mt-3 text-sm", { [conditionColor]: true })}>
            {description}
          </div>
        )}
        {conditionIsLocked && (
          <div className={"cursor-pointer"} onClick={() => history.push(MEMBERSHIP_DETAILS)}>
            <Typography className={"mt-5 text-center"} variant={"hyperlink"}>
              Upgrade to Alpha Plus+ Plan
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default VisitCard;
