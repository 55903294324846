/** @format */

import React from "react";

import { FilterChips, Typography } from "@alphamedical/components";

import type { ReturnConsultTaskSchema, SynchronousVisitSchema } from "src/api";
import type { IncludeOptions } from "src/v2/models/service";
import type { ConsultationWithUrgent } from "src/v2/utils/returnVisitUtils";

import { serializeDependency } from "src/utils";
import { useSynchronousVisits } from "src/utils/hooks/useSynchronousVisits";
import VisitList from "src/v2/components/Visits/VisitList";
import { useStoreDispatch, useStoreState } from "src/v2/models";
import { returnVisitsSelector } from "src/v2/models/action_items";
import SynchronousVisitsList from "src/v2/routes/MyCases/SynchronousVisitsList";
import {
  isUrgentVisit,
  isVisitActive,
  isVisitPast,
  isVisitStarted,
  sortAndGroupVisits,
} from "src/v2/routes/MyCases/utils";
import { getConsultsFromServices, isReturnVisitDue } from "src/v2/utils/returnVisitUtils";

interface CasesProps {
  careType: string;
}

const Cases = ({ careType }: CasesProps) => {
  const dispatch = useStoreDispatch();
  const [visits, setVisits] = React.useState<ConsultationWithUrgent[]>([]);
  const scheduledCalls: SynchronousVisitSchema[] = useSynchronousVisits();

  const { serviceList } = useStoreState((state) => state.service);
  const returnVisits = useStoreState(returnVisitsSelector);

  const [selectedFilter, setSelectedFilter] = React.useState<string>("active");
  const isUrgentCare = careType === "urgent";

  React.useEffect(() => {
    const include: IncludeOptions[] = ["consults"];
    dispatch.service.fetchAllServices({ include });
    dispatch.actionItems.fetchReturnVisitActionItems();
  }, []);

  React.useEffect(() => {
    setSelectedFilter("active");
    if (serviceList && serviceList.length > 0) {
      const serviceVisits = getConsultsFromServices(serviceList);

      const careTypeVisits = serviceVisits.filter((visit) =>
        isUrgentCare ? isUrgentVisit(visit) : !isUrgentVisit(visit),
      );
      setVisits(sortAndGroupVisits(careTypeVisits));
    }
  }, [serializeDependency(serviceList, ["id"]), careType]);

  const filterOptions = [
    {
      key: "active",
      label: "Active",
    },
    {
      key: "started",
      label: "Started",
    },
    ...(!isUrgentCare
      ? [
          {
            key: "due",
            label: "Due",
          },
          {
            key: "upcoming",
            label: "Upcoming",
          },
        ]
      : [
          {
            key: "past",
            label: "Past",
          },
        ]),
  ];

  if (scheduledCalls.length > 0) {
    filterOptions.unshift({ key: "providerCalls", label: "Provider calls" });
  }

  const filterActive = ["active", "started"].includes(selectedFilter);
  let filteredVisits: ConsultationWithUrgent[] | ReturnConsultTaskSchema[];
  if (filterActive) {
    filteredVisits = visits.filter((consult) => {
      switch (selectedFilter) {
        case "active":
          // Don't include past ones if urgent care.
          return isVisitActive(consult) && (!isUrgentCare || !isVisitPast(consult));
        case "started":
          return isVisitStarted(consult);
        default:
          return true;
      }
    });
  } else {
    if (isUrgentCare) {
      filteredVisits = visits.filter((consult) => isVisitActive(consult) && isVisitPast(consult));
    } else {
      filteredVisits = returnVisits.filter((returnVisit) => {
        switch (selectedFilter) {
          case "due":
            return isReturnVisitDue(returnVisit);
          case "upcoming":
            return returnVisit.returnVisitStatus === "upcoming";
          default:
            return true;
        }
      });
    }
  }

  return (
    <div className="border-b border-grey-60 mb-8">
      <Typography className="mb-6" variant="h3">
        My Visits
      </Typography>
      <div className="mb-6">
        <FilterChips
          filterOptions={filterOptions}
          selected={selectedFilter}
          onOptionChange={setSelectedFilter}
        />
      </div>
      <div className="mb-8">
        {selectedFilter === "providerCalls" ? (
          <SynchronousVisitsList scheduledCalls={scheduledCalls} />
        ) : (
          <VisitList
            consults={filteredVisits}
            limitItems={3}
            listType={filterActive || isUrgentCare ? "consultList" : "taskList"}
            linkText={`+${filteredVisits.length - 3} more`}
            startVisitCard={true}
          />
        )}
      </div>
    </div>
  );
};

export default Cases;
