/** @format */

import React from "react";

import type { ButtonVariant } from "src/v2/designSystem/ButtonPdb/ButtonPdb";

import { ButtonPdb } from "src/v2/designSystem/ButtonPdb/ButtonPdb";
import { ProgressBar } from "src/v2/designSystem/ProgressBar/ProgressBar";
import { TopBarNavigation } from "src/v2/designSystem/TopBarNavigation/TopBarNavigation";

import { Loader } from "src/components/Loader";

import type { FunnelPageContent } from "./WeightLossSignUpFunnel";

interface QuizPageVariantProps {
  currentPage: FunnelPageContent;
  onBackCallback: () => void;
  onClickCallback: () => void;
  progress: number[];
  styles?: { [key: string]: string };
}

export const QuizPageVariant = ({
  currentPage,
  onBackCallback,
  onClickCallback,
  progress,
  styles = {},
}: QuizPageVariantProps) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [loadedImage, setLoadedImage] = React.useState("");

  React.useEffect(() => {
    if (currentPage.image) {
      setIsLoading(true);
      const img = new Image();
      img.src = currentPage.image;
      img.onload = () => {
        setLoadedImage(currentPage.image);
        setIsLoading(false);
      };
    } else {
      setIsLoading(false);
    }
  }, [currentPage.image]);

  return isLoading ? (
    <Loader show={true} />
  ) : (
    <>
      <TopBarNavigation
        showBackButton
        onBackCallback={onBackCallback}
        variant="top-level"
        cxContainer="bg-sand-20"
      />
      <ProgressBar
        progress={progress}
        segmented
        key={currentPage.key}
        progressBarColor={styles.progressBarColor}
      />
      <div className="sm:max-w-2xl mx-auto w-full px-6 pb-10 pt-6">
        {currentPage.title && (
          <div className="pb-6">
            <p className="text-xl font-medium text-left">{currentPage.title}</p>
          </div>
        )}
        {loadedImage && (
          <div className="pb-6">
            <img
              src={loadedImage}
              alt={currentPage.imageAlt}
              className="object-cover mx-auto rounded-lg min-h-60"
            />
          </div>
        )}
        {currentPage.mainText && (
          <div className="pb-2">
            <p className={`${currentPage.mainTextStyles || "text-xl font-medium text-left"}`}>
              {currentPage.mainText}
            </p>
          </div>
        )}
        {currentPage.quote && (
          <div className="pb-2">
            <p className="text-base font-normal text-left">{currentPage.quote}</p>
          </div>
        )}
        {currentPage.captionText && (
          <div className="pb-6">
            <p className="text-sm font-normal text-left text-grey-160">{currentPage.captionText}</p>
          </div>
        )}
        <ButtonPdb
          variant={styles.buttonVariant as ButtonVariant}
          className="w-full mb-2"
          onClick={(e) => {
            e.preventDefault();
            onClickCallback();
          }}
        >
          {currentPage.primaryCta}
        </ButtonPdb>
        {currentPage.secondaryCta && (
          <ButtonPdb
            variant="secondary"
            className="w-full mb-6"
            onClick={(e) => {
              e.preventDefault();
              onClickCallback();
            }}
          >
            {currentPage.secondaryCta}
          </ButtonPdb>
        )}
      </div>
    </>
  );
};
