/** @format */

import React from "react";

import {
  faCalendarCheck,
  faCalendarLinesPen,
  faClipboardPrescription,
  faList,
  faLocationDot,
  faMoneyCheckDollar,
  faPhone,
  faPills,
  faRepeat,
  faTruckFast,
} from "@fortawesome/pro-regular-svg-icons";
import _, { some } from "lodash";
import moment from "moment";

import { Typography } from "@alphamedical/components";

import type { AlphaMedication } from "src/api";

import { RoundedFontAwesomeIcon } from "src/components/Icons/RoundedFontAwesomeIcon";
import { RecordAccordionActions } from "src/components/RecordAccordion/RecordAccordionActions";
import { RecordValuesList } from "src/components/RecordAccordion/RecordValuesList";
import { useCurrentUser } from "src/v2/models/profile";

interface MedicationRecordAccordionProps {
  medication: AlphaMedication;
  onActionClick?: (actionKey: string) => void;
}

export const pluralizeWord = (word: string, quantity: number | undefined) =>
  `${word}${quantity !== 1 ? "s" : ""}`;

const generateGoogleMapsLink = (isWelldyne: boolean, userAddress: any, externalAddress: any) => {
  const terms = isWelldyne
    ? [userAddress.address, userAddress.city, userAddress.state, userAddress.zipcode]
    : [
        externalAddress.address1,
        externalAddress.city,
        externalAddress.state,
        externalAddress.zipcode,
      ];

  return `https://maps.google.com/?q=${terms.join(", ")}`;
};

export const MedicationRecordAccordion = ({ medication }: MedicationRecordAccordionProps) => {
  const user = useCurrentUser();
  const isWelldyne = medication.pharmacy_provider?.key === "welldyne";

  const hasStructuredSig = some([
    medication.dosage,
    medication.dosage_units,
    medication.dosage_frequency,
  ]);

  const googleMapsLink = generateGoogleMapsLink(
    isWelldyne,
    user.pii.address,
    medication.external_pharmacy_address,
  );
  const refillsRemaining = _.isNumber(medication.refills_remaining)
    ? medication.refills_remaining
    : medication.total_refills;

  return (
    <RecordAccordionActions
      IconElement={
        <RoundedFontAwesomeIcon
          backgroundClassName={"bg-cornflower-100"}
          iconClassName={"text-white"}
          icon={faClipboardPrescription}
        />
      }
      title={medication.name || "N/A"}
    >
      <RecordValuesList
        rowData={[
          {
            id: "created_at",
            icon: faCalendarCheck,
            text: `Prescribed on ${medication.created_at ? moment(medication.created_at).format("ll") : "N/A"}`,
          },
          {
            id: "sig",
            icon: faList,
            text: !hasStructuredSig
              ? medication.medication_sig
              : `${medication.dosage} ${medication.dosage_units} ${medication.additional_instructions || ""} ${medication.dosage_frequency}`,
          },
          {
            id: "days_supply",
            icon: faPills,
            text:
              _.isNumber(medication.days_supply) &&
              `${medication.days_supply} ${pluralizeWord("day", medication.days_supply)} supply`,
          },
          ...(_.isNumber(medication.total_refills)
            ? [
                {
                  id: "refills",
                  icon: faRepeat,
                  text: isWelldyne
                    ? `${refillsRemaining} ${pluralizeWord("refill", refillsRemaining)} remaining (of ${
                        medication.total_refills
                      })`
                    : `${medication.total_refills} ${pluralizeWord("refill", medication.total_refills)}`,
                },
              ]
            : []),
          ...(isWelldyne
            ? [
                {
                  id: "provider",
                  icon: faTruckFast,
                  text: (
                    <div>
                      <Typography variant="h5">via {medication.pharmacy_provider?.name}</Typography>
                      <Typography variant="caption">(Hello Alpha Partner)</Typography>
                    </div>
                  ),
                },
              ]
            : []),
          {
            id: "address",
            icon: faLocationDot,
            text: (
              <div>
                <Typography variant="h5" className="text-cornflower-100 font-bold">
                  {isWelldyne ? "Ships to" : medication.external_pharmacy_address?.name}
                </Typography>
                <a href={googleMapsLink} target="_blank" rel="noopener noreferrer">
                  <Typography variant="h5" className="text-cornflower-100">
                    {isWelldyne ? (
                      <>
                        <div>{user.pii.address.address}</div>
                        <div>
                          {user.pii.address.city}, {user.pii.address.state},{" "}
                          {user.pii.address.zipcode}
                        </div>
                      </>
                    ) : (
                      <>
                        <div>{medication.external_pharmacy_address?.address1}</div>
                        <div>
                          {medication.external_pharmacy_address?.city},{" "}
                          {medication.external_pharmacy_address?.state},{" "}
                          {medication.external_pharmacy_address?.zipcode}
                        </div>
                      </>
                    )}
                  </Typography>
                </a>
              </div>
            ),
          },
          ...(!isWelldyne && medication.external_pharmacy_address?.phone
            ? [
                {
                  id: "phone",
                  icon: faPhone,
                  text: (
                    <a href={`tel:${medication.external_pharmacy_address.phone}`}>
                      <Typography variant="h5" className="text-cornflower-100">
                        {medication.external_pharmacy_address.phone}
                      </Typography>
                    </a>
                  ),
                },
              ]
            : []),
          ...(medication.last_renewal_date
            ? [
                {
                  id: "renewal",
                  icon: faCalendarLinesPen,
                  text: `Last renewed on ${moment(medication.last_renewal_date).format("ll")}`,
                },
              ]
            : []),
          {
            id: "payment",
            icon: faMoneyCheckDollar,
            text: medication.payment_type === "CASH" ? "Paid with cash" : "Paid with insurance",
          },
        ]}
      />
    </RecordAccordionActions>
  );
};
