/** @format */

import { STATES_FULL } from "src/v2/constants";

export const fields = [
  {
    key: "autocomplete",
    label: "Address line 1",
    type: "text",
  },
  {
    key: "address",
    label: "Address line 1",
    required: true,
    type: "text",
    category: "pii",
  },
  {
    key: "address2",
    label: "Apartment, suite, etc. (optional)",
    type: "text",
    category: "pii",
  },
  {
    key: "city",
    label: "City",
    required: true,
    type: "text",
    category: "pii",
  },
  {
    key: "state",
    label: "State",
    required: true,
    category: "pii",
    type: "select",
    subtype: "dropdown",
    options: STATES_FULL,
  },
  {
    key: "zipcode",
    label: "ZIP code",
    required: true,
    minLength: 5,
    type: "text",
    category: "pii",
  },
  {
    key: "phone_number",
    label: "Phone number",
    required: true,
    type: "text",
    category: "pii",
  },
  {
    key: "sms_allowed",
    type: "select",
    subtype: "checkbox",
    required: true,
    defaultValue: ["yes"],
    toggle: true,
    options: [
      {
        value: "yes",
        label:
          "<div>Text me shipping updates and other notifications related to my medical care.</div><div class=\"text-xs\"><div>Standard messaging rates may apply. See <a class='underline' href='https://www.helloalpha.com/terms/' target='_blank' rel='noopener noreferrer'>Terms & Conditions</a> & <a class='underline' href='https://www.helloalpha.com/terms/privacy/' target='_blank' rel='noopener noreferrer'>Privacy Policy</a></div></div>",
      },
    ],
  },
];
