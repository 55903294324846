/** @format */
import type React from "react";

import { useHistory } from "react-router-dom";

import { useAuthenticatedUser } from "src/utils/hooks";
import CompletionPage from "src/v2/components/Page/Complete/CompletionPage";
import { useStoreActions } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";
import VisitCard from "src/v2/routes/Visit/VisitCard";

export const PediatricsAfterVisit: React.FC = () => {
  const currentUser = useCurrentUser();
  const [authenticatedUser] = useAuthenticatedUser();
  const actions = useStoreActions((actions) => actions.profile);
  const sex = authenticatedUser.pii.sex || "female";
  const history = useHistory();
  const heading = `Thanks, ${
    authenticatedUser.pii.preferred_firstname || authenticatedUser.pii.first_name
  }! Your visit for your child, ${currentUser.pii.first_name}, is complete.`;
  return (
    <CompletionPage
      showMessagesLink={false}
      heading={heading}
      thankYouMessage={"Who would you like to continue using Alpha as?"}
    >
      <VisitCard
        idx={0}
        onClick={() => history.push("/")}
        title={`Continue to ${currentUser.pii.first_name}'s profile`}
        imageUrl="/assets/visit-groups/pediatrics.svg"
        description="View your child’s treatment plan, message your child’s Alpha Provider and more."
      />
      <VisitCard
        idx={1}
        onClick={() =>
          actions.setCurrentProfileId({ userId: authenticatedUser.id, redirectUrl: "/" })
        }
        title={`Continue to your profile`}
        imageUrl={sex !== "male" ? "/assets/visit-groups/mens-health.svg" : "/assets/woman.svg"}
        description="Get treatment for yourself for over 50 medical conditions."
      />
    </CompletionPage>
  );
};
