/** @format */

import { SchedulingPage } from "src/components/DynamicForm/Fields/CustomFields/Synchronous/SchedulingPage";

import type { CustomFieldProps } from "./types";

import { BillingInfoField } from "./BillingInfoField";
import { CouponCodeField } from "./CouponField";

interface CustomFieldsMap {
  [componentName: string]: React.FC<CustomFieldProps> | undefined;
}

export const CustomFields: CustomFieldsMap = {
  BillingInfoField,
  CouponCodeField,
  SchedulingPage,
};
