/** @format */

import React from "react";
import { Form } from "react-final-form";

import { noop } from "lodash";

import MedicationReporting from "src/components/DynamicForm/Fields/CustomFields/MedicationReporting";
import { PageWrapper } from "src/components/PageWrapper";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";

export const SelfReportedMedications = () => {
  useTopBar({
    variant: "nested",
    title: "Self-reported medications",
    subtitle: "",
    showBackButton: true,
  });
  return (
    <PageWrapper cxPadding="px-6">
      <Form
        onSubmit={noop}
        render={() => <MedicationReporting showSelfReportedOnly={true} />}
      ></Form>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(SelfReportedMedications);
