/** @format */

import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import axios from "axios";
import _ from "lodash";

import type { ErrorResponse } from "src/v2/models/api_types";

/** Type guard to return whether or not an error is an instance of AxiosError
 * @param error The object to check, can be any type
 */
export const isAxiosError = <T = ErrorResponse>(error: any): error is AxiosError<T> =>
  error.isAxiosError === true;

interface APIClientConfig extends AxiosRequestConfig {
  /** Should not be set manually, used to track internal state of how many times a request has been retried */
  _retryAttempt?: number;
  /** Callback that should return the tokens that can be passed to the backend */
  getAPITokens?: () => {
    legacyToken?: string | null;
    authToken?: string | null;
    refreshToken?: string | null;
  };
  /** Callback for any successful HTTP requests */
  onResponse?: (response: AxiosResponse) => void;
}

// The names of the authentication to be used when making authenticated requests
const LEGACY_TOKEN_HEADER = "Authentication-Token";
const TOKEN_HEADER = "Authorization";

/** Creates an axios instance pre-configured to work with the Alpha Medical API
 * @param config
 */
export const getAPIClient = (config?: APIClientConfig) => {
  const instance = axios.create(config);

  // Adds callback to be run to modify the request config before it is sent
  instance.interceptors.request.use((config) => {
    // Automatically apply auth headers if a callback is provided
    const { getAPITokens } = config as APIClientConfig;
    if (getAPITokens) {
      const { legacyToken, authToken } = getAPITokens();
      if (authToken && !config.headers?.[TOKEN_HEADER]) {
        config.headers[TOKEN_HEADER] = `Bearer ${authToken}`;
      }

      // If we have a legacy token and still haven't populated the primary token header
      if (legacyToken && !config.headers[TOKEN_HEADER]) {
        config.headers[LEGACY_TOKEN_HEADER] = legacyToken;
      }
    }
    return config;
  });

  // Adds separate callbacks to be run whenever successful and error https responses are received
  instance.interceptors.response.use(
    (response) => {
      // Call the successful response callback if passed in
      const callback = (response.config as APIClientConfig).onResponse;
      callback && callback(response);
      return response;
    },
    (err) => {
      // If there was a 401 response, retry the request with the refresh token (if available)
      if (isAxiosError(err)) {
        const getAPITokens = (err.config as APIClientConfig)?.getAPITokens;
        if (err.config && getAPITokens) {
          const isUnauthorized = err.response?.status === 401;
          const attemptNumber = (err.config as APIClientConfig)._retryAttempt || 0;
          const { refreshToken } = getAPITokens();

          if (isUnauthorized && attemptNumber === 0 && refreshToken) {
            const updatedConfig = _.cloneDeep<InternalAxiosRequestConfig>(err.config);
            updatedConfig.headers[TOKEN_HEADER] = `Bearer ${refreshToken}`;
            (updatedConfig as APIClientConfig)._retryAttempt = attemptNumber + 1;
            return instance.request(updatedConfig);
          }
        }
      }
      return Promise.reject(err);
    },
  );
  return instance;
};
