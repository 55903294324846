/**
 * istanbul ignore file
 *
 * @format
 */

/* tslint:disable */
/* eslint-disable */
import type { ApiRequestOptions } from "./ApiRequestOptions";
import type { ApiResult } from "./ApiResult";
import config from "src/config";
import { getAPIClient } from "src/helpers/axios";
import { CancelablePromise, type OpenAPIConfig } from "src/api";


const getUrl = (options: ApiRequestOptions): string =>
  options.url.replace(/{(.*?)}/g, (substring: string, group: string) => {
    if (options.path?.hasOwnProperty(group)) {
      return encodeURI(String(options.path[group]));
    }
    return substring;
  });

export function request<T>(
  openAPIConfig: OpenAPIConfig,
  options: ApiRequestOptions,
): CancelablePromise<T> {
  return new CancelablePromise<T>(async (resolve, reject) => {
    const client = getAPIClient({
      baseURL: config.apiUrl,
      getAPITokens: () => {
        return {
          legacyToken: localStorage.getItem("v2:auth:key:token"),
          authToken: localStorage.getItem("auth:token"),
          refreshToken: localStorage.getItem("auth:refresh-token"),
        };
      },
      onResponse: (res) => {
        if (res.headers["access-token"]) {
          localStorage.setItem("auth:token", res.headers["access-token"]);
        }
        if (res.headers["refresh-token"]) {
          localStorage.setItem("auth:refresh-token", res.headers["refresh-token"]);
        }
      },
    });
    try {
      const {
        path,
        url: optionsUrl,
        query,
        formData,
        mediaType,
        headers,
        body,
        ...axiosConfig
      } = options;
      const isFormData = mediaType === "multipart/form-data";
      let newHeaders = headers;
      if (isFormData) {
        newHeaders = {
          ...headers,
        "Content-Type": "multipart/form-data",
        };
      }

      const url = getUrl(options);
      const res = await client.request({
        url,
        headers: newHeaders,
        params: query,
        data: isFormData ? formData : body,
      ...axiosConfig,
      });

      const result: ApiResult = {
        url,
        ok: res.status < 300,
        status: res.status,
        statusText: res.statusText,
      body: res.data,
      };

      resolve(result.body);
    } catch (e) {
      reject(e);
    }
  });
}
