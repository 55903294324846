/** @format */

import React from "react";

import { faCalendarCheck } from "@fortawesome/pro-regular-svg-icons";
import { faDownload, faFlask, faNotes } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";

import type { GenericLabSchema } from "src/api";

import { RoundedFontAwesomeIcon } from "src/components/Icons/RoundedFontAwesomeIcon";
import { RecordAccordionActions } from "src/components/RecordAccordion/RecordAccordionActions";
import { RecordValuesList } from "src/components/RecordAccordion/RecordValuesList";
import { openUrl } from "src/utils/download";

interface LabRecordAccordionProps {
  lab: GenericLabSchema;
}

export const LabRecordAccordion = ({
  lab: { created_at, name, notes, external_file },
}: LabRecordAccordionProps) => {
  return (
    <RecordAccordionActions
      preventFooterCollapse
      IconElement={
        <RoundedFontAwesomeIcon
          backgroundClassName={"bg-sand-120"}
          iconClassName={"text-primary"}
          icon={faFlask}
        />
      }
      title={name}
      actions={[
        {
          key: "download",
          label: "Download",
          iconClassName: "text-cornflower-100",
          icon: faDownload,
        },
        ...(notes
          ? [
              {
                key: "note",
                label: "View Note",
                icon: faNotes,
              },
            ]
          : []),
      ]}
      onActionClick={(actionKey) => {
        if (actionKey === "download" && external_file?.url) {
          openUrl(external_file.url);
        }
      }}
    >
      <RecordValuesList
        rowData={[
          {
            id: "created_at",
            icon: faCalendarCheck,
            text: `Updated on ${moment(created_at).format("ll")}`,
          },
        ]}
      />
    </RecordAccordionActions>
  );
};
