/** @format */

import React from "react";

import type { Action, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";

import type {
  Auth_ResetResetRequestBodySchema,
  PiiSchema,
  ProfileSchema,
  SecondaryUserViewsRegisterRequestBodySchema,
} from "src/api";

import { PermissionService, UsersService } from "src/api";
import config from "src/config";
import { _GET, _POST, getTokenHeaderV2 } from "src/helpers/http";

import type { FetchProfileResponse, LegacyResponse } from "./api_types";
import type { FetchProfileOptions } from "./profile";

import { CURRENT_PROFILE_ID } from "./profile";

const AUTH_TOKEN_KEY = "auth:token";

const AUTHENTICATED_PROFILE_ID = "authenticatedProfileId";

export const AuthenticatedUserContext = React.createContext<FetchProfileResponse | null>(null);

export const getAuthenticatedId = () => {
  return localStorage.getItem(AUTHENTICATED_PROFILE_ID);
};

export const setAuthenticatedId = (authenticatedProfileId: string) => {
  localStorage.setItem(AUTHENTICATED_PROFILE_ID, authenticatedProfileId);
};

type LoginResponse = LegacyResponse<{
  user: {
    authentication_token: string;
    is_jwt?: boolean;
    refresh_token?: string;
    id: string;
  };
}>;

export interface OrganizationEligibilityLookup {
  eligibleIndividualFound: boolean;
  lookup_type?: string;
  address_1?: string;
  address_2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  phone_number?: string;
}

export interface AuthModel {
  authenticated: boolean;
  authenticatedUser?: FetchProfileResponse;
  showLogin?: boolean;
  unauthorizedUserRole?: boolean;

  eligibilityLookupValues?: OrganizationEligibilityLookup;

  setEligibilityLookupValues: Action<AuthModel, OrganizationEligibilityLookup>;

  setAuthenticated: Action<AuthModel, boolean>;

  setUnauthorizedUserRole: Action<AuthModel, boolean>;

  clearAuthData: Action<AuthModel>;

  setAuthenticatedUser: Action<AuthModel, ProfileSchema>;

  setAuthenticatedUserPii: Action<AuthModel, PiiSchema>;

  setShowLogin: Action<AuthModel, boolean>;

  checkAuth: Thunk<AuthModel>;

  login: Thunk<AuthModel, { email: string; password: string }>;

  register: Thunk<AuthModel, SecondaryUserViewsRegisterRequestBodySchema>;

  logout: Thunk<AuthModel, { noRedirect?: boolean } | undefined>;

  sendResetLink: Thunk<AuthModel, { email: string }>;

  resetPassword: Thunk<AuthModel, { token: string; requestBody: Auth_ResetResetRequestBodySchema }>;

  fetchAuthenticatedUser: Thunk<AuthModel, FetchProfileOptions>;
}

export const authStore: AuthModel = {
  authenticated: false,
  eligibilityLookupValues: { eligibleIndividualFound: false },

  setAuthenticated: action((state, isAuthenticated) => {
    state.authenticated = isAuthenticated;
  }),

  setEligibilityLookupValues: action((state, eligibilityLookupValues) => {
    state.eligibilityLookupValues = eligibilityLookupValues;
  }),

  setUnauthorizedUserRole: action((state, authorized) => {
    state.unauthorizedUserRole = authorized;
  }),

  clearAuthData: action((state) => {
    state.authenticated = false;
    localStorage.removeItem(AUTH_TOKEN_KEY);
    localStorage.removeItem("v2:auth:key:token");
    localStorage.removeItem("auth:refresh-token");
    localStorage.removeItem(CURRENT_PROFILE_ID);
    localStorage.removeItem(AUTHENTICATED_PROFILE_ID);
  }),

  setAuthenticatedUser: action((state, authenticatedUser) => {
    state.authenticatedUser = { ...state.authenticatedUser, ...authenticatedUser };
  }),

  setAuthenticatedUserPii: action((state, pii) => {
    if (state.authenticatedUser != null) {
      state.authenticatedUser.pii = pii;
    }
  }),

  setShowLogin: action((state, showLogin) => {
    state.showLogin = showLogin;
  }),

  checkAuth: thunk(async (actions, __, { dispatch }) => {
    return _POST("/need-auth", {}, getTokenHeaderV2())
      .then(() => actions.setAuthenticated(true))
      .catch(({ response }) => {
        actions.clearAuthData();
        if (response?.status === 403) {
          actions.setUnauthorizedUserRole(true);
        }
      });
  }),

  login: thunk(async (actions, payload) => {
    return _POST<LoginResponse>("/login", payload)
      .catch((err) => {
        actions.clearAuthData();
        throw err;
      })
      .then((res) => {
        if (res.meta.code !== 200) {
          actions.clearAuthData();
          throw res;
        }
        const { authentication_token, refresh_token, is_jwt } = res.response.user;
        if (is_jwt) {
          localStorage.setItem(AUTH_TOKEN_KEY, authentication_token);
          if (refresh_token) {
            localStorage.setItem("auth:refresh-token", refresh_token);
          }
        } else {
          localStorage.setItem("v2:auth:key:token", authentication_token);
        }
        actions.setAuthenticated(true);
        return res;
      });
  }),

  register: thunk(async (actions, payload) => {
    const {
      email,
      password,
      condition,
      eligibility_lookup_values,
      employer_id,
      signed_consents,
      metadata,
    } = payload;
    const requestBody: SecondaryUserViewsRegisterRequestBodySchema = {
      email,
      password,
      referrer: document.referrer,
      eligibility_lookup_values,
      employer_id,
      signed_consents,
      metadata,
    };

    if (condition) {
      requestBody["condition"] = condition;
    }

    return UsersService.register({
      requestBody,
    }).then((response: any) => {
      if (response.meta.code === 200) {
        const { authentication_token, refresh_token, is_jwt } = response.response.user;
        if (is_jwt) {
          localStorage.setItem(AUTH_TOKEN_KEY, authentication_token);
          if (refresh_token) {
            localStorage.setItem("auth:refresh-token", refresh_token);
          }
        } else {
          localStorage.setItem("v2:auth:key:token", authentication_token);
        }
        actions.setAuthenticated(true);
      }
    });
  }),

  logout: thunk(async (actions, payload) => {
    return _POST("/logout").finally(() => {
      actions.clearAuthData();
      if (!payload?.noRedirect) {
        window.location.href = config.wwwUrl;
      }
    });
  }),

  sendResetLink: thunk(async (actions, payload) => {
    return PermissionService.forgotPassword({ requestBody: payload });
  }),

  resetPassword: thunk(async (actions, payload) => {
    return PermissionService.reset({ ...payload });
  }),

  fetchAuthenticatedUser: thunk(async (actions, options) => {
    const include = ["pii", "insurance", "followup_items", "consults", "children"];
    return _GET<ProfileSchema>(
      `/profile/me?include=${include.join(" + ")}`,
      {},
      getTokenHeaderV2(),
    ).then(actions.setAuthenticatedUser);
  }),
};
