/** @format */

import { ChartType } from "src/v2/components/Charts/types";

export const titles = {
  [ChartType.WEIGHT]: "Your History",
  [ChartType.BMI]: "Your History",
  [ChartType.WAIST_CIRCUMFERENCE]: "Your History",
  [ChartType.DEPRESSION]: "Depression",
  [ChartType.ANXIETY]: "Anxiety",
  [ChartType.DEPRESSION_ASSESSMENT]: "Depression Assessment",
  [ChartType.ANXIETY_ASSESSMENT]: "Anxiety Assessment",
  [ChartType.PPD_ASSESSMENT]: "Postpartum Depression Assessment",
};
