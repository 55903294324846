/** @format */

import React from "react";

import type { Consultation } from "src/v2/types/consultation";

import ServicesPopupContent from "src/components/Popups/OutlineInfoPopupContent/ServicesPopupContent";
import { AlphaModal } from "src/v2/components/AlphaModal";

export interface OutlineInfoPopupContainerProps {
  showPopUp: boolean;
  condition: string;
  consult?: Consultation;
}

const mentalHealthServicesPopupBenefits = [
  {
    heading: "Medical consultations",
    content: "",
  },
  {
    heading: "Personalized treatment plan",
    content: "",
  },
  {
    heading: "Unlimited messaging with our care team",
    content: "",
  },
  {
    heading: "Insurance coordinator",
    content: "",
  },
];

const titleList = [
  {
    subHeader: "$205",
    text: "every year",
  },
  {
    subHeader: "$70",
    text: "every 3 months",
  },
];

const OutlineInfoPopupContainer = (props: OutlineInfoPopupContainerProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(props.showPopUp);

  return (
    <AlphaModal
      isOpen={props.showPopUp && isOpen}
      className="bg-white max-w-sm m-auto"
      cxPadding="p-0"
    >
      {props.condition === "mental-health" &&
        isOpen &&
        props.consult &&
        props.consult.consult_type === "INITIAL_CONSULT" && (
          <ServicesPopupContent
            bodyBackgroundColor="violator-20"
            foregroundColor="primary"
            heading="Alpha Mental Health Service"
            titleList={titleList}
            subText="Treatment for both depression and anxiety"
            bulletListHeader="Our mental health service includes:"
            bulletList={mentalHealthServicesPopupBenefits}
            btnText="Continue"
            onClick={() => setIsOpen(false)}
          />
        )}

      {/* V V V Can add more popup content's here when needed. V V V */}
    </AlphaModal>
  );
};

export default OutlineInfoPopupContainer;
