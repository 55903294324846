/** @format */

import React from "react";

import { SimpleForm, TextFieldNew } from "@alphamedical/components";
import { ButtonPdb, CheckboxSelection, Paper } from "src/v2/designSystem";

import { PageWrapper } from "src/components/PageWrapper";
import { serializeDependency } from "src/utils";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { preferredPronounsOptions, preferredPronounsValues } from "src/v2/constants";
import { useStoreDispatch, useStoreState } from "src/v2/models";

const PreferredPronouns = () => {
  useTopBar({ title: "Pronouns", variant: "nested", showBackButton: true });
  const dispatch = useStoreDispatch();
  const { profile } = useStoreState((state) => state.profile);
  const [pronouns, setPronouns] = React.useState<string[]>([]);
  const [customPronoun, setCustomPronoun] = React.useState<string>();

  React.useEffect(() => {
    dispatch.profile.fetchProfile({ include: ["pii"] });
  }, []);

  React.useEffect(() => {
    if (profile && profile.pii) {
      if (profile.pii.preferred_pronouns) {
        const custom = profile.pii.preferred_pronouns.find(
          (pronoun: string) => !preferredPronounsValues.includes(pronoun),
        );
        setCustomPronoun(custom);
        const defaultSelectedPronouns = profile.pii.preferred_pronouns.filter((pronoun: string) =>
          preferredPronounsValues.includes(pronoun),
        );
        setPronouns(defaultSelectedPronouns);
      }
    }
  }, [serializeDependency(profile?.pii)]);

  const saveValues = (values: any) => {
    dispatch.profile.updatePii(values);
  };

  const onSubmitPronouns = async (values: any) => {
    try {
      const selected_pronouns = values.preferred_pronouns.filter((pronoun: string) =>
        preferredPronounsValues.includes(pronoun),
      );
      if (values.preferred_pronouns_other && values.preferred_pronouns.includes("other")) {
        selected_pronouns.push(values.preferred_pronouns_other);
      }
      await saveValues({ preferred_pronouns: selected_pronouns });
      dispatch.snacks.addSnack({
        type: "success",
        message: "Preferred Pronouns Saved Successfully!",
        id: "preferred_pronouns_saved",
        delay: 5,
      });
    } catch (e) {
      dispatch.snacks.addSnack({
        type: "error",
        message: "Something went wrong when saving Preferred Pronouns",
        id: "preferred_pronouns_error",
        delay: 5,
      });
    }
  };

  return (
    <PageWrapper cxPadding="px-0">
      <Paper elevation={0} rounded={false} className="flex-1 mx-auto w-full max-w-2xl px-6">
        <SimpleForm<{ preferred_pronouns: string[]; preferred_pronouns_other: string }>
          onSubmit={onSubmitPronouns}
          hideDebugInfo={true}
          initialValues={{
            preferred_pronouns: pronouns,
            preferred_pronouns_other: customPronoun,
          }}
          validate={(values) => {
            const errors = {};
            if (values.preferred_pronouns.length == 0) {
              errors["preferred_pronouns"] = "Must have at least one value selected";
            }
            if (values.preferred_pronouns.includes("other")) {
              if (!values.preferred_pronouns_other || values.preferred_pronouns_other.length == 0) {
                errors["preferred_pronouns_other"] = "Required if 'Other' is selected";
              }
            }
            return errors;
          }}
        >
          {({ submitting, values }) => {
            const showOther = values.preferred_pronouns.includes("other");
            return (
              <>
                <CheckboxSelection
                  required={true}
                  name="preferred_pronouns"
                  options={preferredPronounsOptions}
                  className="mt-8"
                />
                <div className={`mt-4  ${showOther ? "" : "hidden"}`}>
                  <TextFieldNew
                    label="Please specify"
                    required={showOther}
                    name="preferred_pronouns_other"
                  />
                </div>
                <ButtonPdb className="w-full my-6" type="submit" disabled={submitting}>
                  Save
                </ButtonPdb>
              </>
            );
          }}
        </SimpleForm>
      </Paper>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(PreferredPronouns);
