/** @format */

import React from "react";
import { useFormState } from "react-final-form";

import { NameIntro } from "src/components/DynamicForm/Fields/CustomFields/WelcomeFlow/WelcomeNameIntro/NameIntro";
import { WelcomePageContainer } from "src/components/DynamicForm/Fields/CustomFields/WelcomeFlow/WelcomePageCotainer/WelcomePageContainer";
import welcome from "src/content/welcome";

export const WelcomeNameIntro = () => {
  const formState = useFormState();
  const values = formState.values;

  return (
    <WelcomePageContainer
      pageName="welcome-name-intro"
      submitButtonTitle={welcome.screen2.submitButton}
    >
      <NameIntro
        defaultValue={values["preferred_firstname"]}
        onChange={(value) => (values["preferred_firstname"] = value)}
      />
    </WelcomePageContainer>
  );
};
