/** @format */

import type { FieldInputProps } from "react-final-form";

import React, { useRef } from "react";

import classnames from "classnames";

import { FieldError, NumberField } from "@alphamedical/components";

import { useStoreState } from "src/v2/models";

export const FieldWeight = (props: any) => {
  const { field } = props;

  // Get patient's weight from PHI values
  const patientWeight = useStoreState((state) => state.profile.profile?.phi?.weight_in_pounds);

  const [weightHasBeenPrePopulated, setWeightHasBeenPrePopulated] = React.useState(false);
  const [userStartedTyping, setUserStartedTyping] = React.useState(false);

  const ref = useRef<HTMLInputElement>(null);

  const [textColor, setTextColor] = React.useState("text-grey-100");

  // This should clear any pre populated value the first time the user attempts to enter a new value
  const handleOnKeyDown = (event: any, input: any) => {
    if (
      (event.key === "Backspace" || event.key === "Delete" || !isNaN(+event.key)) &&
      !userStartedTyping
    ) {
      input.onChange("");
      setUserStartedTyping(true);
    }
  };

  return (
    <NumberField name={field.key} min={field.min} max={field.max} {...props}>
      {({ input }: { input: FieldInputProps<string, HTMLElement>; meta: any }) => {
        if (patientWeight && !weightHasBeenPrePopulated) {
          input.onChange(input.value || patientWeight);
        }

        if (input.value && !weightHasBeenPrePopulated) {
          setWeightHasBeenPrePopulated(true);
        }

        return (
          <>
            <div className="flex items-center w-full relative overflow-hidden">
              <input
                ref={ref}
                type="number"
                min={field.min}
                max={field.max}
                id="weightInput"
                {...input}
                defaultValue={input.value || patientWeight?.toString()}
                placeholder={props.placeholder}
                onInput={() => setTextColor("text-forest-120")}
                className={classnames(
                  "relative py-3 pr-12 mt-1 w-full text-right border-2 border-grey-100  rounded focus:outline-none focus:border-primary",
                  `focus:${textColor}`,
                )}
                onKeyDown={(event) => handleOnKeyDown(event, input)}
              />
              <label className="absolute right-0 pr-4 pt-1" htmlFor="weightInput">
                lbs.
              </label>
            </div>
            <FieldError name={input.name} cxError="border-t border-red" />
          </>
        );
      }}
    </NumberField>
  );
};
