/** @format */

import type { InvalidType, IsInvalid } from "src/v2/utils/page/validators";

export interface InsuranceValues {
  rx_bin: string;
  preferred_pharmacy?: string;
  payment_preference?: string;
  "consult-state": string;
}
export interface InsuranceInvalid extends InvalidType {
  rx_bin?: boolean;
}

export type IsInvalidInsurance = IsInvalid<InsuranceValues, InsuranceInvalid>;
export const INVALID_TYPE_INSURANCE = "insurance";

const EXPRESS_SCRIPTS_APPROVED_STATES = [
  "AL",
  "AZ",
  "CA",
  "CO",
  "FL",
  "GA",
  "ID",
  "IL",
  "IN",
  "MA",
  "MD",
  "MO",
  "MS",
  "NC",
  "NM",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "SC",
  "TN",
  "TX",
  "UT",
  "WA",
];

export const isInsuranceInvalid: IsInvalidInsurance = (values: InsuranceValues) => {
  /* TODO: eventually remove hardcoded values and move to b/e to be managed */
  const state = values["consult-state"] || "N/A";
  const _invalidRxBins = ["610014", "003858", "610053", "610575"];
  const rxbin =
    !!values.rx_bin &&
    values.payment_preference !== "cash" &&
    values.preferred_pharmacy !== "external" &&
    _invalidRxBins.indexOf(values.rx_bin) >= 0 &&
    EXPRESS_SCRIPTS_APPROVED_STATES.indexOf(state) === -1;
  return {
    rx_bin: rxbin,
    invalid: rxbin,
    invalidType: INVALID_TYPE_INSURANCE,
  };
};
