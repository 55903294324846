/** @format */

import React from "react";
import { Link } from "react-router-dom";

import type { Consultation } from "src/v2/types/consultation";

import { _POST, getTokenHeaderV2 } from "src/helpers/http";
import { useCurrentUser } from "src/v2/models/profile";

interface EligibilityFormWaitlistProps {
  closeModal: any;
  condition: string;
  invalid: any;
  saveValues: any;
  moveForward: any;
  values: any;
  consult: Consultation;
}

export const EligibilityFormWaitlist = (props: EligibilityFormWaitlistProps) => {
  const [fetching, setFetching] = React.useState<boolean>(false);
  const [successfullyCompleted, setSuccessfullyCompleted] = React.useState<boolean>(false);
  const [error, setError] = React.useState(undefined);
  const user = useCurrentUser();

  const addToWaitlist = () => {
    setFetching(true);
    _POST(
      `/users/${user.id}/waitlist`,
      {
        key: "nutrition_waitlist",
        association_class: "Consult",
        association_pk: props.consult.id,
        details: { state: props.values["consult-state"] },
      },
      getTokenHeaderV2(),
    )
      .then((res) => {
        setSuccessfullyCompleted(true);
      })
      .catch(setError)
      .finally(() => setFetching(false));
  };

  return (
    <div>
      <div
        style={{
          backgroundColor: "#F8FAFC",
          maxWidth: "inherit",
          width: "inherit",
          minHeight: "70px",
        }}
        className="flex z-20 sticky inset-x-0 top-0 items-center justify-between px-8"
      >
        <p className="text-18 font-semibold m-0">
          {successfullyCompleted ? "We'll notify you soon" : "Not available in your state"}
        </p>
        <img
          src="/assets/close-light.svg"
          onClick={props.closeModal}
          className="cursor-pointer"
          alt="Close form for entering your billing information"
        />
      </div>
      <div className="p-6">
        {successfullyCompleted
          ? "We'll send you an email when this Alpha service becomes available in your state. In the meantime you can:"
          : "Sign up to get notified by email when this service becomes available in your state."}
      </div>
      {successfullyCompleted ? (
        <div className="pl-6 pb-6">
          <Link to="/your-care/explore-visits" className="text-sky-dark text-md font-bold">
            Explore Alpha Services
          </Link>
        </div>
      ) : (
        <div
          className="sticky left-0 right-0 bottom-0 flex items-center justify-center"
          style={{
            backgroundColor: "#F8FAFC",
            maxWidth: "inherit",
            width: "inherit",
            minHeight: "87px",
          }}
        >
          <div className="flex flex-col justify-center items-center w-full mx-8 py-4">
            <div className="w-full">
              <button
                disabled={fetching}
                onClick={addToWaitlist}
                className="btn btn-primary w-full font-bold"
              >
                Notify Me
              </button>
              <p className="text-red">{error}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
