/** @format */

import React from "react";
import { Link } from "react-router-dom";

export interface ButtonProps {
  children: string | JSX.Element;
  href?: string;
  target?: string;
  onClick?: (e?: any) => void;
  bgColor?: string;
  hoverBgColor?: string;
  borderColor?: string;
  textColor?: string;
  disabled?: boolean;
  cxMargin?: string;
  extraClassNames?: string;
  hrefStyles?: string;
}

export const secondaryProps = {
  extraClassNames: `
  text-center
  border border-grey
  hover:border-primary-normal
  hover:text-white
  items-center cursor-pointer`,
  bgColor: "white",
  hoverBgColor: "primary-80",
  textColor: "primary",
};

export const Button = (props: ButtonProps) => {
  const {
    children,
    href,
    onClick,
    bgColor,
    hoverBgColor,
    borderColor,
    textColor,
    disabled,
    extraClassNames,
  } = props;
  const cxBgColor = `bg-${disabled ? "grey-3" : bgColor || "primary"}`;
  const cxHoverBgColor = `hover:bg-${hoverBgColor || `${bgColor || "primary"}-normal`}`;
  const cxTextColor = `text-${textColor || "white"}`;
  const external_props = props.target
    ? { rel: "noopener noreferrer", "aria-describedby": "new-window-2" }
    : {};

  const renderedButton = (
    <button
      role="button"
      onClick={onClick}
      className={`
          ${extraClassNames ? extraClassNames : ""}
          btn ${cxBgColor} ${cxTextColor} w-full text-center ${props.cxMargin || "mt-4"}
          ${borderColor ? `border border-${borderColor}` : ""}
          ${disabled ? "cursor-not-allowed" : `${cxHoverBgColor}`}
        `}
      disabled={!!disabled}
    >
      {children}
    </button>
  );
  if (href) {
    return href.startsWith("http") || href.startsWith("www") ? (
      <a
        target={props.target}
        href={href}
        {...external_props}
        className={`no-underline ${props.hrefStyles ? props.hrefStyles : ""}`}
      >
        {renderedButton}
      </a>
    ) : (
      <Link
        target={props.target}
        to={href}
        className={`no-underline ${props.hrefStyles ? props.hrefStyles : ""}`}
      >
        {renderedButton}
      </Link>
    );
  }
  return renderedButton;
};
