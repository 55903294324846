/** @format */

import React from "react";

export const TrustpilotWidget = () => {
  React.useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
    script.async = true;
    script.onload = () => {
      (window as any).Trustpilot.loadFromElement(document.getElementById("trustpilot-widget"));
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div className="text-2xl font-medium text-center pb-2">What Our Patients Say</div>
      <div
        id="trustpilot-widget"
        className="trustpilot-widget"
        data-locale="en-US"
        data-template-id="539ad0ffdec7e10e686debd7"
        data-businessunit-id="5cba1bc3001a030001a806fc"
        data-style-height="350px"
        data-style-width="100%"
        data-theme="light"
        data-stars="5"
        data-review-languages="en"
      >
        <a
          href="https://www.trustpilot.com/review/helloalpha.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Trustpilot
        </a>
      </div>
    </>
  );
};

export default TrustpilotWidget;
