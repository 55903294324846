/** @format */

import * as Sentry from "@sentry/react";
import type { Action, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";

import type { Content, HealthActionPlan } from "src/api";

import { HealthActionPlanService } from "src/api";
import { isAxiosError } from "src/helpers/axios";

export interface HealthActionPlanModel {
  healthActionPlan: HealthActionPlan | undefined;
  careGuide: Content | undefined;
  isLoading: boolean;
  setHealthActionPlan: Action<HealthActionPlanModel, HealthActionPlan>;
  fetchHealthActionPlan: Thunk<
    HealthActionPlanModel,
    { userId: number; responseCollectionId: number }
  >;
  setCareGuide: Action<HealthActionPlanModel, Content>;
  markItemAsRead: Thunk<HealthActionPlanModel, { userId: number; healthActionPlanId: number }>;
  getLastHealthActionPlan: Thunk<HealthActionPlanModel, { userId: number }>;
  setIsLoading: Action<HealthActionPlanModel, boolean>;
}

export const healthActionPlanStore: HealthActionPlanModel = {
  healthActionPlan: undefined,
  careGuide: undefined,
  isLoading: false,

  setHealthActionPlan: action((state, healthActionPlan) => {
    state.healthActionPlan = healthActionPlan;
  }),

  fetchHealthActionPlan: thunk(async (actions, { userId, responseCollectionId }) => {
    actions.setIsLoading(true);
    return HealthActionPlanService.getHealthActionPlan({ userId, responseCollectionId })
      .then((res) => {
        actions.setHealthActionPlan(res);
        return res;
      })
      .catch((err) => {
        if (isAxiosError(err)) {
          Sentry.captureException(err);
        }
        return err;
      })
      .finally(() => {
        actions.setIsLoading(false);
      });
  }),

  setCareGuide: action((state, careGuide) => {
    state.careGuide = careGuide;
  }),

  markItemAsRead: thunk(async (_actions, { userId, healthActionPlanId }) => {
    return HealthActionPlanService.markItemAsRead({ userId, healthActionPlanId })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (isAxiosError(err)) {
          Sentry.captureException(err);
        }
        return err;
      });
  }),

  getLastHealthActionPlan: thunk(async (actions, { userId }) => {
    actions.setIsLoading(true);
    return HealthActionPlanService.getLastHealthActionPlan({ userId })
      .then((res) => {
        actions.setHealthActionPlan(res);
        return res;
      })
      .catch((err) => {
        if (isAxiosError(err)) {
          Sentry.captureException(err);
        }
        return err;
      })
      .finally(() => {
        actions.setIsLoading(false);
      });
  }),

  setIsLoading: action((state, isLoading) => {
    state.isLoading = isLoading;
  }),
};
