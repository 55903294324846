/** @format */

import type { Action, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";

import type { PiiSchema, SecondaryUserViewsUpdateUserPiiRequestBodySchema } from "src/api";

import { UsersService } from "src/api";

import type { CreateModel } from "./_create";

export interface User {
  user: { id: number };
  address: { address: string; address2: string; city: string; state: string; zipcode: string };
  first_name?: string;
  last_name?: string;
  dob?: string;
  sex?: string;
  state?: string;
  phone_number?: string;
  sms_allowed: boolean;
  license_photo?: string;
}

export interface UserModel {
  fetching: boolean;
  message: string;
  updatePasswordError: string;
  updatingPassword: boolean;

  setUpdatingPassword: Action<UserModel, boolean>;

  setMessage: Action<UserModel, string>;

  setUpdatePasswordError: Action<UserModel, string>;

  setFetching: Action<UserModel, boolean>;

  currentUser?: PiiSchema;

  setUser: Action<UserModel, PiiSchema>;

  fetchUser: Thunk<UserModel>;

  updateUser: Thunk<UserModel, SecondaryUserViewsUpdateUserPiiRequestBodySchema>;

  updatePassword: Thunk<UserModel, { password: string; passwordConfirm: string }>;
}

export const userStore: UserModel = {
  fetching: false,
  message: "",
  updatePasswordError: "",
  updatingPassword: false,

  setMessage: action((state, message) => {
    state.message = message;
  }),

  setUpdatePasswordError: action((state, error) => {
    state.updatePasswordError = error;
  }),

  setUpdatingPassword: action((state, updating) => {
    state.updatingPassword = updating;
  }),

  setUser: action((state, user) => {
    state.currentUser = user;
  }),

  setFetching: action((state, fetching) => {
    state.fetching = fetching;
  }),

  fetchUser: thunk(async (actions, __, { getState, getStoreState }) => {
    actions.setMessage("");
    const state = getState();
    if (state.fetching) {
      return;
    }
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;
    actions.setFetching(true);
    return UsersService.getUserPii({ userId: currentProfileId as number })
      .then((res) => {
        actions.setUser(res);
        return res;
      })
      .catch((err) => {
        actions.setMessage("Failed to fetch user");
        return err;
      })
      .finally(() => {
        actions.setFetching(false);
      });
  }),

  updateUser: thunk(async (actions, edits, { getState, getStoreState }) => {
    const state = getState();
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;
    const hasChanged = Object.keys(edits).reduce((result, attr) => {
      if (
        !state.currentUser ||
        (state.currentUser[attr] !== edits[attr] &&
          edits[attr] != null &&
          Object.keys(state.currentUser).indexOf(attr) !== -1)
      ) {
        return true;
      }
      return result;
    }, false);

    if (hasChanged) {
      actions.setFetching(true);
      return UsersService.updateUserPii({
        userId: currentProfileId as number,
        requestBody: { ...edits },
      })
        .then((res) => {
          actions.setUser(res);
          return res;
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          actions.setFetching(false);
        });
    }
  }),

  updatePassword: thunk(async (actions, { password, passwordConfirm }) => {
    actions.setUpdatePasswordError("");
    actions.setUpdatingPassword(true);
    return UsersService.putUsers({
      userId: "me",
      requestBody: { password, password_confirm: passwordConfirm },
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        actions.setUpdatePasswordError("Error updating password");
        return err;
      })
      .finally(() => {
        actions.setUpdatingPassword(false);
      });
  }),
};
