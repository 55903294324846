/** @format */

/*
Content for the BenefitHolderRegistration route.
*/
export const BenefitHolderRegistrationContent = {
  // Each view is it's own object.
  optionsPage: {
    title: "How would you like to confirm your sponosored benefit?",
    link: {
      text: "Need help with your sponsored benefit?",
    },
    iconTextChevronOptions: [
      {
        text: "Confirm with your phone number",
        icon: "/assets/phone.svg",
        iconAlt: "Handheld hardline phone",
        planAction: "phone",
      },
      {
        text: "Confirm with your home address",
        icon: "/assets/home-forest.svg",
        iconAlt: "House",
        planAction: "address",
      },
    ],
    iconTextChevron: {
      text: "Contact Alpha Support",
      icon: "/assets/MessageIcon.svg",
      iconAlt: "Envelope",
      planAction: "help",
    },
  },
  dependentStatus: {
    title: "Is your employer sponsoring your Alpha plan?",
    statuses: [
      {
        title: "Yes, I am an employee",
        subtitle: "",
        path: "/options",
      },
      {
        title: "No, I am a dependent",
        subtitle: "I am a dependent of the employee who gets this benefit",
        path: "/address?isDependent=true",
      },
    ],
  },
  helpPage: {
    modalTitle: "Help with accessing your benefit",
    title: "Need help with your sponsored benefit?",
    body: "If you have an employer-sponsored benefit that you're unable to access, please contact us.",
    action: {
      icon: "/assets/MessageIcon.svg",
      text: "Email us at [sponsored@helloalpha.com](mailto:sponsored@helloalpha.com)",
      alt: "Envelope",
    },
    cta: {
      text: "Go back",
    },
  },
  addressLookUp: {
    title: "Okay, let's confirm your benefit.",
    body: "Please enter the home address of the primary benefit holder.",
    cta: [
      {
        text: "Confirm",
      },
      {
        text: "Confirm another way",
        to: "/sponsored-benefits/options",
      },
    ],
  },
  phoneLookUp: {
    title: "Confirm with your phone number.",
    body: "This is the phone number you have on file with your employer.",
    cta: [
      {
        text: "Confirm",
      },
      {
        text: "Confirm another way",
        to: "/sponsored-benefits/options",
      },
    ],
  },
  registrationPage: {
    title: "We found you! Next, create an Alpha account.",
    errorState: {
      title: "We need to verify your information before you can register for your Alpha benefits.",
      link: {
        to: "/sponsored-benefits",
        text: "Click here to verify your information.",
      },
    },
  },
};
