/** @format */

import React from "react";

import { AccordionBox } from "src/components/QuestionAccordionBox/AccordionBox";

export interface AccordionProps {
  questionText: string;
  title: string;
  expandedAnswer: string[];
  accordionType?: "question" | "information";
  backgroundColor?: string;
}

export const Accordion = ({
  questionText,
  title,
  expandedAnswer,
  accordionType = "question",
  backgroundColor,
}: AccordionProps) => {
  return (
    <div>
      <p className="text-xl">{questionText}</p>
      <AccordionBox
        backgroundColor={backgroundColor}
        accordionType={accordionType}
        title={title}
        expandedAnswer={expandedAnswer}
      />
    </div>
  );
};
