/** @format */

import type { FormRenderProps } from "react-final-form";

import React from "react";
import { useLocalStorage } from "react-use";

import { generateUUID } from "pubnub";

import { CheckboxNewField, InfoPopup } from "@alphamedical/components";

import type { BillingInfoModel } from "src/v2/models/billing-info";

import CouponInput from "src/components/Coupon/CouponInput";
import { BillingInfoField } from "src/components/DynamicForm/Fields/CustomFields/BillingInfoField";
import { Loader } from "src/components/Loader";
import { _PUT, getTokenHeaderV2 } from "src/helpers/http";
import { useHasEmployerBenefits } from "src/utils/hooks/useHasEmployerBenefits";
import { Button } from "src/v2/components/Button";
import { MarkdownText } from "src/v2/components/MarkdownText";
import { MembershipModal } from "src/v2/components/MonthlyMembership/MembershipModal";
import { PaymentFooter } from "src/v2/components/Payment/payment-footer";
import { PaymentLineItem } from "src/v2/components/Payment/PaymentLineItem";
import PromoErrorContent from "src/v2/components/Payment/PromoErrorContent";
import {
  MONTHLY_MEMBERSHIP_FEE_IN_CENTS,
  MONTHLY_MEMBERSHIP_PLUS_FEE_IN_CENTS,
} from "src/v2/constants";
import { useStoreDispatch, useStoreState } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";

import type { PageFormProps } from "./types";

import { PageFormWrapper } from "./pageWrapper";

interface PromoState {
  error?: string;
  discount?: number;
  discounted_amount?: number;
  is_percent?: boolean;
}

export const MonthlyBillingInfoPage = (props: PageFormProps) => {
  const currentUser = useCurrentUser();
  const { form, page, paymentPlan } = props;
  const dispatch = useStoreDispatch();
  const [promoState, setPromoState] = React.useState<PromoState>({});
  const [, setHistoryPage] = useLocalStorage("monthly-membership-page");
  const isSubmitting = useStoreState((state) => state.membership.MembershipPost);

  const { billingInfo } = useStoreState((state) => state.billingInfo) as BillingInfoModel;

  const [openModal, setModalOpen] = React.useState(false);
  const isSonjaCaresPatient = useHasEmployerBenefits("sonja-cares");

  const membershipCost =
    (!paymentPlan || paymentPlan === "alpha"
      ? MONTHLY_MEMBERSHIP_FEE_IN_CENTS
      : MONTHLY_MEMBERSHIP_PLUS_FEE_IN_CENTS) / 100;

  const promoSubmit = (e: any, promo: string) => {
    if (e) {
      e.preventDefault();
    }
    _PUT(
      `/users/${currentUser.id}/membership/promo?coupon_code=${promo.toLowerCase().trim()}&payment_plan=${paymentPlan}`,
      {},
      getTokenHeaderV2(),
    )
      .then((data) => {
        setPromoState({
          discount: data.discount,
          is_percent: data.is_percent,
          discounted_amount: data.is_percent
            ? (data.discount / 100) * membershipCost
            : data.discount / 100,
        });
        let message = `A $${data.discount} discount has been added.`;
        if (data.is_percent) {
          message = `A ${data.discount}% discount has been added`;
        }
        dispatch.snacks.addSnack({
          type: "success",
          id: generateUUID(),
          delay: 5,
          message: message,
        });
      })
      .catch((_error) => {
        setPromoState({
          error: _error.response.data.description
            ? _error.response.data.description
            : "We're sorry something went wrong.",
          discounted_amount: 0,
          discount: undefined,
          is_percent: undefined,
        });
      });
  };

  const onClose = (e: any) => {
    e.preventDefault();
    setPromoState({ ...promoState, error: "" });
  };

  React.useEffect(() => {
    setHistoryPage(1);
    return () => {
      setHistoryPage(0);
    };
  });

  React.useEffect(() => {
    const timer = setTimeout(() => {
      // get current available recurly coupon codes
      if (localStorage.getItem("membership_signup.coupon_code") && paymentPlan) {
        promoSubmit(null, localStorage.getItem("membership_signup.coupon_code") as string);
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [paymentPlan]);

  const employerBenefits = currentUser.employer_benefits?.find((benefit) => benefit.active);

  const membershipDiscount = employerBenefits?.plan?.membership_discount_in_cents || 0;

  const totalDue =
    membershipCost -
    (promoState.discounted_amount || (membershipDiscount && membershipDiscount / 100) || 0);

  const canSkipBillingInfo = isSonjaCaresPatient && totalDue == 0;
  const planImage = paymentPlan === "alpha" ? "alphaPlanBasic.svg" : "alphaPlanPlus.svg";

  return (
    <PageFormWrapper form={form} page={page} customNext={true}>
      {(renderProps: FormRenderProps) => {
        if (isSubmitting) {
          return <Loader screen={true} show={true} />;
        }
        const agreesToConsent = (renderProps?.values?.consents || [])[0] === "true";
        return (
          <>
            <MembershipModal modalOpen={openModal} setModalOpen={setModalOpen} />
            <img className={"w-full"} src={`/assets/${planImage}`} alt="Alpha plan logo" />
            <div className="flex flex-col my-8 mb-16">
              <div className="text-16 flex justify-between flex-col my-1">
                <span className="font-bold text-sm" style={{ color: "#004B46" }}>
                  YOUR SUBSCRIPTION:{" "}
                </span>
                <div className="border-b-2 mt-2 w-full border-sand-100" />
                <span
                  className={"underline text-sm mt-4 text-cornflower-100"}
                  onClick={() => setModalOpen(true)}
                >
                  {!paymentPlan || paymentPlan === "alpha"
                    ? "Alpha Basic Plan"
                    : "Alpha Plus+ Plan"}
                </span>
                <PaymentLineItem textSize={"text-sm"} description={"Renews"} cost={"every month"} />
                <PaymentLineItem
                  textSize={"text-sm"}
                  description={"Price"}
                  cost={`$${membershipCost}/month`}
                />
                {!!membershipDiscount && (
                  <PaymentLineItem
                    textSize={"text-sm"}
                    description={"Membership discount applied"}
                    cost={-membershipDiscount}
                  />
                )}
              </div>
              <CouponInput onSubmit={(e, promoString) => promoSubmit(e, promoString)} />
              <div className="border-b-2 border-primary mt-16 mb-3" />
              <PaymentLineItem
                cxDescriptionStyles={"font-bold"}
                textSize={"text-base"}
                description={"Due now"}
                cost={`$${totalDue.toFixed(2)}`}
              />
              <InfoPopup
                onRequestClose={() =>
                  setPromoState({
                    error: undefined,
                    discounted_amount: 0,
                    discount: undefined,
                    is_percent: undefined,
                  })
                }
                headerText={"Coupon code error"}
                isOpen={!!promoState.error}
                accentColor={"white"}
              >
                <PromoErrorContent
                  btnClass={"btn-primary"}
                  onClose={onClose}
                  error={promoState.error}
                />
              </InfoPopup>
            </div>

            {!canSkipBillingInfo && <BillingInfoField field={{ key: "payment", type: "text" }} />}
            <CheckboxNewField
              className={"mt-4 mx-auto max-w-sm"}
              required={true}
              label="consent"
              name="consents"
              options={[
                {
                  label: (
                    <MarkdownText
                      containerClassName="leading-none"
                      text="I agree to the [Consent to Telehealth](https://www.helloalpha.com/terms/consent)."
                    />
                  ),
                  value: "true",
                },
              ]}
            />
            <PaymentFooter cxMargin="mt-4" />
            <Button
              extraClassNames={"mb-10"}
              disabled={(!billingInfo && !canSkipBillingInfo) || !agreesToConsent || isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Access Plan Now"}
            </Button>
          </>
        );
      }}
    </PageFormWrapper>
  );
};
