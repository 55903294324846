/** @format */

import React from "react";

import { capitalize } from "lodash";
import moment from "moment";

import { NumberFieldNew, SimpleForm, Typography } from "@alphamedical/components";
import { Datepicker } from "src/v2/designSystem";

import type { ChartData } from "src/api";
import type { BottomSheetProps } from "src/components/BottomSheet/BottomSheet";
import type { ChartType } from "src/v2/components/Charts/types";

import { Analytics } from "src/analytics";
import { BottomSheet } from "src/components/BottomSheet/BottomSheet";
import {
  getMeasurementTypeDisplay,
  getShortUnitsForChartType,
} from "src/routes/Records/WeightLossJournal/constants";
import { now } from "src/utils/date_utils";
import { useStoreDispatch } from "src/v2/models";

type AddJournalEntryBottomSheetProps = Omit<BottomSheetProps, "title" | "open"> & {
  chartType: ChartType.WEIGHT | ChartType.WAIST_CIRCUMFERENCE;
  entry?: ChartData;
};

interface AddJournalEntryValues {
  value: number;
  date: string;
}

export const AddJournalEntryBottomSheet = (props: AddJournalEntryBottomSheetProps) => {
  const isEdit = !!props.entry;
  const isLocked = props.entry && props.entry?.observation !== true;

  const selectedChartType = props.chartType;
  const dispatch = useStoreDispatch();

  const handleSubmit = async (values: AddJournalEntryValues) => {
    if (!selectedChartType) return;

    const action = `${!isEdit ? "added" : "updated"}`;
    try {
      if (!isEdit) {
        await dispatch.charts.submitObservation({
          chartType: selectedChartType,
          value: values.value,
          date: values.date,
        });
      } else {
        const observationId = props.entry?.observation_id;
        if (observationId) {
          await dispatch.charts.updateObservation({
            value: values.value,
            date: values.date,
            observationId: observationId,
            chartType: selectedChartType,
          });
          dispatch.charts.fetchChartData(selectedChartType);
        }
      }
      Analytics.trackEvent({ category: "WeightLossJournal", action: "AddEntry" });
      dispatch.snacks.addSnack({
        type: "primary",
        id: "add-journal-entry",
        message: `Journal entry ${action}.`,
        delay: 10,
      });
    } catch (e) {
      dispatch.snacks.addSnack({
        type: "error",
        id: "add-journal-entry-error",
        message: `Sorry, your journal entry could not be ${action}.`,
        delay: 10,
      });
    } finally {
      props.onClose();
    }
  };

  const handleDelete = async () => {
    if (selectedChartType && props.entry?.observation_id) {
      try {
        await dispatch.charts.deleteObservation({
          chartType: selectedChartType,
          observationId: props.entry?.observation_id,
        });
        Analytics.trackEvent({ category: "WeightLossJournal", action: "DeleteEntry" });
        dispatch.snacks.addSnack({
          type: "primary",
          id: "delete-journal-entry",
          message: `Journal entry deleted.`,
          delay: 10,
        });
      } catch (e) {
        dispatch.snacks.addSnack({
          type: "error",
          id: "delete-journal-entry-error",
          message: `Sorry, your journal entry could not be deleted.`,
          delay: 10,
        });
      } finally {
        props.onClose();
      }
    }
  };
  const editButtonText = isLocked ? "Close" : "Save";
  const editLabel = isLocked ? "View" : "Edit";
  const entryDateFormat = moment(props.entry?.x).format("MM-DD-YYYY");
  const title = selectedChartType
    ? capitalize(`${!isEdit ? "Add" : editLabel} ${getMeasurementTypeDisplay(selectedChartType)}`)
    : "Add to journal";

  const today = now("MM-dd-yyyy");

  const initialValues = !props.entry
    ? {}
    : {
        value: props.entry.y,
        date: entryDateFormat,
      };
  return (
    <SimpleForm<AddJournalEntryValues>
      initialValues={initialValues}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ invalid, handleSubmit, form, pristine }) => (
        <BottomSheet open={true} onClose={props.onClose} title={title}>
          <div className="py-4">
            {!isLocked ? (
              <div className="space-y-4">
                <NumberFieldNew
                  name="value"
                  required
                  label={getMeasurementTypeDisplay(selectedChartType)}
                  units={getShortUnitsForChartType(selectedChartType)}
                />
                <Datepicker label="Date" name="date" defaultValue={today} />
              </div>
            ) : (
              <>
                {props.chartType && (
                  <>
                    <Typography>
                      This entry cannot be edited or deleted. To update your{" "}
                      {getMeasurementTypeDisplay(props.chartType).toLowerCase()}, please add a new
                      entry in your journal.
                    </Typography>
                    <Typography variant="body2" className="pt-4 pb-2">
                      {getMeasurementTypeDisplay(props.chartType)}
                    </Typography>
                    <Typography variant="body1">
                      {props.entry?.y} {getShortUnitsForChartType(props.chartType)}
                    </Typography>
                    <Typography variant="body2" className="pt-4 pb-2">
                      Date
                    </Typography>
                    <Typography variant="body1">{entryDateFormat}</Typography>
                  </>
                )}
              </>
            )}
          </div>
          {(selectedChartType || isLocked) && (
            <BottomSheet.Actions
              showDelete={isEdit && !isLocked}
              showBack={false}
              onConfirm={() => {
                if (!isLocked) {
                  handleSubmit();
                } else {
                  props.onClose();
                }
              }}
              buttonText={!isEdit ? "Confirm" : editButtonText}
              disabled={((isEdit && !isLocked) || !isEdit) && (invalid || pristine)}
              onDelete={handleDelete}
            />
          )}
        </BottomSheet>
      )}
    </SimpleForm>
  );
};
