/** @format */

import React from "react";

import _ from "lodash";

import { Popup } from "@alphamedical/components";

import type { ValidationError } from "src/components/DynamicForm/Validators/types";

import { ValidationErrorContent } from "./CustomPopups";

interface ValidationErrorPopupProps {
  error: ValidationError;
}

// Put custom logic here if the header text needs to change based on error type
const getHeader = (validationError: ValidationError) => "We're sorry";

export const ValidationErrorPopup = (props: ValidationErrorPopupProps) => {
  const [isOpen, setIsOpen] = React.useState(true);

  // If the error has change, reopen the popup
  React.useEffect(() => {
    if (!isOpen && props.error) {
      setIsOpen(true);
    }
  }, [JSON.stringify(props.error)]);

  return (
    <Popup
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      headerText={getHeader(props.error)}
    >
      <div className="px-5 py-8">
        <ValidationErrorContent validationError={props.error} />
      </div>
    </Popup>
  );
};
