/** @format */

import React from "react";

import { FilterChips, Typography } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import type { GenericLabSchema } from "src/api";

import { BaseCard } from "src/components/Cards/BaseCard";
import { PageWrapper } from "src/components/PageWrapper";
import { LabRecordAccordion } from "src/components/RecordAccordion/LabRecordAccordion";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useStoreDispatch, useStoreState } from "src/v2/models";
import { UploadLabBottomSheet } from "src/v2/routes/Labs/UploadLabBottomSheet";

const labTypes: Record<string, string[]> = {
  user: ["user", "consult"],
  athena: ["lab_result"],
};

export const LabsPage = () => {
  useTopBar({ variant: "nested", showBackButton: true, title: "Labs" });
  const dispatch = useStoreDispatch();
  const labList = useStoreState((state) => state.userLabs.userLabs);
  const [selectedType, setSelectedType] = React.useState<string | undefined>();

  const [isUploading, setIsUploading] = React.useState(false);

  React.useEffect(() => {
    dispatch.userLabs.fetchUserLabs();
  }, []);

  const filteredLabs = labList.filter(
    (value) => !selectedType || labTypes[selectedType].includes(value.lab_type),
  );

  return (
    <PageWrapper cxPadding={"px-4 py-6"}>
      <BaseCard
        bgClassName="bg-sand-20"
        icon={
          <img
            className="mr-4"
            src={"/assets/flask-brand.svg"}
            alt=""
            role="presentation"
            style={{ height: "40px" }}
          />
        }
        title="Need to submit lab work?"
        description="We’ll send it to your Alpha provider."
      >
        <ButtonPdb onClick={() => setIsUploading(true)}>Upload a lab</ButtonPdb>
      </BaseCard>
      <div>
        <div className="mb-6">
          <FilterChips
            filterOptions={[
              {
                key: "user",
                label: "My uploads",
              },
              {
                key: "athena",
                label: "Provider uploads",
              },
            ]}
            selected={selectedType}
            onOptionChange={(filterKey) => {
              const isCurrent = filterKey === selectedType;
              if (isCurrent) {
                setSelectedType(undefined);
              } else {
                setSelectedType(filterKey);
              }
            }}
          />
        </div>
        {filteredLabs.map((lab: GenericLabSchema) => (
          <LabRecordAccordion lab={lab} key={`${lab.lab_type}_${lab.id}`} />
        ))}
        {filteredLabs.length === 0 && (
          <Typography variant="body2" className="bg-grey-10 p-4 mb-8">
            No labs to show
          </Typography>
        )}
      </div>
      <UploadLabBottomSheet open={isUploading} onClose={() => setIsUploading(false)} />
    </PageWrapper>
  );
};

export default WithTopBarNavigation(LabsPage);
