/** @format */

import React from "react";

import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";

import type { FetchProfileResponse } from "src/v2/models/api_types";

import config from "src/config";
import { useAuthenticatedUser } from "src/utils/hooks";
import { useStoreDispatch } from "src/v2/models";

type TPubNub = PubNub & {
  setToken: (token: string) => void;
  parseToken: (token: string) => {
    ttl: number;
    timestamp: number;
  };
};

const getPubNubUUID = (userId: number) => {
  return `${userId}`;
};

const usePubNubClient = (user: FetchProfileResponse): [PubNub, boolean] => {
  const dispatch = useStoreDispatch();

  const userId = user.id;
  const chatAuthKey = user.chat_auth_key;

  // Create the pubnub client object with the required configuration
  const [pubNubClient] = React.useState(() => {
    const client = new PubNub({
      subscribeKey: config.pubnub.subscribeKey || "",
      publishKey: config.pubnub.publishKey || "",
      uuid: getPubNubUUID(userId),
    }) as TPubNub;
    if (chatAuthKey) {
      client.setToken(chatAuthKey);
    }
    return client;
  });
  const [isValidAuthKey, setIsValidAuthKey] = React.useState(false);

  React.useEffect(() => {
    let isValid = false;
    let timeoutId: NodeJS.Timeout | null = null;
    let refreshAuthKeyTimeoutId: NodeJS.Timeout | null = null;
    const refreshToken = () => {
      dispatch.profile.refreshChatAuthToken();
    };

    // If the auth key is set and it is parseable the check if it has expired yet.
    if (chatAuthKey) {
      try {
        // ttl is in minutes, timestamp is the unix timestamp in seconds.
        const { ttl, timestamp } = pubNubClient.parseToken(chatAuthKey);

        // Calculate the time difference in milliseconds between the expiration time and now. If the different is
        // negative then it has already expired
        const expiresIn = (timestamp + ttl * 60) * 1000 - new Date().getTime();
        if (expiresIn > 0) {
          isValid = true;
          // Update the client auth token since we know it is valid now
          pubNubClient.setToken(chatAuthKey);

          // Schedulea a timeout so that isValidAuthKey is reset when the token expires.
          timeoutId = setTimeout(() => setIsValidAuthKey(false), expiresIn);

          // Schedule a timeout to refresh the auth token before it actually expires. To calculate the buffer time, take
          // 5% of the ttl of the auth token, and subtract that from the time that the token will actually expire.
          const refreshIn = Math.max(expiresIn - ttl * 0.05 * 60 * 1000, 0);
          refreshAuthKeyTimeoutId = setTimeout(refreshToken, refreshIn);
        }
      } catch {
        console.error("Unable to parse chat token");
      }
    }

    // Update if the auth key is currently valid, and refresh it if needed
    setIsValidAuthKey(isValid);
    if (!isValid) {
      refreshToken();
    }

    // Clear any existing timeouts if the auth key changes
    return () => {
      timeoutId && clearTimeout(timeoutId);
      refreshAuthKeyTimeoutId && clearTimeout(refreshAuthKeyTimeoutId);
    };
  }, [chatAuthKey]);

  // Initialize the client subscriptions and reset them if the current user changes
  React.useEffect(() => {
    if (userId) {
      pubNubClient.setUUID(getPubNubUUID(userId));
      pubNubClient.subscribe({ channelGroups: [`user-${user.uuid}`] });
      dispatch.pubnub.initialize({ pubnub: pubNubClient });
    }
    return () => {
      pubNubClient.unsubscribeAll();
      dispatch.pubnub.reset({ pubnub: pubNubClient });
    };
  }, [!!pubNubClient, userId]);

  return [pubNubClient, isValidAuthKey];
};

export const PubNubClient: React.FunctionComponent = (props) => {
  const [authenticatedUser] = useAuthenticatedUser();
  const [pubNubClient, isValidAuthKey] = usePubNubClient(authenticatedUser);

  // Do not render the child content if we want to delay rendering until a valid auth key is fetched
  const renderChildren = isValidAuthKey || !config.pubnub.requireValidAuthKey;

  return (
    (renderChildren && <PubNubProvider client={pubNubClient}>{props.children}</PubNubProvider>) ||
    null
  );
};
