/** @format */

import React from "react";

import type { Consultation } from "src/v2/types/consultation";

import { Button } from "src/v2/components/Button";

const reselectMesssage = (props: EligibilityFormReselectProps) => {
  switch (props.invalid.name) {
    case "liraglutide-injection":
      return {
        title: "Saxenda Pricing",
        body: `The cash price for Saxenda ranges from $990 to $1650 when fulfilled by Alpha's Partner Pharmacy. Saxenda is also bundled with a needle which costs an additional $28. Please confirm that you would like to proceed.`,
        mainCTA: "Cancel",
        secondaryCTA: "Confirm",
      };
    case "naltrexone-bupropion":
      return {
        title: "Contrave Pricing",
        body: `The cash price for Contrave ranges from $280 to $480 when fulfilled by Alpha's Partner Pharmacy. Please confirm that you would like to proceed.`,
        mainCTA: "Cancel",
        secondaryCTA: "Confirm",
      };
    case "orlistat":
      return {
        title: "Xenical Pricing",
        body: `The cash price for Xenical is $800 when fulfilled by Alpha's Partner Pharmacy. Please confirm that you would like to proceed.`,
        mainCTA: "Cancel",
        secondaryCTA: "Confirm",
      };
    case "semaglutide-wegovy":
      return {
        title: "Wegovy Pricing",
        body: `The cash price for Wegovy is $1650 when fulfilled by Alpha's Partner Pharmacy. Please confirm that you would like to proceed.`,
        mainCTA: "Cancel",
        secondaryCTA: "Confirm",
      };
    case "semaglutide-ozempic":
      return {
        title: "Ozempic Pricing",
        body: `The cash price for Ozempic ranges from $1225 to $1250 when fulfilled by Alpha's Partner Pharmacy. Please confirm that you would like to proceed.`,
        mainCTA: "Cancel",
        secondaryCTA: "Confirm",
      };
    case "liraglutide-injection-victoza":
      return {
        title: "Victoza Pricing",
        body: `The cash price for Victoza ranges from $1000 to $1500 when fulfilled by Alpha's Partner Pharmacy. Please confirm that you would like to proceed.`,
        mainCTA: "Cancel",
        secondaryCTA: "Confirm",
      };
    case "semaglutide-rybelsus":
      return {
        title: "Rybelsus Pricing",
        body: `The cash price for Rybelsus is $1350 when fulfilled by Alpha's Partner Pharmacy. Please confirm that you would like to proceed.`,
        mainCTA: "Cancel",
        secondaryCTA: "Confirm",
      };
    case "non-quest-lab":
      // eslint-disable-next-line no-case-declarations
      const is_NY =
        props.consult.answers["consult-state"] &&
        props.consult.answers["consult-state"].value === "NY";
      return {
        title: "Are you sure?",
        body: `
          <p><strong>Quest Diagnostics</strong></p>
          <ul>
            ${is_NY ? "" : "<li>Guaranteed pricing</li>"}
            <li>Convenient locations</li>
            <li>Results available instantly</li>
          </ul>
          <br/>
          <p><strong>Other lab</strong></p>
          <ul>
            <li>Pricing may vary</li>
            <li>Results may take longer</li>
          </ul>
        `,
        mainCTA: "Go back to choose Quest Lab",
        secondaryCTA: "Continue with other lab",
      };
    case "choose-medications":
      return {
        title: "Please choose again",
        body: `Estrogen-containing contraceptives are not recommended in women
        ages 35 years or older who smoke due to higher risks of heart attack and
        stroke.`,
      };
    case "osteo-orilissa-warning":
      return {
        title: "Please choose again",
        body: `Orilissa is not recommended for patients with osteoporosis.
                <p><strong>Please press the X and choose a different option.</strong></p>`,
      };
    case "cyclosporine-orilissa-warning":
      return {
        title: "Please choose again",
        body: `Orilissa is not recommended for patients using Cyclosporine.
                <p><strong>Please press the X and choose a different option.</strong></p>`,
      };
    case "gemfibrozil-orilissa-warning":
      return {
        title: "Please choose again",
        body: `Orilissa is not recommended for patients using Gemfibrozil.
                <p><strong>Please press the X and choose a different option.</strong></p>`,
      };
    case "no-estrogen-bc-with-risk-factors":
      return {
        title: "Please choose again",
        body: `Estrogen-containing contraceptives are not recommended for women ages 35 years old or older who smoke due to higher risk of heart attack and stroke.
                <p><strong>Please press the X and choose a different option.</strong></p>`,
      };
    case "aygestin-warning":
      return {
        title: "Please choose again",
        body: `Aygestin is not recommended in women with a history of clotting disorders or blood clots in the legs or lungs.
                <p><strong>Please press the X and choose a different option.</strong></p>`,
      };
    case "brand-latisse":
      return {
        title: "Latisse is Out of Stock",
        body: "We are sorry but name brand Latisse is temporarily out of stock. You will need to choose the generic Latisse if you would like to proceed.",
        mainCTA: "Okay",
      };
    default:
      return {
        title: "Notice",
        body: "One of the options you selected is not a valid response based on your previous answers. Please select a different option.",
      };
  }
};

interface EligibilityFormReselectProps {
  closeModal: any;
  condition: string;
  invalid: any;
  saveValues: any;
  moveForward: any;
  values: any;
  consult: Consultation;
}

export const EligibilityFormReselect = (props: EligibilityFormReselectProps) => {
  const CTAHandler = async (props: EligibilityFormReselectProps, cta?: string) => {
    if (
      [
        "non-quest-lab",
        "liraglutide-injection",
        "naltrexone-bupropion",
        "orlistat",
        "semaglutide-ozempic",
        "semaglutide-wegovy",
        "semaglutide-rybelsus",
        "liraglutide-injection-victoza",
        "brand-latisse",
      ].indexOf(props.invalid.name) !== -1
    ) {
      if (cta === "mainCTA") {
        props.closeModal();
      } else {
        await props.saveValues(props.values);
        props.closeModal();
        props.moveForward();
      }
    }
    return;
  };
  return (
    <div className="flex flex-col">
      <h1 style={{ top: "-18px", width: "315px" }} className="text-3xl md:relative font-500">
        {reselectMesssage(props).title}
      </h1>
      <div
        style={{ top: "-14px" }}
        className={`w-full md:relative border ${"border-b-grey-500"} mb-2`}
      />
      <div
        className={`text-lg mt-4`}
        dangerouslySetInnerHTML={{ __html: reselectMesssage(props).body }}
      />
      {reselectMesssage(props).mainCTA && (
        <Button onClick={() => CTAHandler(props, "mainCTA")}>
          <span>{reselectMesssage(props).mainCTA}</span>
        </Button>
      )}
      {reselectMesssage(props).secondaryCTA && (
        <Button onClick={() => CTAHandler(props, "secondaryCTA")}>
          <span>{reselectMesssage(props).secondaryCTA}</span>
        </Button>
      )}
    </div>
  );
};
