/** @format */

import React from "react";

import type { FollowupItem } from "src/components/Popups/types";

import { AlphaModal } from "src/v2/components/AlphaModal";
import { Button } from "src/v2/components/Button";
import { formatPrice } from "src/v2/components/Payment/utils";
import { useStoreDispatch } from "src/v2/models";

export interface CopayApprovalPopupProps {
  item: FollowupItem;
  showPopup: boolean;
  setShowPopup: (arg: boolean) => void;
  key?: number;
  pii?: any;
  profile?: any;
}

export const CopayApprovalPopup = ({
  item: { followup_type, copay_amount, medication_name, order_id },
  showPopup,
  setShowPopup,
  pii,
  profile,
}: CopayApprovalPopupProps) => {
  const [confirm, setConfirm] = React.useState<boolean>(false);
  const dispatch = useStoreDispatch();
  const copayIsApproved = (approved: boolean, copay_approval: boolean) => {
    dispatch.profile.sendOrderApproval({
      approved,
      order_id,
      copay_amount,
      medication_name,
      followup_type,
      copay_approval,
    });
    setShowPopup(false);
    dispatch.profile.setHasSeenPopup(true);
    setConfirm(false);
  };

  return (
    <div>
      <AlphaModal
        isOpen={showPopup && !profile.hasSeenPopup}
        className="max-w-sm m-auto bg-white shadow-md"
        cxPadding="p-8"
        addPaddingForNavBar={true}
        onRequestClose={() => {
          setShowPopup(false);
          dispatch.profile.setHasSeenPopup(true);
        }}
      >
        {!confirm && (
          <div className="flex flex-col text-center text-16">
            <p className="font-semibold">
              Hi {(pii && pii.preferred_firstname) || (pii && pii.first_name)},
            </p>
            <p className="mb-4">We've determined your copay for your recent order.</p>
            <div className="flex flex-col bg-sand-light py-2">
              <p className="font-semibold underline mb-1">COPAY</p>
              <p className="text-20 mb-1">{medication_name}</p>
              <p className="font-semibold mt-0 text-18">
                {copay_amount && formatPrice(copay_amount, 2)}
              </p>
            </div>
            <p className="mt-5 mb-8">
              Your card will be charged the amount stated above and your order will be shipped to
              you within 7 days. Please note that your medication order will be submitted
              automatically after 72 hours if no action is taken.
            </p>
            <div className="flex justify-content">
              <Button
                extraClassNames="btn btn-violator w-1/2 mr-4 cursor-pointer"
                onClick={() => copayIsApproved(true, true)}
                disabled={profile.postingApproval}
              >
                Approve
              </Button>
              <Button
                extraClassNames={`
                        text-center
                        border-2 border-primary
                        hover:text-white
                        items-center w-1/2 cursor-pointer`}
                disabled={profile.postingApproval}
                bgColor={"white"}
                hoverBgColor={"primary-80"}
                textColor={"primary"}
                onClick={() => setConfirm(true)}
              >
                Contact Us
              </Button>
            </div>
            {profile.error && <p className="text-red">{profile.error}</p>}
          </div>
        )}

        {confirm && (
          <div className="w-sm bg-white m-auto p-6">
            <p className="text-18 text-center">
              Are you sure? Clicking continue will cancel this order.
            </p>
            <div className="flex">
              <Button
                extraClassNames="w-1/2 mr-1"
                onClick={() => copayIsApproved(false, true)}
                disabled={profile.postingApproval}
              >
                Continue
              </Button>
              <Button
                bgColor="error"
                extraClassNames="w-1/2"
                disabled={profile.postingApproval}
                onClick={() => setConfirm(false)}
              >
                Never mind.
              </Button>
            </div>
          </div>
        )}
      </AlphaModal>
    </div>
  );
};
