/** @format */

import type { FormRenderProps } from "react-final-form";

import React from "react";

import * as Sentry from "@sentry/react";
import cn from "classnames";
import { map } from "lodash";

import { Typography } from "@alphamedical/components";
import { BadgePdb } from "src/v2/designSystem";

import type { Address } from "src/api/models/Address";
import type { DynamicField as IDynamicField } from "src/components/DynamicForm/types";

import { ContentfulService } from "src/api/services/ContentfulService";
import { EhrService } from "src/api/services/EhrService";
import { ServicesService } from "src/api/services/ServicesService";
import { DynamicField } from "src/components/DynamicForm";
import { Loader } from "src/components/Loader";
import {
  DEFAULT_SONJA_CARES_USERS_PHARMACY_CONTENT,
  PHARMACY_DIFFERENCES_MODAL_CONTENT as standardModalContent,
} from "src/content/src/v2/components/Pharmacy/PharmacyDifferencesModal";
import { useHasEmployerBenefits } from "src/utils/hooks/useHasEmployerBenefits";
import { IconTextChevron } from "src/v2/components/IconTextLink";
import { Line } from "src/v2/components/Line";
import { B2B_ORGANIZATION_SLUGS } from "src/v2/constants";
import { useStoreDispatch, useStoreState } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";
import { useCurrentConsult } from "src/v2/routes/Consultation";

import PharmacyDifferencesModal from "./PharmacyDifferencesModal";

interface PharmacyProps {
  formProps: FormRenderProps;
  fields: IDynamicField[];
  nextDisabled: boolean;
  nextLabel?: string;
  nextClick: () => any;
  condition?: string;
}

const optionDescriptions: Partial<Record<string, string>> = {
  alpha: "Alpha's trusted partner pharmacy will ship your prescription right to your door.",
  external: "Search for a pharmacy near you and pick up in person or check their shipping options.",
};

const optionLabels: Partial<Record<string, string>> = {
  alpha: "Free shipping",
  external: "Pickup",
};

export interface PharmacyContentFromCMS {
  title: string;
  options: PharmacyContentOption[];
  modal: PharmacyModalContent;
}

interface PharmacyContentOption {
  title: string;
  value: string;
  showInUrgentVisits?: boolean;
  description: string;
  badge?: string;
}

export interface PharmacyModalContent {
  title: string;
  options: PharmacyModalOption[];
  footer?: string[];
}

interface PharmacyModalOption {
  title: string;
  bullets: string[];
}

const Pharmacy = (props: PharmacyProps) => {
  const [field] = props.fields;
  const { formProps } = props;
  const {
    values: { preferred_pharmacy },
  } = formProps;
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const isSonjaCaresPatient = useHasEmployerBenefits(B2B_ORGANIZATION_SLUGS.SONJA_CARES);
  const [pageContent, setPageContent] = React.useState<PharmacyContentFromCMS | undefined>();
  const [modalContent, setModalContent] = React.useState<PharmacyModalContent | undefined>(
    standardModalContent,
  );
  const currentUser = useCurrentUser();
  const dispatch = useStoreDispatch();

  const sonjaCaresPharmacy = { name: "TOWER PHARMACY", zip: "95380" };
  const [preferredPharmacy, setPreferredPharmacy] = React.useState<string>(preferred_pharmacy);
  const [isUrgentVisit, setIsUrgentVisit] = React.useState<boolean>(false);

  React.useEffect(() => {
    const initializePageContent = async () => {
      // As part of our partnership launch with Sonja Cares / LHE,
      // we're introducing a third option: Towers Pharmacy. This is only for Sonja Cares users
      // However, it's essentially a shortcut to quickly select an external pharmacy.
      if (isSonjaCaresPatient) {
        try {
          setIsLoading(true);
          const { content } = await ContentfulService.getUnstructuredContentByKey({
            key: `sonja-cares-pharmacy-preference-content`,
          });

          if (content) {
            setPageContent(content as PharmacyContentFromCMS);
            setModalContent(content.modal);
          } else {
            setPageContent(DEFAULT_SONJA_CARES_USERS_PHARMACY_CONTENT);
            setModalContent(DEFAULT_SONJA_CARES_USERS_PHARMACY_CONTENT.modal);
          }

          // Check if the consult is urgent, and if so, only show the options that are marked for urgent visits
          const service = await ServicesService.getServiceForUser({
            userId: "me",
            serviceType: props.condition || "",
          });
          setIsUrgentVisit(service?.condition?.is_urgent || false);

          // Auto select Tower Pharmacy if the user's default pharmacy is Tower Pharmacy
          const addresses = await dispatch.addresses.fetchAddresses({ type: "PHARMACY" });
          if (addresses && addresses.length > 0 && preferred_pharmacy !== "alpha") {
            const defaultPharmacy = addresses.find((pharmacy: Address) => pharmacy.default);
            if (
              defaultPharmacy &&
              defaultPharmacy.name?.toUpperCase() === sonjaCaresPharmacy.name &&
              defaultPharmacy.zipcode === sonjaCaresPharmacy.zip
            ) {
              setPreferredPharmacy("tower");
            }
          }
        } catch (error) {
          setPageContent(DEFAULT_SONJA_CARES_USERS_PHARMACY_CONTENT);
          setModalContent(DEFAULT_SONJA_CARES_USERS_PHARMACY_CONTENT.modal);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    initializePageContent();
  }, [isSonjaCaresPatient]);

  const onSelect = async (value: string) => {
    try {
      if (value === "tower") {
        setIsLoading(true);
        const response = await EhrService.searchPharmacies(sonjaCaresPharmacy);
        if (response && response.length > 0) {
          await EhrService.setDefaultPharmacy({
            userId: currentUser.id,
            requestBody: {
              athena_pharmacy: response[0],
            },
          });
          dispatch.addresses.fetchAddresses({ type: "PHARMACY" });
          formProps.form.change("preferred_pharmacy", "external");
        } else {
          formProps.form.change("preferred_pharmacy", value);
        }
      } else {
        formProps.form.change("preferred_pharmacy", value);
      }
      props.nextClick();
    } catch (err) {
      Sentry.captureException(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOptionKeyDown = (
    event: React.KeyboardEvent<HTMLButtonElement>,
    optionValue: string,
  ) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      onSelect(optionValue);
    }
  };

  const handleLinkKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      setModalOpen(true);
    }
  };

  return (
    <div className="w-full mt-6">
      <Loader show={isLoading}>
        <PharmacyDifferencesModal
          isOpen={modalOpen}
          setIsOpen={setModalOpen}
          content={modalContent as PharmacyModalContent}
        />
        <div className="hidden">
          <DynamicField field={field} />
        </div>
        {isSonjaCaresPatient && pageContent ? (
          <div className="mb-2">
            <Typography variant="h3" className="mb-4">
              {pageContent.title}
            </Typography>
            {pageContent.options
              .filter((option) => (isUrgentVisit ? option.showInUrgentVisits : true))
              .map((option) => {
                const isTower = option.value === "tower";
                return (
                  <button
                    key={option.value}
                    className={cn("p-4 mb-6 rounded w-full flex flex-col", {
                      "bg-sand-60 border border-sand-60": preferredPharmacy === option.value,
                      "bg-transparent border border-grey-100": preferredPharmacy !== option.value,
                    })}
                    tabIndex={0}
                    onKeyDown={(event) => handleOptionKeyDown(event, option.value)}
                    onClick={(e) => {
                      e.preventDefault();
                      onSelect(option.value);
                    }}
                    aria-label={option.value}
                  >
                    {option.badge && (
                      <div className="mb-2">
                        <BadgePdb variant="success" label={option.badge} />
                      </div>
                    )}
                    <div className="flex flex-col justify-between items-start">
                      <div className={cn("font-medium mb-3", isTower && "text-green")}>
                        {option.title}
                      </div>
                    </div>
                    <Typography
                      variant="body2"
                      className={cn("text-left", isTower && "text-green")}
                    >
                      {option.description}
                    </Typography>
                  </button>
                );
              })}
          </div>
        ) : (
          map((field as any)?.options, (option: any) => {
            const isAlpha = option.value === "alpha";
            return (
              <button
                key={option.value}
                className={cn("p-4 mb-6 rounded w-full", {
                  "bg-sand-60 border-2 border-sand-60": preferredPharmacy === option.value,
                  "bg-transparent border-2 border-grey-100": preferredPharmacy !== option.value,
                })}
                tabIndex={0}
                onKeyDown={(event) => handleOptionKeyDown(event, option.value)}
                onClick={(e) => {
                  e.preventDefault();
                  onSelect(option.value);
                }}
                aria-label={optionLabels[option.value]}
              >
                <div className="flex flex-col justify-between items-start">
                  <div className={cn("font-medium mb-3", isAlpha && "text-green")}>
                    {optionLabels[option.value]}
                  </div>
                </div>
                <Typography variant="body2" className="text-left">
                  {optionDescriptions[option.value]}
                </Typography>
              </button>
            );
          })
        )}
        <Line />
        <IconTextChevron
          planAction=""
          text="Learn more about your options"
          icon="/assets/question-icon.svg"
          iconAlt="Help Icon"
          iconWidth={24}
          onClick={() => setModalOpen(true)}
          onKeyDown={handleLinkKeyDown}
          role="link"
        />
        <Line />
        <button className="btn btn-primary w-full mt-10 mb-5" disabled={props.nextDisabled}>
          {props.nextLabel || "Next"}
        </button>
      </Loader>
    </div>
  );
};

export default Pharmacy;
