/** @format */

import React from "react";

import { BackButtonHeading } from "src/components/_buttons/BackButtonHeading";
import { PageSectionTitle } from "src/components/PageSectionTitle";
import { PageWrapper } from "src/components/PageWrapper";

const SelfCare = () => {
  return (
    <PageWrapper cxMaxWidth={"w-full"} cxPadding={"pt-3 pb-3"} bgColor={"bg-cloud-lighter"}>
      <div className="px-4 max-w-xl mx-auto">
        {/* ImageHeading component, will be replaced once available with props passed on develop */}

        <div className="text-center pb-8">
          <BackButtonHeading title="Self Care Guide" />
          <img alt="" role="presentation" className="mx-auto mb-6 h-12" src="/assets/hands.svg" />
          <p>
            Self care can be as effective as medication when treating depression and anxiety. Here
            are some tips from your Alpha Providers.
          </p>
        </div>

        <div className="mx-auto max-w-lg ">
          <PageSectionTitle title="Self Care Tips" cxMargin="m-0 mb-4" />
          <div className="p-6 bg-white text-sm mb-8">
            <p className="mb-6">
              <b>Social interaction</b> - like getting together with a friend for dinner or a walk.
            </p>
            <p className="mb-6">
              <b>Physical activity</b> - yoga, hiking, or something that allows you to get some
              fresh air.
            </p>
            <p className="mb-6">
              <b>A regular schedule</b> - sleep for at least 8 hours per night.
            </p>
            <p className="mb-6">
              <b>
                <a
                  href="https://store.samhsa.gov/sites/default/files/d7/priv/sma16-4958.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#1E88E5" }}
                  aria-describedby="new-window-2"
                >
                  Wellness worksheets
                </a>
              </b>{" "}
              - change habits that make you unsatisfied and implement behavior change programs.
            </p>
          </div>
          <PageSectionTitle
            title="Possible Triggers to Identify &amp; Remove"
            cxMargin="m-0 mb-4"
          />
          <div className="p-6 bg-white text-sm">
            <ul className="pl-4">
              <li className="mb-6">Caffeine</li>
              <li className="mb-6">Stimulants</li>
              <li className="mb-6">Nicotine</li>
              <li className="mb-6">Dietary triggers</li>
              <li className="mb-6">Stress</li>
              <li className="mb-6">
                Complete wellness worksheets to help cultivate behavior change
              </li>
            </ul>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default SelfCare;
