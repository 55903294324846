/** @format */
import React from "react";

import classNames from "classnames";

export enum InformationalCardTypeEnum {
  neutral = "Neutral",
  brand = "Brand",
  outline = "Outline",
  warning = "Warning",
  error = "Error",
}

enum InformationalCardIconEnum {
  information = "/assets/information-icon.svg",
  informationWhite = "/assets/information-white-icon.svg",
  warning = "/assets/warning-icon.svg",
}

interface InformationalCardProps {
  /**
   * Type of the Card. By default it's Neutral.
   */
  type?: "Neutral" | "Brand" | "Outline" | "Warning" | "Error";

  /**
   * Optional. The title of the card.
   */
  title?: string;

  /**
   * Optional. Sets whether the card should display an icon, either the default or one passed by prop. By default it's true.
   */
  showIcon?: boolean;

  /**
   * Optional. Overwrites the default icon.
   */
  icon?: string;

  /**
   * The content of the card. It can be just a string or another component.
   */
  content: string | React.ReactNode;

  containerClasses?: string;
}

const InformationalCard = ({
  type = "Neutral",
  title,
  showIcon = true,
  icon,
  content,
  containerClasses,
}: InformationalCardProps): JSX.Element => {
  const getBackgroundColor = (cardType?: string) => {
    switch (cardType) {
      case InformationalCardTypeEnum.neutral:
        return "bg-grey-10";
      case InformationalCardTypeEnum.brand:
        return "bg-cloud-light-40";
      case InformationalCardTypeEnum.outline:
        return "";
      case InformationalCardTypeEnum.warning:
        return "bg-warning-2";
      case InformationalCardTypeEnum.error:
        return "bg-error-2";
      default:
        return "bg-grey-10";
    }
  };

  const getIcon = (cardType?: string) => {
    switch (cardType) {
      case InformationalCardTypeEnum.warning:
        return InformationalCardIconEnum.warning;
      case InformationalCardTypeEnum.error:
        return InformationalCardIconEnum.informationWhite;
      case InformationalCardTypeEnum.brand:
        return undefined;
      default:
        return InformationalCardIconEnum.information;
    }
  };

  const getTextColor = (cardType?: string) => {
    switch (cardType) {
      case InformationalCardTypeEnum.error:
        return "text-white";
      case InformationalCardTypeEnum.brand:
        return "";
      default:
        return "text-forest-120";
    }
  };

  return (
    <div
      className={classNames(
        "rounded p-4",
        getBackgroundColor(type),
        type === "Outline" && "border border-grey-100",
        containerClasses,
      )}
    >
      {(icon || showIcon || title) && (
        <span className="flex flex-row justify-center mb-4 ">
          {(icon || showIcon) && (
            <img
              alt="Card icon"
              height="24px"
              width="24px"
              src={icon || getIcon(type)}
              className="mr-4"
            />
          )}
          <h4
            className={classNames(
              "flex flex-1 text-base font-medium align-middle",
              getTextColor(type),
            )}
            style={{ color: type === "Brand" ? "#004F47" : "" }}
          >
            {title}
          </h4>
        </span>
      )}
      {content && React.isValidElement(content) ? (
        content
      ) : (
        <p
          className={classNames(
            "text-base font-normal text-forest-120",
            type === "Error" ? "text-white" : "text-forest-120",
          )}
        >
          {content}
        </p>
      )}
    </div>
  );
};

export default InformationalCard;
