/** @format */
import React from "react";
import { useFormState } from "react-final-form";

import { Typography } from "@alphamedical/components";

import { STATES_FULL } from "src/v2/constants";

interface StepDescriptionProps {
  title: string;
  stepText: string;
  stepInfo?: string;
  replaceState?: boolean;
  iconUrl?: string;
}

export const StepDescription = ({
  replaceState = false,
  stepInfo,
  stepText,
  title,
  iconUrl,
}: StepDescriptionProps) => {
  const formState = useFormState();
  const values = formState.values;

  const stateCode = values["consult-state"];
  const stateName = STATES_FULL.find((value) => value.value === stateCode)?.label || stateCode;

  const stepTitle = replaceState ? title.replace("[stateName]", stateName) : title;
  return (
    <div>
      <Typography variant="h2">{stepTitle}</Typography>
      <div className="mt-4">
        <div>{stepText}</div>
        {stepInfo && (
          <Typography className="mt-4 text-grey-160" variant="caption">
            {stepInfo}
          </Typography>
        )}
        {iconUrl && (
          <div className="mt-4 p-8 bg-cloud-light-60 flex items-center justify-center">
            <img style={{ height: "100px" }} src={iconUrl} alt={`${stepTitle} icon`} />
          </div>
        )}
      </div>
    </div>
  );
};
