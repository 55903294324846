/** @format */

import React from "react";
import { Form } from "react-final-form";

import type { IProvidedProps } from "@peacechen/google-maps-react";
import { GoogleApiWrapper } from "@peacechen/google-maps-react";
import type { FormApi } from "final-form";
import { isEmpty } from "lodash";

import { TextFieldNew } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import { DynamicField } from "src/components/DynamicForm";
import config from "src/config";
import { STATES_FULL } from "src/v2/constants";

interface SearchAddressFormProps extends IProvidedProps {
  fields: any;
  button: string;
  initialValues: any;
  onSubmit: any;
  children?: React.ReactNode;
}

const SearchAddressForm = (props: SearchAddressFormProps) => {
  const formRef = React.useRef<FormApi<any, Partial<any>>>();
  const { google, initialValues, onSubmit, button } = props;
  const fields = props.fields.reduce((_fields: any, field: any) => {
    if (field["field"] === "state") field.options = STATES_FULL;
    _fields[field.field] = field;

    return _fields;
  }, {});

  const enablePlaceApi = !isEmpty(config.googleAPIKey);
  const enableAutocomplete = enablePlaceApi && !!fields.autocomplete;

  const autoCompleteRef = React.useRef<HTMLInputElement>(null);
  const addressRef = React.useRef<HTMLInputElement>(null);

  const fillInAddress = (autoCompleteInput: any) => {
    const place = autoCompleteInput.getPlace();
    const addressString: string[] = [];

    if (place.address_components) {
      const addressComponents = place.address_components;
      addressComponents.forEach((component: any) => {
        if (component.types.indexOf("street_number") !== -1) {
          addressString.push(`${component.short_name}`);
        }
        if (component.types.indexOf("route") !== -1) {
          addressString.push(" ", component.short_name);
          formRef.current?.change(fields.address.key, addressString.join(""));
        }
        if (component.types.indexOf("locality") !== -1) {
          formRef.current?.change(fields.city.key, component.long_name);
        }
        if (component.types.indexOf("administrative_area_level_1") !== -1) {
          formRef.current?.change(fields.state.key, component.short_name);
        }
        if (component.types.indexOf("postal_code") !== -1) {
          formRef.current?.change(fields.zipcode.key, component.short_name);
        }
      });
    }

    if (place.name) {
      formRef.current?.change(fields.address.key, place.name);
    }

    formRef.current?.change(fields.autocomplete.key, "");
    addressRef.current?.focus();
  };

  React.useLayoutEffect(() => {
    const initializeAutocomplete = () => {
      if (autoCompleteRef && autoCompleteRef.current) {
        const autoCompleteInput = new google.maps.places.Autocomplete(autoCompleteRef.current, {
          types: ["address"],
          componentRestrictions: { country: "us" },
        });

        if (autoCompleteInput) {
          autoCompleteInput.setFields(["address_component"]);
          autoCompleteInput.addListener("place_changed", () => fillInAddress(autoCompleteInput));
        }
      }
    };

    if (autoCompleteRef.current && google && google.maps) {
      initializeAutocomplete();
    }
  }, [google]);

  React.useEffect(() => {
    if (!addressRef.current?.value) {
      autoCompleteRef.current?.focus();
    }
  }, [addressRef.current?.value]);

  const onFormSubmit = (values: any) => {
    return onSubmit(values);
  };

  return (
    <Form onSubmit={onFormSubmit} initialValues={initialValues}>
      {({ handleSubmit, submitting, form }) => {
        const formState = form.getState();
        if (form && !formRef.current) {
          formRef.current = form;
        }
        return (
          <div className="flex-1 text-sm sm:text-base overflow-hidden">
            {fields.name && <DynamicField field={fields.name} />}

            <div
              style={{
                display:
                  !formState.values[fields.address.key] && enableAutocomplete ? "block" : "none",
              }}
            >
              <TextFieldNew
                name={fields.autocomplete.key}
                className={`w-full py-3`}
                {...fields.autocomplete}
                ref={autoCompleteRef}
                placeholder={fields.autocomplete.label}
                autocomplete="new-password"
                onBlur={(event: any) => {
                  if (event.currentTarget.value) {
                    form.change(fields.address.key, event.currentTarget.value);
                  }
                }}
              />
            </div>

            <div
              style={{
                display:
                  formState.values[fields.address.key] || !enableAutocomplete ? "block" : "none",
              }}
            >
              <TextFieldNew
                name={fields.address.key}
                className={`w-full py-3`}
                placeholder={fields.address.label}
                {...fields.address}
                ref={addressRef}
                onChange={(event) => {
                  if (!event.currentTarget.value) {
                    autoCompleteRef.current?.focus();
                  }
                }}
              />
            </div>

            <DynamicField field={fields.address2} />
            <DynamicField field={fields.city} />
            <div className="flex justify-between space-x-3">
              <div className="flex-1 mr-3">
                <DynamicField field={fields.state} />
              </div>
              <div className="flex-1 ml-3">
                <DynamicField field={fields.zipcode} />
              </div>
            </div>
            {fields.phone && <DynamicField field={fields.phone} />}
            {props.children}
            <ButtonPdb
              variant="primary"
              disabled={submitting}
              className="mt-6 w-full"
              onClick={handleSubmit}
            >
              {button}
            </ButtonPdb>
          </div>
        );
      }}
    </Form>
  );
};

export default GoogleApiWrapper((_props: any) => ({
  apiKey: config.googleAPIKey || "",
  libraries: ["places"],
}))(SearchAddressForm);
