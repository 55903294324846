/** @format */

import type React from "react";

import { map } from "lodash";

interface DropdownProps {
  value?: string;
  onChange: (value: string) => void;
  options?: { label: string; value: any }[];
  placeholder: string;
}

export const Dropdown: React.FC<DropdownProps> = ({ value, onChange, options, placeholder }) => (
  <div className="relative">
    <select
      placeholder={placeholder}
      className={`block appearance-none py-3 px-4 pr-8
                  bg-grey-1 text-primary
                  leading-tight text-sm
                  w-full`}
      onChange={(e) => onChange(e.target.value)}
      value={value}
    >
      <option value={placeholder}>{placeholder}</option>
      {map(options, (option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
    <div
      className={`
                pointer-events-none absolute
                inset-y-0 right-0 flex items-center px-2 text-grey-darker
              `}
    >
      <div className="fas fa-chevron-down text-forest-40 mx-2" />
    </div>
  </div>
);
