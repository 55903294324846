/** @format */

import React from "react";

import { deleteFile } from "src/components/Chat/utils";
import config from "src/config";

interface UploadedAttachmentProps {
  attachments: Array<any>;
  setAttachments: (attachments: Array<any>) => void;
}

const UploadedAttachments = ({ attachments, setAttachments }: UploadedAttachmentProps) => {
  return (
    <div className="divide-y divide-dashed w-4/5 ml-5 flex flex-col space-y-2">
      {attachments.map((attachment: any) => (
        <div key={attachment}>
          <div className="flex flex-row justify-between">
            <div className="flex flex-row">
              <img src="/assets/attach-icon.svg" alt="File attachment: " className="mr-4"></img>
              {`${config.apiUrl}/files/${attachment}`}
            </div>
            <button
              type="button"
              onClick={() => deleteFile(attachment, attachments, setAttachments)}
            >
              x
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UploadedAttachments;
