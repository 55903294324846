/** @format */

import React from "react";

import type { ChartType } from "src/v2/components/Charts/types";
import type { Record as RecordProps } from "src/v2/models/record";

import PatientChart from "src/v2/components/Charts/PatientChart";

export interface DataPointProps {
  x: Date;
  y: number;
  description: string;
}

const Record = (props: RecordProps) => {
  const { title, severity_info, assessment_type } = props;
  const [showInfo, setShowInfo] = React.useState(false);

  return (
    <div className="border-b border-primary my-12">
      <div className="bg-white mb-4">
        <PatientChart chartType={assessment_type as ChartType} />
      </div>
      <h5 className="text-base font-semibold text-center pb-8">Your Scores for {title}</h5>
      <div className="pb-8">
        <div
          className="cursor-pointer flex justify-between items-center"
          onClick={() => setShowInfo(!showInfo)}
        >
          <p className="m-0">What does my score mean?</p>
          <img
            alt="Reveal information about your score"
            src={"/assets/carrot-down.svg"}
            style={{ transform: showInfo ? "rotate(180deg)" : "" }}
          />
        </div>
        {showInfo && (
          <div>
            <p className="my-8">
              These measures are an important piece of information about you. They are not meant to
              represent your entire clinical picture or minimize your thoughts, feelings, or
              experiences.
            </p>
            {severity_info.map((info) => (
              <div className="flex mb-6" key={info.score_range}>
                <p className="text-primary-40 text-xl font-semibold min-w-24 mt-1">
                  {info.score_range}
                </p>
                <div>
                  <h5 className="mb-2">{info.title}</h5>
                  <p className="m-0">{info.description}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Record;
