/** @format */

import React from "react";
import { Field } from "react-final-form";

import { FieldError } from "./error";

interface MultiSelectFieldProps {
  name: string;
  options: { value: string; label: string }[];
}

export const MultiSelectField = (props: MultiSelectFieldProps) => (
  <>
    {props.options.map((option, idx) => (
      <Field
        key={`${props.name}-checkbox-${idx}`}
        name={props.name}
        type="checkbox"
        value={option.value}
      >
        {({ input, meta }) => {
          return (
            <>
              <label className="block normal-case field flex items-center">
                <input type="checkbox" id={input.name} {...input} className="display-none" />
                <div
                  tabIndex={0}
                  title={option.label}
                  onKeyPress={(e: any) => {
                    if (e.which === 32) {
                      e.target.parentElement.click();
                    }
                  }}
                  className={`
                    flex items-center justify-center
                    checkbox-field w-8 h-8 bg-sky-60
                    border-8 border-sky-60 text-primary
                    ${(input.checked && `fas fa-check`) || ""}
                  `}
                />
                <div
                  className="checked-field p-4 t-2 rounded-sm"
                  dangerouslySetInnerHTML={{ __html: option.label }}
                />
              </label>
              {meta.error && meta.touched && <div className="text-red">{meta.error}</div>}
            </>
          );
        }}
      </Field>
    ))}
    <FieldError name={props.name} />
  </>
);
