/** @format */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Paper } from "src/v2/designSystem";

import type { RecurlyInvoiceSchema } from "src/api";

import { RecurlyService } from "src/api";
import { PageWrapper } from "src/components/PageWrapper";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useCurrentUser } from "src/v2/models/profile";

import InvoiceDetailsPage from "./InvoiceDetailsPage";
import InvoicesListView from "./InvoicesListView";

const InvoicesPage = () => {
  const currentUser = useCurrentUser();
  const [invoices, setInvoices] = useState<RecurlyInvoiceSchema[]>();
  const { uuid } = useParams<{ uuid: string }>();

  useEffect(() => {
    RecurlyService.getRecurlyUserInvoices({ userId: currentUser.id }).then((res) =>
      setInvoices(res),
    );
  }, []);

  const selectedInvoice = uuid ? invoices?.find((i) => i.uuid === uuid) : undefined;

  useTopBar({
    title: selectedInvoice ? `Invoice #${selectedInvoice.number} details` : "Invoices",
    variant: "nested",
    showBackButton: true,
  });

  return (
    <PageWrapper cxPadding="px-0" fitToPageHeight showIntercom>
      <Paper
        elevation={0}
        rounded={false}
        className="flex-1 mx-auto h-screen w-full max-w-2xl pb-24"
      >
        {!selectedInvoice ? (
          invoices && <InvoicesListView invoices={invoices} />
        ) : (
          <InvoiceDetailsPage selectedInvoice={selectedInvoice} />
        )}
      </Paper>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(InvoicesPage);
