/** @format */

import type { RouteComponentProps } from "react-router-dom";

import React from "react";

import { FORM_ERROR } from "final-form";
import _ from "lodash";

import type { DynamicFormPage } from "src/components/DynamicForm/types";
import type { Consultation } from "src/v2/types/consultation";

import { Analytics } from "src/analytics";
import { DynamicForm } from "src/components/DynamicForm/DynamicForm";
import { Loader } from "src/components/Loader";
import { formatText } from "src/content/contentUtils";
import membershipSignup from "src/content/v2/routes/MembershipSignup/membershipSignup";
import { useChildRedirect, useQueryState, useThunk } from "src/utils/hooks";
import { useFeatureFlags } from "src/utils/hooks/useFeatureFlags";
import { SnackBar } from "src/v2/components";
import OutlineForm from "src/v2/components/OutlineForm";
import { MonthlyMembershipSignupComplete } from "src/v2/components/Page/Complete";
import { useStoreActions, useStoreDispatch, useStoreState } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";
import { conditionMap } from "src/v2/routes/MyCases/MyCasesV2";

const MEMBERSHIP_OUTLINE_KEY = "membership";
const MONTHLY_MEMBERSHIP_OUTLINE_KEY = "monthly-membership";

const MembershipSignUp = (props: RouteComponentProps) => {
  useChildRedirect();
  const useMonthlyMembership = useFeatureFlags("enableMonthlyMembership");
  const outlineKey = useMonthlyMembership ? MONTHLY_MEMBERSHIP_OUTLINE_KEY : MEMBERSHIP_OUTLINE_KEY;
  const [couponCode] = useQueryState(props.history, "coupon_code");
  const currentOutline = useStoreState((state) => state.outlines.outlines[outlineKey]);
  const { membership } = useStoreState((state) => state.membership);
  const currentUser = useCurrentUser();
  const { pcp } = useStoreState((state) => state.primaryCareProvider);
  const dispatch = useStoreDispatch();
  const [loadingOutline] = useThunk(() => dispatch.outlines.fetchOutline({ key: outlineKey }));
  const [loadingMembership] = useThunk(dispatch.membership.fetchOrCreateMembership);
  const [loadingBillingInfo] = useThunk(dispatch.billingInfo.fetchBillingInfo);
  const [loadingPCP] = useThunk(dispatch.primaryCareProvider.fetchPCP);
  const { visits } = useStoreState((state) => state.consults);

  const loading = loadingOutline || loadingMembership || loadingBillingInfo || loadingPCP;

  const [redirect, setRedirect] = React.useState("/my-cases");
  const [signupCompleteDetails, setSignupCompleteDetails] = React.useState("");
  const [signupCompleteContinueVisit, setSignupCompleteContinueVisit] = React.useState("");
  const [completionButtonText, setCompletionButtonText] = React.useState(
    membershipSignup.visitFlow.noVisitButtonText,
  );
  const { addSnack } = useStoreActions((actions) => actions.snacks);

  // Hotfix redirect to fix badly formed url params in promo email
  React.useEffect(() => {
    if (
      props.location.search ===
      "?coupon_code=gomoms50?utm_source=email&utm_campaign=mothers_day_50off"
    ) {
      window.location.href = `${props.location.pathname}?coupon_code=gomoms50&utm_source=email&utm_campaign=mothers_day_50off`;
    }
    dispatch.consults.fetchAllConsults();
  }, []);

  React.useEffect(() => {
    if (visits.length > 0) {
      let currentVisit: Consultation | null = null;
      const currentVisitId = localStorage.getItem("currentConsultationId");
      if (currentVisitId) {
        currentVisit = visits.find((visit) => visit.id === Number(currentVisitId)) || null;
      }

      let lastConsult: Consultation | null = null;
      visits.forEach((visit) => {
        if (visit.status === "STARTED") {
          if (lastConsult === null) {
            lastConsult = visit;
          } else {
            if (lastConsult.id < visit.id) {
              lastConsult = visit;
            }
          }
        }
      });

      if (!currentVisit) {
        currentVisit = lastConsult;
      }

      if (currentVisit) {
        const conditionName = currentVisit?.subscription.condition.name || "";
        setSignupCompleteDetails(formatText(membershipSignup.visitFlow.details, { conditionName }));
        setSignupCompleteContinueVisit(
          formatText(membershipSignup.visitFlow.visitText, { conditionName }),
        );
        setCompletionButtonText(membershipSignup.visitFlow.firstButtonText);

        const href = `/${currentVisit.consult_type === "VISIT" ? "visit" : "consultation"}/${
          conditionMap[currentVisit.subscription.condition.key] ||
          currentVisit?.subscription.condition.key
        }`;
        setRedirect(href);
      }
    }
  }, [visits.length]);

  const getCurrentAnswers = (): object => {
    const { pii } = currentUser;
    return {
      ...pii.address,
      dob: pii.dob,
      sex: pii.sex,
      first_name: pii.first_name,
      last_name: pii.last_name,
      phone_number: pii.phone_number,
      sms_allowed: pii.sms_allowed,
      license_photo: pii.license_photo,
      pcp: pcp || {},
      has_pcp: (!!pcp && !!pcp.id) || undefined,
      preferred_firstname: pii.preferred_firstname,
      preferred_lastname: pii.preferred_lastname,
      preferred_pronouns: pii.preferred_pronouns,
      prefix: pii.prefix,
    };
  };

  const onPageSubmit = (
    _page: DynamicFormPage,
    changedValues: any,
    allValues: Partial<Record<string, any>>,
    isLastPage: boolean,
  ): Promise<any> => {
    const { pcp: updatedPCP, ...rest } = changedValues;
    const promises: Promise<any>[] = [];

    if (Object.keys(rest).length) {
      if (_.get(rest, "sms_allowed", []).length > 0 && rest.sms_allowed[0] === "yes") {
        rest.sms_allowed = true;
      } else {
        rest.sms_allowed = false;
      }
      if (!rest.address2) {
        rest.address2 = "";
      }
      if ("payment" in rest) {
        delete rest.payment;
      }
      if ("consent" in rest) {
        delete rest.consent;
      }
      if ("payment-plan" in rest) {
        delete rest["payment-plan"];
      }
      promises.push(dispatch.profile.updatePii(rest));
    }

    if (!!updatedPCP && Object.keys(updatedPCP).length) {
      promises.push(dispatch.primaryCareProvider.createOrUpdatePCP(updatedPCP));
    }

    if (isLastPage) {
      const healthGoals: string[] = allValues["membership-goal"] || [];
      if (allValues["membership-goal-other"]) {
        healthGoals.push(allValues["membership-goal-other"]);
      }
      const hasPCP: string = allValues["has-pcp"] || "";
      const consultState: string = allValues["consult-state"] || "";

      // default paymentPlan as yearly if not available
      const paymentPlan: string = allValues["payment-plan"] || "yearly";

      promises.push(
        dispatch.membership
          .signupMembership({
            healthGoals: healthGoals.join(", "),
            has_pcp: hasPCP,
            consult_state: consultState,
            payment_plan: paymentPlan,
          })
          .then(() => {
            // fire analytics
            Analytics.trackEvent({ category: "Membership", action: "SignUp", name: paymentPlan });
          })
          .catch(() => {
            addSnack({
              type: "error",
              message: "Something went wrong. Please try again or add a new payment method. ",
              id: "membership-signup-failure",
              delay: 5,
            });
          }),
      );
    }

    return new Promise((resolve) => {
      Promise.all(promises)
        .then((values) => {
          resolve(values);
        })
        .catch((err) => {
          const errorMsg = _.get(err, "response.data.description");
          resolve({ [FORM_ERROR]: errorMsg });
        });
    });
  };

  React.useEffect(() => {
    if (!!couponCode && couponCode !== "") {
      localStorage.setItem("membership_signup.coupon_code", couponCode);
    }
  }, [couponCode]);

  const useNewForm = false;

  const firstName = currentUser?.pii?.preferred_firstname || currentUser?.pii?.first_name || "";
  const signupCompleteTitle = !firstName
    ? "You're in! \n Welcome to Hello Alpha."
    : formatText(membershipSignup.visitFlow.title, { patientName: firstName });

  return (
    <>
      <Loader show={!membership?.active && loading} />
      <SnackBar />
      {!!currentOutline && !!membership && !membership.active && !useNewForm && (
        <OutlineForm
          initialValues={getCurrentAnswers()}
          outline={currentOutline}
          onPageSubmit={onPageSubmit}
        />
      )}
      {!!currentOutline && !!membership && !membership.active && useNewForm && (
        <DynamicForm
          initialValues={getCurrentAnswers()}
          outline={currentOutline}
          onPageSubmit={({ currentPageIdx, hasNextPage, nextPage, values, changedValues }) => {
            const page: DynamicFormPage = currentOutline.pages[currentPageIdx];
            return onPageSubmit(page, changedValues, values, !hasNextPage).then(() => {
              if (hasNextPage) {
                nextPage();
              }
            });
          }}
        />
      )}
      {!!membership && membership.active && (
        <MonthlyMembershipSignupComplete
          flow="visit"
          conditionKey="membership"
          title={signupCompleteTitle}
          details={signupCompleteDetails}
          visitMessage={signupCompleteContinueVisit}
          firstButtonText={completionButtonText}
          firstHref={redirect}
        />
      )}
    </>
  );
};

export default MembershipSignUp;
