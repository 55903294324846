/** @format */

import config from "src/config";

export default {
  /**
   * Welcome intro/splash screen
   */
  screen1: {
    title: "Welcome to Alpha",
    b2bTitle:
      "Welcome to Alpha! You have access to care for over 100 medical conditions, all online.",
    subtitle: "The first stop for your healthcare needs, from an expert in Women's Health",
    submitButton: "Get Started",
  },

  /**
   * Welcome page 2, personalized, asks for name.
   */
  screen2: {
    title: "Let's get to know each other.",
    nameEntryPlaceholder: "What's your first name?",
    footerNote: "Join over 50,000 Alpha patients accessing affordable healthcare.",
    submitButton: "Next",
  },

  /**
   * Meet and greet screen introduces the patient to alpha.
   */
  screen3: {
    titleBackWithName: "It's good to see you again,",
    titleBackWithSansName: "It's good to see you again!",
    titleWithName: "We're pleased to meet you,",
    titleSansName: "We're pleased to see you here.",
    body: "We treat over **100 medical conditions** online: from primary care to women's health, mental health, and more.",
    submitButton: "Next",
  },
  /**
   * Last page of the onboarding welcome experience with a video of Dr. J.
   */
  screen4: {
    title: "Here's a little bit about us and how we personalize your care.",
    videoTranscript: [
      "Hi, I'm Dr Mary Jacobson. My patients call me Dr J. I'm Chief Medical Officer at Alpha.",
      "Alpha is a virtual primary care platform. All of your medical visits happen online, even on your phone, at your convenience and on your schedule. There are no appointments and no videos - all communication is handled through our secure messaging platform.",
      "All Alpha Providers are experts in women's health, which is important because hormonal fluctuations at different life stages can affect different health conditions.",
      "At Alpha, we treat the whole you. We strive to be the first stop for all of your healthcare needs and treat more than 100 medical conditions online, from physical to mental health.",
      "You'll receive care from the same provider at every visit, so your care is continuous and coordinated.  No need to re-explain your medical history.  Also, you can message with your care team for support at any time.",
      "Thank you for choosing Alpha for your care, and letting us be a part of your healthcare journey.",
    ],
    videoId: "725448907",
    audioUrl: config.assetsUrl + "/data/DRJ-WELCOME-Audio(no-music).m4a",
    switchFromVideo: "Read text or listen to audio only ",
    switchFromAudio: "Watch the video or read text",
    switchFromText: "Watch the video or listen to audio only",
    readText: "Read text of the video script",
    playVideo: "Play Video",
    playAudio: "Play Audio",
    footerNote:
      "All Alpha Providers are experts in Women's Health, and you'll see the same Provider at every visit for continuous, coordinated care.",
    submitButton: "Start my Visit",
    b2bSubmitButton: "Next",
  },

  /**
   * Conditions for mapping and ordering
   */
  orderedConditionKeys: [
    "weight-loss",
    "mental-health",
    "birthcontrol",
    "vulvovaginitis",
    "herpes",
    "nail-infection",
    "asthma",
    "cystitis",
    "nutrition",
    "cold-and-flu",
    "menopause",
    "migraine",
    "skincare",
    "eyelashes",
    "pcos",
    /* Only showing the top 15 per the AC in the ticket **/
    // 'hypothyroidism',
    // 'womens-health',
    // 'hay-fever',
    // 'smoking-cessation',
    // 'skin-and-hair',
    // 'acid-reflux',
    // 'sti-testing-kit',
    // 'mens-health',
    // 'hairloss',
    // 'covid-19-treatment',
    // 'hyperlipidemia',
    // 'dermatitis',
    // 'diabetes',
    // 'endometriosis',
    // 'emergency-contraceptives',
    // 'preconception-counseling',
    // 'peds-itchy-rash',
    // 'abnormal-uterine-bleeding',
    // 'diaper-rash',
    // 'peds-covid-19-treatment',
    // 'postpartum-mastitis',
  ],
};
