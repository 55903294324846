/** @format */

import React from "react";

import ConditionScroller from "src/components/DynamicForm/Fields/CustomFields/WelcomeFlow/ConditionScroller";
import welcome from "src/content/welcome";
import { MarkdownText } from "src/v2/components/MarkdownText";
import { useStoreState } from "src/v2/models";

interface ConditionCarouselProps {
  name: string;
}

export const ConditionCarousel = ({ name }: ConditionCarouselProps) => {
  const profile = useStoreState((state) => state.profile.profile);
  const currentConsultation = useStoreState((state) => state.consults.currentConsultation);
  return (
    <div className="flex flex-1 flex-col justify-center mb-20">
      <div>
        <div>
          {profile?.meta &&
          profile.meta["has_seen_welcome_experience"] &&
          profile.meta["has_seen_welcome_experience"] === true ? (
            <h1 className="text-xl mb-2 text-center text-primary">
              {name
                ? `${welcome.screen3.titleBackWithName} ${name}`
                : welcome.screen3.titleBackWithSansName}
            </h1>
          ) : (
            <h1 className="text-xl mb-2 text-center text-primary">
              {name ? `${welcome.screen3.titleWithName} ${name}` : welcome.screen3.titleSansName}
            </h1>
          )}
        </div>
        <ConditionScroller
          className="welcome__fill-container-width h-40 my-10"
          currentConsultation={currentConsultation}
        />
      </div>
      <div>
        <div className="w-full flex flex-row justify-center mt-8">
          <div className="w-5/6">
            <p className="text-base text-primary text-center">
              <MarkdownText text={welcome.screen3.body} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
