/** @format */

import React from "react";
import { useHistory } from "react-router-dom";
import { components } from "react-select";
import AsyncSelect from "react-select/async";

import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import Fuse from "fuse.js";

import { Typography } from "@alphamedical/components";

import type { ServiceTypeCategory } from "src/api";
import type { Option } from "src/v2/models/record";

import { Analytics } from "src/analytics";
import { MEMBERSHIP_DETAILS } from "src/routes";
import { REQUIRED_PLUS_MEMBERSHIP_CONDITIONS } from "src/v2/constants";
import { useStoreState } from "src/v2/models";
import VisitLockedTooltip from "src/v2/routes/Visit/VisitLockedTooltip";

interface ServicePageHeaderProps {
  serviceLinks: ServiceTypeCategory[];
}

export const ServicePageHeader = ({ serviceLinks }: ServicePageHeaderProps) => {
  const membership = useStoreState((state) => state.membership.membership);

  const isActivePlusMember = membership?.plan_code === "alpha-membership-plus" && membership.active;
  const history = useHistory();
  const fuseOptions = {
    threshold: 0.38,
    ignoreLocation: true,
    keys: [
      { name: "title", weight: 0.5 },
      { name: "description", weight: 0.1 },
      { name: "meta_data", weight: 0.4 },
    ],
  };

  const fuse = new Fuse(serviceLinks, fuseOptions);

  const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
    const searchResult = fuse.search(inputValue);
    const searchOptions = searchResult.map((linkSearch: ServiceTypeCategory) => ({
      value: linkSearch.id,
      label: linkSearch.title,
    }));

    callback(searchOptions);
  };

  const customOption = ({ data, options }: any) => {
    const serviceLink = serviceLinks.find((link) => link.id === data.value);
    const conditionIsLocked =
      !isActivePlusMember &&
      REQUIRED_PLUS_MEMBERSHIP_CONDITIONS.includes(serviceLink?.service_key || "");
    const conditionCursor = conditionIsLocked ? "cursor-not-allowed" : "cursor-pointer";
    const conditionColor = conditionIsLocked ? "text-grey-160" : "text-forest-120";
    return (
      <div
        onClick={() => {
          if (conditionIsLocked) {
            return;
          }
          Analytics.trackContentInteraction("click", {
            name: "ExploreVisitsSearch",
            piece: serviceLink?.title || "null",
            target: serviceLink?.link ? serviceLink.link : "null",
          });
          history.push(serviceLink?.link as string);
        }}
        className={classNames(
          "p-4 hover:bg-forest-8 focus:bg-sand-60",
          options[options.length - 1].value !== serviceLink?.id && "border-b border-grey-100",
          `${conditionCursor} ${conditionColor}`,
        )}
      >
        <div className="flex justify-between">
          <Typography className={conditionColor} variant={"subtitle1"}>
            {serviceLink?.title}
          </Typography>
          {conditionIsLocked && <VisitLockedTooltip />}
        </div>
        <p className={"text-grey-160 mt-2"}>{serviceLink?.description}</p>
        {conditionIsLocked && (
          <div className={"cursor-pointer"} onClick={() => history.push(MEMBERSHIP_DETAILS)}>
            <Typography className={"mt-5 text-center"} variant={"hyperlink"}>
              Upgrade to Alpha Plus+ Plan
            </Typography>
          </div>
        )}
      </div>
    );
  };

  const Control = ({ children, ...props }: any) => {
    return (
      components.Control && (
        <components.Control {...props}>
          {!!children && (
            <FontAwesomeIcon icon={faSearch} className="text-2xl text-grey-100 mr-1" />
          )}
          {children}
        </components.Control>
      )
    );
  };

  return (
    <AsyncSelect
      className={"mb-6"}
      placeholder={"What are you looking for?"}
      styles={{
        control: (baseStyles, state) => {
          return {
            ...baseStyles,
            borderColor: state.isFocused ? "#1C4A46 !important" : "#AFB4B3",
            backgroundColor: "#F8FAFC",
            boxShadow: "none",
            borderWidth: "2px",
            paddingTop: "6px",
            paddingBottom: "6px",
            paddingRight: "8px",
            paddingLeft: "16px",
          };
        },
        menuList: (base) => {
          return {
            ...base,
            height: "min-content",
          };
        },
        placeholder: (baseStyles) => {
          return { ...baseStyles, color: "#AFB4B3FF" };
        },
      }}
      noOptionsMessage={() => "Your search returned no results"}
      components={{
        Option: customOption,
        Control,
        IndicatorSeparator: null,
        DropdownIndicator: null,
      }}
      loadOptions={loadOptions}
    />
  );
};
