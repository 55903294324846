/** @format */

import React from "react";

import type { FetchProfileResponse } from "src/v2/models/api_types";

import { PubNubClient } from "src/components/Chat/PubNubClient";
import { Loader } from "src/components/Loader";
import { useCheckAndSaveAssessment } from "src/utils/hooks";
import { useStoreActions, useStoreDispatch, useStoreState } from "src/v2/models";
import { AuthenticatedUserContext } from "src/v2/models/auth";
import { CurrentUserContext } from "src/v2/models/profile";

export const BasePage: React.FunctionComponent = (props) => {
  const dispatch = useStoreDispatch();
  const actions = useStoreActions((action) => action);
  const { profile, currentProfileId } = useStoreState((state) => state.profile);
  const { authenticatedUser } = useStoreState((state) => state.auth);

  React.useEffect(() => {
    if (!profile) {
      if (!currentProfileId || currentProfileId === "me") {
        dispatch.profile.fetchProfile({}).then((res: FetchProfileResponse) => {
          actions.profile.setCurrentProfileId({ userId: res.id });
        });
      } else {
        dispatch.profile.fetchProfile({ userId: currentProfileId });
      }
    }

    if (!authenticatedUser) {
      dispatch.auth.fetchAuthenticatedUser({});
    }
  }, []);
  useCheckAndSaveAssessment(authenticatedUser);

  if (!profile || !authenticatedUser) return <Loader show={true} />;
  return (
    <AuthenticatedUserContext.Provider value={authenticatedUser}>
      <CurrentUserContext.Provider value={profile}>
        <PubNubClient>{props.children}</PubNubClient>
      </CurrentUserContext.Provider>
    </AuthenticatedUserContext.Provider>
  );
};
