/** @format */

import type React from "react";

import { DynamicField } from "src/components/DynamicForm";

import MembershipConsent from "./membership-consent";

// Delete all of these docs
export const consentMap: Record<string, React.FC> = {
  "membership-consent": MembershipConsent,
};

// Delete
export const ConsentForm = (props: any) => {
  const { fields, formProps, nextClick, consentKey } = props;
  const { initialValues, values } = formProps;
  const ConsentFormBodyComponent = consentMap[consentKey];
  const consentField = fields[0];

  if (
    initialValues[consentField.key] === undefined &&
    values[consentField.key] === undefined &&
    consentField.defaultValue
  ) {
    formProps.form.change(consentField.key, consentField.defaultValue);
  }

  return (
    <>
      <ConsentFormBodyComponent />
      <div className="display-none">
        <DynamicField field={consentField} />
      </div>
      <button className="btn btn-primary w-full" onClick={nextClick}>
        I Agree
      </button>
    </>
  );
};
