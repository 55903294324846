/** @format */

import React from "react";
import { generatePath, Link, useHistory } from "react-router-dom";

import classnames from "classnames";

import { useWindowSize } from "@alphamedical/components";

import type { Consultation } from "src/v2/types/consultation";

import { ChannelLoader } from "src/components/Chat/ChannelLoader";
import { Button } from "src/v2/components/Button";
import { Nav } from "src/v2/components/Nav";
import { useStoreDispatch, useStoreState } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";

interface CompletePageProps {
  conditionKey?: string;
  title?: string;
  subtitle?: string;
  resetMembership?: boolean;
  consult?: Consultation;
  hideDashboardButton?: boolean;
  isSynchronous?: boolean;
}

interface MonthlyMembershipSignupCompleteProps {
  flow: "navigation" | "visit";
  conditionKey?: string;
  title: string;
  details?: string;
  visitMessage?: string;
  firstButtonText: string;
  firstHref: string;
  secondButtonText?: string;
  secondHref?: string;
  resetMembership?: boolean;
  icon?: string;
}

export const Complete: React.FC<CompletePageProps> = (props) => {
  const dispatch = useStoreDispatch();
  const currentUser = useCurrentUser();
  const [width] = useWindowSize();

  React.useEffect(() => {
    if (props.resetMembership) {
      dispatch.membership.clearMembership();
      dispatch.alerts.clearMembershipAlerts();
    }
    dispatch.channels.fetchChannels();
    localStorage.removeItem(`${props.conditionKey}:start_anyway`);
  }, []);

  const firstName = currentUser.pii.preferred_firstname || currentUser.pii.first_name;
  const isSynchronous = props.isSynchronous;
  const visitSubmittedText = isSynchronous
    ? "You've submitted your visit details."
    : "Your visit is complete.";

  return (
    <ChannelLoader>
      <div className="flex flex-col h-screen bg-cloud-light-20">
        <Nav />
        <div className={"flex"}>
          {width >= 640 && <div className={"w-64 flex-shrink-0 h-full border"}></div>}
          <div className={`sm:max-w-2xl pb-32 mx-auto ${width < 640 ? "px-10" : "px-6"} py-16`}>
            <div className="text-3xl">{firstName ? `Thanks, ${firstName}!` : "Thanks!"}</div>
            <div className="text-3xl mb-10 xl:mb-5">
              {props.title == null ? visitSubmittedText : props.title}
            </div>
            <div className="text-base flex-1 flex flex-col justify-around">
              {!!props.children && (
                <>
                  <div className="text-sm font-bold text-grey-dark my-4 uppercase">
                    {props.subtitle || isSynchronous ? "What happens next:" : "Here's what's next"}
                  </div>
                  {props.children}
                </>
              )}
              {!props.hideDashboardButton && (
                <Link
                  className="btn btn-primary flex-1 no-underline w-full mb-2 sm:mx-auto mt-5 sm:mt-10 sm:w-1/2"
                  to="/my-cases"
                >
                  Continue to Your Dashboard
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </ChannelLoader>
  );
};

export const MonthlyMembershipSignupComplete: React.FC<MonthlyMembershipSignupCompleteProps> = (
  props,
) => {
  const dispatch = useStoreDispatch();
  const [width] = useWindowSize();
  const history = useHistory();

  // Get user registration data
  const userRegistrationData = useStoreState((state) => state.profile.userRegistration);

  React.useEffect(() => {
    if (props.resetMembership) {
      dispatch.membership.clearMembership();
      dispatch.alerts.clearMembershipAlerts();
    }

    dispatch.channels.fetchChannels();
    localStorage.removeItem(`${props.conditionKey}:start_anyway`);

    // If the user has registration data but it's not completed, we need to skip this page and redirect them into account setup flow.
    if (userRegistrationData && !userRegistrationData.completed && userRegistrationData.id) {
      const url = generatePath("/form/:id", { id: userRegistrationData.id });
      const searchParams = new URLSearchParams({ form_type: "registration" });
      const queryString = searchParams.toString();

      // force patient back into account setup flow
      history.push({
        pathname: url,
        search: queryString,
      });
    }
  }, []);

  return (
    <ChannelLoader>
      <div className="flex flex-col h-screen bg-cloud-light-20">
        <div className={"flex"}>
          <Nav />
          {width >= 640 && <div className={"w-64 flex-shrink-0"}></div>}
          <div className="sm:max-w-2xl mx-auto w-full px-6 py-10">
            <div className="flex flex-col items-center ">
              <img className="mb-2 py-8" src={props.icon || "/assets/membership-checked.svg"} />
              <div className="text-xl leading-8 font-medium text-center mb-8 whitespace-pre-line">
                {props.title ? props.title : "You're in! Welcome to Hello Alpha."}
              </div>
              <div
                className={classnames(
                  "text-base leading-6 text-center font-normal",
                  props.flow === "visit" && "mb-10",
                )}
              >
                {props.details}
              </div>
              <div
                className={classnames(
                  "flex flex-col items-center p-6 w-full",
                  props.flow === "visit" && "border border-grey-100 rounded",
                )}
              >
                {props.flow === "visit" && (
                  <>
                    <div className="text-base leading-6 text-center font-medium">
                      {props.visitMessage}
                    </div>
                    <img className="pt-6 pb-3 w-10" src="/assets/membership-cross.svg" />
                  </>
                )}
                <Button
                  onClick={() => null}
                  bgColor="forest-100"
                  textColor="white"
                  href={props.firstHref}
                  extraClassNames={"rounded font-medium"}
                  hrefStyles="w-full mb-2"
                >
                  {props.firstButtonText}
                </Button>
                {props.flow === "navigation" && (
                  <Link
                    className="font-medium text-cornflower-100 pt-4"
                    to={props.secondHref || ""}
                  >
                    {props.secondButtonText}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ChannelLoader>
  );
};
