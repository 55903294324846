/** @format */

import React from "react";

import "./steps.scss";

export interface ConsultStage {
  key: string;
  title: string;
}

interface SideNavigationState {
  opacity: number;
  show: boolean;
}
interface SideNavigationProps {
  interstitial?: boolean;
  stages: ConsultStage[];
  currentPage: any;
}

const TIMEOUT_OPACITY = 300;
const TIMEOUT_INTERSTITIAL = 1200;
export class SideNavigation extends React.Component<SideNavigationProps, SideNavigationState> {
  constructor(props: SideNavigationProps) {
    super(props);
    this.state = {
      show: true,
      opacity: 0,
    };
  }

  showInterstitial = () => {
    setTimeout(() => {
      this.setState({ opacity: 100 });
    }, 0);
  };

  hideInterstitial = () => {
    setTimeout(() => {
      this.setState({ opacity: 0 });
    }, TIMEOUT_INTERSTITIAL);
  };

  componentDidMount() {
    this.showInterstitial();
    setTimeout(this.hideInterstitial, TIMEOUT_OPACITY);
  }

  componentDidUpdate(prevProps: SideNavigationProps, prevState: SideNavigationState) {
    const previousStageIdx = prevProps.stages.findIndex(
      (stage) => stage.key === prevProps.currentPage.stage,
    );
    const currentStageIdx = this.props.stages.findIndex(
      (stage) => stage.key === this.props.currentPage.stage,
    );

    if (previousStageIdx < currentStageIdx) {
      this.setState({ show: true, opacity: 0 }, () => {
        this.showInterstitial();
        setTimeout(this.hideInterstitial, TIMEOUT_OPACITY);
      });
    }
    if (!!prevState.opacity && !this.state.opacity) {
      setTimeout(() => this.setState({ show: false }), TIMEOUT_OPACITY);
    }
  }

  render() {
    const cxTransition = `t-${TIMEOUT_OPACITY / 100}`;
    const currentStageIdx = this.props.stages.findIndex(
      (stage) => stage.key === this.props.currentPage.stage,
    );
    const cxStep = `
      step-badge bg-cloud content-center
      flex flex-col w-12 h-12 justify-center
      rounded-full text-center mx-auto
      ${cxTransition}
    `;
    const cxContainer = `
      ${
        this.state.show
          ? `
          absolute z-10 inset-0
          t-${TIMEOUT_OPACITY / 100} opacity-${this.state.opacity}
          steps-container--${this.state.opacity === 100 ? "show" : "hide"}
        `
          : "hidden"
      }
      steps-container
      bg-sand-dark md:opacity-100
      md:relative md:flex sm:w-80 flex-col overflow-scroll
    `;
    const cxIcons = `
      bg-no-repeat self-end bg-contain sm:bg-cover
    `;

    return (
      <div className={cxContainer}>
        <div className="text-center md:pt-4 pb-10">
          <a href="/">
            <img className="w-32 mx-auto" src="/assets/alpha_forest.png" alt="Go to home page" />
          </a>
        </div>

        <div className={`text-center w-1/2 mx-auto`}>
          {this.props.stages &&
            this.props.stages.map((stage, idx: number) => (
              <React.Fragment key={`stage-${idx}`}>
                <div
                  className={`
                mb-8 step
                ${currentStageIdx === idx ? "font-bold step-selected text-stroke-3" : ""}
                ${cxTransition}
              `}
                >
                  <div className={`${cxStep} text-xl`}>{idx + 1}</div>
                  <div
                    className={`
                  step-title text-primary my-3 tracking-wide
                  ${cxTransition}
                `}
                  >
                    {stage.title}
                  </div>
                </div>
                {(idx === 1 && (
                  <div
                    style={{ backgroundImage: `url(/assets/arc.svg)` }}
                    className={`
                  ${cxIcons}
                  step-arc mr-10 absolute right-0
                `}
                  />
                )) ||
                  (idx === 2 && this.props.stages.length > 3 && (
                    <div
                      style={{ backgroundImage: `url(/assets/loop-arrow.svg)` }}
                      className={`
                  ${cxIcons}
                  step-arrow absolute ml-10 left-0
                `}
                    />
                  ))}
              </React.Fragment>
            ))}
        </div>
      </div>
    );
  }
}
