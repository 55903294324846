/** @format */

import type React from "react";

import classNames from "classnames";

import { Typography } from "@alphamedical/components";

interface MembershipOptionCardProps {
  price: string;
  color: string;
  borderColor?: string;
  containerClass?: string;
}

export const MembershipOptionCard: React.FC<MembershipOptionCardProps> = ({
  children,
  price,
  color,
  borderColor,
  containerClass,
}) => {
  return (
    <div
      className={classNames("border-2 rounded", containerClass, `border-${borderColor || color}`)}
    >
      <div className={"px-5 py-4"}>
        <div className="flex flex-col">
          <Typography variant="h1">{price}</Typography>
        </div>
        {children}
      </div>
    </div>
  );
};
