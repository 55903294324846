/** @format */

import React from "react";
import { Link } from "react-router-dom";

import { faNotesMedical } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Typography } from "@alphamedical/components";

import { serializeDependency } from "src/utils";
import { getConditionInfo } from "src/v2/constants";

import VisitCard from "./VisitCard";

interface VisitListProps {
  startVisitCard?: boolean;
  linkPath?: string;
  linkText?: string;
  consults: any[];
  limitItems?: number;
  listType: "taskList" | "consultList";
}

const VisitList = ({
  consults: allItems,
  // Limit displayed items to 20 if there's no explicitly set limit
  limitItems = 20,
  linkPath,
  linkText,
  listType,
  startVisitCard,
}: VisitListProps) => {
  const [viewAllItems, setViewAllItems] = React.useState<boolean>(false);
  const limitedItems = limitItems > 0 ? allItems.slice(0, limitItems) : allItems;
  const filteredVisits = !viewAllItems ? limitedItems : allItems;

  React.useEffect(() => {
    setViewAllItems(false);
  }, [serializeDependency(allItems)]);

  return (
    <div className="flex-col w-full space-y-4 mt-2">
      {/* Your visits */}
      {listType === "consultList" &&
        filteredVisits &&
        filteredVisits.map((visit) => {
          return (
            <VisitCard
              visit={visit}
              key={`${visit.subscription.condition.key}-${visit.id}`}
              cardType={"consultCard"}
            />
          );
        })}
      {startVisitCard && listType === "consultList" && (
        <VisitCard
          text="Start a visit"
          iconElement={<FontAwesomeIcon size="xl" icon={faNotesMedical} />}
          link="/your-care/explore-visits"
          cardType={"consultCard"}
        />
      )}

      {/* Return visits */}
      {listType === "taskList" &&
        filteredVisits &&
        filteredVisits.map((visit) => {
          const conditionInfo = getConditionInfo(visit.parameters.args[2]);
          return (
            <VisitCard
              key={`${visit.task_key}-${visit.id}`}
              visit={visit}
              cardType="taskCard"
              link={`/return-visit-details/${visit.parameters.args[2]}`}
              text={conditionInfo.label}
            />
          );
        })}

      {/* Section link */}
      {allItems.length > limitItems && linkText && (
        <Link
          onClick={() => {
            if (!linkPath) {
              setViewAllItems(!viewAllItems);
            }
          }}
          to={{
            pathname: linkPath,
          }}
        >
          <div className="text-cornflower-100 mt-3 flex flex-row items-center">
            <Typography variant="hyperlink">{!viewAllItems ? linkText : "Hide"}</Typography>
            {linkPath && (
              <FontAwesomeIcon className="w-2 h-3 ml-1" icon={["fas", "chevron-right"]} />
            )}
          </div>
        </Link>
      )}
    </div>
  );
};

export default VisitList;
