/** @format */

import type { ReactElement } from "react";

import React from "react";
import { useFormState } from "react-final-form";

import SkipQuestion from "src/v2/components/SkipQuestion/SkipQuestion";
import { useStoreState } from "src/v2/models";
import { useFormNav } from "src/v2/routes/Consultation";

import "src/components/DynamicForm/Fields/CustomFields/WelcomeFlow/welcome-flow.scss";

interface WelcomePageContainerProps {
  children: ReactElement | Array<ReactElement>;
  pageName: string;
  backgroundColorClass?: string;
  submitButtonTitle: string;
}

export const WelcomePageContainer = ({
  children,
  pageName,
  submitButtonTitle,
  backgroundColorClass,
}: WelcomePageContainerProps) => {
  const formNav = useFormNav();
  const formState = useFormState();
  const values = formState.values;
  const profile = useStoreState((state) => state.profile.profile);

  const className = `flex flex-col justify-between h-full ${
    backgroundColorClass
      ? backgroundColorClass + " welcome__fill-container"
      : "welcome__fill-default"
  }`;

  return (
    <div className={className}>
      {children}
      <button
        onClick={() => {
          // setting the welcome video value to true so this video doesn't show again
          values[pageName] = "true";
          formNav.nextClick(values);
        }}
        className={`btn btn-primary w-full mb-5`}
      >
        {submitButtonTitle}
      </button>
      {!profile?.is_new_patient && (
        <SkipQuestion
          goToPage={formNav.goToPage}
          nextClick={formNav.nextClick}
          skip={{
            nextPageKey: "eligibility",
            showPopup: false,
          }}
        />
      )}
    </div>
  );
};
