/** @format */

import type { ImageFile } from "react-dropzone";

import React from "react";
import Dropzone from "react-dropzone";
import { OnChange } from "react-final-form-listeners";

import { faPaperclip, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { FormApi } from "final-form";
import moment from "moment";

import { SimpleForm, TextFieldNew, Typography } from "@alphamedical/components";
import { ButtonPdb, Datepicker } from "src/v2/designSystem";

import type { BottomSheetProps } from "src/components/BottomSheet/BottomSheet";

import { BottomSheet } from "src/components/BottomSheet/BottomSheet";
import { RadioList } from "src/v2/components/Radio/RadioList";
import { useStoreDispatch, useStoreState } from "src/v2/models";

// Use this object to define fixed options for services
const serviceLabs: Partial<Record<string, { key: string; label: string }[]>> = {
  "weight-loss": [
    {
      key: "Lipid Panel",
      label: "Lipid Panel",
    },
    {
      key: "CRP test",
      label: "CRP test",
    },
  ],
};

interface UploadLabValues {
  service_id: number;
  lab_name: string;
  lab_manual: string;
  completed_at: string;
  file: ImageFile;
}

const OTHER_LAB_KEY = "#other";

type UploadLabBottomSheetProps = Omit<BottomSheetProps, "title">;

export const UploadLabBottomSheet = ({
  onClose,
  ...bottomSheetProps
}: UploadLabBottomSheetProps) => {
  const dispatch = useStoreDispatch();
  const serviceList = useStoreState((state) => state.service.serviceList);

  const dropzoneRef = React.createRef<Dropzone>();

  const steps = ["service", "lab", "lab_manual", "lab_completed", "submit"];
  const [currentStep, setCurrentStep] = React.useState<number>(0);
  const stepKey = steps[currentStep];
  const isSubmit = stepKey === "submit";
  const isLabCompleted = stepKey === "lab_completed";

  React.useEffect(() => {
    dispatch.service.fetchServices();
  }, []);

  const isDisabled = (values: UploadLabValues) => {
    if (stepKey === "service" && values.service_id) {
      return false;
    }

    if (stepKey === "lab" && (values.lab_name || values.lab_manual)) {
      return false;
    }

    if (stepKey === "lab_manual" && values.lab_manual) {
      return false;
    }

    if (stepKey === "lab_completed" && values.completed_at) {
      return false;
    }

    return !(isSubmit && values.file);
  };

  const handleClose = (form: FormApi<UploadLabValues>) => {
    onClose();
    setCurrentStep(0);

    // form.reset Can't be called inside onSubmit
    setTimeout(form.reset);
  };

  const getCurrentServiceLabsOptions = (serviceId: number) => {
    const currentService = serviceList.find((service) => service.id === serviceId);
    return currentService?.condition?.key ? serviceLabs[currentService.condition.key] : null;
  };

  const submitUserLab = async (values: UploadLabValues, form: FormApi<UploadLabValues>) => {
    try {
      const currentServiceLabs = getCurrentServiceLabsOptions(values.service_id);
      const isOption = currentServiceLabs && values.lab_name !== OTHER_LAB_KEY;

      // Ensure start of day
      const completedUTC = moment(values.completed_at).utc(true);

      await dispatch.userLabs.uploadUserLab({
        labName: isOption ? values.lab_name : values.lab_manual,
        file: values.file,
        serviceId: values.service_id,
        completedAt: completedUTC.toISOString(),
      });
      dispatch.snacks.addSnack({
        type: "primary",
        id: "upload-user-lab",
        message: "Lab uploaded successfully. We’ll let your provider know.",
        delay: 10,
      });
    } catch (e) {
      dispatch.snacks.addSnack({
        type: "error",
        id: "upload-user-lab-error",
        message: "Something went wrong. Try again or contact support.",
        delay: 10,
      });
    } finally {
      handleClose(form);
    }
  };

  return (
    <SimpleForm<UploadLabValues>
      onSubmit={(values, form) => submitUserLab(values, form)}
      render={({ form, values, handleSubmit }) => {
        const serviceId = values.service_id;
        const currentServiceLabs = getCurrentServiceLabsOptions(serviceId);

        const selectedLabLabel =
          currentServiceLabs && values.lab_name !== OTHER_LAB_KEY
            ? currentServiceLabs.find((value: any) => value.key === values.lab_name)?.label
            : values.lab_manual;

        const selectedFile = values.file;
        const stepTitle = isLabCompleted
          ? "When did you complete this lab? If you aren’t sure, an estimated date is okay. "
          : "What is this lab for?";

        return (
          <>
            <BottomSheet
              {...bottomSheetProps}
              onClose={() => handleClose(form)}
              title={(isSubmit && selectedLabLabel) || `Upload a lab`}
            >
              <Typography variant="h4" className="mb-4">
                {!isSubmit ? stepTitle : "Add the document you want to upload to your provider."}
              </Typography>
              {stepKey === "service" && (
                <RadioList
                  options={serviceList
                    .sort((a, b) => (a.name || a.type).localeCompare(b.name || b.type))
                    .map((service) => ({
                      key: `${service.id}`,
                      label: service.name || service.type,
                    }))}
                  onSelected={(key) => form.change("service_id", parseInt(key))}
                  selected={`${serviceId}`}
                />
              )}
              {serviceId && ["lab", "lab_manual"].includes(stepKey) && (
                <>
                  {currentServiceLabs && stepKey === "lab" ? (
                    <RadioList
                      options={[
                        ...currentServiceLabs,
                        { key: OTHER_LAB_KEY, label: "Something else..." },
                      ]}
                      selected={values.lab_name}
                      onSelected={(key) => form.change("lab_name", key)}
                    />
                  ) : (
                    <TextFieldNew name="lab_manual" required />
                  )}
                </>
              )}
              {serviceId && isLabCompleted && (
                <Datepicker maxDate={moment().format("YYYY-MM-DD")} name="completed_at" required />
              )}
              {isSubmit && (
                <div className="border-b border-grey-60">
                  {!selectedFile && (
                    <>
                      <ButtonPdb
                        onClick={() => dropzoneRef.current?.open()}
                        variant="secondary"
                        icon={"fas fa-upload text-cornflower-100"}
                      >
                        Add file
                      </ButtonPdb>
                      <Dropzone
                        ref={dropzoneRef}
                        className="display-none"
                        multiple={false}
                        onDrop={(accepted) => {
                          if (accepted.length > 0) {
                            form.change("file", accepted[0]);
                          }
                        }}
                        accept="image/*, application/pdf"
                      >
                        Drop files here
                      </Dropzone>
                    </>
                  )}
                  {selectedFile && (
                    <div className="flex items-center justify-between p-4 bg-grey-10">
                      <div className="flex items-center">
                        <FontAwesomeIcon className="mr-2" icon={faPaperclip} />
                        <Typography variant="h4" className="text-cornflower-100">
                          {selectedFile.name}
                        </Typography>
                      </div>
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() => form.change("file", undefined)}
                      />
                    </div>
                  )}
                  <Typography variant="body2" className="py-6">
                    File formats accepted: .jpg, .png, .pdf
                  </Typography>
                </div>
              )}
              <OnChange name={"service_id"}>
                {() => {
                  form.change("lab_name", undefined);
                  form.change("lab_manual", undefined);
                }}
              </OnChange>
              <OnChange name={"lab_name"}>{() => form.change("lab_manual", undefined)}</OnChange>
              <BottomSheet.Actions
                onBack={() => {
                  let prevStep = currentStep - 1;
                  if (
                    isLabCompleted &&
                    (values.lab_name !== OTHER_LAB_KEY || !currentServiceLabs)
                  ) {
                    prevStep--;
                  }
                  setCurrentStep(prevStep);
                }}
                onConfirm={() => {
                  if (!isSubmit) {
                    let nextStep = currentStep + 1;

                    if (
                      stepKey === "lab" &&
                      (values.lab_name !== OTHER_LAB_KEY || !currentServiceLabs)
                    ) {
                      nextStep += 1;
                    }
                    setCurrentStep(nextStep);
                  } else if (values.file) {
                    handleSubmit();
                  }
                }}
                showBack={currentStep > 0}
                disabled={isDisabled(values)}
                buttonText={!isSubmit ? "Continue" : "Submit"}
              />
            </BottomSheet>
          </>
        );
      }}
    />
  );
};
