/** @format */

import React from "react";
import { generatePath, useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";

import classNames from "classnames";

import { Typography } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import { Analytics } from "src/analytics";
import { ContentfulService } from "src/api/services/ContentfulService";
import { FormOutlinesService } from "src/api/services/FormOutlinesService";
import { Loader } from "src/components/Loader";
import { PageWrapper } from "src/components/PageWrapper";
import * as PDB_ROUTES from "src/routes";
import { serializeDependency } from "src/utils";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useStoreActions, useStoreState } from "src/v2/models";
import { CHECKIN_OUTLINE_KEYS } from "src/v2/models/checkins";

import type {
  CheckinResultsPageContentType,
  DisplayedContentDataType,
  ScoreData,
  YourResultWithScoreDescriptor,
} from "./CheckinResultPage.types";

interface CheckinResultsPageProps {
  contentKey: string;
  checkinKey: string;
  isPageStandalone?: boolean;
  scoreData?: ScoreData[];
  resultDate?: string;
}

export interface CheckInLocationState {
  title?: string;
}

interface RouteParams {
  checkinKey: string;
}

interface RouteMatch {
  id: string;
  page?: string;
}

// Helper functions
// --------------------------------------------
export const fetchContentfulContent = async (contentKey: string) => {
  try {
    const { content } = await ContentfulService.getUnstructuredContentByKey({ key: contentKey });
    return content;
  } catch (error) {
    return undefined;
  }
};

export const useContentfulContent = (contentKey: string) => {
  const [pageContent, setPageContent] = React.useState<any | undefined>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const fetchContent = async () => {
      try {
        setIsLoading(true);
        const content = await fetchContentfulContent(contentKey);
        setPageContent(content);
      } catch (error) {
        setPageContent(undefined);
      } finally {
        setIsLoading(false);
      }
    };
    fetchContent();
  }, []);

  return { pageContent, isLoading };
};
// End of Helper functions
// --------------------------------------------

export const CheckinResultsPage = ({
  contentKey,
  checkinKey,
  isPageStandalone = false,
  scoreData,
}: CheckinResultsPageProps) => {
  const { pageContent, isLoading } = useContentfulContent(contentKey);
  const history = useHistory();

  if (!pageContent || isLoading) {
    return <Loader show={isLoading} />;
  }

  const getContentBasedOnScore = (
    scoreDataObj: ScoreData[] | undefined,
  ): DisplayedContentDataType => {
    const content: DisplayedContentDataType = {};

    scoreDataObj?.forEach((scoreObj) => {
      const matchingContent = pageContent.find(
        (contentItem: CheckinResultsPageContentType) =>
          contentItem.scoreDescriptorKey === scoreObj.score_content_key,
      );
      if (matchingContent) {
        if (matchingContent.yourResult) {
          if (!content.yourResult) content.yourResult = [];
          const yourResultItem = {
            ...matchingContent.yourResult,
            scoreDescriptor: matchingContent.scoreDescriptor,
          };
          content.yourResult.push(yourResultItem);
        }
        if (matchingContent.nextSteps) {
          if (!content.nextSteps) content.nextSteps = [];
          content.nextSteps.push(matchingContent.nextSteps);
        }
        if (matchingContent.actionPlan) {
          if (!content.actionPlan) content.actionPlan = [];
          content.actionPlan.push(matchingContent.actionPlan);
        }
      }
    });

    return content;
  };

  // If no score data to show, redirect patient to either the HAP page if this is a outline flow, or to retake the checkin if it comes from the checkins list.
  if (scoreData?.length === 0) {
    const searchParams = new URLSearchParams();

    if (isPageStandalone) searchParams.set("action", "retake");
    else searchParams.set("redirect", PDB_ROUTES.HEALTH_ACTION_PLAN);

    const url =
      generatePath(PDB_ROUTES.CHECKIN_REDIRECT_PAGE, {
        checkinKey,
      }) + `?${searchParams.toString()}`;
    history.push(url);
  }

  const { yourResult, nextSteps, actionPlan } = getContentBasedOnScore(scoreData);

  return (
    <>
      {pageContent && (
        <div className={classNames("max-w-2xl mx-auto", isPageStandalone && "flex flex-col")}>
          {yourResult && (
            <>
              <div className={classNames("pb-2", isPageStandalone && "pl-6 pt-6")}>
                <Typography variant="h3" className="pb-1">
                  {yourResult[0].title}
                </Typography>
                {isPageStandalone && scoreData && (
                  <Typography variant="h5" className="text-grey-160">
                    {scoreData[0]?.completed_at}
                  </Typography>
                )}
              </div>
              {yourResult.map((result: YourResultWithScoreDescriptor, idx: number) => (
                <div className={classNames("shadow px-6 mb-4")} key={idx}>
                  <div className={classNames(isPageStandalone ? "mt-6" : "pt-6")}>
                    {result.scoreDescriptor && (
                      <div className="bg-cloud-40 p-4 mb-4 rounded">
                        <Typography variant="h1" className="pb-2">
                          {result.scoreDescriptor}
                        </Typography>
                        {scoreData && scoreData[idx].score_to_show && (
                          <Typography variant="h5" className="text-information">
                            {scoreData[idx].score_to_show}
                          </Typography>
                        )}
                      </div>
                    )}
                    <Typography variant="body1" className="pb-6">
                      {result.description}
                    </Typography>
                  </div>
                </div>
              ))}
            </>
          )}
          {nextSteps && (
            <div
              className={classNames(
                "flex flex-col shadow mb-4 p-6",
                !actionPlan ? "flex-1" : "",
                isPageStandalone && "pt-0",
              )}
            >
              {nextSteps[0].title && (
                <Typography variant="h3" className="pt-2 pb-2">
                  {nextSteps[0].title}
                </Typography>
              )}
              {nextSteps[0].description && (
                <Typography variant="body1" className="">
                  {nextSteps[0].description}
                </Typography>
              )}
              <ButtonPdb
                variant="primary"
                className="w-full mt-6"
                onClick={(e) => {
                  e.preventDefault();
                  Analytics.trackContentInteraction("click", {
                    name: "HCIResultPage",
                    piece: nextSteps[0].title || "null",
                    target: nextSteps[0].url,
                  });
                  history.push(nextSteps[0].url);
                }}
              >
                {nextSteps[0].button}
              </ButtonPdb>
            </div>
          )}
          {actionPlan && (
            <div className={classNames("flex flex-col shadow flex-1 p-6")}>
              <div className="bg-sky-20 rounded p-4">
                <Typography variant="h4" className="pb-2">
                  {actionPlan[0].title}
                </Typography>
                <Typography variant="body2" className="pb-2">
                  {actionPlan[0].description}
                </Typography>
                <span
                  onClick={() => {
                    Analytics.trackContentInteraction("click", {
                      name: "HCIResultPage",
                      piece: actionPlan[0].title || "null",
                      target: actionPlan[0].url,
                    });
                    history.push(actionPlan[0].url);
                  }}
                  role="button"
                >
                  <Typography variant="body2">{actionPlan[0].button}</Typography>
                </span>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export const CheckinResultsPageOutline = (props: any) => {
  const routeMatch = useRouteMatch<RouteMatch>();
  const responsesId = parseInt(routeMatch.params.id);
  const [scoreData, setScoreData] = React.useState<ScoreData[] | undefined>();

  React.useEffect(() => {
    const getScoresAsync = async () => {
      const processedScores = await FormOutlinesService.getResponseCollectionScores({
        userId: "me",
        responseCollectionId: responsesId,
      });
      setScoreData(processedScores as ScoreData[]);
    };
    getScoresAsync();
  }, [responsesId]);

  return (
    <CheckinResultsPage
      contentKey={props.contentKey}
      checkinKey={props.checkinKey}
      scoreData={scoreData}
    />
  );
};

export const CheckinResultsPageStandalone = () => {
  const { checkinList } = useStoreState((state) => state.checkins);
  const { fetchCheckIn } = useStoreActions((actions) => actions.checkins);
  const [scoreData, setScoreData] = React.useState<ScoreData[] | undefined>();

  // Setting Topbar options
  const location = useLocation<CheckInLocationState>();
  const title = location.state?.title || "Check-in";
  useTopBar({ title, variant: "nested", showBackButton: true });

  // Finding the content key of the page
  const { checkinKey } = useParams<RouteParams>();
  const checkinContentMap = {
    [CHECKIN_OUTLINE_KEYS.ANXIETY_CHECK_IN]: "anxiety-checkin-retaking-page",
    [CHECKIN_OUTLINE_KEYS.REPRODUCTIVE_HEALTH_CHECK_IN]:
      "reproductive-health-checkin-retaking-page",
  };
  const contentKey = checkinContentMap[checkinKey];

  // If there is no check-in, try fetching it
  if (!checkinList[checkinKey]) fetchCheckIn({ checkinKey, userId: "me" });

  React.useEffect(() => {
    const getScoresAsync = async () => {
      const { outline_response_collection_id } = checkinList[checkinKey] || {};
      if (outline_response_collection_id) {
        const processedScores = await FormOutlinesService.getResponseCollectionScores({
          userId: "me",
          responseCollectionId: outline_response_collection_id,
        });

        setScoreData(processedScores as ScoreData[]);
      }
    };

    if (checkinList[checkinKey]) {
      getScoresAsync();
    }
  }, [serializeDependency(checkinList[checkinKey] || undefined)]);

  return (
    <PageWrapper cxPadding="p-0 pb-6">
      <CheckinResultsPage
        contentKey={contentKey}
        checkinKey={checkinKey}
        isPageStandalone
        scoreData={scoreData}
      />
    </PageWrapper>
  );
};

export default WithTopBarNavigation(CheckinResultsPageStandalone);
