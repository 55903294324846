/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AthenaPharmacyAddress } from '../models/AthenaPharmacyAddress';
import type { EhrSetDefaultPharmacyRequestBodySchema } from '../models/EhrSetDefaultPharmacyRequestBodySchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EhrService {

    /**
     * Get all patient lab orders, optionally by consult_id.
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getPatientLabOrders({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/labs/orders/patient/{user_id}',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns AthenaPharmacyAddress
     * @throws ApiError
     */
    public static searchPharmacies({
        name,
        zip,
    }: {
        name?: string,
        zip?: string,
    }): CancelablePromise<Array<AthenaPharmacyAddress>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pharmacies/search',
            query: {
                'name': name,
                'zip': zip,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getDefaultPharmacy({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{user_id}/default_pharmacy',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static setDefaultPharmacy({
        userId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        requestBody: EhrSetDefaultPharmacyRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/{user_id}/default_pharmacy',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
