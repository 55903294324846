/** @format */

import type React from "react";

import cn from "classnames";

import type { MedicalRecordsRequestFormPageProps } from "./MedicalRecordsRequestForm";

const Authorization: React.FC<MedicalRecordsRequestFormPageProps> = ({ show }) => (
  <div className={cn({ block: show, hidden: !show }, "mb-10")}>
    <div className="mt-6 text-xl font-medium">
      Confirm that Alpha can disclose your medical information.
    </div>
    <div className="mt-4">
      Name and address of (or class of) persons or organizations authorized to use and/or disclose
      the information:
    </div>
    <div className="mt-8 bg-grey-2 px-5 py-6">
      <div className="font-bold">Alpha Telemedicine</div>
      <div>555 Bryant Street, STE 814</div>
      <div>Palo Alto, CA, 94301</div>
      <div>contact@helloalpha.com</div>
    </div>
    <div className="mt-8 bg-sand-20 px-5 py-6">
      <div className="flex mb-4">
        <img src="/assets/help-sand.svg" alt="help icon" />
        <div className="text-sm font-bold ml-4">Why can't I change this?</div>
      </div>
      <div className="text-sm">
        Alpha’s information has been pre-filled into this form for your convenience. You will
        receive a copy of this form automatically once completed.
      </div>
    </div>
  </div>
);

export default Authorization;
