/** @format **/

import React from "react";

import SandBullet from "src/v2/components/SandBullet";

interface GenInfoImg {
  src: string;
  alt: string;
  height?: string;
}

interface InfoItem {
  title: string;
  bulletPoints?: string[];
}

interface GeneralInfoProps {
  title?: string;
  img?: GenInfoImg;
  infoItems?: InfoItem[];
}

const GeneralInfo = ({ title, img, infoItems }: GeneralInfoProps) => {
  return (
    <div className="py-8 px-5 rounded bg-sand-40">
      {title && <div className="text-xl">{title}</div>}
      {img && (
        <div className="py-6">
          <img src={img.src} alt={img.alt} style={{ height: img.height }} />
        </div>
      )}
      {infoItems &&
        infoItems.map(({ title, bulletPoints }, index) => {
          const itemKey = index + "-" + title.replace(" ", "");
          return (
            <div key={itemKey.substr(0, Math.min(10, itemKey.length))} className="mb-5">
              <div className="text-base mb-5">{title}</div>
              {bulletPoints &&
                bulletPoints.map((point, index) => {
                  const key = index + "-" + point.replace(" ", "");
                  return <SandBullet point={point} key={key.substr(0, Math.min(10, key.length))} />;
                })}
            </div>
          );
        })}
    </div>
  );
};

export default GeneralInfo;
