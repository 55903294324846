/** @format */

import type React from "react";

import { SUICIDE_HOTLINE_NUMBER } from "src/v2/constants";

import type { ValidationErrorContentProps } from "./types";

export const SuicidalWarningMessage: React.FC<ValidationErrorContentProps> = () => (
  <p>
    Our clinicians cannot safely treat you via telemedicine.
    <br />
    <br /> We recommend that you seek immediate help:
    <ul>
      <li>Go to the emergency room</li>
      <li>Call {SUICIDE_HOTLINE_NUMBER}</li>
      <li>Text 741741</li>
      <li>Or call 911 so you can be evaluated.</li>
    </ul>
  </p>
);
