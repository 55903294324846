/** @format */
import React from "react";

import moment from "moment";

import maintenance from "src/content/maintenance";
import { MarkdownText } from "src/v2/components/MarkdownText";
import { useStoreActions, useStoreState } from "src/v2/models";

export const MaintenancePage = () => {
  const checkMaintenanceData = useStoreActions(
    (actions) => actions.maintenance.checkMaintenanceData,
  );

  const [expanded, setExpanded] = React.useState(false);
  const { inMaintenance, startTime, endTime } = useStoreState((state) => state.maintenance);

  const formattedStart = moment(startTime).format(maintenance.formatters.time);
  const formattedEnd = moment(endTime).format(maintenance.formatters.time);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      // We want to make sure that we exit the maintenance screen when we should.
      // Check every 2 minutes while a user is looking at the screen so we can restore service
      // quickly at the end of maintenance
      checkMaintenanceData().then((stillInMaintenance: boolean) => {
        if (inMaintenance && stillInMaintenance === false) {
          window.location.reload();
        }
      });
    }, 120 * 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="bg-white h-full">
      <div className="h-22 bg-sand flex flex-col items-center justify-center content-end pt-6">
        <img src={"/assets/alpha-logo.svg"} className="pb-2" />
      </div>
      <div className="p-6 max-w-2xl mx-auto">
        <div className="flex flex-col items-center py-8">
          <img src={"/assets/tools.svg"} />
        </div>
        <div className="flex flex-col items-center">
          <p className="text-forest text-2xl text-semibold text-center">
            {maintenance.screen.title}
          </p>
          <div className="flex flex-col content-between rounded bg-cloud-light-40 w-full p-4  my-4">
            <div className="mb-2">
              <p className="text-center text-l">{moment().format(maintenance.formatters.date)}</p>
            </div>
            <div>
              <p className="text-center font-bold text-l">{`${formattedStart} - ${formattedEnd}`}</p>
            </div>
          </div>
          <div className="mb-8">
            <MarkdownText
              containerClassName="text-base text-center"
              text={maintenance.screen.details}
            />
          </div>
          <div className="mb-20 bg-sand-40 w-full px-4 py-5">
            <div onClick={() => setExpanded(!expanded)}>
              <div className="flex flex-row justify-center w-full cursor-pointer">
                <img src={"/assets/helper-info.svg"} alt={"Question icon"} className={"mr-2"} />
                <p
                  className="text-base font-semibold text-center"
                  dangerouslySetInnerHTML={{ __html: maintenance.screen.disclaimerTitle }}
                />
              </div>
            </div>
            {expanded &&
              maintenance.screen.disclaimerContent.map((content) => {
                return (
                  <div
                    key={content.replace(" ", "").slice(0, Math.min(10, content.length))}
                    className="pt-4 pl-2 cursor-pointer"
                  >
                    <MarkdownText className={"text-center"} text={content} />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
