/** @format */

import React from "react";
import { useForm } from "react-final-form";

import cn from "classnames";
import { isEmpty } from "lodash";

import { RadioButtonField, TextFieldNew } from "@alphamedical/components";
import { CheckboxSelection } from "src/v2/designSystem";

import type { MedicalRecordsRequestFormPageProps } from "./MedicalRecordsRequestForm";
import type { FormValues } from "./validation";

const exclsusions = [
  { value: "psychiatry", label: "Mental health records" },
  {
    value: "communicable",
    label: "Communicable disease records, including sexually transmitted infection (STI) records",
  },
  { value: "substances", label: "Drug, alcohol, or substance use records" },
  { value: "genetic", label: "Genetic information" },
  { value: "other", label: "Other" },
];

interface DisclosureOverviewProps extends MedicalRecordsRequestFormPageProps {
  values: FormValues;
}

const DisclosureOverview: React.FC<DisclosureOverviewProps> = ({ show, values }) => {
  const form = useForm();

  React.useEffect(() => {
    if (!isEmpty(values?.all_except_details)) {
      form.change("disclosure_overview", "all_except");
    }
  }, [values?.all_except_details]);

  React.useEffect(() => {
    // clear details when top level selection changes
    if (values?.disclosure_overview !== "all_except") {
      form.change("all_except_details", null);
      form.change("all_except_details_other", null);
    }
    if (values?.disclosure_overview !== "only") {
      form.change("only_details", null);
    }
  }, [values?.disclosure_overview]);

  return (
    <div className={cn({ block: show, hidden: !show })}>
      <div className="mt-6 text-xl font-medium">
        Choose what information to disclose in your medical records.
      </div>
      <div className="mt-4 mb-8">
        This authorization permits Alpha to disclose the following medical records:
      </div>
      <RadioButtonField
        name="disclosure_overview"
        className="flex flex-col"
        options={[
          {
            value: "all",
            label: (
              <div className="mb-4">
                <div className="pb-4">
                  All of my health information that the provider has in his, her, or its possession,
                  including information relating to any medical history, mental or physical
                  condition and any treatment received by me, including without limitation, x-rays,
                  HIV/AIDS status, genetic testing, psychotherapy notes, and other mental health
                  information, drug, alcohol, or other controlled substance information, billing
                  information, correspondence, and records from my other healthcare providers that
                  the above-named healthcare provider may hold.
                </div>
              </div>
            ),
          },
          {
            value: "all_except",
            label: (
              <div className="mb-4">
                <div className="mb-2">
                  All of my health information described above except for (select what you{" "}
                  <strong className="underline">do not want included</strong> in your medical
                  records):
                </div>
                <CheckboxSelection name="all_except_details" options={exclsusions} />
                {values?.all_except_details?.includes("other") && (
                  <TextFieldNew
                    name="all_except_details_other"
                    className="my-4"
                    placeholder="Please specify"
                  />
                )}
              </div>
            ),
          },
          {
            value: "only",
            label: (
              <div>
                <div>
                  Only the following records or types of health information (include dates of
                  treatment, types of treatment, or other designation)
                </div>
                <TextFieldNew
                  type="textarea"
                  name="only_details"
                  placeholder="Please specify"
                  className="my-4"
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};
export default DisclosureOverview;
