/** @format */

// Module dependencies
import type { FC } from "react";

import React, { isValidElement } from "react";

import type { WebNavigationIconProps } from "src/v2/designSystem/WebNavigation";

// UI Components
import { BadgePdb } from "src/v2/designSystem/BadgePdb";

export const LeftHandNavigationIcon: FC<WebNavigationIconProps> = ({
  badgeLabel,
  badgeVariant,
  clickCallback,
  iconActive,
  iconInactive,
  isActive,
  label,
  value,
}) => {
  const handleIconClick = () => {
    if (clickCallback) clickCallback(value);
  };

  return (
    <div
      className="flex flex-row items-center justify-between w-full cursor-pointer"
      onClick={() => handleIconClick()}
    >
      <div
        className="flex items-center justify-start gap-4"
        style={{ color: "#1C4A46", fontSize: "21px" }}
      >
        <div
          className={`w-6 flex items-center justify-center ${isActive ? "text-forest" : "text-neutral"}`}
        >
          {isActive ? (
            <>
              {typeof iconActive === "string" ? (
                <i className={iconActive} />
              ) : (
                isValidElement(iconActive) && iconActive
              )}
            </>
          ) : (
            <>
              {typeof iconInactive === "string" ? (
                <i className={iconInactive} />
              ) : (
                isValidElement(iconInactive) && iconInactive
              )}
            </>
          )}
        </div>

        <span
          className={`text-base leading-6 font-medium ${isActive ? "text-forest" : "text-neutral"}`}
        >
          {label}
        </span>
      </div>

      <div className="w-16 flex items-center justify-center">
        <BadgePdb
          variant={badgeVariant ? badgeVariant : "notification"}
          label={badgeLabel}
          textTransform="capitalize"
        />
      </div>
    </div>
  );
};
