/** @format */

import React from "react";

import type { GenericFieldCondition } from "src/components/DynamicForm/types";

import { FieldWrapper } from "src/components/DynamicForm/Fields/DynamicFieldWrapper";
import { isFileField, isOptionField, isTextField } from "src/components/DynamicForm/types";
import { fieldFilter } from "src/v2/components/OutlineForm";
import {
  DateField,
  ImageField,
  MultiSelectField,
  RadioField,
  SelectField,
  TextField,
} from "src/v2/components/Wizard/fields";
import { ConditionalWrapper } from "src/v2/components/Wizard/utils";
import { useStoreDispatch, useStoreState } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";

import type { PageFormProps } from "./types";

import { PageFormWrapper } from "./pageWrapper";

export const GenericPage = ({ form, page }: PageFormProps) => {
  const dispatch = useStoreDispatch();
  const { profile } = useStoreState((state) => state.profile);
  const [loading, setLoading] = React.useState<boolean>(page.key === "eligibility");
  const user = useCurrentUser();

  React.useEffect(() => {
    if (!profile || !profile.consults) {
      dispatch.profile.fetchProfile({ include: ["consults"] }).finally(() => setLoading(false));
    }
    setLoading(false);
  }, [page.key]);

  return (
    <PageFormWrapper form={form} page={page} loading={loading}>
      {page.fields
        // filter these fields if patient has a completed consult.
        .filter((field) => fieldFilter(field, user))
        .map((field, idx) => (
          <ConditionalWrapper
            key={field.key}
            conditionals={field.conditionals as undefined | GenericFieldCondition[]}
            resetOnHide={[field.key]}
          >
            <FieldWrapper field={field}>
              {isTextField(field) &&
                (field.subtype === "date" ? (
                  <DateField name={field.key} />
                ) : (
                  <TextField name={field.key} placeholder={field.label} />
                ))}
              {isOptionField(field) && (
                <>
                  {field.type === "radio" && (
                    <RadioField name={field.key} options={field.options} />
                  )}
                  {field.type === "select" &&
                    (field.subtype === "checkbox" ? (
                      <MultiSelectField name={field.key} options={field.options || []} />
                    ) : (
                      <SelectField name={field.key} options={field.options || []} />
                    ))}
                </>
              )}
              {isFileField(field) && (
                <ImageField name={field.key} message={field.message} bgImageUrl={field.imageUrl} />
              )}
            </FieldWrapper>
          </ConditionalWrapper>
        ))}
    </PageFormWrapper>
  );
};
