/** @format */

import React from "react";

import Form from "src/components/Settings/FormLoginDetails";
import { useStoreDispatch, useStoreState } from "src/v2/models";

const EditLoginDetails = () => {
  const { fetching, message, currentUser, updatePasswordError, updatingPassword } = useStoreState(
    (state) => state.user,
  );
  const dispatch = useStoreDispatch();

  React.useEffect(() => {
    dispatch.user.fetchUser();
  }, []);
  const handleSubmit = async (values: any) => {
    dispatch.user
      .updatePassword({ password: values.password, passwordConfirm: values.passwordConfirm })
      .then((res: any) => {
        if (res.meta && res.meta.code && res.meta.code === 200) {
          dispatch.snacks.addSnack({
            type: "success",
            id: "update_password_success",
            message: "Your password has been changed",
            delay: 10,
          });
        }
      });
  };

  const renderForm = () => {
    if (fetching || fetching === undefined) {
      return "Loading the login details data...";
    }

    return (
      <Form
        isSubmitting={updatingPassword}
        error={updatePasswordError ? updatePasswordError : null}
        onSubmit={handleSubmit}
        defaultValues={{
          email: currentUser?.email ? currentUser.email : "",
          password: "",
          passwordConfirm: "",
        }}
      />
    );
  };

  const renderError = () => {
    if (message) {
      return <p className="text-red">Getting the login details data failed.</p>;
    }

    return null;
  };

  return (
    <>
      {renderForm()}

      {/* Rendering the error doesn't need to block displaying of the Form. */}
      {renderError()}
    </>
  );
};

export default EditLoginDetails;
