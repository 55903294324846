/** @format */

import type React from "react";

import classNames from "classnames";

import { InfoCard } from "src/v2/designSystem";

import type { DynamicField } from "src/components/DynamicForm/types";

import { DynamicComponent } from "src/components/DynamicComponent";
import { isHTML } from "src/components/DynamicForm/utils";

export interface FieldWrapperProps {
  field: DynamicField;
  className?: string;
  hideFooter?: boolean;
}

export const FieldWrapper: React.FC<FieldWrapperProps> = ({
  field,
  className,
  hideFooter,
  children,
}) => {
  const renderFieldTitle = (
    key: string,
    title: string,
    repeatedQuestionStyling: boolean | undefined,
  ) => {
    const repeatedStyling = <InfoCard variant="repeating" hideIcon={true} title="" body={title} />;
    const normalStyling = (
      <DynamicComponent
        stringWrapperProps={{ className: "pb-2", variant: "h3" }}
        renderStringAsHtml={isHTML(title)}
        content={title}
      />
    );
    const component = repeatedQuestionStyling ? repeatedStyling : normalStyling;
    return (
      <label htmlFor={key} className="flex">
        {component}
      </label>
    );
  };

  return (
    <div className={classNames(className)}>
      {!field.hideTitle &&
        field.title &&
        renderFieldTitle(field.key, field.title, field.repeatedQuestionStyling)}
      {field.subtitle && (
        <DynamicComponent
          stringWrapperProps={{ className: "py-2", variant: "body1" }}
          renderStringAsHtml={isHTML(field.subtitle)}
          content={field.subtitle}
        />
      )}
      {field.description && (
        <DynamicComponent
          stringWrapperProps={{ className: "py-2", variant: "body2" }}
          renderStringAsHtml={isHTML(field.description)}
          content={field.description}
        />
      )}
      {children}
      {field.type !== "component" && !!field.component && (
        <DynamicComponent
          stringWrapperProps={{ className: "text-sm py-2" }}
          renderStringAsHtml={true}
          content={field.component}
        />
      )}
      {!hideFooter && field.footer && (
        <DynamicComponent
          stringWrapperProps={{ className: "py-2", variant: "caption" }}
          renderStringAsHtml={isHTML(field.footer)}
          content={field.footer}
        />
      )}
    </div>
  );
};
