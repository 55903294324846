/** @format */

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import moment from "moment";

import { SimpleForm, TextFieldNew, Typography } from "@alphamedical/components";
import { ButtonPdb, RadioSelection } from "src/v2/designSystem";

import type { CancellationDiscountEligibleSchema, TagsSchema } from "src/api";
import type { FlowModalProps } from "src/content/v2/routes/Membership/types";

import { ServicesService, TagsService } from "src/api";
import { formatText } from "src/content/contentUtils";
import { PLAN_NAMES } from "src/content/v2/routes/Membership/constants";
import { CONTENT } from "src/content/v2/routes/Membership/utils";
import { useMembershipPrice } from "src/utils/hooks/useMembershipPrice";
import InformationalCard from "src/v2/components/InformationalCard/InformationalCard";
import { useStoreDispatch, useStoreState } from "src/v2/models";

export const CancellationFlow = ({
  setModalOpen,
  hasRequestedCancellation = false,
  hasRequestedDowngrade = false,
}: FlowModalProps) => {
  const [isCanceled, setIsCanceled] = React.useState(false);
  const dispatch = useStoreDispatch();
  const history = useHistory();
  const [showLastStep, setShowLastStep] = useState(false);
  const [showReasonForm, setShowReasonForm] = React.useState(false);
  const [showOffer, setShowOffer] = React.useState(false);
  const [cancelReasons, setCancelReasons] = React.useState<TagsSchema[]>([]);
  const [discountInfo, setDiscountInfo] = React.useState<CancellationDiscountEligibleSchema>();
  const membership = useStoreState((state) => state.membership.membership);
  const membershipPriceInfo = useMembershipPrice();

  useEffect(() => {
    setShowLastStep(hasRequestedCancellation || hasRequestedDowngrade);
    TagsService.getTagList({ tagType: "patient_cancel_membership_reason" }).then((response) => {
      setCancelReasons(response);
    });
    ServicesService.membershipCancellationDiscountEligible({ userId: "me" }).then((response) => {
      setDiscountInfo(response);
    });
  }, []);

  const cancelMembership = async () => {
    if (membership?.recurly_subscription_id) {
      await dispatch.membership.cancelMembership({
        recurlySubscriptionId: membership.recurly_subscription_id,
      });
      setIsCanceled(true);
    }
  };

  const closeModal = () => setModalOpen(false);

  const getProperLastFlowStep = () => {
    const processingPage = CONTENT["cancel"].processingPage;
    return hasRequestedCancellation ? (
      <div className={"p-6 flex flex-col mb-10"}>
        <section className={"pt-4 mb-5"}>
          <h4 className="text-xl font-medium text-forest-120 mb-8">{processingPage.title}</h4>
          <p className={"text-forest-120 text-base leading-6"}>{processingPage.body[0]}</p>
          <br />
          <p className={"text-forest-120 text-base leading-6"}>{processingPage.body[1]}</p>
          <br />
        </section>
        <section className="mb-16">
          <InformationalCard
            content={processingPage.info.message}
            title={processingPage.info.title}
            showIcon
            type="Brand"
          ></InformationalCard>
        </section>
        <section className="mb-12">
          <ButtonPdb
            className="w-full"
            onClick={() => history.push("/messages")}
            variant="secondary"
          >
            {processingPage.cta}
          </ButtonPdb>
        </section>
      </div>
    ) : (
      <div className={"p-6 flex flex-col mb-10"}>
        <section className={"pt-4 mb-16"}>
          <h4 className="text-xl font-medium text-forest-120 mb-8">
            {CONTENT["cancel"].attemptsToCancelWhileChanging.title}
          </h4>
          <p className={"text-forest-120 text-base leading-6"}>
            {CONTENT["cancel"].attemptsToCancelWhileChanging.body[0]}
          </p>
          <br />
        </section>
        <section className="mb-12">
          <ButtonPdb
            className="w-full"
            onClick={() => history.push("/messages")}
            variant="secondary"
          >
            {CONTENT["cancel"].attemptsToCancelWhileChanging.cta}
          </ButtonPdb>
        </section>
      </div>
    );
  };

  const downgradeMembership = async () => {
    const recurlyId = membership?.recurly_subscription_id;
    if (recurlyId) {
      await dispatch.membership.upgradeMembership({
        recurlyId,
        newPlan: "alpha-membership",
      });
      closeModal();
    }
  };

  const acceptDiscount = async () => {
    await ServicesService.acceptMembershipCancellationDiscount({ userId: "me" });
    dispatch.snacks.addSnack({
      type: "success",
      id: "claim_discount_success",
      message: "Your plan discount has been applied",
      delay: 10,
    });
    closeModal();
  };

  const renderChoicePage = () => (
    <SimpleForm<{ action: string }>
      hideDebugInfo
      onSubmit={(values) => {
        if (values.action === "cancel") {
          if (discountInfo?.is_eligible) {
            setShowOffer(true);
          } else {
            cancelMembership();
          }
        }
        if (values.action === "switch") {
          downgradeMembership();
        }
      }}
      render={({ values, handleSubmit }) => {
        const pageContent = CONTENT["cancel"].confirmationPage;
        const confirmButton = pageContent.confirmButton;
        const isCancel = !discountInfo?.is_eligible && values.action === "cancel";
        const downgradeOption = membershipPriceInfo.alpha;

        const price = downgradeOption.hasBenefit
          ? downgradeOption.discountAmountString
          : downgradeOption.originalPriceString;

        return (
          <>
            <section className="pt-4 space-y-4">
              <Typography variant="h2" className="mb-4">
                {pageContent.title}
              </Typography>
              <Typography className="mb-4">{pageContent.body[0]}</Typography>
              <RadioSelection
                defaultValue="cancel"
                name="action"
                options={[
                  {
                    label: `Switch to Alpha Basic Plan (${price}/month)`,
                    value: "switch",
                  },
                  {
                    label: "Cancel my plan",
                    value: "cancel",
                  },
                ]}
                required
              />
              <Typography variant="caption">
                Alpha Basic Plan does not include care for weight loss, mental health, and diabetes.
              </Typography>
            </section>
            <section className="mt-24 mb-12">
              <ButtonPdb
                className="w-full mb-4"
                onClick={handleSubmit}
                {...(isCancel ? { variant: "danger" } : {})}
              >
                {isCancel ? "Cancel plan" : confirmButton}
              </ButtonPdb>
              <ButtonPdb className="w-full" onClick={closeModal} variant="secondary">
                {pageContent.notNowButton}
              </ButtonPdb>
            </section>
          </>
        );
      }}
    />
  );

  const renderCanceledMessage = () => {
    const followUpPage = CONTENT["cancel"].followUpPage;
    return (
      <>
        <section className="pt-4">
          <Typography variant="h2" className="mb-4">
            {followUpPage.title}
          </Typography>
          <Typography className="mb-4">
            {formatText(followUpPage.accessMessage, {
              date: moment(membership?.renews_at).format("MM/DD/YYYY"),
              time: moment(membership?.renews_at).format("hh:mm A"),
            })}
          </Typography>
        </section>
        <section className="mt-4">
          <InformationalCard
            content={followUpPage.info.message}
            title={followUpPage.info.title}
            showIcon={false}
            type="Brand"
          />
        </section>
        <section className="mt-12">
          <ButtonPdb className="w-full" onClick={() => setShowReasonForm(true)}>
            {followUpPage.cta}
          </ButtonPdb>
        </section>
      </>
    );
  };

  const renderReasonForm = () => (
    <SimpleForm<{ reason: string; reason_other: string }>
      hideDebugInfo={false}
      onSubmit={(values) => {
        ServicesService.cancelReason({ userId: "me", requestBody: values }).then(() => {
          closeModal();
        });
      }}
      render={({ values, handleSubmit, invalid }) => {
        const reasonPage = CONTENT["cancel"].reasonPage;
        return (
          <>
            <section className="pt-4 space-y-4">
              <Typography variant="h2" className="mb-4">
                {reasonPage.title}
              </Typography>
              <Typography className="mb-4">{reasonPage.body[0]}</Typography>
              <RadioSelection
                multiple
                name="reason"
                options={cancelReasons?.map((value) => ({
                  label: value.label,
                  value: value.slug,
                }))}
                required
              />
              {values.reason === "cancel-membership-other" && (
                <TextFieldNew
                  name="reason_other"
                  label="Other reason"
                  placeholder="Please specify"
                  required
                />
              )}
            </section>
            <section className="mt-24 mb-12">
              <ButtonPdb disabled={invalid} className="w-full" onClick={handleSubmit}>
                {reasonPage.confirmButton}
              </ButtonPdb>
            </section>
          </>
        );
      }}
    />
  );

  const renderOfferPage = () => {
    const offerPage = CONTENT["cancel"].offerPage;
    return (
      <>
        <section className="pt-4">
          <Typography variant="h2" className="mb-4">
            {formatText(offerPage.title, {
              planCode: membership?.plan_code && PLAN_NAMES[membership?.plan_code],
              discount: discountInfo?.discount.toString() || "0",
            })}
          </Typography>
          <Typography className="mb-4">{offerPage.body[0]}</Typography>
        </section>
        <section className="mt-12">
          <ButtonPdb className="w-full" onClick={acceptDiscount}>
            {offerPage.cta}
          </ButtonPdb>
          <ButtonPdb className="w-full mt-4" onClick={cancelMembership} variant="danger">
            Cancel plan
          </ButtonPdb>
        </section>
      </>
    );
  };

  const renderConfirmDeletePage = () => {
    const confirmDeletePage = CONTENT["cancel"].confirmDeletePage;
    return (
      <>
        <section className="pt-4">
          <Typography variant="h2" className="mb-4">
            {confirmDeletePage.title}
          </Typography>
          <Typography className="mb-4">{confirmDeletePage.body[0]}</Typography>
        </section>
        <section className="mt-12">
          <ButtonPdb className="w-full" onClick={cancelMembership} variant="danger">
            {confirmDeletePage.cta}
          </ButtonPdb>
          <ButtonPdb className="w-full mt-4" onClick={closeModal} variant="secondary">
            Go back
          </ButtonPdb>
        </section>
      </>
    );
  };

  const getIsCancellingFlow = () => {
    let page;
    if (isCanceled) {
      page = !showReasonForm ? renderCanceledMessage() : renderReasonForm();
    } else {
      const isMembership = [membership?.plan_code, membership?.scheduled_plan_code].includes(
        "alpha-membership",
      );
      if (showOffer || (isMembership && discountInfo?.is_eligible)) {
        page = renderOfferPage();
      } else if (isMembership) {
        page = renderConfirmDeletePage();
      } else {
        page = renderChoicePage();
      }
    }
    return <div className="p-6 flex flex-col mx-auto mb-10 max-w-2xl">{page}</div>;
  };

  if (!discountInfo) return null;

  return <>{showLastStep ? getProperLastFlowStep() : getIsCancellingFlow()}</>;
};
