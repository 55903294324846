/** @format */

import React from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useHistory, useParams } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";

import type { OptionType } from "@alphamedical/components";

import {
  CheckboxNewField,
  FieldWrapper,
  Typography,
  useWindowSize,
} from "@alphamedical/components";
import { ButtonPdb, Paper } from "src/v2/designSystem";

import type { OrganizationsLandingPageEligibilityScreeningRequestBodySchema } from "src/api";

import { ContentfulService, OrganizationsService } from "src/api";
import { Loader } from "src/components/Loader";
import BenefitHolderRegistration from "src/routes/BenefitHolderRegistration";
import SearchAddressForm from "src/v2/components/SearchAddressForm/SearchAddressForm";
import { useStoreActions } from "src/v2/models";

import "./sponsoredBenefits.scss";

export interface LandingPageRouteParams {
  employer: string;
}

export interface PageContent {
  title: string;
  subtitle: string;
  cta: string;
  closingStatement?: string;
  contactInfo?: string;
  consents?: { [key: string]: string }[];
}

interface EligibilityScreeningData {
  address1: string;
  address2: string;
  autocomplete: string;
  city: string;
  consents: string[];
}

const CONSENTS = ["medical-benefits-consent", "financial-hardship-consent"];

const addressFormFields = [
  {
    field: "address",
    key: "address1",
    label: "Address line 1",
    required: true,
    type: "text",
  },
  {
    field: "autocomplete",
    key: "autocomplete",
    label: "Address line 1*",
    type: "text",
  },
  {
    field: "address2",
    key: "address2",
    label: "Address line 2",
    type: "text",
  },
  {
    field: "city",
    key: "city",
    label: "City",
    required: true,
    type: "text",
  },
  {
    field: "state",
    key: "state",
    label: "State",
    required: true,
    type: "select",
    subtype: "dropdown",
    placeholder: "Select",
  },
  {
    field: "zipcode",
    key: "zipcode",
    label: "ZIP code",
    required: true,
    minLength: 5,
    type: "text",
    subtype: "zipcode",
  },
];

const defaultValues: any = {
  autocomplete: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zipcode: "",
};

// If there is no employer param, or it's equal to any of the options listed below, we have to redirect the user to the old B2B flow.
const shouldRedirectToWDFlow = (employer: string) => {
  const redirectOptions = ["register", "phone", "address", "options"];
  return !employer || redirectOptions.includes(employer);
};

export const SponsoredBenefits = (props: any) => {
  const history = useHistory();
  const { setShowLogin } = useStoreActions((actions) => actions.auth);
  const { employer } = useParams<LandingPageRouteParams>();
  const isSonjaCaresPatient = employer === "sonja-cares";
  const [isLoading, setIsLoading] = React.useState(true);
  const [pageContent, setPageContent] = React.useState<PageContent>();
  const [width] = useWindowSize();
  const [mustValidateAddress, setMustValidateAddress] = React.useState(false);
  const [employerData, setEmployerData] = React.useState<any>({});

  const banner = isSonjaCaresPatient ? (
    <img
      src="/assets/sponsored-benefits-banner-lhe.png"
      alt="A woman reviewing her mobile phone, with the Sonja Cares and Alpha logos displayed below."
      className="mt-6 h-72 w-auto overflow-hidden object-center object-cover"
    />
  ) : (
    <img
      src="/assets/sponsored-benefits-banner.png"
      alt="Cheerful women standing together"
      className="mt-6"
    />
  );

  const getAnonymousUserId = () => {
    let auid = localStorage.getItem("anonymousUserId");
    if (!auid) {
      auid = uuidv4();
      localStorage.setItem("anonymousUserId", auid);
    }
    return auid;
  };

  React.useEffect(() => {
    // If we cannot verify the employer, we have to redirect the user to the login/signup page.
    // If there is any employer information, we store it to use later after registration occurs.
    const checkIfEmployerExists = async () => {
      if (employer && !shouldRedirectToWDFlow(employer)) {
        // As part of #2454, we updated the landing page to include address validation for users from some organizations,
        // currently this only applies to LHE/Sonja Cares though.
        setMustValidateAddress(isSonjaCaresPatient);

        let employerExists = false;
        let response = {};
        try {
          {
            response = await OrganizationsService.getOrganizationByEmployer({ employer });
            employerExists = !!response;
          }
        } catch (error) {
          console.error("Error:", error);
        } finally {
          if (!employerExists) history.push("/");
          else {
            // Check availability of the employer.
            const availabilityResponse = await OrganizationsService.signupAvailabilityCheck({
              orgId: response["id"],
            });
            // Custom page to show if the signup is not available for the employer.
            if (!availabilityResponse.signup_available) {
              history.push(`/sponsored-benefits/not-available/${employer}`);
            } else {
              sessionStorage.setItem("employer", JSON.stringify(response));
              setEmployerData(response);
              const { content } = await ContentfulService.getUnstructuredContentByKey({
                key: `b2b-${employer}-landing-page`,
              });

              // Prevent endless loop if the BE return a null value
              if (content) {
                getAnonymousUserId();
                setPageContent(content);
                setIsLoading(false);
              } else history.push("/my-cases");
            }
          }
        }
      }
    };
    checkIfEmployerExists();
  }, [employer]);

  if (shouldRedirectToWDFlow(employer)) {
    const newProps = { ...props, match: { params: { step: employer } } };
    return <BenefitHolderRegistration {...newProps} />;
  }

  const handleEmployeeRegistration = () => {
    setShowLogin(false);
    history.push({ pathname: "/my-cases", state: { from: history.location.pathname } });
  };

  // If the employer is sonja-cares, we have to validate the address before allowing the user to register.
  const onCheckEligibilityClick = (values: EligibilityScreeningData) => {
    if (values.consents.length === 2) {
      const payload: OrganizationsLandingPageEligibilityScreeningRequestBodySchema = {
        eligibility_screening_values: { ...values },
        auid: getAnonymousUserId(),
      };

      setIsLoading(true);
      OrganizationsService.landingPageEligibilityScreening({
        orgId: employerData?.id,
        requestBody: payload,
      })
        .then(() => {
          handleEmployeeRegistration();
        })
        .catch((error) => {
          if (error.response?.status === 403) {
            const capReached = error.response.data.description === "Cap reached.";
            history.push(
              `/sponsored-benefits/${capReached ? "not-available" : "not-eligible"}/${employer}`,
            );
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
      return {};
    } else {
      return { consents: "Both consents must be accepted." };
    }
  };

  const addressValidationForm = (
    <div className="pt-6 border-t border-grey-60 mb-16">
      <Typography variant="body2" className="mb-1">
        {pageContent?.closingStatement}
      </Typography>
      <SearchAddressForm
        fields={addressFormFields}
        button={pageContent?.cta || "Confirm that you're eligible"}
        onSubmit={onCheckEligibilityClick}
        initialValues={defaultValues}
      >
        <div className="my-2 w-full">
          <FieldWrapper name="consents">
            {({ input }) => {
              const consentOptions = CONSENTS.flatMap((key) => {
                return (
                  pageContent?.consents
                    ?.filter((consent) => consent[key])
                    ?.map((consent) => ({ value: key, label: consent[key] })) || []
                );
              }) as OptionType<string>[];
              return (
                <CheckboxNewField
                  {...input}
                  options={consentOptions}
                  className="py-2 max-w-2xl space-y-8"
                  required={true}
                  validate={(value, _, meta) => {
                    return meta?.dirtySinceLastSubmit && value?.length !== 2
                      ? "Both consents must be accepted."
                      : "";
                  }}
                />
              );
            }}
          </FieldWrapper>
        </div>
      </SearchAddressForm>
    </div>
  );

  return isLoading || !pageContent ? (
    <Loader show={true} />
  ) : (
    <div className="flex flex-col justify-between min-h-screen">
      <div>
        <div className="flex justify-center items-center bg-sand-40 h-12">
          <div className="flex flex-1 justify-center">
            <img
              src="/assets/alpha-logo.svg"
              alt="Alpha's Brand Logo"
              className="w-20 col-span-2 place-self-center"
            />
          </div>
        </div>
        {pageContent && (
          <Paper
            elevation={0}
            rounded={false}
            className="flex flex-col justify-center m-auto px-6 max-w-xl"
          >
            <div className="flex flex-1 flex-col items-center mb-6">
              {banner}
              <Typography variant="h1" className="mt-6">
                {pageContent?.title}
              </Typography>
              <Typography variant="h4" className="mt-2">
                {pageContent?.subtitle}
              </Typography>
              {!mustValidateAddress && (
                <>
                  <ButtonPdb className="w-full mt-8" onClick={handleEmployeeRegistration}>
                    {pageContent?.cta}
                  </ButtonPdb>
                  <Typography variant="h2" className="mt-10">
                    {pageContent?.closingStatement}
                  </Typography>
                  <Typography variant="h4" className="mt-4 mb-16">
                    <ReactMarkdown skipHtml className="email-link">
                      {pageContent?.contactInfo || ""}
                    </ReactMarkdown>
                  </Typography>
                </>
              )}
            </div>
            {mustValidateAddress && addressValidationForm}
          </Paper>
        )}
      </div>
      {width > 640 && <div className="flex flex-1 w-full bg-sand-40 max-h-32"></div>}
    </div>
  );
};
