/** @format */
import { format } from "date-fns";
/*
 * Gets day difference between dates
 * return number of days
 */
export const dayDiff = (d1: any, d2: any) => {
  const oneday = 24 * 60 * 60 * 1000;
  return Math.round(Math.abs((d1 - d2) / oneday)) - 1;
};

export const guessTimezone = () => {
  try {
    // By default, use the browser Internationalization API, which is widely supported
    // https://caniuse.com/internationalization
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch {
    // If the Intl API is not available, fall back to a naive solution based on the Date API.
    try {
      return new Date() // Get the current time with timezone data
        .toLocaleTimeString("en-US", { timeZoneName: "short" }) // Convert to "HH:MM:SS AM TZ" format
        .split(" ")[2]; // Return the 3rd part (the timezone) after splitting on whitespace
    } catch {
      return null;
    }
  }
};

export function now(): Date;
export function now(formatStr: string): string;
export function now(formatStr?: string): Date | string {
  const currentTime = new Date();
  return formatStr ? format(currentTime, formatStr) : currentTime;
}
