/** @format */

import React from "react";
import { Link } from "react-router-dom";

import { HIPAABadges } from "src/components/Badges/ HIPAABadges";
import { BenefitHolderRegistrationContent } from "src/content/v2/routes/BenefitHolderRegistration";
import { useStoreDispatch, useStoreState } from "src/v2/models";
import { RegisterForm } from "src/v2/routes/Register";

export const RegistrationForm = () => {
  const eligiblilibtyValues = useStoreState((state) => state.auth.eligibilityLookupValues);
  const dispatch = useStoreDispatch();
  React.useEffect(() => {
    // We do this in case a user is attempting to register a new benefit holder account while still being logged into another account.
    dispatch.auth.logout({ noRedirect: true });
  }, []);

  return (
    <div>
      {eligiblilibtyValues?.eligibleIndividualFound ? (
        <>
          <p className="mb-8 text-xl">
            {BenefitHolderRegistrationContent?.registrationPage?.title}
          </p>
          {/* TODO: Add props to this component so that we can update the styles of the text/password inputs */}
          <RegisterForm registrationType="benefit-holder-registration" />
          <div className="mt-12">
            <HIPAABadges />
          </div>
        </>
      ) : (
        <>
          <p className="text-xl text-center mb-8">
            {BenefitHolderRegistrationContent?.registrationPage?.errorState?.title}
          </p>
          <Link
            to={BenefitHolderRegistrationContent?.registrationPage?.errorState?.link?.to}
            className="text-cornflower-100 text-center flex justify-center font-bold"
          >
            {BenefitHolderRegistrationContent?.registrationPage?.errorState?.link?.text}
          </Link>
        </>
      )}
    </div>
  );
};
