/** @format */

import type { FC } from "react";

import React from "react";
import { useFormState } from "react-final-form";

// Module dependencies
import type { SelectFieldProps } from "@alphamedical/components";
// UI Components & styles
import type { BadgePdbProps } from "src/v2/designSystem/BadgePdb";

import { FieldWrapper, useFieldName } from "@alphamedical/components";

import { RadioOption } from "./RadioOption";

import "./RadioSelection.scss";

type RadioSelectionOptionType = {
  label: string | JSX.Element;
  value: string;
  badgeProps?: BadgePdbProps;
  disabled?: boolean;
};

export type RadioSelectionProps = Omit<SelectFieldProps, "options"> & {
  options: RadioSelectionOptionType[];
  ariaLabel?: string;
};

export const RadioSelection: FC<RadioSelectionProps> = ({
  options,
  className = "",
  ariaLabel = "radio selection",
  children,
  ...fieldProps
}) => {
  const { values, submitFailed, dirtyFields } = useFormState();
  const fieldName = useFieldName(fieldProps.name);
  const fieldDirty = dirtyFields[fieldName];

  const validationErrors =
    (fieldDirty && !values[fieldName]?.length) || (!fieldDirty && submitFailed);
  const errorState = fieldProps.required && validationErrors;

  return (
    <ul
      className={`${className} flex flex-col gap-4 list-none p-0`}
      aria-labelledby={ariaLabel}
      role="radiogroup"
    >
      {options.map(({ value, label, badgeProps, disabled }) => (
        <FieldWrapper
          key={`${fieldProps.name}-${value}`}
          type="radio"
          value={value}
          {...fieldProps}
        >
          {({ input, meta }) => {
            const defaultOption = (
              <RadioOption
                selected={input.checked}
                disabled={disabled}
                label={label}
                badgeProps={badgeProps}
                {...input}
              />
            );
            if (children) {
              return children({
                input,
                meta,
                option: { value, label },
                defaultOption,
                disabled,
              });
            }
            return <li>{defaultOption}</li>;
          }}
        </FieldWrapper>
      ))}
      {errorState && <div className={"min-h-6 w-full text-red"}>Required</div>}
    </ul>
  );
};
