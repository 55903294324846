/** @format */

import React from "react";
import { Field, Form, useForm } from "react-final-form";

import * as Sentry from "@sentry/react";
import cn from "classnames";
import { includes, isEmpty, map, pick, size } from "lodash";

import { Pill, TextField } from "@alphamedical/components";

import type { AddressesCreateAddressRequestBodySchema } from "src/api";

import { UsersService } from "src/api";
import { _POST } from "src/helpers/http";
import AddressForm from "src/v2/components/AddressForm";
import { AlphaModal } from "src/v2/components/AlphaModal";
import { Button, secondaryProps } from "src/v2/components/Button";
import { Dropdown } from "src/v2/components/Dropdown";
import { STATES } from "src/v2/constants";
import { useCurrentUser } from "src/v2/models/profile";

import PaxlovidInfoModal from "./PaxlovidInfoModal";

const options = [
  { label: "ZIP Code", value: "zip", placeholder: "Enter ZIP code" },
  { label: "City", value: "city", placeholder: "Enter city" },
  { label: "County", value: "county", placeholder: "Enter county" },
];

interface LocatorResult {
  provider_name: string;
  address1: string;
  address2: string;
  city: string;
  county: string;
  state_code: string;
  zip: string;
}

interface PaxlovidLocatorProps {
  nextClick: () => any;
}

export const paxlovidWarning = (
  <div className="bg-cloud-light-40 p-5 text-sm font-bold mb-8">
    Alpha cannot guarantee that PAXLOVID™ is available at these pharmacies. Due to limited supplies
    of PAXLOVID™, we recommend checking if the pharmacy you select has the medication in stock.
  </div>
);

const stateOptions = map(STATES, (state) => ({ value: state, label: state }));

const PaxlovidLocator: React.FC<PaxlovidLocatorProps> = ({ nextClick }) => {
  const [activeField, setActiveField] = React.useState("zip");
  const [offset, setOffset] = React.useState<Partial<Record<string, number>>>({});
  const [noMoreResults, setNoMoreResults] = React.useState({});
  const [isFetching, setIsFetching] = React.useState(false);
  const [searchResults, setSearchResults] = React.useState<Record<string, LocatorResult[]>>({});
  const [isAddressFormOpen, setIsAddressFormOpen] = React.useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = React.useState(false);
  const resultsRef = React.useRef<HTMLDivElement>(null);
  const emptyRef = React.useRef<HTMLDivElement>(null);
  const [width, setWidth] = React.useState<number>(10);
  const tinyScreen = width < 345;
  const smallScreen = width < 700;
  const patient = useCurrentUser();

  const handleResize = () => setWidth(window.innerWidth);
  React.useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const answersForm = useForm();

  const fetchResults = (values: Record<string, any> = {}, moreResults = false) => {
    setIsFetching(true);
    const body = pick(values, activeField);
    if (values[`state-${activeField}`]) {
      body["state_code"] = values[`state-${activeField}`];
    }
    if (moreResults) {
      const defaultOffset = offset[activeField] || 10;
      body["offset"] = defaultOffset;
      setOffset({ ...offset, [activeField]: defaultOffset + 10 });
    }
    _POST(`/covid_treatment/locator`, body)
      .then((response) => {
        const updatedResults = moreResults
          ? [...searchResults[activeField], ...response]
          : response;
        setSearchResults({ ...searchResults, [activeField]: updatedResults });
        if (smallScreen && !moreResults) {
          const ref = isEmpty(response) ? emptyRef : resultsRef;
          ref.current?.scrollIntoView({ behavior: "smooth" });
        }
        setNoMoreResults({ ...noMoreResults, [activeField]: size(response) < 10 });
      })
      .finally(() => setIsFetching(false));
  };

  const handleSubmit = (values = {}) => fetchResults(values);

  const selectPharamacy = (id: number) => {
    answersForm.change("pharmacy_selected", id);
    answersForm.change("covid_treatment_locator", id);
  };
  const saveAddress = (address: LocatorResult) => {
    const requestBody: AddressesCreateAddressRequestBodySchema = {
      ...pick(address, "address1", "address2", "city"),
      name: address.provider_name,
      state: address.state_code,
      zipcode: address.zip,
      type: "PHARMACY",
    };

    UsersService.createAddress({ userId: patient.id, requestBody })
      .then((response) => {
        selectPharamacy(response.id);
        nextClick();
      })
      .catch(Sentry.captureException);
  };
  const results = searchResults[activeField];
  const showMoreResults = !isEmpty(results) && !isFetching && !noMoreResults[activeField];

  return (
    <>
      <Form<any>
        onSubmit={handleSubmit}
        render={({ form, values, handleSubmit }) => (
          <div
            className="sm:mx-auto mb-12"
            onKeyDown={(e) => e.key === "Enter" && handleSubmit(values)}
          >
            <div className="text-xl">
              Let’s find a pharmacy where you can pick up a{" "}
              <span
                className="text-sky-dark underline font-bold cursor-pointer"
                onClick={() => setIsInfoModalOpen(true)}
              >
                PAXLOVID™
              </span>{" "}
              prescription.
            </div>
            <div ref={emptyRef} className="pt-2" />
            <div className="font-bold my-6">Search by</div>
            <div className={cn("flex mb-8", { "flex-wrap": tinyScreen })}>
              {map(options, (option) => (
                <div
                  onClick={() => setActiveField(option.value)}
                  className="mr-4 mb-4"
                  key={option.value}
                >
                  <Pill
                    disabled={false}
                    cxFontSize="base px-4 py-2 cursor-pointer"
                    bgColor={activeField === option.value ? "sand" : "grey-1"}
                  >
                    {option.label}
                  </Pill>
                </div>
              ))}
            </div>
            <div className="mb-4">
              {map(
                options,
                (option) =>
                  activeField === option.value && (
                    <div
                      className="flex justify-between items-baseline"
                      key={`input-${option.value}`}
                    >
                      <TextField
                        className={cn("flex-grow", {
                          "mr-6": includes(["city", "county"], option.value),
                        })}
                        name={option.value}
                        placeholder={option.placeholder}
                        required={true}
                      />
                      {includes(["city", "county"], option.value) && (
                        <div style={{ width: "6.25rem" }}>
                          <Dropdown
                            options={stateOptions}
                            value={values?.[`state-${activeField}`]}
                            onChange={(value) => form.change(`state-${activeField}`, value)}
                            placeholder="State"
                          />
                        </div>
                      )}
                    </div>
                  ),
              )}
            </div>
            <Button
              disabled={isFetching}
              extraClassNames="mb-8 py-5"
              onClick={(e) => {
                e.preventDefault();
                handleSubmit(values);
              }}
            >
              Find PAXLOVID™
            </Button>
            <div
              className="text-sky-dark underline font-bold cursor-pointer"
              onClick={() => setIsAddressFormOpen(true)}
            >
              I already found a pharmacy
            </div>
            {results && (
              <div className={cn({ "min-h-screen sm:min-h-0 pb-20": !isEmpty(results) })}>
                {isEmpty(results) ? (
                  <>
                    <div className="mt-8 mb-6 text-xl">
                      We were unable to find any pharmacies offering PAXLOVID™ at that location.
                    </div>
                    <Button
                      {...{
                        ...secondaryProps,
                        extraClassNames: secondaryProps.extraClassNames + " py-5 border-primary",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setIsAddressFormOpen(true);
                      }}
                    >
                      Manually enter a pharmacy instead
                    </Button>
                  </>
                ) : (
                  <div className="mb-6">
                    <div className="text-xl mb-8 mt-4 pt-4" ref={resultsRef}>
                      Select the pharmacy where you will pick up your prescription.
                    </div>
                    {paxlovidWarning}
                    <div>
                      From{" "}
                      <a
                        href="https://healthdata.gov/Health/COVID-19-Public-Therapeutic-Locator/rxn6-qnx8/data"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline"
                      >
                        HealthData.gov
                      </a>
                    </div>
                  </div>
                )}
                {map(results, (result: LocatorResult, index: number) => (
                  <div
                    className="border-2 border-grey-1 p-4 mb-4 cursor-pointer"
                    key={`${result.provider_name}-${index}`}
                    onClick={() => saveAddress(result)}
                  >
                    <div className="font-medium mb-2">{result.provider_name}</div>
                    <div className="mb-1">{result.address1}</div>
                    <div>{`${result.city}, ${result.state_code} ${result.zip}`}</div>
                  </div>
                ))}
                {showMoreResults && (
                  <div
                    onClick={() => fetchResults(values, true)}
                    className="text-sky-dark cursor-pointer flex items-center mt-8"
                  >
                    <img src="/assets/plus.svg" alt="plus icon" className="h-4 mr-3" />
                    Show more pharmacies
                  </div>
                )}
              </div>
            )}
            <Field name={"covid_treatment_locator"} component="input" type="hidden" />
            {isAddressFormOpen && (
              <AlphaModal
                bgColor="white"
                closeIconColor="primary-40"
                cxPadding="p-0"
                isOpen={true}
                onRequestClose={() => setIsAddressFormOpen(false)}
                className="max-w-sm m-auto"
              >
                <AddressForm
                  closeModal={() => setIsAddressFormOpen(false)}
                  selectedPharmacy={{}}
                  addressType="PHARMACY"
                  updateSelected={(value) => answersForm.change("pharmacy_selected", value)}
                  nextClick={() => setTimeout(() => nextClick(), 1000)}
                >
                  {paxlovidWarning}
                </AddressForm>
              </AlphaModal>
            )}
            {isInfoModalOpen && (
              <PaxlovidInfoModal isOpen={true} onRequestClose={() => setIsInfoModalOpen(false)} />
            )}
          </div>
        )}
      />
    </>
  );
};

export default PaxlovidLocator;
