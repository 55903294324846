/** @format */

import React from "react";

import { BackButtonHeading } from "src/components/_buttons/BackButtonHeading";
import config from "src/config";
import IconText from "src/v2/components/TreatmentInfo/IconText";
import IconTextLink from "src/v2/components/TreatmentInfo/IconTextLink";
import { createTreatmentInfo } from "src/v2/constants";

interface Libre2Props {
  setLoading: (loading: boolean) => void;
}

/**
 * Displays information for libre2 device.
 * @param setLoading
 * @constructor
 */
const Libre2 = ({ setLoading }: Libre2Props) => {
  React.useEffect(() => {
    setLoading(false);
  }, []);
  return (
    <div className="mx-5">
      <BackButtonHeading title="Freestyle Libre 2 (CGM)" />
      <div className="mt-8">
        <IconText
          icon={"/assets/graphIcon.svg"}
          iconAlt={"Graph Icon"}
          text={
            "Your Alpha Care Team will provide details about how to set up your device and answer any questions you might have "
          }
        />
        <IconTextLink
          treatmentInfo={createTreatmentInfo(
            "open_consults",
            "Open Consult Icon",
            "View instructions",
            config.assetsUrl + "/data/Alpha+CGM+Guide.pdf",
          )}
          showBorderTop={true}
        />
      </div>
    </div>
  );
};

export default Libre2;
