/** @format */
import React from "react";
import { useHistory } from "react-router-dom";

import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Sentry from "@sentry/react";
import classNames from "classnames";

import { Typography } from "@alphamedical/components";
import { Accordion } from "src/v2/designSystem";
import { ButtonPdb } from "src/v2/designSystem/ButtonPdb/ButtonPdb";
import { Paper } from "src/v2/designSystem/Paper/Paper";

import type { PiiSchema, ProviderPii } from "src/api";

import { UsersService } from "src/api/services/UsersService";
import { Loader } from "src/components/Loader";
import { PageWrapper } from "src/components/PageWrapper";
import { EXPLORE_VISITS } from "src/routes";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useStoreDispatch, useStoreState } from "src/v2/models";

import "./MyCareTeamPage.scss";

const MyCareTeamPage = () => {
  useTopBar({ title: "My care team", variant: "nested", showBackButton: true });
  const history = useHistory();
  const dispatch = useStoreDispatch();
  const primaryCareProvider = useStoreState((state) =>
    state.profile.profile ? state.profile.profile.primary_provider : null,
  );
  const [providersData, setProvidersData] = React.useState<ProviderPii[]>();
  const [contentExpanded, setContentExpanded] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(true);
    if (!primaryCareProvider || !primaryCareProvider.pii) {
      dispatch.profile
        .fetchProfile({ include: ["primary_provider"] })
        .catch(Sentry.captureException);
    }

    UsersService.getAllProvidersPii({ userId: "me" })
      .then((response) => {
        const providers = response.filter(
          (provider: PiiSchema) => provider.full_name !== primaryCareProvider?.full_name,
        );
        setProvidersData([...providers]);
      })
      .catch(Sentry.captureException)
      .finally(() => setIsLoading(false));
  }, [primaryCareProvider]);

  const readMoreButtonHandler = () => {
    setContentExpanded(!contentExpanded);
  };

  const readMoreButton = (
    <div
      className={classNames("cursor-pointer", !contentExpanded ? "read-more-button" : "")}
      onClick={readMoreButtonHandler}
    >
      <Typography variant="hyperlink">{contentExpanded ? "Read Less" : "Read More"}</Typography>
    </div>
  );

  const getSections = (provider: ProviderPii, mainSection = false) => {
    const customHeader = (
      <>
        <div className="flex flex-row items-center">
          <div className={classNames("photo-container", mainSection ? "" : "small")}>
            {provider.headshot_photo_url ? (
              <img
                src={provider.headshot_photo_url}
                alt="Provider headshot photo"
                className={classNames("photo-circular", mainSection ? "" : "small")}
              />
            ) : (
              <div
                className={classNames(
                  "photo-circular rounded-full flex items-center justify-center bg-sand-60",
                  mainSection ? "" : "small",
                )}
              >
                {provider.full_name?.charAt(0)}
              </div>
            )}
          </div>
          <Typography variant={mainSection ? "subtitle1" : "h5"}>{provider.full_name}</Typography>
        </div>
      </>
    );

    const customDivider = <div className="py-1 border-b border-grey-60" />;

    const bioSection = provider.biography && (
      <div>
        <Typography variant="subtitle2" className="pb-2">
          <span className="text-black-real"> About</span>
        </Typography>
        <div className="">
          <Typography variant="body1">{provider.biography}</Typography>
        </div>
      </div>
    );

    const customContent = (
      <>
        {provider.practitioner_speciality && (
          <div className="mt-6">
            <Typography variant="subtitle2" className="pb-2">
              <span className="text-black-real"> Specialties</span>
            </Typography>
            <Typography variant="body1">{provider.practitioner_speciality}</Typography>
          </div>
        )}
        {provider.languages && (
          <div className="mt-6">
            <Typography variant="subtitle2" className="pb-2">
              <span className="text-black-real"> Languages</span>
            </Typography>
            {provider.languages.map((language: string) => {
              return (
                <Typography variant="body1" className="capitalized" key={language}>
                  {language.toLowerCase()}
                </Typography>
              );
            })}
          </div>
        )}
        {provider.npi && (
          <div className="mt-6">
            <Typography variant="subtitle2" className="pb-2">
              <span className="text-black-real"> National Provider Identifier (NPI)</span>
            </Typography>
            <Typography variant="body1">{provider.npi}</Typography>
          </div>
        )}
      </>
    );

    const customIcons = [
      <FontAwesomeIcon
        key="down"
        icon={faChevronDown}
        size="lg"
        className="forest-100 border-none"
      />,
      <FontAwesomeIcon key="up" icon={faChevronUp} size="lg" className="forest-100 border-none" />,
    ];

    return { customHeader, customDivider, customContent, customIcons, bioSection };
  };

  const myProviders = providersData && providersData.length > 0 && (
    <div className="mb-6">
      <Typography variant="h3">My providers</Typography>
      <div>
        <Accordion
          variant="content"
          sections={providersData.map((provider: ProviderPii, idx: number) => {
            const { customHeader, customContent, customDivider, customIcons, bioSection } =
              getSections(provider);

            const content = (
              <div className="pb-4 mt-3">
                {bioSection}
                {customContent}
              </div>
            );

            return {
              id: idx,
              title: "",
              customTitle: customHeader,
              content: content,
              customDivider: idx + 1 !== providersData.length ? customDivider : <></>,
              customIcons: customIcons,
            };
          })}
          expandMultiple={false}
          className="w-full"
        />
      </div>
    </div>
  );

  const myPrimaryCareProvider = primaryCareProvider && (
    <div className="flex flex-1 flex-col mb-6">
      <Typography variant="h3" className="mb-4">
        My primary care provider
      </Typography>
      <div className={classNames("pt-4 px-5 pb-5 bg-sand-20 shadow")}>
        <div className={classNames(contentExpanded ? "" : "main-container")}>
          <div className="mb-4">
            {primaryCareProvider.pii && getSections(primaryCareProvider.pii, true).customHeader}
          </div>
          {primaryCareProvider.pii?.biography && (
            <div>
              <Typography variant="subtitle2" className="pb-2">
                <span className="text-black-real"> About</span>
              </Typography>
              <div className="">
                <Typography
                  variant="body1"
                  className={classNames(!contentExpanded && "truncated-text")}
                >
                  {primaryCareProvider.pii.biography}
                </Typography>
              </div>
            </div>
          )}
        </div>
        {contentExpanded &&
          primaryCareProvider.pii &&
          getSections(primaryCareProvider.pii, true).customContent}
        <div
          className={classNames("pb-1", contentExpanded ? "text-left mt-6 " : "mt-2 text-right")}
        >
          {readMoreButton}
        </div>
      </div>
    </div>
  );

  if (isLoading) {
    return <Loader show={isLoading} />;
  }

  return (
    <PageWrapper cxPadding="px-0" fitToPageHeight>
      <Paper elevation={0} rounded={false} className="mx-auto w-full max-w-2xl px-6">
        <div className="mt-6">
          {myPrimaryCareProvider}

          {myProviders}

          {!primaryCareProvider && providersData?.length === 0 && (
            <>
              <Typography variant="body1">
                You haven't started your care with Alpha. Once you start a visit, information about
                your provider will appear here.
              </Typography>
              <ButtonPdb
                className="w-full mt-9"
                onClick={() => {
                  history.push(EXPLORE_VISITS);
                }}
              >
                Start a visit
              </ButtonPdb>
            </>
          )}
        </div>
      </Paper>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(MyCareTeamPage);
