/** @format */

import React from "react";

import { Typography } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import VisitStatusPill from "src/v2/components/Visits/VisitStatusPill";
import { getConditionInfo } from "src/v2/constants";

interface VisitInformationProps {
  condition_key: string;
  status?: string;
  subtitle?: string;
  content?: string;
  ctaText?: string;
  ctaCallback?: (values?: any) => void;
  buttonVariant?: "primary" | "secondary" | "tertiary" | "danger" | "transparent";
}

const VisitInformation = (props: VisitInformationProps) => {
  const condition_key = props.condition_key;
  const status = props.status;
  const conditionDetails = getConditionInfo(condition_key);

  return (
    <>
      <div className="flex pb-6">
        <div className="h-16 w-16 min-w-16 flex justify-center items-center rounded bg-sand-40">
          <img
            className="p-3"
            src={conditionDetails.imageSrc}
            alt={`${conditionDetails.label} image`}
          ></img>
        </div>
        <div className="flex flex-col items-start pl-6">
          <Typography variant="h4" component="h4" className="pb-1">
            {conditionDetails.label}
          </Typography>
          {status && <VisitStatusPill status={status} treated={false} />}
        </div>
      </div>
      <Typography variant="h4" component="h4" className="text-grey-160 pb-2">
        {props.subtitle}
      </Typography>
      <Typography variant="body1" className="forest-120 pb-6" component="p">
        {props.content}
      </Typography>
      {props.ctaText && (
        <ButtonPdb onClick={props.ctaCallback} variant={props.buttonVariant}>
          {props.ctaText}
        </ButtonPdb>
      )}
    </>
  );
};

export default VisitInformation;
