/** @format */

import React from "react";

import moment from "moment";

import { Popup, Typography } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

interface WaitForPCPPopupProps {
  open: boolean;
  onCancel: () => void;
  onWait: () => void;
  onAutoClose: () => void;
  providerName: string;
  returnDate: string;
  type: "consult" | "channel";
  pending_consult_task?: boolean;
}

export const WaitForPCPPopup = ({
  onCancel,
  onWait,
  open,
  returnDate,
  providerName,
  type,
  onAutoClose,
  pending_consult_task = false,
}: WaitForPCPPopupProps) => {
  const isConsult = type === "consult";
  const waitItem = isConsult ? "this vist" : "your message";
  const receiveText = isConsult || pending_consult_task ? "care" : "a response";

  React.useEffect(() => {
    const popUpTimeout = 1000 * 60 * 4;
    const timeoutId = setTimeout(() => onAutoClose && onAutoClose(), popUpTimeout);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Popup
      headerText={
        pending_consult_task
          ? "Your provider will respond when they return"
          : "Your provider is out-of-office"
      }
      isOpen={open}
    >
      <div className="p-4">
        <div className="mb-4 p-3">
          <Typography>
            {pending_consult_task ? (
              <p>
                Your primary care provider, <strong>{providerName}</strong>, is out-of-office. In
                your visit, you chose to wait to receive care until they return on{" "}
                <strong>{moment.utc(returnDate).format("LL")}</strong>. Your provider will review
                your visit and respond to this message when they return. However, you can choose to
                receive care from another provider.
              </p>
            ) : (
              <p>
                Your primary care provider, <strong>{providerName}</strong>, will return on{" "}
                <strong>{moment.utc(returnDate).format("LL")}</strong>. Do you want to wait for your
                primary care provider to review {waitItem} or receive {receiveText} from another
                Alpha provider sooner?
              </p>
            )}
          </Typography>
        </div>
        <div className="space-y-2 flex flex-col justify-end">
          <ButtonPdb onClick={onWait}>
            {pending_consult_task ? "I understand" : "Wait for my primary care provider"}
          </ButtonPdb>
          <ButtonPdb variant="secondary" onClick={onCancel}>
            Receive {receiveText} from another provider
          </ButtonPdb>
        </div>
      </div>
    </Popup>
  );
};
