/** @format */

export const howAlphaWorks = {
  title: "How Alpha works:",
  steps: [
    {
      icon: "cellphoneCircle.svg",
      title: "Describe your symptoms",
      text: "Answer questions about your health condition online.",
    },
    {
      icon: "stethoscopeCircle.svg",
      title: "Connect with a medical provider",
      text: "A medical provider will review your responses and send a personalized treatment plan and prescription, if appropriate.",
    },
    {
      icon: "truckCircle.svg",
      title: "Get whole-person care",
      text: "Reach your care team through our secure messaging platform at any time. Choose to have medication shipped to you for free, or sent to your preferred pharmacy. ",
    },
  ],
};
