/** @format */

import React, { useState } from "react";

import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons/faInfoCircle";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import { faChevronUp } from "@fortawesome/pro-solid-svg-icons/faChevronUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import { Chevron, Typography } from "@alphamedical/components";

export type AccordionContentType = {
  /**
   * This is needed for set its internal key and tabindex props.
   */
  id: number;

  /**
   * Title of this accordion section.
   */
  title: string;

  /**
   * Content of this accordion section.
   */
  content?: string | React.ReactNode;

  /**
   * If this section will be collapsed at the beggining.
   */
  collapsed?: boolean;

  /**
   * If this section will be disabled.
   */
  disabled?: boolean;

  /**
   * Optional icon for each section.
   */
  sectionIcon?: any;

  /**
   * Pass the title directly. Only used for Accordion with variant "content"
   */
  customTitle?: React.ReactNode;

  customIcons?: React.ReactNode[];

  showIcon?: boolean;
  showDivider?: boolean;
};

interface AccordionTitleProps {
  title: string;
  titleClassName?: string;
}

const AccordionTitle: React.FC<AccordionTitleProps> = ({ title, titleClassName }) => {
  return (
    <Typography variant="h2" className={titleClassName}>
      {title}
    </Typography>
  );
};

const AccordionDivider: React.FC = () => {
  return <div className="border-b border-grey-40 text-center items-center"></div>;
};

interface AccordionSectionContentProps {
  content: any;
  variant: "simple" | "helper" | "content";
}

const AccordionSectionContent: React.FC<AccordionSectionContentProps> = ({ content, variant }) => {
  if (variant === "content" && content) {
    return <>{content}</>;
  } else if (variant === "simple") {
    return <div className="flex mx-2 px-4 border-l-4 border-sand-100">{content}</div>;
  } else {
    return (
      <div className="flex mx-5 mb-5 mt-2 border-sand-100 padding-horizontal-9">{content}</div>
    );
  }
};

export interface AccordionItemProps {
  title: string;
  content?: string | React.ReactNode;
  collapsed?: boolean;
  disabled?: boolean;
  chevronColor?: string;
  titleClass?: string;
  onClick: any;
  index: number;
  sectionIcon?: any;
  variant?: "simple" | "helper" | "content";
  customTitle?: React.ReactNode;
  customDivider?: React.ReactNode;
  customIcons?: React.ReactNode[];
  showIcon?: boolean;
  showDivider?: boolean;
}

const AccordionSectionSimpleVariant: React.FC<AccordionItemProps> = ({
  title,
  content,
  collapsed = true,
  disabled = false,
  chevronColor,
  titleClass,
  onClick,
  index,
}) => {
  return (
    <>
      <button
        className={classNames(
          "flex flex-row justify-between items-center p-5 px-2 w-full btn-accordion",
          disabled && "opacity-48",
        )}
        tabIndex={index}
        onClick={() => {
          if (!disabled) onClick();
        }}
        disabled={disabled}
        aria-expanded={!collapsed}
      >
        <Typography className={classNames("pr-2 text-left", titleClass)}>{title}</Typography>
        <Chevron
          color={chevronColor}
          direction={collapsed ? "up" : "down"}
          className="chevron-min-width"
        />
      </button>
      {!disabled && !collapsed && (
        <div
          className={classNames("btn-accordion-content", !collapsed && "py-5")}
          tabIndex={index}
          aria-hidden={collapsed}
        >
          <AccordionSectionContent content={content} variant={"simple"} />
        </div>
      )}
      <AccordionDivider />
    </>
  );
};

interface AccordionIconProps {
  /**
   * component to be passed in as icon.
   */
  iconComponent: React.ComponentType;

  /**
   * if option is selected.
   */
  selected: boolean;
}

const AccordionIcon: React.FC<AccordionIconProps> = ({ iconComponent, selected }) => {
  return <div className={selected ? "text-forest-100" : "text-grey-140"}>{iconComponent}</div>;
};

export const AccordionContentTitle = (props: { title: string }) => (
  <Typography variant="h3" align="left" component="h3" noWrap={true}>
    {props.title}
  </Typography>
);

const AccordionSectionContentVariant: React.FC<AccordionItemProps> = ({
  title,
  content,
  collapsed = true,
  disabled = false,
  titleClass,
  onClick,
  index,
  sectionIcon,
  customTitle,
  customDivider,
  customIcons,
}) => {
  return (
    <>
      <button
        className={classNames(
          "w-full btn-accordion-content-variant focus:outline-none",
          disabled && "opacity-48",
        )}
        tabIndex={index}
        onClick={() => {
          if (!disabled) onClick();
        }}
        aria-expanded={!collapsed}
      >
        {/* Method used to only show focus on tab press and not on click
        https://stackoverflow.com/questions/31402576/enable-focus-only-on-keyboard-use-or-tab-press */}
        <div
          className={classNames(
            "flex flex-row justify-between items-center btn-accordion-content-variant-inner py-5 w-full",
            !collapsed && "pb-2",
          )}
          tabIndex={-1}
        >
          <div className="flex flex-1 items-center">
            {sectionIcon && <AccordionIcon iconComponent={sectionIcon} selected={!collapsed} />}
            <div className={classNames(customTitle ? "" : "text-forest-120 pl-4", titleClass)}>
              {!customTitle ? <AccordionContentTitle title={title} /> : customTitle}
            </div>
          </div>
          {customIcons && customIcons.length > 1 ? (
            collapsed ? (
              customIcons[0]
            ) : (
              customIcons[1]
            )
          ) : (
            <div
              className={classNames(
                "h-6 w-6 rounded-full flex items-center justify-center",
                collapsed ? "border border-grey-100" : "bg-grey-60",
              )}
            >
              <FontAwesomeIcon
                icon={collapsed ? faChevronDown : faChevronUp}
                size="xs"
                className="forest-100 border-none"
              />
            </div>
          )}
        </div>
      </button>
      {!disabled && !collapsed && (
        <div
          className={classNames("btn-accordion-content focus:border-none focus:outline-none")}
          tabIndex={index}
          aria-hidden={collapsed}
        >
          {typeof content === "string" ? (
            <AccordionSectionContent content={content} variant="content" />
          ) : (
            content
          )}
        </div>
      )}
      {customDivider}
    </>
  );
};

const AccordionSectionHelperVariant: React.FC<AccordionItemProps> = ({
  title,
  content,
  collapsed = true,
  disabled = false,
  chevronColor,
  titleClass,
  onClick,
  index,
  showIcon = true,
  showDivider = false,
}) => {
  return (
    <>
      <button
        className={classNames(
          "flex flex-row justify-between items-center p-5 w-full btn-accordion",
          disabled && "opacity-48",
        )}
        tabIndex={index}
        onClick={() => {
          if (!disabled) onClick();
        }}
        aria-expanded={!collapsed}
      >
        <div className="flex flex-1 items-center">
          {showIcon && <FontAwesomeIcon size="lg" icon={faInfoCircle} />}
          <Typography className={classNames("pr-4 text-left", titleClass, showIcon && "pl-4")}>
            {title}
          </Typography>
        </div>
        <Chevron
          color={chevronColor}
          direction={collapsed ? "up" : "down"}
          className="chevron-min-width"
        />
      </button>
      {!disabled && !collapsed && (
        <div
          className={classNames("btn-accordion-content")}
          tabIndex={index}
          aria-hidden={collapsed}
        >
          <AccordionSectionContent content={content} variant="helper" />
        </div>
      )}
      {showDivider && <div className="border-b border-grey-40"></div>}
    </>
  );
};

interface AccordionProps {
  /**
   * The variant of the accordion. Could be simple or helper.
   */
  variant: "simple" | "helper" | "content";

  /**
   * The information the accordion will display.
   */
  sections: AccordionContentType[];

  /**
   * If the accordion could have more than one section open at same time. By default this is false.
   */
  expandMultiple: boolean;

  /**
   * Optional. Accordion title.
   */
  title?: string;

  /**
   * Optional. Additional classes to implement.
   */
  className?: string;

  /**
   * Optional. For changing the default chevron color.
   */
  chevronColor?: string;
}

export const Accordion: React.FunctionComponent<React.PropsWithChildren<AccordionProps>> = ({
  variant,
  sections,
  title = "",
  className,
  expandMultiple = false,
  chevronColor,
}) => {
  const [accordionItems, setAccordionItems] = useState<AccordionContentType[]>([]);

  React.useEffect(() => {
    setAccordionItems(
      sections.map((section) => ({
        ...section,
        collapsed: section.collapsed ?? true,
      })),
    );
  }, [sections.length]);

  const toggleItem = (id: number) => {
    setAccordionItems((prevState: AccordionContentType[]) => {
      const index = prevState.findIndex((item) => item.id == id);
      prevState[index].collapsed = !prevState[index].collapsed;
      return [...prevState];
    });

    if (!expandMultiple) toggleAllExceptCurrentOne(true, id);
  };

  const toggleAllExceptCurrentOne = (collapsed: boolean, id: number) => {
    setAccordionItems((prevState: AccordionContentType[]) => {
      const items = prevState.map((item) => {
        if (item.id !== id) item.collapsed = collapsed;
        return item;
      });
      return [...items];
    });
  };

  const isContent = variant === "content";
  const isSimple = variant === "simple";
  const isHelper = variant === "helper";

  const backgroundClasses = {
    "bg-white": isContent,
    "bg-grey-lightest": isSimple,
    "bg-cloud-40": isHelper,
  };

  return (
    <div className={classNames(className, isContent ? "m-0" : "my-5", backgroundClasses)}>
      {title && isSimple && (
        <AccordionTitle
          title={title}
          titleClassName="flex justify-center m-5 text-center"
        ></AccordionTitle>
      )}
      {isSimple && <AccordionDivider />}
      <ul className="list-none m-0 p-0">
        {accordionItems.map((item, index) => {
          if (isSimple) {
            return (
              <li key={item.id}>
                <AccordionSectionSimpleVariant
                  key={item.id}
                  title={item.title}
                  content={item.content || ""}
                  collapsed={item.collapsed}
                  disabled={item.disabled}
                  onClick={() => toggleItem(item.id)}
                  index={index + 1}
                  chevronColor={chevronColor}
                />
              </li>
            );
          } else if (isContent) {
            return (
              <li key={item.id}>
                <AccordionSectionContentVariant
                  {...item}
                  key={item.id}
                  onClick={() => toggleItem(item.id)}
                  index={index + 1}
                />
              </li>
            );
          } else {
            return (
              <li key={item.id}>
                <AccordionSectionHelperVariant
                  key={item.id}
                  title={item.title}
                  content={item.content || ""}
                  collapsed={item.collapsed}
                  disabled={item.disabled}
                  onClick={() => toggleItem(item.id)}
                  index={index + 1}
                  chevronColor={chevronColor}
                  showIcon={item.showIcon}
                  showDivider={item.showDivider}
                />
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};
