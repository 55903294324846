/** @format */

import type React from "react";

interface LinkableText {
  url?: string;
  callback?: boolean;
  text: string;
}

const getLinks = (text: string): Array<LinkableText> => {
  const placeholder = "@@@@@";

  const links = text.match(/\[([^[]+)\](\([^)\s]*\))/gm);
  const callbacks = text.match(/\[([^[]+)\](\{[^)\s]*\})/gm);

  let updatedText = text;
  if (links && links.length) {
    const linkData = links.map((link) => {
      const [title, url] = link.slice(1, -1).split("](");
      // using a placeholder so we can split on a consistent string
      updatedText = updatedText.replace(link, placeholder);
      return {
        url,
        text: title,
        originalText: link,
      };
    });

    return updatedText.split(placeholder).reduce<Array<LinkableText>>((list, next, index) => {
      if (next !== "") {
        list.push({ text: next });
      }
      if (linkData.length > index) {
        list.push(linkData[index]);
      }
      return list;
    }, []);
  }

  if (callbacks && callbacks.length) {
    const callbackData = callbacks.map((callback) => {
      const [title, fn] = callback.slice(1, -1).split("]{");

      updatedText = updatedText.replace(callback, placeholder);
      return {
        callback: true,
        text: title,
      };
    });

    return updatedText.split(placeholder).reduce<Array<LinkableText>>((list, next, index) => {
      if (next !== "") {
        list.push({ text: next });
      }
      if (callbackData.length > index) {
        list.push(callbackData[index]);
      }
      return list;
    }, []);
  }

  return [{ text }];
};

const combine = (...args: string[]): string => {
  return args.filter((x) => x && x !== " ").join(" ");
};

/**
 * This Markdown Text formatter currently only supports bold and links to fit the immediate needs.
 * It should be extended as needed.
 *
 * @param
 * @returns {React.ReactElement}
 */
const MarkdownText = ({
  containerClassName = "",
  className = "",
  linkStyle = {},
  text,
  callback,
  ...props
}: MarkdownTextProps): React.ReactElement => {
  if (!text) {
    return <></>;
  }

  // split to grab bold parts of the text
  const parts = text.split("**");

  return (
    <p className={containerClassName}>
      {parts.map((part, index) => {
        const fontClassName = index % 2 !== 0 ? "font-bold" : "";

        return getLinks(part).map((linkableText: LinkableText, idx) => {
          if (linkableText.url) {
            if (linkableText.url.includes("http")) {
              return (
                <a
                  key={linkableText.text + idx}
                  href={linkableText.url}
                  rel={"noreferrer"}
                  target={"_blank"}
                >
                  <span
                    style={linkStyle}
                    className={combine(className, fontClassName, "underline")}
                    {...props}
                  >
                    {linkableText.text}
                  </span>
                </a>
              );
            } else {
              return (
                <a key={linkableText.text + idx} href={linkableText.url}>
                  <span
                    style={linkStyle}
                    className={combine(className, fontClassName, "underline")}
                    {...props}
                  >
                    {linkableText.text}
                  </span>
                </a>
              );
            }
          }
          if (linkableText.callback) {
            return (
              <a
                key={linkableText.text + idx}
                onClick={() => callback?.(linkableText.text)}
                className="cursor-pointer"
              >
                <span
                  style={linkStyle}
                  className={combine(className, fontClassName, "underline")}
                  {...props}
                >
                  {linkableText.text}
                </span>
              </a>
            );
          }
          return (
            <span key={part + idx} className={combine(className, fontClassName)} {...props}>
              {linkableText.text}
            </span>
          );
        });
      })}
    </p>
  );
};

export interface MarkdownTextProps {
  containerClassName?: string;
  className?: string;
  linkStyle?: object;
  text: string;
  callback?: (text: string) => void;
}

export default MarkdownText;
