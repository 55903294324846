/** @format */
import React from "react";
import ReactMarkdown from "react-markdown";

import { Typography } from "@alphamedical/components";

import InformationalCard from "src/v2/components/InformationalCard/InformationalCard";

interface CareGuideHeaderSectionProps {
  quickFactContent: string | undefined;
  guideBodyContent: string | undefined;
}

const title = "Care Guide";

export const CareGuideHeaderSection = ({
  quickFactContent,
  guideBodyContent,
}: CareGuideHeaderSectionProps): JSX.Element => {
  return (
    <div className="flex flex-col items-center mb-4 p-6 bg-white shadow">
      <Typography variant="h2" className="mt-2 ">
        {title}
      </Typography>
      {quickFactContent && <QuickFactCard content={quickFactContent} />}
      {guideBodyContent && <CareGuideBodySection content={guideBodyContent} />}
    </div>
  );
};

interface QuickFactCardProps {
  content: string | undefined;
}

// TODO refactor InformationalCard component to handle FA icons.
const QuickFactCard = ({ content }: QuickFactCardProps): JSX.Element => {
  return content ? (
    <InformationalCard
      icon="/assets/tip-icon.svg"
      title="Quick fact"
      type="Brand"
      content={content}
      containerClasses="mt-8 w-full"
    />
  ) : (
    <></>
  );
};

interface CareGuideBodySectionProps {
  content: string | undefined;
}

const CareGuideBodySection = ({ content }: CareGuideBodySectionProps): JSX.Element => {
  return content ? (
    <Typography variant="body1" className="pt-6">
      <ReactMarkdown skipHtml>{content}</ReactMarkdown>
    </Typography>
  ) : (
    <></>
  );
};
