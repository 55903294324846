/** @format */

import type { AriaRole } from "react";
import type React from "react";

import CarrotSvg from "src/v2/components/Svgs/CarrotSvg";
import IconText from "src/v2/components/TreatmentInfo/IconText";
import { COLORS } from "src/v2/utils/colors";

interface IconTextChevronProps {
  planAction: string;
  icon: string;
  iconWidth?: number;
  iconAlt: string;
  text: string | React.ReactNode;
  textColor?: string;
  tabIndex?: number;
  role?: AriaRole;
  onClick?: (key: string) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
}

export const IconTextChevron = ({
  planAction,
  icon,
  iconWidth,
  iconAlt,
  text,
  textColor = "text-forest-darker",
  role,
  tabIndex = 0,
  onClick,
  onKeyDown,
}: IconTextChevronProps) => {
  const handleClick = () => {
    if (onClick && typeof onClick === "function") {
      onClick(planAction);
    }
  };

  return (
    <div
      onClick={handleClick}
      key={planAction}
      tabIndex={tabIndex}
      role={role}
      className={`mt-6 flex justify-between items-center ${onClick && "cursor-pointer"}`}
      onKeyDown={onKeyDown}
    >
      <IconText
        icon={icon}
        iconWidth={iconWidth}
        iconAlt={iconAlt}
        text={text}
        textAlignment={"items-center"}
        mb={"mb-0"}
        iconMarginTop={"mt-0"}
        textColor={textColor}
      />
      <CarrotSvg direction={"right"} fill={COLORS["grey-160"]} />
    </div>
  );
};
