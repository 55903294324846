/** @format */

import React from "react";

import type { LegacyConsultSchema } from "src/api";
import type { ConditionTreatmentInfo } from "src/v2/constants";

import IconTextLink from "src/v2/components/TreatmentInfo/IconTextLink";
import { getConditionTreatmentInfo } from "src/v2/constants";
import { useStoreDispatch, useStoreState } from "src/v2/models";

interface TreatmentInfoProps {
  conditionKey: string;
}

/**
 * Displays a list of cards with icons to the left. Then the title. Then a right arrow. Clicking on the card will
 * direct the uer to the given href.
 *
 * @param conditionKey key of the condition
 * @constructor
 */
const TreatmentInfo = ({ conditionKey }: TreatmentInfoProps) => {
  const [conditionTreatmentInfo, setTreatmentInfo] = React.useState<ConditionTreatmentInfo[]>([]);
  const { consultsByCondition } = useStoreState((state) => state.consults);
  const dispatch = useStoreDispatch();

  React.useEffect(() => {
    dispatch.consults.fetchConsultsByCondition(conditionKey);
  }, []);

  React.useEffect(() => {
    if (consultsByCondition.length > 0) {
      let firstApproved: LegacyConsultSchema | null = null;
      consultsByCondition.every((consult) => {
        if (consult.approved_at && consult.consult_type === "INITIAL_CONSULT") {
          firstApproved = consult;
          return false;
        }
        return true;
      });
      if (firstApproved) {
        const info = getConditionTreatmentInfo(conditionKey, firstApproved);
        if (info) {
          setTreatmentInfo(info);
        }
      }
    }
  }, [consultsByCondition]);

  return (
    <div className={"mt-10 mb-5"}>
      {conditionTreatmentInfo.map((treatmentInfo, idx) => {
        return (
          <IconTextLink
            showBorderTop={idx !== 0}
            key={treatmentInfo.title.replace(" ", "")}
            treatmentInfo={treatmentInfo}
          />
        );
      })}
    </div>
  );
};

export default TreatmentInfo;
