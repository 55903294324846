/** @format */

import type { Action, State, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";

import type { AlphaMedication } from "src/api";
import type { MedicationOrder } from "src/v2/types/medications";

import { MedicationsService } from "src/api";
import { _GET, getTokenHeaderV2 } from "src/helpers/http";
import { isAlphaMedication } from "src/routes/Prescriptions/ActivePrescriptions";

import type { CreateModel } from "./_create";

export interface MedicationModel {
  medicationsByCondition?: MedicationOrder[];
  medications: AlphaMedication[];

  setMedicationsByCondition: Action<MedicationModel, MedicationOrder[]>;
  setMedications: Action<MedicationModel, AlphaMedication[]>;

  fetchMedicationsByCondition: Thunk<MedicationModel, { params: { [x: string]: any } } | void>;
  fetchMedications: Thunk<MedicationModel, void, any, object, Promise<AlphaMedication[]>>;
}

export const weightLossMedicationSelector = (state: State<CreateModel>) =>
  state.medications.medications.filter(
    (value) => value.condition?.key && ["weight-loss", "diabetes"].includes(value.condition.key),
  );

export const medicationStore: MedicationModel = {
  medicationsByCondition: [],
  medications: [],

  setMedicationsByCondition: action((state, medications) => {
    state.medicationsByCondition = [...medications];
  }),

  fetchMedicationsByCondition: thunk(async (actions, payload, { getStoreState }) => {
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;
    let paramStr = "";
    if (!!payload && !!payload.params) {
      paramStr =
        "?" +
        Object.keys(payload.params)
          .map((k) => `${k}=${payload.params[k]}`)
          .join("&");
    }
    const url = `/v2/users/${currentProfileId}/orders-by-condition${paramStr}`;
    const response = await _GET(url, {}, getTokenHeaderV2());
    actions.setMedicationsByCondition(response);
  }),
  setMedications: action((state, medications) => {
    state.medications = medications;
  }),
  fetchMedications: thunk(async (actions, payload, { getStoreState }) => {
    const medicationsResponse = await MedicationsService.getMedications({ userId: "me" });

    // filter out non-alpha meds and legacy pharmacy providers
    const alphaMedications = medicationsResponse.filter(
      (medication) =>
        isAlphaMedication(medication) &&
        (!medication.pharmacy_provider || medication.pharmacy_provider.key === "welldyne"),
    ) as AlphaMedication[];

    actions.setMedications(alphaMedications);
    return alphaMedications;
  }),
};
