/** @format */

import React from "react";

interface ArrowSvgProps {
  fill?: string;
  direction?: "left" | "up" | "down" | "right";
}

export const ArrowSvg = ({ fill = "#1C4A46", direction = "left" }: ArrowSvgProps) => {
  let rotate = 0;

  switch (direction) {
    case "right":
      rotate = 180;
      break;
    case "up":
      rotate = 90;
      break;
    case "down":
      rotate = -90;
      break;
  }
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" transform={`rotate(${rotate})`}>
      <path
        d="M0.25574 7.63242L6.0339 13.4814C6.19885 13.6484 6.41868 13.74 6.65308 13.74C6.88775 13.74 7.10746 13.6482 7.2724 13.4814L7.79701 12.9502C7.96182 12.7835 8.05262 12.5609 8.05262 12.3235C8.05262 12.0862 7.96182 11.856 7.79701 11.6894L4.42611 8.26971L15.1356 8.26971C15.6185 8.26971 16 7.88707 16 7.39818V6.64726C16 6.15836 15.6185 5.73714 15.1356 5.73714L4.38787 5.73714L7.79688 2.29841C7.96169 2.13145 8.05249 1.91485 8.05249 1.67744C8.05249 1.4403 7.96169 1.22054 7.79688 1.05372L7.27227 0.524266C7.10733 0.357306 6.88762 0.266321 6.65296 0.266321C6.41855 0.266321 6.19872 0.358491 6.03377 0.525451L0.25561 6.3743C0.0902777 6.54179 -0.000648499 6.76536 2.86102e-06 7.00303C-0.000516891 7.24149 0.0902777 7.4652 0.25574 7.63242Z"
        fill={fill}
      />
    </svg>
  );
};
