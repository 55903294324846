/** @format */

import { isArray } from "lodash";
import moment from "moment";

import { now } from "src/utils/date_utils";
import { _date, _phone } from "src/v2/utils/auth/_regex";

export const validate =
  (fields: any[], fieldCanBeShown: any, page: { mustHaveValues: any }) => (values: any) => {
    const errors: any = {};

    fields.forEach((field: any) => {
      let hasValue = false;
      const val = values[field.key];
      if (isArray(val)) {
        hasValue = val.length > 0;
      } else {
        hasValue = val != undefined;
      }
      if (fieldCanBeShown(values, field) && field.required && !hasValue) {
        errors[field.key] = "Required";
      }
      if (field.minLength >= 0 && val && val.length < field.minLength) {
        errors[field.key] = `Min: ${field.minLength} characters`;
      }
      if (field.key === "phone_number") {
        if (val) {
          const regex = _phone;
          if (!regex.test(val)) {
            errors[field.key] = "Invalid phone number. Must be in the format 987-654-3210";
          }
        }
      }
      if (field.type === "text" && field.subtype === "date") {
        if (val) {
          const regex = new RegExp(_date, "ig");
          if (!regex.test(val)) {
            errors[field.key] = "Please enter date as mm-dd-yyyy.";
          }
        }
      }
      if (field.type === "text" && field.subtype === "date" && field.key === "dob") {
        const today = now("MM-dd-yyyy");
        if (val) {
          const future = moment(val).isAfter(today);
          const dateDiff = moment(val).diff(today, "years");
          const maxAge = dateDiff <= -125;
          if (future) {
            errors[field.key] = "Date cannot be in the future";
          }
          if (maxAge) {
            errors[field.key] = "Date is too far in the past";
          }
        }
      }
    });
    const { mustHaveValues } = page;
    if (mustHaveValues) {
      mustHaveValues.map((field: any) => {
        if (values[field.fieldKey]) {
          const currentValue = values[field.fieldKey];

          if (currentValue instanceof Array) {
            if (currentValue.indexOf(field.value) < 0) {
              errors[field.fieldKey] = `This field must be marked ${field.value} to continue`;
            }
          } else if (values[field.fieldKey] !== field.value) {
            errors[field.fieldKey] = `This field must be marked ${field.value} to continue`;
          }
        }
      });
    }

    return errors;
  };
