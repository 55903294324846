/** @format */
import React from "react";

import { Typography } from "@alphamedical/components";

import type { Content } from "src/api";

interface CareGuideNextStepsSectionProps {
  nextSteps: Content | undefined;
}

const title = "Next steps";

export const CareGuideNextStepsSection = ({
  nextSteps,
}: CareGuideNextStepsSectionProps): JSX.Element => {
  return nextSteps ? (
    <div className="flex flex-col mb-4 p-6 bg-white justify-start shadow">
      <Typography variant="h3">{nextSteps?.group_section_title?.title || title}</Typography>
      <ul className="mb-0 pl-6">
        {nextSteps.subSection?.map((step, idx) => {
          return (
            <li key={idx}>
              <Typography variant="body1">{step.subtext}</Typography>
            </li>
          );
        })}
      </ul>
    </div>
  ) : (
    <></>
  );
};
