/** @format */

import type { match } from "react-router-dom";

import React from "react";
import { useHistory } from "react-router-dom";

import { toLower } from "lodash";

import { PageWrapper } from "src/components/PageWrapper";
import { braze } from "src/utils/braze";
import { now } from "src/utils/date_utils";
import InfoPage from "src/v2/components/InfoPage/InfoPage";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { getConditionInfo } from "src/v2/constants";

interface VisitUnavailableParams {
  step: "notify-me" | "confirmation" | "error";
  state?: string;
  visit?: string;
}

export const VisitUnavailablePage = ({ match }: { match: match<VisitUnavailableParams> }) => {
  const step = match.params.step;
  const state = match.params.state;
  const visit = match.params.visit;
  const history = useHistory();
  const condition = visit ? toLower(getConditionInfo(visit).label) : "this visit";

  const addToWaitlist = () => {
    const eventTriggered = braze.logCustomEvent(`waitlist_restricted_visit_${state}`, {
      segment: state,
      service: visit,
      date: now("PPPPpppp"),
    });

    if (eventTriggered) {
      history.push(`/visit-unavailable/confirmation/${state}/${visit}`);
    } else {
      console.log("braze error");
      history.push("/visit-unavailable/error");
    }
  };

  React.useEffect(() => {
    if (step === "notify-me" && !state) {
      history.push("/visit-unavailable/error");
    }
    if (step === "notify-me" && !visit) {
      history.push("/visit-unavailable/error");
    }
  });

  return (
    <PageWrapper>
      <div className="my-8 flex flex-col max-w-xl mx-auto">
        {step === "notify-me" && state && visit && (
          <InfoPage
            title={`Care for ${condition} isn't available in your state right now.`}
            content={`We’ll notify you via email when we’re able to provide care for ${condition} in your state.`}
            icon="/assets/coming-soon.svg"
            ctaText="Notify me"
            ctaCallback={addToWaitlist}
          />
        )}
        {step === "confirmation" && visit && (
          <InfoPage
            title={`Thanks! We’ll send you an email when care for ${condition} is available in your state.`}
            icon="/assets/group-3.svg"
            ctaText="Explore other visits"
            ctaCallback={() => {
              history.push("/your-care/explore-visits");
            }}
          />
        )}
        {step === "error" && (
          <InfoPage
            title={"We’re sorry but something went wrong."}
            content={"Please try again later."}
            icon="/assets/layer-25.svg"
            ctaText="Explore other visits"
            ctaCallback={() => {
              history.push("/your-care/explore-visits");
            }}
          />
        )}
      </div>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(VisitUnavailablePage);
