/** @format */

import type { DropFilesEventHandler } from "react-dropzone";

import React from "react";
import { Field, Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import Select from "react-select";

import { usePubNub } from "pubnub-react";

import { Popup, useWindowSize } from "@alphamedical/components";

import type { SendMessageProps } from "src/components/Chat/utils";
import type { Channel } from "src/v2/models/channels";
import type { Tag } from "src/v2/models/tags";

import BackButton from "src/components/_buttons/BackButton";
import { useChannels } from "src/components/Chat/ChannelLoader";
import UploadedAttachments from "src/components/Chat/ChatChannel/UploadedAttachments";
import { MessageInput } from "src/components/Chat/Message/MessageInput";
import { onFileDrop, sendMessage } from "src/components/Chat/utils";
import { isOption } from "src/components/DynamicForm/types";
import { NavMenuButtonPortal } from "src/components/HeaderNav/NavMenuButton";
import { Loader } from "src/components/Loader";
import { PageWrapper } from "src/components/PageWrapper";
import { serializeDependency } from "src/utils";
import { useAuthenticatedUser, useServices, useTagGroups, useTags } from "src/utils/hooks";
import { useFeatureFlags } from "src/utils/hooks/useFeatureFlags";
import { Button } from "src/v2/components/Button";
import { MarkdownText } from "src/v2/components/MarkdownText";
import { useStoreDispatch } from "src/v2/models";
import { useQuery } from "src/v2/utils/useQuery";

interface OptionProps {
  label: string;
  value: string;
}

export interface MessageFields {
  subject?: string;
  service?: string;
}

const NewMessageThread = () => {
  const dispatch = useStoreDispatch();
  const [authenticatedUser] = useAuthenticatedUser();
  const pubnub = usePubNub();
  const query = useQuery();
  const channels = useChannels();
  const [selectedService, setSelectedService] = React.useState<OptionProps>();
  const [selectedSubject, setSelectedSubject] = React.useState<OptionProps>();
  const [text, setText] = React.useState("");
  const [attachments, setAttachments] = React.useState<any[]>([]);
  const [channel, setChannel] = React.useState<Channel>();
  const { serviceList, serviceOptions } = useServices();
  const { subjectList, subjectOptions } = useTags();
  const groupList = useTagGroups();
  const [categoryOptions, setCategoryOptions] = React.useState<any>({});
  const [selectedGroup, setSelectedGroup] = React.useState<string>("");
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
  const enableNewDropdowns = useFeatureFlags("enableHierarchicalDropdowns");

  const [popupOpen, setPopupOpen] = React.useState<boolean>(false);
  const [popupContent, setPopupContent] = React.useState<string>("");
  const [popupTitle, setPopupTitle] = React.useState<string>("");
  const [, height] = useWindowSize();

  const openAndSetPopupContent = (tag: Tag | undefined) => {
    if (tag?.popup_active && tag?.popup_content) {
      setPopupOpen(true);
      setPopupContent(tag.popup_content);
      setPopupTitle(tag?.popup_title || "");
    }
  };

  const createOptionsForGroup = (
    providedSubjectList: Tag[],
    providedSubjectOptions: OptionProps[],
    slug: string,
  ) => {
    return providedSubjectList
      .filter((subject) => subject.parent_tag_id == slug)
      .map((subject) => providedSubjectOptions.find((option) => option.value == subject.slug));
  };

  const createBackHeader = (label: string) => {
    return (
      <div className="cursor-pointer" onClick={() => setSelectedGroup("")}>
        <img className="inline-block" src={"/assets/back-arrow.svg"} alt="Back Arrow" />
        &nbsp;{label}
      </div>
    );
  };

  const createGroupComponent = (group: Tag) => {
    return {
      label: (
        <div className="font-bold">
          <img className="inline-block" src={"/assets/carrot-right.svg"} alt="Right Arrow" />
          &nbsp;{group.label}
        </div>
      ),
      value: group.slug,
    };
  };

  React.useEffect(() => {
    let groupedOptions;
    const currentGroup = groupList.find((group) => group.slug == selectedGroup);
    if (currentGroup) {
      groupedOptions = [
        {
          label: createBackHeader(currentGroup.label),
          options: createOptionsForGroup(subjectList, subjectOptions, currentGroup.slug),
        },
      ];
    } else {
      groupedOptions = groupList.reduce((arr, group) => {
        if (subjectList.filter((subject) => subject.parent_tag_id == group.slug).length > 0) {
          arr.push(createGroupComponent(group));
        }
        return arr;
      }, [] as any[]);
    }
    setCategoryOptions(groupedOptions);
  }, [groupList, subjectList, selectedGroup]);

  const clinicalSubjects = subjectList
    .filter((subject) => subject.team === "CLINICAL")
    .map((subject) => ({ label: subject.label, value: subject.slug }));

  const prePopFields = () => {
    const condition_key = query.get("condition_key");
    const category = query.get("category");

    if (condition_key) {
      if (condition_key === "other") {
        setSelectedService({ label: "Other", value: "other" });
      } else {
        const [service] = serviceOptions.filter(
          (service) => service && service.value === condition_key,
        );
        service && setSelectedService(service);
      }
    }

    if (category === "membership") {
      setSelectedSubject({
        label: "Question about my Alpha plan",
        value: "question-about-my-membership",
      });
    }
  };

  React.useEffect(() => {
    prePopFields();
  }, [serializeDependency(serviceList, ["id"])]);

  React.useEffect(() => {
    const support_channel = channels.find((channel) => channel.channel_type === "SUPPORT");
    support_channel && setChannel(support_channel);
  }, [serializeDependency(channels, ["id"])]);

  const customStyles = {
    control: (styles: any) => ({
      ...styles,
      paddingTop: ".35rem",
      paddingBottom: ".35rem",
      borderRadius: ".125rem",
      marginBottom: "2.5rem",
    }),
  };

  const validate = (values: any) => {
    const errors: MessageFields = {};

    if (!values.service) {
      errors.service = "This is a required field";
    }

    if (!values.subject) {
      errors.subject = "This is a required field";
    }

    if (
      values.service &&
      values.subject &&
      values.service.value === "other" &&
      clinicalSubjects.find((subject) => subject.value === values.subject.value)
    ) {
      errors.service =
        "You must select an Alpha Medical Condition to send a message with your chosen subject.";
    }
    return errors;
  };

  const onSubmit = (values: any) => {
    if (channel && channel.name && text && values.service && values.service.value) {
      const m: SendMessageProps = {
        text: text,
        authorId: authenticatedUser.id,
        channelName: channel.name,
        setText: setText,
        pubnub,
        categorySlug: values.subject.value,
        callBack: () => {
          window.location.assign(`/messages/${channel.name}`);
          setAttachments([]);
        },
        newMessageThread: true,
        conditionKey: values.service.value,
        attachments,
      };
      sendMessage(m);
    }
  };

  const onDrop: DropFilesEventHandler = (acceptedFiles, _rejectedFiles) => {
    channel &&
      onFileDrop({
        acceptedFiles,
        channels,
        channelName: channel.name,
        setAttachments,
        attachments,
        dispatch,
      });
  };

  const defaultSelectAdapter: React.FunctionComponent<any> = ({ input, meta, ...rest }) => {
    return (
      <>
        {meta.error && meta.touched && <p className="text-red text-xs mb-1">{meta.error}</p>}
        <Select {...input} {...rest} />
      </>
    );
  };

  const subjectHierarchicalSelectAdapter: React.FunctionComponent<any> = ({
    input,
    meta,
    ...rest
  }) => {
    return (
      <>
        {meta.error && meta.touched && <p className="text-red text-xs mb-1">{meta.error}</p>}
        <Select<any>
          {...input}
          {...rest}
          menuIsOpen={menuOpen}
          onChange={(option: OptionProps) => {
            if (option && groupList.map((x) => x.slug).indexOf(option.value) !== -1) {
              setSelectedGroup((cur) => (cur == "" ? option.value : ""));
              setMenuOpen(true);
            } else if (option) {
              const tag = subjectList.find((tag: Tag) => tag.slug === option.value);
              openAndSetPopupContent(tag);
              setSelectedSubject(option);
              setMenuOpen(false);
            }
          }}
          autoFocus={menuOpen}
          onFocus={() => setMenuOpen(true)}
          onBlur={() => setMenuOpen(false)}
          blurInputOnSelect={false}
          value={selectedSubject}
          styles={{
            groupHeading: (provided) => ({
              ...provided,
              fontWeight: "bold",
              fontSize: "1rem",
              textTransform: "none",
              color: "black",
              height: "2rem",
              "&:hover": {
                backgroundColor: "#DEEBFF",
              },
            }),
            option: (provided) => ({
              ...provided,
              paddingLeft: "36px",
            }),
          }}
        />
      </>
    );
  };

  const valueWatch = (values: any) => {
    // Match the form and service values to the full subject object
    const _subject =
      values.subject && subjectList.find((subject) => subject.slug === values.subject.value);
    const service =
      values.service &&
      serviceList.find(
        (service) => service.condition && service.condition.key === values.service.value,
      );
    // Find the clinical channel that matches the selected service
    const clinicalChannel =
      service && channels.find((channel) => channel.service && channel.service.id === service.id);
    const supportChannel = channels.find((channel) => channel.channel_type === "SUPPORT");

    // If the selected subject is not a support/pharmacy subject AND clinicalChannel is set use the clinicalChannel, else use support channel
    const _channel =
      ["PHARMACY", "SUPPORT"].indexOf(_subject?.team || "") === -1 && clinicalChannel
        ? clinicalChannel
        : supportChannel;

    // Set the channel state
    setChannel(_channel);
  };

  if (!authenticatedUser) return <Loader show={true} />;

  return (
    <div style={{ height: `${height - 80}px` }}>
      <Popup headerText={popupTitle} isOpen={popupOpen} onRequestClose={() => setPopupOpen(false)}>
        <div className="p-4">
          <div className="mb-4">
            <MarkdownText
              linkStyle={{ color: "#6271C2", fontWeight: "bold" }}
              text={popupContent}
            />
          </div>
          <Button onClick={() => setPopupOpen(false)}>Continue</Button>
        </div>
      </Popup>
      <NavMenuButtonPortal>
        <BackButton />
      </NavMenuButtonPortal>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={{
          service: selectedService,
          subject: selectedSubject,
        }}
        render={({ handleSubmit, submitting, values, pristine }) => {
          valueWatch(values);
          return (
            <form
              onSubmit={handleSubmit}
              className="mx-auto pt-4 bg-white w-full h-full flex flex-col"
            >
              <div className="py-12 text-center">
                <h4 className="text-xl">Message your Alpha Care Team</h4>
              </div>
              <div className="px-4">
                <Field
                  name="service"
                  component={defaultSelectAdapter}
                  className="mb-8"
                  placeholder={"Choose an Alpha Medical Condition"}
                  options={serviceOptions}
                  styles={customStyles}
                  isSearchable={false}
                />
                <OnChange name={"service"}>
                  {(value: OptionProps | "") => {
                    if (isOption(value)) {
                      setSelectedService(value);
                    }
                  }}
                </OnChange>
                <Field
                  name="subject"
                  component={
                    enableNewDropdowns ? subjectHierarchicalSelectAdapter : defaultSelectAdapter
                  }
                  placeholder={"Choose a subject"}
                  options={enableNewDropdowns ? categoryOptions : subjectOptions}
                  styles={customStyles}
                  isSearchable={false}
                />
                <OnChange name={"subject"}>
                  {(value: OptionProps | "") => {
                    if (isOption(value) && !enableNewDropdowns) {
                      setSelectedSubject(value);
                    }
                  }}
                </OnChange>
              </div>
              {attachments.length > 0 && (
                <UploadedAttachments attachments={attachments} setAttachments={setAttachments} />
              )}
              <div className="mt-auto sticky left-0 right-0 bottom-0 flex items-center justify-center p-3">
                <MessageInput
                  text={text}
                  setText={setText}
                  onDrop={onDrop}
                  disableOption={submitting || (text.length === 0 && attachments.length === 0)}
                />
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};

const NewMessageThreadWrapper = () => (
  <PageWrapper fitToPageHeight={true} cxMaxWidth={"max-w-xl w-full"} cxPadding={"p-0"}>
    <NewMessageThread />
  </PageWrapper>
);

export default NewMessageThreadWrapper;
