/** @format */

import React from "react";
import { Field, useForm, useFormState } from "react-final-form";

import Fuse from "fuse.js";
import _ from "lodash";

import type { Option } from "src/v2/models/record";

import { UserProblemsService } from "src/api";

interface ProblemSearchFieldProps {
  field: any;
}

export const ProblemSearchField = ({ field }: ProblemSearchFieldProps) => {
  const form = useForm();
  const formState = useFormState();
  const fieldRef = formState.values[field.key];
  const [problemResults, setProblemResults] = React.useState<Option[]>([]);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = React.useState<Option[]>([]);
  const [searchTerm, setSearchTerm] = React.useState<string>("");

  const fetchProblem = (problemSearchTerm: string) => {
    if (problemSearchTerm.length > 3) {
      UserProblemsService.searchProblemsTable({ term: problemSearchTerm }).then((problems) => {
        const fuse = new Fuse(problems, { keys: ["term"], minMatchCharLength: 4 });
        const results = fuse.search(problemSearchTerm).filter((item, idx) => idx < 10);
        const problemOptions = results.map((problem) => {
          return { value: problem.concept_id, label: problem.term };
        });
        setProblemResults(problemOptions);
      });
    }
  };

  const debouncedFetch = _.debounce(fetchProblem, 200);
  const onChange = (e: any) => {
    if (e.target.value.length > 1) debouncedFetch(e.target.value);
    setSearchTerm(e.target.value);
  };

  const chooseOption = (option: any) => {
    if (!selectedOptions.some((e) => e.value == option.value)) {
      setSelectedOptions([...selectedOptions, option]);
      form.change(`${field.key}`, [...selectedOptions, option]);
      setSearchTerm("");
    }
  };

  const removeOption = (option: any) => {
    const filteredOptions = selectedOptions.filter((e) => e.value !== option.value);
    setSelectedOptions(filteredOptions);
    form.change(field.key, filteredOptions);
  };

  return (
    <Field name={field.key}>
      {() => {
        return (
          <>
            <div
              className="flex items-center"
              style={{ backgroundColor: "#F8FAFC", marginBottom: "1.25rem" }}
            >
              <input
                name={field.key}
                placeholder="Search for a medical condition/procedure"
                className={"w-full py-3 px-5"}
                onChange={onChange}
                value={searchTerm}
                style={{ backgroundColor: "transparent", outline: "none" }}
              />
              <img className="mr-3" src="/assets/search.svg" />
            </div>
            {searchTerm.length > 1 && (
              <>
                {problemResults.length > 0 && (
                  <div className="-mt-6">
                    {problemResults.map((result) => (
                      <div
                        key={result.label}
                        className="p-4 bg-white hover:bg-grey-1 shadow-lg cursor-pointer"
                        onClick={() => chooseOption(result)}
                      >
                        {result.label}
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
            {fieldRef && fieldRef.length > 0 && (
              <>
                {fieldRef.map((option: any, idx: number) => (
                  <div
                    className={`flex justify-between items-center pb-6 pt-3 ${
                      idx !== fieldRef.length - 1 && "border-b border-grey"
                    }`}
                    key={option.value}
                  >
                    {option.label}
                    <img
                      src="/assets/cancel.svg"
                      className="ml-4 cursor-pointer"
                      onClick={() => removeOption(option)}
                    />
                  </div>
                ))}
              </>
            )}
            {!isOpen && (
              <div style={{ marginBottom: "3rem", marginTop: 0 }}>
                <span
                  className="text-sm"
                  style={{ color: "#6BA5A5", fontWeight: 500 }}
                  onClick={() => {
                    setIsOpen(!isOpen);
                    form.change("display-problems-other", "true");
                  }}
                >
                  Can't find my medical condition/procedure?
                </span>
              </div>
            )}
          </>
        );
      }}
    </Field>
  );
};
