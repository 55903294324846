/** @format */
import type { Action, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";
import type { ListenerParameters } from "pubnub";
import type PubNub from "pubnub";

import type { CreateModelDispatch } from "./_create";

interface UnreadMessageCounts {
  [channelId: string]: number;
}

// Pubnubs built in type seems to be missing the data field, so we add it in our own type
type MessageActionEvent = PubNub.MessageActionEvent & { data?: { type?: string; value?: string } };
type EmptyObject = Record<string, never>;

export interface PubNubModel {
  uuid?: string;
  listeners: ListenerParameters | EmptyObject;
  unreadMessages: UnreadMessageCounts | EmptyObject;

  setUUID: Action<PubNubModel, string | undefined>;

  setUnreadMessages: Action<PubNubModel, UnreadMessageCounts>;

  addUnreadMessage: Action<PubNubModel, { channel: string; publisher: string }>;

  setListeners: Action<PubNubModel, { pubnub: PubNub; listeners: ListenerParameters }>;

  initialize: Thunk<PubNubModel, { pubnub: PubNub }>;

  reset: Thunk<PubNubModel, { pubnub: PubNub }>;
}

const onNewMessage =
  (dispatch: CreateModelDispatch) =>
  ({ publisher, channel }: PubNub.MessageEvent) => {
    dispatch.pubnub.addUnreadMessage({ publisher, channel });
  };

const onNewMessageAction = (dispatch: CreateModelDispatch) => (event: MessageActionEvent) => {
  if (event.data && event.data.type === "receipt" && event.data.value === "message_read") {
    dispatch.pubnub.setUnreadMessages({ [event.channel]: 0 });
  }
};

export const pubNubStore: PubNubModel = {
  listeners: {},
  unreadMessages: {},

  setUUID: action((state, uuid) => {
    state.uuid = uuid;
  }),

  setUnreadMessages: action((state, counts) => {
    state.unreadMessages = { ...state.unreadMessages, ...counts };
  }),

  addUnreadMessage: action((state, { channel, publisher }) => {
    if (publisher !== state.uuid) {
      state.unreadMessages = {
        ...state.unreadMessages,
        [channel]: (state.unreadMessages[channel] || 0) + 1,
      };
    }
  }),

  setListeners: action((state, { pubnub, listeners }) => {
    pubnub.removeListener(state.listeners);
    pubnub.addListener(listeners);
    state.listeners = listeners;
  }),

  initialize: thunk(async (actions, { pubnub }, { dispatch }) => {
    actions.setUUID(pubnub.getUUID());
    actions.setListeners({
      pubnub,
      listeners: {
        message: onNewMessage(dispatch as CreateModelDispatch),
        messageAction: onNewMessageAction(dispatch as CreateModelDispatch),
      },
    });
  }),

  reset: thunk(async (actions, { pubnub }, { dispatch }) => {
    actions.setUUID(undefined);
    actions.setListeners({
      pubnub,
      listeners: {},
    });
  }),
};
