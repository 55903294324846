/** @format */

import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";

import { MaintenanceBanner } from "src/components";
import { GenericBanner } from "src/components/Maintenance/GenericBanner";
import { useGetChannels } from "src/utils/hooks";
import { useUnreadMessages } from "src/utils/hooks/pubnub";
import { TopBarNavigationPdb } from "src/v2/components/WithTopBarNavigation/TopBarNavigationPdb";
import { useStoreState } from "src/v2/models";

/**
 * A higher-order component that adds the `TopBarNavigation` component to the wrapped component.
 *
 * @param WrappedComponent The component to be wrapped with `TopBarNavigation`.
 * @returns The wrapped component with `TopBarNavigation` added.
 */
export const WithTopBarNavigation = <T extends object>(
  WrappedComponent: React.ComponentType<T>,
) => {
  /**
   * The component that wraps `WrappedComponent` with `TopBarNavigation`.
   *
   * @param props The props to pass to `WrappedComponent` and `TopBarNavigation`.
   * @returns The wrapped component with `TopBarNavigation` added.
   */
  const TopBarNavigationWrapper = (props: T) => {
    const topBarProps = useStoreState((state) => state.topBar);
    const history = useHistory();

    const [helpOpen, setHelpOpen] = React.useState(false);
    const { maintenanceData, upcomingMaintenance } = useStoreState((state) => state.maintenance);
    const { channels = [] } = useGetChannels();
    const unreadMessages = useUnreadMessages();
    const notificationChannel = channels.find((value) => value.channel_type === "NOTIFICATION");
    const notificationCount = notificationChannel ? unreadMessages[notificationChannel.name] : 0;
    const notificationCallback = useMemo(
      () => () => {
        notificationChannel && history.push(`/messages/${notificationChannel.name}`);
      },
      [channels],
    );

    const notificationPageActive = notificationChannel
      ? history.location.pathname == `/messages/${notificationChannel.name}`
      : false;

    const inMessagingPath = history.location.pathname.includes("/messages");

    let showNotificationBell = !!notificationChannel;

    if (inMessagingPath) {
      showNotificationBell = false;
    }

    return (
      <>
        {upcomingMaintenance && <MaintenanceBanner data={maintenanceData} />}
        <GenericBanner />
        <TopBarNavigationPdb
          {...topBarProps}
          {...props}
          showNotificationButton={showNotificationBell}
          notifications={notificationCount}
          onNotificationCallback={notificationCallback}
          isNotificationPageActive={notificationPageActive}
        />
        <WrappedComponent {...props} />
      </>
    );
  };

  // Set the display name of the wrapper component for better debugging.
  const displayName = WrappedComponent?.displayName || WrappedComponent?.name || "Component";
  TopBarNavigationWrapper.displayName = `WithTopBarNavigation(${displayName})`;

  return TopBarNavigationWrapper;
};
