/** @format */

import React from "react";
import { useForm } from "react-final-form";

import { useMultiPageForm } from "@alphamedical/components";

export const ScreeningToolNav = () => {
  const { currentPageIdx, pageCount, hasNextPage, hasPrevPage, prevPage, nextPage } =
    useMultiPageForm();
  const form = useForm();
  const { hasValidationErrors } = form.getState();
  const canGoToNextPage = hasNextPage && !hasValidationErrors;
  return (
    <div className="flex justify-between mb-4 font-bold">
      <div className="flex items-center">
        <div className="w-24 h-4 relative overflow-hidden rounded-full bg-primary mr-4">
          <div
            className="h-full bg-sky absolute t-3"
            style={{ width: `${(100 * (currentPageIdx + 1)) / (pageCount + 1)}%` }}
          />
        </div>
        {currentPageIdx + 1} / {pageCount}
      </div>
      <div className="space-x-2">
        <button
          disabled={!hasPrevPage}
          type="button"
          onClick={prevPage}
          className={`bg-${hasPrevPage ? "sky" : "grey"} cursor-default px-2 py-1`}
        >
          ←
        </button>
        <button
          disabled={!canGoToNextPage}
          type="button"
          onClick={nextPage}
          className={`bg-${canGoToNextPage ? "sky" : "grey"} cursor-default px-2 py-1`}
        >
          →
        </button>
      </div>
    </div>
  );
};
