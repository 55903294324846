/** @format */

import React from "react";
import { Link } from "react-router-dom";

import moment from "moment";

import type { Consultation } from "src/v2/types/consultation";

import { CardContent } from "src/components/CardContent";
import { VisitSummary } from "src/components/Visits/VisitSummary";
import { getFollowupUrl, statusMapper } from "src/helpers/visitDetails";
import { AlphaModal } from "src/v2/components/AlphaModal";
import { useQuery } from "src/v2/utils/useQuery";

interface YourVisitCardsProps {
  consults: Consultation[];
  condition: string;
}

export const YourVisitCards = ({ consults, condition }: YourVisitCardsProps) => {
  const [summaryConsultId, setSummaryConsultId] = React.useState<number | undefined>();
  const consult_id = useQuery().get("consult_id");
  const consultPicker = consult_id
    ? consults.filter(
        (consult: Consultation) => consult.consult_type === "VISIT" && consult.id === +consult_id,
      )
    : consults;
  return (
    <div>
      {consultPicker.map((consult: Consultation) => {
        return (
          <div key={consult.id} className="bg-white mb-4 w-full flex flex-col p-6 relative">
            <img
              src="/assets/get-treated.svg"
              alt=""
              role="presentation"
              className="absolute opacity-50"
              style={{ top: "1.5rem", right: "1.5rem" }}
            />
            <CardContent
              detailList={[
                {
                  title: "status",
                  data: statusMapper(consult.status, consult.consult_type),
                },
                {
                  title: "date started",
                  data: moment(consult.created_at).format("l"),
                },
              ]}
            />
            {consult.status === "STARTED" &&
              ["RETURN_CONSULT", "FOLLOWUP"].indexOf(consult.consult_type) !== -1 && (
                <div>
                  <Link
                    to={getFollowupUrl(condition, consult.consult_type)}
                    className="no-underline font-bold text-violator mt-2"
                  >
                    <div className="inline-block">
                      Complete Your Visit <span className="fas fa-arrow-right text-sm" />
                    </div>
                  </Link>
                </div>
              )}
            {consult.consult_type === "VISIT" && consult.status === "APPROVED" && (
              <div
                className="inline-block font-bold text-sky-dark mt-2 cursor-pointer"
                onClick={() => setSummaryConsultId(consult.id)}
              >
                View Your Post-Visit Summary <span className="fas fa-arrow-right text-sm" />
              </div>
            )}
          </div>
        );
      })}
      <AlphaModal
        isOpen={!!summaryConsultId}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setSummaryConsultId(undefined)}
        className="max-w-xl mx-auto"
        cxPadding="p-0"
        closeIconColor="cloud"
        bgColor="white"
        addPaddingForNavBar={true}
      >
        {summaryConsultId && <VisitSummary consultId={summaryConsultId} />}
      </AlphaModal>
    </div>
  );
};
