/** @format */

import type React from "react";

import type { StateEligibilityError } from "src/components/DynamicForm/Validators/types";

import type { ValidationErrorContentProps } from "./types";

export const UnsupportedStateMessage: React.FC<
  ValidationErrorContentProps<StateEligibilityError>
> = ({ validationError: { state } }) => (
  <>
    <p>We're sorry, we do not support {state} for this service at this time. Check back soon.</p>
  </>
);
