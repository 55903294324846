/** @format */

import type React from "react";

import { Link } from "react-router-dom";

export interface LinkTracking {
  url: string;
  payload?: any;
}
interface ImageHeadingProps {
  title?: string;
  imageSrc?: string;
  description?: string;
  ctaLink?: string;
  ctaText?: string;
  secondaryCtaText?: string;
  secondaryCtaLink?: string;
  tracking?: LinkTracking;
  secondaryTracking?: LinkTracking;
  btnStyle?: string;
  bgColor?: string;
  hasArrow?: boolean;
  externalLink?: boolean;
  descriptionMargin?: string;
  extraClassName?: string;
  titleFontSize?: string;
}

const ImageHeading: React.FC<ImageHeadingProps> = ({
  title,
  imageSrc,
  description,
  ctaLink,
  ctaText,
  secondaryCtaLink,
  secondaryCtaText,
  btnStyle,
  bgColor,
  hasArrow,
  externalLink,
  children,
  descriptionMargin,
  extraClassName,
  titleFontSize,
}) => {
  const renderLink = (isExternal: boolean, link: string, text: string, styleAsLink?: boolean) => {
    const linkProps = {
      target: externalLink ? "_blank" : undefined,
      rel: externalLink ? "noopener noreferrer" : undefined,
      className:
        btnStyle || styleAsLink
          ? "font-bold text-sm border-b-1 border-primary pb-1 mt-6"
          : "btn btn-violator w-full mx-auto",
      "aria-describedby": externalLink ? "new-window-2" : undefined,
    };
    return (
      (isExternal && (
        <a href={link} {...linkProps}>
          {text}
          {hasArrow && <span className="ml-2 fa fa-arrow-right" />}
        </a>
      )) || (
        <Link to={link} {...linkProps}>
          {text}
          {hasArrow && <span className="ml-2 fa fa-arrow-right" />}
        </Link>
      )
    );
  };

  return (
    <div className={`text-center py-8 px-6 bg-${bgColor} ${extraClassName}`}>
      {title && <h5 className={`mb-6 w-full ${titleFontSize || "text-2xl"}`}>{title}</h5>}
      {imageSrc && <img alt="" role="presentation" className="mx-auto h-16" src={imageSrc} />}

      <div className="flex flex-col items-center w-full max-w-2xl mx-auto">
        {description && (
          <p className={`text-base mx-auto ${descriptionMargin || "mt-8 mb-4"} leading-relaxed`}>
            {description}
          </p>
        )}
        {children}
        {(ctaLink && ctaText && renderLink(externalLink || false, ctaLink, ctaText)) ||
          (ctaText && (
            <button disabled={true} className={btnStyle || `btn btn-primary w-full mx-auto`}>
              {ctaText}
            </button>
          ))}
        {secondaryCtaLink &&
          secondaryCtaText &&
          renderLink(externalLink || false, secondaryCtaLink, secondaryCtaText, true)}
      </div>
    </div>
  );
};

export default ImageHeading;
