/** @format */

import React from "react";
import { Form } from "react-final-form";

import { Condition, Typography } from "@alphamedical/components";
import { ButtonPdb, Paper } from "src/v2/designSystem";

import { type EmergencyContactSchema, UsersService } from "src/api";
import { DynamicField } from "src/components/DynamicForm";
import { axiosErrorHandler } from "src/components/DynamicForm/utils";
import { Loader } from "src/components/Loader";
import { PageWrapper } from "src/components/PageWrapper";
import { useGetOnMount } from "src/utils/hooks";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useCurrentUser } from "src/v2/models/profile";

const EmergencyContactInformation = () => {
  useTopBar({ title: "Emergency contact", variant: "nested", showBackButton: true });
  const user = useCurrentUser();
  const [emergencyContact, loading] = useGetOnMount<EmergencyContactSchema>(
    `/users/${user.id}/emergency_contact`,
    {},
  );

  const submitForm = async (values: any) => {
    UsersService.putUserEmergencyContact({ userId: user.id, requestBody: values }).catch(
      axiosErrorHandler,
    );
  };
  return (
    <PageWrapper cxPadding="px-0" fitToPageHeight>
      <Paper
        elevation={0}
        rounded={false}
        className="flex-1 mx-auto h-screen w-full max-w-2xl px-6 overflow-hidden pb-10"
      >
        <Loader show={loading}>
          <Form onSubmit={submitForm} initialValues={emergencyContact}>
            {({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit} className="flex flex-row mt-8 mb-16">
                <div className="flex-1">
                  <Typography variant="h4">
                    To be contacted in the event of an emergency.
                  </Typography>
                  <DynamicField
                    field={{
                      key: "name",
                      label: "Full name",
                      required: true,
                      type: "text",
                    }}
                    className="mt-4"
                  />
                  <DynamicField
                    field={{
                      key: "relationship",
                      title: "What is your relationship to that person?",
                      label: "Relationship to you",
                      placeholder: "Select",
                      required: true,
                      type: "select",
                      subtype: "dropdown",
                      options: [
                        {
                          value: "Spouse",
                          label: "Spouse",
                        },
                        { value: "Domestic", label: "Domestic partner" },
                        { value: "Significant-other", label: "Significant other" },
                        {
                          value: "Parent",
                          label: "Parent or guardian",
                        },
                        { value: "Child", label: "Child" },
                        {
                          value: "Sibling",
                          label: "Sibling",
                        },
                        {
                          value: "Friend",
                          label: "Friend",
                        },
                        {
                          value: "other",
                          label: "Other",
                        },
                      ],
                    }}
                    className="mt-2"
                  />
                  <Condition when="relationship" is="other">
                    <DynamicField
                      field={{
                        key: "relationship-other",
                        conditionals: [
                          {
                            key: "relationship",
                            value: "other",
                          },
                        ],
                        label: "Relationship (other)",
                        required: true,
                        type: "text",
                      }}
                    />
                  </Condition>
                  <DynamicField
                    field={{
                      key: "phone",
                      label: "Phone number",
                      required: true,
                      type: "text",
                      subtype: "phone",
                    }}
                    className="mt-2"
                  />
                  <ButtonPdb type={"submit"} disabled={submitting} className="mt-8 w-full">
                    Save
                  </ButtonPdb>
                </div>
              </form>
            )}
          </Form>
        </Loader>
      </Paper>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(EmergencyContactInformation);
