/** @format **/
import React from "react";

import classnames from "classnames";

import type { OptionType } from "@alphamedical/components";

import type { CustomOptionComponentProps } from "src/components/DynamicForm/Fields/CustomOptionComponents/types";

import SandBullet from "src/v2/components/SandBullet";

interface MedsWithInfoOption extends OptionType {
  sublabel?: string;
  type?: string;
  description?: string;
  info?: string[];
}

const MedsWithInfo = ({
  field,
  option,
  input,
  meta,
  defaultOption,
}: CustomOptionComponentProps) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const fieldOption: MedsWithInfoOption | undefined = field.options.find(
    (_option) => _option.label === option.label,
  );

  if (fieldOption?.type === "MedsWithInfo") {
    return (
      <>
        <div className="border p-6 my-5 border-grey-2 mt-8">
          <div className="text-lg font-semibold">{fieldOption?.label}</div>
          {fieldOption?.sublabel && <div className="text-lg">{fieldOption.sublabel}</div>}
          {fieldOption?.description && (
            <div className="text-base mt-5">{fieldOption.description}</div>
          )}
          {open ? (
            <div className="mt-5 mb-10">
              {fieldOption.info?.map((point: string, index: number) => {
                const key = index + "-" + point.replace(" ", "");
                return (
                  <SandBullet
                    classes={"text-sm"}
                    point={point}
                    key={key.substr(0, Math.min(10, key.length))}
                  />
                );
              })}
            </div>
          ) : (
            <div className="flex mb-8 text-base my-5 cursor-pointer" onClick={() => setOpen(!open)}>
              Learn More
              <img className="ml-3" src="/assets/down-arrow.svg" alt="down arrow" />
            </div>
          )}
          <label
            className={classnames("px-8 py-4 t-2 cursor-pointer", {
              "bg-sand-100": input.checked,
              "bg-sand-40": !input.checked,
            })}
          >
            Select This Medication
            <input className="appearance-none inline" {...input} />
          </label>
        </div>
      </>
    );
  } else {
    return defaultOption;
  }
};

export default MedsWithInfo;
