/** @format */

import type { FieldRenderProps } from "react-final-form";

import React from "react";
import { Form } from "react-final-form";

import type { UseRecurlyInstance } from "@recurly/react-recurly";
import {
  CardCvvElement,
  CardMonthElement,
  CardNumberElement,
  CardYearElement,
  Elements,
  RecurlyProvider,
  useRecurly,
} from "@recurly/react-recurly";
import { FORM_ERROR } from "final-form";
import _ from "lodash";

import type { BaseModalProps, TextFieldProps } from "@alphamedical/components";

import { Condition, FieldError, Popup, TextField, ToggleField } from "@alphamedical/components";

import type { BillingInfoSchema } from "src/api";
import type { FetchProfileResponse } from "src/v2/models/api_types";

import { UsersService } from "src/api";
import { axiosErrorHandler } from "src/components/DynamicForm/utils";
import { isAxiosError } from "src/helpers/axios";
import { getRecurlyPublicKey } from "src/helpers/http";
import { Button } from "src/v2/components/Button";
import { useStoreDispatch } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";

import "./billingStyles.scss";

interface Address {
  first_name: string;
  last_name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postal_code: string;
  country: "US";
}

export const FIELD_STYLES = {
  all: {
    fontFamily: "ARS Maquette Pro, Montserrat, sans-serif",
    fontColor: "#1c4a46",
    padding: "0px",
  },
  month: { placeholder: { content: "MM", color: "#779290", opacity: "0.8" } },
  year: { placeholder: { content: "YYYY", color: "#779290", opacity: "0.8" } },
  number: { placeholder: { content: "Credit card number", color: "#779290", opacity: "0.8" } },
  cvv: { placeholder: { content: "CVV", color: "#779290", opacity: "0.8" } },
};

const RecurlyField = (props: TextFieldProps) => (
  <TextField {...props}>
    {({ input }: FieldRenderProps<string>) => (
      <div className="recurly-eleme">
        <input
          {...input}
          className="recurly-element"
          placeholder={props.placeholder}
          data-recurly={input.name}
        />
        <FieldError name={input.name} cxError="border-t border-red" />
      </div>
    )}
  </TextField>
);

const getBillingInfo = ({ pii }: FetchProfileResponse): Partial<Address> => ({
  first_name: pii.first_name,
  last_name: pii.last_name,
  address1: pii.address.address,
  address2: pii.address.address2,
  city: pii.address.city,
  state: pii.address.state,
  postal_code: pii.address.zipcode,
  country: "US",
});

const customErrorMessages: { [key: string]: string } = {
  number: "Enter a valid credit card number",
  month: "Enter MM",
  year: "Enter YYYY",
  cvv: "Enter CVV",
  first_name: "Enter first name",
  last_name: "Enter last name",
  address1: "Enter address line 1",
  city: "Enter city",
  state: "Enter state",
  postal_code: "Enter ZIP code",
};

const getRecurlyToken = (recurly: UseRecurlyInstance, values: Address): Promise<{ id: string }> => {
  return new Promise((resolve, reject) => {
    recurly.token(values, (err, token) => {
      if (err) {
        // Try and get the per-field error messages, otherwise fallback to the general error message
        const formErrors = (err as any).details
          ? _.chain((err as any).details)
              .keyBy("field")
              .mapValues((detail: any) => {
                const field = detail.field;
                return customErrorMessages[field] || detail.message;
              })
              .value()
          : { [FORM_ERROR]: err.message };

        reject(formErrors);
      } else {
        resolve(token);
      }
    });
  });
};

interface BillingInfoFormProps {
  onBillingInfoUpdated: (billingInfo: BillingInfoSchema) => void;
  onBillingInfoFailure?: (errorMessage?: string) => void;
}

const BillingInfoForm = (props: BillingInfoFormProps) => {
  const dispatch = useStoreDispatch();
  const patient = useCurrentUser();
  const recurly = useRecurly();

  const currentBillingAddress = getBillingInfo(patient);
  const [validating, setIsValidating] = React.useState(false);

  const [cardErrors, setCardErrors] = React.useState({
    number: "",
    month: "",
    year: "",
    cvv: "",
  });

  const clearCardErrors = (field = "") => {
    if (field) setCardErrors({ ...cardErrors, [field]: undefined });
    else
      setCardErrors({
        number: "",
        month: "",
        year: "",
        cvv: "",
      });
  };

  // Check if the user existing shipping information is enough to prepopuplate the billing address
  const missingBillingInfo = !Object.entries(currentBillingAddress).every(([key, value]) => {
    return !!value || key === "address2";
  });

  return (
    <Form<Address>
      initialValues={currentBillingAddress}
      onSubmit={(values) => {
        return getRecurlyToken(recurly, values)
          .then((token) => {
            clearCardErrors();
            setIsValidating(true);
            UsersService.saveUserBillingInfo1({ requestBody: { token: token.id } })
              .then((res) => {
                dispatch.billingInfo.setBillingInfo(res);
                props.onBillingInfoUpdated(res);
              })
              .catch((err) => {
                props.onBillingInfoFailure &&
                  props.onBillingInfoFailure(err?.response?.data?.description);
              })
              .finally(() => {
                setIsValidating(false);
              });
          })
          .catch((err) => {
            setCardErrors(err);
            // Final form expects errors to be returned by onSubmit, so we catch and return the error object instead of
            // allowing it to be thown.
            return isAxiosError(err)
              ? axiosErrorHandler("Unable to update billing information")(err)
              : err;
          });
      }}
    >
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <div className="p-5" style={{ fontFamily: "ARS Maquette Pro, Montserrat, sans-serif" }}>
            <CardNumberElement style={{ ...FIELD_STYLES.all, ...FIELD_STYLES.number }} />
            {cardErrors.number && (
              <div className="text-red border-t border-red">{cardErrors.number}</div>
            )}
            <div className="flex space-x-6 mb-4">
              <div>
                <CardMonthElement style={{ ...FIELD_STYLES.all, ...FIELD_STYLES.month }} />
                {cardErrors.month && (
                  <div className="text-red border-t border-red">{cardErrors.month}</div>
                )}
              </div>
              <div>
                <CardYearElement style={{ ...FIELD_STYLES.all, ...FIELD_STYLES.year }} />
                {cardErrors.month && (
                  <div className="text-red border-t border-red">{cardErrors.year}</div>
                )}
              </div>
              <div>
                <CardCvvElement style={{ ...FIELD_STYLES.all, ...FIELD_STYLES.cvv }} />
                {cardErrors.cvv && (
                  <div className="text-red border-t border-red">{cardErrors.cvv}</div>
                )}
              </div>
            </div>
            {!missingBillingInfo && (
              <ToggleField
                name="use_shipping_address"
                defaultValue={!missingBillingInfo}
                label="Billing address same as shipping"
              />
            )}
            <Condition when="use_shipping_address" isNot={true}>
              <RecurlyField name="first_name" placeholder="First name" />
              <RecurlyField name="last_name" placeholder="Last name" />
              <RecurlyField name="address1" placeholder="Address line 1" />
              <RecurlyField name="address2" placeholder="Address line 2" />
              <RecurlyField name="city" placeholder="City" />
              <div className="flex space-x-6">
                <RecurlyField name="state" placeholder="State" />
                <RecurlyField name="postal_code" placeholder="ZIP Code" />
              </div>
            </Condition>
          </div>
          <div className="bg-sand-20 p-5">
            <Button bgColor="violator" disabled={submitting || validating}>
              {submitting ? "verifying..." : "Add payment method"}
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};

type BillingInfoPopupProps = BaseModalProps & {
  onBillingInfoUpdated: (billingInfo: BillingInfoSchema) => void;
  onBillingInfoFailure?: () => void;
};

export const BillingInfoPopup = ({
  onBillingInfoUpdated,
  onBillingInfoFailure,
  ...props
}: BillingInfoPopupProps) => {
  return (
    <div>
      <Popup size="small" accentColor="sand-20" headerText="Add Payment Method" {...props}>
        <RecurlyProvider publicKey={getRecurlyPublicKey()}>
          <Elements>
            <BillingInfoForm
              onBillingInfoUpdated={onBillingInfoUpdated}
              onBillingInfoFailure={onBillingInfoFailure}
            />
          </Elements>
        </RecurlyProvider>
      </Popup>
    </div>
  );
};
