/** @format */

import React from "react";

import type { InvalidType } from "src/v2/utils/page/validators";

interface EligibilityNoBloodPressureProps {
  invalid: InvalidType;
  values: any;
  closeModal: any;
  saveValues: any;
  moveForward: any;
  submitted: boolean;
}
interface EligibilityNoBloodPressureState {
  disableButtons: boolean;
  error: any;
}

class EligibilityNoBloodPressure extends React.Component<
  EligibilityNoBloodPressureProps,
  EligibilityNoBloodPressureState
> {
  constructor(props: EligibilityNoBloodPressureProps) {
    super(props);
    this.state = {
      disableButtons: false,
      error: undefined,
    };
  }

  submitProgestinAndSave = async () => {
    this.setState({
      disableButtons: true,
      error: undefined,
    });
    try {
      await this.props.saveValues(this.props.values);
      this.props.moveForward();
      this.setState(
        {
          disableButtons: false,
          error: undefined,
        },
        this.props.closeModal,
      );
    } catch (e) {
      const message = "There was an error saving progestin-only pill. Please try again.";
      // tslint:disable-next-line
      console.warn(message);
      this.setState({
        disableButtons: false,
        error: {
          code: "save-error",
          message,
        },
      });
    }
  };

  render() {
    return (
      <div className="m-5">
        <div className="text-3xl">Great!</div>
        <div className="text-lg my-3">
          We'll move forward with the progestin-only medication. You can review your order before
          submitting your payment info.
        </div>
        <button
          className="btn btn-primary w-full my-2"
          onClick={this.submitProgestinAndSave}
          disabled={this.state.disableButtons}
        >
          I'll take the progestin-only medication.
        </button>
        <div
          onClick={this.props.closeModal}
          className={`
            block
            text-sm
            text-primary-60 hover:text-primary
            cursor-pointer hover:underline text-center
          `}
        >
          Nevermind, I want to provide my blood pressure
        </div>
        {this.state.error && (
          <div className="text-red text-center mt-5">{this.state.error.message}</div>
        )}
      </div>
    );
  }
}

export default EligibilityNoBloodPressure;
