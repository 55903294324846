/** @format */

import type { FC } from "react";

import cn from "classnames";

import SandBullet from "src/v2/components/SandBullet";

import type { MedicalRecordsRequestFormPageProps } from "./MedicalRecordsRequestForm";

const Intro: FC<MedicalRecordsRequestFormPageProps> = ({ show }) => (
  <div className={cn({ block: show, hidden: !show }, "bg-sand-20 px-5 py-6 mb-10")}>
    <div className="font-medium text-md mb-8">
      You have a right to your healthcare data. Use this form to request a copy of your Alpha
      medical records.
    </div>
    <p className="mb-8">
      We cannot provide medical records for a provider outside Alpha Telemedicine.
    </p>
    <img src="/assets/medical-record.svg" alt="record icon" className="mb-8" />
    <div className="font-medium mb-5">To complete this form, you will need to:</div>
    <SandBullet
      point={"Authorize Alpha to disclose your medical information"}
      style={{ color: "#005246" }}
    />
    <SandBullet
      point={"Provide the name and address of people or organizations receiving your information"}
      style={{ color: "#005246" }}
    />
    <SandBullet
      point={"Specify what information to include in the records"}
      style={{ color: "#005246" }}
    />
    <SandBullet point={"Provide your photo ID"} style={{ color: "#005246" }} />
    <SandBullet point={"Sign this authorization"} style={{ color: "#005246" }} />
  </div>
);

export default Intro;
