/** @format */

import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import moment from "moment/moment";

import { Typography } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import type { FlowModalProps } from "src/content/v2/routes/Membership/types";

import { UsersService } from "src/api/services/UsersService";
import { formatText } from "src/content/contentUtils";
import { PLAN_NAMES } from "src/content/v2/routes/Membership/constants";
import { CONTENT } from "src/content/v2/routes/Membership/utils";
import { useMembershipPrice } from "src/utils/hooks/useMembershipPrice";
import { BillingInfoPopup } from "src/v2/components/Forms/BillingInfo/BillingInfoPopup";
import { useStoreActions, useStoreDispatch, useStoreState } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";

const PLAN_SLUGS = {
  "alpha-membership": "alpha",
  "alpha-membership-plus": "plus",
};

export const UpgradePlanFlow = ({
  setModalOpen,
  hasRequestedCancellation = false,
  hasRequestedDowngrade = false,
}: FlowModalProps) => {
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [showBillingInfoPopup, setShowBillingInfoPopup] = React.useState(false);
  const [hasBillingInfo, setHasBillingInfo] = React.useState(false);
  const [isUpgrading, setIsUpgrading] = React.useState(false);
  const membership = useStoreState((state) => state.membership.membership);
  const planCode = membership?.scheduled_plan_code || membership?.plan_code;
  const dispatch = useStoreDispatch();
  const history = useHistory();
  const currentUser = useCurrentUser();
  const username = currentUser.pii.first_name ? `${currentUser.pii.first_name}` : "";
  const [showLastStep, setShowLastStep] = useState(false);
  const membershipPriceInfo = useMembershipPrice();
  const { addSnack } = useStoreActions((actions) => actions.snacks);

  const CHANGE_CONTENT = CONTENT["change"];
  const planOptions = CHANGE_CONTENT.selectPlanPage.planOptions;

  React.useEffect(() => {
    setShowLastStep(hasRequestedCancellation || hasRequestedDowngrade);
  }, [hasRequestedCancellation, hasRequestedDowngrade]);

  React.useEffect(() => {
    // if the user has billing info, we can upgrade them. If not, this request will fail
    UsersService.userBillingInfo().then(() => {
      setHasBillingInfo(true);
    });
  }, []);

  const confirmUpgrade = async (newPlan: "alpha-membership" | "alpha-membership-plus") => {
    if (hasBillingInfo) {
      setIsUpgrading(true);
      const recurlyId = membership?.recurly_subscription_id;
      recurlyId &&
        dispatch.membership
          .upgradeMembership({ newPlan, recurlyId })
          .then(() => {
            setIsUpgraded(true);
            addSnack({
              type: "success",
              message: "Plan changed successfully!",
              id: "changed-membership",
              delay: 5,
            });
          })
          .catch((e: any) => {
            addSnack({
              type: "error",
              message: "Failed to change plan",
              id: "change-membership-failure",
              delay: 5,
            });
          })
          .finally(() => setIsUpgrading(false));
    } else {
      // if the user does not have billing info, we need to collect it
      setShowBillingInfoPopup(true);
    }
  };

  const renderAttemptWhileCancelling = () => (
    <>
      <section className={"pt-4 mb-16"}>
        <h4 className="text-xl font-medium text-forest-120 mb-8">
          {CHANGE_CONTENT.attemptsToChangeWhileCancelling.title}
        </h4>
        <p className={"text-forest-120 text-base leading-6"}>
          {CHANGE_CONTENT.attemptsToChangeWhileCancelling.body[0]}
        </p>
        <br />
      </section>
      <section className="mb-12">
        <ButtonPdb className="w-full" onClick={() => history.push("/messages")} variant="secondary">
          {CHANGE_CONTENT.attemptsToChangeWhileCancelling.cta}
        </ButtonPdb>
      </section>
    </>
  );

  const renderPlanUpgraded = () => {
    const currentPlanCode = membership?.scheduled_plan_code || planCode;
    return (
      <section className={"pt-4"}>
        <h4 className="text-xl font-medium text-forest-120 mb-8">
          {CHANGE_CONTENT.changeSuccessfulPage.title}
        </h4>
        <p className={"text-forest-120 text-base leading-6"}>
          {currentPlanCode &&
            formatText(CHANGE_CONTENT.changeSuccessfulPage.planDescription[currentPlanCode], {
              patientName: username,
              renewalDate: moment(membership?.renews_at).format("MM/DD/YYYY [at] h:mm a"),
            })}
        </p>
        <ButtonPdb className="w-full mt-12" onClick={() => setModalOpen(false)}>
          Close
        </ButtonPdb>
      </section>
    );
  };

  const renderFlow = () => {
    const suggestedPlanCode =
      planCode === "alpha-membership" ? "alpha-membership-plus" : "alpha-membership";

    if (isUpgraded) {
      return renderPlanUpgraded();
    } else if (showLastStep) {
      return renderAttemptWhileCancelling();
    } else {
      return (
        <>
          <div>
            <Typography variant="h2" className="mb-4">
              {formatText(CHANGE_CONTENT.selectPlanPage.title, {
                planName: PLAN_NAMES[suggestedPlanCode],
              })}
            </Typography>
            <div>
              <Typography>
                {formatText(
                  "For {{price}}/month, you’ll get access to all {{planName}} benefits including:",
                  {
                    price:
                      membershipPriceInfo[PLAN_SLUGS[suggestedPlanCode]].discountAmountString ||
                      membershipPriceInfo[PLAN_SLUGS[suggestedPlanCode]].originalPriceString,
                    planName: planOptions[suggestedPlanCode].value,
                  },
                )}
              </Typography>
              <div className="ml-4">
                <ul className="ml-4  list-disc">
                  {planOptions[suggestedPlanCode].bulletPoints.map((point) => (
                    <li key={point}>
                      <Typography>{point}</Typography>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-4">
                <Typography variant="caption">{planOptions[suggestedPlanCode].extra}</Typography>
              </div>
            </div>
            <div className="mt-12">
              <ButtonPdb
                className="w-full mb-4"
                isLoading={isUpgrading}
                icon={isUpgrading && "fa fa-spinner fa-spin"}
                onClick={() => confirmUpgrade(suggestedPlanCode)}
              >
                Change plan
              </ButtonPdb>
              <ButtonPdb
                className="w-full mr-6"
                onClick={() => setModalOpen(false)}
                variant="secondary"
              >
                {CHANGE_CONTENT.selectPlanPage.notNowButton}
              </ButtonPdb>
            </div>
          </div>
          <BillingInfoPopup
            isOpen={showBillingInfoPopup}
            onBillingInfoUpdated={() => {
              setHasBillingInfo(true);
              setShowBillingInfoPopup(false);
              addSnack({
                type: "success",
                message: "Billing info successfully added",
                id: "billing-info",
                delay: 5,
              });
            }}
            onBillingInfoFailure={(error_message = "") => {
              addSnack({
                type: "error",
                message:
                  error_message ||
                  "Something went wrong. Please try again or add a new payment method. ",
                id: "billing-info-failure",
                delay: 5,
              });
            }}
            onRequestClose={() => setShowBillingInfoPopup(false)}
          />
        </>
      );
    }
  };

  return <div className={"p-6 flex flex-col mx-auto mb-10 max-w-2xl"}>{renderFlow()}</div>;
};
