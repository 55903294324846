/** @format */

import type { Action, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";

import type { TestResult } from "src/v2/types/medications";

import { _GET, getTokenHeaderV2 } from "src/helpers/http";

import type { CreateModel } from "./_create";

export interface LabOrder {
  type: string;
  sku_key: string;
  athena_document_id: number;
  clinician_id: number;
  patient_id: number;
  athena_orderset_id: number;
  id: number;
  consult_id: number;
  athena_encounter_id: number;
  athena_facility_id: number;
  name: string;
  athena_ordertype_id: number;
  created_at: string;
  facility_name: string;
  snomed_code: string;
  icd10_code: string;
  status: string;
  updated_at: string;
  stat: boolean;
  lab_results: {
    athena_raw_data: {
      [key: string]: any;
      observations: { [key: string]: any }[];
    };
  }[];
}

export interface MTLOrder {
  id: number;
  order_number: string;
  test_results: TestResult;
}

export interface LabModel {
  labOrders: LabOrder[];
  mtlOrders: MTLOrder[];
  fetchingLabs: boolean;

  setFetchingLabs: Action<LabModel, boolean>;

  setLabs: Action<LabModel, LabOrder[]>;

  setMTLOrders: Action<LabModel, MTLOrder[]>;

  fetchLabs: Thunk<LabModel>;

  fetchMTLOrders: Thunk<LabModel>;
}

export const labStore: LabModel = {
  labOrders: [],
  mtlOrders: [],
  fetchingLabs: false,

  setFetchingLabs: action((state, fetching) => {
    state.fetchingLabs = fetching;
  }),

  setLabs: action((state, labOrders) => {
    state.labOrders = labOrders;
    state.fetchingLabs = false;
  }),

  setMTLOrders: action((state, mtlOrders) => {
    state.mtlOrders = mtlOrders;
    state.fetchingLabs = false;
  }),

  fetchLabs: thunk(async (actions, __, { getStoreState, getState }) => {
    const state = getState();
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;
    if (!state.fetchingLabs) {
      actions.setFetchingLabs(true);
      const _orders = await _GET(
        `/labs/orders/patient/${currentProfileId}`,
        {},
        getTokenHeaderV2(),
      );
      actions.setLabs(_orders.lab_orders);
    }
  }),

  fetchMTLOrders: thunk(async (actions, __, { getStoreState, getState }) => {
    const storeState = getStoreState() as CreateModel;
    const { currentProfileId } = storeState.profile;
    const mtlOrders = await _GET(`/users/${currentProfileId}/test_kits`, {}, getTokenHeaderV2());
    actions.setMTLOrders(mtlOrders);
  }),
};
