/** @format */

import React from "react";
import { Link } from "react-router-dom";

import type { ExternalSubscription, Subscription } from "src/v2/types/subscription";

import { CardContent } from "src/components/CardContent";
import { createDetailsList, expiresIn30Days, getFollowupUrl } from "src/helpers/visitDetails";
import ModifyTreatmentModal from "src/v2/components/_modals/ModifyTreatmentModal";
import { VALID_FOLLOWUP_CONSULTS } from "src/v2/constants";
import { useStoreDispatch } from "src/v2/models";

interface PrescriptionCardProps {
  subscription?: Subscription;
  condition: string;
  detailListType: string;
  externalSubscription?: ExternalSubscription;
}

const isSubscriptionModifiable = (subscription: Subscription) => {
  return (
    subscription.next_refill != null &&
    ["PROCESSING", "UNKNOWN", "INACTIVE"].indexOf(subscription.status) < 0 &&
    ["eyelashes", "skincare"].indexOf(subscription.condition.key) >= 0
  );
};

export const PrescriptionCard = ({
  subscription,
  condition,
  detailListType,
  externalSubscription,
}: PrescriptionCardProps) => {
  const dispatch = useStoreDispatch();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isSkippingShipment, setIsSkippingShipment] = React.useState(
    ["birthcontrol", "skincare"].indexOf(condition) >= 0,
  );

  const openModal = () => {
    if (subscription && isSubscriptionModifiable(subscription)) {
      setIsOpen(true);
      setIsSkippingShipment(["skincare"].indexOf(subscription.condition.key) >= 0);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    dispatch.medications.fetchMedicationsByCondition({
      params: { conditionKey: condition, filterInvalidStatuses: true, descOrder: true },
    });
  };
  const modifiableTreatment = subscription && isSubscriptionModifiable(subscription);

  return (
    <div>
      <div
        key={
          (subscription && subscription.id) || (externalSubscription && externalSubscription.name)
        }
        className="bg-white mb-4 w-full flex flex-col p-6 relative"
      >
        <img
          src={condition === "sti-testing-kit" ? "/assets/tube.svg" : "/assets/syringe-pdb.svg"}
          alt=""
          role="presentation"
          className="absolute"
          style={{ top: "1.5rem", right: "1.5rem" }}
        />
        <CardContent
          detailList={createDetailsList({
            type: detailListType,
            subscription,
            externalSubscription,
          })}
        />
        <div className="flex flex-col">
          {VALID_FOLLOWUP_CONSULTS.indexOf(condition) !== -1 &&
            subscription &&
            subscription.expires_on &&
            expiresIn30Days(subscription.expires_on) && (
              <Link
                to={getFollowupUrl(condition)}
                className="no-underline font-bold text-violator mt-4"
              >
                <div className="inline-block">
                  Renew Your Prescription <span className="fas fa-arrow-right text-sm" />
                </div>
              </Link>
            )}
          {modifiableTreatment && (
            <div
              onClick={openModal}
              className="inline-block font-bold mt-2 cursor-pointer"
              style={{ color: "#6BA5A5" }}
            >
              {isSkippingShipment ? "Skip Next Shipment" : "Modify Prescription"}{" "}
              <span className="fas fa-arrow-right text-sm" />
            </div>
          )}
        </div>
      </div>
      {isOpen && subscription && (
        <ModifyTreatmentModal
          showModal={true}
          closeModal={closeModal}
          subscription={subscription}
          isSkippingShipment={isSkippingShipment}
        />
      )}
    </div>
  );
};
