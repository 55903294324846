/** @format */

import React from "react";
import { useHistory } from "react-router-dom";

import { PageWrapper } from "src/components/PageWrapper";
import { UpgradePlanFlow } from "src/content/v2/routes/Membership/UpgradePlanFlow";
import { CONTENT } from "src/content/v2/routes/Membership/utils";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useStoreState } from "src/v2/models";

const MembershipChange = () => {
  const history = useHistory();
  useTopBar({
    title: CONTENT["change"].title,
    variant: "nested",
    showBackButton: true,
  });
  const membershipAlerts = useStoreState((state) => state.alerts);
  const hasRequestedCancellation = membershipAlerts.requestedCancellation;
  const hasRequestedDowngrade = membershipAlerts.requestedDowngrade;

  return (
    <PageWrapper>
      <UpgradePlanFlow
        setModalOpen={() => history.goBack()}
        hasRequestedCancellation={hasRequestedCancellation}
        hasRequestedDowngrade={hasRequestedDowngrade}
      />
    </PageWrapper>
  );
};

export default WithTopBarNavigation(MembershipChange);
