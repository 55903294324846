/** @format */

import React from "react";
import { useForm, useFormState } from "react-final-form";

import * as Sentry from "@sentry/react";
import { pick } from "lodash";

import SearchAddressForm from "src/v2/components/SearchAddressForm/SearchAddressForm";
import { useStoreActions, useStoreState } from "src/v2/models";

import defaultContent from "./defaultFields.json";

const defaultValues: any = {
  "lab-name": "",
  "lab-autocomplete": "",
  "lab-address1": "",
  "lab-address2": "",
  "lab-city": "",
  "lab-state": "",
  "lab-zipcode": "",
  "lab-phone": "",
};

export const LabAddressPage = (props: any) => {
  const { formFields, button, questionKey } = props;
  const { fetchAddresses, postAddress, updateAddress } = useStoreActions(
    (action) => action.addresses,
  );
  const { addresses } = useStoreState((state) => state.addresses);
  const form = useForm();
  const formState = useFormState();
  const labAddressId = formState.values[questionKey];
  const [initialValues, setInitialValues] = React.useState(defaultValues);

  React.useEffect(() => {
    const fetchAsyncAddress = async () => {
      if (labAddressId) {
        await fetchAddresses({ id: labAddressId });
      }
    };
    fetchAsyncAddress();
  }, [labAddressId]);

  React.useEffect(() => {
    if (addresses && addresses.length > 0) {
      const updatedInitialValues = mapValues(addresses[0], true);
      setInitialValues(updatedInitialValues);
    }
  }, [addresses]);

  const saveAddress = async (address: any) => {
    const requestBody: any = {
      ...pick(address, "address1", "address2", "city", "name", "state", "zipcode", "phone"),
    };

    try {
      if (labAddressId) {
        requestBody.id = labAddressId;
        await updateAddress({ address: requestBody, validate: false });
      } else {
        requestBody.type = "LAB";
        const response = await postAddress({ address: requestBody, validate: false });
        form.change(questionKey, response.id);
      }
      form.submit();
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const onSubmit = async (values: any) => {
    saveAddress(mapValues(values));
  };

  const mapValues = (values: any, reverse = false) => {
    return Object.keys(values).reduce((acc, key) => {
      const newKey = reverse ? `lab-${key}` : key.replace("lab-", "");
      acc[newKey] = values[key];
      return acc;
    }, {});
  };

  return (
    <>
      <SearchAddressForm
        fields={formFields || defaultContent.formFields}
        button={button || defaultContent.button}
        onSubmit={onSubmit}
        initialValues={initialValues}
      />
    </>
  );
};
