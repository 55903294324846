/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Auth_LoginCustomLoginRequestBodySchema } from '../models/Auth_LoginCustomLoginRequestBodySchema';
import type { Auth_ResetForgotPasswordRequestBodySchema } from '../models/Auth_ResetForgotPasswordRequestBodySchema';
import type { Auth_ResetResetRequestBodySchema } from '../models/Auth_ResetResetRequestBodySchema';
import type { ResetTokenSchema } from '../models/ResetTokenSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PermissionService {

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static customLogin({
        requestBody,
    }: {
        requestBody: Auth_LoginCustomLoginRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Changes logout to be treated as an API call and returns an 'OK' instead
     * of redirecting to an url
     * @returns any No response format defined
     * @throws ApiError
     */
    public static customLogout(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/logout',
        });
    }

    /**
     * Creates a route that can be used to validate authentication
     * @returns any No response format defined
     * @throws ApiError
     */
    public static needAuthRoute(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/need-auth',
        });
    }

    /**
     * View function that handles a forgotten password request.
     * @returns any No response format defined
     * @throws ApiError
     */
    public static forgotPassword({
        requestBody,
    }: {
        requestBody: Auth_ResetForgotPasswordRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/reset',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Implements a /reset/<token> route, with the same logic as the one from
     * flask_security.views, but which would return proper JSON response
     *
     * Note: When called from non-json requests it will
     * execute the original logic from the library instead
     *
     * see: https://github.com/mattupstate/flask-security/blob/1.7.5/flask_security/views.py#L266
     * @returns ResetTokenSchema
     * @throws ApiError
     */
    public static reset({
        token,
        requestBody,
    }: {
        token: string,
        requestBody: Auth_ResetResetRequestBodySchema,
    }): CancelablePromise<ResetTokenSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/reset/{token}',
            path: {
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
