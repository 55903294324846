/** @format */

import React from "react";

import * as Sentry from "@sentry/react";
import { isNumber } from "lodash";

import type { VisitInfoSchema } from "src/api";

import { ServicesService } from "src/api";
import { isAxiosError } from "src/helpers/axios";
import { useHasEmployerBenefits } from "src/utils/hooks/useHasEmployerBenefits";
import { useCurrentUser } from "src/v2/models/profile";

interface PaymentVisitInfoProps {
  showNumberOfVisits: boolean;
}

export const PaymentVisitInfo = ({ showNumberOfVisits }: PaymentVisitInfoProps) => {
  const user = useCurrentUser();
  const [paymentVisitInfo, setPaymentVisitInfo] = React.useState<VisitInfoSchema | null>(null);

  const allVisitsUsed = paymentVisitInfo?.all_completed_visits;
  const completedVisits = paymentVisitInfo?.completed_visits;
  const freeVisitLimit = paymentVisitInfo?.free_visit_limit;
  const visitsRemaining =
    freeVisitLimit && isNumber(completedVisits) ? freeVisitLimit - completedVisits : 0;

  // Sonja Cares functionality
  const hasSonjaCaresBenefits = useHasEmployerBenefits("sonja-cares");
  const SONJA_CARES_TOTAL_VISITS_ALLOWED = 12;
  const sonjaCaresTotalNumberOfVisitsText =
    isNumber(allVisitsUsed) &&
    `${SONJA_CARES_TOTAL_VISITS_ALLOWED - allVisitsUsed} visits (of ${SONJA_CARES_TOTAL_VISITS_ALLOWED}) available with your plan benefits. `;

  React.useEffect(() => {
    ServicesService.getVisitLimitInfo({ userId: user.id })
      .then((res) => setPaymentVisitInfo(res))
      .catch((err) => {
        if (isAxiosError(err)) {
          Sentry.captureException(err);
        }
      });
  }, []);

  return (
    <>
      {showNumberOfVisits && (
        <div className={"mt-6"}>
          <div className={"flex flex-row justify-between"}>
            <p className={"text-forest-darker font-medium"}>Your visits</p>
          </div>
          <p className={"mt-2 text-sm text-forest-darker"}>
            {hasSonjaCaresBenefits && sonjaCaresTotalNumberOfVisitsText}
            {visitsRemaining < 0 ? 0 : visitsRemaining} visit{visitsRemaining === 1 ? " " : "s "}
            {freeVisitLimit ? `(of ${freeVisitLimit}) ` : ""}
            available this month.
          </p>
        </div>
      )}
    </>
  );
};
