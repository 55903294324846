/** @format */

import React from "react";
import { Form } from "react-final-form";
import ReactModal from "react-modal";

import classNames from "classnames";

import { ButtonPdb, Paper } from "src/v2/designSystem";

import type { AddressInvalid } from "src/v2/utils/page/_validators/address";

import { PageWrapper } from "src/components/PageWrapper";
import { serializeDependency } from "src/utils";
import { useTopBar } from "src/utils/hooks/useTopBar";
import Pii from "src/v2/components/Pii";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useStoreActions, useStoreDispatch, useStoreState } from "src/v2/models";
import { EligibilityFormAddress } from "src/v2/routes/Consultation/EligibilityFormAddress";
import { _date } from "src/v2/utils/auth/_regex";
import { isAddressInvalid } from "src/v2/utils/page/_validators/address";
import { isInvalidType } from "src/v2/utils/page/validators";

import { fields } from "./fields";

interface AddressInformationProps {
  values: any;
  fields: any[];
  invalid?: AddressInvalid;
  invalidValues?: any;
  condition: any;
  onClose: any;
}

interface AddressValues {
  first_name?: string;
  last_name?: string;
  address?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  phone_number?: string;
  sms_allowed?: boolean;
}
const AddressInformation = (props: AddressInformationProps) => {
  useTopBar({ title: "Contact information", variant: "nested", showBackButton: true });
  const dispatch = useStoreDispatch();
  const { profile } = useStoreState((state) => state.profile);
  const [_fields] = React.useState(fields);
  const [invalid, setInvalid] = React.useState(props.invalid);
  const [invalidValues, setInvalidValues] = React.useState(props.invalidValues);
  const [condition] = React.useState(props.condition);
  const [values, setValues] = React.useState({});
  const addSnack = useStoreActions((actions) => actions.snacks.addSnack);
  const [formKey, setFormKey] = React.useState(Date.now());

  React.useEffect(() => {
    if (profile && profile.pii) {
      const _pii = profile && profile.pii;
      const _values = {} as AddressValues;

      if (profile.pii.address) {
        _values.address = _pii.address.address;
        _values.address2 = _pii.address.address2;
        _values.city = _pii.address.city;
        _values.state = _pii.address.state;
        _values.zipcode = _pii.address.zipcode;
        _values.phone_number = _pii.phone_number;
        _values.sms_allowed = _pii.sms_allowed || false;
      }
      setValues(_values);
    }
  }, [serializeDependency(profile?.pii)]);

  const fieldCanBeShown = (values: any, field: any = {}) => {
    let show = true;

    if (field.conditionals) {
      show = field.conditionals.every((conditional: any) => {
        const _values = values[conditional.key];
        if (_values instanceof Array) {
          return _values.indexOf(conditional.value) >= 0;
        }
        return values[conditional.key] === conditional.value;
      });
    }
    return show;
  };

  const validate = (values: any) => {
    const errors: any = {};
    _fields.map((field: any) => {
      if (fieldCanBeShown(values, field) && field.required && !values[field.key]) {
        errors[field.key] = "Required";
      }
      if (field.type === "text" && field.subtype === "date") {
        if (values[field.key]) {
          const regex = new RegExp(_date, "ig");
          if (!regex.test(values[field.key])) {
            errors[field.key] = "Invalid date. Must be MM-DD-YYYY";
          }
        }
      }
      return undefined;
    });

    return errors;
  };

  const saveValues = async (values: any) => {
    try {
      const response = await dispatch.profile.updatePii(values);
      addSnack({
        type: "success",
        message: "Success! The address you entered has been saved",
        id: "success-saving-address",
        delay: 5,
      });
      setFormKey(Date.now());
      return response;
    } catch (error) {
      addSnack({
        type: "error",
        message: "An error occurred while saving the address. Please try again.",
        id: "error-saving-address",
        delay: 5,
      });
      throw error;
    }
  };

  const onSubmit = async (values: any) => {
    // ensure autocomplete is removed from values before submitting them
    if ("autocomplete" in values) delete values.autocomplete;

    // forces blank address2 to be sent in update
    if (!values.address2) {
      values.address2 = "";
    }

    // need to force it to be a string
    values.zipcode = values.zipcode.toString();
    try {
      await isAddressInvalid(values);
      await saveValues(values);
    } catch (e) {
      if (isInvalidType(e) && e.invalid) {
        setInvalid(e as AddressInvalid);
        setInvalidValues(values);
      } else {
        addSnack({
          type: "error",
          message:
            "The address as submitted could not be found. Please check for excessive abbreviations in the street address line or in the city name",
          id: "error-validating-address",
          delay: 10,
        });
      }
    }
    return;
  };

  const onCloseInvalid = () => {
    setInvalid(undefined);
  };

  return (
    <PageWrapper cxPadding="px-0" fitToPageHeight>
      <Paper
        elevation={0}
        rounded={false}
        className="flex-1 mx-auto w-full max-w-2xl px-6 h-screen"
      >
        <Form validate={validate} onSubmit={onSubmit} initialValues={values} key={formKey}>
          {(formProps) => {
            const { handleSubmit, submitting } = formProps;
            return (
              <form onSubmit={handleSubmit} className="flex flex-col flex-1 mt-3">
                {invalid && (
                  <ReactModal
                    style={{
                      content: {
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        outlineStyle: "none",
                        borderRadius: "4px",
                      },
                    }}
                    isOpen={!!invalid}
                    overlayClassName={"alpha-modal-overlay-new"}
                    className={classNames(
                      "flex flex-1 bg-white max-w-sm xs:max-w-md sm:max-w-lg md:max-w-xl justify-center overflow-y-auto no-scrollbar",
                    )}
                    closeTimeoutMS={500}
                  >
                    <EligibilityFormAddress
                      condition={condition}
                      invalid={invalid as AddressInvalid}
                      values={invalidValues}
                      closeModal={onCloseInvalid}
                      saveValues={saveValues}
                      moveForward={() => undefined}
                    />
                  </ReactModal>
                )}
                <Pii formProps={formProps} fields={fields} />
                <ButtonPdb type={"submit"} disabled={submitting} className="mt-8 w-full">
                  Save
                </ButtonPdb>
              </form>
            );
          }}
        </Form>
      </Paper>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(AddressInformation);
