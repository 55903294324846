/** @format */

export const TERMS_AND_CONDITIONS_TEXT = {
  body: "We've recently updated our [Terms of Use](https://www.helloalpha.com/terms). To continue, we'll need you to agree. Please click to review the terms, then select \"I agree\" below to indicate your agreement. Thank you!",
  agree: "I agree",
  disagree: "I don't agree",
  goBack: "Go Back",
  welcome: "Welcome back",
  contactUs: "Contact us here for questions",
  modalTitle: "Other options",
  modalHeader: "Unfortunately you won't be able to continue using Alpha.",
  logout: "Log Out",
  modalBody:
    'In order for Alpha to continue providing your care, we need you to agree to the [Terms of Use](https://www.helloalpha.com/terms). Please select "Go back" below to return to the previous page, then select "I agree" to indicate your agreement. Thank you!',
  listItemEmail: "contact@helloalpha.com",
  listItemTelephone: "(415) 663-5584",
};
