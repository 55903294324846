/** @format **/

import React from "react";

interface ActivateBenefitsSettingsButtonProps {
  arrowPrimary?: boolean;
  text: string;
}

export const ActivateBenefitsSettingsButton = ({
  arrowPrimary,
  text,
}: ActivateBenefitsSettingsButtonProps) => {
  return (
    <button
      key={text}
      className="flex justify-between border-grey-light border-b py-4 w-full bg-transparent no-underline"
    >
      <p className="text-primary text-base">{text}</p>
      <img
        src={arrowPrimary ? "/assets/carrot-right-primary.svg" : "/assets/carrot-right.svg"}
        alt={`Open account settings modal for ${text}`}
      />
    </button>
  );
};
