/** @format */

import React from "react";

import type { MedicationOrder } from "src/v2/types/medications";

import { TestKitCard } from "src/components/Cards/TestKitCard";
import { useStoreState } from "src/v2/models";

interface TestKitCardsProps {
  testKits?: MedicationOrder[];
  condition: string;
}

export const TestKitCards = ({ testKits, condition }: TestKitCardsProps) => {
  const channels = useStoreState((state) => state.channels.channels);
  const supportChannel =
    !!channels && channels.find((channel) => channel.channel_type === "SUPPORT");
  return (
    <div>
      {testKits &&
        testKits.map((testKit) => {
          return (
            <div key={testKit.id}>
              <TestKitCard
                testKit={testKit}
                supportChannel={supportChannel}
                condition={condition}
              />
            </div>
          );
        })}
    </div>
  );
};
