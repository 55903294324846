/** @format */

import React from "react";
import { FormSpy } from "react-final-form";

import { isString } from "lodash";

import { ValidationErrorPopup } from "src/components/DynamicForm/Popups";

export const DynamicFormSubmitErrors = () => {
  return (
    <FormSpy subscription={{ submitError: true, submitFailed: true }}>
      {({ submitError, submitFailed }) => {
        const showError = submitFailed && submitError;
        if (!showError) {
          return null;
        }
        if (isString(submitError)) {
          return <span className="h-6 text-red">{submitError}</span>;
        } else {
          return <ValidationErrorPopup error={submitError} />;
        }
      }}
    </FormSpy>
  );
};
