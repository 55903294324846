/** @format */

import type { Action, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";

import type {
  Medication_TrackingAddMedicationTrackingRequestBodySchema,
  Medication_TrackingAddSymptomTrackingRequestBodySchema,
  PatientMedicationTrackingSchema,
  SymptomTrackingSchema,
} from "src/api";
import type { CreateModelDispatch } from "src/v2/models/_create";

import { UsersService } from "src/api";

export interface MedicationTrackingModel {
  medicationTrackingRecords: PatientMedicationTrackingSchema[];
  symptomTrackingRecords: SymptomTrackingSchema[];

  // Medication Tracking
  setMedicationTrackingRecords: Action<MedicationTrackingModel, PatientMedicationTrackingSchema[]>;
  fetchMedicationTracking: Thunk<MedicationTrackingModel>;
  createMedicationTracking: Thunk<
    MedicationTrackingModel,
    Medication_TrackingAddMedicationTrackingRequestBodySchema
  >;
  updateMedicationTracking: Thunk<
    MedicationTrackingModel,
    {
      medicationTrackingId: number;
      requestBody: Medication_TrackingAddMedicationTrackingRequestBodySchema;
    }
  >;
  deleteMedicationTracking: Thunk<MedicationTrackingModel, number>;

  // Symptom Tracking
  setSymptomTrackingRecords: Action<MedicationTrackingModel, SymptomTrackingSchema[]>;
  fetchSymptomTracking: Thunk<MedicationTrackingModel>;
  createSymptomTracking: Thunk<
    MedicationTrackingModel,
    Medication_TrackingAddSymptomTrackingRequestBodySchema
  >;
  updateSymptomTracking: Thunk<
    MedicationTrackingModel,
    {
      symptomTrackingId: number;
      requestBody: Medication_TrackingAddSymptomTrackingRequestBodySchema;
    }
  >;
  deleteSymptomTracking: Thunk<MedicationTrackingModel, number>;
}

export const medicationTrackingStore: MedicationTrackingModel = {
  medicationTrackingRecords: [],
  symptomTrackingRecords: [],

  setMedicationTrackingRecords: action((state, payload) => {
    state.medicationTrackingRecords = payload;
  }),
  fetchMedicationTracking: thunk(async (actions) => {
    UsersService.getMedicationTracking({ userId: "me" }).then((response) => {
      actions.setMedicationTrackingRecords(response);
    });
  }),
  createMedicationTracking: thunk(async (actions, payload, { dispatch }) => {
    const _dispatch = dispatch as CreateModelDispatch;
    UsersService.addMedicationTracking({ userId: "me", requestBody: payload }).then((response) => {
      actions.fetchMedicationTracking();
      _dispatch.profile.setShowShareData(true);
    });
  }),

  updateMedicationTracking: thunk(async (actions, payload) => {
    UsersService.updateMedicationTracking({
      userId: "me",
      trackingId: payload.medicationTrackingId,
      requestBody: payload.requestBody,
    }).then((response) => {
      actions.fetchMedicationTracking();
    });
  }),
  deleteMedicationTracking: thunk(async (actions, payload) => {
    return UsersService.deleteMedicationTracking({ userId: "me", trackingId: payload }).then(
      (response) => {
        actions.fetchMedicationTracking();
      },
    );
  }),

  setSymptomTrackingRecords: action((state, payload) => {
    state.symptomTrackingRecords = payload;
  }),

  fetchSymptomTracking: thunk(async (actions) => {
    UsersService.getSymptomTracking({ userId: "me" }).then((response) => {
      actions.setSymptomTrackingRecords(response);
    });
  }),

  createSymptomTracking: thunk(async (actions, payload, { dispatch }) => {
    const _dispatch = dispatch as CreateModelDispatch;
    UsersService.addSymptomTracking({ userId: "me", requestBody: payload }).then((response) => {
      actions.fetchSymptomTracking();
      _dispatch.profile.setShowShareData(true);
      return response;
    });
  }),

  updateSymptomTracking: thunk(async (actions, payload) => {
    UsersService.updateSymptomTracking({
      userId: "me",
      trackingId: payload.symptomTrackingId,
      requestBody: payload.requestBody,
    }).then((response) => {
      actions.fetchSymptomTracking();
      return response;
    });
  }),

  deleteSymptomTracking: thunk(async (actions, payload) => {
    return UsersService.deleteSymptomTracking({ userId: "me", trackingId: payload }).then(
      (response) => {
        actions.fetchSymptomTracking();
        return response;
      },
    );
  }),
};
