/** @format */

import React from "react";
import { useParams } from "react-router-dom";

import { WeighLossJournalReport } from "@alphamedical/components";
import { Banner } from "src/v2/designSystem";

import type { ChartData, ScatterChart } from "src/api";

import { Loader } from "src/components/Loader";
import { PageWrapper } from "src/components/PageWrapper";
import { AddJournalEntryBottomSheet } from "src/routes/Records/WeightLossJournal/AddJournalEntryBottomSheet";
import WithShareDataModal from "src/routes/Records/WeightLossJournal/WithShareDataModal";
import { useMembership } from "src/utils/hooks/useMembership";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { ChartType } from "src/v2/components/Charts/types";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { MEMBERSHIP_PLANS } from "src/v2/constants";
import { useStoreDispatch, useStoreState } from "src/v2/models";
import { chartsSelector } from "src/v2/models/charts";
import { useCurrentUser } from "src/v2/models/profile";

const WeightLossJournal = () => {
  const { type: chartType } = useParams<{ type?: string }>();
  useTopBar({
    title: chartType === "waist-circumference" ? "Waist circ." : "Weight",
    variant: "nested",
    showBackButton: true,
  });
  const [selectedChartType, setSelectedChartType] = React.useState<
    "weight" | "waist-circumference"
  >(chartType === "waist-circumference" ? ChartType.WAIST_CIRCUMFERENCE : ChartType.WEIGHT);
  const [selectedEntry, setSelectedEntry] = React.useState<ChartData>();

  const dispatch = useStoreDispatch();
  const charts = useStoreState(chartsSelector);
  const currentUser = useCurrentUser();
  const { activePlanCode } = useMembership();

  const [addOpen, setAddOpen] = React.useState(false);

  React.useEffect(() => {
    dispatch.charts.fetchAllCharts();
  }, []);

  const selectedChart = charts[selectedChartType];
  const asian =
    currentUser &&
    currentUser.pii.racial_description?.some((race) =>
      ["east-asian-or-asian-american", "south-asian-or-indian-american"].includes(race),
    );
  const bmiChart = charts[ChartType.BMI]?.chart_data;
  const currentBmi = bmiChart && bmiChart.length ? bmiChart[bmiChart.length - 1]?.y : undefined;

  const isAllowedEntry =
    selectedChartType === ChartType.WEIGHT || selectedChartType === ChartType.WAIST_CIRCUMFERENCE;

  return (
    <PageWrapper cxPadding="px-0 py-0" cxMaxWidth="w-screen sm:max-w-xl">
      <Banner
        body={
          <div>
            The Weight Loss Journal will only be available to Alpha Plus+ Plan subscribers starting
            April 1, 2024.
            <a href="/membership-signup?plan=plus" className="text-forest-120 underline ml-2">
              Join Alpha Plus+ Plan today
            </a>
            .
          </div>
        }
        variant="warning"
        showBanner={!activePlanCode || activePlanCode !== MEMBERSHIP_PLANS.plus}
        showIcon={false}
      />
      <Loader show={!selectedChart}>
        {selectedChart && (
          <WeighLossJournalReport<ScatterChart>
            chart={selectedChart}
            chartType={selectedChartType}
            bmiValue={currentBmi}
            isAsianBmi={asian}
            onClickAdd={() => setAddOpen(true)}
            onSelectEntry={setSelectedEntry}
          />
        )}
        {(addOpen || (selectedEntry && isAllowedEntry)) && (
          <AddJournalEntryBottomSheet
            chartType={selectedChartType as ChartType.WEIGHT | ChartType.WAIST_CIRCUMFERENCE}
            entry={selectedEntry}
            onClose={() => {
              setAddOpen(false);
              setSelectedEntry(undefined);
            }}
          />
        )}
      </Loader>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(WithShareDataModal(WeightLossJournal));
