/** @format */

import React from "react";
import { useHistory } from "react-router-dom";

import * as Sentry from "@sentry/react";
import { FORM_ERROR } from "final-form";
import _ from "lodash";

import type { GroupSessionSignupSchema } from "src/v2/models/api_types";
import type { Service } from "src/v2/models/service";

import { DynamicForm, FormContext } from "src/components/DynamicForm/DynamicForm";
import { useURLNavigationController } from "src/components/DynamicForm/hooks";
import { axiosErrorHandler, getResponsesPayload } from "src/components/DynamicForm/utils";
import { SideNavigation } from "src/components/SideNavigation";
import { isAxiosError } from "src/helpers/axios";
import { _GET, _POST, _PUT } from "src/helpers/http";
import Div100vh from "src/v2/components/Div100vh";
import { OutlineNavbar } from "src/v2/components/OutlineNavbar";
import { useStoreDispatch } from "src/v2/models";
import { OutlineContext } from "src/v2/models/outline";
import { useCurrentUser } from "src/v2/models/profile";
import { useQuery } from "src/v2/utils/useQuery";

interface GroupSessionSignupProps {
  sessionType: "group-coaching" | "group-therapy";
  route: "workshops" | "support-groups";
}

interface GroupSessionFormContext {
  // Used for type guards to accurately determine if a variable is this type
  context_type: "GroupSessionFormContext";
  signup: GroupSessionSignupSchema;
}

export const isGroupSessionFormContext = (value: any): value is GroupSessionFormContext =>
  value?.context_type === "GroupSessionFormContext";

export const useGroupSessionFormContext = () => {
  const context = React.useContext(FormContext);
  return isGroupSessionFormContext(context) ? context : {};
};

const getCurrentSignupData = (userId: number, sessionType: string) => {
  // 1. Get data about the current service we want to signup for
  return (
    _GET<Service>(`/users/${userId}/services/${sessionType}?include=group_session_signups`)
      // 2. If the service already exists, return the first incomplete signup that it can find
      .then((service) =>
        service.group_session_signups?.find((signup) => !signup.responses.completed),
      )
      // 3. If there is not already a service (api returns 404), return undefined
      .catch((err) => {
        if (isAxiosError(err) && err.response?.status === 404) {
          return undefined;
        }
        throw err;
      })
      // 4. If there is not an existing signup, create and return a new one
      .then((existingSignup) => {
        if (!existingSignup) {
          return _POST<GroupSessionSignupSchema>(`/users/${userId}/group_session_signups`, {
            session_type: sessionType,
          });
        }
        return existingSignup;
      })
  );
};

const urlParamsToObject = (params: URLSearchParams) => {
  return Object.fromEntries(params.entries());
};

export const GroupSessionSignup = (props: GroupSessionSignupProps) => {
  const history = useHistory();
  const dispatch = useStoreDispatch();
  const currentUser = useCurrentUser();

  // Get the query parameters as an object that can be passed into the form's default values
  const urlParams = useQuery();
  const [defaultUrlValues] = React.useState(urlParamsToObject(urlParams));

  const [currentSignup, setCurrentSignup] = React.useState<GroupSessionSignupSchema | undefined>();

  const { pageKey, setPageKey } = useURLNavigationController(
    `/${props.route}/signup`,
    (routeMatch) => routeMatch.params.page,
  );

  React.useEffect(() => {
    getCurrentSignupData(currentUser.id, props.sessionType)
      .then(setCurrentSignup)
      .catch(Sentry.captureException);
  }, []);

  return (
    <Div100vh className="flex flex-col w-full">
      {!!currentSignup && !currentSignup.responses.completed && (
        <DynamicForm<Record<string, any>, GroupSessionFormContext>
          allowFormAutoSubmit={true}
          // Get the default page key from the url, if available
          defaultPageKey={pageKey}
          // The key of the oultine file to use for this signup
          outlineKey={props.sessionType}
          // Pass in data for the form context that will be accessible in other parts of the framework
          context={{ context_type: "GroupSessionFormContext", signup: currentSignup }}
          // Change the page url to match the page key we are currently on. This is helpful for analytics/trackers
          onPageChange={({ page }) => setPageKey(page.key)}
          // Set the initial values with any existing values already submitted to the signup form.
          initialValues={{
            ...(currentSignup.responses.values || {}),
            dob: currentUser.pii.dob,
            sex: currentUser.pii.sex,
            topic_type: props.sessionType,
          }}
          // Set the default values for the forms. These values will not be in the form state until the matching field is
          // shown
          defaultValues={{ ...currentUser.pii, ...currentUser.pii.address, ...defaultUrlValues }}
          // Submit the changed fields to the api
          onPageSubmit={async ({ outline, changedValues, hasNextPage, nextPage }) => {
            if (!_.isEmpty(changedValues)) {
              try {
                const res = await _PUT<GroupSessionSignupSchema>(
                  `/users/${currentUser.id}/group_session_signups/${currentSignup.id}/responses`,
                  getResponsesPayload(outline, changedValues),
                );
                setCurrentSignup(res);
              } catch (err) {
                const errMsg = "Unable to submit responses";
                return isAxiosError(err)
                  ? axiosErrorHandler(errMsg)(err)
                  : { [FORM_ERROR]: errMsg };
              }
            }

            // Group submitted values by category and call other endpoints if needed to keep easy-peasy store up to date
            const { pii, insurance } = _.groupBy<string>(
              Object.keys(changedValues),
              (fieldKey) => outline.fields[fieldKey]?.category,
            );

            // If either pii or insurance data is submitted, refetch that data
            if (pii != null || insurance != null) {
              dispatch.profile
                .fetchProfile({ include: insurance ? ["insurance"] : [] })
                .catch(Sentry.captureException);
            }

            if (hasNextPage) {
              nextPage();
            } else {
              try {
                await _POST(
                  `/users/${currentUser.id}/group_session_signups/${currentSignup.id}/submit`,
                );
              } catch (err) {
                if (isAxiosError(err)) {
                  const errType = err.response?.data?.type;
                  if (
                    errType &&
                    ["GroupSessionUnavailableError", "RecurlyValidationError"].includes(errType)
                  ) {
                    return {
                      [FORM_ERROR]: {
                        type: errType,
                        description: err.response?.data.description,
                      },
                    };
                  }
                  return axiosErrorHandler("Signup error")(err);
                }
                return { [FORM_ERROR]: "Signup error" };
              }

              history.push(`/my-cases`);
            }
          }}
        >
          {({ currentPageIdx, pageCount, outline, hasPrevPage, prevPage, page }) => {
            const currentPage = outline.pages[currentPageIdx];
            return (
              <OutlineContext.Provider value={outline}>
                <OutlineNavbar
                  progress={(100 * (currentPageIdx + 1)) / (pageCount + 1)}
                  leftRender={() =>
                    hasPrevPage ? (
                      <div className="text-4xl" onClick={prevPage}>
                        &lt;
                      </div>
                    ) : null
                  }
                />
                <div className="flex flex-1 overflow-auto">
                  {currentPage && (
                    <SideNavigation currentPage={currentPage} stages={outline.stages} />
                  )}
                  <div className="w-full flex flex-col flex-1 px-5 py-6 max-w-2xl m-auto h-full overflow-auto">
                    {currentPageIdx > 0 && (
                      <div
                        className="text-sm cursor-pointer py-2 hover:text-primary-100 text-primary-80 hidden md:block"
                        onClick={prevPage}
                      >
                        &lt; Back
                      </div>
                    )}
                    {page}
                  </div>
                </div>
              </OutlineContext.Provider>
            );
          }}
        </DynamicForm>
      )}
    </Div100vh>
  );
};
