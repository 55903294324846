/** @format */
import type { Action, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";

import type { GenericLabSchema } from "src/api";

import { UsersService } from "src/api";

export const fileToDataURI = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.result instanceof ArrayBuffer) {
        reject(new Error("Failed to read the file"));
      } else {
        const dataURI = reader.result as string;
        resolve(dataURI);
      }
    };

    reader.readAsDataURL(file);
  });

export interface UserLabsModel {
  userLabs: GenericLabSchema[];

  setUserLabs: Action<UserLabsModel, GenericLabSchema[]>;

  fetchUserLabs: Thunk<UserLabsModel>;

  uploadUserLab: Thunk<
    UserLabsModel,
    { file: File; serviceId: number; labName: string; completedAt: string }
  >;
}

export const userLabsStore: UserLabsModel = {
  userLabs: [],
  setUserLabs: action((state, userOrders) => {
    state.userLabs = userOrders.filter((value) => !!value.external_file);
  }),

  fetchUserLabs: thunk(async (actions) => {
    const userLabs = await UsersService.getUserLabs({ userId: "me" });
    actions.setUserLabs(userLabs);
  }),

  uploadUserLab: thunk(async (actions, { labName, serviceId, file, completedAt }) => {
    const dataUri = await fileToDataURI(file);

    await UsersService.uploadLab({
      userId: "me",
      requestBody: {
        lab_name: labName,
        service_id: serviceId,
        data_uri: dataUri,
        completed_at: completedAt,
      },
    });
    actions.fetchUserLabs();
  }),
};
