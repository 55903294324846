/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FileSchema } from '../models/FileSchema';
import type { RecurlyInvoiceSchema } from '../models/RecurlyInvoiceSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RecurlyService {

    /**
     * @returns FileSchema
     * @throws ApiError
     */
    public static getRecurlyInvoiceAsPdf({
        userId,
        invoiceId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        invoiceId: string,
    }): CancelablePromise<FileSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/recurly/{user_id}/invoice/{invoice_id}/pdf',
            path: {
                'user_id': userId,
                'invoice_id': invoiceId,
            },
        });
    }

    /**
     * @returns RecurlyInvoiceSchema
     * @throws ApiError
     */
    public static getRecurlyUserInvoices({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<Array<RecurlyInvoiceSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/recurly/{user_id}/invoices',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @deprecated
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getRecurlySubscriptions({
        userId,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/users/{user_id}/recurly/subscriptions',
            path: {
                'user_id': userId,
            },
        });
    }

}
