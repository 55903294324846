/** @format */

import type React from "react";

import { useForm } from "react-final-form";
import { useHistory } from "react-router-dom";

import * as Sentry from "@sentry/react";
import classNames from "classnames";
import type { FormApi } from "final-form";
import type * as H from "history";

import type { FormPopupError, FormPopupOption } from "src/v2/models/api_types";

import { MarketingService } from "src/api";
import { Accordion } from "src/components/DynamicForm/Fields/CustomFields/Accordion";
import { useCurrentUser } from "src/v2/models/profile";

interface ResponsesValidationPopupProps {
  validationError: FormPopupError;
  onSubmit: (
    event?: Partial<Pick<React.SyntheticEvent, "preventDefault" | "stopPropagation">>,
  ) => Promise<any> | undefined;
  goToPage?: (pageKey: string, values: any) => void;
  clearValidationError: () => void;
  condition?: string;
}

// Defines the logic for what should happen if a button representing a FormPopupOption is clicked. Does not handle
// react router navigation using the link prop.
const onClickHandler =
  (
    form: FormApi,
    option: FormPopupOption,
    history: H.History,
    popupProps: ResponsesValidationPopupProps,
  ) =>
  (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { onSubmit, clearValidationError, validationError } = popupProps;

    if (option.link) {
      // First check if this option should navigate the user to a new page
      history.push(option.link);
    } else if (option.external_link) {
      window.location.replace(option.external_link);
    } else if (option.close_modal) {
      // Next check if the modal should instead be closed so that the user can correct their responses if needed
      clearValidationError();
    } else {
      // By default, we will treat the click as a form submit

      // If popup is a "soft stop" we add this validation code to the list of validators to skip on submit.
      if (option.allow_continue) {
        const { values } = form.getState();
        form.change("validatorsToSkip", [
          ...(values.validatorsToSkip || []),
          validationError.validator_code,
        ]);
        clearValidationError();
      }

      // If this option also defines a list of field values that need to be changed before submitting, apply those changes
      // to the form state
      const changedValues = option.change_values || {};
      Object.keys(changedValues).forEach((field) => {
        form.change(field, changedValues[field]);
      });

      const nextPageKey = option.next_page;
      if (nextPageKey && popupProps.goToPage) {
        clearValidationError();
        const { values } = form.getState();
        // Move to a specified page
        popupProps.goToPage(nextPageKey, values);
      } else {
        // Re-trigger the original on submit handler
        onSubmit(e);
      }
    }
  };

export const ResponsesValidationPopup = (props: ResponsesValidationPopupProps) => {
  const form = useForm();
  const history = useHistory();
  const patient = useCurrentUser();

  const { condition = "" } = props;

  return (
    <>
      <div className="px-5 pt-8">
        <p dangerouslySetInnerHTML={{ __html: props.validationError.description }} />
      </div>
      <div className={"p-2"}>
        {props.validationError.accordion && <Accordion {...props.validationError.accordion} />}
      </div>
      <div className="bg-grey-lightest p-5 space-y-4">
        {props.validationError.options.map((option, idx) => (
          <button
            key={option.cta}
            onClick={(event) => {
              if (option.lead) {
                MarketingService.leadCreate({
                  requestBody: {
                    product: condition,
                    platform: option.lead,
                    email: patient.email,
                  },
                }).catch(Sentry.captureException);
              }
              onClickHandler(form, option, history, props)(event);
            }}
            type="submit"
            className={classNames("w-full h-12 text-center font-bold", {
              "bg-forest text-white": idx === 0,
              "bg-clear text-primary border border-primary": idx !== 0,
            })}
          >
            {option.cta}
          </button>
        ))}
      </div>
    </>
  );
};
