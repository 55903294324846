/** @format */
import React from "react";
import { useField, useFormState } from "react-final-form";

import { addMinutes } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

import { Typography } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import { useStoreState } from "src/v2/models";
import { useFormNav } from "src/v2/routes/Consultation";

export const SchedulingJoinInfo = () => {
  const field = useField("scheduling");
  const consult = useStoreState((state) => state.consults.currentConsultation);
  const formNav = useFormNav();
  const { values } = useFormState();

  const { date, patient_timezone } = field.input.value;

  // Format date, e.g.: Tue April 9th, 2024
  const appointmentDate = date && formatInTimeZone(date, patient_timezone, "EEE PPP");
  // Format times, e.g.: 11:36 AM
  const appointmentStart = date && formatInTimeZone(date, patient_timezone, "p");
  const appointmentEnd =
    date && formatInTimeZone(addMinutes(new Date(date), 20), patient_timezone, "p");
  return (
    <>
      <div className="bg-cloud-40 flex flex-col items-center p-4 my-4">
        <div
          className="bg-white flex items-center justify-center shadow"
          style={{ width: "48px", height: "48px", borderRadius: "24px" }}
        >
          <img src="/assets/google-voice-icon.png" alt="Google voice icon" />
        </div>
        {appointmentDate && (
          <div className="flex flex-col items-center mt-4 text-center">
            <Typography variant="subtitle1">
              {consult?.subscription.condition.name} call with my Alpha Provider
            </Typography>
            <Typography variant="body1">{appointmentDate}</Typography>
            <Typography variant="body1">
              {appointmentStart} - {appointmentEnd} ({patient_timezone})
            </Typography>
          </div>
        )}
      </div>
      <Typography variant="body1">
        You'll receive an email with instructions to join. Your provider doesn’t call you directly.
        You will need to dial in and enter a PIN at the time of your call.
      </Typography>
      <div>
        <ButtonPdb
          type="button"
          onClick={() => formNav.goToPage("synchronous-scheduling", values)}
          className="w-full mt-6"
          variant="secondary"
        >
          Reschedule
        </ButtonPdb>
      </div>
    </>
  );
};
