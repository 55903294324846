/** @format */

import React from "react";
import { useHistory } from "react-router-dom";

import HomeCard from "src/v2/designSystem/HomeCard/HomeCard";

const ExploreVisitsCard = () => {
  const history = useHistory();

  const clickHandler = () => {
    history.push("/your-care/explore-visits");
  };

  return (
    <HomeCard
      title="Healthcare from anywhere"
      body="Get care for over 100 medical conditions online from an expert in women's health."
      ctaMessage="Explore visits"
      cardIcon={
        <img
          src="/assets/explore-visits.svg"
          alt="Explore Visits Icon"
          className="min-w-16 w-16 h-auto"
        />
      }
      onClick={clickHandler}
    />
  );
};

export default ExploreVisitsCard;
