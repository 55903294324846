/** @format */

import React from "react";
import { useHistory } from "react-router-dom";

import { Typography } from "@alphamedical/components";
import { ButtonPdb, Paper } from "src/v2/designSystem";

import ConditionScroller from "src/components/DynamicForm/Fields/CustomFields/WelcomeFlow/ConditionScroller";
import { PageWrapper } from "src/components/PageWrapper";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useStoreDispatch, useStoreState } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";

export const CompletionPage = () => {
  const history = useHistory();
  const dispatch = useStoreDispatch();
  const { id } = useCurrentUser();

  const beforeRegistrationLink = useStoreState((state) => state.profile.beforeRegistrationLink);

  const onClickHandler = async (cta: number) => {
    const homepage = "/";
    const visit = "/your-care/explore-visits";

    await dispatch.profile.fetchRegistrationOutlineData({ userId: id });

    history.push(cta === 1 ? beforeRegistrationLink || visit : homepage);
  };

  return (
    <PageWrapper cxPadding="px-0">
      <Paper
        elevation={0}
        rounded={false}
        className="flex-1 mx-auto w-full max-w-2xl px-6 pt-6 overflow-hidden bg-sand-20"
      >
        <div className="flex flex-1 flex-col" style={{ height: "80vh" }}>
          <div className="flex-1">
            <Typography variant="h1" className="mt-2 text-forest-100">
              You've successfully created your account!
            </Typography>
            <ConditionScroller
              className="welcome__fill-container-width h-40 my-4"
              backgroundColor="bg-white"
            />
            <Typography variant="h4" align="center" className="text-forest-100">
              Explore treatment for more than <b>100 medical conditions</b> online. Alpha is here to
              provide care and support for you and help you reach your health goals!
            </Typography>
          </div>
          <ButtonPdb className="w-full mt-4" onClick={() => onClickHandler(1)}>
            Start a visit
          </ButtonPdb>
          <ButtonPdb variant="secondary" className="w-full mt-4" onClick={() => onClickHandler(2)}>
            Go to home page
          </ButtonPdb>
        </div>
      </Paper>
    </PageWrapper>
  );
};

export const CompletionPageWithTopBar = WithTopBarNavigation(CompletionPage);
