/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NpsSaveNpsScoreRequestBodySchema } from '../models/NpsSaveNpsScoreRequestBodySchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NpsService {

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static declineNps({
        userId,
    }: {
        userId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/nps/{user_id}/decline',
            path: {
                'user_id': userId,
            },
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static saveNpsScore({
        userId,
        requestBody,
    }: {
        userId: number,
        requestBody: NpsSaveNpsScoreRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/nps/{user_id}/score',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
