/** @format */

import React from "react";
import { Link } from "react-router-dom";

import type { InvalidType } from "src/v2/utils/page/validators";

interface EligibilityHighBloodPressureProps {
  invalid: InvalidType;
  values: any;
  closeModal: any;
  saveValues: any;
  moveForward: any;
  submitted: boolean;
}
interface EligibilityHighBloodPressureState {
  disableButtons: boolean;
  error: any;
}

const initialLocalState = { disableButtons: false, error: undefined };

const EligibilityHighBloodPressure = (props: EligibilityHighBloodPressureProps) => {
  const [localState, setLocalState] =
    React.useState<EligibilityHighBloodPressureState>(initialLocalState);

  React.useEffect(() => setLocalState(initialLocalState), []);

  const highDiastolicNoSymptoms = async () => {
    setLocalState({
      disableButtons: true,
      error: undefined,
    });
    try {
      await props.saveValues(props.values);
      props.moveForward();
      setLocalState({
        disableButtons: false,
        error: undefined,
      });
      props.closeModal();
    } catch (e) {
      const message = "There was an error saving progestin-only pill. Please try again.";
      // tslint:disable-next-line
      console.warn(message);
      setLocalState({
        disableButtons: false,
        error: {
          code: "save-error",
          message,
        },
      });
    }
  };

  return (
    <div className="m-5">
      {props.invalid.invalidType === "high-systolic" && (
        <div className="text-center">
          <h1 className="text-center text-2xl my-0">Warning</h1>
          <p className="font-bold mt-0 mx-auto w-3/4">
            You entered that your systolic blood pressure is higher than 179.
          </p>
          <p className="text-primary">
            Unfortunately, we are not able to provide care to you at this time.
            <br /> If the number you entered is correct, you should seek medical help immediately.
            If the number is incorrect, please provide the correct measurement.
          </p>
          <button onClick={props.closeModal} className={`btn btn-primary w-full my-2`}>
            I entered the incorrect number for my systolic blood pressure.
          </button>
          <Link to="/my-cases">
            <button onClick={props.closeModal} className={`btn btn-primary w-full my-2`}>
              I entered the correct amount, end my visit.
            </button>
          </Link>
        </div>
      )}

      {props.invalid.invalidType === "high-diastolic" && (
        <div className="text-center">
          <h1 className="text-center text-2xl my-0">Warning</h1>
          <p className="font-bold mx-auto mt-0 w-3/4">
            You entered that your diastolic blood pressure is higher than 139.
          </p>
          <p className="text-primary text-left">
            If this number is correct and you experience one or more of the following symptoms, we
            cannot provide you with care and you should seek medical help immediately:
          </p>
          <ul className="text-left mb-2">
            <li>Severe headache</li>
            <li>Shortness of breath</li>
            <li>Nosebleeds</li>
            <li>Severe anxiety</li>
          </ul>
          <p className="text-primary text-left font-bold text-xl mt-8">Your options:</p>
          <button onClick={props.closeModal} className={`btn btn-primary w-full my-2`}>
            I entered the incorrect number for my diastolic blood pressure.
          </button>
          <button onClick={highDiastolicNoSymptoms} className={`btn btn-primary w-full my-2`}>
            I entered the correct amount, but I'm not experiencing any of the above symptoms.
          </button>
          <Link to="/my-cases">
            <button onClick={props.closeModal} className={`btn btn-primary w-full my-2`}>
              I entered the correct amount, and I've experienced one of the above symptoms, end my
              visit.
            </button>
          </Link>
          {localState.error && (
            <div className="text-red text-center mt-5">{localState.error.message}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default EligibilityHighBloodPressure;
