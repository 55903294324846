/** @format */

import type React from "react";

import { useCallback, useEffect, useState } from "react";
import { useFormState } from "react-final-form";

import * as Sentry from "@sentry/react";
import _ from "lodash";

import { ButtonPdb, RadioSelection } from "src/v2/designSystem";

import type { AddressValues } from "src/v2/utils/page/_validators/address";

import { CloseIcon } from "src/v2/components/Svgs";
import { ACTIVE_STATES } from "src/v2/constants";

interface EligibilityFormAddressProps {
  condition: string;
  invalid: {
    current: AddressValues;
    suggested?: {
      street1?: string;
      street2?: string;
      city?: string;
      state?: string;
      zip?: string;
    };
    suggestion?: boolean;
  };
  values: {
    [key: string]: any;
  };
  closeModal?: () => void;
  saveValues: (values: any) => void;
  moveForward: () => void;
}

export const EligibilityFormAddress: React.FC<EligibilityFormAddressProps> = ({
  invalid,
  values,
  closeModal,
  saveValues,
  moveForward,
}) => {
  const [suggested, setSuggested] = useState<{ [key: string]: any }>({});
  const [current, setCurrent] = useState<{ [key: string]: any }>({});
  const [disableButtons, setDisableButtons] = useState(false);
  const { values: formValues } = useFormState();

  const buildAddressSuggested = useCallback(
    () => ({
      address: invalid.suggested?.street1 || invalid.current.address,
      address2: invalid.suggested?.street2 || invalid.current.address2,
      city: invalid.suggested?.city || invalid.current.city,
      state: invalid.suggested?.state || invalid.current.state,
      zipcode: invalid.suggested?.zip || invalid.current.zipcode,
    }),
    [invalid],
  );

  const buildAddressCurrent = useCallback(
    () => ({
      address: invalid.current.address,
      address2: invalid.current.address2,
      city: invalid.current.city,
      state: invalid.current.state,
      zipcode: invalid.current.zipcode,
    }),
    [invalid],
  );

  useEffect(() => {
    setSuggested(buildAddressSuggested());
    setCurrent(buildAddressCurrent());
  }, [buildAddressSuggested, buildAddressCurrent]);

  const renderAddressString = (keys: string[], type: "current" | "suggested"): string => {
    return keys
      .map((a) => (type === "current" ? current[a] : suggested[a]))
      .filter((a) => !!a)
      .join(", ");
  };

  const renderAddress = (type: "current" | "suggested") => {
    const lines = [
      renderAddressString(["address", "address2"], type),
      renderAddressString(["city", "state", "zipcode"], type),
    ];
    return (
      <>
        <span className="font-bold text-forest-100 ">
          {type === "suggested" ? "Suggested address" : "Original address"}
        </span>
        {lines.map((line, idx) => (
          <div key={`address-${type}-${idx}`}>{line}</div>
        ))}
      </>
    );
  };

  const saveAddress = async (addressValues: { [key: string]: any }) => {
    try {
      if (!addressValues.address2) {
        addressValues.address2 = "";
      }
      await saveValues(addressValues);
      moveForward();
      closeModal && closeModal();
      setDisableButtons(false);
    } catch (e) {
      setDisableButtons(false);
    }
  };

  const useAddress = async () => {
    if (!disableButtons) {
      const selectedAddressType = formValues["selected-address"];
      setDisableButtons(true);

      const newValues: { [key: string]: any } = {
        ...values,
        ...(selectedAddressType === "current" ? current : suggested),
      };
      delete newValues["selected-address"];
      await saveAddress(newValues);
    } else {
      Sentry.captureMessage("Duplicate tap of use address button");
    }
  };

  const checkCoverage = (state: string) => {
    return ACTIVE_STATES.includes(state);
  };

  const suggestedState = _.get(invalid, "suggested.state");
  const suggestedObject = _.get(invalid, "suggested");
  const covered = checkCoverage(suggestedState);

  const checkShippoSuggestionObject = (_suggested: any) => {
    if (_suggested.state && !checkCoverage(_suggested.state)) {
      return false;
    }
    return _suggested;
  };

  return (
    <div className="flex flex-1 flex-col w-svw">
      {invalid.suggestion && checkShippoSuggestionObject(suggestedObject) && (
        <div>
          <div className="flex flex-row p-4 justify-between bg-grey-10">
            <h1 id={"modal-header"} className={"text-forest-120 text-base font-bold py-2"}>
              Verify your address
            </h1>
            <button onClick={closeModal} className="p-2">
              <CloseIcon width={16} fill="#656E6D" />
            </button>
          </div>
          <div className="px-4">
            <RadioSelection
              defaultValue="suggested"
              name="selected-address"
              options={[
                {
                  label: renderAddress("suggested"),
                  value: "suggested",
                },
                {
                  label: renderAddress("current"),
                  value: "current",
                },
              ]}
              required
            />
          </div>
          <div className="p-4">
            <ButtonPdb onClick={useAddress} variant="primary" className="w-full">
              Use this address
            </ButtonPdb>
            <ButtonPdb onClick={closeModal} variant="secondary" className="w-full mt-4">
              Edit address
            </ButtonPdb>
          </div>
        </div>
      )}

      {invalid.suggestion && suggestedState !== undefined && !covered && (
        <>
          <div className="text-base">
            <div>
              The zipcode you entered appears to be located in a state we don't currently cover:
              {` ${suggestedState}`}.
            </div>
            <div>
              To get updates on when we are available in your area, please reach out to our Customer
              Success Team at{" "}
              <a className="text-primary" href="mailto:contact@helloalpha.com">
                contact@helloalpha.com
              </a>{" "}
              | (415) 663-5584
            </div>
          </div>
          <div className="text-lg p-5 border border-primary my-4">{renderAddress("current")}</div>
          <div className="text-base">
            {`States we currently cover: `}
            {ACTIVE_STATES.join(", ")}
          </div>
          <button onClick={closeModal} className="btn btn-primary w-full my-5">
            Accidentally entered the wrong address? Click here.
          </button>
        </>
      )}

      {invalid.suggestion === undefined && (
        <>
          <div className="text-base">We can't find the address you've entered</div>
          <div className="text-lg p-5 border border-primary my-4">{renderAddress("current")}</div>
          <button onClick={closeModal} className="btn btn-primary w-full my-5">
            Go back and update address
          </button>
          <div
            onClick={useAddress}
            className={`
              text-sm
              text-primary-60 hover:text-primary
              cursor-pointer hover:underline text-center
            `}
          >
            If the address entered is correct, click here to save and move forward.
          </div>
        </>
      )}
    </div>
  );
};
