/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TagsSchema } from '../models/TagsSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TagsService {

    /**
     * Return tags based on tag_type.
     * @returns TagsSchema
     * @throws ApiError
     */
    public static getTagList({
        tagType,
        internalUse,
        sort,
        team,
    }: {
        tagType: string,
        /**
         * Filters the list of tags to only include tags only for internal use
         */
        internalUse?: boolean,
        /**
         * Sorts results by label or by sort order and then by label
         */
        sort?: string,
        /**
         * Filters the list of tags to only tags for a specific team
         */
        team?: string,
    }): CancelablePromise<Array<TagsSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tags/{tag_type}',
            path: {
                'tag_type': tagType,
            },
            query: {
                'internal_use': internalUse,
                'sort': sort,
                'team': team,
            },
        });
    }

}
