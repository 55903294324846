/** @format */

import React from "react";

import type { Channel } from "src/v2/models/channels";

import { Loader } from "src/components/Loader";
import { useGetChannels } from "src/utils/hooks";

const ChannelsContext = React.createContext<Channel[] | undefined>(undefined);

export const useChannels = () => {
  const channels = React.useContext(ChannelsContext);
  if (channels == null) {
    throw Error(
      "Cannot access channels. Make sure that useChannels is called inside ChannelsContext.Provider and that the channels is set.",
    );
  }
  return channels;
};

export const ChannelLoader: React.FunctionComponent = (props) => {
  const { loadingChannels, channels } = useGetChannels();

  return loadingChannels ? (
    <Loader show={true} />
  ) : (
    <ChannelsContext.Provider value={channels}>{props.children}</ChannelsContext.Provider>
  );
};

export const withChannelLoader =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: P) => (
    <ChannelLoader>
      <Component {...props} />
    </ChannelLoader>
  );
