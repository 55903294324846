/** @format */

import React from "react";
import { Form } from "react-final-form";

import { RadioField } from "@alphamedical/components";

interface EligibilityFormInsuranceProps {
  email: string;
  submitLead: any;
  submitted: boolean;
  saveValues: any;
  closeModal: any;
  values: any;
}

const _initOptions = [
  {
    value: "yes",
    label:
      "Yes, I'll proceed as a cash patient. Use my payment card on file to bill me for my medication.",
  },
  {
    value: "no",
    label: "No, please contact me when Express Scripts will cover my prescription",
  },
];
export const EligibilityFormInsurance: React.FC<EligibilityFormInsuranceProps> = (props) => {
  const [requestedForCash, setRequestedForCash] = React.useState<boolean>(false);

  return (
    (!requestedForCash && (
      <div className="p-5">
        <div className="text-2xl font-normal mb-10 text-primary">
          Unfortunately your insurance carrier does not allow mail delivery from our partner
          pharmacy.
        </div>
        <p className="text-base leading-normal my-5">
          It looks like your mail order prescription benefits are covered by Express Scripts. At
          this time, Express Scripts does not cover prescription purchases mailed by our partner
          pharmacy. We're working on changing this, but until then, your prescription will not be
          covered by insurance. Would you like to proceed without insurance?
        </p>
        <Form
          onSubmit={() => {
            return;
          }}
          validate={async (formValues) => {
            const value = formValues["request-as-cash"];
            if (value === "yes") {
              const values = {
                ...props.values,
                rx_bin: "",
                rx_pcn: "",
                group_number: "",
                cardholder_id: "",
                payment_preference: "cash",
              };

              await props.saveValues(values);
              props.closeModal();
            } else if (value === "no") {
              await props.submitLead({ email: props.email });
              setRequestedForCash(true);
            }
          }}
        >
          {() => <RadioField name="request-as-cash" options={_initOptions} />}
        </Form>
      </div>
    )) || (
      <div className="p-5">
        <div className="text-center m-auto">
          <div className="text-2xl font-normal mb-10 text-primary">
            We'll reach out to you when your insurance carrier changes its policy for mail order
            prescriptions from our pharmacy.
          </div>
          <div
            style={{
              backgroundImage: "url(https://www.helloalpha.com/static/images/thumbs_up.png)",
              width: "71px",
              height: "152px",
            }}
            className="w-full h-full bg-no-repeat mb-6 mx-auto"
          />
        </div>
      </div>
    )
  );
};
