/** @format */

import React from "react";
import { generatePath, useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

import type { ServiceCategorySchema, ServiceTypeCategory } from "src/api";

import { Analytics } from "src/analytics";
import { PageWrapper } from "src/components/PageWrapper";
import { useHasEmployerBenefits } from "src/utils/hooks/useHasEmployerBenefits";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useStoreDispatch, useStoreState } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";
import { ServicePageHeader } from "src/v2/routes/YourCare/ServicePageHeader";
import { VisitGroupList } from "src/v2/routes/YourCare/VisitGroupSelection";

export const ExploreVisits = () => {
  const history = useHistory();
  useTopBar({ variant: "main", title: "Explore visits" });

  const dispatch = useStoreDispatch();
  const [loading, setLoading] = React.useState(true);
  const serviceCategories = useStoreState((state) => state.service.serviceCategories);
  const [serviceLinks, setServiceLinks] = React.useState<ServiceTypeCategory[]>([]);
  const hasSonjaCaresBenefits = useHasEmployerBenefits("sonja-cares");

  const user = useCurrentUser();

  const filteredGroups = serviceCategories.filter((visit) => {
    const sex = user.pii.sex;
    if (!sex) {
      return true;
    } else {
      let showServiceGroup = false;
      visit.service_type_category?.forEach((service) => {
        if (!service.gender || service.gender === sex) {
          showServiceGroup = true;
        }
      });
      return showServiceGroup;
    }
  });

  React.useEffect(() => {
    dispatch.service
      .fetchServiceCategories()
      .then((res: ServiceCategorySchema[]) => {
        const links: ServiceTypeCategory[] = [];
        res.forEach((cat) => {
          cat.service_type_category?.forEach((link) => {
            links.push(link);
          });
          setServiceLinks(links);
        });
      })
      .finally(() => setLoading(false));
    Analytics.trackContentImpression({
      name: "ExploreVisitsPage",
      piece: "ExploreVisitsPage",
      target: "PageLoad",
    });
  }, []);

  return (
    <PageWrapper cxPadding="px-6 py-8">
      <ServicePageHeader serviceLinks={serviceLinks} />
      {!loading ? (
        <>
          <VisitGroupList
            options={filteredGroups}
            onSelectVisitGroup={(group) => {
              Analytics.trackContentInteraction("click", {
                name: "ExploreVisitsPage",
                piece: group.key || "null",
                target: `/your-care/explore-visits/${group.key}`,
              });
              history.push(
                generatePath(`/your-care/explore-visits/:categoryKey`, { categoryKey: group.key }),
              );
            }}
          />
        </>
      ) : (
        <div className="mt-10 h-full w-full flex items-center justify-center">
          <ClipLoader color={"#A4B7B5"} loading={true} />
        </div>
      )}
    </PageWrapper>
  );
};

export default WithTopBarNavigation(ExploreVisits);
