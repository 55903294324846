/** @format */

import type { Action, Dispatch } from "easy-peasy";
import { createStore } from "easy-peasy";

import type { AddressModel } from "src/v2/models/address";
import type { BillingInfoModel } from "src/v2/models/billing-info";
import type { ChannelsModel } from "src/v2/models/channels";
import type { ConsentModel } from "src/v2/models/consent";
import type { HealthActionPlanModel } from "src/v2/models/healthActionPlan";
import type { MaintenanceModel } from "src/v2/models/maintenance";
import type { MedicationTrackingModel } from "src/v2/models/medication_tracking";
import type { MedicationModel } from "src/v2/models/medications";
import type { MembershipModel } from "src/v2/models/membership";
import type { OrdersModel } from "src/v2/models/orders";
import type { OutlinesModel } from "src/v2/models/outline";
import type { PaymentsModel } from "src/v2/models/payment";
import type { PostVisitSummariesModel } from "src/v2/models/post_visit_summaries";
import type { PrimaryCareProviderModel } from "src/v2/models/primary_care_provider";
import type { ProfileModel } from "src/v2/models/profile";
import type { RecordModel } from "src/v2/models/record";
import type { ServiceModel } from "src/v2/models/service";
import type { SnackBarModel } from "src/v2/models/snackBar";
import type { SubscriptionsModel } from "src/v2/models/subscriptions";
import type { UserModel } from "src/v2/models/user";
import type { UserLabsModel } from "src/v2/models/userLabs";

import { addressStore } from "src/v2/models/address";
import { billingInfoStore } from "src/v2/models/billing-info";
import { channelStore } from "src/v2/models/channels";
import { consentStore } from "src/v2/models/consent";
import { healthActionPlanStore } from "src/v2/models/healthActionPlan";
import { maintenanceStore } from "src/v2/models/maintenance";
import { medicationTrackingStore } from "src/v2/models/medication_tracking";
import { medicationStore } from "src/v2/models/medications";
import { membershipStore } from "src/v2/models/membership";
import { ordersStore } from "src/v2/models/orders";
import { outlineStore } from "src/v2/models/outline";
import { paymentStore } from "src/v2/models/payment";
import { postVisitSummariesStore } from "src/v2/models/post_visit_summaries";
import { primaryCareProviderStore } from "src/v2/models/primary_care_provider";
import { profileStore } from "src/v2/models/profile";
import { recordStore } from "src/v2/models/record";
import { serviceStore } from "src/v2/models/service";
import { snackBarModel } from "src/v2/models/snackBar";
import { subscriptionsStore } from "src/v2/models/subscriptions";
import { userStore } from "src/v2/models/user";
import { userLabsStore } from "src/v2/models/userLabs";

import type { ActionItemModel } from "./action_items";
import type { MembershipAlertsModel } from "./alerts";
import type { AuthModel } from "./auth";
import type { ChartModel } from "./charts";
import type { CheckInsModel } from "./checkins";
import type { ConsultsModel } from "./consults";
import type { LabModel } from "./labs";
import type { PubNubModel } from "./pubnub";
import type { SkuModel } from "./skus";
import type { TagsModel } from "./tags";
import type { TopBarModel } from "./topBar";

import { actionItemStore } from "./action_items";
import { membershipAlertsStore } from "./alerts";
import { authStore } from "./auth";
import { chartStore } from "./charts";
import { CheckInsStore } from "./checkins";
import { consultStore } from "./consults";
import { labStore } from "./labs";
import { pubNubStore } from "./pubnub";
import { skuStore } from "./skus";
import { tagStore } from "./tags";
import { topBarStore } from "./topBar";

export type CreateModelDispatch = Dispatch<CreateModel, Action<any>>;

export interface CreateModel {
  auth: AuthModel;
  addresses: AddressModel;
  billingInfo: BillingInfoModel;
  consults: ConsultsModel;
  checkins: CheckInsModel;
  healthActionPlan: HealthActionPlanModel;
  membership: MembershipModel;
  primaryCareProvider: PrimaryCareProviderModel;
  outlines: OutlinesModel;
  skus: SkuModel;
  subscriptions: SubscriptionsModel;
  user: UserModel;
  consent: ConsentModel;
  profile: ProfileModel;
  records: RecordModel;
  medications: MedicationModel;
  channels: ChannelsModel;
  labs: LabModel;
  tags: TagsModel;
  service: ServiceModel;
  pubnub: PubNubModel;
  actionItems: ActionItemModel;
  charts: ChartModel;
  payments: PaymentsModel;
  maintenance: MaintenanceModel;
  snacks: SnackBarModel;
  alerts: MembershipAlertsModel;
  topBar: TopBarModel;
  userLabs: UserLabsModel;
  orders: OrdersModel;
  postVisitSummaries: PostVisitSummariesModel;
  medicationTracking: MedicationTrackingModel;
}

export const store = createStore<CreateModel>({
  auth: authStore,
  addresses: addressStore,
  billingInfo: billingInfoStore,
  checkins: CheckInsStore,
  healthActionPlan: healthActionPlanStore,
  consults: consultStore,
  membership: membershipStore,
  primaryCareProvider: primaryCareProviderStore,
  outlines: outlineStore,
  skus: skuStore,
  subscriptions: subscriptionsStore,
  user: userStore,
  consent: consentStore,
  profile: profileStore,
  records: recordStore,
  medications: medicationStore,
  channels: channelStore,
  labs: labStore,
  tags: tagStore,
  service: serviceStore,
  pubnub: pubNubStore,
  actionItems: actionItemStore,
  charts: chartStore,
  payments: paymentStore,
  maintenance: maintenanceStore,
  snacks: snackBarModel,
  alerts: membershipAlertsStore,
  topBar: topBarStore,
  userLabs: userLabsStore,
  orders: ordersStore,
  postVisitSummaries: postVisitSummariesStore,
  medicationTracking: medicationTrackingStore,
});
