/** @format */

import React from "react";

import moment from "moment";

import type { ExtendedMaintenanceData } from "src/v2/models/maintenance";

import { formatText } from "src/content/contentUtils";
import maintenance from "src/content/maintenance";
import { MarkdownText } from "src/v2/components/MarkdownText";
import { useStoreActions, useStoreState } from "src/v2/models";

interface MaintenanceBannerProps {
  data: ExtendedMaintenanceData;
}

export const MaintenanceBanner = ({ data }: MaintenanceBannerProps): React.ReactElement | null => {
  const dismissed = useStoreState((state) => state.maintenance.bannerDismissed);
  const setBannerDismissed = useStoreActions((actions) => actions.maintenance.setBannerDismissed);
  const [countdown, setCountdown] = React.useState<number | undefined>();

  React.useEffect(() => {
    let intervalId: number | undefined = undefined;
    if (data.maintenanceWithin2Hours && data.startTime) {
      intervalId = window.setInterval(() => {
        setCountdown(moment(data.startTime).diff(moment(), "seconds"));
      }, 1000) as any;
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [data.maintenanceWithin2Hours]);

  if (dismissed || !data.startTime || !data.endTime) {
    // if we are dismissed, or if we don't have any time data, we have nothing we can show here.
    return null;
  }

  const message = data.maintenanceWithin2Hours
    ? maintenance.banner.shortly
    : data.maintenanceToday
      ? maintenance.banner.today
      : maintenance.banner.later;

  const startTime = moment(data.startTime);
  const endTime = moment(data.endTime);

  const formatCountdown = (duration?: number) => {
    const count = duration ?? startTime.diff(moment(), "seconds");
    const hours = Math.floor(count / 3600);
    const minutes = Math.floor((count - hours * 3600) / 60);
    const seconds = count - (minutes * 60 + hours * 3600);
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  const dynamicMessage = formatText(message, {
    date: startTime.format(maintenance.formatters.date),
    start: startTime.format(maintenance.formatters.time),
    end: endTime.format(maintenance.formatters.time),
    timer: formatCountdown(countdown),
  });

  return (
    <div className="flex-row flex justify-between items-center w-full bg-forest-100 p-4">
      <div className={"w-10"}>
        <img src={"/assets/information_filled_white.svg"} alt={"information icon"} />
      </div>
      <div className={"mx-2 w-full flex justify-center"}>
        <MarkdownText
          containerClassName="text-white text-sm text-center w-3/4"
          text={dynamicMessage}
        />
      </div>

      <div className={"w-10"}>
        <div onClick={() => setBannerDismissed()} className={"p-2"}>
          <img src={"/assets/close-light.svg"} alt={"close icon"} />
        </div>
      </div>
    </div>
  );
};
