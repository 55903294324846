/** @format */

import moment from "moment";

import type { InvalidType, IsInvalid } from "src/v2/utils/page/validators";

export interface ConditionValues {
  sex: string;
  dob: string;
  state: string;
  "consult-state": string;
}

export interface ConditionInvalid extends InvalidType {
  "consult-state"?: string;
  sex?: boolean;
  dob?: boolean;
}
export type IsInvalidCondition = IsInvalid<ConditionValues, ConditionInvalid>;
export const INVALID_TYPE_CONDITION = "condition";
export const INVALID_TYPE_HYPOTHYROIDISM_PRESCRIPTION = "invalid-hypothyroidism-prescription";
export const INVALID_TYPE_MED_NOT_COVERED = "med-not-covered";
export const INVALID_TYPE_WAITLIST = "waitlist";

export const IsDateOfBirthValid = (value: any, minAge = 18) => {
  if (!value) {
    return value;
  }
  const maxAge = 125;

  let dateOfBirth = value.split("-");
  if (dateOfBirth.length !== 3) {
    return value;
  }

  dateOfBirth = new Date(dateOfBirth[2], dateOfBirth[0] - 1, dateOfBirth[1]);
  const age = moment().diff(dateOfBirth, "years");
  return age >= minAge && age <= maxAge;
};

export const calculateAgeRange = (
  dob: string,
  minAge: number | undefined,
  maxAge: number | undefined,
): boolean => {
  if (!dob) {
    return false;
  }

  const dateOfBirthSplit = dob.split("-");
  if (dateOfBirthSplit.length !== 3) {
    return false;
  }

  const dateOfBirth = new Date(
    parseInt(dateOfBirthSplit[2]),
    parseInt(dateOfBirthSplit[0]) - 1,
    parseInt(dateOfBirthSplit[1]),
  );
  const age = moment().diff(dateOfBirth, "years");

  if (minAge === undefined) {
    minAge = 0;
  }
  if (maxAge === undefined) {
    maxAge = 125;
  }

  return minAge <= age && age <= maxAge;
};

export const isMinor: IsInvalidCondition = (values: ConditionValues) => {
  const state = values["consult-state"] || values.state;
  const minAge = state === "NE" ? 19 : state === "MS" ? 21 : 18;
  const dob = !IsDateOfBirthValid(values.dob, minAge);

  return {
    dob,
    minAge,
    dateOfBirth: values.dob,
    invalid: dob,
    invalidType: INVALID_TYPE_CONDITION,
  };
};

export const isPediatricsMinor: IsInvalidCondition = (values: ConditionValues) => {
  const parts = values.dob.split("-").map((numStr) => parseInt(numStr));
  const dateOfBirth = new Date(parts[2], parts[0] - 1, parts[1]);
  const sixMonths = new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 6,
    new Date().getDate(),
  );
  const dob = dateOfBirth > sixMonths;

  return {
    name: "isPediatricsMinor",
    dob,
    dateOfBirth: values.dob,
    invalid: dob,
    invalidType: INVALID_TYPE_CONDITION,
  };
};

interface IsMigraineDiagnosedValues {
  "feel-nauseated-or-sick-to-your-stomach": string;
  "light-bothered-you": string;
  "headache-limited-you": string;
}

export const isMigraineDiagnosed = (values: IsMigraineDiagnosedValues) => {
  const nauseatedOrSick = values["feel-nauseated-or-sick-to-your-stomach"];
  const lightBotheredYou = values["light-bothered-you"];
  const headacheLimitedYou = values["headache-limited-you"];
  const symptomList = [nauseatedOrSick, lightBotheredYou, headacheLimitedYou];
  let count = 0;

  symptomList.forEach((symptom) => {
    if (symptom === "yes") {
      ++count;
    }
  });

  return {
    name: "isMigraineDiagnosed",
    diagnosedWithMigraine: count >= 2 ? true : false,
    invalid: true,
    invalidType: INVALID_TYPE_CONDITION,
  };
};

interface IsMigraineQuestionnaireValues {
  "who-diagnosed": string;
}

interface HypothyroidismPrescriptionValues {
  "hypothyroidism-prescriptions": string;
}

export const isHypothyroidismPrescriptionInvalid = (values: HypothyroidismPrescriptionValues) => {
  const invalidPrescriptions =
    ["!none", "levothyroxine", "synthroid"].indexOf(values["hypothyroidism-prescriptions"]) === -1;
  return {
    invalid: invalidPrescriptions,
    modalClasses: "max-w-md bg-white",
    invalidType: INVALID_TYPE_HYPOTHYROIDISM_PRESCRIPTION,
  };
};

interface MedNotCoveredValues {
  payment_preference: string;
  "bc-medication-type-selection": string;
  "bc-medication-ring-brand": string;
}

export const medNotCovered = (values: MedNotCoveredValues) => {
  const choseInsurance = values["payment_preference"] === "insurance";
  const insuranceXulane = choseInsurance && values["bc-medication-type-selection"] === "patch";
  const insuranceNuvaring =
    choseInsurance &&
    values["bc-medication-type-selection"] === "ring" &&
    values["bc-medication-ring-brand"] === "sku_3";

  if (insuranceXulane) {
    return {
      invalid: insuranceXulane,
      modalClasses: "max-w-md bg-white",
      invalidType: INVALID_TYPE_MED_NOT_COVERED,
      name: "xulane",
    };
  }
  if (insuranceNuvaring) {
    return {
      invalid: insuranceNuvaring,
      modalClasses: "max-w-md bg-white",
      invalidType: INVALID_TYPE_MED_NOT_COVERED,
      name: "nuvaring",
    };
  }
  return {
    invalid: false,
    invalidType: INVALID_TYPE_MED_NOT_COVERED,
  };
};

export const NUTRITION_VALID_STATES = [
  "AK",
  "AL",
  "AR",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "IA",
  "ID",
  "IL",
  "KS",
  "KY",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "VA",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
];
