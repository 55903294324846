/** @format */

import React from "react";

import type { FormApi } from "final-form";

import type { OptionType } from "@alphamedical/components";

import type { DynamicOptionField } from "src/components/DynamicForm/types";
import type { FetchProfileResponse, StateSchema } from "src/v2/models/api_types";
import type { GroupSessionTopicSchema } from "src/v2/types/groupSessions";

import { ServicesService } from "src/api";
import { _GET } from "src/helpers/http";

export type OptionLoader = (
  form: FormApi,
  field: DynamicOptionField,
) => Promise<OptionType[]> | OptionType[];

export const getConditionOptions: OptionLoader = () =>
  ServicesService.getConditions().then((res) =>
    res.map((condition) => ({ value: condition.key, label: condition.name })),
  );

export const getActiveStates: OptionLoader = () =>
  _GET<StateSchema[]>("/states?supported=1").then((states) =>
    states.map((state) => ({ value: state.code, label: state.name })),
  );

export const getZoomSessionTopics: OptionLoader = (form) => {
  const { values } = form.getState();
  return _GET<GroupSessionTopicSchema[]>(
    `/group-topics?status=ACTIVE&topic_type=${values["topic_type"]}`,
  ).then((res) =>
    res.map((topic) => ({
      value: topic.slug,
      label: (
        <div className="inline">
          <div className="flex justify-between">
            <span>{topic?.topic}</span>
          </div>
        </div>
      ),
    })),
  );
};

export const getLatisseOptions: OptionLoader = (form, field) => {
  const defaultOptions = [...field.options];
  return _GET<FetchProfileResponse>(`/profile/me?include=winbacks`).then((res) => {
    if (
      res.winbacks &&
      res.winbacks.some((winback) => winback.active && winback.type === "latisse winback")
    ) {
      defaultOptions.push({ value: "sku_4_5", label: "Bimatoprost 0.03% (Generic Latisse) 5mL" });
    }
    return defaultOptions;
  });
};

export const getLatisseRefillFrequencies: OptionLoader = (form, field) => {
  const options = [...field.options];
  const { values } = form.getState();
  const preferredSize = values["preferred-size"];
  return options.filter((option) => {
    if (`${option.value}` === "30" && ["sku_4_1_2mon", "sku_4_12"].includes(preferredSize)) {
      return false;
    } else if (`${option.value}` === "90" && ["sku_1", "sku_4_13"].includes(preferredSize)) {
      return false;
    }
    return true;
  });
};

const CASH_PRICE_LABELS: Partial<Record<string, string>> = {
  sku_12: "$30 a month",
  sku_32: "$145 a month",
  sku_6: "$175 for 3 months",
  sku_60: "$75.00 for 3 months",
  sku_3_5_3mo_v1: "$15 a month",
  sku_3_8_3mo_v1: "$15 a month",
  sku_3: "$170 a month",
  sku_63: "$2,030 a year",
};

const INSURANCE_PRICE_LABELS: Partial<Record<string, string>> = {
  sku_12: "Monthly copay",
  sku_32: "Monthly copay",
  sku_6: "Copay every 3 months",
  sku_60: "Copay every 3 months",
  sku_3_5_3mo_v1: "Monthly copay",
  sku_3_8_3mo_v1: "Monthly copay",
  sku_3: "Monthly copay",
  sku_63: "Annual copay",
};

export const getBirthControlOptions: OptionLoader = (form, field) => {
  const paymentType = form.getState().values["payment_preference"];
  return field.options.map((option) => {
    const newOption = { ...option };
    if (paymentType === "cash" && CASH_PRICE_LABELS[option.value] != null) {
      newOption.label = `${option.label} - ${CASH_PRICE_LABELS[option.value]}`;
    } else if (paymentType === "insurance" && INSURANCE_PRICE_LABELS[option.value] != null) {
      newOption.label = `${option.label} - ${INSURANCE_PRICE_LABELS[option.value]}`;
    }
    return newOption;
  });
};

export const getPreventativeMigraineMedicationDosageOptions: OptionLoader = (form) => {
  const dosageOptionsMap: { [key: string]: string[] } = {
    "topiramate-tablet": ["25mg", "50mg", "75mg"],
    "propanolol-standard-release": ["80mg", "160mg", "240mg"],
    "propanolol-extended-release": ["80mg", "120mg", "160mg"],
    "timolol-tablet": ["10mg", "20mg", "30mg"],
    "frovatriptan-tablet": ["2.5mg"],
  };
  const { values } = form.getState();
  const dosages = dosageOptionsMap[values["current-preventative-prescriptions"]];

  return dosages.map((dosage) => ({ value: dosage, label: dosage }));
};

export const getPreventativeMigraineMedicationFrequencyOptions: OptionLoader = (form) => {
  const frequencyOptionsMap = {
    "topiramate-tablet": [
      { value: "one-tablet-daily", label: "Take one tablet daily" },
      { value: "one-tablet-twice-daily", label: "Take one tablet twice a day" },
      { value: "divided-doses-daily", label: "Take in divided doses daily" },
    ],
    "propanolol-standard-release": [
      { value: "divided-doses-daily", label: "Take in divided doses daily" },
    ],
    "propanolol-extended-release": [{ value: "one-tablet-daily", label: "Take one tablet daily" }],
    "timolol-tablet": [
      { value: "one-tablet-daily", label: "Take one tablet daily" },
      { value: "one-tablet-twice-daily", label: "Take one tablet twice a day" },
      { value: "divided-doses-daily", label: "Take in divided doses daily" },
    ],
    "frovatriptan-tablet": [
      {
        value: "one-tablet-twice-daily",
        label: "Take one tablet twice a day for up to 6 days a month",
      },
      {
        value: "one-tablet-three-times-a-day",
        label: "Take one tablet three times a day for up to 6 days a month",
      },
    ],
  };
  const { values } = form.getState();
  return frequencyOptionsMap[values["current-preventative-prescriptions"]];
};
