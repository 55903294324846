/** @format */

import React from "react";

import * as Sentry from "@sentry/react";

import { SimpleForm, TextField } from "@alphamedical/components";

import type { BadAddressDetails } from "src/api";

import { OrdersService } from "src/api";
import BackButton from "src/components/_buttons/BackButton";
import { Button } from "src/v2/components/Button";
import { useStoreDispatch } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";

interface ConfirmShippingAddressProps {
  setModalContent: (content: string) => void;
  item: BadAddressDetails;
  setShowPopup: (value: boolean) => void;
}

const ConfirmShippingAddress = ({
  setModalContent,
  item,
  setShowPopup,
}: ConfirmShippingAddressProps) => {
  const { id } = useCurrentUser();
  const dispatch = useStoreDispatch();
  const [disabled, setDisabled] = React.useState(false);

  const submit = (values: any) => {
    setDisabled(true);
    OrdersService.badAddressAutomation({
      userId: id,
      orderId: item.order_id.toString(),
      requestBody: { action: "confirm", note: values.note },
    })
      .then((res) => {
        setShowPopup(false);
        dispatch.profile.setHasSeenPopup(true);
        setDisabled(false);
      })
      .catch((err) => {
        Sentry.captureException(err);
        setShowPopup(false);
        dispatch.profile.setHasSeenPopup(true);
        setDisabled(false);
      });
  };

  return (
    <div>
      <BackButton onClick={() => setModalContent("message")} />
      <SimpleForm onSubmit={submit} hideDebugInfo={true}>
        {({ dirty }) => {
          return (
            <>
              <div>
                <h2 className="mt-6">Please leave us a note</h2>
                <TextField name={"note"} type="textarea" />
              </div>
              <Button disabled={!dirty || disabled}>Submit</Button>
            </>
          );
        }}
      </SimpleForm>
    </div>
  );
};

export default ConfirmShippingAddress;
