/** @format */

import React from "react";

import { isFunction } from "lodash";

import type { BadAddressDetails, SecondaryUserViewsUpdateUserPiiRequestBodySchema } from "src/api";
import type { FollowupItem } from "src/components/Popups/types";

import BadAddressDetailsPopup from "src/components/Popups/FollowupItemPopupsFile/BadAddressDetails/BadAddressDetailsPopup";
import { CashApprovalPopup } from "src/components/Popups/FollowupItemPopupsFile/CashApprovalPopup";
import { ClosedNetworkApprovalPopup } from "src/components/Popups/FollowupItemPopupsFile/ClosedNetworkApprovalPopup";
import { CopayApprovalPopup } from "src/components/Popups/FollowupItemPopupsFile/CopayApprovalPopup";
import CoverageNotEffectiveApproval from "src/components/Popups/FollowupItemPopupsFile/CoverageNotEffectiveApprovalPopup";
import InsuranceApprovalPopup, {
  renderInsuranceDetails,
} from "src/components/Popups/FollowupItemPopupsFile/InsuranceApprovalPopup";
import { MailOrderApprovalPopup } from "src/components/Popups/FollowupItemPopupsFile/MailOrderApprovalPopup";
import { MedNotCoveredPopup } from "src/components/Popups/FollowupItemPopupsFile/MedNotCoveredApproval";
import NonFormularyApprovalPopup from "src/components/Popups/FollowupItemPopupsFile/NonFormularyApprovalPopup";
import { OutOfStockPopup } from "src/components/Popups/FollowupItemPopupsFile/OutOfStockPopup";
import { RefillTooSoonApprovalPopup } from "src/components/Popups/FollowupItemPopupsFile/RefillTooSoonApprovalPopup";
import RXRefillApprovalPopup from "src/components/Popups/FollowupItemPopupsFile/RXRefillApprovalPopup";
import TermsAndConditions from "src/components/Popups/FollowupItemPopupsFile/TermsAndConditions";
import { serializeDependency } from "src/utils";
import { useStoreState } from "src/v2/models";

import NpsPopup from "./Nps/NpsPopup";

export interface FollowupItemPopupsProps {
  key?: number;
  followupItems: any;
}

export const FollowupItemPopups = ({ followupItems }: FollowupItemPopupsProps) => {
  const profile = useStoreState((state) => state.profile);
  const pii = profile && profile.profile && profile.profile.pii;
  const [showCopayApproval, setShowCopayApproval] = React.useState<boolean>(false);
  const [showConsent, setShowConsent] = React.useState<boolean>(false);
  const [showRxRefill, setShowRxRefill] = React.useState<boolean>(false);
  const [showClosedNetwork, setShowClosedNetwork] = React.useState<boolean>(false);
  const [showCoverageNotEffective, setShowCoverageNotEffective] = React.useState<boolean>(false);
  const [showRefillTooSoon, setShowRefillTooSoon] = React.useState<boolean>(false);
  const [showNonFormulary, setShowNonFormulary] = React.useState<boolean>(false);
  const [showMailOrder, setShowMailOrder] = React.useState<boolean>(false);
  const [showBadInsuranceDetails, setShowBadInsuranceDetails] = React.useState<boolean>(false);
  const [medNotCoveredFollowup, setMedNotCoveredFollowup] = React.useState<boolean>(false);
  const [showPrimaryPayer, setShowPrimaryPayer] = React.useState<boolean>(false);
  const [cashApproval, setCashApproval] = React.useState<boolean>(false);
  const [outOfStock, setOutOfStock] = React.useState<boolean>(false);
  const [showBadAddressDetails, setShowBadAddress] = React.useState(false);
  const [showNps, setShowNps] = React.useState<boolean>(false);

  const followUpCallback = {
    copay_needs_approval: setShowCopayApproval,
    consent_needs_approval: setShowConsent,
    rx_refill_approval: setShowRxRefill,
    closed_network_approval: setShowClosedNetwork,
    coverage_not_effective_approval: setShowCoverageNotEffective,
    refill_too_soon_approval: setShowRefillTooSoon,
    non_formulary_approval: setShowNonFormulary,
    mail_order_approval: setShowMailOrder,
    bad_insurance_details_approval: setShowBadInsuranceDetails,
    med_not_covered_followup: setMedNotCoveredFollowup,
    primary_payer_approval: setShowPrimaryPayer,
    cash_approval: setCashApproval,
    out_of_stock: setOutOfStock,
    bad_address_details: setShowBadAddress,
    show_nps: setShowNps,
  };

  React.useEffect(() => {
    followupItems.forEach((item: FollowupItem) => {
      const callback = followUpCallback[item.followup_type];
      if (isFunction(callback)) {
        callback(!profile.hasSeenPopup);
      }
    });
  }, [serializeDependency(followupItems, ["key"])]);

  return (
    <div>
      {followupItems.map((item: FollowupItem, idx: number) => {
        switch (item.followup_type) {
          case "bad_address_details":
            return (
              <BadAddressDetailsPopup
                key={idx}
                item={item as BadAddressDetails}
                showPopup={showBadAddressDetails}
                setShowPopup={setShowBadAddress}
                pii={pii as SecondaryUserViewsUpdateUserPiiRequestBodySchema}
              />
            );
          case "copay_needs_approval":
            return (
              <CopayApprovalPopup
                key={idx}
                item={item}
                showPopup={showCopayApproval}
                setShowPopup={setShowCopayApproval}
                pii={pii}
                profile={profile}
              />
            );
          case "consent_needs_approval":
            return (
              <TermsAndConditions
                key={idx}
                mainModalOpen={showConsent}
                closeMainModal={() => setShowConsent(false)}
                item={item}
              />
            );
          case "rx_refill_approval":
            return (
              <RXRefillApprovalPopup
                key={idx}
                item={item}
                showPopup={showRxRefill}
                setShowPopup={setShowRxRefill}
                pii={pii}
                profile={profile}
              />
            );
          case "closed_network_approval":
            return (
              <ClosedNetworkApprovalPopup
                key={idx}
                item={item}
                showPopup={showClosedNetwork}
                setShowPopup={setShowClosedNetwork}
                pii={pii}
                profile={profile}
              />
            );
          case "coverage_not_effective_approval":
            return (
              <CoverageNotEffectiveApproval
                key={idx}
                item={item}
                showPopup={showCoverageNotEffective}
                setShowPopup={setShowCoverageNotEffective}
                pii={pii}
                profile={profile}
              />
            );
          case "refill_too_soon_approval":
            return (
              <RefillTooSoonApprovalPopup
                key={idx}
                item={item}
                showPopup={showRefillTooSoon}
                setShowPopup={setShowRefillTooSoon}
                pii={pii}
                profile={profile}
              />
            );
          case "non_formulary_approval":
            return (
              <NonFormularyApprovalPopup
                key={idx}
                item={item}
                showPopup={showNonFormulary}
                setShowPopup={setShowNonFormulary}
                pii={pii}
                profile={profile}
              />
            );
          case "mail_order_approval":
            return (
              <MailOrderApprovalPopup
                key={idx}
                item={item}
                showPopup={showMailOrder}
                setShowPopup={setShowMailOrder}
                pii={pii}
                profile={profile}
              />
            );
          case "bad_insurance_details_approval":
            return (
              <InsuranceApprovalPopup
                key={idx}
                item={item}
                showPopup={showBadInsuranceDetails}
                setShowPopup={setShowBadInsuranceDetails}
                pii={pii}
                profile={profile}
              >
                <p className="mb-4">
                  Alpha Care Team here! Unfortunately, we submitted your order for{" "}
                  {item.medication_name} to be processed by our partner pharmacy and it looks like
                  the insurance information you provided is incomplete.
                </p>
                <p className="mb-4">
                  We will need you to please verify that the insurance information we have on file
                  for you is correct and up to date. We will need your pharmacy benefit information
                  for your insurance plan. This includes your Member ID, RxBIN, RxPCN and RxGroup
                  number. This is the information we currently have on file for you:
                </p>
                {renderInsuranceDetails(item)}
                <p className="mb-4">
                  Once this has been verified we will resubmit your order for processing. Please let
                  us know if you have any questions.
                </p>
              </InsuranceApprovalPopup>
            );
          case "primary_payer_approval":
            return (
              <InsuranceApprovalPopup
                key={idx}
                item={item}
                showPopup={showPrimaryPayer}
                setShowPopup={setShowPrimaryPayer}
                pii={pii}
                profile={profile}
                showContactInsurance={true}
              >
                <p className="mb-4">
                  Alpha Care Team here! Our partner pharmacy has notified us that your insurance
                  rejected the fill of your {item.medication_name} prescription because they claim
                  they are not your primary payer. This is the information we currently have on file
                  for you:
                </p>
                {renderInsuranceDetails(item)}
                <p className="mb-4">
                  If you have a different prescription insurance that is primary that is different
                  from the above information, you can select "Verify Now" or "Verify Later" below to
                  update the insurance information we have on file for you, and then we can have our
                  partner pharmacy reprocess your prescription fill.
                </p>
                <p className="mb-4">
                  However, if you believe this is an error, please select "I'll Contact my
                  Insurance" below and call the member services number on the back of your insurance
                  card and ask them to input in their system that they are your primary payer. Then
                  send us a message and we can have our pharmacy reprocess the claim.
                </p>
                <p className="mb-4">
                  <strong className="mr-2">Note:</strong>
                  If you verify later or contact your insurance we will not process your
                  prescription fill until we hear back from you.
                </p>
              </InsuranceApprovalPopup>
            );
          case "med_not_covered_followup":
            return (
              <MedNotCoveredPopup
                key={idx}
                item={item}
                showPopup={medNotCoveredFollowup}
                setShowPopup={setMedNotCoveredFollowup}
                pii={pii}
                profile={profile}
              />
            );
          case "cash_approval":
            return (
              <CashApprovalPopup
                key={idx}
                item={item}
                showPopup={cashApproval}
                setShowPopup={setCashApproval}
                pii={pii}
                profile={profile}
              />
            );
          case "out_of_stock":
            return (
              <OutOfStockPopup
                key={idx}
                item={item}
                showPopup={outOfStock}
                setShowPopup={setOutOfStock}
                pii={pii}
                profile={profile}
              />
            );
          case "show_nps":
            return <NpsPopup key={idx} showNps={showNps} setShowNps={setShowNps} />;

          default:
            return null;
        }
      })}
    </div>
  );
};
