/** @format */

import React from "react";
import { useHistory } from "react-router-dom";

import { PageWrapper } from "src/components/PageWrapper";
import * as PDB_ROUTES from "src/routes";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useStoreState } from "src/v2/models";

import { CareGuideHeaderSection } from "./CareGuideHeaderSection";
import { CareGuideNextStepsSection } from "./CareGuideNextStepsSection";
import { CareGuideResourceLinksSection } from "./CareGuideResourceLinksSection";

export const CareGuidePage = (): JSX.Element => {
  const { healthActionPlan, careGuide } = useStoreState((state) => state.healthActionPlan);

  const history = useHistory();
  const title = "Care Guide";

  if (!healthActionPlan) {
    // If we try to use a guide url directly in the browser and there is no HAP data, we should redirect to HAP main page
    history.push(PDB_ROUTES.HEALTH_ACTION_PLAN);
  }

  const quickFact = careGuide?.subSection?.find((guide) => guide.type === "QUICK_FACT");
  const guideBody = careGuide?.subSection?.find((guide) => guide.type === "BODY");

  useTopBar({
    title: careGuide?.group_section_title?.title || title,
    variant: "nested",
    showBackButton: true,
  });

  return (
    <>
      <PageWrapper cxPadding="pl-0 mb-12">
        <div className="max-w-2xl mx-auto">
          <CareGuideHeaderSection
            quickFactContent={quickFact?.subtext}
            guideBodyContent={guideBody?.subtext}
          />
          {careGuide?.subSection?.map((section) => {
            switch (section.type) {
              case "NEXT_STEPS":
                return <CareGuideNextStepsSection nextSteps={section} />;
              case "RESOURCE_LINKS":
                return <CareGuideResourceLinksSection moreResources={section} />;
              default:
                return <></>;
            }
          })}
        </div>
      </PageWrapper>
    </>
  );
};

export default WithTopBarNavigation(CareGuidePage);
