/** @format */

import React from "react";

import type { Braze, IBrazeGenders, IBrazeUser } from "src/utils/braze";
import type { FetchProfileResponse } from "src/v2/models/api_types";

import { braze as _braze, apiKey, defaultOptions, Genders } from "src/utils/braze";
import { useStoreState } from "src/v2/models";

export type BrazeContextType =
  | {
      isLoading: true;
      braze: null;
      error: null;
    }
  | {
      isLoading: false;
      braze: Braze;
      error: null;
    }
  | {
      isLoading: false;
      braze: null;
      error: Error;
    };

/**
 * Use a react context to contain global side effects
 */
const BrazeContext = React.createContext<BrazeContextType>({
  isLoading: true,
  braze: null,
  error: null,
});

export interface BrazeContextProviderProps {
  enableLogging?: boolean;
  initialSession?: Braze | null;
}

/**
 * Given a Braze user representation alongside our internal user representation,
 * ensure all available fields are synchronized and copied to Braze.
 *
 * note: Unchanged data is **not** posted over the wire.
 *
 * @param brazeUser
 * @param authenticatedUser
 */
export const synchronizeUserFields = (
  brazeUser: IBrazeUser,
  authenticatedUser: FetchProfileResponse,
) => {
  // email
  brazeUser.setEmail(authenticatedUser.email);

  // first name
  if (authenticatedUser.pii.first_name) {
    brazeUser.setFirstName(authenticatedUser.pii.first_name);
  }

  // last name
  if (authenticatedUser.pii.last_name) {
    brazeUser.setLastName(authenticatedUser.pii.last_name);
  }

  // DOB
  if (authenticatedUser.pii.dob) {
    // parse MM-DD-YYYY date format into integer parts
    const [month, day, year] = authenticatedUser.pii.dob.split("-").map((n) => parseInt(n, 10));
    brazeUser.setDateOfBirth(year, month, day);
  }

  // City/Country
  if (authenticatedUser.pii.address.city) {
    brazeUser.setHomeCity(authenticatedUser.pii.address.city);
    brazeUser.setCountry("USA");
  }

  // Gender
  if (authenticatedUser.pii.sex) {
    // sex is not the same as gender, so we must do the best we can with
    // the info we have available
    let gender: IBrazeGenders = Genders.OTHER;
    if (authenticatedUser.pii.sex === "male") {
      gender = Genders.MALE;
    } else if (authenticatedUser.pii.sex === "female") {
      gender = Genders.FEMALE;
    }
    brazeUser.setGender(gender);
  }

  // Phone
  if (authenticatedUser.pii.phone_number) {
    brazeUser.setPhoneNumber(authenticatedUser.pii.phone_number);
  }
};

/**
 * Braze context provider. Will dynamically import braze symbols and initialize global context.
 * State is cleaned up and removed on dismount. To skip the auto-import and initialization process, one can provide an initialized
 * braze instance directly by using the `initialSession` property.
 *
 * We must mount our provider below the global store, and it is recommended to place it above the routes to avoid
 * re-initializing on every page change.
 *
 * @param props
 * @returns
 */
export const BrazeContextProvider = ({
  initialSession = null,
  enableLogging = false,
  children,
}: React.PropsWithChildren<BrazeContextProviderProps>) => {
  const [braze, setBraze] = React.useState<Braze | null>(initialSession);
  const [error, setError] = React.useState<Error | null>();
  const authenticatedUser = useStoreState((state) => state.auth.authenticatedUser);

  // init braze if initialSession was not provided
  ////

  React.useEffect(() => {
    if (!braze) {
      try {
        // initialize the SDK overriding default logging
        _braze.initialize(apiKey, {
          ...defaultOptions,
          enableLogging: defaultOptions.enableLogging && enableLogging,
        });

        setBraze(_braze);
      } catch (_error) {
        setError(_error as Error);
      }
    }

    return () => {
      braze?.destroy();
    };
  }, [braze]);

  // Setup & connect Braze session
  React.useEffect(() => {
    if (!braze) return;

    // optionally show all in-app messages without custom handling
    // braze.automaticallyShowInAppMessages();

    // if you use Content Cards
    // braze.subscribeToContentCardsUpdates(function(cards) {
    //   // cards have been updated
    // });

    // Be sure to call `openSession` after `automaticallyShowInAppMessages`
    braze.openSession();
  }, [braze]);

  // run callback whenever the uuid changes
  React.useEffect(() => {
    if (!braze || !authenticatedUser) return;
    // link user account with Braze fingerprint using uuid (required)
    braze.changeUser(authenticatedUser.uuid);
  }, [braze, authenticatedUser?.uuid]);

  // define our context value from local state
  const value: BrazeContextType = React.useMemo(() => {
    if (error) {
      return {
        isLoading: false,
        braze: null,
        error,
      };
    }

    if (braze) {
      return {
        isLoading: false,
        braze,
        error: null,
      };
    }

    return {
      isLoading: true,
      braze: null,
      error: null,
    };
  }, [braze, error]);

  return <BrazeContext.Provider value={value}>{children}</BrazeContext.Provider>;
};

/**
 * React hook to safely interact with global Braze context
 *
 * @returns
 */
export const useBrazeContext = (): BrazeContextType => {
  const context = React.useContext(BrazeContext);
  if (!context) {
    throw new Error(`useBrazeContext must be used within a BrazeContextProvider.`);
  }

  return context;
};
