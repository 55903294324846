/** @format */

import React from "react";

import type { BaseFieldProps } from "@alphamedical/components/dist/DesignSystemV1/Forms/Fields/types";

import { TextFieldNew } from "@alphamedical/components";

interface PasswordFieldProps extends Partial<Omit<BaseFieldProps, "type">> {
  label?: string;
  name: string;
}

export const PasswordField: React.FC<PasswordFieldProps> = ({ label = "Password", ...props }) => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const showHideToggle = (
    <button
      type="button"
      onClick={() => {
        setShowPassword(!showPassword);
      }}
      className="text-sm text-cornflower-100 w-10 mr-4"
    >
      {showPassword ? "Hide" : "Show"}
    </button>
  );

  return (
    <TextFieldNew
      className="mb-4"
      autocomplete="new-password"
      label={label}
      type={showPassword ? "text" : "password"}
      trailingIconClass={showHideToggle}
      {...props}
    />
  );
};
