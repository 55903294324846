/** @format */

import type React from "react";

import { Form } from "react-final-form";

import { pick } from "lodash";

import { TextField } from "@alphamedical/components";

import type { CreateModel } from "src/v2/models/_create";

import { UsersService } from "src/api";
import { DynamicField } from "src/components/DynamicForm";
import { axiosErrorHandler } from "src/components/DynamicForm/utils";
import { SubmitButton } from "src/v2/components/Wizard/fields";
import { useStoreActions, useStoreState } from "src/v2/models";

export interface ParentPIIFormProps {
  callback: (values?: any) => void;
}

type FormValues = {
  first_name: string;
  last_name: string;
  sex: string;
  dob: string;
};

export const ParentPIIForm: React.FC<ParentPIIFormProps> = ({ callback }) => {
  const state = useStoreState((state) => state) as CreateModel;
  const actions = useStoreActions((actions) => actions.auth);
  const { authenticatedUser } = state.auth;

  return (
    <div>
      <h3 className="text-xl font-bold">Parent information</h3>
      {authenticatedUser && (
        <Form<FormValues>
          initialValues={pick(authenticatedUser.pii, "first_name", "last_name", "sex", "dob")}
          onSubmit={(values) => {
            return UsersService.updateUserPii({ userId: "me", requestBody: values })
              .then((res) => {
                actions.setAuthenticatedUserPii(res);
                callback && callback(res);
              })
              .catch(
                axiosErrorHandler("Something went wrong. Please try again or message support"),
              );
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="text-sm sm:text-base overflow-hidden">
                <div className="flex justify-between space-x-4">
                  <TextField name="first_name" placeholder="Parent First Name" required={true} />
                  <TextField name="last_name" placeholder="Parent Last Name" required={true} />
                </div>
                <p className="text-xs leading-normal mb-2">
                  *Name provided must match name on ID (and insurance card, if paying with
                  insurance)
                </p>
                <DynamicField
                  field={{
                    key: "dob",
                    required: true,
                    title: "What is your birth date?",
                    type: "text",
                    subtype: "date",
                    validate: "isValidDOB",
                  }}
                />
                <DynamicField
                  field={{
                    key: "sex",
                    required: true,
                    title: "Gender",
                    type: "radio",
                    options: [
                      {
                        value: "male",
                        label: "Male",
                      },
                      {
                        value: "female",
                        label: "Female",
                      },
                    ],
                  }}
                />
              </div>
              <SubmitButton />
            </form>
          )}
        />
      )}
    </div>
  );
};
