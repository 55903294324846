/** @format */

import type { AxiosRequestConfig, Method } from "axios";

import config from "src/config";
import { getAPIClient } from "src/helpers/axios";

const HTTP_METHOD_PUT = "PUT";
const HTTP_METHOD_POST = "POST";
const HTTP_METHOD_GET = "GET";

export const getTokenHeaderV2 = () => ({
  "Authentication-Token": localStorage.getItem("v2:auth:key:token"),
});

export const getRecurlyPublicKey = () => {
  return config.recurly.publicKey || "";
};

export const AXIOS_FETCH_PARAMS = <T = any>(params: AxiosRequestConfig) => {
  const client = getAPIClient({
    baseURL: config.apiUrl,
    getAPITokens: () => {
      return {
        legacyToken: localStorage.getItem("v2:auth:key:token"),
        authToken: localStorage.getItem("auth:token"),
        refreshToken: localStorage.getItem("auth:refresh-token"),
      };
    },
    onResponse: (res) => {
      if (res.headers["access-token"]) {
        localStorage.setItem("auth:token", res.headers["access-token"]);
      }
      if (res.headers["refresh-token"]) {
        localStorage.setItem("auth:refresh-token", res.headers["refresh-token"]);
      }
    },
  });
  return client.request<T>(params).then((response) => response.data);
};

const AXIOS_FETCH = <T = any>(
  url: string,
  method: Method = HTTP_METHOD_GET,
  data = {},
  headers = {},
  axiosConfig: Partial<AxiosRequestConfig> = {},
) => {
  return AXIOS_FETCH_PARAMS<T>({ ...axiosConfig, url, method, data, headers });
};

export const _POST = <T = any>(
  url: string,
  data = {},
  headers = {},
  axiosConfig: Partial<AxiosRequestConfig> = {},
) => AXIOS_FETCH<T>(url, HTTP_METHOD_POST, data, headers, axiosConfig);

export const _PUT = <T = any>(
  url: string,
  data = {},
  headers = {},
  axiosConfig: Partial<AxiosRequestConfig> = {},
) => AXIOS_FETCH<T>(url, HTTP_METHOD_PUT, data, headers, axiosConfig);

export const _GET = <T = any>(
  url: string,
  data = {},
  headers = {},
  axiosConfig: Partial<AxiosRequestConfig> = {},
) => AXIOS_FETCH<T>(url, HTTP_METHOD_GET, data, headers, axiosConfig);
