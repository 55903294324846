/** @format */

import type { RouteComponentProps } from "react-router-dom";

import React from "react";
import { withRouter } from "react-router-dom";

import type { FollowupItem } from "src/components/Popups/types";

import { AlphaModal } from "src/v2/components/AlphaModal";
import { Button } from "src/v2/components/Button";
import { formatPrice } from "src/v2/components/Payment/utils";
import { useStoreDispatch } from "src/v2/models";

export interface CoverageNotEffectiveApprovalProps extends RouteComponentProps {
  item: FollowupItem;
  showPopup: boolean;
  setShowPopup: (arg: boolean) => void;
  key?: number;
  pii?: any;
  profile?: any;
}

const CoverageNotEffectiveApproval = ({
  item: {
    followup_type,
    medication_name,
    order_id,
    condition_name,
    medication_cost,
    days_supply,
    is_one_time,
    condition_key,
  },
  showPopup,
  setShowPopup,
  pii,
  profile,
  history,
}: CoverageNotEffectiveApprovalProps) => {
  const [showConfirm, setShowConfirm] = React.useState<boolean>(false);
  const dispatch = useStoreDispatch();
  const payCashOrSelectNewCoverage = (approved: boolean) => {
    if (approved) {
      dispatch.profile.sendOrderApproval({
        approved,
        order_id,
        medication_name,
        followup_type,
        condition_name,
        days_supply,
        medication_cost,
      });
      setShowPopup(false);
      setShowConfirm(false);
    } else {
      dispatch.profile.sendOrderApproval({ approved, order_id, followup_type, medication_name });
      setShowConfirm(true);
    }
  };

  const monthsSupply = days_supply && Math.round(days_supply / 30);
  const formattedCost =
    condition_key === "skincare" || is_one_time
      ? medication_cost && formatPrice(medication_cost)
      : medication_cost &&
        `${formatPrice(medication_cost)} ${
          monthsSupply && monthsSupply > 1 ? `every ${monthsSupply} months` : "per month"
        }`;

  return (
    <div>
      <AlphaModal
        isOpen={showPopup}
        className="max-w-sm m-auto bg-white shadow-md"
        addPaddingForNavBar={true}
        cxPadding="p-8"
        onRequestClose={() => {
          !showConfirm && setShowPopup(false);
        }}
      >
        {!showConfirm && (
          <div className="flex flex-col text-16">
            <p className="font-semibold">
              Hi {(pii && pii.preferred_firstname) || (pii && pii.first_name)},
            </p>
            <p className="mb-4">
              We submitted your prescription for {medication_name} to be filled by our partner
              pharmacy and it was rejected. It looks like your insurance coverage has been
              terminated or isn’t effective until a later date.
            </p>
            <p className="mb-4">
              This may be because they have sent you a new insurance card, and you need to update
              your account with your new insurance information.
            </p>
            <p className="mb-4">
              If you don't have a new insurance card, then you can call the member services number
              located on the back of your insurance card and speak with an agent.
            </p>
            <p className="mb-4">
              Additionally, you are always welcome to proceed as a cash patient. As a cash patient,{" "}
              {medication_name} is {formattedCost}. Shipping is always free.
            </p>
            <p className="mb-6">
              Please let us know if you have any questions, we are here to help!
            </p>
            <div className="flex justify-content flex-col">
              <Button
                extraClassNames="btn btn-violator cursor-pointer"
                disabled={profile.postingApproval}
                onClick={() => payCashOrSelectNewCoverage(true)}
              >
                Pay Cash
              </Button>
              <Button
                extraClassNames={`
                                    text-center
                                    items-center cursor-pointer
                                  `}
                disabled={profile.postingApproval}
                textColor={"white"}
                onClick={() => payCashOrSelectNewCoverage(false)}
              >
                Come Back With New Insurance Info
              </Button>
            </div>
            {profile.error && <p className="text-red">{profile.error}</p>}
          </div>
        )}
        {showConfirm && (
          <div className="bg-white m-auto">
            <p className="text-18 text-center">
              We just sent you a message with the link where you will be able to submit images of
              your new insurance card.
            </p>
            <p className="text-16 text-center">
              <strong>
                Please note: <br />
              </strong>{" "}
              It may take up to 30 seconds for the message to arrive.
            </p>

            <div className="flex flex-col text-16">
              <Button
                extraClassNames=""
                disabled={profile.postingApproval}
                onClick={() => {
                  setShowPopup(false);
                  setShowConfirm(false);
                  history.push("/messages");
                  dispatch.profile.fetchProfile({ include: ["followup_items", "pii"] });
                }}
              >
                Take me to my messages
              </Button>
            </div>
          </div>
        )}
      </AlphaModal>
    </div>
  );
};

export default withRouter(CoverageNotEffectiveApproval);
