/** @format */

import type React from "react";

import { Paper } from "src/v2/designSystem";

import type { AccountLinksProps } from "src/routes/YourAccount/types";

import { PageWrapper } from "src/components/PageWrapper";
import SettingsLink from "src/components/Settings/SettingsLink";
import { useChildRedirect } from "src/utils/hooks";
import { useTopBar } from "src/utils/hooks/useTopBar";

interface AccountListItemPageProps {
  title: string;
  accountLinks: AccountLinksProps[];
}

const AccountListItemPage: React.FC<AccountListItemPageProps> = ({ title, accountLinks }) => {
  useChildRedirect();
  useTopBar({ title: title, variant: "nested", showBackButton: true });
  return (
    <PageWrapper cxPadding="px-0" fitToPageHeight>
      <Paper
        elevation={0}
        rounded={false}
        className="flex-1 mx-auto h-screen w-full max-w-2xl px-6 overflow-hidden pb-24"
      >
        <div className="pb-4" />
        {accountLinks.map((link) => (
          <SettingsLink arrowPrimary={true} key={link.title} url={link.url} title={link.title} />
        ))}
      </Paper>
    </PageWrapper>
  );
};

export default AccountListItemPage;
