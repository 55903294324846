/** @format */

import React from "react";
import { useForm, useFormState } from "react-final-form";

import { isNumber } from "lodash";

import {
  CLOT_ITEMS,
  FLOW_ITEMS,
  PAD_ITEMS,
  TAMPON_ITEMS,
} from "src/components/DynamicForm/Fields/CustomFields/BloodLossReporting/constants";
import ReportingSection from "src/components/DynamicForm/Fields/CustomFields/BloodLossReporting/ReportingSection";
import { AccordionBox } from "src/components/QuestionAccordionBox/AccordionBox";
import { Button } from "src/v2/components/Button";
import { scrollToTop } from "src/v2/utils/page/handlers";

type BloodLossReportingProps = {
  field: any;
};

const BloodLossReporting = (props: BloodLossReportingProps) => {
  const formState = useFormState();
  const form = useForm();
  const productsUsed = formState.values["products-used"];
  const usePads = productsUsed?.includes("pads");
  const useTampons = productsUsed?.includes("tampons");
  const days = parseInt(formState.values["period-days"]);
  const dayStored = localStorage.getItem("currentDay");
  const [currentDay, setCurrentDay] = React.useState<number>(dayStored ? parseInt(dayStored) : 1);
  const symptoms = formState.values["period-symptoms"];
  const hasClots = symptoms?.includes("blood_clots");
  const hasAccident = symptoms?.includes("flooding");
  if (typeof formState.values["blood_loss"] === "string") {
    // Convert string formState.values["blood_loss"] to JSON
    const jsonBloodLoss = JSON.parse(formState.values["blood_loss"].replace(/'/g, '"'));
    form.change("blood_loss", jsonBloodLoss);
  }

  const handleBackClick = (e: Event) => {
    setCurrentDay((curDay) => {
      if (curDay !== 1) {
        e.stopPropagation();
        localStorage.setItem("currentDay", (curDay - 1).toString());
        return curDay - 1;
      }
      return curDay;
    });
  };

  React.useEffect(() => {
    const backButton = document.getElementById("formBackButton");
    const backButtonMobile = document.getElementById("backButtonMobile");

    backButton?.addEventListener("click", handleBackClick);
    backButtonMobile?.addEventListener("click", handleBackClick);

    return () => {
      backButton?.removeEventListener("click", handleBackClick);
      backButtonMobile?.removeEventListener("click", handleBackClick);
    };
  }, []);

  const buttonClick = (e: any) => {
    if (currentDay !== days) {
      e.preventDefault();
      if (isNumber(currentDay)) {
        setCurrentDay(currentDay + 1);
        localStorage.setItem("currentDay", (currentDay + 1).toString());
        scrollToTop();
      }
    } else {
      localStorage.removeItem("currentDay");
    }
  };
  const content = props?.field?.content || {};

  return (
    <div>
      <h1 className={"text-lg"}>{`Day ${currentDay} of your period: ${
        content.pageTitle || "tell us about your flow."
      }`}</h1>
      <AccordionBox
        title={content.accordionTitle || "What if I haven't kept track?"}
        expandedAnswer={[
          content.accordionDescription ||
            "If you haven't been tracking this information regularly, you can answer these questions based on your memory.",
        ]}
      />
      <p className={"mt-10 font-bold mb-4"}>Day {currentDay}</p>
      {usePads && (
        <ReportingSection
          titleLeft={"What do your pads look like?"}
          titleRight={"Pads used"}
          periodItems={PAD_ITEMS}
          day={currentDay}
        />
      )}
      {useTampons && (
        <ReportingSection
          titleLeft={"What do your tampons look like?"}
          titleRight={"Tampons used"}
          periodItems={TAMPON_ITEMS}
          day={currentDay}
        />
      )}
      {hasClots && (
        <ReportingSection
          titleLeft={content.clotsTitle || "Do you notice blood clots"}
          titleRight={"Number of clots"}
          periodItems={CLOT_ITEMS}
          day={currentDay}
        />
      )}
      {hasAccident && (
        <ReportingSection
          titleLeft={content.otherSymptomsTitle || "Other heavy flow syptoms"}
          titleRight={"Number of times"}
          periodItems={FLOW_ITEMS}
          day={currentDay}
        />
      )}
      <Button onClick={buttonClick}>
        {currentDay === days ? "Finish" : `Go to Day ${currentDay + 1}`}
      </Button>
    </div>
  );
};

export default BloodLossReporting;
