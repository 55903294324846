/** @format */

import React from "react";

import { PageWrapper } from "src/components/PageWrapper";
import { useTopBar } from "src/utils/hooks/useTopBar";
import BmiCalculator from "src/v2/components/BmiCalculator/BmiCalculator";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";

const BmiCalculatorPage = () => {
  useTopBar({ showBackButton: true });

  return (
    <PageWrapper fitToPageHeight={true} cxPadding={"p-0"}>
      <BmiCalculator
        heading="Not sure if you need weight loss treatment?"
        subHeader="Calculate Your BMI"
        text="<p>Your BMI (body mass index) will help us to determine if prescription medication for weight loss is a viable option for you. </p>"
        backgroundColor="sand-light"
        foregroundColor="forest-100"
        submitButtonText="Calculate Your BMI"
      />
    </PageWrapper>
  );
};

export default WithTopBarNavigation(BmiCalculatorPage);
