/** @format */

import React from "react";

import { Paper } from "src/v2/designSystem";

import { PageWrapper } from "src/components/PageWrapper";
import SettingsLink from "src/components/Settings/SettingsLink";
import {
  ACCOUNT_PERSONAL_INFORMATION,
  MEDICAL_RECORDS,
  MEMBERSHIP_AND_PAYMENT,
  MY_CARE_TEAM_PAGE,
  SETTINGS,
} from "src/routes";
import { useChildRedirect } from "src/utils/hooks";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { ActivateBenefitsModal } from "src/v2/components/ActivateBenefits/ActivateBenefitsModal";
import { ActivateBenefitsSettingsButton } from "src/v2/components/ActivateBenefits/ActivateBenefitsSettingsButton";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useStoreDispatch, useStoreState } from "src/v2/models";

const Account = () => {
  useTopBar({ variant: "main", title: "Account" });
  const [isOpen, setIsOpen] = React.useState(false);
  useChildRedirect();
  const dispatch = useStoreDispatch();
  const { profile } = useStoreState((state) => state.profile);
  const activeBenefits =
    profile?.employer_benefits?.length && profile?.employer_benefits.some((e) => e.active === true);

  React.useEffect(() => {
    dispatch.profile.fetchEmployerBenefits();
  }, [profile?.employer_benefits?.length]);

  const links = [
    {
      title: "My information",
      url: ACCOUNT_PERSONAL_INFORMATION,
    },
    {
      title: "My care team",
      url: MY_CARE_TEAM_PAGE,
    },
    {
      title: "My health records",
      url: MEDICAL_RECORDS,
    },
    {
      title: "Plan & payment",
      url: MEMBERSHIP_AND_PAYMENT,
    },
    {
      title: "Activate benefits",
      url: "",
      modal: true,
    },
    {
      title: "Change your password",
      url: SETTINGS,
    },
    {
      title: "Log out",
      url: "/logout",
      callback: () => dispatch.auth.logout(),
    },
  ];

  return (
    <PageWrapper cxPadding="px-0">
      <Paper
        elevation={0}
        rounded={false}
        className="flex-1 mx-auto w-full max-w-2xl px-6 overflow-hidden pb-24"
      >
        <h5 className="text-center text-2xl font-normal pt-6 pb-2">Account</h5>
        {links.map((link) =>
          link.modal ? (
            <div
              key={link.title}
              onClick={() => {
                setIsOpen(true);
              }}
            >
              <ActivateBenefitsSettingsButton key={link.title} text={link.title} arrowPrimary />
            </div>
          ) : (
            <SettingsLink
              key={link.title}
              url={link.url}
              title={link.title}
              callback={link.callback}
              arrowPrimary
            />
          ),
        )}
        <ActivateBenefitsModal modalOpen={isOpen} setModalOpen={setIsOpen} />
      </Paper>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(Account);
