/** @format */

import React from "react";

interface LineProps {
  color?: string;
  mt?: string;
}

export const Line = ({ color = "grey-60", mt = "6" }: LineProps) => {
  return <div className={`border-b border-${color} w-full mt-${mt}`} />;
};
