/** @format */

import type { Action, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";

export interface Snack {
  type: SnackType;
  message: string;
  id: string;
  delay?: number;
}

export type SnackType = "success" | "warning" | "error" | "light" | "brand" | "primary";

export interface SnackBarModel {
  snackList: Snack[];

  addToList: Action<SnackBarModel, Snack>;
  removeSnack: Action<SnackBarModel, string>;
  addSnack: Thunk<SnackBarModel, Snack>;
}

export const snackBarModel: SnackBarModel = {
  snackList: [],

  addToList: action((state, snack) => {
    let inList = false;
    state.snackList.forEach((sn) => {
      if (sn.id === snack.id) {
        inList = true;
      }
    });
    if (!inList) {
      state.snackList.push(snack);
    }
  }),

  removeSnack: action((state, snackId) => {
    let index = -1;
    state.snackList.forEach((snack, idx) => {
      if (snack.id === snackId) {
        index = idx;
      }
    });
    if (index > -1) {
      state.snackList.splice(index, 1);
    }
  }),

  addSnack: thunk((actions, snack) => {
    return new Promise<void>((resolve) => {
      actions.addToList(snack);
      if (snack.delay) {
        window.setTimeout(() => {
          actions.removeSnack(snack.id);
          resolve();
        }, snack.delay * 1000);
      } else {
        resolve();
      }
    });
  }),
};
