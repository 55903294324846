/** @format */

import React from "react";
import { Field, useForm, useFormState } from "react-final-form";

import _ from "lodash";

import type { FetchProfileResponse } from "src/v2/models/api_types";
import type { Option } from "src/v2/models/record";

import { AllergiesService } from "src/api";

interface AllergySearchFieldProps {
  profile: FetchProfileResponse;
  field: any;
}

export const AllergySearchField = ({ profile, field }: AllergySearchFieldProps) => {
  const form = useForm();
  const formState = useFormState();
  const fieldRef = formState.values[field.key];
  const [allergyResults, setAllergyResults] = React.useState<Option[]>([]);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = React.useState<Option[]>([]);
  const [searchTerm, setSearchTerm] = React.useState<string>("");

  const fetchAllergy = (allergyName: string) => {
    AllergiesService.searchAllergies({ userId: profile.id, value: allergyName }).then(
      (allergies) => {
        const allergyOptions = allergies.map((allergy) => {
          return { value: allergy.allergenid, label: allergy.allergenname };
        });
        setAllergyResults(allergyOptions);
      },
    );
  };

  const debouncedFetch = _.debounce(fetchAllergy, 200);
  const onChange = (e: any) => {
    if (e.target.value.length > 1) debouncedFetch(e.target.value);
    setSearchTerm(e.target.value);
  };

  const chooseOption = (option: any) => {
    if (!selectedOptions.some((e) => e.value == option.value)) {
      setSelectedOptions([...selectedOptions, option]);
      form.change(`${field.key}`, [...selectedOptions, option]);
      setSearchTerm("");
    }
  };

  const removeOption = (option: any) => {
    const filteredOptions = selectedOptions.filter((e) => e.value !== option.value);
    setSelectedOptions(filteredOptions);
    form.change(field.key, filteredOptions);
  };

  return (
    <Field name={field.key}>
      {() => {
        return (
          <>
            <div
              className="flex items-center"
              style={{ backgroundColor: "#F8FAFC", marginBottom: "1.25rem" }}
            >
              <input
                name={field.key}
                placeholder="Search for an allergy"
                className={"w-full py-3 px-5"}
                onChange={onChange}
                value={searchTerm}
                style={{ backgroundColor: "transparent", outline: "none" }}
              />
              <img className="mr-3" src="/assets/search.svg" />
            </div>
            {searchTerm.length > 1 && (
              <>
                {allergyResults.length > 0 && (
                  <div className="-mt-6">
                    {allergyResults.map((result) => (
                      <div
                        key={result.label}
                        className="p-4 bg-white hover:bg-grey-1 shadow-lg cursor-pointer"
                        onClick={() => chooseOption(result)}
                      >
                        {result.label}
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
            {fieldRef && fieldRef.length > 0 && (
              <>
                {fieldRef.map((option: any, idx: number) => (
                  <div
                    className={`flex justify-between items-center pb-6 pt-3 ${
                      idx !== fieldRef.length - 1 && "border-b border-grey"
                    }`}
                    key={option.value}
                  >
                    {option.label}
                    <img
                      src="/assets/cancel.svg"
                      className="ml-4 cursor-pointer"
                      onClick={() => removeOption(option)}
                    />
                  </div>
                ))}
              </>
            )}
            {!isOpen && (
              <div style={{ marginBottom: "3rem", marginTop: 0 }}>
                <span
                  className="text-sm"
                  style={{ color: "#6BA5A5", fontWeight: 500 }}
                  onClick={() => {
                    setIsOpen(!isOpen);
                    form.change("display-allergies-other", "true");
                  }}
                >
                  Can't find my allergy?
                </span>
              </div>
            )}
          </>
        );
      }}
    </Field>
  );
};
