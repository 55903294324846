/** @format */

import React from "react";

import { Typography } from "@alphamedical/components";

import { RadioPdb } from "src/v2/components/Radio/RadioPdb";

interface RadioListProps {
  options: { label: string; key: string }[];
  selected?: string;
  onSelected: (key: string) => void;
}

export const RadioList = (props: RadioListProps) => (
  <div className="space-y-6">
    {props.options.map(({ key, label }) => (
      <div
        onClick={() => props.onSelected(key)}
        key={key}
        className={"flex items-center pb-4 border-b border-grey-60"}
      >
        <div>
          <RadioPdb className="pr-2" selected={props.selected === key} />
        </div>
        <Typography variant="h4">{label}</Typography>
      </div>
    ))}
  </div>
);
