/** @format */

import React from "react";
import { useHistory } from "react-router-dom";

import { Typography } from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import type { OrderSchema } from "src/api";

import { BaseCard } from "src/components/Cards/BaseCard";
import { Loader } from "src/components/Loader";
import { PageWrapper } from "src/components/PageWrapper";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { OrderAccordion } from "src/v2/components/OrderAccordion";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useStoreDispatch, useStoreState } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";

export const Orders = () => {
  useTopBar({ variant: "nested", title: "Orders", showBackButton: true });
  const dispatch = useStoreDispatch();
  const user = useCurrentUser();
  const [orderMap, setOrderMap] = React.useState<{ [key: string]: OrderSchema[] }>({});
  const [conditions, setConditions] = React.useState<string[]>([]);
  const history = useHistory();

  const { fetchingOrders, orders } = useStoreState((state) => state.orders);
  React.useEffect(() => {
    dispatch.orders.fetchOrders({ user_id: user.id });
  }, []);

  const addOrderToMap = (conditiion: string, order: any, map: { [key: string]: OrderSchema[] }) => {
    if (conditiion in map) {
      map[conditiion].push(order);
    } else {
      map[conditiion] = [order];
    }
  };

  React.useEffect(() => {
    const _conditions: string[] = [];
    const ordersMap = {};
    orders.forEach((order) => {
      if (order.condition) {
        if (!_conditions.includes(order.condition)) {
          _conditions.push(order.condition);
        }
        addOrderToMap(order.condition, order, ordersMap);
      } else {
        if (!conditions.includes("Other")) {
          _conditions.push("Other");
        }
        addOrderToMap("Other", order, ordersMap);
      }
    });
    setOrderMap(ordersMap);
    setConditions(_conditions);
  }, [orders.length]);

  return (
    <>
      <Loader show={!orders || fetchingOrders}></Loader>
      {orders && !fetchingOrders && (
        <PageWrapper cxMaxWidth="max-w-2xl px-6">
          {!orders ||
            (orders.length === 0 && (
              <div className={"mt-8"}>
                <BaseCard
                  icon={
                    <img
                      src="/assets/doctor-icon-no-background.svg"
                      alt="Doctor Icon"
                      style={{ width: 36, marginRight: "20px" }}
                    />
                  }
                  title={"Prescription"}
                  description={
                    "Answer some questions about your condition so your Alpha provider can create a personalized treatment plan for you."
                  }
                >
                  <ButtonPdb
                    onClick={() => {
                      history.push("/your-care/explore-visits");
                    }}
                  >
                    Start a visit
                  </ButtonPdb>
                </BaseCard>
                <div className={`p-4 mt-8 rounded bg-grey-10`}>
                  <Typography variant={"body1"} className={"text-grey-160"}>
                    You don’t have any active orders. Start a visit to place an order.
                  </Typography>
                </div>
              </div>
            ))}
          {conditions.map((condition) => {
            return (
              <>
                <Typography className={"text-black mt-8 mb-6"} variant={"h3"} key={condition}>
                  {condition}
                </Typography>
                {orderMap[condition].map((order) => {
                  return <OrderAccordion key={order.id} order={order}></OrderAccordion>;
                })}
              </>
            );
          })}
        </PageWrapper>
      )}
    </>
  );
};

export default WithTopBarNavigation(Orders);
