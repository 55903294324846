/** @format */
import React from "react";

import type { User } from "src/v2/models/user";

import { _GET } from "src/helpers/http";
import { useStoreState } from "src/v2/models";

interface Medication {
  active: boolean;
  added_by: User;
  consult: {
    completed_at: string;
    id: number;
  };
  created_at: string;
  id: number;
  is_otc: boolean;
  name: string;
  patient: User;
  source: string;
}

const CurrentMedications = (props: any) => {
  const authUser = useStoreState((state) => state.auth.authenticatedUser);
  const [medications, setMedications] = React.useState<Medication[]>([]);

  React.useEffect(() => {
    _GET(`/users/${authUser?.id}/medications`)
      .then((res) => {
        setMedications(res);
      })
      .catch((err) => console.log(err));
  }, [authUser?.id]);

  return (
    <>
      {medications.length > 0 && (
        <div>
          <b>Note: </b>
          These are the medications we currently have on file for you.
        </div>
      )}
      <ul>
        {medications.map((med) => {
          return <li key={med.name}>{med.name}</li>;
        })}
      </ul>
      {medications.length > 0 && (
        <p>
          If you're using any additional medications to treat your symptoms, please select from the
          options below, otherwise select "None".
        </p>
      )}
      <p>Select All That Apply</p>
    </>
  );
};

export default CurrentMedications;
