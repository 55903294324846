/** @format */
import type React from "react";

import { AlphaModal } from "src/v2/components/AlphaModal";
import { Button } from "src/v2/components/Button";

interface PaxlovidInfoModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const PaxlovidInfoModal: React.FC<PaxlovidInfoModalProps> = ({ isOpen, onRequestClose }) => (
  <AlphaModal
    shouldCloseOnOverlayClick={true}
    className="max-w-sm m-auto text-center shadow-lg bg-white"
    onRequestClose={() => onRequestClose()}
    isOpen={isOpen}
    cxPadding="p-0"
    closeIconSize="md"
  >
    <div>
      <h1
        className={"text-left pl-5 py-4 text-primary font-bold"}
        style={{ backgroundColor: "#FFF9F7" }}
      >
        Learn more about PAXLOVID™
      </h1>
      <div className="relative">
        <div className="text-left px-5 py-8">
          Under an Emergency Use Authorization (EUA), the FDA has authorized the use of PAXLOVID™
          for the treatment of mild-to-moderate COVID-19 in adults and children 12 years of age and
          older weighing at least 88 pounds (40 kg), with a positive COVID-19 test, and who are at
          high risk for progression to severe COVID-19 including hospitalization or death.
          PAXLOVID™ must be taken within the first five days that symptoms appear. Your Alpha
          Provider may refer you to an in-person clinical visit if they are concerned that your
          symptoms require additional care.
          <br />
          <br />
          PAXLOVID™ is investigational because it is still being studied. There is limited
          information about the safety and effectiveness of using PAXLOVID™ to treat people with
          mild-to-moderate COVID-19.
          <br />
          <br />
          <strong>Important:</strong> Your Alpha Provider cannot guarantee that PAXLOVID™ is
          available at your local or preferred pharmacy. Due to limited supplies of PAXLOVID™ , we
          recommend checking if a pharmacy near you has the medication in stock before you begin
          your visit with us. Please note that the one-time medical fee is non-refundable.
        </div>
        <div className="bg-white w-full px-4 pb-5 sticky bottom-0">
          <Button onClick={onRequestClose}>Go Back</Button>
        </div>
      </div>
    </div>
  </AlphaModal>
);

export default PaxlovidInfoModal;
