/** @format */

import React from "react";
import { useHistory, useParams } from "react-router-dom";

import type { RecordModel } from "src/v2/models/record";

import { DynamicForm } from "src/components/DynamicForm/DynamicForm";
import { PageWrapper } from "src/components/PageWrapper";
import { ScreeningToolNav } from "src/components/ScreeningTools/ScreeningToolNav";
import { ScreeningToolResults } from "src/components/ScreeningTools/ScreeningToolResults";
import { Button } from "src/v2/components/Button";
import { useStoreDispatch, useStoreState } from "src/v2/models";

const SCORE_MAP: Partial<Record<string, number>> = {
  "several-days": 1,
  "over-half-the-days": 2,
  "nearly-every-day": 3,
};

const calculateAssessmentScore = (values: Record<string, string>) => {
  return Object.keys(values).reduce((total, fieldKey) => {
    return total + (SCORE_MAP[values[fieldKey]] || 0);
  }, 0);
};

const SCREENING_TOOLS = {
  anxiety: {
    title: "Anxiety Screening Tool",
    type: "ANXIETY",
    outlineKey: "anxiety-assessment",
    intro: {
      description:
        "This free screening tool can help you understand if you may show signs of anxiety and should seek medical consultation.",
    },
    assessmentLabel: "Anxiety Disorder",
  },
  depression: {
    title: "Depression Screening Tool",
    type: "DEPRESSION",
    outlineKey: "depression-assessment",
    intro: {
      description:
        "This free screening tool can help you understand if you may show signs of depression and should seek medical consultation.",
    },
    assessmentLabel: "Depression",
  },
};

interface PathParams {
  type: "anxiety" | "depression";
}

export const Assessment = () => {
  const dispatch = useStoreDispatch();
  const history = useHistory();
  const params = useParams<PathParams>();
  const content = SCREENING_TOOLS[params.type];
  const screeningToolType = params.type;

  const [loading, setLoading] = React.useState(true);
  const [showTool, setShowTool] = React.useState(false);

  const { assessmentScores } = useStoreState((state) => state.records) as RecordModel;
  const scores = assessmentScores.find((record) => record.assessment_type === content.type);
  const hasExistingScores = !!scores && scores.scores.length > 0;

  const loadScores = () => {
    setLoading(true);
    dispatch.records.fetchAssessmentScores().finally(() => {
      setLoading(false);
    });
  };

  React.useEffect(() => {
    loadScores();
  }, []);

  React.useEffect(() => {
    if (!loading && !hasExistingScores) {
      history.replace({ pathname: `/tool/${screeningToolType}/intro` });
    } else if (!loading && hasExistingScores) {
      history.replace({ pathname: `/tool/${screeningToolType}/results` });
    }
  }, [loading, hasExistingScores]);

  return (
    <PageWrapper title={content.title} cxMaxWidth="max-w-2xl">
      {!showTool && !hasExistingScores && (
        <>
          <p>{content.intro.description}</p>
          <Button bgColor="violator" onClick={() => setShowTool(true)}>
            Get Started
          </Button>
        </>
      )}
      {!showTool && !!scores && (
        <ScreeningToolResults
          scores={scores}
          assessmentLabel={content.assessmentLabel}
          onStartNewAssessment={() => {
            setShowTool(true);
          }}
        />
      )}
      {showTool && (
        <DynamicForm
          outlineKey={content.outlineKey}
          onPageChange={({ page }) => {
            history.push({ pathname: `/tool/${screeningToolType}/${page.key}` });
          }}
          allowFormAutoSubmit={true}
          onPageSubmit={(submitArgs) => {
            if (submitArgs.hasNextPage) {
              submitArgs.nextPage();
            } else {
              const score = calculateAssessmentScore(submitArgs.values);
              dispatch.records
                .saveAssessmentScore({
                  assessmentType: submitArgs.outline.condition,
                  assessmentScore: score,
                })
                .then(() => {
                  loadScores();
                  setShowTool(false);
                });
            }
          }}
        >
          {({ page }) => (
            <>
              <ScreeningToolNav />
              {page}
            </>
          )}
        </DynamicForm>
      )}
    </PageWrapper>
  );
};
