/** @format */

export const parseBloodPressure = (value: string) => {
  if (!value) {
    return value;
  }
  // First remove all non digits or forward slashes
  value = value.replace(/[^\s\d/]/g, "");
  return value;
};
