/** @format */

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleQuestion,
  faLock,
  faHome as farHome,
  faMessages as farMessages,
  faNotesMedical as farNotesMedical,
  faTruckFast as farTruckFast,
  faUser as farUser,
  faTriangleExclamation,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faCapsules,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faClipboardListCheck,
  faCommentMedical,
  faCommentPlus,
  faHandsHoldingHeart,
  faHome,
  faKitMedical,
  faMessages,
  faMoneyCheckDollar,
  faNotesMedical,
  faStethoscope,
  faSuitcaseMedical,
  faTruckFast,
  faUser,
  faVials,
} from "@fortawesome/pro-solid-svg-icons";

library.add(
  faCapsules,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleQuestion,
  faClipboardListCheck,
  faCommentMedical,
  faCommentPlus,
  faHandsHoldingHeart,
  faHome,
  faKitMedical,
  faLock,
  faMessages,
  faMoneyCheckDollar,
  faNotesMedical,
  farHome,
  farMessages,
  farNotesMedical,
  farTruckFast,
  farUser,
  faStethoscope,
  faSuitcaseMedical,
  faTruckFast,
  faUser,
  faVials,
  faTriangleExclamation,
);
