/** @format */

import React from "react";
import { Link, useHistory } from "react-router-dom";

import { generateUUID } from "pubnub";

import { ActivateBenefitsContent } from "src/content/v2/routes/ActivateBenefits";
import { Button } from "src/v2/components/Button";
import { useStoreActions, useStoreState } from "src/v2/models";
import { useCurrentUser } from "src/v2/models/profile";

const Confirm = () => {
  const eligibleIndividualFound = useStoreState(
    (state) => state.auth.eligibilityLookupValues?.eligibleIndividualFound,
  );

  React.useEffect(() => {
    return () => {
      // force refresh on unmount so user gets redirected to health check-in
      window.location.href = "/my-cases";
    };
  });

  return (
    <div>
      {eligibleIndividualFound ? (
        <div className="text-forest-120 mx-auto max-w-lg px-5 flex flex-col">
          <div className="py-6">
            <h3 className="mb-8 w-full text-xl">
              {ActivateBenefitsContent.confirmationPage.title}
            </h3>
            <p>{ActivateBenefitsContent.confirmationPage.body}</p>
          </div>
          <Button
            href={ActivateBenefitsContent.confirmationPage.link.to}
            bgColor="primary"
            hrefStyles="w-full flex justify-center"
            extraClassNames="rounded"
          >
            {ActivateBenefitsContent.confirmationPage.link.text}
          </Button>
        </div>
      ) : (
        <>
          <p className="text-xl text-center mb-8">
            {ActivateBenefitsContent?.confirmationPage?.errorState?.title}
          </p>
          <Link
            to={ActivateBenefitsContent?.confirmationPage?.errorState?.link?.to}
            className="text-cornflower-100 text-center flex justify-center font-bold"
          >
            {ActivateBenefitsContent?.confirmationPage?.errorState?.link?.text}
          </Link>
        </>
      )}
    </div>
  );
};

export default Confirm;
