/** @format */

import React from "react";

import { isEmpty, keys, map, mapValues, maxBy, orderBy } from "lodash";

import { Typography } from "@alphamedical/components";

import type { AlphaMedication, GenericMedicationSchema } from "src/api";

import { PageWrapper } from "src/components/PageWrapper";
import { MedicationRecordAccordion } from "src/components/RecordAccordion/MedicationRecordAccordion";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useStoreDispatch } from "src/v2/models";

type MedicationMap = Partial<Record<string, AlphaMedication[]>>;

export function isAlphaMedication(
  medication: GenericMedicationSchema,
): medication is AlphaMedication {
  return medication.source === "alpha";
}

export const ActivePrescriptions = () => {
  useTopBar({
    variant: "nested",
    title: "Active prescriptions",
    subtitle: "",
    showBackButton: true,
  });
  const dispatch = useStoreDispatch();
  const [medications, setMedications] = React.useState<MedicationMap>({});

  const loadMedications = async () => {
    const filteredMeds = await dispatch.medications.fetchMedications();
    // group medications by condition
    let conditionMedications = filteredMeds.reduce<MedicationMap>((previousValue, currentValue) => {
      const currentKey = (currentValue as AlphaMedication).condition?.name || "Other";
      return {
        ...previousValue,
        [currentKey]: [...(previousValue[currentKey] || []), currentValue],
      };
    }, {});

    // sort within groups by most recent meds
    conditionMedications = mapValues(conditionMedications, (meds) =>
      orderBy(meds, ["created_at"], ["desc"]),
    );

    // filter out duplicate skus
    const uniqueSkus: Partial<Record<string, boolean>> = {};
    conditionMedications = mapValues(conditionMedications, (meds) => {
      const filteredMeds: AlphaMedication[] = [];
      map(meds, (med: AlphaMedication) => {
        const skuId = med.sku?.sku_id;
        if (skuId && !uniqueSkus[skuId]) {
          filteredMeds.push(med);
          uniqueSkus[skuId] = true;
        }
      });
      return filteredMeds;
    });

    setMedications(conditionMedications);
  };

  React.useEffect(() => {
    loadMedications();
  }, []);

  // sort the condition groups based on the most recent item in each group
  const mostRecentMeds: Partial<Record<string, string>> = {};
  map(medications, (meds: AlphaMedication[], condition) => {
    mostRecentMeds[condition] = maxBy(meds, (medication) => medication.created_at)?.created_at;
  });

  let sortedGroups: string[] = orderBy(
    keys(medications),
    (condition) => mostRecentMeds[condition],
    "desc",
  ) as string[];

  // remove groups with no prescriptions
  sortedGroups = sortedGroups.filter((group) => !isEmpty(medications?.[group]));

  return (
    <PageWrapper cxPadding="px-6">
      <div className="mt-4">
        {sortedGroups.map((conditionName) => (
          <div key={conditionName}>
            <Typography className="mb-6" variant="h3">
              {conditionName}
            </Typography>
            {(medications[conditionName] || []).map((medication) => {
              return (
                isAlphaMedication(medication) && (
                  <MedicationRecordAccordion key={medication.id} medication={medication} />
                )
              );
            })}
          </div>
        ))}
        {isEmpty(sortedGroups) && (
          <Typography variant="body1" className="bg-grey-10 p-4 mb-8">
            No active prescriptions to show.
          </Typography>
        )}
      </div>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(ActivePrescriptions);
