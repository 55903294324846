/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PartnerPrescriptionOrderAttachmentRequestBodySchema } from '../models/PartnerPrescriptionOrderAttachmentRequestBodySchema';
import type { PartnerPrescriptionRequestRequestBodySchema } from '../models/PartnerPrescriptionRequestRequestBodySchema';
import type { PartnerUpdatePartnerConfigRequestBodySchema } from '../models/PartnerUpdatePartnerConfigRequestBodySchema';
import type { PartnerUpdatePrescriptionRequestRequestBodySchema } from '../models/PartnerUpdatePrescriptionRequestRequestBodySchema';
import type { PartnerVerifyPartnerTokenRequestBodySchema } from '../models/PartnerVerifyPartnerTokenRequestBodySchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PartnerApiService {

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static getPartnerConfig(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/partner/configuration',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static updatePartnerConfig({
        requestBody,
    }: {
        requestBody: PartnerUpdatePartnerConfigRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/partner/configuration',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static prescriptionRequest({
        requestBody,
    }: {
        requestBody: PartnerPrescriptionRequestRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/partner/order',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static updatePrescriptionRequest({
        externalOrderId,
        requestBody,
    }: {
        externalOrderId: string,
        requestBody: PartnerUpdatePrescriptionRequestRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/partner/order/{external_order_id}',
            path: {
                'external_order_id': externalOrderId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static prescriptionOrderAttachment({
        externalOrderId,
        requestBody,
    }: {
        externalOrderId: string,
        requestBody: PartnerPrescriptionOrderAttachmentRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/partner/order/{external_order_id}/attachment',
            path: {
                'external_order_id': externalOrderId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response format defined
     * @throws ApiError
     */
    public static verifyPartnerToken({
        userId,
        requestBody,
    }: {
        /**
         * The id of the user to query
         */
        userId: (number | 'me'),
        requestBody: PartnerVerifyPartnerTokenRequestBodySchema,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/{user_id}/partner/token-verify',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
