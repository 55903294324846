/** @format */

import React from "react";

import type { Consultation } from "src/v2/types/consultation";

interface EligibilityFormHypothyroidismPrescriptionProps {
  closeModal: any;
  condition: string;
  invalid: any;
  saveValues: any;
  moveForward: any;
  values: any;
  consult: Consultation;
}

export const EligibilityFormHypothyroidismPrescription = (
  props: EligibilityFormHypothyroidismPrescriptionProps,
) => {
  const [text, setText] = React.useState("");
  const submitExternalPharmacy = async (text: string) => {
    if (text) {
      props.values["preferred_pharmacy"] = "external";
      props.values["pharmacy_info"] = text;
      await props.saveValues(props.values);
      props.closeModal();
      props.moveForward();
    }
  };
  return (
    <div className="flex flex-col">
      <h1 style={{ top: "-18px", width: "315px" }} className="text-2xl md:relative font-bold">
        Local Pharmacy Only
      </h1>
      <div style={{ top: "-14px" }} className={`w-full md:relative border border-grey-2 mb-4`} />
      <div className={`text-base mb-2`}>
        We're sorry but the pharmacies in our network don't deliver refills of that medication.
        Instead, we can make it available for pickup at your local pharmacy.
      </div>
      <label>Please provide your pharmacy name and address of your local pharmacy.</label>
      <textarea
        className="border w-full border-primary-20 border-primary-20 focus:border-primary p-3 my-4 outline-none"
        onChange={(e) => setText(e.target.value)}
      />
      <button
        disabled={text.length === 0}
        className="btn btn-primary"
        onClick={() => submitExternalPharmacy(text)}
      >
        Next
      </button>
    </div>
  );
};
