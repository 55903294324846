/** @format */

import { omit, startsWith } from "lodash";

import type { InsuranceSchema } from "src/api";

import type { InsuranceFormValues } from "./InsuranceForm";

export const CARRIER_OPTIONS = [
  {
    label: "Blueshield",
    value: "Blueshield",
  },
  {
    label: "United Healthcare/Optum",
    value: "United Healthcare/Optum",
  },
  {
    label: "Cigna",
    value: "Cigna",
  },
  {
    label: "Anthem",
    value: "Anthem",
  },
  {
    label: "Aetna",
    value: "Aetna",
  },
  {
    label: "Other",
    value: "other",
  },
];

export const RELATIONSHIP_OPTIONS = [
  // "0" = "Not Specified", used as a default by the api
  // "1" = "Cardholder", inferred from the primary cardholder checkbox
  {
    label: "Spouse",
    value: "2",
  },
  {
    label: "Child",
    value: "3",
  },
  {
    label: "Other Dependent",
    value: "4",
  },
  {
    label: "Student Dependent",
    value: "5",
  },
  {
    label: "Disabled Dependent",
    value: "6",
  },
  {
    label: "Adult Dependent",
    value: "7",
  },
  {
    label: "Significant Other",
    value: "8",
  },
];

export const validation = (values: InsuranceFormValues) => {
  const errors: any = {};
  if (!values.cardholder_id) {
    errors.cardholder_id = "Required";
  }
  if (!values.carrier) {
    errors.carrier = "Required";
  }
  if (
    values.carrier == "other" &&
    (values.carrier_other == undefined || values.carrier_other == "")
  ) {
    errors.carrier_other = "Required";
  }
  return errors;
};

export const isPrimaryCardholder = (insurance: InsuranceSchema) => {
  return (
    !insurance.cardholder_first_name &&
    !insurance.cardholder_last_name &&
    insurance.relationship_code === "1"
  );
};

export const processInsuranceFormValues = (values: InsuranceFormValues = {}) => {
  let processedValues = values;
  if (values.primary_cardholder_checkbox && values.primary_cardholder_checkbox?.length > 0) {
    processedValues.cardholder_first_name = "";
    processedValues.cardholder_last_name = "";
    processedValues.relation_to_cardholder = "";
    processedValues.relationship_code = "1";
  }
  if (
    values.is_federally_funded_plan_checkbox &&
    values.is_federally_funded_plan_checkbox?.length > 0
  )
    processedValues.is_federally_funded_plan = true;
  else processedValues.is_federally_funded_plan = false;

  processedValues = omit(
    processedValues,
    "primary_cardholder_checkbox",
    "is_federally_funded_plan_checkbox",
  );
  const omitUnchangedPhoto = (side: string) => {
    if (!startsWith(values[side], "data:")) {
      processedValues = omit(processedValues, side);
    }
  };
  omitUnchangedPhoto("front_photo");
  omitUnchangedPhoto("back_photo");
  return processedValues;
};
