/** @format */

import type { PropsWithChildren } from "react";

import React from "react";
import Sheet from "react-modal-sheet";

import { BottomSheetActions } from "src/components/BottomSheet/BottomSheetActions";
import { BottomSheetHeader } from "src/components/BottomSheet/BottomSheetHeader";

import "./BottomSheet.scss";

export interface BottomSheetProps {
  open: boolean;
  onClose: () => void;
  title: string;
  hideHeader?: boolean;
}

export const BottomSheet = (props: PropsWithChildren<BottomSheetProps>) => (
  <Sheet
    style={{ zIndex: 1000 }}
    className="mx-auto md:w-7/12 lg:w-5/12"
    detent="content-height"
    disableDrag
    isOpen={props.open}
    onClose={props.onClose}
  >
    <Sheet.Container style={{ zIndex: 1000 }}>
      {!props.hideHeader && (
        <div className="px-6">
          <BottomSheetHeader title={props.title} onClick={props.onClose} />
        </div>
      )}
      <Sheet.Content>
        <Sheet.Scroller>
          <div className="px-6" style={{ maxHeight: "500px" }}>
            {props.children}
          </div>
        </Sheet.Scroller>
      </Sheet.Content>
    </Sheet.Container>
    <Sheet.Backdrop />
  </Sheet>
);

BottomSheet.Actions = BottomSheetActions;
BottomSheet.Header = BottomSheetHeader;
