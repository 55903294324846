/**
 * /*
 * NOTICE: This is a copy of https://github.com/mvasin/react-div-100vh.
 * The NPM pkg unfortunately breaks on build because of this library: "jest-get-type": "22.4.3"
 * It's only used to get the type, which seems like overkill, so I've replicated the code for
 * now, and will create a PR on the github repo
 * -- ZA
 *
 * @format
 */

import React from "react";

import convertStyle from "./convertStyle";
import getWindowHeight from "./getWindowHeight";

export default class Div100vh extends React.Component<any, any> {
  state = {
    style: {},
  };

  // On mount and window resize converts rvh values to px (if there are any).
  // Also, adds `height: 100rvh` if height is not specified at all
  updateStyle = () => {
    const convertedStyle = convertStyle(this.props.style, getWindowHeight());
    this.setState({ style: convertedStyle });
  };

  componentDidMount() {
    this.updateStyle();
    window.addEventListener("resize", this.updateStyle);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateStyle);
  }

  render() {
    return <div {...this.props} style={this.state.style} />;
  }
}
