/** @format */
import moment from "moment/moment";

import type { Consultation } from "src/v2/types/consultation";
import type { ConsultationWithUrgent } from "src/v2/utils/returnVisitUtils";

const STI_TESTING_KIT_KEY = "sti-testing-kit";

export const isVisitContinuing = (visit: Consultation, excludeDenied = false) =>
  ["APPROVED", "NEEDS_FOLLOWUP", ...(!excludeDenied ? ["DENIED"] : [])].includes(visit.status) ||
  ((visit.subscription.condition.key === STI_TESTING_KIT_KEY || visit.isA1C) &&
    ["COMPLETED"].includes(visit.status));

export const isVisitPending = (visit: Consultation) =>
  ["READY_FOR_REVIEW", "COMPLETED"].includes(visit.status) &&
  visit.subscription.condition.key !== STI_TESTING_KIT_KEY;

const isVisitDenied = (visit: Consultation) => visit.status === "DENIED";

export const isVisitStarted = (visit: Consultation) => visit.status === "STARTED";

export const isVisitActive = (visit: Consultation) =>
  isVisitContinuing(visit) || isVisitPending(visit) || isVisitDenied(visit);

export const isUrgentVisit = (visit: ConsultationWithUrgent) => visit.is_urgent;

export const isVisitPast = (consult: Consultation) =>
  moment().diff(moment(consult.completed_at), "days") > 30;

export const groupActiveVisits = (sortedVisits: Consultation[]) => {
  const actives = sortedVisits.filter((visit) => isVisitContinuing(visit, true));
  const pending = sortedVisits.filter((visit) => isVisitPending(visit));
  const deniedVisits = sortedVisits.filter((visit) => isVisitDenied(visit));

  return [...actives, ...pending, ...deniedVisits];
};

export const sortConsults = (consults: Consultation[]) => {
  const map: Record<string, boolean> = {};
  return consults
    ?.filter((consult) => {
      if (consult.status === "APPROVED") {
        if (consult.consult_condition && map[consult.consult_condition.name]) {
          return false;
        } else {
          map[consult.consult_condition.name] = true;
          return true;
        }
      } else {
        return ["FOLLOWUP"].indexOf(consult.consult_type) === -1;
      }
    })
    ?.sort((a, b) => {
      const date1 = new Date(a.updated_at).getTime();
      const date2 = new Date(b.updated_at).getTime();
      return date2 - date1;
    });
};

export const sortAndGroupVisits = (visits: Consultation[]) => {
  const sortedVisits = sortConsults(visits);

  const activeVisits = sortedVisits.filter((visit) => isVisitContinuing(visit));
  const pendingVisits = sortedVisits.filter((visit) => isVisitPending(visit));
  const startedVisits = sortedVisits.filter((visit) => isVisitStarted(visit));

  return [...activeVisits, ...pendingVisits, ...startedVisits];
};
