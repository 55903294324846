/** @format */

import type React from "react";

import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";

import { Typography } from "@alphamedical/components";

export interface RecordValuesListProps {
  icon: IconProp;
  id: string;
  text: string | React.ReactNode;
}

export const RecordValuesList = (props: { rowData: RecordValuesListProps[] }) => (
  <>
    {props.rowData.map((value, index) => (
      <div
        key={value.id}
        className={cn("flex items-center", index != props.rowData.length - 1 && "pb-5")}
      >
        <div className="w-6 h-6 mr-6 text-grey-160 flex items-center justify-center">
          <FontAwesomeIcon size="lg" icon={value.icon} />
        </div>
        {typeof value.text === "string" ? (
          <Typography variant="h5">{value.text}</Typography>
        ) : (
          <div>{value.text}</div>
        )}
      </div>
    ))}
  </>
);
