/** @format */

import React from "react";

import type { TopBarNavigationProps } from "src/v2/designSystem";

import { TopBarNavigation } from "src/v2/designSystem";

import { HelpBottomSheet } from "src/v2/components/WithTopBarNavigation/HelpBottomSheet";

type TopBarNavigationPdbProps = Omit<TopBarNavigationProps, "onHelpCallback">;

export const TopBarNavigationPdb = (props: TopBarNavigationPdbProps) => {
  const [showHelp, setShowHelp] = React.useState(false);

  return (
    <>
      <TopBarNavigation {...props} onHelpCallback={() => setShowHelp(true)} />
      {showHelp && <HelpBottomSheet onClose={() => setShowHelp(false)} />}
    </>
  );
};
