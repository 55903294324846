/** @format */

import React from "react";

import moment from "moment";

import type { RecurlyInvoiceSchema } from "src/api";

import { RecurlyService } from "src/api";
import { openUrl } from "src/utils/download";
import { useCurrentUser } from "src/v2/models/profile";

interface InvoiceDetailsPageProps {
  selectedInvoice: RecurlyInvoiceSchema;
}

const InvoiceDetailsPage = ({ selectedInvoice }: InvoiceDetailsPageProps) => {
  const currentUser = useCurrentUser();
  const getRecurlyInvoiceAsPDF = () => {
    RecurlyService.getRecurlyInvoiceAsPdf({
      userId: currentUser.id,
      invoiceId: selectedInvoice.uuid,
    }).then((response) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      openUrl(response.url!);
    });
  };
  return (
    <div className="grid justify-items-center">
      <div className="flex justify-center bg-grey-lightest w-full">
        <p className="text-primary text-3xl font-medium py-6">{selectedInvoice.amount}</p>
      </div>
      <div className="w-full mb-8 mx-4">
        <div
          className="flex justify-between w-full py-6 px-4"
          style={{ borderBottom: "1px solid #DFE1E1" }}
        >
          <p className="text-grey-160">Paid on</p>
          <p>{moment(selectedInvoice.paid_on).format("l")}</p>
        </div>
      </div>
      <div className="w-full flex justify-center">
        <button
          className=" text-white bg-primary px-32 py-4"
          style={{ borderRadius: "4px" }}
          onClick={() => getRecurlyInvoiceAsPDF()}
        >
          Download Invoice
        </button>
      </div>
    </div>
  );
};

export default InvoiceDetailsPage;
