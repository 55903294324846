/** @format */

export enum ChartType {
  WEIGHT = "weight",
  BMI = "bmi",
  WAIST_CIRCUMFERENCE = "waist-circumference",
  DEPRESSION = "phq9_depression", // consult score
  ANXIETY = "gad7_anxiety", // consult score
  DEPRESSION_ASSESSMENT = "DEPRESSION", // free assessment
  ANXIETY_ASSESSMENT = "ANXIETY", // free assessment
  PPD_ASSESSMENT = "POSTPARTUM_DEPRESSION", // free assessment
}
