/** @format */

import React from "react";

import { Typography } from "@alphamedical/components";

import BmiCategory from "./BmiCategory";

interface DisplayBmiProps {
  bmi: number;
  height: number;
}

const BMI_CATEGORIES = [
  {
    label: "underweight",
    range: "Below 18.5",
    bgColor: "sand-40",
    ctaText: "Learn more",
    link: "https://www.helloalpha.com/weight-loss",
    copy: "We do not prescribe weight loss medication to underweight individuals. If you would like to improve your nutrition and eating habits, connect with a Registered Dietitian at Alpha.",
  },
  {
    label: "healthy",
    range: "18.5 - 24.9",
    bgColor: "sand-60",
    ctaText: "Learn more",
    link: "https://www.helloalpha.com/weight-loss",
    copy: "Prescription weight loss medication is not intended for individuals with a BMI in the healthy range. If you would like to improve your nutrition and eating habits, connect with a Registered Dietitian at Alpha.",
  },
  {
    label: "overweight",
    range: "25.0 - 30.0",
    bgColor: "sand-80",
    ctaText: "Learn more",
    link: "https://www.helloalpha.com/weight-loss",
    copy: "Prescription weight loss medication is generally not recommended for individuals with a BMI in the overweight range depending on other health factors. To lose weight by improving your diet and eating habits, connect with a Registered Dietitian at Alpha.",
  },
  {
    label: "obese",
    range: "Above 30.0",
    bgColor: "#F3C4B5",
    ctaText: "Get Treated",
    link: "/consultation/weight-loss",
    copy: "Prescription weight loss medication can be helpful for individuals with a BMI in the obese range and may be appropriate for you. Connect with an Alpha Provider today. ",
  },
];

function getCategory(number: number) {
  if (isNaN(number)) {
    return 4;
  } else if (number < 18.5) {
    return 0;
  } else if (number < 25) {
    return 1;
  } else if (number < 30) {
    return 2;
  } else {
    return 3;
  }
}

const DisplayBmi = ({ bmi, height }: DisplayBmiProps) => {
  const lowHealthyWeight = Math.round((18.5 * height * height) / 703);
  const highHealthWeight = Math.round((24.9 * height * height) / 703);
  return (
    <div className="mt-12">
      <Typography variant="subtitle1">YOUR BMI</Typography>
      <Typography variant="h1" className="text-3xl font-bold mb-6">
        {bmi.toFixed(1)}
      </Typography>
      <p className="text-center w-full mb-6">
        This means that you are in the <strong>{BMI_CATEGORIES[getCategory(bmi)].label}</strong>{" "}
        range for your height and weight.
      </p>
      <div className="flex flex-wrap">
        {BMI_CATEGORIES.map(({ label, bgColor, range }) => (
          <BmiCategory key={label} bgColor={bgColor} label={label} range={range} />
        ))}
      </div>
      <p className="mt-10 mb-2 text-center w-full">
        A healthy weight for your height would be from{" "}
        <strong>
          {lowHealthyWeight} to {highHealthWeight} pounds
        </strong>
        .
      </p>
      <>
        <p className="mb-6 text-center text-md w-full">{BMI_CATEGORIES[getCategory(bmi)].copy}</p>
        <a
          href={BMI_CATEGORIES[getCategory(bmi)].link}
          className="flex"
          target="_blank"
          rel="noreferrer"
        >
          <button className="text-white text-base p-4 w-full text-center bg-violator-100">
            {BMI_CATEGORIES[getCategory(bmi)].ctaText}
          </button>
        </a>
      </>
    </div>
  );
};

export default DisplayBmi;
