/** @format */

import React from "react";
import { useHistory } from "react-router-dom";

import {
  faCircleInfo,
  faEnvelope,
  faTriangleExclamation,
} from "@fortawesome/pro-regular-svg-icons";

import { SimpleForm, TextFieldNew } from "@alphamedical/components";

import { ChatService } from "src/api";
import { BottomSheet } from "src/components/BottomSheet/BottomSheet";
import config from "src/config";
import { useGetChannels } from "src/utils/hooks";
import { IconMenuList } from "src/v2/components/Menus/IconMenuList";
import { useStoreDispatch } from "src/v2/models";

interface ReportProblemValues {
  report: string;
}

export const HelpBottomSheet = (props: { onClose: () => void }) => {
  const [isFillReport, setIsFillReport] = React.useState(false);
  const dispatch = useStoreDispatch();
  const { channels } = useGetChannels();
  const history = useHistory();
  const supportChannel = channels?.find((channel) => channel.channel_type === "SUPPORT");

  const onSubmit = async (values: ReportProblemValues) => {
    try {
      await ChatService.reportIssue({
        requestBody: { text: values.report, url: window.location.href },
      });
      dispatch.snacks.addSnack({
        type: "success",
        message: "Success! Our team received your report.",
        id: "send-problem-report-success",
      });
    } catch (e) {
      dispatch.snacks.addSnack({
        type: "error",
        message: "Something went wrong. Try again or contact support.",
        id: "send-problem-report-error",
      });
    } finally {
      props.onClose();
    }
  };

  const faqUrl = config.faqHelpCenterUrl;
  return (
    <BottomSheet
      open
      onClose={props.onClose}
      title={!isFillReport ? "How can we help?" : "Report problem"}
    >
      {!isFillReport ? (
        <IconMenuList
          tools={[
            ...(faqUrl
              ? [
                  {
                    icon: faCircleInfo,
                    iconClassName: "text-cornflower-100",
                    label: "Review FAQs",
                    onClick: () => open(faqUrl),
                  },
                ]
              : []),
            {
              icon: faTriangleExclamation,
              iconClassName: "text-cornflower-100",
              label: "Report a technical issue",
              onClick: () => setIsFillReport(true),
            },
            {
              icon: faEnvelope,
              iconClassName: "text-cornflower-100",
              label: "Contact Alpha Support",
              onClick: () =>
                history.push(
                  supportChannel?.name ? `/messages/${supportChannel?.name}` : "/messages",
                ),
            },
          ]}
        />
      ) : (
        <SimpleForm<ReportProblemValues> hideDebugInfo onSubmit={onSubmit}>
          {({ valid, handleSubmit }) => (
            <>
              <TextFieldNew
                required
                type="textarea"
                name="report"
                placeholder="Describe your problem"
              />
              <BottomSheet.Actions
                showBack
                onBack={() => setIsFillReport(false)}
                onConfirm={() => handleSubmit()}
                disabled={!valid}
                buttonText="Submit"
              />
            </>
          )}
        </SimpleForm>
      )}
    </BottomSheet>
  );
};
