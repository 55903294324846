/** @format */

import React from "react";
import { Link } from "react-router-dom";

import _ from "lodash";

import type { Address } from "src/api";
import type { FetchProfileResponse } from "src/v2/models/api_types";
import type { Consultation } from "src/v2/types/consultation";

import { PageWrapper } from "src/components/PageWrapper";
import config from "src/config";
import { Button } from "src/v2/components/Button";
import { CompletionSteps, getDefaultSteps } from "src/v2/components/Page/Complete/CompletionPage";
import { useStoreDispatch } from "src/v2/models";

interface DiabetesCompletionPageProps {
  consult: Consultation;
  firstName?: string;
  info?: string;
  showMessagesLink?: boolean;
  heading?: string;
  headingFontSize?: string;
  cxMaxWidth?: string;
  profile: FetchProfileResponse;
  isExternalPharmacy: boolean;
  selectedPharmacyId: any;
  addresses: Address[];
}

const DiabetesCompletionPage: React.FC<DiabetesCompletionPageProps> = ({
  consult,
  firstName,
  info,
  heading,
  headingFontSize,
  cxMaxWidth,
  showMessagesLink = true,
  profile,
  isExternalPharmacy,
  selectedPharmacyId,
  addresses,
}) => {
  const dispatch = useStoreDispatch();
  const [curPage, setCurPage] = React.useState<number>(0);
  const [pages, setPages] = React.useState<string[]>([
    "whats-next",
    "which-diabetes-test",
    "try-cgm-free",
  ]);
  const isSynchronous = consult.is_synchronous;

  React.useEffect(() => {
    dispatch.channels.fetchChannels();
  }, []);

  const _heading = heading || (firstName ? `Thanks, ${firstName}!` : "Thank you!");
  const whereTested = consult?.answers["diabetes-where-tested"];
  const whichTest = consult?.answers["which-diabetes-test"];
  const tryCgm = consult?.answers["try-cgm-free"];

  const clickNext = () => {
    if (curPage < pages.length - 1) setCurPage(curPage + 1);
    else setCurPage(0);
  };
  React.useEffect(() => {
    const pageList = ["whats-next"];
    if (
      whichTest &&
      (whichTest.value == "at-home-self-collection" ||
        (whichTest.value != "at-home-self-collection" && whichTest.value != "none"))
    ) {
      pageList.push("which-diabetes-test");
    }
    if (tryCgm && tryCgm.value) {
      pageList.push("try-cgm-free");
    }
    setPages(pageList);
  }, [Object.keys(consult).length]);
  React.useEffect(() => {
    dispatch.addresses.fetchAddresses({
      id: consult.answers?.["provide-address-other-lab"]?.value,
    });
  }, [consult.answers?.["provide-address-other-lab"]?.value]);
  return (
    <PageWrapper
      fitToPageHeight={true}
      cxMaxWidth={`${cxMaxWidth || "sm:max-w-2xl"} w-full mx-auto pt-4`}
      cxPadding="p-0"
    >
      <div className="h-full px-6 pb-6">
        {/* Page 1 */}
        {pages[curPage] == "whats-next" && (
          <div>
            <div className="py-12 text-xl">
              <h1 className={` ${headingFontSize || "text-2xl font-bold"} leading-tight`}>
                {_heading}
              </h1>
              {info && <p>{info}</p>}
            </div>
            <div>
              <h4 className="text-sm font-semibold text-grey-dark pb-6 uppercase">
                {isSynchronous ? "What happens next:" : "Here's what's next"}
              </h4>
              <CompletionSteps
                steps={getDefaultSteps(
                  isExternalPharmacy,
                  consult.subscription.condition.name,
                  consult.is_synchronous,
                  isExternalPharmacy
                    ? _.find(addresses, (address) => address.id === selectedPharmacyId)
                    : profile.pii.address,
                )}
              />
            </div>
          </div>
        )}
        {/* Page 2 Lab Test @ Quest or elsewhere */}
        {whichTest &&
          whichTest.value != "at-home-self-collection" &&
          whichTest.value != "none" &&
          pages[curPage] == "which-diabetes-test" && (
            <div>
              <div className="py-12 text-xl">
                <h1 className={` ${headingFontSize || "text-2xl font-bold"} leading-tight`}>
                  Your lab tests
                </h1>
              </div>
              <div>
                <CompletionSteps
                  steps={[
                    whereTested && whereTested.value == "id-like-to-go-to-a-quest-lab-near-me"
                      ? {
                          imgSrc: "/assets/lab.svg",
                          description:
                            "Because you chose to go to a Quest lab near you, if tests are needed, your Alpha Provider will submit a request on your behalf ",
                        }
                      : {
                          imgSrc: "/assets/lab.svg",
                          description:
                            "Because you chose to go to a lab of your choice, if tests are needed, your Alpha Provider will submit a request to this lab on your behalf:",
                          address: _.find(
                            addresses,
                            (address) =>
                              address.id === consult.answers["provide-address-other-lab"]?.value,
                          ),
                        },
                  ]}
                />
              </div>
            </div>
          )}
        {/* Page 2 At Home Test */}
        {whichTest &&
          whichTest.value == "at-home-self-collection" &&
          pages[curPage] == "which-diabetes-test" && (
            <div>
              <div className="py-12 text-xl">
                <h1 className={` ${headingFontSize || "text-2xl font-bold"} leading-tight`}>
                  Steps for your at-home test kit
                </h1>
              </div>
              <div>
                <CompletionSteps
                  steps={[
                    {
                      imgSrc: "/assets/shipping-icon.svg",
                      description: "Our lab partner, Molecular Labs, will send you an A1C test kit",
                    },
                    {
                      imgSrc: "/assets/sti-kit-icon.svg",
                      description:
                        "Follow the instructions included in your kit to collect your sample, then use the prepaid label and packaging to send it to our lab partner for testing",
                    },
                    {
                      imgSrc: "/assets/sms-icon.svg",
                      description: `We will notify you when your sample has been tested and your lab results are available. Our medical team will reach out within ${config.responseTime} to discuss treatment if necessary`,
                    },
                  ]}
                />
              </div>
            </div>
          )}
        {/* Page 3 CGM */}
        {pages[curPage] === "try-cgm-free" && (
          <div>
            <div className="py-12 text-xl">
              <h1 className={` ${headingFontSize || "text-2xl font-bold"} leading-tight`}>
                Steps for your Freestyle Libre 2 (Continuous Glucose Monitor){" "}
              </h1>
            </div>
            <div>
              <CompletionSteps
                steps={[
                  {
                    imgSrc: "/assets/shipping-icon.svg",
                    description:
                      "Your Alpha Care Team will provide details about how to set up your device and answer any questions you might have ",
                    link: (
                      <a
                        className="font-bold text-sky-dark"
                        target="_blank"
                        href="/assets/alpha-cgm-guide.pdf"
                      >
                        View Instructions
                        <span className="ml-2 fa fa-arrow-right" />
                      </a>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        )}
        {curPage === pages.length - 1 ? (
          <Link to="/">
            <Button>Continue to Your Dashboard</Button>
          </Link>
        ) : (
          <Button onClick={clickNext}>Next</Button>
        )}
      </div>
    </PageWrapper>
  );
};

export default DiabetesCompletionPage;
