/** @format */

import type { RouteComponentProps } from "react-router-dom";

import React from "react";
import { Field, Form } from "react-final-form";

import { isEmpty } from "lodash";

import { SelectField, TextField } from "@alphamedical/components";

import { BackButtonHeading } from "src/components/_buttons/BackButtonHeading";
import { DynamicField } from "src/components/DynamicForm";
import { Loader } from "src/components/Loader";
import { PageWrapper } from "src/components/PageWrapper";
import { _POST, getTokenHeaderV2 } from "src/helpers/http";
import { AlphaModal } from "src/v2/components/AlphaModal";
import { Button } from "src/v2/components/Button";
import { STATES } from "src/v2/constants";
import { useStoreDispatch, useStoreState } from "src/v2/models";

export const PharmacyFormFields = () => {
  return (
    <>
      <Field name="id" component="input" type="hidden" />
      <div className="flex flex-col w-full items-center">
        <div className="w-full md:w-1/2">
          <TextField name="name" placeholder="Pharmacy Name" required={true} />
          <TextField name="address1" placeholder="Address 1" required={true} />
          <TextField name="address2" placeholder="Address 2" />
          <TextField name="city" placeholder="City" required={true} />
          <div className="flex justify-between space-x-3">
            <div className="flex-1">
              <SelectField
                name="state"
                placeholder="State"
                allowNull={true}
                options={STATES.map((state) => ({ value: state, label: state }))}
                required={true}
              />
            </div>
            <div className="flex-1 hidden sm:block" />
            <div className="flex-1">
              <DynamicField
                hideFieldWrapper={true}
                field={{
                  key: "zipcode",
                  label: "Zipcode",
                  required: true,
                  type: "text",
                  subtype: "zipcode",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const PharmacyForm = ({ history }: RouteComponentProps) => {
  const addresses = useStoreState((state) => state.addresses.addresses);
  const dispatch = useStoreDispatch();
  const [showForm, setShowForm] = React.useState<boolean>(false);
  const [showNotice, setShowNotice] = React.useState<boolean>(false);
  const [submitError, setSubmitError] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch.addresses.fetchAddresses({ type: "PHARMACY" });
      setLoading(false);
    })();
  }, [!isEmpty(dispatch.addresses)]);

  let pharmacyAddress: any = {};

  addresses.forEach((address) => {
    if (address.type === "PHARMACY") {
      pharmacyAddress = { ...address };
    }
  });

  const submitPharmacyForm = (values: any) => {
    const body = `
        Pharmacy Name: ${values.name}
        Address 1: ${values.address1}
        Address 2: ${values.address2 ? values.address2 : "none"}
        City: ${values.city}
        State: ${values.state}
        Zipcode: ${values.zipcode}
        `;
    _POST(
      "/zendesk/tickets/me/create",
      { subject: "Pharmacy Change Request", body, tags: ["pharmacy_change_request"] },
      getTokenHeaderV2(),
    )
      .then((res) => {
        setShowNotice(true);
      })
      .catch((err) => {
        setShowNotice(true);
        setSubmitError(true);
      });
  };

  const validateAddressInfo = {
    address1: pharmacyAddress.address1,
    city: pharmacyAddress.city,
    name: pharmacyAddress.name,
    state: pharmacyAddress.state,
    zipcode: pharmacyAddress.zipcode,
  };

  const hasAllPharmacyInfo = Object.values(validateAddressInfo).every(
    (addressItem) => typeof addressItem === "string",
  );

  return (
    <PageWrapper>
      <BackButtonHeading title="View or Change Pharmacy Information" />
      <Loader message={`Loading...`} show={loading}>
        {hasAllPharmacyInfo && !showForm && (
          <div className="flex flex-col items-center">
            <h1 className="text-2xl font-500 mb-8">Pharmacy Details</h1>
            <ul className="list-none">
              <li className="text-xl">
                <strong>Pharmacy Name:</strong> {pharmacyAddress.name}
              </li>
              <li className="text-xl">
                <strong>Address:</strong> {pharmacyAddress.address1}
              </li>
              {pharmacyAddress.address2 && (
                <li className="text-xl">
                  <strong>Address 2:</strong> {pharmacyAddress.address2}
                </li>
              )}
              <li className="text-xl">
                <strong>City:</strong> {pharmacyAddress.city}
              </li>
              <li className="text-xl">
                <strong>State:</strong> {pharmacyAddress.state}
              </li>
              <li className="text-xl">
                <strong>Zipcode:</strong> {pharmacyAddress.zipcode}
              </li>
            </ul>

            <div className="flex justify-center mx-auto mt-8 w-64">
              <Button onClick={() => setShowForm(true)}>Update</Button>
            </div>
          </div>
        )}
        {(showForm || !hasAllPharmacyInfo) && (
          <div>
            <Form onSubmit={submitPharmacyForm} initialValues={{}}>
              {({ handleSubmit, submitting }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="flex flex-col item-center justify-center">
                      <h1 className="text-2xl font-500 text-center mb-4">Pharmacy Details</h1>
                      <PharmacyFormFields />
                    </div>
                    <div className="flex justify-center mt-8">
                      <button type="submit" className="btn btn-primary" disabled={submitting}>
                        {submitting ? "Saving..." : "Submit"}
                      </button>
                    </div>
                  </form>
                );
              }}
            </Form>
          </div>
        )}
        <AlphaModal
          isOpen={showNotice}
          className="max-w-sm mx-auto bg-white"
          onRequestClose={() => {
            setShowNotice(false);
            setSubmitError(false);
            history.push("/account");
          }}
        >
          <div className="flex flex-col">
            <h1 style={{ top: "-18px", width: "315px" }} className="text-3xl md:relative font-500">
              {submitError ? "Notice" : "Pharmacy Info Submitted"}
            </h1>
            <div
              style={{ top: "-14px" }}
              className={`w-full md:relative border border-b-grey-500 mb-2`}
            />
            <div className={`text-body2 mt-4 mb-10`}>
              {submitError
                ? "Something went wrong, try again or reach out to the Customer Success Team through the messaging portal."
                : "Thank you for submitting your new pharmacy information. Please note that this change will need to be reviewed and approved by your Care Team before any of your active prescriptions can be updated."}
            </div>
          </div>
        </AlphaModal>
      </Loader>
    </PageWrapper>
  );
};
