/** @format */

import React from "react";
import { useHistory } from "react-router-dom";

import { PageWrapper } from "src/components/PageWrapper";
import { CancellationFlow } from "src/content/v2/routes/Membership/CancellationFlow";
import { useTopBar } from "src/utils/hooks/useTopBar";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";
import { useStoreState } from "src/v2/models";

const MembershipCancellation = () => {
  useTopBar({ title: "Cancel Plan", variant: "nested", showBackButton: true });
  const history = useHistory();
  const membershipAlerts = useStoreState((state) => state.alerts);
  const hasRequestedCancellation = membershipAlerts.requestedCancellation;
  const hasRequestedDowngrade = membershipAlerts.requestedDowngrade;

  const onClose = () => history.goBack();
  return (
    <PageWrapper>
      <CancellationFlow
        setModalOpen={onClose}
        hasRequestedCancellation={hasRequestedCancellation}
        hasRequestedDowngrade={hasRequestedDowngrade}
      />
    </PageWrapper>
  );
};

export default WithTopBarNavigation(MembershipCancellation);
