/** @format */

import type { FieldInputProps } from "react-final-form";

import React from "react";
import { useFormState } from "react-final-form";

import moment from "moment";
import { generateUUID } from "pubnub";

import type { BaseFieldProps, PopupProps } from "@alphamedical/components";

import {
  FieldError,
  FieldWrapper,
  Popup,
  Typography,
  useFieldName,
} from "@alphamedical/components";
import { ButtonPdb } from "src/v2/designSystem";

import type { DynamicFileField as IDynamicFileField } from "src/components/DynamicForm/types";
import type { ExifImageRotateCallbackPropsType } from "src/utils/image/types";

import { processImage } from "src/utils/image/process";
import NumberedList from "src/v2/components/NumberedList";
import { useStoreActions, useStoreState } from "src/v2/models";

import type { DynamicFieldProps } from "./index";

const imageWidth = "328px";

type DynamicFileFieldProps = Omit<DynamicFieldProps, "field"> &
  Partial<Omit<BaseFieldProps, "type">> & {
    field: IDynamicFileField;
    showLabelForImage?: boolean;
    disabled?: boolean;
    hideTryAgain?: boolean;
    version?: number;
    label?: string;
  };

const exifCallBack = (input: any) => (cb: ExifImageRotateCallbackPropsType) => {
  const image = cb.canvas.toDataURL("image/jpeg", 1);
  input.onChange(image);
};

const processPDF = (input: any, file: File) => {
  const fileReader = new FileReader();
  fileReader.readAsDataURL(file);
  fileReader.onload = () => {
    input.onChange(fileReader.result);
  };
};

const handleAnswerChange = (input: any) => (event: any) => {
  const file = event.target.files[0];
  if (file) {
    event.preventDefault();
    if (file.type === "application/pdf") {
      processPDF(input, file);
    } else {
      processImage(event.target.name, file, exifCallBack(input));
    }
  }
};

const DynamicFileFieldComponentV1 = (
  { field, showLabelForImage, ...props }: DynamicFileFieldProps,
  fieldName: string,
) => {
  return (
    <div>
      <FieldWrapper {...props} name={field.key} type="file">
        {({ input, meta }) => {
          const hasBgImage = !!field.imageUrl;
          return (
            <>
              <input
                name={`upload-for-${input.name}`}
                id={`upload-for-${input.name}`}
                className="display-none"
                type="file"
                accept={field.accepts || "image/*, application/pdf"}
                onChange={handleAnswerChange(input)}
              />
              <label htmlFor={`upload-for-${input.name}`}>
                {(input.value && (
                  <>
                    {input.value.indexOf("pdf") !== -1 ? (
                      <div>
                        <div style={{ height: "400px" }}>
                          <iframe className="w-full h-full" src={input.value} title="image" />
                        </div>
                        <p className="cursor-pointer">Choose different document</p>
                      </div>
                    ) : (
                      <div>
                        <img
                          className={`
                          flex flex-col
                          w-full max-w-sm
                          bg-white
                          border border-sky
                          m-auto
                          cursor-pointer
                        `}
                          src={input.value}
                          alt="display for file that was attached"
                        />
                        {showLabelForImage && (
                          <div className="mt-3 text-center cursor-pointer">
                            Choose a different photo
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )) || (
                  <>
                    <div
                      className={`
                          flex flex-col
                          w-full max-w-sm
                          h-64 bg-white
                          ${!hasBgImage ? "border border-sky" : ""}
                          m-auto
                          cursor-pointer
                          bg-center bg-contain bg-no-repeat
                        `}
                      style={{ backgroundImage: hasBgImage ? `url(${field.imageUrl})` : undefined }}
                    >
                      <div style={{ height: "100px" }} />
                      {!hasBgImage && (
                        <div className="h-1/3 flex justify-center items-center text-sky fas fa-plus text-6xl" />
                      )}
                      <div
                        className={`h-1/3 flex items-center text-center max-w-xs m-auto text-sm`}
                      >
                        {field.message || "Tap to take or upload a photo to us"}
                      </div>
                    </div>
                    <input {...input} id={input.name} type="hidden" value={input.value} />
                  </>
                )}
              </label>
            </>
          );
        }}
      </FieldWrapper>
      <FieldError name={fieldName} />
    </div>
  );
};

const renderFilePreview = (
  input: FieldInputProps<any>,
  disabled?: boolean,
  hideTryAgain?: boolean,
) => {
  const isPDF =
    input.value.startsWith("data:application/pdf;base64,") || input.value.indexOf(".pdf") !== -1;

  const iFrameForPDF = (
    <div style={{ height: "400px" }}>
      <iframe className="w-full h-full" src={input.value} title="image" />
    </div>
  );

  const imgElement = (
    <img
      className={`
        flex flex-col
        bg-white
        border border-sky
        m-auto
        cursor-pointer
      `}
      width={imageWidth}
      src={input.value}
      alt="display for file that was attached"
    />
  );

  const tryAgainLogo = (
    <>
      <img className="bg-sky-dark p-1 rounded-full" src="/assets/undo-arrow.svg" alt="Try Again" />
      <span className="text-sky-dark font-bold text-sm ml-2">Try again?</span>
    </>
  );

  return (
    <>
      {isPDF ? iFrameForPDF : imgElement}
      {!disabled && (
        <div className="flex justify-center items-center mt-2 cursor-pointer">
          {!hideTryAgain && tryAgainLogo}
        </div>
      )}
    </>
  );
};

const DynamicFileFieldComponentV2 = (
  { field, disabled, hideTryAgain, ...props }: DynamicFileFieldProps,
  fieldName: string,
) => {
  return (
    <div>
      <FieldWrapper {...props} name={field.key} type="file">
        {({ input }) => {
          return (
            <>
              <input
                name={`upload-for-${input.name}`}
                id={`upload-for-${input.name}`}
                className="display-none"
                type="file"
                accept={field.accepts || "image/*, application/pdf"}
                onChange={handleAnswerChange(input)}
                disabled={disabled}
              />
              <label htmlFor={`upload-for-${input.name}`}>
                {(input.value && renderFilePreview(input, disabled, hideTryAgain)) || (
                  <>
                    <div
                      className="grid max-w-sm bg-white items-start m-auto cursor-pointer justify-center p-10"
                      style={{ border: "2px dashed #6BA5A5" }}
                    >
                      <div className="flex items-center justify-center mt-8">
                        <div className="text-sky-dark fas fa-plus-circle text-md" />
                        <span className="ml-2 text-sky-dark font-bold text-md">Add Photo ID</span>
                      </div>
                      <p className="text-center text-forest-60 max-w-xs text-sm mt-3 mb-8">
                        {field.message || "Tap to take or upload a photo to us"}
                      </p>
                    </div>
                    <input {...input} id={input.name} type="hidden" value={input.value} />
                  </>
                )}
              </label>
            </>
          );
        }}
      </FieldWrapper>
      <FieldError name={fieldName} />
    </div>
  );
};

// This component variation is the standard file upload that will be iterated on to build more flexibility as needs arise
const DynamicFileFieldComponentV3 = (
  { field, disabled, hideTryAgain, ...props }: DynamicFileFieldProps,
  fieldName: string,
) => {
  return (
    <>
      <FieldWrapper {...props} name={field.key} type="file">
        {({ input }) => {
          return (
            <div className="flex flex-col my-2 justify-center items-center">
              {field.label && (
                <Typography variant="body2" className="mb-2 text-forest-100 text-left w-80">
                  {field.label}
                </Typography>
              )}
              <input
                name={`upload-for-${input.name}`}
                id={`upload-for-${input.name}`}
                className="display-none"
                type="file"
                accept={field.accepts || "image/*, application/pdf"}
                onChange={handleAnswerChange(input)}
                disabled={disabled}
              />
              <label htmlFor={`upload-for-${input.name}`}>
                {(input?.value && renderFilePreview(input, disabled, hideTryAgain)) || (
                  <>
                    <div className="p-6 flex flex-col h-48 w-80 text-grey-160 bg-grey-10 text-center items-center justify-center cursor-pointer border border-dashed border-cornflower-100">
                      <Typography variant="hyperlink">
                        {field.hyperlink || "Click to upload"}
                      </Typography>
                      <Typography
                        className="mt-4 whitespace-pre-line text-grey-160"
                        variant="body2"
                      >
                        {field.message || "Accepted file types:\nPDF, JPG, and PNG"}
                      </Typography>
                    </div>
                    <input {...input} id={input.name} type="hidden" value={input.value} />
                  </>
                )}
              </label>
            </div>
          );
        }}
      </FieldWrapper>
      <FieldError name={fieldName} />
    </>
  );
};

// This component variant is only used for license uploads
const DynamicFileFieldComponentV4 = (
  { field, disabled, ...props }: DynamicFileFieldProps,
  fieldName: string,
) => {
  const fileInput = React.useRef<any>();
  const [popupIsOpen, setPopupIsOpen] = React.useState(false);
  const addSnack = useStoreActions((actions) => actions.snacks.addSnack);
  const savedExpiration = useStoreState((state) => state.profile?.profile?.pii?.license_expiration);
  const [checkedLicenseExpiration, setCheckedLicenseExpiration] = React.useState(false);

  const exampleIdSrc = "/assets/drivers-license-example.png";
  const tipsPopupContentItems = [
    "Use a plain, dark surface for your background.",
    "Hold your phone vertically.",
    "Position the camera directly over your ID.",
    "Move the camera as close to your ID without cutting off any edges.",
  ];

  React.useEffect(() => {
    // This code checks for expired licence expiration and shows a toast to the user if their current license is expired
    if (field.key === "license_photo" && !checkedLicenseExpiration && savedExpiration) {
      // this function gets called every time the formValues are updated for license_expiration.
      // So we check if it has less than the correct number of values then set it to checked
      if (savedExpiration.length < 2) {
        setCheckedLicenseExpiration(true);
      } else if (moment(savedExpiration, "MM-DD-YYYY").isBefore()) {
        setCheckedLicenseExpiration(true);
        addSnack({
          type: "error",
          message: "This ID has expired. To proceed, please upload a valid ID.",
          id: "expired-id",
          delay: 5,
        });
      }
    }
  }, [savedExpiration]);

  const tipsPopupContent = (
    <div className="flex flex-col">
      <div className="mx-4 mt-4">
        <NumberedList items={tipsPopupContentItems} />
      </div>
      <div className="flex bg-grey-lightest p-4 justify-end">
        <ButtonPdb onClick={() => setPopupIsOpen(false)}>Close</ButtonPdb>
      </div>
    </div>
  );

  const tipsPopupProps: PopupProps = {
    headerText: "Tips for a clear photo",
    isOpen: popupIsOpen,
    onRequestClose: () => setPopupIsOpen(false),
  };

  return (
    <FieldWrapper {...props} name={field.key} type="file">
      {({ input }) => {
        return (
          <>
            <div className="flex justify-center mb-4">
              <div className="flex flex-col ">
                <div className="mt-6 mb-2" onClick={() => setPopupIsOpen(true)}>
                  <Typography className="cursor-pointer no-underline text-cornflower-100">
                    Tips for a clear photo
                  </Typography>
                </div>
                <Popup {...tipsPopupProps}>{tipsPopupContent}</Popup>
                <input
                  ref={fileInput}
                  name={`upload-for-${input.name}`}
                  id={`upload-for-${input.name}`}
                  className="display-none"
                  type="file"
                  accept={field.accepts || "image/*, application/pdf"}
                  onChange={handleAnswerChange(input)}
                  disabled={disabled}
                />
                <label htmlFor={`upload-for-${input.name}`} className="cursor-pointer">
                  {(input.value && renderFilePreview(input, disabled, true)) || (
                    <>
                      <img
                        src={exampleIdSrc}
                        width={imageWidth}
                        alt="An example of a Photo ID"
                        className="mb-3"
                      />
                      <input {...input} id={input.name} type="hidden" value={input.value} />
                    </>
                  )}
                </label>
                <FieldError name={fieldName} />
              </div>
            </div>
            <ButtonPdb
              type="button"
              variant="secondary"
              className="w-full"
              onClick={() => fileInput?.current?.click()}
            >
              {input.value ? "Choose a different photo" : "Choose a photo"}
            </ButtonPdb>
          </>
        );
      }}
    </FieldWrapper>
  );
};

export const DynamicFileFieldComponent = (props: DynamicFileFieldProps) => {
  const { field } = props;
  const version = props.version || field.componentVersion || 1;
  const fieldName = useFieldName(field.key);

  switch (version) {
    case 1:
      return DynamicFileFieldComponentV1(props, fieldName);
    case 2:
      return DynamicFileFieldComponentV2(props, fieldName);
    case 3:
      return DynamicFileFieldComponentV3(props, fieldName);
    case 4:
      return DynamicFileFieldComponentV4(props, fieldName);
    default:
      return <></>;
  }
};
