/** @format */

import type { History } from "history";

// Super hacky way to allow the History instance from react-router to be accessed without a hook.
// This is so that the history object can be access in easy-peasy models which can't use hooks.
// In general using this reference to history should be avoided if possible.
export let history: History | null = null;
export const setHistory = (h: History) => {
  history = h;
};
