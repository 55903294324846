/** @format */

import React from "react";

import { ServicesService } from "src/api";
import BackButton from "src/components/_buttons/BackButton";
import { PageWrapper } from "src/components/PageWrapper";
import config from "src/config";
import { useGetChannels, useServices } from "src/utils/hooks";
import { useTopBar } from "src/utils/hooks/useTopBar";
import IconTextLink from "src/v2/components/TreatmentInfo/IconTextLink";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";

const Help = () => {
  useTopBar({ showHelpButton: false, variant: "main", title: "Help" });
  const [serviceOptions, setServiceOptions] = React.useState<{ value: string; label: string }[]>(
    [],
  );
  const { serviceList } = useServices();
  const { channels } = useGetChannels();
  const supportChannel = channels?.find((channel) => channel.channel_type === "SUPPORT");
  const faqUrl = config.faqHelpCenterUrl;

  React.useEffect(() => {
    ServicesService.getServiceTypes().then((res) => {
      const options: Record<string, { value: string; label: string }> = {};
      res.forEach((service) => {
        if (!options[service.name]) {
          options[service.name] = { value: service.key, label: service.name };
        }
      });
      setServiceOptions(Object.values(options));
    });
  }, []);

  React.useEffect(() => {
    if (serviceOptions.length > 0 && serviceList.length > 0) {
      const map: Record<string, boolean> = {};
      serviceList.forEach((service) => (map[service.type] = true));
      const sortedList = serviceOptions.sort((a, b) => {
        if (map[a.value] && map[b.value]) {
          return a.label > b.label ? 1 : -1;
        } else if (map[a.value]) {
          return -1;
        } else if (map[b.value]) {
          return 1;
        } else {
          return a.label > b.label ? 1 : -1;
        }
      });
      setServiceOptions(sortedList);
    }
  }, [serviceList.length, serviceOptions.length]);

  return (
    <PageWrapper>
      <div className="mx-auto max-w-lg px-4 pt-12">
        <div className="flex">
          <BackButton />
          <h1 className="text-center w-full text-xl">We're Here to Help</h1>
        </div>
        <div className="mt-10">
          <div className="mt-12">
            <IconTextLink
              treatmentInfo={{
                icon: "/assets/email.svg",
                iconAlt: "Email Icon",
                url: supportChannel?.name ? `/messages/${supportChannel?.name}` : "/messages",
                title: "Contact Alpha Support →",
              }}
              showRightCarrot={false}
              showBorderTop={false}
              textClass={"text-forest-60"}
            />
            {faqUrl && (
              <IconTextLink
                treatmentInfo={{
                  icon: "/assets/information.svg",
                  iconAlt: "Information Icon",
                  url: faqUrl,
                  title: "View all FAQs →",
                }}
                openInNewWindow={true}
                showRightCarrot={false}
                showBorderTop={true}
                textClass={"text-forest-60"}
              />
            )}
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(Help);
