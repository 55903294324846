/** @format */

import type { UserPlan } from "src/api/models/UserPlan";

import { useStoreState } from "src/v2/models";

/**
 * Custom hook that checks if a user has employer benefits.
 * @param employ - Optional parameter that specifies the employer slug(s) to check against.
 * @returns A boolean value indicating whether the user has employer benefits. If multiple employer slugs are provided, the user must have benefits from at least one of them.
 */
export const useHasEmployerBenefits = (employ?: string | string[]) => {
  const userPlanRecords = useStoreState((state) => state.profile.profile?.employer_benefits);

  return hasEmployerBenefits(userPlanRecords, employ);
};

/**
 * Checks if a user has employer benefits based on the provided user plan records and employer slugs.
 * @param userPlanRecords - An array of user plan records.
 * @param employ - Optional parameter that specifies the employer slug(s) to check against.
 * @returns A boolean value indicating whether the user has employer benefits. If multiple employer slugs are provided, the user must have benefits from at least one of them.
 */
export const hasEmployerBenefits = (
  userPlanRecords: UserPlan[] | undefined,
  employ?: string | string[],
) => {
  if (!employ) {
    return userPlanRecords?.some((plan) => plan.active);
  }

  const employerSlugs = typeof employ === "string" ? [employ] : employ;
  return userPlanRecords?.some(
    (plan) => plan.active && employerSlugs.some((slug) => slug == plan.organization?.slug),
  );
};
