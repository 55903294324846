/** @format */

import React from "react";
import { useHistory } from "react-router-dom";

import type { History } from "history";
import { filter, map } from "lodash";

import type { FetchProfileResponse } from "src/v2/models/api_types";
import type { Channel } from "src/v2/models/channels";
import type { Service } from "src/v2/models/service";
import type { Tag } from "src/v2/models/tags";

import { _GET, getTokenHeaderV2 } from "src/helpers/http";
import { ACCEPTED_ASSESSMENT_TYPES } from "src/v2/constants";
import { useStoreDispatch, useStoreState } from "src/v2/models";
import { AuthenticatedUserContext } from "src/v2/models/auth";
import { OutlineContext } from "src/v2/models/outline";
import { CurrentUserContext, useCurrentUser } from "src/v2/models/profile";

import { serializeDependency } from "..";

export const useGetInsurance = () => {
  const profile = useStoreState((state) => state.profile.profile);
  const dispatch = useStoreDispatch();
  React.useEffect(() => {
    if (!profile || !profile.insurance) {
      dispatch.profile.fetchProfile({ include: ["insurance"] });
    }
  }, []);
  return profile && profile.insurance;
};

export const useGetMembership = () => {
  const dispatch = useStoreDispatch();
  const { membership, isFetchingMembership } = useStoreState((state) => state.membership);
  React.useEffect(() => {
    if (!membership) {
      dispatch.membership.fetchMembership();
    }
  }, []);
  return { membership, isFetchingMembership };
};

export const useGetFollowupItems = () => {
  const { currentProfileId, profile } = useStoreState((state) => state.profile);
  const dispatch = useStoreDispatch();
  React.useEffect(() => {
    if (!profile || !profile.followup_items) {
      dispatch.profile.fetchProfile({
        userId: currentProfileId,
        include: ["followup_items", "pii"],
      });
    }
  }, []);
  return profile && profile.followup_items;
};

export const useGetChannels = () => {
  const channels = useStoreState((state) => state.channels.channels) as Channel[] | undefined;
  const [loading, setLoading] = React.useState<boolean>(channels == null);
  const dispatch = useStoreDispatch();

  React.useEffect(() => {
    if (channels == null) {
      dispatch.channels.fetchChannels().finally(() => setLoading(false));
    }
  }, [serializeDependency(channels, ["id"])]);
  return { loadingChannels: loading, channels };
};

// Usage:
// const [value, setValue] = useQueryState(history, paramName)
// Creates a variable in the state that is tied to a URL query parameter.
// The default value is set by the query parameter if it exists
// and if the state value is modified, then the URL is updated as well.
export const useQueryState = (history: History, paramName: string): [string | null, any] => {
  const searchParams = new URLSearchParams(history.location.search);
  const [value, setValue] = React.useState<string | null>(searchParams.get(paramName));
  React.useEffect(() => {
    if (value !== searchParams.get(paramName)) {
      history.push({
        pathname: history.location.pathname,
        search: value ? `?${paramName}=${value}` : undefined,
      });
    }
  }, [value]);
  return [value, setValue];
};

// A convenience hook to wrap meta data around using an async Thunk, namely tracking a flag for loading and error
// handling. Could be moved and used generally in the codebase. Current shortcomings are: it will call the thunk when
// the component is mounted and will only call it once, and the return value of the thunk is not passed up.
export const useThunk = <T>(dispatchThunk: any): [boolean, T | undefined] => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<T | undefined>(undefined);

  React.useEffect(() => {
    dispatchThunk()
      .catch(setError)
      .finally(() => setLoading(false));
  }, []);

  return [loading, error];
};

// A hook to make a GET request to the API when the component is mounted.
// Usage: const [response, isLoading, error] = useGetOnMount<IResponse, IError>('/some/path', {});
export function useGetOnMount<TResponse, TError = any>(
  url: string,
  initialData: TResponse,
): [TResponse, boolean, TError | undefined] {
  const [fetching, setFetching] = React.useState<boolean>(true);
  const [error, setError] = React.useState<TError | undefined>(undefined);
  const [response, setResponse] = React.useState<TResponse>(initialData);
  React.useEffect(() => {
    _GET(url, {}, getTokenHeaderV2())
      .then(setResponse)
      .catch(setError)
      .finally(() => setFetching(false));
  }, []);
  return [response, fetching, error];
}

export const useAuthenticatedUser = (): [FetchProfileResponse, boolean] => {
  const profile = React.useContext(CurrentUserContext);
  const authenticatedUser = React.useContext(AuthenticatedUserContext);
  if (authenticatedUser == null) {
    throw Error(
      "Cannot access authenticatedUser. Make sure that useAuthenticatedUser is called inside AuthenticatedUserContext.Provider and that the authenticatedUser is set.",
    );
  }
  const isAuthenticatedUser = Boolean(
    authenticatedUser && profile && authenticatedUser.id === profile.id,
  );
  return [authenticatedUser, isAuthenticatedUser];
};

export const useCurrentUserIsChild = (): [FetchProfileResponse[] | undefined, boolean, boolean] => {
  const dispatch = useStoreDispatch();
  const [authenticatedUser] = useAuthenticatedUser();
  const currentUser = useCurrentUser();
  const { children } = authenticatedUser;
  const [currentUserIsChild, setCurrentUserIsChild] = React.useState<boolean>(false);
  const [loading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    dispatch.auth
      .fetchAuthenticatedUser({})
      .then(() => {
        if (currentUser && children?.find((child) => child.id === currentUser.id)) {
          setCurrentUserIsChild(true);
        }
      })
      .finally(() => setIsLoading(false));
  }, [currentUser.id]);

  return [children, currentUserIsChild, loading];
};

export const useChildRedirect = (redirectTo?: string) => {
  const history = useHistory();
  const [, isAuthenticatedUser] = useAuthenticatedUser();
  if (isAuthenticatedUser) return;
  // TODO: Add a state object to the push, to allow us to put an error message
  // on the page that has been redirected to
  return history.push(redirectTo || "/my-visits");
};

export const useCheckAndSaveAssessment = (authenticatedUser?: any) => {
  const dispatch = useStoreDispatch();
  const assessmentScore = localStorage.getItem("assessment_score");
  const assessmentType = localStorage.getItem("assessment_type") || "";
  const [scoreSaved, setScoreSaved] = React.useState(false);

  React.useEffect(() => {
    if (
      !scoreSaved &&
      authenticatedUser &&
      assessmentScore &&
      ACCEPTED_ASSESSMENT_TYPES.includes(assessmentType)
    ) {
      dispatch.records
        .saveAssessmentScore({
          assessmentScore: parseInt(assessmentScore),
          assessmentType,
        })
        .then(() => setScoreSaved(true));
    }
  }, [assessmentScore, assessmentType, serializeDependency(authenticatedUser, ["id", "uuid"])]);
};

export const useCurrentOutline = () => {
  const outline = React.useContext(OutlineContext);
  if (outline == null) {
    throw Error(
      "Cannot access outline. Make sure that useOutline is called inside OutlineContext.Provider and that the outline is set.",
    );
  }
  return outline;
};

interface Option {
  label: string;
  value: string;
}

export const useTags = () => {
  const dispatch = useStoreDispatch();
  const subjectList: Tag[] = useStoreState((state) => state.tags.tagsList);

  React.useEffect(() => {
    dispatch.tags.fetchTags();
  }, []);

  const subjectOptions: Option[] = map(subjectList, (subject) => ({
    label: subject.label,
    value: subject.slug,
    team: subject.team,
  }));
  return { subjectList, subjectOptions };
};

export const useTagGroups = () => {
  const dispatch = useStoreDispatch();
  const superTagList: Tag[] = useStoreState((state) => state.tags.tagGroupsList);

  React.useEffect(() => {
    dispatch.tags.fetchMessageCategoryGroups();
  }, []);

  return superTagList;
};

export const useServices = () => {
  const dispatch = useStoreDispatch();
  const serviceList: Service[] = useStoreState((state) => state.service.serviceList);

  React.useEffect(() => {
    dispatch.service.fetchServices();
  }, []);

  const filteredServiceList = filter(
    serviceList,
    (service) => !!service.condition && service.channel_id,
  );
  const serviceOptions: Option[] = map(filteredServiceList, (service) => ({
    label: service?.condition?.name || "",
    value: service?.condition?.key || "",
  }));
  return { serviceList, serviceOptions };
};

export const usePromise = <T>(getPromise: () => Promise<T>) => {
  const [result, setResult] = React.useState<T | undefined>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<unknown>();

  const triggerPromise = () => {
    setLoading(true);
    getPromise()
      .then(setResult)
      .catch(setError)
      .finally(() => setLoading(false));
  };

  return [result, loading, error, triggerPromise] as const;
};

export const usePromiseOnMount = <T>(getPromise: () => Promise<T>) => {
  const [result, loading, error, triggerPromise] = usePromise<T>(getPromise);

  React.useEffect(() => {
    triggerPromise();
  }, []);

  return [result, loading, error] as const;
};
