/** @format */

export default {
  /**
   * Plan Benefits Prompt
   */
  title: "Alpha Basic Plan",
  details:
    "Discover healthcare that's in hand and on demand, with personalized care from an expert in women's health.",
  benefitHealth:
    "[2 visits per month]{callback}, that can be used for [over 100 medical conditions]{callback}",
  benefitCost: "Flexible, month-to-month commitment",
  benefitMessages: "Unlimited messaging with your Care Team",
  joinButton: "Join Alpha Basic Plan for {{price}}/month",
  skipButton: "Continue Single Visit for {{price}}",
};
