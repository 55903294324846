/** @format */

import React from "react";

import { AlphaModal } from "src/v2/components/AlphaModal";
import { Button } from "src/v2/components/Button";

interface InformationalPopupProps {
  isOpen: boolean;
  onRequestClose: (boolean: boolean) => void;
  boxBgColor?: any;
  title?: string;
  text?: string;
  btnStyle?: string;
  btnText?: string;
  positionCloseButton?: any;
  paymentPreference?: string;
}

export const InformationalPopup = ({
  isOpen,
  onRequestClose,
  positionCloseButton,
  boxBgColor,
  title,
  text,
  btnStyle,
  btnText,
}: InformationalPopupProps) => {
  return (
    <AlphaModal
      className="p-4 bg-white max-w-sm m-auto"
      isOpen={isOpen}
      positionCloseButton={positionCloseButton}
    >
      <div className="flex flex-col">
        <div style={boxBgColor} className="p-6 flex flex-col justify-center items-center mb-6">
          <h3 className="text-2xl font-bold text-center">{title}</h3>
          <p className="mb-0">{text}</p>
        </div>
        <Button onClick={() => onRequestClose(false)} bgColor={btnStyle}>
          <span>{btnText}</span>
        </Button>
      </div>
    </AlphaModal>
  );
};
