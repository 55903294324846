/** @format */

import React from "react";

import type { FollowupItem } from "src/components/Popups/types";

import { AlphaModal } from "src/v2/components/AlphaModal";
import { Button } from "src/v2/components/Button";
import { formatPrice } from "src/v2/components/Payment/utils";
import { useStoreDispatch } from "src/v2/models";

export interface ClosedNetworkApprovalPopupProps {
  item: FollowupItem;
  showPopup: boolean;
  setShowPopup: (arg: boolean) => void;
  key?: number;
  pii?: any;
  profile?: any;
}

export const ClosedNetworkApprovalPopup = ({
  item: {
    followup_type,
    medication_name,
    order_id,
    condition_name,
    medication_cost,
    days_supply,
    is_one_time,
    condition_key,
  },
  showPopup,
  setShowPopup,
  pii,
  profile,
}: ClosedNetworkApprovalPopupProps) => {
  const [showForm, setShowForm] = React.useState<boolean>(false);
  const dispatch = useStoreDispatch();
  const payCashOrSelectClosedNetwork = (approved: boolean) => {
    if (approved) {
      dispatch.profile.sendOrderApproval({
        approved,
        order_id,
        medication_name,
        followup_type,
        condition_name,
        days_supply,
        medication_cost,
      });
    } else {
      dispatch.profile.sendOrderApproval({
        approved,
        order_id,
        followup_type,
        condition_name,
        medication_name,
      });
    }
    setShowPopup(false);
    setShowForm(false);
  };

  const monthsSupply = days_supply && Math.round(days_supply / 30);
  const formattedCost =
    condition_key === "skincare" || is_one_time
      ? medication_cost && formatPrice(medication_cost)
      : medication_cost &&
        `${formatPrice(medication_cost)} ${
          monthsSupply && monthsSupply > 1 ? `every ${monthsSupply} months` : "per month"
        }`;

  return (
    <div>
      <AlphaModal
        isOpen={showPopup}
        className="max-w-sm m-auto bg-white shadow-md"
        addPaddingForNavBar={true}
        cxPadding="p-8"
        onRequestClose={() => {
          setShowPopup(false);
        }}
      >
        {!showForm && (
          <div className="flex flex-col text-16">
            <p className="font-semibold">
              Hi {(pii && pii.preferred_firstname) || (pii && pii.first_name)},
            </p>
            <p className="mb-4">
              We submitted your prescription to our partner pharmacy and it was denied because your
              insurance carrier has a closed network and doesn't cover pharmacies outside of their
              network. For more information, you can call the member services number located on the
              back of your insurance card.
            </p>
            <p className="mb-4">
              You are always welcome to proceed as a cash patient. The cash price for{" "}
              {medication_name} is {formattedCost} per month. Shipping is always free.
            </p>

            <p className="mb-4">
              Alternatively, you can transfer your prescription to a pharmacy that is a part of your
              carrier's network.
            </p>
            <p className="mb-6">
              Please let us know if you have any questions, we are here to help!
            </p>
            <div className="flex justify-content">
              <Button
                extraClassNames="btn btn-violator w-1/2 mr-4 cursor-pointer"
                disabled={profile.postingApproval}
                onClick={() => payCashOrSelectClosedNetwork(true)}
              >
                Pay Cash
              </Button>
              <Button
                extraClassNames={`
                                    text-center
                                    border-2 border-primary
                                    hover:text-white
                                    items-center w-1/2 cursor-pointer`}
                disabled={profile.postingApproval}
                bgColor={"white"}
                hoverBgColor={"primary-80"}
                textColor={"primary"}
                onClick={() => setShowForm(true)}
              >
                Transfer
              </Button>
            </div>
            {profile.error && <p className="text-red">{profile.error}</p>}
          </div>
        )}
        {showForm && (
          <div className="w-sm bg-white m-auto py-6">
            <div
              className={`
            absolute top-0 left-0 fas fa-arrow-left text-2xl p-5 mt-1 cursor-pointer text-primary-60 hover:text-primary
        `}
              onClick={() => setShowForm(false)}
            />
            <p className="mb-4">
              To continue with this option, you must contact your preferred pharmacy to request that
              they complete this prescription transfer.
            </p>
            <p className="mb-4">
              Please note that you will no longer receive your medication from our partner pharmacy
              in the mail if you choose to proceed with this option. Your preferred pharmacy will
              need to contact our partner pharmacy (contact information below) to request the
              transfer for you:
            </p>
            <ul>
              <li>
                <b>Pharmacy Name:</b> Welldyne
              </li>
              <li>
                <b>Address:</b> 500 Eagles Landing Dr Suite A, Lakeland, FL 33810
              </li>
              <li>
                <b>Pharmacy customer service:</b> 888-525-2197
              </li>
              <li>
                <b>Main Fax Line:</b> 855-618-4608
              </li>
            </ul>
            <div className="flex">
              <Button
                extraClassNames="w-1/2"
                disabled={profile.postingApproval}
                onClick={() => payCashOrSelectClosedNetwork(false)}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </AlphaModal>
    </div>
  );
};
