/** @format */

import React from "react";
import { Field } from "react-final-form";

import { CheckboxNewField, FieldGroup } from "@alphamedical/components";

import { DynamicField } from "src/components/DynamicForm";
import { STATES_FULL } from "src/v2/constants";

export const PrimaryCarePhysicianFields = () => (
  <>
    <Field name="id" component="input" type="hidden" />
    <FieldGroup name="pcp">
      <div className="w-full mt-4">
        <DynamicField
          field={{
            key: "first_name",
            label: "First name",
            required: true,
            type: "text",
          }}
        />
        <DynamicField
          field={{
            key: "last_name",
            label: "Last name",
            required: true,
            type: "text",
          }}
        />
        <DynamicField
          field={{
            key: "address1",
            label: "Address line 1",
            type: "text",
          }}
        />
        <DynamicField
          field={{
            key: "address2",
            label: "Address line 2",
            type: "text",
          }}
        />
        <DynamicField
          field={{
            key: "city",
            label: "City",
            type: "text",
          }}
        />
        <div className="flex flex-row">
          <DynamicField
            field={{
              key: "state",
              label: "State",
              placeholder: "Select",
              required: true,
              category: "pii",
              type: "select",
              options: STATES_FULL,
              subtype: "dropdown",
            }}
            className="flex-1 mr-3"
          />
          <DynamicField
            field={{
              key: "zipcode",
              required: true,
              type: "text",
              subtype: "zipcode",
              label: "ZIP code",
            }}
            className="flex-1 ml-3"
          />
        </div>
        <DynamicField
          field={{
            key: "phone_number",
            label: "Phone number",
            required: true,
            type: "text",
            subtype: "phone",
          }}
        />
        <CheckboxNewField
          name="authorized"
          options={[{ label: "Authorized to contact", value: "option1" }]}
          className="mt-1"
        />
      </div>
    </FieldGroup>
  </>
);
