/** @format */

import React from "react";
import BarLoader from "react-spinners/BarLoader";

import { Paper } from "src/v2/designSystem";

import type { BillingInfoSchema } from "src/api";

import { UsersService } from "src/api";
import { PageWrapper } from "src/components/PageWrapper";
import { PAYMENT_METHODS } from "src/routes";
import { useTopBar } from "src/utils/hooks/useTopBar";
import BillingInfoForm from "src/v2/components/Forms/BillingInfo/BillingInfoForm";
import { WithTopBarNavigation } from "src/v2/components/WithTopBarNavigation";

export const PaymentsPage = () => {
  useTopBar({ title: "Payment methods", variant: "nested", showBackButton: true });
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [billing, setBilling] = React.useState<BillingInfoSchema>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [credit, setCredit] = React.useState<any>();
  const [redemptionBalance, setRedemptionBalance] = React.useState<string | null>(null);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [creditError, setCreditError] = React.useState<boolean>(false);

  const toggleModal = (toggle: boolean) => {
    setIsOpen(!toggle);
  };

  const resetCard = () => {
    setBilling(undefined);
  };

  React.useEffect(() => {
    const initializePayment = async () => {
      try {
        setBilling(await UsersService.userBillingInfo());
      } catch (e) {
        // tslint:disable-next-line
        console.log("no billing...rendering payment form");
      }
      try {
        const balanceInfo = await UsersService.userCreditInfo();
        let creditBalance = balanceInfo.credit_balance;
        let redemptionBalance = balanceInfo.redemptions_balance;
        creditBalance = (Math.min(creditBalance, 0) / 100) * -1;
        redemptionBalance = (Math.min(redemptionBalance, 0) / 100) * -1;
        setCredit && setCredit(creditBalance.toFixed(2));
        if (redemptionBalance) {
          setRedemptionBalance(redemptionBalance.toFixed(2));
        }
        setCreditError(false);
      } catch (e) {
        setCreditError(true);
      }
    };
    initializePayment().finally(() => setLoading(false));
  }, []);

  return (
    <PageWrapper cxPadding="px-0" fitToPageHeight showIntercom>
      <Paper
        elevation={0}
        rounded={false}
        className="flex-1 mx-auto h-screen w-full max-w-2xl overflow-hidden pb-24"
      >
        {loading && (
          <div className="flex justify-center pt-32 mx-auto mt-8 px-6">
            <BarLoader color={"#A4B7B5"} loading={loading} />
          </div>
        )}
        {!loading && (
          <div className="mx-auto mt-8 px-6">
            <>
              <div className="m-auto">
                <h3 className="mt-2 text-forest-100 font-semibold text-sm ">
                  YOUR PAYMENT METHODS
                </h3>
              </div>
              <div className="px-5 m-auto h-16 bg-white flex items-center justify-center mt-2 mb-8">
                {(billing && (
                  <div className="w-full flex items-center">
                    <div className="text-18 flex-1">{billing.card_type}</div>
                    <div className="text-18 mr-8">
                      <div>
                        <span className="display-none sm:display-inline">**** ****</span>
                        **** {billing.last_four}
                      </div>
                    </div>
                    <div className="text-18 mr-4 sm:mr-8">
                      <div>
                        {billing.month} / {billing.year}
                      </div>
                    </div>
                    <div
                      className="text-20 cursor-pointer"
                      onClick={() => {
                        toggleModal(isOpen);
                        resetCard();
                      }}
                    >
                      <img
                        src="/assets/cancel.svg"
                        alt="Clear current billing information and enter new billing information"
                      />
                    </div>
                  </div>
                )) || (
                  <>
                    <div onClick={() => setIsOpen(!isOpen)} className="flex cursor-pointer">
                      <img src="/assets/plus.svg" alt="Enter billing information" />
                      <p className="ml-3 font-semibold text-lg">Add your payment method</p>
                    </div>
                    {isOpen && (
                      <BillingInfoForm
                        isOpen={isOpen}
                        saving={saving}
                        onRequestClose={toggleModal}
                        setBilling={setBilling}
                        setSaving={setSaving}
                      />
                    )}
                  </>
                )}
              </div>
            </>
            <div className="m-auto">
              <h3 className="mt-2 text-forest-100 font-semibold text-sm ">
                YOUR CREDITS &amp; COUPONS
              </h3>
              <p className="text-forest-60 text-sm mt-4 mb-4">
                These will be automatically applied to your next transaction, including consult
                fees, medication orders or any Alpha Medical service.
              </p>
            </div>
            {!creditError ? (
              <>
                <div className="px-5 m-auto h-16 bg-white flex items-center justify-center mt-2 mb-3">
                  <div className="w-full flex items-center">
                    <div className="flex-1">Credit balance</div>
                    <div className="flex-initial mr-2">{credit ? `$${credit}` : "$0.00"}</div>
                  </div>
                </div>
                <div className="px-5 m-auto h-16 bg-white flex items-center justify-center mt-2 mb-8">
                  <div className="w-full flex items-center">
                    <div className="flex-1">Coupon balance</div>
                    <div className="flex-initial mr-2">
                      {redemptionBalance ? `$${redemptionBalance}` : "$0.00"}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="px-5 m-auto h-16 bg-white flex items-center justify-center mt-2 mb-8">
                <div className="w-full flex items-center">
                  <div className="p-2 text-sm">
                    Your credit details could not be found. Please{" "}
                    <a className="text-forest-60 underline font-semibold" href={PAYMENT_METHODS}>
                      refresh
                    </a>{" "}
                    and contact support if the issue persists.
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </Paper>
    </PageWrapper>
  );
};

export default WithTopBarNavigation(PaymentsPage);
